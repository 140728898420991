import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
  WithStore
} from 'pure-react-carousel'
import { Fragment, RefObject, forwardRef, useEffect, useState } from 'react'

import { Tag } from 'components/Tag'

import {
  MAX_WIDTH_TAILWIND_LG,
  MAX_WIDTH_TAILWIND_XS,
  MIN_WIDTH_TAILWIND_2XL,
  MIN_WIDTH_TAILWIND_XL
} from 'constants/breakpoints'

import {
  Collection,
  ProgramBookmarkPartsFragment,
  SavedArtifactsAndGuidesForUserQuery
} from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { cn } from 'utils/tailwind'

import { ReactComponent as ChevronLeftIcon } from 'images/icon--thin-chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from 'images/icon--thin-chevron-right.svg'

import FeedItem from './FeedItem'

interface SwimlaneCarouselProps {
  recommendedCollection: Collection
  savedData?: SavedArtifactsAndGuidesForUserQuery
  pageLocation: string
  index: number
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  highlighted?: boolean
  userIsPremember?: Boolean
}

interface SwimlaneCarouselContainerState {
  currentSlide: number
  totalSlides: number
  visibleSlides: number
}

export const SwimlaneCarousel = ({
  recommendedCollection,
  savedData,
  pageLocation,
  index,
  highlighted,
  openAddToBookmarkFolderModal,
  userIsPremember
}: SwimlaneCarouselProps) => {
  const [slideData, setSlideData] = useState(recommendedCollection)
  const visibleSlides = useSwimlanesSlidesCount()

  const [slidesToShow, setSlidesToShow] = useState(visibleSlides)
  const isMobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
  const isDesktop = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_LG})`)
  const isLiveRecs = recommendedCollection.title.includes('upcoming live course')

  useEffect(() => {
    if (isLiveRecs) {
      const items = recommendedCollection.items.filter(
        (course) => course.live === true && course.nextLiveSession !== null
      )

      setSlideData({ ...recommendedCollection, items })

      if (isMobileView) {
        setSlidesToShow(1)
      } else if (isDesktop) {
        setSlidesToShow(2)
      } else {
        setSlidesToShow(3)
      }
    }
  }, [recommendedCollection, isLiveRecs, isDesktop, isMobileView])

  return (
    <CarouselProvider
      naturalSlideWidth={400}
      naturalSlideHeight={400}
      totalSlides={isMobileView ? slideData.items.length + 1 : slideData.items.length} // +1 for the empty element
      visibleSlides={isLiveRecs ? slidesToShow : visibleSlides}
      isIntrinsicHeight={true}
      className="w-full"
    >
      <CarouselWithStore
        openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
        visibleSlides={isLiveRecs ? slidesToShow : visibleSlides}
        recommendedCollection={slideData}
        savedData={savedData}
        pageLocation={pageLocation}
        highlighted={highlighted}
        index={index}
        userIsPremember={userIsPremember}
      />
    </CarouselProvider>
  )
}

interface CarouselProps {
  visibleSlides: number
  recommendedCollection: Collection
  savedData?: SavedArtifactsAndGuidesForUserQuery
  pageLocation: string
  index: number
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  highlighted?: boolean
  userIsPremember?: Boolean
}

const BUTTON_STYLINGS = 'text-rb-black flex items-center justify-center'

const Carousel = forwardRef(
  (
    {
      recommendedCollection,
      pageLocation,
      openAddToBookmarkFolderModal,
      visibleSlides,
      currentSlide,
      savedData,
      index,
      userIsPremember,
      highlighted = false
    }: CarouselProps & SwimlaneCarouselContainerState,
    // Required otherwise there will be an error in console.
    ref: RefObject<HTMLDivElement> // eslint-disable-line @typescript-eslint/no-unused-vars
  ) => {
    const [slideWidth, setSlideWidth] = useState(0)
    const isMobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
    const gapWidth = 24
    const minCardWidth = 272
    const containerWidth = document.body.offsetWidth - 16 * 2 // remove the left/right content padding - only used on mobile view
    const totalSlidesWidth = slideWidth * visibleSlides
    const totalGapWidth = gapWidth * visibleSlides
    const sliderWidth = totalSlidesWidth + totalGapWidth
    const emptyElementWidth = isMobileView
      ? slideWidth - Math.abs(containerWidth - sliderWidth) + 2 * gapWidth
      : 0
    const isLastSlideVisible =
      recommendedCollection.items.length - visibleSlides === currentSlide
    const isFirstSlideVisible = currentSlide === 0
    const isLiveCourseSection =
      recommendedCollection.title.includes('upcoming live course')

    const isDraftBeta = recommendedCollection.title.includes('Draft your own PRDs')

    const preprocessCollection = () => {
      if (isLiveCourseSection) {
        return recommendedCollection.items.filter(
          (course) => course.live === true && course.nextLiveSession !== null
        )
      }

      return recommendedCollection.items
    }

    useEffect(() => {
      document.body.classList.add('overscroll-x-none')

      return () => {
        document.body.classList.remove('overscroll-x-none')
      }
    }, [])

    useEffect(() => {
      const onResize = () => {
        if (isMobileView) {
          const containerWidth = document.body.offsetWidth - 16 * 2
          setSlideWidth(
            Math.max(containerWidth * 0.7 + gapWidth, minCardWidth + gapWidth)
          )
        }
      }
      window.addEventListener('resize', onResize)

      onResize()

      return () => {
        window.removeEventListener('resize', onResize)
      }
    }, [isMobileView])

    return (
      <div className={cn(highlighted ? 'pt-8' : 'py-8')}>
        <div
          className={cn(
            'flex flex-col gap-6',
            highlighted && 'bg-rb-orange-25 pb-8 pt-4 rounded-xl mx-[-24px] px-6'
          )}
        >
          <div className="relative w-full overflow-x-hidden">
            <div className="mb-6 flex items-center justify-between">
              <div className="flex items-center gap-4">
                <div
                  className={cn(
                    'text-xl font-semibold',
                    isLiveCourseSection && 'font-sans'
                  )}
                >
                  {recommendedCollection.title}
                </div>
                {isDraftBeta && (
                  <Tag
                    text="Beta"
                    className="ml-2 cursor-default bg-[rgba(8,10,10,0.10)] text-rb-gray-300"
                  />
                )}
              </div>
              <div
                className={cn('hidden justify-end gap-2 pr-6 md:flex', {
                  'md:hidden': recommendedCollection.items.length === visibleSlides
                })}
              >
                <ButtonBack
                  className={cn(BUTTON_STYLINGS, {
                    'cursor-default ': isFirstSlideVisible
                  })}
                  disabled={isFirstSlideVisible}
                >
                  <ChevronLeftIcon
                    className={cn('h-4 w-4 text-rb-black', {
                      'text-rb-gray-100': isFirstSlideVisible
                    })}
                    fill="currentColor"
                  />
                </ButtonBack>
                <ButtonNext
                  className={cn(BUTTON_STYLINGS, {
                    'cursor-default': isLastSlideVisible
                  })}
                  disabled={isLastSlideVisible}
                >
                  <ChevronRightIcon
                    className={cn('h-4 w-4 text-rb-black', {
                      'text-rb-gray-100': isLastSlideVisible
                    })}
                    fill="currentColor"
                  />
                </ButtonNext>
              </div>
            </div>

            <Slider
              style={{
                width: isMobileView ? `${totalSlidesWidth}px` : null
              }}
              classNameAnimation="transition-transform"
              trayProps={{
                draggable: true
              }}
            >
              {preprocessCollection().map((recommendedItem, i) => {
                return (
                  <Fragment key={i}>
                    {/* Hack - we render the below div with changing width to mimic behavior of homepage UiKit carousel: */}
                    {/* - sticking the last Slide to the right edge of the container when it becomes the active slide */}
                    {i === recommendedCollection.items.length - visibleSlides && (
                      <div
                        className="h-[1px] transition-width"
                        style={{
                          width:
                            isMobileView && currentSlide >= i + 1
                              ? `${emptyElementWidth}px`
                              : 0
                        }}
                      />
                    )}

                    <Slide
                      index={i}
                      key={i}
                      innerClassName="flex h-full"
                      style={
                        isMobileView
                          ? { paddingRight: gapWidth, width: slideWidth }
                          : { paddingRight: gapWidth }
                      }
                    >
                      <FeedItem
                        pageLocation={pageLocation}
                        recommendedCollectionId={recommendedCollection.id}
                        feedItem={recommendedItem}
                        index={i}
                        sectionIndex={index}
                        sectionTitle={recommendedCollection.title}
                        savedData={savedData}
                        openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                        userIsPremember={userIsPremember}
                      />
                    </Slide>
                  </Fragment>
                )
              })}
            </Slider>
          </div>
        </div>
      </div>
    )
  }
)

Carousel.displayName = 'SwimlaneCarousel'

const CarouselWithStore = WithStore<CarouselProps, SwimlaneCarouselContainerState>(
  Carousel,
  (state) => ({
    currentSlide: state.currentSlide,
    totalSlides: state.totalSlides,
    visibleSlides: state.visibleSlides
  })
)

const useSwimlanesSlidesCount = () => {
  const thirdSlide = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_XL})`)
  const fourthSlide = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_2XL})`)

  return [fourthSlide, thirdSlide, true, true].filter((slide) => slide).length
}

export default SwimlaneCarousel
