import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useGlobalState } from 'state'

import BulkRsvpChecklistCard from 'domains/CohortDashboard/CohortDashboardProgram/CohortDashboardWeeklyBlock/WeeklyBlockChecklist/BulkRsvpChecklistCard'
import WeeklyBlockChecklistCard from 'domains/CohortDashboard/CohortDashboardProgram/CohortDashboardWeeklyBlock/WeeklyBlockChecklist/WeeklyBlockChecklistCard'

import CommitmentModal from 'components/CommitmentModal'
import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import { COMMITMENT_MODAL_ID } from 'constants/elementIds'

import {
  CohortDashboardScheduledWeekUserCohortPartsFragment,
  useUpdateUserCohortMutation
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { INTRO_TOPIC_SLUG } from 'utils/cohortUtils'
import { showModal } from 'utils/modal'
import { makeConversationUrl } from 'utils/url'

export interface WeeklyBlockChecklistKickoffWeekProps {
  cohortSlug: string
  userMadeIntroPost: boolean
  userCohort?: CohortDashboardScheduledWeekUserCohortPartsFragment | null // null if not enrolled in cohort
  handleRsvpClick: () => void
  bulkRsvpChecked: boolean
}

export const WeeklyBlockChecklistKickoffWeek = ({
  cohortSlug,
  userMadeIntroPost,
  userCohort,
  handleRsvpClick,
  bulkRsvpChecked
}: WeeklyBlockChecklistKickoffWeekProps) => {
  const history = useHistory()
  // 40 is the ID of Spring 2023 Season
  const isAfterSpring2023Season = parseInt(userCohort?.cohort?.season.id || '0') > 40
  const [envVariables] = useGlobalState('envVariables')
  const { currentUser } = useCurrentUser()

  const socIsClickable = !!userCohort && !userCohort.hasSignedStatementOfCommitment
  const [updateUserCohort] = useUpdateUserCohortMutation()
  const [hasClickedMeetCohortParticipantsCta, setHasClickedMeetCohortParticipantsCta] =
    useState(
      userCohort?.hasClickedMeetCohortParticipantsCta.includes(userCohort.cohort.id)
    )

  const introTitle = (
    <div className="flex !flex-row space-x-1">
      <RfParagraphMedium>
        Introduce yourself in the
        <span className="font-semibold"> Intros & off topic </span>
        channel
      </RfParagraphMedium>
    </div>
  )

  const introInSlackChannelTitle = (
    <div className="flex !flex-row space-x-1">
      <RfParagraphMedium>Join the slack channel</RfParagraphMedium>
    </div>
  )

  const statementOfCommitmentTitle = (
    <RfParagraphMedium>Sign our statement of commitment</RfParagraphMedium>
  )

  const handleIntroCardClick = () => {
    if (isAfterSpring2023Season) {
      setHasClickedMeetCohortParticipantsCta(true)

      if (userCohort) {
        const clickedInCohortIds = [
          ...userCohort.hasClickedMeetCohortParticipantsCta,
          userCohort.cohort.id
        ]

        updateUserCohort({
          variables: {
            input: {
              id: userCohort.id,
              hasClickedMeetCohortParticipantsCta: clickedInCohortIds
            }
          }
        })
      }

      window.open(
        currentUser?.slackUserId
          ? `https://slack.com/app_redirect?channel=${userCohort?.cohort.slackChannelId}&team=T01LD82F0M8`
          : envVariables.joinSlackHref,
        '_blank'
      )
    } else {
      history.push(makeConversationUrl(cohortSlug, INTRO_TOPIC_SLUG))
    }
  }

  // no userCohort if not enrolled (for ex EIRs and staff) - do not show Statement of Commitment modal as it will not work for them
  const handleSocClick = () => {
    if (socIsClickable) {
      showModal(COMMITMENT_MODAL_ID)
    }
  }

  return (
    <div data-testid="checklist-kickoff-week">
      <div className="mb-8">
        <RfHeader3SemiBold className="py-4">Let&apos;s get set up</RfHeader3SemiBold>
        <div
          onClick={handleIntroCardClick}
          onKeyDown={handleIntroCardClick}
          role="button"
          tabIndex={0}
          data-testid="intro-checkist-card"
        >
          <WeeklyBlockChecklistCard
            titleComponent={
              isAfterSpring2023Season ? introInSlackChannelTitle : introTitle
            }
            checked={
              isAfterSpring2023Season
                ? hasClickedMeetCohortParticipantsCta
                : userMadeIntroPost
            }
          />
        </div>
        <div
          onClick={handleSocClick}
          onKeyDown={handleSocClick}
          role="button"
          tabIndex={0}
          data-testid="statement-of-commitment-checkist-card"
        >
          <WeeklyBlockChecklistCard
            titleComponent={statementOfCommitmentTitle}
            checked={userCohort?.hasSignedStatementOfCommitment}
            clickable={socIsClickable}
          />
        </div>
        <div data-testid="bulk-rsvp-checkist-card">
          <BulkRsvpChecklistCard
            handleRsvpClick={handleRsvpClick}
            bulkRsvpChecked={bulkRsvpChecked}
          />
        </div>
      </div>
      {userCohort && <CommitmentModal userCohortId={userCohort?.id} />}
    </div>
  )
}

export default WeeklyBlockChecklistKickoffWeek
