import { ReactionButtons } from 'components/ReactionButtons/ReactionButtons'

import { CohortPostPartsFragment, CohortPostReactionPartsFragment } from 'gql'

import { useReactions } from 'hooks/useReactions'

import { onEnterKeyPress } from 'utils/keyboard'

interface CohortPostCardReactionsProps {
  currentUserId: string
  reactions: CohortPostReactionPartsFragment[]
  reactableId: string
  reactableType: string
  reactablePost: CohortPostPartsFragment
}

const CohortPostCardReactions = ({
  currentUserId,
  reactions,
  reactableId,
  reactableType,
  reactablePost
}: CohortPostCardReactionsProps) => {
  const { posting, addReaction, removeReaction } = useReactions({
    reactions,
    currentUserId,
    reactableId,
    reactableType,
    reactablePost,
    isFromCohort: true
  })

  return (
    <div
      role="button"
      tabIndex={0}
      className="mt-2 flex w-10 items-center"
      onClick={(e) => e.stopPropagation()}
      onKeyUp={onEnterKeyPress((e) => e.stopPropagation())}
    >
      <ReactionButtons
        currentUserId={currentUserId}
        reactableType={reactableType}
        reactions={reactions}
        reactableId={reactableId}
        disabled={posting}
        addReaction={addReaction}
        removeReaction={removeReaction}
        svgContainerClassName="w-10"
        width="16"
        height="16"
      />
    </div>
  )
}

export default CohortPostCardReactions
