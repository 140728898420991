import { CarouselProvider } from 'pure-react-carousel'
import { ReactNode } from 'react'

import { CarouselWithStore } from 'domains/Seo/SeoPageCarousel/SeoPageCarousel'
import { SEOTrackingRelatedIdentifiers } from 'domains/Seo/helpers'
import useSeoPageSlidesCount from 'domains/Seo/useSeoPageSlidesCount'

import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'

import { ArtifactWithWorkEntry } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { ArtifactSeoCard } from '../ArtifactSeoCard'

export interface ArtifactSeoCarouselSectionProps {
  artifacts: ArtifactWithWorkEntry[]
  title?: string | ReactNode
  subtitle?: string | ReactNode
  viewAllText?: string
  ctaText?: string
  ctaHref?: string
  tracking?: SEOTrackingRelatedIdentifiers
}

export const ArtifactSeoCarouselSection = ({
  artifacts,
  title,
  subtitle,
  viewAllText,
  ctaText,
  ctaHref,
  tracking
}: ArtifactSeoCarouselSectionProps) => {
  const isMobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)
  const slideWidth = isMobileView ? 292 : 400
  const visibleSlides = useSeoPageSlidesCount()

  return (
    <CarouselProvider
      naturalSlideWidth={slideWidth}
      naturalSlideHeight={400}
      totalSlides={artifacts.length + 1} // +1 for the empty element
      visibleSlides={visibleSlides}
      isIntrinsicHeight={true}
      className="mb-[104px] w-full"
    >
      <CarouselWithStore
        isSmallGap={isMobileView}
        slideWidth={slideWidth}
        visibleSlides={visibleSlides}
        title={title}
        subtitle={subtitle}
        bottomText={viewAllText}
        ctaText={ctaText}
        ctaHref={ctaHref}
        tracking={tracking}
      >
        {artifacts.map((artifact) => (
          <ArtifactSeoCard artifact={artifact} key={artifact.id} />
        ))}
      </CarouselWithStore>
    </CarouselProvider>
  )
}

export default ArtifactSeoCarouselSection
