import ModuleSection from 'domains/CohortViewer/ModuleSection'

import Loading from 'components/Loading'

import { ScheduledWeekPartsFragment, useCohortViewerMaterialQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { isBetweenDates } from 'utils/date'
import { cn } from 'utils/tailwind'

// TODO: define fragments and update CohortViewerPage to use typed hook

interface MaterialProps {
  programId: string
  scheduledWeeks: ScheduledWeekPartsFragment[]
}

const Material = ({ programId, scheduledWeeks }: MaterialProps) => {
  const { currentUser } = useCurrentUser()
  const { data } = useCohortViewerMaterialQuery({
    variables: { programId: programId }
  })
  const currentWeek = scheduledWeeks.find((week) => {
    return isBetweenDates(week.startsAt, week.endsAt)
  })
  const urlSearchParams = new URLSearchParams(window.location.search)
  const mapsAndTemplates = urlSearchParams.get('templates')

  return (
    <>
      <div className="program-index">
        <ul
          className={cn(
            'uk-list uk-list-divider mt-0 bg-white',
            !currentUser && 'list-none pl-0 sm:pl-2 lg:pl-0'
          )}
        >
          {data?.cmsProgram &&
            data.cmsProgram.cmsModules.map((cmsModule, i) => (
              <ModuleSection
                key={`cmsModule${i}`}
                startsOpen={
                  Boolean(
                    currentWeek?.orderedCmsModules.find((mod) => mod.id === cmsModule.id)
                  ) ||
                  (Boolean(mapsAndTemplates) && cmsModule.slug === 'maps-and-templates')
                }
                cmsModule={cmsModule}
                currentUser={currentUser || undefined}
                numSectionBookmarks={data.currentUser?.numSectionBookmarks}
                numModuleBookmarks={data.currentUser?.numModuleBookmarks}
                userProgress={data.currentUser?.programProgress?.progress || {}}
              />
            ))}
        </ul>
      </div>
      {!data && (
        <div className="pt-5">
          <Loading />
        </div>
      )}
    </>
  )
}

export default Material
