import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom-v5-compat'
import { twMerge } from 'tailwind-merge'

import { SVGIcon } from 'components/Icon'
import { useLeftSidebar } from 'components/LeftSidebar/useLeftSidebar'
import Tooltip from 'components/Tooltip/Tooltip'

import { capitalizeFirstLetter } from 'utils/stringUtils'
import { trackNavigationClicked } from 'utils/tracking/analytics'

import { isPathActive } from './useNavigationTab'

export interface NavigationTabProps {
  id: string
  link: string
  matchExactPath?: boolean
  name: string
  copyClassName?: string
  iconName?: string
  iconClassName?: string
  chevronClassName?: string
  isButton?: boolean // will return button instead of link
  isCollapsed?: boolean
  isSubnavCollapsed?: boolean
  showCollapsedChevron?: boolean
  closeSidebarOnClick?: boolean
  onClick?: () => void
  isPathActiveOverride?: (pathname: string) => boolean // pathname returned by useLocation
}

const NavigationTab = ({
  id,
  link,
  matchExactPath,
  name = '',
  iconName = name,
  chevronClassName,
  copyClassName,
  isButton,
  isCollapsed,
  isSubnavCollapsed,
  onClick,
  isPathActiveOverride,
  showCollapsedChevron = false,
  closeSidebarOnClick = true
}: NavigationTabProps) => {
  const { pathname } = useLocation()
  const { setIsLeftSidebarOpen } = useLeftSidebar()
  const handleClick = () => {
    trackNavigationClicked({
      location: 'sidebar_navigation',
      type: 'sidebar link',
      destination: `${window.location.origin}${link}`,
      text: capitalizeFirstLetter(name)
    })
    closeSidebarOnClick && setIsLeftSidebarOpen(false)
    if (onClick) {
      onClick()
    }
  }

  const pathIsActive = isPathActive({
    pathname,
    path: link?.split('?')[0] || '',
    exact: matchExactPath,
    isPathActiveOverride
  })

  // return button if we want a button to act like a link, but not be a link
  if (isButton) {
    return (
      <button
        data-test={id}
        id={id}
        onClick={handleClick}
        className={twMerge(
          'flex h-[40px] w-full items-center rounded px-2 text-base text-rb-gray-400 hover:bg-rb-gray-100 hover:text-rb-gray-400 hover:no-underline lg:pl-0 lg:pr-1',
          isCollapsed ? 'lg:pl-0' : '',
          pathIsActive ? `bg-rb-gray-50 ${isCollapsed ? 'w-[90%]' : ''}` : ''
        )}
      >
        <span
          className={twMerge(
            'flex-shrink-0 lg:transition-[margin] lg:duration-[400ms] lg:ease-in-out',
            isCollapsed ? 'ml-5' : 'lg:ml-[20px]',
            pathIsActive && isCollapsed ? 'ml-[calc(33%-8px)] p-2' : ''
          )}
        >
          <SVGIcon width="24" height="24" name={iconName} fill="#080A0A" />
        </span>
        <span className="flex w-full items-center">
          <span
            className={twMerge(
              'opacity ml-4 capitalize text-rb-gray-400 transition duration-500 ease-in-out',
              copyClassName,
              isCollapsed ? 'hidden' : ''
            )}
          >
            {name}
          </span>
          {showCollapsedChevron && (
            <SVGIcon
              name={isSubnavCollapsed ? 'thin-chevron-up' : 'thin-chevron-down'}
              fill="currentColor"
              stroke="currentColor"
              width="15"
              className={twMerge(
                'ml-auto text-rb-gray-400 opacity-100 transition-opacity lg:mr-4',
                isSubnavCollapsed ? 'text-rb-gray-400' : '',
                isCollapsed ? 'lg:opacity-0' : '',
                chevronClassName
              )}
            />
          )}
        </span>
      </button>
    )
  }

  return (
    <Tooltip
      disable={!isCollapsed}
      offset={{ right: 10 }}
      backgroundColor="#080A0A"
      className="rounded-lg"
      tooltipBody={
        <div>
          {name.charAt(0).toLocaleUpperCase() + name.slice(1).toLocaleLowerCase()}
        </div>
      }
      place="right"
      contentWrapperClassname={twMerge(
        'w-full text-rb-gray-300 hover:text-rb-gray-300 flex items-center h-[40px]',
        isCollapsed ? 'lg:pl-0' : ''
      )}
    >
      <Link
        data-test={id}
        id={id}
        to={link}
        onClick={handleClick}
        className={twMerge(
          'flex h-full w-full items-center rounded px-2 text-base text-rb-gray-400 hover:bg-rb-gray-100 hover:text-rb-gray-400 hover:no-underline lg:pl-0 lg:pr-1',
          pathIsActive ? 'hover:bg-rb-gray-100} bg-rb-gray-50' : ''
        )}
      >
        <span
          className={twMerge(
            'flex-shrink-0 lg:transition-[margin] lg:duration-[400ms] lg:ease-in-out',
            isCollapsed ? 'ml-5' : 'lg:ml-[20px]'
          )}
        >
          <SVGIcon width="20" height="20" name={iconName} fill="#080A0A" />
        </span>
        <span className="flex w-full items-center">
          <span
            className={twMerge(
              'opacity ml-4 capitalize text-rb-gray-400 transition duration-500 ease-in-out',
              copyClassName,
              isCollapsed ? 'hidden' : ''
            )}
          >
            {name}
          </span>
          {showCollapsedChevron && (
            <SVGIcon
              name={isSubnavCollapsed ? 'thin-chevron-up' : 'thin-chevron-down'}
              fill="currentColor"
              stroke="currentColor"
              width="15"
              className={twMerge(
                'ml-auto text-rb-gray-400 opacity-100 transition-opacity lg:mr-4',
                isSubnavCollapsed ? 'text-rb-gray-400' : '',
                isCollapsed ? 'lg:opacity-0' : '',
                chevronClassName
              )}
            />
          )}
        </span>
      </Link>
    </Tooltip>
  )
}

export default NavigationTab
