import Button from 'components/Button'
import { Modal } from 'components/Modal'

import { MIN_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import useMediaQuery from 'hooks/useMediaQuery'

import { cn } from 'utils/tailwind'

export interface ConfirmRequestAcceptedModalProps {
  isOpen: boolean
  handleClose: () => void
  onAssignSeat: () => void
  joiningUserName?: string | null | undefined
}

export const ConfirmRequestAcceptedModal = ({
  isOpen,
  handleClose,
  onAssignSeat,
  joiningUserName
}: ConfirmRequestAcceptedModalProps) => {
  const isNotSmall = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_XS})`)

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className="max-w-[728px] rounded-lg"
      scrollContent={false}
    >
      <div
        data-testid="confirm-request-accepted-modal"
        className="pb-8 flex flex-col items-center max-w-[480px] mx-auto"
      >
        <div
          className={cn(
            'pb-8 text-center leading-[25.6px] w-full flex-grow',
            isNotSmall && 'pb-8'
          )}
        >
          You have an empty seat in your plan. Do you want to assign this seat to{' '}
          {joiningUserName || 'the member'}?
        </div>
        <section
          className={cn(
            'flex flex-col items-center w-full',
            isNotSmall && 'flex-row justify-center'
          )}
        >
          <Button
            variant="outline"
            onClick={handleClose}
            size="medium"
            className={cn('w-[200px] h-[40px] mb-5', isNotSmall && 'mr-4 mb-0')}
          >
            No, cancel
          </Button>

          <Button
            onClick={onAssignSeat}
            dataTest="confirm-seat-assignment"
            className="w-[200px] h-[40px]"
            size="medium"
          >
            Yes, assign the seat
          </Button>
        </section>
      </div>
    </Modal>
  )
}
