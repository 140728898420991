import { Link } from 'react-router-dom'

import EmbeddedHubspotForm from 'domains/Patronage/EmbeddedHubspotForm'

import { trackCtaClicked } from 'utils/tracking/analytics'

import { ReactComponent as ReforgeLogo } from 'images/reforge-logo.svg'

export const TeamRenewalSalesFormHeader = () => {
  return (
    <div className="flex h-20 w-full items-center bg-black pl-7 font-sans text-xl uppercase tracking-vast text-white">
      <Link to="/">
        <ReforgeLogo className="h-5 w-full text-rb-white" />
      </Link>
    </div>
  )
}

const TeamRenewalSalesForm = () => {
  const handleFormSubmit = () => {
    trackCtaClicked({
      cta_location: 'hubspot_billing_page_contact_form',
      cta_type: 'button',
      text: 'submit to get in touch'
    })
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <TeamRenewalSalesFormHeader />
      <EmbeddedHubspotForm
        classes="w-3/5"
        region="na1"
        portalId="20491267"
        formId="76351026-ad1d-4011-b435-9e23c990c2f6"
        onFormSubmit={handleFormSubmit}
      />
    </div>
  )
}

export default TeamRenewalSalesForm
