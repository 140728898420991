import { NavLink, useLocation, useRouteMatch } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

export type BreadcrumbStep = {
  title: string
  url: string
}
export interface NumberedBreadcrumbsProps {
  steps: BreadcrumbStep[]
}

interface NumberedBreadcrumbProps {
  steps: BreadcrumbStep[]
  step: BreadcrumbStep
  index: number
}
/**
 * NumberedBreadcrumb
 * @param step, @param index
 * @returns a singular, numbered breadcrumb
 * link's availability depends on route matching with steps
 */
const NumberedBreadcrumb = ({ steps, step, index }: NumberedBreadcrumbProps) => {
  const match = useRouteMatch(step.url)
  const location = useLocation()
  // determine if link should be clickable by seeing which step is active, all higher indexes should be disabled
  const activeIndex = steps.findIndex((x) => x.url.includes(location.pathname))
  const isFutureStep = !match && index > activeIndex
  return (
    <NavLink
      to={step.url}
      activeClassName="text-rb-teal-200 text-bold"
      className={twMerge(
        'flex items-center gap-1 font-semibold text-rb-gray-300 hover:text-[#080A0AD9] hover:no-underline',
        isFutureStep && 'pointer-events-none'
      )}
    >
      <span
        className={twMerge(
          'mr-1 flex h-6 w-6 flex-shrink-0 justify-center rounded-full bg-rb-gray-100',
          match && 'bg-rb-teal-200 text-white'
        )}
      >
        {index + 1}
      </span>
      <span>{step.title}</span>
    </NavLink>
  )
}

/**
 * NumberedBreadcrumbs
 * @param steps: an array of steps, should always include a URL for routing
 * @returns an ordered list of links based on route matching with steps[x].url
 * responsive design included <3
 */
export const NumberedBreadcrumbs = ({ steps }: NumberedBreadcrumbsProps) => {
  return (
    <ol className="ml-4 hidden sm:flex list-none flex-col p-0 sm:flex-row sm:items-center sm:justify-center lg:-ml-28">
      {steps.map((step, index) => {
        return (
          <li key={index} className="mb-2.5 sm:mr-6 sm:mb-0">
            <NumberedBreadcrumb step={step} index={index} steps={steps} />
          </li>
        )
      })}
    </ol>
  )
}
