import { twMerge } from 'tailwind-merge'

import CheckIcon from 'components/icons/CheckIcon'
import LinkIcon from 'components/icons/LinkIcon'

import { ContentBlock } from 'gql'

import { useResetToFalseAfter } from 'hooks/useResetToFalseAfter'

import { displaySuccessToast } from 'utils/toastService'
import { trackCopyLinkClicked } from 'utils/tracking/analytics'
import { CopyLinkClicked } from 'utils/tracking/generated/types'

interface CopyAnchorLinkButtonProps {
  anchorSlug: string
  className?: string
  contentBlock?: ContentBlock | null
}

export const CopyAnchorLinkButton = ({
  anchorSlug,
  className,
  contentBlock
}: CopyAnchorLinkButtonProps) => {
  const [showCheckIcon, setShowCheckIcon] = useResetToFalseAfter(6000)

  const handleClick = () => {
    if (contentBlock) {
      const trackingData: CopyLinkClicked = {
        content_link_id: contentBlock.id,
        content_link_type: 'guide_content_block',
        content_link_title: contentBlock.header || anchorSlug
      }

      trackCopyLinkClicked(trackingData)
    }
    navigator.clipboard.writeText(
      `${window.location.origin}${window.location.pathname}#${anchorSlug}`
    )
    displaySuccessToast({ message: 'Link copied to clipboard' })
    setShowCheckIcon(true)
  }
  return (
    <button
      data-test="unit_copy_link_button"
      className={twMerge(
        'flex h-10 w-10 items-center justify-center rounded-lg border border-gray-200 bg-white',
        className,
        showCheckIcon && 'border-green-600'
      )}
      disabled={showCheckIcon}
      onClick={handleClick}
    >
      {showCheckIcon ? (
        <CheckIcon
          data-test="unit_copy_link_button_check_icon"
          className="fill-green-600"
        />
      ) : (
        <LinkIcon data-test="unit_copy_link_button_link_icon" className="h-4 w-4" />
      )}
    </button>
  )
}
export default CopyAnchorLinkButton
