import { useCallback, useEffect, useRef, useState } from 'react'

import Button from 'components/Button'

import {
  TaxIdReverseChargeStatus,
  TaxIdVerificationStatus,
  TaxInfoItem,
  useTaxIdReverseChargeStatusLazyQuery,
  useTaxInfoLazyQuery
} from 'gql'

interface useTaxInfoProps {
  productKeys: string[]
  skip?: boolean
  onTaxIdVerified?: () => void
}

const TAX_ID_VERIFICATION_POLLING_INTERVAL = 1000 * 10 // 10 seconds

export const useTaxInfo = ({ productKeys, skip, onTaxIdVerified }: useTaxInfoProps) => {
  const [loading, setLoading] = useState(false)
  const [fetchTaxInfo, { data }] = useTaxInfoLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      productKeys
    },
    onError: () => setLoading(false),
    onCompleted: () => setLoading(false)
  })

  const canCallVerifiedCallbackRef = useRef(true)
  const [fetchTaxReverseChargeStatus, { data: taxId }] =
    useTaxIdReverseChargeStatusLazyQuery({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    })

  const taxIdReverseChargeStatus = taxId?.taxIdReverseChargeStatus
  const { taxIdVerificationStatus, isReverseCharge } = taxIdReverseChargeStatus || {}

  const fetchData = useCallback(
    ({ shouldCheckTaxIdVerification }: { shouldCheckTaxIdVerification?: boolean }) => {
      if (!skip) {
        setLoading(true)
        fetchTaxInfo()
        fetchTaxReverseChargeStatus({
          onCompleted: (data) => {
            if (
              shouldCheckTaxIdVerification &&
              data.taxIdReverseChargeStatus?.taxIdVerificationStatus ===
                TaxIdVerificationStatus.VERIFIED
            ) {
              canCallVerifiedCallbackRef.current = false
            } else {
              canCallVerifiedCallbackRef.current = true
            }
          }
        })
      }
    },
    [skip, fetchTaxReverseChargeStatus, fetchTaxInfo]
  )

  const refetch = useCallback(() => {
    fetchData({ shouldCheckTaxIdVerification: false })
  }, [fetchData])

  useEffect(() => {
    fetchData({ shouldCheckTaxIdVerification: true })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let interval: number | null = null

    if (
      taxIdVerificationStatus === TaxIdVerificationStatus.PENDING ||
      (taxIdVerificationStatus === TaxIdVerificationStatus.VERIFIED && !isReverseCharge)
    ) {
      interval = window.setInterval(() => {
        fetchTaxReverseChargeStatus()
      }, TAX_ID_VERIFICATION_POLLING_INTERVAL)
    } else if (
      taxIdVerificationStatus === TaxIdVerificationStatus.VERIFIED &&
      isReverseCharge &&
      canCallVerifiedCallbackRef.current
    ) {
      interval && clearInterval(interval)
      onTaxIdVerified?.()
    }

    return () => {
      interval && clearInterval(interval)
    }
  }, [
    taxIdVerificationStatus,
    isReverseCharge,
    fetchTaxReverseChargeStatus,
    onTaxIdVerified
  ])

  return {
    taxInfo: data ? (data.taxInfo as TaxInfoItem[]) : undefined,
    taxIdReverseChargeStatus: taxIdReverseChargeStatus
      ? (taxIdReverseChargeStatus as TaxIdReverseChargeStatus)
      : undefined,
    taxInfoLoading: loading,
    refetchTaxInfo: refetch
  }
}

export const getSalesTaxLabelDetails = (
  taxIdReverseChargeStatus?: TaxIdReverseChargeStatus | null,
  refreshCallback?: () => void
) => {
  const { isReverseCharge, taxIdVerificationStatus } = taxIdReverseChargeStatus || {}

  if (taxIdVerificationStatus === TaxIdVerificationStatus.VERIFIED && isReverseCharge) {
    return <span className="text-rb-success">Tax ID validated.</span>
  }

  if (
    taxIdVerificationStatus === TaxIdVerificationStatus.PENDING ||
    (taxIdVerificationStatus === TaxIdVerificationStatus.VERIFIED && !isReverseCharge)
  ) {
    return (
      <span className="text-rb-warning">
        Your Tax ID is still pending. This may take a few minutes.{' '}
        <Button
          variant="text-only"
          size="x-small"
          onClick={refreshCallback}
          className="inline p-0 text-xs hover:bg-transparent hover:underline"
        >
          Refresh
        </Button>
      </span>
    )
  }

  if (taxIdVerificationStatus === TaxIdVerificationStatus.UNVERIFIED) {
    return (
      <span className="text-rb-destructive">
        Tax ID validation failed. Taxes will be applied to your purchase. Please contact
        support@reforge.com for assistance.
      </span>
    )
  }

  return null
}
