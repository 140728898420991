import { matchPath } from 'react-router-dom'

import { useCohortQuery } from 'gql'

export const getCohortSlug = (pathname: string) => {
  // Get the cohort slug only if they are on the cohort page
  if (!matchPath(pathname, { path: '/cohorts/*' })) {
    return ''
  }

  const splitPath = pathname.split('/')

  const cohortSubpathIndex = splitPath.indexOf('cohorts')
  const cohortSlug = splitPath[cohortSubpathIndex + 1]

  return cohortSlug
}

export const useGetCohortFromPath = (pathname: string) => {
  const cohortSlug = getCohortSlug(pathname)

  const { data: cohortData, loading: cohortLoading } = useCohortQuery({
    variables: {
      slug: cohortSlug
    },
    skip: cohortSlug === ''
  })

  return {
    cohortSlug,
    cohortData,
    cohortLoading
  }
}
