import { PropsWithChildren } from 'react'

import { cn } from 'utils/tailwind'

const SeoPageSectionSubtitle = ({
  children,
  className
}: PropsWithChildren<{ className?: string }>) => (
  <p
    className={cn(
      'mb-6 text-sm leading-[1.5] text-rb-gray-300 md:text-base md:leading-[1.6]',
      className
    )}
  >
    {children}
  </p>
)

export default SeoPageSectionSubtitle
