import { useEffect } from 'react'

import { ErrorMessage, Loading } from 'components'
import Button from 'components/Button'
import { ModalContent, ModalFooter } from 'components/Modal'
import { CheckIcon } from 'components/icons'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import { useUserSubscriptionCancelModalQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'
import { trackModalDisplayed } from 'hooks/useModalTracking'

import { getCancelationDetails } from './helpers'

export type EasyToManageSubscriptionType = {
  howEasyToManageSubscription: string
}

export interface SubscriptionCancelSuccessModalContentProps {
  handleClose: () => void
}

const SubscriptionCancelSuccessModalContent = ({
  handleClose
}: SubscriptionCancelSuccessModalContentProps) => {
  const { currentUser } = useCurrentUser()
  const { id: userId, timezone } = currentUser || {}
  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
  const { loading, error, data } = useUserSubscriptionCancelModalQuery({
    variables: { id: userId || '' }
  })

  useEffect(() => {
    trackModalDisplayed({
      modal_group: 'cancelation_flow',
      modal_name: 'cancel success'
    })
  }, [])

  if (loading) return <Loading />
  if (error) return <ErrorMessage error={error} />

  return (
    <>
      <ModalContent dataTest="subscription-cancel-success-modal">
        <CheckIcon className="mx-auto mb-3 max-h-10 w-14 fill-current text-rb-success-100 sm:mb-10" />

        <div className="mb-4 text-center text-2xl font-semibold">
          {isMobile
            ? 'Your subscription is canceled.'
            : 'Your subscription has been canceled.'}
        </div>

        <div className="mb-2 text-center sm:mb-10">
          {getCancelationDetails(data, timezone)}
        </div>
      </ModalContent>

      <ModalFooter className="mt-4 sm:mt-10">
        <Button size="small" className="mr-4" onClick={handleClose}>
          Done
        </Button>
      </ModalFooter>
    </>
  )
}

export default SubscriptionCancelSuccessModalContent
