import {
  LIST_CIRCLE,
  LIST_LOWER_ALPHA,
  LIST_LOWER_ROMAN,
  LIST_SQUARE
} from './../domains/Sanity/PortableText/shared/componentTypes/Lists'

type ListType =
  | typeof LIST_CIRCLE
  | typeof LIST_SQUARE
  | typeof LIST_LOWER_ALPHA
  | typeof LIST_LOWER_ROMAN

const listTypes: ListType[] = [
  LIST_CIRCLE,
  LIST_SQUARE,
  LIST_LOWER_ALPHA,
  LIST_LOWER_ROMAN
]

const addExtraPaddingToElement = (el: HTMLElement) => {
  const level = el?.getAttribute('data-level') || '0'

  el.style.paddingLeft = `${2 * +level}rem`
}

const isOfListType = (el: Element) => {
  const containsListType = (listType: ListType) => el.classList.contains(listType)

  return listTypes.some(containsListType)
}

const isChildOfListItem = (el: Element) => el.parentElement?.tagName !== 'LI'

export const alignSubLists = () => {
  // Targets all the ul elements that aren't nested in another ul
  let elements: Element[] = []
  try {
    elements = Array.from(document.querySelectorAll('body :has(> ul) > ul'))
  } catch (e) {
    console.error(e)
  }

  elements
    .filter(isOfListType)
    .filter(isChildOfListItem)
    .forEach(addExtraPaddingToElement)
}
