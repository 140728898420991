const CalendarIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.03125 3.125V3.90625H3.90625V21.0938H21.0938V3.90625H17.9688V3.125H16.4062V3.90625H8.59375V3.125H7.03125ZM5.46875 5.46875H7.03125V6.25H8.59375V5.46875H16.4062V6.25H17.9688V5.46875H19.5312V7.03125H5.46875V5.46875ZM5.46875 8.59375H19.5312V19.5312H5.46875V8.59375ZM10.1562 10.1562V11.7188H11.7188V10.1562H10.1562ZM13.2812 10.1562V11.7188H14.8438V10.1562H13.2812ZM16.4062 10.1562V11.7188H17.9688V10.1562H16.4062ZM7.03125 13.2812V14.8438H8.59375V13.2812H7.03125ZM10.1562 13.2812V14.8438H11.7188V13.2812H10.1562ZM13.2812 13.2812V14.8438H14.8438V13.2812H13.2812ZM16.4062 13.2812V14.8438H17.9688V13.2812H16.4062ZM7.03125 16.4062V17.9688H8.59375V16.4062H7.03125ZM10.1562 16.4062V17.9688H11.7188V16.4062H10.1562ZM13.2812 16.4062V17.9688H14.8438V16.4062H13.2812Z" />
  </svg>
)

export default CalendarIcon
