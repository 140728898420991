import { useCallback, useEffect } from 'react'

import { setCookie } from 'utils/cookieUtils'

import { useCurrentUser } from './useCurrentUser'

/**
 * Sets a cookie (reforge_signin_source) having the passed in value if the user is not logged in
 * This cookie is used in session_controller.rb to track the source of the first signin.
 * The cookie is set and sent with subsequent login requests, but its value will only be saved on the user when the account is created.
 * @returns
 */
const useFirstSigninSource = () => {
  const { isLoggedIn } = useCurrentUser()
  const pathname = window.location.pathname

  const setSigninSource = useCallback(
    (value?: string | null) => {
      if (!value || isLoggedIn) {
        return
      }

      if (window.location.pathname.includes('login')) {
        console.error('useFirstSigninSource should not be used on the login page') // because it would reset any existing value set in the cookie on other pages
        return
      }

      setCookie('reforge_signin_source', value, 365)
    },
    [isLoggedIn]
  )

  useEffect(() => {
    setSigninSource(pathname)
  }, [pathname, setSigninSource])

  const setCustomSigninSource = useCallback(
    (value?: string | null) => {
      setTimeout(() => {
        setSigninSource(value)
      }, 100)
    },
    [setSigninSource]
  )

  return { setCustomSigninSource }
}

export default useFirstSigninSource
