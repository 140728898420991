import Button from 'components/Button'
import Modal from 'components/Modal'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

interface NotifyCohortModalProps {
  isOpen: boolean
  submitForm: () => void
  onClose: () => void
}

const NotifyCohortModal = ({ isOpen, submitForm, onClose }: NotifyCohortModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={onClose}
      header={false}
      className="max-w-[312px] rounded-2xl p-8"
      closeOnEscape
    >
      <RfParagraphMediumBold className="mb-3">
        You are about to notify the cohort
      </RfParagraphMediumBold>
      <RfParagraphSmall className="mb-10">
        This will send an email to all cohort members.
      </RfParagraphSmall>
      <Button variant="fill" fullWidth onClick={submitForm} className="mb-4">
        Notify Cohort
      </Button>
      <Button variant="outline" fullWidth onClick={onClose}>
        Cancel
      </Button>
    </Modal>
  )
}

export default NotifyCohortModal
