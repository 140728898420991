import OldCoursesIndexPage from 'pages/CoursesPage/OldCoursesIndexPage'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import CoursesIndexPage from './CoursesIndexPage'

export const CoursesIndexPageBridge = () => {
  const { showV2CoursesIndexPage } = useFeatureFlags()

  if (!showV2CoursesIndexPage) {
    return <OldCoursesIndexPage />
  }

  return <CoursesIndexPage />
}

export default CoursesIndexPageBridge
