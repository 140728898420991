import { useHistory } from 'react-router-dom'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import {
  CourseDetailCourseBlockArtifactPartsFragment,
  CourseDetailCourseBlockPartsFragment,
  CourseDetailPartsFragment
} from 'gql'

import { buildCourseDetailsUrl } from 'utils/courseUtils'
import { onEnterKeyPress } from 'utils/keyboard'
import { track } from 'utils/tracking/segment'

interface CourseDetailCourseBlockArtifactProps {
  artifact: CourseDetailCourseBlockArtifactPartsFragment
  courseBlock: CourseDetailCourseBlockPartsFragment
  courseDetail: CourseDetailPartsFragment
}

const CourseDetailCourseBlockArtifact = ({
  artifact,
  courseBlock,
  courseDetail
}: CourseDetailCourseBlockArtifactProps) => {
  const history = useHistory()

  if (!artifact) return null

  const onArtifactClick = (e: any) => {
    e.stopPropagation() // Prevent double tracking

    track('Content Clicked', {
      content_type: 'artifact',
      content_title: artifact.title,
      content_sanity_id: artifact.sourceId,
      related_identifiers: {
        ccl_course_id: courseDetail.id,
        course_title: courseDetail?.title,
        course_sanity_id: courseDetail?.sourceId,
        content_block_id: courseBlock.id,
        content_block_title: courseBlock.title,
        content_mode: 'preview'
      }
    })

    const destination = buildCourseDetailsUrl({
      courseSlug: courseDetail.slug,
      artifactSlug: artifact.slug
    })

    history.push(destination, {
      backTo: [
        {
          label: 'Course Home',
          path: buildCourseDetailsUrl({ courseSlug: courseDetail.slug })
        }
      ],
      fromCourse: true
    })
  }

  return (
    <div
      className="cursor-pointer hover:no-underline"
      data-test="course_block_artifact"
      onClick={onArtifactClick}
      role="link"
      tabIndex={0}
      onKeyUp={onEnterKeyPress(onArtifactClick)}
    >
      <div className="flex flex-grow items-center justify-between gap-6">
        <div className="flex items-center">
          <div className="group relative mr-6 flex h-[50px] min-w-[50px] items-center justify-center rounded-2xl border border-rb-gray-100 group-hover:bg-rb-orange-25">
            <SVGIcon width="16" height="16" name="artifacts" fill="#080A0A" />
          </div>
          <div className="flex flex-col gap-1">
            <RfParagraphSmall>
              <span className="text-rb-gray-400 line-clamp-2">{artifact.title}</span>
            </RfParagraphSmall>

            <div className="flex items-center justify-between gap-6">
              <Button
                variant="fill"
                color="teal"
                size="x-small"
                className="block border-none px-2.5 py-1 font-normal sm:hidden"
                onClick={onArtifactClick}
              >
                Preview
              </Button>
            </div>
          </div>
        </div>

        <Button
          variant="fill"
          color="teal"
          size="x-small"
          className="hidden border-none px-2.5 py-1.5 font-normal sm:block"
          onClick={onArtifactClick}
        >
          Preview
        </Button>
      </div>
    </div>
  )
}

export default CourseDetailCourseBlockArtifact
