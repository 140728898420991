import Image from 'domains/Sanity/Image'
import ButtonMarketingCTA from 'domains/Sanity/marketingSite/ButtonMarketingCTA'
import { MarketingH2, MarketingH3 } from 'domains/Sanity/marketingSite/textHelpers'

import Button from 'components/Button'
import LottieInView from 'components/LottieInView'

import { Cta, HomepageContent } from 'gql'

import { trackNavigationClicked } from 'utils/tracking/analytics'

import artifactAnimation from './artifacts_animation.json'
import artifactStaticImage from './artifacts_fallback.png'
import guidesAnimation from './guides_animation.json'
import guideStaticImage from './guides_fallback.png'

const ArtifactAndGuideSection = ({ content }: { content: HomepageContent }) => {
  const onContentCtaClick = (cta: Cta) => () => {
    trackNavigationClicked({
      location: 'marketing_homepage__artifact_guide_section',
      type: 'hyperlink text',
      destination: cta.ctaLink,
      text: cta.ctaText.toLowerCase()
    })
  }

  return (
    <section className="py-[50px] sm:py-[75px] lg:py-[100px] px-4 md:px-[4vw]">
      <div className="mx-auto max-w-[1280px] text-center flex flex-col gap-[50px] sm:gap-[75px] lg:gap-[100px]">
        <div>
          <MarketingH2 className="mb-2.5 sm:max-w-[440px] md:max-w-none">
            {content.artifactsAndGuidesTitle}
          </MarketingH2>
          <MarketingH3 className="sm:max-w-[440px] lg:max-w-[640px] mx-auto">
            {content.artifactsAndGuidesSubtitle}
          </MarketingH3>
        </div>

        <div className="flex flex-col lg:flex-row lg:items-center justify-between">
          <LottieInView
            animationData={guidesAnimation}
            className="w-full shrink-0 sm:max-w-[500px] xl:max-w-[640px] 2xl:max-w-[760px] mb-[25px] lg:mb-0 hidden lg:block"
            loop={false}
          />

          <Image
            src={guideStaticImage}
            alt=""
            className="w-full mx-auto shrink-0 sm:max-w-[500px] xl:max-w-[640px] 2xl:max-w-[760px] mb-[25px] lg:hidden"
          />
          <div className="flex flex-col items-center">
            {content.guidesBenefits.map((benefit, i) => (
              <p
                className="text-lg lg:text-2xl !leading-[1.35] mb-3 w-full sm:max-w-[440px] lg:max-w-[360px] xl:max-w-[456px]"
                key={i}
              >
                {benefit}
              </p>
            ))}

            <Button
              href={content.guidesCta.ctaLink}
              className="p-0 text-sm lg:text-lg font-normal !leading-[1.35] underline hover:bg-transparent hover:underline"
              variant="text-only"
              onClick={onContentCtaClick(content.guidesCta)}
            >
              {content.guidesCta.ctaText}
            </Button>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row-reverse lg:items-center justify-between">
          <LottieInView
            animationData={artifactAnimation}
            className="w-full shrink-0 sm:max-w-[500px] xl:max-w-[640px] 2xl:max-w-[760px] mb-[25px] lg:mb-0 hidden lg:block"
            loop={false}
          />

          <Image
            src={artifactStaticImage}
            alt=""
            className="w-full mx-auto shrink-0 sm:max-w-[500px] xl:max-w-[640px] 2xl:max-w-[760px] mb-[25px] lg:hidden"
          />
          <div className="flex flex-col items-center">
            {content.artifactsBenefits.map((benefit, i) => (
              <p
                className="text-lg lg:text-2xl !leading-[1.35] mb-3 w-full sm:max-w-[440px] lg:max-w-[360px] xl:max-w-[456px]"
                key={i}
              >
                {benefit}
              </p>
            ))}

            <Button
              href={content.artifactsCta.ctaLink}
              className="p-0 text-sm lg:text-lg font-normal !leading-[1.35] underline hover:bg-transparent hover:underline"
              variant="text-only"
              onClick={onContentCtaClick(content.artifactsCta)}
            >
              {content.artifactsCta.ctaText}
            </Button>
          </div>
        </div>

        <ButtonMarketingCTA
          href={content.mainCta.ctaLink}
          location="marketing_homepage__artifact_guide_section"
          className="self-center"
        >
          {content.mainCta.ctaText}
        </ButtonMarketingCTA>
      </div>
    </section>
  )
}
export default ArtifactAndGuideSection
