export const TealDocumentIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      className={className}
    >
      <path
        d="M16.2308 17.1154C16.2308 17.4826 16.0848 17.8348 15.8252 18.0944C15.5656 18.3541 15.2134 18.5 14.8462 18.5H2.38462C2.01739 18.5 1.66521 18.3541 1.40554 18.0944C1.14588 17.8348 1 17.4826 1 17.1154V1.88462C1 1.51739 1.14588 1.16521 1.40554 0.905544C1.66521 0.645879 2.01739 0.5 2.38462 0.5H11.3846L16.2308 5.34615V17.1154Z"
        stroke="#1D7D7A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.15381 6.03845H7.92304"
        stroke="#1D7D7A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.15381 10.1923H12.0769"
        stroke="#1D7D7A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.15381 14.3462H12.0769"
        stroke="#1D7D7A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
