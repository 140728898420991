import cx from 'classnames'

import { ArtifactsPortableText } from 'domains/Sanity/PortableText/ArtifactsPortableText/ArtifactsPortableText'

interface BlockProps {
  blocks: any // @todo: define type
  className?: string
}

const Content = ({ blocks, className = '' }: BlockProps) => {
  if (!blocks) return null

  return (
    <ArtifactsPortableText
      // @ts-ignore
      renderContainerOnSingleChild
      className={cx('rc', className)}
      content={blocks}
    />
  )
}

export default Content
