import clsx from 'clsx'
import * as React from 'react'

import { SVGIcon } from 'components/Icon'

interface SelectListBlockProps {
  children: React.ReactNode
  onClick: () => void
  isSelected: boolean
  loading: boolean
}

const SelectListBlock = ({
  children,
  onClick,
  isSelected,
  loading
}: SelectListBlockProps) => (
  <div
    className="group relative flex h-24 items-center justify-center space-y-2 border-[1.25px] border-transparent bg-white shadow-default hover:border-rb-green-100"
    onClick={onClick}
  >
    {children}
    <span
      className={clsx(
        'absolute top-4 right-4',
        isSelected ? 'block' : 'hidden group-hover:block'
      )}
    >
      <SVGIcon name="color-circle-check" circleFill={getIconColor(isSelected, loading)} />
    </span>
  </div>
)

function getIconColor(isSelected: boolean, loading: boolean) {
  if (loading && isSelected) return '#80E1AF'
  return isSelected ? null : '#D3D2D3'
}

export default SelectListBlock
