import { useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { useAntiCopy } from 'domains/AntiPiracy/hooks/useAntiCopy'
import LessonViewerContainer from 'domains/LessonViewer/LessonViewerContainer'
import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'

import { ErrorMessage, Loading } from 'components'

import {
  InferableAction,
  PageSeo,
  useGenerateAiInferenceMutation,
  useLessonViewerQuery
} from 'gql'
import { LessonViewerQueryVariables } from 'gql/index'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import useLessonUrlData from 'hooks/useLessonUrlData'
import useURLParams from 'hooks/useURLParams'

import { matchesViewAccessApolloError } from 'utils/graphqlErrors'
import { lightboxImages } from 'utils/lightbox'

import { lessonViewerState } from 'stores'

export type LessonViewerProps = {
  cmsProgramSlug?: string
  cmsModuleSlug?: string
  cmsSectionParentSlug?: string
  cmsSectionSlug?: string
  fromCohortViewer?: boolean
  cohortSlug?: string
  setPage?: (page: GenericPageType) => void
}

export const LessonViewer = ({
  fromCohortViewer = false,
  setPage,
  ...props
}: LessonViewerProps) => {
  const [isInSavedItems, setIsInSavedItems] = useState(false)
  // set globally so we know this is embedded in lesson viewer
  lessonViewerState.fromCohortViewer = fromCohortViewer
  const lessonUrlData = useLessonUrlData()
  const cmsProgramSlug = props.cmsProgramSlug || lessonUrlData.cmsProgramSlug || ''
  const cmsModuleSlug = props.cmsModuleSlug || lessonUrlData.cmsModuleSlug || ''
  const cmsSectionParentSlug =
    props.cmsSectionParentSlug || lessonUrlData.cmsSectionParentSlug || ''
  const cmsSectionSlug = props.cmsSectionSlug || lessonUrlData.cmsSectionSlug || ''

  const { getParam } = useURLParams()
  const { showHighlighting } = useFeatureFlags()

  const { pathname } = useLocation()
  const { currentUser, currentUserLoading, currentUserError } = useCurrentUser()

  const variables: LessonViewerQueryVariables = {
    cmsProgramSlug,
    cmsModuleSlug,
    cmsSectionSlug,
    path: location.pathname
  }
  if (getParam('referring-post')) {
    variables.referringPostId = getParam('referring-post')
  }
  if (cmsSectionParentSlug) {
    variables.cmsSectionParentSlug = cmsSectionParentSlug
  }
  const {
    loading: lessonViewerLoading,
    error: lessonViewerError,
    data: lessonViewerData
  } = useLessonViewerQuery({
    variables
  })

  const [generateAiInference] = useGenerateAiInferenceMutation({
    variables: {
      input: {
        actionType: InferableAction.CONTENT_VIEW,
        path: pathname
      }
    }
  })

  useEffect(() => {
    if (!currentUser || !pathname) return
    generateAiInference()
  }, [currentUser, pathname, generateAiInference])

  useEffect(() => {
    if (setPage && lessonViewerData) {
      const { lessonViewer } = lessonViewerData
      const { cmsModule, cmsSection } = lessonViewer || {}
      const title = `${cmsModule?.name} - ${cmsSection?.name} - Reforge`
      const description = cmsSection?.shortDescription

      setPage({
        title,
        seo: {
          metaTitle: title,
          metaDesc: description,
          shareTitle: title,
          shareDesc: description,
          type: 'website',
          shareGraphic: {
            asset: cmsSection?.heroImageUrl
          }
        } as PageSeo
      })
    }
  }, [lessonViewerData, setPage])

  const { contentBookmarkId = '', id: currentLessonId = '' } =
    lessonViewerData?.lessonViewer?.cmsSection || {}

  useEffect(() => {
    setIsInSavedItems(
      !!lessonViewerData?.lessonViewer?.cmsSection?.contentIsInMySavedItems
    )
  }, [lessonViewerData?.lessonViewer?.cmsSection?.contentIsInMySavedItems])

  const loading = currentUserLoading || lessonViewerLoading
  const error = currentUserError || lessonViewerError

  useEffect(() => {
    if (!loading && !showHighlighting) {
      lightboxImages('.fr-content-wrapper img')
    }
  }, [loading, showHighlighting])

  const fullLength =
    lessonViewerData?.lessonViewer?.cmsSection?.descriptionText?.length || 0

  useAntiCopy({ fullLength, currentUser })

  if (loading) {
    return <Loading />
  }

  if (error || !lessonViewerData?.lessonViewer) {
    if (matchesViewAccessApolloError(error)) {
      // wait for redirect from apollo client onError
      return <Loading />
    }
    return (
      <div className="ml-0 h-screen">
        <ErrorMessage error={error || new Error('Failed to load lesson viewer data.')} />
      </div>
    )
  }

  const { lessonViewer } = lessonViewerData
  const { cmsProgram, cmsModule, cmsSection } = lessonViewer

  // If a member tries to view a unit's section page
  // redirect them to the first resource of that unit instead
  if (cmsSection?.isUnit && !lessonUrlData.cmsSectionParentSlug) {
    const unitPreviewUrl = cmsSection?.children?.[0]?.href
    return <Redirect to={unitPreviewUrl || '/'} />
  }

  if (!cmsProgram || !cmsModule || !cmsSection) {
    return (
      <div className="ml-0 h-screen">
        <ErrorMessage error={new Error('Failed to load lesson viewer data.')} />
      </div>
    )
  }

  const needsFeedback =
    cmsModule.showFeedback &&
    cmsSection.showFeedback &&
    !cmsSection.currentUserSubmittedFeedback &&
    !currentUser?.preference?.skipFeedback &&
    lessonViewer.viewAccess === 'full'

  return (
    <>
      <LessonViewerContainer
        currentUser={currentUser || undefined}
        lessonViewerData={lessonViewerData}
        currentLessonId={currentLessonId}
        contentBookmarkId={contentBookmarkId || ''}
        isInSavedItems={!!isInSavedItems}
        setIsInSavedItems={setIsInSavedItems}
        needsFeedback={needsFeedback}
        cohortSlug={props.cohortSlug}
        fromCohortViewer={fromCohortViewer}
      />
    </>
  )
}

export default LessonViewer
