import React, { type ChangeEvent, useState } from 'react'

import CommentForm from 'domains/Artifact/CommentForm'
import { showEditedToast, showErrorToast } from 'domains/Artifact/comments/utils'

import { useUpdateArtifactCommentMutation } from 'gql'

interface EditCommentFormProps {
  comment: any
  onSaveSuccess?: () => void
  onCancel?: () => void
}

const EditCommentForm = ({
  comment,
  onSaveSuccess = () => {},
  onCancel = () => {}
}: EditCommentFormProps) => {
  const [value, setValue] = useState(comment?.message ?? '')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [updateComment] = useUpdateArtifactCommentMutation({
    refetchQueries: ['ArtifactComments'],
    awaitRefetchQueries: true
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (isSubmitting) return

    setIsSubmitting(true)

    const message = value

    try {
      await updateComment({
        variables: {
          input: {
            artifactCommentId: comment.id,
            message
          }
        }
      })

      showEditedToast()
      setIsSubmitting(false)
      onSaveSuccess()
    } catch (err) {
      showErrorToast()
      setIsSubmitting(false)
    }
  }

  const handleCancelClick = () => {
    onCancel()
  }

  const handleTextChange = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(target.value)
  }

  return (
    <div id="artifact-comment-form" className="flex flex-row">
      <CommentForm
        mode="expanded"
        value={value}
        onChange={handleTextChange}
        onSubmit={handleSubmit}
        onCancel={handleCancelClick}
        isSubmitting={isSubmitting}
        focusOnMount
        postButtonLabel="Save"
      />
    </div>
  )
}

export default EditCommentForm
