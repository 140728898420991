import { PopoverPosition } from 'react-tiny-popover'

import { useBookmarkCard } from 'domains/Bookmarks/hooks/useBookmarkCard'

import { useProductTour } from 'components/ProductTour'
import { displayToast } from 'components/Toast'
import ToastCard, { toastOptions } from 'components/ToastCard'
import DropdownContextMenu from 'components/dropdowns/Dropdown/DropdownContextMenu'

import {
  ProductTourKey,
  ProgramBookmarkPartsFragment,
  UserBookmarksFeedDocument
} from 'gql'

import { onEnterKeyPress } from 'utils/keyboard'
import { trackBookmarkAction } from 'utils/tracking/analytics'
import { trackModalDisplayed } from 'utils/tracking/generated/events'

interface BookmarkCardControlsProps {
  bookmark: ProgramBookmarkPartsFragment
  position?: PopoverPosition[]
  toggleNote: (show: boolean) => void
  openAddToBookmarkFolderModal: () => void
  hasProductTour?: boolean
}

const BookmarkCardControls = ({
  bookmark: { noteBody },
  bookmark,
  position = ['bottom'],
  toggleNote,
  openAddToBookmarkFolderModal,
  hasProductTour = false
}: BookmarkCardControlsProps) => {
  const hasNote = !!noteBody
  const { restoreBookmark } = useBookmarkCard({ bookmark })
  const { portal, completeTour } = useProductTour({
    productTourKey: ProductTourKey.COLLECTION_SAVE_BOOKMARK,
    nodeRefOrSelector: '.tour-bookmark-control-button',
    title: '📁 Organize your highlights',
    disabled: !hasProductTour,
    description:
      'You can add them to an existing or new collection. Highlights are also automatically saved to your Library.',
    position: 'fixed',
    wait: 2500
  })

  const onDeleteBookmark = () => {
    displayToast(
      <ToastCard
        type="destructive"
        message="Your highlight has been deleted"
        onUndo={() =>
          restoreBookmark({
            refetchQueries: [UserBookmarksFeedDocument]
          })
        }
      />,
      toastOptions
    )
  }

  const onUpdateBookmarkNote = () => {
    trackBookmarkAction({ action: 'delete_note', component: 'action_menu' })
  }

  const { deleteBookmark, updateBookmarkNote } = useBookmarkCard({
    bookmark,
    onDeleteBookmark,
    onUpdateBookmarkNote
  })

  const bookmarkFolderDropdown = () => {
    // To be used for when there are no folders
    // const hasFolder = bookmarkFolders && bookmarkFolders?.length > 0
    return (
      <DropdownContextMenu.DropdownItem
        text="Save to Collection"
        onClick={() => {
          trackModalDisplayed({
            category: 'app',
            location: 'content_viewer_drawer',
            modal_group: 'collections',
            modal_name: 'save_to_collection_modal',
            related_identifiers: {
              path: window.location.pathname,
              cms_section_id: bookmark.cmsSection?.id,
              cms_program_id: bookmark.cmsProgram?.id,
              cms_module_id: bookmark.cmsModule?.id,
              cms_section_name: bookmark.cmsSection?.name,
              cms_program_name: bookmark.cmsProgram?.name,
              cms_module_name: bookmark.cmsModule?.name
            }
          })

          openAddToBookmarkFolderModal()
        }}
      />
    )
  }

  const deleteMutationOptions = {
    update: (cache: any, mutationResult: any) => {
      const deletedBookmarkId = mutationResult?.data?.deleteBookmark?.id
      if (!deletedBookmarkId) return

      const normalizedId = cache.identify({
        id: deletedBookmarkId,
        __typename: 'Bookmark'
      })
      cache.evict({ id: normalizedId })
      cache.gc()
    }
  }

  const menu = (
    <DropdownContextMenu
      positions={position}
      reposition={false}
      boundaryInset={54}
      dismissOnClick
    >
      <DropdownContextMenu.DropdownItem
        text={hasNote ? 'Delete Note' : 'Add Note'}
        onClick={() => {
          if (hasNote) {
            updateBookmarkNote({ note: '' })
          }
          toggleNote(!hasNote)
        }}
        className="w-full"
      />
      <DropdownContextMenu.DropdownItem
        text="Delete Highlight"
        onClick={() => deleteBookmark(deleteMutationOptions)}
        className="w-full"
      />
      {bookmarkFolderDropdown()}
    </DropdownContextMenu>
  )

  return hasProductTour ? (
    <>
      {portal}
      <div
        className="tour-bookmark-control-button"
        role="button"
        tabIndex={0}
        onClick={completeTour}
        onKeyUp={onEnterKeyPress(completeTour)}
      >
        {menu}
      </div>
    </>
  ) : (
    menu
  )
}

export default BookmarkCardControls
