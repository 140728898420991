export function getCookie(name: string): string | null | undefined {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  return parts && parts.length === 2 ? parts.pop()?.split(';').shift() : null
}

export function setCookie(name: string, value: string, days: number) {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = `; expires=${date.toUTCString()}`
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`
}
