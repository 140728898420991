import React from 'react'

import NoteMarkerImage from 'assets/images/notes-paper-text.svg'

interface ButtonNoteMarkerProps {
  active: boolean
  showSignUp?: boolean
}

const ButtonNoteMarker = React.forwardRef<HTMLButtonElement, ButtonNoteMarkerProps>(
  function ButtonNoteMarker({ active }: ButtonNoteMarkerProps, ref) {
    return (
      <button
        className={`${
          active ? 'border border-rb-green-500 bg-rb-green-100' : 'bg-rb-green-75'
        } h-8 w-8 p-0 hover:bg-rb-green-100`}
        ref={ref}
      >
        <div className="flex h-full w-full flex-row items-center justify-center">
          <img src={NoteMarkerImage} alt="Icon" className="h-6 w-6" />
        </div>
      </button>
    )
  }
)

export default ButtonNoteMarker
