import { EyeIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import { useEffect, useMemo } from 'react'
import { Link, Redirect, useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import FaqSection from 'domains/Sanity/FAQSection'
import Image from 'domains/Sanity/Image'
import { UnitComplexPortableText } from 'domains/Sanity/PortableText/UnitComplexPortableText/UnitComplexPortableText'
import ButtonMarketingCTA from 'domains/Sanity/marketingSite/ButtonMarketingCTA'
import ExpertCard from 'domains/Sanity/marketingSite/ExpertCard'

import { Loading } from 'components'

import {
  ArtifactAuthor,
  MarketingCourse,
  MarketingCourseStartDatePartsFragment,
  useMarketingCourseBySlugLazyQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { prettyStartDateFromMarketingCourse } from 'utils/courseUtils'
import { formatMoney } from 'utils/moneyUtils'

import ActionablePic from 'images/courses/Actionable.png'
import CoursesHeaderHeroPic from 'images/courses/CoursesHeaderHero.png'
import DirectPic from 'images/courses/Direct.png'
import ExpertLedPic from 'images/courses/ExpertLed.png'

export type CourseDetailsPageParams = {
  courseSlug: string
}

export interface OldCourseDetailsPageProps {
  course: Omit<MarketingCourse, '__typename'>
  courseSlug: string
}

const WHY_REFORGE = [
  {
    icon: ActionablePic,
    title: 'Actionable',
    description: 'Knowledge you can use immediately.'
  },
  {
    icon: DirectPic,
    title: 'Direct',
    description: 'No fluff. To the point.'
  },
  {
    icon: ExpertLedPic,
    title: 'Expert Led',
    description: 'Created and led by the best tech operators.'
  }
]

export const OldCourseDetailsPage = ({
  course,
  courseSlug
}: OldCourseDetailsPageProps) => {
  const { isLoggedIn } = useCurrentUser()
  const courseDetails = course
  const ctaIsPreview = !!courseDetails.coursePreviewAvailable

  // if CTA is not Preview, then it is Enroll;
  // only show Enroll CTA if the course start date and price are available
  const showCta = ctaIsPreview || (!!courseDetails?.startDate && !!courseDetails?.price)

  const createPath = (url: string, isLoggedIn: boolean) =>
    isLoggedIn ? url : `/login?fwd=${encodeURIComponent(window.location.origin + url)}`

  const { enrollPath, previewPath } = useMemo(() => {
    const formattedStartDate = dayjs(courseDetails?.startDate).format('YYYY-MM-DD')
    const enrollPath = `/course-payment/${courseDetails.course?.slug}/${formattedStartDate}`
    const previewPath = `/courses/${courseSlug}/preview`

    return {
      enrollPath: createPath(enrollPath, isLoggedIn),
      previewPath: createPath(previewPath, isLoggedIn)
    }
  }, [courseDetails.course?.slug, courseDetails?.startDate, courseSlug, isLoggedIn])

  if (!courseSlug || !courseDetails?.course) {
    return <Redirect to="/courses" />
  }

  const { imageUrl, aspectRatio } = courseDetails?.backgroundImage || {}
  const courseCreators = courseDetails?.creators || []

  const courseDetailsPrice = +(courseDetails?.price || '0')

  const coursePriceText = course.price ? `${formatMoney(courseDetailsPrice)} • ` : ''

  const courseDurationText = courseDetails?.duration ? `${courseDetails.duration} • ` : ''

  const courseStartsAtText =
    courseDetails &&
    `Starts ${prettyStartDateFromMarketingCourse(
      courseDetails as unknown as MarketingCourseStartDatePartsFragment,
      'MMMM dd, yyyy'
    )}`

  return (
    <div className="marketing-site">
      <section className="relative flex flex-col">
        <Image
          src={imageUrl || ''}
          alt=""
          width={1920}
          height={1920 / (aspectRatio || 1)}
          className="-z-1 hidden h-auto w-full xl:block"
        />

        <div className="relative top-0 flex flex-col bg-rb-background-beige px-[16px] pt-[10vw] md:px-[4vw] xl:absolute xl:bg-transparent xl:pt-0">
          <div className={'w-full xl:min-w-[620px] xl:max-w-[55%] xl:py-[20px]'}>
            <h1 className="mb-[30px] font-normal leading-none tracking-tight fluid-text-6xl">
              {courseDetails?.course?.title}
            </h1>
            <h3
              className={
                'mt-[10px] mb-[20px] pr-[20px] font-light tracking-tight fluid-text-2xl'
              }
            >
              {courseDetails?.course?.shortDescription}
            </h3>
            <p className="mt-[3vw] mb-[8vw] !leading-6 tracking-normal fluid-text-base xl:mb-[1vw]">
              {courseDetails?.hostSubtitle}
            </p>
          </div>

          <div className="z-20 w-full rounded-[10px] bg-rb-white p-[4vw] drop-shadow-[rgba(0,0,0,.22)_0px_0px_5px] xl:mr-[4vw] xl:min-w-[400px] xl:max-w-[38%] xl:p-[2vw]">
            {showCta && (
              <div className="flex items-start justify-between">
                <div>
                  <p className="mb-[16px] font-bold fluid-text-3xl xl:fluid-text-xl">
                    {formatMoney(courseDetailsPrice)}
                  </p>
                  <p className="mb-[3vw] !leading-6 fluid-text-sm">
                    One Time Payment
                    <br />
                    Or use a Live Course Pass
                  </p>
                </div>

                <div className="justify-items-center">
                  <ButtonMarketingCTA
                    className="hidden xl:block"
                    location="course_details_price_card"
                    href={enrollPath}
                  >
                    Enroll Today ↗
                  </ButtonMarketingCTA>
                  {ctaIsPreview && (
                    <ButtonMarketingCTA
                      location="course_details_price_card_preview"
                      className="mx-auto mt-5 hidden border-none bg-transparent text-black xl:flex"
                      href={previewPath}
                      iconBefore={<EyeIcon />}
                    >
                      Free Preview
                    </ButtonMarketingCTA>
                  )}
                </div>
              </div>
            )}

            <p className="mb-[1vw] fluid-text-base">
              <strong className="font-medium">What’s included?</strong>
            </p>

            <ul className="block-content mb-[2vw]">
              <li className="mb-[8px] !leading-[1.3] fluid-text-sm">
                Downloadable templates
              </li>
              <li className="mb-[8px] !leading-[1.3] fluid-text-sm">
                Small group with capped enrollment
              </li>
              <li className="mb-[8px] !leading-[1.3] fluid-text-sm">Live events</li>
              <li className="mb-[8px] !leading-[1.3] fluid-text-sm">
                Access to this course content for 6 months
              </li>
            </ul>

            <p className="mb-[16px] text-center fluid-text-sm">
              Unable to make this round?{' '}
              <Link
                to="/courses-marketplace-waitlist"
                className="text-rb-black underline"
                rel="noreferrer"
                target="_blank"
              >
                Sign up to to hear about future sessions.
              </Link>
            </p>

            <Link
              to="/reimbursement"
              className="block text-center text-rb-black underline fluid-text-sm"
              rel="noreferrer"
              target="_blank"
            >
              Get reimbursed by your employer
            </Link>

            <div className="mb-8 justify-center">
              <ButtonMarketingCTA
                location="course_details_price_card"
                className="mx-auto mt-10 xl:hidden"
                href={enrollPath}
              >
                Enroll Today ↗
              </ButtonMarketingCTA>
              {ctaIsPreview && (
                <ButtonMarketingCTA
                  location="course_details_price_card_preview"
                  className="mx-auto mt-5 border-none bg-transparent text-black xl:hidden"
                  href={previewPath}
                  iconBefore={<EyeIcon />}
                >
                  Free Preview
                </ButtonMarketingCTA>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="flex flex-col bg-rb-background-beige px-[16px] pt-[6.6vmax] md:px-[4vw] xl:pb-[18vmax]">
        <div className="block-content relative w-full xl:pl-[45%]">
          <h3 className="mb-[32px] font-normal tracking-tight fluid-text-3xl">
            Who is this course for?
          </h3>

          <UnitComplexPortableText
            content={JSON.parse(courseDetails?.course?.whoThisIsFor || '{}')}
          />

          <h3 className="!mt-12 mb-[32px] font-normal tracking-tight fluid-text-3xl">
            What you’ll do:
          </h3>

          <UnitComplexPortableText
            content={JSON.parse(courseDetails?.course?.whatYouWillLearn || '{}')}
          />
        </div>
      </section>

      <section
        className={twMerge(
          'grid w-full grid-cols-1 gap-[20px] bg-rb-background-beige px-[16px] pt-[6.6vmax] pb-[6.6vmax] sm:grid-cols-2 md:px-[4vw] xl:-mt-[8vmax] xl:grid-cols-3 xl:bg-transparent xl:pt-0',
          courseDetails?.courseInfoCards &&
            courseDetails?.courseInfoCards.length > 3 &&
            'lgr:grid-cols-4'
        )}
      >
        {courseDetails?.courseInfoCards?.map((card) => (
          <div
            key={card.id}
            className="rounded-[5px] bg-rb-white p-[10%] text-center shadow-[0_0_5px_rgba(0,0,0,.15)]"
          >
            <h3 className="mb-[6%] font-normal fluid-text-xl">{card.title}</h3>
            <p className="!leading-6 fluid-text-base">{card.description}</p>
          </div>
        ))}
      </section>

      {courseCreators.length > 0 && (
        <section className="mb-[40px] px-[16px] py-[6.6vmax] md:px-[4vw]">
          <h2 className="mb-[40px] font-normal tracking-tight fluid-text-6xl">
            Meet the Creator{courseCreators.length > 1 && 's'}
          </h2>

          <div
            className={twMerge(
              'grid grid-cols-1 items-stretch md:grid-cols-2',
              courseCreators.length > 1 && 'gap-4',
              courseCreators.length >= 3 && 'md:grid-cols-3'
            )}
          >
            {courseCreators.map((creator) => {
              if (!creator?.expert) {
                return null
              }

              return (
                <ExpertCard
                  key={creator.id}
                  expert={
                    {
                      ...creator.expert,
                      pastCompanies: creator.role
                    } as ArtifactAuthor
                  }
                  horizontal={courseCreators.length < 3}
                />
              )
            })}
          </div>
        </section>
      )}

      <section className="px-[16px] pt-[6.6vmax] pb-[2.6vmax] xl:px-[4vw] xl:pb-[6.6vmax] xl:pt-[2.6vmax]">
        <h3
          className={`mb-[40px] block font-normal tracking-tight fluid-text-3xl ${
            courseDetails?.hosts && courseDetails?.hosts.length > 1 ? '' : 'xl:hidden'
          }`}
        >
          Meet your Host{courseDetails?.hosts && courseDetails?.hosts.length > 1 && 's'}
        </h3>

        <div className="flex flex-wrap">
          {courseDetails?.hosts?.map(({ id, hostPicture, hostName, hostBio }) => {
            const {
              imageUrl: hostPic,
              aspectRatio: hostPicAspectRatio,
              imageAlt: alt
            } = hostPicture || {}

            return (
              <div
                key={id}
                className={twMerge(
                  'mb-[40px] flex flex-col items-center xl:mb-0 xl:w-[47.5%] xl:flex-row xl:items-start xl:justify-between odd:xl:mr-[2.5%] even:xl:ml-[2.5%]',
                  courseDetails?.hosts?.length === 1 && 'xl:w-[85%] odd:xl:mr-0'
                )}
              >
                {courseDetails?.hosts?.length === 1 ? (
                  <div
                    className={twMerge(
                      'relative mb-[32px] aspect-1 h-full w-full max-w-[300px] xl:aspect-[auto] xl:w-[35%] xl:max-w-none'
                    )}
                  >
                    <Image
                      src={hostPic || ''}
                      alt={alt || hostName || ''}
                      width={500}
                      height={500 * (hostPicAspectRatio || 1)}
                      className="h-auto w-full"
                    />
                  </div>
                ) : (
                  <div
                    className={twMerge(
                      'relative mb-[32px] aspect-1 h-full w-full max-w-[300px] xl:aspect-[auto] xl:w-[35%] xl:max-w-none'
                    )}
                  >
                    <Image
                      src={hostPic || ''}
                      alt={alt || hostName || ''}
                      className="h-auto w-full object-contain object-top"
                    />
                  </div>
                )}

                <div className="xl:w-[55%]">
                  {courseDetails?.hosts?.length === 1 && (
                    <h3 className="mb-[40px] hidden font-normal tracking-tight fluid-text-3xl xl:block">
                      Meet your Host
                    </h3>
                  )}

                  <h4 className="mb-[32px] !font-serif font-light tracking-tight fluid-text-5xl xl:fluid-text-3xl">
                    {hostName}
                  </h4>

                  <p className="!leading-7 fluid-text-base">{hostBio}</p>
                </div>
              </div>
            )
          })}
        </div>
      </section>

      <section className="px-[16px] pb-[6.6vmax] xl:px-[4vw] xl:pt-[6.6vmax]">
        <h2 className="mb-[6vw] font-normal tracking-tight fluid-text-3xl xl:fluid-text-6xl">
          Why Reforge?
        </h2>

        <div className="flex flex-col-reverse items-stretch xl:flex-row">
          <div className="relative h-[270px] w-full xl:mr-[2vw] xl:h-auto xl:w-[55%]">
            <img
              src={CoursesHeaderHeroPic}
              alt="Person in front of a computer screen attending an online course"
              className="h-full w-full object-contain"
            />
          </div>

          <div className="w-full pb-[7vw] xl:w-[45%]">
            {WHY_REFORGE.map((item, i) => (
              <div className="mb-[8vw] flex xl:mb-[5vw]" key={i}>
                <div className="w-[25%] shrink-0 pr-[3vw] xl:w-[20%] xl:pr-[2vw]">
                  <Image
                    src={item.icon}
                    alt={item.title}
                    width={150}
                    height={150}
                    className="mx-auto block h-auto w-full max-w-[60px] md:max-w-[80px]"
                  />
                </div>
                <div>
                  <p className="mb-[16px] !leading-6 fluid-text-lg">
                    <strong className="font-semibold">{item.title}</strong>
                  </p>
                  <p className="!leading-6 fluid-text-base">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {showCta && (
          <div className="mx-[4vw] mt-[80px] flex flex-col items-center rounded-[10px] bg-rb-blue-50 px-[4vw] py-[8vw] text-center drop-shadow-[rgba(0,0,0,.27)_0px_0px_5px] xl:mb-[-230px] xl:px-[2vw] xl:py-[3vw] 2xl:mb-[-250px]">
            <h3 className="mb-[8vw] font-normal tracking-tight fluid-text-3xl xl:mb-[2vw]">
              {courseDetails?.course?.title}
            </h3>

            {(coursePriceText || courseDurationText || courseStartsAtText) && (
              <p className="mb-[8vw] !leading-[1.3] fluid-text-lg md:mb-[3vw]">{`${coursePriceText}${courseDurationText}${courseStartsAtText}`}</p>
            )}

            <ButtonMarketingCTA location="course_details_bottom_card" href={enrollPath}>
              Enroll Today ↗
            </ButtonMarketingCTA>
          </div>
        )}
      </section>

      <div className="hidden h-[100px] bg-rb-background-beige xl:block" />

      {courseDetails?.courseFaq && <FaqSection faqs={courseDetails?.courseFaq} />}
    </div>
  )
}

const OldCourseDetailsPageBehindFeatureFlag = () => {
  const { courseSlug } = useParams<CourseDetailsPageParams>()
  const [getMarketingCourseBySlug, { data, loading }] = useMarketingCourseBySlugLazyQuery(
    {
      variables: { slug: courseSlug }
    }
  )

  useEffect(() => {
    getMarketingCourseBySlug()
  }, [getMarketingCourseBySlug])

  if (loading || !data) {
    return <Loading />
  }

  return <OldCourseDetailsPage course={data.marketingCourse} courseSlug={courseSlug} />
}

export default OldCourseDetailsPageBehindFeatureFlag
