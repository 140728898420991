import clsx from 'clsx'

import { SkeletonTags } from 'components/skeletons/foundation/SkeletonTags/SkeletonTags'
import SkeletonRfParagraph from 'components/skeletons/typography/SkeletonRfParagraph'

export interface SkeletonPostProps {
  className?: string
}
export default function SkeletonPost({ className = '' }: SkeletonPostProps) {
  return (
    <div
      className={clsx(
        'flex animate-pulse flex-col rounded-md border border-rb-gray-50 bg-white p-2.5 pb-3.5 md:p-7',
        className
      )}
    >
      <div className="flex max-w-full justify-between align-top ">
        <div className="flex max-w-[150px] flex-initial flex-wrap md:max-w-none">
          <SkeletonTags />
        </div>
        <div className="min-w-1/2 flex flex-shrink-0 flex-col items-end text-right">
          <SkeletonRfParagraph lines={1} className="mb-0 mb-1 h-4 w-[100px]" />
          <SkeletonRfParagraph lines={1} className="mb-0 h-4 w-[120px]" />
        </div>
      </div>
      <div>
        <div className="my-4 flex items-center">
          <div className="h-12 w-12 rounded-full bg-rb-gray-100" />
          <div className="ml-4">
            <SkeletonRfParagraph lines={1} className=" mb-2 h-5 w-[100px]" />
            <SkeletonRfParagraph lines={1} className=" mb-2 h-5  w-[120px]" />
            <SkeletonRfParagraph lines={1} className=" h-5 w-[140px]" />
          </div>
        </div>
        <div className="my-4 flex flex-wrap items-center">
          <SkeletonRfParagraph lines={1} className=" mb-2 h-5 w-full md:w-[800px]" />
          <SkeletonRfParagraph
            lines={1}
            className=" inline-block h-5  w-[100px] md:hidden"
          />
        </div>
        <div className="flex w-full  flex-col">
          <SkeletonRfParagraph className="mb-2 h-4 w-full" />
          <SkeletonRfParagraph className="mb-[-8px] h-4 w-full" />
          <div className="z-[1] h-2 w-full bg-white"></div>
        </div>
        <div className="flex w-full pt-4 ">
          <div className="mr-2 h-4 w-6 rounded-full bg-rb-gray-100" />
          <SkeletonRfParagraph className="mr-2 hidden h-4 w-[100px] md:block" />
          <div className="h-4 w-6 rounded-full bg-rb-gray-100" />
          <SkeletonRfParagraph className="ml-2 hidden h-4 w-[100px] md:block" />
          <div className="flex flex-1 justify-end">
            <SkeletonRfParagraph className="h-4 w-[70px]" />
          </div>
        </div>

        <div className="pt-4">
          <hr />
          <div className="flex py-4">
            <div className="my-auto h-9 w-9 rounded-full bg-rb-gray-100" />
            <div className="flex flex-1 flex-col flex-wrap pl-1.5 pr-4">
              <SkeletonRfParagraph lines={1} className=" mb-2 h-5 w-full" />
              <SkeletonRfParagraph lines={1} className=" mb-2 h-5 w-full" />
            </div>
            <div className="w-[100px]">
              <SkeletonRfParagraph lines={1} className="h-5 w-full" />
            </div>
          </div>
          <hr />
        </div>
        <div className="flex w-full justify-center pt-4">
          <SkeletonRfParagraph lines={1} className=" mb-0 h-3 w-[100px]" />
        </div>
      </div>
    </div>
  )
}
