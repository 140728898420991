import pluralize from 'pluralize'

import { getContentSubtypeIcon } from 'components/ContentCard/utils/getContentSubtypeIcon'
import { getContentTypeIcon } from 'components/ContentCard/utils/getContentTypeIcon'
import { BookmarkFolderIcon, PlayCircleBorderIcon, SessionIcon } from 'components/icons'
import HighlightIcon from 'components/icons/HighlightIcon'
import LockIcon from 'components/icons/LockIcon'

import { CmsSectionContentType } from 'gql/index'

// TODO: should probably take a params object
export function getContentTypeLabel(
  contentSubType?: string | null,
  contentType?: string | null,
  typename?: string | null,
  numLessons?: number | null,
  isShared?: boolean | null
) {
  if (contentSubType) {
    return (
      <>
        {getContentSubtypeIcon(contentSubType)}
        {contentSubType}
      </>
    )
  } else if (
    typename === 'CmsSection' &&
    contentType &&
    contentType !== CmsSectionContentType.SECTION &&
    contentType !== CmsSectionContentType.UNIT
  ) {
    return (
      <>
        {getContentTypeIcon(contentType)}
        {contentType}
      </>
    )
  } else if (typename === 'Clip') {
    return (
      <>
        <PlayCircleBorderIcon className="mr-1.5 h-[14px] w-[14px]" />
        Highlight
      </>
    )
  } else if (typename === 'Event') {
    return <SessionIcon className="mr-1.5" />
  } else if (typename === 'Bookmark') {
    return (
      <>
        <HighlightIcon className="mr-1.5 h-[14px] w-[14px] fill-black" />
        Highlight
      </>
    )
  } else if (typename === 'Collection') {
    const IconComponent = isShared ? BookmarkFolderIcon : LockIcon

    return (
      <>
        <IconComponent className="mr-1.5 h-[14px] w-[14px] stroke-current" />
        {isShared ? 'Collection' : 'Private Collection'}
      </>
    )
  } else if (contentType === CmsSectionContentType.SECTION) {
    if (numLessons && numLessons > 0) {
      return <>{pluralize('Lesson', numLessons, true)}</>
    } else {
      return <>Section</>
    }
  } else if (
    contentType === CmsSectionContentType.UNIT ||
    contentType === CmsSectionContentType.RESOURCE
  ) {
    if (numLessons && numLessons > 0) {
      return <>{pluralize('Resource', numLessons, true)}</>
    } else {
      return <>Resource</>
    }
  }

  return null
}
