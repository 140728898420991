import React from 'react'
import ReactDOM from 'react-dom'

import { Maybe } from 'gql'

interface IUserProjectLink {
  linkText: string
  linkHref?: Maybe<string>
  classStr: string
}

const UserProjectLink = ({ linkText, linkHref, classStr = '' }: IUserProjectLink) => {
  const stopLinkClickAction = (e: React.MouseEvent) => {
    e.preventDefault()
  }

  let linkToggleAction
  let linkClickAction: ((e: React.MouseEvent) => void) | undefined = stopLinkClickAction
  if (linkHref) {
    if (linkHref === '#') {
      linkToggleAction = 'target: #project-email-modal'
    } else {
      linkClickAction = undefined
    }
  } else {
    linkToggleAction = 'target: #project-creation-in-progress'
  }

  return (
    <a
      className={classStr}
      href={linkHref || ''}
      target="_blank"
      rel="noreferrer"
      uk-toggle={linkToggleAction}
      onClick={linkClickAction}
    >
      {linkText}
    </a>
  )
}

interface IUserProjectLinks {
  links: HTMLElement[]
  url: Maybe<string> | undefined
  templateUrl?: Maybe<string>
}

export default function UserProjectLinks({ links, url, templateUrl }: IUserProjectLinks) {
  return (
    <>
      {links.map((el) => {
        const linkEl = el.children[0]
        if (!linkEl) {
          return null
        }

        // maintain existing classes to keep button if present
        let classStr = ''
        let classList = Array.from(linkEl.classList)
        classList = [...classList].filter(
          (linkClass) => linkClass !== 'user-project-link-placeholder'
        )
        if (classList.length > 0) {
          classStr = classList.join(' ')
        }
        return ReactDOM.createPortal(
          <UserProjectLink
            linkText={linkEl.textContent || ''}
            linkHref={url}
            classStr={classStr}
          />,
          el
        )
      })}
      <div
        id="project-creation-in-progress"
        uk-modal={'{"container": "#rf-modal-container-portal" }'}
      >
        <div className="uk-modal-dialog uk-modal-body">
          <h2 className="m-0 overflow-hidden overflow-ellipsis whitespace-nowrap font-sans text-2xl font-bold leading-7 tracking-[.15px] text-rb-gray-500">
            Project Creation In Progress
          </h2>
          <p>Your project file is being created. Try again shortly.</p>
          {templateUrl && (
            <p>
              You can also{' '}
              <a href={templateUrl} target="_blank" rel="noreferrer">
                click here
              </a>{' '}
              to view a read-only copy of the template.
            </p>
          )}
          <button className="uk-modal-close-default" type="button" uk-icon="close" />
        </div>
      </div>
    </>
  )
}
