import PropTypes from 'prop-types'
import { useEffect, useReducer } from 'react'
import UIkit from 'uikit'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'

import { sendData } from 'utils/sendData'

export default function DeletePaymentMethodModal(props) {
  const initialState = {
    loading: false,
    paymentMethodDeleted: false
  }
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialState
  )

  useEffect(() => {
    const modal = document.getElementById('modal-delete-payment-method')
    if (modal) {
      UIkit.util.on(modal, 'hidden', function (event) {
        setState(initialState)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deletePaymentMethod = () => {
    setState({ loading: true })
    sendData(
      `/api/v1/stripe_payments/payment_methods/${props.paymentMethod.id}`,
      'DELETE',
      {},
      (errorMessage, responseText) => {
        if (errorMessage) {
          setState({ loading: false })
          return
        }
        if (responseText) {
          props.afterDelete()
          window.location.reload()
        }
      }
    )
  }

  return (
    <div id="modal-delete-payment-method" uk-modal={'{"container": false}'}>
      <div className="uk-modal-dialog uk-modal-body ">
        {!state.paymentMethodDeleted && (
          <div className="uk-text-center text-xl text-rb-gray-300">
            <p className="uk-margin uk-margin-bottom p-4">
              Are you sure you want to remove this card as a payment method?
            </p>
            <div className="uk-margin flex items-center justify-center">
              <Button
                size="small"
                variant="outline"
                disabled={state.loading}
                className="uk-modal-close mr-4"
              >
                Cancel
              </Button>

              <Button size="small" disabled={state.loading} onClick={deletePaymentMethod}>
                Remove Card
              </Button>
            </div>
          </div>
        )}
        {state.paymentMethodDeleted && (
          <>
            <div className="flex justify-end">
              <a className="uk-icon-link uk-modal-close-default" uk-icon="icon: close;" />
            </div>
            <div className="uk-text-center text-xl text-rb-gray-300">
              <SVGIcon
                name="check-slim"
                height="42"
                width="55"
                fill="#000000"
                className="uk-margin-small-bottom uk-margin-top mx-auto"
              />
              <div className="uk-margin" style={{ marginBottom: '40px' }}>
                <p>Payment method removed</p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

DeletePaymentMethodModal.propTypes = {
  paymentMethod: PropTypes.object,
  afterDelete: PropTypes.func
}
