import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { ComplexPortableText } from 'domains/Sanity/PortableText/ComplexPortableText/ComplexPortableText'

import { ChevronDownThin } from 'components/icons'

import { MarketingFaq } from 'gql'

interface FaqSectionProps {
  faqs: MarketingFaq[]
}

const FaqSection = ({ faqs }: FaqSectionProps) => {
  const [activeAccordion, setActiveAccordion] = useState<string | null>(null)
  const onToggle = (id: string, status: boolean) => {
    setActiveAccordion(status ? id : null)
  }

  return (
    <section className={'bg-rb-background-beige py-[6.6vmax] px-4 md:px-[4vw]'}>
      <h2 className="mb-[60px] font-medium fluid-text-6xl">FAQs</h2>

      <div className="mx-auto sm:max-w-[85%]">
        {faqs.map(({ id, answer, question }) => (
          <div key={id} id={id} className="w-full border-t border-black last:border-b">
            <button
              onClick={() => onToggle(id, !(activeAccordion === id))}
              aria-expanded={activeAccordion === id}
              aria-controls={`accordion-${id}`}
              className="flex w-full items-center justify-between bg-transparent py-8 px-0 text-left font-serif font-light fluid-text-xl"
            >
              {question}
              <div
                className={twMerge(
                  'relative ml-2 block h-3 w-3 flex-shrink-0',
                  activeAccordion === id && 'rotate-180'
                )}
              >
                <ChevronDownThin />
              </div>
            </button>
            <div
              className={`m-0 h-0 max-w-2xl overflow-hidden opacity-0 transition-all duration-500 ease-in-out sm:max-w-[70%]
              ${activeAccordion === id && 'h-max opacity-100'}`}
            >
              <div className="block-content pr-8 pb-4">
                <ComplexPortableText content={answer} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default FaqSection
