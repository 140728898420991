import PropTypes from 'prop-types'

import EmailsForm from 'domains/User/EmailsForm'
import JoinSlack from 'domains/User/JoinSlack'
import PreferenceForm from 'domains/User/PreferenceForm'

import { UserOauthProvider } from 'gql/index'

interface EditFormProps {
  preference: any
  canEditEmailPreferences: boolean
  user: any
  oauthProviders?: UserOauthProvider[] | null
  joinSlackHref: string
}

export const EditForm = ({
  user,
  preference,
  canEditEmailPreferences,
  oauthProviders,
  joinSlackHref
}: EditFormProps) => {
  return (
    <div key="uikit-reactrouter-hack-usereditformindex">
      {user.is.member && <JoinSlack user={user} joinSlackHref={joinSlackHref} />}

      <EmailsForm oauthProviders={oauthProviders} />

      {canEditEmailPreferences && <PreferenceForm preference={preference} />}
    </div>
  )
}

EditForm.propTypes = {
  preference: PropTypes.object.isRequired,
  canEditEmailPreferences: PropTypes.bool,
  user: PropTypes.object.isRequired,
  oauthProviders: PropTypes.array.isRequired,
  joinSlackHref: PropTypes.string
}
