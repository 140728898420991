import React, { useEffect, useState } from 'react'

interface SidebarNavProps {
  sectionRefs: {
    id: string
    ref: React.RefObject<HTMLElement>
    linkText: string
    onClick: (e: React.MouseEvent) => void
  }[]
  children?: React.ReactNode
}

export const SidebarNav = ({ sectionRefs, children }: SidebarNavProps) => {
  const [visibleSection, setVisibleSection] = useState('')

  const getDimensions = (ele: HTMLElement) => {
    const { height } = ele.getBoundingClientRect()
    const offsetTop = ele.offsetTop
    const offsetBottom = offsetTop + height

    return {
      height,
      offsetTop,
      offsetBottom
    }
  }

  useEffect(() => {
    const updateVisibleSection = () => {
      const scrollPosition = window.scrollY

      const selected = sectionRefs.find(({ ref }) => {
        const ele = ref.current
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele)
          return scrollPosition >= offsetTop && scrollPosition <= offsetBottom
        }
      })

      if (selected && selected.id !== visibleSection) {
        setVisibleSection(selected.id)
      } else if (!selected && visibleSection) {
        setVisibleSection('')
      }
      // all this code is for highlighting the menu items when they are scrolled into view
      // I got it from https://www.codedaily.io/tutorials/Sticky-Header-with-Highlighting-Sections-on-Scroll
    }
    window.addEventListener('scroll', updateVisibleSection)
    return () => {
      window.removeEventListener('scroll', updateVisibleSection)
    }
  }, [sectionRefs, visibleSection])

  return (
    <div className="hidden sm:block">
      <div className="sticky top-4 mr-3 mt-3 h-screen max-w-min">
        <nav>
          <ul className="list-none pl-0">
            {sectionRefs.map(({ id, linkText, onClick }) => (
              <li className="mb-4" key={`menu-link-${id}`}>
                <a
                  href={`#${id}`}
                  className={`text-left text-rb-teal-200 underline hover:font-medium focus:font-medium focus:outline-none ${
                    visibleSection === id ? 'font-medium' : 'font-normal'
                  }`}
                  onClick={(e) => {
                    setVisibleSection(id)
                    onClick(e)
                  }}
                >
                  {linkText}
                </a>
              </li>
            ))}
          </ul>
        </nav>
        {children}
      </div>
    </div>
  )
}

const Divider = () => (
  <div className="my-8">
    <hr />
  </div>
)
interface SidebarNavigablePageProps {
  sections: {
    component: React.ReactNode
    linkText: string
    onClick: (e: React.MouseEvent) => void
    ref: React.RefObject<HTMLElement>
  }[]
  header: React.ReactNode
  additionalSidebarContent: React.ReactNode
  children?: React.ReactNode
}

const SidebarNavigablePage = ({
  additionalSidebarContent,
  sections,
  header,
  children
}: SidebarNavigablePageProps) => {
  const sectionRefs = sections.map(({ component, linkText, onClick, ref }) => {
    return {
      id: linkText.split(' ').join('-'),
      ref,
      linkText,
      onClick,
      component
    }
  })
  return (
    <div>
      <div className="py-6 px-6 shadow-card-large lg:px-8 ">{header}</div>
      <div className="flex py-8 px-6 lg:px-8">
        <SidebarNav sectionRefs={sectionRefs}>{additionalSidebarContent}</SidebarNav>
        <main className="sm:px-6 lg:px-8">
          {children}
          {sectionRefs.map(({ component, ref, id }) => (
            <section key={`section-${id}`} id={id} ref={ref} tabIndex={-1}>
              {component}
              <Divider />
            </section>
          ))}
        </main>
      </div>
    </div>
  )
}

export default SidebarNavigablePage
