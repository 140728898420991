import { useEffect } from 'react'

import { useGlobalModal } from 'components/GlobalModal'

import { CompletionSnapshot } from 'gql'

import { getSnapshotDismissalCookieName, openShareableSnapshotModal } from './helpers'

export interface ShareableSnapshotModalProps {
  page: string
  data: CompletionSnapshot
}

export function ShareableSnapshotModal({
  page,
  data: snapshotData
}: ShareableSnapshotModalProps) {
  const urlParams = new window.URLSearchParams(window.location.search)
  const { openGlobalModal, closeGlobalModal } = useGlobalModal()

  useEffect(() => {
    const dismissalCookie = getSnapshotDismissalCookieName(snapshotData.seasonName)
    const hasDismissed = window.localStorage.getItem(dismissalCookie) === '1'
    const shouldShow =
      !hasDismissed || (hasDismissed && urlParams.get('show_snapshot') === 'true')

    if (shouldShow && snapshotData.isReleased) {
      openShareableSnapshotModal({
        snapshotData,
        page,
        openGlobalModal,
        closeGlobalModal,
        isOneTime: true
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapshotData])

  return null
}
