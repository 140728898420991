import { twMerge } from 'tailwind-merge'

import { BaseFilterSet } from './Filter.types'

export interface ListFilterSet extends BaseFilterSet {
  explainer?: string
  options: string[][]
  type: 'list'
}

export interface IListFilter {
  filterSet: ListFilterSet
  replaceFilters: (filterKey: string, newValue: string) => void
  val: string | number | null | string[]
}

const ListFilter = (props: IListFilter) => {
  const {
    filterSet,
    filterSet: { key }, // fixes linter error
    replaceFilters,
    val
  } = props

  const listItem = (filterOption: string[], index: number) => {
    const curFilterDisplay = filterOption[1]
    const curFilterValue = filterOption[0]

    const isActive = val === curFilterValue

    return (
      <li key={`option_${index}`}>
        <a
          className={twMerge(
            'block py-[5px] pl-5 leading-[30px] text-rb-gray-300 hover:no-underline',
            isActive && 'bg-rb-gray-50 text-rb-gray-500'
          )}
          onClick={() => {
            replaceFilters(key, curFilterValue)
          }}
        >
          {curFilterDisplay}
        </a>
      </li>
    )
  }

  return (
    <ul className="list-none p-0">
      {filterSet.options.map((filterOption, index) => listItem(filterOption, index))}
    </ul>
  )
}

export default ListFilter
