import { EventCountdownPageEventPartsFragment } from 'gql'

import { formatInTimezone } from 'utils/date'

const EVENT_START_THRESHOLD = 300000

export const timeUntilEventStarts = (event: EventCountdownPageEventPartsFragment) =>
  new Date().getTime() - Date.parse(event.startsAtUtc)

export const isEventInProgress = (event: EventCountdownPageEventPartsFragment) =>
  new Date().getTime() > Date.parse(event.startsAtUtc) &&
  new Date().getTime() < Date.parse(event.endsAtUtc)

export const isEventAboutToStart = (event: EventCountdownPageEventPartsFragment) =>
  timeUntilEventStarts(event) < EVENT_START_THRESHOLD &&
  timeUntilEventStarts(event) > -EVENT_START_THRESHOLD

export const isEventEnded = (event: EventCountdownPageEventPartsFragment) =>
  new Date().getTime() > Date.parse(event.endsAtUtc)

export const startsInLessThan24Hours = (event: EventCountdownPageEventPartsFragment) => {
  const substrings = ['hour', 'minute', 'second']
  return new RegExp(substrings.join('|')).test(event.timeUntil || '')
}

export const formatTime = (time: string, format: string, userTimezone: string) =>
  formatInTimezone(time, userTimezone, format).toLowerCase().split(' ').join('')
