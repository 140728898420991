import { PropsWithChildren } from 'react'

import { CloseIcon } from 'components/icons'

export interface CohortDashboardSidebarProps {
  isOpen: boolean
  onClose: () => void
}

const CohortDashboardSidebar = ({
  isOpen,
  onClose,
  children
}: PropsWithChildren<CohortDashboardSidebarProps>) => {
  if (!isOpen) return null

  return (
    <div className="right-0 top-0 flex h-full w-full flex-col overflow-hidden border-l-1.4 border-solid border-rb-gray-50 bg-white lg:w-1/2">
      <button className="absolute right-0 top-0 hidden lg:inline-block" onClick={onClose}>
        <CloseIcon className="mt-3 mr-5 h-6 w-6 cursor-pointer text-rb-black hover:text-rb-gray-300" />
      </button>
      {children}
    </div>
  )
}

export default CohortDashboardSidebar
