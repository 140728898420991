import { formatDateRange } from 'utils/date'

import { CourseCardProps } from './CourseCard'
import CourseCardWithCustomThumbnail from './CourseCardWithCustomThumbnail'

const CourseInProgressCard = (props: CourseCardProps) => {
  const CourseInProgressFooter = () => {
    if (!props.courseSession) {
      return (
        <div>
          <span>On-demand course</span>
        </div>
      )
    }

    const { startsAt, endsAt } = props.courseSession

    if (!startsAt || !endsAt) {
      return null
    }
    return (
      <div>
        <span>Live course • {formatDateRange(startsAt, endsAt)}</span>
      </div>
    )
  }

  return (
    <CourseCardWithCustomThumbnail
      {...props}
      CourseFooterOverride={CourseInProgressFooter}
    />
  )
}

export default CourseInProgressCard
