import Button from 'components/Button'

import { ExpertUserProfileFragmentFragment } from 'gql'

import { trackCtaClicked } from 'utils/tracking/analytics'

type ProfileSideBarProps = {
  userProfile: ExpertUserProfileFragmentFragment
  slug: string
}

const CtaButton = ({ userProfile, slug }: ProfileSideBarProps) => {
  if (!userProfile.ctaUrl || !userProfile.ctaText) {
    return null
  }

  const handleCtaClicked = () => {
    trackCtaClicked({
      cta_location: 'author_profile',
      cta_type: 'button',
      text: userProfile?.ctaText?.toLowerCase() || '',
      related_identifiers: {
        author_slug: slug
      }
    })
  }

  return (
    <Button
      onClick={handleCtaClicked}
      className="h-10"
      target="_blank"
      href={userProfile.ctaUrl}
    >
      {userProfile.ctaText}
    </Button>
  )
}

export default CtaButton
