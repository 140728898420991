import { ReactNode, createContext, useCallback, useContext, useState } from 'react'

import { TabsType } from 'components/Tabs/Tabs'

import PageHeader from './PageHeader'

interface PageContextState {
  setPageTitle: (content?: string | ReactNode | null) => void
  setPageTabs: (content?: TabsType | null) => void
  setPageHeaderTopRightContent: (content?: ReactNode | null) => void
  setPageHeaderTopLeftContent: (content?: ReactNode | null) => void
}

const PageContext = createContext<PageContextState>({
  setPageTitle: () => {},
  setPageTabs: () => {},
  setPageHeaderTopRightContent: () => {},
  setPageHeaderTopLeftContent: () => {}
})

interface PageProviderProps {
  showHeader: boolean
  pageTitle?: string | ReactNode
  children: ReactNode
  headerWrapperClassName?: string
  dataTest?: string
}

export const PageProvider = ({
  showHeader,
  pageTitle,
  children,
  headerWrapperClassName,
  dataTest
}: PageProviderProps) => {
  const [title, setTitle] = useState<string | ReactNode | null>()
  const [headerTopRight, setHeaderTopRight] = useState<ReactNode | null>()
  const [headerTopLeft, setHeaderTopLeft] = useState<ReactNode | null>()
  const [tabs, setTabs] = useState<TabsType | null>()

  const setPageTitle = useCallback((t: string | ReactNode | null) => {
    setTitle(!t ? null : t)
  }, [])

  return (
    <>
      {showHeader && (
        <div className={headerWrapperClassName}>
          <PageHeader
            title={title ?? pageTitle}
            headerTopRight={headerTopRight}
            headerTopLeft={headerTopLeft}
            tabs={tabs}
            dataTest={dataTest}
          />
        </div>
      )}
      <PageContext.Provider
        value={{
          setPageTitle,
          setPageTabs: setTabs,
          setPageHeaderTopRightContent: setHeaderTopRight,
          setPageHeaderTopLeftContent: setHeaderTopLeft
        }}
      >
        {children}
      </PageContext.Provider>
    </>
  )
}

export function usePage() {
  return useContext(PageContext)
}
