import React from 'react'
import { twMerge } from 'tailwind-merge'

import { CohortPostCardDropdownProps } from 'components/CohortPostCard'
import DropdownContextMenu from 'components/dropdowns/Dropdown/DropdownContextMenu'

import { ReactComponent as NotificationIcon } from 'images/icon--cohort-notification.svg'
import { ReactComponent as PencilIcon } from 'images/icon--cohort-pencil.svg'
import { ReactComponent as PinIcon } from 'images/icon--cohort-pin.svg'
import { ReactComponent as TrashIcon } from 'images/icon--cohort-trash.svg'
import { ReactComponent as ReportIcon } from 'images/icon--report.svg'

interface IconProps {
  className: string
  width: string
  height: string
}

const defaultIconProps: IconProps = {
  className: 'fill-current',
  width: '20',
  height: '20'
}

interface CohortPostCardDropdownItemProps {
  text: string
  icon: React.ReactElement
  onClick: () => void
  className?: string
  textClassName?: string
  disabled: boolean
}

const CohortPostCardDropdownItem = ({
  text,
  icon,
  onClick,
  className,
  textClassName,
  disabled
}: CohortPostCardDropdownItemProps) => {
  return (
    <DropdownContextMenu.DropdownItem
      onClick={onClick}
      text={text}
      textClassName={twMerge(textClassName, 'text-[14px] font-semibold')}
      className={twMerge('flex h-full w-full px-4 py-4', className)}
      iconContainerClassName="pr-4"
      icon={React.cloneElement(icon, { ...defaultIconProps })}
      disabled={disabled}
    />
  )
}

type DropdownOptionsProps = Omit<CohortPostCardDropdownProps, 'postId'>

const DropdownOptions = ({
  onEditPost,
  onDeletePost,
  onTogglePinPost,
  onTogglePostNotification,
  onReportPost,
  canUserEditPost,
  canUserDeletePost,
  canUserPinPost,
  disabled = false,
  isPostPinned,
  isUserFollowingPost,
  label
}: DropdownOptionsProps) => (
  <>
    {!disabled && (
      <>
        {canUserDeletePost && onDeletePost && (
          <CohortPostCardDropdownItem
            onClick={onDeletePost}
            text="Delete post"
            className="pt-0 sm:pt-4"
            textClassName="text-rb-destructive-100"
            icon={<TrashIcon />}
            disabled={disabled}
          />
        )}

        {canUserEditPost && onEditPost && (
          <CohortPostCardDropdownItem
            onClick={onEditPost}
            text={`Edit ${label}`}
            icon={<PencilIcon />}
            disabled={disabled}
          />
        )}

        <CohortPostCardDropdownItem
          onClick={onTogglePostNotification}
          text={
            isUserFollowingPost
              ? 'Turn notification off for replies'
              : 'Get notified about new replies'
          }
          icon={<NotificationIcon />}
          disabled={disabled}
        />

        {canUserPinPost && onTogglePinPost && (
          <CohortPostCardDropdownItem
            disabled={disabled}
            onClick={onTogglePinPost}
            text={isPostPinned ? 'Unpin post from channel' : 'Pin post to channel'}
            icon={<PinIcon />}
          />
        )}

        <CohortPostCardDropdownItem
          onClick={onReportPost}
          text="Report post"
          icon={<ReportIcon />}
          disabled={disabled}
        />
      </>
    )}
  </>
)

export default DropdownOptions
