import { twMerge } from 'tailwind-merge'

export interface SkeletonRfHeaderProps {
  header?: 'h1' | 'h2' | 'h3' | 'h4'
  width?: string | number
  className?: string
}

/**
 * SkeletonRfHeader
 * returns a styled loading skeleton for header text
 * */
export default function SkeletonRfHeader({
  header = 'h1',
  width = 'full',
  className = ''
}: SkeletonRfHeaderProps) {
  const staticStyles = `w-${width} animate-pulse rounded-full bg-rb-gray-100`

  const computedStyles = (headerType: string) => {
    switch (headerType) {
      case 'h1':
        return 'h-8'
      case 'h2':
        return 'h-6'
      case 'h3':
        return 'h-4'
      case 'h4':
        return 'h-3'
      default:
        return ''
    }
  }

  return (
    <div
      className={twMerge(staticStyles, computedStyles(header), className)}
      data-testid="skeleton-rf-header"
    />
  )
}
