import { ExpertUserProfileFragmentFragment } from 'gql'

export interface AboutTabProps {
  userProfile: ExpertUserProfileFragmentFragment
}

const AboutTab = ({ userProfile }: AboutTabProps) => {
  return (
    <div className="font-sans text-base font-normal text-rb-gray-400">
      {userProfile.aboutMeText}
    </div>
  )
}

export default AboutTab
