import { useEffect, useMemo } from 'react'
import Helmet from 'react-helmet'
import { Link, Redirect, useHistory, useLocation, useParams } from 'react-router-dom'

import ArchivedBanner from 'domains/Post/ArchivedBanner'
import { Breadcrumbs } from 'domains/Post/Breadcrumbs'
import { GuidelinesLink } from 'domains/Post/Guidelines'
import PeopleInPost from 'domains/Post/PeopleInPost'
import { PostCard } from 'domains/Post/PostContent'
import PostRepliesList from 'domains/Post/PostRepliesList'
import Reactions from 'domains/Post/Reactions'

import { usePage } from 'components/PageHeader/usePage'

import {
  PostReplyCommentFragment,
  PostReplyFragment,
  PostShowFieldsFragment,
  usePostShowQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { lightboxImages } from 'utils/lightbox'

const scrollToAnchor = () => {
  const hashParts = window.location.hash.split('#')
  if (hashParts.length === 2) {
    const hash = hashParts[1]

    setTimeout(function () {
      const targetElement = document.getElementById(hash)
      if (!targetElement) return

      const pageHeader = document.getElementById('page-header')
      const headerHeight = pageHeader?.clientHeight || 0
      document.getElementById('page')?.scrollTo({
        top: targetElement.offsetTop - headerHeight - 10,
        left: 0
      })
    }, 300) // Needs time for the ui-sticky JS in the header to initialise
  }
}

const trimString = (string = '', length = 50) => {
  return string.length > length ? `${string.substring(0, length)}...` : string
}

const PostShow = () => {
  const history = useHistory()
  const location = useLocation<{ from: string }>()
  const backLink = location?.state?.from || ''
  const { slug: postSlug = '', group: groupSlug = '' } = useParams<{
    slug?: string
    group?: string
  }>()

  const { currentUser, currentUserLoading } = useCurrentUser()
  const currentUserId = currentUser?.id

  const { data, loading, error } = usePostShowQuery({
    variables: { slug: postSlug }
  })
  const post = data?.discussionPost
  const replies = post?.replies || []
  const unreadElements = post?.unreadElements || []
  const pageTitle = `${trimString(post?.title || '')}`
  const { setPageTitle } = usePage()

  useEffect(() => {
    if (window.location.hash) {
      const anchor = window.location.hash // remove the hash so tha page doesnt jump immediately.
      window.location.hash = ''
      window.history.pushState(null, '', anchor) // put it back again, without triggering the jump.
    }
  }, [])

  const createBackLink = useMemo(() => {
    const classNames =
      'font-semibold text-rb-gray-300 hover:no-underline hover:text-rb-teal-200 flex items-center'
    let url = '/posts/?backButton=true'
    let text = 'All Posts'

    if (groupSlug) {
      url = `/groups/${groupSlug}`
      text = 'Group'
    } else if (backLink === 'activity') {
      url = '/posts/activity'
      text = 'Activity'
    } else if (backLink === 'my-contributions') {
      url = '/posts/my-contributions'
      text = 'My Contributions'
    } else if (backLink === 'following') {
      url = '/posts?filter=following'
      text = 'Following'
    }

    return (
      <Link to={url} className={classNames}>
        <span uk-icon="icon: chevron-left; ratio:1" className="text-rb-gray-400" />
        <span className="ml-[5px] text-lg font-semibold">Back to {text}</span>
      </Link>
    )
  }, [backLink, groupSlug])

  useEffect(() => {
    if (pageTitle) {
      // @ts-ignore
      lightboxImages('.uk-comment-body img') // eslint-disable-line no-undef

      scrollToAnchor()
      window.onhashchange = scrollToAnchor
    }

    setPageTitle(<Breadcrumbs>{createBackLink}</Breadcrumbs>)
    return () => {
      setPageTitle(null)
    }
  }, [post, pageTitle, setPageTitle, createBackLink])

  useEffect(() => {}, [post, setPageTitle])

  useEffect(() => {
    if (error) {
      history.push('/')
    }
  }, [error, history])

  if (!currentUserLoading && (!currentUser || !currentUser.can.viewDiscussionIndex)) {
    return <Redirect to="/" />
  }

  const extractPeopleFields = (
    object?: PostShowFieldsFragment | PostReplyFragment | PostReplyCommentFragment | null
  ) => {
    const activities = post?.activities || []

    return {
      id: object?.id,
      userSlug: object?.user.profile.slug,
      userName: object?.user.profile.fullName,
      userPublicImageUrl: object?.user.profile.avatarUrl,
      userRole: object?.user.profile.role,
      userCompany: object?.user.profile.companyName,
      userLocation: object?.user.profile?.location,
      userStatus: object?.user.profile?.status,
      activities: activities.filter((activity) => activity.userId === object?.user.id),
      hasBadge: object?.user.profile.hasBadge,
      kind: object?.user.profile.kind
    }
  }

  const generatePeopleObjects = () => {
    // extract the information we need from each reply and its comments
    const people = replies
      .map((reply) => {
        return [
          extractPeopleFields(reply),
          reply.comments.map((comment) => {
            return extractPeopleFields(comment)
          })
        ]
      })
      .flat(2)
    people.unshift(extractPeopleFields(post))

    // get unique people from the list
    return [...new Map(people.map((item) => [item.userSlug, item])).values()]
  }

  const canShowReplies = !!post && !!replies && !!currentUserId

  const peopleObjects = generatePeopleObjects()

  return (
    <>
      {pageTitle && (
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      )}
      <ArchivedBanner currentUser={currentUser} />
      <div className="py-0 xs:py-4 md:py-8">
        <div className="ml-0" uk-grid="">
          <div className="uk-margin-top w-full pl-0 lg:w-3/4">
            <div className="lg:pr-8">
              {!loading && post && (
                <PostCard post={post}>
                  <div
                    className="uk-margin-top uk-child-width-1-1"
                    uk-grid="margin: uk-margin-small"
                  >
                    <div className="uk-margin-small-bottom flex items-center">
                      <div className="inline-flex flex-auto items-center justify-start">
                        <Reactions
                          reactableType="Post"
                          reactableId={post.id}
                          reactions={post.reactions || []}
                          currentUserId={currentUserId || ''}
                          reactablePost={post}
                        />
                      </div>
                    </div>

                    {!loading && canShowReplies && (
                      <PostRepliesList
                        post={post}
                        replies={replies}
                        unreadElements={unreadElements}
                      />
                    )}
                  </div>
                </PostCard>
              )}
            </div>
          </div>

          <div className="uk-margin-top hidden px-0 lg:flex lg:w-1/4">
            {!loading && post && (
              <div className="post__forum-sidebar">
                <div className="sidebar-content">
                  <PeopleInPost people={peopleObjects} />
                  <hr />
                  <GuidelinesLink />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default PostShow
