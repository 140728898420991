import CourseDetailUpcomingSession from 'domains/CourseDetail/CourseDetailUpcomingSession'

import CarouselSection from 'components/pagination/CarouselSection/CarouselSection'

import { CclCourseSessionPartsFragment, CourseDetailPartsFragment } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

interface CourseDetailUpcomingSessionsProps {
  upcomingCourseSessions?: CclCourseSessionPartsFragment[] | null
  courseDetails: CourseDetailPartsFragment
  showTitle?: boolean
}

const CourseDetailUpcomingSessions = ({
  courseDetails,
  upcomingCourseSessions,
  showTitle = false
}: CourseDetailUpcomingSessionsProps) => {
  const isTablet = useMediaQuery('(min-width: 960px)')
  const isMedium = useMediaQuery('(min-width: 768px)')
  const isSmall = useMediaQuery('(min-width: 640px)')
  const visibleSlides = isTablet ? 2 : isMedium ? 1.9 : isSmall ? 1.6 : 1

  return (
    <div className="space-y-4">
      <CarouselSection
        slideWidth={290}
        visibleSlides={visibleSlides}
        title={
          <span className="rf-h3 !mb-0 font-polysans !text-[24px] font-normal">
            Upcoming live courses
          </span>
        }
      >
        {upcomingCourseSessions?.map((session) => {
          return (
            <CourseDetailUpcomingSession
              key={session?.id}
              session={session}
              courseDetails={courseDetails}
              showTitle={showTitle}
            />
          )
        })}
      </CarouselSection>
    </div>
  )
}

export default CourseDetailUpcomingSessions
