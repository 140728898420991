import { EventPastRecordingGrid } from 'pages/EventRecordings/components/EventRecordingGrid'

import { EventCurrentUserPartsFragment, EventPartsFragment } from 'gql'

interface EventCardsGridProps {
  user: EventCurrentUserPartsFragment
  events: EventPartsFragment[]
}

export const EventCardsGrid = ({ events }: EventCardsGridProps) => {
  return <EventPastRecordingGrid events={events} />
}
