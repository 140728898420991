import Pluralize from 'react-pluralize'

import BadgeableAvatar from 'components/BadgeableAvatar'

import { MAX_WIDTH_TAILWIND_MD } from 'constants/breakpoints'

import { MemberStackUserFragment } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { GetMemberBadgeInput } from 'utils/member'

type TLegacyMemberStackUser = Omit<MemberStackUserFragment, 'id' | '__typename'> & {
  id: number
}
type TUser = MemberStackUserFragment | TLegacyMemberStackUser

interface IMemberStack {
  membersCount: number
  memberStack: TUser[]
  userIsMember: boolean
  variation: string
}

const thumbnails = (memberStack: TUser[], membersCount: number, small: boolean) => {
  let summaryText = ''
  if (memberStack.some((member) => member.isCurrentUser)) {
    summaryText = 'You'

    const othermembers = memberStack
      .filter((m) => !m.isCurrentUser)
      .map((m) => m.fullName)

    if (othermembers.length === 0) {
      summaryText += ' are a member'
    } else if (othermembers.length === 1) {
      summaryText += ` and ${othermembers[0]} are members`
    } else if (othermembers.length === 2) {
      if (membersCount - memberStack.length > 0) {
        summaryText += `, ${othermembers[0]}, ${othermembers[1]} and ${
          membersCount - memberStack.length
        } others are members`
      } else {
        summaryText += `, ${othermembers[0]} and ${othermembers[1]} are members`
      }
    }
  } else {
    const members = memberStack.map((m) => m.fullName)
    if (members.length === 0) {
      summaryText = 'No members yet'
    } else if (members.length === 1) {
      summaryText = `${members[0]} is a member`
    } else if (members.length === 2) {
      summaryText = `${members[0]} and ${members[1]} are members`
    } else if (members.length === 3) {
      if (membersCount - memberStack.length > 0) {
        summaryText = `${members[0]}, ${members[1]}, ${members[2]} and ${
          membersCount - memberStack.length
        } others are members`
      } else {
        summaryText = `${members[0]}, ${members[1]} and ${members[2]} are members`
      }
    }
  }
  const avatarSize = small ? '24' : '30'

  return (
    <span
      className="flex cursor-pointer"
      uk-tooltip={`title: <div class='rf-tooltip__container'>${summaryText}</div>; cls: uk-active rf-tooltip-down-arrow; pos: top`}
    >
      {memberStack.map((member) => (
        <BadgeableAvatar
          key={`member_${member.slug}_${member.id}`}
          user={member as GetMemberBadgeInput}
          avatarUrl={member.avatarUrl}
          width={avatarSize}
          height={avatarSize}
          fullName={member.fullName}
        />
      ))}
    </span>
  )
}

const MemberStack = (props: IMemberStack) => {
  const { memberStack, membersCount, variation, userIsMember } = props
  const small = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_MD})`)

  return (
    <div className="flex shrink-0 items-center">
      {membersCount > 0 && (
        <div className="mr-4">{thumbnails(memberStack, membersCount, small)}</div>
      )}
      <div className="mr-2 text-rb-gray-300">
        {userIsMember ? (
          <span className="text-xs font-medium tracking-widest">
            YOU
            {membersCount - 1 > 0 && (
              <>
                {variation === 'header' && (
                  <>
                    {' '}
                    +{' '}
                    <Pluralize
                      singular={'MEMBER'}
                      plural={'MEMBERS'}
                      count={membersCount - 1}
                    />
                  </>
                )}
                {variation !== 'header' && <> + {membersCount - 1}</>}
              </>
            )}
          </span>
        ) : (
          <span className="flex items-center text-xs font-semibold">
            {variation === 'header' && (
              <Pluralize
                singular={'MEMBER'}
                plural={'MEMBERS'}
                zero={'NO MEMBERS'}
                count={membersCount}
              />
            )}
            {variation !== 'header' && <>{membersCount}</>}
          </span>
        )}
      </div>
    </div>
  )
}

export default MemberStack
