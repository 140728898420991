import { useEffect, useState } from 'react'

import CheckboxFilter from 'components/CheckboxFilter'

import { FilterProps } from './helpers'

const CompanyAudience = ({ filters, setFilters }: FilterProps) => {
  const name = 'companyAudience'
  const filteredAudiences = filters[name]
  const checkboxState = [
    {
      name: 'B2B',
      boxLabel: 'Business-to-Business (B2B)',
      checked: !!filteredAudiences?.includes('B2B')
    },
    {
      name: 'B2C',
      boxLabel: 'Business-to-Consumer (B2C)',
      checked: !!filteredAudiences?.includes('B2C')
    }
  ]
  const [checkboxes, setCheckboxes] = useState(checkboxState)

  useEffect(() => {
    setCheckboxes(checkboxState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters[name]])

  return (
    <div className="company-audience">
      <CheckboxFilter
        checkboxes={checkboxes}
        filters={filters}
        label="company audience"
        name={name}
        setFilters={setFilters}
        text="Browse members focused on specific audiences and business types."
        setCheckboxes={setCheckboxes}
      />
    </div>
  )
}

export default CompanyAudience
