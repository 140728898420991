import {
  LightningBolt,
  LoopIcon,
  MaintenanceIcon,
  OverviewIcon,
  SearchIcon,
  SessionIcon,
  TrophyIcon
} from 'components/icons'

export function getContentSubtypeIcon(contentSubtype: string) {
  switch (contentSubtype) {
    case 'Example':
      return <SearchIcon className="mr-1.5" />
    case 'Template':
      return <MaintenanceIcon className="mr-1.5" />
    case 'Framework':
      return <LoopIcon className="mr-1.5" />
    case 'Case Study':
      return <TrophyIcon className="mr-1.5" />
    case 'Quick Tip':
      return <LightningBolt className="mr-1.5" />
    case 'Overview':
      return <OverviewIcon className="mr-1.5" />
    case 'Session':
      return <SessionIcon className="mr-1.5" />
    default:
      return <></>
  }
}
