import React from 'react'

import Button from 'components/Button'
import SeatAvatar from 'components/SeatAvatar'
import { AvailableSeatAvatar } from 'components/SeatAvatar/AvailableSeatAvatar'
import { PendingInviteSeatAvatar } from 'components/SeatAvatar/PendingInviteSeatAvatar'
import { CloseIcon } from 'components/icons'

import {
  TeamMembersPageDocument,
  TeamMembersPageSubscriptionFragment,
  TeamMembersPageSubscriptionSeatFragment,
  useAssignCohortCreditMutation,
  useUnassignCohortCreditMutation
} from 'gql'

import { displayErrorToast, displaySuccessToast } from 'utils/toastService'
import { trackCtaClicked } from 'utils/tracking/analytics'

import { AssignSeatPanelContent } from './AssignSeatPanelContent'
import { CohortAccessPanelContent } from './CohortAccessPanelContent'
import { UnassignSeatPanelContent } from './UnassignSeatPanelContent'

export interface PanelContentProps {
  onActionSuccess: () => void
  onCancelCtaClick: () => void
}

export interface SidePanelProps {
  seat: TeamMembersPageSubscriptionSeatFragment
  subscriptionHasDomainName: boolean
  onClose: () => void
  children: React.ReactNode
}

const getSeatAvatar = (
  subscriptionMember: TeamMembersPageSubscriptionSeatFragment['subscriptionMember'],
  subscriptionHasDomainName: boolean,
  avatarSize: number
) => {
  if (subscriptionMember && subscriptionMember.status === 'pending') {
    return (
      <PendingInviteSeatAvatar
        emailStyles="text-base font-normal text-rb-black truncate ... w-52"
        email={subscriptionMember.user.workEmail || subscriptionMember.user.email}
        avatarSize={avatarSize}
      />
    )
  } else if (subscriptionMember) {
    return (
      <SeatAvatar
        avatarSize={avatarSize}
        nameStyles="text-xl font-medium leading-7 truncate ... w-52"
        emailStyles="text-base font-normal text-rb-black truncate ... w-52"
        memberName={subscriptionMember.user.fullName || subscriptionMember.user.email}
        email={subscriptionMember.user.workEmail || subscriptionMember.user.email}
        isWorkEmail={!!subscriptionMember.user.workEmail}
        subscriptionHasDomainName={subscriptionHasDomainName}
        avatarUrl={subscriptionMember.user.profile.avatarUrl}
      />
    )
  }

  return (
    <AvailableSeatAvatar
      avatarSize={avatarSize}
      avatarWrapperSize={avatarSize}
      inSidePanel={true}
    />
  )
}

export function SidePanel({
  seat,
  subscriptionHasDomainName,
  onClose,
  children
}: SidePanelProps) {
  const subscriptionMember = seat?.subscriptionMember
  const avatarSize = 64

  return (
    <div
      className="fixed top-[72px] right-0 bottom-0 z-[1001] h-[calc(100vh-72px)] overflow-y-scroll rounded-tl-md border-l border-t border-rb-gray bg-white p-6 md:w-[365px]"
      data-test="manage-seat-side-panel"
    >
      <div className="mb-4 flex justify-end">
        <Button
          variant="text-only"
          className="p-0"
          onClick={onClose}
          aria-label="Close menu"
        >
          <CloseIcon className="h-[32px] w-[32px]" />
        </Button>
      </div>
      {getSeatAvatar(subscriptionMember, subscriptionHasDomainName, avatarSize)}
      {children}
    </div>
  )
}

export enum SeatManagementMode {
  ASSIGN_SEAT = 'assign_seat',
  UNASSIGN_SEAT = 'unassign_seat',
  COHORT_ACCESS = 'cohort_access'
}

export interface ManageSeatSidePanelProps
  extends Omit<SidePanelProps, 'children' | 'onClose'> {
  currentUserId: string
  mode: SeatManagementMode
  dispatch: Function
  subscription: TeamMembersPageSubscriptionFragment
  onCancelOrCloseCtaClick: () => void
  onActionSuccess: () => void
}

/**
 * ManageSeatSidePanel
 * returns style drawer for managing seats
 * */
export function ManageSeatSidePanel({
  currentUserId,
  seat,
  subscription,
  onActionSuccess,
  mode,
  dispatch,
  onCancelOrCloseCtaClick
}: ManageSeatSidePanelProps) {
  const [
    assignCohortCreditMutation,
    { data: assignCohortCreditData, loading: assignCohortCreditLoading }
  ] = useAssignCohortCreditMutation({
    variables: { input: { seatId: seat.id } },
    refetchQueries: [
      {
        query: TeamMembersPageDocument,
        variables: { id: currentUserId }
      }
    ]
  })

  const [
    unassignCohortCreditMutation,
    { data: unAssignCohortCreditData, loading: unAssignCohortCreditLoading }
  ] = useUnassignCohortCreditMutation({
    variables: { input: { seatId: seat.id } },
    refetchQueries: [
      {
        query: TeamMembersPageDocument,
        variables: { id: currentUserId }
      }
    ]
  })

  let PanelContent
  const handleUnassignCtaClick = () => {
    trackCtaClicked({
      cta_location: 'manage_seat_side_panel',
      text: 'remove user',
      cta_type: 'button'
    })
  }

  const handleAssignCtaClick = () => {
    trackCtaClicked({
      cta_location: 'manage_seat_side_panel',
      text: 'assign seat',
      cta_type: 'button'
    })
  }

  if (mode === SeatManagementMode.ASSIGN_SEAT) {
    // eslint-disable-next-line react/display-name
    PanelContent = () => (
      <AssignSeatPanelContent
        seat={seat}
        subscription={subscription}
        onAssignCtaClick={handleAssignCtaClick}
        onCancelCtaClick={onCancelOrCloseCtaClick}
        onActionSuccess={onActionSuccess}
      />
    )
  } else if (mode === SeatManagementMode.UNASSIGN_SEAT) {
    // eslint-disable-next-line react/display-name
    PanelContent = () => (
      <UnassignSeatPanelContent
        currentUserId={currentUserId}
        seat={seat}
        onUnassignCtaClick={handleUnassignCtaClick}
        onCancelCtaClick={onCancelOrCloseCtaClick}
        onActionSuccess={onActionSuccess}
      />
    )
  } else if (mode === SeatManagementMode.COHORT_ACCESS) {
    const firstName = seat.subscriptionMember?.user?.firstName || null
    // eslint-disable-next-line react/display-name
    PanelContent = () => (
      <CohortAccessPanelContent
        firstName={firstName}
        hasCohortAccess={seat.hasCohortAccess}
        onCancelCtaClick={onCancelOrCloseCtaClick}
        isLoading={assignCohortCreditLoading || unAssignCohortCreditLoading}
        onSave={async (cohortAccess: boolean) => {
          const mutation = cohortAccess
            ? assignCohortCreditMutation
            : unassignCohortCreditMutation
          await mutation()

          const successfulMutation =
            !assignCohortCreditData?.assignCohortCredit?.errors ||
            !unAssignCohortCreditData?.unassignCohortCredit?.errors

          dispatch({
            type: 'CLICK_SAVE_COHORT_ACCESS_CTA_BUTTON'
          })

          if (successfulMutation) {
            const determineName = seat.subscriptionMember ? `${' '}to ${firstName}` : ''
            onActionSuccess()
            displaySuccessToast({
              message: `You assigned a Live Course Pass${determineName}!`
            })
          } else {
            displayErrorToast({ message: 'Error updating cohort access' })
          }
        }}
        remainingCohortAccessCount={subscription.cohortCredits.assignable.length}
        userEnrolledInCohort={!!seat.subscriptionMember?.user?.is?.enrolledInLiveProgram}
      />
    )
  } else {
    // eslint-disable-next-line react/display-name
    PanelContent = () => <div>should not get here</div>
  }

  return (
    <SidePanel
      seat={seat}
      subscriptionHasDomainName={!!subscription.domainName}
      onClose={onCancelOrCloseCtaClick}
    >
      <PanelContent />
    </SidePanel>
  )
}
