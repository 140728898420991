import React, { useRef } from 'react'

import UserProjectEmail from 'domains/User/UserProjectEmail'

import { SVGIcon } from 'components/Icon'
import CardProgressBar from 'components/cards/CardProgressBar'
import { ExamplesDropdown } from 'components/cards/ExamplesDropdown'

import { DeliverableExamplePartsFragment, useProjectDeliverableQuery } from 'gql'

import notifyError from 'utils/errorNotifier'

import { ModuleProgress } from 'typings/scalars'

interface IDeliverableCard {
  cmsContentId: string
  cmsDeliverableId: string
  userProgress?: ModuleProgress
  cmsExamples: DeliverableExamplePartsFragment[]
  userEmails?: string[]
  projectEmail?: string | null
}

export const DeliverableCard = (props: IDeliverableCard) => {
  const {
    cmsContentId,
    cmsDeliverableId,
    cmsExamples,
    userEmails,
    projectEmail,
    userProgress
  } = props

  const retryCountRef = useRef(0)
  const { data, loading, startPolling, stopPolling } = useProjectDeliverableQuery({
    variables: { deliverableId: cmsDeliverableId },
    // so onCompleted is called on every poll and when mutation updates url
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      retryCountRef.current += 1
      // if backend errored or didn't update for 15 retries, stop polling
      if (data.projectDeliverable?.url === '#' || retryCountRef.current > 15) {
        stopPolling()
        return
      }
      // if we got a valid url, stop polling
      if (data.projectDeliverable?.url) {
        stopPolling()
        return
      }
      startPolling(1500)
    },
    onError(err) {
      stopPolling()
      notifyError(err)
    }
  })

  const title = data?.projectDeliverable?.title || ''
  const url = data?.projectDeliverable?.url || ''

  const handleShareClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  let linkToggleAction

  if (url) {
    if (url === '#') {
      linkToggleAction = 'target: #project-email-modal'
    }
  } else {
    linkToggleAction = 'target: #project-creation-in-progress'
  }

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    if (url && url !== '#') {
      window.open(url)
    }
  }

  return (
    <>
      <div className="deliverable-card w-[270px] cursor-pointer pl-2.5 xs:pl-4 md:pl-8">
        <div className="hover:!bg-rb-green-100">
          <a
            href={url}
            className="flex h-44 rounded-t border bg-rb-gray-400 p-4 font-bold"
            target="_blank"
            rel="noreferrer"
            uk-toggle={linkToggleAction}
            onClick={handleClick}
          >
            <div className="relative h-full w-full">
              <div className="deliverable-card__header uk-grid-collapse" uk-grid="">
                <div className="deliverable-card__icon-deliverable">
                  <SVGIcon name="icon-deliverable" />
                </div>
                <div className="uk-width-expand" />
                <div
                  className="uk-hidden h-4"
                  onClick={handleShareClick}
                  uk-tooltip="title: Get input; cls: uk-active rf-tooltip-up-arrow; pos: bottom"
                  uk-toggle="target: #project-share-coming-soon"
                >
                  <SVGIcon name="icon-deliverable-share" />
                </div>
                <div className=" ml-5">
                  <SVGIcon name="icon-arrow-up-right" />
                </div>
              </div>
              <div className="deliverable-card__title-container">
                <div className="mb-2 pt-9 font-sans !text-[10px] font-medium uppercase !leading-3 tracking-widest text-white opacity-75">
                  Your Project
                </div>
                <div className="rf-truncated-text-2-lines min-h-[35px] font-sans !text-base font-semibold leading-4 !text-white">
                  {title}
                </div>
              </div>
            </div>
          </a>
          <div id="project-share-coming-soon" uk-modal={'{"container": false}'}>
            <div className="uk-modal-dialog uk-modal-body">
              <h2 className="cms-header__module-name">Coming Soon</h2>
              <p>Sharing your project to others coming soon.</p>
              <button className="uk-modal-close-default" type="button" uk-icon="close" />
            </div>
          </div>
          <CardProgressBar
            className="h-1 !bg-rb-green-100"
            sectionId={cmsContentId}
            userProgress={userProgress}
          />
        </div>
        {cmsExamples.length > 0 && <ExamplesDropdown cmsExamples={cmsExamples} />}
      </div>
      <div
        id="project-email-modal"
        className="project-email-modal"
        uk-modal={'{"container": false}'}
      >
        <div className="uk-modal-dialog uk-modal-body">
          <UserProjectEmail
            emails={userEmails}
            projectEmail={projectEmail}
            cmsDeliverableId={cmsDeliverableId}
            cmsDeliverableUrl={url}
            cmsDeliverableLoading={loading}
          />
        </div>
      </div>
    </>
  )
}
