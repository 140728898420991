import * as React from 'react'
import { useHistory } from 'react-router-dom'

import GroupMembers from 'domains/Group/GroupMembers'

import { Tag } from 'components/Tag'

import { GroupsListGroupFragment, PostsSearchGroupFragment } from 'gql'

const cancelParentOnClick = (e: React.MouseEvent | React.KeyboardEvent) => {
  e.stopPropagation()
  e.nativeEvent.stopImmediatePropagation()
}

interface GroupCardProps {
  hideTopics?: boolean
  className?: string
  group: GroupsListGroupFragment | PostsSearchGroupFragment
}

const GroupCard = (props: GroupCardProps) => {
  const { group } = props
  const { slug, title, membersCount, memberStack, userIsMember, populatedTopics } = group
  const categoryName = group.groupCategory?.category
  const groupSlug = slug || ''
  const history = useHistory()

  const { hideTopics } = props

  const visitGroup = (e: React.MouseEvent) => {
    const href = `/groups/${slug}`
    e.metaKey || e.ctrlKey ? window.open(href) : history.push(href)
  }

  const visitGroupWithTopicApplied = (
    e: React.MouseEvent | React.KeyboardEvent,
    groupSlug: string,
    topicSlug: string
  ) => {
    cancelParentOnClick(e)
    const href = `/groups/${groupSlug}?topics=${topicSlug}`
    e.metaKey || e.ctrlKey ? window.open(href) : history.push(href)
  }

  const topLevelTopics = populatedTopics.filter((topic) => topic.topicId === null)

  return (
    <div
      data-test={`group-card--${groupSlug}`}
      onClick={visitGroup}
      style={{ flexDirection: 'column' }}
      className={`uk-link-reset uk-margin-bottom uk-card-body rf-rb-card-interactive flex p-4 ${
        props.className || ''
      }`}
    >
      <h4 className="uk-text-uppercase mb-1.5 text-xs font-medium tracking-widest text-rb-gray-300">
        {categoryName}
      </h4>
      <h3 className="mx-0 mb-3 mt-0 text-lg font-medium leading-6">{title}</h3>
      {!hideTopics && topLevelTopics.length > 0 && (
        <div className="mb-1 flex flex-wrap">
          {topLevelTopics.slice(0, 3).map((topic) => {
            return (
              <Tag
                key={topic.slug}
                onClick={(e) => {
                  visitGroupWithTopicApplied(e, groupSlug, topic.id)
                }}
                variant="light"
                text={topic.title}
                className="mb-2 mr-2"
              />
            )
          })}
          {topLevelTopics.length > 3 && (
            <Tag variant="light" className="mb-2">
              +{topLevelTopics.length - 3}
            </Tag>
          )}
        </div>
      )}
      <div style={{ marginTop: 'auto' }}>
        <GroupMembers
          variation="card"
          memberStack={memberStack}
          membersCount={membersCount}
          userIsMember={userIsMember}
        />
      </div>
    </div>
  )
}

export default GroupCard
