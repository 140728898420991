import { useNavigate } from 'react-router-dom-v5-compat'
import { twMerge } from 'tailwind-merge'

import Button from 'components/Button'

import { trackCtaClicked } from 'utils/tracking/analytics'

const ReactivateLegacySubscriptionButton = ({ className }: { className?: string }) => {
  const navigate = useNavigate()

  // Intentionally 'Manage Plan' despite component name
  const buttonText = 'Manage Plan'

  const handleClick = () => {
    trackCtaClicked({
      cta_location: 'subscription_card',
      cta_type: 'button',
      text: buttonText.toLowerCase()
    })

    navigate('/manage-plan')
  }

  return (
    <>
      <Button
        onClick={handleClick}
        size="x-small"
        shape="rounded"
        className={twMerge('h-7.5', className)}
      >
        {buttonText}
      </Button>
    </>
  )
}

export default ReactivateLegacySubscriptionButton
