import { useEffect } from 'react'

import { SEOTrackingRelatedIdentifiers } from 'domains/Seo/helpers'

import { Loading } from 'components'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import {
  ArtifactSubtopic,
  ArtifactsForSubtopic,
  useArtifactSubtopicQuery,
  useArtifactsForSubtopicQuery
} from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import ArtifactSeoCarouselSection from '../ArtifactSeoCarouselSection'

interface ArtifactTopicCarouselSectionWithQueryProps {
  subtopicSlug: string
  topicTitle: string
  onDataReceived?: ({
    subtopic,
    artifactsForSubtopic
  }: {
    subtopic: ArtifactSubtopic
    artifactsForSubtopic: ArtifactsForSubtopic
  }) => void
}

const ArtifactTopicCarouselSectionWithQuery = ({
  subtopicSlug,
  topicTitle,
  onDataReceived
}: ArtifactTopicCarouselSectionWithQueryProps) => {
  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)

  const { data: subtopicData, loading: subtopicLoading } = useArtifactSubtopicQuery({
    variables: {
      slug: subtopicSlug
    }
  })

  const { data: artifactsForSubtopicData, loading: artifactsForSubtopicLoading } =
    useArtifactsForSubtopicQuery({
      variables: {
        slug: subtopicSlug,
        max: 10
      }
    })

  const subtopic = subtopicData?.subtopic
  const artifactsForSubtopic = artifactsForSubtopicData?.artifactsForSubtopic

  useEffect(() => {
    if (subtopic && artifactsForSubtopic && onDataReceived) {
      onDataReceived({
        subtopic,
        artifactsForSubtopic
      })
    }
  }, [artifactsForSubtopic, onDataReceived, subtopic])

  if (subtopicLoading || artifactsForSubtopicLoading) {
    return (
      <div className="h-96">
        <Loading />
      </div>
    )
  }

  if (!subtopic || !artifactsForSubtopic) {
    return null
  }

  const tracking: SEOTrackingRelatedIdentifiers = {
    sanityType: 'subtopic',
    sanityId: subtopic.id || '',
    sanityName: subtopic.title.toLowerCase()
  }

  return (
    <ArtifactSeoCarouselSection
      title={subtopic.titlePlural}
      subtitle={`Check out real ${topicTitle.toLowerCase()} documents`}
      viewAllText={`View all ${
        artifactsForSubtopic?.totalCount ? `${artifactsForSubtopic?.totalCount} ` : ''
      }
              examples of ${subtopic.titlePlural.toLowerCase()} from top companies`}
      ctaText={
        isMobile
          ? 'See more examples'
          : `See more ${subtopic.title.toLowerCase()} examples`
      }
      ctaHref={`/artifacts/c/${subtopic.topic.slug}/${subtopic.slug}`}
      artifacts={artifactsForSubtopic.artifacts}
      tracking={tracking}
    />
  )
}

export default ArtifactTopicCarouselSectionWithQuery
