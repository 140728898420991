import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'

import { MarketingH2 } from 'domains/Sanity/marketingSite/textHelpers'

import Tabs from 'components/Tabs'
import { TabsType } from 'components/Tabs/Tabs'

import {
  ArtifactSubtopic,
  ArtifactTopic,
  BlogPost,
  Unit,
  useAllGuidesWithSubtopicsQuery,
  useArtifactSubtopicsWithRelatedBlogsQuery
} from 'gql'

import { sentenceCase } from 'utils/stringUtils'
import { cn } from 'utils/tailwind'
import { trackNavigationClicked } from 'utils/tracking/analytics'

const SEO_LANDING_PAGE_TYPES = ['Artifacts', 'Guides', 'Courses', 'Blogs']

/**
 * ArtifactSeoTopicTabListSection
 * @tutorial Storybook - Domains/ArtifactSeo/ArtifactSeoTopicTabList & MarketingSite/Homepage
 * FETCHES ITS OWN DATA - TOTALLY REUSABLE COMPONENT - DROP IT ANYWHERE!
 * @returns a section of Tabs (topics) that when clicked display a list of subtopic links
 * Clicking on subtopic links will navigate to its corresponding SEO landing page
 * */
export const ArtifactSeoTopicTabListSection = () => {
  // fetch all subtopics
  const { data: subtopicData, loading: subtopicsLoading } =
    useArtifactSubtopicsWithRelatedBlogsQuery({
      variables: { topicSlug: null, subtopicSlug: null }
    })
  const { data: guidesData, loading: guidesLoading } = useAllGuidesWithSubtopicsQuery()

  const [activeTab, setActiveTab] = useState('Career Development')

  const handleTabChange = async (topic: ArtifactTopic) => {
    setActiveTab(topic.title)
    trackNavigationClicked({
      location: window.location.pathname,
      text: topic.title.toLowerCase(),
      type: 'button'
    })
  }

  const topics: TabsType = []
  subtopicData?.subtopics?.forEach((subtopic) => {
    // ensure no duplicates
    if (!topics.find((topic) => subtopic.topic.title === topic.label)) {
      topics.push({
        label: subtopic.topic.title,
        isActive: activeTab.toLowerCase() === subtopic.topic.title.toLowerCase(),
        onClick: () => handleTabChange(subtopic.topic)
      })
    }
  })

  // @ts-ignore
  const tabs = topics.sort((a, b) => (a?.label > b?.label ? 1 : -1))

  const isLoading = subtopicsLoading || !tabs.length || guidesLoading

  const activeSubtopics = subtopicData?.subtopics?.filter(
    (subtopic) => subtopic.topic.title.toLowerCase() === activeTab.toLowerCase()
  )

  const activeTopic = activeSubtopics?.[0]?.topic

  const activeGuides = guidesData?.allGuidesWithSubtopics?.filter(
    (guide) => guide.topic?.title.toLowerCase() === activeTab.toLowerCase()
  )

  const activeBlogs = activeSubtopics
    // remove duplicates, null/undefined values and flatten array
    ?.flatMap((subtopic) => subtopic.relatedBlogPosts)
    .filter(
      (blog, index, self) => blog && self.findIndex((b) => b?.id === blog?.id) === index
    ) as BlogPost[]

  const trackLinkClick = (text: string) => {
    trackNavigationClicked({
      location: window.location.pathname,
      text: text.toLowerCase(),
      type: 'link'
    })
  }

  return (
    <section className="flex flex-col" data-testid="artifact-seo-topic-tab-list-section">
      <MarketingH2 className="mx-auto text-center mb-[26px] lg:mb-[30px] sm:max-w-[440px] md:max-w-none lg:max-w-[750px] xl:max-w-none">
        Relevant expertise for your hardest challenges
      </MarketingH2>

      {!isLoading && <Tabs tabs={tabs} className="mb-[50px]" />}
      {!isLoading && (
        <div className="mx-auto lg:mx-0 w-[300px] lg:w-auto lg:columns-3 gap-x-12 text-nowrap">
          {SEO_LANDING_PAGE_TYPES.map((type) => (
            <Fragment key={type}>
              <div className="mb-8 text-lg lg:text-xl leading-6 lg:leading-[1.4] font-semibold break-after-avoid">
                {type === 'Blogs' ? 'Blog' : type}
              </div>
              <ColumnContent
                type={type}
                activeTopic={activeTopic}
                activeSubtopics={activeSubtopics}
                activeGuides={activeGuides as Unit[]}
                activeBlogs={activeBlogs}
                trackLinkClick={trackLinkClick}
              />
            </Fragment>
          ))}
        </div>
      )}
    </section>
  )
}

interface ColumnContentProps {
  type: string
  activeTopic: ArtifactTopic | undefined
  activeSubtopics: ArtifactSubtopic[] | undefined
  activeGuides: Unit[] | undefined
  activeBlogs: BlogPost[]
  trackLinkClick: (text: string) => void
}

const ColumnContent = ({
  type,
  activeTopic,
  activeSubtopics,
  activeGuides,
  activeBlogs,
  trackLinkClick
}: ColumnContentProps) => {
  const linkPrefixes: { [key: string]: string } = {
    Guides: '/guides',
    Courses: '/course-categories',
    Blogs: '/blog'
  }
  const linkPrefix = linkPrefixes[type] || '/artifacts/c'
  const itemClassName =
    'block pb-2 text-base font-normal leading-[1.6rem] text-rb-gray-400 underline decoration-solid decoration-1 column overflow-hidden text-ellipsis'

  return (
    <>
      {type === 'Artifacts' &&
        activeSubtopics?.map((subtopic) => (
          <Link
            key={subtopic.slug}
            to={`${linkPrefix}${`/${activeTopic?.slug}/${subtopic.slug}`}`}
            onClick={() => trackLinkClick(subtopic.title)}
            className={itemClassName}
          >
            {sentenceCase(subtopic.title)}
          </Link>
        ))}

      {type === 'Guides' &&
        activeGuides?.map((guide) => (
          <Link
            key={guide.slug}
            to={`${linkPrefix}${`/${guide.slug}?swimlane=${activeTopic?.slug}`}`}
            onClick={() => trackLinkClick(guide?.title || '')}
            className={itemClassName}
          >
            {sentenceCase(guide?.title || '')}
          </Link>
        ))}

      {type === 'Blogs' &&
        activeBlogs.map((blog) => (
          <Link
            key={blog.id}
            to={`/blog/${blog.slug}`}
            onClick={() => trackLinkClick(blog.title)}
            className={itemClassName}
          >
            {sentenceCase(blog.title)}
          </Link>
        ))}

      {activeTopic && (
        <Link
          to={`${linkPrefix}${['Artifacts', 'Courses'].includes(type) ? `/${activeTopic.slug}` : ''}`}
          onClick={() => trackLinkClick(activeTopic.title)}
          className={cn(itemClassName, 'pb-[42px]')}
        >
          {sentenceCase(
            `See all ${['Artifacts', 'Courses'].includes(type) ? `${activeTopic.title} ` : ''}${type}`
          )}
        </Link>
      )}
    </>
  )
}

export default ArtifactSeoTopicTabListSection
