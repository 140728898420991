import { subMinutes } from 'date-fns'
import React, { Fragment, ReactNode } from 'react'
import { useHistory } from 'react-router-dom'
import UIkit from 'uikit'

import { CohortEvent } from 'domains/CohortViewer/utils'

import Button, { ButtonProps } from 'components/Button'
import CalendarDropdownMenu, {
  useCalendarDropDown
} from 'components/CalendarDropdownMenu'
import { SVGIcon } from 'components/Icon'
import AddToCalendar from 'components/cards/EventCard/AddToCalendar'
import { ChevronDownThin } from 'components/icons'
import PlayCircleBorder from 'components/icons/PlayCircleBorderIcon'

import { EventPartsFragment, useNotAttendingMutation, useRsvpMutation } from 'gql'

import {
  formatInTimezone,
  getCurrentTimezone,
  getTimezoneAbbreviation,
  isBetweenDates
} from 'utils/date'
import notifyError from 'utils/errorNotifier'
import sendEventTrackingEvent from 'utils/tracking/event'

export interface MultiTimeAttendButtonProps {
  dataTestId?: string
  event: CohortEvent
  hasTooltip?: boolean
  rsvpCallback: (event: CohortEvent) => void
  timezone?: string | null
  toggleTooltip?: (arg0: boolean) => void
  tooltipRef?: any
  eventUrlOverride?: string
  goingOrAttendCta?: ReactNode
  rsvpButtonProps?: Omit<ButtonProps, 'children'>
  joinNowButtonProps?: Omit<ButtonProps, 'children'>
  replayButtonProps?: Omit<ButtonProps, 'children'>
  threeDotIconFill?: string
}

export const MultiTimeAttendButton = ({
  dataTestId = 'MultiTimeAttendButton',
  event,
  hasTooltip,
  timezone,
  rsvpCallback,
  toggleTooltip = () => {},
  tooltipRef,
  eventUrlOverride,
  goingOrAttendCta,
  rsvpButtonProps,
  joinNowButtonProps,
  replayButtonProps,
  threeDotIconFill
}: MultiTimeAttendButtonProps) => {
  const { isAddToCalendarOpen, setIsAddToCalendarOpen } = useCalendarDropDown()
  const [createRsvp] = useRsvpMutation()
  const [notAttending] = useNotAttendingMutation()
  const history = useHistory()

  const eventOneRsvp = Boolean(
    event.sameEventInfo.find((eventInfo) => eventInfo.attendingStatus === 'Attending')
  )
  const allEventsPast = event.sameEventInfo.every((eventInfo) => eventInfo.past)
  const eventRecordingAvailable = Boolean(
    event.sameEventInfo.find((eventInfo) => eventInfo.summaryWistiaCode)
  )
  const currentTimezone = getCurrentTimezone(timezone)
  const sameEventInfo = event.sameEventInfo.find(
    (sameEvent) => sameEvent.attendingStatus === 'Attending' && !sameEvent.past
  )
  const rsvpedEventInfo = sameEventInfo && { ...sameEventInfo, name: event.name }

  const eventIsInSession =
    Boolean(
      event.sameEventInfo.find((eventInfo) => {
        return (
          (eventInfo.attendingStatus === 'Attending' ||
            eventInfo.attendingStatus === 'Attended') &&
          isBetweenDates(
            subMinutes(Date.parse(eventInfo.startsAtUtc), 5).toUTCString(),
            eventInfo.endsAtUtc
          )
        )
      })
    ) ||
    (event.sameEventInfo.every(
      (sameEvent) =>
        !sameEvent.attendingStatus || sameEvent.attendingStatus === 'Not Attending'
    ) &&
      event.sameEventInfo.some((sameEvent) =>
        isBetweenDates(
          subMinutes(Date.parse(sameEvent.startsAtUtc), 5).toUTCString(),
          sameEvent.endsAtUtc
        )
      ))

  const attendApiCall = async (id: string) => {
    try {
      const { data } = await createRsvp({
        variables: {
          input: {
            id,
            timezone: currentTimezone,
            happening: eventIsInSession
          }
        }
      })

      if (data?.rsvp?.errors?.length) {
        const errors = data.rsvp.errors
        UIkit.notification({
          message: `<span uk-icon="icon: warning"></span> ${errors[0]}`,
          status: 'warning',
          pos: 'top-right'
        })

        notifyError(errors)
      }
    } catch (e) {
      notifyError(e)
    }
  }

  const notAttendApiCall = async (id: string) => {
    try {
      const { data } = await notAttending({
        variables: { input: { id } }
      })

      if (data?.notAttending?.errors?.length) {
        const errors = data.notAttending.errors
        UIkit.notification({
          message: `<span uk-icon="icon: warning"></span> ${errors[0]}`,
          status: 'warning',
          pos: 'top-right'
        })

        notifyError(errors)
      }
    } catch (err) {
      return notifyError(err)
    }
  }

  const rsvpYesOrAttend = (
    e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement>,
    attendEventInfo: Pick<EventPartsFragment, 'id'> & Partial<EventPartsFragment>
  ) => {
    e.stopPropagation()

    sendEventTrackingEvent('attend_click', attendEventInfo, currentTimezone)

    if (!eventIsInSession) {
      window.UIkit.dropdown(`#event-card-dropdown-${event.id}`).hide(0)
    }

    const newEvent = { ...event }
    newEvent.sameEventInfo.forEach((eventInfo, i) => {
      if (eventInfo.id === attendEventInfo.id) {
        attendApiCall(eventInfo.id)
        newEvent.sameEventInfo[i].attendingStatus = 'Attending'
      } else {
        notAttendApiCall(eventInfo.id)
        newEvent.sameEventInfo[i].attendingStatus = 'Not Attending'
      }
    })
    setIsAddToCalendarOpen(true)
    rsvpCallback(newEvent)
  }

  const rsvpNo = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    sendEventTrackingEvent(
      'cant_make_it_click',
      event as Pick<EventPartsFragment, 'id'> & Partial<EventPartsFragment>,
      currentTimezone
    )

    window.UIkit.dropdown(`#event-card-dropdown-${event.id}`).hide(0)
    const newEvent = { ...event }
    newEvent.sameEventInfo.forEach((eventInfo, i) => {
      notAttendApiCall(eventInfo.id)
      newEvent.sameEventInfo[i].attendingStatus = 'Not Attending'
    })
    setIsAddToCalendarOpen(false)
    rsvpCallback(newEvent)
  }

  const getEventDetailLink = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    let eventToVisit = event

    if (event.sameEventInfo) {
      const eventRsvped = event.sameEventInfo.find(
        (eventInfo) => eventInfo.attendingStatus === 'Attending'
      )

      const nonePastEvent = event.sameEventInfo.find((eventInfo) => !eventInfo.past)

      if (eventRsvped) {
        eventToVisit = eventRsvped as CohortEvent
      } else if (nonePastEvent) {
        eventToVisit = nonePastEvent as CohortEvent
      }
    }

    sendEventTrackingEvent(
      'view_event_details_click',
      eventToVisit as Pick<EventPartsFragment, 'id'> & Partial<EventPartsFragment>,
      currentTimezone
    )

    if (eventUrlOverride) {
      history.push(`${eventUrlOverride}`)
    } else {
      window.open(`/events/${eventToVisit.id}-${eventToVisit.slug}`)
    }
  }

  const onWatchRecordingClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    if (eventUrlOverride) {
      history.push(`${eventUrlOverride}/summary`)
    } else {
      window.open(`/events/${event.id}-${event.slug}/summary`)
    }
  }

  const addToCalendarHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setIsAddToCalendarOpen(true)
  }

  return (
    <div className="relative">
      <div
        ref={hasTooltip ? tooltipRef : null}
        data-tip={hasTooltip ? String(event.id) : null}
        onClick={() => toggleTooltip(false)}
        role="button"
        tabIndex={0}
        data-test={`${dataTestId}-cta-container`}
        data-testid={`${dataTestId}-cta-container`}
      >
        <JoinCTA
          allEventsPast={allEventsPast}
          dataTestId={dataTestId}
          event={event}
          isAttendingEvent={eventOneRsvp}
          timezone={currentTimezone}
          eventIsInSession={eventIsInSession}
          rsvpYesOrAttend={rsvpYesOrAttend}
          eventRecordingAvailable={eventRecordingAvailable}
          goingOrAttendCta={goingOrAttendCta}
          rsvpButtonProps={rsvpButtonProps}
          threeDotIconFill={threeDotIconFill}
          joinNowButtonProps={joinNowButtonProps}
          replayButtonProps={replayButtonProps}
        />
      </div>

      {!eventIsInSession && !isAddToCalendarOpen && (
        <div
          id={`event-card-dropdown-${event.id}`}
          className="bg-dl-card-primary-background z-10 p-0 text-rb-gray-400"
          uk-dropdown={'mode: click; pos: bottom-center; offset: 25; flip: false;'}
        >
          {rsvpedEventInfo && !allEventsPast && (
            <div
              className="bg-dl-card-primary-background z-10 cursor-pointer px-5 py-[13px] pr-[5px] pl-[15px] text-rb-gray-400 hover:bg-rb-gray-100 hover:text-rb-teal-200"
              onClick={addToCalendarHandler}
              role="button"
              tabIndex={0}
            >
              Add to calendar
            </div>
          )}

          {eventRecordingAvailable && (
            <div
              className="bg-dl-card-primary-background z-10 cursor-pointer px-5 py-[13px] pr-[5px] pl-[15px] text-rb-gray-400 hover:bg-rb-gray-100 hover:text-rb-teal-200"
              onClick={onWatchRecordingClick}
              role="button"
              tabIndex={0}
            >
              Watch recording
            </div>
          )}

          <div
            className="bg-dl-card-primary-background z-10 cursor-pointer px-5 py-[13px] pr-[5px] pl-[15px] text-rb-gray-400 hover:bg-rb-gray-100 hover:text-rb-teal-200"
            onClick={getEventDetailLink}
            role="button"
            tabIndex={0}
          >
            View event details
          </div>

          {!allEventsPast &&
            event.sameEventInfo.map((eventInfo, i) => (
              <Fragment key={`attend_${i}`}>
                {eventOneRsvp &&
                  eventInfo.attendingStatus !== 'Attending' &&
                  !eventInfo.past && (
                    <div
                      className="prevent-default bg-dl-card-primary-background z-10 cursor-pointer px-5 py-[13px] pr-[5px] pl-[15px] text-rb-gray-400 hover:bg-rb-gray-100 hover:text-rb-teal-200"
                      onClick={(e: React.MouseEvent<HTMLInputElement>) =>
                        rsvpYesOrAttend(e, eventInfo)
                      }
                    >
                      Switch to{' '}
                      {formatInTimezone(eventInfo.startsAtUtc, timezone, 'h:mm a - ')}
                      {formatInTimezone(eventInfo.endsAtUtc, timezone, 'h:mm a ')}(
                      {getTimezoneAbbreviation(timezone, eventInfo.startsAtUtc)}) Session
                    </div>
                  )}
                {!eventOneRsvp && !eventInfo.past && (
                  <div
                    className="prevent-default bg-dl-card-primary-background z-10 cursor-pointer px-5 py-[13px] pr-[5px] pl-[15px] text-rb-gray-400 hover:bg-rb-gray-100 hover:text-rb-teal-200"
                    onClick={(e: React.MouseEvent<HTMLInputElement>) =>
                      rsvpYesOrAttend(e, eventInfo)
                    }
                    data-test={`${dataTestId}-event-time-${i}`}
                  >
                    {formatInTimezone(eventInfo.startsAtUtc, timezone, 'h:mm a - ')}
                    {formatInTimezone(eventInfo.endsAtUtc, timezone, 'h:mm a ')}(
                    {getTimezoneAbbreviation(timezone, eventInfo.startsAtUtc)}) Session
                  </div>
                )}
              </Fragment>
            ))}

          {!allEventsPast && sameEventInfo && (
            <div
              className="prevent-default bg-dl-card-primary-background z-10 cursor-pointer px-5 py-[13px] pr-[5px] pl-[15px] text-rb-gray-400 hover:bg-rb-gray-100 hover:text-rb-teal-200"
              onClick={rsvpNo}
              role="button"
              tabIndex={0}
            >
              Can&apos;t make it
            </div>
          )}
        </div>
      )}

      {isAddToCalendarOpen && (
        <CalendarDropdownMenu
          containerClassName="absolute top-[32px] bg-white z-10"
          offset={window.innerWidth < 600 ? 'right-0' : ''}
          onClose={() => {
            setIsAddToCalendarOpen(false)
          }}
        >
          <AddToCalendar
            event={rsvpedEventInfo as EventPartsFragment}
            timezone={timezone}
          />
        </CalendarDropdownMenu>
      )}
    </div>
  )
}

interface JoinCTAProps {
  allEventsPast: boolean
  event: CohortEvent
  isAttendingEvent: boolean
  eventIsInSession: boolean
  rsvpYesOrAttend: (
    e: React.MouseEvent<HTMLButtonElement>,
    attendEventInfo: Pick<EventPartsFragment, 'id'> & Partial<EventPartsFragment>
  ) => any
  timezone: string
  eventRecordingAvailable: boolean
  dataTestId: string
  goingOrAttendCta: ReactNode
  rsvpButtonProps?: Omit<ButtonProps, 'children'>
  joinNowButtonProps?: Omit<ButtonProps, 'children'>
  replayButtonProps?: Omit<ButtonProps, 'children'>
  threeDotIconFill?: string
}

const JoinCTA = ({
  allEventsPast,
  event,
  isAttendingEvent,
  eventIsInSession,
  rsvpYesOrAttend,
  timezone,
  eventRecordingAvailable,
  dataTestId,
  goingOrAttendCta,
  rsvpButtonProps,
  threeDotIconFill,
  joinNowButtonProps,
  replayButtonProps
}: JoinCTAProps) => {
  const handleClickDuringEvent = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    const eventAttending = event.sameEventInfo.find((eventInfo) => {
      return isBetweenDates(
        subMinutes(Date.parse(eventInfo.startsAtUtc), 10).toUTCString(),
        eventInfo.endsAtUtc
      )
    })
    sendEventTrackingEvent(
      'join_now_click',
      eventAttending as Pick<EventPartsFragment, 'id'> & Partial<EventPartsFragment>,
      timezone
    )

    if (!isAttendingEvent) {
      await rsvpYesOrAttend(
        e,
        eventAttending as Pick<EventPartsFragment, 'id'> & Partial<EventPartsFragment>
      )
    }

    eventAttending && window.open(`/events/links/${eventAttending.uuid}`)
  }

  if (eventIsInSession) {
    return (
      <Button
        className="h-8 w-32"
        variant="outline"
        size="x-small"
        shape="rounded-full"
        color="teal"
        dataTest={`${dataTestId}-rsvp-cta-button`}
        onClick={handleClickDuringEvent}
        {...joinNowButtonProps}
      >
        Join Now
      </Button>
    )
  }

  if (allEventsPast && eventRecordingAvailable) {
    return (
      <Button
        className="h-8 w-32"
        variant="fill"
        size="x-small"
        shape="rounded-full"
        color="teal"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => e.stopPropagation()}
        {...replayButtonProps}
      >
        <PlayCircleBorder className="mr-3 h-4 w-4 fill-current" />
        Replay
      </Button>
    )
  }

  const showGoingOrRsvp = () => {
    const notPastAttendingEvent = event.sameEventInfo.some(
      (sameEvent) => !sameEvent.past && sameEvent.attendingStatus === 'Attending'
    )

    const notRsvpedToAnyEvent = event.sameEventInfo.every(
      (sameEvent) =>
        !sameEvent.attendingStatus || sameEvent.attendingStatus === 'Not Attending'
    )

    const allEventsPast = event.sameEventInfo.every((sameEvent) => sameEvent.past)

    return (notPastAttendingEvent || notRsvpedToAnyEvent) && !allEventsPast
  }

  const rsvpButton = goingOrAttendCta ? (
    <div
      onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => e.stopPropagation()}
      tabIndex={0}
      role="button"
    >
      {goingOrAttendCta}
    </div>
  ) : (
    <Button
      variant={isAttendingEvent ? 'outline' : 'fill'}
      size="x-small"
      shape="rounded-full"
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => e.stopPropagation()}
      className="h-8 w-32"
      dataTest={`${dataTestId}-rsvp-cta-button`}
      {...rsvpButtonProps}
    >
      {isAttendingEvent ? (
        <>
          <span>Going</span>
          <ChevronDownThin className="ml-2 h-4 w-3 fill-current" />
        </>
      ) : (
        <>
          <span>Attend</span>
          <ChevronDownThin className="ml-2 h-4 w-3 fill-current" />
        </>
      )}
    </Button>
  )

  return showGoingOrRsvp() ? (
    rsvpButton
  ) : (
    <div
      className="prevent-default hover-rb-blue-100 cursor-pointer"
      onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
    >
      <SVGIcon
        id="event-card-past-button"
        name="three-dots"
        className="rf-icon--stroke"
        fill={threeDotIconFill || '#000000'}
      />
    </div>
  )
}

export default MultiTimeAttendButton
