export const ctaClickedLocation = (path: string) => {
  if (path === '/') {
    return 'homepage event card'
  } else if (path === '/events') {
    return 'events_index event card'
  } else if (path.match(/\/events\/:id-:slug*/)) {
    return 'event_details_page'
  } else if (path.match(/\/directory\/:userId*/)) {
    return 'member_profile event card'
  } else if (path.match(/\/programs*/)) {
    return 'program_page event card'
  } else {
    return 'event card'
  }
}

export function getSizeClasses(size: string) {
  if (size === 'small') return 'px-2.5 py-1.5'
  if (size === 'large') return 'py-[10px] px-7'
}
