import { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { ErrorMessage } from 'components'
import { useModal } from 'components/Modal'

import { EventPartsFragment, useUserStartingSoonEventsQuery } from 'gql'

import { getPageFromPath } from 'utils/location'
import { trackModalDisplayed } from 'utils/tracking/analytics'

import RealTimeEventReminderModal from './RealTimeEventReminderModal'

const RealTimeEventReminder = () => {
  const { isModalOpen, setIsModalOpen } = useModal()
  const { pathname } = useLocation()
  const [isEventCountdownPage] = useState(pathname.startsWith('/events/links/'))

  const { data, error } = useUserStartingSoonEventsQuery({
    pollInterval: 600000
  })

  const event = data?.currentUser?.eventsAttendingStartingSoon[0]

  useMemo(() => {
    if (!event) return
    const modalClosedByUser = window.localStorage.getItem(
      `real-time-modal-dismissed-${event.id}`
    )

    const handleModalOpen = (event: EventPartsFragment) => {
      setIsModalOpen(true)

      trackModalDisplayed({
        category: 'app',
        location: getPageFromPath(),
        modal_group: 'events',
        modal_name: 'live event starting',
        related_identifiers: {
          event_id: event.id,
          event_name: event.name,
          event_kind: event.kind,
          cms_program_id: event.cmsProgramOptional?.id,
          cms_program_name: event.cmsProgramOptional?.name
        }
      })
    }

    modalClosedByUser === 'dismissed' ? setIsModalOpen(false) : handleModalOpen(event)
  }, [event, setIsModalOpen])

  if (error) {
    return <ErrorMessage error={error} />
  }

  return (
    <>
      {isModalOpen && event && !isEventCountdownPage ? (
        <RealTimeEventReminderModal
          upcomingEvent={event}
          isModalOpen={isModalOpen}
          setIsModalClose={() => setIsModalOpen(false)}
          data-testid="event-reminder-modal"
        />
      ) : (
        <></>
      )}
    </>
  )
}

export default RealTimeEventReminder
