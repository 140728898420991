import { Tag } from 'components/Tag'

export const Title = ({
  text,
  savingsTagContent
}: {
  text: string
  savingsTagContent?: string
}) => (
  <h3 className="mb-1 flex text-base font-medium text-rb-black">
    {text}
    {savingsTagContent && (
      <Tag variant="green" className="ml-2 bg-rb-green-50 text-rb-black text-opacity-85">
        {savingsTagContent}
      </Tag>
    )}
  </h3>
)
