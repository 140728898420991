import CoursesList from './CoursesList'

export const CoursesIndexPage = () => {
  return (
    <div className="flex min-w-[311px] flex-col space-y-8">
      <CoursesList />
    </div>
  )
}

export default CoursesIndexPage
