import { CurrentUserPartsFragment } from 'gql'

export const handleNavigation = (currentUser: CurrentUserPartsFragment) => {
  const queryParams = new URLSearchParams(window.location.search)
  const refererOrFwd = queryParams.get('referer') || queryParams.get('fwd')
  const teamJoinParam = queryParams.get('joined-team')
  const courseType = queryParams.get('courseType')
  const cmsProgramSlug = queryParams.get('cmsProgramSlug')

  if (refererOrFwd) {
    let redirect = getRedirectFromReferer(refererOrFwd, courseType, cmsProgramSlug)
    if (teamJoinParam) {
      redirect += redirect.includes('?')
        ? '&joined-team=true'
        : redirect.endsWith('/')
          ? '?joined-team=true'
          : '/?joined-team=true'
    }
    return `/?redirect=${encodeURIComponent(redirect)}`
  } else {
    if (
      currentUser.is.banned ||
      currentUser.is.planManager ||
      currentUser.is.noAccess ||
      currentUser.is.copyHold
    ) {
      return '/account'
    }

    return `/${teamJoinParam ? '?joined-team=true' : ''}`
  }
}

const getRedirectFromReferer = (
  referer: string,
  courseType: string | null,
  cmsProgramSlug: string | null
) => {
  const redirectToArtifacts = /\/artifacts\/c\/\S*/.test(referer)
  const redirectToReferer =
    /^\/artifacts\/?\S*/.test(referer) ||
    /^\/teams\/get-in-touch\S*/.test(referer) ||
    /^\/profiles\/?\S*/.test(referer)
  const redirectToCourseDetails = /\/courses\/\S*/.test(referer)
  const redirectToCourses = /\/course\S*/.test(referer)
  const redirectToUpgrade =
    /^\/pricing\S*/.test(referer) ||
    /^\/teams\S*/.test(referer) ||
    /^\/membership\S*/.test(referer)

  if (redirectToArtifacts) {
    return '/artifacts'
  }

  if (redirectToReferer) {
    return referer
  }

  if (redirectToCourseDetails) {
    if (courseType === 'marketplace') {
      return `${referer}/details`
    }
    if (courseType === 'legacy') {
      return `/programs/${cmsProgramSlug}`
    }
  }

  if (redirectToCourses) {
    return '/courses'
  }

  if (redirectToUpgrade) {
    return '/upgrade'
  }

  return '/'
}
