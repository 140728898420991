import ContributorLevelsTable from './ContributorLevelsTable'

export default function JoinExpertCommunitySection({
  typeformUrl
}: {
  typeformUrl: string
}) {
  return (
    <div>
      <h2 className="text-6xl font-normal">Join the Reforge expert community</h2>
      <div className="mb-12 text-3xl">
        Share your expertise on Reforge to unlock more career opportunities.
      </div>
      <ContributorLevelsTable typeformUrl={typeformUrl} />
    </div>
  )
}
