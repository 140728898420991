import { ChangeEventHandler, Dispatch, FormEventHandler, SetStateAction } from 'react'

import Filters, { FilterOption, FiltersProp } from 'components/Filters'

import FilterInput from './FilterInput'
import { BookmarkFilterKey } from './useBookmarkFilters'

export type SortOption = string

export type SortOptionSelection = {
  [filter: string]: SortOption
}
interface BookmarksFilterSidebarProps {
  activeFilterCount?: number
  className?: string
  filterKeys: readonly BookmarkFilterKey[]
  filterOptions: FilterOption[]
  filters: FiltersProp
  handleSearchSubmit: FormEventHandler<HTMLFormElement>
  handleSearchTextChanged: ChangeEventHandler<HTMLInputElement>
  searchText: string
  setFilters: Dispatch<SetStateAction<FiltersProp>>
}

const BookmarksFilterSidebar = ({
  activeFilterCount = 0,
  className = '',
  filterKeys,
  filterOptions,
  filters,
  handleSearchSubmit,
  handleSearchTextChanged,
  searchText,
  setFilters
}: BookmarksFilterSidebarProps) => (
  <div id="filters-with-keyword-placeholder" className={className}>
    <div id="filters-with-keyword">
      <div className="mb-4 flex h-10 items-center">
        <div className="text-sm font-medium uppercase text-rb-gray-500">Filters</div>
        {activeFilterCount > 0 && (
          <div className="ml-2.5 h-4 w-4 rounded bg-rb-gray-500 text-center text-xs font-medium text-white">
            {activeFilterCount}
          </div>
        )}
      </div>
      <div className="mb-0 lg:mb-4">
        <FilterInput
          handleSearchSubmit={handleSearchSubmit}
          searchText={searchText}
          handleSearchTextChanged={handleSearchTextChanged}
        />
      </div>

      <Filters
        filterKeys={filterKeys as string[]}
        filterOptions={filterOptions}
        filters={filters}
        setFilters={setFilters}
        startOpen={true}
        headerText=""
      />
    </div>
  </div>
)

export default BookmarksFilterSidebar
