import React from 'react'
import { twMerge } from 'tailwind-merge'

interface FacePileContainerProps {
  children: React.ReactNode
  className?: string
}

const FacePileContainer = ({ children, className }: FacePileContainerProps) => (
  <div className={twMerge('flex items-center outline-none sm:py-1', className)}>
    {children}
  </div>
)

export default FacePileContainer
