import { useStripe } from '@stripe/react-stripe-js'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ErrorMessage, Loading } from 'components'
import { Alert } from 'components/Alert'
import Button from 'components/Button'
import OrderRecapLabel from 'components/CheckoutModal/OrderRecap/components/OrderRecapLabel'
import OrderRecapPrice from 'components/CheckoutModal/OrderRecap/components/OrderRecapPrice'
import { TermsAndConditions } from 'components/CheckoutModal/OrderRecap/components/TermsAndConditions'
import { SectionBorder } from 'components/CheckoutModal/components/SectionBorder'
import { getSalesTaxLabelDetails, useTaxInfo } from 'components/CheckoutModal/useTaxInfo'
import {
  ProductItemCodes,
  calculateOrderTotal,
  calculateTaxPrice
} from 'components/CheckoutModal/utils'

import {
  TaxIdReverseChargeStatus,
  useCheckoutMutation,
  useCreateRailsSubscriptionMutation,
  useStartingBalanceQuery
} from 'gql'

import { cn } from 'utils/tailwind'
import { trackCtaClicked } from 'utils/tracking/analytics'

import { OrderRecapItems, OrderRecapItemsProps } from '../OrderRecapSection'
import OrderRecapFreeTrialFooter from '../components/OrderRecapFreeTrialFooter'
import OrderRecapFreeTrialGroup from '../components/OrderRecapFreeTrialGroup'
import { OrderRecapItemGroup } from '../components/OrderRecapItemGroup'
import { usePurchaseFlowContext } from '../contexts/PurchaseFlowContext'

export const PurchaseConfirmationPageWrapper = () => {
  const {
    planName,
    planPrice,
    planSeatCount,
    cohortPassQuantity,
    cohortPassPrice,
    discountedCohortPassPrice,
    discountedCohortPassQuantity,
    selectedPaymentMethodId,
    subTotal,
    setOrderTotal,
    setTaxAmount,
    setError,
    isFreeTrialPurchase,
    error
  } = usePurchaseFlowContext()
  const history = useHistory()
  const stripe = useStripe()

  const { taxInfo, taxInfoLoading, refetchTaxInfo, taxIdReverseChargeStatus } =
    useTaxInfo({
      productKeys: [
        ProductItemCodes.COHORT_PASS_ITEM_CODE,
        ProductItemCodes.SUBSCRIPTION_ITEM_CODE
      ],
      onTaxIdVerified: useCallback(() => {
        refetchTaxInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
    })

  const { data: startingBalance, loading: startingBalanceLoading } =
    useStartingBalanceQuery({ fetchPolicy: 'network-only' })

  const subscriptionTaxInfo = taxInfo?.find(
    (item) => item.productItemCode === ProductItemCodes.SUBSCRIPTION_ITEM_CODE
  )

  const cohortPassTaxInfo = taxInfo?.find(
    (item) => item.productItemCode === ProductItemCodes.COHORT_PASS_ITEM_CODE
  )

  const startingBalancePrice = (startingBalance?.startingBalance || 0) / 100
  const subscriptionTaxPrice = calculateTaxPrice({
    price: planPrice,
    taxItem: subscriptionTaxInfo
  })

  const cohortPassSubtotalPrice =
    cohortPassPrice * cohortPassQuantity +
    discountedCohortPassPrice * discountedCohortPassQuantity
  const cohortPassTaxPrice = calculateTaxPrice({
    price: cohortPassSubtotalPrice,
    taxItem: cohortPassTaxInfo
  })

  const taxTotal = subscriptionTaxPrice + cohortPassTaxPrice

  useEffect(() => {
    setTaxAmount(taxTotal)
  }, [taxTotal, setTaxAmount])

  const total = calculateOrderTotal({
    subTotal,
    taxTotal,
    startingBalance: startingBalancePrice
  })

  useEffect(() => {
    setOrderTotal(total)
  }, [total, setOrderTotal])

  const [customerActionRequired, setCustomerActionRequired] = useState(false)

  const [checkoutPurchase, { loading: checkoutPurchaseLoading }] = useCheckoutMutation({
    onCompleted: (response) => {
      if (response.checkout?.success) {
        history.push('/subscribe/success')
      } else {
        const error = response.checkout?.errors?.[0]
        if (error === 'requires_action') {
          setCustomerActionRequired(true)
          handlePaymentThatRequiresCustomerAction({
            clientSecret: response.checkout?.clientSecret || undefined,
            stripeSubscriptionId: response.checkout?.stripeSubscriptionId || undefined
          })
        } else {
          handleGenericError(error)
        }
      }
    },
    onError: (error: any) => {
      if (error.message === 'Card declined: Your card was declined.') {
        setError(
          'Card declined: Your payment method failed. Please add a new card or contact your bank and then try again.'
        )
        console.log('Error purchasing subscription', error)
      } else {
        handleGenericError(error)
      }
    }
  })

  const handleGenericError = (error: any) => {
    setError('Error purchasing subscription. Please contact support')
    console.log('Error purchasing subscription', error)
  }

  const [createRailsSubscription, { loading: createRailsSubscriptionLoading }] =
    useCreateRailsSubscriptionMutation()

  const handlePaymentThatRequiresCustomerAction = async ({
    clientSecret,
    stripeSubscriptionId
  }: {
    clientSecret?: string
    stripeSubscriptionId?: string
  }) => {
    if (!clientSecret) {
      return
    }

    const confirmCardResult = await stripe?.confirmCardPayment(clientSecret, {
      payment_method: selectedPaymentMethodId || ''
    })

    if (confirmCardResult?.error) {
      // start code flow to handle updating the payment details
      // Display error message in your UI.
      // The card was declined (i.e. insufficient funds, card has expired, etc)
      setCustomerActionRequired(false)

      if (confirmCardResult?.error.code === 'lock_timeout') {
        return setError(
          'Payment processing failed. Please wait a few seconds and try again.'
        )
      }

      setError(confirmCardResult.error.message || null)
    } else if (
      confirmCardResult?.paymentIntent?.status === 'succeeded' &&
      stripeSubscriptionId
    ) {
      // TODO: There's a risk of the customer closing the window before callback
      // execution. To handle this case, set up a webhook endpoint and
      // listen to invoice.payment_succeeded. This webhook endpoint
      // returns an Invoice.
      setCustomerActionRequired(false)
      const response = await createRailsSubscription({
        variables: {
          input: {
            stripeSubscriptionId
          }
        },
        onError: (error: any) => handleGenericError(error)
      })

      const error = response.data?.createRailsSubscription?.errors?.[0]

      if (error) {
        setError(error || null)
        return
      }

      history.push('/subscribe/success')
    }
  }

  if (taxInfoLoading || startingBalanceLoading) {
    return <Loading className="mt-12" />
  }

  if (!selectedPaymentMethodId) {
    return <ErrorMessage error="Please go back and select a payment method" />
  }

  const handlePurchaseClick = async () => {
    trackCtaClicked({
      cta_location: 'review_purchase_page',
      text: 'complete purchase',
      cta_type: 'button'
    })

    setError(null)

    checkoutPurchase({
      variables: {
        input: {
          paymentMethodId: selectedPaymentMethodId,
          planName: planName,
          numCohortPasses: cohortPassQuantity
        }
      }
    })
  }

  const cohortPassLabel = 'Live Course Pass'
  const cohortPassSubTotal = cohortPassQuantity * cohortPassPrice
  const loading =
    checkoutPurchaseLoading || customerActionRequired || createRailsSubscriptionLoading

  return (
    <PurchaseConfirmation
      planName={planName}
      planPrice={planPrice}
      planSeatCount={planSeatCount}
      cohortPassQuantity={cohortPassQuantity}
      cohortPassPrice={cohortPassPrice}
      cohortPassSubTotal={cohortPassSubTotal}
      cohortPassLabel={cohortPassLabel}
      discountedCohortPassPrice={discountedCohortPassPrice}
      discountedCohortPassQuantity={discountedCohortPassQuantity}
      subTotal={subTotal}
      taxTotal={taxTotal}
      startingBalance={startingBalancePrice}
      error={error}
      onPurchaseCtaClick={handlePurchaseClick}
      purchaseInProgress={loading}
      taxIdReverseChargeStatus={taxIdReverseChargeStatus}
      refetchTaxInfo={refetchTaxInfo}
      total={total}
      isFreeTrialPurchase={isFreeTrialPurchase}
    />
  )
}

export interface PurchaseConfirmationPageProps extends OrderRecapItemsProps {
  cohortPassQuantity: number
  subTotal: number
  taxTotal: number
  error: string | null
  onPurchaseCtaClick: () => void
  purchaseInProgress: boolean
  taxIdReverseChargeStatus?: TaxIdReverseChargeStatus
  refetchTaxInfo: () => void
  startingBalance: number
  total: number
  isFreeTrialPurchase: boolean
}

const Divider = ({ className }: { className?: string }) => (
  <div className={cn('my-4 border-t border-rb-gray-100', className)} />
)

export const PurchaseConfirmation = ({
  planName,
  planPrice,
  planSeatCount,
  cohortPassQuantity,
  cohortPassPrice,
  cohortPassSubTotal,
  cohortPassLabel,
  discountedCohortPassPrice,
  discountedCohortPassQuantity,
  subTotal,
  taxTotal,
  error,
  onPurchaseCtaClick,
  purchaseInProgress,
  taxIdReverseChargeStatus,
  refetchTaxInfo,
  startingBalance,
  total,
  isFreeTrialPurchase
}: PurchaseConfirmationPageProps) => {
  return (
    <div className="flex flex-col items-center justify-center py-12 px-4 mx-auto max-w-[420px]">
      <div className="mb-4 text-xl">Let’s make sure everything looks right</div>
      <SectionBorder className="flex w-full flex-col">
        <div className="flex flex-col">
          <OrderRecapItems
            planName={planName}
            planPrice={planPrice}
            planSeatCount={planSeatCount}
            cohortPassPrice={cohortPassPrice}
            cohortPassSubTotal={cohortPassSubTotal}
            cohortPassLabel={cohortPassLabel}
            cohortPassQuantity={cohortPassQuantity}
            discountedCohortPassPrice={discountedCohortPassPrice}
            discountedCohortPassQuantity={discountedCohortPassQuantity}
          />

          {isFreeTrialPurchase ? (
            <>
              <OrderRecapItemGroup className="mt-4">
                <OrderRecapLabel
                  label="Sales Tax"
                  details={getSalesTaxLabelDetails(
                    taxIdReverseChargeStatus,
                    refetchTaxInfo
                  )}
                />
                <OrderRecapPrice price={taxTotal} />

                {startingBalance ? (
                  <>
                    <OrderRecapLabel label="Account Credit" />
                    <OrderRecapPrice price={startingBalance} />
                  </>
                ) : null}

                <Divider className="col-span-2 my-1.5" />

                <OrderRecapFreeTrialGroup subTotal={total} showDueOnDate />
              </OrderRecapItemGroup>
            </>
          ) : (
            <>
              <Divider />
              <OrderRecapItemGroup>
                <OrderRecapLabel label="Subtotal" />
                <OrderRecapPrice price={subTotal} />

                <OrderRecapLabel
                  label="Sales Tax"
                  details={getSalesTaxLabelDetails(
                    taxIdReverseChargeStatus,
                    refetchTaxInfo
                  )}
                />
                <OrderRecapPrice price={taxTotal} />

                {startingBalance ? (
                  <>
                    <OrderRecapLabel label="Account Credit" />
                    <OrderRecapPrice price={startingBalance} />
                  </>
                ) : null}
              </OrderRecapItemGroup>
              <Divider />
              <div className="flex flex-row justify-between pt-4 text-xl font-semibold">
                <OrderRecapLabel label="Total" className="text-xl" />
                <OrderRecapPrice price={total} />
              </div>
            </>
          )}
        </div>
        <Button
          className="mt-6 h-12 w-full"
          onClick={onPurchaseCtaClick}
          isLoadingSpinner={purchaseInProgress}
          data-test="purchase-button"
        >
          Complete Purchase
        </Button>
        <Alert className="mt-5">{error}</Alert>
        <TermsAndConditions collapsible hasPlan />
      </SectionBorder>

      {isFreeTrialPurchase && <OrderRecapFreeTrialFooter />}
    </div>
  )
}
