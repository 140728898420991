/* eslint-disable */
// This file was automatically generated using yarn segment:generate:prod.
// DO NOT MODIFY IT BY HAND. Instead, update segment tracking protocol,
// and run yarn segment:generate to regenerate this file.

import { FollowAction } from '../types'
import { track } from 'utils/tracking/segment'

export function trackFollowAction(event: FollowAction) {
  track('Follow - Action', event)
}
