import Display from '../components/Display'

interface BlockProps {
  value?: any // @todo: define type
  children?: any // @todo: define type
}

export default {
  display: ({ value: { children } }: BlockProps) => <Display>{children}</Display>
}
