import { Component, KeyboardEvent } from 'react'
import ReactDOM from 'react-dom'
import { renderToString } from 'react-dom/server'

import BookmarkNoteForm from 'domains/Cms/BookmarkNoteForm'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'

import { BookmarkNoteFormPartsFragment, ProgramBookmarkPartsFragment } from 'gql'

import { isMobileDevice } from 'utils/device.utils'
import notifyError from 'utils/errorNotifier'
import { track } from 'utils/tracking/segment'

import { WithInlinePostsProps, withInlinePostsHook } from '../CommentsBlock/helpers'

export interface CommentsBlockProps {
  synopsis: string
  cmsSectionId?: string
  elementId: string
  referenceImageUrl?: string
  userId?: string
  cmsModuleId?: string
  cmsProgramId?: string
  bookmarksByAnchor: any
  inlinePostAnchors: string[]
  accessPolicyKind: string
  hasApplied: boolean
  saveBookmark: any
  removeBookmark: any
  updateBookmark: any
  setActivePanel: (type: string) => void
  setActiveFilterId: (id: string) => void
  openDrawer: (type: string) => void
  onCommentBlockOpen: (
    anchor: string,
    basedOn?: string,
    referenceImageUrl?: string
  ) => void
}

interface CommentsBlockState {
  numPosts: number
  bookmark: undefined | (Partial<BookmarkNoteFormPartsFragment> & { error?: boolean })
  bookmarking: boolean
  mounted: boolean
  isPopoverOpen: boolean
}

const CommentButton = ({
  onClick,
  numPosts
}: {
  onClick?: () => void
  numPosts: number
}) => {
  return (
    <Button
      data-testid="comment-button"
      variant="text-only"
      size="small"
      onClick={onClick}
      shape="rounded-full"
      className="pl-5 font-sans font-semibold hover:bg-inherit  hover:bg-rb-gray-50"
      iconClassName="mr-0"
      iconBefore={<SVGIcon name="discussions" className="h-4 w-3.5 stroke-0" />}
    >
      {numPosts > 0 && <span className="pb-1 pl-1">{numPosts}</span>}
      <span className="ml-1 hidden text-[10.5px] leading-[15.75px] text-rb-gray-400 sm:text-sm sm:leading-[18px] lg:block">
        {numPosts === 0 ? ' Ask question' : ''}
      </span>
    </Button>
  )
}

class CommentsBlock extends Component<
  CommentsBlockProps & WithInlinePostsProps,
  CommentsBlockState
> {
  constructor(props: CommentsBlockProps & WithInlinePostsProps) {
    super(props)

    this.state = {
      numPosts: props.inlinePostAnchors?.reduce(
        (n, x) => n + Number(x === props.elementId),
        0
      ),
      bookmark:
        props.bookmarksByAnchor && props.elementId
          ? props.bookmarksByAnchor[props.elementId]
          : undefined,
      bookmarking: false,
      mounted: false,
      isPopoverOpen: false
    }
  }

  renderAppendedComponent = (startNotebodyActive: boolean) => {
    const { bookmark, isPopoverOpen } = this.state
    const noteEl = document.querySelector(`#content #${this.props.elementId}-note`)
    if (!noteEl) return

    if (bookmark && !isPopoverOpen) {
      ReactDOM.render(
        <BookmarkNoteForm
          startActive={startNotebodyActive}
          key={bookmark.id}
          bookmark={bookmark as ProgramBookmarkPartsFragment}
          saveBookmarkNote={this.saveEditedBookmark}
          onDeleteBookmark={() => this.sendDeleteBookmarkRequest('delete')}
          type="Content"
        />,
        noteEl
      )
    } else {
      ReactDOM.unmountComponentAtNode(noteEl)
    }
  }

  appendBookmarkNoteToContent = (startNotebodyActive: boolean, containerEl: Element) => {
    if (!containerEl) return

    const noteId = `${this.props.elementId}-note`
    const noteEl = containerEl.querySelector(`#${noteId}`)
    if (!noteEl) {
      const bookmarkNoteForm = <div id={noteId} />
      containerEl.insertAdjacentHTML('beforeend', renderToString(bookmarkNoteForm))
    }

    this.renderAppendedComponent(startNotebodyActive)
    this.setState({ mounted: true })
  }

  componentDidUpdate() {
    if (this.state.mounted) {
      const potentialBookmark = this.props?.elementId
        ? this.props?.bookmarksByAnchor?.[this.props.elementId]
        : undefined

      const bookmarkNotesDoNotMatch =
        potentialBookmark?.noteBody !== this.state?.bookmark?.noteBody

      if (bookmarkNotesDoNotMatch) {
        this.setState({ bookmark: potentialBookmark })
      }

      const startNotebodyActive = false
      this.renderAppendedComponent(startNotebodyActive)
    }
  }

  componentDidMount() {
    const { elementId, setActivePanel, openDrawer } = this.props
    const linkedSection = window.location.hash.replace('#', '')
    if (elementId === linkedSection) {
      if (window.location.search.indexOf('referring-post') > -1) {
        // link is from a post so open the posts sidebar for context
        setActivePanel('discussions')
        openDrawer('discoveryDrawer')
      }

      // scroll to the anchor tag, taking into account the floating header height
      const isContent =
        document.querySelector('.learning-learning-show_section') ||
        document.querySelector('.content-show_content')

      if (
        linkedSection &&
        isContent &&
        typeof document.getElementById('page')?.scrollTo === 'function'
      ) {
        const targetElement = document.getElementById(linkedSection)
        if (targetElement) {
          setTimeout(function () {
            const cmsHeader = document.querySelector('.cms-header') as HTMLElement
            document.getElementById('page')?.scrollTo({
              top: targetElement.offsetTop - (cmsHeader?.offsetHeight || 0) - 15,
              left: 0
            })
          }, 300) // Set the timeout equal to the transition time for the opening of the right sidebar
        }
      }
    }

    const contentSection = document.querySelector(
      `#content #${elementId} .inline-posts__content-block`
    )
    if (contentSection) {
      // add an element for a bookmark note
      const startNotebodyActive = false
      this.appendBookmarkNoteToContent(startNotebodyActive, contentSection)
    }
  }

  handleEmptyKey = (e: KeyboardEvent) => {
    e.preventDefault()
  }

  saveEditedBookmark = (updatedBookmark: BookmarkNoteFormPartsFragment) => {
    const { bookmark } = this.state

    if (!bookmark || !bookmark.id) {
      notifyError(`invalid bookmark, cannot update ${JSON.stringify(bookmark)}`)
      this.setState({
        bookmarking: false,
        bookmark: { error: true }
      })
      return
    }

    const newBookmark = {
      ...bookmark,
      note_body: updatedBookmark.noteBody
    }
    this.props.updateBookmark(newBookmark, (errors: any) => {
      if (errors) {
        this.setState({
          bookmarking: false,
          bookmark: { ...updatedBookmark, error: true }
        })
      } else {
        this.setState({
          bookmarking: false,
          bookmark: updatedBookmark
        })
      }
    })
  }

  handleDeleteBookmarkSuccess = () => {
    const { elementId, cmsSectionId } = this.props
    const targetElement = document.getElementById(elementId || '')

    window.changeNumSectionBookmarks?.(`${cmsSectionId}`, -1)
    this.setState({ bookmarking: false, bookmark: undefined })
    if (targetElement) {
      targetElement.removeAttribute('data-bookmarked')
    }
  }

  trackBookmarkDelete = (type: 'delete' | 'unsave') => {
    const action = {
      delete: 'Bookmark__confirm_delete',
      unsave: 'Bookmark__unsave'
    }[type]
    // @ts-ignore - 'Content Viewer - Action' event is not defined in Segment JIRA#REF-5159
    track('Content Viewer - Action', {
      action,
      location: window.location.pathname
    })
  }

  sendDeleteBookmarkRequest = (type: 'delete' | 'unsave') => {
    this.trackBookmarkDelete(type)
    if (!this.state.bookmark || !this.state.bookmark.id) {
      notifyError(
        `invalid bookmark, cannot delete ${JSON.stringify(this.state.bookmark)}`
      )
      this.setState({
        bookmarking: false,
        bookmark: { error: true }
      })
      return
    }
    this.props.removeBookmark(this.state.bookmark, (errors: any) => {
      if (errors) {
        this.setState({ bookmarking: false, bookmark: { error: true } })
      } else {
        this.handleDeleteBookmarkSuccess()
      }
    })
  }

  handleCreateBookmarkSuccess = (
    newBookmark: Pick<BookmarkNoteFormPartsFragment, 'anchor' | 'id'>
  ) => {
    const { elementId, cmsSectionId } = this.props
    const targetElement = document.getElementById(elementId || '')

    this.setState({
      bookmarking: false,
      bookmark: newBookmark,
      isPopoverOpen: true
    })
    if (targetElement) {
      targetElement.setAttribute('data-bookmarked', 'true')
    }

    window.changeNumSectionBookmarks?.(`${cmsSectionId}`, 1)
    if (targetElement) {
      const contentSection = targetElement.querySelector('.inline-posts__content-block')
      if (contentSection) {
        const startNotebodyActive = true
        this.appendBookmarkNoteToContent(startNotebodyActive, contentSection)
        contentSection.classList.add('inline-posts__content-block--highlighted')
        setTimeout(() => {
          contentSection.classList.remove('inline-posts__content-block--highlighted')
        }, 1000)
      }
    }
  }

  sendCreateBookmarkRequest = () => {
    // @ts-ignore - 'Content Viewer - Action' event is not defined in Segment JIRA#REF-5159
    track('Content Viewer - Action', {
      action: 'Bookmark__initiate_create',
      location: window.location.pathname
    })

    const {
      elementId,
      cmsProgramId,
      cmsModuleId,
      cmsSectionId,
      synopsis,
      referenceImageUrl
    } = this.props
    const targetElement = document.getElementById(elementId || '')

    let basedOn = synopsis
    this.setState({ bookmarking: true })

    // Get the content from the page with all of the html tags
    if (!referenceImageUrl && targetElement) {
      const html = targetElement.querySelector('.inline-posts__content-block')?.innerHTML

      if (html) {
        basedOn = html
      }
    }

    const newBookmark = {
      cms_program_id: cmsProgramId,
      cms_module_id: cmsModuleId,
      cms_section_id: cmsSectionId,
      anchor: elementId,
      based_on: basedOn,
      reference_image_url: referenceImageUrl,
      type: referenceImageUrl ? 'ImageBookmark' : 'TextBookmark'
    }

    this.props.saveBookmark(newBookmark, (errors: any, id: string) => {
      if (errors) {
        this.setState({ bookmarking: false, bookmark: { error: true } })
      } else {
        this.handleCreateBookmarkSuccess({ anchor: id, id: id })
      }
    })
  }

  showPostForm = () => {
    const { elementId, synopsis, onCommentBlockOpen, referenceImageUrl } = this.props

    onCommentBlockOpen(elementId, synopsis, referenceImageUrl)

    if (!isMobileDevice()) {
      // eslint-disable-line no-undef
      const input = document.querySelector('.add-post__input') as HTMLElement
      if (input) {
        input.focus()
      }
    }

    this.trackEvent('comment_button_content')
  }

  trackEvent = (actionTrigger: string) => {
    // @ts-ignore - 'Content Viewer - Action' event is not defined in Segment JIRA#REF-5159
    track('Content Viewer - Action', {
      user_id: this.props.userId,
      cms_program_id: this.props.cmsProgramId,
      module_id: this.props.cmsModuleId,
      lesson_id: this.props.cmsSectionId,
      anchor: this.props.elementId,
      action: 'display_content_viewer_drawer',
      action_trigger: actionTrigger
    })
  }

  updateInlinePosts = (difference: number) => {
    this.setState((prevState) => {
      return { numPosts: prevState.numPosts + difference }
    })
  }

  showPostPane = () => {
    this.props.setActivePanel('discussions')
    this.props.openDrawer('discoveryDrawer')
    this.props.setActiveFilterId(this.props.elementId)
  }

  renderDiscussionIcon = () => {
    const { numPosts } = this.state

    const callback = numPosts > 0 ? this.showPostPane : this.showPostForm

    return <CommentButton onClick={callback} numPosts={numPosts} />
  }

  onPopoverClose() {
    this.setState({ isPopoverOpen: false })
  }

  onSaveBookmarkMouseEnter = () => {
    const targetElement = document.getElementById(this.props.elementId || '')

    if (targetElement) {
      const contentSection = targetElement.querySelector('.inline-posts__content-block')
      if (contentSection) {
        contentSection.classList.add('inline-posts__content-block--hover')
      }
    }
  }

  onSaveBookmarkMouseLeave = () => {
    const targetElement = document.getElementById(this.props.elementId || '')

    if (targetElement) {
      const contentSection = targetElement.querySelector('.inline-posts__content-block')
      if (contentSection) {
        contentSection.classList.remove('inline-posts__content-block--hover')
      }
    }
  }

  render() {
    const { numPosts, bookmark, bookmarking } = this.state

    const isBookmarked = !!bookmark
    const isPosted = numPosts > 0

    return (
      <div className="inline-posts__controls flex items-center justify-between">
        <div
          className="inline-posts__bookmark-control uk-width-auto"
          data-bookmarked={isBookmarked}
        >
          <span className="bookmark-toggle flex" data-bookmarked={isBookmarked}>
            <Button
              id={`${this.props.elementId}-save-bookmark-button`}
              onMouseEnter={this.onSaveBookmarkMouseEnter}
              onMouseLeave={this.onSaveBookmarkMouseLeave}
              onClick={(e) => {
                e.stopPropagation()
                !isBookmarked && !bookmarking && this.sendCreateBookmarkRequest()
                !isBookmarked && this.onSaveBookmarkMouseLeave()
                !!isBookmarked && this.sendDeleteBookmarkRequest('unsave')
              }}
              size="small"
              variant="outline"
              shape="rounded-full"
              disabled={bookmarking}
              className={'-my-2.5 mr-2 border-[1.5px] py-2 px-5 hover:bg-rb-gray-50'}
              iconClassName={'mr-0'}
              iconBefore={
                isBookmarked ? (
                  <SVGIcon
                    name="content-bookmark"
                    className="h-4 w-3 fill-current stroke-current lg:h-2.5 lg:w-2"
                  />
                ) : (
                  <SVGIcon
                    name="content-bookmark"
                    className="h-4 w-3 stroke-current lg:h-2.5 lg:w-2"
                  />
                )
              }
            >
              <span className="hidden !font-sans lg:block">
                <span className="text-[10.5px] leading-[15.75px] text-rb-gray-400 sm:text-sm sm:leading-[18px]">
                  {isBookmarked ? 'Saved' : 'Save'}
                </span>
              </span>
            </Button>
          </span>
          {/* changing the class causes a UIKit render bug (the component's offhover event occurs) */}
        </div>
        <div className="inline-posts__comments-control" data-posted={isPosted}>
          {this.renderDiscussionIcon()}
        </div>
      </div>
    )
  }
}

export default withInlinePostsHook(CommentsBlock)
