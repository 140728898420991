import { useState } from 'react'

import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import ArtifactPage from './ArtifactPage'

const LoggedOutArtifactsIndexPage = () => {
  const [page, setPage] = useState<GenericPageType>()

  return (
    <MarketingLayout page={page} showFooter={false}>
      <ArtifactPage setPage={setPage} />
    </MarketingLayout>
  )
}

export default LoggedOutArtifactsIndexPage
