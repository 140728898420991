import { twMerge } from 'tailwind-merge'

import Image from 'domains/Sanity/Image'

const FacePile = ({
  users,
  limit = 3,
  size = 30,
  showExtraCount = true
}: {
  users: Array<{ avatarUrl: string; id: string; avatarPhotoAlt: string }>
  limit?: number
  size?: number
  showExtraCount?: boolean
}) => {
  const avatarUsers = users.slice(0, limit)

  return (
    <div className="flex items-center">
      {avatarUsers.map(
        (u: any, i) =>
          (u.avatarUrl || u.artifactAuthor?.avatarPhoto?.imageUrl) && (
            <FacePileImage
              className={i === 0 ? 'ml-0' : ''}
              imageSource={u.avatarUrl || u.artifactAuthor?.avatarPhoto?.imageUrl}
              key={u.id}
              alt={u.avatarPhotoAlt || 'Avatar of contributor'}
              zIndex={users.length - i}
              size={size}
            />
          )
      )}

      {showExtraCount && users.length > limit && (
        <div className="pl-[2px] text-xs font-semibold">+{users.length - limit}</div>
      )}
    </div>
  )
}

const FacePileImage = ({
  alt,
  className,
  imageSource,
  size
}: {
  alt: string
  className: string
  imageSource: string
  zIndex: number
  size: number
}) => (
  <div
    style={{ width: `${size}px`, height: `${size}px` }}
    className={twMerge('-ml-[10px] rounded-full border border-white bg-white', className)}
  >
    <Image src={imageSource} alt={alt} className="rounded-full" />
  </div>
)

export default FacePile
