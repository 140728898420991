import MuxPlayer from '@mux/mux-player-react'
import { ReactComponent as ChevronLeft } from 'chevron-left.svg'
import { Link, Redirect } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import UnitExpertCollaboratorsPreview from 'pages/UnitLandingPage/UnitExpertCollaboratorsPreview'

import CourseDetailsEnroll from 'domains/CourseDetail/CourseDetailsEnroll'

import { Loading } from 'components'
import RfHeader1 from 'components/typography/RfHeader/RfHeader1'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import {
  CclCourseSessionPartsFragment,
  CourseDetailEntityPreviewPartsFragment,
  CoursePageAccessPartsFragment,
  useCourseEventQuery
} from 'gql'

import { buildCourseDetailsUrl, buildCourseSessionLink } from 'utils/courseUtils'
import { FULL_DATE_ABBREV_MONTH_FORMAT, getFormattedStartDate } from 'utils/date'
import { trackCtaClicked, trackVideoPlayed } from 'utils/tracking/generated/events'

interface CourseEventPageProps {
  course?: CoursePageAccessPartsFragment | null
  courseSessionId?: string
  backToCourseLink?: string
  sanityEventId?: string
  preview?: boolean
  courseDetails?: CourseDetailEntityPreviewPartsFragment | null
  upcomingCourseSessions?: CclCourseSessionPartsFragment[] | null
}

const CourseEventPage = ({
  course,
  courseSessionId,
  backToCourseLink,
  sanityEventId = '',
  preview = false,
  courseDetails,
  upcomingCourseSessions
}: CourseEventPageProps) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const { loading, data } = useCourseEventQuery({
    variables: { sanityId: sanityEventId, courseSessionId }
  })

  if (loading) return <Loading />

  if (!data?.courseEvent || !sanityEventId || !data?.courseEvent?.recording) {
    if (course?.slug && courseSessionId) {
      const destination =
        backToCourseLink ||
        buildCourseSessionLink({
          courseSlug: course.slug,
          sessionId: courseSessionId
        })

      return <Redirect to={destination} />
    }

    return <Redirect to="/" />
  }

  const { courseEvent } = data

  const onPlayRecording = () => {
    trackVideoPlayed({
      object_type: 'Event',
      object_id: courseEvent.sanityId,
      related_identifiers: {
        course_id: course?.id,
        course_title: course?.title,
        course_session_id: courseSessionId
      }
    })
  }

  const experts = courseEvent.hosts?.concat(courseEvent?.featuredGuests || [])

  const trackCourseHomeClick = () => {
    trackCtaClicked({
      cta_type: 'hyperlink text',
      cta_location: 'course_page',
      text: 'Course Home',
      related_identifiers: {
        ccl_course_id: courseDetails?.id,
        course_sanity_id: courseDetails?.sourceId
      }
    })
  }

  const destination = () => {
    return buildCourseDetailsUrl({
      courseSlug: courseDetails?.slug || ''
    })
  }

  return (
    <>
      {!preview && (
        <div
          id="course-dashboard-header"
          className="sticky top-0 z-10 flex w-full items-center bg-white p-3"
        >
          <Link
            to={destination()}
            className="hover:no-underline"
            onClick={trackCourseHomeClick}
          >
            <RfParagraphMini>
              <span className="flex items-center justify-center gap-2 text-rb-gray-300">
                <span className="rounded-full border border-rb-gray-100 p-2 text-rb-gray-100">
                  <ChevronLeft className="h-[17px] w-[17px] stroke-current text-rb-gray-100" />
                </span>
                Course Home
              </span>
            </RfParagraphMini>
          </Link>
        </div>
      )}

      <div className="mt-4 flex flex-col px-6 pb-[162px]">
        {!preview && courseEvent?.recording?.startTime && (
          <div
            className="mb-8 w-fit rounded-full px-5 py-2"
            style={{
              background:
                'linear-gradient(116deg, rgba(255, 162, 207, 0.35) -12.05%, rgba(255, 234, 222, 0.35) 62.27%), #FFF'
            }}
          >
            <RfParagraphSmall>
              Event Recording •{' '}
              {getFormattedStartDate(
                courseEvent?.recording?.startTime,
                userTimeZone,
                FULL_DATE_ABBREV_MONTH_FORMAT
              )}
            </RfParagraphSmall>
          </div>
        )}

        <div className="flex items-start justify-between gap-12">
          <div>
            {preview && (
              <div
                className="mb-8 w-fit rounded-full px-5 py-2"
                style={{
                  background:
                    'linear-gradient(134deg, rgba(202, 221, 72, 0.50) 15.68%, rgba(255, 255, 214, 0.50) 57.66%)'
                }}
              >
                <RfParagraphSmall>Event Recording Preview</RfParagraphSmall>
              </div>
            )}

            <div className="flex justify-between gap-4">
              <div>
                <RfHeader1 className="mb-4 !font-polysans">
                  <span className="font-light">{courseEvent?.title}</span>
                </RfHeader1>

                <RfParagraphMedium>{courseEvent?.shortDescription}</RfParagraphMedium>

                <UnitExpertCollaboratorsPreview
                  experts={experts}
                  avatarImageClassName="w-[48px] h-[48px]"
                  expertNameClassName="font-semibold"
                />
              </div>
            </div>
          </div>

          {preview && courseDetails && (
            <CourseDetailsEnroll
              courseDetails={courseDetails}
              courseSessions={upcomingCourseSessions}
              preview
            />
          )}
        </div>

        <div
          className={twMerge(
            'rounded-2xl p-5 md:p-10',
            (!experts || experts.length === 0) && 'mt-6'
          )}
          style={
            preview
              ? {
                  background:
                    'linear-gradient(134deg, rgba(202, 221, 72, 0.50) 15.68%, rgba(255, 255, 214, 0.50) 57.66%)'
                }
              : {
                  background:
                    'linear-gradient(131deg, rgba(255, 162, 207, 0.25) 22.99%, rgba(255, 234, 222, 0.25) 72.18%), #FFF'
                }
          }
        >
          <MuxPlayer
            className="max-h-2xl w-full"
            playbackId={
              courseEvent?.recording?.asset?.playbackId ||
              courseEvent?.recording?.video?.asset?.playbackId
            }
            streamType="on-demand"
            onPlay={onPlayRecording}
          />
        </div>
      </div>
    </>
  )
}

export default CourseEventPage
