import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { PageProvider } from 'components/PageHeader/usePage'

import { PageSeo, PageTypeEnum } from 'gql'

import EventsPage from './EventsPage'

const LoggedOutEventsPage = () => {
  const page: GenericPageType = {
    title: 'Attend events at Reforge',
    seo: {
      metaTitle: 'Attend events at Reforge',
      type: 'website' as PageTypeEnum,
      shareTitle: 'Attend events at Reforge'
    } as PageSeo
  }

  return (
    <MarketingLayout page={page}>
      <PageProvider
        showHeader
        pageTitle="Events"
        headerWrapperClassName="px-4 md:px-[4vw] pt-4 max-w-7xl mx-auto"
      >
        <div className="pb-14 max-w-7xl mx-auto">
          <EventsPage />
        </div>
      </PageProvider>
    </MarketingLayout>
  )
}

export default LoggedOutEventsPage
