import UIkit from 'uikit'

import { SVGIcon } from 'components/Icon'

interface MobileFiltersProps {
  showFilterByKeyword?: boolean
}

export default function MobileFilters({
  showFilterByKeyword = false
}: MobileFiltersProps) {
  const filters = showFilterByKeyword
    ? document.getElementById('filters-with-keyword')
    : document.getElementById('filters')
  const modal = document.getElementById('modal-filters')

  const showMobileFilters = () => {
    const placeholder = document.getElementById('filters-placeholder-modal')
    if (placeholder && filters) {
      placeholder.appendChild(filters)
    }

    if (modal) {
      UIkit.modal(modal, { 'esc-close': false, 'bg-close': false }).show()
    }
  }

  const hideMobileFilters = () => {
    const placeholder = showFilterByKeyword
      ? document.getElementById('filters-with-keyword-placeholder')
      : document.getElementById('filters-placeholder')
    if (placeholder && filters) {
      placeholder.appendChild(filters)
    }

    if (modal) {
      UIkit.modal(modal).hide()
    }
  }

  return (
    <div className="uk-margin-small-bottom flex justify-end lg:hidden">
      <span onClick={showMobileFilters} className="flex cursor-pointer items-center">
        <SVGIcon className="mr-1" name="icon-filters" fill="#080A0A" />
        <div className="text-m-small text-rb-gray-500">Filter</div>
      </span>
      <div
        id="modal-filters"
        className="uk-background-default uk-modal-full"
        uk-modal={'{"container": false}'}
      >
        <div className="uk-modal-dialog">
          <div id="filters-placeholder-modal" className="relative p-6">
            <a
              onClick={hideMobileFilters}
              className="uk-icon-link uk-modal-close-default mt-3 mr-2"
              uk-icon="icon: close"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
