import React, { useRef } from 'react'

import SidebarNavigablePage from 'components/SidebarNavigablePage'

import { trackCtaClicked, trackNavigationClicked } from 'utils/tracking/analytics'

interface ReimbursementSidebarProps {
  header: React.ReactNode
  overview: React.ReactNode
  emailTemplates: React.ReactNode
  tips: React.ReactNode
}
const ReimbursementSidebar = ({
  header,
  overview,
  emailTemplates,
  tips
}: ReimbursementSidebarProps) => {
  const trackSidebarNavigationLinkClick = (e: any) => {
    trackNavigationClicked({
      location: 'sidebar_navigation',
      type: 'hyperlink text',
      text: e.currentTarget.textContent || ''
    })
  }

  const trackSidebarTextLinkClick = (e: any) => {
    trackCtaClicked({
      cta_type: 'hyperlink text',
      cta_location: 'sidebar',
      text: e.currentTarget.textContent || ''
    })
  }
  const overviewRef = useRef(null)
  const emailTemplatesRef = useRef(null)
  const tipsRef = useRef(null)

  const sections = [
    {
      linkText: 'Overview',
      onClick: trackSidebarNavigationLinkClick,
      component: overview,
      ref: overviewRef
    },
    {
      linkText: 'Email Templates',
      onClick: trackSidebarNavigationLinkClick,
      component: emailTemplates,
      ref: emailTemplatesRef
    },
    {
      linkText: 'Tips',
      onClick: trackSidebarNavigationLinkClick,
      component: tips,
      ref: tipsRef
    }
  ]

  const AdditionalSidebarContent = () => (
    <p className="text-rb-gray-400">
      Any unanswered questions? <br />
      Send a note to{' '}
      <a
        className="text-rb-teal-200 underline"
        href="mailto:hello@reforge.com"
        onClick={trackSidebarTextLinkClick}
      >
        hello@reforge.com
      </a>
    </p>
  )

  return (
    <div>
      <SidebarNavigablePage
        header={header}
        additionalSidebarContent={<AdditionalSidebarContent />}
        sections={sections}
      />
    </div>
  )
}

export default ReimbursementSidebar
