import { differenceInMinutes, parseISO } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

import AddToCalendar from 'components/AddToCalendar'
import CalendarDropdownMenu from 'components/CalendarDropdownMenu'
import { SVGIcon } from 'components/Icon'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { cn } from 'utils/tailwind'
import { trackAddToCalendar } from 'utils/tracking/generated/events/addToCalendar'
import { EVENT_LINK_URL, makeUrl } from 'utils/url'

import { CalendarPopupProps } from '../types'

export default function AddToCalendarCard({
  event,
  showHeader = false,
  className,
  sourceCtaText,
  onClose = () => {}
}: CalendarPopupProps) {
  const { startsAtUtc, endsAtUtc } = event
  const joinLink = makeUrl(`${window.location.origin}${EVENT_LINK_URL}`, {
    eventUUID: event.uuid
  })
  const { currentUser } = useCurrentUser()
  const timezone = currentUser?.timezone || 'UTC'
  const eventTitle = event.name

  const handleOnClickCallback = () => {
    const duration =
      differenceInMinutes(parseISO(endsAtUtc || ''), parseISO(startsAtUtc || '')) / 60

    trackAddToCalendar({
      location: 'event_details_page',
      source_cta_text: sourceCtaText,
      event_sanity_id: event.id,
      event_title: eventTitle || '',
      event_day_of_week: formatInTimeZone(startsAtUtc || '', timezone!, 'EEEE'),
      event_starts_at: startsAtUtc || '',
      event_time_of_day: formatInTimeZone(startsAtUtc || '', timezone!, 'h:mm a'),
      event_ends_at: endsAtUtc || '',
      event_duration_hrs: duration || 0,
      event_timezone: timezone || '',
      add_type: 'single'
    })
  }

  const addToCalendarProps = {
    timezone: timezone,
    startsAtUtc,
    endsAtUtc,
    eventTitle: eventTitle,
    eventDescription: event.description,
    joinLink,
    className: '!shadow-none',
    onClickCallback: () => {
      handleOnClickCallback()
    }
  }

  return (
    <CalendarDropdownMenu
      defaultStyles={false}
      containerClassName={cn(className)}
      onClose={onClose}
    >
      {showHeader && (
        <div className="mb-4 flex w-full items-center justify-center">
          <SVGIcon width="16" height="16" name="events" fill="#2D2F2F" />
          <span className="ml-2 font-sans text-sm font-semibold">Add to calendar</span>
        </div>
      )}
      <AddToCalendar {...addToCalendarProps} />
    </CalendarDropdownMenu>
  )
}
