import { EventPartsFragment } from 'gql'

import { UpcomingEventCard } from './UpcomingEventCard'

type UpcomingEventContainerProps = {
  events: EventPartsFragment[]
}

export function UpcomingEventContainer({ events }: UpcomingEventContainerProps) {
  return (
    <div className="flex h-full w-full flex-col gap-6">
      {events.map((event, index) => (
        <UpcomingEventCard key={index} event={event} />
      ))}
    </div>
  )
}
