import { closing, opening } from './sharedMarkdown'

const programName = 'Finding Product/Market Fit'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://reforge.com/finding-product-market-fit), because I will learn a systematic approach to iterating on and validating ideas to get to product/market fit. This course was built with [Sachin Rekhi](https://www.reforge.com/experts/sachin-rekhi), and covers:
* **Defining The Product/Market Fit Narrative.** Define your product/market fit narrative with the six key dimensions of your product strategy as your first step to finding product/market fit. 
* **Validating Riskiest Assumptions.** Apply both broad and targeted validation techniques to identify, prioritize, and validate the riskiest elements of your product strategy.
* **Measuring Product/Market Fit.** Determine the right metrics to assess whether you’ve achieved product/market fit in order to avoid premature scaling – one of the top reasons new products fail.
* **Growing Users.** Set initial traction goals and discover the most commonly used channels for achieving these goals. Get on the right path to finding a source of sustainable growth.

&NewLine;

${closing}
`

export default { markdown, title: programName }
