import React from 'react'

import { InlinePost, useInlinePostsLazyQuery } from 'gql'

import notifyError from 'utils/errorNotifier'

export interface WithInlinePostsProps {
  fetchInlinePosts: ({ cmsSectionId }: FetchFunctionProps) => Promise<InlinePost[]>
}

interface FetchFunctionProps {
  cmsSectionId: string
}

export const withInlinePostsHook = <WCProps extends WithInlinePostsProps>(
  WrappedComponent: React.ComponentType<WCProps>
) => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component'

  const ComponentWithInlinePosts = (props: Omit<WCProps, keyof WithInlinePostsProps>) => {
    const [getInlinePosts] = useInlinePostsLazyQuery()

    const fetchInlinePosts = async ({ cmsSectionId }: FetchFunctionProps) => {
      try {
        const response = await getInlinePosts({
          variables: {
            cmsSectionId
          }
        })
        return response.data?.inlinePosts
      } catch (e) {
        notifyError(e)
      }
    }

    return (
      <WrappedComponent {...(props as WCProps)} fetchInlinePosts={fetchInlinePosts} />
    )
  }

  ComponentWithInlinePosts.displayName = `withInlinePosts(${displayName})`

  return ComponentWithInlinePosts
}
