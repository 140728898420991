import { useNavigate } from 'react-router-dom-v5-compat'

import { SubscriptionManagementCard } from 'domains/Subscription/SubscriptionCard/SubscriptionManagementCards'

import {
  PlanName,
  UserSubscriptionWithTeamQuery,
  UserSubscriptionWithTeamSubscriptionFragment
} from 'gql'

import { getPrettyFullDate } from 'utils/date'
import { titleize } from 'utils/stringUtils'
import { trackCtaClicked } from 'utils/tracking/analytics'

export interface CurrentPlanCardProps {
  numTotalSeats: number
  numUsedSeats: number
  planName: string
  renewalPriceCopy: string
  isSubscriptionOwner: boolean
  isAssistantAdmin?: boolean
  expiresAt: string
  isCanceled?: boolean
  isDunning?: boolean
  dunningReason?: string
  subscription?: UserSubscriptionWithTeamSubscriptionFragment
  user?: UserSubscriptionWithTeamQuery['user']
}

export const CurrentPlanCard = ({
  subscription,
  user,
  numTotalSeats,
  numUsedSeats,
  planName,
  renewalPriceCopy,
  isSubscriptionOwner,
  isAssistantAdmin,
  expiresAt,
  isCanceled = false,
  isDunning = false,
  dunningReason
}: CurrentPlanCardProps) => {
  const navigate = useNavigate()

  let note = `Renews on ${getPrettyFullDate(expiresAt)}`

  if (isDunning && dunningReason) {
    note = dunningReason
  } else if (isCanceled) {
    note = `Expires on ${getPrettyFullDate(expiresAt)}`
  } else if (user?.is.trialing && subscription?.trialEndDate) {
    note = `Trial ends on ${getPrettyFullDate(subscription.trialEndDate)}`
  }

  let primaryButton
  let secondaryButton

  if (isSubscriptionOwner) {
    if (isCanceled) {
      primaryButton = {
        copy: 'Reactivate Subscription',
        // NOTE: This is a noop where the button is set within SubscriptionManagementCard
        onclick: () => {}
      }

      secondaryButton = {
        copy: 'Manage Plan',
        onclick: () => {
          trackCtaClicked({
            cta_location: 'subscription_card',
            cta_type: 'button',
            text: 'manage plan',
            destination: '/manage-plan'
          })
          navigate('/manage-plan')
        }
      }
    } else {
      primaryButton = {
        copy: 'Manage Plan',
        onclick: () => {
          trackCtaClicked({
            cta_location: 'subscription_card',
            cta_type: 'button',
            text: 'manage plan',
            destination: '/manage-plan'
          })
          navigate('/manage-plan')
        }
      }
    }

    if (isDunning) {
      secondaryButton = {
        copy: 'Manage Plan',
        onclick: () => {
          trackCtaClicked({
            cta_location: 'subscription_card',
            cta_type: 'button',
            text: 'manage plan',
            destination: '/manage-plan'
          })
          navigate('/manage-plan')
        }
      }
    }
  } else {
    secondaryButton = {
      copy: 'View Plans',
      onclick: () => {
        trackCtaClicked({
          cta_location: 'subscription_card',
          cta_type: 'button',
          text: 'view plans',
          destination: '/manage-plan'
        })
        navigate('/manage-plan')
      }
    }
  }

  const isNotIndividual = planName !== PlanName.INDIVIDUAL

  return (
    <SubscriptionManagementCard
      isTrialing={user?.is.trialing}
      subscription={subscription}
      dataTestId="subscription-plan-card"
      heading={`${titleize(planName)} Plan`}
      note={note}
      noteColor={(isDunning && dunningReason) || isCanceled ? 'red' : undefined}
      contentLeft={renewalPriceCopy}
      contentRight={
        isNotIndividual ? `${numTotalSeats} Seat${numTotalSeats > 1 ? 's' : ''}` : ''
      }
      progressBar={
        isNotIndividual
          ? {
              type: 'seat',
              used: numUsedSeats,
              total: numTotalSeats,
              cta:
                (isSubscriptionOwner || isAssistantAdmin) && numTotalSeats > 1
                  ? {
                      copy: 'Manage Seats',
                      onclick: () => {
                        trackCtaClicked({
                          cta_location: 'subscription_card',
                          cta_type: 'hyperlink text',
                          text: 'manage seats',
                          destination: '/team_members'
                        })
                        navigate('/team_members')
                      }
                    }
                  : undefined
            }
          : undefined
      }
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
    />
  )
}
