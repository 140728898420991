import { closing, opening } from './sharedMarkdown'

const programName = 'Product Marketing'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://www.reforge.com/programs/product-marketing), because I will learn how to repeatedly deliver great products to the market successfully. This course was built with [Meghan Keaney Anderson](https://www.reforge.com/experts/meghan-keaney-anderson) and [Mary Sheehan](https://www.reforge.com/experts/mary-sheehan), and covers:
* **Product Positioning and Messaging.** Strong positioning is the foundation of your go-to-market strategy. We’ll discuss how to identify and lean into your product’s differentiation to strike and win the market.
* **Budgeting and Pricing & Packaging.** Products need more than just smart positioning and messaging to succeed. We’ll discuss how to secure and allocate resources in a way to maximize for each launch’s differentiation.
* **Go-to-Market Strategy and Metrics.** We’ll cover how to build a go-to-market strategy that not only aligns cross-functional stakeholders but is also tied to business goals.
* **Post-Launch Measurement.** A product marketer’s work isn’t done with the launch. We’ll discuss how to diagnose and triage post-launch performance to optimize for sustained product growth.

&NewLine;

${closing}
`

export default { markdown, title: programName }
