import { useState } from 'react'

import RemoveSubscriptionModal from 'domains/Org/RemoveSubscriptionModal'

import { OrgSubscriptionFragment, UserOrgFragment } from 'gql'

interface MyOrganizationTableProps {
  org: UserOrgFragment
  handleSubscriptionRemovalClick: (sub: OrgSubscriptionFragment) => void
}

export const MyOrganizationTable = ({
  org,
  handleSubscriptionRemovalClick
}: MyOrganizationTableProps) => {
  return (
    <table className="w-full">
      <thead>
        <tr className="text-gray-500">
          <th className="py-2 pl-2 text-left text-sm font-medium uppercase">Owner</th>
          <th className="py-2 text-left text-sm font-medium uppercase">Type</th>
          <th className="text-left text-sm font-medium uppercase">Team Name</th>
          <th className="text-left text-sm font-medium uppercase">Seats</th>
          <th className="text-left text-sm font-medium uppercase">Actions</th>
        </tr>
      </thead>
      <tbody>
        {org.subscriptions.map((sub) => {
          return (
            <tr
              key={sub.id}
              className="border-t border-gray-200 px-2 text-sm leading-8 odd:bg-gray-100"
              data-test={`org-subscription-${sub.id}`}
            >
              <td className="pl-2">{sub.user.fullName || sub.user.email}</td>
              <td>{sub.isTeam ? 'Team' : 'Individual'}</td>
              <td>{sub.teamName}</td>
              <td>
                {sub.numSeats} {sub.numSeats === 1 ? 'member' : 'members'}
              </td>
              <td>
                <button
                  className="cursor-pointer text-blue-400 hover:text-blue-600 hover:underline"
                  onClick={() => handleSubscriptionRemovalClick(sub)}
                >
                  Remove
                </button>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

interface MyOrganizationProps {
  org: UserOrgFragment | undefined | null
}

export const MyOrganization = ({ org }: MyOrganizationProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedSubscription, setSelectedSubscription] =
    useState<OrgSubscriptionFragment | null>(null)

  if (!org) return null

  const handleSubscriptionRemovalClick = (subscription: OrgSubscriptionFragment) => {
    setSelectedSubscription(subscription)
    setIsModalOpen(true)
  }

  return (
    <>
      <div
        data-test="my-organization-table"
        className="rf-rb-card flex min-h-[300px] flex-col bg-white p-8"
      >
        <h3 className="rf-text-21 rf-text-reforge-black font-medium">
          Subscriptions in this organization
        </h3>
        {org.subscriptions.length ? (
          <MyOrganizationTable
            org={org}
            handleSubscriptionRemovalClick={handleSubscriptionRemovalClick}
          />
        ) : (
          <div className="flex h-full w-full flex-grow flex-col justify-center bg-gray-100 text-center">
            <div className="mb-2 text-xl">
              There are no subscriptions found for this organization.
            </div>
            <div>
              To add subscriptions to your org, contact{' '}
              <a href="mailto:hello@reforge.com">hello@reforge.com</a>
            </div>
          </div>
        )}
      </div>
      {selectedSubscription && (
        <RemoveSubscriptionModal
          isModalOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          subscription={selectedSubscription}
          org={org}
        />
      )}
    </>
  )
}
