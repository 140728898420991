import { ReactNode } from 'react'

import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

interface ChildrenProps {
  children: ReactNode
  color?: ColorOptions
  className?: string
}

const RfParagraphSmallSemiBold = ({ children, color, className }: ChildrenProps) => (
  <RfParagraphSmall color={color} className={className}>
    <span className="font-semibold">{children}</span>
  </RfParagraphSmall>
)

export default RfParagraphSmallSemiBold
