import { useState } from 'react'

import { ErrorMessage, Loading } from 'components'
import Button from 'components/Button'
import { ModalContent, ModalFooter } from 'components/Modal'

import { useChoosePlanMutation, useUserSubscriptionModalQuery } from 'gql'

import { trackCtaClicked } from 'utils/tracking/analytics'

export interface CohortPassOptOutModalContentProps {
  handleClose: () => void
  onPassOptOut: () => void
}

export const CohortPassOptOutModalContent = ({
  handleClose,
  onPassOptOut
}: CohortPassOptOutModalContentProps) => {
  const [choosePlan, { loading: loadingChoosePlanMutation }] = useChoosePlanMutation()
  const [isError, setIsError] = useState(false)
  const { loading, error, data } = useUserSubscriptionModalQuery()
  const activeSubscription = data?.currentUser?.subscriptions?.active
  const [optOutSelected, setOptOutSelected] = useState(false)

  const handleRemoveClick = async () => {
    trackCtaClicked({
      cta_location: 'cohort_pass_removal_modal',
      cta_type: 'button',
      text: 'remove Live Course Passes'
    })
    setOptOutSelected(true)
    const result = await choosePlan({
      variables: {
        input: {
          newPlan: 'individual',
          numCohortPasses: 0,
          updateType: 'remove_cohort_pass'
        }
      }
    })
    if (result.data?.choosePlan?.errors?.length !== 0) {
      setIsError(true)
    } else {
      onPassOptOut()
    }
  }

  if (error || !activeSubscription) {
    return (
      <div className="pt-[30px]" data-testid="pass-opt-out-modal">
        <ErrorMessage error="Sorry, there was a problem. Please contact hello@reforge.com for assistance." />
      </div>
    )
  } else if (loading) return <Loading />
  else {
    return (
      <div className="px-[40px]">
        <ModalContent className="px-3 sm:px-8" dataTest="pass-opt-out-modal">
          <h1 className="mb-[16px] text-center text-xl font-semibold leading-7">
            Are you sure you want to remove the Live Course Passes?
          </h1>
          <p className="text-md mb-0 font-normal">
            We are offering two Live Course Passes at a discounted price for renewing
            members. This deal will save you $1,795 and will only be available for a
            limited time.
          </p>
          {isError ? (
            <div className="pt-[30px]">
              <ErrorMessage error="Sorry, there was a problem. Please contact hello@reforge.com for assistance." />
            </div>
          ) : null}
        </ModalContent>
        <ModalFooter className="flex justify-center">
          <Button
            className="mr-[15px] w-[200px]"
            onClick={handleClose}
            variant="outline"
            disabled={optOutSelected}
          >
            Cancel
          </Button>
          <Button
            className="w-[230px]"
            onClick={handleRemoveClick}
            disabled={optOutSelected}
            isLoadingSpinner={loadingChoosePlanMutation}
          >
            Remove Live Course Passes
          </Button>
        </ModalFooter>
      </div>
    )
  }
}

export default CohortPassOptOutModalContent
