import { FacePile } from 'components/FacePile'
import RoundedCheckmark from 'components/icons/RoundedCheckMark'
import RfHeader2 from 'components/typography/RfHeader/RfHeader2'

import { CheckoutCourseSessionFragment, CourseCheckoutPartsFragment } from 'gql'

import { listify } from 'utils/stringUtils'

import { ReactComponent as ReforgeWordmark } from 'images/reforge-logo-black.svg'

import CourseCheckoutTestimonialCarousel from './CourseCheckoutTestimonialCarousel'

interface CourseCheckoutInfoSidebarProps {
  course: CourseCheckoutPartsFragment
  courseSessions: CheckoutCourseSessionFragment[]
  selectedCourseSessionId: string
}

export const CourseCheckoutInfoSidebar = ({
  course,
  courseSessions,
  selectedCourseSessionId
}: CourseCheckoutInfoSidebarProps) => {
  const selectedCourseSession = courseSessions.filter(
    (session) => session.id === selectedCourseSessionId
  )[0]
  const creatorNames = course.creators?.map((creator) => creator.name) || []
  const creatorFacepileUsers = course.creators?.map((creator) => {
    return {
      id: creator.id,
      avatarUrl: creator.avatarUrl || ''
    }
  })

  return (
    <>
      <div className="lg:hidden w-full p-4 md:p-6 bg-rb-orange-25 fixed top-0">
        <ReforgeWordmark className="h-4 md:h-6 mb-4 md:mb-6" />

        <RfHeader2>{course.title}</RfHeader2>

        <div className="mt-4 flex flex-row items-center">
          <FacePile users={creatorFacepileUsers} imageSize="medium" />
          {creatorNames.length > 0 && (
            <span className="ml-2 inline-block text-ellipsis line-clamp-1 text-sm">
              created by {listify(creatorNames)}
            </span>
          )}
        </div>
      </div>

      <div className="hidden lg:block w-[480px] py-8 px-3 bg-rb-orange-25">
        <div className="flex flex-col">
          <div className="px-8">
            <ReforgeWordmark className="h-[32px] mb-12" />

            <RfHeader2>{course.title}</RfHeader2>

            <div className="mt-4 mb-12 flex flex-row items-center">
              <FacePile users={creatorFacepileUsers} imageSize="medium" />
              {creatorNames.length > 0 && (
                <span className="ml-2 inline-block text-ellipsis line-clamp-1 text-sm">
                  created by {listify(creatorNames)}
                </span>
              )}
            </div>

            <div className="mb-2 text-2xl font-medium">
              ${selectedCourseSession.price}
            </div>
            {selectedCourseSession.duration && (
              <div className="mb-12">
                {selectedCourseSession.duration.replace(/s+$/, '')} course
              </div>
            )}

            <div className="mb-8 flex flex-row items-center">
              <RoundedCheckmark className="inline-block text-black mr-2" />
              <span className="font-semibold">Actionable step-by-step guides</span>
            </div>
            <div className="mb-8 flex flex-row items-center">
              <RoundedCheckmark className="inline-block text-black mr-2" />
              <span className="font-semibold">Real-world examples</span>
            </div>
            <div className="mb-8 flex flex-row items-center">
              <RoundedCheckmark className="inline-block text-black mr-2" />
              <span className="font-semibold">Live events with expert operators</span>
            </div>
          </div>

          {course.testimonials && course.testimonials.length > 0 && (
            <CourseCheckoutTestimonialCarousel course={course} />
          )}
        </div>
      </div>
    </>
  )
}
