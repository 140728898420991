import { MutableRefObject } from 'react'

import { useTrackServerEventMutation } from 'gql'

import { getAnonymousId } from 'utils/tracking/segment'

import { calculateProgressPercent } from './utils'

export interface useTrackAudioPlayerProps {
  trackingMetadata: AudioPlayerTrackingMetadata
}

export interface AudioPlayerTrackingMetadata {
  accessPolicyKind?: string
  objectType?: string
  objectId?: string | number
  relatedIdentifiers?: object
}

export const useTrackAudioPlayer = ({ trackingMetadata }: useTrackAudioPlayerProps) => {
  const [trackServerEvent] = useTrackServerEventMutation()

  const trackEvent = (
    eventName: string,
    audioRef: MutableRefObject<HTMLAudioElement>
  ) => {
    trackServerEvent({
      variables: {
        input: {
          anonymousId: getAnonymousId(),
          event: eventName,
          properties: {
            access_policy_kind: trackingMetadata.accessPolicyKind,
            playback_speed: audioRef.current.playbackRate,
            duration_seconds: audioRef.current.duration,
            progress_percent: calculateProgressPercent(audioRef.current),
            path: window.location.pathname,
            url: window.location.href,
            object_type: trackingMetadata.objectType,
            object_id: trackingMetadata.objectId,
            related_identifiers: trackingMetadata.relatedIdentifiers
          }
        }
      }
    })
  }

  const trackPlayed = (audioRef: MutableRefObject<HTMLAudioElement>) =>
    trackEvent('Audio Played - Server', audioRef)
  const trackPaused = (audioRef: MutableRefObject<HTMLAudioElement>) =>
    trackEvent('Audio Paused - Server', audioRef)
  const trackCompleted = (audioRef: MutableRefObject<HTMLAudioElement>) =>
    trackEvent('Audio Completed - Server', audioRef)

  return {
    trackPlayed,
    trackPaused,
    trackCompleted
  }
}
