import { Route, Switch } from 'react-router-dom'

import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import CourseWaitList from 'components/CourseWaitList'
import Loading from 'components/Loading'

import { useCoursesMarketplaceWaitlistQuery } from 'gql'

const CoursesMarketplaceWaitlistPage = () => {
  const { data, loading } = useCoursesMarketplaceWaitlistQuery()
  const page = data?.coursesMarketplaceWaitlist

  return (
    <MarketingLayout page={page}>
      {loading ? (
        <Loading />
      ) : (
        <Switch>
          <Route
            exact
            path="/courses-marketplace-waitlist/course-full"
            render={() => <CourseWaitList heading="This Course Is Full" />}
          />
          <Route
            exact
            path="/courses-marketplace-waitlist"
            render={() => <CourseWaitList heading="Notify me about future Courses" />}
          />
        </Switch>
      )}
    </MarketingLayout>
  )
}

export default CoursesMarketplaceWaitlistPage
