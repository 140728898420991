import React, { useEffect, useState } from 'react'

type UseSyncedStateReturnValue<T> = [T, React.Dispatch<React.SetStateAction<T>>]

// This hook allows us to have a local state-variable that can still be
// overridden by the parent component.
const useSyncedState = <T>(stateComingFromProp: T): UseSyncedStateReturnValue<T> => {
  const [syncedState, setSyncedState] = useState<T>(stateComingFromProp)

  useEffect(() => {
    setSyncedState(stateComingFromProp)
  }, [stateComingFromProp])

  return [syncedState, setSyncedState]
}

export default useSyncedState
