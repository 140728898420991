import { useRef, useState } from 'react'
import Rater from 'react-rater'

import RaterIcon from 'components/RaterIcon'

import { Maybe, useContentFeedbackMutation } from 'gql'

import { track } from 'utils/tracking/segment'

const helpers = [
  'Not useful at all',
  'Not very useful',
  'Somewhat useful',
  'Useful',
  'Very useful'
]

interface ILegacyFeedbacksCmsSection {
  cmsSectionId: number
  cmsSectionCmsProgramId: number
  userId: number
  nextSectionUrl: string
  inline?: boolean
}

interface IFeedbacksCmsSection {
  cmsSectionId?: string
  cmsSectionCmsProgramId?: string
  userId?: string
  nextSectionUrl?: Maybe<string>
  inline?: boolean
}

const FeedbacksCmsSection = ({
  cmsSectionId,
  cmsSectionCmsProgramId,
  userId,
  nextSectionUrl,
  inline
}: IFeedbacksCmsSection | ILegacyFeedbacksCmsSection) => {
  const [giveContentFeedback] = useContentFeedbackMutation()

  const [rating, setRating] = useState(0)
  const helperTextRef = useRef<HTMLDivElement>(null)

  const save = (rating: number) => {
    // @ts-ignore - 'Feedback-qualitative-action' event is not defined in Segment JIRA#REF-5159
    track('Feedback-qualitative-action', {
      user_id: userId,
      qual_rating: false
    })

    giveContentFeedback({
      variables: {
        input: {
          cmsSectionId: `${cmsSectionId}`,
          cmsProgramId: `${cmsSectionCmsProgramId}`,
          quant: rating
        }
      }
    })

    if (inline) {
      const nextSectionNav = document.querySelector('.cms-footer__nav')
      if (nextSectionNav) {
        nextSectionNav.classList.add('cms-footer__nav--highlighted')
      }
    } else if (nextSectionUrl) {
      window.location.href = nextSectionUrl
    }
  }

  const onRatingHover = ({ rating }: { rating: number }) => {
    // We are manipulating the dom because there is a bug in Rater component's onmouseover
    helperTextRef?.current && $(helperTextRef.current).html(helpers[rating - 1])
  }

  const changeRating = ({ rating }: { rating: number }) => {
    setRating(rating)
    save(rating)
    // @ts-ignore - 'Feedback-quant-action' event is not defined in Segment JIRA#REF-5159
    track('Feedback-quant-action', {
      user_id: userId,
      quant_rating: rating
    })
  }

  const skip = () => {
    // @ts-ignore - 'Feedback-quant-action' event is not defined in Segment JIRA#REF-5159
    track('Feedback-quant-action', {
      user_id: userId,
      skipped: true
    })
    if (nextSectionUrl) {
      window.location.href = nextSectionUrl
    }
  }

  return (
    <div className="inline-block text-center">
      {inline && (
        <>
          <p className="mt-5 mb-4 block !font-sans text-[1.2rem] font-medium sm:hidden sm:text-[21px] sm:font-normal">
            You&apos;ve completed this lesson. How useful was it?
          </p>
          <p className="mt-5 mb-4 hidden !font-sans text-[1.2rem] font-medium sm:block sm:text-[21px] sm:!font-bold">
            Great work! You&apos;ve completed this lesson. How useful was it for you?
          </p>
        </>
      )}
      {!inline && (
        <p className="mt-5 mb-4 !font-sans text-[1.2rem] font-medium  sm:text-[21px] sm:font-normal">
          How would you rate this lesson?
        </p>
      )}
      <div className="sm:float-left sm:-ml-[7px]">
        <div className="uk-position-relative uk-inline-block mb-2.5">
          <Rater
            total={5}
            rating={rating}
            onRating={onRatingHover}
            onRate={changeRating}
            uk-toggle={`target: #feedback-helper-text-${
              inline ? 'inline' : 'reveal'
            }; cls: opacity-100 sm:opacity-0; mode: hover;`}
          >
            <RaterIcon name="rounded-star" />
          </Rater>
          {!inline && (
            <a
              className="uk-position-absolute left-0 -bottom-[70px] block w-full font-medium hover:no-underline"
              onClick={skip}
            >
              Skip
            </a>
          )}
        </div>
        <div
          id={`feedback-helper-text-${inline ? 'inline' : 'reveal'}`}
          ref={helperTextRef}
          className="text-[1.2rem] font-medium !text-rb-gray-400 opacity-100 transition-opacity duration-300 sm:opacity-0"
        >
          &nbsp;
        </div>
      </div>
    </div>
  )
}

export default FeedbacksCmsSection
