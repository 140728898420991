import { getTableOfContentsSlug, slugify } from 'domains/Sanity/lib/helpers'

import {
  renderBlockquoteMark,
  renderCode,
  renderEm,
  renderLink,
  renderStrong,
  renderUnderline
} from '../../shared/componentTypes/Marks'
import { ComplexPortableTextMarkProp } from '../../types'

export const renderTableOfContents = ({
  value,
  children
}: ComplexPortableTextMarkProp) => {
  const id = getTableOfContentsSlug({
    title: value.tocTitle || (children as any)[0],
    id: value._key
  })

  return (
    <span id={id} className="relative -z-1 pt-[100px]">
      {children}
    </span>
  )
}

export const renderContentId = ({ value, children }: ComplexPortableTextMarkProp) => {
  const id = slugify(value.idAttribute)
  return (
    <span id={id} className="relative -z-1 pt-[100px]">
      {children}
    </span>
  )
}

const marks = {
  em: renderEm,
  underline: renderUnderline,
  strong: renderStrong,
  code: renderCode,
  link: renderLink,
  blockquote: renderBlockquoteMark,
  tableOfContents: renderTableOfContents,
  contentId: renderContentId
}

export default marks
