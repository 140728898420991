import ArtifactCard from 'components/cards/Content/ArtifactCard'
import { CardVariants } from 'components/cards/Content/BaseCard'
import CourseCard, {
  COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS
} from 'components/cards/Content/CourseCard'
import { SkeletonEventRelatedResourceCard } from 'components/skeletons/cards'

import { EventQuery, useEventRelatedResourcesQuery, useUserBookmarksQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

export default function EventRelatedResources({ event }: { event: EventQuery['event'] }) {
  const { data, loading } = useEventRelatedResourcesQuery({
    variables: { eventId: event.id },
    skip: !event.id
  })

  const { isLoggedIn } = useCurrentUser()

  const { data: allBookmarksData } = useUserBookmarksQuery({ skip: !isLoggedIn })

  const courseBookmarks = allBookmarksData?.currentUser?.courseBookmarks || []
  const artifactBookmarks = allBookmarksData?.currentUser?.artifactBookmarks || []

  if (!loading && !data?.eventRelatedResources.length) return null

  return (
    <div>
      <div className="mb-5 text-lg font-semibold text-black">Related Resources</div>
      <div className="flex flex-col gap-3">
        {loading ? (
          <SkeletonEventRelatedResourceCard />
        ) : (
          data?.eventRelatedResources.map((resource) => {
            switch (resource.__typename) {
              case 'Artifact': {
                const bookmark = artifactBookmarks.find(
                  (artifactBookmark) => artifactBookmark.sanityId === resource.id
                )

                const artifact = {
                  ...resource,
                  title: resource.artifactTitle,
                  slug: resource.artifactSlug
                }

                return (
                  <ArtifactCard
                    key={`event-related-resource-${artifact.id}`}
                    variant={CardVariants.Horizontal}
                    artifact={artifact}
                    hideBookmarkButton={!isLoggedIn}
                    bookmark={bookmark}
                    pageLocation="event_details_page__related_resources_section"
                    additionalRelatedIdentifiers={{
                      event_id: event.id,
                      event_title: event.name,
                      event_kind: event.kind,
                      is_completed_event: event.endsAtUtc < new Date().toISOString()
                    }}
                  />
                )
              }

              case 'MarketingCourse': {
                const bookmark = courseBookmarks.find(
                  (courseBookmark) =>
                    courseBookmark.cclCourse?.id === resource.cclCourse?.id
                )

                const marketingCourse = {
                  ...resource,
                  title: resource.marketingCourseTitle,
                  slug: resource.marketingCourseSlug
                }

                if (!marketingCourse.cclCourse) return null

                return (
                  resource.cclCourse && (
                    <CourseCard
                      key={`event-related-resource-${marketingCourse.id}`}
                      variant={CardVariants.Horizontal}
                      pageLocation="event_details_page__related_resources_section"
                      course={marketingCourse.cclCourse}
                      courseSession={marketingCourse.cclCourse.upcomingSessions?.[0]}
                      destinationType={COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS}
                      additionalRelatedIdentifiers={{
                        event_id: event.id,
                        event_title: event.name,
                        event_kind: event.kind,
                        is_completed_event: event.endsAtUtc < new Date().toISOString()
                      }}
                      hideBookmarkButton={!isLoggedIn}
                      bookmark={bookmark}
                    />
                  )
                )
              }

              case 'CclCourse': {
                const bookmark = courseBookmarks.find(
                  (courseBookmark) => courseBookmark.cclCourse?.id === resource?.id
                )
                return (
                  <CourseCard
                    key={`event-related-resource-${resource.id}`}
                    variant={CardVariants.Horizontal}
                    pageLocation="event_details_page__related_resources_section"
                    course={resource}
                    courseSession={resource.upcomingSessions?.[0]}
                    destinationType={COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS}
                    additionalRelatedIdentifiers={{
                      event_id: event.id,
                      event_title: event.name,
                      event_kind: event.kind,
                      is_completed_event: event.endsAtUtc < new Date().toISOString()
                    }}
                    hideBookmarkButton={!isLoggedIn}
                    bookmark={bookmark}
                  />
                )
              }
            }
          })
        )}
      </div>
    </div>
  )
}
