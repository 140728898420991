import React, { useState } from 'react'
import Pluralize from 'react-pluralize'

import CohortConversationThreadPost from 'domains/CohortConversation/CohortConversationThreadPost'
import ReplyPostForm from 'domains/CohortConversation/Post/ReplyPostForm'
import useDeleteCohortPost from 'domains/CohortConversation/hooks/useDeleteCohortPost'
import useDeleteCohortReply from 'domains/CohortConversation/hooks/useDeleteCohortReply'
import { CohortDashboardSidebar } from 'domains/CohortDashboard'
import { CohortDashboardSidebarProps } from 'domains/CohortDashboard/CohortDashboardSidebar'

import { ReactComponent as IconChevronLeftBold } from 'components/Breadcrumbs/BackButton/chevron-left-bold.svg'
import { CohortPostDeleteConfirmationModal } from 'components/CohortPostDeleteConfirmationModal'
import { CohortPostReportConfirmationModal } from 'components/CohortPostReportConfirmationModal'
import { useModal } from 'components/Modal'
import RfParagraphMediumSemiBold from 'components/typography/RfParagraph/RfParagraphMediumSemiBold'
import RfParagraphMiniBold from 'components/typography/RfParagraph/RfParagraphMiniBold'
import RfParagraphSmallBold from 'components/typography/RfParagraph/RfParagraphSmallBold'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import {
  CohortPostPartsFragment,
  CohortPostReplyPartsFragment,
  CohortPostTopicPartsFragment,
  CohortPostUserPartsFragment
} from 'gql'

import { ReactableType } from 'utils/reactionUtils'

interface CohortConversationThreadSidebarProps extends CohortDashboardSidebarProps {
  post?: CohortPostPartsFragment
  refetchThread?(): Promise<unknown>
  refetchPosts?(): Promise<unknown>
  currentUserId: string
  openEditPostModal(post: CohortPostPartsFragment | CohortPostReplyPartsFragment): void
  openProfileModal: (user: CohortPostUserPartsFragment) => void
  topic?: CohortPostTopicPartsFragment
  cohortSlug: string
}

const CohortConversationThreadSidebar = ({
  isOpen,
  onClose,
  post,
  refetchPosts,
  refetchThread,
  currentUserId,
  openEditPostModal,
  openProfileModal,
  topic,
  cohortSlug
}: CohortConversationThreadSidebarProps) => {
  const [deleteConfirmCallback, setDeleteConfirmCallback] = useState(() => () => {})
  const [deleteLabel, setDeleteLabel] = useState('post')
  const [reportLabel, setReportLabel] = useState('post')
  const [reportPostOrReplyId, setReportPostOrReplyId] = useState('')
  const {
    isModalOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal
  } = useModal()
  const {
    isModalOpen: isReportModalOpen,
    openModal: openReportModal,
    closeModal: closeReportModal
  } = useModal()

  const { deleteCohortReply } = useDeleteCohortReply()
  const { deleteCohortPost } = useDeleteCohortPost()

  if (!post) return null

  const handleDeletePostClick = async () => {
    await deleteCohortPost({ postId: post.id })

    refetchPosts?.()
    closeDeleteModal()
    onClose()
    setDeleteConfirmCallback(() => () => {})
  }

  const handleDeleteReplyClick = async (reply: CohortPostReplyPartsFragment) => {
    await deleteCohortReply({ replyToId: post.id, replyId: reply.id })

    refetchThread?.()
    closeDeleteModal()
    setDeleteConfirmCallback(() => () => {})
  }

  const handleReportClick = (label: 'post' | 'reply', postOrReplyId: string) => {
    setReportLabel(label)
    setReportPostOrReplyId(postOrReplyId)
    openReportModal()
  }

  const onReplyDelete = (reply: CohortPostReplyPartsFragment) => {
    setDeleteLabel('reply')
    setDeleteConfirmCallback(() => () => handleDeleteReplyClick(reply))
    openDeleteModal()
  }

  const onPostDelete = () => {
    setDeleteLabel('post')
    setDeleteConfirmCallback(() => handleDeletePostClick)
    openDeleteModal()
  }

  return (
    <CohortDashboardSidebar onClose={onClose} isOpen={isOpen}>
      <CohortPostDeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        deletePost={deleteConfirmCallback}
        label={deleteLabel}
      />

      <CohortPostReportConfirmationModal
        isOpen={isReportModalOpen}
        onClose={closeReportModal}
        label={reportLabel}
        postOrReplyId={reportPostOrReplyId}
      />

      <div className="flex items-center gap-4 border-b-1.4 border-solid border-rb-gray-50">
        <button className="inline pl-5 lg:hidden" onClick={onClose}>
          <IconChevronLeftBold width={8} height={16} />
        </button>

        <RfParagraphMediumSemiBold className="py-3 pl-6 text-rb-black">
          Thread
        </RfParagraphMediumSemiBold>
        <RfParagraphMiniBold
          color={ColorOptions.black}
          className="inline items-baseline py-3 lg:hidden"
        >
          {topic?.title}
        </RfParagraphMiniBold>
      </div>
      <div className="relative flex h-full flex-col">
        <div className="overflow-auto">
          <CohortConversationThreadPost
            post={post}
            refetchPosts={refetchPosts}
            reactableType={ReactableType.post}
            currentUserId={currentUserId}
            openEditModal={() => openEditPostModal(post)}
            onDeletePostClick={onPostDelete}
            onReportPostClick={() => handleReportClick('post', post.id)}
            openProfileModal={() => openProfileModal(post.user)}
            label="post"
            cohortSlug={cohortSlug}
          />

          {post.replies.length > 0 && (
            <>
              <div className="mt-4 flex w-full items-center gap-2 pl-6">
                <RfParagraphSmallBold>
                  <Pluralize
                    singular="reply"
                    count={post.replies.length}
                    plural="replies"
                  />
                </RfParagraphSmallBold>
              </div>
              {post.replies?.map((reply) => (
                <CohortConversationThreadPost
                  key={reply.id}
                  reply={reply}
                  refetchPosts={refetchPosts}
                  reactableType={ReactableType.reply}
                  currentUserId={currentUserId}
                  openEditModal={() => openEditPostModal(reply)}
                  onDeletePostClick={() => onReplyDelete(reply)}
                  onReportPostClick={() => handleReportClick('reply', reply.id)}
                  openProfileModal={() => openProfileModal(reply.user)}
                  label="reply"
                  post={post}
                  cohortSlug={cohortSlug}
                />
              ))}
            </>
          )}
          <ReplyPostForm refetch={refetchThread} postId={post.id} />
        </div>
      </div>
    </CohortDashboardSidebar>
  )
}

export default CohortConversationThreadSidebar
