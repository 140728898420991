import { closing, opening } from './sharedMarkdown'

const programName = 'Growth Marketing'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://www.reforge.com/programs/growth-marketing), because I will learn how to build and evolve a growth marketing strategy across diverse channels, strategies, and tactics. This course was built with [Brittany Bingham](https://www.reforge.com/experts/brittany-bingham) and [Mark Fiske](https://www.reforge.com/experts/mark-fiske), and covers:
* **Evaluate Channels.** Learn frameworks to evaluate key growth marketing channels. We'll dive into channel attributes, emerging channels, and how to scale new channels.
* **Prioritize and Invest.** Where should you focus your efforts? We've assembled approaches to prioritize your marketing portfolio and build your investment strategy.
* **Metrics and Measurement.** Deepen your understanding of how to define the right metrics to optimize growth outcomes. We'll build a plan to measure performance of your marketing strategies.
* **Strategy and Optimization.** Build your lifecycle strategy and optimize your paid acquisition portfolio to drive outcomes more effectively in the growth model. 

&NewLine;

${closing}
`

export default { markdown, title: programName }
