import CollectionsIndex from 'domains/Collections/CollectionsIndex'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

const SavedItemsPage = () => {
  const currentUser = useAssertCurrentUser()

  return (
    <div className="box-content">
      <CollectionsIndex currentUser={currentUser} />
    </div>
  )
}

export default SavedItemsPage
