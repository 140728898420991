import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import CollectionActivitySection from 'domains/Collections/CollectionActivity/CollectionActivitySection'
import CollectionHeader from 'domains/Collections/CollectionHeader'
import CollectionsList from 'domains/Collections/CollectionsList'
import NoAccess from 'domains/Collections/NoAccess'

import {
  MostRecentCollectionActivityPerUserDocument,
  MostRecentCollectionActivityPerUserQuery,
  MostRecentCollectionActivityPerUserQueryVariables,
  useBookmarksFromFolderQuery,
  useViewBookmarkFolderMutation
} from 'gql'

interface CollectionShowProps {
  editOnShow?: boolean
}

const CollectionShow = ({ editOnShow = false }: CollectionShowProps) => {
  const { id } = useParams<{ id: string }>()
  const [currentPage, setCurrentPage] = useState(1)

  const { data, loading, error } = useBookmarksFromFolderQuery({
    variables: { folderId: id },
    fetchPolicy: 'cache-and-network'
  })

  const handlePagination = (page: number) => {
    if (page !== currentPage) {
      setCurrentPage(page)
      document.getElementById('page')?.scrollTo(0, 0)
    }
  }

  const [registerCollectionView] = useViewBookmarkFolderMutation({
    variables: { input: { bookmarkFolderId: id } },
    update: (cache, mutationResult) => {
      const { data } = mutationResult
      if (!data) return

      const { viewBookmarkFolder } = data
      const activity = viewBookmarkFolder?.activity
      const bookmarkFolder = viewBookmarkFolder?.bookmarkFolder

      if (!activity || !bookmarkFolder) return

      const variables = {
        collectionId: bookmarkFolder?.id,
        userIds: bookmarkFolder?.usersSharedWith.map((user) => user.id)
      }

      const activityQuery = cache.readQuery<
        MostRecentCollectionActivityPerUserQuery,
        MostRecentCollectionActivityPerUserQueryVariables
      >({
        query: MostRecentCollectionActivityPerUserDocument,
        variables
      }) as MostRecentCollectionActivityPerUserQuery

      const existingActivity = activityQuery.mostRecentCollectionActivityPerUser.find(
        (activityInCache) => activityInCache.user.id === activity.user.id
      )

      const dataToWrite: MostRecentCollectionActivityPerUserQuery = {
        __typename: 'Query',
        mostRecentCollectionActivityPerUser: [
          ...activityQuery.mostRecentCollectionActivityPerUser
        ]
      }

      if (existingActivity) {
        const index = dataToWrite.mostRecentCollectionActivityPerUser.findIndex(
          (activityInCache) => activityInCache.user.id === activity.user.id
        )
        dataToWrite.mostRecentCollectionActivityPerUser[index] = activity
      } else {
        dataToWrite.mostRecentCollectionActivityPerUser.push(activity)
      }

      cache.writeQuery<MostRecentCollectionActivityPerUserQuery>({
        query: MostRecentCollectionActivityPerUserDocument,
        variables,
        data: {
          ...dataToWrite
        }
      })
    }
  })

  useEffect(() => {
    // Only register the view if we were able to fetch the bookmark folder
    if (!loading && !error && data && data.bookmarkFolder) {
      registerCollectionView()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, error, data])

  const currentFolder = data?.bookmarkFolder
  const bookmarks = data?.bookmarksFromFolder || []

  if (!loading && !currentFolder) return <NoAccess />

  return (
    <div className="mx-auto w-full gap-x-8 pb-6">
      <CollectionHeader currentFolder={currentFolder} editOnShow={editOnShow} />
      <div className="flex w-full">
        <div className="w-3/4">
          <CollectionsList
            currentFolder={currentFolder}
            bookmarks={bookmarks}
            bookmarksCount={bookmarks?.length}
            loading={loading}
            error={error}
            handlePagination={handlePagination}
            currentPage={currentPage}
          />
        </div>
        <div className="-mt-5 ml-4 w-1/4">
          {currentFolder && <CollectionActivitySection collectionId={currentFolder.id} />}
        </div>
      </div>
    </div>
  )
}

export default CollectionShow
