import { ConceptIcon, LessonIcon, ProjectIcon } from 'components/icons'

import { CmsSectionContentType } from 'gql'

export function getContentTypeIcon(contentType: string) {
  switch (contentType) {
    case CmsSectionContentType.LESSON || CmsSectionContentType.SECTION:
      return <LessonIcon className="mr-1.5" />
    case CmsSectionContentType.CONCEPT:
      return <ConceptIcon className="mr-1.5" />
    case CmsSectionContentType.PROJECT:
      return <ProjectIcon className="mr-1.5" />
    default:
      return <></>
  }
}
