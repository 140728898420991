import React from 'react'
import { ArrowContainer, Popover, PopoverPosition } from 'react-tiny-popover'
import { twMerge } from 'tailwind-merge'

import useDropdown from 'components/dropdowns/Dropdown/useDropdown'

import { onEnterKeyPress } from 'utils/keyboard'

import PopupMenuItem from './PopupMenuItem'

interface PopupMenuProps {
  id: string
  content: React.ReactElement
  children: React.ReactElement
  onClickOutside?: () => void
  positions?: PopoverPosition[]
  containerClassName?: string
}

const PopupMenu = ({
  id,
  children,
  content,
  positions = ['top', 'left'],
  containerClassName
}: PopupMenuProps) => {
  const { isOpen, setIsOpen, toggleDropdown: togglePopupMenu } = useDropdown()
  const ref = React.useRef() as React.MutableRefObject<HTMLDivElement>

  return (
    <Popover
      data-testid={id}
      isOpen={isOpen}
      positions={positions}
      reposition={false}
      parentElement={ref.current}
      onClickOutside={() => setIsOpen(false)}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor="#E5E3E4"
          arrowSize={10}
        >
          <div
            className={twMerge(
              'flex flex-col bg-rb-gray-100 py-4 px-5 text-rb-gray-400',
              containerClassName
            )}
            onClick={() => setIsOpen(false)}
          >
            {content}
          </div>
        </ArrowContainer>
      )}
    >
      <div
        role="button"
        tabIndex={0}
        ref={ref}
        onClick={togglePopupMenu}
        onKeyUp={onEnterKeyPress(togglePopupMenu)}
      >
        {children}
      </div>
    </Popover>
  )
}

PopupMenu.Item = PopupMenuItem

export default PopupMenu
