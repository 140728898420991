import { formatMoney } from 'utils/moneyUtils'

export const PlanAndPricingInfo = ({ price }: { price?: number | null }) => {
  return (
    <div className="flex flex-wrap items-center">
      {price ? (
        <>
          <span className="text-xl font-medium text-rb-black">{formatMoney(price)}</span>
          <span className="mx-1 font-medium text-rb-black">/</span>
          <span className="mr-2 text-base font-medium text-rb-gray-300">
            year + sales tax
          </span>
        </>
      ) : (
        <p className="m-0 text-base font-semibold leading-7">Custom Pricing</p>
      )}
    </div>
  )
}
