import { useLottie } from 'lottie-react'
import { useEffect } from 'react'

import confettiAnimation from './confetti.json'

const FullScreenConfetti = () => {
  const { View, play } = useLottie({
    animationData: confettiAnimation,
    loop: false,
    autoplay: false
  })

  useEffect(() => {
    setTimeout(() => {
      play()
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="pointer-events-none absolute inset-0 h-screen w-screen">{View}</div>
  )
}

export default FullScreenConfetti
