import { ReactComponent as ChevronLeft } from 'chevron-left.svg'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { BackToParams } from 'pages/UnitLandingPage/UnitLandingPage'

import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'

import { onEnterKeyPress } from 'utils/keyboard'

interface BreadcrumbProps {
  id: string
  onClick?: () => void
  title: string
  backTo?: BackToParams
}

export const Breadcrumb = ({ id, onClick, backTo, title }: BreadcrumbProps) => {
  return (
    <div
      id={id}
      className="sticky top-0 z-10 mb-2 flex w-full items-center bg-white p-2 pb-2 sm:mb-6"
    >
      <RfParagraphMini className="flex items-center gap-2">
        {backTo &&
          backTo.map(({ label, path }, index) => (
            <Fragment key={label}>
              <Link
                to={path}
                className="group flex items-center gap-2 text-rb-gray-300 hover:text-rb-teal-400 hover:no-underline"
                onClick={onClick}
                onKeyUp={onClick && onEnterKeyPress(onClick)}
              >
                {index === 0 && (
                  <span className="rounded-full border border-rb-gray-100 p-2 text-rb-gray-100 group-hover:text-rb-teal-400">
                    <ChevronLeft className="h-[17px] min-w-[17px] stroke-current" />
                  </span>
                )}
                {label}
              </Link>

              <span className="hidden sm:block">/</span>
            </Fragment>
          ))}
        <span className="hidden flex-1 font-semibold text-rb-gray-400 sm:block">
          {title}
        </span>
      </RfParagraphMini>
    </div>
  )
}
