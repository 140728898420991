import { PortableTextBlock } from '@portabletext/types'

import { UnitComplexPortableText } from 'domains/Sanity/PortableText/UnitComplexPortableText/UnitComplexPortableText'

import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'

import { UnitBlockContainer } from './UnitBlockContainer'

interface UnitOverviewSectionProps {
  overview: PortableTextBlock[]
  whatYouWillDo?: PortableTextBlock[]
}

export const UnitOverviewSection = ({
  overview,
  whatYouWillDo
}: UnitOverviewSectionProps) => {
  if (!overview) return null

  return (
    <UnitBlockContainer id="overview" className="scroll-mt-16">
      <RfHeader3SemiBold>Overview</RfHeader3SemiBold>
      <UnitComplexPortableText content={overview} />

      {whatYouWillDo && (
        <>
          <RfHeader3SemiBold className="!mt-10">What you&apos;ll do</RfHeader3SemiBold>
          <UnitComplexPortableText content={whatYouWillDo} />
        </>
      )}
    </UnitBlockContainer>
  )
}

export default UnitOverviewSection
