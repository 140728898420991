import { LinkedInIcon, TwitterXIcon } from 'components/icons'

import { ArtifactAuthor, ExpertUserProfileFragmentFragment } from 'gql'

import CtaButton from './CtaButton'

type ProfileSideBarProps = {
  userProfile: ExpertUserProfileFragmentFragment
  authorProfile: ArtifactAuthor
}

const SocialsAndCTA = ({ userProfile, authorProfile }: ProfileSideBarProps) => {
  return (
    <>
      {(!!userProfile?.linkedin || !!userProfile?.twitter) && (
        <div className="flex items-center gap-3">
          <CtaButton userProfile={userProfile} slug={authorProfile.slug || ''} />
          {!!userProfile?.linkedin &&
            !/http/.test(userProfile?.linkedin) &&
            !/linkedin/.test(userProfile?.linkedin) && (
              <a
                className="text-rb-black no-underline"
                href={`https://linkedin.com/in/${userProfile?.linkedin}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon width={22} height={22} />
              </a>
            )}

          {!!userProfile?.twitter && (
            <a
              className="text-rb-black no-underline"
              href={userProfile?.twitter}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterXIcon width={16} height={16} />
            </a>
          )}
        </div>
      )}
    </>
  )
}

export default SocialsAndCTA
