import { Helmet } from 'react-helmet'

import Image from 'domains/Sanity/Image'

import useMediaQuery from 'hooks/useMediaQuery'

import { cn } from 'utils/tailwind'

import expertCard0 from './expertCardImages/expert-card-0.png'
import expertCard1 from './expertCardImages/expert-card-1.png'
import expertCard2 from './expertCardImages/expert-card-2.png'
import expertCard3 from './expertCardImages/expert-card-3.png'
import expertCard4 from './expertCardImages/expert-card-4.png'
import expertCard5 from './expertCardImages/expert-card-5.png'
import expertCard6 from './expertCardImages/expert-card-6.png'
import expertCard7 from './expertCardImages/expert-card-7.png'
import expertCard8 from './expertCardImages/expert-card-8.png'
import expertCard9 from './expertCardImages/expert-card-9.png'
import expertCard10 from './expertCardImages/expert-card-10.png'
import expertCard11 from './expertCardImages/expert-card-11.png'

const images = [
  expertCard0,
  expertCard1,
  expertCard2,
  expertCard3,
  expertCard4,
  expertCard5,
  expertCard6,
  expertCard7,
  expertCard8,
  expertCard9,
  expertCard10,
  expertCard11
]

const ExpertsCarousel = () => {
  const showMask = useMediaQuery('(min-width: 2560px)')

  return (
    <div
      className={cn(
        'w-max max-w-[2560px] mx-auto overflow-hidden',
        showMask &&
          '[&]:[mask-image:linear-gradient(to_right,transparent_1%,black_10%,black_90%,transparent_99%)]'
      )}
    >
      <Helmet>
        {images.map((image, i) => (
          <link key={i} rel="preload" href={image} as="image" />
        ))}
      </Helmet>
      <div className="w-max relative flex items-center">
        {/* We render twice the same component to create the infinite effect without jumping to start */}
        <Images />
        <Images />
      </div>
    </div>
  )
}

const Images = () => {
  return (
    <div className="flex items-center gap-4 lg:gap-6 animate-continuousSlideRight pr-4 lg:pr-6">
      {images.map((image, i) => (
        <Image
          key={i}
          src={image}
          alt=""
          className="h-[160px] sm:h-[220px] lg:h-[340px] aspect-[0.82/1]"
          priority
        />
      ))}
    </div>
  )
}

export default ExpertsCarousel
