import * as React from 'react'

import LinkTags from 'components/LinkTags'

import {
  ABOUT_MY_PRODUCT_TAB,
  FREE_TEXT_CLASSES,
  HEADING_CLASSES,
  SUBHEADING_CLASSES,
  TOOLTIP_STRING,
  WHAT_IM_SEEKING_TAB
} from 'constants/profile'

import EditableSection from './EditableSection'

interface IWorkSection {
  businessModelTags?: string | null
  canEdit: boolean
  companyAudienceTags?: string | null
  companyProductText?: string | null
  handleEditClick: (tab: number, field?: string) => void
  learningGoalsText?: string | null
}

const WorkSection = ({
  businessModelTags,
  canEdit,
  companyAudienceTags,
  companyProductText,
  handleEditClick,
  learningGoalsText
}: IWorkSection) => {
  const showWorkSection =
    canEdit ||
    businessModelTags ||
    companyAudienceTags ||
    companyProductText ||
    learningGoalsText
  const showAboutMyProductSection =
    canEdit || businessModelTags || companyAudienceTags || companyProductText
  const showExcitedToLearnSection = canEdit || learningGoalsText

  if (!showWorkSection) return null

  return (
    <div data-test="member-profile-work-section">
      <div className={HEADING_CLASSES}>Work</div>
      {showAboutMyProductSection && (
        <>
          {' '}
          <div className={SUBHEADING_CLASSES}>About the product I work on</div>
          <div className="flex flex-wrap">
            <LinkTags
              input={businessModelTags || ''}
              filterName="business_model[]"
              tooltipString={TOOLTIP_STRING}
            />
            <LinkTags
              input={companyAudienceTags || ''}
              filterName="company_audience[]"
              tooltipString={TOOLTIP_STRING}
            />
          </div>
        </>
      )}
      {companyProductText && (
        <div className={FREE_TEXT_CLASSES}>{companyProductText}</div>
      )}
      {!companyProductText && canEdit && (
        <EditableSection
          handleEditClick={() =>
            handleEditClick(ABOUT_MY_PRODUCT_TAB, 'company_product_textarea')
          }
          text="Tell us more about your company’s product and what you do there"
          ctaText="Edit Section"
          dataTest="member-profile-edit-product-cta"
        />
      )}
      {showExcitedToLearnSection && (
        <>
          <div className={SUBHEADING_CLASSES}>I’m excited to learn</div>
          {learningGoalsText && (
            <div className={FREE_TEXT_CLASSES}>{learningGoalsText}</div>
          )}
          {!learningGoalsText && canEdit && (
            <EditableSection
              handleEditClick={() =>
                handleEditClick(WHAT_IM_SEEKING_TAB, 'excited_to_learn_textarea')
              }
              text="What do you hope to learn from the content and the Reforge community you’ve joined?"
              ctaText="Edit Section"
            />
          )}
        </>
      )}
    </div>
  )
}

export default WorkSection
