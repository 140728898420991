import { Maybe } from 'graphql/jsutils/Maybe'
import React, { useEffect } from 'react'

import EndOfModuleOverlay from './EndOfModuleOverlay'
import NextLessonOverlay, { NextLessonData } from './NextLessonOverlay'

interface VideoOverlayProps {
  currentModuleName: string
  contentType: string
  isOpen: boolean
  nextLessonData: Maybe<NextLessonData>
  onClose: () => void
}

const useVideoControlsWithOverlay = () => {
  useEffect(() => {
    const videoControlNode = document.querySelector<HTMLElement>('.w-bottom-bar-lower')

    if (videoControlNode) {
      // Bump zIndex of wistia toolbar in order to appear above the overlay.
      videoControlNode.style.zIndex = '10'
    }
  }, [])
}

const VideoOverlay = ({
  currentModuleName,
  contentType,
  isOpen,
  nextLessonData,
  onClose
}: VideoOverlayProps) => {
  useVideoControlsWithOverlay()

  if (!isOpen) return null

  return (
    <OverlayContainer>
      {nextLessonData ? (
        // Need a cancel button that closes overlay using above `onClose` prop.
        <NextLessonOverlay
          contentType={contentType}
          nextLessonData={nextLessonData}
          onClose={onClose}
        />
      ) : (
        <EndOfModuleOverlay currentModuleName={currentModuleName} />
      )}
    </OverlayContainer>
  )
}

interface OverlayContainerProps {
  children: React.ReactNode
}

const OverlayContainer = ({ children }: OverlayContainerProps) => (
  <div className="overlay pointer-events-none absolute z-[1] flex h-[100.25%] w-full items-center justify-center bg-black/[0.7]">
    {children}
  </div>
)

export default VideoOverlay
