const SharedBookmarkFolderEmptyState = () => {
  return (
    <div className="flex flex-col items-center justify-center rounded-xl bg-rb-gray-20 p-8">
      <div className="font-semibold">
        There aren&apos;t any collections shared with you yet
      </div>
      <div className="text-rb-gray-300">When others share, they&apos;ll appear here</div>
    </div>
  )
}

export default SharedBookmarkFolderEmptyState
