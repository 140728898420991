import React, { useEffect } from 'react'
import { validate } from 'uuid'

import HardcodedSearchResults from 'domains/Search/HardcodedSearchResults'
import {
  ResultHighlights,
  ResultHighlightsProvider
} from 'domains/Search/ResultHighlights'
import SearchResultsList from 'domains/Search/SearchResultsList'
import SearchTopResultsList from 'domains/Search/SearchTopResultsList'
import SimilaritySearchResultsList from 'domains/Search/SimilaritySearchResultsList'
import SimilaritySearchTopResultsList from 'domains/Search/SimilaritySearchTopResultsList'
import useSearchResultsFilters, {
  SearchLinkType
} from 'domains/Search/useSearchResultsFilters'

import { ErrorMessage, Loading } from 'components'
import { ShareableSnapshotModal } from 'components/ShareableSnapshotModal'
import NewProgramBanner from 'components/banners/TopBanner/NewProgramBanner'

import {
  SimilaritySearchContent,
  useSearchDocumentsQuery,
  useSimilaritySearchQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import useURLParams from 'hooks/useURLParams'

import { trackContentClicked } from 'utils/tracking/analytics'

export interface SearchPageProps {
  searchQuery: string
  searchType: string
  programSlug: string
  topicSlug: string
  page: number
  path: string
}

const SearchPageContainer = () => {
  const { getParam } = useURLParams()
  const searchQuery = getParam('q')
  const searchType = getParam('type')
  const programSlug = getParam('program')
  const topicSlug = getParam('topic')
  const page = +getParam('page', '1')
  const path = getParam('path')

  useEffect(() => {
    // reset scroll position when search query changes
    document.getElementById('page')?.scrollTo(0, 0)
  }, [searchQuery])

  return (
    <SearchPage
      searchQuery={searchQuery}
      searchType={searchType}
      programSlug={programSlug}
      topicSlug={topicSlug}
      page={page}
      path={path}
    />
  )
}

export const SearchPage = ({
  searchQuery,
  searchType,
  programSlug,
  topicSlug,
  page,
  path
}: SearchPageProps) => {
  const { currentUser, currentUserLoading, currentUserError } = useCurrentUser()
  const { showAiSidekick, freeAi } = useFeatureFlags()

  const { loading, error, data } = useSearchDocumentsQuery({
    variables: {
      query: searchQuery,
      type: searchType,
      page,
      program: programSlug,
      topic: topicSlug,
      path
    },
    skip: searchType !== SearchLinkType.USER
  })

  const {
    loading: similarityLoading,
    data: similarityData,
    error: similarityError
  } = useSimilaritySearchQuery({
    variables: {
      query: searchQuery,
      type: searchType,
      initiator: path
    },
    skip: !searchQuery || searchType === SearchLinkType.USER
  })

  const searchSuggestions = similarityData?.similaritySearch?.suggestions || []

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const searchId = similarityData?.similaritySearch.id || null

  const searchDocuments = data?.searchDocuments?.results || []
  const searchResultsTotalCount = data?.searchDocuments?.totalCount || 0
  const similarResultsTotalCount = similarityData?.similaritySearch
    ? similarityData.similaritySearch.results.length
    : 0
  const userCan = currentUser?.can || {
    linkToSearchResults: false,
    viewMemberProfile: false,
    viewPost: false,
    viewSearchTabs: false
  }

  useSearchResultsFilters({ searchType, userCan, searchQuery })

  if (currentUserLoading) return <Loading />
  if (currentUserError || error || similarityError) {
    return <ErrorMessage error={currentUserError || similarityError || error} />
  }

  const calculateResultPosition = (page: number, searchPosition: number) => {
    const pagesAfterFirst = page - 1
    const offset = pagesAfterFirst * 10
    return offset + searchPosition
  }

  const handleBaseResultClick = (
    topResult: boolean,
    url: string,
    path: string,
    searchPosition: number,
    resultComponent: string,
    contentType?: string,
    subType?: string | null,
    doc?: SimilaritySearchContent | null
  ) => {
    const resultPosition = calculateResultPosition(page, searchPosition)

    const documentId = doc?.id || ''
    const eventObj = {
      content_sanity_id: validate(documentId) ? doc?.id : null,
      content_title: doc?.title,
      content_type: contentType,
      display_type: 'horizontal_card',
      location: 'search',
      related_identifiers: {
        search_query: searchQuery,
        search_result_component: resultComponent,
        search_number_of_results: searchResultsTotalCount + similarResultsTotalCount,
        search_result_position: `${resultPosition}`,
        search_is_top_result: topResult,
        content_mode: 'async'
      }
    }

    try {
      trackContentClicked(eventObj)
    } catch (e) {
      console.error(e)
    }
  }

  const handleSimilarityResultClick = handleBaseResultClick.bind(null, false)

  const handleSimilarityResultClickTop = (
    topResult: boolean,
    url: string,
    path: string,
    searchPosition: number,
    resultComponent: string,
    contentType: string,
    subType: string | null,
    doc: SimilaritySearchContent | null
  ) => {
    // Provide default values for string parameters that could be undefined
    const safeResultComponent = resultComponent || ''
    const safeContentType = contentType || ''
    const safeSubType = subType || ''

    // // Call handleBaseResultClick with the safe string values
    handleBaseResultClick(
      topResult,
      url,
      path,
      searchPosition,
      safeResultComponent,
      safeContentType,
      safeSubType,
      doc
    )
  }

  const handleAnchorResultClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    searchPosition: number,
    resultComponent: string,
    contentType?: string,
    subType?: string | null
  ) => {
    const currentHref = e.currentTarget.href || ''
    const currentPath = e.currentTarget.pathname
    // This handler is only used for Member results, and so `topResult` is always false.
    handleBaseResultClick(
      false,
      currentHref,
      currentPath,
      searchPosition,
      resultComponent,
      contentType,
      subType
    )
  }

  return (
    <>
      <NewProgramBanner hasBorder className="mb-6" />

      <ShareableSnapshotModal page="Search" />

      <ResultHighlightsProvider query={searchQuery} searchType={searchType}>
        <div className="flex gap-8">
          <div id="list" className="flex w-full max-w-5xl flex-col gap-8">
            <HardcodedSearchResults currentUser={currentUser} searchQuery={searchQuery} />

            {/* ALL TAB */}
            {!searchType && (
              <>
                <SimilaritySearchTopResultsList
                  key={`top-1-${searchQuery}`}
                  linkType={SearchLinkType.CMS_SECTION}
                  searchQuery={searchQuery}
                  handleResultClick={handleSimilarityResultClickTop}
                  userCan={userCan}
                  itemsPerSection={3}
                  loading={similarityLoading}
                  searchResults={similarityData?.similaritySearch?.results}
                  suggestions={searchSuggestions}
                />
                {/* 👇 Only displaying user directory, other content types were migrated to similarity search */}
                <SearchTopResultsList
                  key={`top-2-${searchQuery}`}
                  searchQuery={searchQuery}
                  handleResultClick={handleAnchorResultClick}
                  userCan={userCan}
                />
              </>
            )}

            {/* PROGRAM CONTENT TAB */}
            {!!searchType && searchType === SearchLinkType.CMS_SECTION && (
              <SimilaritySearchResultsList
                key={`sim-lessons-1-${searchQuery}`}
                searchQuery={searchQuery}
                handleResultClick={handleSimilarityResultClick}
                userCan={userCan}
                sectionHeader="Program Content"
                linkType={SearchLinkType.CMS_SECTION}
                hideHeader={true}
                loading={similarityLoading}
                searchResults={similarityData?.similaritySearch?.results}
              />
            )}

            {/* COURSES TAB */}
            {!!searchType && searchType === SearchLinkType.COURSE && (
              <SimilaritySearchResultsList
                key={`sim-courses-1-${searchQuery}`}
                searchQuery={searchQuery}
                handleResultClick={handleSimilarityResultClick}
                userCan={userCan}
                sectionHeader="Courses"
                linkType={SearchLinkType.COURSE}
                hideHeader={true}
                loading={similarityLoading}
                searchResults={similarityData?.similaritySearch?.results}
              />
            )}

            {/* ARTIFACTS TAB */}
            {!!searchType && searchType === SearchLinkType.ARTIFACT && (
              <SimilaritySearchResultsList
                key={`sim-artifacts-1-${searchQuery}`}
                searchQuery={searchQuery}
                handleResultClick={handleSimilarityResultClick}
                userCan={userCan}
                sectionHeader="Artifacts"
                linkType={SearchLinkType.ARTIFACT}
                hideHeader={true}
                loading={similarityLoading}
                searchResults={similarityData?.similaritySearch?.results}
              />
            )}

            {/* GUIDES TAB */}
            {!!searchType && searchType === SearchLinkType.GUIDE && (
              <SimilaritySearchResultsList
                key={`sim-guides-1-${searchQuery}`}
                searchQuery={searchQuery}
                handleResultClick={handleSimilarityResultClick}
                userCan={userCan}
                sectionHeader="Guides"
                linkType={SearchLinkType.GUIDE}
                hideHeader={true}
                loading={similarityLoading}
                searchResults={similarityData?.similaritySearch?.results}
              />
            )}

            {/* DIRECTORY TAB */}
            {!!searchType &&
              searchType === SearchLinkType.USER &&
              userCan.viewSearchTabs &&
              !currentUser?.is.trialing && (
                <SearchResultsList
                  searchDocuments={searchDocuments}
                  searchQuery={searchQuery}
                  searchType={searchType}
                  loading={loading}
                  page={page}
                  searchResultsTotalCount={searchResultsTotalCount}
                  userCan={userCan}
                  handleResultClick={handleAnchorResultClick}
                />
              )}
          </div>

          {!searchType && (showAiSidekick || freeAi) && (
            <div className="hidden w-full max-w-sm lgr:block">
              <ResultHighlights key={searchQuery} />
            </div>
          )}
        </div>
      </ResultHighlightsProvider>
    </>
  )
}

export default SearchPageContainer
