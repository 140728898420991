import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Loading } from 'components'
import RfHeader2 from 'components/typography/RfHeader/RfHeader2'

import { useCourseCheckoutPageQuery } from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { CourseCheckoutInfoSidebar } from './CourseCheckoutInfoSidebar'
import { CourseCheckoutStartDateSection } from './CourseCheckoutStartDateSection'

export const CourseCheckoutPage = () => {
  const { courseSlug } = useParams<{ courseSlug: string }>()
  const [selectedCourseSessionId, setSelectedCourseSessionId] = useState('')
  const { data, loading } = useCourseCheckoutPageQuery({
    variables: { slug: courseSlug }
  })

  const { courseCheckoutV2, userIdentified } = useFeatureFlags()

  if (loading || !data || !data.cclCourse || !userIdentified) {
    return <Loading />
  }

  if (
    (userIdentified && !courseCheckoutV2) ||
    !data.cclCourse ||
    !data.upcomingCourseSessions ||
    data.upcomingCourseSessions.length < 1
  ) {
    window.location.href = '/courses'
  }

  const currentUser = data.currentUser
  const upcomingCourseSessions = data.upcomingCourseSessions!

  if (!selectedCourseSessionId) {
    setSelectedCourseSessionId(upcomingCourseSessions[0].id)
  }

  return (
    <div className="flex flex-col lg:flex-row mt-[160px] md:mt-[172px] lg:mt-0">
      <CourseCheckoutInfoSidebar
        course={data.cclCourse}
        courseSessions={upcomingCourseSessions}
        selectedCourseSessionId={selectedCourseSessionId}
      />
      <div className="w-full flex flex-col p-4 md:p-12">
        <RfHeader2>Enroll in this course</RfHeader2>
        <SectionBreak />
        <CourseCheckoutStartDateSection
          courseSessions={upcomingCourseSessions}
          selectedCourseSessionId={selectedCourseSessionId}
          setSelectedCourseSessionId={setSelectedCourseSessionId}
          timezone={currentUser?.timezone || undefined}
        />
        <SectionBreak />
      </div>
    </div>
  )
}

const SectionBreak = () => {
  return <div className="mb-3 border-t border-gray-100 md:mt-9 md:mb-12" />
}
