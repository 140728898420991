import { useLayoutEffect } from 'react'

import notifyError from 'utils/errorNotifier'

/**
 * useIntersectionObserver
 *
 * You can use this hook to observe the intersection of elements.
 * by default, it will observe the intersection of the elements with the viewport.
 * You can also pass options to the IntersectionObserver to customize the behavior.
 *
 * The hook will apply the classes in the highlightClasses to the element
 *
 * @param querySelector - querySelector for the elements to observe
 * @param highlightClasses - array of classes to add to the element when it is intersecting
 * @param options - IntersectionObserverInit
 */

const useTableOfContentHighlighter = (
  querySelector: string | null,
  highlightClasses: string[],
  options: IntersectionObserverInit
) => {
  useLayoutEffect(() => {
    if (!querySelector) {
      return
    }

    try {
      const elements = document.querySelectorAll(querySelector)

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            document
              .querySelector(`[href="#${entry.target.id}"]`)
              ?.classList.add(...highlightClasses)
          } else {
            document
              .querySelector(`[href="#${entry.target.id}"]`)
              ?.classList.remove(...highlightClasses)
          }
        })
      }, options)

      elements.forEach((section) => {
        observer.observe(section)
      })

      return () => {
        elements.forEach((section) => {
          observer.unobserve(section)
        })
      }
    } catch (error) {
      notifyError(error)
    }
  }, [querySelector, highlightClasses, options])
}

export default useTableOfContentHighlighter
