const UserPositionAndCompany = ({
  companyName,
  position
}: {
  companyName?: string | null
  position?: string | null
}) => {
  if (companyName && position) {
    return (
      <span>
        {position} - {companyName}
      </span>
    )
  }

  return <span>{companyName || position}</span>
}

export default UserPositionAndCompany
