import { cn } from 'utils/tailwind'

const BaseCardPlayIcon = ({ className }: { className?: string }) => (
  <div
    className={cn(
      'flex h-[57px] w-[57px] items-center justify-center rounded-full bg-black bg-opacity-40',
      className
    )}
  >
    <svg
      width="23"
      height="27"
      viewBox="0 0 23 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ml-1.5"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9375 13.5C22.9392 14.2122 22.5674 14.8732 21.9578 15.2415L3.385 26.6033C2.74737 26.9938 1.94837 27.0086 1.29672 26.642C0.651329 26.2811 0.251079 25.5999 0.25 24.8605V2.13949C0.251079 1.40007 0.651329 0.718859 1.29672 0.358008C1.94837 -0.0085659 2.74737 0.00623035 3.385 0.39668L21.9578 11.7585C22.5674 12.1268 22.9392 12.7878 22.9375 13.5Z"
        fill="white"
      />
    </svg>
  </div>
)

export default BaseCardPlayIcon
