import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import SkeletonRfParagraph from 'components/skeletons/typography/SkeletonRfParagraph'

interface SectionCardSkeletonProps {
  className?: string
}

export const SectionCardContainerSkeleton = ({ className }: SectionCardSkeletonProps) => (
  <div
    className={twMerge(
      'border-bg-rb-gray-300 flex h-24 w-full animate-pulse flex-row space-x-4 rounded-2xl border p-5',
      className
    )}
  >
    <SkeletonRfParagraph className="mt-3 h-4 w-6" />
    <div className="mt-3 flex h-full w-full flex-col space-y-1">
      <SkeletonRfParagraph className="h-4 w-1/2" />
      <SkeletonRfParagraph className="h-2 w-1/4" />
    </div>
  </div>
)

interface SectionCardContainerProps {
  children: ReactNode
  className?: string
  darkenBorderOnHover?: boolean
}

const SectionCardContainer = ({
  children,
  className,
  darkenBorderOnHover = true
}: SectionCardContainerProps) => (
  <div
    className={twMerge(
      `border-bg-rb-gray-300 mb-4 w-full rounded-xl border hover:cursor-pointer ${
        darkenBorderOnHover && 'hover:border-rb-gray-500'
      }`,
      className
    )}
  >
    {children}
  </div>
)

export default SectionCardContainer
