export const AvailableSeatTooltipContent = () => {
  return (
    <>
      <p className="m-0 font-medium">Email does not match company domain.</p>
      <p className="m-0">
        This team member can add a matching email in the connected accounts section of
        their settings.
      </p>
    </>
  )
}

export default AvailableSeatTooltipContent
