import React, { useState } from 'react'
import UIkit from 'uikit'

import ContentProgressTracker from 'domains/Cms/ContentProgressTracker'
import CtaButtons from 'domains/Cms/CtaButtons'
import ExpertCollaborators from 'domains/Cms/ExpertCollaborators'
import HeaderBookmark from 'domains/Cms/HeaderBookmark'
import TocItem from 'domains/Cms/TocItem'
import UserProjectLinks from 'domains/Cms/UserProjectLinks'

import { SVGIcon } from 'components/Icon'
import { useSideDrawer } from 'components/SideDrawer'
import { LessonContentLocation } from 'components/cards/Content/utils'
import { CloseIcon } from 'components/icons'

import {
  CmsSectionContentType,
  ContentBookmarkAnchorPartsFragment,
  ContentTocItemPartsFragment,
  ContentViewerTocPartsFragment,
  ExpertUserPartsFragment,
  Maybe
} from 'gql'

import { sendData } from 'utils/sendData'
import { trackContentCompleted, trackContentStarted } from 'utils/tracking/analytics'

import {
  UpdateContentBlockProgressPayload,
  UserProgramUpdateContentProgressPayload
} from 'typings/payloads'
import { CmsSectionProgress, ModuleProgress, ProgressStatus } from 'typings/scalars'

const getTopOffset = () => {
  let topOffset = 0
  const stickyTop = document.getElementById('sticky-top')
  if (stickyTop) {
    const boundingRect = stickyTop.getBoundingClientRect()
    topOffset = boundingRect.height
  }
  return topOffset
}

const itemWithProgress = (
  item: ContentTocItemPartsFragment,
  progress: CmsSectionProgress
) => {
  return {
    ...item,
    href: item.href || '',
    completed: progress[item.id] === 'complete'
  }
}

const optionalStep = (item: ContentTocItemPartsFragment) => {
  const lowerTitle = item.name?.toLowerCase()
  return (
    lowerTitle && (lowerTitle.endsWith('(optional)') || lowerTitle.includes('optional:'))
  )
}

interface ItemProps {
  item: ContentTocItemPartsFragment
  progress: CmsSectionProgress
}

const Item = ({ item, progress }: ItemProps) => {
  const hasChildren = item.children && item.children.length > 0

  const [isOpen, setIsOpen] = useState(true)

  const onToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div
      className={`${isOpen ? 'uk-open' : ''} ${hasChildren ? 'mt-[18px] mb-[1px]' : ''}`}
    >
      <div className="border-rb-green-100 text-rb-gray-300"></div>
      <TocItem
        item={itemWithProgress(item, progress)}
        isParent={!!hasChildren}
        isOpen={isOpen}
        onToggle={onToggle}
      />
      {hasChildren && (
        <ul className="uk-accordion-content mt-0 mb-0 list-none p-0">
          {item.children?.map((childItem) => (
            <TocItem
              key={`tocitem${childItem.id}`}
              item={itemWithProgress(childItem, progress)}
              isOpen={isOpen}
            />
          ))}
        </ul>
      )}
    </div>
  )
}

type ListenerFunc = (e: any) => void

interface ICmsTocState {
  items: ContentTocItemPartsFragment[]
  progress: CmsSectionProgress
  itemMonitoring: ItemMonitoringData
  experts: ExpertUserPartsFragment[]
  percentChangeListeners: ListenerFunc[]
  userProjectEls: HTMLElement[]
}

interface ICmsContentToc {
  userProgramId?: string
  userProgress: ModuleProgress
  contentBookmarkAnchors?: ContentBookmarkAnchorPartsFragment[]
  contentBookmarkAnchorsInMySavedItems?: ContentBookmarkAnchorPartsFragment[]
  currentContentId?: string
  currentContentName?: string
  currentContentSubtype?: string
  currentModuleId?: string
  currentProgramId?: Maybe<string>
  currentProgramName?: string
  toc: ContentTocItemPartsFragment[]
  contentType?: CmsSectionContentType | null
  cmsDeliverableId?: string
  userDeliverableUrl?: Maybe<string>
  templateUrl?: Maybe<string>
  experts: ExpertUserPartsFragment[]
  closeDrawer: () => void
}

type PrevTocItem = {
  prev?: Maybe<ContentTocItemPartsFragment>
  prevParent?: Maybe<ContentTocItemPartsFragment>
  prevChild?: Maybe<ContentTocItemPartsFragment>
}

type NextTocItem = {
  next: ContentTocItemPartsFragment
  nextParent: ContentTocItemPartsFragment
  nextChild: ContentTocItemPartsFragment
}

type ItemMonitoringData = {
  'end-center-content': Partial<PrevTocItem>
  [itemId: string]: Partial<PrevTocItem & NextTocItem>
}

class CmsContentToc extends React.Component<ICmsContentToc, ICmsTocState> {
  constructor(props: ICmsContentToc) {
    super(props)

    this.state = {
      progress: (props.userProgress?.[`${props.currentContentId}d`] ||
        {}) as CmsSectionProgress,
      items: props.toc || [],
      itemMonitoring: { 'end-center-content': {} },
      experts: props.experts || [],
      percentChangeListeners: [],
      userProjectEls: []
    }
  }

  setAllBlocksToComplete = () => {
    const userProgressForContent =
      this.props.userProgress?.[`${this.props.currentContentId}d`]
    const updatedProgress = { ...this.state.progress }
    this.state.items.forEach((parentItem) => {
      if (parentItem.children) {
        parentItem.children.forEach((childItem) => {
          const userProgressStatus =
            typeof userProgressForContent === 'object'
              ? userProgressForContent[childItem.id]
              : null
          if (userProgressStatus === 'complete') {
            updatedProgress[childItem.id] = 'complete'
          } else {
            updatedProgress[childItem.id] = 'incomplete'
          }
        })
        updatedProgress[parentItem.id] = 'complete'
      }
    })
    this.setState({ progress: updatedProgress })
  }

  markContentComplete = () => {
    this.setAllBlocksToComplete()
  }

  onContentProgressComplete = () => this.markContentComplete()

  sendProgressUpdate = (contentPercent: number) => {
    if (!this.props.currentContentId) return

    if (contentPercent === 100) {
      // Tracks when a Concept or Project is completed
      trackContentCompleted({
        content_id: parseInt(this.props.currentContentId, 10),
        content_name: this.props.currentContentName,
        content_type: this.props.contentType,
        path: window.location.pathname
      })
    }
    const data: UserProgramUpdateContentProgressPayload = {
      cms_section_id: this.props.currentContentId,
      content_percent: contentPercent
    }
    sendData('/api/v1/user_programs/update_content_progress', 'POST', data, () => {
      // no user facing error display
    })
  }

  setBlockComplete = (blockId: string) => {
    if (this.state.progress[blockId] === 'complete') return
    const newStatus = 'complete'
    const data: UpdateContentBlockProgressPayload = {
      cms_section_id: this.props.currentContentId,
      block_id: blockId,
      status: newStatus
    }
    sendData(
      '/api/v1/user_programs/update_content_block_progress',
      'POST',
      data,
      (err: Error) => {
        if (err) {
          // no user facing error display
        } else {
          this.handleCtaStatusChange(blockId, newStatus)
        }
      }
    )
  }

  addContentPercentChangeListener = (listenerFn: ListenerFunc) => {
    this.setState({
      percentChangeListeners: [...this.state.percentChangeListeners, listenerFn]
    })
  }

  onPercentChanged = (listenerChangeUpdate: Record<string, CmsSectionProgress>) => {
    this.state.percentChangeListeners.forEach((percentChangeListener) => {
      percentChangeListener(listenerChangeUpdate)
    })
  }

  updateProgressOnChanges = (
    items: ContentTocItemPartsFragment[],
    progress: CmsSectionProgress
  ) => {
    const updatedProgress = { ...progress }
    let numTotal = 0
    let numComplete = 0
    items.forEach((parentItem) => {
      if (parentItem.children && parentItem.children.length > 0) {
        let parentComplete = true
        parentItem.children.forEach((childItem) => {
          if (!optionalStep(childItem)) {
            numTotal += 1
          }
          if (progress[childItem.id] === 'complete') {
            numComplete += 1
          } else {
            parentComplete = false
          }
        })
        if (parentComplete) {
          updatedProgress[parentItem.id] = 'complete'
        }
      } else {
        numTotal += 1
        if (progress[parentItem.id] === 'complete') {
          numComplete += 1
        }
      }
    })
    const oldContentPercent = progress.content_percent
    let calculatedPercent = numTotal ? Math.round(100 * (numComplete / numTotal)) : 0
    if (calculatedPercent > 100) {
      /* Allows for the marking of optional steps as complete. */
      calculatedPercent = 100
    }

    updatedProgress.content_percent = numTotal
      ? Math.round(100 * (numComplete / numTotal))
      : 0

    if (oldContentPercent !== updatedProgress.content_percent) {
      this.sendProgressUpdate(updatedProgress.content_percent)

      const listenerChangeUpdate = {
        [`${this.props.currentContentId}d`]: updatedProgress
      }
      this.onPercentChanged(listenerChangeUpdate)
    }
    return updatedProgress
  }

  handleCtaStatusChange = (blockId: string, status: ProgressStatus) => {
    let updatedProgress = {
      ...this.state.progress,
      [blockId]: status
    }
    updatedProgress = this.updateProgressOnChanges(this.state.items, updatedProgress)
    this.setState({
      progress: updatedProgress
    })
  }

  refreshProgress = () => {
    const updatedProgress = this.updateProgressOnChanges(
      this.state.items,
      this.state.progress
    )
    this.setState({
      progress: updatedProgress
    })
  }

  onTocItemInView = (item: Pick<ContentTocItemPartsFragment, 'id' | 'children'>) => {
    const mInfo = this.state.itemMonitoring[item.id]
    if (!mInfo) return

    if (mInfo.prev) {
      this.setBlockComplete(mInfo.prev.id)
    }
    if (item.children && mInfo.prevChild) {
      // last child of previous parent needs marking complete
      this.setBlockComplete(mInfo.prevChild.id)
    }
  }

  setupScrollMonitoring = () => {
    const itemMonitoring: ItemMonitoringData = { 'end-center-content': {} }
    let prevParent: Maybe<ContentTocItemPartsFragment> = null
    let prevChild: Maybe<ContentTocItemPartsFragment> = null

    this.state.items.forEach((parentItem, parentIdx, parentItems) => {
      const nextParent =
        parentIdx < parentItems.length - 1 ? parentItems[parentIdx + 1] : undefined
      itemMonitoring[parentItem.id] = {
        prev: prevParent,
        next: nextParent,
        prevChild: prevChild
      }
      if (parentItem.children?.length) {
        parentItem.children.forEach((childItem, childIdx, childItems) => {
          itemMonitoring[childItem.id] = {
            prev: prevChild,
            next: childIdx < childItems.length - 1 ? childItems[childIdx + 1] : undefined,
            prevParent: prevParent,
            nextParent: nextParent
          }
          // @ts-ignore
          UIkit.util.on(`#${childItem.id}`, 'inview', () => {
            this.onTocItemInView(childItem)
          })
          prevChild = childItem
        })
      }
      // @ts-ignore
      UIkit.util.on(`#${parentItem.id}`, 'inview', () => {
        this.onTocItemInView(parentItem)
      })
      prevParent = parentItem
    })

    itemMonitoring['end-center-content'] = {
      prev: prevParent,
      prevChild: prevChild
    }
    // @ts-ignore
    UIkit.util.on('#end-center-content', 'inview', () => {
      this.onTocItemInView({ id: 'end-center-content', children: [] })
    })
    this.setState({ itemMonitoring })
  }

  setupScrollspy() {
    const scrollspyOptions = {
      target: 'h1,h2',
      repeat: true,
      hidden: false
    }
    const contentElement = document.getElementById('content')
    contentElement && UIkit.scrollspy(contentElement, scrollspyOptions)

    const scrollspyNavOptions = {
      cls: 'cms-sidebar__lesson--current relative font-medium !text-rb-gray-500 border-rb-gray-100 bg-rb-green-85 bg-opacity-60 !no-underline hover:!bg-rb-green-85 hover:!bg-opacity-60',
      offset: getTopOffset(),
      overflow: false
    }
    const scrollspyMenu = document.getElementById('cms-sidebar__scrollspy-menu')
    scrollspyMenu && UIkit.scrollspyNav(scrollspyMenu, scrollspyNavOptions)
  }

  componentDidMount() {
    window.changeNumSectionBookmarks = () => {}

    window.markContentComplete = () => {
      this.markContentComplete()
    }

    window.addContentPercentChangeListener = (listenerFn: ListenerFunc) => {
      this.addContentPercentChangeListener(listenerFn)
    }

    setTimeout(() => {
      const openSection = document.querySelector<HTMLElement>('.cms-sidebar .uk-open')
      const sidenavigation = document.getElementById('sidenav')
      if (
        openSection &&
        sidenavigation &&
        openSection !== openSection.parentNode?.firstElementChild &&
        typeof sidenavigation.scrollTo === 'function'
      ) {
        const sidebarUtilities = document.querySelector<HTMLElement>(
          '.cms-sidebar__utilities'
        )
        const sidebarUtilitiesOffset = sidebarUtilities
          ? sidebarUtilities.offsetHeight
          : 0
        sidenavigation.scrollTo({
          top: openSection.offsetTop - sidebarUtilitiesOffset,
          left: 0,
          behavior: 'smooth'
        })
      }
    }, 500)

    this.setupScrollspy()

    if (this.props.contentType === 'Concept') {
      this.setupScrollMonitoring()
    }

    this.refreshProgress()

    const startedComplete =
      this.props.currentContentId && this.props.userProgress
        ? this.props.userProgress[this.props.currentContentId] === 'completed'
        : false
    if (startedComplete) {
      this.setAllBlocksToComplete()
    }

    this.setState({
      userProjectEls: Array.from(document.querySelectorAll('.user-project-link'))
    })

    this.trackContentStarted()
  }

  trackContentStarted = () => {
    this.props.currentContentId &&
      trackContentStarted({
        content_id: this.props.currentContentId,
        content_name: this.props.currentContentName,
        content_type: this.props.contentType,
        path: window.location.pathname
      })
  }

  closeSidebarClicked = () => {
    this.props.closeDrawer()
  }

  render() {
    const {
      contentBookmarkAnchors,
      contentBookmarkAnchorsInMySavedItems,
      currentContentId,
      currentModuleId,
      currentProgramId,
      currentContentName,
      currentProgramName,
      currentContentSubtype,
      userProgramId,
      contentType,
      userDeliverableUrl,
      templateUrl
    } = this.props

    const { items, experts } = this.state
    // useMemo could be used to get the value after converting the component to a function component
    const contentBookmarkId = contentBookmarkAnchors?.find(
      (contentBookmark) =>
        contentBookmark.anchor === `content-header-bookmark-${currentContentId}`
    )?.id
    const contentIsInMySavedItems = !!contentBookmarkAnchorsInMySavedItems?.find(
      (contentBookmark) =>
        contentBookmark.anchor === `content-header-bookmark-${currentContentId}`
    )

    return (
      <div>
        {currentContentId && userProgramId && (
          <>
            <CtaButtons
              cmsContentId={currentContentId}
              progress={this.state.progress}
              onCtaStatusChange={this.handleCtaStatusChange}
            />
            <ContentProgressTracker
              cmsSectionId={currentContentId}
              onComplete={this.onContentProgressComplete}
              startingProgress={this.state.progress?.content_percent || 0}
              cmsProgramId={currentProgramId || ''}
              cmsProgramName={currentProgramName}
              path={location.pathname}
              location={LessonContentLocation.ContentViewer}
              viewedInModal={false}
              contentType={contentType || CmsSectionContentType.LESSON}
              contentName={currentContentName || ''}
              contentSubtype={currentContentSubtype}
            />
          </>
        )}

        {currentProgramId && contentType && (
          <HeaderBookmark
            contentBookmarkId={contentBookmarkId}
            cmsContentId={currentContentId}
            contentType={contentType}
            cmsModuleId={currentModuleId}
            contentIsInMySavedItems={contentIsInMySavedItems}
            cmsProgramId={currentProgramId}
          />
        )}

        <UserProjectLinks
          links={this.state.userProjectEls || []}
          url={userDeliverableUrl}
          templateUrl={templateUrl}
        />

        <div className="cms-sidebar__header flex h-11 justify-end pt-3 pb-2 pr-3 md:pt-8 md:pb-12 md:pr-8">
          <div
            className="flex items-center"
            uk-tooltip="title: Close Sidebar; pos: left; cls: uk-active rf-tooltip-right-arrow"
          >
            <a
              className="cms-sidebar__left-nav-hide flex items-center"
              onClick={this.closeSidebarClicked}
              uk-toggle="target: #sidenav; cls: left-nav-revealed"
            >
              <span className="hidden items-center sm:flex">
                <SVGIcon name="thin-chevron-left" />
                <SVGIcon name="thin-chevron-left" />
              </span>
              <span className="flex sm:hidden">
                <CloseIcon className="h-5 w-5 text-rb-gray-300 hover:text-rb-gray-400" />
              </span>
            </a>
          </div>
        </div>
        <div className="pr-2.5 xs:pr-4 md:pr-8 ">
          <hr className="cms-sidebar__divider mt-0" />
        </div>
        <h1 className="cms-sidebar__title m-0 my-4 font-sans text-lg font-medium leading-[21px] tracking-normal !text-rb-gray-500">
          Contents
        </h1>

        {items.length > 0 && (
          <ul
            id="cms-sidebar__scrollspy-menu"
            className="list-none p-0"
            uk-accordion="multiple: true"
          >
            {items.map((item) => (
              <Item
                key={`tocitem${item.id}`}
                item={item}
                progress={this.state.progress}
              />
            ))}
          </ul>
        )}
        {experts.length > 0 && (
          <>
            <div className="cms-sidebar__experts mt-[60px] pt-2.5 xs:pt-4">
              <div className="pr-2.5 xs:pr-4 md:pr-8 ">
                <hr className="cms-sidebar__divider" />
              </div>
              <h1 className="cms-sidebar__title m-0 mt-4 font-sans text-lg font-medium leading-[21px] tracking-normal !text-rb-gray-500">
                Expert Collaborators
              </h1>
              <ExpertCollaborators experts={experts} />
            </div>
          </>
        )}
      </div>
    )
  }
}

interface ICmsContentTocContainer {
  contentViewer: ContentViewerTocPartsFragment
}

const CmsContentTocContainer = ({ contentViewer }: ICmsContentTocContainer) => {
  const { closeDrawer } = useSideDrawer()

  const experts = contentViewer?.expertUsers
  const userProgram = contentViewer?.userProgram
  const cmsModule = contentViewer?.cmsModule
  const cmsContent = contentViewer?.cmsContent
  const cmsProjectDeliverable = contentViewer?.cmsProjectDeliverable
  const userProgramProgress = contentViewer?.userProgram?.progress || {}

  return (
    <CmsContentToc
      userProgramId={userProgram?.id}
      userProgress={cmsModule ? userProgramProgress[cmsModule.id] : {}}
      contentBookmarkAnchors={contentViewer?.contentBookmarkAnchors}
      contentBookmarkAnchorsInMySavedItems={
        contentViewer?.contentBookmarkAnchorsInMySavedItems
      }
      currentContentId={cmsContent?.id}
      currentContentName={cmsContent?.name}
      currentModuleId={cmsContent?.cmsModuleId}
      currentProgramId={cmsContent?.cmsProgramId}
      toc={cmsContent?.toc || []}
      contentType={cmsContent?.contentType}
      cmsDeliverableId={cmsProjectDeliverable?.id}
      userDeliverableUrl={cmsProjectDeliverable?.url}
      templateUrl={cmsProjectDeliverable?.templateUrl}
      experts={experts || []}
      currentProgramName={cmsContent?.cmsProgram?.name}
      currentContentSubtype={cmsContent?.contentSubtype || ''}
      closeDrawer={closeDrawer}
    />
  )
}

export default CmsContentTocContainer
