import { useParams } from 'react-router-dom'

import useURLParams from './useURLParams'

type PathParams = {
  cmsProgramSlug: string
  cmsModuleSlug: string
  cmsSectionParentSlug?: string
  cmsSectionSlug: string
}

const useLessonUrlData = (): PathParams => {
  const { getParam } = useURLParams()
  // || undefined because getParam defaults to an empty string and that doesn't play well with backend
  const parentSlug = getParam('cms_section_parent_slug') || undefined
  const matchedParams = useParams<PathParams>()

  return {
    ...matchedParams,
    cmsSectionParentSlug: matchedParams.cmsSectionParentSlug || parentSlug
  }
}

export default useLessonUrlData
