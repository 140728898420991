import { useCertificatesQuery } from 'gql'

import ErrorMessage from './ErrorMessage'
import Loading from './Loading'

const Certificates = () => {
  const { data, loading, error } = useCertificatesQuery()

  if (loading) return <Loading />
  if (error || !data?.currentUser?.certificates) return <ErrorMessage error={error} />

  const certificates = data.currentUser.certificates
  return (
    <div className="m-0 px-3 py-6 tl:px-8">
      <ul className="uk-list">
        {certificates.map(({ completed, name, pdfUrl, id }) => (
          <li key={id} className="uk-text-light">
            {completed ? (
              <a href={pdfUrl || ''} target="_blank" rel="noreferrer">
                <span uk-icon="download" className="uk-text-middle"></span>
                <span className="uk-text-middle">{name}</span>
              </a>
            ) : (
              <span
                className="uk-text-muted cursor-pointer"
                uk-tooltip="title: <div class='py-1 px-2.5'>You must complete this program before downloading the letter of completion.</div>; cls: uk-active rf-tooltip-down-arrow; pos: top"
              >
                {name}
              </span>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Certificates
