interface ProgramOutcomesProps {
  slug: string
  description?: string | null
}

const ProgramOutcomes = ({ slug, description }: ProgramOutcomesProps) => {
  if (!description) return null

  return (
    <div className="flex rounded-lg bg-rb-blue-50 p-4 sm:p-6">
      <img
        src={`https://reforge-uploads-dev.s3.amazonaws.com/programs/${slug}/${slug}--outcomes.png`}
        className="mr-4 h-16 w-16 rounded-lg sm:h-[104px] sm:w-[104px]"
        alt={`program-${slug}-outcomes`}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null
          currentTarget.src =
            'https://reforge-uploads-dev.s3.amazonaws.com/programs/default/default--outcomes.png'
        }}
      />
      <div>
        <h2 className="mb-2 font-medium lg:text-lg">Course outcomes</h2>
        <p className="text-sm lg:text-base">{description}</p>
      </div>
    </div>
  )
}

export default ProgramOutcomes
