import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'
import { MouseEvent, useMemo, useRef, useState } from 'react'

import Button from 'components/Button'
import Modal, { ModalContent } from 'components/Modal'

import {
  BillingInformationFragment,
  InvoiceFieldsFragment,
  useUpdateInvoiceUserFieldsMutation
} from 'gql'

import { formatMoney } from 'utils/moneyUtils'

const PAID_STATUS = 'paid'

const customStyles = {
  noBottomMargin: {
    marginBottom: 0
  }
}

export type InvoiceProps = {
  invoice: InvoiceFieldsFragment
}

const Invoice = ({ invoice }: InvoiceProps) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [internalPoNumber, setInternalPoNumber] = useState(invoice.internalPoNumber || '')
  const [extraInformation, setExtraInformation] = useState(invoice.extraInformation || '')
  const [companyAddress, setCompanyAddress] = useState(
    invoice.companyAddress || strigifyAddress(invoice.billingInformation?.address) || ''
  )
  const pdfRef = useRef<HTMLDivElement>(null)
  const [updateInvoiceMutation] = useUpdateInvoiceUserFieldsMutation()

  const stripeInvoice = invoice.stripeInvoice

  // For each tax on the invoice we calculate the amouunt that is taxed by that tax
  const amountTaxed = useMemo(
    () =>
      stripeInvoice.totalTaxAmounts.reduce(
        (acc, taxAmount) => {
          const amount = stripeInvoice.lineItems
            .map((line) =>
              line.taxAmounts.find((ta) => ta.taxRateId === taxAmount.taxRateId)
                ? line.amount
                : 0
            )
            .reduce((a, b) => a + b, 0)

          acc[taxAmount.taxRateId] = (acc[taxAmount.taxRateId] || 0) + amount
          return acc
        },
        {} as Record<string, number>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stripeInvoice]
  )

  const handlePrint = async (e: MouseEvent) => {
    e.preventDefault()

    setIsModalOpen(true)

    const pdfElement = pdfRef.current
    if (!pdfElement) return
    const prevWidth = pdfElement.style.width
    pdfElement.style.width = '1000px'
    const canvas = await html2canvas(pdfElement)
    const pdf = new JsPDF('p', 'mm', 'a4', true)
    pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 211, 298, 'FAST')
    pdf.save('reforge-invoice.pdf')
    pdfElement.style.width = prevWidth

    setIsModalOpen(false)
  }

  const submitForm = async () => {
    try {
      await updateInvoiceMutation({
        variables: {
          input: {
            id: invoice.id,
            companyAddress,
            extraInformation,
            internalPoNumber
          }
        }
      })
    } catch (e) {
      // we take no action in case of failure as this is an optimistic update
      // and the changes are already reflected in the UI and is enough for the
      // current session
    }
    setIsEditMode(false)
  }

  const toggleEditMode = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setIsEditMode(!isEditMode)
  }

  const buildPoNumberField = () => {
    if (isEditMode) {
      return (
        <div>
          <label htmlFor="invoice_po_number" className="uk-text-muted text-lg">
            PO Number:
          </label>
          <input
            id="invoice_po_number"
            className="uk-input"
            name="po-number-input"
            type="text"
            value={internalPoNumber}
            onChange={(e) => setInternalPoNumber(e.target.value)}
          />
        </div>
      )
    }
    return (
      internalPoNumber && (
        <div>
          <span className="uk-text-muted text-lg">PO Number:</span>
          <p className="uk-margin-small-bottom mt-0">{internalPoNumber}</p>
        </div>
      )
    )
  }

  const addressField = isEditMode ? (
    <textarea
      className="uk-textarea"
      rows={2}
      value={companyAddress}
      onChange={(e) => setCompanyAddress(e.target.value)}
      placeholder="Company Address"
    />
  ) : (
    <address style={{ marginBottom: 0, whiteSpace: 'pre-line' }} className="mt-0 text-lg">
      {companyAddress}
    </address>
  )
  const extraInformationField = isEditMode ? (
    <textarea
      className="uk-textarea"
      rows={10}
      value={extraInformation}
      onChange={(e) => setExtraInformation(e.target.value)}
      placeholder="Any extra information you need to list..."
    />
  ) : (
    <p style={{ whiteSpace: 'pre-line' }} className="uk-margin-small-top uk-text-justify">
      {extraInformation}
    </p>
  )

  const companyName =
    invoice.billingInformation?.name || invoice.paymentRecord.companyName

  return (
    <div className="uk-height-1-1 flex justify-center">
      <Modal
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        header={false}
        className="max-w-2xl"
      >
        <ModalContent className="overflow-y-auto p-5">
          Your pdf is processing. It should download shortly.
        </ModalContent>
      </Modal>
      <div className="uk-width-5-6@m uk-height-1-1">
        <div className="uk-card uk-height-1-1">
          <div className="uk-card-header flex flex-none items-center">
            <div className="flex-1 truncate">
              <h3 className="uk-card-title mb-0 truncate font-normal">Invoice</h3>
            </div>
            <div>
              <a
                className="uk-margin-right"
                href=""
                uk-icon="print"
                onClick={handlePrint}
              />
              <a
                href=""
                uk-toggle="target: #invoice-options; animation: uk-animation-slide-top-small"
                uk-icon="pencil"
                onClick={toggleEditMode}
              />
            </div>
          </div>
          <hr className="m-0" />
          <div hidden id="invoice-options" className="flex-1 py-0 xs:py-4 md:py-8">
            <div className="flex items-center justify-between p-4">
              <span className="uk-badge">Edit Mode</span>

              <Button
                size="small"
                color="teal"
                onClick={submitForm}
                uk-toggle="target: #invoice-options; animation: uk-animation-slide-top-small"
              >
                Save
              </Button>
            </div>
          </div>
          <div ref={pdfRef} id="pdf-print" className="uk-card-body">
            <div className="uk-margin-bottom">
              <div className="flex">
                <h2 className="text-2xl font-normal">REFORGE</h2>
                {invoice.paymentRecord.status === PAID_STATUS && (
                  <div className="ml-5 mt-1.5 flex h-6 w-12 items-center justify-center rounded bg-rb-teal-200 pt-1.5 pr-1.5 pb-1.5 pl-2 font-sans tracking-widest text-white">
                    PAID
                  </div>
                )}
              </div>
              <div className="mb-10 flex justify-between">
                <div>
                  <span className="uk-text-muted text-lg">Invoice from:</span>
                  <address
                    style={customStyles.noBottomMargin}
                    className="mt-0 mb-0 text-lg"
                  >
                    Reforge Inc. <br />
                    548 Market St <br />
                    PMB 88675 <br />
                    San Francisco, CA 94104-5401 <br />
                  </address>
                </div>
                <div>
                  <span className="uk-text-muted text-lg">Invoice to:</span>
                  <div className="text-lg">{invoice.paymentRecord.fullName}</div>
                  {companyName !== invoice.paymentRecord.fullName && (
                    <div className="text-lg">{companyName}</div>
                  )}
                  {invoice.billingInformation?.taxIds && (
                    <div className="text-lg">
                      {invoice.billingInformation.taxIds?.join(', ')}
                    </div>
                  )}
                  <address style={customStyles.noBottomMargin} className="mt-0 text-lg">
                    {addressField}
                  </address>
                  {buildPoNumberField()}
                </div>
              </div>
              {/* end invoice information uk-grid */}
              <div className="mb-10">
                <table className="min-w-full divide-y divide-rb-gray-300">
                  <thead>
                    <tr className="bg-rb-gray-50">
                      <th className="py-3.5 pl-4 pr-3 text-left text-base font-normal uppercase text-rb-gray-300">
                        Description
                      </th>
                      <th className="py-3.5 pl-4 pr-3 text-right text-base font-normal uppercase text-rb-gray-300">
                        Qty
                      </th>
                      <th className="py-3.5 pl-4 pr-3 text-right text-base font-normal uppercase text-rb-gray-300">
                        Tax
                      </th>
                      <th className="py-3.5 pl-4 pr-3 text-right text-base font-normal uppercase text-rb-gray-300">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {stripeInvoice.lineItems.map((line) => (
                      <tr key={line.id} className="border-b border-gray-200">
                        <td className="py-4 px-3 text-base">{line.description}</td>
                        <td className="py-4 px-3 text-right text-base text-rb-gray-400">
                          {line.quantity}
                        </td>
                        <td className="py-4 px-3 text-right text-base text-rb-gray-400">
                          {Number.isFinite(line.taxRate) ? `${line.taxRate}%` : null}
                        </td>
                        <td className="py-4 px-3 text-right text-base text-rb-gray-400">
                          {formatMoney(line.amount / 100, true)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr className="pt-6">
                      <th
                        scope="row"
                        colSpan={3}
                        className="px-3 pt-6 text-right text-base font-normal text-rb-gray-400"
                      >
                        Subtotal
                      </th>
                      <td className="px-3 pt-6 text-right text-base text-rb-gray-400">
                        {formatMoney(stripeInvoice.subtotal / 100, true)}
                      </td>
                    </tr>
                    {stripeInvoice.totalTaxAmounts.map((tax) => (
                      <tr key={tax.taxRateId}>
                        <th
                          scope="row"
                          colSpan={3}
                          className="px-3 pt-4 text-right text-base font-normal text-rb-gray-400"
                        >
                          {tax.taxRate
                            ? `${tax.taxRate.displayName} - ${
                                tax.taxRate.jurisdiction
                              } (${tax.taxRate.percentage}% on ${formatMoney(
                                amountTaxed[tax.taxRateId] / 100,
                                true
                              )})`
                            : 'Tax'}
                        </th>
                        <td className="px-3 pt-4 text-right text-base text-rb-gray-400">
                          {formatMoney(tax.amount / 100, true)}
                        </td>
                      </tr>
                    ))}

                    <tr>
                      <th
                        scope="row"
                        colSpan={3}
                        className="px-3 pt-4 text-right text-base font-semibold text-rb-gray-500"
                      >
                        Total
                      </th>
                      <td className="px-3 pt-4  text-right text-base font-semibold text-rb-gray-500">
                        {formatMoney(stripeInvoice.total / 100, true)}
                      </td>
                    </tr>

                    {stripeInvoice?.postPaymentCreditNotesAmount && (
                      <>
                        <tr>
                          <td className="py-4 px-3 text-right text-base" colSpan={3}>
                            Tax Reimbursement
                          </td>
                          <td className="py-4 px-3 text-right text-base text-rb-gray-400">
                            {formatMoney(
                              (stripeInvoice.postPaymentCreditNotesAmount / 100) * -1,
                              true
                            )}
                          </td>
                        </tr>

                        <tr>
                          <th
                            scope="row"
                            colSpan={3}
                            className="px-3 text-right text-base font-semibold text-rb-gray-500"
                          >
                            Adjusted Total
                          </th>
                          <td className="px-3 text-right text-base font-semibold text-rb-gray-500">
                            {formatMoney(
                              (stripeInvoice.total -
                                stripeInvoice.postPaymentCreditNotesAmount) /
                                100,
                              true
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </tfoot>
                </table>
              </div>
              <div className="uk-overflow-auto uk-margin-top mb-10">
                <table className="uk-table uk-table-small uk-table-divider uk-table-middle">
                  <thead>
                    <tr className="bg-rb-gray-50">
                      <th>All Attendees Enrolled</th>
                      <th className="uk-text-nowrap uk-text-center">Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoice.members.map((mem) => (
                      <tr key={`member${mem.id}`}>
                        <td className="uk-text-nowrap">{mem.fullName}</td>
                        <td className="uk-text-center">{mem.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="uk-grid uk-grid-divider uk-margin-top uk-grid-stack">
                <div className="uk-first-column flex-1">
                  <table className="uk-table uk-table-middle uk-table-divider uk-table-small">
                    <thead>
                      <tr>
                        <th colSpan={2}>Payment Information</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="uk-text-nowrap">Accepted Methods</td>
                        <td className="uk-text-right">
                          <p>Credit/Debit Card or ACH/Wire Transfer</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="uk-text-nowrap">Bank Address</td>
                        <td className="uk-text-right">
                          <address style={customStyles.noBottomMargin}>
                            First Republic Bank
                            <br />
                            111 Pine Street
                            <br />
                            San Francisco, CA 94111
                          </address>
                        </td>
                      </tr>

                      <tr>
                        <td className="uk-text-nowraps">A/C Name:</td>
                        <td className="uk-text-right">Invoice Receivable</td>
                      </tr>
                      <tr>
                        <td className="uk-text-nowraps">A/C Number:</td>
                        <td className="uk-text-right">80010453274</td>
                      </tr>
                      <tr>
                        <td className="uk-text-nowraps">ABA/Routing Number</td>
                        <td className="uk-text-right">321081669</td>
                      </tr>
                      <tr>
                        <td className="uk-text-nowraps">Account Number</td>
                        <td className="uk-text-right">80010453274</td>
                      </tr>
                      <tr>
                        <td className="uk-text-nowraps">SWIFT Code</td>
                        <td className="uk-text-right">FRBBUS6S</td>
                      </tr>
                      <tr>
                        <td className="uk-text-nowraps">Bill.com Payment Network ID</td>
                        <td className="uk-text-right">0149938526489121</td>
                      </tr>
                      <tr>
                        <td className="uk-text-nowraps">Reforge TIN</td>
                        <td className="uk-text-right">
                          81-5253945
                          <br />
                          W9 available at{' '}
                          <a
                            href="https://www.reforge.com/w-9"
                            target="_blank"
                            rel="noreferrer"
                          >
                            www.reforge.com/w-9
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="uk-text-nowraps">IMPORTANT</td>
                        <td className="uk-text-justify">
                          <p>
                            Please include attendee names and program selection on the
                            wire transfer document so we can track your payment.
                            <br />
                            <strong>Please do not send a check.</strong>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="uk-width-2-5@s uk-margin-small-top">
                  <p className="uk-text-muted uk-margin-small-bottom">
                    Extra Information:
                  </p>
                  {extraInformationField}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Invoice

const strigifyAddress = (address: BillingInformationFragment['address']) => {
  if (!address) return ''
  const { city, state, line1, line2, country, postalCode } = address
  return `${line1} \n ${line2 ? `${line2} \n` : ''} ${city}, ${
    state ? `${state},` : ''
  } ${postalCode}, ${country}`
}
