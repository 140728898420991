import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { useCurrentScheduledWeekId, useLessonNavInfo } from 'domains/CohortDashboard'
import { UserAvatar } from 'domains/CohortDashboard/CohortDashboardHeader/UserAvatar'

import Button from 'components/Button'
import { ChevronRightThinIcon } from 'components/icons'
import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'

import {
  CohortViewerNewCohortPartsFragment,
  Season,
  useCohortDashboardHeaderLazyQuery,
  useCohortDashboardHeaderUserQuery
} from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import { trackCtaClicked } from 'utils/tracking/analytics'

import { ReactComponent as Hamburger } from 'images/icon--hamburger.svg'

import { useCohortDashboardTrackingContext } from '../hooks/useCohortDashboardTrackingContext'

interface CohortDashboardHeaderProps {
  cohort: CohortViewerNewCohortPartsFragment
  toggleSidebar: () => void
  isLessonViewer: boolean
}

export const CohortDashboardHeader = ({
  cohort,
  toggleSidebar,
  isLessonViewer
}: CohortDashboardHeaderProps) => {
  const currentUser = useAssertCurrentUser()
  const { weekId } = useCurrentScheduledWeekId({
    cohort,
    ignoreUrlParams: true,
    loaded: true
  })
  const [getCohortDashboardHeader, { data: headerData }] =
    useCohortDashboardHeaderLazyQuery({
      variables: {
        weekId,
        cohortSlug: cohort.slug
      }
    })

  const { data: cohortHeaderUserData, loading } = useCohortDashboardHeaderUserQuery({
    variables: {
      seasonId: cohort.season.id,
      userId: currentUser.id
    }
  })

  const history = useHistory()
  const currentScheduledWeek = headerData?.cohortDashboardScheduledWeek

  useEffect(() => {
    if (weekId) getCohortDashboardHeader()
  }, [getCohortDashboardHeader, weekId])

  const lessonNavInfo = useLessonNavInfo({
    cohort,
    scheduledWeek: currentScheduledWeek,
    activeProgram: currentUser?.activeProgram
  })

  const { userCohortId } = useCohortDashboardTrackingContext()

  const onClick = () => {
    trackCtaClicked({
      cta_type: 'button',
      cta_location: 'cohort_dashboard_header_lesson_button',
      text: lessonNavInfo.label,
      related_identifiers: {
        cohort_id: cohort.id,
        cohort_name: cohort.name,
        user_cohort_id: userCohortId
      }
    })
    history.push(lessonNavInfo.url)
  }

  return (
    <div
      className={twMerge(
        'flex h-[68px] border-b-1.4 border-solid border-rb-gray-50 py-3.5 px-6 sm:px-8',
        isLessonViewer && 'fixed top-0 left-0 z-50 w-full bg-white'
      )}
    >
      <div className="flex flex-1 items-center justify-between">
        <div className="flex items-center justify-between">
          <Button
            variant="text-only"
            className={twMerge('mr-5 block p-0 lg:hidden', isLessonViewer && 'lg:block')}
            onClick={toggleSidebar}
          >
            <Hamburger width={17.5} height={11.25} fill="#080A0A" />
          </Button>
          <Link
            to={`/cohorts/${cohort.slug}`}
            className="flex flex-row space-x-3 hover:no-underline"
          >
            <div className="h-5 w-7 bg-reforge-logo-symbol-black bg-contain bg-no-repeat" />
            <RfHeader3SemiBold className="!mb-0">
              {cohort.cmsProgram.name}
            </RfHeader3SemiBold>
          </Link>
        </div>
        <div className="flex items-center">
          {!isLessonViewer &&
            currentScheduledWeek?.isCurrentWeek &&
            lessonNavInfo.label && (
              <Button
                variant="fill"
                size="x-small"
                shape="rounded-full"
                onClick={onClick}
                className="hidden px-4 py-2.5 font-medium md:flex"
              >
                {lessonNavInfo.label}
                <ChevronRightThinIcon className="ml-3 h-3 w-3 fill-current" />
              </Button>
            )}
          {!loading && cohortHeaderUserData?.user && (
            <UserAvatar
              currentUser={cohortHeaderUserData?.user}
              cmsProgramId={cohort.cmsProgram.id}
              season={(cohortHeaderUserData?.season || {}) as Season}
            />
          )}
        </div>
      </div>
    </div>
  )
}
