import { RefObject, forwardRef, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { BookmarkNoteControls } from 'domains/Bookmarks/BookmarkCard'
import { useBookmarkCard } from 'domains/Bookmarks/hooks/useBookmarkCard'

import { useSideDrawer } from 'components/SideDrawer'
import VideoTimeElement from 'components/VideoTimeElement'

import { ProgramBookmarkPartsFragment } from 'gql'

import { track } from 'utils/tracking/segment'

export interface BookmarkNoteFormProps {
  bookmark: ProgramBookmarkPartsFragment
  show?: boolean
  hasFocus?: boolean
  type?: string
  setShowNote: (show: boolean) => void
  onDeleteBookmark?: () => void
  onSelectBookmark?: () => void
  onFocusedBookmark?: (id: string) => void
  shouldShowNoteElement?: boolean
  startActive?: boolean
  className?: string
}

interface NoteImageProps {
  show?: boolean
  onSelectBookmark?: () => void
  bookmark: ProgramBookmarkPartsFragment
}

const onUpdateBookmarkNote = () => {
  // @ts-ignore - 'Content Viewer - Action' event is not defined in Segment JIRA#REF-5159
  track('Content Viewer - Action', {
    action: 'Bookmark__save',
    location: window.location.pathname
  })
}

const NoteImage = ({ show = false, onSelectBookmark, bookmark }: NoteImageProps) =>
  show ? (
    <div
      className="uk-width-1-5 relative p-0 hover:cursor-pointer"
      onClick={onSelectBookmark}
    >
      <img src={bookmark.referenceImageUrl || ''} alt="bookmark" />
      <div className="absolute left-2 bottom-[5px]">
        <VideoTimeElement
          bookmark={bookmark}
          type="Dashboard"
          onSelectBookmark={onSelectBookmark}
        />
      </div>
    </div>
  ) : null

const BookmarkNoteForm = (
  {
    bookmark,
    show = false,
    hasFocus = false,
    setShowNote,
    type,
    onSelectBookmark,
    onFocusedBookmark = () => {},
    startActive = false,
    className
  }: BookmarkNoteFormProps,
  ref: RefObject<HTMLTextAreaElement>
) => {
  const [noteText, setNoteText] = useState(bookmark.noteBody || '')

  const { unsetActiveBookmarkId } = useSideDrawer()

  useEffect(() => {
    if (show && hasFocus) ref?.current?.focus()
  }, [hasFocus, show, ref])

  useEffect(() => {
    if (startActive && bookmark.id) {
      onFocusedBookmark(bookmark.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startActive])

  useEffect(() => {
    setNoteText(bookmark.noteBody || '')
  }, [bookmark.noteBody])

  const { updateBookmarkNote } = useBookmarkCard({ bookmark, onUpdateBookmarkNote })
  const hasImage = type === 'Video' && !!bookmark.referenceImageUrl

  const onBlur = () => {
    unsetActiveBookmarkId()
    const shouldUpdateNote = noteText.trim() !== (bookmark.noteBody || '')
    if (shouldUpdateNote) {
      updateBookmarkNote({ note: noteText.trim() })
      if (!noteText.trim()) {
        setShowNote(false)
      }
    }
    ref?.current?.blur()
  }

  return show ? (
    <div className={twMerge('flex', className)}>
      <NoteImage
        show={hasImage}
        bookmark={bookmark}
        onSelectBookmark={onSelectBookmark}
      />
      <div className="group flex w-full justify-between rounded-md border-2 border-transparent bg-rb-gray-50 p-4 transition-[border] duration-500 hover:border-rb-gray-250">
        <textarea
          ref={ref}
          className="autoexpand w-full resize-none overflow-hidden border-none !bg-rb-gray-50 font-sans !leading-6 !text-black opacity-100 outline-none placeholder:text-rb-gray-300 sm:pr-[15px]"
          placeholder="add a note"
          value={noteText}
          onChange={(e) => setNoteText(e.target.value)}
          onBlur={onBlur}
        />
        <BookmarkNoteControls
          bookmark={bookmark}
          hideNote={() => setShowNote(false)}
          shouldDeleteBookmark={type?.toLowerCase() === 'dashboard'}
        />
      </div>
    </div>
  ) : null
}

export default forwardRef<HTMLTextAreaElement, BookmarkNoteFormProps>(BookmarkNoteForm)
