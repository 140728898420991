import { ReactNode } from 'react'

import RfHeader2 from 'components/typography/RfHeader/RfHeader2'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphMediumSemiBold from 'components/typography/RfParagraph/RfParagraphMediumSemiBold'

import { ReactComponent as LoginKey } from 'images/icon--login-key.svg'
import { ReactComponent as MonitorChart } from 'images/icon--monitor-chart.svg'
import { ReactComponent as TaskList } from 'images/icon--task-list.svg'

interface Reason {
  icon: ReactNode
  title: string
  subtitle: string
}

const reasons: Reason[] = [
  {
    icon: <LoginKey width={60} height={60} />,
    title: 'Access to top experts in tech',
    subtitle:
      'Imagine getting real-time answers from the VP of Growth at Dropbox, or the VP of Product at Netflix? Each course is led by seasoned experts in their field, bringing their experience from launching and scaling products at top companies.'
  },
  {
    icon: <MonitorChart width={64} height={64} />,
    title: 'Live sessions for applied strategy',
    subtitle:
      'Lessons are brought to life with guest experts sharing real-world case studies and relevant behind-the-scenes stories from their time at Google, Slack, Tinder, Airbnb, and more.'
  },
  {
    icon: <TaskList width={60} height={60} />,
    title: 'Structure and accountability',
    subtitle:
      'Through weekly priorities and a group of peers solving similar challenges, our live course experience is designed to help you prioritize your development and get you to the next level in your career.'
  }
]

interface CourseDetailWhyTakeCourseProps {
  headerOverride?: string
}

const CourseDetailWhyTakeCourse = ({
  headerOverride
}: CourseDetailWhyTakeCourseProps) => {
  return (
    <div className="space-y-8 rounded-xl bg-rb-orange-25 p-8">
      <RfHeader2>{headerOverride || 'Why take a course with Reforge'}</RfHeader2>
      {reasons.map((reason) => {
        const iconComponent = (
          <div className="my-auto ml-2 flex h-20 w-20">
            <div className="my-auto">{reason.icon}</div>
          </div>
        )

        return (
          <div key={reason.title} className="flex flex-row sm:space-x-8">
            <div className="hidden sm:block">{iconComponent}</div>
            <div className="flex flex-col space-y-4">
              <div className="mx-auto sm:hidden">{iconComponent}</div>
              <RfParagraphMediumSemiBold>{reason.title}</RfParagraphMediumSemiBold>
              <RfParagraphMedium>{reason.subtitle}</RfParagraphMedium>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default CourseDetailWhyTakeCourse
