export const opening = `
Hi there,

I recently signed up for a [Reforge](https://reforge.com/) account to evaluate their available content and ensure that it was the right fit to not only help me accelerate my career growth, but drive relevant impact at **[your company]**. 

After evaluating the platform along with hearing stellar recommendations from my network, I am writing to you to request reimbursement for their annual membership fee, which opens full access to their platform and community. 

If you aren’t familiar with Reforge, they are a highly reputable learning platform founded by Brian Balfour and Andrew Chen. Reforge partners with the top leaders of the fastest-growing tech companies to build in-depth courses, templates, case studies, and more across Product, Growth, Marketing, and Engineering disciplines. 

Membership includes access to their full on-demand content library, participation in a four- or six-week intensive course in a Cohort, access to their Slack community, and more. 

Here are some good resources that demonstrate the kinds of content members get access to:
1. [Example case study from a Reforge Cohort Course](https://reforge.wistia.com/medias/qozttfbff2)
2. [Courses overview](https://www.reforge.com/programs)

&NewLine;

Because the cohorts only happen twice per year, and the fact that I have on-demand access to the content, I am able to participate part-time, so I won't require time off from my responsibilities. Additionally, the live sessions are virtual, so they won’t require me to travel. 
`

export const closing = `My overarching reason for requesting this membership is simple: I want to increase my ability to create results for **[your company]** and help drive our business forward. I’m excited to do the extra work and am committed to sharing what I’ve learned with my colleagues.

**Who's behind Reforge?** Reforge was created by leaders from companies like Atlassian, HubSpot, Pinterest, and Uber to help leading tech professionals scale in their careers and at their companies. Top executives participate and share first-hand how they grew companies like Box, Calm, Credit Karma, Dropbox, Instacart, Lambda School, LinkedIn, Meta, Miro, Nextdoor, Reddit, Slack, Spotify, Stitchfix, Twitter, Uber, and more. 

**Who has completed the training?** Reforge has over 10,000 members from companies like Airbnb, Drift, Dropbox, Evernote, Facebook, Google, HubSpot, IBM, LinkedIn, Nike, and many more. Check out their [case studies](http://reforge.com/case-studies) here. 

**What's [the cost](https://www.reforge.com/pricing)?** An annual subscription for individuals is priced at $1,995. However, if we want more people from our team to participate, we could equip up to 10 members of our team with membership for $5,995.

I believe a Reforge membership is an important step in my career development and knowledge,  and that it will increase my impact at our company. Reforge’s frameworks, live events, and discussions with other practitioners will help improve my performance, making the membership a worthy investment for both myself and the company.

`

export default opening
