import { SectionHeading } from 'components/CheckoutModal/components/Headings'
import Loading from 'components/Loading'
import { NewUserPlanOptions } from 'components/PlanOptionCard/NewUserPlanOptions'

import { CohortPassPriceTier, PlanForSale, PlanName } from 'gql'

import { usePurchaseFlowContext } from '../PurchaseFlow/contexts/PurchaseFlowContext'
import { PurchaseFlowContainer } from './components/PurchaseFlowContainer'

export const ChoosePlanPageWrapper = () => {
  const { selectPlan, plansForSale, loading, cohortPassPriceTiers } =
    usePurchaseFlowContext()

  const onPlanSelected = (plan: PlanName | null) => {
    selectPlan(plan)
  }
  return (
    <ChoosePlanPage
      onPlanSelected={onPlanSelected}
      plansForSale={plansForSale}
      loading={loading}
      cohortPassPriceTiers={cohortPassPriceTiers}
    />
  )
}

export interface ChoosePlanPageProps {
  onPlanSelected: (plan: PlanName) => void
  plansForSale: PlanForSale[]
  cohortPassPriceTiers: CohortPassPriceTier[]
  loading: boolean
}

export const ChoosePlanPage = ({
  onPlanSelected,
  plansForSale,
  cohortPassPriceTiers,
  loading
}: ChoosePlanPageProps) => {
  return (
    <PurchaseFlowContainer>
      <div className="flex flex-col items-center justify-center md:m-0">
        {loading ? (
          <Loading />
        ) : (
          <div className="justify-start">
            <SectionHeading>
              Select the membership plan that&rsquo;s right for you
            </SectionHeading>
            <div className="max-w-[600px]">
              Reforge membership makes knowledge from world-class experts accessible and
              actionable so you can build with efficiency and confidence.
            </div>
            <NewUserPlanOptions
              onPlanSelected={onPlanSelected}
              isSubscriptionOwner={true}
              plansForSale={plansForSale}
              cohortPassPriceTiers={cohortPassPriceTiers}
            />
          </div>
        )}
      </div>
    </PurchaseFlowContainer>
  )
}
