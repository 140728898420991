import { Popover } from '@headlessui/react'
import React, { useState } from 'react'
import { usePopper } from 'react-popper'

const PopoverCustom = ({
  children,
  triggerElement
}: {
  children: React.ReactNode
  triggerElement: React.ReactNode
}) => {
  const [referenceElement, setReferenceElement] = useState()
  const [popperElement, setPopperElement] = useState()
  const { styles, attributes } = usePopper(referenceElement, popperElement)

  return (
    <div>
      <Popover>
        <Popover.Button
          className="bg-transparent p-0"
          // @ts-ignore
          ref={setReferenceElement}
        >
          {triggerElement}
        </Popover.Button>

        <div className="relative z-1">
          <Popover.Panel
            // @ts-ignore
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            {children}
          </Popover.Panel>
        </div>
      </Popover>
    </div>
  )
}

export default PopoverCustom
