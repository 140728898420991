import { useRef } from 'react'
import { Link } from 'react-router-dom'

import { getArtifactAuthorPositionAtCompany } from 'domains/Profile/utils'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useOnClickOutside from 'hooks/useOnClickOutside'

const ContributorDropdown = ({ contributors, isOpen, setIsOpen }: any) => {
  const { currentUser: user } = useCurrentUser()

  const ref = useRef<HTMLDivElement | null>(null)

  useOnClickOutside(ref, () => setIsOpen(false), {
    enabled: isOpen
  })

  return (
    <div
      ref={ref}
      className="absolute right-0 top-full z-10 mt-[8px] max-h-max w-full max-w-max rounded border border-rb-gray-250 bg-white p-[10px] shadow-lg"
    >
      {user &&
        contributors.map((contributor: any, idx: number) => {
          const contributorAvatarUrl = contributor.user.avatarUrl
          const contributorName = contributor.user.fullName

          return (
            <Link
              to={`/profiles/${contributor.slug}`}
              key={idx}
              className="flex items-center rounded py-[10px] px-[15px] text-rb-black hover:bg-rb-gray-50"
            >
              {contributorAvatarUrl && (
                <img
                  src={contributorAvatarUrl}
                  style={{ width: '40px', height: '40px' }}
                  className="rounded-full object-cover"
                  alt={`${contributorName} avatar`}
                />
              )}
              <div className="overflow-hidden pl-[15px]">
                <div className="truncate">{contributorName}</div>
                <div className="truncate text-sm font-normal">
                  {getArtifactAuthorPositionAtCompany(contributor)}
                </div>
              </div>
            </Link>
          )
        })}
      {!user &&
        contributors.map((contributor: any, idx: number) => {
          const contributorAvatarUrl = contributor.user.avatarUrl
          const contributorName = contributor.user.fullName

          return (
            <div
              key={idx}
              className="flex items-center rounded py-[10px] px-[15px] text-rb-black"
            >
              <img
                src={contributorAvatarUrl}
                style={{ width: '40px', height: '40px' }}
                className="rounded-full object-cover"
                alt={`${contributorName} avatar`}
              />
              <div className="overflow-hidden pl-[15px]">
                <div className="truncate">{contributorName}</div>
                <div className="truncate text-sm font-normal">
                  {getArtifactAuthorPositionAtCompany(contributor)}
                </div>
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default ContributorDropdown
