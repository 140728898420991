import { Link } from 'react-router-dom'

import UserHeading from 'domains/User/UserHeading'

import { ErrorMessage } from 'components'
import Loading from 'components/Loading'

import { useGroupMembersQuery } from 'gql'

interface PeopleWithExpertiseProps {
  group: string
}

const PeopleWithExpertise = (props: PeopleWithExpertiseProps) => {
  const { group } = props

  const { data, loading, error } = useGroupMembersQuery({
    variables: {
      perPage: 10,
      slug: group,
      sortBy: 'expertise'
    }
  })

  if (error) return <ErrorMessage error={error} />

  const people = data?.groupMembers.members || []

  return (
    <div>
      <div className="uk-margin-bottom flex items-center justify-between border-b border-rb-gray-100 pb-2.5">
        <span className="m-0 text-lg font-semibold text-black">
          People with expertise
        </span>
        <Link
          to={`/groups/${group}/members`}
          className="uk-inline-block uk-text-uppercase m-0 text-xs font-normal tracking-widest text-black hover:text-rb-teal-600 hover:no-underline"
        >
          View All
        </Link>
      </div>
      {loading && <Loading />}
      {!loading && people.length === 0 && (
        <div>Oops, there aren&apos;t any users in this group yet.</div>
      )}
      {!loading &&
        people.length > 0 &&
        people.map((person) => (
          <div
            key={person.id}
            className="uk-card uk-margin-bottom uk-card-body bg-transparent p-4 shadow-none"
          >
            <UserHeading user={person} type="medium" imgWidth="60" imgHeight="60" />
          </div>
        ))}
    </div>
  )
}

export default PeopleWithExpertise
