import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'

export interface PendingInviteSeatAvatarProps {
  email: string
  emailStyles?: string | undefined
  avatarSize: number
}

/**
 * PendingInviteSeatAvatar
 * returns a presentational component for table within Manage Seats
 * containing the seat owner's email
 */
export function PendingInviteSeatAvatar({
  avatarSize = 48,
  email,
  emailStyles = ''
}: PendingInviteSeatAvatarProps) {
  return (
    <div className={`flex h-[${avatarSize}px] items-center`} data-testid="seat-avatar">
      <span
        className={clsx(
          'relative -ml-1.5 inline-block first:ml-0',
          'badgeable__avatar-wrapper',
          'mr-4'
        )}
        style={{ width: `${avatarSize}px`, height: `${avatarSize}px` }}
      >
        <div
          className={'mr-4 rounded-full bg-rb-gray-100'}
          style={{ width: `${avatarSize}px`, height: `${avatarSize}px` }}
        />
      </span>
      <div className="flex flex-col text-left">
        <div
          data-test="pending-invite-seat-text"
          className="m-0 text-sm font-normal italic text-rb-gray-300"
        >
          Pending Invite
        </div>
        <div
          data-test="member-email"
          className={twMerge(
            'flex items-center text-sm font-semibold text-rb-gray-300',
            emailStyles
          )}
        >
          <span className="min-w-0 truncate">{email}</span>
        </div>
      </div>
    </div>
  )
}
