import React from 'react'
import { renderToString } from 'react-dom/server'
import { twMerge } from 'tailwind-merge'

import FacePileContainer from 'components/FacePile/FacePileContainer'
import Tooltip from 'components/Tooltip/Tooltip'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import {
  ActivityFacepileCmsActivityPartsFragment,
  ActivityFacepileCollectionActivityPartsFragment,
  UserAvatarPartsFragment
} from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { capitalizeFirstLetter } from 'utils/stringUtils'

import { FacePileImage, FacePileWithTooltip } from './FacePile'
import { useActivityFacepileInfo } from './useActivityFacepileInfo'

export type UserProps = UserAvatarPartsFragment
type Activity =
  | ActivityFacepileCollectionActivityPartsFragment
  | ActivityFacepileCmsActivityPartsFragment

const activityLineTooltipCopy = ({
  actionType,
  actor,
  creator,
  actionTimeAgoInWords,
  createdTimeAgoInWords
}: {
  actionType: string
  actor: UserAvatarPartsFragment
  creator?: UserAvatarPartsFragment
  actionTimeAgoInWords?: string
  createdTimeAgoInWords?: string
}) => {
  if (!actionTimeAgoInWords) return null

  return renderToString(
    <div className="py-1 px-2">
      {actor.id !== creator?.id && (
        <div>
          {actionType} by <b>{actor.fullName}</b> {actionTimeAgoInWords}
        </div>
      )}
      {creator && (
        <div>
          Created by <b>{creator.fullName}</b> {createdTimeAgoInWords}
        </div>
      )}
    </div>
  )
}

const UserActivityRow = ({
  userId,
  avatarUrl,
  fullName,
  timeAgo
}: {
  userId: string
  avatarUrl: string
  fullName?: string | null
  timeAgo?: string
}) => (
  <div key={userId} className="mt-1.5 flex items-center">
    <div key={userId} className="flex w-full min-w-[140px] items-end">
      <div className="flex min-w-0 flex-shrink-0 flex-row items-center">
        <FacePileImage
          zIndex={1}
          imageSize="small"
          src={avatarUrl}
          className="ml-0"
          fullName={fullName}
        />
        <b className="ml-2 truncate">{fullName}</b>
      </div>
      <div className="ml-auto flex-grow pl-3 text-right">{timeAgo}</div>
    </div>
  </div>
)

const avatarsRemainingTooltipCopy = ({
  usersWithActivity,
  usersWithoutActivity,
  activityUserMap
}: {
  usersWithActivity: UserProps[]
  usersWithoutActivity: UserProps[]
  activityUserMap: { [key: string]: Activity }
}) => {
  if (Object.keys(activityUserMap).length === 0) return null

  return renderToString(
    <div className="flex max-h-[400px] flex-col justify-start overflow-y-auto py-1 px-1">
      {!!usersWithActivity.length && <div>Last viewed by</div>}
      {!!usersWithActivity.length &&
        usersWithActivity.map((user: UserProps) => {
          const activity = activityUserMap[user.id]

          return (
            <UserActivityRow
              key={user.id}
              userId={user.id}
              avatarUrl={user.profile?.avatarUrl || ''}
              fullName={user.fullName || user.email}
              timeAgo={activity.createdAtInWords}
            />
          )
        })}
      {!!usersWithoutActivity?.length && <div className="mt-2">Shared with</div>}
      {!!usersWithoutActivity?.length &&
        usersWithoutActivity.map((user: UserAvatarPartsFragment) => (
          <UserActivityRow
            key={user.id}
            userId={user.id}
            avatarUrl={user.profile.avatarUrl || ''}
            fullName={user.fullName || user.email}
          />
        ))}
    </div>
  )
}

const avatarTooltipCopy = ({
  fullName,
  activity
}: {
  fullName?: string | null
  activity?: Activity | null
}) => {
  const Content = () => {
    return activity ? (
      <>
        {fullName && <span className="font-semibold">{fullName}</span>}
        <span>Last viewed {activity.createdAtInWords}</span>
      </>
    ) : (
      <>
        <span className="font-semibold">Shared with</span>
        <span>{fullName}</span>
      </>
    )
  }

  return renderToString(
    <div className="flex flex-col py-1 px-2.5">
      <Content />
    </div>
  )
}

const ActivityText = ({
  actionType,
  actionTimeAgoInWords
}: {
  actionType: string
  actionTimeAgoInWords?: string | null
}) => {
  if (!actionTimeAgoInWords) return null
  return (
    <div className="mr-2">
      {actionType} {actionTimeAgoInWords}
    </div>
  )
}

const RemainingUserCountTooltip = ({
  children,
  usersWithActivity,
  usersWithoutActivity,
  activityUserMap
}: {
  children: React.ReactNode
  usersWithActivity: UserAvatarPartsFragment[]
  usersWithoutActivity: UserAvatarPartsFragment[]
  activityUserMap: { [key: string]: Activity }
}) => {
  return (
    <Tooltip
      place="bottom"
      html={true}
      delayUpdate={300}
      delayHide={300}
      className="!opacity-100"
      tooltipBody={avatarsRemainingTooltipCopy({
        usersWithActivity,
        usersWithoutActivity,
        activityUserMap
      })}
    >
      {children}
    </Tooltip>
  )
}

const ActivityTextTooltip = ({
  children,
  actionType,
  actor,
  creator,
  createdTimeAgoInWords,
  actionTimeAgoInWords
}: {
  children: React.ReactNode
  actionType: string
  actor: UserAvatarPartsFragment
  creator?: UserAvatarPartsFragment
  createdTimeAgoInWords?: string
  actionTimeAgoInWords?: string
}) => {
  return (
    <Tooltip
      place="bottom"
      html={true}
      tooltipBody={activityLineTooltipCopy({
        actionType,
        actor,
        creator,
        createdTimeAgoInWords,
        actionTimeAgoInWords
      })}
      className="!opacity-100"
      contentWrapperClassname="hover:no-underline"
    >
      {children}
    </Tooltip>
  )
}

export interface ActivityFacepileProps {
  users: UserAvatarPartsFragment[]
  creator?: UserAvatarPartsFragment
  avatarDisplayCount?: number
  activities: Activity[]
  createdTimeAgoInWords?: string
  inCard?: boolean
  isShared?: boolean
}

export const ActivityFacepile = ({
  avatarDisplayCount = 4,
  users,
  creator,
  createdTimeAgoInWords,
  activities,
  inCard = false,
  isShared = false
}: ActivityFacepileProps) => {
  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)

  // fallbacks assume that the activity is only by creator
  const mostRecentActivity = activities[0]
  const actor = mostRecentActivity?.user || creator
  const actionType = capitalizeFirstLetter(mostRecentActivity?.actionType || 'edited')
  const actionTimeAgoInWords =
    mostRecentActivity?.createdAtInWords || createdTimeAgoInWords

  const imageSize = inCard ? 'small' : isMobile ? 'xlarge' : 'xxlarge'

  const {
    onlyCreatorActivity,
    usersWithActivityInFacepile,
    usersWithoutActivityInFacepile,
    usersWithActivityNotInFacepile,
    usersWithoutActivityNotInFacepile,
    usersNotInFacepile,
    activityUserMap
  } = useActivityFacepileInfo({
    users,
    activities,
    creator,
    avatarDisplayCount
  })

  return (
    <div
      className={`flex items-center text-rb-gray-300 ${inCard ? 'text-sm' : 'text-base'}`}
    >
      <ActivityTextTooltip
        actionType={actionType}
        actor={actor}
        creator={creator}
        actionTimeAgoInWords={actionTimeAgoInWords}
        createdTimeAgoInWords={createdTimeAgoInWords}
      >
        <ActivityText
          actionType={actionType}
          actionTimeAgoInWords={actionTimeAgoInWords}
        />
      </ActivityTextTooltip>
      {!isShared ? null : onlyCreatorActivity && isShared ? (
        <FacePileImage
          zIndex={1}
          imageSize={imageSize}
          src={creator?.profile?.avatarUrl || ''}
          className={twMerge('ml-0', isMobile ? 'h-8 w-8' : '')}
          fullName={creator?.fullName}
        />
      ) : (
        <FacePileContainer>
          <FacePileWithTooltip
            usersWithActivities={usersWithActivityInFacepile}
            usersWithoutActivities={usersWithoutActivityInFacepile}
            imageSize={imageSize}
            className={isMobile ? 'h-8 w-8' : ''}
            getTooltipBody={({ user }: { user: UserProps }) =>
              avatarTooltipCopy({
                fullName: user.fullName,
                activity: activityUserMap[user.id]
              })
            }
          />
          {!onlyCreatorActivity && usersNotInFacepile.length > 0 && (
            <RemainingUserCountTooltip
              usersWithActivity={usersWithActivityNotInFacepile}
              usersWithoutActivity={usersWithoutActivityNotInFacepile}
              activityUserMap={activityUserMap}
            >
              <div className={`${inCard ? 'pl-1' : 'pl-2'}`}>
                {`+ ${usersNotInFacepile.length}`}
              </div>
            </RemainingUserCountTooltip>
          )}
        </FacePileContainer>
      )}
    </div>
  )
}
