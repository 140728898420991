import { clsx } from 'clsx'
import React from 'react'

export const Column = ({
  children,
  className
}: {
  children: React.ReactNode
  className?: string
}) => {
  return <div className={clsx('flex flex-col space-y-10', className)}>{children}</div>
}
