import { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

interface RfCardTitleProps {
  className?: string
}

const RfCardTitle = ({ children, className }: PropsWithChildren<RfCardTitleProps>) => (
  <h1
    className={twMerge(
      'mb-0 text-rb-gray-500',
      className, // allow color, margin and padding overrides
      'text-[20px] font-medium leading-[24px]'
    )}
  >
    {children}
  </h1>
)

export default RfCardTitle
