import { renderToString } from 'react-dom/server'

import { TooltipCopy } from 'components/ResponseCount/responseCountUtils'

import { ReactionKind } from 'gql'

import { TLegacyOrGraphQLReaction, kindToEnglish } from 'utils/reactionUtils'

interface ReactionTooltipNameProps {
  userFullName: string
}

const ReactionTooltipName = ({ userFullName }: ReactionTooltipNameProps) => (
  <>
    <span>{userFullName}</span>
    <br />
  </>
)

export const getReactionNames = (
  reactions: TLegacyOrGraphQLReaction[],
  currentUserId?: string
) => {
  const sortedUsers = reactions
    .map((reaction) => reaction.user)
    .sort(function (first) {
      if (first.id.toString() === currentUserId) {
        return -1
      } else {
        return 0
      }
    })

  return sortedUsers.map((user) => {
    const userFullName = 'profile' in user ? user?.profile?.fullName : user.fullName

    if (user.id.toString() === currentUserId) {
      return 'You'
    } else {
      return userFullName
    }
  })
}

const getNames = (reactions: TLegacyOrGraphQLReaction[], currentUserId: string) => {
  const sortedUsers = reactions
    .map((reaction) => reaction.user)
    .sort(function (first) {
      if (first.id.toString() === currentUserId) {
        return -1
      } else {
        return 0
      }
    })

  const names = [
    ...new Map(
      sortedUsers.map((user, index) => {
        const userFullName = 'profile' in user ? user?.profile?.fullName : user.fullName

        // extract the unique user's names, append 'You' to current users name if present
        if (user.id.toString() === currentUserId) {
          return [
            user.id,
            <ReactionTooltipName
              key={index}
              userFullName={`${userFullName?.split(' ')[0]} (You)`}
            />
          ]
        } else {
          return [
            user.id,
            <ReactionTooltipName key={index} userFullName={userFullName || ''} />
          ]
        }
      })
    ).values()
  ]

  return names
}

interface TooltipProps {
  reactions: TLegacyOrGraphQLReaction[]
  currentUserId: string
  kind: ReactionKind
}

export const getTooltipCopy = ({ reactions, currentUserId, kind }: TooltipProps) => {
  if (reactions.length === 0) {
    return renderToString(<TooltipCopy>No reactions yet</TooltipCopy>)
  }

  return renderToString(
    <TooltipCopy>
      {getNames(reactions, currentUserId)}
      <span className="text-rb-gray-100">
        reacted &lsquo;{kindToEnglish[kind]}&lsquo;
      </span>
    </TooltipCopy>
  )
}
