import { useState } from 'react'

import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import LessonViewer from './LessonViewer'

const LoggedOutLessonViewer = () => {
  const [page, setPage] = useState<GenericPageType>()

  return (
    <MarketingLayout page={page}>
      <div className="px-4 py-8 md:px-[4vw]">
        <div className="mx-auto max-w-[1280px]">
          <LessonViewer setPage={setPage} />
        </div>
      </div>
    </MarketingLayout>
  )
}

export default LoggedOutLessonViewer
