import ReactionButton from 'components/ReactionButton'
import ReactionPicker from 'components/ReactionPicker'
import UpvoteButton from 'components/UpvoteButton'

import { ReactionKind } from 'gql'

import {
  TLegacyOrGraphQLReaction,
  hasReactionOfKind,
  mapReactionKinds,
  sortedReactions
} from 'utils/reactionUtils'

// The `readOnly` attribute has been added in addition to `disabled`, to allow for displaying reactions without
// allowing for adding new ones that have not been added yet by omitting the ReactionPicker. Using the `disabled`
// property to omit the `ReactionPicker` would result in a weird user experience as `disabled` is typically set to
// true when a reaction GraphQL mutation is executing, so this would cause the ReactionPicker to disappear while
// the mutation executes, then reappear when it has completed. It is also passed as a property to the UpvoteButton
// component, to slightly change its behavior.
interface ReactionButtonsProps {
  reactions: TLegacyOrGraphQLReaction[]
  currentUserId: string
  addReaction: (kind: ReactionKind) => void
  removeReaction: (id: string | number) => void
  reactableType: string
  reactableId: string
  disabled: boolean
  containerClassName?: string
  reactionPickerContainerClassName?: string
  svgContainerClassName?: string
  className?: string
  height?: string
  width?: string
  readOnly?: boolean
}

export const ReactionButtons = ({
  reactions,
  currentUserId,
  addReaction,
  removeReaction,
  reactableType,
  reactableId,
  disabled,
  reactionPickerContainerClassName,
  svgContainerClassName,
  className,
  height,
  width,
  readOnly = false
}: ReactionButtonsProps) => {
  const reactionKinds = mapReactionKinds(reactions)
  const isMissingOneReactionKind = Object.values(reactionKinds).some(
    (kind) => kind.length === 0
  )

  const isActive = reactions.some((reaction) => reaction.user.id === currentUserId)

  const sortedWithoutUpvote = sortedReactions.filter(
    (reaction) => reaction !== ReactionKind.UPVOTE
  )

  return (
    <div className="flex flex-row">
      <UpvoteButton
        className={
          hasReactionOfKind({ currentUserId, reactions, kind: ReactionKind.UPVOTE })
            ? 'text-rb-jade-200 no-underline'
            : ''
        }
        reactions={reactionKinds[ReactionKind.UPVOTE]}
        currentUserId={currentUserId}
        removeReaction={removeReaction}
        addReaction={addReaction}
        disabled={disabled}
        isActive={isActive}
        containerClassName={reactionPickerContainerClassName}
        svgContainerClassName={svgContainerClassName}
        readOnly={readOnly}
      />
      {sortedWithoutUpvote.map((kind: ReactionKind) => (
        <ReactionButton
          key={kind}
          kind={kind}
          isActive={isActive}
          reactions={reactionKinds[kind]}
          currentUserId={currentUserId}
          removeReaction={removeReaction}
          addReaction={addReaction}
          disabled={disabled || readOnly}
          className={
            hasReactionOfKind({ currentUserId, reactions, kind })
              ? 'text-rb-jade-200 no-underline'
              : ''
          }
        />
      ))}
      {isMissingOneReactionKind && !readOnly && (
        <ReactionPicker
          addReaction={addReaction}
          removeReaction={removeReaction}
          reactions={reactions}
          currentUserId={currentUserId}
          reactableType={reactableType}
          reactableId={reactableId}
          containerClassName={reactionPickerContainerClassName}
          svgContainerClassName={svgContainerClassName}
          className={className}
          width={width}
          height={height}
        />
      )}
    </div>
  )
}

export default ReactionButtons
