import { twJoin } from 'tailwind-merge'

import AvatarImage from 'domains/Artifact/AvatarImage'

import { CompanyLogoAvatar } from 'components/CompanyLogoAvatar/CompanyLogoAvatar'

import { ReactComponent as ArtifactsIcon } from './artifacts-icon.svg'
import { ReactComponent as CoursesIcon } from './courses-icon.svg'
import { ReactComponent as SaveIcon } from './save-icon.svg'

export interface ProfileCardProps {
  fullName: string | null
  role: string | null
  companyName: string | null
  avatarUrl: string | null
  isTransitioning: boolean
  companyLogoUrl: string | null
}

/**
 * ProfileCard
 * Returns a styled ProfileCard
 * Displays empty default state if no props are provided
 * */
export const ProfileCard = ({
  fullName,
  role,
  avatarUrl,
  companyName,
  isTransitioning,
  companyLogoUrl
}: ProfileCardProps) => {
  const shouldShowAvatar = fullName || avatarUrl
  const getCompanyName = () => {
    if (isTransitioning) {
      return ''
    }
    return companyName ? ` @ ${companyName}` : ' @ Company'
  }

  return (
    <div
      className={twJoin(
        'text-md w-72 rounded-lg bg-white p-6 shadow-card',
        shouldShowAvatar ? 'pt-16' : ''
      )}
      data-testid="profile-card"
    >
      {shouldShowAvatar && (
        <div className="mt-[-120px]">
          <div className="relative">
            <AvatarImage
              size="large"
              user={{ fullName: fullName || null, avatarUrl }}
              withWhiteBorder={true}
            />
            <div className="absolute right-[127px] -bottom-0.5 rounded bg-rb-white">
              <CompanyLogoAvatar
                company={companyName || null}
                imageUrl={companyLogoUrl || null}
              />
            </div>
          </div>
        </div>
      )}
      <p className="mb-1 mt-6 text-sm">{fullName || 'Your name'}</p>
      <p className="text-sm text-rb-gray-300">
        {role || 'Your role'}
        {getCompanyName()}
      </p>
      <div className="mt-6 grid grid-cols-3 items-center gap-0.5 text-rb-gray-300">
        <div className="flex flex-col items-center justify-center">
          <ArtifactsIcon width={20} height={20} />
          <p className="mb-0 mt-1 text-xs">Artifacts</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <CoursesIcon width={20} height={20} />

          <p className="mb-0 mt-1 text-xs">Courses</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <SaveIcon width={20} height={20} />

          <p className="mb-0 mt-1 text-xs">Saves</p>
        </div>
      </div>
    </div>
  )
}

export default ProfileCard
