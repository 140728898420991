import { ReforgeCollection } from './reforgeCollectionsConstants'

const BUSINESS_OUTCOME_COLLECTION_IDS: string[] = [
  '116',
  '134',
  '126',
  '125',
  '140',
  '144',
  '387',
  '357'
]
const LEVEL_UP_COLLECTION_IDS: string[] = [
  '251345',
  '146',
  '153',
  '158',
  '132',
  '258',
  '170',
  '220873',
  '226037',
  '241124'
]
const DEVELOP_CORE_COLLECTION_IDS: string[] = ['122', '149', '240']

export const filterCollections = (
  filter: string,
  collections: ReforgeCollection[] | null | undefined,
  goals: string[]
) => {
  if (!collections) return null
  const [BUSINESS_OUTCOME, LEVEL_UP, DEVELOP_CORE] = goals
  if (filter === BUSINESS_OUTCOME) {
    return collections.filter((collection) => {
      return (
        BUSINESS_OUTCOME_COLLECTION_IDS.includes(collection.id) &&
        !collection.isWhiteGlove
      )
    })
  } else if (filter === LEVEL_UP) {
    return collections.filter((collection) => {
      return LEVEL_UP_COLLECTION_IDS.includes(collection.id) && !collection.isWhiteGlove
    })
  } else if (filter === DEVELOP_CORE) {
    return collections.filter((collection) => {
      return (
        DEVELOP_CORE_COLLECTION_IDS.includes(collection.id) && !collection.isWhiteGlove
      )
    })
  }
}
