import { useElements, useStripe } from '@stripe/react-stripe-js'
import { useCallback } from 'react'

import { BillingAddressFragment, useUpdatePaymentMethodMutation } from 'gql'

type AddPaymentMethodOptions = {
  saveAsDefault?: boolean
  billingAddress?: BillingAddressFragment | null
}

export function useAddPaymentMethod() {
  const stripe = useStripe()
  const elements = useElements()
  const [updatePaymentMethod] = useUpdatePaymentMethodMutation()

  const addPaymentMethod = useCallback(
    async (options: AddPaymentMethodOptions) => {
      if (!stripe || !elements) {
        throw new Error('Stripe not loaded')
      }

      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${window.location.href}?success=true`,
          payment_method_data: {
            billing_details: {
              address: {
                line1: options.billingAddress?.line1,
                line2: options.billingAddress?.line2 || '',
                city: options.billingAddress?.city,
                state: options.billingAddress?.state || '',
                postal_code: options.billingAddress?.postal_code,
                country: options.billingAddress?.country
              }
            }
          }
        },
        redirect: 'if_required'
      })

      if (error?.message || !setupIntent?.payment_method) {
        throw new Error(
          error?.message || 'Something went wrong while processing your payment method'
        )
      }

      const paymentMethodId =
        typeof setupIntent.payment_method === 'string'
          ? setupIntent.payment_method
          : setupIntent.payment_method.id

      options?.saveAsDefault &&
        (await updatePaymentMethod({
          variables: { input: { id: paymentMethodId, isDefaultPaymentMethod: true } }
        }))

      return paymentMethodId
    },
    [stripe, elements, updatePaymentMethod]
  )

  return addPaymentMethod
}
