import { SuggestedPromptsWidget } from 'domains/Ai/SuggestedPrompts/SuggestedPromptsWidget'
import AntiCopy from 'domains/AntiPiracy/AntiCopy'
import BookmarkableWistiaPlayer, {
  MappedVideoBookmarks
} from 'domains/Cms/BookmarkableWistiaPlayer'
import CmsSectionDescription from 'domains/Cms/CmsSectionDescription'
import { CmsLessonPartialContent } from 'domains/Cms/CmsSectionPartialContent'
import CopyrightFooter from 'domains/Cms/CopyrightFooter'
import FeedbacksCmsSection from 'domains/Cms/FeedbacksCmsSection'
import FooterNav from 'domains/Cms/FooterNav'
import LessonSummary from 'domains/Cms/LessonSummary'
import { NextLessonData } from 'domains/Cms/VideoOverlay/NextLessonOverlay'

import { ErrorMessage } from 'components'

import {
  BookmarkableWistiaPlayerBookmarkFragment,
  CurrentUserPartsFragment,
  LessonViewerQuery,
  ProgramBookmarkPartsFragment
} from 'gql'

import { cn } from 'utils/tailwind'
import { makeLessonViewerUrl } from 'utils/url'

import { CmsSectionProgress } from 'typings/scalars'

interface LessonViewerMainContentProps {
  currentUser?: CurrentUserPartsFragment
  lessonViewerData: LessonViewerQuery
  needsFeedback: boolean
  fromCohortViewer?: boolean
  cohortSlug?: string
  isSectionPage?: boolean
  setActiveDiscoveryDrawerPanel?: (type: string) => void
  trackActionButtonClick?: (type: 'discussions' | 'bookmarks') => void
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
}

const LessonViewerMainContent = ({
  lessonViewerData,
  currentUser,
  needsFeedback,
  fromCohortViewer = false,
  cohortSlug,
  isSectionPage = false,
  setActiveDiscoveryDrawerPanel,
  trackActionButtonClick,
  openAddToBookmarkFolderModal
}: LessonViewerMainContentProps) => {
  const { lessonViewer } = lessonViewerData

  if (
    !lessonViewer?.cmsProgram ||
    !lessonViewer?.cmsModule ||
    !lessonViewer?.cmsSection
  ) {
    return (
      <div className="ml-0 h-screen">
        <ErrorMessage error={new Error('Failed to load lesson viewer data.')} />
      </div>
    )
  }

  const { cmsProgram, cmsModule, cmsSection, userProgram } = lessonViewer

  return (
    <div className="cms-content learning-learning-show_section">
      <div
        className={cn(
          'wysiwig-container pr-2.5 pl-2.5 xs:pr-4 xs:pl-4 md:pr-8 md:pl-8',
          !currentUser && 'px-0'
        )}
      >
        <div className="min-h-[calc( 100vh - 100px)] box-border max-w-[970px] transition-width-margin duration-300 tl:my-0 tl:mx-auto">
          {cmsSection.wistiaCode ? (
            <>
              <BookmarkableWistiaPlayer
                code={cmsSection.wistiaCode}
                objectType={
                  cmsSection.contentType &&
                  ['Unit', 'Resource'].includes(cmsSection.contentType)
                    ? cmsSection.contentType
                    : 'Lesson'
                }
                cmsProgramId={cmsProgram.id}
                cmsModuleId={cmsModule.id}
                cmsSectionId={cmsSection.id}
                userEmail={currentUser?.contact.primaryEmail || ''}
                bookmarks={mapVideoBookmarks(cmsSection.videoBookmarks || [])}
                moduleName={cmsModule.name}
                isCoreLesson={cmsSection.countsTowardsCompletion}
                nextLessonData={
                  cmsSection.nextSection?.countsTowardsCompletion
                    ? ({
                        estimatedTime: cmsSection.nextSection.estimatedTime.toString(),
                        image: cmsSection.nextSection.heroImageUrl,
                        lessonCount: (cmsSection.nextSection.position || 0) + 1,
                        name: cmsSection.nextSection.name,
                        path:
                          cohortSlug &&
                          cmsModule.slug &&
                          cmsSection.nextSection?.parent?.slug &&
                          cmsSection.nextSection?.slug
                            ? makeLessonViewerUrl(
                                cohortSlug,
                                cmsProgram.slug,
                                cmsModule.slug,
                                cmsSection.nextSection.parent.slug,
                                cmsSection.nextSection.slug
                              )
                            : cmsSection.nextSection.href
                      } as NextLessonData)
                    : null
                }
                userProgress={
                  (userProgram?.progress?.[cmsModule.id]?.[`${cmsSection.id}d`] ||
                    {}) as CmsSectionProgress
                }
              />
              <div className="mb-8">
                <SuggestedPromptsWidget
                  premiumUser={!!currentUser && !currentUser?.is?.premember}
                />
              </div>
            </>
          ) : (
            <div className="!pt-[30px]" />
          )}
          <div className="fr-content-wrapper">
            {lessonViewer.viewAccess === 'partial' && (
              <CmsLessonPartialContent
                currentUser={currentUser}
                cmsSection={lessonViewer.cmsSection}
              />
            )}
            {lessonViewer.viewAccess === 'full' && (
              <>
                <CmsSectionDescription
                  cmsSection={cmsSection}
                  descriptionHTML={
                    cmsSection.blockedDescriptionHtmlSafe ||
                    cmsSection.descriptionHtmlSafe
                  }
                  setActiveDiscoveryDrawerPanel={setActiveDiscoveryDrawerPanel}
                  trackActionButtonClick={trackActionButtonClick}
                  openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                />

                {cmsSection.summaryHtmlSafe && (
                  <LessonSummary summaryHTML={cmsSection.summaryHtmlSafe} />
                )}
              </>
            )}
            <br />
            {!isSectionPage && needsFeedback && currentUser && (
              <div
                className="lesson-feedback lesson-feedback--bottom mt-5 mb-5 border-t-2 border-solid border-rb-gray-50 text-center text-rb-gray-400 sm:!text-left"
                uk-scrollspy="cls: uk-animation-fade; repeat: true"
                data-test="lesson-feedback-bottom"
              >
                <FeedbacksCmsSection
                  cmsSectionId={cmsSection.id}
                  cmsSectionCmsProgramId={cmsProgram.id}
                  userId={currentUser.id}
                  nextSectionUrl={cmsSection.nextSectionLearningCmsSectionPath}
                  inline
                />
              </div>
            )}
          </div>
        </div>
        {!isSectionPage && currentUser && (
          <FooterNav
            currentUser={currentUser}
            lessonDetails={lessonViewer}
            fromCohortViewer={fromCohortViewer}
            cohortSlug={cohortSlug}
          />
        )}
        <div id="anticopy"></div>
        {currentUser && (
          <AntiCopy isAdmin={currentUser.is.admin} userId={currentUser.id} />
        )}
      </div>
      <CopyrightFooter />
    </div>
  )
}

function mapVideoBookmarks(
  bookmarks: BookmarkableWistiaPlayerBookmarkFragment[]
): MappedVideoBookmarks {
  return bookmarks.reduce((videoBookmarks, bookmark) => {
    if (typeof bookmark.seconds === 'number') {
      return {
        ...videoBookmarks,
        [bookmark.seconds]: bookmark
      }
    }
    return videoBookmarks
  }, {})
}

export default LessonViewerMainContent
