import CourseFeatureGrid from 'domains/Course/CourseFeatureGrid'

import { Modal } from 'components/Modal'
import { CloseIcon } from 'components/icons'

import { onEnterKeyPress } from 'utils/keyboard'

interface CourseFeatureGridModalProps {
  isModalOpen: boolean
  setIsModalOpen: (isOpen: boolean) => void
}

const CourseFeatureGridModal = ({
  isModalOpen,
  setIsModalOpen
}: CourseFeatureGridModalProps) => {
  if (!isModalOpen) return null

  const handleClose = () => {
    setIsModalOpen(false)
  }

  return (
    <Modal
      isOpen={isModalOpen}
      handleClose={handleClose}
      containerClass="z-[1011]"
      className="relative max-w-6xl rounded p-6 md:p-12 overflow-y-auto"
      header={false}
      closeOnEscape={true}
      closeOnOutsideClick={true}
      scrollContent
    >
      <div
        onClick={handleClose}
        onKeyUp={onEnterKeyPress(handleClose)}
        className="hover:bg-default absolute right-6 top-5 cursor-pointer"
        role="button"
        tabIndex={0}
      >
        <CloseIcon className="h-6 w-6 sm:h-8 sm:w-8" />
      </div>

      <CourseFeatureGrid tableClassName="w-full" />
    </Modal>
  )
}

export default CourseFeatureGridModal
