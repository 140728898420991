import { useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import CohortFroalaWrapper from 'domains/CohortConversation/Post/CohortFroalaWrapper'
import CreatePostOrReplyFormModal from 'domains/CohortConversation/Post/CreatePostOrReplyFormModal'
import NotifyCohortModal from 'domains/CohortConversation/Post/NotifyCohortModal'
import { PostFormButton } from 'domains/CohortConversation/Post/PostFormButton'
import useCreateCohortPost from 'domains/CohortConversation/hooks/useCreateCohortPost'
import useUpdateCohortPost from 'domains/CohortConversation/hooks/useUpdateCohortPost'

import BadgeableAvatar from 'components/BadgeableAvatar'
import { useModal } from 'components/Modal'
import { Checkbox, Form } from 'components/forms'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import RfParagraphSmallBold from 'components/typography/RfParagraph/RfParagraphSmallBold'

import { CohortPostPartsFragment } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'
import useOnClickOutside from 'hooks/useOnClickOutside'

export interface CreatePostFormProps {
  refetch?(): Promise<unknown>
  active?: boolean
  noStyling?: boolean
  post?: CohortPostPartsFragment
  onSaveCallback?(): void
  isMobile?: boolean
  placeholder?: string | null
}

const CreatePostForm = ({
  post,
  refetch,
  active,
  noStyling,
  onSaveCallback,
  isMobile,
  placeholder
}: CreatePostFormProps) => {
  const [content, setContent] = useState(post?.body || '')
  const [notifyCohort, setNotifyCohort] = useState(true)
  const [loading, setLoading] = useState(false)
  const [isActive, setIsActive] = useState(active)
  const [postButtonOpacity, setPostButtonOpacity] = useState(active ? 100 : 0)
  const { createCohortPost } = useCreateCohortPost()
  const { updateCohortPost } = useUpdateCohortPost()
  const { openModal, isModalOpen, closeModal } = useModal()
  const {
    openModal: openFormModal,
    isModalOpen: isFormModalOpen,
    closeModal: closeFormModal
  } = useModal()

  // Since Froala automatically converts the editor contents into an HTML string, we need to explicitely check
  // either for an empty string (before content saved in the Froala editor) or for an HTML string that only contains
  // the space character of &nbsp; or actual empty spaces in a paragraph tag
  const editorContentIsEmpty =
    content === '' || content.replace(/&nbsp;/g, '').replace(/ /g, '') === '<p></p>'

  const containerRef = useRef<HTMLDivElement>(null)
  const currentUser = useAssertCurrentUser()
  const mentionContainerId = 'create-post-mention-container'
  const showNotifyCohort = (currentUser?.is.collective || currentUser?.is.staff) && !post

  const toggleIsActive = (isActive: boolean) => {
    if (!active) {
      setIsActive(isActive)

      if (isActive) setTimeout(() => setPostButtonOpacity(1), 50)
      else setPostButtonOpacity(0)
    }
  }

  useOnClickOutside(containerRef, toggleIsActive.bind(this, false))

  const submitForm = async () => {
    if (loading) {
      return
    }

    setLoading(true)

    if (post && post.id) {
      await updateCohortPost({ postId: post.id, body: content })
    } else {
      await createCohortPost({ body: content, notifyCohort })
    }

    await refetch?.()
    setContent('')
    setLoading(false)
    setNotifyCohort(false)
    onSaveCallback?.()
    isFormModalOpen && closeFormModal()
  }

  return (
    <div
      className={twMerge(
        !noStyling &&
          'flex flex-col overflow-visible rounded-[8px] py-[20px] px-[25px] shadow-cohort-post-card transition-max-height duration-[500ms]',
        isActive ? 'max-h-[500px]' : 'max-h-[100px]'
      )}
      ref={containerRef}
      onClick={toggleIsActive.bind(this, true)}
    >
      <div id={mentionContainerId} className="relative top-[-150px]" />

      <div className="box-border flex w-full max-w-full items-center">
        <BadgeableAvatar
          avatarUrl={currentUser.profile.avatarUrl}
          user={currentUser}
          className="mr-[25px]"
          width="40"
          height="40"
          borderClassNames="rounded"
          fullName={currentUser.profile.firstName}
        />
        {isMobile ? (
          <div role="button" onClick={openFormModal}>
            <RfParagraphSmallBold>
              {placeholder || 'Create a post...'}
            </RfParagraphSmallBold>
          </div>
        ) : isActive || active ? (
          <div className="flex w-full flex-col">
            <CohortFroalaWrapper
              model={content}
              updateModel={setContent}
              menuContainerId={mentionContainerId}
              placeholder="Write something..."
              className="create-post w-0 min-w-full grow"
              autofocus={true}
              heightMax={200}
            />

            <div
              className={twMerge(
                'flex items-end',
                showNotifyCohort ? 'justify-between' : 'justify-end'
              )}
            >
              {showNotifyCohort && (
                <Form
                  submit={() => {}}
                  submitButton={false}
                  className="flex items-center"
                >
                  <Checkbox
                    className="mr-3"
                    name="notifyCohort"
                    onChange={() => setNotifyCohort((notifyCohort) => !notifyCohort)}
                    showLabel={false}
                    currentValue={notifyCohort}
                  />
                  <RfParagraphSmall>Notify Cohort</RfParagraphSmall>
                </Form>
              )}
              <PostFormButton
                loading={loading}
                submitForm={notifyCohort ? openModal : submitForm}
                disabled={editorContentIsEmpty}
                className={`opacity-${postButtonOpacity} transition-[opacity]`}
                label={post ? 'Save' : 'Post'}
              />
            </div>
          </div>
        ) : (
          <div className="flex h-[60px] w-full cursor-text items-center rounded-[10px] bg-rb-gray-50 px-7">
            <RfParagraphSmallBold>
              {placeholder || 'Create a post...'}
            </RfParagraphSmallBold>
          </div>
        )}
        <CreatePostOrReplyFormModal
          isModalOpen={isFormModalOpen}
          setContent={setContent}
          handleModalClose={closeFormModal}
          isMobile={isMobile}
          content={content}
          type="post"
          menuContainerId={mentionContainerId}
          loading={loading}
          submitForm={submitForm}
          postButtonOpacity={postButtonOpacity}
        />
      </div>

      {showNotifyCohort && (
        <NotifyCohortModal
          isOpen={isModalOpen}
          submitForm={() => {
            closeModal()
            submitForm()
          }}
          onClose={closeModal}
        />
      )}
    </div>
  )
}

export default CreatePostForm
