import { useHistory } from 'react-router-dom'

import Button from 'components/Button'

import { ProductTourKey } from 'gql'

import { cn } from 'utils/tailwind'

import { CourseLiveBanner } from '../CourseLiveBanner'
import SlackBanner from '../SlackBanner'

type AnnouncementBannerProps = {
  data: any
  identifier: string
  isVisible: boolean
  onClick?: (identifier: ProductTourKey | string) => void
  setActiveComponents: (prev: string[] | string) => void
}

export const AnnouncementBanner = ({
  data,
  identifier,
  isVisible,
  onClick,
  setActiveComponents
}: AnnouncementBannerProps) => {
  const history = useHistory()

  if (!isVisible) return null

  if (identifier === 'slack-announcement-banner') {
    return <SlackBanner hasSeenJoinSlackBanner={false} />
  }

  if (identifier === 'course-live-banner') {
    return <CourseLiveBanner setActiveComponents={setActiveComponents} />
  }

  const handleClick = () => {
    onClick?.(identifier)
    history.push(data.ctaUrl)
  }

  return (
    <div
      className={cn(
        'flex w-full h-[200px] flex-col justify-center gap-8 self-stretch rounded-xl p-6 md:px-12 lg:py-0 md:flex-row md:items-center'
      )}
      style={{ backgroundColor: data.color }}
    >
      {data.image && (
        <div
          className={cn(
            'hidden items-center lg:flex max-w-[184px] h-full [&_img]:object-cover [&_*]:h-full'
          )}
        >
          <img alt="banner" src={data.image} />
        </div>
      )}
      <div className="flex w-full flex-col gap-8 md:flex-1 md:flex-row">
        <div className="lg:py-12">
          <div className="text-[28px] font-light leading-[1.25] tracking-[-0.56px] mb-2">
            {data.header}
          </div>
          <div className="leading-[1.6]">{data.subHeader}</div>
        </div>
      </div>
      <div>
        <Button
          onClick={handleClick}
          variant="outline"
          size="small"
          className="font-semibold"
        >
          {data.ctaText}
        </Button>
      </div>
    </div>
  )
}
