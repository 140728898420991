import LiveHost from 'live-hosts.png'
import { useEffect, useState } from 'react'

import Button from 'components/Button'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { track, trackNavigationClicked } from 'utils/tracking/analytics'

export const CourseLiveBanner = ({ setActiveComponents }: any) => {
  const [ctaText, setCtaText] = useState('')
  const [bannerText, setBannerText] = useState({
    header: '',
    subheader: ''
  })

  const { currentUser } = useCurrentUser()

  const handleBannerDismissed = () => {
    track('Banner Dismissed', {
      banner_name: 'live_courses_available',
      category: 'app'
    })
    window.localStorage.setItem('dismiss-live-course-banner', '1')

    setActiveComponents((previousVal: string[]) =>
      previousVal.filter((v: string) => v !== 'course-live-banner')
    )
  }

  useEffect(() => {
    const numberOfCohortCredits = currentUser?.numberOfUseableCohortCredits
    const hasUseableCohortCredits = currentUser?.hasUseableCohortCredits

    if (currentUser?.is.planManager && hasUseableCohortCredits) {
      setCtaText('View live courses')
      setBannerText({
        header: 'Live Courses now open for enrollment!',
        subheader: `Your team has ${numberOfCohortCredits} of unassigned live course passes. Check out the suite of courses your team can enroll in with their passes.`
      })
    } else if (currentUser?.is.member && hasUseableCohortCredits) {
      setCtaText('Enroll for free')
      setBannerText({
        header: 'Enroll in a live course for free',
        subheader:
          'Learn from and connect with top AI, Growth, Product, and Strategy experts in live courses available this fall. Use your live course pass to join for free.'
      })
    } else {
      setCtaText('View live courses')
      setBannerText({
        header: 'Live Courses now open for enrollment!',
        subheader:
          'Learn from and connect with top AI, Growth, Product, and Strategy experts in live courses available this fall.'
      })
    }
  }, [currentUser])

  useEffect(() => {
    track('Banner Displayed', {
      banner_name: 'live_courses_available',
      category: 'app'
    })
  }, [])
  return (
    <div className="bg-rb-orange-25 h-[200px] gap-6 md:gap-8 p-6 lg:py-8 lg:px-12 flex flex-col lg:flex-row justify-around items-start lg:items-center rounded-xl">
      <div className="h-[145px] w-[145px] hidden xl:flex items-center">
        <img alt="many courses" src={LiveHost} width={145} height={145} />
      </div>
      <div className="flex flex-col justify-center flex-1 xl:w-[600px]">
        <div className="font-polysans text-xl md:text-[28px] font-normal">
          {bannerText.header}
        </div>
        <div className="font-sans font-normal text-sm md:text-base">
          {bannerText.subheader}
        </div>
      </div>
      <div className="flex items-center gap-4">
        <Button size="small" variant="outline" onClick={handleBannerDismissed}>
          Dismiss
        </Button>
        <Button
          size="small"
          href="/courses?tab=live"
          onClick={() =>
            trackNavigationClicked({
              location: 'free_user_home | premium_user_home'
            })
          }
        >
          {ctaText}
        </Button>
      </div>
    </div>
  )
}
