import { SectionHeading } from 'components/CheckoutModal/components/Headings'
import { SectionBorder } from 'components/CheckoutModal/components/SectionBorder'

import { PlanName } from 'gql'

import { formatMoney } from 'utils/moneyUtils'
import { capitalizeFirstLetter } from 'utils/stringUtils'

export interface SelectedPlanProps {
  name: PlanName
  pricePerYear: number | null
  maxSeatCount: number | null
}

export const SelectedPlan = ({ name, pricePerYear, maxSeatCount }: SelectedPlanProps) => {
  return (
    <div className="flex flex-col" data-testid="selected-plan-section">
      <SectionHeading>Selected Plan</SectionHeading>
      <SectionBorder className="items-center">
        <div>
          <div className="text-lg font-medium text-rb-teal-200">
            {capitalizeFirstLetter(name)}
          </div>
          <div data-testid="max-seats" className="text-sm font-light">
            {maxSeatCount === 1 ? '1 seat' : `Up to ${maxSeatCount} seats`}
          </div>
        </div>
        {pricePerYear && (
          <div className="pl-8">
            <div className="pb-0.5 text-xl font-semibold">
              <span className="text-rb-teal-200">{formatMoney(pricePerYear)}</span>
              <span> / year</span>
            </div>
            <div className="text-right font-medium text-rb-gray-300 xs:text-left">
              + sales tax
            </div>
          </div>
        )}
      </SectionBorder>
    </div>
  )
}
