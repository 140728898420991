import { useState } from 'react'
import UIkit from 'uikit'

import {
  formattedEventDate,
  formattedEventTimeRange
} from 'domains/CohortDashboard/CohortDashboardProgram/CohortDashboardWeeklyBlock/WeeklyBlockEventTimes'
import { CohortEvent } from 'domains/CohortViewer/utils'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'
import { Modal } from 'components/Modal'
import SelectListBlock from 'components/SelectListBlock'
import { InfoIcon } from 'components/icons'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import {
  CohortRsvpKickoffEventPartsFragment,
  CohortRsvpTimeDataPartsFragment,
  UserCohort,
  useBatchRsvpMutation
} from 'gql'

import {
  HOUR_MIN_FORMAT,
  formatInTimezone,
  getCurrentTimezone,
  isBeforeDate
} from 'utils/date'
import notifyError from 'utils/errorNotifier'

type TimeSlot = CohortRsvpTimeDataPartsFragment

interface RsvpAllEventsModalProps {
  id: string
  cohortEventTimeData: {
    cohortEventTimes: CohortRsvpTimeDataPartsFragment[]
    timezone?: string | null
    kickoffEvents: CohortRsvpKickoffEventPartsFragment[]
  }
  year: string
  name: string
  programName: string
  timeGroupingSelected: UserCohort['timeGroupingSelected']
  cohortId: number | string
  isOpen: boolean
  handleClose: () => void
  additionalAskAnExpertEventsToRsvp?: CohortEvent[] // these would still need to be added to the AddEvent calendar to by Insights/Ops
}

const RsvpAllEventsModal = ({
  id,
  cohortEventTimeData,
  programName,
  timeGroupingSelected,
  cohortId,
  year,
  name,
  isOpen,
  handleClose,
  additionalAskAnExpertEventsToRsvp = []
}: RsvpAllEventsModalProps) => {
  const [batchRsvp] = useBatchRsvpMutation()
  const { cohortEventTimes, kickoffEvents, timezone } = cohortEventTimeData
  const userTimezone = getCurrentTimezone(timezone)
  const kickoffEventIds = kickoffEvents.map((kickoffEvent) => kickoffEvent.id)
  const kickOffTimeSlots = cohortEventTimes
    .filter((ts) =>
      kickoffEventIds.some((kickoffEventId) => ts.ids.includes(kickoffEventId))
    )
    .sort(
      (a, b) =>
        new Date(a.startsAtUtc || 0).getTime() - new Date(b.startsAtUtc || 0).getTime()
    )

  const timeSlots = cohortEventTimes
    .filter(
      (ts) => !kickoffEventIds.some((kickoffEventId) => ts.ids.includes(kickoffEventId))
    ) // filter out the 1-hour kick-off events
    .sort(
      (a, b) =>
        new Date(a.startsAtUtc || 0).getTime() - new Date(b.startsAtUtc || 0).getTime()
    )

  const findSelectedTimeGroup = () => {
    return timeSlots.find((ts) => ts.timeGrouping === timeGroupingSelected) || null
  }

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(findSelectedTimeGroup())

  const getPreviouslySelectedEvents = () => {
    return timeSlots
      .filter((ts) => ts.timeGrouping === timeGroupingSelected)
      .flatMap((ts) => ts.ids)
  }

  const getMatchingKickoffTimeSlot = (timeSlot: TimeSlot) => {
    const timeSlotTimeGrouping = timeSlot.timeGrouping?.substring(
      0,
      timeSlot.timeGrouping?.length - 4
    )

    return kickOffTimeSlots.find(
      (ts) =>
        // Matching based on the time grouping but exclude the end time as kickoff events and cohort events
        // don't/might not have the same duration
        ts.timeGrouping?.substring(0, ts.timeGrouping?.length - 4) ===
        timeSlotTimeGrouping
    )
  }

  const submit = async () => {
    if (!selectedTimeSlot) {
      throw new Error('No selected time slot')
    }
    setLoading(true)

    const previouslySelectedTimeSlot = timeSlots.find(
      (ts) => ts.timeGrouping === timeGroupingSelected
    )
    const selectedKickoffTimeSlot = selectedTimeSlot
      ? getMatchingKickoffTimeSlot(selectedTimeSlot)
      : undefined
    const previousKickoffTimeSlot = previouslySelectedTimeSlot
      ? getMatchingKickoffTimeSlot(previouslySelectedTimeSlot)
      : undefined

    try {
      await batchRsvp({
        variables: {
          input: {
            eventIds: selectedTimeSlot.ids
              .concat(selectedKickoffTimeSlot ? selectedKickoffTimeSlot.ids : [])
              .concat(
                additionalAskAnExpertEventsToRsvp.map((combinedEvent) => combinedEvent.id)
              ),
            timeGrouping: selectedTimeSlot.timeGrouping,
            previouslySelectedEventIds: getPreviouslySelectedEvents().concat(
              previousKickoffTimeSlot ? previousKickoffTimeSlot.ids : []
            ),
            timezone: userTimezone,
            cohortId: `${cohortId}`
          }
        }
      })

      setLoading(false)
      setSuccess(true)

      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } catch (e) {
      setLoading(false)
      const [error] = e
      if (error) {
        UIkit.notification({
          message: `<span uk-icon="icon: warning"></span> ${error.message}`,
          status: 'warning',
          pos: 'top-right'
        })
      }
      return notifyError(e)
    }
  }

  const shouldShowDaylightSavingWarningForSpring2022 = () => {
    const timezone = userTimezone || ''
    return (
      timezone.includes('Europe') &&
      year === '2022' &&
      name === 'spring' &&
      isBeforeDate(new Date('2022-03-27'))
    )
  }

  const spring2022DaylightSavingWarningCopy = () => {
    return (
      'Please note that daylight savings time falls during your live program and events ' +
      'taking place before March 27, 2022 will begin 1 hour before than stated below.'
    )
  }

  return (
    <Modal
      modalId={id}
      isOpen={isOpen}
      handleClose={handleClose}
      containerClass="items-start pt-12"
      className="w-[825px]"
      data-testid="rsvp-all-events-in-program-modal"
    >
      <div className={`self-center p-8 pt-0 ${loading && 'text-rb-gray-300'}`}>
        {success ? (
          <div className="pb-[50px] pt-10 text-center">
            <div className="flex justify-center">
              <SVGIcon name="check-slim" fill="#00C361" height="33px" width="45px" />
            </div>

            <div className="mt-[26px] text-[21px] font-medium">
              Success! You&#39;ll receive an email to add events to your calendar shortly
            </div>
          </div>
        ) : (
          <>
            <div className="mb-[5px] text-2xl font-bold">
              {`RSVP to ${programName || 'your live program'} events`}
            </div>

            <div className="mb-[30px] text-base">
              {getTimeSlotText(timeSlots)}
              <RfParagraphMediumBold className="mt-2">
                Please note the time of the live session(s) and be aware of possible time
                shifts due to different daylight savings time changes around the world.
              </RfParagraphMediumBold>
            </div>

            {shouldShowDaylightSavingWarningForSpring2022() && (
              <div className="mb-6 flex justify-between rounded border border-rb-orange-100 bg-rb-orange-100/[0.07] p-4 text-rb-gray-400">
                <InfoIcon className="mr-3 w-8" fill="#ffa843" />
                <div>{spring2022DaylightSavingWarningCopy()}</div>
              </div>
            )}

            <div>
              {timeSlots.map((ts, i) => (
                <div
                  className="select-list-block"
                  key={`${i}${ts.startsAtUtc}`}
                  data-testid={`select-list-block-${ts.startsAtUtc}`}
                >
                  <SelectListBlock
                    isSelected={
                      !!selectedTimeSlot &&
                      ts.startsAtUtc === selectedTimeSlot.startsAtUtc &&
                      ts.endsAtUtc === selectedTimeSlot.endsAtUtc
                    }
                    onClick={() => setSelectedTimeSlot(timeSlots[i])}
                    loading={loading}
                  >
                    {getSelectText(ts, userTimezone)}
                  </SelectListBlock>
                </div>
              ))}
            </div>

            {additionalAskAnExpertEventsToRsvp.length ? (
              <div className="mt-8">
                <RfParagraphMedium className="mb-1">
                  You will also be RSVPing to the following Ask an Expert session
                  {additionalAskAnExpertEventsToRsvp.length > 1 ? 's' : ''}:
                </RfParagraphMedium>
                {additionalAskAnExpertEventsToRsvp.map((event) => {
                  return (
                    <RfParagraphSmall color={ColorOptions.gray} key={event.id}>
                      {`${formattedEventTimeRange(
                        event,
                        userTimezone
                      )} - ${formattedEventDate(event, userTimezone)}`}
                    </RfParagraphSmall>
                  )
                })}
              </div>
            ) : null}

            <div className="flex justify-end pb-4 pt-14">
              <Button onClick={handleClose} size="small" variant="text-only">
                Cancel
              </Button>

              <Button
                disabled={!selectedTimeSlot}
                onClick={submit}
                size="small"
                isLoadingSpinner={loading}
              >
                RSVP
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

function getTimeSlotText(timeSlots: TimeSlot[]) {
  return timeSlots.length > 1
    ? `We offer ${timeSlots.length} timeslots to accommodate different schedules. Choose the timeslot that works best for your schedule. You can modify your reservations later.`
    : 'We offer 1 timeslot for this program.'
}

function getSelectText(timeSlot: TimeSlot | null, timezone?: string | null) {
  const { startsAtUtc, endsAtUtc } = timeSlot || {}

  if (!startsAtUtc || !endsAtUtc) return null

  return `${formatInTimezone(
    startsAtUtc,
    timezone,
    "cccc's' @ h:mma"
  )} - ${formatInTimezone(endsAtUtc, timezone, HOUR_MIN_FORMAT)}`
}

export default RsvpAllEventsModal
