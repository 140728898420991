import React, { useRef, useState } from 'react'
import Cropper from 'react-cropper'

import FallbackTeamLogo from 'domains/Team/TeamCard/FallbackTeamLogo'

import Button from 'components/Button'
import { displayToast } from 'components/Toast/index'

import { MAX_WIDTH_TAILWIND_TL } from 'constants/breakpoints'

import { useExternalStylesheet } from 'hooks/useExternalStylesheet'
import useMediaQuery from 'hooks/useMediaQuery'

interface LogoPickerProps {
  logoUrl: string | null | undefined
  handleCloseModal: () => void
  setLogoInForm: (url: string | null) => void
}

const LogoPicker = ({
  handleCloseModal,
  setLogoInForm,
  logoUrl: currentLogoUrl
}: LogoPickerProps) => {
  const cropperRef = useRef<HTMLImageElement | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [logoUrl, setLogoUrl] = useState<string | null | undefined>(currentLogoUrl)
  const [canUpload, setCanUpload] = useState(false)
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  useExternalStylesheet(
    'https://cdnjs.cloudflare.com/ajax/libs/cropperjs/1.6.1/cropper.min.css'
  )

  const isSmallerTabletCustomSize = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_TL})`)

  const genericErrorMsg =
    'There was an error while uploading this image. Please try again'

  const chooseFile = (e: React.MouseEvent<HTMLButtonElement>) => {
    fileInputRef.current?.click()
    e.currentTarget.blur()
  }

  const removePhoto = (e: React.MouseEvent<HTMLButtonElement>) => {
    setLogoUrl(null)
    setCanUpload(true)
    e.currentTarget.blur()
  }

  const setLogo = async (logoUrl: string | null) => {
    setLogoInForm(logoUrl)
    handleCloseModal()
  }

  const onChangeAvatar = (
    e: React.ChangeEvent<HTMLInputElement> & { dataTransfer?: DataTransfer }
  ) => {
    e.preventDefault()

    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.currentTarget.files
    }

    const reader = new window.FileReader()

    reader.onload = () => {
      setCanUpload(true)
      setLogoUrl((reader.result as string) || '')
      const imageElement: any = cropperRef?.current
      imageElement?.cropper.enable()
    }

    if (files?.[0]) reader.readAsDataURL(files[0])
  }

  const submitLogo = () => {
    if (!logoUrl) {
      setLogo(null)
      return
    }

    const imageElement: any = cropperRef?.current
    const croppedCanvas = imageElement?.cropper.getCroppedCanvas()
    const dataUrl = croppedCanvas && croppedCanvas.toDataURL()
    // Verify that there is imageData (that the user didn't choose a crop
    // size so small there isn't anything left after cropping)
    const imageData = dataUrl && dataUrl.split(',')

    if (imageData && imageData[1]) {
      setLogo(dataUrl)
    } else {
      setErrorMsg(genericErrorMsg)
      displayToast(genericErrorMsg)
    }
  }

  return (
    <div
      data-test="upload-logo-modal"
      id="imageInput"
      className={`flex-1 ${isSmallerTabletCustomSize ? 'pb-3' : 'pb-10'} ${
        !canUpload || !logoUrl ? 'cropper-no-border' : ''
      }`}
    >
      {logoUrl ? (
        <Cropper
          ref={cropperRef}
          style={{ width: '100%', height: 400 }}
          src={logoUrl}
          background={false}
          center={false}
          checkCrossOrigin={false}
          aspectRatio={1 / 1}
          guides={false}
          highlight={false}
          rotatable={false}
        />
      ) : (
        <div
          style={{ width: '100%', height: 400 }}
          className="flex items-center justify-center"
        >
          <FallbackTeamLogo data-test="fallback-logo" />
        </div>
      )}

      <div className="sm:text-base--mobile py-3 text-m-medium sm:text-base">
        <div className="text-rb-gray-400">
          Choose a file to upload for your team logo.
        </div>
        {errorMsg && (
          <div className="text-m-small text-rb-destructive-100 sm:text-m-medium">
            {errorMsg}
          </div>
        )}
        <input
          hidden
          ref={fileInputRef}
          type="file"
          accept="image/png,image/jpeg,image/gif,image/bmp"
          onChange={onChangeAvatar}
          data-test="logo-picker-file-input"
        />
      </div>

      <div className="ml-0 flex flex-wrap justify-between">
        <div
          className={`flex items-center justify-center ${
            isSmallerTabletCustomSize ? 'w-full' : ''
          }`}
        >
          <Button
            onClick={chooseFile}
            size="x-small"
            color="teal"
            shape="rounded-full"
            className="min-w-[140px]"
            dataTest="choose-file-button"
          >
            Choose File
          </Button>

          {logoUrl && (
            <Button
              onClick={removePhoto}
              dataTest="remove-logo-button"
              variant="outline"
              size="x-small"
              shape="rounded-full"
              className="ml-5 min-w-[140px]"
            >
              Remove Logo
            </Button>
          )}
        </div>
        <div
          className={`flex items-center justify-center ${
            isSmallerTabletCustomSize ? 'mt-8' : ''
          }`}
        >
          <Button size="small" variant="text-only" onClick={handleCloseModal}>
            Cancel
          </Button>

          <Button
            disabled={!canUpload}
            onClick={submitLogo}
            size="small"
            dataTest="upload-button"
          >
            Upload
          </Button>
        </div>
      </div>
    </div>
  )
}

export default LogoPicker
