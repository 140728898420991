import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { ReactComponent as HideMinusIcon } from 'images/icon--minus-sign.svg'
import { ReactComponent as RevealPlusIcon } from 'images/icon--plus-sign.svg'

import { BaseFilterSet } from './Filter.types'

export interface RadioFilterSet extends BaseFilterSet {
  explainer?: string
  options: string[][]
  title: string
  type: 'radio'
}

export interface IRadioFilter {
  filterSet: RadioFilterSet
  resetFilters: (filterKey: string) => void
  startOpen?: boolean
  replaceFilters: (filterKey: string, value: string) => void
  value: string | number | null | string[]
}

interface IRadios {
  filterSet: RadioFilterSet
  replaceFilters: (filterKey: string, value: string) => void
  value: string | number | null | string[]
}

const Radios = ({ filterSet, replaceFilters, value }: IRadios) => {
  const getOptionFontClasses = (isActive = false) =>
    isActive
      ? 'text-black hover:text-black font-medium'
      : 'text-rb-gray-500 hover:text-rb-gray-500'

  const update = (value: string) => {
    replaceFilters(filterSet.key, value)
  }

  return (
    <>
      {filterSet.options.map((filterOption) => {
        const filterVariable = filterOption[0]
        const displayName = filterOption[1]
        const active = filterVariable === value

        return (
          <label
            key={`option_${filterVariable}`}
            className={twMerge(
              'relative flex cursor-pointer items-center text-sm font-normal leading-7 focus:outline-none',
              getOptionFontClasses(active)
            )}
          >
            <span className="m-0 mr-2 flex h-5 w-5 min-w-[20px] items-center justify-center rounded-full border border-rb-teal-200">
              <input
                type="radio"
                checked={active}
                onChange={() => update(filterVariable)}
                className="h-3.5 w-3.5 appearance-none rounded-full checked:bg-rb-teal-200"
              />
            </span>
            {displayName}
          </label>
        )
      })}
    </>
  )
}

const RadioFilter = ({
  filterSet,
  value,
  startOpen,
  replaceFilters,
  resetFilters
}: IRadioFilter) => {
  const [isOpen, setOpen] = useState(!!(value || startOpen))

  const getHiddenClass = (shouldHideWhenOpen = false) => {
    const shouldHide = shouldHideWhenOpen ? isOpen : !isOpen
    return shouldHide ? 'hidden' : ''
  }

  const onToggle = () => {
    setOpen(!isOpen)
  }

  const reset = (event: React.MouseEvent) => {
    event.stopPropagation()
    resetFilters(filterSet.key)
  }

  const borderColorClass = 'border-rb-gray-100 hover:border-rb-gray-299'

  return (
    <div
      className={twMerge('mb-4 rounded-sm border border-solid', borderColorClass)}
      id={`filter-${filterSet.key}`}
    >
      <div className="flex cursor-pointer px-4 py-3" onClick={onToggle}>
        <h3
          className={twMerge(
            'mb-0 grow cursor-pointer text-sm font-medium text-rb-gray-500'
          )}
        >
          {filterSet.title}
        </h3>
        {!!value && (
          <button
            type="button"
            className="mr-3 cursor-pointer text-sm leading-5 text-rb-gray-300 hover:underline hover:decoration-rb-gray-300 focus:outline-none"
            onClick={reset}
          >
            Reset
          </button>
        )}
        <div className={twMerge('cursor-pointer leading-5', getHiddenClass(true))}>
          <RevealPlusIcon height="16" width="16" stroke="black" />
        </div>
        <div className={twMerge('cursor-pointer leading-5', getHiddenClass())}>
          <HideMinusIcon height="16" width="16" stroke="black" />
        </div>
      </div>
      <div className={twMerge('px-4 pt-0 pb-3', getHiddenClass())}>
        {filterSet.explainer && <p className="mb-1 text-sm">{filterSet.explainer}</p>}
        <Radios filterSet={filterSet} value={value} replaceFilters={replaceFilters} />
      </div>
    </div>
  )
}

export default RadioFilter
