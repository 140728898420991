import React from 'react'

import CollectiveBadgeSmall from 'domains/Member/CollectiveBadgeSmall'

import Button from 'components/Button'
import ArrowUpRight from 'components/icons/ArrowUpRight'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { cn } from 'utils/tailwind'
import { trackCtaClicked } from 'utils/tracking/analytics'

import { ReactComponent as CheckmarkIcon } from 'images/p-checkmark.svg'

function GetStartedButton({
  ctaLocation,
  typeformUrl
}: {
  ctaLocation: string
  typeformUrl: string
}) {
  const { isLoggedIn } = useCurrentUser()

  return (
    <Button
      className="h-10 w-full"
      variant="outline"
      href={typeformUrl}
      target="_blank"
      onClick={() => {
        trackCtaClicked({
          cta_location: ctaLocation,
          cta_type: 'button',
          destination: typeformUrl,
          text: 'apply',
          logged_in: isLoggedIn
        })
      }}
    >
      Apply
      <ArrowUpRight className="ml-2" width={16} height={16} />
    </Button>
  )
}

function Checkmark() {
  return (
    <div className="h-[36px] w-[36px] text-rb-green-400">
      <CheckmarkIcon />
    </div>
  )
}

function ContributorLevelsTableDesktop({ typeformUrl }: { typeformUrl: string }) {
  const headerCells: Array<{ content: string | React.ReactNode }> = [
    {
      content: <div className="mb-7 pb-10">Compare Levels</div>
    },
    {
      content: (
        <>
          <div className="mb-7">Contributor</div>
          <div>
            <GetStartedButton
              ctaLocation="creator_landing_page__contributor_column"
              typeformUrl={typeformUrl}
            />
          </div>
        </>
      )
    },
    {
      content: (
        <>
          <div className="mb-7">Author</div>
          <div>
            <GetStartedButton
              ctaLocation="creator_landing_page__author_column"
              typeformUrl={typeformUrl}
            />
          </div>
        </>
      )
    },
    {
      content: (
        <>
          <div className="mb-6 flex items-center gap-4">
            <div className="h-[24px] w-[24px] flex justify-center items-center">
              <CollectiveBadgeSmall />
            </div>
            Collective
          </div>
          <div>
            <GetStartedButton
              ctaLocation="creator_landing_page__collective_column"
              typeformUrl={typeformUrl}
            />
          </div>
        </>
      )
    }
  ]

  const bodyRows = [
    [
      { content: 'Requirements' },
      {
        content: (
          <ul>
            <li>Senior IC or manager</li>
            <li>Publish 3 artifacts</li>
          </ul>
        )
      },
      {
        content: (
          <ul>
            <li>Director, VP, or equivalent</li>
            <li>Publish 5 artifacts</li>
          </ul>
        )
      },
      {
        content: (
          <ul>
            <li>VP or C-level leader</li>
            <li>Works with Reforge team to create artifacts, guides, or courses</li>
          </ul>
        )
      }
    ],
    [
      { content: 'New opportunities' },
      {
        content: (
          <ul>
            <li>Portfolio showcasing your expertise</li>
            <li>Add custom profile calls to action (CTAs) based on your goals</li>
          </ul>
        )
      },
      {
        content: (
          <>
            <p>Everything in &quot;Contributor&quot; plus...</p>
            <ul>
              <li>Badges displaying your expertise</li>
              <li>Opportunities for revenue share based on engagement</li>
            </ul>
          </>
        )
      },
      {
        content: (
          <>
            <p>Everything in &quot;Author&quot; plus...</p>
            <ul>
              <li>Premium content placement</li>
              <li>Collective badge</li>
              <li>Advising & fractional opportunities</li>
            </ul>
          </>
        )
      }
    ],
    [
      { content: 'Content promotion' },
      { content: 'Promotion based on performance' },
      { content: 'Promotion across several channels' },
      { content: 'Promotion across several channels' }
    ],
    [
      { content: 'Reforge team support' },
      { content: 'Light editing & formatting' },
      { content: 'Editing & redacting' },
      { content: 'Content creation & editing support' }
    ],
    [
      { content: 'Performance analytics' },
      { content: <Checkmark /> },
      { content: <Checkmark /> },
      { content: <Checkmark /> }
    ],
    [
      { content: 'Reforge Individual membership' },
      { content: '3 months free' },
      { content: '6 months free' },
      { content: '1 year free' }
    ],
    [
      { content: 'Expert community & resources' },
      { content: '' },
      { content: '' },
      { content: 'Full access to expert community and resources' }
    ]
  ]

  return (
    <table className="w-full table-auto border-separate border-spacing-0">
      <thead>
        <tr>
          {headerCells.map((cell, headerCellIdx, cells) => (
            <th
              key={headerCellIdx}
              className={cn(
                headerCellIdx !== 0 && 'py-6 pl-12 pr-6',
                headerCellIdx !== 0 &&
                  headerCellIdx !== 1 &&
                  headerCellIdx !== cells.length - 1 &&
                  'border-r border-t',
                headerCellIdx === cells.length - 1 && 'rounded-tr-lg border-r border-t',
                headerCellIdx === 0 && 'border-b',
                headerCellIdx === 1 && 'rounded-tl-lg border-t border-l border-r',
                'border-b text-left text-xl'
              )}
            >
              {cell.content}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {bodyRows.map((row, rowIdx, rows) => (
          <tr key={rowIdx} className="">
            {row.map((cell, cellIdx, cells) => (
              <td
                key={cellIdx}
                className={cn(
                  cellIdx !== 0 && 'w-[356px] border-r px-12 py-5 text-sm',
                  cellIdx === 0 && 'w-44 border-b py-5 pr-8',
                  cellIdx === 1 && 'border-l',
                  rowIdx === rows.length - 1 && 'border-b',
                  rowIdx === rows.length - 1 && cellIdx === 0 && 'border-b-0',
                  rowIdx === rows.length - 1 && cellIdx === 1 && 'rounded-bl-lg',
                  rowIdx === rows.length - 1 &&
                    cellIdx === cells.length - 1 &&
                    'rounded-br-lg',
                  rowIdx !== rows.length - 1 && 'border-b'
                )}
              >
                {cell.content}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

function MobileTableColumn({
  header,
  headerChips = [],
  benefits = [],
  ctaLocation,
  typeformUrl
}: {
  header: string | React.ReactNode
  headerChips?: string[]
  benefits: string[]
  ctaLocation?: string
  typeformUrl: string
}) {
  return (
    <div className=" space-y-8 rounded-md border border-rb-gray-100 py-12 px-9">
      <div className="space-y-6">
        {typeof header === 'string' ? (
          <h3 className="text-3xl">{header}</h3>
        ) : (
          <div className="text-3xl">{header}</div>
        )}
        <ul className="flex list-none gap-2 p-0">
          {headerChips.map((chip, i) => (
            <li key={i} className="rounded bg-rb-gray-100 px-1 text-xs">
              {chip}
            </li>
          ))}
        </ul>
      </div>
      <div>
        <ul className="flex list-none flex-col gap-3 p-0 text-sm">
          {benefits.map((text, i) => (
            <li key={i} className="flex gap-3">
              <div className="h-[20px] w-[20px] shrink-0 grow-0">
                <CheckmarkIcon />
              </div>
              {text}
            </li>
          ))}
        </ul>
      </div>
      <div>
        <GetStartedButton
          ctaLocation={ctaLocation || 'creator_landing_page'}
          typeformUrl={typeformUrl}
        />
      </div>
    </div>
  )
}

function ContributorLevelsTableMobile({ typeformUrl }: { typeformUrl: string }) {
  return (
    <div className="space-y-8">
      <MobileTableColumn
        header="Contributor"
        headerChips={['3 artifacts', 'Senior IC or Manager']}
        benefits={[
          'Portfolio showcasing your expertise',
          'Add custom profile calls to action (CTAs) based on your goals',
          'Promotion based on performance',
          'Light editing & formatting',
          'Performance analytics',
          '3 months Reforge individual subscription for free'
        ]}
        ctaLocation="creator_landing_page__contributor_column"
        typeformUrl={typeformUrl}
      />
      <MobileTableColumn
        header="Author"
        headerChips={['5 artifacts', 'Director, VP, or equivalent']}
        benefits={[
          'Portfolio showcasing your expertise',
          'Add custom profile calls to action (CTAs) based on your goals',
          'Badges displaying your expertise',
          'Opportunities for revenue share based on engagement',
          'Promotion based on performance',
          'Editing & redacting',
          'Performance analytics',
          '6 months Reforge individual subscription for free'
        ]}
        ctaLocation="creator_landing_page__author_column"
        typeformUrl={typeformUrl}
      />
      <MobileTableColumn
        header={
          <div className="mb-7 flex items-center gap-4">
            <div className="h-[24px] w-[24px]">
              <CollectiveBadgeSmall />
            </div>
            <h3 className="mb-0 text-3xl">Collective</h3>
          </div>
        }
        headerChips={[
          'Artifacts, Guides, or Courses',
          'VP or C-level leader',
          'Works with Reforge team'
        ]}
        benefits={[
          'Portfolio showcasing your expertise',
          'Add custom profile calls to action (CTAs) based on your goals',
          'Badges displaying your expertise',
          'Opportunities for revenue share based on engagement',
          'Premium content placement',
          'Collective badge',
          'Advising & fractional opportunities',
          'Content creation & editing support',
          'Performance analytics',
          '1 year Reforge individual subscription for free',
          'Full access to expert community and resources'
        ]}
        ctaLocation="creator_landing_page__collective_column"
        typeformUrl={typeformUrl}
      />
    </div>
  )
}

export default function ContributorLevelsSection({
  typeformUrl
}: {
  typeformUrl: string
}) {
  return (
    <div>
      <div className="hidden lg:block">
        <ContributorLevelsTableDesktop typeformUrl={typeformUrl} />
      </div>
      <div className="lg:hidden">
        <ContributorLevelsTableMobile typeformUrl={typeformUrl} />
      </div>
    </div>
  )
}
