import { useMemo } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import Comment from 'domains/Post/Comment'
import Reactions from 'domains/Post/Reactions'
import UnreadBadge from 'domains/Post/UnreadBadge'
import UserHeading from 'domains/User/UserHeading'

import {
  PostReplyCommentFragment,
  PostReplyFragment,
  PostShowFieldsFragment,
  PostUnreadElementFragment
} from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

interface PostReplyProps {
  reply: PostReplyFragment
  unreadElements: PostUnreadElementFragment[]
  post: PostShowFieldsFragment
}

const PostReply = ({ reply, unreadElements, post }: PostReplyProps) => {
  const { id: currentUserId } = useAssertCurrentUser()
  const unreadComments = useMemo(
    () => unreadElements.filter((element) => element.type === 'Comment'),
    [unreadElements]
  )
  const unreadReplies = useMemo(
    () => unreadElements.filter((element) => element.type === 'Reply'),
    [unreadElements]
  )

  const replyIsUnread = (reply: PostReplyFragment) => {
    return elementIsUnread(reply, unreadReplies)
  }

  const commentIsUnread = (comment: PostReplyCommentFragment) => {
    return elementIsUnread(comment, unreadComments)
  }

  const elementIsUnread = (
    element: PostReplyFragment | PostReplyCommentFragment,
    elementList: PostUnreadElementFragment[]
  ) => {
    const foundElement = elementList.find((item) => item.id === element.id)
    return foundElement !== undefined
  }

  const copyPermalink = () => {
    const tooltip = document.querySelector('.uk-tooltip')
    if (tooltip) {
      tooltip.innerHTML =
        'Copied to clipboard <span uk-icon="icon:check; ratio:0.75"></span>'
    }
  }

  const getPermalinkUrl = () => {
    const id = reply.id
    if (window.location.hash) {
      return window.location.href.replace(window.location.hash, `#reply${id}`)
    } else {
      return `${window.location.href}#reply${id}`
    }
  }

  const comments = reply.comments

  return (
    <div
      id={`reply${reply.id}`}
      className="rf-comment--mobile rf-rb-card mb-5 mt-0 p-7"
      data-test="post-reply-list"
    >
      <article className="uk-comment mt-5">
        <div className="uk-margin-bottom flex">
          <div className="flex-auto justify-start">
            <UserHeading user={reply.user.profile} imgWidth="48" imgHeight="48" />
          </div>
          <div className="uk-text-right uk-text-muted uk-text-nowrap flex-auto justify-end">
            <CopyToClipboard
              uk-tooltip="title: Click to copy link; cls: uk-active rf-tooltip-down-arrow"
              onCopy={copyPermalink}
              text={getPermalinkUrl()}
            >
              <span className="cursor-pointer text-rb-gray-300">
                {reply.createdAtInWords} ago
              </span>
            </CopyToClipboard>
            &nbsp;{replyIsUnread(reply) && <UnreadBadge />}
          </div>
        </div>

        <div className="uk-comment-body font-sans text-rb-gray-300">
          <div className="uk-child-width-1-1" uk-grid="margin: uk-margin-small">
            <p dangerouslySetInnerHTML={{ __html: reply.body || '' }} />
          </div>
        </div>
      </article>

      <Reactions
        reactableType="Reply"
        reactableId={reply.id}
        reactions={reply.reactions || []}
        currentUserId={currentUserId}
        reactablePost={post}
      />

      <hr className="uk-margin-small-top" />

      <div className="uk-margin px-2.5 pr-0 xs:px-4 md:px-8">
        {comments.map((comment) => (
          <span key={`comment${comment.id}`}>
            <Comment comment={comment} post={post} unread={commentIsUnread(comment)} />
            <hr />
          </span>
        ))}
      </div>
    </div>
  )
}

export default PostReply
