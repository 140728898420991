import CohortPreview from 'domains/Enrollment/CohortPreview/CohortPreview'

import Loading from 'components/Loading'
import Modal, { CloseButton } from 'components/Modal'

import { Season, useCohortAboutProgramQueryQuery } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

interface AboutCohortModalProps {
  isOpen: boolean
  onClose: () => void
  cmsProgramId: string
  season: Season
}

export const AboutCohortModal = ({
  isOpen,
  onClose,
  cmsProgramId,
  season
}: AboutCohortModalProps) => {
  const { timezone: userTimezone } = useAssertCurrentUser()
  const timezone = userTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone // default to browser timezone

  const { data: program, loading } = useCohortAboutProgramQueryQuery({
    variables: { cmsProgramId }
  })

  if (loading) return <Loading />
  if (!program?.cmsProgram) return null

  return (
    <Modal
      isOpen={isOpen}
      handleClose={onClose}
      fullWidth={true}
      className="relative max-w-[800px] xs:mx-[40px]"
      header={false}
    >
      <div className="absolute top-0 right-0 z-[1] flex pr-2">
        <CloseButton handleClose={onClose} />
      </div>
      <div className="relative overflow-auto">
        <CohortPreview
          program={program?.cmsProgram}
          timezone={timezone}
          season={season}
        />
      </div>
    </Modal>
  )
}
