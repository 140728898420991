import { type SelectedPlanProps } from 'components/CheckoutModal/SelectedPlan'
import { useCheckoutModal } from 'components/CheckoutModal/useCheckoutModal'
import { useConfirmPlan } from 'components/ConfirmPlanFlow/useConfirmPlan'
import { useGlobalModal } from 'components/GlobalModal'

import { trackModalDisplayed } from 'hooks/useModalTracking'

import { trackCtaClicked } from 'utils/tracking/analytics'

import UpdatePlanNowModal from './UpdatePlanNowModal'

export function useUpdatePlanNowModal() {
  const { openGlobalModal, closeGlobalModal } = useGlobalModal()
  const { openConfirmPlanModal } = useConfirmPlan()
  const { openCheckoutModal } = useCheckoutModal()

  const handleUpdatePlanNowModalClose = () => {
    closeGlobalModal()
  }

  const openUpdatePlanNowModal = ({
    selectedPlan,
    numCohortPasses,
    isIndividual
  }: {
    selectedPlan: SelectedPlanProps
    numCohortPasses?: number
    isIndividual?: boolean
  }) => {
    trackModalDisplayed({
      modal_group: 'plan_choice_modal_flow',
      modal_name: 'start_plan_update_confirmation'
    })

    const handleUpdateOnRenewalClick = () => {
      trackCtaClicked({
        cta_location: 'plan_choice_modal_flow',
        cta_type: 'hyperlink text',
        text: 'update upon renewal'
      })

      closeGlobalModal()
      openConfirmPlanModal({ selectedPlan, numCohortPasses })
    }

    const handleUpdateNowClick = () => {
      trackCtaClicked({
        cta_location: 'plan_choice_modal_flow',
        cta_type: 'button',
        text: 'update now'
      })

      closeGlobalModal()
      openCheckoutModal({ planName: selectedPlan.name, isIndividual, numCohortPasses })
    }

    openGlobalModal(
      <UpdatePlanNowModal
        planName={selectedPlan.name}
        onUpdateOnRenewalClick={handleUpdateOnRenewalClick}
        onUpdateNowClick={handleUpdateNowClick}
      />,
      {
        className: 'mx-2 max-w-[646px] rounded-lg',
        handleClose: handleUpdatePlanNowModalClose
      }
    )
  }

  return {
    openUpdatePlanNowModal
  }
}
