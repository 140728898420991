import { closing, opening } from './sharedMarkdown'

const programName = 'Marketing Leadership'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://reforge.com/marketing-leadership), because I believe it will help me develop and evolve the team, processes, and relationships to deliver on our marketing strategy and outcomes as **[your company]** scales. This course was built with [Kiran Akkineni](https://www.reforge.com/experts/kiran-akkineni) and [Stephanie Kwok](https://www.reforge.com/experts/stephanie-kwok), and covers:
* **Manage Marketing Work.** Learn best practices for managing and prioritizing your team's work. Examine an approach to defining marketing OKRs to up-level your management.
* **Manage Marketing Finances.** Build your credibility with finance. Learn how to effectively manage your marketing budget and build business cases.
* **Strengthen Your Org.** Design your marketing org structure and build out your marketing team. You'll define a marketing competency model to identify needs and build a hiring plan.
* **Nurture Your Talent.** Develop the internal and external resources on your team. Explore frameworks to build a feedback culture and career pathways.

&NewLine;

${closing}
`

export default { markdown, title: programName }
