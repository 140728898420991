import { Helmet } from 'react-helmet'
import { twMerge } from 'tailwind-merge'

import { LegacyCourse } from 'pages/CoursesPage/OldLoggedOutCourseDetails'

import FaqSection from 'domains/Sanity/FAQSection'
import Image from 'domains/Sanity/Image'

import { ArtifactAuthor } from 'gql'

import ButtonMarketingCTA from '../ButtonMarketingCTA'
import ExpertCard from '../ExpertCard'
import MarketingHero from '../MarketingHero'

export interface LegacyCoursePageProps {
  course: LegacyCourse
}

const LegacyCoursePage = ({ course }: LegacyCoursePageProps) => {
  const host = course.isLive ? course.hosts?.[0] : null
  const courseCreatorLength = (course.creators || []).length

  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'CourseInstance',
    'name': course.title,
    'description': course.subtitle,
    'provider': {
      '@type': 'Organization',
      'name': 'Reforge',
      'sameAs': 'https://www.reforge.com/'
    }
  }

  const ctaLink = course.ctaButtonLink?.includes('http')
    ? course.ctaButtonLink
    : course.ctaButtonLink?.[0] === '/'
      ? `${window.location.origin}${course.ctaButtonLink}`
      : `${window.location.origin}/courses`

  return (
    <>
      <Helmet>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
        ></script>
      </Helmet>

      <MarketingHero
        headerText={course.title}
        description={
          <>
            {course.subtitle}
            {course.isLive && (
              <div className="mt-[3vw] mb-[1vw] !leading-6 tracking-normal fluid-text-sm">
                <em>
                  <strong>{course.availabilitySubtitle}</strong>
                </em>
              </div>
            )}
          </>
        }
        heroImage={{
          url: course.icon?.imageUrl || '',
          alt: course.icon?.imageAlt
        }}
        bgImage={{
          url: course.backgroundImage?.imageUrl || '',
          alt: course.backgroundImage?.imageAlt
        }}
        ctas={[
          {
            href: ctaLink,
            text: course.ctaButtonText || ''
          }
        ]}
        noCtaMargin
        heroImageClassName="max-h-[130px] md:max-h-full shrink mb-[4vw] mt-[8vw]"
      />

      <section className="whitespace-pre-line px-[16px] py-[6.6vmax] !leading-7 fluid-text-lg md:max-w-[70%] md:px-[4vw]">
        {course.description}
      </section>

      {course.isLive && (
        <section className="px-[16px] py-[6.6vmax] md:px-[4vw]">
          <div className="mx-[4vw] rounded-[10px] bg-rb-blue-50 px-[4vw] pt-[2vw] drop-shadow-[rgba(0,0,0,.22)_0px_0px_5px] md:px-[2vw]">
            <p className="mb-[16px] text-center font-sans font-medium tracking-tight fluid-text-xl">
              {course.hostSectionTitle}
            </p>

            <p className="mb-[40px] text-center !leading-6 fluid-text-base">
              {course.hostSectionSubtitle}
            </p>

            <div className="flex flex-col md:flex-row-reverse">
              <div className="w-full md:w-[60%]">
                <h3 className="mb-[32px] font-normal tracking-tight fluid-text-3xl">
                  Meet your host
                </h3>

                <h4 className="mb-[32px] !font-serif font-normal tracking-tight fluid-text-2xl">
                  {host?.hostName}
                </h4>

                <p className="mb-[32px] !leading-7 fluid-text-base">{host?.hostBio}</p>

                <ButtonMarketingCTA href={ctaLink} className="mb-[32px]">
                  {course.ctaButtonText}
                </ButtonMarketingCTA>
              </div>
              <div className="flex w-full items-end md:mr-[4vw] md:w-[40%]">
                <Image
                  src={host?.hostPicture?.imageUrl || ''}
                  alt={host?.hostPicture?.imageAlt || host?.hostName || ''}
                  width={500}
                  height={500 * (host?.hostPicture?.aspectRatio || 1)}
                  className="mx-auto aspect-1 h-auto w-full max-w-[420px]"
                />
              </div>
            </div>
          </div>
        </section>
      )}

      {courseCreatorLength > 0 && (
        <section className="mb-[40px] px-[16px] py-[6.6vmax] md:px-[4vw]">
          <h2 className="mb-[40px] font-normal tracking-tight fluid-text-6xl">
            Meet the Creator{courseCreatorLength > 1 && 's'}
          </h2>

          <div
            className={twMerge(
              'grid grid-cols-1 items-stretch gap-[30px] md:grid-cols-2',
              courseCreatorLength >= 3 && 'md:grid-cols-3'
            )}
          >
            {course.creators?.map((creator) => (
              <ExpertCard
                key={creator.id}
                expert={
                  {
                    ...creator.expert,
                    pastCompanies: creator.role
                  } as ArtifactAuthor
                }
                horizontal={courseCreatorLength < 3}
              />
            ))}
          </div>
        </section>
      )}

      <section className="relative px-[16px] py-[6.6vmax] md:px-[4vw]">
        <Image
          src={course.breakdownBackgroundImage?.imageUrl || ''}
          alt={course.breakdownBackgroundImage?.imageAlt || ''}
          fill
          className="-z-1 object-cover"
        />
        <div className="flex flex-col items-stretch justify-between md:mb-[6vw] md:flex-row">
          <div>
            <h2 className="mb-[40px] font-normal tracking-tight fluid-text-6xl">
              Course Breakdown
            </h2>
            <div className="flex flex-col md:flex-row">
              <div className="mb-[40px] md:mr-[8%]">
                <h3 className="mb-[30px] font-normal fluid-text-3xl">Who is this for?</h3>
                <p className="!leading-6 fluid-text-base">{course.targetAudience}</p>
              </div>
              <div className="mb-[40px] md:mr-[8%]">
                <h3 className="mb-[30px] font-normal fluid-text-3xl">Course Goal</h3>
                <p className="!leading-6 fluid-text-base">{course.goals}</p>
              </div>
            </div>
          </div>

          <div className="relative mx-auto mb-[40px] flex w-full max-w-[80%] items-center md:mb-0 md:max-w-[40%] lg:max-w-[35%] xl:max-w-[30%]">
            <Image
              src={course.breakdownImage?.imageUrl || ''}
              alt={course.breakdownImage?.imageAlt || ''}
              width={465}
              height={465 * (course.breakdownImage?.aspectRatio || 1)}
              className="h-auto w-full"
            />
          </div>
        </div>

        <h2 className="mb-[4vw] font-normal tracking-tight fluid-text-6xl">
          Topics Covered
        </h2>
        <div className="grid grid-cols-1 gap-[5vw] sm:grid-cols-2">
          {course.topicsCovered?.map((topicCovered) => (
            <div key={topicCovered.id}>
              <h3 className="mb-[32px] font-normal tracking-tight fluid-text-3xl">
                {topicCovered.heading}
              </h3>
              <p className="!leading-6 fluid-text-base">{topicCovered.description}</p>
            </div>
          ))}
        </div>
      </section>

      {course.isLive ? (
        <FaqSection faqs={course.courseFaq || []} />
      ) : (
        <section className="bg-rb-background-beige px-[16px] py-[6.6vmax] md:px-[4vw]">
          <h3 className="mb-[30px] font-normal tracking-tight fluid-text-3xl md:max-w-[80%]">
            Unlock the Reforge collection of courses to help you level up in your career
            and deliver meaningful impact today.
          </h3>
          <ButtonMarketingCTA href={ctaLink}>{course.ctaButtonText}</ButtonMarketingCTA>
        </section>
      )}
    </>
  )
}

export default LegacyCoursePage
