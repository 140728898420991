import clsx from 'clsx'

import SkeletonRfParagraph from 'components/skeletons/typography/SkeletonRfParagraph'

interface SkeletonEventCardProps {
  className?: string
}
export const SkeletonEventCard = ({ className }: SkeletonEventCardProps) => (
  <div
    className={clsx(
      'rf-rb-card-interactive  flex min-h-[375px] min-w-[250px] max-w-[560px] animate-pulse flex-col p-5 lg:max-h-[none]',
      className
    )}
  >
    <div className="flex w-full pb-4 ">
      <div className="mr-2 h-4 w-6 rounded-full bg-rb-gray-100" />
      <SkeletonRfParagraph className="mr-2 mb-0 h-4 w-[100px]" />
    </div>
    <div className="flex justify-start space-x-4 pb-2 pb-5">
      <div className="aspect-w-16 aspect-h-9 w-1/2 rounded-[20px] bg-rb-gray-100" />
      <div className="aspect-w-16 aspect-h-9 w-1/2 rounded-[20px] bg-rb-gray-100" />
    </div>
    <SkeletonRfParagraph className="mb-2 h-5 w-full" />
    <SkeletonRfParagraph className="mb-3 h-5 w-[50%]" />
    <SkeletonRfParagraph lines={2} className="mb-2 h-[14px] w-full" />
    <div className="flex flex-grow items-end">
      <div className="mt-auto flex w-full items-center justify-between">
        <div className="h-6 w-20 rounded-full bg-rb-gray-100" />
        <div className="h-8 w-20 rounded-full bg-rb-gray-100" />
      </div>
    </div>
  </div>
)
