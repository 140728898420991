import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { useForkBookmarkFolder } from 'domains/Collections/useForkBookmarkFolder'

import { Loading } from 'components'
import Button from 'components/Button'
import { Modal, ModalContent, ModalHeader, ModalTitle } from 'components/Modal'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import { CurrentUserPartsFragment, useBookmarksFromFolderQuery } from 'gql'

import notifyError from 'utils/errorNotifier'
import { onEnterKeyPress } from 'utils/keyboard'
import { trackCollectionAction, trackCtaClicked } from 'utils/tracking/analytics'

import { ReactComponent as ReforgeSymbol } from 'images/reforge-logo-symbol.svg'

import ItemList from './ItemList'

export interface PreviewModalProps {
  isOpen: boolean
  collectionId?: string
  currentUser: CurrentUserPartsFragment
  handleClose: () => void
  saveDisabled?: boolean
}

const PreviewModal = ({
  isOpen,
  currentUser,
  collectionId,
  handleClose,
  saveDisabled = false
}: PreviewModalProps) => {
  const { forkBookmarkFolder } = useForkBookmarkFolder()
  const history = useHistory()
  const [isForking, setIsForking] = useState(false)
  const { data, loading, error } = useBookmarksFromFolderQuery({
    variables: { folderId: collectionId || '' },
    skip: !collectionId
  })

  const bookmarks = data?.bookmarksFromFolder || []
  const bookmarkFolder = data?.bookmarkFolder || undefined

  const handleCopyClick = async () => {
    if (!collectionId) return

    setIsForking(true)

    try {
      const { errors, data } = await forkBookmarkFolder({
        variables: {
          input: {
            forkedFromId: collectionId
          }
        }
      })

      if (errors || !data) {
        notifyError(errors)
      } else {
        const copiedBookmarkFolder = data.forkBookmarkFolder?.bookmarkFolder
        trackCollectionAction({
          action: 'collection_created',
          location: window.location.pathname,
          collection_id: copiedBookmarkFolder?.id,
          collection_name: copiedBookmarkFolder?.name,
          parent_collection_id: bookmarkFolder?.id,
          parent_collection_name: bookmarkFolder?.name,
          is_reforge_collection: bookmarkFolder?.reforgeCollection,
          trigger_action: 'reforge_collections_page__collection_created'
        })
        trackCollectionAction({
          action: 'collection_shared',
          location: window.location.pathname,
          collection_id: copiedBookmarkFolder?.id,
          collection_name: copiedBookmarkFolder?.name,
          parent_collection_id: bookmarkFolder?.id,
          parent_collection_name: bookmarkFolder?.name,
          is_reforge_collection: bookmarkFolder?.reforgeCollection
        })
        history.push(`/saved/${copiedBookmarkFolder?.id}?edit=1`)
      }
    } catch (error: unknown) {
      notifyError(error)
    }
    setIsForking(false)
  }

  const handleViewAll = () => {
    trackCtaClicked({
      cta_type: 'hyperlink text',
      cta_location: 'preview_collection_modal',
      text: 'view all Collections'
    })
    handleClose()
  }

  return (
    <Modal
      modalId="collection-preview"
      isOpen={isOpen}
      handleClose={handleClose}
      fullWidth={true}
      className="min-h-fit max-w-3xl rounded-2xl"
    >
      <ModalHeader>
        <ModalTitle>{bookmarkFolder?.name}</ModalTitle>
        {bookmarkFolder?.reforgeCollection && (
          <div className="mb-6 flex items-center gap-2">
            <ReforgeSymbol className="h-6 w-6 rounded-3xl bg-black fill-black p-1" />
            <RfParagraphMedium className="text-rb-gray-300">
              Curated by Reforge
            </RfParagraphMedium>
          </div>
        )}
      </ModalHeader>
      <ModalContent scrollContent={true}>
        {(loading || isForking) && <Loading />}
        {!loading && !error && !isForking && (
          <ItemList bookmarks={bookmarks} currentUser={currentUser} />
        )}
      </ModalContent>
      <div className="flex flex-row items-center justify-between border-t border-solid border-rb-gray-250 bg-rb-gray-20 px-11 py-5">
        <RfParagraphMedium>
          <Link
            to="/collections"
            className="text-rb-gray-300"
            onClick={handleViewAll}
            onKeyUp={onEnterKeyPress(handleViewAll)}
          >
            view all Collections
          </Link>
        </RfParagraphMedium>
        <Button onClick={handleCopyClick} disabled={isForking || saveDisabled}>
          Copy to My Collections
        </Button>
      </div>
    </Modal>
  )
}

export default PreviewModal
