import { Helmet } from 'react-helmet'

import { getCanonicalUrl, replaceTemplateTags } from 'domains/Sanity/lib/helpers'

import { PageSeo, SiteSettings } from 'gql'

import { sanityImageBuilder } from 'utils/sanity/sanityUtils'

interface HeadSEOProps {
  site: SiteSettings
  page: GenericPageType
  pageTitle?: string
}

export interface ExtendedPageSeo extends PageSeo {
  canonicalUrl?: string
}

export type GenericPageType = {
  title?: string
  createdAt?: string
  updatedAt?: string
  seo?: ExtendedPageSeo | null
}

const MarketingHeadSEO = ({ site, page = {}, pageTitle }: HeadSEOProps) => {
  const siteTitle = 'Reforge'

  if (page == null) return null

  const templateTags = [
    {
      tag: '{{page_title}}',
      value: page?.title
    },
    {
      tag: '{{site_title}}',
      value: siteTitle
    }
  ]

  const metaTitle =
    pageTitle ||
    replaceTemplateTags(page.seo?.metaTitle || site.seo?.metaTitle || '', templateTags)

  const metaDesc = page.seo?.metaDesc

  const shareTitle =
    pageTitle ||
    replaceTemplateTags(page.seo?.shareTitle || site.seo?.shareTitle || '', templateTags)
  const shareDesc = page.seo?.shareDesc
  const shareGraphic = page.seo?.shareGraphic?.asset || site.seo?.shareGraphic?.asset
  const shareImageUrl = shareGraphic
    ? typeof shareGraphic === 'string'
      ? shareGraphic
      : sanityImageBuilder.image(shareGraphic).width(1200).height(630).url()
    : null

  const shareGraphicAlt = page.seo?.shareGraphicAlt

  const ogType = page.seo?.type

  const canonical =
    page.seo?.canonicalUrl ||
    getCanonicalUrl(window.location.pathname + window.location.search)

  const publishedAt = page.seo?.publishDate || page.createdAt || null
  const updatedAt = page.updatedAt || null
  const authors = page.seo?.authors || null
  const structuredDataSchema = page.seo?.structuredDataSchema || null

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="format-detection" content="telephone=no" />
        <link rel="preconnect" href="https://cdn.sanity.io" crossOrigin="" />

        <link rel="canonical" href={canonical} />

        <title>{metaTitle}</title>
        {metaDesc && <meta name="description" content={metaDesc} />}

        {/* OG */}
        <meta property="og:site_name" content={siteTitle} />
        <meta property="og:title" content={shareTitle} />
        {shareDesc && <meta property="og:description" content={shareDesc} />}
        <meta property="og:url" content={canonical} />
        {ogType && <meta property="og:type" content={ogType} />}
        {shareImageUrl && <meta property="og:image" content={shareImageUrl} />}
        {shareGraphicAlt && <meta name="og:image:alt" content={shareGraphicAlt} />}

        {/* Twitter */}
        <meta name="twitter:title" content={shareTitle} />
        {shareDesc && <meta name="twitter:description" content={shareDesc} />}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={canonical} />
        {shareImageUrl && <meta name="twitter:image" content={shareImageUrl} />}

        {/* other meta - inspired from Squarespace blog pages */}
        <meta itemProp="name" content={metaTitle} />
        <meta itemProp="url" content={canonical} />
        {metaDesc && <meta itemProp="description" content={metaDesc} />}
        {updatedAt && <meta itemProp="dateModified" content={updatedAt} />}
        <meta itemProp="headline" content={page?.title} />
        <meta itemProp="publisher" content={siteTitle} />

        {structuredDataSchema && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: structuredDataSchema
            }}
          />
        )}
      </Helmet>
      {shareImageUrl && (
        <Helmet>
          <meta itemProp="thumbnailUrl" content={shareImageUrl} />
          <link rel="image_src" href={shareImageUrl} />
          <meta itemProp="image" content={shareImageUrl} />
        </Helmet>
      )}
      {authors && (
        <Helmet>
          <meta property="article:author" content={authors} />
          <meta itemProp="author" content={authors} />
        </Helmet>
      )}
      {publishedAt && (
        <Helmet>
          <meta property="article:published_time" content={publishedAt} />
          <meta itemProp="datePublished" content={publishedAt} />
        </Helmet>
      )}
    </>
  )
}

export default MarketingHeadSEO
