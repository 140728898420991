import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom-v5-compat'

import Post from 'domains/Cms/Post/Post'
import useDiscussions from 'domains/Cms/hooks/useDiscussions'

import { ErrorMessage } from 'components'
import Button from 'components/Button'
import {
  EmptyStateCard,
  EmptyStateVariant
} from 'components/DiscoveryDrawer/EmptyStateCard'
import Loading from 'components/Loading'
import { useSideDrawer } from 'components/SideDrawer/SideDrawer'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import { InlinePost } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

export interface DiscoveryDrawerDiscussionsPanelProps {
  cmsProgramId: string
  cmsModuleId: string
  cmsSectionId: string
  userId: string
  isTrial: boolean
  isActive?: boolean
}

export const DiscoveryDrawerDiscussionsPanel = ({
  cmsSectionId,
  cmsModuleId,
  cmsProgramId,
  userId,
  isTrial,
  isActive = false
}: DiscoveryDrawerDiscussionsPanelProps) => {
  const currentUser = useAssertCurrentUser()
  const [userTagFound, setUserTagFound] = useState<boolean>(false)
  const { openDrawer, setActiveFilterId } = useSideDrawer()

  const { inlinePosts, loading, errors, refetch, trackContentAction } = useDiscussions({
    cmsSectionId,
    cmsModuleId,
    cmsProgramId,
    userId
  })

  const [searchParams] = useSearchParams()

  const sortPosts = (
    anchorElementMap: Record<string, HTMLElement | null>,
    posts: InlinePost[]
  ) => {
    if (!posts) return []
    const sortedPosts = [...posts] as InlinePost[]
    sortedPosts.sort((a, b) => {
      // TODO: there is a potential race condition here if the CmsSectionDescription is not loaded yet
      const aElem = anchorElementMap[a.anchor]
      const bElem = anchorElementMap[b.anchor]
      if (!aElem || !bElem) return 0
      const position = aElem.compareDocumentPosition(bElem)
      if (
        position & Node.DOCUMENT_POSITION_FOLLOWING ||
        position & Node.DOCUMENT_POSITION_CONTAINED_BY
      ) {
        return -1
      } else if (
        position & Node.DOCUMENT_POSITION_PRECEDING ||
        position & Node.DOCUMENT_POSITION_CONTAINS
      ) {
        return 1
      } else {
        return 0
      }
    })
    return sortedPosts
  }

  const sortedPosts = useMemo(() => {
    if (!inlinePosts) return []
    const anchorElementMap: Record<string, HTMLElement | null> = inlinePosts.reduce(
      (m: Record<string, HTMLElement | null>, p: InlinePost) => {
        const elem = document.getElementById(p.anchor)
        if (elem) m[p.anchor] = elem
        return m
      },
      {}
    )
    return sortPosts(anchorElementMap, inlinePosts as InlinePost[])
  }, [inlinePosts])

  useEffect(() => {
    if (!loading) {
      const teamPostId = searchParams.get('tid')
      if (!teamPostId) return

      openDrawer('discoveryDrawer')

      if (location.hash) setActiveFilterId(location.hash.substring(1))

      const element = document.getElementById(`inline-post--${teamPostId}`)
      const container = document.getElementById('discovery-drawer-discussions-panel')
      if (!element || !container) return

      const rect = element.getBoundingClientRect()

      container.scrollTo({
        top: container.scrollTop + rect.top - 100
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  if (!isActive) return null

  if (errors) return <ErrorMessage />

  return (
    <div
      id="discovery-drawer-discussions-panel"
      data-testid="discovery-drawer-discussions-panel"
      className="w-screen overflow-auto md:w-auto md:min-w-[350px]"
    >
      <div className="flex flex-col gap-4 p-6">
        <div className="flex justify-between">
          <h4 className="my-0 text-xs font-medium uppercase text-rb-gray-500">
            Comments
            {!!sortedPosts?.length && (
              <span className="pl-4 text-rb-gray-300">{sortedPosts?.length}</span>
            )}
          </h4>
        </div>
        {loading && <Loading />}
        {isTrial ? (
          <>
            <h4 className="mx-5 mt-0 mb-0 font-sans text-xl font-black text-rb-gray-500">
              Paid Membership Required
            </h4>
            <p className="comments-sidebar__synopsis m-5 font-sans text-[15px] leading-[1.5]">
              Only paid participants are able to see the discussions and ask questions.
            </p>
          </>
        ) : (
          <>
            {!loading && (
              <>
                <div>
                  <RfParagraphMini color={ColorOptions.gray}>
                    Only your team can see these comments
                  </RfParagraphMini>
                  {!!sortedPosts?.length && (
                    <div className="mt-4">
                      {sortedPosts.map((inlinePost) => (
                        <Post
                          key={inlinePost.id}
                          post={inlinePost as InlinePost}
                          currentUserId={userId}
                          updatePost={refetch}
                          trackEvent={trackContentAction}
                          userTagFound={userTagFound}
                          setUserTagFound={setUserTagFound}
                          cmsSectionId={cmsSectionId}
                        />
                      ))}
                    </div>
                  )}
                  {!sortedPosts?.length && (
                    <div className="mt-2 text-sm font-normal">
                      <EmptyStateCard
                        title="Leave a comment to your team"
                        body="Drag some text or click an image to bring up the comment button and start a new thread with your team!"
                        show={true}
                        variant={EmptyStateVariant.comment}
                      />
                    </div>
                  )}
                  {!currentUser.hasTeam && (
                    <div className="mt-8 w-[298px]">
                      <RfParagraphMedium className="mb-4">
                        Oh no! You don’t have any teammates.
                      </RfParagraphMedium>
                      <Button
                        shape="rounded-full"
                        size="medium"
                        href="/manage-plan"
                        fullWidth={true}
                      >
                        Upgrade to a team plan
                      </Button>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default DiscoveryDrawerDiscussionsPanel
