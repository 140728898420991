import { twMerge } from 'tailwind-merge'

import { SVGIcon } from 'components/Icon'
import PopupMenu from 'components/PopupMenu'

import { ReactionKind } from 'gql'

import {
  TLegacyOrGraphQLReaction,
  kindToEnglish,
  kindToSvg,
  sortedReactions
} from 'utils/reactionUtils'

import { useReactionPicker } from './useReactionPicker'

interface ReactionPickerProps {
  reactions: TLegacyOrGraphQLReaction[]
  currentUserId: string
  addReaction: (kind: ReactionKind) => void
  removeReaction: (id: string | number) => void
  reactableType: string
  reactableId: string
  containerClassName?: string
  svgContainerClassName?: string
  className?: string
  height?: string
  width?: string
}

const ReactionPicker = ({
  reactions,
  currentUserId,
  addReaction,
  removeReaction,
  reactableType,
  reactableId,
  containerClassName,
  svgContainerClassName,
  className,
  height,
  width
}: ReactionPickerProps) => {
  const { handleReactionClick } = useReactionPicker({
    reactions,
    currentUserId,
    addReaction,
    removeReaction
  })

  return (
    <PopupMenu
      id={`reaction-picker-${reactableType}-${reactableId}`}
      content={
        <>
          {sortedReactions.map((kind) => (
            <div key={kind} className="group" onClick={() => handleReactionClick(kind)}>
              <div className="text-rb-gray-30 mb-1 flex cursor-pointer items-center group-hover:text-rb-teal-200 group-focus:text-rb-teal-200 group-active:text-rb-teal-200">
                <SVGIcon
                  className={twMerge(
                    'text-rb-gray-30 mr-2.5 group-hover:text-rb-teal-200',
                    className
                  )}
                  name={kindToSvg[kind]}
                  height={height || '18'}
                  width={width || '18'}
                  fill="currentColor"
                />
                {kindToEnglish[kind]}
              </div>
            </div>
          ))}
        </>
      }
    >
      <div
        className={twMerge(
          'mr-2.5 flex cursor-pointer items-center rounded-2xl p-2 text-center leading-4 text-rb-gray-400 hover:bg-rb-gray-100',
          containerClassName
        )}
      >
        <span className={twMerge('flex h-4 w-8 justify-center', svgContainerClassName)}>
          <SVGIcon
            className={twMerge('mr-1 text-rb-gray-300', className)}
            name="simple-smile"
            fill="currentColor"
            height="16"
            width="16"
          />
          <SVGIcon
            name="plus"
            fill="currentColor"
            height="10"
            width="10"
            className={twMerge('text-rb-gray-300', className)}
          />
        </span>
      </div>
    </PopupMenu>
  )
}

export default ReactionPicker
