import { useGlobalModal } from 'components/GlobalModal'

import OutOfSeatsModal from './OutOfSeatsModal'

export function useOutOfSeatsModal() {
  const { openGlobalModal, closeGlobalModal } = useGlobalModal()

  const handleClose = () => {
    closeGlobalModal()
  }

  const openOutOfSeatsModal = () => {
    openGlobalModal(
      <OutOfSeatsModal onUpgradePlan={handleClose} onCancel={handleClose} />,
      {
        className: 'mx-2 max-w-[646px] rounded-lg',
        handleClose
      }
    )
  }

  return {
    openOutOfSeatsModal
  }
}
