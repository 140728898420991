import useDeleteCohortPost from 'domains/CohortConversation/hooks/useDeleteCohortPost'

import {
  CohortPostCardBaseContainer,
  CohortPostCardReactions,
  CohortPostCardReplies
} from 'components/CohortPostCard'
import { CohortPostDeleteConfirmationModal } from 'components/CohortPostDeleteConfirmationModal'
import { CohortPostReportConfirmationModal } from 'components/CohortPostReportConfirmationModal'
import { useModal } from 'components/Modal'

import {
  CohortPostCardUserPartsFragment,
  CohortPostPartsFragment,
  CohortPostUserPartsFragment
} from 'gql'

import { ReactableType } from 'utils/reactionUtils'

interface CohortPostCardProps {
  post: CohortPostPartsFragment
  className?: string
  showProfile: (user: CohortPostUserPartsFragment) => void
  showThread: (post: CohortPostPartsFragment) => void
  closeThread: () => void
  refetch?(): Promise<unknown>
  currentUser: CohortPostCardUserPartsFragment | null | undefined
  includeInteractions?: boolean
  openEditModal?: () => void
  cohortSlug: string
}

const CohortPostCardContainer = ({
  post,
  className,
  showProfile,
  showThread,
  closeThread,
  refetch,
  currentUser,
  includeInteractions = false,
  openEditModal,
  cohortSlug
}: CohortPostCardProps) => {
  const {
    isModalOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal
  } = useModal()
  const {
    isModalOpen: isReportModalOpen,
    openModal: openReportModal,
    closeModal: closeReportModal
  } = useModal()
  const { deleteCohortPost } = useDeleteCohortPost()

  const handleDeletePostClick = async () => {
    if (!post) {
      return
    }

    await deleteCohortPost({ postId: post.id })

    refetch?.()
    closeDeleteModal()
    closeThread()
  }

  return (
    <CohortPostCardBaseContainer
      post={post}
      className={className}
      showProfile={showProfile}
      includeInteractions={includeInteractions}
      openEditModal={openEditModal}
      onDeletePostClick={openDeleteModal}
      onReportPostClick={openReportModal}
      refetch={refetch}
      showThread={() => showThread(post)}
      cohortSlug={cohortSlug}
    >
      <CohortPostDeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        deletePost={handleDeletePostClick}
        label="post"
      />

      <CohortPostReportConfirmationModal
        isOpen={isReportModalOpen}
        onClose={closeReportModal}
        label="post"
        postOrReplyId={post.id}
      />

      <CohortPostCardReactions
        currentUserId={currentUser?.id || ''}
        reactableId={post.id}
        reactions={post.reactions}
        reactableType={ReactableType.post}
        reactablePost={post}
      />

      <CohortPostCardReplies
        post={post}
        showThread={showThread}
        replies={post.replies}
        lastRespondedAt={post.lastRespondedAtInWords || ''}
      />
    </CohortPostCardBaseContainer>
  )
}

export default CohortPostCardContainer
