import pluralize from 'pluralize'
import { twMerge } from 'tailwind-merge'

import { getContentTypeLabel } from 'components/ContentCard/utils/getContentTypeLabel'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'

import { CmsSectionContentType, ConceptCardSectionPartsFragment } from 'gql'

export interface ContentCardHeaderProps {
  content?: ConceptCardSectionPartsFragment
  typename?: string
  contentType?: string | null
  contentSubtype?: string | null
  estimatedTime?: string | null
  numPhases?: string | number | null
  headerClassName?: string
  hideDefaultUnit?: boolean | null
  contentDate?: string
  contentTime?: string
  contentTimeZone?: string
  numLessons?: number | null // section card variant
  numLessonsCompleted?: number | null // section card variant
  showProgress?: boolean
}

const ContentCardHeader = ({
  content,
  headerClassName,
  hideDefaultUnit,
  contentDate,
  contentTime,
  contentTimeZone = '',
  numLessons,
  numLessonsCompleted,
  showProgress,
  ...props
}: ContentCardHeaderProps) => {
  const typename = content?.__typename ?? props.typename
  const contentType = content?.contentType ?? props.contentType
  const contentSubtype = content?.contentSubtype ?? props.contentSubtype
  const estimatedTime = content?.estimatedTime ?? props.estimatedTime
  const numPhases = content?.numPhases ?? props.numPhases

  const isVideoContent = (type?: string | null) =>
    type && ['Clip', 'Event', 'Lesson'].includes(type)
  const seenSuffix =
    isVideoContent(typename) || isVideoContent(contentType) ? 'video' : 'read'
  const showEstimatedTime = contentType !== 'Project' && contentSubtype !== 'Template'

  return (
    <div className={twMerge('flex min-h-[26px] pb-2.5', headerClassName)}>
      <RfParagraphMini
        className={twMerge(
          'flex items-start whitespace-nowrap',
          typename === 'Bookmark' && 'items-center'
        )}
      >
        <span className="flex items-center font-medium text-rb-gray-500">
          {getContentTypeLabel(contentSubtype, contentType, typename, numLessons)}
        </span>
        <span
          className={twMerge(
            'flex items-start text-[10px] text-rb-gray-400',
            typename === 'Bookmark' && 'items-center'
          )}
        >
          {contentDate && <span className="px-1 font-bold">{contentDate}</span>}
          {contentTime && (
            <span>
              <span className="lowercase">{contentTime}</span> {contentTimeZone}
            </span>
          )}
          {estimatedTime && showEstimatedTime && (
            <>
              <span className="px-1">·</span>
              {estimatedTime}
              {!hideDefaultUnit && ' min'}
              {contentType !== CmsSectionContentType.SECTION && ` ${seenSuffix}`}
            </>
          )}
          {contentType === CmsSectionContentType.SECTION &&
            showProgress &&
            numLessons && (
              <>
                <span className="px-1">·</span>
                <span className="whitespace-normal">
                  {numLessonsCompleted || 0}/{numLessons}{' '}
                  {pluralize('lesson', numLessons)} completed
                </span>
              </>
            )}
          {numPhases && contentType === CmsSectionContentType.PROJECT && (
            <>
              <span className="px-1">·</span>
              {numPhases} Phases
            </>
          )}
        </span>
      </RfParagraphMini>
    </div>
  )
}

export default ContentCardHeader
