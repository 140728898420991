import { MARKETPLACE_COURSE_TYPE_LEGACY } from 'pages/CoursesPage/CourseCard'

import Image from 'domains/Sanity/Image'

import { HomepageCourse, MarketingCourse } from 'gql'

import ButtonMarketingCTA from '../ButtonMarketingCTA'

interface MarketingCourseCardProps {
  course: MarketingCourse | HomepageCourse
  showTitle?: boolean
}

function isHomepageCourse(
  course: MarketingCourse | HomepageCourse
): course is HomepageCourse {
  return (course as HomepageCourse).homepageCoursePreviewImage !== undefined
}

const MarketingCourseCard = ({ course, showTitle }: MarketingCourseCardProps) => {
  const title =
    course.courseType === MARKETPLACE_COURSE_TYPE_LEGACY
      ? course.title
      : course?.course?.title
  const slug =
    course.courseType === MARKETPLACE_COURSE_TYPE_LEGACY
      ? course.slug
      : course?.course?.slug
  const description =
    course.courseType === MARKETPLACE_COURSE_TYPE_LEGACY
      ? course.subtitle
      : course?.course?.shortDescription
  const creators = course.creators?.map((c) => c.expert?.name) || []
  const previewImage = isHomepageCourse(course)
    ? course.homepageCoursePreviewImage
    : course.previewImage

  const getCtaHref = (course: MarketingCourse | HomepageCourse, slug?: string | null) => {
    if (!slug) return '/courses'
    if (
      !isHomepageCourse(course) &&
      course.courseType === MARKETPLACE_COURSE_TYPE_LEGACY
    ) {
      return `/courses/${slug}?courseType=${course.courseType}&cmsProgramSlug=${course.cmsProgramSlug}`
    }
    return `/courses/${slug}?courseType=${course.courseType}`
  }

  return (
    <li
      className="rounded-[5px] bg-rb-white p-[6%] shadow-[0_0_5px_rgba(0,0,0,.15)]"
      aria-label={title || ''}
    >
      <Image
        src={previewImage?.imageUrl || ''}
        alt=""
        width={400}
        height={400 * (previewImage?.aspectRatio || 1)}
        className="mb-[5%] h-auto w-full"
      />

      {showTitle && (
        <h3 className="mb-[1%] font-normal !leading-7 tracking-tight fluid-text-lg">
          {title}
        </h3>
      )}

      <p className="mb-[16px] !leading-[1.25] fluid-text-sm">
        <em>
          Created by{' '}
          {creators?.map((c, i) => {
            return `${i === creators.length - 1 && i !== 0 ? ' & ' : ''}${c}${
              i < creators.length - 2 ? ', ' : ''
            }`
          })}
        </em>
      </p>

      <p className="mb-[16px] !leading-[1.25] fluid-text-sm">{description}</p>

      <ButtonMarketingCTA
        size="small"
        variant="text"
        href={getCtaHref(course, slug)}
        className="font-semibold underline hover:underline"
      >
        Learn More ↗
      </ButtonMarketingCTA>
    </li>
  )
}

export default MarketingCourseCard
