import {
  ContentCardContainer,
  ContentCardFooter,
  ContentCardHeroImage
} from 'components/ContentCard'

import { getPrememberCardTooltip } from 'utils/prememberCardTooltips'

import { ContentCardProps } from './ContentCard'
import ContentCardBody from './ContentCardBody'
import ContentCardTopSection from './ContentCardTopSection'
import useContentCardData from './useContentCardData'

const ResponsiveContentCard = (props: ContentCardProps) => {
  const {
    content,
    contentCardContainerProps,
    topSectionProps,
    heroImageProps,
    isPremember,
    previewable,
    showNewBadge,
    tooltipId
  } = useContentCardData(props)

  return (
    <ContentCardContainer {...contentCardContainerProps}>
      <ContentCardTopSection {...props} {...topSectionProps} />
      <div className="flex h-full flex-col justify-between">
        <ContentCardHeroImage {...heroImageProps} />
        <div className="mt-6 grow">
          <ContentCardBody content={content} />
        </div>
        <div>
          <ContentCardFooter
            lastViewedAt={content.lastViewedAt}
            showNewBadge={showNewBadge}
            showFreeBadge={isPremember && previewable}
          />
        </div>
      </div>
      {isPremember && getPrememberCardTooltip(tooltipId)}
    </ContentCardContainer>
  )
}

export default ResponsiveContentCard
