import { useEffect, useReducer } from 'react'
import Pluralize from 'react-pluralize'
import UIkit from 'uikit'

import Seat from 'domains/User/Seat'

import Button from 'components/Button'
import Card from 'components/Card'
import { SVGIcon } from 'components/Icon'
import Loading from 'components/Loading'
import { displayToast } from 'components/Toast'
import RfCardTitle from 'components/typography/RfCard/RfCardTitle'

import { UserSubscriptionWithTeamSubscriptionFragment } from 'gql'

import { trackModalDisplayed } from 'hooks/useModalTracking'

import { trackCtaClicked } from 'utils/tracking/analytics'

export interface SubscriptionMembersFormProps {
  userId: string | number
  activeSubscription?: UserSubscriptionWithTeamSubscriptionFragment | null
  userEmail: string
  allUserEmails: string[]
}

export default function SubscriptionMembersForm({
  userId,
  activeSubscription,
  userEmail,
  allUserEmails
}: SubscriptionMembersFormProps) {
  /* eslint-disable react-hooks/rules-of-hooks */
  if (!activeSubscription || !activeSubscription.userIsOwner) return null

  const {
    id: subscriptionId,
    termEndsAt,
    numSeats,
    seats,
    expiringSeatCount: expiringSeats
  } = activeSubscription

  const confirmationMessageMappings = {
    seat_transferred: 'Seat successfully transferred',
    seat_released: 'Seat successfully released',
    seat_scheduled_for_deletion: 'Seat successfully removed'
  }

  const initialState = {
    seats: seats || [],
    loading: false,
    confirmationModalMessage: undefined
  }

  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    initialState
  )

  useEffect(() => {
    // NOTE: This is used for when the seat is updated in a mutation (e.g. removeSeat)
    setState({ seats })
  }, [seats])

  const handleCtaClick = () => {
    trackCtaClicked({
      cta_location: 'manage_seats_page',
      cta_type: 'button',
      text: 'manage plan'
    })
  }

  const openManageSeatsModal = () => {
    UIkit.modal('#modal-manage-seats').show()

    trackModalDisplayed({
      modal_group: 'manage seats',
      modal_name: 'manage seats'
    })
  }

  /* Some controller endpoints redirect to this page with one of these params to show a confirmation message */
  useEffect(() => {
    const queryParams = new window.URLSearchParams(window.location.search)
    const confirmationParam =
      (queryParams.get('seat_transferred') && 'seat_transferred') ||
      (queryParams.get('seat_released') && 'seat_released') ||
      (queryParams.get('seat_scheduled_for_deletion') && 'seat_scheduled_for_deletion')
    const errorParam = queryParams.get('error')
    if (confirmationParam || errorParam) {
      window.history.replaceState(
        {},
        document.title,
        window.location.pathname
      ) /* dont allow user to browse back */
    }

    if (confirmationParam) {
      setState({
        confirmationModalMessage: confirmationMessageMappings[confirmationParam]
      })
      UIkit.modal('#modal-confirmation-message').show()
    }

    if (errorParam) {
      displayToast(errorParam, { theme: 'colored', type: 'error' })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayToast])
  /* eslint-enable react-hooks/rules-of-hooks */

  const updateSeats = (index: number, subscriptionMember: any) => {
    const seatsCopy = [...state.seats]
    seatsCopy[index] = { ...seatsCopy[index] }
    seatsCopy[index].subscriptionMember = subscriptionMember
    setState({ seats: seatsCopy })

    /* If we've assigned a seat to the current user, refresh the page to show the new access priveleges */
    if (allUserEmails.includes(subscriptionMember.email)) {
      window.location.reload()
    }
  }

  if (numSeats === 0) {
    return null
  }

  const thClassName =
    'tl:!py-[7px] tl:!px-3 !py-1 !px-1.5 !text-xs !font-medium !text-rb-gray-300 !tracking-widest text-left uppercase text-rb-gray-500'

  return (
    <div key="uikit-reactrouter-hack-submemberform">
      <Card dataTest="seat-allocation">
        <RfCardTitle className="rb-gray-500 mb-4 text-xl font-medium">
          People on this plan
        </RfCardTitle>
        {state.loading && <Loading />}
        <table
          className="uk-table uk-table-striped uk-table-small text-[13px] text-rb-gray-500 tl:text-sm"
          data-test="people-on-plan-table"
        >
          <thead>
            <tr>
              <th className={`${thClassName} uk-visible@l`}>Seat</th>
              <th className={thClassName}>Email</th>
              <th className={`${thClassName} uk-visible@l`}>Role</th>
              <th className={thClassName}>Status</th>
              <th className={thClassName}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {state.seats &&
              state.seats.map((seat: any, i: number) => (
                <Seat
                  key={`manage_${i}`}
                  index={i}
                  subscriptionId={subscriptionId}
                  userId={userId}
                  seatId={seat.id}
                  subscriptionMember={seat.subscriptionMember}
                  scheduledForDeletionDate={seat.scheduledForDeletionDate}
                  readonly={true}
                  manageSeats={openManageSeatsModal}
                  userEmail={userEmail}
                  termEndsAt={termEndsAt}
                  numSeats={numSeats}
                  allUserEmails={allUserEmails}
                  expiringSeats={expiringSeats}
                />
              ))}
          </tbody>
        </table>
        <div className="mt-5 mb-2">
          To add more seats to your plan, update your subscription.
        </div>
        <Button onClick={handleCtaClick} className="py-2.5" href="/manage-plan">
          Manage Plan
        </Button>
      </Card>
      <div
        id="modal-manage-seats"
        className="uk-modal-container"
        uk-modal={'{"container": false}'}
        data-test="modal-manage-seats"
      >
        <div className="uk-modal-dialog uk-modal-body p-3 sm:p-8 lg:!w-[970px]">
          <div className="text-2xl font-medium text-black">Manage Seats</div>
          <p className="text-rb-gray-300" style={{ margin: '0' }}>
            Invite, organize and grow your team. You have{' '}
            <b className="font-medium">
              <Pluralize singular={'prepaid seat'} count={numSeats} /> in your
              subscription.
            </b>
          </p>
          <button
            className="uk-modal-close-default"
            type="button"
            uk-close="true"
            aria-label="close"
          />

          <table className="uk-table uk-table-striped uk-table-small uk-margin-small-top text-[13px] text-rb-gray-500 tl:text-sm">
            <thead>
              <tr>
                <th className={`${thClassName} uk-visible@l`}>Seat</th>
                <th className={thClassName}>Email</th>
                <th className={thClassName}>Status</th>
                <th className={`${thClassName} sm:w-[160px]`}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {state.seats &&
                state.seats.map((seat: any, i: number) => {
                  return (
                    <Seat
                      key={`index_${i}`}
                      index={i}
                      subscriptionId={subscriptionId}
                      userId={userId}
                      seatId={seat.id}
                      subscriptionMember={seat.subscriptionMember}
                      scheduledForDeletionDate={seat.scheduledForDeletionDate}
                      readonly={false}
                      updateSeats={updateSeats}
                      userEmail={userEmail}
                      termEndsAt={termEndsAt}
                      numSeats={numSeats}
                      allUserEmails={allUserEmails}
                      expiringSeats={expiringSeats}
                    />
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>
      <div
        id="modal-confirmation-message"
        className="uk-modal-container"
        uk-modal={'{"container": false}'}
        data-test="modal-confirmation-message"
      >
        <div className="uk-modal-dialog uk-modal-body lg:!w-[970px]">
          <div className="uk-text-center text-xl text-rb-gray-300 ">
            <SVGIcon
              name="check-slim"
              height="42"
              width="55"
              fill="#000000"
              className="uk-margin-small-bottom uk-margin-top mx-auto"
            />
            <div
              data-test="seat-confirmation-modal"
              className="uk-margin"
              style={{ marginBottom: '40px' }}
            >
              <p>
                <b className="font-medium">{state.confirmationModalMessage}</b>
                <br />
                We&apos;ll send an email confirmation shortly
                <br />
              </p>
              <button
                className="uk-modal-close-default"
                type="button"
                uk-close="true"
                aria-label="close"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
