import Button from 'components/Button'
import {
  ShareableSnapshotModal,
  useShareableSnapshotModal
} from 'components/ShareableSnapshotModal'
import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import { CohortDashboardCmsProgramPartFragment } from 'gql'

import { ReactComponent as CheckmarkIcon } from 'images/icon--filled-green-checkmark.svg'

interface CohortCelebrateAchievementProps {
  program: CohortDashboardCmsProgramPartFragment
}

const CohortCelebrateAchievement = ({ program }: CohortCelebrateAchievementProps) => {
  const { showShareableSnapshotModal } = useShareableSnapshotModal()

  return (
    <div className="flex w-full max-w-[890px] flex-col gap-12 rounded-[8px] bg-white p-8 md:flex-row md:gap-20 md:p-12">
      <div className="md:max-w-[194px]">
        <RfHeader3SemiBold className="!mb-8">
          Let&apos;s celebrate what you achieved
        </RfHeader3SemiBold>
        <Button
          className="px-4 py-2.5 normal-case"
          onClick={() => showShareableSnapshotModal('Cohort Dashboard')}
        >
          Share your snapshot
        </Button>
      </div>
      <div className="flex-1">
        {program.byTheEnd?.split(';').map((item: string, i) => (
          <div key={`items-${i}`} className="mb-6 flex items-start">
            <div className="block h-full max-h-[16px] w-full max-w-[16px] pt-1">
              <CheckmarkIcon />
            </div>
            <RfParagraphMedium className="ml-4">{item}</RfParagraphMedium>
          </div>
        ))}
      </div>
      <ShareableSnapshotModal page="Cohort Dashboard" />
    </div>
  )
}

export default CohortCelebrateAchievement
