import { useLocation } from 'react-router-dom'

import useURLParams from 'hooks/useURLParams'

import NavItem from './NavItem'

const LogInButton = () => {
  const { pathname } = useLocation()
  const { getQueryString } = useURLParams()

  const getUrl = () => {
    const referer = encodeURIComponent(pathname)
    const existingParams = getQueryString()
    return `/login?marketingNav=true&referer=${referer}&${existingParams}`
  }

  return (
    <NavItem
      item={{
        title: 'Log in',
        url: getUrl()
      }}
      linkClassName="text-base font-normal mr-2"
      containerClassName="w-min"
    />
  )
}

export default LogInButton
