import { ReactComponent as SparkleIcon } from 'icon--sparkle.svg'
import React from 'react'

import Button from 'components/Button'
import { getDefaultPassCount } from 'components/CheckoutModal/utils'

import { PlanName } from 'gql'

import { titleize } from 'utils/stringUtils'
import { trackCtaClicked } from 'utils/tracking/analytics'

import { PlanOptionCardProps } from './PlanOptionCard'
import { Container } from './components/Container'
import { FeatureBreakdown } from './components/FeatureBreakdown'
import { PlanAndPricingInfo } from './components/PlanAndPricingInfo'
import { Title } from './components/Title'

export type NewUserPlanOptionCardProps = Pick<
  PlanOptionCardProps,
  | 'cardPlanName'
  | 'isSubscriptionOwner'
  | 'title'
  | 'subtitle'
  | 'price'
  | 'content'
  | 'headerBarBgClass'
  | 'onPlanSelected'
>

export const NewUserPlanOptionCard = ({
  cardPlanName,
  isSubscriptionOwner,
  title,
  subtitle,
  price,
  content,
  onPlanSelected,
  headerBarBgClass = 'bg-rb-green-50'
}: NewUserPlanOptionCardProps) => {
  const isIndividualCard = cardPlanName === PlanName.INDIVIDUAL
  const isEnterpriseCard = cardPlanName === PlanName.ENTERPRISE
  const ctaText = isEnterpriseCard ? 'Get in Touch' : `Select ${titleize(cardPlanName)}`

  const handleCtaClick = () => {
    const ctaLocation = 'select_plans_page'

    if (cardPlanName === PlanName.ENTERPRISE) {
      trackCtaClicked({
        cta_type: 'button',
        cta_location: ctaLocation,
        text: ctaText.toLowerCase(),
        destination: 'new_team_interest_form'
      })
      return window.open('https://www.reforge.com/teams/get-in-touch', '_blank')
    }

    trackCtaClicked({
      cta_type: 'button',
      cta_location: ctaLocation,
      text: ctaText.toLowerCase()
    })

    onPlanSelected()
  }

  const showCohortPassSuggestion =
    cardPlanName && ![PlanName.ENTERPRISE, PlanName.INDIVIDUAL].includes(cardPlanName)

  return (
    <Container headerBarBgClass={headerBarBgClass}>
      <div className="flex h-full flex-col">
        <Title text={title} />
        <PlanAndPricingInfo price={price} />
        <div className="text-sm font-semibold">{subtitle}</div>

        {showCohortPassSuggestion && (
          <div className="flex pt-1 pb-5 text-xs text-rb-success">
            We suggest at least {getDefaultPassCount(cardPlanName)} Live Course Passes for{' '}
            {titleize(cardPlanName)} plans.
          </div>
        )}
        {cardPlanName === PlanName.INDIVIDUAL && <div className="mb-[28px]" />}
        {cardPlanName === PlanName.ENTERPRISE && <div className="mb-[56px]" />}

        {isSubscriptionOwner && (
          <Button
            className="w-full normal-case"
            size="small"
            variant="outline"
            onClick={handleCtaClick}
            dataTest="planOptionCardCta"
          >
            {ctaText}
          </Button>
        )}

        <div className="mt-6">
          {React.isValidElement(content) ? (
            content
          ) : (
            <FeatureBreakdown features={content} showExcludedFeatures={true} />
          )}
        </div>
        {isIndividualCard && <CohortPassInfo />}
      </div>
    </Container>
  )
}

const CohortPassInfo = () => (
  <div className="mt-8 rounded border border-rb-green-100 bg-rb-green-50 align-middle text-sm">
    <div className="mb-2 flex flex-row space-x-2 p-2">
      <div className="mb-2 font-semibold">
        <SparkleIcon width={16} height={16} className="mr-2.5 inline" stroke="black" />
      </div>
      <div className="space-y-2">
        <div className="font-semibold">1 Live Course</div>
        <div>The individual subscription comes with 1 Live Course Pass</div>
      </div>
    </div>
  </div>
)
