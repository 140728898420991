import Image from 'domains/Sanity/Image'

import { TestimonialWithImage } from 'gql'

const TestimonialCard = ({ testimonial }: { testimonial: TestimonialWithImage }) => {
  return (
    <div className="bg-white p-4 lg:p-6 w-full flex flex-col gap-4 lg:gap-6 lg:w-[1/3] self-stretch rounded-xl shadow-card-hover">
      <div className="flex items-center gap-4">
        <div className="shrink-0">
          <Image
            src={testimonial.avatar?.imageUrl || ''}
            height={72}
            width={72}
            alt={`${testimonial.name} avatar`}
            className="rounded-full object-cover"
          />
        </div>
        <div className="flex flex-col justify-center">
          <span className="w-full font-semibold text-ellipsis !leading-[1.35] text-lg lg:text-2xl">
            {testimonial.name}
          </span>
          <span className="w-full font-semibold text-rb-gray-300 !leading-[1.35] text-sm lg:text-lg">
            {testimonial.positionAndCompany}
          </span>
        </div>
      </div>
      <p className="text-lg lg:text-2xl !leading-[1.35] mb-0">{testimonial.quote}</p>
    </div>
  )
}

export default TestimonialCard
