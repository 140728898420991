import { Fragment } from 'react'

import { PlanOptionFields } from 'constants/plans'

import { PricingPlanFeature, PricingPlanFeatureCategory } from 'gql'

import { cn } from 'utils/tailwind'

import { ReactComponent as CheckmarkIcon } from 'images/icon--checkmark-thin.svg'

import ButtonMarketingCTA from '../ButtonMarketingCTA'
import { MarketingH2 } from '../textHelpers'

interface PricingFeaturesProps {
  features?: PricingPlanFeature[] | null
  planOptionsData?: PlanOptionFields[] | null
}

const PricingFeatures = ({ features, planOptionsData }: PricingFeaturesProps) => {
  if (
    !features ||
    !planOptionsData ||
    features.length === 0 ||
    planOptionsData.length === 0
  ) {
    return null
  }

  // group features by category
  const featuresByCategory: Record<string, PricingPlanFeature[]> = {}
  const uniqueCategories: PricingPlanFeatureCategory[] = []
  features.forEach((feature) => {
    if (!featuresByCategory[feature.category.name]) {
      featuresByCategory[feature.category.name] = []
    }
    featuresByCategory[feature.category.name].push(feature)

    if (!uniqueCategories.find((cat) => cat.name === feature.category.name)) {
      uniqueCategories.push(feature.category)
    }
  })
  const sortedCategories = uniqueCategories.sort((a, b) => a.order - b.order)

  return (
    <section className="px-4 md:px-[4vw] pt-[50px] lg:pt-[100px] xl:pb-[100px]">
      <div className="mx-auto text-rb-black lg:max-w-[1120px]">
        <MarketingH2 className="mb-[50px] lg:mb-[100px] text-center">
          Compare plans
        </MarketingH2>

        <div className="grid grid-cols-[1.7fr,repeat(4,1fr)] mb-[50px] md:mb-[120px]">
          {/* empty top left cell */}
          <div className="border-b border-rb-gray-100" />

          {/* Plan name top heading */}
          {planOptionsData.map((plan) => (
            <div
              key={plan.name}
              className="py-3 sm:py-2.5 lg:py-2 text-xs sm:text-sm lg:text-lg font-semibold !leading-[1.35] border-b border-rb-gray-100"
            >
              {plan.title}
            </div>
          ))}

          {sortedCategories.map((category, i) => (
            <Fragment key={category.name}>
              {/* Category name left heading */}
              <div
                className={cn(
                  'py-3 sm:py-2.5 lg:py-2 text-xs sm:text-sm lg:text-lg font-semibold !leading-[1.35] border-b border-rb-gray-100',
                  i > 0 && 'mt-10'
                )}
              >
                {category.name}
              </div>

              {/* empty top row next to gategory name */}
              {planOptionsData.map((plan) => (
                <div
                  key={plan.name}
                  className={cn(
                    'py-3 sm:py-2.5 lg:py-2 border-b border-rb-gray-100',
                    i > 0 && 'mt-10'
                  )}
                />
              ))}

              {featuresByCategory[category.name].map((feature) => (
                <Fragment key={feature.id}>
                  {/* Feature name left column */}
                  <div className="py-3 sm:py-2.5 lg:py-2 pr-2 lg:pr-4 text-xs sm:text-sm lg:text-lg !leading-[1.35] border-b border-rb-gray-100 flex items-center">
                    {feature.feature}
                  </div>

                  {/* Feature values */}
                  {feature.values?.map((value) => (
                    <div
                      className="py-3 sm:py-2.5 lg:py-2 text-xs sm:text-sm lg:text-lg !leading-[1.35] border-b border-rb-gray-100 flex items-center"
                      key={value.id}
                    >
                      {value.enabled ? (
                        value.textValue ? (
                          value.textValue
                        ) : (
                          <CheckmarkIcon className="h-4 w-4" />
                        )
                      ) : null}
                    </div>
                  ))}
                </Fragment>
              ))}
            </Fragment>
          ))}
        </div>

        <ButtonMarketingCTA
          href={'/login/?reforgeLogin=true'}
          location="marketing_pricing_page__compare_plans_section"
          className="mx-auto"
        >
          Start a 14-day free trial
        </ButtonMarketingCTA>
      </div>
    </section>
  )
}

export default PricingFeatures
