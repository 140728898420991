import { KeyboardEvent, MouseEvent, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import Image from 'domains/Sanity/Image'

import ArrowUpRight from 'components/icons/ArrowUpRight'

import ButtonMarketingCTA, {
  ButtonMarketingCTAVariant
} from '../ButtonMarketingCTA/ButtonMarketingCTA'

interface HeroProps {
  headerText: string | ReactNode
  description?: string | ReactNode
  ctas?: {
    href: string
    text: string
    className?: string
    variant?: ButtonMarketingCTAVariant
    onClick?: (e: MouseEvent | KeyboardEvent) => void
    target?: '_blank' | '_self' | '_parent' | '_top'
    location?: string
    includeExternalLinkArrow?: boolean
  }[]
  bgImage?: {
    url: string
    alt?: string | null
  }
  heroImage?: {
    url: string
    alt?: string | null
  }
  noCtaMargin?: boolean
  containerClassName?: string
  heroImageClassName?: string
  contentClassName?: string
}

// Marketing Hero
// handles:
// * just header text
// * (desktop) text on the left, image on the right; (mobile) image top, text on bottom
// * text over background image
// * optional CTA
const MarketingHero = ({
  headerText,
  description,
  ctas,
  bgImage,
  heroImage,
  noCtaMargin,
  containerClassName,
  contentClassName,
  heroImageClassName
}: HeroProps) => {
  return (
    <div
      className={twMerge(
        'relative flex flex-col px-[16px] md:flex-row md:px-[4vw]',
        heroImage && 'grid-rows-2 md:grid-cols-4 md:grid-rows-1 md:flex-row-reverse',
        containerClassName
      )}
    >
      {bgImage && (
        <Image
          src={bgImage.url}
          alt={bgImage.alt || ''}
          fill
          className="-z-1 object-cover object-bottom"
          priority
        />
      )}
      {heroImage && (
        <div
          className={twMerge(
            'relative my-[20px] mx-auto aspect-1 w-full max-w-[400px] shrink-0 md:my-[50px] md:ml-[6vw] md:mr-[2vw] md:aspect-[auto] md:w-[45%] md:max-w-none',
            heroImageClassName
          )}
        >
          <Image
            src={heroImage.url}
            alt={heroImage.alt || ''}
            fill
            className="object-contain object-center"
            priority
          />
        </div>
      )}
      <div
        className={twMerge(
          'w-full md:py-[60px]',
          !heroImage && 'max-w-[75%] pt-[4vmax]',
          contentClassName
        )}
      >
        <h1 className="mb-[30px] font-normal leading-none tracking-tight fluid-text-7xl">
          {headerText}
        </h1>
        {description && (
          <h2
            className={twMerge(
              'mt-[10px] mb-[80px] pr-[20px] font-normal tracking-tight fluid-text-3xl',
              noCtaMargin && 'mb-0'
            )}
          >
            {description}
          </h2>
        )}
        {ctas && (
          <div className={twMerge('py-[50px]', noCtaMargin && 'py-[30px]')}>
            {ctas.map((cta, i) => (
              <ButtonMarketingCTA
                key={`cta-${i}`}
                href={cta.href}
                variant={cta.variant}
                className={twMerge('mr-[25px] inline-block', cta.className)}
                onClick={cta.onClick}
                target={cta.target}
                location={cta.location}
                ctaText={cta.text}
              >
                <div className="flex items-center">
                  {cta.text}
                  {cta.includeExternalLinkArrow && (
                    <ArrowUpRight className="ml-2" width={16} height={16} />
                  )}
                </div>
              </ButtonMarketingCTA>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default MarketingHero
