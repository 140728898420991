import { useState } from 'react'
import Pluralize from 'react-pluralize'

import { CohortEvent, combineSameEvents, getTimeCopy } from 'domains/CohortViewer/utils'

import { SVGIcon } from 'components/Icon'
import {
  getNPSBannerDismissalFlag,
  getNPSCompletionFlag
} from 'components/banners/TopBanner/NpsBanner'
import FullWidthEventCard from 'components/cards/FullWidthEventCard'
import FullWidthLinkCard, {
  FullWidthLinkCardProps
} from 'components/cards/FullWidthLinkCard'
import FullWidthModuleCard from 'components/cards/FullWidthModuleCard'
import SimpleCardWithAction from 'components/cards/SimpleCardWithAction'
import ProgressCircle from 'components/cards/shared/ProgressCircle'
import { ChevronDownThin, ChevronUpThinIcon } from 'components/icons'

import {
  CohortViewerEventPartsFragment,
  CohortViewerOrderedCmsModulePartsFragment,
  CurrentUserPartsFragment,
  UserCohort
} from 'gql'

interface WeekBlockProps {
  dataTestId?: string
  todoTasks?: FullWidthLinkCardProps[]
  events?: CohortViewerEventPartsFragment[]
  cmsModules?: CohortViewerOrderedCmsModulePartsFragment[]
  currentUser: CurrentUserPartsFragment
  startsCollapsed: boolean
  title: string
  description: string
  numLessons: number
  estimatedTimeInMinutes: number
  progressPercent: number
  isTasks?: boolean | undefined
  isProject?: boolean | undefined
  weekNumber: number
  numScheduledWeeks?: number
  contentReleaseDate?: string
  userCohort: any
}

const WeekBlock = ({
  dataTestId,
  todoTasks,
  events,
  cmsModules,
  currentUser,
  startsCollapsed,
  title,
  description,
  numLessons,
  estimatedTimeInMinutes,
  progressPercent,
  isTasks,
  isProject,
  weekNumber,
  numScheduledWeeks,
  contentReleaseDate,
  userCohort
}: WeekBlockProps) => {
  const [isCollapsed, setIsCollapsed] = useState(startsCollapsed)
  const [combinedEvents, setCombinedEvents] = useState(combineSameEvents(events))

  const rsvpCallback = (event: CohortEvent, index: number) => {
    if (!combinedEvents) return

    const updatedCombinedEvents = [...combinedEvents]
    updatedCombinedEvents[index] = event
    setCombinedEvents(updatedCombinedEvents)
  }

  const expandIfCollapsed = () => {
    if (isCollapsed) {
      setIsCollapsed(false)
    }
  }

  const shouldDisplayPostCohortSurvey =
    userCohort.cohort.shouldShowNpsSurvey && userCohort.cohort.season.npsSurveyLink
  const isBeforeLastWeek = numScheduledWeeks && weekNumber === numScheduledWeeks - 1
  const isLastWeek = numScheduledWeeks && weekNumber === numScheduledWeeks

  return (
    <div
      className={`rf-rb-card flex p-5 pt-4 sm:p-4 ${isCollapsed ? 'cursor-pointer' : ''}`}
      onClick={expandIfCollapsed}
    >
      <div className="flex w-full flex-col">
        <div className="flex">
          <div className="mr-3 flex sm:mr-4">
            <div className="h-8 w-8">
              <ProgressCircle percentComplete={progressPercent} />
            </div>
          </div>
          <div className="flex flex-1 flex-col">
            <div className="mb-3 text-lg font-medium" data-test={`${dataTestId}-header`}>
              {title}
            </div>
            <div
              className="mb-7 text-rb-gray-400"
              data-test={`${dataTestId}-description`}
            >
              {description}
            </div>
            <div className="mb-7 flex flex-row justify-between">
              <div className="flex cursor-default items-center">
                {Boolean(estimatedTimeInMinutes) && estimatedTimeInMinutes !== 0 && (
                  <>
                    <div>
                      <SVGIcon name="clock" width="16" height="14" />
                    </div>
                    <span
                      className="ml-2 mr-4 text-xs leading-4 text-rb-gray-300"
                      data-test={`${dataTestId}-estimated-time`}
                    >
                      {getTimeCopy(estimatedTimeInMinutes)}
                    </span>
                  </>
                )}
                <div className="hidden flex-row items-center sm:flex">
                  {Boolean(numLessons) && numLessons !== 0 && (
                    <>
                      <div style={{ marginTop: '-1px' }}>
                        <SVGIcon
                          name="play-circle"
                          width="16"
                          height="16"
                          fill="#A2A1A2"
                        />
                      </div>
                      <span className="ml-2 mr-4 text-xs leading-4 text-rb-gray-300">
                        <>
                          {numLessons} {isTasks ? 'Tasks' : 'Lessons'}
                        </>
                      </span>
                    </>
                  )}
                  {isProject && (
                    <>
                      <SVGIcon name="projects" width="12" height="14" />
                      <span className="ml-2 mr-4 text-xs leading-4 text-rb-gray-300">
                        1 Project
                      </span>
                    </>
                  )}
                  {Boolean(combinedEvents) && combinedEvents.length > 0 && (
                    <>
                      <SVGIcon name="calendar" width="14" height="14" />
                      <span className="ml-2 mr-4 text-xs capitalize leading-4 text-rb-gray-300">
                        <Pluralize singular="event" count={combinedEvents.length} />
                      </span>
                    </>
                  )}
                </div>
              </div>
              {isCollapsed ? (
                <div
                  className="flex cursor-pointer items-center hover:text-rb-teal-200"
                  onClick={() => setIsCollapsed(false)}
                  data-test={`${dataTestId}-view-detail-button`}
                >
                  <ChevronDownThin height="16" width="16" className="fill-current" />
                  <div
                    style={{ width: '90px' }}
                    className="ml-2 text-xs font-medium tracking-widest"
                  >
                    View Details
                  </div>
                </div>
              ) : (
                <div
                  className="flex cursor-pointer items-center hover:text-rb-teal-200"
                  onClick={() => setIsCollapsed(true)}
                  data-test={`${dataTestId}-hide-detail-button`}
                >
                  <ChevronUpThinIcon height="16" width="16" className="fill-current" />
                  <div
                    style={{ width: '90px' }}
                    className="ml-2 text-xs font-medium tracking-widest"
                  >
                    Hide Details
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {!isCollapsed && (
          <div className="ml-0 sm:ml-12">
            {todoTasks &&
              todoTasks.map((todoTask, i) => (
                <div key={`todo_${i}`} className="mb-3">
                  <FullWidthLinkCard
                    content={todoTask.content}
                    linkText={todoTask.linkText}
                    linkAction={todoTask.linkAction}
                    isTaskCompleted={todoTask.isTaskCompleted}
                    disableActionButton={todoTask.disableActionButton}
                    dataTestId={todoTask.dataTestId}
                  />
                </div>
              ))}
            {cmsModules &&
              cmsModules.map((cmsModule, i) => (
                <div key={`module_${i}`} className="mb-3">
                  <FullWidthModuleCard
                    id={cmsModule.id}
                    name={cmsModule.name}
                    href={cmsModule.href}
                    numBookmarks={cmsModule.numBookmarks}
                    progressPercent={cmsModule.progressPercent}
                    programIconImageUrl={cmsModule.programIconImageUrl || ''}
                    estimatedTime={cmsModule.estimatedTime}
                    shortDescription={cmsModule.shortDescription || ''}
                    numLessons={cmsModule.numLessons}
                    published={!!cmsModule.published}
                    publishAt={cmsModule.publishAt || ''}
                    weekNumber={weekNumber}
                    releaseDate={contentReleaseDate}
                    isProject={isProject}
                  />
                </div>
              ))}
            {combinedEvents &&
              combinedEvents.map((event, i) => (
                <div key={`event_${i}`} className="mb-3">
                  <FullWidthEventCard
                    dataTestId={`${dataTestId}-${event.slug}`}
                    event={event}
                    currentUser={currentUser}
                    rsvpCallback={(event) => rsvpCallback(event, i)}
                  />
                </div>
              ))}
            {shouldDisplayPostCohortSurvey && isBeforeLastWeek && (
              // NOTE: There are 2 additional scheduled weeks beyond the cohort duration
              //       for providing schedules for members for one week pre-cohort
              //       and one week post-cohort
              <SurveyActionCard
                currentUser={currentUser}
                userCohort={userCohort}
                title="Share (optional) feedback with the Reforge team"
              />
            )}
            {shouldDisplayPostCohortSurvey && isLastWeek && (
              <SurveyActionCard currentUser={currentUser} userCohort={userCohort} />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const SurveyActionCard = ({
  currentUser,
  title,
  userCohort
}: {
  currentUser: CurrentUserPartsFragment
  userCohort: UserCohort
  title?: string
}) => {
  const hasCompleted =
    window.localStorage.getItem(getNPSCompletionFlag(userCohort.cohort.season)) === '1'
  return (
    <div className="mb-3">
      <SimpleCardWithAction
        title={title || 'Share feedback with the Reforge team'}
        description="Reflect on your program experience and share feedback"
        linkText={hasCompleted ? 'View' : 'Begin'}
        linkAction={() => {
          window.localStorage.setItem(
            getNPSBannerDismissalFlag(userCohort.cohort.season),
            '1'
          )
          window.localStorage.setItem(getNPSCompletionFlag(userCohort.cohort.season), '1')
          window.open(
            `${userCohort.cohort.season.npsSurveyLink}?program=${userCohort.cohort.cmsProgram.name}&pid=${currentUser.id}`
          )
          window.location.reload()
        }}
        icon={
          <div className="h-8 w-8">
            <ProgressCircle percentComplete={hasCompleted ? 100 : 0} />
          </div>
        }
        disableActionButton={false}
        minHeight="110px"
        buttonVariant="outline"
      />
    </div>
  )
}

export default WeekBlock
