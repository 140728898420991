import { getUnixTime } from 'date-fns'
import { useState } from 'react'
import DatePicker from 'react-datepicker'

import Button from 'components/Button'
import Modal, {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle
} from 'components/Modal'

import { useExternalStylesheet } from 'hooks/useExternalStylesheet'

import {
  FULL_DATE_AND_TIME_ABBREV_MONTH_FORMAT,
  formatInTimezone,
  getTimezoneAbbreviation
} from 'utils/date'
import getCsrfToken from 'utils/getCsrfToken'

const csrfToken = getCsrfToken()

const TimeMachine = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [timeTravelModalOpen, setTimeTravelModalOpen] = useState(false)
  const [startDate, setStartDate] = useState(new Date())

  const userTimezone = new window.Intl.DateTimeFormat().resolvedOptions().timeZone
  useExternalStylesheet(
    'https://cdnjs.cloudflare.com/ajax/libs/react-datepicker/5.0.0/react-datepicker.css'
  )

  const travelToPresent = async () => {
    await window.fetch('/time_machine/reset', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken
      }
    })

    window.location.reload()
  }

  const timeTravelTo = async () => {
    await window.fetch('/time_machine/travel_to', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken
      },
      body: JSON.stringify({
        timestamp: getUnixTime(startDate)
      })
    })

    window.location.reload()
  }

  const getFullTime = () => {
    const now = new Date()

    return `${formatInTimezone(
      now,
      userTimezone,
      FULL_DATE_AND_TIME_ABBREV_MONTH_FORMAT
    )} ${getTimezoneAbbreviation(userTimezone, now)}`
  }

  const buttonContent = isExpanded ? (
    <div data-time={Math.floor(Date.now() / 1000)}>
      <div>Time Travel</div>
      <div className="text-xs">{getFullTime()}</div>
    </div>
  ) : (
    <span data-time={Math.floor(Date.now() / 1000)}>📆</span>
  )

  return (
    <>
      <div
        className="fixed bottom-5 right-3 z-50"
        onMouseEnter={() => setIsExpanded(true)}
        onMouseLeave={() => setIsExpanded(false)}
      >
        <Button onClick={() => setTimeTravelModalOpen(true)} className="p-2">
          {buttonContent}
        </Button>
      </div>
      <Modal
        className="z-50"
        isOpen={timeTravelModalOpen}
        fullWidth={false}
        handleClose={() => setTimeTravelModalOpen(false)}
      >
        <ModalHeader>
          <ModalTitle>Time Machine</ModalTitle>
        </ModalHeader>

        <ModalContent>
          <div className="pt-2 [&>.react-datepicker-wrapper]:block">
            <div className="pb-4">
              Time is currently frozen to{' '}
              <span className="font-medium">{getFullTime()}</span>
            </div>
            <div className="mb-2.5 block text-lg font-medium leading-5">
              Select a date and time (You are in <em>{userTimezone}</em> time zone)
            </div>
            <DatePicker
              className="mb-2 inline-block w-full rounded-sm border border-rb-gray-100 p-px px-4 py-4 text-rb-gray-300 focus-within:border-rb-blue-100 focus-within:shadow-input hover:border-rb-blue-100"
              selected={startDate}
              onChange={(date: any) => setStartDate(date)}
              dateFormat={FULL_DATE_AND_TIME_ABBREV_MONTH_FORMAT}
              timeCaption="time"
              showTimeSelect
            />
          </div>
        </ModalContent>
        <ModalFooter className="justify-between">
          <Button className="mr-5" size="small" onClick={travelToPresent}>
            Reset
          </Button>
          <Button size="small" onClick={timeTravelTo}>
            Travel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default TimeMachine
