export const OTHER_OPTION = 'Other'

export const COMPANY_SIZES = [
  '1 - 9',
  '10 - 49',
  '50 - 249',
  '250 - 999',
  '1,000 - 4,999',
  '5,000 - 9,999',
  '10,000 - 49,999',
  '50,000 - 99,999',
  '100K +'
]

export const HOW_YOU_HEARD_ABOUT_US = [
  'Social media',
  'Online search',
  'Friend or colleague',
  'Podcast or radio',
  'Reforge contacted me',
  'Other'
]

export const JOB_FUNCTIONS = [
  'Business / Operations',
  'Data',
  'Design',
  'Engineering',
  'Executive',
  'Founder',
  'Learning / Development',
  'Marketing',
  'Product',
  'Sales / Partnerships',
  OTHER_OPTION
]

export const JOB_LEVELS = [
  'New to Role',
  'Junior Individual Contributor',
  'Senior Individual Contributor',
  'People Manager',
  'Functional Leader',
  'Executive',
  'Founder',
  OTHER_OPTION
]
