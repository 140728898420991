import React, { useState } from 'react'

interface ICharacterCounter {
  count?: number
  max: number
}

const CharacterCounter = ({ count, max }: ICharacterCounter) => (
  <div className="text-right text-xs text-rb-gray-300">
    {count} / {max} characters
  </div>
)

interface IWithCharacterCounter extends ICharacterCounter {
  defaultValue?: string
  onChange?: (event: any) => void
  value?: string
}

const withCharacterCounter = <P extends object>(
  Element: React.ComponentType<P>
): React.FC<P & ICharacterCounter> => {
  const WithCharacterCounter = ({
    defaultValue,
    max,
    value,
    ...props
  }: IWithCharacterCounter) => {
    const initialCharacterCount = (value || defaultValue || '').length

    const [characterCount, setCharacterCount] = useState<number>(initialCharacterCount)

    return (
      <>
        <Element
          {...(props as P)}
          onChange={(event: any) => {
            setCharacterCount(Number(event.target.value.length))

            // Call user-provided onChange if provided.
            props.onChange?.(event)
          }}
        />
        <CharacterCounter count={characterCount} max={max} />
      </>
    )
  }

  return WithCharacterCounter
}

export default withCharacterCounter
