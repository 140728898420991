import emojiData from '@emoji-mart/data'
import EmojiPicker from '@emoji-mart/react'
import { init } from 'emoji-mart'
import { useRef } from 'react'

import { ArtifactCommentReactionGrouped } from 'gql'

import ButtonAddReaction from './ButtonAddReaction'
import ButtonEmoji from './ButtonEmoji'
import PopoverCustom from './PopoverCustom'

if (process.env.NODE_ENV !== 'test') {
  init({ emojiData })
}

const ReactionBar = ({
  id = '',
  reactions = [],
  onReactionAdded = () => {},
  onReactionRemoved = () => {}
}: {
  id?: string
  reactions?: ArtifactCommentReactionGrouped[]
  onReactionAdded?: (reaction: string) => void
  onReactionRemoved?: (reaction: string) => void
}) => {
  const addReactionButtonRef = useRef<any>(null)

  const handleEmojiSelected = (selected: { id: string }, closePicker = true) => {
    const userAlreadySelectedReaction = reactions.find(
      (reaction) => reaction.name === selected.id && reaction.currentUserHasReacted
    )

    if (userAlreadySelectedReaction) {
      onReactionRemoved(selected.id)
    } else {
      onReactionAdded(selected.id)
    }

    if (closePicker) {
      addReactionButtonRef.current?.click()
    }
  }

  const handleEmojiClick = (e: any) => {
    const emojiId = e.currentTarget?.id
    handleEmojiSelected({ id: emojiId }, false)
  }

  return (
    <div className="flex flex-row">
      <PopoverCustom
        triggerElement={
          <ButtonAddReaction id={`${id}-add-button`} ref={addReactionButtonRef} />
        }
      >
        <div className="my-10">
          <EmojiPicker
            data={emojiData}
            onEmojiSelect={handleEmojiSelected}
            emojiSize={20}
            skinTonePosition="none"
            maxFrequentRows={1}
          />
        </div>
      </PopoverCustom>
      <div className="flex flex-wrap gap-y-[5px]">
        {reactions.map((reaction) => {
          return (
            <ButtonEmoji
              count={reaction.count}
              emojiId={reaction.name}
              key={`${id}-${reaction.name}`}
              onClick={handleEmojiClick}
              selected={reaction.currentUserHasReacted}
            />
          )
        })}
      </div>
    </div>
  )
}

export default ReactionBar
