import { useHistory, useLocation } from 'react-router-dom'

import { ArtifactSubtopic, ArtifactTopicFilter } from 'gql'

import { ARTIFACTS_INDEX_MODES } from './constants'
import { ArtifactsIndexMode } from './types'

export function getArtifactIndexModeFromLocation(
  location: ReturnType<typeof useLocation>
): ArtifactsIndexMode {
  const pathnameWithSearch = location.pathname + location.search

  switch (true) {
    case pathnameWithSearch.includes('/artifacts?topics='):
      return ARTIFACTS_INDEX_MODES.INDEX_WITH_TOPIC

    case pathnameWithSearch.includes('/artifacts?subtopics='):
      return ARTIFACTS_INDEX_MODES.INDEX_WITH_SUBTOPIC

    case pathnameWithSearch.includes('/artifacts?for-you'):
      return ARTIFACTS_INDEX_MODES.FOR_YOU

    case pathnameWithSearch.includes('/artifacts?recently-viewed'):
      return ARTIFACTS_INDEX_MODES.RECENTLY_VIEWED

    default:
      return ARTIFACTS_INDEX_MODES.INDEX
  }
}

export const sortMapping: Record<string, string> = {
  'default': 'date added',
  'most-popular': 'most popular'
}

export const topicMapping: Record<string, string> = {
  'Topics': 'topics',
  'Subtopics': 'subtopics',
  'Functions': 'functions',
  'Company size': 'company_size',
  'Company stage': 'company_stage',
  'Business model': 'business_model'
}

export function removeLastIdParam(history: ReturnType<typeof useHistory>) {
  const searchParams = new URLSearchParams(location.search)

  if (searchParams.has('last-id')) {
    searchParams.delete('last-id')

    history.replace({
      pathname: location.pathname,
      search: searchParams.toString()
    })
  }
}

const shouldConvertIdsToTitles = (trackingKey: string) => {
  return ['topic', 'subtopic', 'function'].includes(trackingKey)
}

const filterTypeToTrackingKeyMap: Record<string, string> = {
  topicIds: 'topic',
  subtopicIds: 'subtopic',
  functionIds: 'function',
  companySize: 'company_size',
  companyStage: 'company_stage',
  businessModel: 'business_model'
}

const allowedFilterTypes = [
  'functionIds',
  'topicIds',
  'subtopicIds',
  'companySize',
  'companyStage',
  'businessModel'
]

export const getSanityIdToFilterTitleMapHelper = (
  topicList: ArtifactTopicFilter[],
  functionList: ArtifactTopicFilter[],
  subtopicList: ArtifactSubtopic[]
): { [key: string]: string } => {
  const sanityIdToFilterTitleMap: { [key: string]: string } = {}

  const allSanityFilters = [...topicList, ...functionList, ...subtopicList]

  allSanityFilters.forEach((sanityFilter) => {
    if (!sanityFilter.id) {
      return
    }
    sanityIdToFilterTitleMap[sanityFilter.id] = sanityFilter.title.toLowerCase()
  })

  return sanityIdToFilterTitleMap
}

export const getSanityFilterMap = (
  topicList: ArtifactTopicFilter[],
  functionList: ArtifactTopicFilter[],
  subtopicList: ArtifactSubtopic[]
) => {
  return new Map([
    ['topicIds', new Map(topicList?.map((topic) => [topic.slug, topic.id]))],
    ['functionIds', new Map(functionList?.map((func) => [func.slug, func.id]))],
    ['subtopicIds', new Map(subtopicList.map((subtopic) => [subtopic.slug, subtopic.id]))]
  ])
}
export const getFilterTrackingValues = (
  filters: any,
  topicList: ArtifactTopicFilter[],
  functionList: ArtifactTopicFilter[],
  subtopicList: ArtifactSubtopic[]
) => {
  const filterTypes = Object.keys(filters)
  return filterTypes
    .filter((filterType) => allowedFilterTypes.includes(filterType))
    .reduce((result: any, filterType: string) => {
      const trackingKey: string = filterTypeToTrackingKeyMap[filterType]

      if (shouldConvertIdsToTitles(trackingKey)) {
        const sanityIdToFilterTitleMap = getSanityIdToFilterTitleMapHelper(
          topicList,
          functionList,
          subtopicList
        )
        const listOfTitles = filters[filterType].map(
          (id: string) => sanityIdToFilterTitleMap[id]
        )
        result[trackingKey] = listOfTitles
      } else {
        result[trackingKey] = filters[filterType]
      }
      return result
    }, {})
}
