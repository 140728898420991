import { ReactComponent as InfoIcon } from 'icon--info.svg'
import { useEffect, useState } from 'react'
import { Link, Redirect, useParams } from 'react-router-dom'

import Bookmarks from 'domains/Bookmarks/Bookmarks'
import { LOCATION_LOCAL_SAVED_ITEMS } from 'domains/Bookmarks/utils'
import AboutProgramModal from 'domains/Program/AboutProgramModal'
import ProgramCourseOnDemandBanner from 'domains/Program/ProgramCourseOnDemandBanner'
import ProgramNotificationModal from 'domains/Program/ProgramNotificationModal'
import ProgramPageContent from 'domains/Program/ProgramPageContent/ProgramPageContent'

import { ErrorMessage, Loading } from 'components'
import CardTooltip from 'components/CardTooltip'
import { FacePile, FacePileContainer } from 'components/FacePile'
import Popover from 'components/Popover/Popover'
import ProgramPageAboutSection from 'components/ProgramPageAboutSection/ProgramPageAboutSection'
import { Tag } from 'components/Tag'
import SkinnyInfoIcon from 'components/icons/SkinnyInfoIcon'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import {
  useProgramSummaryQuery,
  useTrackServerEventMutation,
  useUserProgramTeammatesQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import { CONTENT_MODE_ON_DEMAND } from 'utils/contentTrackingUtils'
import { trackModalDismissed, trackModalDisplayed } from 'utils/tracking/generated/events'
import { getAnonymousId } from 'utils/tracking/segment'

enum ProgramTabs {
  Material = 'Material',
  Highlights = 'Highlights'
}

const MarketingStrategy2020Badge = () => (
  <Popover
    offsetBottom="bottom-[-162px]"
    cta={
      <div className="px-2">
        <Link
          target="_blank"
          rel="noreferrer"
          to="/faq#-what-are-the-updates-to-marketing-strategy-should-i-take-it-again-"
          className="text-sm font-medium uppercase tracking-wider text-white hover:text-rb-green-75 hover:no-underline"
        >
          Learn more
        </Link>
      </div>
    }
    text="You're viewing the 2020 Edition of Marketing Strategy. We've revamped this content and included it in Growth Marketing."
    zIndex="z-[981]" // 1 above the header value.
  >
    <Tag
      variant="gray"
      text="2020 Edition"
      className="ml-4 cursor-pointer"
      contentAfter={
        <SkinnyInfoIcon className="ml-1.5 h-3.5 w-3.5 fill-current stroke-0" />
      }
    />
  </Popover>
)

const ProgramPage = () => {
  const { programSlug } = useParams<{ programSlug: string }>()
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
  const [trackServerEvent] = useTrackServerEventMutation()

  const { currentUser, currentUserLoading, currentUserError } = useCurrentUser()

  const PROGRAM_TABS = [ProgramTabs.Material, ProgramTabs.Highlights]

  const [programTab, setProgramTab] = useState<ProgramTabs>(ProgramTabs.Material)

  const {
    loading: programSummaryLoading,
    error: programSummaryError,
    data: programSummaryData
  } = useProgramSummaryQuery({
    variables: {
      slug: programSlug
    }
  })

  const {
    loading: userProgramProgressLoading,
    error: userProgramProgressError,
    data: userProgramProgressData
  } = useUserProgramTeammatesQuery({
    variables: {
      slug: programSlug
    }
  })

  const loading =
    currentUserLoading || programSummaryLoading || userProgramProgressLoading
  const error = currentUserError || programSummaryError || userProgramProgressError

  useEffect(() => {
    if (!programSummaryData?.cmsProgram) {
      return
    }

    trackServerEvent({
      variables: {
        input: {
          event: 'Course Dashboard Viewed - Server',
          anonymousId: getAnonymousId(),
          properties: {
            user_id: currentUser?.id,
            path: window.location.pathname,
            access_policy_kind: currentUser?.accessPolicyKind,
            content_type: 'program',
            ccl_course_id: programSummaryData?.cmsProgram.cclCourse?.id,
            ccl_course_static_id: programSummaryData?.cmsProgram.cclCourse?.staticId,
            course_id: programSummaryData.cmsProgram.cclCourse?.legacyCourseId,
            cms_program_id: !!programSummaryData?.cmsProgram.cclCourse,
            program_title: programSummaryData.cmsProgram.name,
            is_on_demand: true,
            related_identifiers: {
              cms_program_id: programSummaryData?.cmsProgram.id,
              content_mode: CONTENT_MODE_ON_DEMAND
            },
            trial_status: currentUser?.trialStatus
          }
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programSummaryData?.cmsProgram?.id])

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <ErrorMessage error={error} />
  }

  if (!programSummaryData?.cmsProgram) {
    return <Redirect to="/courses" />
  }

  if (!currentUser || !currentUser.can.viewProgramsDashboard || !programSummaryData) {
    return <Redirect to="/" />
  }

  if (!currentUser.is.paidMember && !currentUser.is.planManager) {
    return <Redirect to={`/programs/${programSlug}/preview`} />
  }

  const { cmsProgram } = programSummaryData

  if (!cmsProgram) {
    return <ErrorMessage error="Program details failed to load" />
  }

  const isDeprecatedProgramMarketingStrategy =
    cmsProgram.code === 'mcms' &&
    cmsProgram?.deprecatedAt !== null &&
    cmsProgram?.deprecatedAt !== undefined
  const programImageUrl = cmsProgram.iconImageUrl
    ? `url('${cmsProgram.iconImageUrl}')`
    : ''

  const hasStarted = cmsProgram.progressPercent > 0
  const hasCompleted = cmsProgram.progressPercent === 100

  const teammates = userProgramProgressData?.currentUser?.programTeammates || []

  const handleAboutModalClick = (setOpen: boolean) => {
    if (setOpen) {
      trackModalDisplayed({
        category: 'app',
        location: 'program_page',
        modal_group: 'async program',
        modal_name: 'about program',
        related_identifiers: {
          cms_program_id: cmsProgram.id,
          cms_program_name: cmsProgram.name
        }
      })
    } else {
      trackModalDismissed({
        category: 'app',
        location: 'program_page',
        modal_group: 'async program',
        modal_name: 'about program',
        related_identifiers: {
          cms_program_id: cmsProgram.id,
          cms_program_name: cmsProgram.name
        }
      })
    }
    setIsAboutModalOpen(setOpen)
  }

  return (
    <div className="pb-32">
      <AboutProgramModal
        isOpen={isAboutModalOpen}
        handleClose={() => handleAboutModalClick(false)}
        program={cmsProgram}
        isMobile={!isMobile}
      />
      <div className="uk-modal-container">
        {cmsProgram.programNotification && (
          <ProgramNotificationModal
            notificationName={cmsProgram.programNotification.notificationName}
            title={cmsProgram.programNotification.title}
            message={cmsProgram.programNotification.message}
            imageUrl={cmsProgram.programNotification.imageUrl}
          />
        )}
      </div>
      <div id="sticky-top" className="sticky top-0 z-20 min-h-60 bg-white">
        {cmsProgram.cclCourse?.onDemand && cmsProgram.cclCourse?.slug && (
          <ProgramCourseOnDemandBanner
            cmsProgramId={cmsProgram.id}
            cclCourse={cmsProgram.cclCourse}
          />
        )}
        <div className="pt-2.5 sm:pt-3.5 tl:pt-5">
          <div
            className="dashboard-header__center-container uk-grid-collapse pb-2.5 sm:pb-3.5 tl:pb-5"
            uk-grid=""
          >
            <div
              className="-ml-1 flex h-[34px] flex-1 flex-col justify-center bg-contain bg-no-repeat pl-10 sm:h-[52px] sm:pl-16"
              style={{ backgroundImage: programImageUrl }}
            >
              <div className="h-[14px] text-xs font-medium leading-[14px] tracking-widest text-rb-gray-300">
                COURSE
              </div>
              <div className="flex items-center truncate text-lg font-medium sm:overflow-visible sm:whitespace-normal xl:text-2xl">
                {cmsProgram.name}
                {isDeprecatedProgramMarketingStrategy && <MarketingStrategy2020Badge />}
              </div>
            </div>
          </div>

          <hr className="my-0" />

          <div className={isMobile ? 'mt-4 flex h-[88px]' : 'flex h-[88px]'}>
            <div className="flex flex-grow items-center">
              {PROGRAM_TABS.map((tab) => (
                <span
                  key={`topic_${tab}`}
                  role="button"
                  onKeyUp={() => setProgramTab(tab)}
                  tabIndex={0}
                  className={`mr-4 h-8 cursor-pointer border-b-2 text-base font-medium sm:mr-6 ${
                    tab === programTab
                      ? 'border-b-2 border-rb-black text-rb-black'
                      : 'border-transparent text-rb-gray-300 hover:text-rb-black'
                  }`}
                  onClick={() => setProgramTab(tab)}
                >
                  {tab}
                </span>
              ))}
              {hasStarted && !isMobile && (
                <div className="h-8 items-center">
                  <button
                    onClick={() => handleAboutModalClick(true)}
                    className="${ flex h-8 cursor-pointer flex-row items-start text-base font-medium text-gray-500 hover:text-rb-black"
                  >
                    <InfoIcon width={16} height={16} className="pt-1" />
                    <span className="pl-2">About course</span>
                  </button>
                </div>
              )}
            </div>
            {hasStarted && !isMobile && (
              <div className="px-4 py-8">
                <Tag
                  variant={hasCompleted ? 'green' : 'light'}
                  text={hasCompleted ? 'Completed' : 'In progress'}
                  className={
                    hasCompleted
                      ? 'bg-rb-green-75 align-middle font-semibold'
                      : 'align-middle font-semibold text-rb-blue-200'
                  }
                />
              </div>
            )}
            {teammates.length > 1 && (
              <CardTooltip
                className="left-2.5 flex w-[175px] p-1 text-xs"
                content={`${teammates.length - 1}
                  of your teammates have also made progress on this course!`}
                useRelPos
                yOffset={'-45px'}
                xOffset={teammates.length <= 2 ? '-15px' : ''}
              >
                <div className="py-4">
                  <FacePileContainer>
                    <FacePile
                      users={teammates
                        .filter((teammate) => teammate.id !== currentUser.id)
                        .slice(0, 4)}
                      imageSize={isMobile ? 'xlarge' : 'xxlarge'}
                      className={`${isMobile ? 'h-8 w-8' : ''}`}
                    />
                    <div className="pl-2 align-middle text-sm">
                      {teammates.length - 1}
                    </div>
                  </FacePileContainer>
                </div>
              </CardTooltip>
            )}
          </div>
          {hasStarted && isMobile && (
            <div className="mt-4 border-t-[1px]">
              <button
                onClick={() => handleAboutModalClick(true)}
                className="flex cursor-pointer flex-row items-start py-4 text-base font-medium text-gray-500 hover:text-rb-black"
              >
                <InfoIcon width={16} height={16} className="pt-1" />
                <span className="pl-2">About course</span>
              </button>
            </div>
          )}
        </div>
      </div>
      {programTab === ProgramTabs.Material && (
        <div>
          {!userProgramProgressData?.currentUser?.programTeammates.filter(
            (teammate) => teammate.id === currentUser.id
          ).length && (
            <div className="px-4 pb-8">
              <ProgramPageAboutSection program={cmsProgram} />
            </div>
          )}
          <ProgramPageContent cmsProgramId={cmsProgram.id} />
        </div>
      )}
      {programTab === ProgramTabs.Highlights && (
        <div className="px-4 lg:px-8">
          <Bookmarks
            trackingLocation={LOCATION_LOCAL_SAVED_ITEMS}
            cmsProgramId={cmsProgram.id}
            programName={cmsProgram.name}
          />
        </div>
      )}
    </div>
  )
}

export default ProgramPage
