import { micromark } from 'micromark'
import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'

import { SVGIcon } from 'components/Icon'
import { Toast, displayToast } from 'components/Toast'
import Dropdown from 'components/forms/Dropdown'

import { trackCtaClicked } from 'utils/tracking/analytics'

import { templates as defaultTemplates } from '../EmailPitchTemplates'

const DEFAULT_SELECTED_TEMPLATE_TITLE = 'General Membership'

interface ReimbursementTemplateSelectorProps {
  selectedTemplateId: string
  setSelectedTemplateId: (templateId: string) => void
  options: { id: string; label: string }[]
}

const ReimbursementTemplateSelector = ({
  selectedTemplateId,
  setSelectedTemplateId,
  options
}: ReimbursementTemplateSelectorProps) => {
  return (
    <div className="w-64">
      <Dropdown
        placeholder="Select a template"
        selection={selectedTemplateId}
        setSelection={setSelectedTemplateId}
        options={options}
      />
    </div>
  )
}

interface HeadingAndCaptionProps {
  heading: string
  caption: string | React.ReactNode
}

const HeadingAndCaption = ({ heading, caption }: HeadingAndCaptionProps) => (
  <header className="rb-gray-400 flex flex-col">
    <h2 className="font-semibold lg:text-xl">{heading}</h2>
    <div className="rb-gray-400">{caption}</div>
  </header>
)

const copyRichText = async (content: string) => {
  const blob = new Blob([content], { type: 'text/html' })
  const richTextInput = new window.ClipboardItem({ 'text/html': Promise.resolve(blob) })
  await navigator.clipboard.write([richTextInput])
}

interface CopyButtonProps {
  templateTitle: string
  textToCopy: string
}

const CopyButton = ({ templateTitle, textToCopy }: CopyButtonProps) => {
  return (
    <button
      data-test="copy-button"
      onClick={async () => {
        await copyRichText(textToCopy)
        displayToast('Copied template to clipboard')
        trackCtaClicked({
          cta_type: 'button',
          cta_location: 'email_template_section',
          text: `COPY - ${templateTitle}`
        })
      }}
      className="flex flex-row place-items-center gap-x-2"
    >
      <SVGIcon className="ml-8 sm:ml-0" name="copy" />
      <span className="text-sm font-semibold">COPY</span>
    </button>
  )
}

interface Template {
  title: string
  markdown: string
}

interface ReimbursementPitchTemplatesProps {
  templates?: Template[]
}

const ReimbursementPitchTemplates = ({
  templates = defaultTemplates
}: ReimbursementPitchTemplatesProps) => {
  const defaultTemplateName = templates.find(
    ({ title }: Template) => title === DEFAULT_SELECTED_TEMPLATE_TITLE
  )?.title

  const [selectedTemplateId, setSelectedTemplateId] = useState(
    defaultTemplateName || templates[0].title
  )

  return (
    <div data-test="pitch-templates" className="flex flex-col gap-y-8">
      <HeadingAndCaption
        heading="Pitching Your Reforge Membership"
        caption="Communicate the value of Reforge to your manager using the customizable email templates below. Copy the doc and edit from there!"
      />
      <div className="flex flex-col gap-y-2">
        <div className="font-medium">Templates</div>
        <div className="flex flex-col justify-between gap-y-10 sm:flex-row sm:items-center sm:gap-y-0">
          <ReimbursementTemplateSelector
            selectedTemplateId={selectedTemplateId}
            setSelectedTemplateId={setSelectedTemplateId}
            options={templates.map(({ title }) => ({ id: title, label: title }))}
          />
          <div className="mr-4">
            <Toast />
            <CopyButton
              templateTitle={selectedTemplateId}
              textToCopy={micromark(templates[0].markdown)}
            />
          </div>
        </div>
      </div>
      <ReactMarkdown className="container h-[560px] overflow-auto overflow-x-scroll border border-gray-100 p-6 shadow-default sm:overflow-x-auto sm:p-8 lg:p-11">
        {templates.find(({ title }) => title === selectedTemplateId)?.markdown || ''}
      </ReactMarkdown>
    </div>
  )
}

export { ReimbursementPitchTemplates }
