import SimpleModal from 'components/SimpleModal'

import { PLAN_OPTIONS } from 'constants/plans'

import { PlanName } from 'gql'

interface RestartPlanNowModalProps {
  planName: PlanName
  onRestartOnRenewalClick: () => void
  onRestartNowClick: () => void
}

export default function RestartPlanNowModal({
  planName,
  onRestartOnRenewalClick,
  onRestartNowClick
}: RestartPlanNowModalProps) {
  const plan = PLAN_OPTIONS.find((option) => option.name === planName)

  return (
    <SimpleModal
      renderHeaderText={() => 'Please confirm your selection'}
      renderBodyText={() => (
        <div>
          <div>
            Confirm your subscription to {plan?.title} and you will immediately update to
            the new plan with prorated fees. You can also choose to delay these changes
            until your renewal date.
          </div>

          <div className="pt-[10px] text-sm">
            <button className="link" onClick={onRestartOnRenewalClick}>
              Restart at renewal --{'>'}
            </button>
          </div>
        </div>
      )}
      renderFooterActionButtons={() => [
        {
          size: 'small',
          className: 'w-[200px]',
          onClick: onRestartNowClick,
          children: 'Restart Now'
        }
      ]}
    />
  )
}
