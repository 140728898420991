import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { PageProvider } from 'components/PageHeader/usePage'

import { PageSeo, PageTypeEnum } from 'gql'

import GuidesPage from './GuidesPage'

const LoggedOutGuidesIndexPage = () => {
  const page: GenericPageType = {
    title: 'Guides – Reforge',
    seo: {
      metaTitle: 'Guides – Reforge',
      metaDesc:
        'Your shortcut to getting work done. Step-by-step guides you can finish in 30 minutes or less give you a head start with the knowledge you need.',
      type: 'website' as PageTypeEnum,
      shareTitle: 'Guides – Reforge',
      shareDesc:
        'Your shortcut to getting work done. Step-by-step guides you can finish in 30 minutes or less give you a head start with the knowledge you need.',
      shareGraphic: {
        asset:
          'https://cdn.sanity.io/images/pghoxh0e/production/b9dae5a6f390c763b0415f33fcd9150df59d30f3-2240x1120.jpg?format=webp'
      },
      shareGraphicAlt: 'Reforge Guides'
    } as PageSeo
  }

  return (
    <MarketingLayout page={page}>
      <PageProvider
        showHeader
        headerWrapperClassName="px-4 md:px-[4vw] pt-4 mx-auto max-w-[calc(1280px+8vw)]"
      >
        <div className="px-4 pb-8 md:px-[4vw]">
          <div className="mx-auto max-w-[1280px]">
            <GuidesPage />
          </div>
        </div>
      </PageProvider>
    </MarketingLayout>
  )
}

export default LoggedOutGuidesIndexPage
