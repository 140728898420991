import { useEffect, useRef, useState } from 'react'

import Button from 'components/Button'

import { UserNotificationPreferences, useUpdatePreferencesMutation } from 'gql'

interface PreferenceFormProps {
  preference: UserNotificationPreferences
}

const PreferenceForm = ({ preference }: PreferenceFormProps) => {
  const [mentionEmail, setMentionEmail] = useState(preference.mentionEmail)
  const [repliesActivity, setRepliesActivity] = useState(preference.repliesActivity)
  const [commentsActivity, setCommentsActivity] = useState(preference.commentsActivity)
  const [postsActivity, setPostsActivity] = useState(preference.postsActivity)
  const [updatePreferencesMutation, { error }] = useUpdatePreferencesMutation()
  const [success, setSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [saveEnabled, setSaveEnabled] = useState(false)
  const firstRender = useRef(true)

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    setSuccess(false)
    setSaveEnabled(true)
    setErrorMessage(null)
  }, [mentionEmail, repliesActivity, commentsActivity, postsActivity])

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message)
    }
  }, [error])

  const submitForm = async () => {
    try {
      await updatePreferencesMutation({
        variables: {
          input: {
            mentionEmail,
            repliesActivity,
            commentsActivity,
            postsActivity
          }
        }
      })

      setSuccess(true)
      setErrorMessage(null)
      setSaveEnabled(false)
    } catch (e) {
      setSuccess(false)
    }
  }

  const thClassName =
    'tl:!py-[7px] !py-1 px-0 !text-xs !font-medium !text-rb-gray-300 !tracking-widest'
  const tdClassName = 'tl:!py-[7px] !py-1 px-0'

  return (
    <div
      id="email_preferences"
      className="email-preferences rf-rb-card mb-5 bg-white p-6"
    >
      <h3 className="text-xl font-medium text-black">Email Preferences</h3>

      <table className="uk-table uk-table-small uk-text-left uk-table-middle uk-table-divider text-[13px] text-rb-gray-500 tl:text-sm">
        <thead>
          <tr>
            <th className={thClassName}>NOTIFICATION</th>
            <th className={`${thClassName} email-preferences__second-col`}>FREQUENCY</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={tdClassName}>When I am mentioned in Discussions</td>
            <td className={tdClassName}>
              <span>
                <select
                  className="w-100 uk-select uk-form-small text-m-small sm:text-m-medium"
                  value={mentionEmail}
                  onChange={(e) => setMentionEmail(e.target.value)}
                >
                  <option value="hourly">Hourly</option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="never">Never</option>
                </select>
              </span>
            </td>
          </tr>
          <tr>
            <td className={tdClassName}>On activity on a post that I make</td>
            <td className={tdClassName}>
              <span>
                <select
                  className="w-100 uk-select uk-form-small text-m-small sm:text-m-medium"
                  value={postsActivity}
                  onChange={(e) => setPostsActivity(e.target.value)}
                >
                  <option value="hourly">Hourly</option>
                  <option value="daily">Daily</option>
                  <option value="never">Never</option>
                </select>
              </span>
            </td>
          </tr>
          <tr>
            <td className={tdClassName}>On activity on a reply that I make</td>
            <td className={tdClassName}>
              <span>
                <select
                  className="w-100 uk-select uk-form-small text-m-small sm:text-m-medium"
                  value={repliesActivity}
                  onChange={(e) => setRepliesActivity(e.target.value)}
                >
                  <option value="hourly">Hourly</option>
                  <option value="daily">Daily</option>
                  <option value="never">Never</option>
                </select>
              </span>
            </td>
          </tr>
          <tr>
            <td className={tdClassName}>On activity on a comment that I make</td>
            <td className={tdClassName}>
              <span>
                <select
                  className="w-100 uk-select uk-form-small text-m-small sm:text-m-medium"
                  value={commentsActivity}
                  onChange={(e) => setCommentsActivity(e.target.value)}
                >
                  <option value="hourly">Hourly</option>
                  <option value="daily">Daily</option>
                  <option value="never">Never</option>
                </select>
              </span>
            </td>
          </tr>
          <tr>
            <td className={tdClassName}>
              {errorMessage && (
                <div className="uk-alert uk-alert-danger">{errorMessage}</div>
              )}
              {success && <div className="uk-alert uk-alert-success">Saved</div>}
            </td>
            <td className={`${tdClassName} float-right`}>
              <Button
                size="x-small"
                onClick={submitForm}
                disabled={!saveEnabled}
                className="mt-3"
              >
                Save
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default PreferenceForm
