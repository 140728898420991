import React, { useState } from 'react'

import { ChevronDownThin, ChevronUpThinIcon } from 'components/icons'

export const TermsAndConditions = ({
  hasPlan,
  collapsible
}: {
  hasPlan?: boolean
  collapsible?: boolean
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const toggleCollapse = () => setIsCollapsed(!isCollapsed)

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      toggleCollapse()
    }
  }

  return (
    <div className="mt-5 text-sm text-rb-gray-300">
      {collapsible && (
        <div
          role="button"
          aria-expanded={!isCollapsed}
          tabIndex={0}
          onClick={toggleCollapse}
          onKeyDown={onKeyDown}
          className="mb-2 flex cursor-pointer flex-row items-center space-x-2"
        >
          <span>Terms and Conditions</span>

          {isCollapsed ? (
            <ChevronDownThin height="14" width="14" className="fill-current" />
          ) : (
            <ChevronUpThinIcon height="14" width="14" className="fill-current" />
          )}
        </div>
      )}
      {(!collapsible || !isCollapsed) && (
        <ul className="pl-4">
          {hasPlan && (
            <li>Reforge memberships automatically renew 365 days after purchase.</li>
          )}
          <li>
            Live Course Passes must be used within 365 days of today, then your passes
            will expire.
          </li>
          <li>You must have an active membership to use your Live Course Passes.</li>
          <li>
            You may cancel your membership at any time in your account settings; however,
            previous charges will not be refunded, but you may continue to use the service
            until the end of the subscription term.
          </li>
          <li>
            By clicking &quot;Purchase&quot;, you are agreeing to our{' '}
            <a
              className="text-rb-blue hover:text-rb-blue"
              href="https://www.reforge.com/terms-of-service"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>
            , that your subscription will renew on an annual basis, and that your credit
            card will automatically be charged the applicable subscription fee.
          </li>
        </ul>
      )}
    </div>
  )
}
