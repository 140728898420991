import { SearchField } from 'domains/Search/SearchField'

import { cn } from 'utils/tailwind'

const SearchBar = ({ whiteBg }: { whiteBg?: boolean }) => {
  return (
    <div className="-mr-2 xs:mr-0 lg:mr-auto lg:ml-4 lg:pr-4 xl:pr-[10%] w-10 lg:w-full max-w-[calc(430px+10%)]">
      <SearchField
        dropdownClassName="lg:w-[480px] lg:shadow-[0_4px_9px_0_rgba(15,15,15,0.10)] lg:border-none lg:mt-3"
        inputClassName={cn('border-none [&>svg]:text-rb-black', whiteBg && 'bg-white')}
      />
    </div>
  )
}

export default SearchBar
