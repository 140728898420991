import { ReactNode, createContext, useContext } from 'react'

import { CclCourseIdentifierPartsFragment } from 'gql'

interface CourseTrackingIdentifiersType {
  cclCourse?: CclCourseIdentifierPartsFragment | null
}

interface UserContextProviderProps {
  children: ReactNode
  value: CourseTrackingIdentifiersType
}

export const CourseTrackingIdentifiersContext =
  createContext<CourseTrackingIdentifiersType>({
    cclCourse: null
  })

export function CourseTrackingIdentifiersContextProvider({
  children,
  value
}: UserContextProviderProps) {
  return (
    <CourseTrackingIdentifiersContext.Provider value={value}>
      {children}
    </CourseTrackingIdentifiersContext.Provider>
  )
}

export function useCourseTrackingIdentifiersContext() {
  return useContext(CourseTrackingIdentifiersContext)
}
