import { useEffect } from 'react'

const CareersOpenPositions = ({ pathname }: { pathname: string }) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = `https://boards.greenhouse.io/embed/job_board/js?for=reforge&b=https://www.reforge.com/${pathname}`
    script.async = true
    script.onload = () => {
      const w: Window & {
        Grnhse?: {
          Iframe?: {
            load: () => void
          }
        }
      } = window

      w.Grnhse?.Iframe?.load()
    }
    const el = document.getElementById('grnhse_app')

    el?.parentNode?.insertBefore(script, el.nextSibling)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="relative min-h-[150px] overflow-hidden">
      <div
        id="grnhse_app"
        className={
          pathname === 'careers'
            ? 'mt-[-180px] xs:mt-[-150px] sm:mt-[-110px]'
            : 'mt-[-180px] xs:mt-[-150px]'
        } // This is to hide the title on the iframe
      />
    </div>
  )
}

export default CareersOpenPositions
