import { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'

import MembersList from 'domains/Group/MembersList'
import MobileFilters from 'domains/Program/MobileFilters'

import Filters, { FiltersProp } from 'components/Filters'
import { SortByFilterSet } from 'components/Filters/SortBy'

const filterKeys = ['page', 'sort_by'] // initial state will be set from url variables
const filterOptions: SortByFilterSet[] = [
  {
    key: 'sort_by',
    type: 'sortby',
    options: [
      ['recently_active', 'Recent'],
      ['recent', 'New']
    ]
  }
  // {
  //   key: 'audience',
  //   type: 'checkbox',
  //   title: 'Company Audience',
  //   options: [
  //     ['B2B', 'B2B'],
  //     ['B2C', 'B2C'],
  //     ['B2B2C', 'B2B2C']
  //   ]
  // }
]

interface MembersWrapperProps {
  group: string
}

/* Define the endpoint and filters for the members page. This component maintains the filter state (shares it between the list and the filters) */
const MembersWrapper = (props: MembersWrapperProps) => {
  const [filters, setFilters] = useState<FiltersProp>({})
  const [busy, setBusy] = useState(false)
  const history = useHistory()

  const { group } = props

  return (
    <Fragment>
      <div className="uk-margin-top w-full pl-0 lg:w-3/4">
        <div className="lg:pr-8">
          <MobileFilters />
          <MembersList
            group={group}
            filters={filters}
            setFilters={setFilters}
            setBusy={setBusy}
          />
        </div>
      </div>
      <div className="uk-margin-top hidden pl-0 lg:flex lg:w-1/4">
        <div className="w-full">
          <Filters
            filterKeys={filterKeys}
            filters={filters}
            setFilters={setFilters}
            filterOptions={filterOptions}
            history={history}
            busy={busy}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default MembersWrapper
