import Button from 'components/Button'

import {
  TeamMembersPageDocument,
  TeamMembersPageSubscriptionSeatFragment,
  useUnassignSeatMutation
} from 'gql'

import { displayErrorToast, displaySuccessToast } from 'utils/toastService'

import { ReactComponent as TrashIcon } from 'images/icon--manage-seats-trash.svg'

import { PanelContentProps } from '.'

export interface UnassignSeatPanelContentProps extends PanelContentProps {
  currentUserId: string
  onUnassignCtaClick: () => void
  onActionSuccess: () => void
  seat: TeamMembersPageSubscriptionSeatFragment
  onCancelCtaClick: () => void
}

export const UnassignSeatPanelContent = ({
  currentUserId,
  onUnassignCtaClick,
  onActionSuccess,
  onCancelCtaClick,
  seat
}: UnassignSeatPanelContentProps) => {
  const [unassignSeat, { loading }] = useUnassignSeatMutation()
  const handleSubmit = async () => {
    const result = await unassignSeat({
      variables: {
        input: {
          id: seat.id
        }
      },
      refetchQueries: [
        {
          query: TeamMembersPageDocument,
          variables: { id: currentUserId }
        }
      ]
    })

    if (result.data?.unassignSeat?.errors || !result.data?.unassignSeat?.seat) {
      displayErrorToast({
        message: result.data?.unassignSeat?.errors
          ? result.data.unassignSeat.errors[0]
          : 'Unable to remove member'
      })
    } else {
      displaySuccessToast({ message: "You've successfully removed this member" })
      onActionSuccess()
      onUnassignCtaClick()
    }
  }
  return (
    <>
      <div className="mt-12 mb-6 font-semibold">
        Are you sure you want to remove this member?
      </div>

      <p className="text-rb-destructive-100">
        Once you do, this member will <br />
        immediately lose access to Reforge.
      </p>
      <div className="mb-5">
        <p className="mb-0 font-semibold">How does this affect my subscription?</p>
        <p>
          This seat will be empty but it&apos;ll still be a part of your subscription. You
          can invite a new member to this seat.
        </p>
      </div>
      <Button
        data-test="panel-unassign-seat-button"
        className="mb-4 mt-6 w-full"
        disabled={loading}
        isLoadingSpinner={loading}
        onClick={handleSubmit}
      >
        <TrashIcon className="mr-2 h-4 w-4" />
        Remove member
      </Button>
      <Button className="w-full" variant="outline" onClick={onCancelCtaClick}>
        Cancel
      </Button>
    </>
  )
}
