import { ReactComponent as DiscussionIcon } from 'icon--discussion.svg'
import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import ManageBookmarkButton from 'domains/Collections/ManageBookmarkButton'

import { useProductTour } from 'components/ProductTour'
import { SideDrawer, useSideDrawer } from 'components/SideDrawer'
import HighlightIcon from 'components/icons/HighlightIcon'

import {
  BookmarkType,
  ContentViewerDocument,
  ProductTourKey,
  ProgramBookmarkPartsFragment
} from 'gql'

import { track } from 'utils/tracking/analytics'

import {
  DiscoveryDrawerActionButton as Button,
  DiscoveryDrawerActionButtonProps as ButtonProps,
  DiscoveryDrawerActionButtons as Buttons,
  DiscoveryDrawerActionButtonsProps as ButtonsProps
} from './ActionButtons'
import {
  DiscoveryDrawerBookmarksPanel as BookmarksPanel,
  DiscoveryDrawerBookmarksPanelProps as BookmarksPanelProps
} from './BookmarksPanel/BookmarksPanel'
import {
  DiscoveryDrawerDiscussions as DiscussionsPanel,
  DiscoveryDrawerDiscussionsPanelProps as DiscussionsPanelProps
} from './DiscussionsPanel'

export interface DiscoveryDrawerProps {
  id: string
  activePanel: string
  setActivePanel: (type: string) => void
  contentBookmarkId?: string | null
  isInSavedItems: boolean
  setIsInSavedItems: (isInSavedItems: boolean) => void
  bookmarkType: string
  cmsProgramId: string
  cmsSectionId: string
  cmsModuleId: string
  userId: string
  children: ReactNode
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  isCohortViewer?: boolean
}

const DiscoveryDrawer = ({
  id,
  activePanel,
  setActivePanel,
  bookmarkType,
  contentBookmarkId = '',
  isInSavedItems,
  setIsInSavedItems,
  cmsProgramId,
  cmsSectionId,
  cmsModuleId,
  userId,
  openAddToBookmarkFolderModal,
  children,
  isCohortViewer
}: DiscoveryDrawerProps) => {
  const { closeDrawer, setActiveFilterId } = useSideDrawer()

  const contentBookmark = {
    type: bookmarkType as BookmarkType,
    cmsSectionId,
    cmsProgramId,
    cmsModuleId,
    ...(contentBookmarkId && { id: contentBookmarkId })
  }

  const { portal, completeTour } = useProductTour({
    productTourKey: ProductTourKey.TEAM_COMMENT_VIEW,
    nodeRefOrSelector: '.tour-discovery-drawer-discussions-button',
    title: '💬 See your team’s comments',
    disabled: false,
    description:
      'See past discussions, share ideas, ask questions, or spark new dialogue with your team here.',
    position: 'fixed',
    wait: 2500
  })

  const handleCloseDrawer = () => {
    closeDrawer()
    // @ts-ignore - 'Content Viewer - Action' event is not defined in Segment JIRA#REF-5159
    track('Content Viewer - Action', {
      user_id: userId,
      cms_module_id: cmsModuleId,
      cms_program_id: cmsProgramId,
      cms_section_id: cmsSectionId,
      action: 'dismiss_content_viewer_drawer',
      anchor: 'discovery-panel-close-button',
      location: window.location.pathname
    })
  }

  const handleActionButtonClick = (type: 'discussions' | 'bookmarks') => {
    setActivePanel(type)
    const actionTrigger = {
      discussions: 'click_discussions_content_viewer_drawer_header',
      bookmarks: 'click_bookmark_content_viewer_drawer_header'
    }[type]

    const anchor = {
      discussions: 'discovery-drawer-discussions-tab-button',
      bookmarks: 'discovery-drawer-bookmarks-tab-button'
    }[type]

    if (type === 'discussions' && activePanel === 'discussions') {
      // reset the active filter for an improved UX
      setActiveFilterId('')
    }

    // @ts-ignore - 'Content Viewer - Action' event is not defined in Segment JIRA#REF-5159
    track('Content Viewer - Action', {
      user_id: userId,
      cms_module_id: cmsModuleId,
      cms_program_id: cmsProgramId,
      cms_section_id: cmsSectionId,
      action: 'display_content_viewer_drawer',
      anchor,
      action_trigger: actionTrigger,
      location: window.location.pathname
    })
  }

  const handleDicussionsButtonClick = () => {
    completeTour()
    handleActionButtonClick('discussions')
  }

  return (
    <SideDrawer
      id={id}
      className={twMerge(
        isCohortViewer && 'top-[68px]',
        'right-0 left-auto box-border flex h-screen flex-col border-solid border-rb-gray-100 bg-white'
      )}
      openClasses="tl:border-l-[1px]"
      animate
    >
      {portal}
      <Buttons closeDrawer={handleCloseDrawer}>
        <Button
          isActive={activePanel === 'bookmarks'}
          icon={<HighlightIcon className="h-4 w-4 fill-black" />}
          key="bookmarks"
          id="discovery-drawer-bookmarks-tab-button"
          onClick={() => handleActionButtonClick('bookmarks')}
        />
        <Button
          isActive={activePanel === 'discussions'}
          icon={<DiscussionIcon width={16} height={16} />}
          key="discussions"
          id="discovery-drawer-discussions-tab-button"
          className="tour-discovery-drawer-discussions-button"
          onClick={handleDicussionsButtonClick}
        />
        <ManageBookmarkButton
          contentBookmarkId={contentBookmarkId}
          fetchOptions={{ refetchQueries: [ContentViewerDocument] }}
          openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
          isInSavedItems={isInSavedItems}
          setIsInSavedItems={setIsInSavedItems}
          bookmarkInfo={contentBookmark}
          dropdownPosition="left"
        />
      </Buttons>
      {children}
    </SideDrawer>
  )
}

DiscoveryDrawer.Buttons = function DiscovertDrawerButtons({ children }: ButtonsProps) {
  return <Buttons>{children}</Buttons>
}

DiscoveryDrawer.BookmarksPanel = function DiscoveryBookmarksPanel({
  cmsProgramId,
  cmsModuleId,
  cmsSectionId,
  cmsContent,
  cmsProgramName,
  isActive
}: BookmarksPanelProps) {
  return (
    <BookmarksPanel
      isActive={isActive}
      cmsProgramId={cmsProgramId}
      cmsProgramName={cmsProgramName}
      cmsModuleId={cmsModuleId}
      cmsSectionId={cmsSectionId}
      cmsContent={cmsContent}
    />
  )
}

DiscoveryDrawer.DiscussionsPanel = function DiscoveryDiscussionsPanel({
  cmsProgramId,
  cmsModuleId,
  cmsSectionId,
  userId,
  isTrial,
  isActive
}: DiscussionsPanelProps) {
  return (
    <DiscussionsPanel
      isActive={isActive}
      cmsProgramId={cmsProgramId}
      cmsModuleId={cmsModuleId}
      cmsSectionId={cmsSectionId}
      userId={userId}
      isTrial={isTrial}
    />
  )
}

DiscoveryDrawer.Button = function DiscoveryDrawerButtons({
  icon,
  ...props
}: ButtonProps) {
  return <Button icon={icon} {...props} />
}

export default DiscoveryDrawer
