import React from 'react'

import CreatePostForm, {
  CreatePostFormProps
} from 'domains/CohortConversation/Post/CreatePostForm'
import ReplyPostForm, {
  ReplyPostFormProps
} from 'domains/CohortConversation/Post/ReplyPostForm'

import Modal, { ModalContent, ModalHeader } from 'components/Modal'
import { CloseIcon } from 'components/icons'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'

import { onEnterKeyPress } from 'utils/keyboard'

interface CohortPostOrReplyFormModalProps
  extends ReplyPostFormProps,
    CreatePostFormProps {
  isModalOpen: boolean
  handleModalClose: () => void
  type: 'reply' | 'post'
}

const CohortPostOrReplyFormModal = ({
  isModalOpen,
  handleModalClose,
  refetch,
  post,
  reply,
  postId,
  type
}: CohortPostOrReplyFormModalProps) => {
  return (
    <Modal
      isOpen={isModalOpen}
      handleClose={handleModalClose}
      header={false}
      className="my-0 h-screen max-h-full w-full overflow-hidden md:my-auto md:mx-4 md:h-max md:max-h-[calc(100%-40px)] md:max-w-[658px] md:rounded-2xl"
    >
      <ModalHeader className="py-2 md:px-6">
        <div className="flex items-center justify-between">
          <RfParagraphMediumBold>
            Edit {type === 'post' ? 'post' : 'reply'}
          </RfParagraphMediumBold>

          <div
            tabIndex={0}
            role="button"
            className="hover:bg-default ml-auto cursor-pointer py-3 pl-3 text-xl md:py-4 md:pl-4"
            onClick={handleModalClose}
            onKeyUp={onEnterKeyPress(handleModalClose)}
          >
            <CloseIcon className="h-6 w-6" />
          </div>
        </div>
      </ModalHeader>
      <ModalContent className="overflow-hidden pb-6">
        {type === 'reply' && (
          <ReplyPostForm
            refetch={refetch}
            reply={reply}
            noStyling
            onSaveCallback={handleModalClose}
            postId={postId}
          />
        )}

        {type === 'post' && (
          <CreatePostForm
            refetch={refetch}
            post={post}
            noStyling
            active
            onSaveCallback={handleModalClose}
          />
        )}

        {}
      </ModalContent>
    </Modal>
  )
}

export default CohortPostOrReplyFormModal
