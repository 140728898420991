import { useEffect, useRef, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'

import ActivityList from 'domains/Post/ActivityList'

import { ErrorMessage, Loading } from 'components'
import Filters from 'components/Filters'
import { ListFilterSet } from 'components/Filters/ListFilter'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useScrollToTopOnLocationChange from 'hooks/useScrollToTopOnLocationChange'

import { sendData } from 'utils/sendData'

const filterKeys = ['page', 'filter']
const filterOptions: ListFilterSet[] = [
  {
    key: 'filter',
    type: 'list',
    options: [
      ['', 'All Activity'],
      ['unread', 'Unread'],
      ['onUser', 'My Posts'],
      ['byUser', 'My Activity'],
      ['mentions', 'Mentions'],
      ['following', 'Following']
    ]
  }
]

const PostActivity = () => {
  const [filters, setFilters] = useState(
    {}
  ) /* read by the posts list component set by the filters component. Defaults to url params */
  const [busy, setBusy] = useState(false)
  const isComponentMounted =
    useRef(
      false
    ) /* we want to use createPortal in a child component to inject into this component so we need to know when its mounted. */

  const history = useHistory()

  const { currentUser, currentUserLoading, currentUserError } = useCurrentUser()
  const userCanViewActivity = currentUser?.can?.viewActivity

  useScrollToTopOnLocationChange()

  useEffect(() => {
    if (!isComponentMounted.current) {
      isComponentMounted.current = true
    }
  }, [])

  useEffect(() => {
    // hide the unseen activity counter in the sidebar
    const unseenActivityBadge = document.querySelector(
      '.uk-nav-primary .nav-activity-count'
    )
    if (unseenActivityBadge) {
      unseenActivityBadge.remove()
      sendData('/api/v1/users/touch', 'PATCH', {}, () => {}) // bust sidebar cache so badge doesn't show on next reload
    }
  })

  if (currentUserLoading) {
    return <Loading />
  }

  if (currentUserError) {
    return <ErrorMessage error={currentUserError} />
  }

  if (!userCanViewActivity) {
    return <Redirect to="/" />
  }

  return (
    <>
      <div className="py-0 xs:py-4 md:py-8">
        <div className="ml-0" uk-grid="">
          <div className="uk-margin-top w-full pl-0 lg:w-3/4">
            <div className="lg:pr-8">
              <ActivityList
                filters={filters}
                setFilters={setFilters}
                parentIsMounted={isComponentMounted.current}
                setBusy={setBusy}
              />
            </div>
          </div>
          <div className="uk-margin-top hidden pl-0 lg:flex lg:w-1/4">
            <div className="w-full">
              <Filters
                filterKeys={filterKeys}
                filters={filters}
                setFilters={setFilters}
                history={history}
                filterOptions={filterOptions}
                busy={busy}
              />
              <div id="additional-sidebar-content"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PostActivity
