import { twMerge } from 'tailwind-merge'

export interface SkeletonRfParagraphProps {
  lines?: string | number
  width?: string | number
  className?: string
}

/**
 * SkeletonRfParagraph
 * returns a styled loading skeleton for body text
 * */
export default function SkeletonRfParagraph({
  lines = 1,
  width = 'full',
  className = ''
}: SkeletonRfParagraphProps) {
  return (
    <>
      {[...Array(lines)].map((_, i) => (
        <div
          key={i}
          className={twMerge(
            `mb-4 h-2.5 w-${width} animate-pulse rounded-full bg-rb-gray-100`,
            className
          )}
          data-testid="skeleton-rf-paragraph"
        />
      ))}
    </>
  )
}
