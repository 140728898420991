import { Transition } from '@headlessui/react'
import { ComponentProps, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { scrollIntoView } from 'seamless-scroll-polyfill'
import { twMerge } from 'tailwind-merge'

import { useCommentQueryParamPresentOnLoad } from 'domains/Artifact/hooks/useCommentQueryParamPresentOnLoad'

import Tabs from 'components/Tabs'
import Tab from 'components/Tabs/Tab'

import { DRAWER_PADDING, DRAWER_WIDTH } from 'constants/artifacts'
import { MIN_WIDTH_TAILWIND_LG } from 'constants/breakpoints'

import { useArtifactAnnotationsQuery, useArtifactCommentsQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import { trackNavigationClicked } from 'utils/tracking/analytics'
import { getFullPath, getQueryParamValue, removeQueryParamFromUrl } from 'utils/url'

import { artifactState, useSnapshot } from 'stores'

import ArtifactSidebarLists from './ArtifactSidebarLists'
import NotesContentGate from './NotesContentGate'
import SideDrawer from './SideDrawer'
import {
  type State as SideDrawerState,
  type Event as SideDrawerStateEvent
} from './hooks/useArtifactViewerSideDrawerStateMachine'

interface ArtifactViewerSidebarProps {
  artifact: any
  authorFromArtifact: any
  sideDrawerTab: string
  setSideDrawerTab: (tab: string) => void
  sideDrawerState: SideDrawerState
  sendSideDrawerStateEvent: (event: SideDrawerStateEvent) => void
  activeAnnotation: string | null
  setActiveAnnotation: (annotation: string | null) => void
  sideDrawerOpen: boolean
}

const ArtifactViewerSidebar = ({
  artifact,
  authorFromArtifact,
  sideDrawerState,
  sendSideDrawerStateEvent,
  sideDrawerTab,
  setSideDrawerTab,
  activeAnnotation,
  setActiveAnnotation,
  sideDrawerOpen
}: ArtifactViewerSidebarProps) => {
  const { isLoggedIn } = useCurrentUser()
  const location = useLocation()
  const history = useHistory()

  const { welcomeMode } = useSnapshot(artifactState)

  const isViewportLg = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_LG})`)
  const redirectPath = getFullPath(location)

  // TODO: (stu) move to sidebar where it is needed. Do a separate query for the counts.
  const { data: commentsData, loading: loadingComments } = useArtifactCommentsQuery({
    variables: {
      artifactId: artifact?.id
    },
    skip: !isLoggedIn
  })

  const { data: annotationsData, loading: loadingAnnotations } =
    useArtifactAnnotationsQuery({
      variables: {
        artifactSlug: artifact?.slug
      }
    })

  const sideDrawerStateToTransitionProps = {
    initial: isViewportLg ? sideDrawerTransitionGteLg : sideDrawerTransitionLtLg,
    sideDrawerGteLgOpened: sideDrawerTransitionGteLg,
    sideDrawerLtLgOpening: sideDrawerTransitionLtLg,
    sideDrawerLtLgOpened: sideDrawerTransitionLtLg,
    sideDrawerLtLgClosed: sideDrawerTransitionLtLg,
    sideDrawerLtLgClosing: sideDrawerTransitionLtLg,
    closingAfterLtLg: sideDrawerTransitionGteLg,
    openingAfterGteLg: sideDrawerTransitionGteLg
  }

  const sideDrawerStateToSideDrawerProps = {
    initial: isViewportLg ? { className: DRAWER_WIDTH } : { className: 'w-full' },
    sideDrawerGteLgOpened: { className: DRAWER_WIDTH },
    sideDrawerLtLgOpened: { className: 'w-full' },
    sideDrawerLtLgClosed: { className: 'w-full' },
    closingAfterLtLg: { className: DRAWER_WIDTH },
    openingAfterGteLg: { className: DRAWER_WIDTH }
  }

  const sideDrawerTransitionStateMachineProps =
    sideDrawerStateToTransitionProps[
      sideDrawerState as keyof typeof sideDrawerStateToTransitionProps
    ]
  const sideDrawerStateMachineProps =
    sideDrawerStateToSideDrawerProps[
      sideDrawerState as keyof typeof sideDrawerStateToSideDrawerProps
    ]

  useEffect(() => {
    const hash = location.hash.split('#')[1]
    setActiveAnnotation(hash || null)
  }, [location.hash, setActiveAnnotation])

  useEffect(() => {
    if (activeAnnotation) {
      sendSideDrawerStateEvent({ type: 'active annotation detected' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAnnotation])

  useEffect(() => {
    if (isViewportLg) {
      sendSideDrawerStateEvent({ type: 'viewport hit lg' })
    } else {
      sendSideDrawerStateEvent({
        type: 'viewport went below lg',
        payload: { hasActiveAnnotation: !!activeAnnotation }
      })
    }

    // We're treating EVENT_TYPES as a const, thus we only care about changes to `isViewportLg`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewportLg])

  useEffect(() => {
    const removeDrawerQueryParam = () => {
      removeQueryParamFromUrl(history, location.search, 'drawer')
    }

    const drawer = getQueryParamValue(location.search, 'drawer')

    if (drawer === 'all') {
      setSideDrawerTab('All')
      removeDrawerQueryParam()
    } else if (drawer === 'comments') {
      setSideDrawerTab('Comments')
      removeDrawerQueryParam()
    } else if (drawer === 'notes') {
      setSideDrawerTab('Notes')
      removeDrawerQueryParam()
    } else if (drawer === 'closed') {
      sendSideDrawerStateEvent({ type: '?drawer=closed detected' })

      // dispatch a resize event so that RoughNotation highlights resize
      window.dispatchEvent(new Event('resize'))
      removeDrawerQueryParam()
    }
  }, [location.search, setSideDrawerTab, sendSideDrawerStateEvent, history])

  // This is what scrolls to the active annotation and sets the sidebar tab based on the url.
  useEffect(() => {
    const scrollToActiveAnnotationViewer = () => {
      const timer = setTimeout(() => {
        const activeAnnotationViewer: HTMLElement | null = document.querySelector(
          `div[data-annotation-viewer-hash="${activeAnnotation}"]`
        )

        if (activeAnnotationViewer && !welcomeMode.active) {
          document.querySelector('#side-drawer')?.scrollTo({
            left: 0,
            top: activeAnnotationViewer.offsetTop - 90,
            behavior: 'smooth'
          })
        }
      }, 250)
      return timer
    }

    if (!activeAnnotation) return

    if (sideDrawerTab === 'Comments' && !welcomeMode.active) {
      setSideDrawerTab('Notes')
    }

    const timer = scrollToActiveAnnotationViewer()
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [welcomeMode.active, activeAnnotation])

  useCommentQueryParamPresentOnLoad({
    onQueryParamPresentOnLoad: () => {
      setSideDrawerTab('Comments')
    }
  })

  // This scrolls to the annotation highlight (hotspot) in the content based on the active annotation.
  useEffect(() => {
    const activeAnnotationHighlight = document.querySelector(
      `span[data-annotation-hash="${activeAnnotation}"]`
    )
    const activeHotspotMarker = document.querySelector(
      `div[data-hotspot-hash="${activeAnnotation}"]`
    )

    if (!welcomeMode.active) {
      activeAnnotationHighlight?.getElementsByTagName('a')[0].focus()
      activeHotspotMarker?.getElementsByTagName('a')[0].focus()
    }

    const scrollToActiveHighlight = () => {
      const timer = setTimeout(() => {
        const activeAnnotationHighlight = document.querySelector(
          `span[data-annotation-hash="${activeAnnotation}"]`
        )

        if (activeAnnotationHighlight && !welcomeMode.active) {
          scrollIntoView(activeAnnotationHighlight, {
            behavior: 'smooth',
            block: 'center'
          })
        }

        const activeHotspotMarker = document.querySelector(
          `div[data-hotspot-hash="${activeAnnotation}"]`
        )
        if (activeHotspotMarker && !welcomeMode.active) {
          scrollIntoView(activeHotspotMarker, {
            behavior: 'smooth',
            block: 'center'
          })
        }
      }, 100)
      return timer
    }

    if (!activeAnnotation) {
      return
    }

    const timer = scrollToActiveHighlight()
    return () => clearTimeout(timer)
  }, [activeAnnotation, welcomeMode.active])

  const onClickTab = (filter: string) => {
    if (sideDrawerTab !== filter) {
      setSideDrawerTab(filter)

      trackNavigationClicked({
        // TODO: `destination` key when we have the URL for jumping to
        // each of these tabs
        location: 'artifacts_right_sidebar',
        text: filter,
        type: 'button'
      })
    }
  }

  const handleCloseSideDrawerClick = () => {
    sendSideDrawerStateEvent({ type: 'close side drawer button clicked' })
  }

  const loadingData = loadingComments || loadingAnnotations

  const annotations = annotationsData?.artifactAnnotations || []
  const comments = commentsData?.artifactComments || []

  // const tabAll = {
  //   label: 'All',
  //   isActive: sideDrawerTab === 'All',
  //   onClick: () => onClickTab('All')
  // }

  const tabNotes = {
    label: 'Notes',
    isActive: sideDrawerTab === 'Notes',
    onClick: () => onClickTab('Notes')
  }

  const tabComments = {
    label: comments?.length > 0 ? `Comments (${comments.length})` : 'Comments',
    isActive: sideDrawerTab === 'Comments',
    onClick: () => onClickTab('Comments')
  }

  // Don't show the "all" tab if user is not logged in
  const tabs: ComponentProps<typeof Tab>[] = [tabNotes, tabComments]
  // ? [tabAll, tabNotes, tabComments]
  // : [tabNotes, tabComments]

  return (
    <Transition
      id="sidebar-transition"
      show={sideDrawerOpen}
      className={twMerge(
        'duration-250 pointer-events-none fixed top-10 z-[1009] left-0 lg:left-auto w-full lg:w-auto h-[calc(100vh-40px)] flex-none bg-rb-white transition-[width,transform] ease-out lg:static lg:bg-transparent',
        welcomeMode.step === 'AuthorNotes' && 'z-[1003] rounded !bg-white'
      )}
      {...sideDrawerTransitionStateMachineProps}
      beforeEnter={() =>
        sendSideDrawerStateEvent({
          type: 'enter transition started'
        })
      }
      afterEnter={() =>
        sendSideDrawerStateEvent({
          type: 'enter transition ended'
        })
      }
      beforeLeave={() =>
        sendSideDrawerStateEvent({
          type: 'leave transition started'
        })
      }
      afterLeave={() =>
        sendSideDrawerStateEvent({
          type: 'leave transition ended'
        })
      }
    >
      <SideDrawer
        open={sideDrawerOpen}
        onCloseClick={handleCloseSideDrawerClick}
        {...sideDrawerStateMachineProps}
      >
        <div className={DRAWER_PADDING}>
          <div className="my-8 flex flex-row">
            <Tabs tabs={tabs} size="default" />
          </div>

          <div>
            {loadingData ? (
              <>
                <div className="chromatic-ignore mb-10 h-[70px] w-full animate-pulse rounded-md bg-rb-gray-100" />
                <div className="chromatic-ignore mb-10 h-[50px] w-full animate-pulse rounded-md bg-rb-gray-100" />
                <div className="chromatic-ignore mb-2 h-[200px] w-full animate-pulse rounded-md bg-rb-gray-100" />
                <div className="chromatic-ignore h-[200px] w-full animate-pulse rounded-md bg-rb-gray-100" />
              </>
            ) : (
              <ArtifactSidebarLists
                artifact={artifact}
                comments={comments}
                annotations={annotations}
                activeAnnotationId={activeAnnotation}
                primaryAuthor={authorFromArtifact}
                sideDrawerTab={sideDrawerTab}
                setSideDrawerTab={setSideDrawerTab}
              />
            )}

            {artifact?.showContentGate && (
              <NotesContentGate
                artifact={artifact}
                redirectPath={redirectPath}
                sideDrawerTab={sideDrawerTab}
              />
            )}
          </div>
        </div>
      </SideDrawer>
    </Transition>
  )
}

const sideDrawerTransitionGteLg = {
  enter: 'sticky top-[80px] self-start',
  enterFrom: 'translate-x-full w-0',
  enterTo: `translate-x-DUR ${DRAWER_WIDTH}`,
  entered: 'sticky self-start',
  leave: 'sticky top-[80px] self-start',
  leaveFrom: `translate-x-0 ${DRAWER_WIDTH}`,
  leaveTo: 'translate-x-full w-0'
}

const sideDrawerTransitionLtLg = {
  enter: 'fixed right-0 w-full pl-0 lg:pl-[60px] lg:pl-0',
  enterFrom: 'translate-x-full',
  enterTo: 'translate-x-0',
  entered: 'fixed right-0 w-full pl-0 lg:pl-[60px] lg:pl-0',
  leave: 'fixed right-0 w-full pl-0 lg:pl-[60px] lg:pl-0',
  leaveFrom: 'translate-x-0',
  leaveTo: 'translate-x-full'
}

export default ArtifactViewerSidebar
