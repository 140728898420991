import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import Person, { PersonData } from 'domains/Post/Person'

import PersonSuggester from 'components/Filters/PersonSuggester'
import { SVGIcon } from 'components/Icon'

import { usePostContributorsFilterLazyQuery } from 'gql'

import notifyError from 'utils/errorNotifier'

import { BaseFilterSet } from './Filter.types'

export interface PeopleFilterSet extends BaseFilterSet {
  explainer?: string
  title: string
  groupSlug?: string
  type: 'people'
}

export interface PeopleFilterProps {
  filterSet: PeopleFilterSet
  resetFilters: (filterKey: string) => void
  updateFilters: (filterKey: string, value: string, remove: boolean) => void
  values: string | number | null | string[]
  startOpen?: Boolean
}

const PeopleFilter = (props: PeopleFilterProps) => {
  const { filterSet, values, startOpen } = props
  const [currentValues, setCurrentValues] = useState<PersonData[]>([])
  const isComponentMounted = useRef(false)
  const [isOpen, setOpen] = useState(!!(values || startOpen))
  const [postContributorsFilterQuery] = usePostContributorsFilterLazyQuery()

  const onToggle = () => {
    setOpen(!isOpen)
  }

  useEffect(() => {
    if (isComponentMounted.current) {
      if (Array.isArray(values)) {
        // the values are user slugs. Get user objects for these slugs and put them in the currentValues array
        fetchUserObjects(values)
      } else {
        // this is passed when all filters are reset from the parent Filters component
        setCurrentValues([])
      }
    } else {
      isComponentMounted.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]) // call this any time the values change, except on first load before filters are set from url params

  // the internal function used to add values to the currentValues array
  const updateFilters = (userObject: PersonData, remove: boolean) => {
    if (remove) {
      setCurrentValues(currentValues.filter((e) => e.slug !== userObject.slug))
    } else {
      setCurrentValues(currentValues.concat(userObject))
    }
    props.updateFilters(filterSet.key, userObject.slug, remove)
  }

  const addPerson = (personData: PersonData) => {
    updateFilters(personData, false)
  }

  const removeLastPerson = () => {
    if (currentValues) {
      const lastPerson = currentValues[currentValues.length - 1]
      updateFilters(lastPerson, true)
    }
  }

  const reset = () => {
    setCurrentValues([])
    props.resetFilters(filterSet.key)
  }

  const fetchUserObjects = async (slugs: string[]) => {
    try {
      const response = await postContributorsFilterQuery({
        variables: {
          slugs: slugs as string[]
        }
      })

      setCurrentValues(response.data?.postContributorsFilter || [])
    } catch (e) {
      notifyError(e)
    }
  }
  const isFilterHidden = currentValues.length === 0

  return (
    <div
      className={clsx(
        'mb-8 -ml-4 rounded-sm border p-0 hover:border-rb-gray-250 tl:mb-4 tl:ml-0',
        isFilterHidden && 'filter--hidden'
      )}
      data-selected={currentValues.length > 0}
      id={`filter-${filterSet.key}`}
    >
      <div
        className="flex cursor-pointer py-3 px-[15px]"
        onClick={onToggle}
        uk-toggle={`target: #filter-${filterSet.key}; cls: filter--hidden`}
      >
        <h3
          className={twMerge(
            'mb-0 grow cursor-pointer text-sm font-medium capitalize text-rb-gray-500'
          )}
        >
          {filterSet.title}
        </h3>
        {currentValues.length > 0 && (
          <div
            className="mr-[5px] cursor-pointer text-sm leading-5 text-rb-teal-200 hover:underline hover:decoration-rb-teal-300"
            onClick={reset}
          >
            Reset
          </div>
        )}
        <div className="hidden cursor-pointer leading-5 [.filter--hidden_&]:block">
          <SVGIcon name="reveal-plus" height="20" width="20" fill="#A2A1A2" />
        </div>
        <div className="hidden cursor-pointer leading-5 tl:block [.filter--hidden_&]:tl:hidden">
          <SVGIcon name="hide-minus" height="20" width="20" fill="#000000" />
        </div>
      </div>
      <div className="px-[15px] pt-0 pb-[5px] [.filter--hidden_>_&]:block [.filter--hidden_>_&]:tl:hidden">
        {filterSet.explainer && <p className="mb-2.5 text-sm">{filterSet.explainer}</p>}
        <div className="rounded-xs relative mb-2.5 border-[1.5px] border-rb-gray-400 bg-rb-gray-50 px-2.5 pt-2.5 pb-0">
          {currentValues.map((personData, index) => (
            <Person
              key={`person_${index}`}
              person={personData}
              selected
              updateFilters={updateFilters}
            />
          ))}
          <div className="uk-position-relative">
            <PersonSuggester
              addPerson={addPerson}
              removeLastPerson={removeLastPerson}
              groupSlug={filterSet.groupSlug}
              selectedPeopleSlugs={currentValues.map((person) => person.slug) || []}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PeopleFilter
