import { ReactPortal, RefObject, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import {
  ProductTourKey,
  ProductTour as ProductTourType,
  useCompleteProductTourMutation,
  useCompletedProductToursQuery
} from 'gql'

import { toCamelCase } from 'utils/stringUtils'
import { trackModalDismissed, trackModalDisplayed } from 'utils/tracking/analytics'

import ProductTour from './ProductTour'

// If the product tour is not defined, we assume it is not completed
const defaultCompletedPredicate = (key: ProductTourKey, productTour: ProductTourType) => {
  if (key === ProductTourKey.SEARCH_TRAINER_VIEWS) {
    return (productTour[toCamelCase(key)] || 0) > 5
  }
  return productTour[toCamelCase(key)] ?? false
}

const COMPLETED_PREDICATE_MAP = new Map<
  ProductTourKey,
  (tour: ProductTourType) => boolean
>([
  [
    ProductTourKey.TEAM_COMMENT_VIEW,
    (productTour) => {
      const completedSaveBookmark = defaultCompletedPredicate(
        ProductTourKey.COLLECTION_SAVE_BOOKMARK,
        productTour
      )
      const completedCreateBookmark = defaultCompletedPredicate(
        ProductTourKey.BOOKMARK_CREATE,
        productTour
      )
      const completedTeamCommentView = defaultCompletedPredicate(
        ProductTourKey.TEAM_COMMENT_VIEW,
        productTour
      )

      // If we have not completed these other tours, we return true
      // so that this tour is not shown.
      if (!completedSaveBookmark || !completedCreateBookmark) return true
      return completedTeamCommentView
    }
  ],
  [
    ProductTourKey.COLLECTION_VIEW_CONTENT,
    (productTour) => {
      const completedShareCollection = defaultCompletedPredicate(
        ProductTourKey.COLLECTION_SHARE,
        productTour
      )
      const completedCollectionView = defaultCompletedPredicate(
        ProductTourKey.COLLECTION_VIEW_CONTENT,
        productTour
      )

      // If we have not completed these other tours, we return true
      // so that this tour is not shown.
      if (!completedShareCollection) return true
      return completedCollectionView
    }
  ],
  [
    ProductTourKey.COLLECTIONS_TAB,
    (ProductTour) => {
      const completedCollectionsTab = defaultCompletedPredicate(
        ProductTourKey.COLLECTIONS_TAB,
        ProductTour
      )
      const completedSavedItemsTab = defaultCompletedPredicate(
        ProductTourKey.SAVED_ITEMS_TAB,
        ProductTour
      )

      if (!completedSavedItemsTab) return true
      return completedCollectionsTab
    }
  ]
])

const isCompletedPredicate = (
  key: ProductTourKey,
  productTour: ProductTourType,
  checkKeyOnly: boolean
) => {
  return COMPLETED_PREDICATE_MAP.has(key) && !checkKeyOnly
    ? COMPLETED_PREDICATE_MAP.get(key)?.(productTour)
    : defaultCompletedPredicate(key, productTour)
}

type ProductTourKeysWithModal = Exclude<
  ProductTourKey,
  | ProductTourKey.SEARCH_TRAINER_VIEWS
  | ProductTourKey.NEW_SEARCH_CLICKED
  | ProductTourKey.USED_REFORGE_AI_GLOBAL_ENTRY_POINT
  | ProductTourKey.START_DRAFT_FROM_BUTTON
>

interface UseProductTourProps {
  productTourKey: ProductTourKeysWithModal
  nodeRefOrSelector: RefObject<HTMLElement> | string
  disabled?: boolean
  title: string
  description: string
  onClose?: () => void
  position?: 'absolute' | 'fixed'
  wait?: number
  // If true, useProductTour will not check complex tour conditions defined
  // in COMPLETED_PREDICATE_MAP and instead only check the specified productTourKey
  checkKeyOnly?: boolean
  darkMode?: boolean
}

export function useProductTour({
  productTourKey,
  nodeRefOrSelector,
  title,
  description,
  disabled = false,
  onClose,
  position = 'absolute',
  wait,
  checkKeyOnly = false,
  darkMode = false
}: UseProductTourProps) {
  const [portal, setPortal] = useState<ReactPortal | null>(null)
  const { data, loading } = useCompletedProductToursQuery()
  const [completeProductTour] = useCompleteProductTourMutation()
  const [modalShown, setModalShown] = useState(false)

  const productTour = data?.currentUser?.completedProductTours
  const isCompleted =
    productTour && (data?.currentUser?.is?.member || data?.currentUser?.is?.paidMember)
      ? isCompletedPredicate(productTourKey, productTour, checkKeyOnly)
      : true

  const completeTour = () => {
    if (!productTour) return

    completeProductTour({
      variables: {
        input: {
          productTourKey: productTourKey
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        completeProductTour: {
          __typename: 'CompleteProductTourPayload',
          completedProductTours: {
            ...productTour,
            [toCamelCase(productTourKey)]: true
          }
        }
      }
    })
    onClose?.()
  }

  useEffect(() => {
    if (!nodeRefOrSelector || disabled || loading || isCompleted) {
      setPortal(null)
      return
    }
    // If the node is a ref, we can use it directly
    let element: HTMLElement | null = null
    // Check if nodeRefOrSelector is a string or a ref
    if (typeof nodeRefOrSelector === 'string') {
      element = document.querySelector(nodeRefOrSelector)
    } else if (nodeRefOrSelector.current) {
      element = nodeRefOrSelector.current
    }

    if (!element) return

    // Create a element to render the ProductTour into and append it to the body
    let portalDiv = document.getElementById(`portal-${productTourKey}`)
    if (!portalDiv) {
      portalDiv = document.createElement('div')
      portalDiv.id = `portal-${productTourKey}`
      document.body.appendChild(portalDiv)
    }

    if (!portalDiv) return

    if (!modalShown) {
      setModalShown(true)
      trackModalDisplayed({
        modal_group: 'product_tour',
        modal_name: productTourKey,
        category: 'app',
        location: window.location.pathname
      })
    }

    const portal = createPortal(
      <ProductTour
        key={productTourKey}
        reference={element}
        title={title}
        description={description}
        position={position}
        wait={wait}
        darkMode={darkMode}
        handleClose={() => {
          trackModalDismissed({
            modal_group: 'product_tour',
            modal_name: productTourKey,
            category: 'app',
            location: window.location.pathname
          })
          completeTour()
        }}
      />,
      portalDiv
    )
    setPortal(portal)

    // Observe DOM changes to check if element still exists
    const observer = new MutationObserver(() => {
      if (!document.body.contains(element)) {
        setPortal(null)
        if (portalDiv) {
          portalDiv.parentNode?.removeChild(portalDiv)
        }
      }
    })

    observer.observe(document.body, { childList: true, subtree: true })

    // Clean up function
    return () => {
      observer.disconnect()
      setPortal(null)
      // If the portalDiv was created by this instance, remove it
      if (portalDiv) {
        portalDiv.parentNode?.removeChild(portalDiv)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    nodeRefOrSelector,
    productTourKey,
    disabled,
    loading,
    isCompleted,
    productTour,
    checkKeyOnly
  ])

  return {
    portal,
    isCompleted,
    completeTour
  }
}
