import clsx from 'clsx'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { SVGIcon } from 'components/Icon'
import { SelectableTag } from 'components/Tag'

import { BaseFilterSet } from './Filter.types'

export interface TagFilterSet extends BaseFilterSet {
  explainer?: string
  options: string[][]
  title: string
  type: 'tag'
}

export interface ITagFilter {
  filterSet: TagFilterSet
  resetFilters: (filterKey: string) => void
  updateFilters: (filterKey: string, value: string, remove: boolean) => void
  values: string | number | null | string[]
  startOpen?: Boolean
}

const TagFilter = (props: ITagFilter) => {
  const { filterSet, values, startOpen } = props

  const updateFilters = (value: string, remove: boolean) => {
    props.updateFilters(filterSet.key, value, remove)
  }

  const reset = () => {
    props.resetFilters(filterSet.key)
  }

  const [isOpen, setOpen] = useState(!!(values || startOpen))

  const onToggle = () => {
    setOpen(!isOpen)
  }

  const isFilterHidden = !values

  return (
    <div
      className={clsx(
        'mb-8 -ml-4 rounded-sm border p-0 hover:border-rb-gray-250 tl:mb-4 tl:ml-0',
        isFilterHidden && 'filter--hidden'
      )}
      data-selected={!!values}
      id={`filter-${filterSet.key}`}
    >
      <div
        className="flex cursor-pointer py-3 px-[15px]"
        onClick={onToggle}
        uk-toggle={`target: #filter-${filterSet.key}; cls: filter--hidden`}
      >
        <h3
          className={twMerge(
            'mb-0 grow cursor-pointer text-sm font-medium capitalize text-rb-gray-500'
          )}
        >
          {filterSet.title}
        </h3>
        {!!values && (
          <div
            className="mr-[5px] cursor-pointer text-sm leading-5  text-rb-teal-200 hover:underline hover:decoration-rb-teal-300"
            onClick={reset}
          >
            Reset
          </div>
        )}
        <div className="hidden cursor-pointer leading-5 [.filter--hidden_&]:block">
          <SVGIcon name="reveal-plus" height="20" width="20" fill="#A2A1A2" />
        </div>
        <div className="hidden cursor-pointer leading-5 tl:block [.filter--hidden_&]:tl:hidden">
          <SVGIcon name="hide-minus" height="20" width="20" fill="#000000" />
        </div>
      </div>
      <div
        className={clsx(
          'px-[15px] pt-0 pb-[5px] [.filter--hidden_>_&]:block [.filter--hidden_>_&]:tl:hidden'
        )}
      >
        {filterSet.explainer && (
          <p className="uk-margin-small-bottom text-m-small sm:text-m-medium">
            {filterSet.explainer}
          </p>
        )}
        {filterSet.options.map((filterOption, index) => {
          const filterVariable = filterOption[0]
          const displayDisplayName = filterOption[1]
          const selected =
            !!values && Array.isArray(values) && values.includes(filterVariable)
          return (
            <SelectableTag
              className="mr-2.5 mb-2.5"
              key={`option_${index}`}
              text={displayDisplayName}
              onChange={() => updateFilters(filterVariable, selected)}
              selected={selected}
            />
          )
        })}
      </div>
    </div>
  )
}

export default TagFilter
