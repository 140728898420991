import ButtonMarketingCTA from 'domains/Sanity/marketingSite/ButtonMarketingCTA'

import ArrowUpRight from 'components/icons/ArrowUpRight'

interface PreFooterCTAProps {
  headerText: string
  description?: string
  cta: {
    href: string
    text: string
    location?: string
    includeExternalLinkArrow?: boolean
  }
}

const PreFooterCTA = ({ headerText, description, cta }: PreFooterCTAProps) => {
  return (
    <>
      <section className="bg-rb-background-beige px-[16px] pt-[6.6vmax] pb-[4vw] md:px-[4vw]">
        <h2 id="getintouch" className="font-normal tracking-tight fluid-text-6xl">
          {headerText}
        </h2>
        <p className="my-[30px] font-normal tracking-tight fluid-text-2xl lg:mt-0 xl:fluid-text-3xl">
          {description}
        </p>
        <ButtonMarketingCTA
          href={cta.href}
          location={cta.location}
          ctaText={cta.text}
          target="_blank"
        >
          {cta.text}
          {cta.includeExternalLinkArrow && (
            <ArrowUpRight className="ml-2" width={16} height={16} />
          )}
        </ButtonMarketingCTA>
      </section>
    </>
  )
}

export default PreFooterCTA
