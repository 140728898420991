import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

import FieldError from 'components/forms/FieldError'

import { onSpaceKeyPress } from 'utils/keyboard'
import { humanizeCamelCase } from 'utils/stringUtils'

export interface ICheckbox {
  'name': string
  'label'?: string | React.ReactNode
  'showLabel'?: boolean
  'hidden'?: boolean
  'onChange'?: (val: boolean) => void
  'className'?: string
  'data-test'?: string
  'currentValue'?: boolean
  'labelClasses'?: string
}

const Checkbox = ({
  name,
  label,
  showLabel = true,
  hidden = false,
  onChange,
  className,
  'data-test': dataTest,
  currentValue,
  labelClasses,
  ...rest
}: ICheckbox) => {
  const { register, setValue, getValues, errors } = useFormContext()
  const [checked, setChecked] = useState(() =>
    getValues && getValues(name) ? [true, 'true'].includes(getValues(name)) : false
  )

  function handleChange() {
    const val = !checked

    setValue(name, val, true)
    setChecked(val)

    if (onChange) onChange(val)
  }

  useEffect(() => {
    if (currentValue !== undefined) {
      setValue(name, currentValue, true)
      setChecked(currentValue)
    }
  }, [currentValue, name, setValue])

  const hasError = errors && errors[name]
  const labelText = label || humanizeCamelCase(name)
  const style = hidden ? { display: 'none' } : {}

  return (
    <div
      style={style}
      className={`relative outline-none select-none${className ? ` ${className}` : ''}`}
    >
      <div
        className="flex cursor-pointer items-center"
        style={style}
        onClick={handleChange}
        data-test={dataTest}
        role="checkbox"
        aria-checked={checked}
        tabIndex={0}
        onKeyUp={onSpaceKeyPress(handleChange)}
      >
        <div
          className={`flex shrink-0 items-center justify-center rounded-sm border-1.5 ${
            checked
              ? 'color-white border-rb-gray-400 bg-rb-gray-400'
              : 'border-rb-gray-100'
          }`}
          style={{ width: '20px', height: '20px' }}
        >
          <svg
            className={checked ? 'block' : 'hidden'}
            width="14"
            height="10"
            viewBox="0 0 14 10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.3333 1L5 8.33333L1.66667 5"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        {!!showLabel && (
          <div
            className={twMerge(
              `${hasError ? 'text-rb-warning-200 ' : ''}
              ${checked ? 'font-bold ' : 'font-medium '}
            ml-4 font-medium text-neutral-800`,
              labelClasses
            )}
          >
            {labelText}
          </div>
        )}

        <label style={{ display: 'none' }}>
          {labelText}
          <input name={name} type="checkbox" ref={register} {...rest} />
        </label>
      </div>

      <FieldError name={name} errors={errors} />
    </div>
  )
}

export default Checkbox
