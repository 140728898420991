import { useState } from 'react'

import LegacyContentCard from 'components/cards/Content/LegacyContentCard'

import {
  CohortViewerMaterialParentSectionPartsFragment,
  CurrentUserPartsFragment
} from 'gql'

import { onEnterKeyPress } from 'utils/keyboard'
import { cn } from 'utils/tailwind'

import { ProgramProgressScalar } from 'typings/scalars'

interface ModuleCardRowProps {
  cmsModuleId: string
  cmsSections: CohortViewerMaterialParentSectionPartsFragment[]
  numSectionBookmarks: Record<number, number>
  currentUser?: CurrentUserPartsFragment
  userProgress: ProgramProgressScalar
}

interface IModuleCardRowParentSection {
  cmsModuleId: string
  cmsSection: CohortViewerMaterialParentSectionPartsFragment
  currentUser?: CurrentUserPartsFragment
  userProgress: ProgramProgressScalar
  numSectionBookmarks: Record<number, number>
}

const ModuleCardRow = ({
  cmsModuleId,
  cmsSections,
  numSectionBookmarks,
  currentUser,
  userProgress
}: ModuleCardRowProps) => {
  return (
    <div className="uk-grid-collapse" uk-grid="">
      {cmsSections.map((cmsSection) => {
        const sectionBookmarks = numSectionBookmarks?.[parseInt(cmsSection.id, 10)] || 0
        if (cmsSection.children.length > 0) {
          return (
            <ModuleCardRowParentSection
              key={`sec${cmsSection.id}`}
              cmsModuleId={cmsModuleId}
              currentUser={currentUser}
              cmsSection={cmsSection}
              userProgress={userProgress}
              numSectionBookmarks={numSectionBookmarks}
            />
          )
        } else {
          return (
            <div
              data-test="module-card"
              key={`sec${cmsSection.id}`}
              className="mb-5 w-full sm:mr-[30px] sm:w-[252px]"
            >
              <LegacyContentCard
                content={{ ...cmsSection, numBookmarks: sectionBookmarks }}
                hideBookmarkButton={!currentUser}
              />
            </div>
          )
        }
      })}
    </div>
  )
}

const ModuleCardRowParentSection = ({
  cmsSection,
  numSectionBookmarks,
  currentUser
}: IModuleCardRowParentSection) => {
  const [sectionOpen, setSectionOpen] = useState(true)

  const toggleSectionOpen = () => {
    setSectionOpen(!sectionOpen)
  }

  return (
    <div
      data-test="module-card"
      className="uk-width-1-1 uk-margin-small-bottom uk-grid-collapse"
      uk-grid=""
    >
      <div
        role="button"
        onKeyUp={onEnterKeyPress(toggleSectionOpen)}
        tabIndex={0}
        className={cn(
          'uk-width-1-1 uk-margin-small-bottom flex cursor-pointer items-center',
          !currentUser && 'mb-4 cursor-default'
        )}
        onClick={toggleSectionOpen}
      >
        <span
          uk-icon={`icon: ${
            sectionOpen ? 'triangle-down' : 'triangle-right'
          }; ratio: 1.0`}
          className="uk-margin-small-right"
        />
        <span className="text-sm font-medium leading-[14px] tracking-widest text-rb-gray-500 sm:text-[15px] sm:leading-[18px]">
          {cmsSection.name.toUpperCase()}
        </span>
        {cmsSection.estimatedTime > 0 && (
          <span className="ml-3 text-sm font-normal leading-[18px] text-rb-gray-300">{`${cmsSection.estimatedTime} min`}</span>
        )}
      </div>
      <div className={`${sectionOpen ? '' : 'uk-hidden'}`}>
        <div
          className={cn('uk-grid-collapse', !currentUser && 'flex flex-wrap')}
          uk-grid=""
        >
          {cmsSection.children.map((childSection) => {
            const numChildBookmarks =
              numSectionBookmarks?.[parseInt(childSection.id, 10)] || 0
            return (
              <div
                key={`sec${childSection.id}`}
                className="mb-5 w-full sm:mr-[30px] sm:w-[252px]"
              >
                <LegacyContentCard
                  content={{ ...childSection, numBookmarks: numChildBookmarks }}
                  hideBookmarkButton={!currentUser}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ModuleCardRow
