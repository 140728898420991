import { ReactNode } from 'react'

import Image from 'domains/Sanity/Image'

import CheckIcon from 'images/marketing-round-icons/check-icon.png'

interface ColumnsOfCheckboxesAndContentProps {
  column1Content: ReactNode
  column2Content: ReactNode
  column3Content: ReactNode
  column4Content?: ReactNode
}

const ColumnsOfCheckboxesAndContent = ({
  column1Content,
  column2Content,
  column3Content,
  column4Content
}: ColumnsOfCheckboxesAndContentProps) => {
  const checkmarkImage = (
    <Image
      alt="checkmark icon"
      src={CheckIcon}
      width={130}
      height={130}
      className="absolute top-0 left-1/2 right-0 h-auto w-1/6 -translate-y-2/3 -translate-x-1/2 transform sm:w-1/12 md:w-1/4 2xl:w-1/5"
    />
  )

  const gridSize = column4Content ? 4 : 3

  return (
    <>
      <ul
        className={`mt-[100px] grid grid-cols-1 grid-rows-${gridSize} gap-x-[30px] gap-y-[80px] p-0 pb-[50px] md:grid-cols-${gridSize} md:grid-rows-1 md:gap-y-0`}
      >
        <li className="relative list-none rounded-lg bg-white px-[50px] py-[40px] drop-shadow-[0_0_3px_rgba(0,0,0,0.24)] md:px-[25px] lg:px-[50px]">
          {checkmarkImage}
          <p className="!leading-tight tracking-tight fluid-text-base">
            {column1Content}
          </p>
        </li>
        <li className="relative list-none rounded-lg bg-white px-[50px] py-[40px] drop-shadow-[0_0_3px_rgba(0,0,0,0.24)] md:px-[25px] lg:px-[50px]">
          {checkmarkImage}
          <p className="!leading-tight tracking-tight fluid-text-base">
            {column2Content}
          </p>
        </li>
        <li className="relative list-none rounded-lg bg-white px-[50px] py-[40px] drop-shadow-[0_0_3px_rgba(0,0,0,0.24)] md:px-[25px] lg:px-[50px]">
          {checkmarkImage}
          <p className="!leading-tight tracking-tight fluid-text-base">
            {column3Content}
          </p>
        </li>
        {column4Content && (
          <li className="relative list-none rounded-lg bg-white px-[50px] py-[40px] drop-shadow-[0_0_3px_rgba(0,0,0,0.24)] md:px-[25px] lg:px-[50px]">
            {checkmarkImage}
            <p className="!leading-tight tracking-tight fluid-text-base">
              {column4Content}
            </p>
          </li>
        )}
      </ul>
    </>
  )
}

export default ColumnsOfCheckboxesAndContent
