import BadgeableAvatar from 'components/BadgeableAvatar'

import { PostListQuery } from 'gql'

type ReplyType = PostListQuery['discussionPosts']['posts'][0]['replies'][0]
type ReplyUser = ReplyType['user']

interface IReplyPreview {
  replies: ReplyType[]
}

export const ReplyPreview = (props: IReplyPreview) => {
  const { replies } = props

  if (replies.length === 0) return null

  const companyCopy = (user: ReplyUser) => {
    let copy = ''
    if (user.profile.companyName) {
      copy += ', '
      if (user.profile.role) {
        copy += `${user.profile.role} @ `
      }
      copy += user.profile.companyName
    }
    return copy
  }

  const sortedReplies = [...replies].sort((r1, r2) =>
    r1.createdAt > r2.createdAt ? -1 : 1
  )

  return (
    <div className="uk-margin-small-top">
      <hr />
      {sortedReplies.map((reply) => (
        <div key={`reply-preview-${reply.id}`}>
          <div className="flex flex-row py-4">
            <div className="mr-2.5 flex items-center">
              <BadgeableAvatar
                user={reply.user.profile}
                avatarUrl={reply.user.profile.avatarUrl}
                width="36"
                height="36"
                fullName={reply.user.profile.fullName}
              />
            </div>

            <div className="mr-2.5 flex-1">
              <div className="uk-link-heading cursor-pointer">
                <div className="overflow-ellipsis line-clamp-2">
                  <a
                    href={`/directory/${reply.user.profile.slug}`}
                    // prevent navigation to post first and then going to member profile
                    onClick={(e) => e.stopPropagation()}
                    className="uk-link-heading hover:!text-rb-teal-200"
                  >
                    <span className="font-bold">{reply.user.profile.fullName}</span>
                    <span>{companyCopy(reply.user)}: </span>
                  </a>
                  <span
                    className="font-sans text-rb-gray-300"
                    style={{ wordBreak: 'break-word' }}
                    dangerouslySetInnerHTML={{ __html: reply.bodyText || '' }}
                  />
                </div>
              </div>
            </div>
            <div className="uk-text-nowrap text-rb-gray-300">
              {reply.createdAtInWords} ago
            </div>
          </div>
          <hr />
        </div>
      ))}
      <div className="mt-4 cursor-pointer text-center text-xs font-semibold tracking-[0.1rem] hover:text-rb-teal-200">
        VIEW FULL THREAD
      </div>
    </div>
  )
}
