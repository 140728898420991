import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface OrderRecapLabelProps {
  label: string | ReactNode
  details?: string | ReactNode
  className?: string
}

export const OrderRecapLabel = ({ label, details, className }: OrderRecapLabelProps) => {
  return (
    <span className={twMerge('text-sm leading-6', className)}>
      {label}
      {details && (
        <span className="block text-xs font-medium text-rb-gray-300">{details}</span>
      )}
    </span>
  )
}

export default OrderRecapLabel
