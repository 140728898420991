import { ProfileSidebarSectionHeader } from 'pages/MemberProfilePage'

import { ErrorMessage, Loading } from 'components'
import LegacyContentCard from 'components/cards/Content/LegacyContentCard'

import { useUserRecentlyReviewedContentQuery } from 'gql'

export interface RecentlyReviewedContentProps {
  userId: string
}

const RecentlyReviewedContent = ({ userId }: RecentlyReviewedContentProps) => {
  const { loading, error, data } = useUserRecentlyReviewedContentQuery({
    variables: { userId }
  })

  if (loading) return <Loading />
  if (error) return <ErrorMessage error={error} />
  if (!data) return <ErrorMessage error="Failed to load CMS activity." />
  if (!data.user) return <ErrorMessage error="Failed to load user." />

  const recentlyReviewedContent = data.user.recentlyReviewedContent
  if (!recentlyReviewedContent.length) return null
  return (
    <div>
      <ProfileSidebarSectionHeader title={'Recently Reviewed Content'} />
      <div>
        {recentlyReviewedContent.map((contentItem) => {
          return (
            <div key={contentItem.cmsSection.id} className="my-3">
              <LegacyContentCard
                content={{
                  ...contentItem.cmsSection,
                  lastViewedAt: null,
                  href: `${contentItem.cmsSection.href}?from=profile-recent-content-section&from_member_id=${userId}`,
                  contentBookmarkId: contentItem.contentBookmarkId
                }}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default RecentlyReviewedContent
