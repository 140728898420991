import { twMerge } from 'tailwind-merge'

import { onEnterKeyPress } from 'utils/keyboard'

interface WeeklyTabProps {
  title: string
  className?: string
  onClick: () => void
}

export const WeeklyTab = ({ title, className, onClick }: WeeklyTabProps) => (
  <div
    tabIndex={0}
    role="button"
    className={twMerge(
      'flex w-full items-center justify-center whitespace-nowrap rounded-full py-1 px-3 text-xs hover:border hover:border-black md:max-w-[112px] md:py-2 md:px-6 md:text-sm',
      className
    )}
    onClick={onClick}
    onKeyUp={onEnterKeyPress(onClick)}
  >
    {title}
  </div>
)
