import { ChangeEventHandler, Dispatch, FormEventHandler, SetStateAction } from 'react'
import Pluralize from 'react-pluralize'

import FilterInput from 'domains/Bookmarks/FilterInput'
import SortByFilter from 'domains/Bookmarks/SortByFilter'

import { Loading } from 'components'
import Filters from 'components/Filters'

import { useAllUserBookmarksProgramsQuery } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

const SORT_OPTIONS = {
  notes_old_to_new: { displayValue: 'Old to New', queryValue: 'notes_old_to_new' },
  notes_new_to_old: { displayValue: 'New to Old', queryValue: 'notes_new_to_old' }
}
const DEFAULT_SORT = SORT_OPTIONS.notes_new_to_old

interface CollectionFiltersHeaderProps {
  loading: boolean
  count: number
  setSort: (value: string) => void
  selectedSort: 'notes_old_to_new' | 'notes_new_to_old'
}

const CollectionFiltersHeader = ({
  loading,
  count,
  setSort,
  selectedSort
}: CollectionFiltersHeaderProps) => {
  const handleSortOptionChange = (selected: string) => {
    const optionInfo = Object.values(SORT_OPTIONS).find(
      (option) => option.displayValue === selected
    )
    if (!optionInfo) {
      return
    }
    setSort(optionInfo.queryValue)
  }

  return (
    <div
      className="relative mb-4 flex w-full flex-col sm:flex-row sm:items-center sm:justify-between"
      data-testid="bookmarks-bookmarks-filters-header"
    >
      <div className="flex justify-between py-3 sm:py-0">
        {!loading && (
          <Pluralize
            className="text-base font-normal"
            showCount={true}
            singular={count > 0 ? 'Result' : 'Saved Item'}
            count={count}
          />
        )}
      </div>
      <div className="flex">
        <SortByFilter
          handleSortOptionChange={handleSortOptionChange}
          sortOptions={Object.values(SORT_OPTIONS).map((option) => option.displayValue)}
          selectedSortOption={
            SORT_OPTIONS[selectedSort]?.displayValue || DEFAULT_SORT.displayValue
          }
        />
      </div>
    </div>
  )
}

interface CollectionFiltersSidebarProps {
  filters: any
  setFilters: Dispatch<SetStateAction<any>>
}

const CollectionFiltersSidebar = ({
  filters,
  setFilters
}: CollectionFiltersSidebarProps) => {
  const currentUser = useAssertCurrentUser()
  const { data, loading } = useAllUserBookmarksProgramsQuery({
    variables: { userId: currentUser.id }
  })

  if (loading || !data?.user?.bookmarkCmsPrograms) {
    return <Loading />
  }
  const programFilters = data.user.bookmarkCmsPrograms.map((program) => [
    program.id,
    program.name
  ])
  const filterKeys = Object.keys(filters)
  const activeFilterCount = filterKeys.filter(
    (key) => key !== 'page' && key !== 'sort_by'
  ).length

  const handleSearchSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
  }

  const handleSearchTextChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value === '') {
      delete filters.searchText
      setFilters({ ...filters })
    } else {
      setFilters({ ...filters, searchText: e.target.value })
    }
  }

  const filterOptions = [
    {
      options: programFilters,
      title: 'Courses',
      type: 'radio' as const,
      key: 'cmsProgramId'
    }
  ]

  return (
    <div id="filters-with-keyword-placeholder">
      <div id="filters-with-keyword">
        <div className="mb-4 flex h-10 items-center">
          <div className="text-sm font-medium uppercase text-rb-gray-500">Filters</div>
          {activeFilterCount > 0 && (
            <div className="ml-2.5 h-4 w-4 rounded bg-rb-gray-500 text-center text-xs font-medium text-white">
              {activeFilterCount}
            </div>
          )}
        </div>
        <div className="mb-0 lg:mb-4">
          <FilterInput
            handleSearchSubmit={handleSearchSubmit}
            searchText={filters.searchText || ''}
            handleSearchTextChanged={handleSearchTextChanged}
          />
        </div>
        <Filters
          filterKeys={filterKeys as string[]}
          filters={filters}
          setFilters={setFilters}
          startOpen={true}
          headerText=""
          filterOptions={filterOptions}
        />
      </div>
    </div>
  )
}

export { CollectionFiltersHeader, CollectionFiltersSidebar, DEFAULT_SORT, SORT_OPTIONS }
