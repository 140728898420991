import { LocationDescriptor } from 'history'
import React, { useEffect, useRef } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import BasedOn from 'domains/Post/BasedOn'
import { ReplyPreview } from 'domains/Post/ReplyPreview'
import UnreadBadge from 'domains/Post/UnreadBadge'
import UserHeading from 'domains/User/UserHeading'

import TagHeader from 'components/TagHeader'

import {
  PostGroupFragment,
  PostIndustryFragment,
  PostListQuery,
  PostShowFieldsFragment,
  PostTopicFragment
} from 'gql'

type PostListPost = PostListQuery['discussionPosts']['posts'][0]
type PostListGroup = PostListPost['groups'][0]
type PostListTopic = PostListPost['topics'][0]
type PostListIndustry = PostListPost['industries'][0]

const postUrlObject = (
  group: string | undefined,
  postToParam: string | null | undefined,
  source: string | undefined
): LocationDescriptor => {
  if (group !== undefined) {
    return {
      pathname: `/groups/${group}/posts/${postToParam}`,
      state: { from: 'group', group: group }
    }
  } else {
    if (source !== undefined) {
      return { pathname: `/posts/${postToParam}`, state: { from: source } }
    } else {
      return { pathname: `/posts/${postToParam}` }
    }
  }
}

interface ILinkToPost {
  post: PostListPost
  source?: string
  group?: string
  className: string
  children?: React.ReactNode
}

export const LinkToPost = ({ post, children, className, source, group }: ILinkToPost) => {
  const history = useHistory()
  const location = useLocation()

  const storeScrollPosition = () => {
    const scrollPosition = document.documentElement.scrollTop || document.body.scrollTop
    window.localStorage.previousScrollLocation = String(scrollPosition)
    window.localStorage.previousLocation = JSON.stringify(location)
  }

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const url = new URL(window.location.href)
    url.searchParams.set('backButton', 'true')
    window.history.pushState({}, '', url)
    storeScrollPosition()
    const postToParam = post.toParam
    const postPath = group
      ? `/groups/${group}/posts/${postToParam}`
      : `/posts/${postToParam}`
    if (e.metaKey || e.ctrlKey) {
      window.open(postPath)
    } else {
      history.push(postUrlObject(group, postToParam, source))
    }
  }

  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  )
}

interface IPostCardLayout {
  post: PostListPost | PostShowFieldsFragment
  isUnread?: boolean
  source?: string
  group?: string
  children?: React.ReactNode
  linksToPost?: boolean
  editButtons?: React.ReactNode
}

const PostCardLayout = ({
  post,
  isUnread,
  children,
  linksToPost,
  editButtons,
  source,
  group
}: IPostCardLayout) => {
  // LinkToPost wraps the post in a div with a click handler.
  // Cancel that click handler for <Link> elements within the post
  const cancelParentOnClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    // TODO: How to call storeScrollPosition() here?
  }

  const postToParam = post.toParam

  const buildHeaderTags = (postObjects: {
    groups: PostListGroup[] | PostGroupFragment[]
    industries: PostListIndustry[] | PostIndustryFragment[]
    topics: PostListTopic[] | PostTopicFragment[]
  }) => {
    const tagObjects: Array<{
      href?: string
      onClick: (e: React.MouseEvent) => void
      text: string
    }> = []

    postObjects.groups.forEach((group) =>
      tagObjects.push({
        href: `/groups/${group.slug}`,
        onClick: cancelParentOnClick,
        text: group.title || ''
      })
    )
    postObjects.topics.forEach((topic) => {
      const group = post.groups.find((g) => g.id.toString() === topic.groupId?.toString()) // find the topics group
      const topicIds = topic.topicId ? `${topic.topicId},${topic.id}` : topic.id // add parent topics if present (for the filter list on the linked page)
      if (group) {
        tagObjects.push({
          href: `/groups/${group.slug}?topics=${topicIds}`,
          onClick: cancelParentOnClick,
          text: topic.title || ''
        })
      } else {
        tagObjects.push({
          text: topic.title || '',
          onClick: cancelParentOnClick
        })
      }
    })
    postObjects.industries.forEach((industry) =>
      tagObjects.push({
        href: `/posts?industries=${industry.slug}`,
        onClick: cancelParentOnClick,
        text: industry.name || ''
      })
    )

    return tagObjects
  }

  const tagObjects = buildHeaderTags({
    groups: post.groups,
    topics: post.topics,
    industries: post.industries
  })

  const hasNoTagObjects = tagObjects.length === 0

  return (
    <>
      <div
        className={`uk-comment-meta flex max-w-full justify-between align-top ${
          hasNoTagObjects ? 'mb-12' : ''
        }`}
      >
        <TagHeader tagObjects={tagObjects} />

        <div className="min-w-1/2 flex-shrink-0 justify-end text-right text-rb-gray-300">
          <div className="uk-text-nowrap text-rb-gray-400">
            <span className="lg:hidden">
              {post.createdAtDate}
              &nbsp;
            </span>
            <span className="hidden lg:block">
              Created {post.createdAtDate}
              &nbsp;
            </span>
          </div>
          <div className="text-rb-gray-300">
            <span className="lg:hidden">{post.lastRespondedAtInWords} ago&nbsp;</span>
            <span className="hidden lg:block">
              Updated {post.lastRespondedAtInWords} ago&nbsp;
            </span>
            {isUnread && <UnreadBadge />}
          </div>
          <div className={`pt-2 ${hasNoTagObjects ? 'text-left' : ''}`}>
            {editButtons}
          </div>
        </div>
      </div>

      <div className="my-4">
        <UserHeading
          user={post?.user?.profile}
          imgWidth="48"
          imgHeight="48"
          // prevent navigation to post first and then going to member profile
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
        />
      </div>

      <>
        <h4
          data-test="post-title"
          className="uk-link-reset mb-6 text-xl font-black hover:text-rb-teal-200"
        >
          {linksToPost ? (
            <Link
              to={postUrlObject(group, postToParam, source)}
              onClick={cancelParentOnClick}
            >
              <b>{post.title}</b>
            </Link>
          ) : (
            <>{post.title}</>
          )}
        </h4>

        {children}
      </>
    </>
  )
}

interface IPostCard {
  post: PostShowFieldsFragment
  isUnread?: boolean
  children?: React.ReactNode
  editButtons?: React.ReactNode
}

export const PostCard = ({ post, isUnread, children, editButtons }: IPostCard) => {
  return (
    <div className="uk-comment uk-comment-primary rf-rb-card">
      <PostCardLayout post={post} isUnread={isUnread} editButtons={editButtons}>
        <div
          className="post-content uk-comment-body uk-margin-bottom font-sans text-rb-gray-300"
          dangerouslySetInnerHTML={{ __html: post.body || '' }}
        />
        <BasedOn
          postId={post.id}
          basedOn={post.basedOn || ''}
          cmsSectionName={post.cmsSectionName || ''}
          cmsSectionLink={post.cmsSectionLink || ''}
          referenceImageUrl={post.referenceImageUrl || ''}
        />
        {children}
      </PostCardLayout>
    </div>
  )
}

interface ITruncatedPostCard {
  post: PostListPost
  isUnread?: boolean
  source?: string
  group?: string
  children?: React.ReactNode
}

export const TruncatedPostCard = ({
  post,
  isUnread,
  children,
  source,
  group
}: ITruncatedPostCard) => {
  const bodyBoxRef = useRef<HTMLDivElement>(null)
  const showMoreRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!bodyBoxRef.current) return
    if (!showMoreRef.current) return
    if (bodyBoxRef.current.scrollHeight > bodyBoxRef.current.clientHeight) {
      showMoreRef.current.style.visibility = 'visible'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LinkToPost post={post} className="cursor-pointer" source={source} group={group}>
      <div
        id={`post-${post.id}`}
        data-test="post-card"
        className="uk-comment md:uk-comment-primary post rf-rb-card-interactive p-2.5 pb-3.5 md:p-7"
      >
        <div className="relative mb-5 max-h-[275px] overflow-hidden" ref={bodyBoxRef}>
          <PostCardLayout
            post={post}
            isUnread={isUnread}
            source={source}
            group={group}
            linksToPost
          >
            <div
              className="post-content uk-comment-body font-sans text-rb-gray-300"
              dangerouslySetInnerHTML={{ __html: post.body || '' }}
            />
            <BasedOn
              postId={post.id}
              basedOn={post.basedOn || ''}
              cmsSectionName={post.cmsSectionName || ''}
              cmsSectionLink={post.cmsSectionLink || ''}
              referenceImageUrl={post.referenceImageUrl || ''}
            />

            <div
              className="invisible absolute bottom-0 z-10 w-full bg-truncated-content pt-16 text-center"
              ref={showMoreRef}
            />
          </PostCardLayout>
        </div>
        {children}
        <ReplyPreview replies={post.replies.slice(0, 3)} />
      </div>
    </LinkToPost>
  )
}
