import { CarouselProvider } from 'pure-react-carousel'

import { CarouselWithStore } from 'domains/Seo/SeoPageCarousel/SeoPageCarousel'
import useSeoPageSlidesCount from 'domains/Seo/useSeoPageSlidesCount'

import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'

import { BlogPost } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { cn } from 'utils/tailwind'

import SeoPageBlogPostCard from '../SeoPageBlogPostCard'

export interface RelatedBlogPostsCarouselSectionProps {
  blogPosts: BlogPost[]
  providerClassName?: string
}

const TRACKING_LOCATION = 'blog_posts__related_content_section'

export const RelatedBlogPostsCarouselSection = ({
  blogPosts,
  providerClassName
}: RelatedBlogPostsCarouselSectionProps) => {
  const isMobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)
  const slideWidth = isMobileView ? 288 : 352
  const visibleSlides = useSeoPageSlidesCount()

  return (
    <CarouselProvider
      naturalSlideWidth={slideWidth}
      naturalSlideHeight={400}
      totalSlides={blogPosts.length + 1} // +1 for the empty element
      visibleSlides={visibleSlides}
      isIntrinsicHeight={true}
      className={cn('mb-[104px] w-full', providerClassName)}
    >
      <CarouselWithStore
        isSmallGap={isMobileView}
        slideWidth={slideWidth}
        visibleSlides={visibleSlides}
        title="Related Blog Posts"
        bottomText="Explore more blog posts from top experts"
        ctaText="See all blog posts"
        ctaHref="/blog"
        navigationLocation={TRACKING_LOCATION}
      >
        {blogPosts.map((post) => (
          <SeoPageBlogPostCard
            blogPost={post}
            location={TRACKING_LOCATION}
            key={post.id}
          />
        ))}
      </CarouselWithStore>
    </CarouselProvider>
  )
}

export default RelatedBlogPostsCarouselSection
