import cx from 'classnames'
import { ReactNode } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

import { getDynamicRoute, getStaticRoute } from '../utils'

interface LinkProps {
  link: {
    title: string
    url?: string
    href?: string
    isButton?: boolean
    page?: {
      type?: string
      isHome?: boolean
      isArtifactIndex?: boolean
      slug?: string
    }
    styles?: {
      style?: any // @todo: define type
      isLarge?: boolean
      isBlock?: boolean
      align?: 'left' | 'center' | 'right'
    }
  }
  children?: ReactNode
  className?: string
}

const Link = ({ link, children, ...rest }: LinkProps) => {
  const href = link.url || link.href

  const isStatic = getStaticRoute(link.page?.type)

  const isCollection = link.page?.type === 'collection'

  const collectionCount = 'all'

  if (href) {
    // External Link
    return (
      <a
        href={href}
        target={!href.match('^mailto:|^tel:|^#') ? '_blank' : undefined}
        rel="noopener noreferrer"
        className={
          link.isButton
            ? cx('btn', link.styles?.style, {
                'is-large': link.styles?.isLarge,
                'is-block': link.styles?.isBlock,
                'ml-auto': link.styles?.align === 'right',
                'mx-auto': link.styles?.align === 'center'
              })
            : null
        }
        {...rest}
      >
        {link.title || children}
      </a>
    )
  } else {
    // Internal Page
    const isDynamic = getDynamicRoute(link.page?.type)
    const isHome = link.page?.isHome
    const isArtifactIndex = link.page?.isArtifactIndex

    return (
      <ReactRouterLink
        to={
          isHome || isArtifactIndex
            ? `/${isArtifactIndex ? 'artifacts' : ''}`
            : isStatic !== false
              ? `/${isStatic}`
              : `/${isDynamic ? `${isDynamic}/` : ''}${link.page?.slug}`
        }
        // TODO: Not sure how to implement this yet.
        // scroll={false}
        className={
          link.isButton
            ? cx('btn', link.styles?.style, {
                'is-large': link.styles?.isLarge,
                'is-block': link.styles?.isBlock,
                'ml-auto': link.styles?.align === 'right',
                'mx-auto': link.styles?.align === 'center'
              })
            : null
        }
        {...rest}
      >
        {link.title || children}

        {isCollection && (
          <span aria-hidden="true" className="collection-count">
            {collectionCount}
          </span>
        )}
      </ReactRouterLink>
    )
  }
}

export default Link
