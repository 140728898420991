const CopyrightFooter = () => (
  <footer className="bg-white p-2.5 font-sans text-[15px] text-rb-gray-400">
    <div className="text-center">
      <span>Copyright &copy; {new Date().getFullYear()} REFORGE. </span>
      <span>Sharing of content and user accounts is strictly prohibited.</span>
    </div>
  </footer>
)

export default CopyrightFooter
