import produce from 'immer'

import {
  BookmarksFromFolderDocument,
  BookmarksFromFolderQuery,
  useRestoreCollectionActivityMutation
} from 'gql'

const useHandleRestoreCollectionActivity = () => {
  const [restoreCollectionActivity] = useRestoreCollectionActivityMutation({
    update(cache, { data }) {
      const restoredCollectionActivity =
        data?.restoreCollectionActivity?.collectionActivity

      if (restoredCollectionActivity) {
        const normalizedId = cache.identify({
          id: restoredCollectionActivity.id,
          __typename: 'CollectionActivity'
        })
        cache.evict({ id: normalizedId })
        cache.gc()
      }

      const filedBookmark = data?.restoreCollectionActivity?.filedBookmark

      if (filedBookmark) {
        const existingSavedItemsData = cache.readQuery<BookmarksFromFolderQuery>({
          query: BookmarksFromFolderDocument,
          variables: {
            folderId: filedBookmark.bookmarkFolderId
          }
        })

        if (existingSavedItemsData) {
          const newSavedItemsData = produce(existingSavedItemsData, (draft) => {
            draft?.bookmarksFromFolder?.splice(
              filedBookmark.position,
              0,
              filedBookmark.bookmark
            )
          })

          cache.writeQuery<BookmarksFromFolderQuery>({
            query: BookmarksFromFolderDocument,
            variables: {
              folderId: filedBookmark.bookmarkFolderId
            },
            data: newSavedItemsData
          })
        }
      }
    }
  })

  return [restoreCollectionActivity]
}

export default useHandleRestoreCollectionActivity
