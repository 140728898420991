import {
  renderCodeBlock,
  renderH2,
  renderH3,
  renderNormal
} from '../../shared/componentTypes/Blocks'
import { ComplexPortableTextBlockProp } from '../../types'
import { renderNull } from './Common'

const renderDisplay = ({ children, value }: ComplexPortableTextBlockProp) => (
  <h1 className="mb-[72px] font-normal fluid-text-8xl" id={value?._key}>
    {children}
  </h1>
)

const block = {
  h1: renderNull,
  h2: renderH2,
  h3: renderH3,
  h4: renderNull,
  h5: renderNull,
  h6: renderNull,
  codeBlock: renderCodeBlock,
  normal: renderNormal,
  display: renderDisplay
}

export default block
