import { proxy } from 'valtio'
import { subscribeKey } from 'valtio/utils'

const initialState: any = {
  author: undefined,
  company: undefined,
  savedCount: 0,
  scrollingMenuActive: false,
  welcomeMode: {
    active: false,
    step: undefined,
    annotationId: undefined
  }
}

export const artifactState = proxy(initialState)

// TODO: define type
subscribeKey(artifactState, 'annotations', (data: any) => {
  if (data?.length) {
    artifactState.welcomeMode.annotationId = data[0].id
  }
})
