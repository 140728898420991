import { useEffect } from 'react'

const EmbeddedHubspotForm = ({
  region,
  portalId,
  formId,
  onFormSubmit,
  onFormSubmitted,
  classes
}: {
  region: string
  portalId: string
  formId: string
  onFormSubmit: ($form: any) => void
  onFormSubmitted?: ($form: any) => void
  classes?: string
}) => {
  useEffect(() => {
    if (document.getElementById('hs-script')) {
      return
    }
    const createHubspotForm = () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          region: region,
          portalId: portalId,
          formId: formId,
          target: '#hubspotForm',
          onFormSubmit: onFormSubmit,
          onFormSubmitted: onFormSubmitted
        })
      }
    }

    const script = document.createElement('script')
    script.id = 'hs-script'
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', createHubspotForm)
    return () => {
      script.removeEventListener('load', createHubspotForm)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div id="hubspotForm" className={classes} />
}

export default EmbeddedHubspotForm
