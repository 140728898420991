import Image from 'domains/Sanity/Image'
import ButtonMarketingCTA from 'domains/Sanity/marketingSite/ButtonMarketingCTA'
import { MarketingH2, MarketingH3 } from 'domains/Sanity/marketingSite/textHelpers'

import { HomepageContent } from 'gql'

import { cn } from 'utils/tailwind'

const TeamSection = ({ content }: { content: HomepageContent }) => {
  return (
    <section className="px-4 md:px-[4vw] py-[50px] sm:py-[75px] lg:py-[100px] text-center">
      <div className="mx-auto max-w-[1280px] flex flex-col items-center">
        <MarketingH2 className="mb-2.5 lg:max-w-[750px] xl:max-w-none">
          {content.teamsTitle}
        </MarketingH2>
        <MarketingH3 className="mb-[50px] lg:mb-[100px] md:max-w-[660px] lg:max-w-[890px] mx-auto">
          {content.teamsSubtitle}
        </MarketingH3>

        <div className="flex flex-col items-center justify-center md:flex-row gap-[50px] xl:gap-[100px]">
          <Image
            src={content.teamsBenefitsImage.imageUrl || ''}
            alt={content.teamsBenefitsImage.imageAlt || ''}
            className="h-auto w-full max-w-[300px] lg:max-w-[400px] xl:max-w-[500px]"
          />

          <div className="w-full flex flex-col items-center sm:max-w-[490px] md:max-w-[320px] lg:max-w-[480px] gap-[25px] sm:gap-10 md:gap-6 lg:gap-10">
            {content.teamsBenefits.map((item, i) => (
              <div key={i}>
                <h2 className="mb-2.5 text-2xl lg:text-4xl !leading-[1] tracking-[-0.48px] lg:tracking-[-0.72px]">
                  {item.title}
                </h2>
                <MarketingH3
                  as="p"
                  className={cn(
                    'mb-0',
                    i === content.teamsBenefits.length - 1 && 'mb-[15px] sm:mb-0'
                  )}
                >
                  {item.description}
                </MarketingH3>
              </div>
            ))}

            <ButtonMarketingCTA
              href={content.teamsCta.ctaLink}
              location="marketing_homepage__teams_section"
            >
              {content.teamsCta.ctaText}
            </ButtonMarketingCTA>
          </div>
        </div>
      </div>
    </section>
  )
}
export default TeamSection
