import { Redirect } from 'react-router-dom'

import GroupCard from 'domains/Group/GroupCard'
import ArchivedBanner from 'domains/Post/ArchivedBanner'

import { SkeletonGroupCard } from 'components/skeletons/cards/SkeletonGroupCard'
import SkeletonRfParagraph from 'components/skeletons/typography/SkeletonRfParagraph'

import { useGroupsListQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

const GroupCardsSkeleton = () => (
  <>
    <h5 className="mb-5 mt-[15px]">
      <SkeletonRfParagraph className="h-5 max-w-[300px]" />
    </h5>
    <div uk-grid="" className="uk-grid-small uk-child-width-1-4@m">
      {[...Array(16)].map((_, index) => (
        <div key={`group_${index}`} className="mb-5 w-72 xl:pr-4">
          <SkeletonGroupCard className="min-h-[208px] tl:min-h-[237px]" />
        </div>
      ))}
    </div>
  </>
)

const GroupsPage = () => {
  const { data, loading } = useGroupsListQuery()
  const groups = data?.groups.groups || []
  const { currentUser, currentUserLoading } = useCurrentUser()

  if (!currentUserLoading && (!currentUser || !currentUser.can.viewDiscussionIndex)) {
    return <Redirect to="/" />
  }

  const groupCards = (categoryName: string) => {
    const categoryGroups = groups.filter((g) => {
      return g.groupCategory?.category === categoryName
    })
    return (
      <>
        <h5
          className="mt-4 mb-5 text-lg font-semibold text-rb-gray-400"
          style={{ marginTop: '15px' }}
        >
          {categoryGroups[0]?.groupCategory?.description}
        </h5>
        <div uk-grid="" className="uk-grid-small uk-child-width-1-4@m">
          {categoryGroups.map((group) => (
            <div key={`group_${group.id}`} className="w-72 xl:pr-4">
              <GroupCard
                key={`group_${group.id}`}
                group={group}
                className="min-h-[208px] tl:min-h-[237px]"
              />
            </div>
          ))}
        </div>
      </>
    )
  }

  return (
    <>
      <ArchivedBanner className="mb-4" currentUser={currentUser} />
      <div
        className="ml-0 bg-rb-gray-50 pr-2.5 pl-2.5 pt-0 xs:pr-4 xs:pl-4 md:pr-8 md:pl-8"
        uk-grid=""
      >
        <div
          className="uk-width-1-1 uk-margin-top pl-0"
          uk-height-match="target: .group-card--small; row: false"
        >
          <h4
            className="mb-7 text-xl font-semibold text-black "
            style={{ marginBottom: '30px' }}
          >
            Join a Group
          </h4>
          {loading && <GroupCardsSkeleton />}
          {groups.length === 0 && !loading && <p>There are no groups at the moment</p>}
          {groups.length > 0 && (
            <>
              {groupCards('Topic')}
              {groupCards('Functions + Roles')}
              {groupCards('Business Model')}
              {groupCards('Company Audience')}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default GroupsPage
