import { cn } from '@/lib/utils'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import Image from 'domains/Sanity/Image'

import Button from 'components/Button'

import { MAX_WIDTH_TAILWIND_TL } from 'constants/breakpoints'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'
import useURLParams from 'hooks/useURLParams'

import { trackCtaClicked, trackNavigationClicked } from 'utils/tracking/analytics'

import Logo from 'images/reforge-logo-centered.svg'
import LogoSymbol from 'images/reforge-logo-symbol-black.svg'

import ButtonMarketingCTA from '../ButtonMarketingCTA'
import BackButton from './BackButton'
import NavItem, { NavItemType } from './NavItem'

const NAV_ITEMS: NavItemType[] = [
  {
    title: 'Artifacts',
    url: '/artifacts'
  },
  {
    title: 'Guides',
    url: '/guides'
  },
  {
    title: 'Courses',
    url: '/courses'
  },
  {
    title: 'Events',
    url: '/events'
  },
  {
    title: 'Pricing',
    url: '/pricing'
  },
  {
    title: 'For Teams',
    url: '/teams'
  },
  {
    title: 'More',
    dropdown: [
      {
        title: 'Experts',
        url: '/experts'
      },
      {
        title: 'Podcast',
        url: '/podcast/unsolicited-feedback'
      },
      {
        title: 'Blog',
        url: '/blog'
      },
      {
        title: 'Extension',
        url: '/extension'
      }
    ]
  }
]

interface MarketingHeaderProps {
  transparentNav?: boolean
}

/**
 * @deprecated
 * To be removed together with the showLoggedOutSearch feature flag
 */
const MarketingHeaderOld = ({ transparentNav }: MarketingHeaderProps) => {
  const { currentUser, isLoggedIn } = useCurrentUser()
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const [shouldTransition, setShouldTransition] = useState(false)
  const [activeDropdownUrl, setActiveDropdownUrl] = useState<string | null | undefined>(
    null
  )
  const [dropdown, setDropdown] = useState<NavItemType | null>(null)
  const [showNavBg, setShowNavBg] = useState(false)
  const isMobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_TL})`)

  const navItems = NAV_ITEMS
  const scrollThreshold = 50 // px

  useEffect(() => {
    if (mobileNavOpen) {
      document.documentElement.classList.add('overflow-hidden')
    } else {
      // reset the dropdown when the mobile nav is closed, with a delay to allow the animation to complete
      setTimeout(() => {
        setDropdown(null)
      }, 300)
      document.documentElement.classList.remove('overflow-hidden')
    }
  }, [mobileNavOpen])

  useEffect(() => {
    // reset mobile view when the window is resized to desktop
    if (!isMobileView) {
      setMobileNavOpen(false)
      setShouldTransition(false)
      setDropdown(null)
      setActiveDropdownUrl(null)
    }
  }, [isMobileView])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > scrollThreshold) {
        setShowNavBg(true)
      } else {
        setShowNavBg(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const onHamburgerClick = () => {
    setMobileNavOpen(!mobileNavOpen)
    setShouldTransition(true)
  }

  return (
    <header
      className={cn(
        'fixed top-0 left-0 right-0 z-1010 flex items-center justify-between px-[16px] h-14 lg:h-[76px] transition-all md:px-[4vw]',
        transparentNav && !mobileNavOpen && !showNavBg ? 'bg-transparent' : 'bg-rb-white'
      )}
    >
      <ButtonMarketingCTA
        href="#content"
        className="fixed top-[20px] left-[16px] -translate-x-[200%] focus:translate-x-0"
      >
        Skip to Content
      </ButtonMarketingCTA>

      <Link
        to="/"
        className="flex items-center"
        onClick={() =>
          trackNavigationClicked({
            location: 'marketing_site__top_nav',
            type: 'top navigation',
            destination: '/',
            text: '-logo-'
          })
        }
      >
        <Image
          src={LogoSymbol}
          alt="Reforge Logo"
          height={40}
          width={40}
          className="max-h-5 w-auto max-w-full sm:hidden"
        />

        <Image
          src={Logo}
          alt="Reforge Logo"
          height={36}
          width={151}
          className="hidden max-h-8 lg:max-h-9 w-auto max-w-full sm:block"
        />
      </Link>

      <div className="flex items-center">
        {isMobileView && !isLoggedIn && (
          <div className="mr-4 flex items-center gap-4">
            <LogInButton />
            <SignUpButton />
          </div>
        )}

        <button
          type="button"
          className="cursor-pointer bg-transparent lg:hidden"
          onClick={onHamburgerClick}
        >
          <span className="hidden">{mobileNavOpen ? 'Close' : 'Open'} Menu</span>
          <div className="relative h-[40px] w-[40px]">
            <div
              className={twMerge(
                'absolute top-1/2 left-[3px] h-[1px] w-[34px] translate-y-[-6px] bg-rb-black transition-all duration-[250ms] ease-[cubic-bezier(.2,.6,.3,1)]',
                mobileNavOpen &&
                  'w-[28px] translate-x-[3px] translate-y-0 -rotate-[135deg]'
              )}
            />
            <div
              className={twMerge(
                'absolute top-1/2 left-[3px] h-[1px] w-[34px] translate-y-[6px] bg-rb-black transition-all duration-[250ms] ease-[cubic-bezier(.2,.6,.3,1)]',
                mobileNavOpen &&
                  'w-[28px] translate-x-[3px] translate-y-0 rotate-[135deg]'
              )}
            />
          </div>
        </button>
      </div>

      <div
        className={twMerge(
          'invisible fixed bottom-0 left-0 right-0 -z-1 top-14 flex translate-y-20 flex-col overflow-y-auto overflow-x-hidden bg-rb-white opacity-0 transition-none duration-300 ease-in-out lg:visible lg:static lg:!top-0 lg:z-0 lg:translate-y-0 lg:flex-row lg:overflow-visible lg:opacity-100 lg:transition-none',
          transparentNav && !mobileNavOpen && 'bg-transparent',
          mobileNavOpen && 'visible translate-y-0 opacity-100',
          shouldTransition && 'transition-all lg:transition-none'
        )}
      >
        <nav
          className={twMerge(
            'grid h-full grid-cols-1 grid-rows-[1fr_auto] items-start p-4 duration-300 md:px-[4vw] lg:flex lg:flex-row lg:px-0',
            dropdown && '-translate-x-[calc(100%)]'
          )}
        >
          <div className="relative w-full">
            <ul
              className={twMerge(
                'flex h-full list-none flex-col items-start p-0 lg:flex-row'
              )}
            >
              {navItems.map((item) => (
                <NavItem
                  item={item}
                  key={item.url || item.title}
                  linkClassName="peer"
                  onClick={
                    item.dropdown && isMobileView
                      ? (e) => {
                          e.preventDefault()
                          setDropdown(item)
                          setActiveDropdownUrl(item.url)
                        }
                      : undefined
                  }
                  skipTab={mobileNavOpen && !isMobileView && !!item.dropdown}
                >
                  <div
                    className={twMerge(
                      'absolute top-0 bottom-0 left-0 right-0 -z-1 translate-x-[calc(100%+1rem)] bg-rb-white px-4 md:translate-x-[calc(100%+4vw)] md:px-[4vw] lg:top-full lg:bottom-auto lg:left-auto lg:-right-[16px] lg:z-0 lg:translate-x-[9999px] lg:px-0 lg:focus-within:translate-x-0 lg:hover:translate-x-0 lg:peer-hover:translate-x-0',
                      activeDropdownUrl === item.url && 'z-1'
                    )}
                  >
                    {item.dropdown && (
                      <ul className="flex h-full min-w-[220px] list-none flex-col items-center p-0 lg:items-end lg:px-[16px] lg:py-[8px]">
                        <BackButton
                          onClick={() => {
                            setDropdown(null)
                            setTimeout(() => {
                              setActiveDropdownUrl(null) // remove the dropdown container z-index(1) after the dropdown close animation completes
                            }, 300)
                          }}
                          skipTab={!dropdown}
                        />

                        {Array.isArray(item.dropdown) &&
                          item.dropdown.map((subItem) => (
                            <NavItem
                              item={subItem}
                              key={subItem.url}
                              skipTab={mobileNavOpen && isMobileView && !dropdown}
                              linkClassName="lg:justify-end"
                              containerClassName="lg:ml-0"
                            />
                          ))}
                      </ul>
                    )}
                  </div>
                </NavItem>
              ))}

              {!isLoggedIn && !isMobileView && <LogInButton />}
            </ul>
          </div>
        </nav>
        <div
          className={twMerge(
            'flex items-center px-4 md:px-[4vw] lg:w-auto lg:border-t-0 lg:px-0',
            isLoggedIn && 'border-t border-t-rb-gray-200'
          )}
        >
          {isLoggedIn ? (
            <Link
              to="/account"
              className="flex items-center py-6 lg:ml-4 lg:w-[30px] lg:py-0"
              onClick={() =>
                trackNavigationClicked({
                  location: 'marketing_site__top_nav',
                  type: 'top navigation',
                  destination: '/account',
                  text: currentUser?.fullName || '-user full name missing-'
                })
              }
            >
              <Image
                width={30}
                height={30}
                src={currentUser?.profile.avatarUrl}
                className="h-full max-h-[30px] w-full max-w-[30px] rounded-full object-cover"
              />

              <span className="ml-4 text-base text-rb-black underline hover:text-rb-teal-200 lg:hidden">
                {currentUser?.fullName}
              </span>
            </Link>
          ) : !isMobileView ? (
            <SignUpButton />
          ) : null}
        </div>
      </div>
    </header>
  )
}

const LogInButton = () => {
  const { pathname } = useLocation()
  const { getQueryString } = useURLParams()

  const getUrl = () => {
    const referer = encodeURIComponent(pathname)
    const existingParams = getQueryString()
    return `/login?marketingNav=true&referer=${referer}&${existingParams}`
  }

  return (
    <NavItem
      item={{
        title: 'Log in',
        url: getUrl()
      }}
      linkClassName="text-base"
    />
  )
}

const SignUpButton = () => {
  const { pathname } = useLocation()
  const ctaText = 'Start trial'
  const destination = '/login/?reforgeLogin=true'
  return (
    <Button
      size="small"
      className="ml-2 max-h-10 shrink-0 px-4 text-sm"
      href={destination}
      onClick={() =>
        trackCtaClicked({
          cta_location: `${pathname} top nav`,
          cta_type: 'button',
          destination: destination,
          text: ctaText.toLowerCase()
        })
      }
    >
      {ctaText}
    </Button>
  )
}

export default MarketingHeaderOld
