import { onEnterKeyPress } from 'utils/keyboard'

export const CarouselDot = ({
  active,
  onClick
}: {
  active: boolean
  onClick: () => void
}) => {
  return (
    <div
      role="button"
      tabIndex={0}
      onKeyUp={onEnterKeyPress(onClick)}
      onClick={onClick}
      className={`h-3 w-3 rounded-full ${
        active ? 'bg-rb-green-100' : 'bg-rb-gray-100 hover:bg-rb-gray-200'
      }`}
    ></div>
  )
}
