import { useCallback, useState } from 'react'

import BadgeableAvatar from 'components/BadgeableAvatar'
import Button from 'components/Button'
import { useGlobalModal } from 'components/GlobalModal'
import { SVGIcon } from 'components/Icon'
import { ModalContent, ModalFooter, ModalHeader, ModalTitle } from 'components/Modal'
import { Tag } from 'components/Tag'
import { ToggleSwitch } from 'components/ToggleSwitch'
import Dropdown from 'components/dropdowns/Dropdown'
import DropdownContextMenu from 'components/dropdowns/Dropdown/DropdownContextMenu'
import { CloseIcon } from 'components/icons'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'

import {
  UserAvatarPartsFragment,
  useBookmarkFolderByIdQuery,
  useTeamMembersQuery
} from 'gql'

import useDebouncedStringState from 'hooks/useDebouncedStringState'

import { onEnterKeyPress } from 'utils/keyboard'

import useShareCollection from './hooks/useShareCollection'

interface ShareCollectionModalProps {
  currentFolderId: string
}

type UserParts = Omit<UserAvatarPartsFragment, 'email'>

/**
 * Usage:
 * ```tsx
 *  const { openShareCollectionModal } = useShareCollectionModal()
 *  const handleShare = () => {
 *    openShareCollectionModal({
 *     currentFolderId: '123'
 *    })
 *  }
 * ```
 */
const ShareCollectionModal = ({ currentFolderId }: ShareCollectionModalProps) => {
  const [search, setSearch] = useDebouncedStringState()
  const [selectedUsers, setSelectedUsers] = useState<UserParts[]>([])
  const { closeGlobalModal } = useGlobalModal()
  const { data: bookmarkFolderData } = useBookmarkFolderByIdQuery({
    variables: { folderId: currentFolderId },
    fetchPolicy: 'cache-first'
  })
  const { handleToggleShare, unshareWithUser, shareWithUsers } = useShareCollection()

  const bookmarkFolder = bookmarkFolderData?.bookmarkFolder

  const { loading, data } = useTeamMembersQuery({
    variables: {
      limit: 4,
      offset: 0,
      search
    },
    nextFetchPolicy: 'network-only'
  })

  if (!bookmarkFolder) return null

  const onClose = () => {
    closeGlobalModal()
  }

  const addUserToSelectedUsers = (user: UserParts) => {
    if (selectedUsers.map((u) => u.id).includes(user.id)) return

    setSelectedUsers([...selectedUsers, user])
  }

  const saveSharingChoices = async () => {
    shareWithUsers(bookmarkFolder, selectedUsers)
    setSelectedUsers([])
    setSearch('')
  }

  const onToggleShare = () => {
    handleToggleShare(bookmarkFolder)
  }

  const subSharedFolder = bookmarkFolder.sharedFolders?.find(
    (sf) => sf.sharedPartyType === 'Subscription'
  )
  const internalIsShared = subSharedFolder?.status === 'shared'
  const usersSharedWith = bookmarkFolder.individualUsersSharedWith || []

  const searchUsers =
    data?.currentUser?.subscriptions?.active?.teamMembers?.users?.filter(
      (user) =>
        !selectedUsers.map((u) => u.id).includes(user.id) &&
        !usersSharedWith.map((u) => u.id).includes(user.id) &&
        user.id !== data?.currentUser?.id
    )

  const inputElem = (
    <input
      type="search"
      name="teamMemberSearch"
      placeholder="Enter name"
      onChange={(event) => setSearch(event.target.value)}
      className="h-10 w-full outline-none"
      autoComplete="off"
    />
  )

  const cheveronElem = (
    <SVGIcon
      name="thin-chevron-down"
      fill="currentColor"
      stroke="currentColor"
      width="15"
      className="ml-auto cursor-pointer text-rb-gray-400 opacity-0 transition-opacity lg:mr-4 lg:opacity-100"
    />
  )

  return (
    <>
      <ModalHeader className="mb-8 px-0 md:px-0">
        <ModalTitle className="text-lg font-semibold md:text-xl">
          Share this Collection
        </ModalTitle>
      </ModalHeader>
      <ModalContent className="px-0 md:px-0">
        <div className="mb-8 flex items-center">
          <div className="mr-3 w-full rounded border border-rb-gray-100 pl-3 pr-5 ">
            <div>
              {selectedUsers.map((user) => (
                <Tag
                  key={`selected-${user.id}`}
                  text={user?.fullName || ''}
                  className="mr-2 mt-2 bg-rb-gray-300 font-normal hover:bg-rb-gray-100 sm:text-sm"
                  contentAfter={<CloseIcon className="ml-1 w-3.5" />}
                  onClick={() =>
                    setSelectedUsers(selectedUsers.filter((u) => u !== user))
                  }
                />
              ))}
            </div>
            <Dropdown
              positions={['bottom']}
              triggerElement={inputElem}
              dismissOnClick={false}
            >
              <div>
                <div className="border border-rb-gray-250 bg-white py-2 px-4">
                  {searchUsers?.slice(0, 5).map((user) => (
                    <div
                      role="checkbox"
                      key={user.id}
                      tabIndex={0}
                      onKeyUp={onEnterKeyPress(() => addUserToSelectedUsers(user))}
                      aria-checked={false}
                      onClick={() => addUserToSelectedUsers(user)}
                      className="h-10 w-full cursor-pointer py-1 hover:bg-rb-gray-50"
                    >
                      {user.fullName}
                    </div>
                  ))}
                  {searchUsers?.length === 0 && <div>No team members found</div>}
                  {loading && <div>Loading...</div>}
                </div>
              </div>
            </Dropdown>
          </div>
          <Button
            disabled={selectedUsers.length === 0}
            className="h-10"
            onClick={saveSharingChoices}
          >
            Add
          </Button>
        </div>
        <div className="mb-6">
          <div className="mb-3 flex items-center">
            <BadgeableAvatar
              avatarUrl={bookmarkFolder.user.profile.avatarUrl || ''}
              className="mr-4 transition-width-margin duration-400 ease-in-out"
              width="40"
              height="40"
              fullName={bookmarkFolder.user.fullName}
            />
            <div className="flex-1">{bookmarkFolder.user.fullName}</div>
            <div>Owner</div>
          </div>
          {usersSharedWith.map((user) => {
            const sharedFolder = bookmarkFolder?.sharedFolders.find(
              (sf) => sf.sharedPartyId === user.id && sf.sharedPartyType === 'User'
            )
            return (
              <div className="mb-3 flex items-center" key={user.id}>
                <BadgeableAvatar
                  avatarUrl={user.profile.avatarUrl}
                  className="mr-4 transition-width-margin duration-400 ease-in-out"
                  width="40"
                  height="40"
                  fullName={user.fullName}
                />
                <div className="flex-1">{user.fullName}</div>
                <DropdownContextMenu
                  triggerElement={cheveronElem}
                  positions={['bottom']}
                  dismissOnClick={true}
                >
                  <DropdownContextMenu.DropdownItem
                    text="Remove Access"
                    onClick={() => unshareWithUser(sharedFolder)}
                  />
                </DropdownContextMenu>
              </div>
            )
          })}
        </div>
        <div className="flex items-center gap-y-6 px-0 md:px-0">
          <div>
            <RfParagraphMediumBold>Share with your Team</RfParagraphMediumBold>
            <RfParagraphMini>
              Sharing will give everyone on your Reforge subscription view and edit access
            </RfParagraphMini>
          </div>
          <ToggleSwitch
            toggled={internalIsShared}
            handleClick={onToggleShare}
            className=""
          />
        </div>
      </ModalContent>
      <ModalFooter className="mt-2.5 flex justify-end border-t py-0 pb-0 pt-2.5 md:mt-6 md:py-0 md:px-0 md:pb-0 md:pt-6">
        <Button variant="text-only" onClick={onClose}>
          Close
        </Button>
        <Button onClick={onClose}>Done</Button>
      </ModalFooter>
    </>
  )
}

export const useShareCollectionModal = () => {
  const { openGlobalModal, closeGlobalModal } = useGlobalModal()

  const openShareCollectionModal = useCallback((props: ShareCollectionModalProps) => {
    openGlobalModal(<ShareCollectionModal {...props} />, {
      className: 'max-w-lg rounded-2xl p-8 pb-6',
      header: false,
      closeOnEscape: true,
      closeOnOutsideClick: true
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    openShareCollectionModal,
    closeShareCollectionModal: closeGlobalModal
  }
}

export default ShareCollectionModal
