import React from 'react'

import { PurchaseFlowContainer } from './PurchaseFlowContainer'

export const TwoColumnLayout = ({ children }: { children: React.ReactNode[] }) => {
  return (
    <PurchaseFlowContainer>
      <div className="flex flex-col items-center justify-center lg:flex-row lg:items-start">
        {children}
      </div>
    </PurchaseFlowContainer>
  )
}
