import { useEffect } from 'react'

import Button from 'components/Button'
import Modal from 'components/Modal'
import { CloseIcon, LinkedIn, TwitterBird } from 'components/icons'
import RfHeader3 from 'components/typography/RfHeader/RfHeader3'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import { CourseCompletionSnapshot, CourseEnrollmentSnapshot } from 'gql'

import {
  trackShareableSnapshotButtonClicked,
  trackShareableSnapshotModalDismissed,
  trackShareableSnapshotModalShown
} from 'utils/tracking/generated/events'

interface CourseSnapshotShareModalProps {
  isOpen: boolean
  onClose: () => void
  snapshot?: CourseEnrollmentSnapshot | CourseCompletionSnapshot | null
  courseTitle: string
  courseId: string
  courseSessionId: string
  page: string
}

const CourseSnapshotShareModal = ({
  isOpen,
  onClose,
  snapshot,
  courseTitle,
  courseId,
  courseSessionId,
  page
}: CourseSnapshotShareModalProps) => {
  useEffect(() => {
    if (isOpen) {
      trackShareableSnapshotModalShown({
        page: page,
        course_id: courseId,
        course_session_id: courseSessionId
      })
    }
  }, [courseId, courseSessionId, isOpen, page])

  if (!isOpen) return null

  const getHeaderCopy = () => {
    switch (snapshot?.__typename) {
      case 'CourseEnrollmentSnapshot':
        return {
          header: `You’re enrolled in ${courseTitle}!`,
          subheader:
            'Share with your network so your peers can follow along with your learning.'
        }
      case 'CourseCompletionSnapshot':
        return {
          header: `Congrats! You completed the ${courseTitle} course.`,
          subheader: 'Share with your network so your peers can see what you learned.'
        }
      default:
        return {
          header: '',
          subheader: ''
        }
    }
  }

  const headerCopy = getHeaderCopy()

  const handleLinkedInClick = () => {
    if (!snapshot?.linkedinShareUrl) return

    trackShareableSnapshotButtonClicked({
      page: page,
      platform: 'LinkedIn',
      course_id: courseId,
      course_session_id: courseSessionId
    })

    window.open(
      snapshot.linkedinShareUrl,
      '_blank',
      'width=500,height=730,toolbar=no,location=no,status=no,menubar=no'
    )
  }

  const handleTwitterClick = () => {
    if (!snapshot?.twitterShareUrl) return

    trackShareableSnapshotButtonClicked({
      page: page,
      platform: 'Twitter',
      course_id: courseId,
      course_session_id: courseSessionId
    })

    window.open(snapshot.twitterShareUrl)
  }

  const handleModalClose = () => {
    trackShareableSnapshotModalDismissed({
      page: page,
      course_id: courseId,
      course_session_id: courseSessionId
    })

    onClose()
  }

  if (!snapshot?.imageUrl) return null

  return (
    <Modal
      isOpen
      handleClose={handleModalClose}
      containerClass="z-[1011]"
      className="relative w-full max-w-[688px] rounded p-8"
      scrollContent={false}
      header={false}
    >
      <button onClick={handleModalClose} className="absolute top-4 right-4">
        <CloseIcon className="h-6 w-6" />
      </button>

      <RfHeader3 className="mb-2 !font-semibold">{headerCopy.header}</RfHeader3>

      <RfParagraphMedium className="mt-8">{headerCopy.subheader}</RfParagraphMedium>

      <img
        src={snapshot.imageUrl}
        className="my-8 h-auto w-full rounded"
        alt="Course enrollment snapshot"
      />

      <div className="flex items-center justify-center gap-4">
        <Button
          size="small"
          iconBefore={<LinkedIn fill="white" />}
          onClick={handleLinkedInClick}
        >
          Share on LinkedIn
        </Button>
        <Button
          size="small"
          iconBefore={<TwitterBird fill="white" />}
          onClick={handleTwitterClick}
        >
          Share on Twitter
        </Button>
      </div>
    </Modal>
  )
}

export default CourseSnapshotShareModal
