import { useState } from 'react'

import TextBlockFroalaWrapper from 'domains/Collections/TextBlockFroalaWrapper'

import Button from 'components/Button'
import Tooltip from 'components/Tooltip/Tooltip'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import { onEnterKeyPress } from 'utils/keyboard'

import { ReactComponent as TrashIcon } from 'images/icon--trash.svg'

interface CollectionTextBlockInputProps {
  onSubmit: (inputText: string) => Promise<void> | void
  onDelete: () => Promise<void> | void
  onClose: () => void
  initialTextInput?: string | null
}

const CollectionTextBlockInput = ({
  onSubmit,
  onDelete,
  onClose,
  initialTextInput
}: CollectionTextBlockInputProps) => {
  const [inputText, setInputText] = useState(initialTextInput || '')
  const mentionContainerId = 'text-block-menu-container'

  const handleSubmit = () => {
    onSubmit(inputText.trim())
    if (initialTextInput) {
      setInputText(inputText)
    } else {
      setInputText('')
    }
  }

  return (
    <>
      <div className="group sticky bottom-[24px] z-10 m-6 flex max-h-[500px] w-full flex-col overflow-visible rounded-[8px] p-6 shadow-cohort-post-card">
        <div id={mentionContainerId} className="relative top-[-150px]" />
        <TextBlockFroalaWrapper
          model={inputText}
          updateModel={setInputText}
          menuContainerId={mentionContainerId}
          placeholder="Write something..."
          className="reply-post w-0 min-w-full grow"
          heightMax={200}
        />
        <div className="flex justify-end">
          <div
            className="cursor-pointer opacity-0 transition-opacity duration-500 group-hover:opacity-100"
            role="button"
            tabIndex={0}
            onClick={onDelete}
            onKeyUp={onEnterKeyPress(onDelete)}
          >
            <Tooltip tooltipBody="Delete Text Block">
              <TrashIcon width="10" height="12" fill={ColorOptions.black} />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <Button className="ml-4 mb-2" onClick={handleSubmit}>
          Submit
        </Button>
        <Button className="ml-4" onClick={onClose} variant="outline">
          Close
        </Button>
      </div>
    </>
  )
}

export default CollectionTextBlockInput
