import { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import { Loading } from 'components'

import { useConfirmUserMergeMutation } from 'gql'

const ConfirmUserMerge = () => {
  const { nonce } = useParams<{ nonce: string }>()
  const [confirmUserMerge, { data, loading }] = useConfirmUserMergeMutation()

  useEffect(() => {
    confirmUserMerge({ variables: { input: { nonce } } })
  }, [nonce, confirmUserMerge])

  if (loading) {
    return <Loading />
  }

  const success = data?.confirmUserMerge?.success
  const userMerge = data?.confirmUserMerge?.userMerge

  return (
    <div className="mx-auto mt-5 w-1/2 text-center">
      {success ? (
        <>
          <h3>
            All set. You&apos;ve merged your account: {userMerge?.mergedPrimaryEmail}.
          </h3>
          <p>
            <Link to="/">Click to go to dashboard</Link>.
          </p>
        </>
      ) : (
        <>
          <h3>Account Merge Failed</h3>
          <p>
            We were unable to merge this account. Please try again or reach out to{' '}
            <a href="mailto:hello@reforge.com">hello@reforge.com</a>.
          </p>
        </>
      )}
    </div>
  )
}

export default ConfirmUserMerge
