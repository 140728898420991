import { useEffect } from 'react'

import getRefererUrl from 'utils/url/getRefererUrl'

import ConfettiAnimationWithLoader from '../../../domains/Cms/ModalCelebration/components/ConfettiAnimationWithLoader'
import { usePurchaseFlowContext } from '../contexts/PurchaseFlowContext'

export const PurchaseCompleted = ({ isStorybook }: { isStorybook?: boolean }) => {
  const { isReactivating, referer } = usePurchaseFlowContext()
  const DEFAULT_REDIRECT = '/'
  const refererUrl = getRefererUrl(referer, { purchaseSuccessful: true })
  const href = refererUrl || DEFAULT_REDIRECT

  useEffect(() => {
    setTimeout(() => {
      // we use window.location.href instead of history.push because we need to reload the page after subscription creation
      if (!isStorybook) {
        window.location.href = href
      }
    }, 5000)
  })

  return (
    <div className="flex flex-col px-4 text-center justify-center h-[80dvh]">
      <ConfettiAnimationWithLoader percentComplete={100} />
      <div className="mt-4 flex flex-col items-center">
        <div className="pt-[20px] pb-[10px] text-xl font-semibold">
          {isReactivating
            ? 'Congrats on continuing your Reforge membership!'
            : 'Congrats on becoming a Reforge member!'}
        </div>
        <p>You&apos;ll be redirected to your account in a few seconds...</p>
        <p>
          Click <a href={href}>here </a> if you&apos;re not redirected
        </p>
      </div>
    </div>
  )
}
