import Image from 'domains/Sanity/Image'
import { SEOTrackingRelatedIdentifiers } from 'domains/Seo/helpers'

import Button from 'components/Button'

import { MIN_WIDTH_TAILWIND_LG } from 'constants/breakpoints'

import useMediaQuery from 'hooks/useMediaQuery'

import { trackCtaClicked } from 'utils/tracking/analytics'

import CtaImage from 'images/artifact-seo-cta.png'

export interface SeoPageCtaSectionProps {
  ctaText: string
  ctaHref: string
  tracking: SEOTrackingRelatedIdentifiers
  ctaLocation: string
}

export const SeoPageCtaSection = ({
  ctaText,
  ctaHref,
  tracking,
  ctaLocation
}: SeoPageCtaSectionProps) => {
  const shouldShowImage = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_LG})`)

  const onCtaClick = () => {
    trackCtaClicked({
      cta_type: 'button',
      destination: ctaHref,
      cta_location: ctaLocation,
      text: ctaText.toLowerCase(),
      related_identifiers: {
        sanity_type: tracking.sanityType,
        sanity_id: tracking.sanityId,
        sanity_name: tracking.sanityName
      }
    })
  }

  return (
    <div className="mb-[104px] flex rounded-2xl bg-rb-orange-25 py-8 px-6 lg:px-10">
      <div className="pr-10">
        <h3 className="pb-2 text-2xl tl:text-3xl lg:mt-8 lg:pt-12 lg:text-[40px] lg:leading-[120%]">
          Reforge has everything you need to get more done, faster
        </h3>
        <div className="pb-6 text-sm lg:text-xl">
          Find hundreds of real-life examples, courses, and a world-class community of
          peers to solve your hardest problems.
        </div>
        <Button className="py-3 px-10" color="teal" href={ctaHref} onClick={onCtaClick}>
          {ctaText}
        </Button>
      </div>
      {shouldShowImage && (
        <Image className="-mr-6 w-[370px] lg:-mr-10" src={CtaImage} alt="" priority />
      )}
    </div>
  )
}

export default SeoPageCtaSection
