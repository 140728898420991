import { closing, opening } from './sharedMarkdown'

const programName = 'Scaling Product Delivery'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://www.reforge.com/scaling-product-delivery), because I will learn how to create a system for product and engineering teams to validate ideas, scope projects, implement new processes, and launch big bets repeatedly. This course was built with [Andy Johns](https://www.reforge.com/experts/andy-johns) and [Matt Greenberg](https://www.reforge.com/experts/matt-greenberg), and covers:
* **Building Conviction.** Develop compelling solutions to solve customer problems. Rapidly build and rigorously test prototypes that deliver clear moments of delight. Build conviction around the strategic idea.    
* **Scoping a Dynamic Plan.** Evaluate business impact and feasibility for innovative ideas before investing in the project. Build large-scale project plans and establish a system to reduce risk over time. 
* **Aligning and Implementing Processes.** Align stakeholders through critical meetings that get buy-in along the way. Keep everyone motivated with bottoms-up implementation.
* **Launching and Scaling Products.** Assess product readiness to eliminate risk before launching your products with fanfare that excites the company and your customers. Prioritize debt and ongoing maintenance.

&NewLine;

${closing}
`

export default { markdown, title: programName }
