import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface ContainerProps {
  headerBarBgClass: string
  isPlanCardHighlighted?: boolean
  highlightedHeaderText?: string
  children: ReactNode
}

export const Container = ({
  headerBarBgClass,
  isPlanCardHighlighted,
  highlightedHeaderText,
  children
}: ContainerProps) => {
  const containerClasses = twMerge(
    'relative box-border flex w-full min-w-[250px] max-w-[290px] grow-0 flex-col rounded border border-rb-gray-250 bg-rb-white p-4 pt-10 md:w-72 xl:w-1/4'
  )

  const headerClasses = twMerge(
    'absolute top-0 left-0 flex h-4 w-full items-center justify-center rounded-t text-center text-xs font-medium text-rb-black',
    headerBarBgClass,
    isPlanCardHighlighted ? '-top-3 left-1/2 h-6 w-4/5 -translate-x-1/2 rounded' : ''
  )

  return (
    <div className={containerClasses}>
      <div className={headerClasses}>
        {isPlanCardHighlighted && highlightedHeaderText}
      </div>
      {children}
    </div>
  )
}
