import { MutableRefObject, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import Button from 'components/Button'
import SkipBackIcon from 'components/icons/SkipBackIcon'
import SkipForwardIcon from 'components/icons/SkipForwardIcon'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'

import { isIOS } from 'utils/device.utils'

import { ReactComponent as Megaphone } from 'images/icon--megaphone-white.svg'
import { ReactComponent as PauseIcon } from 'images/icon--pause-outlined.svg'
import { ReactComponent as PlayIcon } from 'images/icon--play-outlined.svg'

import { AudioPlayerTrackingMetadata, useTrackAudioPlayer } from './useTrackAudioPlayer'

const controlTypeVolume = 'volume'
const controlTypePlayPause = 'playPause'
const controlTypePlaybackSpeed = 'playbackSpeed'
const defaultControlType = controlTypePlayPause

const playbackSpeeds = [0.8, 1, 1.2, 1.5, 2]
const skipBackSeconds = 15
const skipForwardSeconds = 30

interface ControlsProps {
  audioRef: MutableRefObject<HTMLAudioElement>
  trackingMetadata: AudioPlayerTrackingMetadata
  onEnded?: (audioRef?: MutableRefObject<HTMLAudioElement>) => void
}

export const Controls = ({
  audioRef,
  trackingMetadata,
  onEnded = () => {}
}: ControlsProps) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [visibleControl, setVisibleControl] = useState(defaultControlType)
  const [playbackSpeed, setPlaybackSpeed] = useState(1)

  const { trackCompleted, trackPaused, trackPlayed } = useTrackAudioPlayer({
    trackingMetadata
  })

  const handleVolumeChange = (volume: number) => {
    audioRef.current.volume = volume
  }

  const toggleShowVolumeControl = () => {
    if (visibleControl !== controlTypeVolume) {
      setVisibleControl(controlTypeVolume)
    } else {
      setVisibleControl(defaultControlType)
    }
  }

  const toggleShowPlaybackSpeedControl = () => {
    if (visibleControl !== controlTypePlaybackSpeed) {
      setVisibleControl(controlTypePlaybackSpeed)
    } else {
      setVisibleControl(defaultControlType)
    }
  }

  useEffect(() => {
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement#events

    audioRef.current.onended = () => {
      // when get to end of audio, show play button again
      setIsPlaying(false)

      // reload the media but do not autoplay again
      // this cleanly resets progress
      audioRef.current.load()
      audioRef.current.autoplay = false

      trackCompleted(audioRef)
      onEnded(audioRef)
    }

    audioRef.current.onpause = () => {
      setIsPlaying(false)
      if (!audioRef.current.ended) {
        // pause seems to be triggered on track end, so do not track it in this case
        trackPaused(audioRef)
      }
    }

    audioRef.current.onplay = () => {
      setIsPlaying(true)
      trackPlayed(audioRef)
    }

    // make copy in case audioRef is unavailable on dismount handling
    const audioRefClone = { ...audioRef }

    return () => {
      // cleanup event handlers on dismount
      audioRefClone.current.onended = null
      audioRefClone.current.onpause = null
      audioRefClone.current.onplay = null
    }
  })

  const handleTogglePlay = () => {
    isPlaying ? audioRef.current.pause() : audioRef.current.play()
    setIsPlaying(!isPlaying)
  }

  const handlePlaybackSpeedChange = (speed: number) => {
    setPlaybackSpeed(speed)
    audioRef.current.playbackRate = speed
  }

  const handleSkipBack = () => {
    if (audioRef.current.currentTime < skipBackSeconds) {
      audioRef.current.currentTime = 0
    } else {
      audioRef.current.currentTime = audioRef.current.currentTime - skipBackSeconds
    }
  }

  const handleSkipForward = () => {
    if (audioRef.current.currentTime + skipForwardSeconds > audioRef.current.duration) {
      audioRef.current.currentTime = audioRef.current.duration
    } else {
      audioRef.current.currentTime = audioRef.current.currentTime + skipForwardSeconds
    }
  }

  return (
    <div className="flex flex-row w-full sm:w-[300px]">
      {!isIOS() &&
        visibleControl !== controlTypePlaybackSpeed && ( // volume control is prevented on iOS, so hide button to control volume
          <Button
            title="Volume"
            data-testid="volume-button"
            variant="text-only"
            className="text-rb-white bg-transparent hover:bg-transparent active:bg-transparent m-0 p-0"
            onClick={toggleShowVolumeControl}
          >
            <Megaphone width={32} height={32} data-testid="volume-icon" />
          </Button>
        )}
      <div className="w-full sm:w-[280px] mx-2 h-8 text-center px-2">
        {visibleControl === controlTypePlaybackSpeed && (
          <div
            className="bg-rb-gray-400 justify-between px-1 flex flex-row w-full h-8 rounded-3xl"
            data-testid="playback-speed-control"
          >
            {playbackSpeeds.map((speed) => (
              <Button
                key={speed}
                data-testid={`playback-speed-control-button-${speed}`}
                onClick={() => handlePlaybackSpeedChange(speed)}
                className={twMerge(
                  'text-white font-bold px-2 py-1 my-1 rounded-3xl border-none active:bg-transparent',
                  playbackSpeed === speed
                    ? 'bg-gray-300 hover:bg-gray-300'
                    : 'bg-transparent hover:bg-transparent'
                )}
              >
                {speed}x
              </Button>
            ))}
          </div>
        )}
        {visibleControl === controlTypeVolume && (
          <div className="bg-rb-gray-400 mt-0.5 w-full h-8 px-2 pb-2 rounded-3xl">
            <input
              aria-label="Volume"
              data-testid="volume-control"
              type="range"
              style={{ accentColor: 'white', height: '2px' }}
              className="h-0.5 w-full size-2 cursor-pointer"
              id="volume"
              name="volume"
              min="0"
              step=".1"
              max="1"
              onChange={(e) => handleVolumeChange(Number(e.target.value))}
            />
          </div>
        )}
        {visibleControl === controlTypePlayPause && (
          <div className="flex flex-row justify-between">
            <Button
              variant="text-only"
              data-testid="skip-back-button"
              className="h-8 p-0 mx-auto bg-transparent hover:bg-transparent active:bg-transparent"
              onClick={handleSkipBack}
              aria-label={`Skip Back ${skipBackSeconds} seconds`}
            >
              <SkipBackIcon
                text={skipBackSeconds}
                textX="42%"
                textY="60%"
                className="w-8 h-8"
                data-testid="skip-back-icon"
              />
            </Button>
            <Button
              title={isPlaying ? 'Pause' : 'Play'}
              variant="text-only"
              data-testid="play-pause-button"
              className="h-8 p-0 mx-auto text-rb-white bg-transparent hover:bg-transparent active:bg-transparent"
              onClick={handleTogglePlay}
              aria-label={isPlaying ? 'Pause' : 'Play'}
            >
              {isPlaying ? (
                <PauseIcon width={32} height={32} data-testid="pause-icon" />
              ) : (
                <PlayIcon width={32} height={32} data-testid="play-icon" />
              )}
            </Button>
            <Button
              variant="text-only"
              data-testid="skip-forward-button"
              className="h-8 p-0 mx-auto bg-transparent hover:bg-transparent active:bg-transparent"
              onClick={handleSkipForward}
              aria-label={`Skip Forward ${skipForwardSeconds} seconds`}
            >
              <SkipForwardIcon
                text={skipForwardSeconds}
                textX="45%"
                textY="60%"
                className="w-8 h-8"
                data-testid="skip-forward-icon"
              />
            </Button>
          </div>
        )}
      </div>
      {visibleControl !== controlTypeVolume && (
        <Button
          title="PlaybackSpeed"
          data-testid="playback-speed-button"
          variant="text-only"
          className="w-6 bg-transparent hover:bg-transparent active:bg-transparent p-0"
          onClick={toggleShowPlaybackSpeedControl}
        >
          <RfParagraphMediumBold className="text-rb-white">
            {playbackSpeed}x
          </RfParagraphMediumBold>
        </Button>
      )}
    </div>
  )
}

export default Controls
