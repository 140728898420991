import { CohortsPageAccessUserPartsFragment } from 'gql'

export interface UseBlockCurrentCohortPageProps {
  slug: String
  currentUser: CohortsPageAccessUserPartsFragment
  showCohortDashboard: boolean
}

const useBlockCurrentCohortPage = ({
  slug,
  currentUser,
  showCohortDashboard
}: UseBlockCurrentCohortPageProps) => {
  // until launchDarkly is implemented for the backend, this is a workaround frontend check give staff/collective
  // the ability to view this page for cohorts they are not enrolled in
  const userWasOrIsEnrolledInCohort = (currentUser.cohorts?.all || []).some(
    (cohort) => cohort?.cohort?.slug === slug
  )

  // user is/was enrolled in this cohort so do not block this cohort page from them
  if (userWasOrIsEnrolledInCohort) {
    return false
  }

  // for cohorts they are not enrolled in, staff and collective can only see the cohort page if it is displaying the new cohort dashboard
  // (which is gated by showCohortDashboard)
  if (currentUser.is.staff || currentUser.is.collective) {
    return !showCohortDashboard
  }

  return true
}

export default useBlockCurrentCohortPage
