import { ApolloError } from '@apollo/client'

import { ErrorMessage, Loading, Search } from 'components'
import { Modal, ModalContent, ModalHeader, ModalTitle } from 'components/Modal'
import SearchModalList from 'components/SearchModal/SearchModalList'

interface SearchModalProps {
  isOpen: boolean
  onClose: () => void
  users: any
  count: number
  modalTitle: string
  handleSearch: (value: string) => void
  hasMoreUsers: boolean
  loadMore: () => void
  isLoading: boolean
  error?: ApolloError
}

const SearchModal = ({
  isOpen,
  onClose,
  users,
  count,
  modalTitle,
  handleSearch,
  hasMoreUsers,
  loadMore,
  isLoading,
  error
}: SearchModalProps) => {
  if (error) return <ErrorMessage error={error} />

  const hasMembersDisplayed = !isLoading && users.length > 0

  return (
    <div data-test="search-modal">
      <Modal
        isOpen={isOpen}
        handleClose={onClose}
        containerClass="z-[1011]"
        fixedHeight={true}
      >
        <ModalHeader className="mb-0">
          <ModalTitle className="md:md-6 mb-4">
            {modalTitle} ({count})
          </ModalTitle>
          <Search
            placeholder="Find members"
            onChange={(event) => handleSearch(event.target.value)}
          />
        </ModalHeader>
        <ModalContent className="px-0 md:px-0">
          {hasMembersDisplayed ? (
            <SearchModalList
              loadMore={loadMore}
              hasMoreUsers={hasMoreUsers}
              users={users}
            />
          ) : isLoading ? (
            <Loading />
          ) : (
            <p className="py-4 text-center font-bold">No members found</p>
          )}
        </ModalContent>
      </Modal>
    </div>
  )
}
export default SearchModal
