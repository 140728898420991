import Bugsnag from '@bugsnag/browser'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useModal } from 'components/Modal'

import { ModuleCompletionStatus, useModuleCompletionStatusLazyQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

const getRandomIntInRange = (max: number) => Math.floor(Math.random() * max + 1)

interface CelebratoryTextProps {
  moduleName?: string
  programName?: string
  programPercentComplete: number
}

export const useCelebratoryText = ({
  moduleName,
  programName,
  programPercentComplete
}: CelebratoryTextProps) => {
  const isProgramComplete = programPercentComplete === 100

  const programCompleteText = useMemo(
    () => [
      `Congrats! That’s a wrap on ${moduleName}.`,
      `You completed 100% of ${programName}.`
    ],
    [moduleName, programName]
  )

  const CELEBRATORY_TEXT_OPTIONS = useMemo(
    () => [
      [
        `Congrats! You aced ${moduleName}.`,
        `You’ve completed ${programPercentComplete}% of ${programName}. Keep it up!`
      ],
      [
        "You're unstoppable!",
        `${programPercentComplete}% of ${programName} down. Only ${
          100 - programPercentComplete
        }% left to go.`
      ],
      [
        `That’s a wrap on ${moduleName}!`,
        `You’ve now completed ${programPercentComplete}% of ${programName}.`
      ],
      [
        'You’re totally crushing it.',
        `Only ${100 - programPercentComplete}% of ${programName} to go!`
      ]
    ],
    [moduleName, programName, programPercentComplete]
  )

  // useMemo to avoid displaying new text on each render.
  const [celebrationTextPrimary, celebrationTextSecondary] = useMemo(
    () =>
      isProgramComplete
        ? programCompleteText
        : CELEBRATORY_TEXT_OPTIONS[
            getRandomIntInRange(CELEBRATORY_TEXT_OPTIONS.length - 1)
          ],
    [CELEBRATORY_TEXT_OPTIONS, isProgramComplete, programCompleteText]
  )

  return { celebrationTextPrimary, celebrationTextSecondary }
}

interface ShouldModalOpenProps {
  cmsSectionId: string
  getOnComplete: (onComplete: () => void) => void
}

export const useShouldModalOpen = ({
  cmsSectionId,
  getOnComplete
}: ShouldModalOpenProps) => {
  const { currentUser } = useCurrentUser()
  const [programPercentComplete, setProgramPercentComplete] = useState(0)
  const [hasModuleBeenCompletedPreviously, setHasModuleBeenCompletedPreviously] =
    useState(false)
  const { isModalOpen, setIsModalOpen } = useModal()
  const [getModuleCompletionStatus] = useModuleCompletionStatusLazyQuery({
    variables: { cmsSectionId },
    fetchPolicy: 'no-cache'
  })

  const closeModal = () => {
    const sidenav = document.getElementById('sidenav')
    if (sidenav) {
      sidenav.style.removeProperty('z-index')
    }

    setIsModalOpen(false)
  }

  const fetchModuleProgress = useCallback(
    async (onSuccess: (data: ModuleCompletionStatus) => void) => {
      if (!currentUser) return

      try {
        const response = await getModuleCompletionStatus()
        onSuccess(
          response.data?.moduleCompletionStatus ||
            ({
              isComplete: false,
              programPercentComplete: 0
            } as ModuleCompletionStatus)
        )
      } catch (e) {
        Bugsnag.notify(e)
      }
    },
    [getModuleCompletionStatus, currentUser]
  )

  const openModalIfComplete = () => {
    if (hasModuleBeenCompletedPreviously) return

    const onSuccess = (data: ModuleCompletionStatus) => {
      setIsModalOpen(data.isComplete)
      if (data.isComplete) {
        const sidenav = document.getElementById('sidenav')
        if (sidenav) {
          sidenav.style.zIndex = '3'
        }
      }

      setProgramPercentComplete(data.programPercentComplete)
    }

    fetchModuleProgress(onSuccess)
  }

  // onMount
  useEffect(() => {
    const onSuccess = (data: ModuleCompletionStatus) => {
      setHasModuleBeenCompletedPreviously(data.isComplete)
    }

    fetchModuleProgress(onSuccess)
  }, [fetchModuleProgress])

  // setOnComplete
  useEffect(() => {
    // Provide our `onComplete` method to parent component to call when
    // completion event occurs.
    getOnComplete(openModalIfComplete)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasModuleBeenCompletedPreviously, fetchModuleProgress])

  // Allow celebration modal to be triggered from external components
  useEffect(() => {
    window.triggerShowCelebrationModal = openModalIfComplete
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { closeModal, isModalOpen, programPercentComplete }
}
