import cloneDeep from 'lodash/cloneDeep'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect, useLocation, useParams } from 'react-router-dom'

import { BackToParams } from 'pages/UnitLandingPage/UnitLandingPage'

import ArtifactViewer from 'domains/Artifact/ArtifactViewer'
import Content404 from 'domains/Sanity/marketingSite/Content404'
import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'

import { Loading } from 'components'

import {
  PageSeo,
  PageTypeEnum,
  useArtifactPageQuery,
  useTrackServerEventMutation
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useFirstSigninSource from 'hooks/useFirstSigninSource'

import { useContentMode } from 'utils/contentTrackingUtils'
import { CourseIdentifiersType } from 'utils/courseUtils'
import { getAnonymousId } from 'utils/tracking/segment'

interface ArtifactPageProps {
  setPage?: (page: GenericPageType) => void
  artifactSlug?: string
  courseIdentifiers?: CourseIdentifiersType
}

interface LocationState {
  backTo?: BackToParams
  fromCourse?: boolean
}

const ArtifactPage = ({
  setPage,
  courseIdentifiers,
  artifactSlug = ''
}: ArtifactPageProps) => {
  const { slug } = useParams<{ slug: string }>()
  const { fromCourse, backTo } = useLocation<LocationState>()?.state || {}
  const { isLoggedIn, currentUser } = useCurrentUser()

  const { setCustomSigninSource } = useFirstSigninSource()
  setCustomSigninSource('artifact detail page')

  const slugToUse = slug || artifactSlug

  const { loading, error, data } = useArtifactPageQuery({
    variables: { slug: slugToUse }
  })

  const [trackServerEvent] = useTrackServerEventMutation()

  const contentMode = useContentMode(courseIdentifiers)

  useEffect(() => {
    if (!data?.artifact) {
      return
    }

    trackServerEvent({
      variables: {
        input: {
          event: 'Content Viewed - Server',
          anonymousId: getAnonymousId(),
          properties: {
            content_type: 'artifact',
            content_title: data?.artifact?.title,
            content_sanity_id: data?.artifact?.id,
            access_policy_kind: currentUser?.accessPolicyKind,
            is_previewable: artifact.isFree,
            related_identifiers: {
              content_mode: contentMode,
              course_id: courseIdentifiers?.course?.id,
              course_title: courseIdentifiers?.course?.title,
              course_session_id: courseIdentifiers?.courseSession?.id
            },
            trial_status: currentUser?.trialStatus
          }
        }
      }
    })

    if (artifact.showContentGate) {
      trackServerEvent({
        variables: {
          input: {
            event: 'Conversion Page Viewed - Server',
            anonymousId: getAnonymousId(),
            properties: {
              access_policy_kind: currentUser?.accessPolicyKind,
              page_type: 'artifact',
              path: window.location.pathname,
              referrer: document.referrer,
              url: window.location.href,
              is_conversion_eligible: !artifact.isFree,
              logged_in: !!currentUser,
              related_identifiers: {
                content_sanity_id: artifact.id,
                content_title: artifact.title
              },
              trial_status: currentUser?.trialStatus
            }
          }
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (!isLoggedIn && data?.artifact && setPage) {
      const page: GenericPageType = {
        title: data.artifact.title,
        seo: {
          metaTitle: data.artifact.title,
          metaDesc: data.artifact.description,
          shareTitle: data.artifact.title,
          shareDesc: data.artifact.description,
          shareGraphic: data.artifact.thumbnail,
          shareGraphicAlt: data.artifact.title,
          type: 'article' as PageTypeEnum,
          publishDate: data.artifact.publishDate,
          authors: data.artifactContributors
            .map((contributor) => contributor.user?.fullName)
            .join(', ')
        } as PageSeo
      }

      setPage(page)
    }
  }, [data, setPage, isLoggedIn])

  if (loading) return <Loading />
  if (error) return <p>Error: {error.message}</p>

  const artifact = data?.artifact

  const shouldRedirect =
    artifact?.publishDate === null || artifact?.publishDate > new Date()
  const overrideRedirect = currentUser?.is?.creator || currentUser?.is?.staff

  if (!artifact || (shouldRedirect && !overrideRedirect)) {
    if (isLoggedIn) {
      return <Redirect to="/artifacts" />
    }
    return <Content404 />
  }

  const descriptionBody = artifact?.descriptionBody || []
  const descriptionBodyCopy = cloneDeep(descriptionBody)

  if (!isLoggedIn) {
    // Loop through descriptionBody
    let internalLinkCount = 0
    let totalHotspotCount = 0
    for (const block of descriptionBodyCopy) {
      if (block?.hotspots?.length) {
        const remainingSpots = 2 - totalHotspotCount
        const spotsToAdd = Math.min(remainingSpots, block.hotspots.length)

        totalHotspotCount += spotsToAdd
        block.hotspots = block.hotspots.slice(0, spotsToAdd)
      }

      if (block.markDefs) {
        // Loop through markDefs
        for (const markDef of block.markDefs) {
          // Check if the markDef has a _type of internalLink
          if (markDef._type === 'internalLink') {
            internalLinkCount++

            if (internalLinkCount > 2) {
              markDef._hideNote = true
            }
          }
        }
      }
    }
  }

  const loggedOutArtifact = { ...artifact, descriptionBody: descriptionBodyCopy }

  // TODO: (stu) This is temporary until we get ordering sorted out for contributors
  const authorsOnArtifact = data?.artifact?.authors
  const authors = data?.artifactContributors?.filter(
    (c: any) => c.contributionVerb === 'authored' && c.status === 'confirmed'
  )

  const primaryAuthorIndex = authors.findIndex(
    (author: any) => author.slug === authorsOnArtifact?.[0]?.slug
  )

  // Put the primary author at the beginning of the authors list.
  if (authors?.length > 1 && primaryAuthorIndex > 0) {
    const primaryAuthor = authors.splice(primaryAuthorIndex, 1)
    authors.unshift(primaryAuthor[0])
  }

  const contributors = data?.artifactContributors?.filter(
    (c: any) => c.contributionVerb === 'contributed' && c.status === 'confirmed'
  )

  const renderedArtifact = !isLoggedIn ? loggedOutArtifact : artifact

  return (
    <>
      {/* defer=false ensures title populates in an unfocused tab,
          say if a user ctrl/cmd-clicks multiple artifacts */}
      <Helmet defer={false}>
        <title>{renderedArtifact.title} — Reforge</title>
      </Helmet>
      <ArtifactViewer
        artifact={renderedArtifact}
        authors={authors}
        backTo={fromCourse ? backTo : undefined}
        contributors={contributors}
      />
    </>
  )
}

export default ArtifactPage
