import googleIcon from 'a-logo--google.svg'
import linkedinIcon from 'a-logo--linkedin.png'
import microsoftIcon from 'a-logo--microsoft.svg'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import UIkit from 'uikit'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'

import { MAX_WIDTH_TAILWIND_MD } from 'constants/breakpoints'

import {
  DuplicateAccount,
  UserOauthProvider,
  useDuplicateAccountsQuery,
  useSendMergeRequestEmailMutation
} from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import notifyError from 'utils/errorNotifier'

interface ConnectedAccountsProps {
  oauthProviders?: UserOauthProvider[] | null
}

const ConnectedAccounts = ({ oauthProviders }: ConnectedAccountsProps) => {
  const linkedinProvider = oauthProviders?.find((obj) => obj.provider === 'linkedin')
  const googleProvider = oauthProviders?.find((obj) => obj.provider === 'google_oauth2')
  const microsoftProvider = oauthProviders?.find(
    (obj) => obj.provider === 'azure_activedirectory_v2'
  )

  return (
    <>
      <OauthProvider
        name="LinkedIn"
        icon={linkedinIcon}
        provider={linkedinProvider}
        link="/auth/linkedin?connect_multiple_oauth=true"
      />
      <OauthProvider
        name="Google"
        icon={googleIcon}
        provider={googleProvider}
        link="/auth/google_oauth2?connect_multiple_oauth=true"
      />

      <OauthProvider
        name="Microsoft"
        icon={microsoftIcon}
        provider={microsoftProvider}
        link="/auth/azure_activedirectory_v2?connect_multiple_oauth=true"
      />

      <MergeCandidateList />
    </>
  )
}

interface OauthProviderProps {
  provider?: UserOauthProvider
  name: string
  icon: string
  link: string
}

const OauthProvider = ({ provider, name, icon, link }: OauthProviderProps) => {
  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_MD})`)
  const createdAt =
    provider && provider.createdAt
      ? format(new Date(provider.createdAt), 'MMM dd, yyyy')
      : null

  const OauthLogo = () => {
    if (name === 'Microsoft') {
      return <img src={icon} alt="" height={37} width={37} />
    } else if (name === 'LinkedIn') {
      return <img src={icon} alt="" className="ml-1" />
    } else {
      return <img src={icon} alt="" />
    }
  }

  return (
    <div
      data-test="oauth-account"
      className="mb-5 grid grid-cols-12 border border-rb-gray-100 py-2.5 px-3"
    >
      <div className="col-span-3 flex flex-col md:col-span-4 md:flex-row md:items-center">
        <div className="flex w-12 items-center justify-center">
          <OauthLogo />
        </div>
        <div className="text-base font-medium md:ml-2">{name}</div>
      </div>

      <div className="col-span-5 flex items-center justify-center text-sm md:col-span-4">
        <div className="flex flex-col">
          <div className="flex items-center">
            <div>
              <SVGIcon
                name="dot"
                width="8"
                height="8"
                fill={provider ? '#00C361' : '#D3D2D3'}
              />
            </div>
            <div className="ml-2">
              {provider ? (
                'Connected'
              ) : (
                <span className="text-neutral-500">Not Connected</span>
              )}
            </div>
          </div>

          {!!createdAt && <div className="text-rb-gray-300">{createdAt}</div>}
        </div>
      </div>

      <div className="col-span-4 flex items-center">
        {!provider && (
          <Button
            className="ml-auto flex w-max items-center justify-center"
            data-method="post"
            href={link}
            shape="rounded-full"
            size={isMobile ? 'small' : 'x-small'}
            variant="outline"
          >
            Connect
          </Button>
        )}
      </div>
    </div>
  )
}

interface SendMergeRequestEmailProps {
  user: DuplicateAccount
}

const SendMergeRequestEmail = ({ user }: SendMergeRequestEmailProps) => {
  const [sendMergeRequestEmailMutation, { error }] = useSendMergeRequestEmailMutation({
    variables: { input: { id: user.id } }
  })
  const [sent, setSent] = useState(false)

  useEffect(() => {
    if (error) {
      UIkit.notification({
        message: 'There was an error sending the email. Please try again later.',
        status: 'danger',
        pos: 'top-right'
      })
    }
  }, [error])

  const sendMergeRequestEmail = async () => {
    try {
      await sendMergeRequestEmailMutation()
      setSent(true)
    } catch (e) {
      notifyError(e)
    }
  }

  if (sent) {
    return <span className="uk-text-success">Check your email!</span>
  }

  return (
    <button onClick={sendMergeRequestEmail} className="m-0 flex items-center font-medium">
      Send Email
    </button>
  )
}

const MergeCandidateList = () => {
  const { data, loading } = useDuplicateAccountsQuery()

  if (loading || !data?.duplicateAccounts || data?.duplicateAccounts.length === 0) {
    return null
  }

  return (
    <>
      <div className="my-5 flex flex-col items-center p-4 text-center text-rb-orange-100">
        <div>
          It looks like you have multiple accounts. Would you like to merge these?
        </div>
        <Button
          size="x-small"
          variant="outline"
          shape="rounded-full"
          uk-toggle={'target: #dupe-acct-modal'}
          className="mt-2"
        >
          Merge Account
        </Button>
      </div>
      <hr />

      <div id="dupe-acct-modal" uk-modal={'{"container": false}'}>
        <div className="uk-modal-dialog uk-modal-body">
          <h4 className="uk-modal-title">Possible Accounts</h4>
          <p>
            If you recognize another account you have used to join Reforge, please click
            below to confirm you own the account and merge them.
          </p>
          <button className="uk-modal-close-default" type="button" uk-close="true" />
          <table className="uk-table uk-table-small">
            <thead>
              <tr>
                <th>Email</th>
                <th>Created</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.duplicateAccounts.map((user: DuplicateAccount) => (
                <tr key={`merge${user.id}`}>
                  <td>{user.email}</td>
                  <td>{user.createdAt}</td>
                  <td>
                    <SendMergeRequestEmail user={user} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="uk-margin" />
        </div>
      </div>
    </>
  )
}

export default ConnectedAccounts
