import { useState } from 'react'

import ClipViewerModal from 'domains/Clip/ClipViewerModal'

import { useModal } from 'components/Modal'
import HighlightsCard from 'components/cards/HighlightsCard'

import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'

import { ClipPartsFragment, CurrentUserPartsFragment } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

export interface HighlightsProps {
  currentUser: CurrentUserPartsFragment
  clips: {
    thisWeekClips: ClipPartsFragment[]
    allClips: ClipPartsFragment[]
    headerName?: string
  }
}

const Highlights = ({ clips }: HighlightsProps) => {
  const isWide = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)
  const { isModalOpen, setIsModalOpen } = useModal()
  const [selectedClipIndex, setSelectedClipIndex] = useState<number>(0)
  const hasClips = !!clips.allClips?.length

  if (!hasClips) return null

  return (
    <>
      <div className="grid w-full grid-cols-1 gap-x-4 gap-y-6 bg-white px-10 pt-8 pb-5 lg:grid-cols-2 xl:grid-cols-3 xl:grid-cols-4">
        {clips.allClips.map((clip, i) => (
          <HighlightsCard
            key={i}
            isWide={isWide}
            clip={clip}
            onClick={() => {
              setSelectedClipIndex(i)
              setIsModalOpen(true)
            }}
          />
        ))}
      </div>
      <ClipViewerModal
        clip={clips.allClips[selectedClipIndex]}
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      />
    </>
  )
}

export default Highlights
