import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import Button from 'components/Button'

export interface PostFormButtonProps {
  disabled?: boolean
  loading?: boolean
  submitForm(): void
  className?: string
  label: string
}

export const PostFormButton = ({
  submitForm,
  disabled,
  loading,
  label,
  className
}: PostFormButtonProps) => {
  return (
    <div className={twMerge('mt-[20px] flex items-center self-end', className)}>
      <div className="flex items-center justify-end font-sans">
        <Button
          dataTest="post-form"
          onClick={submitForm}
          disabled={disabled}
          size="small"
          className={disabled ? '!border-rb-gray-100 !bg-rb-gray-100' : ''}
          isLoadingSpinner={loading}
        >
          {label}
        </Button>
      </div>
    </div>
  )
}
