import { ReactComponent as ArrowRightIcon } from 'arrow-right.svg'
import React from 'react'

import Button from 'components/Button'

interface CoursePreviewButtonProps {
  onClick: (e: React.MouseEvent | React.KeyboardEvent) => void
}

const CoursePreviewButton = ({ onClick }: CoursePreviewButtonProps) => {
  return (
    <Button
      variant="fill"
      iconAfter={<ArrowRightIcon className="h-[12px] w-[12px]" />}
      className="border-none bg-rb-green-50 px-4 py-2 font-normal text-rb-gray-400"
      shape="rounded-full"
      onClick={onClick}
    >
      Preview
    </Button>
  )
}

export default CoursePreviewButton
