import { closing, opening } from './sharedMarkdown'

const programName = 'Product Strategy'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://www.reforge.com/product-strategy), because it will help me build, communicate, and execute a cohesive product strategy across feature, growth, scaling, and innovation product work. This course was built with [Fareed Mosavat](https://www.reforge.com/experts/fareed-mosavat) and [Casey Winters](https://www.reforge.com/experts/casey-winters), and covers:
* **Feature Strategy.** Evaluate existing features based on feature adoption, retention, and satisfaction, and then map that to various improvement strategies. In addition, you will develop a plan for new features that achieve feature product fit.
* **Growth Strategy.** Map out your Growth Strategy by building a qualitative growth model of your acquisition and habit loops.  You will then learn how to improve the various levers of acquisition, retention, and monetization.
* **Product/Market Fit + Expansion Strategy.** Every product reaches a ceiling.  Learn the various types of product saturation, how to identify opportunities to expand the ceiling of your product/market fit, then evaluate which strategy is right given your product, market, shared capabilities, and more.  
* **Scaling Strategy + Build Your Product Strategy.** One of the most under-looked types of product work is scaling work. Learn how to evaluate the three types of scaling work: tech scaling, process scaling, and user scaling. Then, you’ll bring all four different types of product work (Feature, Growth, PMF Expansion, and Scaling) together in a Product Strategy that you can start to execute.

&NewLine;

${closing}
`

export default { markdown, title: programName }
