import { Dispatch, SetStateAction } from 'react'

import OverallLessonProgress from 'components/OverallLessonProgress/OverallLessonProgress'
import { ChevronDownThin, ChevronUpThinIcon } from 'components/icons'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import {
  CohortCmsSectionTagPartsFragment,
  CohortDashboardCmsSectionPartsFragment
} from 'gql'

import { getLessonProgressPercent } from 'utils/cohortUtils'
import { onEnterKeyPress } from 'utils/keyboard'

import { ModuleProgress } from 'typings/scalars'

const CollapseToggle = ({ isExpanded }: { isExpanded: boolean }) => {
  return isExpanded ? (
    <ChevronUpThinIcon height="8" width="16" fill="#0f0f0f" />
  ) : (
    <ChevronDownThin height="8" width="16" fill="#0f0f0f" />
  )
}

interface HeaderProps {
  isExpanded: boolean
  setIsExpanded: Dispatch<SetStateAction<boolean>>
  section: CohortDashboardCmsSectionPartsFragment
  number: number
  userProgress: ModuleProgress
}

const CollapsibleSectionCardHeader = ({
  setIsExpanded,
  isExpanded,
  number,
  section,
  userProgress
}: HeaderProps) => {
  const lessonsForSection = section.children

  let progressPercentRawTotalAcrossLessons = 0
  let completeLessonCount = 0

  lessonsForSection.forEach((lesson) => {
    if (userProgress[lesson.id] === ('completed' as string)) {
      completeLessonCount += 1
    }
    progressPercentRawTotalAcrossLessons += getLessonProgressPercent(userProgress, lesson)
  })

  const overallProgressPercentAcrossLessons =
    progressPercentRawTotalAcrossLessons / lessonsForSection.length

  const tagsOnChildren: CohortCmsSectionTagPartsFragment[] = []

  section.children.forEach((lesson) => {
    if (lesson.tags?.length) {
      tagsOnChildren.push(lesson.tags[0])
    }
  })

  return (
    <div
      className={`flex flex-row ${isExpanded && 'border-b'} w-full p-5`}
      data-testid={`section-${section.id}`}
      onClick={() => setIsExpanded(!isExpanded)}
      role="button"
      tabIndex={0}
      onKeyUp={onEnterKeyPress(() => setIsExpanded(!isExpanded))}
    >
      <div className="w-1/8">
        <RfParagraphMedium>{number}</RfParagraphMedium>
      </div>
      <div className="w-5/8 ml-4 flex flex-col whitespace-nowrap">
        <RfParagraphMedium className="mb-2">{section.name}</RfParagraphMedium>
        <OverallLessonProgress
          overallProgressPercent={overallProgressPercentAcrossLessons}
          totalLessons={lessonsForSection.length}
          completeLessonCount={completeLessonCount}
          totalEstimatedTime={section.estimatedTime}
          uniqueId={section.id}
          tags={tagsOnChildren}
        />
      </div>
      <div className="ml-auto mt-3">
        <CollapseToggle isExpanded={isExpanded} />
      </div>
    </div>
  )
}

export default CollapsibleSectionCardHeader
