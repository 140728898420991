import { COURSE_FILTER_ON_DEMAND } from 'pages/CoursesPage/CoursesFilters'

import Image from 'domains/Sanity/Image'

import {
  CourseSnapshotMarketingCoursesPartsFragment,
  MarketingCourseCourseCardPartsFragment
} from 'gql'

import { CardVariant, CardVariants } from '../Content/BaseCard'

const LegacyCourseInProgressThumbnail = ({
  course,
  cardType,
  showHostInThumbnail,
  cardVariant
}: {
  course:
    | CourseSnapshotMarketingCoursesPartsFragment
    | MarketingCourseCourseCardPartsFragment
  cardType: string
  showHostInThumbnail?: boolean
  cardVariant?: CardVariant
}) => {
  const hosts = showHostInThumbnail
    ? course?.hosts?.map((host) => ({
        url: host.hostPicture?.imageUrl || '',
        name: host.hostName || ''
      })) || []
    : []

  const creators =
    cardType === COURSE_FILTER_ON_DEMAND && !showHostInThumbnail
      ? course.creators?.map((creator) => {
          return {
            url: creator?.expert?.avatarPhoto?.imageUrl || '',
            name: creator?.expert?.name || ''
          }
        }) || []
      : course.course?.creators?.map((courseCreator) => {
          return {
            url: courseCreator?.avatarPhoto?.imageUrl || '',
            name: courseCreator?.name || ''
          }
        }) || []

  const experts = showHostInThumbnail ? hosts : creators

  if (experts.length === 0) return null

  let imageSize
  if (cardVariant === CardVariants.BiggerMini) {
    imageSize = 'h-[120px] w-[120px]'
  } else if (cardVariant === CardVariants.Mini) {
    imageSize = 'h-20 w-20'
  } else {
    imageSize = 'h-20 w-20'
  }

  return (
    <div className="relative">
      {experts.length > 1 && (
        <div className="absolute right-0 bottom-0 rounded-tl-lg bg-rb-orange-25 p-2 text-sm text-rb-gray-400">
          +{experts.length - 1}
        </div>
      )}
      <Image
        className={`${imageSize} rounded-l-lg object-cover`}
        src={experts[0].url}
        alt={experts[0].name}
      />
    </div>
  )
}

export default LegacyCourseInProgressThumbnail
