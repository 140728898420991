import CourseCard, {
  COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD
} from 'components/cards/Content/CourseCard'

import { CourseDetailPartsFragment, RelatedCoursePartsFragment } from 'gql'

interface CourseDetailRelatedCourseProps {
  relatedCourse: RelatedCoursePartsFragment
  courseDetails: CourseDetailPartsFragment
  loggedOut?: boolean
}

const CourseDetailRelatedCourse = ({ relatedCourse }: CourseDetailRelatedCourseProps) => {
  const course = {
    id: relatedCourse.id,
    title: relatedCourse.title,
    slug: relatedCourse.slug,
    creators: relatedCourse.creators?.map((c) => ({
      id: c.id,
      name: c.name ?? '',
      avatarUrl: c.avatarPhoto?.imageUrl,
      __typename: 'CclExpert' as const
    }))
  }

  return (
    <div className="mr-6">
      <CourseCard
        course={course}
        destinationType={COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD}
        hideBookmarkButton={true}
      />
    </div>
  )
}

export default CourseDetailRelatedCourse
