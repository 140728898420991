import { SkeletonProgramIndexCard } from 'components/skeletons/cards'

const SkeletonProgramsPage = () => (
  <div className="flex w-full flex-col">
    {[...Array(12)].map((_, index) => (
      <SkeletonProgramIndexCard
        key={index}
        className="max-lg:min-h-[192px] mb-5 min-h-[260px] md:min-h-[223px] xl:min-h-[175px]"
      />
    ))}
  </div>
)

export default SkeletonProgramsPage
