import { useEffect, useState } from 'react'

import { WISTIA_PIP_HEIGHT, WISTIA_PIP_WIDTH, WistiaVideo } from 'typings/WistiaVideo'

const useWistiaPictureInPicture = (
  videoInstance: WistiaVideo | undefined,
  isMobile: boolean
) => {
  const [inPipMode, setInPipMode] = useState<boolean>(false)

  useEffect(() => {
    const noPipStyle = ['h-[100%]', 'w-[100%]']
    const noPipPosition = ['top-0', 'left-0']
    const pipPosition = ['!right-[20px]', '!bottom-[20px]']
    const pipStyle = [
      `!h-[${WISTIA_PIP_HEIGHT}px]`,
      `!w-[${WISTIA_PIP_WIDTH}px]`,
      '!fixed',
      'animate-fadeIn',
      'z-[1011]'
    ]

    const headerWrapper = document.getElementById('page-header')
    const videoWrapper = document.getElementById('wistia-player-wrapper')
    const videoPlayer = document.getElementById('wistia-player')

    const topBound = headerWrapper?.offsetHeight || 0

    // If the video instance doesn't exist, or if we can't find
    // the player-specific ids in the DOM, return
    if (!videoInstance || isMobile || !videoWrapper || !videoPlayer) return

    const mouseScrollCallback = () => {
      const videoOffsetTop = videoWrapper.offsetTop
      const videoHeight = videoWrapper.offsetHeight

      const videoBottom = videoOffsetTop + videoHeight - topBound
      const scrollTop = window.scrollY

      if (videoBottom < scrollTop && !inPipMode && videoInstance.state() === 'playing') {
        // Picture in picture
        videoPlayer.classList.remove(...noPipStyle, ...noPipPosition)
        videoPlayer.classList.add(...pipStyle, ...pipPosition)

        videoInstance.height(WISTIA_PIP_HEIGHT)
        videoInstance.width(WISTIA_PIP_WIDTH)

        setInPipMode(true)
      } else if (videoBottom > scrollTop && inPipMode) {
        // Cancel picture in picture
        videoPlayer.classList.add(...noPipStyle, ...noPipPosition)
        videoPlayer.classList.remove(...pipStyle, ...pipPosition)

        videoInstance.height(videoWrapper.offsetHeight)
        videoInstance.width(videoWrapper.offsetWidth)

        setInPipMode(false)
      }
    }
    window.addEventListener('scroll', mouseScrollCallback)

    return () => {
      window.removeEventListener('scroll', mouseScrollCallback)
    }
  }, [inPipMode, isMobile, videoInstance])
}

export default useWistiaPictureInPicture
