import moment from 'moment-timezone'

import { ProfileSidebarSectionHeader } from 'pages/MemberProfilePage'

import BetaBadge from 'components/BetaBadge'
import {
  ShareableSnapshotModal,
  useShareableSnapshotModal
} from 'components/ShareableSnapshotModal'

import {
  MemberProfileUserCohortFieldsFragment,
  MemberProfileUserFieldsFragment
} from 'gql'

import { formatSeasonName } from 'utils/cohortUtils'
import { MONTH_FULL_YEAR_FORMAT, isAfterDate } from 'utils/date'

const restrictToOnePerSeason = (cohorts: MemberProfileUserCohortFieldsFragment[]) => {
  const seasonsTaken: { [key: string]: boolean } = {}
  return cohorts.filter((cohort) => {
    if (seasonsTaken[cohort.season]) {
      return false
    }

    seasonsTaken[cohort.season] = true
    return true
  })
}

interface ProgramListProps {
  user: MemberProfileUserFieldsFragment
  isCurrentUser: boolean
}

export default function ProgramList({ user, isCurrentUser }: ProgramListProps) {
  const { canShowSnapshotModal, showShareableSnapshotModal } = useShareableSnapshotModal()

  const hostedCohorts = user.cohortsHosted

  if (hostedCohorts.length) return null

  const currentCohorts = user.cohorts?.current || []
  const previousCohorts = user.cohorts?.previous || []
  const filteredCohorts = restrictToOnePerSeason(
    [...previousCohorts, ...currentCohorts].reverse()
  )
  const numCohorts = Object.keys(filteredCohorts).length
  const unorderedUserCourses = user.userCourses.all || []
  const orderedUserCourses = [...unorderedUserCourses].sort((a, b) =>
    isAfterDate(a.courseSession?.startsAt, b.courseSession?.startsAt) ? 1 : -1
  )

  if (!filteredCohorts.length && !orderedUserCourses.length) return null

  return (
    <div>
      <ProfileSidebarSectionHeader title="Courses" />
      <div>
        {orderedUserCourses.map((userCourse) => {
          const startDate = userCourse.courseSession?.startsAt
            ? Date.parse(userCourse.courseSession?.startsAt)
            : null
          const formattedStartDate = startDate
            ? moment(startDate).format(MONTH_FULL_YEAR_FORMAT)
            : null

          return (
            <div key={userCourse.id} className="mb-5">
              <div className="flex items-center">
                <BetaBadge className="w-7 text-[10px]" />
                <div className="ml-4">
                  <div className="text-xs font-medium uppercase tracking-widest text-rb-gray-300">
                    {formattedStartDate}
                  </div>
                  <div className="text-base font-medium leading-4">
                    {userCourse.course?.title}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div>
        {filteredCohorts.map((cohort, idx) => {
          const programName = cohort.cohort.cmsProgram.name.replace(' Deep Dive', '')

          return (
            <div key={`${cohort.season}${idx}`}>
              <div className="flex items-center">
                <div
                  style={{
                    backgroundImage: `url(${
                      cohort.cohort.cmsProgram.iconImageUrl || ''
                    })`,
                    backgroundSize: 22,
                    backgroundPosition: 4
                  }}
                  className="h-7 w-7 rounded-[46px] bg-center bg-no-repeat shadow-default"
                />
                <div className="ml-4">
                  <div className="text-xs font-medium uppercase tracking-widest text-rb-gray-300">
                    {formatSeasonName(cohort.cohort.season)}
                  </div>
                  <div className="text-base font-medium leading-4">{programName}</div>
                  {isCurrentUser && canShowSnapshotModal(cohort.id) && (
                    <>
                      <button
                        className="text-rb-teal-200 focus:outline-none"
                        data-test="member-profile-snapshot-entrypoint"
                        onClick={() => {
                          showShareableSnapshotModal('Member Profile')
                        }}
                      >
                        View snapshot
                      </button>
                      <ShareableSnapshotModal page="Member Profile" />
                    </>
                  )}
                </div>
              </div>
              {idx + 1 < numCohorts && <div className="my-1 ml-4 h-3.5" />}
            </div>
          )
        })}
      </div>
    </div>
  )
}
