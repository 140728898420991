import * as React from 'react'
import { FormContext, FormContextValues, ValidationMode, useForm } from 'react-hook-form'

import Button, { ButtonProps } from 'components/Button'

interface IForm {
  submit: (data: any, contextValues?: FormContextValues) => void
  validationMode?: keyof ValidationMode
  submitButton?: boolean
  defaultValues?: {}
  validationSchema?: {}
  className?: string
  style?: React.CSSProperties
  children: React.ReactNode
  submitButtonClassname?: string
  submitButtonText?: string
  submitButtonProps?: Omit<ButtonProps, 'children'>
}

const Form = ({
  submit,
  submitButton = true,
  validationMode,
  defaultValues = {},
  validationSchema = {},
  className,
  style,
  children: fields,
  submitButtonClassname,
  submitButtonProps = {},
  submitButtonText = 'Submit'
}: IForm) => {
  // https://legacy.react-hook-form.com/migrate-v5-to-v6/
  const methods = useForm({
    mode: validationMode,
    defaultValues,
    validationSchema
  })
  const { isSubmitted, isSubmitting, isValid } = methods.formState

  return (
    <FormContext {...methods}>
      <form
        onSubmit={methods.handleSubmit((data) => submit(data, methods))}
        className={className}
        style={style}
      >
        {fields}

        {submitButton && (
          <div className="mt-12">
            <Button
              disabled={(isSubmitted && !isValid) || isSubmitting}
              type="submit"
              className={submitButtonClassname}
              {...submitButtonProps}
            >
              {isSubmitting ? 'Submitting...' : submitButtonText}
            </Button>
          </div>
        )}
      </form>

      {/* {process.env.NODE_ENV === 'development' && process.env.REACT_APP_FORM_DEV_TOOLS && (
        <FormDevTools control={methods.control} />
      )} */}
    </FormContext>
  )
}

export default Form
