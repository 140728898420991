import { useHistory } from 'react-router-dom'

import Button from 'components/Button'

import { InterventionEventSameInfoPartsFragment } from 'gql'

interface JoinButtonProps {
  event?: InterventionEventSameInfoPartsFragment
}

const JoinButton = ({ event }: JoinButtonProps) => {
  const history = useHistory()

  return (
    <Button
      size="small"
      shape="rounded-full"
      onClick={() => history.push(`/events/links/${event?.uuid}`)}
    >
      Join
    </Button>
  )
}

export default JoinButton
