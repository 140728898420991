import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { twMerge } from 'tailwind-merge'

import AnnotationViewer from 'domains/Artifact/AnnotationViewer'

import CloseIcon from 'components/icons/CloseIcon'

import { useArtifactAnnotationsQuery } from 'gql'

import useOnClickOutside from 'hooks/useOnClickOutside'

import { parseArtifactSlugFromPath } from 'utils/urlUtils'

import ButtonNoteMarker from './ButtonNoteMarker'

const ZOOM_KEY_DISPLAY_DURATION = 2000

function usePopover<
  RE extends Parameters<typeof usePopper>[0],
  PE extends Parameters<typeof usePopper>[1]
>(options: Parameters<typeof usePopper>[2] = {}) {
  const [referenceElement, setReferenceElement] = useState<RE | null>(null)
  const [popperElement, setPopperElement] = useState<PE | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, options)

  return {
    styles,
    attributes,
    setReferenceElement,
    setPopperElement,
    popperElement
  }
}

const ImageZoomOverlay = ({
  src,
  handleClose,
  artifact,
  hotspots
}: {
  src: string
  handleClose: () => void
  artifact: any
  hotspots: any[]
}) => {
  const artifactSlug = parseArtifactSlugFromPath()

  const urlWithOptimizedSanityImage = src ? `${src}?w=2500&q=90` : ''

  const [selectedAnnotation, setSelectedAnnotation] = useState<any>()
  const [currentScale, setCurrentScale] = useState<number>(1)
  const [showCurrentScale, setShowCurrentScale] = useState<boolean>(true)
  const [isPanning, setIsPanning] = useState<boolean>(false)

  const showCurrentScaleTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const { data: annotationsData } = useArtifactAnnotationsQuery({
    variables: {
      artifactSlug
    }
  })

  const handleZoomStart = useCallback(() => {
    if (showCurrentScaleTimeoutRef.current) {
      clearTimeout(showCurrentScaleTimeoutRef.current)
    }

    setShowCurrentScale(true)
  }, [])

  const handleZoom = useCallback((ref) => {
    setCurrentScale(ref.state.scale)
  }, [])

  const handleZoomStop = useCallback(() => {
    const timeout = setTimeout(() => {
      setShowCurrentScale(false)
    }, ZOOM_KEY_DISPLAY_DURATION)

    showCurrentScaleTimeoutRef.current = timeout
  }, [])

  useEffect(() => {
    setShowCurrentScale(true)
    const timeout = setTimeout(() => {
      setShowCurrentScale(false)
    }, ZOOM_KEY_DISPLAY_DURATION)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.which === 27) {
        handleClose()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    // Stop things from scrolling behind the overlay
    const sideDrawer = document.getElementById('side-drawer')
    sideDrawer?.classList.add('overflow-hidden')

    return () => {
      sideDrawer?.classList.remove('overflow-hidden')
    }
  }, [handleClose])

  const handlePanningStart = useCallback(() => {
    setIsPanning(true)
  }, [])

  const handlePanningStop = useCallback(() => {
    setIsPanning(false)
  }, [])

  const annotations = annotationsData?.artifactAnnotations

  return (
    <div
      className="pointer-events-none fixed top-0 right-0 bottom-0 left-0 z-[9999] flex items-center justify-center"
      style={{
        width: '100%',
        height: '100%'
      }}
    >
      <div className="pointer-events-none absolute top-8 right-0 left-0 z-10 mx-auto flex w-fit items-center justify-center rounded-full bg-[rgba(0,0,0,0.5)] px-4 py-2 text-white">
        Press ESC to exit fullscreen
      </div>

      <div className="pointer-events-auto absolute top-8 right-8 z-10">
        <button
          className="flex h-10 w-10 items-center justify-center rounded-md bg-[rgba(0,0,0,0.5)] text-white"
          onClick={handleClose}
        >
          <CloseIcon className="h-[24px] w-[24px]" />
        </button>
      </div>

      <div
        className={twMerge(
          'pointer-events-none absolute bottom-8 right-8 z-10 flex h-20 w-20 items-center justify-center rounded-md bg-[#F0F2FF] text-lg transition-opacity',
          showCurrentScale ? 'opacity-100' : 'opacity-0'
        )}
      >
        {Math.floor(currentScale * 100)}%
      </div>

      <TransformWrapper
        initialScale={1}
        minScale={1}
        maxScale={10}
        initialPositionY={100}
        wheel={{ smoothStep: 0.007 }}
        panning={{ velocityDisabled: true }}
        onZoomStart={handleZoomStart}
        onZoom={handleZoom}
        onZoomStop={handleZoomStop}
        onPanningStart={handlePanningStart}
        onPanningStop={handlePanningStop}
      >
        <div
          className="pointer-events-auto absolute top-0 right-0 bottom-0 left-0 bg-black/80"
          onClick={handleClose}
          onKeyDown={handleClose}
          tabIndex={0}
          role="button"
        />

        <TransformComponent
          wrapperClass={twMerge(isPanning ? 'cursor-grabbing' : 'cursor-grab')}
          contentClass="relative pointer-events-auto"
          wrapperStyle={{
            width: '100%',
            height: '100%'
          }}
        >
          <img
            src={urlWithOptimizedSanityImage}
            alt="zoom-overlay"
            role="presentation"
            className="z-9"
          />

          {Boolean(hotspots) &&
            hotspots.map((hotspot: any) => (
              <HotspotAnnotation
                key={hotspot._key}
                annotations={annotations}
                artifact={artifact}
                className="pointer-events-auto z-10"
                hotspot={hotspot}
                selectedAnnotation={selectedAnnotation}
                onHotspotClick={(annotation: any) => setSelectedAnnotation(annotation)}
                onHotspotPopoverClickOutside={() => setSelectedAnnotation(undefined)}
              />
            ))}
        </TransformComponent>
      </TransformWrapper>
    </div>
  )
}

const HotspotAnnotation = ({
  annotations,
  artifact,
  className,
  hotspot,
  selectedAnnotation,
  onHotspotClick,
  onHotspotPopoverClickOutside
}: {
  annotations: any
  artifact: any
  className: string
  hotspot: any
  selectedAnnotation: any
  onHotspotClick: (annotation: any) => void
  onHotspotPopoverClickOutside: () => void
}) => {
  const hotspotAnnotationId = hotspot?.annotation?.id
  const annotation = useMemo(
    () => annotations?.find((a: any) => a?.id === hotspotAnnotationId),
    [annotations, hotspotAnnotationId]
  )

  const handleClick = () => {
    if (!annotation?.id) return

    onHotspotClick(annotation)
  }

  const active = selectedAnnotation?.id === hotspotAnnotationId

  const { styles, attributes, setPopperElement, setReferenceElement, popperElement } =
    usePopover<HTMLButtonElement, HTMLDivElement>({
      placement: 'right-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-17, 3]
          }
        }
      ]
    })

  useOnClickOutside({ current: popperElement }, onHotspotPopoverClickOutside)

  return (
    <>
      <div
        style={{
          left: `${hotspot.x}%`,
          top: `${hotspot.y}%`
        }}
        className={twMerge('absolute -ml-[11px] -mt-[11px]', className)}
        data-hotspot-hash={hotspotAnnotationId}
        onClick={handleClick}
        onKeyDown={handleClick}
        tabIndex={0}
        role="button"
      >
        <ButtonNoteMarker active={active} ref={setReferenceElement} />
      </div>

      {active && (
        <div
          className="pointer-events-auto z-[9999] max-h-[700px] w-[500px]"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <AnnotationViewer
            annotation={annotation}
            artifact={artifact}
            primaryAuthor={artifact?.authors?.[0]}
            isImageZoomOverlay
          />
        </div>
      )}
    </>
  )
}

export default ImageZoomOverlay
