import Image from 'domains/Sanity/Image'

import TopFacetLeak from 'images/Top-Facet-Leak.png'

import ButtonMarketingCTA from '../ButtonMarketingCTA'

const CareersBottomCards = () => {
  return (
    <section className="relative min-h-[100px] py-[3.3vmax] px-[16px] md:px-[4vw]">
      <Image src={TopFacetLeak} fill className="-z-1 rotate-180 object-cover" alt="" />

      <div className="flex flex-col items-stretch justify-center md:flex-row">
        <div className="items-between mb-[24px] flex flex-col bg-rb-white p-[40px] px-[10%] drop-shadow-[rgba(0,0,0,0.16)_-2px_0_4px] md:mb-0 md:mr-[24px] md:px-[40px]">
          <h3 className="mb-[30px] font-normal leading-4 tracking-tight fluid-text-3xl md:mb-[50px] md:h-[44px]">
            Become a Reforge Member
          </h3>

          <p className="mb-auto !leading-[1.2] tracking-tight fluid-text-lg">
            Reforge is a growth platform that provides access to relevant content, expert
            insights, and a community of like-minded professionals to help you solve the
            problems you face every day.
          </p>

          <ButtonMarketingCTA href="/login/?reforgeLogin=true" className="mt-[30px]">
            Sign Up ↗
          </ButtonMarketingCTA>
        </div>

        <div className="items-between flex flex-col bg-rb-white p-[40px] px-[10%] drop-shadow-[rgba(0,0,0,0.16)_-2px_0_4px] md:px-[40px]">
          <h3 className="mb-[30px] font-normal leading-4 tracking-tight fluid-text-3xl md:mb-[50px] md:h-[44px]">
            Meet our Experts
          </h3>

          <p className="mb-auto !leading-[1.2] tracking-tight fluid-text-lg">
            Connect with our Executives in Residence and Program Partners in real time,
            and gain access to their insights and experience working for the world’s most
            innovative companies.
          </p>

          <ButtonMarketingCTA href="/experts" className="mt-[30px]">
            Learn More ↗
          </ButtonMarketingCTA>
        </div>
      </div>
    </section>
  )
}

export default CareersBottomCards
