import React from 'react'

import ArtifactCard from 'components/cards/Content/ArtifactCard'
import CourseCard, {
  COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS
} from 'components/cards/Content/CourseCard'
import GuideCard from 'components/cards/Content/GuideCard'
import LegacyContentCard from 'components/cards/Content/LegacyContentCard'
import LegacyCourseCard, {
  COURSE_CARD_DESTINATION_TYPE_COURSE
} from 'components/cards/Content/LegacyCourseCard'
import LegacyProgramCard from 'components/cards/Content/LegacyProgramCard'

import {
  BookmarkFolderPartsFragment,
  BookmarkType,
  CurrentUserPartsFragment,
  ProgramBookmarkPartsFragment,
  Unit,
  UserCohortsQuery
} from 'gql'

interface MySavedItemsListByTypeProps {
  bookmarks: ProgramBookmarkPartsFragment[]
  handleRemoveFromFolder: (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => Promise<string | null | undefined>
  handleBookmarkClickTracking: (
    bookmark: ProgramBookmarkPartsFragment,
    component?: string
  ) => void
  restoreBookmark: (bookmark: ProgramBookmarkPartsFragment) => void
  openAddToBookmarkFolderModal: (bookmark: ProgramBookmarkPartsFragment) => void
  currentUser: CurrentUserPartsFragment
  mySavedItems: BookmarkFolderPartsFragment
  userCohortsData?: UserCohortsQuery
  bookmarkFolders?: BookmarkFolderPartsFragment[]
}

const MySavedItemsListByType = ({
  bookmarks,
  handleRemoveFromFolder,
  handleBookmarkClickTracking,
  restoreBookmark,
  openAddToBookmarkFolderModal,
  currentUser,
  mySavedItems,
  bookmarkFolders
}: MySavedItemsListByTypeProps) => {
  const isPaidMember =
    currentUser && (currentUser.is.paidMember || currentUser.is.planManager)
  const savedPrograms = bookmarks.filter((bookmark) =>
    ['ProgramBookmark', 'CourseBookmark'].includes(bookmark?.type)
  )
  const otherSavedItems = bookmarks.filter(
    (bookmark) =>
      ['ProgramBookmark', 'ArtifactBookmark', 'GuideBookmark'].includes(
        bookmark?.type
      ) === false
  )
  const artifactSavedItems = bookmarks.filter(
    (bookmark) => bookmark?.type === 'ArtifactBookmark'
  )
  const guideSavedItems = bookmarks.filter(
    (bookmark) => bookmark?.type === 'GuideBookmark'
  )

  const hasPrograms = !!savedPrograms?.length
  const hasOtherSavedItems = !!otherSavedItems?.length
  const hasArtifactSavedItems = !!artifactSavedItems?.length
  const hasGuideSavedItems = !!guideSavedItems?.length
  const gridClasses =
    'mb-4 p-0 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4'

  return (
    <>
      {hasArtifactSavedItems && (
        <>
          <div className="my-8 flex items-center justify-between">
            <div className="mb-0 text-xl font-semibold text-rb-gray-400 md:mb-0">
              Artifacts
            </div>
          </div>
          <div className={gridClasses}>
            {artifactSavedItems.map((bookmark) => (
              <React.Fragment key={bookmark.id}>
                {bookmark.artifact && (
                  <ArtifactCard
                    artifact={bookmark.artifact}
                    pageLocation="saved"
                    bookmarkFolders={bookmarkFolders}
                    restoreBookmark={restoreBookmark}
                    currentFolder={mySavedItems}
                    handleRemoveFromFolder={handleRemoveFromFolder}
                    openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                    bookmark={bookmark}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </>
      )}
      {hasGuideSavedItems && (
        <>
          <div className="my-8 flex items-center justify-between">
            <div className="mb-0 text-xl font-semibold text-rb-gray-400 md:mb-0">
              Guides
            </div>
          </div>
          <div className={gridClasses}>
            {guideSavedItems.map((bookmark) => (
              <React.Fragment key={bookmark.id}>
                {bookmark.guide && (
                  <GuideCard
                    guide={bookmark.guide as Unit}
                    pageLocation="saved"
                    bookmarkFolders={bookmarkFolders}
                    restoreBookmark={restoreBookmark}
                    currentFolder={mySavedItems}
                    handleRemoveFromFolder={handleRemoveFromFolder}
                    openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                    bookmark={bookmark}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </>
      )}
      {hasPrograms && (
        <>
          <div className="my-8 flex items-center justify-between">
            <div className="mb-0 text-xl font-semibold text-rb-gray-400 md:mb-0">
              Courses
            </div>
          </div>
          <div className={gridClasses}>
            {savedPrograms.map((bookmark) => (
              <>
                {bookmark.cmsProgram && (
                  <LegacyProgramCard
                    program={bookmark.cmsProgram}
                    currentUser={currentUser}
                    bookmark={bookmark}
                    restoreBookmark={restoreBookmark}
                    bookmarkFolders={bookmarkFolders}
                    currentFolder={mySavedItems}
                    openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                    handleRemoveFromFolder={handleRemoveFromFolder}
                    key={bookmark.id}
                  />
                )}
                {bookmark.type === BookmarkType.COURSEBOOKMARK &&
                  (bookmark.cclCourse ? (
                    <CourseCard
                      pageLocation={`saved (${isPaidMember ? '/saved' : 'free'})`}
                      course={bookmark.cclCourse}
                      key={bookmark.id}
                      destinationType={COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS}
                      bookmark={bookmark}
                      restoreBookmark={restoreBookmark}
                      bookmarkFolders={bookmarkFolders}
                      currentFolder={mySavedItems}
                      openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                      handleRemoveFromFolder={handleRemoveFromFolder}
                    />
                  ) : (
                    bookmark.course && (
                      <LegacyCourseCard
                        pageLocation={`saved (${isPaidMember ? '/saved' : 'free'})`}
                        cardType="on-demand"
                        course={bookmark.course}
                        key={bookmark.id}
                        user={currentUser}
                        showStartDate={false}
                        destinationType={COURSE_CARD_DESTINATION_TYPE_COURSE}
                        bookmark={bookmark}
                        restoreBookmark={restoreBookmark}
                        bookmarkFolders={bookmarkFolders}
                        currentFolder={mySavedItems}
                        openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                        handleRemoveFromFolder={handleRemoveFromFolder}
                      />
                    )
                  ))}
              </>
            ))}
          </div>
        </>
      )}
      {hasOtherSavedItems && (
        <>
          <div className="my-8 flex items-center justify-between">
            <div className="mb-0 text-xl font-semibold text-rb-gray-400 md:mb-0">
              Content
            </div>
          </div>
          <div className={gridClasses}>
            {otherSavedItems.map((bookmark) => (
              <React.Fragment key={bookmark.id}>
                {bookmark.content && (
                  <LegacyContentCard
                    content={bookmark.content}
                    onClick={() => handleBookmarkClickTracking(bookmark, 'content_card')}
                    bookmarkFolders={bookmarkFolders}
                    restoreBookmark={restoreBookmark}
                    currentFolder={mySavedItems}
                    handleRemoveFromFolder={handleRemoveFromFolder}
                    openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                    bookmark={bookmark}
                    key={bookmark.id}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </>
      )}
    </>
  )
}

export default MySavedItemsListByType
