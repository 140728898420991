import { bool, object } from 'yup'

import AntiCopyBlock from 'domains/AntiPiracy/AntiCopyBlock'
import Title from 'domains/AntiPiracy/AntiCopyTitle'
import PolicyLink from 'domains/AntiPiracy/PolicyLink'
import PolicyLinkCheckbox from 'domains/AntiPiracy/PolicyLinkCheckbox'

import { Checkbox, Form } from 'components/forms'

const AntiCopyViolationTwo = ({ submitForm }: { submitForm: () => void }) => {
  const validationSchema = object().shape({
    violationConfirmPolicy: bool().oneOf([true], 'Accepting terms is required'),
    confirmTwo: bool().oneOf([true], 'Checking is required')
  })

  return (
    <Form
      submit={submitForm}
      validationSchema={validationSchema}
      defaultValues={{ violationConfirmPolicy: false, confirmTwo: false }}
      submitButtonClassname="flex px-20 mx-auto"
    >
      <Title>FINAL WARNING: Copying Program Content is Prohibited</Title>

      <AntiCopyBlock>
        We work really hard to create content that helps you do the best work of your
        career, and we ask that you respect our <PolicyLink />. This means that we do not
        permit copying large portions of our content.
      </AntiCopyBlock>

      <AntiCopyBlock>
        This is your second warning. One more occurrence will result in us immediately
        locking your account without a refund. Please confirm your understanding below
      </AntiCopyBlock>

      <AntiCopyBlock>
        <PolicyLinkCheckbox />
      </AntiCopyBlock>

      <AntiCopyBlock>
        <Checkbox
          name="confirmTwo"
          label="I understand that this is
        my second warning and one more occurrence will result in my account being locked
        without a refund."
        />
      </AntiCopyBlock>

      <AntiCopyBlock>
        If you&#39;d like to take notes, please use our Bookmark feature, which allows you
        to take notes directly within our product.
      </AntiCopyBlock>

      <AntiCopyBlock>
        If you&apos;d like to share Reforge with others, please encourage them to visit
        <b>Reforge.com</b> and sign up for a free account. They&apos;ll be able to access
        our full library of artifacts. If they would like to view paid content, they will
        need to purchase a membership or be added to a team plan.
      </AntiCopyBlock>
    </Form>
  )
}

export default AntiCopyViolationTwo
