import clsx from 'clsx'
import * as React from 'react'
import { renderToString } from 'react-dom/server'
import { Link } from 'react-router-dom'

import BadgeableAvatar from 'components/BadgeableAvatar'
import { SVGIcon } from 'components/Icon'

import { UserHeadingProfilePartsFragment } from 'gql'

type UserHeadingType = 'small' | 'medium' | 'large' | 'program-host' | 'default'

interface UserHeadingProps {
  user: Omit<UserHeadingProfilePartsFragment, '__typename'>
  type?: UserHeadingType
  imgWidth: string
  imgHeight: string
  onClick?: (e: React.MouseEvent) => void
}

// these are ported over from an old scss file
// some of these classes are overwritten in the jsx below
const CLASSNAMES: {
  [type in UserHeadingType]: {
    name: string
    role: string
    location?: string
    link?: string
    text?: string
    companies?: string
  }
} = {
  'default': {
    name: '',
    role: ''
  },
  'small': {
    name: 'text-[15px] leading-[18px]',
    role: 'text-xs leading-[14px]',
    location: 'text-xs leading-[14px] text-rb-gray-400'
  },
  'medium': {
    name: 'text-base font-semibold leading-6 text-rb-gray-500',
    role: 'text-sm leading-[21px] text-rb-gray-400',
    location: 'text-sm leading-[21px] text-rb-gray-400'
  },
  'large': {
    link: 'items-start p-6',
    name: 'text-[21px] font-semibold leading-[26px] text-rb-gray-500',
    role: 'text-base leading-[21px] text-rb-gray-400',
    location: 'text-sm leading-[21px] text-rb-gray-300',
    text: 'ml-5'
  },
  'program-host': {
    name: 'text-sm font-bold text-black',
    role: 'text-sm',
    companies: 'text-xs text-rb-gray-300',
    text: 'ml-2.5'
  }
}

const UserHeading = ({
  user,
  type = 'default',
  imgWidth,
  imgHeight,
  onClick
}: UserHeadingProps) => {
  const {
    slug,
    fullName,
    avatarUrl,
    role,
    companyName,
    location,
    status,
    previousCompanies
  } = user
  const tooltipCopy = renderToString(
    <div className="py-1 px-2.5">
      <div className="flex items-center">
        <div className="mr-1">
          <SVGIcon name="private-user-lock" width="18" height="15" fill="#ffffff" />
        </div>
        This member&lsquo;s profile is private
      </div>
    </div>
  )

  const classNames = CLASSNAMES[type]

  const content = (
    <>
      <BadgeableAvatar
        user={user}
        avatarUrl={avatarUrl}
        width={imgWidth}
        height={imgHeight}
        fullName={fullName}
      />
      <div className={clsx(classNames.text, 'ml-4')}>
        <h4 className={clsx(classNames.name, 'm-0 text-lg font-bold text-black')}>
          {fullName}
        </h4>
        <div className={clsx(classNames.role, 'font-normal text-black')}>
          {role}
          {role && companyName && ' @ '}
          {companyName}
        </div>
        {location && (
          <div
            className={classNames.location}
            dangerouslySetInnerHTML={{
              __html: location.replace('•', '<span>•</span>')
            }}
          />
        )}
        {previousCompanies && (
          <div className={classNames.companies}>{previousCompanies}</div>
        )}
      </div>
    </>
  )

  return (
    <header className="m-0">
      {status === 'public' && (
        <Link
          data-test="member-link"
          to={`/directory/${slug}`}
          className={clsx(
            classNames.link,
            'uk-link-reset inline-flex items-center hover:text-rb-teal-600 hover:no-underline'
          )}
          onClick={onClick}
        >
          {content}
        </Link>
      )}
      {status === 'private' && (
        <div
          className="flex cursor-pointer items-center"
          uk-tooltip={`title: ${tooltipCopy}; cls: uk-active rf-tooltip-down-arrow; pos: top`}
        >
          {content}
        </div>
      )}
      {!status && <div className="flex items-center">{content}</div>}
    </header>
  )
}

export default UserHeading
