import { RadioGroup } from '@headlessui/react'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

type RadioButtonGroupProps = {
  defaultValue?: string | null | undefined
  data: { label: string; value: any }[]
  onChange?: (val: any) => void
}
export default function RadioButtonGroup({
  defaultValue,
  data,
  onChange = () => {}
}: RadioButtonGroupProps) {
  const [selected, setSelected] = useState(defaultValue || data[0].value)

  const handleChange = (value: any) => {
    setSelected(value)
    onChange(value)
  }
  return (
    <RadioGroup value={selected} onChange={handleChange}>
      {data.map((d, index) => (
        <div key={index}>
          <RadioGroup.Option value={d.value}>
            {({ checked }) => (
              <>
                <div className="mb-[15px] flex cursor-pointer items-center text-xl">
                  <div
                    className={twMerge(
                      'border-grey flex-no-shrink mr-2 inline-block h-[18px] w-[18px] rounded-full border-2 border-rb-gray-300',
                      checked && 'bg-rb-gray-300 shadow-inner-white'
                    )}
                  ></div>
                  <span className="ml-[10px] font-sans text-sm font-normal text-rb-black">
                    {d.label}
                  </span>
                </div>
              </>
            )}
          </RadioGroup.Option>
        </div>
      ))}
    </RadioGroup>
  )
}
