import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import BookmarkControls from 'domains/Bookmarks/BookmarkControls'
import BookmarkNoteForm from 'domains/Cms/BookmarkNoteForm'

import { ContentCardHeader } from 'components/ContentCard'
import VideoTimeElement from 'components/VideoTimeElement'

import {
  BookmarkFolderPartsFragment,
  BookmarkNoteFormPartsFragment,
  ProgramBookmarkItemPartsFragment,
  ProgramBookmarkPartsFragment,
  UserBookmarksFeedDocument,
  useDeleteBookmarkMutation,
  useUpdateBookmarkMutation
} from 'gql'

import notifyError from 'utils/errorNotifier'
import { onEnterKeyPress } from 'utils/keyboard'

import { ReactComponent as ExpandIcon } from 'images/icon--expand.svg'

import { removeHtmlTags } from './utils'

export interface BookmarkProps {
  bookmark: ProgramBookmarkPartsFragment
  onDelete?: (bookmark: ProgramBookmarkItemPartsFragment) => void
  onEdit: (bookmark: ProgramBookmarkItemPartsFragment) => void
  restoreBookmark: (bookmark: ProgramBookmarkPartsFragment) => void
  onClick?: (bookmark: ProgramBookmarkItemPartsFragment, component?: string) => void
  onMouseDown?: (bookmark: ProgramBookmarkItemPartsFragment, component?: string) => void
  showSectionLink?: boolean
  showProgramInBreadcrumb?: boolean
  bookmarkFolders?: BookmarkFolderPartsFragment[]
  currentFolder?: BookmarkFolderPartsFragment | null
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  handleRemoveFromFolder?: (
    bookmarkId: string,
    BookmarkFolder: BookmarkFolderPartsFragment
  ) => Promise<string | null | undefined>
  reforgeCollection?: boolean
  isCohortDashboard?: boolean
  cohortSlug?: string
  hideUpdatedAgo?: boolean
}

const Bookmark = ({
  bookmark: originalBookmark,
  onDelete,
  onEdit,
  showSectionLink = true,
  showProgramInBreadcrumb = false,
  onClick,
  onMouseDown,
  restoreBookmark,
  bookmarkFolders,
  currentFolder,
  openAddToBookmarkFolderModal,
  handleRemoveFromFolder,
  reforgeCollection = false,
  isCohortDashboard = false,
  cohortSlug,
  hideUpdatedAgo = false
}: BookmarkProps) => {
  const [bookmark, setBookmark] = useState<ProgramBookmarkPartsFragment>(originalBookmark)
  const [showNoteElement, setShowNoteElement] = useState(!!bookmark.noteBody)

  const [updateBookmark] = useUpdateBookmarkMutation()
  const [removeBookmark] = useDeleteBookmarkMutation({
    update: (cache, mutationResult) => {
      const deletedBookmarkId = mutationResult?.data?.deleteBookmark?.id
      if (!deletedBookmarkId) return

      const normalizedId = cache.identify({
        id: deletedBookmarkId,
        __typename: 'Bookmark'
      })
      cache.evict({ id: normalizedId })
      cache.gc()
    }
  })

  const noteInputRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (showNoteElement && !bookmark.noteBody) noteInputRef.current?.focus()
  }, [bookmark.noteBody, showNoteElement])

  const saveEditedBookmark = async ({ noteBody }: BookmarkNoteFormPartsFragment) => {
    if (!bookmark?.id) {
      notifyError(`invalid bookmark, cannot update ${JSON.stringify(bookmark)}`)
      return
    }

    const updatedBookmark = {
      ...bookmark,
      noteBody
    }

    const { errors } = await updateBookmark({
      variables: {
        input: {
          bookmarkId: bookmark.id,
          noteBody
        }
      }
    })

    if (errors) {
      notifyError(
        `Error saving dashboard bookmark, got errors ${errors} for bookmark ${JSON.stringify(
          updatedBookmark
        )}`
      )
    } else {
      setShowNoteElement(!!noteBody)
      setBookmark(updatedBookmark)
      onEdit(updatedBookmark)
    }
  }

  const deleteBookmark = async () => {
    if (!bookmark || !bookmark.id) {
      notifyError(`invalid bookmark, cannot delete ${JSON.stringify(bookmark)}`)
      return
    }
    const { errors } = await removeBookmark({
      variables: {
        input: {
          bookmarkId: bookmark.id
        }
      },
      refetchQueries: [UserBookmarksFeedDocument]
    })
    if (errors) {
      notifyError(
        `Error deleting dashboard bookmark, got errors ${errors} for bookmark ${JSON.stringify(
          bookmark
        )}`
      )
    } else {
      onDelete?.(bookmark)
      window.changeNumSectionBookmarks?.(bookmark.cmsSection?.id || '', -1)
    }
  }

  const {
    anchor,
    type,
    cmsModule,
    cmsSection,
    cmsProgram,
    basedOn,
    referenceImageUrl,
    seconds,
    updated
  } = bookmark

  const sectionName = cmsSection?.name || ''
  const moduleName = cmsModule?.name
  const programName = cmsProgram?.name || ''
  const programBreadcrumbs = showProgramInBreadcrumb
    ? `${programName} / ... / ${sectionName}`
    : `${moduleName} / ${sectionName}`
  // const [isInBookmarkFolder, setIsInBookmarkFolder] = useState(!!currentFolderId)

  // useEffect(() => {
  //   setIsInBookmarkFolder(!!currentFolderId)
  // }, [currentFolderId])

  let bookmarkContentPath = `/${cmsSection?.path || ''}${anchor ? `#${anchor}` : ''}`
  if (isCohortDashboard) {
    bookmarkContentPath = `/cohorts/${cohortSlug}${bookmarkContentPath}`
  }

  if (type === 'VideoBookmark') {
    bookmarkContentPath += `?seconds=${seconds}`
  }

  const handleAddNote = () => {
    setShowNoteElement?.(true)
  }

  const handleSelectBookmark = () => {
    window.location.href = bookmarkContentPath
  }

  const handleNoteBlur = (editedBookmark: BookmarkNoteFormPartsFragment) => {
    if (!editedBookmark.noteBody) setShowNoteElement(false)
  }

  const sectionLink =
    moduleName === 'Projects' || moduleName === 'Concepts' ? (
      <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-rb-gray-300">
        {moduleName} / {sectionName}
      </div>
    ) : (
      <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-rb-gray-300">
        {programBreadcrumbs}
      </div>
    )

  const referenceImgElement = referenceImageUrl ? (
    <div className="group flex">
      <div>
        <div
          className="uk-visible-toggle relative max-h-[383px] max-w-[680px]"
          role="link"
          tabIndex={0}
          uk-lightbox=""
          onClick={() => {
            onClick?.(bookmark)
            window.location.href = bookmarkContentPath
          }}
          onMouseDown={() => onMouseDown?.(bookmark)}
          onKeyDown={onEnterKeyPress(() => onClick?.(bookmark))}
        >
          <img
            className="max-h-[323px] max-w-full cursor-pointer"
            alt="bookmark"
            src={referenceImageUrl}
          />
          <div className="absolute left-6 bottom-[15px]">
            <VideoTimeElement
              bookmark={bookmark}
              type="Dashboard"
              onSelectBookmark={handleSelectBookmark}
            />
          </div>
        </div>
      </div>
      <div uk-lightbox="">
        <a
          className="relative right-16 top-6 mb-2 ml-2 flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg bg-rb-gray-400 opacity-0 hover:bg-rb-teal-200 group-hover:opacity-100 touch:opacity-100"
          href={referenceImageUrl}
        >
          <ExpandIcon width={16} height={16} />
          <img className="hidden" alt="bookmark" src={referenceImageUrl} />
        </a>
      </div>
    </div>
  ) : (
    ''
  )

  return (
    <>
      <Link
        className="hover:no-underline"
        to={bookmarkContentPath}
        onMouseDown={() => onMouseDown?.(bookmark, 'basedOn')}
      >
        <div className="flex flex-wrap rounded-md border border-rb-gray-250 p-6 hover:shadow-content-card">
          <div className="mb-4 flex h-[18px] w-full items-center text-xs">
            <ContentCardHeader headerClassName="pb-0 mr-[8px]" typename="Bookmark" />
            {showSectionLink ? sectionLink : ''}
            {!hideUpdatedAgo && (
              <div className="ml-auto mr-2 flex-shrink-0 cursor-default pl-4 text-rb-gray-300">
                {updated} ago
              </div>
            )}
            {!reforgeCollection && !currentFolder?.forkedFromId && (
              <BookmarkControls
                bookmark={bookmark}
                onDeleteBookmark={deleteBookmark}
                onEditBookmark={saveEditedBookmark}
                restoreBookmark={restoreBookmark}
                onAddNote={handleAddNote}
                handleRemoveFromFolder={handleRemoveFromFolder}
                bookmarkFolders={bookmarkFolders}
                currentFolder={currentFolder}
                openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
              />
            )}
          </div>
          <div className="w-full">
            {basedOn && (
              <div
                className="border-l-4 border-rb-gray-250 px-4 font-sans text-base leading-[1.5] text-rb-gray-500"
                dangerouslySetInnerHTML={{ __html: removeHtmlTags(basedOn) }}
              />
            )}
            {referenceImgElement}
          </div>
          <div className={twMerge('w-full', showNoteElement ? 'mt-4 block' : 'hidden')}>
            <BookmarkNoteForm
              bookmark={bookmark}
              saveBookmarkNote={saveEditedBookmark}
              onSelectBookmark={handleSelectBookmark}
              onBlur={handleNoteBlur}
              ref={noteInputRef}
              type="Dashboard"
            />
          </div>
        </div>
      </Link>
    </>
  )
}

export default Bookmark
