import Banner from 'components/Banner'
import Button from 'components/Button'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import useOpenPurchaseFlow from 'hooks/useOpenPurchaseFlow'

import { trackCtaClicked } from 'utils/tracking/analytics'

const PaymentBanner = () => {
  const { openPurchaseFlow } = useOpenPurchaseFlow()

  const onJoinNowClick = () => {
    trackCtaClicked({
      cta_location: 'join reforge banner',
      cta_type: 'button',
      text: 'join now'
    })

    openPurchaseFlow()
  }

  return (
    <Banner
      title="Become a member today!"
      cta={
        <Button id="payment-banner-cta" onClick={onJoinNowClick}>
          Join Now
        </Button>
      }
      dismissable={false}
    >
      <div data-test="payment-banner-content">
        <RfParagraphSmall>
          Access the world’s leading career development platform for tech professionals.
        </RfParagraphSmall>
      </div>
    </Banner>
  )
}

export default PaymentBanner
