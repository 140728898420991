import { useEffect, useState } from 'react'

import CollectiveBadge from 'domains/Member/CollectiveBadge'

import { SVGIcon } from 'components/Icon'
import { SquarishAvatarImage } from 'components/SquarishAvatarImage'

import { linkedInInputToUrl, twitterInputToUrl } from 'utils/social'
import { cn } from 'utils/tailwind'
import { track } from 'utils/tracking/segment'

type ProfileInfoUser = {
  id: string
  accessPolicyKind: string
  profile: {
    avatarUrl: string
    hasBadge?: boolean | null
    kind?: string | null
    twitter?: string | null
    linkedin?: string | null
    fullName?: string | null
    role?: string | null
    companyName?: string | null
    location?: string | null
  }
}

interface ProfileInfoProps {
  user: ProfileInfoUser
  variation?: string
}

export default function ProfileInfo({ user, variation }: ProfileInfoProps) {
  const [twitterUrl, setTwitterUrl] = useState(
    user.profile.twitter ? twitterInputToUrl(user.profile.twitter) : undefined
  )
  const [linkedInUrl, setLinkedInUrl] = useState(
    user.profile.linkedin ? linkedInInputToUrl(user.profile.linkedin) : undefined
  )

  useEffect(() => {
    setTwitterUrl(
      user.profile.twitter ? twitterInputToUrl(user.profile.twitter) : undefined
    )
  }, [user.profile.twitter])

  useEffect(() => {
    setLinkedInUrl(
      user.profile.linkedin ? linkedInInputToUrl(user.profile.linkedin) : undefined
    )
  }, [user.profile.linkedin])

  const clickedSocial = (src: string) => {
    // @ts-ignore - 'Clicked Social' event is not defined in Segment JIRA#REF-5159
    track('Clicked Social', { src })
    window.open(src)
  }
  const baseball = variation === 'baseball'

  const classNames = baseball ? 'flex-col' : ''
  const nameClasses = baseball ? 'text-xl' : 'text-sm leading-4 sm:text-2xl sm:leading-7'

  return (
    <div className={cn('member-profile flex', classNames)}>
      <div
        className={cn(
          variation === 'baseball' ? 'uk-width-1-1 pl-0 sm:pl-1' : 'uk-width-auto pl-0',
          'uk-position-relative uk-visible-toggle'
        )}
      >
        <div
          className={cn(
            'uk-position-relative h-14 w-14 sm:mx-3 sm:h-[127px] sm:w-[110px]',
            baseball ? 'ml-3 mb-1.5 sm:ml-1' : ''
          )}
        >
          <SquarishAvatarImage
            className="h-full w-full"
            avatarUrl={user.profile.avatarUrl}
            alt={user.profile.fullName || ''}
          />
        </div>
      </div>

      <div
        className={cn(
          variation === 'baseball' ? 'uk-width-1-1' : 'uk-width-expand@m',
          'uk-visible-toggle pl-3'
        )}
      >
        <div className="member-profile__name flex items-center sm:mt-4">
          {Boolean(user.profile.fullName) && (
            <span className={`${nameClasses} font-semibold`}>
              {user.profile.fullName}
            </span>
          )}
          {(user.accessPolicyKind === 'collective' ||
            user.profile.kind === 'collective') && <CollectiveBadge />}
        </div>
        <div className="text-sm font-medium leading-5 sm:mt-1 sm:text-base">
          {user.profile.role}{' '}
          {user.profile.companyName && <span>@ {user.profile.companyName}</span>}
        </div>
        <div className={`${baseball ? ' ' : 'hidden sm:block'} text-sm text-rb-gray-300`}>
          {user.profile.location}
        </div>

        <div
          className={`flex items-center ${
            variation === 'baseball' ? 'mb-5' : 'sm:hidden'
          } mt-3`}
        >
          {linkedInUrl && (
            <a
              className="mr-3"
              onClick={() => clickedSocial(linkedInUrl)}
              data-test="member-profile-linkedin"
            >
              <span>
                <SVGIcon name="linkedin-medium" />
              </span>
            </a>
          )}
          {twitterUrl && (
            <a
              onClick={() => clickedSocial(twitterUrl)}
              data-test="member-profile-twitter"
            >
              <SVGIcon name="twitter-filled" />
            </a>
          )}
        </div>
      </div>
    </div>
  )
}
