import { UserCohortFilter } from 'pages/MemberDirectoryPage/helpers'

import { SVGIcon } from 'components/Icon'

import { UserSearchFilters } from 'gql'

import { isEmptyArrayParameter } from 'utils/arrayUtils'
import { onEnterKeyPress } from 'utils/keyboard'

import AreasOfExpertise from './Filters/AreasOfExpertise'
import BusinessModel from './Filters/BusinessModel'
import Cohort from './Filters/Cohort'
import CommunityInterests from './Filters/CommunityInterests'
import Company from './Filters/Company'
import CompanyAudience from './Filters/CompanyAudience'
import Function from './Filters/Function'
import IsCollective from './Filters/IsCollective'
import JobTitle from './Filters/JobTitle'
import Location from './Filters/Location'
import Name from './Filters/Name'

interface FilterProps {
  userCohorts: UserCohortFilter[]
  userCohortSeasons: string[]
  filters: UserSearchFilters
  handleResetAll: () => void
  numberOfFilters: number
  setFilters: (filters: UserSearchFilters) => void
  setShowModalFilter: (show: boolean) => void
  showModalFilter: boolean
}

const Filter = ({
  userCohorts,
  userCohortSeasons,
  filters,
  handleResetAll,
  numberOfFilters,
  setFilters,
  setShowModalFilter,
  showModalFilter
}: FilterProps) => {
  const isFiltering = Object.values(filters).some(
    (value) => value !== '' && !isEmptyArrayParameter(value)
  )

  return (
    <div
      className={`hide-scrollbar w-full overflow-y-scroll ${
        showModalFilter
          ? ''
          : 'hidden h-full pb-8 tl:top-[104px] tl:block tl:w-[300px] tl:self-start xl:w-1/4'
      }`}
    >
      <div className="mx-auto mb-2.5 flex h-12 items-center justify-between border-b border-rb-gray-100 text-base font-semibold text-rb-gray-400">
        <div>
          Filter{' '}
          {numberOfFilters > 0 && (
            <span className="inline-block h-3 w-3 bg-rb-gray-500 text-center align-middle text-[0.625rem] leading-3 text-white">
              {numberOfFilters}
            </span>
          )}{' '}
        </div>
        <div className="flex">
          <div
            className="px-[7px] text-sm font-normal leading-[30px] text-rb-gray-300 hover:cursor-pointer hover:text-rb-teal-200 active:bg-rb-teal-200"
            onClick={() => isFiltering && handleResetAll()}
            onKeyUp={onEnterKeyPress(() => isFiltering && handleResetAll())}
            role="button"
            tabIndex={0}
          >
            {isFiltering && 'Reset All'}
          </div>
          {showModalFilter && (
            <div onClick={() => setShowModalFilter(false)}>
              <SVGIcon
                name="close"
                height="24"
                width="24"
                stroke="#888"
                strokeWidth="2"
              />
            </div>
          )}
        </div>
      </div>
      <div>
        <Name filters={filters} setFilters={setFilters} />
        <Company filters={filters} setFilters={setFilters} />
        <Location filters={filters} setFilters={setFilters} />
        <JobTitle filters={filters} setFilters={setFilters} />
        {userCohorts.length > 0 && (
          <Cohort
            userCohorts={userCohorts}
            userCohortSeasons={userCohortSeasons}
            filters={filters}
            setFilters={setFilters}
          />
        )}
        <Function filters={filters} setFilters={setFilters} />
        <AreasOfExpertise filters={filters} setFilters={setFilters} />
        <IsCollective filters={filters} setFilters={setFilters} />
        <BusinessModel filters={filters} setFilters={setFilters} />
        <CompanyAudience filters={filters} setFilters={setFilters} />
        <CommunityInterests filters={filters} setFilters={setFilters} />
      </div>
    </div>
  )
}

export default Filter
