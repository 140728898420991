import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import CreatorProfile from 'domains/CreatorProfile/CreatorProfile'
import { formatPositionAtCompany } from 'domains/Profile/utils'
import Content404 from 'domains/Sanity/marketingSite/Content404'
import MarketingHeadSEO, {
  GenericPageType
} from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'

import { Loading } from 'components'

import { PageSeo, PageTypeEnum, SiteSettings, useProfilePageQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useFirstSigninSource from 'hooks/useFirstSigninSource'

interface ProfilePageProps {
  setPage?: (page: GenericPageType) => void
}

const ProfilePage = ({ setPage }: ProfilePageProps) => {
  const { slug } = useParams<{ slug: string }>()
  const { isLoggedIn } = useCurrentUser()
  const { setCustomSigninSource } = useFirstSigninSource() // sets the reforge_signin_source cookie on every page with the pathname
  setCustomSigninSource('/profiles')

  const { loading, error, data } = useProfilePageQuery({
    variables: { slug }
  })

  const artifactAuthor = data?.profile?.artifactAuthor
  const userProfile = data?.profile?.user

  const page: GenericPageType | null = useMemo(() => {
    if (userProfile) {
      const position = formatPositionAtCompany(
        userProfile.role,
        userProfile.companyName,
        'at'
      )
      const previously = userProfile.previousCompanies
        ?.map((previousCompany) => previousCompany.company?.name)
        .join(', ')
      const location = userProfile.location

      const title = `${userProfile.fullName}${position && ` – ${position}`}`
      const description = `${position}${previously ? ` – Previously ${previously}` : ''}. ${location ? `Located in ${location}` : ''}`

      return {
        title,
        seo: {
          metaTitle: title,
          metaDesc: description,
          shareTitle: title,
          shareDesc: description,
          shareGraphic: { asset: userProfile.avatarUrl },
          shareGraphicAlt: userProfile.fullName,
          type: 'website' as PageTypeEnum
        } as PageSeo
      }
    }

    return null
  }, [userProfile])

  useEffect(() => {
    if (page && setPage) {
      setPage(page)
    }
  }, [page, setPage])

  if (loading) return <Loading />
  if (error) {
    return <p>Error: {error?.message}</p>
  }

  // If the end user hits this, it may mean that the ArtifactAuthor didn't have a
  // UserID set. This should not be possible going forward, but exists on some
  // historical users. Should be fixed manually in Sanity.
  if (!artifactAuthor || !userProfile) {
    return <Content404 />
  }

  return (
    <>
      {isLoggedIn && page && <MarketingHeadSEO page={page} site={{} as SiteSettings} />}
      <CreatorProfile authorProfile={artifactAuthor!} userProfile={userProfile!} />
    </>
  )
}

export default ProfilePage
