import { twMerge } from 'tailwind-merge'

import { useArtifactAnnotationsCountQuery, useArtifactCommentsCountQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { trackNavigationClicked } from 'utils/tracking/analytics'

import CommentsIcon from '../../assets/images/comments.svg'
import NotesIcon from '../../assets/images/notes-paper-text.svg'
import NoteCommentButton from './NoteCommentButton'

interface NoteCommentProps {
  artifact: any // @todo: define type
  className?: string
  sideDrawerOpen: boolean
  sideDrawerTab: string
  onNoteButtonClick: () => void
  onCommentButtonClick: () => void
}

const NoteAndCommentButtons = ({
  artifact,
  className,
  sideDrawerOpen,
  sideDrawerTab,
  onNoteButtonClick,
  onCommentButtonClick
}: NoteCommentProps) => {
  const { isLoggedIn, currentUser: user } = useCurrentUser()

  const {
    data: { artifactCommentsCount: commentCount } = {},
    loading: loadingCommentsCount
  } = useArtifactCommentsCountQuery({
    variables: { artifactId: artifact.id },
    skip: !isLoggedIn
  })

  const {
    data: { artifactAnnotationsCount: annotationCount } = {},
    loading: loadingAnnotationsCount
  } = useArtifactAnnotationsCountQuery({
    variables: { artifactSlug: artifact.slug },
    skip: !isLoggedIn
  })

  const handleNoteButtonClick = () => {
    trackNavigationClicked({
      location: 'artifact_detail_page',
      text: 'Notes',
      type: 'button'
    })

    onNoteButtonClick()
  }

  const handleCommentButtonClick = () => {
    trackNavigationClicked({
      location: 'artifact_detail_page',
      text: 'Comments',
      type: 'button'
    })

    onCommentButtonClick()
  }

  return (
    <div className={twMerge('flex items-center gap-[15px]', className)}>
      <div
        data-for="notes-count-button"
        data-tip={`${
          sideDrawerOpen
            ? sideDrawerTab === 'Notes'
              ? 'Hide notes'
              : 'View notes'
            : 'View notes'
        }`}
      >
        <NoteCommentButton
          onClick={handleNoteButtonClick}
          label="Notes"
          src={NotesIcon}
          loading={loadingAnnotationsCount}
          count={annotationCount}
        />
      </div>
      {user && (
        <div
          data-for="comments-count-button"
          data-tip={`${
            sideDrawerOpen
              ? sideDrawerTab === 'Comments'
                ? 'Hide comments'
                : 'View comments'
              : 'View comments'
          }`}
        >
          <NoteCommentButton
            onClick={handleCommentButtonClick}
            label="Comments"
            src={CommentsIcon}
            loading={loadingCommentsCount}
            count={commentCount}
          />
        </div>
      )}
    </div>
  )
}

export default NoteAndCommentButtons
