import { TagColor } from 'components/Tag/helpers'

import { PATH_AND_EMPTY_QUERY } from 'constants/memberDirectory'
import { FUNCTION_OPTIONS, NOT_SOCIAL_ANSWER } from 'constants/onboarding'

import { Tag } from './Tag'

interface LinkTagsProps {
  filterName: string
  input?: string
  tooltipString?: string
  tagVariant?: TagColor
}

const LinkTags = ({ input, filterName, tooltipString, tagVariant }: LinkTagsProps) => {
  if (!input) {
    return null
  }

  return (
    <div className="flex flex-wrap pb-2">
      {input.split(',').map((tag) => {
        if (tag === NOT_SOCIAL_ANSWER) {
          return
        }
        if (filterName === 'function') {
          const selected = FUNCTION_OPTIONS.find((option: any) => option.label === tag)
          const href = PATH_AND_EMPTY_QUERY.replace(
            `${filterName}=`,
            `${filterName}=${selected.id}`
          )
          return (
            <Tag
              className="mr-2.5 mb-2"
              key={selected.label}
              text={selected.label}
              href={href}
              tooltipContent={tooltipString}
              variant={tagVariant}
            />
          )
        } else {
          const href = PATH_AND_EMPTY_QUERY.replace(
            `${filterName}=`,
            `${filterName}=${tag}`
          )
          return (
            <Tag
              className="mr-2.5 mb-2"
              key={tag}
              text={tag}
              href={href}
              tooltipContent={tooltipString}
              variant={tagVariant}
            />
          )
        }
      })}
    </div>
  )
}

export default LinkTags
