import { twMerge } from 'tailwind-merge'

import FacePileImage from './FacePileImage'

export interface FacePileUser {
  id: string
  avatarUrl?: string
  profile?: {
    avatarUrl: string
  }
}

interface FacePileProps {
  users?: FacePileUser[]
  imageSize?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge'
  className?: string
  limit?: number
  showExtraCount?: boolean
  groupKey?: string
}

const FacePile = ({
  users = [],
  imageSize = 'large',
  className,
  limit,
  showExtraCount = true,
  groupKey
}: FacePileProps) => {
  const slicedUsers = limit ? users.slice(0, limit) : users

  return (
    <>
      {slicedUsers.map((u, index) => (
        <FacePileImage
          imageSize={imageSize}
          key={groupKey ? `${groupKey}_${u.id}` : u?.id || index}
          zIndex={users.length - index}
          src={u.profile?.avatarUrl || u.avatarUrl || ''}
          className={twMerge(className, 'chromatic-ignore', index === 0 && 'ml-0')}
        />
      ))}

      {showExtraCount && limit && users.length > limit && (
        <div className="pl-[2px] text-xs font-semibold">+{users.length - limit}</div>
      )}
    </>
  )
}
export default FacePile
