import { MouseEvent, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import ReactTooltip from 'react-tooltip'

import { useProfileEditModal } from 'domains/Profile/ProfileEditModal'
import UserAvatarInfo from 'domains/User/UserAvatarInfo'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'

import { GENERAL_TAB } from 'constants/profile'

import { UserAccountDetailsQuery, UserStatus, useUpdateUserStatusMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { trackMyAccountProfileClicked } from 'utils/tracking/analytics'

import { useFreeUserProfileEditModal } from './ProfileEditModal/FreeUserProfileEditModal'

interface ProfileSummaryProps {
  // TODO: define a fragment
  user: NonNullable<UserAccountDetailsQuery['user']>
}

export default function ProfileSummary({ user }: ProfileSummaryProps) {
  const canViewPublic = user.is.member
  const canHideProfile = user.is.member || user.is.expired
  const profileIsPublic = user.profile.status === UserStatus.PUBLIC
  const [updateUserStatus] = useUpdateUserStatusMutation()
  const { openProfileEditModal } = useProfileEditModal()
  const { openFreeUserProfileEditModal } = useFreeUserProfileEditModal()
  const history = useHistory()
  const [sliderFocused, setSliderFocused] = useState(false)
  const handleOnFocus = () => setSliderFocused(true)
  const handleOnBlur = () => setSliderFocused(false)
  const { currentUser } = useCurrentUser()

  useEffect(() => {
    if (location.hash === '#email_preferences') {
      const element = document.getElementById('email_preferences')
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [])

  const handleVisibilityChanged = () => {
    const newStatus =
      user.profile.status === UserStatus.PRIVATE ? UserStatus.PUBLIC : UserStatus.PRIVATE
    updateUserStatus({
      variables: { input: { status: newStatus } },
      optimisticResponse: {
        __typename: 'Mutation',
        updateUser: {
          __typename: 'UpdateUserPayload',
          user: {
            __typename: 'User',
            id: user.id,
            profile: {
              __typename: 'UserProfile',
              status: newStatus
            }
          }
        }
      }
    })
  }

  const handleViewProfileClick = () => {
    if (currentUser?.is.creator) {
      return history.push(`/profiles/${user.profile.sanitySlug}`)
    }

    if (user.profile && user.profile.profilePath && profileIsPublic && canViewPublic) {
      return history.push(user.profile.profilePath)
    }
  }

  const handleEditClick = (e: MouseEvent<HTMLButtonElement>, field?: string) => {
    e.preventDefault()
    trackMyAccountProfileClicked({
      access_policy_kind: user.accessPolicyKind,
      user_id: user.id,
      email: user.contact.primaryEmail
    })
    if (user.is.member || user.is.paidMember) {
      openProfileEditModal({ initialTab: GENERAL_TAB, autofocusField: field })
    } else {
      openFreeUserProfileEditModal()
    }
  }

  const getPublicProfileTooltipText = () => {
    const visibilityTooltipText = `Your profile is set to ${
      profileIsPublic ? 'public' : 'private'
    }`
    const promptToSubscribeTooltipText = 'Included with a Reforge subscription'
    return canViewPublic ? visibilityTooltipText : promptToSubscribeTooltipText
  }

  return (
    <div
      data-test="account-profile"
      className="rf-rb-card rf-rb-card mb-5 flex flex-wrap justify-between bg-white p-6 gap-4"
    >
      <>
        <UserAvatarInfo
          tailwind="flex"
          user={user}
          avatarUrl={user.profile.avatarUrl}
          onEditAvatarClick={handleEditClick}
        />
        <div className="flex grow flex-col justify-center self-stretch">
          <div className="flex items-center justify-end">
            <Button
              variant="outline"
              size="x-small"
              shape="rounded-full"
              disabled={
                currentUser?.is.creator ? false : !(canViewPublic && profileIsPublic)
              }
              onClick={handleViewProfileClick}
              data-tip={getPublicProfileTooltipText()}
            >
              View Public Profile
            </Button>
            <ReactTooltip effect="solid" />

            <Button
              variant="outline"
              size="x-small"
              shape="rounded-full"
              className="ml-2"
              onClick={handleEditClick}
            >
              Edit
            </Button>
          </div>

          {canHideProfile && (
            <div className="mt-4 flex items-center justify-end tl:justify-end xl:mt-7">
              <SVGIcon className="h-3" name="invisible-eye" fill="#a2a1a2" />
              <div className="ml-4 text-xs font-medium uppercase tracking-widest text-rb-gray-300">
                Hide Member Profile
              </div>
              <label className="relative ml-4 inline-block h-6 w-[50px]">
                <input
                  type="checkbox"
                  className="h-0 w-0 opacity-0"
                  checked={!profileIsPublic}
                  onChange={handleVisibilityChanged}
                  onFocus={handleOnFocus}
                  onBlur={handleOnBlur}
                />
                <span
                  className={`absolute inset-0 cursor-pointer rounded-[15px] ${
                    !profileIsPublic
                      ? 'bg-rb-blue-100 before:translate-x-[26px]'
                      : 'bg-rb-gray-100'
                  } ${
                    sliderFocused ? 'outline-reforge-blue shadow-slider' : ''
                  } duration-400 before:absolute before:bottom-0.5 before:left-0.5 before:h-5 before:w-5 before:rounded-[50%] before:bg-white before:duration-400 before:content-['']`}
                />
              </label>
            </div>
          )}
        </div>
      </>
    </div>
  )
}
