import Button from 'components/Button'

import { trackCtaClicked } from 'utils/tracking/analytics'

const ManageSubscriptionButton = ({ alternateCopy }: { alternateCopy?: string }) => {
  const buttonText = alternateCopy || 'Manage Subscription'
  const destination = '/billing'

  const handleClick = () => {
    trackCtaClicked({
      cta_location: 'subscription_card',
      cta_type: 'button',
      text: buttonText.toLowerCase(),
      destination
    })
  }

  return (
    <Button
      size="x-small"
      shape="rounded-full"
      className="h-7.5"
      onClick={handleClick}
      href="/billing"
    >
      {buttonText}
    </Button>
  )
}

export default ManageSubscriptionButton
