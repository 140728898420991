import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { CohortPost, CohortPostCardHeader } from 'components/CohortPostCard'

import { CohortPostPartsFragment, CohortPostUserPartsFragment } from 'gql'

interface CohortPostCardProps {
  post: CohortPostPartsFragment
  className?: string
  showProfile: (user: CohortPostUserPartsFragment) => void
  includeInteractions?: boolean
  children?: ReactNode
  openEditModal?: () => void
  onDeletePostClick?: () => void
  onReportPostClick: () => void
  refetch?(): Promise<unknown>
  showThread: () => void
  cohortSlug: string
}

const CohortPostCardBaseContainer = ({
  post,
  className,
  showProfile,
  includeInteractions = false,
  children,
  openEditModal,
  onDeletePostClick,
  onReportPostClick,
  refetch,
  showThread,
  cohortSlug
}: CohortPostCardProps) => {
  return (
    <div
      role="button"
      tabIndex={0}
      className={twMerge(
        'rounded-[12px] border pt-4 pb-6 pl-6 pr-4 hover:cursor-pointer',
        className,
        !!includeInteractions && post.isPinned && 'border-none bg-rb-teal-50/30'
      )}
      style={
        !!includeInteractions && post.isPinned
          ? {
              background:
                'radial-gradient(96.67% 128.89% at 25.14% 71.61%, rgba(180, 243, 241, 0.3) 0%, rgba(225, 255, 228, 0.3) 79.69%)'
            }
          : {}
      }
      onClick={showThread}
      onKeyDown={showThread}
    >
      <CohortPostCardHeader
        post={post}
        user={post.user}
        showProfile={showProfile}
        includeInteractions={includeInteractions}
        openEditModal={openEditModal}
        onDeletePostClick={onDeletePostClick}
        onReportPostClick={onReportPostClick}
        refetch={refetch}
      />

      <div className="pl-0 pt-1 pr-2 sm:-mt-6 sm:pl-[56px]">
        <CohortPost post={post} cohortSlug={cohortSlug} />

        {children}
      </div>
    </div>
  )
}

export default CohortPostCardBaseContainer
