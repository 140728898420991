import { SquarishAvatarImage } from 'components/SquarishAvatarImage'
import RfMetaMini from 'components/typography/RfMeta/RfMetaMini'
import RfParagraphMediumSemiBold from 'components/typography/RfParagraph/RfParagraphMediumSemiBold'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'

import { Host, ProgramSelectorHostPartsFragment } from 'gql'

export interface CollaboratorDetailsProps {
  hosts: ProgramSelectorHostPartsFragment[]
  label: string
  row?: boolean
}
const CollaboratorDetails = ({ hosts, label, row }: CollaboratorDetailsProps) => {
  const manyHosts = hosts.length > 2

  return (
    <div>
      <RfMetaMini>{label}</RfMetaMini>
      <div className={row && !manyHosts ? 'flex flex-row' : ''}>
        {hosts.map((host: Host) => (
          <div className="mt-4 flex h-fit w-full flex-row rounded-lg p-2" key={host.id}>
            <SquarishAvatarImage
              avatarUrl={host.thumbnailUrl}
              alt={host.name || 'Host picture'}
              className="mb-5 min-h-[93px] min-w-[96px] rounded-[4px] xs:mb-0"
            />
            <div className="ml-8">
              <RfParagraphMediumSemiBold className="mb-1">
                {host.name}
              </RfParagraphMediumSemiBold>
              <RfParagraphMini>
                {host.title}
                {host.previousCompanies ? `, Formerly ${host.previousCompanies}` : ''}
              </RfParagraphMini>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CollaboratorDetails
