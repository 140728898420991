import {
  MAX_WIDTH_TAILWIND_SM,
  MAX_WIDTH_TAILWIND_TL,
  MIN_WIDTH_TAILWIND_LG,
  MIN_WIDTH_TAILWIND_MD,
  MIN_WIDTH_TAILWIND_SM,
  MIN_WIDTH_TAILWIND_TL
} from 'constants/breakpoints'

import useMediaQuery from 'hooks/useMediaQuery'

const useSeoPageSlidesCount = (props?: { fourthSlide?: boolean }) => {
  const { fourthSlide = false } = props || {}
  const thirdSlideMobile = useMediaQuery(
    `(min-width: ${MIN_WIDTH_TAILWIND_SM}) and (max-width: ${MAX_WIDTH_TAILWIND_SM})`
  )

  const thirdSlideTablet = useMediaQuery(
    `(min-width: ${MIN_WIDTH_TAILWIND_MD}) and (max-width: ${MAX_WIDTH_TAILWIND_TL})`
  )
  const thirdSlideDesktop = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_TL})`)
  const fourthSlideDesktop = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_LG})`)

  return [
    thirdSlideMobile,
    fourthSlide && thirdSlideTablet,
    thirdSlideDesktop,
    fourthSlide && fourthSlideDesktop,
    true,
    true
  ].filter((slide) => slide).length
}

export default useSeoPageSlidesCount
