import React from 'react'
import { twMerge } from 'tailwind-merge'

interface IBreadcrumbs {
  disabled?: boolean
  children: React.ReactNode
}

export const Breadcrumbs = (props: IBreadcrumbs) => {
  return (
    <ul className="m-0 flex list-none flex-wrap items-center p-0 font-semibold leading-[1.25]">
      {React.Children.map(props.children, (child) => {
        const listClassName = props.disabled ? 'pointer-events-none' : ''
        return (
          child && (
            <li className={twMerge('ml-2.5 flex first:mr-2.5 first:ml-0', listClassName)}>
              {child}
            </li>
          )
        )
      })}
    </ul>
  )
}
