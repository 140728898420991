import { ReactComponent as InfoIcon } from 'icon--info.svg'
import pluralize from 'pluralize'
import ReactTooltip from 'react-tooltip'
import { twMerge } from 'tailwind-merge'

import { EngagementReportLesson, EngagementReportSavedItem } from 'gql'

import { getPrettyFullDate } from 'utils/date'

interface ContentEngagementProps {
  topLessons: EngagementReportLesson[]
  recentLessons: EngagementReportLesson[]
  recentSavedItems: EngagementReportSavedItem[]
}

export const ContentEngagement = ({
  topLessons,
  recentLessons,
  recentSavedItems
}: ContentEngagementProps) => {
  if (
    topLessons.length === 0 &&
    recentLessons.length === 0 &&
    recentSavedItems.length === 0
  ) {
    return null
  }

  return (
    <div>
      <h1 className="my-0 text-xl font-medium">What your team is working on</h1>
      <p className="mb-6 text-sm">Content engagement this billing period</p>
      <div className="gap-x-4 lg:flex">
        {!!topLessons.length && (
          <ContentEngagementSection
            title="Top Lessons"
            tooltip="Top lessons by views this billing period"
            lessons={topLessons}
          />
        )}
        {!!recentLessons.length && (
          <ContentEngagementSection
            title="Recent Lessons"
            tooltip="Most recently viewed lessons"
            lessons={recentLessons}
          />
        )}
        {!!recentSavedItems.length && (
          <ContentEngagementSection
            className="mb-0"
            title="Recent Insights Saved"
            tooltip="Most recently bookmarked content"
            savedItems={recentSavedItems}
          />
        )}
      </div>
    </div>
  )
}

const ContentEngagementSection = ({
  title,
  tooltip,
  lessons,
  savedItems,
  className
}: {
  title: string
  tooltip: string
  lessons?: EngagementReportLesson[]
  savedItems?: EngagementReportSavedItem[]
  className?: string
}) => {
  return (
    <div className={twMerge('mb-10 lg:w-1/3', className)}>
      <div>
        <span className="text-xs font-medium uppercase tracking-wider">{title}</span>
        <span className="ml-1" data-tip={tooltip} data-for="team-metric-card-tip">
          <InfoIcon width={10} height={10} className="mb-1 inline" />
        </span>
        <ReactTooltip
          id="team-metric-card-tip"
          effect="solid"
          backgroundColor="#4D4D4D"
          className="w-64"
          multiline={true}
        />
      </div>
      {lessons?.map((lesson) => {
        return <LessonCard key={lesson.id} lesson={lesson} />
      })}
      {savedItems?.map((savedItem) => {
        return <SavedItemCard key={savedItem.id} savedItem={savedItem} />
      })}
    </div>
  )
}

const LessonCard = ({ lesson }: { lesson: EngagementReportLesson }) => {
  const { name, lastViewedAt, numViews, programName } = lesson
  return (
    <div className="mb-2 rounded-lg border border-rb-gray-250 p-4 lg:min-h-[110px]">
      <p className="mb-2 text-m-mini font-medium uppercase tracking-wider">
        {programName}
      </p>
      <p className="mb-0 text-sm font-semibold">{name}</p>
      {lastViewedAt ? (
        <p className="text-xs">Last viewed: {getPrettyFullDate(lastViewedAt)}</p>
      ) : (
        <p className="text-xs">
          {numViews} {pluralize('View', numViews)}
        </p>
      )}
    </div>
  )
}

const SavedItemCard = ({ savedItem }: { savedItem: EngagementReportSavedItem }) => {
  const { cmsSectionName, updatedAt } = savedItem
  return (
    <div className="mb-2 rounded-lg border border-rb-gray-250 p-4 lg:min-h-[110px]">
      <p className="mb-2 text-sm font-semibold">{cmsSectionName}</p>
      <p className="text-xs">Last viewed: {getPrettyFullDate(updatedAt)}</p>
    </div>
  )
}
