import CohortCelebrateAchievement from 'domains/CohortDashboard/CohortDashboardProgram/CohortDashboardEndBlock/CohortCelebrateAchievement'
import CompletionCertificate from 'domains/CohortDashboard/CohortDashboardProgram/CohortDashboardEndBlock/CompletionCertificate'

import RfHeader1 from 'components/typography/RfHeader/RfHeader1'
import RfParagraphLarge from 'components/typography/RfParagraph/RfParagraphLarge'

import { CohortDashboardCmsProgramPartFragment } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

interface CohortDashboardEndBlockProps {
  slug: string
  program: CohortDashboardCmsProgramPartFragment
  snapshotReleased: boolean
}

const CohortDashboardEndBlock = ({
  program,
  slug,
  snapshotReleased
}: CohortDashboardEndBlockProps) => {
  const { currentUser } = useCurrentUser()

  return (
    <div className="mt-16 mb-10 flex flex-col px-0">
      <RfHeader1 className="!mb-6">Congratulations, {currentUser?.firstName}!</RfHeader1>

      <RfParagraphLarge>
        You’ve completed {program.name}. We’re thrilled that you joined us.
      </RfParagraphLarge>

      {snapshotReleased && (
        <div className="mt-12">
          <CohortCelebrateAchievement program={program} />
        </div>
      )}
      <CompletionCertificate slug={slug} />
    </div>
  )
}

export default CohortDashboardEndBlock
