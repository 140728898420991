import { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'

import PeopleWithExpertise from 'domains/Group/PeopleWithExpertise'
import PostList from 'domains/Post/PostList'
import MobileFilters from 'domains/Program/MobileFilters'

import Filters, { FilterOption } from 'components/Filters'

import {
  GroupsListGroupIndustryFragment,
  GroupsListGroupTopicFragment,
  PostSource
} from 'gql'

let filterKeys = [
  'page',
  'sort_by',
  'post_type',
  'audience',
  'business_model',
  'contributors'
] // initial state will be set from url variables

interface IPostsWrapper {
  group: string
  groupTitle: string
  currentSeason: string
  groupTopics: GroupsListGroupTopicFragment[]
  groupId: string
  groupCategory: string
  industries: GroupsListGroupIndustryFragment[]
}

/* Define the endpoint and filters for the posts page. This component maintains the filter state (shares it between the list and the filters) */
const PostsWrapper = (props: IPostsWrapper) => {
  let filterOptions: FilterOption[] = [
    {
      key: 'sort_by',
      type: 'sortby',
      options: [
        ['recent', 'Recent'],
        ['popularity', 'Popular']
      ],
      defaultOptionIndex: 1
    },
    {
      key: 'post_type',
      type: 'checkbox',
      title: 'Post Type',
      options: [
        ['following', 'Following'],
        ['myPosts', 'My posts'],
        ['unansweredPosts', 'Unanswered posts']
      ]
    },
    {
      key: 'audience',
      type: 'checkbox',
      title: 'Company Audience',
      explainer: 'Browse posts that pertain to specific audiences and business types',
      options: [
        ['B2B', 'B2B'],
        ['B2C', 'B2C'],
        ['B2B2C', 'B2B2C']
      ]
    },
    {
      key: 'business_model',
      type: 'checkbox',
      title: 'Business Model',
      explainer: 'Browse posts that pertain to specific business model',
      options: [
        ['transactional', 'Transactional'],
        ['marketplace', 'Marketplace'],
        ['subscription', 'Subscription'],
        ['ads', 'Ads']
      ]
    },
    {
      key: 'contributors',
      type: 'people',
      title: 'Contributors',
      explainer: 'Find posts and responses by peers, experts, and the Reforge team',
      groupSlug: props.group
    }
  ]
  if (['Functions + Roles'].includes(props.groupCategory)) {
    filterOptions = filterOptions.filter(
      (option) => option.key !== 'audience' && option.key !== 'business_model'
    )
    filterKeys = filterKeys.filter(
      (key) => key !== 'audience' && key !== 'business_model'
    )

    if (props.industries.length > 0) {
      filterOptions.push({
        key: 'industries',
        type: 'tag',
        title: 'Industries',
        explainer: 'Discover conversations about specific verticals and business domains',
        options: props.industries.map((industry) => [
          industry.slug || '',
          industry.name || ''
        ])
      })
      filterKeys.push('industries')
    }
  }
  if (
    ['Topic', 'Business Model', 'Company Audience'].includes(props.groupCategory) &&
    props.industries.length > 0
  ) {
    // Add the industry filter
    filterOptions.push({
      key: 'industries',
      type: 'tag',
      title: 'Industries',
      explainer: 'Discover conversations about specific verticals and business domains',
      options: props.industries.map((industry) => [
        industry.slug || '',
        industry.name || ''
      ])
    })
    filterKeys.push('industries')
  }
  if (['Business Model', 'Company Audience'].includes(props.groupCategory)) {
    filterOptions.push({
      key: 'intersecting_groups',
      type: 'twostep',
      title: 'Topics & Concepts',
      explainer: 'Browse relevant topics and concepts within [GROUP_NAME]',
      groupTitle: props.groupTitle,
      groupTopics: [
        {
          id: 'retention-and-engagement',
          title: 'Retention & Engagement',
          kind: null,
          topicId: null
        },
        {
          id: 'growth-foundations',
          title: 'Growth Foundations',
          kind: null,
          topicId: null
        },
        {
          id: 'monetization-pricing',
          title: 'Monetization & Pricing',
          kind: null,
          topicId: null
        },
        {
          id: 'growth-models',
          title: 'Growth Loops & Models',
          kind: null,
          topicId: null
        },
        {
          id: 'experimentation-testing',
          title: 'Experimentation & Testing',
          kind: null,
          topicId: null
        },
        { id: 'product-strategy', title: 'Product Strategy', kind: null, topicId: null },
        {
          id: 'marketing-strategy',
          title: 'Marketing Strategy',
          kind: null,
          topicId: null
        }
      ]
    })
    filterKeys.push('intersecting_groups')

    // remove the audience filter if we're in the Company Audience group
    if (props.groupCategory === 'Company Audience') {
      filterOptions = filterOptions.filter((option) => option.key !== 'audience')
      filterKeys = filterKeys.filter((key) => key !== 'audience')
    }
    // remove the business model filter if we're in the Business Model  group
    if (props.groupCategory === 'Business Model') {
      filterOptions = filterOptions.filter((option) => option.key !== 'business_model')
      filterKeys = filterKeys.filter((key) => key !== 'business_model')
    }
  } else {
    if (props.groupTopics.length > 0) {
      filterOptions.push({
        key: 'topics',
        type: 'twostep',
        title: 'Topics & Concepts',
        explainer: 'Browse relevant topics and concepts within [GROUP_NAME]',
        groupTitle: props.groupTitle,
        groupTopics: props.groupTopics
      })
      filterKeys.push('topics')
    }
  }

  const [filters, setFilters] = useState(
    {}
  ) /* read by the posts list component set by the filters component. Defaults to url params */
  const [busy, setBusy] = useState(false)

  const history = useHistory()

  return (
    <Fragment>
      <div className="uk-margin-top z-0 w-full pl-0 lg:w-3/4">
        <div className="lg:pr-8">
          <MobileFilters />
          <PostList
            group={props.group}
            filters={filters}
            setFilters={setFilters}
            setBusy={setBusy}
            postSource={PostSource.GROUP}
          />
        </div>
      </div>
      <div className="uk-margin-top hidden pl-0 lg:flex lg:w-1/4">
        <div className="hidden lg:block lg:w-full">
          <Filters
            filterKeys={filterKeys}
            filters={filters}
            setFilters={setFilters}
            filterOptions={filterOptions}
            history={history}
            busy={busy}
          />
          <PeopleWithExpertise group={props.group} />
        </div>
      </div>
    </Fragment>
  )
}

export default PostsWrapper
