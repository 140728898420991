import { useEffect, useReducer } from 'react'

// All of these are exclusive to one another
const STATES = {
  initial: 'initial',

  // When desktop viewport (>= lg)
  sideDrawerGteLgOpened: 'sideDrawerGteLgOpened',

  // When mobile viewport (< lg)
  sideDrawerLtLgOpening: 'sideDrawerLtLgOpening',
  sideDrawerLtLgOpened: 'sideDrawerLtLgOpened',
  sideDrawerLtLgClosing: 'sideDrawerLtLgClosing',
  sideDrawerLtLgClosed: 'sideDrawerLtLgClosed',

  // When viewport width changes
  closingAfterLtLg: 'closingAfterLtLg',
  openingAfterGteLg: 'openingAfterGteLg',
  transitioningFromOpenLtToOpenGteLg: 'transitioningFromOpenLtToOpenGteLg',
  transitioningFromOpenGteToOpenLtLg: 'transitioningFromOpenGteToOpenLtLg'
} as const

const EVENT_TYPES = {
  'viewport went below lg': 'viewport went below lg',
  'viewport hit lg': 'viewport hit lg',
  'enter transition started': 'enter transition started',
  'enter transition ended': 'enter transition ended',
  'leave transition started': 'leave transition started',
  'leave transition ended': 'leave transition ended',
  'comment button clicked': 'comment button clicked',
  'note button clicked': 'note button clicked',
  'close side drawer button clicked': 'close side drawer button clicked',
  '?drawer=closed detected': '?drawer=closed detected',
  'active annotation detected': 'active annotation detected'
} as const

const ACTIONS = {
  closeSideDrawer: 'closeSideDrawer',
  openSideDrawer: 'openSideDrawer',
  clearActiveAnnotation: 'clearActiveAnnotation'
} as const

export type State = keyof typeof STATES
export type Event =
  | {
      type: (typeof EVENT_TYPES)['viewport went below lg']
      payload: {
        hasActiveAnnotation: boolean
      }
    }
  | {
      type: (typeof EVENT_TYPES)['viewport hit lg']
    }
  | {
      type: (typeof EVENT_TYPES)['enter transition started']
    }
  | {
      type: (typeof EVENT_TYPES)['enter transition ended']
    }
  | {
      type: (typeof EVENT_TYPES)['leave transition started']
    }
  | {
      type: (typeof EVENT_TYPES)['leave transition ended']
    }
  | {
      type: (typeof EVENT_TYPES)['comment button clicked']
    }
  | {
      type: (typeof EVENT_TYPES)['note button clicked']
    }
  | {
      type: (typeof EVENT_TYPES)['close side drawer button clicked']
    }
  | {
      type: (typeof EVENT_TYPES)['?drawer=closed detected']
    }
  | {
      type: (typeof EVENT_TYPES)['active annotation detected']
    }

type Action = keyof typeof ACTIONS
type Actions = Array<Action>
type StateAndActions = [State, Actions]

function createNewStateAndActions(state: State, actions: Actions = []): StateAndActions {
  return [state, actions]
}

function sideDrawerStateMachineReducer(
  stateAndActions: StateAndActions,
  event: Event
): StateAndActions {
  function getNextState(prevStateAndActions: StateAndActions, event: Event) {
    const [prevState] = prevStateAndActions

    switch (event.type) {
      case EVENT_TYPES['viewport went below lg']:
        if (prevState === STATES.initial) {
          return createNewStateAndActions(STATES.sideDrawerLtLgClosed)
        }

        if (prevState === STATES.sideDrawerGteLgOpened) {
          if (event.payload.hasActiveAnnotation) {
            return createNewStateAndActions(STATES.transitioningFromOpenGteToOpenLtLg, [
              ACTIONS.closeSideDrawer
            ])
          } else {
            return createNewStateAndActions(STATES.closingAfterLtLg, [
              ACTIONS.closeSideDrawer
            ])
          }
        }

        return createNewStateAndActions(prevState)

      case EVENT_TYPES['viewport hit lg']:
        if (prevState === STATES.initial) {
          return createNewStateAndActions(STATES.sideDrawerGteLgOpened, [
            ACTIONS.openSideDrawer
          ])
        }

        if (prevState === STATES.sideDrawerLtLgOpened) {
          return createNewStateAndActions(STATES.transitioningFromOpenLtToOpenGteLg, [
            ACTIONS.closeSideDrawer
          ])
        }

        if (prevState === STATES.sideDrawerLtLgClosed) {
          return createNewStateAndActions(STATES.sideDrawerGteLgOpened, [
            ACTIONS.openSideDrawer
          ])
        }

        return createNewStateAndActions(prevState)

      case EVENT_TYPES['note button clicked']:
      case EVENT_TYPES['comment button clicked']:
        if (prevState === STATES.sideDrawerLtLgClosed) {
          return createNewStateAndActions(STATES.sideDrawerLtLgOpening, [
            ACTIONS.openSideDrawer
          ])
        }

        return createNewStateAndActions(prevState)

      case EVENT_TYPES['close side drawer button clicked']:
        if (prevState === STATES.sideDrawerLtLgOpened) {
          return createNewStateAndActions(STATES.sideDrawerLtLgClosing, [
            ACTIONS.closeSideDrawer,
            ACTIONS.clearActiveAnnotation
          ])
        }

        return createNewStateAndActions(prevState)

      case EVENT_TYPES['?drawer=closed detected']:
        if (prevState === STATES.initial) {
          return createNewStateAndActions(STATES.sideDrawerLtLgClosed)
        }

        if (prevState === STATES.sideDrawerLtLgOpened) {
          return createNewStateAndActions(STATES.sideDrawerLtLgClosing, [
            ACTIONS.closeSideDrawer
          ])
        }

        return createNewStateAndActions(prevState)

      case EVENT_TYPES['active annotation detected']:
        if (prevState === STATES.sideDrawerLtLgClosed) {
          return createNewStateAndActions(STATES.sideDrawerLtLgOpening, [
            ACTIONS.openSideDrawer
          ])
        }

        return createNewStateAndActions(prevState)

      case EVENT_TYPES['enter transition started']:
        return createNewStateAndActions(prevState)

      case EVENT_TYPES['enter transition ended']:
        if (prevState === STATES.openingAfterGteLg) {
          return createNewStateAndActions(STATES.sideDrawerGteLgOpened)
        }

        if (prevState === STATES.sideDrawerLtLgOpening) {
          return createNewStateAndActions(STATES.sideDrawerLtLgOpened)
        }

        return createNewStateAndActions(prevState)

      case EVENT_TYPES['leave transition started']:
        return createNewStateAndActions(prevState)

      case EVENT_TYPES['leave transition ended']:
        if (prevState === STATES.closingAfterLtLg) {
          return createNewStateAndActions(STATES.sideDrawerLtLgClosed)
        }

        if (prevState === STATES.sideDrawerLtLgClosing) {
          return createNewStateAndActions(STATES.sideDrawerLtLgClosed)
        }

        if (prevState === STATES.transitioningFromOpenLtToOpenGteLg) {
          return createNewStateAndActions(STATES.sideDrawerGteLgOpened, [
            ACTIONS.openSideDrawer
          ])
        }

        if (prevState === STATES.transitioningFromOpenGteToOpenLtLg) {
          return createNewStateAndActions(STATES.sideDrawerLtLgOpened, [
            ACTIONS.openSideDrawer
          ])
        }

        return createNewStateAndActions(prevState)

      default:
        return createNewStateAndActions(prevState)
    }
  }

  const nextStateAndActions = getNextState(stateAndActions, event)
  return nextStateAndActions
}

export function useArtifactViewerSideDrawerStateMachine({
  processAction
}: {
  processAction: (action: Action) => void
}) {
  const [sideDrawerState, dispatch] = useReducer(
    sideDrawerStateMachineReducer,
    createNewStateAndActions(STATES.initial)
  )

  function sendSideDrawerStateEvent(event: Event) {
    dispatch(event)
  }

  // Handle actions from sideDrawerStateMachineReducer [,actions] = sideDrawerState
  useEffect(() => {
    const [, actions] = sideDrawerState
    if (!actions || !actions.length) return

    actions.forEach(processAction)
  }, [processAction, sideDrawerState])

  return [sideDrawerState, sendSideDrawerStateEvent] as const
}
