import { CarouselProvider } from 'pure-react-carousel'

import { Concept } from 'pages/CourseSeoLandingPage/CourseSeoLandingPage'

import { CarouselWithStore } from 'domains/Seo/SeoPageCarousel/SeoPageCarousel'
import { SEOTrackingRelatedIdentifiers } from 'domains/Seo/helpers'
import useSeoPageSlidesCount from 'domains/Seo/useSeoPageSlidesCount'

import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'

import { CourseForTopicAndFunction } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import CoursesSeoCarouselCard from '../CoursesSeoCarouselCard'

export interface CoursesSeoCarouselSectionProps {
  concept: Concept
  courses: CourseForTopicAndFunction[]
  totalCourseCount?: number
  tracking: SEOTrackingRelatedIdentifiers
}

export const CoursesSeoCarouselSection = ({
  concept,
  courses,
  totalCourseCount,
  tracking
}: CoursesSeoCarouselSectionProps) => {
  const isMobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)
  const slideWidth = isMobileView ? 288 : 400
  const visibleSlides = useSeoPageSlidesCount()

  return (
    <CarouselProvider
      naturalSlideWidth={slideWidth}
      naturalSlideHeight={400}
      totalSlides={courses.length + 1} // +1 for the empty element
      visibleSlides={visibleSlides}
      isIntrinsicHeight={true}
      className="my-[104px] w-full"
    >
      <CarouselWithStore
        isSmallGap={isMobileView}
        slideWidth={slideWidth}
        visibleSlides={visibleSlides}
        title={`Other ${concept.title.toLowerCase()} courses`}
        subtitle={`Explore the full catalog of ${concept.title.toLowerCase()} courses created by world-class experts`}
        bottomText={`View all ${totalCourseCount} courses to help you level up`}
        ctaText="View all courses"
        ctaHref="/courses"
        navigationLocation="SEO other courses carousel"
        tracking={tracking}
      >
        {courses.map((course) => (
          <CoursesSeoCarouselCard course={course} key={course.id} />
        ))}
      </CarouselWithStore>
    </CarouselProvider>
  )
}

export default CoursesSeoCarouselSection
