import { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import PostList from 'domains/Post/PostList'
import MobileFilters from 'domains/Program/MobileFilters'

import Filters, { FiltersProp } from 'components/Filters'
import { ListFilterSet } from 'components/Filters/ListFilter'
import { SortByFilterSet } from 'components/Filters/SortBy'

import { PostSource } from 'gql'

import useScrollToTopOnLocationChange from 'hooks/useScrollToTopOnLocationChange'

const filterKeys = ['page', 'sort_by', 'filter']
const filterOptions = [
  {
    key: 'sort_by',
    type: 'sortby',
    options: [
      ['recent', 'Recent'],
      ['popularity', 'Popular']
    ]
  } as SortByFilterSet,
  {
    key: 'filter',
    type: 'list',
    options: [
      [undefined, 'All Contributions'],
      ['myPosts', 'My Posts'],
      ['myResponses', 'My Responses']
    ]
  } as ListFilterSet
]

const PostsContributions = () => {
  const [filters, setFilters] = useState<FiltersProp>(
    {}
  ) /* read by the posts list component set by the filters component. Defaults to url params */
  const [busy, setBusy] = useState(false)

  const history = useHistory()

  useScrollToTopOnLocationChange()

  /* We need some manual handling here to update the My Posts / My Respones / Drafts links in the LHS when the filter
    changes. We have to do this because we dont have a global state (the left and right sidebar don't have a common
    parent, one is rednered from root.jsx, the other from withing a component)
  */

  const setSubnavActiveClass = () => {
    Array.from(document.querySelectorAll('.subnav__link')).forEach(function (node) {
      node.classList.remove('!text-white')
    })

    let activeLinkId = ''
    if (filters.filter === 'myPosts') {
      activeLinkId = 'my-posts'
    } else if (filters.filter === 'myResponses') {
      activeLinkId = 'my-responses'
    } else if (filters.filter === 'drafts') {
      activeLinkId = 'my-drafts'
    }

    if (activeLinkId !== '') {
      const linkElement = document.getElementById(activeLinkId)
      if (linkElement) {
        linkElement.classList.add('subnav__link--active')
      }
    }
  }

  useEffect(setSubnavActiveClass, [filters])

  return (
    <Fragment>
      <div className="py-0 xs:py-4 md:py-8">
        <div className="ml-0" uk-grid="">
          <div className="uk-margin-top w-full pl-0 lg:w-3/4">
            <div className="lg:pr-8">
              <MobileFilters />
              <PostList
                filters={filters}
                setFilters={setFilters}
                source="my-contributions"
                setBusy={setBusy}
                postSource={PostSource.CONTRIBUTOR}
              />
            </div>
          </div>
          <div className="uk-margin-top hidden pl-0 lg:flex lg:w-1/4">
            <div className="hidden lg:flex lg:w-full">
              <Filters
                filterKeys={filterKeys}
                filters={filters}
                setFilters={setFilters}
                filterOptions={filterOptions}
                history={history}
                busy={busy}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default PostsContributions
