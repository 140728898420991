import { CohortPostReactionPartsFragment, PostReactionFragment, ReactionKind } from 'gql'

export enum ReactableType {
  post = 'Post',
  reply = 'Reply',
  comment = 'Comment'
}

export interface LegacyReaction {
  id: number
  kind: ReactionKind
  user: {
    id: number
    fullName: string
  }
}

export type TLegacyOrGraphQLReaction =
  | PostReactionFragment
  | LegacyReaction
  | CohortPostReactionPartsFragment

export const kindToSvg: Record<ReactionKind, string> = {
  thank_you: 'pressed-hands',
  agree: '100',
  insightful: 'lightbulb',
  upvote: 'thin-chevron-up',
  wave: 'wave'
}

export const kindToEnglish: Record<ReactionKind, string> = {
  thank_you: 'Thank you',
  agree: 'I agree',
  insightful: 'Insightful',
  upvote: 'Upvote',
  wave: 'Wave'
}

export const kindToSortOrder: Record<ReactionKind, number> = {
  upvote: 0,
  agree: 1,
  insightful: 2,
  thank_you: 3,
  wave: 4
}

export const sortedReactions = Object.values(ReactionKind).sort(
  (a, b) => kindToSortOrder[a] - kindToSortOrder[b]
)

type ReactionCollection = {
  [key in ReactionKind]: TLegacyOrGraphQLReaction[]
}

export const mapReactionKinds = (reactions: TLegacyOrGraphQLReaction[]) => {
  return reactions.reduce(
    (collection: ReactionCollection, reaction) => {
      collection[reaction.kind].push(reaction)
      return collection
    },
    {
      [ReactionKind.AGREE]: [],
      [ReactionKind.INSIGHTFUL]: [],
      [ReactionKind.THANK_YOU]: [],
      [ReactionKind.UPVOTE]: [],
      [ReactionKind.WAVE]: []
    }
  )
}

interface hasReactionOfKindProps {
  currentUserId: string | number
  reactions: TLegacyOrGraphQLReaction[]
  kind: ReactionKind
}

export const hasReactionOfKind = ({
  currentUserId,
  reactions,
  kind
}: hasReactionOfKindProps) => {
  return reactions.some(
    (reaction: TLegacyOrGraphQLReaction) =>
      // eslint-disable-next-line eqeqeq
      reaction.kind === kind && reaction.user.id == currentUserId
  )
}

interface isReactionPresentProps {
  reactions: TLegacyOrGraphQLReaction[]
  id: string | number
}

export const isReactionPresent = ({ reactions, id }: isReactionPresentProps) => {
  // eslint-disable-next-line eqeqeq
  return reactions.some((reaction) => reaction.id == id)
}
