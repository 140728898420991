import bg from 'pricing-page-hero-gradient.svg'

import Image from 'domains/Sanity/Image'
import ButtonMarketingCTA from 'domains/Sanity/marketingSite/ButtonMarketingCTA'
import { MarketingH1, MarketingH3 } from 'domains/Sanity/marketingSite/textHelpers'

import { PricingPageContent } from 'gql'

const PricingPageHero = ({ content }: { content: PricingPageContent }) => {
  return (
    <div className="relative pt-14 lg:pt-[76px]">
      <Image
        src={bg}
        alt=""
        className="absolute top-0 left-0 -z-50 w-full h-full object-cover"
      />
      <div className="text-center pt-[70px] pb-[50px] md:pb-[75px] lg:pb-[100px] lg:pt-[150px] px-4 md:px-[4vw]">
        <MarketingH1 className="mb-3">{content.title || ''}</MarketingH1>
        <MarketingH3
          className="mb-[50px] md:mb-[75px] lg:mb-[100px] w-full sm:max-w-[540px] md:max-w-[650px] lg:max-w-[870px] mx-auto"
          as="h2"
        >
          {content.subtitle || ''}
        </MarketingH3>

        <div className="flex justify-center">
          <ButtonMarketingCTA
            href={'/login/?reforgeLogin=true'}
            location="marketing_pricing_page__hero_section"
          >
            Start a 14-day free trial
          </ButtonMarketingCTA>
        </div>
      </div>
    </div>
  )
}

export default PricingPageHero
