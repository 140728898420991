import { Fragment, MouseEvent } from 'react'
import { Link } from 'react-router-dom'

import { formatPositionAtCompany } from 'domains/Profile/utils'

import BadgeableAvatar from 'components/BadgeableAvatar'
import RfParagraphLarge from 'components/typography/RfParagraph/RfParagraphLarge'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { SearchDocumentResultFragment, SearchDocumentUserFragment } from 'gql'

import { highlightAndSanitize } from '../utils'

export interface UserSearchItemProps {
  document: Omit<SearchDocumentResultFragment, 'searchable'> & {
    searchable: SearchDocumentUserFragment
  }
  searchQuery: string
  userCan: {
    viewMemberProfile?: boolean | null
  }
  onResultClick: (e: MouseEvent<HTMLAnchorElement>) => void
}

function formatMetaInfo({
  role,
  companyName,
  location,
  searchQuery
}: {
  role: string | null
  companyName: string | null
  location: string | null
  searchQuery: string
}) {
  const roleInfo = (role || companyName) && (
    <span
      className="m-0"
      dangerouslySetInnerHTML={{
        __html: highlightAndSanitize(
          searchQuery,
          formatPositionAtCompany(role, companyName)
        )
      }}
    />
  )
  const locationInfo = location && (
    <span
      className="m-0 text-rb-black"
      dangerouslySetInnerHTML={{ __html: highlightAndSanitize(searchQuery, location) }}
    />
  )

  return [roleInfo, locationInfo].filter(Boolean).map((infoFragment, index) => (
    <Fragment key={index}>
      {!!index && <span className="px-1 font-normal text-rb-gray-300">&#183;</span>}
      {infoFragment}
    </Fragment>
  ))
}

const UserSearchItem = ({
  document,
  userCan,
  searchQuery,
  onResultClick
}: UserSearchItemProps) => {
  const { title, searchable } = document
  const { profile } = searchable

  if (!userCan.viewMemberProfile) {
    return null
  }

  return (
    <>
      <Link to={`directory/${profile.slug}`} onClick={onResultClick}>
        <article className="mb-0 flex items-center rounded border border-rb-gray-100 p-4 hover:shadow-card-hover xs:p-6">
          <BadgeableAvatar
            avatarUrl={profile?.avatarUrl || ''}
            user={profile}
            width="60"
            height="60"
            className="mr-4"
          />
          <div>
            <h4 className="m-0">
              <RfParagraphLarge className="font-medium">{title}</RfParagraphLarge>
            </h4>
            <RfParagraphSmall>
              {formatMetaInfo({
                role: profile.role || '',
                companyName: profile.companyName || '',
                location: profile.location || '',
                searchQuery
              })}
            </RfParagraphSmall>
          </div>
        </article>
      </Link>
    </>
  )
}

export default UserSearchItem
