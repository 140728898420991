import { every, uniq } from 'lodash'

import { CohortEvent } from 'domains/CohortViewer/utils'

import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import { CohortEventSameInfoPartsFragment } from 'gql'

import { NO_ATTENDANCE_STATUS } from 'utils/cohortUtils'
import { formatInTimezone, getTimezoneAbbreviation } from 'utils/date'

export const formattedEventTimeRange = (
  eventInfo: CohortEvent | CohortEventSameInfoPartsFragment,
  timezone: string
) => {
  const formattedEventTime = (utcDatetime: string) =>
    formatInTimezone(utcDatetime, timezone, 'p')

  return `${formattedEventTime(eventInfo.startsAtUtc)} - ${formattedEventTime(
    eventInfo.endsAtUtc
  )} (${getTimezoneAbbreviation(timezone, eventInfo.startsAtUtc)})`
}

export const formattedEventDate = (
  eventInfo: CohortEvent | CohortEventSameInfoPartsFragment,
  timezone: string
) => formatInTimezone(eventInfo.startsAtUtc, timezone, 'EEE, MMMM d')

interface WeeklyBlockEventTimesProps {
  event: CohortEvent
  timezone: string
}

export const WeeklyBlockEventTimes = ({
  event,
  timezone
}: WeeklyBlockEventTimesProps) => {
  const notAttendingAnyTimesForEvent = every(
    event.sameEventInfo,
    (eventInfo) =>
      !eventInfo.attendingStatus || eventInfo.attendingStatus === NO_ATTENDANCE_STATUS
  )

  const shouldDisplayEventTime = (eventInfo: CohortEventSameInfoPartsFragment) => {
    if (notAttendingAnyTimesForEvent) {
      return true
    }

    return eventInfo.attendingStatus && eventInfo.attendingStatus !== NO_ATTENDANCE_STATUS
  }

  const formattedEventDates = () => {
    const eventDates = event.sameEventInfo.filter((eventInfo) =>
      shouldDisplayEventTime(eventInfo)
    )

    const formattedEventDates = eventDates.map((date) =>
      formattedEventDate(date, timezone)
    )

    return uniq(formattedEventDates)
  }

  const firstEventDate = formattedEventDates()[0]
  const secondEventDate = formattedEventDates()[1]

  const formattedEventTimes = () => {
    const eventInfos = event.sameEventInfo.filter((eventInfo) =>
      shouldDisplayEventTime(eventInfo)
    )

    return eventInfos.map((eventInfo) => formattedEventTimeRange(eventInfo, timezone))
  }

  const firstEventTime = formattedEventTimes()[0]
  const secondEventTime = formattedEventTimes()[1]

  return (
    <div data-testid="event-times" className="mb-5">
      <RfParagraphMedium className="mb-1">{firstEventDate}</RfParagraphMedium>
      <RfParagraphSmall color={ColorOptions.gray}>{firstEventTime}</RfParagraphSmall>
      {!secondEventDate && (
        <RfParagraphSmall color={ColorOptions.gray}>{secondEventTime}</RfParagraphSmall>
      )}
      {!!secondEventDate && (
        <div>
          <RfParagraphMedium className="mt-1 mb-1">{secondEventDate}</RfParagraphMedium>
          <RfParagraphSmall color={ColorOptions.gray}>{secondEventTime}</RfParagraphSmall>
        </div>
      )}
    </div>
  )
}

export default WeeklyBlockEventTimes
