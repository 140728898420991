import { useLocation } from 'react-router-dom'

import { SetIsModalOpen } from 'components/Modal'
import Tabs from 'components/Tabs'

import { EventQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { cn } from 'utils/tailwind'
import { trackNavigationClicked } from 'utils/tracking/analytics'
import {
  COHORT_DASHBOARD_EVENT_DETAILS_TAB_URL,
  COHORT_DASHBOARD_EVENT_SUMMARY_TAB_URL,
  EVENT_DETAILS_TAB_URL,
  EVENT_SUMMARY_TAB_URL,
  makeUrl
} from 'utils/url'

type IEventShowTabs = {
  event: EventQuery['event']
  isAttending: boolean
  setIsAttendeeModalOpen: SetIsModalOpen
  cohortSlug?: string
  isCohortDashboard: boolean
}

const EventShowTabs = ({ event, cohortSlug, isCohortDashboard }: IEventShowTabs) => {
  const pageUrlTokensMap = { eventID: event.id, eventSlug: event.slug, cohortSlug }
  const detailsTabUrl = isCohortDashboard
    ? COHORT_DASHBOARD_EVENT_DETAILS_TAB_URL
    : EVENT_DETAILS_TAB_URL
  const recordingTabUrl = isCohortDashboard
    ? COHORT_DASHBOARD_EVENT_SUMMARY_TAB_URL
    : EVENT_SUMMARY_TAB_URL

  const location = useLocation()

  const { currentUser } = useCurrentUser()

  const detailsHref = makeUrl(detailsTabUrl, pageUrlTokensMap)
  const recordingHref = makeUrl(recordingTabUrl, pageUrlTokensMap)

  const tabs = [
    {
      label: 'Details',
      href: detailsHref,
      isActive: location.pathname === detailsHref,
      onClick: () => {
        trackNavigationClicked({
          location: 'event_details_page',
          text: 'details',
          type: 'button',
          logged_in: !!currentUser,
          related_identifiers: {
            event_id: event.id,
            event_title: event.name,
            event_kind: event.kind,
            is_completed_event: event.endsAtUtc < new Date().toISOString()
          }
        })
      }
    },
    ...(event.willBeRecorded
      ? [
          {
            label: 'Recording',
            href: recordingHref,
            isActive: location.pathname === recordingHref,
            onClick: () => {
              trackNavigationClicked({
                location: 'event_details_page',
                text: 'recording',
                type: 'button',
                logged_in: !!currentUser,
                related_identifiers: {
                  event_id: event.id,
                  event_title: event.name,
                  event_kind: event.kind,
                  is_completed_event: event.endsAtUtc < new Date().toISOString()
                }
              })
            }
          }
        ]
      : [])
  ]

  return (
    <>
      <div
        className={cn(
          'mb-8 flex flex-row items-center justify-between border-t-2 border-gray-100 md:border-none'
        )}
      >
        <div className={cn('mt-6 p-0 md:mt-0')}>
          <Tabs dataTest="page-header-tabs" tabs={tabs} />
        </div>
      </div>
    </>
  )
}

export default EventShowTabs
