import Button from 'components/Button'

import useOpenPurchaseFlow from 'hooks/useOpenPurchaseFlow'

interface UpgradeUserCardProps {
  hasApplied: boolean
  accessPolicyKind: string
  message: string
}

const UpgradeUserCard = ({
  message,
  hasApplied,
  accessPolicyKind
}: UpgradeUserCardProps) => {
  const { openPurchaseFlow } = useOpenPurchaseFlow()
  if (accessPolicyKind === 'account_created' && !hasApplied) {
    return (
      <div className="m-2.5 min-w-[250px] py-1 px-2.5 font-sans text-sm font-normal leading-5 text-white">
        {message}
        <div className="flex justify-center pb-3.5">
          <Button
            href="/apply"
            size="x-small"
            shape="rounded-full"
            className="mt-4 border-white bg-white text-rb-gray-500 hover:border-rb-green-100 hover:bg-rb-green-100"
          >
            Join Now
          </Button>
        </div>
      </div>
    )
  } else if (
    ['expired', 'accepted', 'plan_manager'].includes(accessPolicyKind) ||
    hasApplied
  ) {
    return (
      <div className="m-2.5 min-w-[250px] py-1 px-2.5 font-sans text-sm font-normal leading-5 text-white">
        {message}
        <div className="flex justify-center pb-3.5">
          <Button
            onClick={openPurchaseFlow}
            size="x-small"
            shape="rounded-full"
            className="mt-4 border-white bg-white text-rb-gray-500 hover:border-rb-green-100 hover:bg-rb-green-100"
          >
            {accessPolicyKind === 'expired' ? 'Reactivate' : 'Join Now'}
          </Button>
        </div>
      </div>
    )
  }

  return null
}

export default UpgradeUserCard
