import {
  renderBlockquoteMark,
  renderCode,
  renderEm,
  renderLink,
  renderStrong,
  renderUnderline
} from '../../shared/componentTypes/Marks'

const marks = {
  em: renderEm,
  underline: renderUnderline,
  strong: renderStrong,
  code: renderCode,
  link: renderLink,
  blockquote: renderBlockquoteMark
}

export default marks
