export const SkeletonMiniCard = () => {
  return (
    <div className="chromatic-ignore flex w-full animate-pulse gap-x-4 bg-rb-white items-center">
      <div className="w-[80px] h-[80px] shrink-0 rounded-xl bg-rb-gray-100" />
      <div className="flex w-full flex-col gap-y-2">
        <div className="mb-2 h-4 w-full rounded-md bg-rb-gray-100" />
        <div className="mb-2 h-2 w-full rounded-md bg-rb-gray-100" />
      </div>
    </div>
  )
}
