import { ApolloError } from '@apollo/client'
import { useState } from 'react'

import { StandaloneBillingInfoForm } from 'domains/User/BillingInfoForm'

import { ErrorMessage, Loading } from 'components'
import Button from 'components/Button'
import { SelectedPlan, SelectedPlanProps } from 'components/CheckoutModal/SelectedPlan'
import { CohortPassSection } from 'components/CheckoutModal/components/CohortPassSection'
import { SectionHeading } from 'components/CheckoutModal/components/Headings'
import { SectionBorder } from 'components/CheckoutModal/components/SectionBorder'
import TermsOfServiceLink from 'components/CheckoutModal/components/TermsOfServiceLink'
import { getDefaultPassCount } from 'components/CheckoutModal/utils'
import { ModalContent } from 'components/Modal'
import { StripeElementsWithoutIntent } from 'components/StripeElements'
import RfParagraphLarge from 'components/typography/RfParagraph/RfParagraphLarge'

import { CONTACT_SUPPORT_ERROR_MESSAGE } from 'constants/errorMessages'

import {
  CohortPassPriceTier,
  PlanName,
  useChoosePlanMutation,
  useCohortPassPriceTiersQuery
} from 'gql'

import { trackCtaClicked } from 'utils/tracking/analytics'

const CONFIRM_PLAN_MODAL_TRACKING_LOCATION = 'plan_choice_modal'

export interface ConfirmPlanModalContainerProps {
  numCohortPasses?: number
  selectedPlan: SelectedPlanProps
  renewalDate: string
  onConfirmPlanSuccess: (amountDue: number) => void
  handleClose: () => void
}

export interface ConfirmPlanModalProps extends ConfirmPlanModalContainerProps {
  cohortPassPriceTiers: CohortPassPriceTier[]
  error?: ApolloError
}

const ConfirmPlanModalContainer = ({
  numCohortPasses,
  selectedPlan,
  renewalDate,
  onConfirmPlanSuccess,
  handleClose
}: ConfirmPlanModalContainerProps) => {
  const { data, error, loading } = useCohortPassPriceTiersQuery()

  if (loading) return <Loading />
  if (!data) return <ErrorMessage error={CONTACT_SUPPORT_ERROR_MESSAGE} />

  return (
    <ConfirmPlanModal
      numCohortPasses={numCohortPasses}
      error={error}
      cohortPassPriceTiers={data.cohortPassPriceTiers}
      selectedPlan={selectedPlan}
      renewalDate={renewalDate}
      onConfirmPlanSuccess={onConfirmPlanSuccess}
      handleClose={handleClose}
    />
  )
}

const ConfirmPlanModal = ({
  numCohortPasses,
  error,
  cohortPassPriceTiers,
  selectedPlan,
  renewalDate,
  onConfirmPlanSuccess,
  handleClose
}: ConfirmPlanModalProps) => {
  const [cohortPassCount, setCohortPassCount] = useState(0)
  const [choosePlanMutation] = useChoosePlanMutation()
  const [isLoading, setIsLoading] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(Boolean(error))
  const [isAddressEditing, setIsAddressEditing] = useState(false)
  const [isBillingInfoFormComplete, setIsBillingInfoFormComplete] =
    useState<boolean>(false)

  const defaultPassCount = getDefaultPassCount(selectedPlan.name)
  const handleConfirm = async () => {
    setShowErrorMessage(false)
    trackCtaClicked({
      cta_location: CONFIRM_PLAN_MODAL_TRACKING_LOCATION,
      cta_type: 'button',
      text: 'confirm update'
    })
    setIsLoading(true)
    const response = await choosePlanMutation({
      variables: {
        input: {
          newPlan: selectedPlan.name,
          numCohortPasses: numCohortPasses || cohortPassCount,
          updateType: 'scheduled_upgrade'
        }
      }
    })
    setIsLoading(false)

    if (response.data?.choosePlan?.errors?.length) {
      setShowErrorMessage(true)
    } else {
      onConfirmPlanSuccess(response.data?.choosePlan?.upcomingInvoice?.subtotal || 0)
    }
  }

  const isConfirmButtonDisabled = !isBillingInfoFormComplete || isAddressEditing
  const isIndividualPlan = selectedPlan.name === PlanName.INDIVIDUAL
  const isOptingInToDiscount = isIndividualPlan && numCohortPasses === 2
  const cohortPassSubtitleText = isOptingInToDiscount
    ? '2 discounted Live Course Passes are available. Original price is $2,790.'
    : 'Select the number of Live Course Passes you would like to add.'

  return (
    <ModalContent>
      <RfParagraphLarge className="font-medium">
        Confirm your plan details
      </RfParagraphLarge>

      {isLoading ? (
        <Loading />
      ) : (
        <div className="mb-4 text-sm">
          This is a scheduled update that will take effect on{' '}
          <span className="font-semibold">{renewalDate}</span>.
        </div>
      )}
      <div className="mb-4">
        <SelectedPlan
          name={selectedPlan.name}
          pricePerYear={selectedPlan.pricePerYear}
          maxSeatCount={selectedPlan.maxSeatCount}
        />
      </div>
      {numCohortPasses !== 0 && (
        <div className="mb-4">
          <CohortPassSection
            defaultPassCount={defaultPassCount}
            titleText="Add-ons"
            isOptingInToDiscount={isOptingInToDiscount}
            subtitleText={cohortPassSubtitleText}
            location="confirm-plan-modal"
            cohortPassPriceTiers={cohortPassPriceTiers}
            count={cohortPassCount}
            onCountChange={(count) => {
              setCohortPassCount(count)
            }}
            planName={selectedPlan.name}
          />
        </div>
      )}

      <div className="mb-7">
        <SectionHeading>Billing Address</SectionHeading>
        <SectionBorder>
          <StripeElementsWithoutIntent>
            <StandaloneBillingInfoForm
              trackingLocation={CONFIRM_PLAN_MODAL_TRACKING_LOCATION}
              onSave={(_, isComplete) => setIsBillingInfoFormComplete(isComplete)}
              onLoad={(_, isComplete) => setIsBillingInfoFormComplete(isComplete)}
              onEditStateChange={(editState) => setIsAddressEditing(editState)}
            />
          </StripeElementsWithoutIntent>
        </SectionBorder>
      </div>

      {showErrorMessage && (
        <div className="pb-6" data-testid="confirm-plan-error">
          <ErrorMessage error={CONTACT_SUPPORT_ERROR_MESSAGE} />
        </div>
      )}
      <div className="mb-5 flex justify-between">
        <Button
          onClick={handleClose}
          variant="outline"
          size="small"
          className="mr-4 grow"
        >
          Cancel
        </Button>
        <Button
          className="grow"
          onClick={handleConfirm}
          size="small"
          disabled={isConfirmButtonDisabled || isLoading}
          isLoadingSpinner={isLoading}
        >
          Confirm Update
        </Button>
      </div>
      <TermsOfService canAddPasses={!!numCohortPasses && numCohortPasses > 1} />
    </ModalContent>
  )
}

const TermsOfService = ({ canAddPasses }: { canAddPasses: boolean }) => {
  return canAddPasses ? (
    <div className="mb-10 text-sm text-rb-gray-300">
      By clicking &quot;Confirm Update&quot;, you are agreeing to our{' '}
      {<TermsOfServiceLink />}, that your membership will renew on an annual basis, and
      that your credit card will automatically be charged the applicable membership fee.
      Live Course Passes must be used within one year from the purchase date. After one
      year, your passes will expire.
    </div>
  ) : (
    <div className="mb-10 text-sm text-rb-gray-300">
      By clicking &quot;Confirm,&quot; you are agreeing to our {<TermsOfServiceLink />},
      that your membership will renew on an annual basis, and that your credit card will
      automatically be charged the applicable membership fee. Live Course Passes must be
      used within one year from the purchase date. After one year, your passes will
      expire.
    </div>
  )
}

export default ConfirmPlanModalContainer
