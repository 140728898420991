import { useEffect, useRef, useState } from 'react'
import { RoughNotation } from 'react-rough-notation'
import { Link, useLocation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import useIntersection from 'hooks/useIntersection'

import NoteMarkerImage from 'assets/images/notes-paper-text.svg'

const highlightColor = '#E2EC9A'

const TextNoteIcon = () => {
  return (
    <span className="inline-block h-full w-auto align-middle">
      <img
        src={NoteMarkerImage}
        alt="Icon"
        className="mx-[5px]"
        style={{ width: '14px', height: '14px' }}
      />
    </span>
  )
}

interface AnnotationHighlightProps {
  href: string
  text: string
  delayMs?: number
  durationMs?: number
}

interface HTMLCustomSpanElement extends HTMLDivElement {
  align: string
}

const AnnotationHighlight = ({
  href,
  text,
  delayMs = 400,
  durationMs = 2000
}: AnnotationHighlightProps) => {
  const location = useLocation()
  const ref = useRef<HTMLCustomSpanElement | null>(null)
  const linkRef = useRef<HTMLAnchorElement | null>(null)
  const inViewport = useIntersection(ref, '0px')
  const [everInViewport, setEverInViewport] = useState(false)
  const [activeHighlight, setActiveHighlight] = useState<string | null>(null)

  useEffect(() => {
    const hash = location.pathname.split('#')[1]

    setActiveHighlight(hash)
  }, [location.pathname])

  const isActive = Boolean(activeHighlight) && href === activeHighlight

  useEffect(() => {
    if (!everInViewport && inViewport) {
      setEverInViewport(true)
    }
  }, [everInViewport, inViewport])

  const handleClick = () => {
    linkRef.current?.click()
  }

  return (
    <span data-annotation-hash={href}>
      <RoughNotation
        color={highlightColor}
        type="highlight"
        animationDelay={delayMs}
        animationDuration={durationMs}
        multiline
        show={true}
        strokeWidth={2}
        brackets={['left', 'right']}
        padding={[0, 5]}
      >
        <span
          ref={ref}
          className={twMerge(
            'annotation-highlight cursor-pointer',
            isActive && 'annotation-highlight--active'
          )}
        >
          <span onClick={handleClick} onKeyDown={handleClick} role="button" tabIndex={0}>
            <TextNoteIcon />
            {text}
          </span>
          <Link
            ref={linkRef}
            to={`#${href}`}
            // TODO: figure out how to make this work with react-router-dom
            // scroll={false}
            // shallow
            className="hidden"
          />
        </span>
      </RoughNotation>
    </span>
  )
}

export default AnnotationHighlight
