import RfHeader2 from 'components/typography/RfHeader/RfHeader2'
import RfMetaMini from 'components/typography/RfMeta/RfMetaMini'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import { MAX_WIDTH_TAILWIND_LG, MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import { ProgramSummaryPartsFragment } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import CollaboratorDetails from '../CollaboratorDetails/CollaboratorDetails'
import useExpandCollapse from './useExpandCollapse'

export interface ProgramPageAboutSectionProps {
  program: ProgramSummaryPartsFragment
}

export default function ProgramPageAboutSection({
  program
}: ProgramPageAboutSectionProps) {
  const isLargeScreen = useMediaQuery(`(min-width: ${MAX_WIDTH_TAILWIND_LG})`)
  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
  const [expandedDescription, setExpandedDescription] = useExpandCollapse(isMobile)

  return (
    <>
      <div className={isMobile ? '' : 'px-4 pt-4 xl:flex'} data-testid="about-section">
        <div className={isLargeScreen ? 'w-4/6 pr-16' : 'pb-4'}>
          <RfHeader2>About this Course</RfHeader2>
          {expandedDescription && (
            <>
              <div className="pb-2 text-base font-semibold text-rb-gray-300">
                {Math.ceil(program.estimatedTime / 60)} hours · {program.lessonCount}{' '}
                Lessons
              </div>
              <RfParagraphMedium className="pb-4">
                {program.shortDescription}
              </RfParagraphMedium>
              <RfParagraphMedium className="pb-8">
                {program.longDescription}
              </RfParagraphMedium>
              <RfMetaMini>What You&apos;ll Learn</RfMetaMini>
              <ul className="mt-0 pl-6 pt-2">
                {program.byTheEnd?.split(';').map((item: string, i) => (
                  <li key={`items-${i}`} className="mb-1">
                    <RfParagraphMedium>{item}</RfParagraphMedium>
                  </li>
                ))}
              </ul>
              {isMobile && (
                <RfParagraphMedium className="pb-4">
                  <span
                    role="button"
                    onClick={() => setExpandedDescription(false)}
                    className="text-rb-teal-200"
                  >
                    View less
                  </span>
                </RfParagraphMedium>
              )}
            </>
          )}
          {!expandedDescription && (
            <>
              <RfParagraphMedium className="pb-4">
                <span>{program.shortDescription}..</span>
                <span
                  role="button"
                  onClick={() => setExpandedDescription(true)}
                  className="text-rb-teal-200"
                >
                  View more
                </span>
              </RfParagraphMedium>
            </>
          )}
        </div>
        {(expandedDescription || !isMobile) && (
          <div
            className={isLargeScreen ? 'w-2/6' : ''}
            data-testid="collaborators-section"
          >
            {program.collaborators && (
              <CollaboratorDetails
                hosts={program.collaborators}
                label="Created By Experts"
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}
