import Button from 'components/Button'
import RfHeader2 from 'components/typography/RfHeader/RfHeader2'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

const NoAccess = () => {
  return (
    <div className="mt-9 rounded-2xl bg-rb-gray-50 p-10">
      <div className="flex flex-col items-start">
        <RfHeader2>Ah! You no longer have access to view this collection.</RfHeader2>
        <RfParagraphMedium className="mb-8 font-normal" color={ColorOptions.gray}>
          Revisit your library to see other shared collections, or share your own with
          your team.
        </RfParagraphMedium>
        <Button href="/saved" className="px-4 py-2.5">
          Saved
        </Button>
      </div>
    </div>
  )
}

export default NoAccess
