import Tooltip from 'react-tooltip'

import Button from 'components/Button'

interface NoteCommentButtonProps {
  onClick: () => void
  label: string
  src: string
  count?: number
  loading?: boolean
}

const NoteCommentButton = ({
  onClick = () => {},
  label,
  src,
  count = 0,
  loading = false
}: NoteCommentButtonProps) => {
  const lowercasedLabel = label.toLowerCase()

  return (
    <Button
      type="button"
      size="medium"
      shape="rounded-full"
      variant="outline"
      className="flex cursor-pointer flex-row items-center justify-center gap-[8px] px-[16px] font-semibold hover:text-rb-teal-300"
      onClick={onClick}
    >
      <img src={src} alt="Icon" width="16px" height="16px" />
      <span>{loading ? '...' : count}</span>
      <Tooltip
        id={`${lowercasedLabel}-count-button`}
        place="top"
        className="text-sm"
        effect="solid"
      />
    </Button>
  )
}

export default NoteCommentButton
