import { capitalizeFirstLetter } from 'utils/stringUtils'

interface ArtifactContextProps {
  companyMaturity: string | null
  companyName: string | null
  companyType: string | null
  employeeCount: string | null
}

const ArtifactContext = ({
  companyMaturity,
  companyName,
  companyType,
  employeeCount
}: ArtifactContextProps) => {
  const companySize = employeeCount ? `Company Size: ${employeeCount}` : null
  const type = () => {
    if (!companyType) return null
    if (['b2b', 'b2c', 'b2b2c'].includes(companyType)) {
      return companyType.toUpperCase()
    }
    return capitalizeFirstLetter(companyType)
  }
  const tags = [companySize, type(), companyMaturity].filter((tag) => tag)
  return (
    <div className="border-b-2 border-rb-gray-250 pb-[21px]">
      {companyName && (
        <div className="pb-7 font-semibold">
          Created at <span className="text-rb-gray-300">{companyName}</span>
        </div>
      )}
      <div className="flex flex-wrap">
        {tags.map((tag, idx) => (
          <div
            className="mb-[10px] mr-[10px] border-[1px] border-rb-gray-300 bg-white px-[10px] py-[4.5px] font-semibold text-rb-gray-300"
            key={idx}
          >
            {tag}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ArtifactContext
