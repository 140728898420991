import Button from 'components/Button'

export interface EndTrialToEnrollPromptProps {
  paymentMethodLast4Digits: string
  onConfirmClick: () => void
  onCancelClick: () => void
  loading?: boolean
}

export const EndTrialToEnrollPrompt = ({
  paymentMethodLast4Digits,
  onConfirmClick,
  onCancelClick,
  loading
}: EndTrialToEnrollPromptProps) => {
  return (
    <section className="max-w-[420px] w-full mx-auto">
      <div className="text-xl font-semibold leading-[1.4] text-rb-white px-4 py-2 bg-rb-blue-100 text-center">
        1 Live Course Pass
      </div>

      <div className="p-8 bg-rb-blue-50 text-center">
        <p className="text-[28px] font-medium leading-[1.2] mb-4">
          End Your Free Trial to Enroll
        </p>
        <p className="text-base leading-[1.6] mb-8">
          In order to enroll in this course, you will need to end your free trial and
          begin your membership. Click “Confirm” below to end your free trial and start
          your subscription. Your card ending in {paymentMethodLast4Digits} will be
          charged immediately.
        </p>

        <div className="flex flex-col gap-2">
          <Button
            size="large"
            shape="rounded"
            fullWidth
            onClick={onConfirmClick}
            isLoadingSpinner={loading}
          >
            Confirm
          </Button>
          <Button
            size="large"
            shape="rounded"
            fullWidth
            variant="outline"
            onClick={onCancelClick}
            disabled={loading}
          >
            Cancel
          </Button>
        </div>
      </div>
    </section>
  )
}

export default EndTrialToEnrollPrompt
