import CollaboratorDetails from 'components/CollaboratorDetails/CollaboratorDetails'
import Modal, { ModalContent, ModalHeaderWithClose } from 'components/Modal'
import RfHeader2 from 'components/typography/RfHeader/RfHeader2'
import RfMetaMini from 'components/typography/RfMeta/RfMetaMini'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import { ProgramSummaryPartsFragment } from 'gql'

export interface AboutProgramModalProps {
  isOpen: boolean
  handleClose: () => void
  program: ProgramSummaryPartsFragment
  isMobile: boolean
}

const AboutProgramModal = ({
  isOpen,
  handleClose,
  program,
  isMobile
}: AboutProgramModalProps) => (
  <Modal
    isOpen={isOpen}
    handleClose={handleClose}
    className="min-h-fit max-w-3xl rounded-2xl"
    header={false}
    closeOnEscape={true}
    closeOnOutsideClick={true}
    scrollContent={true}
  >
    <ModalHeaderWithClose className="mt-10 mb-2 flex" handleClose={handleClose}>
      <RfHeader2>About this Course</RfHeader2>
    </ModalHeaderWithClose>
    <ModalContent scrollContent={true}>
      <div className="pb-2 text-base font-semibold text-rb-gray-300">
        {Math.ceil(program.estimatedTime / 60)} hours · {program.lessonCount} Lessons
      </div>
      <RfParagraphMedium className="pb-4">{program.shortDescription}</RfParagraphMedium>
      <RfParagraphMedium className="pb-8">{program.longDescription}</RfParagraphMedium>
      <RfMetaMini>What You&apos;ll Learn</RfMetaMini>
      <ul className="mt-0 pl-6 pt-2">
        {program.byTheEnd?.split(';').map((item: string, i) => (
          <li key={`items-${i}`} className="mb-1">
            <RfParagraphMedium>{item}</RfParagraphMedium>
          </li>
        ))}
      </ul>
      <div className="my-8">
        <CollaboratorDetails
          hosts={program.collaborators}
          label="Created By Experts"
          row={isMobile}
        />
      </div>
    </ModalContent>
  </Modal>
)

export default AboutProgramModal
