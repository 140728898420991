import clsx from 'clsx'

import BadgeableAvatar from 'components/BadgeableAvatar'

import { PostContributor } from 'gql'

export interface PersonData extends Omit<PostContributor, '__typename'> {}

export interface PersonProps {
  person: PersonData
  selected?: boolean
  updateFilters?: (person: PersonData, remove: boolean) => void
}

const Person = (props: PersonProps) => {
  const { person, selected, updateFilters } = props

  const removePerson = () => {
    updateFilters?.(person, true)
  }

  return (
    <div
      className={clsx(
        'mr-1 inline-flex max-w-full cursor-pointer items-center py-0 px-[5px] leading-4 text-rb-gray-300 hover:text-rb-blue-100',
        selected &&
          'mb-2 cursor-auto rounded-xl bg-white leading-[22px] hover:text-rb-gray-400'
      )}
    >
      <div className="mr-2">
        <BadgeableAvatar
          user={person}
          avatarUrl={person.avatarUrl}
          width="16"
          height="16"
          fullName={person.fullName}
        />
      </div>

      <span className="truncate">{person.fullName}</span>
      {selected && (
        <a
          className="ml-[5px] inline-block [&_svg]:h-2 [&_svg]:w-2 [&_svg]:text-rb-gray-400"
          uk-close=""
          onClick={removePerson}
        ></a>
      )}
    </div>
  )
}

export default Person
