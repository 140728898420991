export function calculateDuration(seconds: number) {
  // Convert seconds to minutes
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  // Convert minutes to hours and remaining minutes
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  // Format output
  if (hours === 0) {
    if (remainingMinutes === 0) {
      return `${remainingSeconds} seconds`
    } else {
      return `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}`
    }
  } else if (hours === 1 && remainingMinutes === 0) {
    return '1 hour'
  } else if (remainingMinutes > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} and ${remainingMinutes} minute${
      remainingMinutes > 1 ? 's' : ''
    }`
  } else {
    return `${hours} hour${hours > 1 ? 's' : ''}`
  }
}
