import CaseStudyCard from 'domains/Sanity/marketingSite/CaseStudyCard'
import { MarketingH2, MarketingH3 } from 'domains/Sanity/marketingSite/textHelpers'

import Button from 'components/Button'

import { PricingPageContent } from 'gql'

interface CaseStudiesSectionProps {
  content: PricingPageContent
}

const CaseStudiesSection = ({ content }: CaseStudiesSectionProps) => {
  return (
    <section className="px-4 md:px-[4vw]">
      <div className="mx-auto max-w-[1180px] flex flex-col items-center py-[50px] lg:py-[100px]">
        <MarketingH2 className="mb-2.5">{content.caseStudiesTitle}</MarketingH2>
        <MarketingH3 className="mb-12 lg:max-w-[850px]">
          {content?.caseStudiesSubtitle}
        </MarketingH3>

        <ul className="grid list-none grid-cols-1 items-stretch gap-3 p-0 sm:grid-cols-3">
          {content.caseStudies?.map((caseStudy) => (
            <CaseStudyCard key={caseStudy.id} {...caseStudy} />
          ))}
        </ul>

        <div className="mt-12 flex justify-center">
          <Button size="medium" shape="rounded" variant="outline" href="/case-studies">
            View all case studies
          </Button>
        </div>
      </div>
    </section>
  )
}

export default CaseStudiesSection
