import clsx from 'clsx'

import BadgeableAvatar from 'components/BadgeableAvatar'

import { ExpertUserPartsFragment } from 'gql'

interface IExpertCollaborators {
  experts: ExpertUserPartsFragment[]
  className?: string
  avatarClassName?: string
}

const ExpertCollaborators = ({
  experts = [],
  className,
  avatarClassName
}: IExpertCollaborators) => (
  <div className={clsx('mt-5', className)}>
    {experts.map((expert) => (
      <div className="!mt-5 ml-0 flex items-center" key={`expert${expert.id}`}>
        <div className="mr-[15px] h-10 w-10 pl-0">
          <BadgeableAvatar
            user={expert}
            avatarUrl={expert.avatarUrl}
            width="40"
            height="40"
            alt={expert.fullName}
            borderClassNames={avatarClassName}
            fullName={expert.fullName}
          />
        </div>
        <div className="uk-width-expand mt-0 pl-0">
          <div className="font-sans text-sm font-medium leading-[22px] text-rb-gray-500">
            {expert.fullName}
          </div>
          <div className="font-sans text-xs font-normal leading-[18px] text-rb-gray-500">
            {expert.role}
            {!!expert.companyName && ` @ ${expert.companyName}`}
          </div>
        </div>
      </div>
    ))}
  </div>
)

export default ExpertCollaborators
