import { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'

import GroupCard from 'domains/Group/GroupCard'

import Loading from 'components/Loading'

import { useGroupsListQuery } from 'gql'

import notifyError from 'utils/errorNotifier'

const GroupsList = () => {
  const { data, error, loading } = useGroupsListQuery({
    variables: { limit: 3, onlyWithoutMembership: true }
  })
  const groups = data?.groups?.groups || []

  useEffect(() => {
    if (error) {
      notifyError(error)
    }
  }, [error])

  return (
    <Fragment>
      <div>
        <div className="uk-margin-bottom flex items-center justify-between border-b border-rb-gray-100 pb-2.5">
          <span className="m-0 text-lg font-semibold text-black">Join a Group</span>
          {groups.length > 0 && (
            <Link
              to="/groups"
              id="view-all-groups"
              className="uk-inline-block uk-text-uppercase m-0 cursor-pointer font-sans text-xs font-normal tracking-widest text-rb-gray-300 hover:text-rb-teal-600 hover:no-underline"
            >
              View All
            </Link>
          )}
        </div>
        {loading && <Loading />}
        {!loading && groups.length === 0 && (
          <div>Oops, looks like no groups have been created yet.</div>
        )}
        {!loading &&
          groups.length > 0 &&
          groups.filter((g) => !g.userIsMember).length > 0 &&
          groups
            .filter((g) => !g.userIsMember)
            .sort((a, b) => b.membersCount - a.membersCount)
            .slice(0, 3)
            .map((group) => <GroupCard key={`group_${group.id}`} group={group} />)}
        {groups.filter((g) => !g.userIsMember).length === 0 && !loading && (
          <p>You&apos;ve already joined each available group, congratulations!</p>
        )}
      </div>
    </Fragment>
  )
}

export default GroupsList
