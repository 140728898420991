import { ReimbursementOverview } from 'domains/Reimbursement/ReimbursementOverview'
import { ReimbursementPitchTemplates } from 'domains/Reimbursement/ReimbursementPitchTemplates'
import ReimbursementSidebar from 'domains/Reimbursement/ReimbursementSidebar'
import { ReimbursementTips } from 'domains/Reimbursement/ReimbursementTips'
import ReimbursementTitle from 'domains/Reimbursement/ReimbursementTitle'

import Breadcrumbs from 'components/Breadcrumbs'
import PaymentBanner from 'components/banners/TopBanner/PaymentBanner'

import { useCurrentUser } from 'hooks/useCurrentUser'

export function ReimbursementCenterPage() {
  const { currentUser } = useCurrentUser()
  const isAccepted = currentUser && ['accepted'].includes(currentUser.accessPolicyKind)

  const history = [
    {
      name: 'Home',
      path: '/'
    },
    {
      name: 'Reimbursement',
      path: '/reimbursement'
    }
  ]
  return (
    <>
      <div className="flex h-12 w-full flex-row justify-between px-3 shadow-headerTab">
        <Breadcrumbs history={history} />
      </div>

      {isAccepted && <PaymentBanner />}

      <ReimbursementSidebar
        header={<ReimbursementTitle />}
        overview={<ReimbursementOverview />}
        emailTemplates={<ReimbursementPitchTemplates />}
        tips={<ReimbursementTips />}
      />
    </>
  )
}
export default ReimbursementCenterPage
