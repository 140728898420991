import Button from 'components/Button'
import Modal, {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle
} from 'components/Modal'
import { EventCard } from 'components/cards'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'

import { EventPartsFragment } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { getPageFromPath } from 'utils/location'
import { trackEventAction, trackModalDismissed } from 'utils/tracking/analytics'

import useRealTimeEventReminderCalculateTimeLeft from './UseRealTimeEventReminderCalculateTimeLeft/UseRealTimeEventReminderCalculateTimeLeft'

interface RealTimeEventReminderModalProps {
  upcomingEvent: EventPartsFragment
  isModalOpen: boolean
  setIsModalClose: () => void
}

const RealTimeEventReminderModal = ({
  upcomingEvent,
  isModalOpen,
  setIsModalClose
}: RealTimeEventReminderModalProps) => {
  const { currentUser } = useCurrentUser()
  const timeLeft = useRealTimeEventReminderCalculateTimeLeft(upcomingEvent)

  if (!currentUser) return null

  const handleButtonClick = () => {
    if (upcomingEvent?.zoomLink) {
      window.open(upcomingEvent.zoomLink, '_blank')
    }

    trackEventAction({
      action: 'join_now_click',
      event_id: upcomingEvent.id,
      event_name: upcomingEvent.name,
      component: 'live_event_starting_modal'
    })
  }

  const handleModalClose = () => {
    setIsModalClose()

    trackModalDismissed({
      category: 'app',
      location: getPageFromPath(),
      modal_group: 'events',
      modal_name: 'live event starting',
      related_identifiers: {
        event_id: upcomingEvent.id,
        event_name: upcomingEvent.name,
        event_kind: upcomingEvent.kind,
        cms_program_id: upcomingEvent.cmsProgramOptional?.id,
        cms_program_name: upcomingEvent.cmsProgramOptional?.name
      }
    })

    window.localStorage.setItem(
      `real-time-modal-dismissed-${upcomingEvent.id}`,
      'dismissed'
    )
  }

  const minutesLeftToSeconds = timeLeft.minutes * 60

  return (
    <Modal
      isOpen={isModalOpen}
      handleClose={handleModalClose}
      className="max-w-[928px] rounded-2xl"
    >
      <div className="flex items-center gap-10" data-testid="real-time-event-modal">
        <div className="w-3/6">
          <ModalHeader className="justify-between">
            {minutesLeftToSeconds + timeLeft.seconds > 0 ? (
              <ModalTitle>Your event is starting soon!</ModalTitle>
            ) : (
              <ModalTitle>Your event is in progress!</ModalTitle>
            )}
          </ModalHeader>

          <ModalContent className="overflow-hidden">
            {timeLeft.minutes >= 2 && (
              <>
                <RfParagraphMedium data-testid="two-mins-out-content">
                  {upcomingEvent?.name} is
                </RfParagraphMedium>
                <RfParagraphMediumBold>starting in 2 minutes</RfParagraphMediumBold>
              </>
            )}
            {minutesLeftToSeconds + timeLeft.seconds > 0 && timeLeft.minutes < 2 && (
              <>
                <RfParagraphMedium data-testid="under-two-mins-out-content">
                  {upcomingEvent?.name} is{' '}
                </RfParagraphMedium>
                <RfParagraphMediumBold>
                  starting in {timeLeft.minutes}:{timeLeft.seconds < 10 ? 0 : ''}
                  {timeLeft.seconds} minutes
                </RfParagraphMediumBold>
              </>
            )}
            {minutesLeftToSeconds + timeLeft.seconds <= 0 && (
              <>
                <RfParagraphMedium data-testid="event-started-content">
                  {upcomingEvent?.name}
                </RfParagraphMedium>
                <RfParagraphMediumBold>has started</RfParagraphMediumBold>
              </>
            )}
          </ModalContent>
          <ModalFooter className="flex-col justify-start sm:flex-row">
            <Button onClick={handleButtonClick} size="small" shape="rounded-full">
              Join Event
            </Button>
          </ModalFooter>
        </div>
        <div className="max-w-[252px] pr-8 pb-8">
          <EventCard
            event={upcomingEvent}
            currentUser={currentUser}
            disabledButton={true}
          />
        </div>
      </div>
    </Modal>
  )
}

export default RealTimeEventReminderModal
