import { SearchComboboxOptions } from './SearchCombobox'

export function SearchSuggestionsContext({ suggestions }: { suggestions: string[] }) {
  if (!suggestions.length) return null

  return (
    <SearchComboboxOptions
      title="Suggested Searches"
      options={suggestions.map((value) => ({
        value,
        origin: 'search-suggestion' as const
      }))}
    />
  )
}
