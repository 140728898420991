import React, { useState } from 'react'

import { SVGIcon } from 'components/Icon'

import { isMobileDevice } from 'utils/device.utils'

interface IVideoControlBar {
  bookmarking: boolean
  videoIsPlaying: boolean
  onBookmarkClick: React.MouseEventHandler<HTMLButtonElement>
  onCustomPlayerClick: React.MouseEventHandler<HTMLButtonElement>
}

const VideoControlBar = ({
  bookmarking,
  videoIsPlaying,
  onBookmarkClick,
  onCustomPlayerClick
}: IVideoControlBar) => {
  const [isHoveringOverPlayPauseControl, setIsHoveringOverPlayPauseControl] =
    useState(false)
  const [isHoveringOverBookmarkControl, setIsHoveringOverBookmarkControl] =
    useState(false)

  const controlIcon = (name: string) => {
    return <SVGIcon className="h-6 w-6 sm:h-[30px] sm:w-[30px]" name={name} />
  }
  const playIcon = isHoveringOverPlayPauseControl
    ? controlIcon('play')
    : controlIcon('play-hollow')
  const pauseIcon = isHoveringOverPlayPauseControl
    ? controlIcon('pause')
    : controlIcon('pause-hollow')
  const spaceIcon = <span className="hotkey">⎵</span>
  const enterIcon = <span className="hotkey">⏎</span>
  const bookmarkIcon = isHoveringOverBookmarkControl
    ? controlIcon('bookmark')
    : controlIcon('bookmark-hollow')

  const spaceElement = <span className="uk-visible@s">(Space {spaceIcon} )</span>
  let playerTextElement = videoIsPlaying ? (
    <span>Playing {spaceElement}</span>
  ) : (
    <span>Paused {spaceElement}</span>
  )
  if (isHoveringOverPlayPauseControl) {
    playerTextElement = videoIsPlaying ? (
      <span>Pause {spaceElement}</span>
    ) : (
      <span>Play {spaceElement}</span>
    )
  }

  // TODO: get rid of isMobileDevice and the onMouseEnter/onMouseLeave
  //       and handle with css and properly configured svgs
  const playPauseControlElement = (
    <button
      className="video-bookmarker__control box-border flex cursor-pointer items-center border-none bg-white text-left font-sans text-[13px] italic text-rb-gray-300 outline-none focus:border-none focus:outline-none sm:min-w-[250px] sm:font-sans sm:text-lg sm:text-rb-gray-300"
      onClick={onCustomPlayerClick}
      onMouseEnter={() => !isMobileDevice() && setIsHoveringOverPlayPauseControl(true)}
      onMouseLeave={() => !isMobileDevice() && setIsHoveringOverPlayPauseControl(false)}
    >
      {(videoIsPlaying && !isHoveringOverPlayPauseControl) ||
      (!videoIsPlaying && isHoveringOverPlayPauseControl)
        ? playIcon
        : pauseIcon}{' '}
      {playerTextElement}
    </button>
  )

  const bookmarkTooltip = 'title: Bookmark slides for later with a simple tap'
  const bookmarkElement = (
    <button
      className="video-bookmarker__control box-border flex cursor-pointer items-center border-none bg-white pl-0 text-left font-sans text-[13px] text-rb-gray-300 outline-none focus:border-none focus:outline-none sm:min-w-[250px] sm:font-sans sm:text-lg sm:text-rb-gray-300"
      onClick={onBookmarkClick}
      onMouseEnter={() => !isMobileDevice() && setIsHoveringOverBookmarkControl(true)}
      onMouseLeave={() => !isMobileDevice() && setIsHoveringOverBookmarkControl(false)}
    >
      <span className="flex items-center" uk-tooltip={bookmarkTooltip}>
        {bookmarkIcon} Bookmark
      </span>
      <span className="uk-visible@s"> (Return/Enter {enterIcon} )</span>
    </button>
  )

  return (
    <React.Fragment>
      {bookmarking ? (
        <div className="video-bookmarker uk-text-center pt-[10.6px] text-left font-sans text-lg leading-[1.28] text-rb-gray-300 sm:pt-[27.2px]">
          <div className="uk-text-center" uk-spinner="ratio: 0.75" />
        </div>
      ) : (
        <div className="uk-grid video-bookmarker mb-2 flex cursor-pointer items-center pt-[10.6px] text-left font-sans text-lg leading-[1.28] text-rb-gray-300 sm:mb-7 sm:pt-[27.2px]">
          {playPauseControlElement}
          {bookmarkElement}
        </div>
      )}
    </React.Fragment>
  )
}

export default VideoControlBar
