import { twMerge } from 'tailwind-merge'

const linearGradientClasses = 'bg-gradient-to-r from-[#FFA2CF] via-[#FDE250] to-[#CADD48]'

interface BetaBadgeProps {
  className?: string
}

export const BetaBadge = ({ className }: BetaBadgeProps) => (
  <div
    className={twMerge(
      `${linearGradientClasses} flex h-[18px] w-[37px] items-center justify-center rounded-[3px] py-1 pl-1.5 pr-2 text-xs text-rb-gray-500`,
      className
    )}
  >
    beta
  </div>
)

export default BetaBadge
