import { ApolloError } from '@apollo/client'

import Expired from 'domains/Subscription/SubscriptionCard/Expired'

import { ErrorMessage } from 'components'

import { PlanName, UserSubscriptionWithTeamQuery } from 'gql'

import { formatInTimezone } from 'utils/date'
import { formatMoney } from 'utils/moneyUtils'

import PricingAndPackagingSubscriptionCard from './PricingAndPackagingSubscriptionCard'

interface SubscriptionCardContainerProps {
  subscriptionData: UserSubscriptionWithTeamQuery | undefined
  subscriptionLoading: boolean
  subscriptionError: ApolloError | undefined
  timezone: string | null | undefined
}

const SubscriptionCardContainer = ({
  subscriptionData,
  subscriptionLoading,
  subscriptionError,
  timezone
}: SubscriptionCardContainerProps) => {
  if (subscriptionLoading || !subscriptionData) return null
  if (subscriptionError) return <ErrorMessage error={subscriptionError} />

  const user = subscriptionData.user
  if (!user) return null

  const { accessPolicyKind, subscriptions, paymentMethods, is } = user

  const activeSubscription = subscriptions?.active
  const lastExpiredSubscription = subscriptions?.lastExpired
  if (accessPolicyKind === 'expired') {
    return (
      <div className="rf-rb-card mb-5 bg-white p-6">
        <div className="mb-2 text-xl font-medium">Subscription</div>
        <Expired
          hasPaymentMethod={!!paymentMethods.length}
          wasTeam={!!lastExpiredSubscription?.isTeam}
        />
      </div>
    )
  }
  if (!activeSubscription) return null

  const amountDue =
    activeSubscription.stripeUpcomingInvoice?.amountDue !== null &&
    activeSubscription.stripeUpcomingInvoice?.amountDue !== undefined &&
    activeSubscription.stripeUpcomingInvoice?.amountDue >= 0
      ? formatMoney(activeSubscription.stripeUpcomingInvoice.amountDue / 100, true)
      : '$0'

  return (
    <div data-test="account-subscription" className="rf-rb-card mb-5 bg-white p-6">
      <div className="mb-2 text-xl font-medium">Subscription</div>
      <PricingAndPackagingSubscriptionCard
        amountDue={amountDue}
        expiresAt={formatInTimezone(
          activeSubscription.expiresAt,
          timezone,
          'LLLL d, yyyy'
        )}
        isCanceled={!!activeSubscription.isCanceled}
        isDunning={activeSubscription.isDunning}
        subscription={activeSubscription}
        canManageTeam={!!user.can?.manageMyTeam}
        canViewBilling={!!user.can?.viewBilling}
        isIndividual={activeSubscription.planName === PlanName.INDIVIDUAL}
        isTrialing={is.trialing}
      />
    </div>
  )
}

export default SubscriptionCardContainer
