import DOMPurify from 'dompurify'

function highlightText(query: string, title: string, startSel = '<b>', stopSel = '</b>') {
  const casedQuery = query
    .replace(/[^0-9a-z ]/i, '')
    .split(' ')
    .map((term) => term.toLowerCase())
  return title
    .split(/\s+/g)
    .map((word) => {
      const isContained = casedQuery.some((term) => word.toLowerCase().startsWith(term))
      return isContained ? `${startSel}${word}${stopSel}` : word
    })
    .join(' ')
}

export function highlightAndSanitize(query: string, templateString: string) {
  return DOMPurify.sanitize(highlightText(query, templateString))
}

export function truncateText(textToTruncate: string, truncationLength = 75) {
  return textToTruncate.length > truncationLength
    ? `${textToTruncate.slice(0, Math.max(0, truncationLength - 3))}...`
    : textToTruncate
}

export function formatTitle(
  query: string,
  title?: string | null,
  truncationLength?: number
) {
  const titleWithoutParens = title?.replace(/[()]/g, '') || ''
  const truncatedTitle = truncateText(titleWithoutParens, truncationLength)
  return highlightAndSanitize(query, truncatedTitle)
}

export function formatSearchBody(searchBody: string) {
  return DOMPurify.sanitize(`&hellip;${searchBody.replace('&nbsp;', ' ')}&hellip;`)
}
