import {
  DATASET_SPLIT_METHOD,
  HighlightRange,
  IDENTIFIER,
  SelectedNode,
  SplitType
} from './types'

export const isNodeEmpty = ($n: Node | null): boolean => !$n || !$n.textContent
export const isHighlightedNode = ($node: HTMLElement): boolean => {
  return !!$node?.dataset && !!$node.dataset[IDENTIFIER]
}

export const wrapNewNode = (
  selected: SelectedNode,
  range: HighlightRange,
  className: string[],
  tag: string
): HTMLElement => {
  const $wrapper = document.createElement(tag)

  $wrapper.classList.add(...className)

  $wrapper.appendChild(selected.$node.cloneNode(false))
  selected.$node.parentNode!.replaceChild($wrapper, selected.$node)

  $wrapper.setAttribute(`data-${IDENTIFIER}`, range.id!)
  $wrapper.setAttribute(`data-${DATASET_SPLIT_METHOD}`, selected.splitType)

  return $wrapper
}

/**
 * Split and highlight each one.
 */
export const wrapPartialNode = (
  selected: SelectedNode,
  range: HighlightRange,
  className: string[],
  tag: string
): HTMLElement => {
  const $wrapper: HTMLElement = document.createElement(tag)
  $wrapper.classList.add(...className)

  const $parentN = selected.$node?.parentNode as HTMLElement
  const $prevN = selected.$node?.previousSibling
  const $nextN = selected.$node?.nextSibling
  const $fragment = document.createDocumentFragment()

  $wrapper.setAttribute(`data-${IDENTIFIER}`, range.id!)
  $wrapper.appendChild(selected.$node.cloneNode(false))

  let headSplit = false
  let tailSplit = false
  let splitType: SplitType

  if ($prevN) {
    const $span = $parentN.cloneNode(false)

    $span.textContent = $prevN.textContent
    $fragment.appendChild($span)
    headSplit = true
  }

  const classNameList: string[] = []

  if (Array.isArray(className)) {
    classNameList.push(...className)
  } else {
    classNameList.push(className)
  }

  // @todo revisit this logic
  const uniqueClasses = [...new Set(classNameList)]

  $wrapper.classList.add(...uniqueClasses)

  $fragment.appendChild($wrapper)

  if ($nextN) {
    const $span = $parentN.cloneNode(false)

    $span.textContent = $nextN.textContent
    $fragment.appendChild($span)
    tailSplit = true
  }

  if (headSplit && tailSplit) {
    splitType = SplitType.both
  } else if (headSplit) {
    splitType = SplitType.head
  } else if (tailSplit) {
    splitType = SplitType.tail
  } else {
    splitType = SplitType.none
  }

  $wrapper.setAttribute(`data-${DATASET_SPLIT_METHOD}`, splitType)
  $parentN.parentNode!.replaceChild($fragment, $parentN)

  return $wrapper
}

/**
 * Edgecase: Update id and info if not existing we should not get here
 */
export const wrapOverlapNode = (
  selected: SelectedNode,
  range: HighlightRange,
  className: string[] | string
): HTMLElement => {
  const $parent = selected.$node.parentNode as HTMLElement
  const $wrapper: HTMLElement = $parent

  // @todo revisit this logic
  $wrapper.className = ''
  $wrapper.classList.add(...className)
  const dataset = $parent.dataset

  dataset[IDENTIFIER] = range.id

  return $wrapper
}
