import LessonCard from 'components/CohortDashboard/LessonCard/LessonCard'

import {
  CohortDashboardCmsSectionPartsFragment,
  CohortDashboardLessonPartsFragment
} from 'gql'

import { ModuleProgress } from 'typings/scalars'

interface BodyProps {
  isExpanded: boolean
  section: CohortDashboardCmsSectionPartsFragment
  userProgress: ModuleProgress
  slug: string
  lockLesson?: boolean
  onTrack?: (lesson: CohortDashboardLessonPartsFragment) => void
}

const CollapsibleSectionCardBody = ({
  isExpanded,
  section,
  userProgress,
  slug,
  lockLesson,
  onTrack
}: BodyProps) => {
  if (!isExpanded) {
    return null
  }

  const sectionChildren = section.children

  return (
    <div className="flex flex-col border-none">
      {sectionChildren.map((lesson, index) => {
        // orderedStyling is needed to be able to extend the hover background beyond the top of the first lesson and
        // below the bottom of the last lesson, as well to not paint over the bottom border of the rounded parent div
        const orderedStyling = () => {
          if (sectionChildren.length === 1) {
            // only lesson
            return 'py-6 rounded-b-xl'
          }

          if (index === 0) {
            // first of multiple lessons
            return 'pt-6 pb-2.5'
          }

          if (index === sectionChildren.length - 1) {
            // last lesson of multiple
            return 'pt-2.5 pb-6 rounded-b-xl'
          }

          return 'py-2.5' // one of multiple lessons, but neither first nor last
        }

        return (
          <LessonCard
            key={`lesson-card-${lesson.id}`}
            lesson={lesson}
            userProgress={userProgress}
            slug={slug}
            className={orderedStyling()}
            number={index + 1}
            thumbnailClassname="min-w-[4rem] w-16 md:min-w-[109px]"
            lockLesson={lockLesson}
            onTrack={onTrack}
          />
        )
      })}
    </div>
  )
}

export default CollapsibleSectionCardBody
