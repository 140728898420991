import { closing, opening } from './sharedMarkdown'

const programName = 'Marketing Strategy'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://www.reforge.com/marketing-strategy), because I will learn new methods to define and execute intersecting strategies across brand, product marketing, and growth marketing.  This course was built with [John Russ](https://www.reforge.com/experts/john-russ) and [Martina Tam](https://www.reforge.com/experts/martina-tam), and covers:
* **Brand Marketing.** Learn how to build the foundation of your brand marketing strategy. From brand identity to governance and distribution, you'll explore the brand marketing wheel.
* **Product Marketing.** Deepen your understanding of the four phases of product marketing. We'll cover market analysis, merchandising, go-to-market, and post launch.
* **Growth Marketing.** Growth marketing powers growth loops. We'll dive into channel strategy, channel prioritization, metrics, and measurement.
* **Executing Your Cross-Domain Strategy.** How do you execute across brand, product, and growth marketing? Learn how to build an investment thesis and identify when to re-evaluate your strategy.  

&NewLine;

${closing}
`

export default { markdown, title: programName }
