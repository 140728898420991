import { ReactText, useEffect, useRef, useState } from 'react'

import useHandleAddToFolder from 'domains/Collections/hooks/useHandleAddToFolder'
import useHandleRemoveFromFolder from 'domains/Collections/hooks/useHandleRemoveFromFolder'

import Banner from 'components/Banner'
import Modal, {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle
} from 'components/Modal'
import { displayToast } from 'components/Toast'
import ToastCard from 'components/ToastCard'
import { CloseIcon } from 'components/icons'

import {
  BookmarkFolderPartsFragment,
  CourseBookmarkPartsFragment,
  ProgramBookmarkPartsFragment
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import notifyError from 'utils/errorNotifier'
import { onEnterKeyPress, onSpaceKeyPress } from 'utils/keyboard'
import { humanizeCamelCase } from 'utils/stringUtils'
import { displayErrorToast, displaySuccessToast } from 'utils/toastService'
import { trackBookmarkAction, trackModalDismissed } from 'utils/tracking/analytics'

interface AddToBookmarkFolderModalProps {
  isOpen: boolean
  handleClose: () => void
  bookmarkFolders: BookmarkFolderPartsFragment[] | undefined
  openCreateBookmarkFolderModal: () => void
  currentBookmarkForDropdown:
    | ProgramBookmarkPartsFragment
    | CourseBookmarkPartsFragment
    | null
  showCollectionsOnboardingInfo?: boolean | null
}

const CloseButton = ({ handleClose }: { handleClose: () => void }) => {
  return (
    <div
      tabIndex={0}
      role="button"
      aria-label="close-modal-button"
      className="hover:bg-default absolute right-[-12px] top-[-12px] cursor-pointer text-xl"
      onClick={handleClose}
      onKeyUp={onEnterKeyPress(handleClose)}
      data-test="introducing-collections-modal-close"
    >
      <CloseIcon className="m-[40px] h-6 w-6" />
    </div>
  )
}

const Checkbox = ({
  name,
  label,
  currentValue,
  onChange
}: {
  name: string
  label: string
  currentValue: boolean
  onChange: (val: boolean) => void
}) => {
  const [checked, setChecked] = useState(currentValue)

  function handleChange() {
    const val = !checked

    setChecked(val)

    if (onChange) onChange(val)
  }

  const labelText = label || humanizeCamelCase(name)

  return (
    <div
      className="flex cursor-pointer items-center"
      onClick={handleChange}
      role="checkbox"
      aria-checked={checked}
      tabIndex={0}
      onKeyUp={onSpaceKeyPress(handleChange)}
    >
      <div
        className={`flex items-center justify-center rounded-sm border-1.5 ${
          checked ? 'color-white border-rb-gray-400 bg-rb-gray-400' : 'border-rb-gray-100'
        }`}
        style={{ width: '20px', height: '20px' }}
      >
        <svg
          className={checked ? 'block' : 'hidden'}
          width="14"
          height="10"
          viewBox="0 0 14 10"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.3333 1L5 8.33333L1.66667 5"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      <div className="pl-2 text-[14px] font-normal leading-[21px] sm:text-[16px] sm:leading-6">
        {labelText}
      </div>
    </div>
  )
}

const AddBookmarkToFolderModal = ({
  isOpen,
  handleClose,
  bookmarkFolders,
  openCreateBookmarkFolderModal,
  currentBookmarkForDropdown,
  showCollectionsOnboardingInfo
}: AddToBookmarkFolderModalProps) => {
  const toastId = useRef<ReactText | null>(null)
  const [addedCount, setAddedCount] = useState(0)
  const [selectedFolderIds, setSelectedFolderIds] = useState<string[]>([])
  const { RemoveBookmarkFromFolder } = useHandleRemoveFromFolder({})
  const { AddBookmarkToFolder } = useHandleAddToFolder({})

  const { currentUser } = useCurrentUser()

  useEffect(() => {
    if (currentBookmarkForDropdown) {
      const originalSelection = currentBookmarkForDropdown.filedBookmarks.map(
        (filedBookmark) => {
          return filedBookmark.bookmarkFolderId
        }
      )
      setSelectedFolderIds(originalSelection)
    }
  }, [setSelectedFolderIds, currentBookmarkForDropdown])

  if (!currentBookmarkForDropdown) return null

  if (
    !currentUser?.is.member &&
    !currentUser?.is.paidMember &&
    !currentUser?.is.planManager
  ) {
    return null
  }

  const programIdentifiers = () => {
    if ('cmsProgram' in currentBookmarkForDropdown) {
      return {
        cms_section_id: currentBookmarkForDropdown.cmsSection?.id,
        cms_program_id: currentBookmarkForDropdown.cmsProgram?.id,
        cms_module_id: currentBookmarkForDropdown.cmsModule?.id,
        cms_section_name: currentBookmarkForDropdown.cmsSection?.name,
        cms_program_name: currentBookmarkForDropdown.cmsProgram?.name,
        cms_module_name: currentBookmarkForDropdown.cmsModule?.name
      }
    }

    return {}
  }

  const onClose = () => {
    trackModalDismissed({
      category: 'app',
      modal_group: 'collections',
      modal_name: 'save_to_collection_modal',
      related_identifiers: {
        path: window.location.pathname,
        ...programIdentifiers()
      }
    })
    trackModalDismissed({
      category: 'app',
      location: window.location.href,
      modal_name: 'update_collection_modal',
      modal_group: 'collections'
    })
    handleClose()
  }

  const handleAddToFolder = async (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => {
    const { errors, data } = await AddBookmarkToFolder({
      variables: {
        input: {
          bookmarkId: bookmarkId,
          bookmarkFolderId: bookmarkFolder.id
        }
      }
    })

    if (errors) {
      notifyError(errors)
      displayErrorToast({
        message: `There was a problem saving your bookmark to ${bookmarkFolder.name}`
      })
      return null
    } else {
      trackBookmarkAction({
        action: 'save_to_collection',
        collection_id: bookmarkFolder.id,
        collection_name: bookmarkFolder.name,
        is_reforge_collection: bookmarkFolder.reforgeCollection,
        is_shared_collection: bookmarkFolder?.sharedFolders[0]?.status === 'shared'
      })

      if (toastId.current === 'add-to-collection-toast') {
        setAddedCount(addedCount + 1)
        displayToast.update(toastId.current, {
          render: (
            <ToastCard
              message={`Saved to ${addedCount} Collections`}
              viewHref="/saved"
              viewText="Saved"
            />
          ),
          onClose: () => {
            toastId.current = null
            setAddedCount(0)
          },
          toastId: 'add-to-collection-toast'
        })
      } else {
        setAddedCount(2) // need to set to 2 to start, possibly due to race condition?
        toastId.current = displaySuccessToast({
          message: `Added to ${bookmarkFolder.name} Collection`,
          viewHref: `/saved/${bookmarkFolder.id}`,
          viewText: 'View Collection',
          onClose: () => {
            toastId.current = null
            setAddedCount(0)
          },
          toastId: 'add-to-collection-toast'
        })
      }
      return data?.createFiledBookmark?.filedBookmark?.bookmarkFolderId
    }
  }

  const handleRemoveFromFolder = async (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => {
    const { errors, data } = await RemoveBookmarkFromFolder({
      variables: {
        input: {
          bookmarkId: bookmarkId,
          bookmarkFolderId: bookmarkFolder.id
        }
      }
    })

    if (errors) {
      notifyError(errors)
      return null
    } else {
      trackBookmarkAction({
        action: 'remove_from_collection',
        collection_id: bookmarkFolder.id,
        collection_name: bookmarkFolder.name,
        is_reforge_collection: bookmarkFolder.reforgeCollection,
        is_shared_collection: bookmarkFolder?.sharedFolders[0]?.status === 'shared'
      })

      if (toastId.current === 'remove-from-collection-toast') {
        setAddedCount(addedCount + 1)
        displayToast.update(toastId.current, {
          render: (
            <ToastCard
              message={`Removed from ${addedCount} Collections`}
              viewHref="/saved"
              viewText="View Library"
            />
          ),
          onClose: () => {
            toastId.current = null
            setAddedCount(0)
          },
          toastId: 'remove-from-collection-toast'
        })
      } else {
        setAddedCount(2) // need to set to 2 to start, possibly due to race condition?
        toastId.current = displaySuccessToast({
          message: `Removed from ${bookmarkFolder.name} Collection`,
          viewHref: `/saved/${bookmarkFolder.id}`,
          viewText: 'View Collection',
          onClose: () => {
            toastId.current = null
            setAddedCount(0)
          },
          toastId: 'remove-from-collection-toast'
        })
      }

      return data?.deleteFiledBookmark?.filedBookmark?.bookmarkFolderId
    }
  }

  const handleToggle = async (
    val: boolean,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => {
    if (val) {
      setSelectedFolderIds([...selectedFolderIds, bookmarkFolder.id])
      handleAddToFolder(currentBookmarkForDropdown.id, bookmarkFolder)
    } else {
      setSelectedFolderIds(selectedFolderIds.filter((id) => id !== bookmarkFolder.id))
      handleRemoveFromFolder(currentBookmarkForDropdown.id, bookmarkFolder)
    }
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        handleClose={onClose}
        className="rounded-2x relative max-w-lg p-8 pb-6"
        header={false}
        closeOnEscape={true}
        closeOnOutsideClick={true}
      >
        <ModalHeader className="mb-6 flex flex-col px-0 md:px-0">
          <CloseButton handleClose={onClose} />
          <ModalTitle className="text-lg font-semibold md:text-xl">Save to</ModalTitle>
          {showCollectionsOnboardingInfo && (
            <Banner className="rounded-md" dismissable={false}>
              <p className="mb-0 text-base">
                Organize your Reforge saves with Collections. Share Collections with your
                team to learn and do your best work together.
              </p>
            </Banner>
          )}
        </ModalHeader>
        <ModalContent className="flex flex-col gap-y-6 px-0 md:px-0" scrollContent={true}>
          {bookmarkFolders?.map((bookmarkFolder, key) => {
            if (bookmarkFolder.name === 'My Saved Items') return null
            return (
              <div key={key} className="flex w-full">
                <Checkbox
                  name={`bookmark_folder_${bookmarkFolder.id}`}
                  label={bookmarkFolder.name}
                  onChange={(val) => handleToggle(val, bookmarkFolder)}
                  currentValue={
                    !!selectedFolderIds.find((id) => id === bookmarkFolder.id)
                  }
                />
              </div>
            )
          })}
        </ModalContent>
        <ModalFooter className="mt-2.5 flex justify-between border-t py-0 pb-0 pt-2.5 md:mt-6 md:py-0 md:px-0 md:pb-0 md:pt-6">
          <button
            className="flex h-full rounded-2xl py-4 px-6 hover:bg-gray-200"
            onClick={openCreateBookmarkFolderModal}
          >
            + Create New
          </button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default AddBookmarkToFolderModal
