import { Fragment, useEffect } from 'react'

import UserHeading from 'domains/User/UserHeading'

import Loading from 'components/Loading'
import Paginator, { Meta } from 'components/Paginator'

import { GroupMembersQueryVariables, useGroupMembersQuery } from 'gql'

import notifyError from 'utils/errorNotifier'

/* eslint-disable camelcase */
interface GroupFiltersProps {
  page?: number
  sort_by?: string
}
/* eslint-enable camelcase */

interface MembersListProps {
  setBusy(nextState: boolean): void
  setFilters(nextState: any): void
  filters: GroupFiltersProps
  group: string
}

const MembersList = (props: MembersListProps) => {
  const { data, loading, refetch } = useGroupMembersQuery({
    variables: { slug: props.group },
    notifyOnNetworkStatusChange: true
  })

  const members = data?.groupMembers?.members
  const pagination =
    data?.groupMembers?.pagination ||
    ({
      currentPage: 1,
      totalPages: 0
    } as Meta)

  const fetchMembers = () => {
    const { filters, setBusy } = props
    if (Object.keys(filters).length === 0) {
      return
    } /* wait until filters have been set by Filters component */

    const variables: GroupMembersQueryVariables = {
      slug: props.group
    }

    if (filters.page) {
      variables.page = filters.page
    }
    if (filters.sort_by) {
      variables.sortBy = filters.sort_by
    }

    setBusy(true)
    document.getElementById('page')?.scrollTo(0, 0)

    refetch(variables)
      .catch((error) => notifyError(error))
      .finally(() => {
        setBusy(false)
      })
  }

  const handlePagination = (page: number) => {
    props.setFilters(Object.assign({}, props.filters, { page: page }))
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchMembers, [props.filters])

  return (
    <div className="uk-position-relative">
      {!members && <Fragment>{loading && <Loading ghosted />}</Fragment>}
      {members && members.length === 0 && (
        <Fragment>
          {loading && <Loading ghosted />}
          <div>Oops, there aren&apos;t any users in this group yet.</div>
        </Fragment>
      )}
      {members && members.length > 0 && (
        <Fragment>
          {loading && <Loading ghosted />}
          <div>
            {members.map((person) => (
              <div key={person.id} className="rf-rb-card-interactive mb-5">
                <UserHeading user={person} type="large" imgWidth="80" imgHeight="80" />
              </div>
            ))}
          </div>
          <div className="uk-text-center">
            <Paginator meta={pagination} handlePagination={handlePagination} />
          </div>
        </Fragment>
      )}
    </div>
  )
}

export default MembersList
