import Leaders from 'leaders-collage.png'
import NetworkImage from 'network-bubbles.png'
import { twMerge } from 'tailwind-merge'
import UnlimitedAcccessImage from 'unlimited-access-cards.png'

import { InfoCard } from 'components/cards/index'

interface ReforgeBenefitsProps {
  className?: string
}

export const ReforgeBenefits = ({ className }: ReforgeBenefitsProps) => {
  return (
    <div className={twMerge('reforge-benefits md:mt-4', className)}>
      <div className="flex flex-col">
        <InfoCard
          headerImage={UnlimitedAcccessImage}
          header="Gain unlimited access to all programs, concepts and projects"
          content="Membership gives access to all available on-demand Reforge programs, content, tools, frameworks, processes, etc."
        />
        <InfoCard
          headerImage={NetworkImage}
          header="Surround yourself with the best &amp; brightest in tech"
          content="Gain access to diverse perspectives and engage in active discussions where you can ask questions and receive valuable insights."
          textRight={true}
        />
        <InfoCard
          headerImage={Leaders}
          header="Learn from featured leaders across the fastest growing companies"
          content="Access to live, in-depth learning experiences led by some of the top executives in tech. They drive accountability and create community."
        />
      </div>
    </div>
  )
}
