import React, { useEffect } from 'react'

import Button from 'components/Button'
import ArrowUpRight from 'components/icons/ArrowUpRight'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { trackCtaClicked } from 'utils/tracking/analytics'
import { CANNY_UPVOTE_TOPICS_URL } from 'utils/url'

interface UpvoteArtifactsButtonProps {
  linkText: string
  ctaLocation: string
  variant?: 'teal_button' | 'outline_button'
  shape?: React.ComponentProps<typeof Button>['shape']
  size?: React.ComponentProps<typeof Button>['size']
}

const UpvoteArtifactsButton = ({
  linkText,
  ctaLocation,
  variant = 'teal_button',
  shape = 'rounded-none',
  size = 'large'
}: UpvoteArtifactsButtonProps) => {
  const { currentUser, isLoggedIn } = useCurrentUser()

  const handleClick = () => {
    trackCtaClicked({
      text: linkText.toLowerCase(),
      cta_type: 'button',
      destination: CANNY_UPVOTE_TOPICS_URL,
      cta_location: ctaLocation,
      logged_in: isLoggedIn
    })

    // If we handle this with an href, the tracking doesn't fire
    // before the window destination changes.
    window.open(CANNY_UPVOTE_TOPICS_URL, '_blank')
  }

  useEffect(() => {
    /* eslint-disable */
    ;(function (w, d, i, s) {
      function l() {
        if (!d.getElementById(i)) {
          var f = d.getElementsByTagName(s)[0],
            e = d.createElement(s)
          // @ts-ignore
          ;(e.type = 'text/javascript'),
            // @ts-ignore
            (e.async = !0),
            // @ts-ignore
            (e.src = 'https://canny.io/sdk.js'),
            // @ts-ignore
            f.parentNode.insertBefore(e, f)
        }
      }
      // @ts-ignore
      if ('function' != typeof w.Canny) {
        var c = function () {
          // @ts-ignore
          c.q.push(arguments)
        }
        // @ts-ignore
        ;(c.q = []),
          // @ts-ignore
          (w.Canny = c),
          'complete' === d.readyState
            ? l()
            : w.attachEvent
              ? w.attachEvent('onload', l)
              : w.addEventListener('load', l, !1)
      }
    })(window, document, 'canny-jssdk', 'script')
    /* eslint-enable */

    if (isLoggedIn && currentUser) {
      // @ts-ignore
      window.Canny('identify', {
        appID: '657b1c83aaa983d0f8942e06',
        user: {
          email: currentUser.contact.primaryEmail,
          name:
            currentUser.fullName ||
            currentUser.contact.primaryEmail?.match(/(?<name>.*)@/)?.groups?.name ||
            '',
          id: currentUser.id
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Button
      shape={shape}
      variant={variant === 'outline_button' ? 'outline' : 'fill'}
      color={variant === 'outline_button' ? 'default' : 'teal'}
      size={size}
      onClick={handleClick}
      data-canny-link
    >
      {linkText}
      <ArrowUpRight className="ml-2" width={16} height={16} />
    </Button>
  )
}

export default UpvoteArtifactsButton
