import AntiCopyBlock from 'domains/AntiPiracy/AntiCopyBlock'
import Title from 'domains/AntiPiracy/AntiCopyTitle'
import PolicyLink from 'domains/AntiPiracy/PolicyLink'

import Button from 'components/Button'

const AntiCopyViolationThree = ({ reset }: { reset: () => void }) => (
  <>
    <Title>Your account has been locked</Title>

    <AntiCopyBlock>
      We work really hard to create content that helps you do the best work of your
      career, and we have found you to be in violation of our <PolicyLink />. We have
      locked your account, effective immediately. Please reach out to hello@reforge.com if
      you have any questions.
    </AntiCopyBlock>

    <Button className="mx-auto flex px-20" onClick={() => reset()}>
      Okay
    </Button>
  </>
)

export default AntiCopyViolationThree
