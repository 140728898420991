import Button from 'components/Button'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { trackCtaClicked } from 'utils/tracking/analytics'

interface NotesContentGateProps {
  artifact: any
  redirectPath?: string
  sideDrawerTab: string
}

export const NotesContentGate = ({
  artifact,
  redirectPath,
  sideDrawerTab
}: NotesContentGateProps) => {
  const { isLoggedIn, currentUser } = useCurrentUser()
  const destination = redirectPath
    ? `/login?reforgeLogin=true&referer=${encodeURIComponent(redirectPath)}`
    : '/login?reforgeLogin=true'
  const ctaCopy = 'Sign up here'
  let ctaLocation: string
  switch (sideDrawerTab) {
    case 'Notes':
      ctaLocation = 'author notes tab'
      break
    case 'Comments':
      ctaLocation = 'comments tab'
      break
    default:
      ctaLocation = ''
  }

  const premiumDestination = isLoggedIn
    ? `/subscribe/choose-plan?referer=${window.location.pathname}`
    : `/login?reforgeLogin=true&referer=${encodeURIComponent(
        `/subscribe/choose-plan?referer=${window.location.pathname}`
      )}`

  if (!isLoggedIn && artifact?.isFree !== false) {
    return (
      <div className="artifacts-theme rounded-md border border-rb-gray-200 px-[60px] py-[40px] text-center tl:px-[35px] xl:px-[60px]">
        <h3 className="mb-[10px] text-xl font-medium">Want to see more?</h3>
        <p className="text-sm leading-5">
          View all notes, add comments, and more with a Reforge account.{' '}
          <Button
            variant="text-only"
            onClick={() =>
              trackCtaClicked({
                cta_location: ctaLocation,
                cta_type: 'link',
                destination: destination,
                text: ctaCopy.toLowerCase()
              })
            }
            href={destination}
            className="inline-block whitespace-nowrap p-0 font-semibold text-rb-teal-200 no-underline"
          >
            {ctaCopy}
          </Button>
        </p>
      </div>
    )
  }

  const ARTIFACT_GATE_BUTTON_TEXT =
    currentUser?.is.eligibleForTrial || !isLoggedIn
      ? 'Start 14-day free trial'
      : 'Become a member'

  return !artifact?.isFree ? (
    <div className="border border-rb-gray-100 p-6 flex flex-col gap-4">
      <p className="font-semibold text-base leading-[1.6] mb-0">
        View all author notes when you become a member
      </p>

      <Button
        color="premium"
        size="small"
        href={premiumDestination}
        onClick={() =>
          trackCtaClicked({
            cta_location: ctaLocation,
            cta_type: 'button',
            destination: premiumDestination,
            text: 'become a member'
          })
        }
        className="whitespace-nowrap font-bold"
      >
        {ARTIFACT_GATE_BUTTON_TEXT}
      </Button>
    </div>
  ) : null
}

export default NotesContentGate
