import 'froala-editor/js/plugins.pkgd.min.js'
import React from 'react'
import FroalaEditor from 'react-froala-wysiwyg'
import { useGlobalState } from 'state'
import { twMerge } from 'tailwind-merge'

import { FroalaHash, useFroalaConfig } from 'domains/Post/FroalaWrapper/helpers'

import { useTeamMentionSearchLazyQuery } from 'gql'

interface InlineCommentsFroalaWrapperProps {
  model?: string
  updateModel: object
  className?: string
  placeholder?: string
  menuContainerId?: string
  tributeOffset?: string
  disableMentions?: boolean
}

const InlineCommentsFroalaWrapper = ({
  placeholder,
  menuContainerId,
  updateModel,
  className,
  model,
  tributeOffset,
  disableMentions = false
}: InlineCommentsFroalaWrapperProps) => {
  const [envVariables] = useGlobalState('envVariables')
  const froalaKey = envVariables.froalaKey || ''
  const froalaHash: FroalaHash = JSON.parse(envVariables.froalaHash) || {}

  const { config } = useFroalaConfig({
    froalaKey,
    placeholder,
    froalaHash,
    menuContainerId,
    mentionQuery: useTeamMentionSearchLazyQuery,
    configOverwrites: {
      height: 75,
      toolbarButtons: []
    },
    tributeClasses: twMerge('!relative !left-0 !top-[-80px]', tributeOffset),
    disableMentions
  })

  return (
    <div className={className}>
      <FroalaEditor
        tag="textarea"
        config={config}
        model={model}
        onModelChange={updateModel}
      />
    </div>
  )
}

export default InlineCommentsFroalaWrapper
