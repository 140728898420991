import { useState } from 'react'

import { MarketingH2, MarketingH3 } from 'domains/Sanity/marketingSite/textHelpers'

import FullScreenVideoModal from 'components/FullScreenVideoModal'

import { PricingPageContent } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { onEnterKeyPress } from 'utils/keyboard'
import { trackModalDisplayed } from 'utils/tracking/analytics'

import thumbnailImage from 'images/hero-image-membership.png'

interface MembershipFeaturesSectionProps {
  content: PricingPageContent
}

const MembershipFeaturesSection = ({ content }: MembershipFeaturesSectionProps) => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)
  const { isLoggedIn } = useCurrentUser()

  const onVideoClick = () => {
    setIsVideoModalOpen(true)
    trackModalDisplayed({
      category: 'marketing',
      location: 'marketing_pricing_page__membership_features',
      modal_group: 'marketing_pricing_page',
      modal_name: 'membership_info_video',
      logged_in: isLoggedIn
    })
  }

  return (
    <section className="px-4 md:px-[4vw]">
      <div className="mx-auto max-w-[1280px] flex flex-col items-center text-center py-[50px] lg:py-[100px]">
        {isVideoModalOpen && (
          <FullScreenVideoModal
            videoSrc={content.membershipSectionVideo.playbackId || ''}
            videoType="mux"
            opts={{ thumbnailTime: 6 }}
            onClose={() => setIsVideoModalOpen(false)}
            autoplay={true}
          />
        )}

        <MarketingH2 className="mb-2.5">{content.membershipSectionTitle}</MarketingH2>
        <MarketingH3 className="mb-20 lg:max-w-[850px]">
          {content.membershipSectionSubtitle}
        </MarketingH3>

        <div
          onClick={onVideoClick}
          onKeyUp={onEnterKeyPress(onVideoClick)}
          role="button"
          tabIndex={0}
          className="sm:max-w-[480px] md:max-w-[660px] xl:max-w-[820px] flex items-center justify-center relative cursor-pointer mb-8"
        >
          <img src={thumbnailImage} alt="demo video thumbnail" />
          <div className="w-[60px] h-[60px] sm:w-[86px] sm:h-[86px] md:w-[120px] md:h-[120px] xl:w-[160px] xl:h-[160px] rounded-full bg-black/[.6] hover:bg-black/[.8] flex items-center justify-center absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2">
            <PlayArrow className="w-8 h-8 sm:w-12 sm:h-12 md:w-16 md:h-16 xl:w-20 xl:h-20 ml-[10%]" />
          </div>
        </div>
      </div>
    </section>
  )
}

const PlayArrow = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="77"
    height="91"
    viewBox="0 0 77 91"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76.2318 45.4999C76.2376 47.8792 74.9955 50.0873 72.959 51.3178L10.9134 89.2738C8.78329 90.5782 6.11408 90.6276 3.93714 89.403C1.78111 88.1975 0.444006 85.9218 0.440403 83.4516V7.54825C0.444006 5.07809 1.78111 2.80238 3.93714 1.5969C6.11408 0.372297 8.78329 0.421727 10.9134 1.72609L72.959 39.6821C74.9955 40.9125 76.2376 43.1207 76.2318 45.4999Z"
      fill="white"
    />
  </svg>
)

export default MembershipFeaturesSection
