import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'

import { useBookmarkFoldersQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useFirstSigninSource from 'hooks/useFirstSigninSource'
import useURLParams from 'hooks/useURLParams'

import { SwimlaneIndex } from './SwimlaneIndex'
import { SwimlaneViewContainer } from './SwimlaneView'
import { CATEGORY_ALL } from './constants'

export const GuidesPage = () => {
  const { getParam } = useURLParams()
  const category = getParam('category') || CATEGORY_ALL
  const swimlane = getParam('swimlane')
  const { currentUser } = useCurrentUser()
  const {
    currentBookmarkForDropdown,
    isAddToBookmarkFolderModalOpen,
    closeAddToBookmarkFolderModal,
    openAddToBookmarkFolderModal
  } = useOpenAddToBookmarkFolderModal()
  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    closeCreateBookmarkFolderModal,
    openCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()
  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }
  const { data: bookmarkFolderData } = useBookmarkFoldersQuery({ skip: !currentUser })
  const { setCustomSigninSource } = useFirstSigninSource()
  setCustomSigninSource('guides (index)')

  return (
    <>
      {swimlane && (
        <SwimlaneViewContainer
          slug={swimlane}
          openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
        />
      )}
      {!swimlane && (
        <SwimlaneIndex
          category={category}
          openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
        />
      )}

      {currentUser && (
        <AddBookmarkToFolderModal
          isOpen={isAddToBookmarkFolderModalOpen}
          handleClose={closeAddToBookmarkFolderModal}
          bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders}
          openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
          currentBookmarkForDropdown={currentBookmarkForDropdown}
          showCollectionsOnboardingInfo={
            !bookmarkFolderData?.currentUser?.hasSeenCollectionsPrompt
          }
        />
      )}

      {currentUser && (
        <CreateBookmarkFolderModal
          isModalOpen={isCreateBookmarkFolderModalOpen}
          handleClose={closeCreateBookmarkFolderModal}
          currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
          trackingTriggerAction={'bookmark'}
        />
      )}
    </>
  )
}

export default GuidesPage
