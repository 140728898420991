import React from 'react'

import LegacyCourseInProgressThumbnail from '../elements/LegacyCourseInProgressThumbnail'
import LegacyCourseCard, { LegacyCourseCardProps } from './LegacyCourseCard'

export const withCustomThumbnail = (
  Component: React.ComponentType<LegacyCourseCardProps>
) => {
  const WithCustomThumbnail = (props: LegacyCourseCardProps) => {
    return (
      <Component
        {...props}
        customThumbnail={
          <LegacyCourseInProgressThumbnail
            course={props.course}
            cardType={props.cardType}
            cardVariant={props.variant}
            showHostInThumbnail={props.showHostInThumbnail}
          />
        }
      />
    )
  }

  WithCustomThumbnail.displayName = `WithCustomThumbnail(${Component.displayName || Component.name || 'Component'})`

  return WithCustomThumbnail
}

const LegacyCourseCardWithCustomThumbnail = withCustomThumbnail(LegacyCourseCard)

export default LegacyCourseCardWithCustomThumbnail
