import produce from 'immer'
import { Fragment, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import DashboardBookmark from 'domains/Bookmarks/Bookmark'
import { NoneElement } from 'domains/Bookmarks/NoneElements'
import { useBookmarkFiltersForTracking } from 'domains/Bookmarks/utils'
import AllHighlightsListEmptyState from 'domains/Collections/AllHighlightsListEmptyState'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useHandleRemoveFromFolder from 'domains/Collections/hooks/useHandleRemoveFromFolder'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'

import { ErrorMessage } from 'components'
import Loading from 'components/Loading'
import Paginator from 'components/Paginator'
import { CardVariants } from 'components/cards/Content/BaseCard'
import LegacyContentCard from 'components/cards/Content/LegacyContentCard'

import {
  BookmarkFilters,
  BookmarkFolderPartsFragment,
  BookmarkType,
  CreateBookmarkInput,
  ProgramBookmarkPartsFragment,
  UserBookmarksFeedDocument,
  UserBookmarksFeedQuery,
  UserBookmarksFeedQueryVariables,
  useBookmarkFoldersQuery,
  useCreateBookmarkMutation,
  useUserBookmarksFeedQuery
} from 'gql'

import notifyError from 'utils/errorNotifier'
import { trackSavedItemClicked } from 'utils/tracking/generated/events/savedItemClicked'

const BOOKMARKS_PER_PAGE = 30

export const SORT_NOTES_NEW_TO_OLD = 'New to Old'
export const SORT_NOTES_OLD_TO_NEW = 'Old to New'
export const SORT_LESSON_FIRST_TO_LAST = 'Lesson, First to Last'
export const SORT_LESSON_LAST_TO_FIRST = 'Lesson, Last to First'

const sortOptionToQueryArg = (sortOption: string) => {
  switch (sortOption) {
    case SORT_NOTES_NEW_TO_OLD:
      return 'notes_new_to_old'
    case SORT_NOTES_OLD_TO_NEW:
      return 'notes_old_to_new'
    case SORT_LESSON_LAST_TO_FIRST:
      return 'lesson_last_to_first'
    case SORT_LESSON_FIRST_TO_LAST:
      return 'lesson_first_to_last'
    default:
      return 'notes_new_to_old'
  }
}

export interface IFilterData {
  currentPage: number
  selectedType: string
  selectedModuleName: string
  selectedProgramName: string
  searchText: string
  moduleOptions: { [key: string]: string }
  programOptions: { [key: string]: string }
  selectedSortOption: string
  topOffset: number
  sidebarFilterRefreshToggle: boolean
  recentlyDeletedCount?: number
}

interface BookmarksListProps {
  trackingLocation: string
  currentUserId: string
  cmsProgramId?: string
  sort?: string
  filterData: IFilterData
  searchText?: string
  isFilterActive: boolean
  filterableByProgram: boolean
  initialModuleId?: string
  initialModuleName?: string
  initialSectionId?: string
  updateFilterData: (newFilterData: Partial<IFilterData>) => void
  bumpRecentlyDeletedCount: () => void
  resetRecentlyDeletedCount: () => void
  recentlyDeletedCount?: number
  isCohortDashboard?: boolean
  cohortSlug?: string
}

interface FiltersForQueryParams {
  filterData?: IFilterData
  cmsProgramId?: string
  searchText?: string
  initialModuleId?: string
  initialSectionId?: string
}

export const filtersForQuery = ({
  filterData,
  cmsProgramId,
  searchText,
  initialModuleId,
  initialSectionId
}: FiltersForQueryParams) => {
  const bookmarkFilters: BookmarkFilters = {}

  if (searchText && searchText.length > 1) {
    // do not search for only one character
    bookmarkFilters.searchText = searchText
  }

  if (cmsProgramId) {
    bookmarkFilters.cmsProgramId = cmsProgramId
  } else if (
    filterData?.selectedProgramName &&
    filterData.selectedProgramName !== 'All Programs'
  ) {
    bookmarkFilters.cmsProgramId =
      filterData.programOptions[filterData.selectedProgramName]
  }

  if (filterData?.selectedType && filterData.selectedType !== 'All Types') {
    bookmarkFilters.type = `${filterData.selectedType}Bookmark`
  }

  if (initialModuleId) {
    bookmarkFilters.cmsModuleId = initialModuleId
  } else if (
    filterData?.selectedModuleName &&
    filterData.selectedModuleName !== 'All Modules'
  ) {
    bookmarkFilters.cmsModuleId = filterData.moduleOptions[filterData.selectedModuleName]
  }

  if (initialSectionId) {
    bookmarkFilters.cmsSectionId = initialSectionId
  }

  return bookmarkFilters
}

const BookmarksList = ({
  currentUserId,
  filterData,
  updateFilterData,
  cmsProgramId,
  searchText,
  isFilterActive,
  filterableByProgram = false,
  initialModuleId,
  initialModuleName,
  initialSectionId,
  bumpRecentlyDeletedCount,
  recentlyDeletedCount = 0,
  resetRecentlyDeletedCount,
  trackingLocation,
  isCohortDashboard = false,
  cohortSlug
}: BookmarksListProps) => {
  const { typeFilterForTracking, programFilterForTracking, moduleFilterForTracking } =
    useBookmarkFiltersForTracking()

  const { data: bookmarkFolderData } = useBookmarkFoldersQuery()
  const {
    isAddToBookmarkFolderModalOpen,
    openAddToBookmarkFolderModal,
    closeAddToBookmarkFolderModal,
    currentBookmarkForDropdown
  } = useOpenAddToBookmarkFolderModal()
  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    openCreateBookmarkFolderModal,
    closeCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()
  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }
  const { RemoveBookmarkFromFolder } = useHandleRemoveFromFolder({})
  const handleRemoveFromFolder = async (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => {
    const { errors, data } = await RemoveBookmarkFromFolder({
      variables: {
        input: {
          bookmarkId: bookmarkId,
          bookmarkFolderId: bookmarkFolder.id
        }
      }
    })

    if (errors) {
      notifyError(errors)
      return null
    } else {
      return data?.deleteFiledBookmark?.filedBookmark?.bookmarkFolderId
    }
  }

  const handlePagination = (page: number) => {
    if (page !== filterData.currentPage) {
      updateFilterData({ currentPage: page })
      document.getElementById('page')?.scrollTo(0, 0)
    }
  }

  const showNoneElement = () =>
    isFilterActive || initialModuleId ? (
      <div data-test="bookmarks">
        <NoneElement />
      </div>
    ) : (
      <div data-test="bookmarks">
        <AllHighlightsListEmptyState />
      </div>
    )

  const offset = (filterData.currentPage - 1) * BOOKMARKS_PER_PAGE
  const bookmarkFilters = filtersForQuery({
    filterData,
    cmsProgramId,
    searchText,
    initialModuleId,
    initialSectionId
  })
  const variables = {
    userId: currentUserId,
    offset,
    limit: BOOKMARKS_PER_PAGE,
    sort: sortOptionToQueryArg(filterData.selectedSortOption),
    filters: bookmarkFilters
  }
  const [createBookmark] = useCreateBookmarkMutation({
    update(cache, { data }) {
      const existingData = cache.readQuery<
        UserBookmarksFeedQuery,
        UserBookmarksFeedQueryVariables
      >({ query: UserBookmarksFeedDocument, variables })
      const newBookmark = data?.createBookmark?.bookmark

      if (!existingData || !newBookmark) return

      const newData = produce(existingData, (draft) => {
        draft?.user?.bookmarksFeed?.bookmarks.unshift(newBookmark)
      })

      cache.writeQuery<UserBookmarksFeedQuery, UserBookmarksFeedQueryVariables>({
        query: UserBookmarksFeedDocument,
        variables,
        data: newData
      })
    }
  })
  const restoreBookmark = async (bookmark: ProgramBookmarkPartsFragment) => {
    const {
      basedOn,
      cmsProgram,
      cmsModule,
      cmsSection,
      noteBody,
      seconds,
      referenceImageUrl,
      type
    } = bookmark
    const bookmarkInput: CreateBookmarkInput = {
      basedOn: basedOn,
      cmsProgramId: cmsProgram?.id,
      cmsModuleId: cmsModule?.id,
      cmsSectionId: cmsSection?.id,
      noteBody: noteBody,
      seconds: seconds,
      referenceImageUrl: referenceImageUrl,
      type: type
    }
    resetRecentlyDeletedCount()

    try {
      await createBookmark({
        variables: {
          input: bookmarkInput
        },
        refetchQueries: [UserBookmarksFeedDocument]
      })
    } catch (error: unknown) {
      notifyError(error)
    }
  }

  const handleBookmarkClickTracking = (
    bookmark: ProgramBookmarkPartsFragment,
    component?: string
  ) => {
    trackSavedItemClicked({
      location: trackingLocation,
      path: pathname,
      cms_program_name: bookmark?.cmsProgram?.name,
      cms_program_id: bookmark?.cmsProgram?.id,
      cms_module_name: bookmark.cmsModule?.name,
      cms_module_id: bookmark.cmsModule?.id,
      cms_section_name: bookmark.cmsSection?.name,
      cms_section_id: bookmark.cmsSection?.id,
      component,
      program_filter: filterableByProgram
        ? programFilterForTracking(filterData.selectedProgramName)
        : null,
      module_filter:
        initialModuleName || moduleFilterForTracking(filterData.selectedModuleName),
      type_filter: typeFilterForTracking(filterData.selectedType),
      sort_by: filterData.selectedSortOption,
      bookmark_type: bookmark.type
    })
  }

  const { data, loading, error } = useUserBookmarksFeedQuery({
    variables
  })
  const bookmarksCount = data?.user?.bookmarksFeed?.total || 0
  const trueCount = bookmarksCount - recentlyDeletedCount
  const { pathname } = useLocation()

  useEffect(() => {
    if (trueCount < 0) {
      resetRecentlyDeletedCount()
    }
  }, [trueCount, resetRecentlyDeletedCount])

  const onBookmarkDeleted = () => {
    bumpRecentlyDeletedCount()
  }

  const bookmarks = data?.user?.bookmarksFeed?.bookmarks || []

  const getDisplayablePageNumber = (totalPagesCount: number) => {
    const { currentPage } = filterData
    return currentPage > totalPagesCount && filterData.currentPage > 1
      ? currentPage - 1
      : currentPage
  }

  const totalPages = Math.ceil(trueCount / BOOKMARKS_PER_PAGE)

  const shouldDisplayWideContentCard = (bookmark: ProgramBookmarkPartsFragment) => {
    if (
      bookmark.type === BookmarkType.CONCEPTBOOKMARK ||
      bookmark.type === BookmarkType.PROJECTBOOKMARK ||
      bookmark.type === BookmarkType.LESSONBOOKMARK ||
      bookmark.type === BookmarkType.SECTIONBOOKMARK ||
      bookmark.type === BookmarkType.UNITBOOKMARK ||
      bookmark.type === BookmarkType.RESOURCEBOOKMARK
    ) {
      return true
    }
    return false
  }

  if (loading) {
    return <Loading />
  } else if (error) {
    return <ErrorMessage error={error} />
  } else if (trueCount === 0) {
    return showNoneElement()
  }

  return (
    <div data-test="bookmarks">
      <div className="flex flex-col gap-y-5">
        {bookmarks.map((bookmark: ProgramBookmarkPartsFragment) => (
          <Fragment key={`bookmark-${bookmark.id}`}>
            {bookmark.content && shouldDisplayWideContentCard(bookmark) ? (
              <LegacyContentCard
                variant={CardVariants.Horizontal}
                content={bookmark.content}
                onClick={() => handleBookmarkClickTracking(bookmark, 'content_card')}
                bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders}
                handleRemoveFromFolder={handleRemoveFromFolder}
                openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
              />
            ) : (
              <DashboardBookmark
                onMouseDown={handleBookmarkClickTracking}
                bookmark={bookmark}
                onDelete={onBookmarkDeleted}
                onEdit={() => {}}
                restoreBookmark={restoreBookmark}
                showProgramInBreadcrumb={!cmsProgramId}
                isCohortDashboard={isCohortDashboard}
                cohortSlug={cohortSlug}
                bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders}
                openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                handleRemoveFromFolder={handleRemoveFromFolder}
              />
            )}
          </Fragment>
        ))}
      </div>
      {totalPages > 1 && (
        <div className="uk-text-center mb-12">
          <Paginator
            meta={{
              currentPage: getDisplayablePageNumber(totalPages),
              totalPages
            }}
            handlePagination={handlePagination}
          />
        </div>
      )}
      <AddBookmarkToFolderModal
        isOpen={isAddToBookmarkFolderModalOpen}
        handleClose={closeAddToBookmarkFolderModal}
        bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders}
        openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdown}
        showCollectionsOnboardingInfo={
          !bookmarkFolderData?.currentUser?.hasSeenCollectionsPrompt
        }
      />
      <CreateBookmarkFolderModal
        isModalOpen={isCreateBookmarkFolderModalOpen}
        handleClose={closeCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
        trackingTriggerAction={'bookmark'}
      />
    </div>
  )
}
export default BookmarksList
