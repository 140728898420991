import { useEffect, useState } from 'react'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import LessonViewer from 'pages/LessonViewer'

import { CohortConversationContainer } from 'domains/CohortConversation'
import {
  CohortDashboardBookmarks,
  CohortDashboardHeader,
  CohortDashboardLeftSideNav,
  CohortDashboardMyTeam,
  CohortDashboardNotifications,
  CohortDashboardToday
} from 'domains/CohortDashboard'
import { CohortDashboardEventPage } from 'domains/CohortDashboard/CohortDashboardProgram'
import { CohortDashboardTrackingContext } from 'domains/CohortDashboard/hooks/useCohortDashboardTrackingContext'

import Loading from 'components/Loading'
import NpsBanner, {
  getNPSBannerDismissalFlag
} from 'components/banners/TopBanner/NpsBanner'

import { MIN_WIDTH_TAILWIND_LG } from 'constants/breakpoints'

import { useCohortViewerNewQuery } from 'gql'

import useLatestActiveProgram from 'hooks/useLatestActiveProgram'
import useMediaQuery from 'hooks/useMediaQuery'

import { onEnterKeyPress } from 'utils/keyboard'
import {
  makeCohortEventDetailsUrl,
  makeConversationUrl,
  makeLessonViewerUrl
} from 'utils/url'

const CohortDashboardPage = () => {
  const {
    slug,
    topicSlug,
    postId,
    eventId,
    eventSlug,
    cmsProgramSlug,
    cmsModuleSlug,
    cmsSectionParentSlug,
    cmsSectionSlug
  } = useParams<{
    slug: string
    topicSlug: string
    postId: string
    eventId: string
    eventSlug: string
    cmsProgramSlug: string
    cmsModuleSlug: string
    cmsSectionParentSlug: string
    cmsSectionSlug: string
  }>()
  const isDesktop = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_LG})`)
  const { data, loading } = useCohortViewerNewQuery({ variables: { slug: slug } })
  const [showSidebar, setShowSidebar] = useState(isDesktop)
  const [isLessonViewer, setIsLessonViewer] = useState(false)
  const history = useHistory()

  // if we're on the home page, update the User cache with the user's latest program progress
  useLatestActiveProgram([`/cohorts/${slug}`])

  useEffect(() => {
    setShowSidebar(isDesktop && !isLessonViewer)
  }, [isDesktop, isLessonViewer])

  useEffect(() => {
    // if we're not on a lesson page, show the sidebar
    if (!history.location.pathname.includes('/c/')) {
      setIsLessonViewer(false)
    } else {
      setIsLessonViewer(true)
    }
  }, [history.location.pathname])

  if (loading) return <Loading />
  if (!data) return null

  const showNpsSurveyBanner =
    data.cohort.hasCohortOffboardingStarted &&
    data.cohort.shouldShowNpsSurvey &&
    data.userCohort?.user.shouldShowNpsSurvey &&
    window.localStorage.getItem(getNPSBannerDismissalFlag(data.cohort.season)) !== '1'

  return (
    <CohortDashboardTrackingContext.Provider
      value={{
        cohort: data.cohort,
        userCohortId: data.userCohort ? data.userCohort.id : null
      }}
    >
      <div
        className={twMerge(
          'flex h-screen flex-col',
          !isLessonViewer && 'overflow-hidden'
        )}
      >
        <CohortDashboardHeader
          cohort={data.cohort}
          toggleSidebar={() => setShowSidebar((prevState: boolean) => !prevState)}
          isLessonViewer={isLessonViewer}
        />

        <div
          className={twMerge(
            'relative flex flex-1',
            !isLessonViewer && 'overflow-hidden',
            isLessonViewer && 'mt-[68px]'
          )}
        >
          <CohortDashboardLeftSideNav
            cohort={data.cohort}
            cohortTeams={data.cohortTeams}
            slug={slug}
            postId={postId}
            showSidebar={showSidebar}
            isLessonViewer={isLessonViewer}
            toggleSidebar={() => {
              if (!isDesktop) {
                setShowSidebar(false)
              }
            }}
          />
          <div className="flex w-full flex-col">
            {showNpsSurveyBanner && (
              <NpsBanner
                page={window.location.pathname}
                liveProgramName={data?.cohort?.cmsProgram?.name}
                userId={data?.userCohort?.user?.id || ''}
                season={data?.cohort?.season}
              />
            )}

            {/* Gray overlay when sidebar is open */}
            {showSidebar && (!isDesktop || isLessonViewer) && (
              <div
                tabIndex={0}
                role="button"
                className="fixed z-40 h-screen w-screen bg-black/50"
                onClick={() => setShowSidebar(false)}
                onKeyUp={onEnterKeyPress(() => setShowSidebar(false))}
              />
            )}

            <Switch>
              <Route
                exact
                path={[`/cohorts/${slug}`, `/cohorts/${slug}/end`]}
                render={() => <CohortDashboardToday slug={slug} />}
              />
              <Route
                path={[
                  makeConversationUrl(slug, topicSlug),
                  makeConversationUrl(slug, topicSlug, postId)
                ]}
                render={() => (
                  <CohortConversationContainer
                    slug={slug}
                    topicSlug={topicSlug}
                    threadPostId={postId}
                    cohort={data.cohort}
                  />
                )}
              />
              <Route
                path={`/cohorts/${slug}/bookmarks`}
                render={() => (
                  <CohortDashboardBookmarks
                    cmsProgramId={data?.cohort?.cmsProgram?.id}
                    cmsProgramName={data?.cohort?.cmsProgram?.name}
                    cohortSlug={slug}
                  />
                )}
              />
              <Route
                path={`/cohorts/${slug}/notifications`}
                render={() => <CohortDashboardNotifications slug={slug} />}
              />
              <Route
                path={[
                  `${makeCohortEventDetailsUrl(slug, eventId, eventSlug)}/summary`,
                  makeCohortEventDetailsUrl(slug, eventId, eventSlug)
                ]}
                render={() => (
                  <CohortDashboardEventPage eventId={eventId} cohortSlug={slug} />
                )}
              />
              <Route
                path={makeLessonViewerUrl(
                  slug,
                  cmsProgramSlug,
                  cmsModuleSlug,
                  cmsSectionParentSlug,
                  cmsSectionSlug
                )}
                render={() => (
                  <LessonViewer
                    cmsProgramSlug={cmsProgramSlug}
                    cmsModuleSlug={cmsModuleSlug}
                    cmsSectionSlug={cmsSectionSlug}
                    cmsSectionParentSlug={cmsSectionParentSlug}
                    fromCohortViewer
                    cohortSlug={slug}
                  />
                )}
              />
              <Route
                path={makeLessonViewerUrl(
                  slug,
                  cmsProgramSlug,
                  cmsModuleSlug,
                  cmsSectionSlug
                )}
                render={() => (
                  <LessonViewer
                    cmsProgramSlug={cmsProgramSlug}
                    cmsModuleSlug={cmsModuleSlug}
                    cmsSectionSlug={cmsSectionSlug}
                    fromCohortViewer
                    cohortSlug={slug}
                  />
                )}
              />
              <Route
                path={`/cohorts/${slug}/my-team`}
                render={() => (
                  <CohortDashboardMyTeam
                    cohort={data.cohort}
                    cohortTeam={data.cohortTeams?.[0]}
                  />
                )}
              />
            </Switch>
          </div>
        </div>
      </div>
    </CohortDashboardTrackingContext.Provider>
  )
}

export default CohortDashboardPage
