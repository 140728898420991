// import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { InputHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'
import { ReactComponent as ChevronDown } from 'thin-chevron-down.svg'

interface MarketingSelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  options: (
    | string
    | {
        value: string
        label: string
        disabled?: boolean
      }
  )[]
}

const MarketingSelect = ({ options, className, ...attributes }: MarketingSelectProps) => {
  return (
    <div className="relative">
      <select
        className={twMerge(
          'peer h-[50px] w-full appearance-none border border-rb-gray-200 p-[12px] pr-[40px] font-serif text-base opacity-70 hover:opacity-100 focus:bg-rb-white focus:opacity-100',
          className
        )}
        {...attributes}
      >
        {options.map((option) => {
          if (typeof option === 'string') {
            return (
              <option key={option} value={option}>
                {option}
              </option>
            )
          }

          return (
            <option key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </option>
          )
        })}
      </select>

      <ChevronDown className="absolute right-[10px] top-1/2 w-[24px] -translate-y-1/2 opacity-70 peer-hover:opacity-100 peer-focus:opacity-100" />
    </div>
  )
}

export default MarketingSelect
