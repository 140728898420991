import Button from 'components/Button'
import Modal from 'components/Modal'

import { MIN_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import useMediaQuery from 'hooks/useMediaQuery'

import { cn } from 'utils/tailwind'

export interface ConfirmRequestDeniedModalProps {
  isModalOpen: boolean
  onClose: () => void
  onDenyRequest: () => void
}

export function ConfirmRequestDeniedModal({
  isModalOpen,
  onClose,
  onDenyRequest
}: ConfirmRequestDeniedModalProps) {
  const isNotSmall = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_XS})`)

  return (
    <Modal
      isOpen={isModalOpen}
      handleClose={onClose}
      className="rounded-lg max-w-[728px]"
    >
      <div
        data-testid="confirm-deny-join-request-modal"
        className={cn(
          'pb-8 flex flex-col items-center max-w-[480px] mx-auto',
          isNotSmall && 'pb-[100px]'
        )}
      >
        <div className={cn('pb-4 text-center text-xl font-medium', isNotSmall && 'pb-8')}>
          Are you sure you want to deny this request?
        </div>
        <div
          className={cn(
            'flex flex-col items-center',
            isNotSmall && 'flex-row justify-center'
          )}
        >
          <Button
            size="medium"
            variant="outline"
            className={cn('w-[200px] h-[40px] mb-5', isNotSmall && 'mr-4 mb-0')}
            onClick={onClose}
          >
            No, cancel
          </Button>
          <Button
            data-testid="confirm-deny-join-request"
            size="medium"
            className="w-[200px] h-[40px]"
            onClick={onDenyRequest}
          >
            Yes, deny request
          </Button>
        </div>
      </div>
    </Modal>
  )
}
