import { SVGIcon } from 'components/Icon'

interface IProgressCircle {
  percentComplete: number
}

const ProgressCircle = ({ percentComplete }: IProgressCircle) => {
  const roundedPercentComplete = Math.round(percentComplete)

  return (
    <div
      uk-tooltip={`title: ${roundedPercentComplete}% complete; cls: uk-active rf-tooltip-up-arrow-tip; pos: bottom`}
      className={`flex h-full w-full items-center justify-center rounded-full border ${
        roundedPercentComplete === 0 ? 'border-rb-gray-100' : 'border-rb-green-100'
      }`}
    >
      <div
        style={{
          backgroundImage: `conic-gradient(#CADD48 ${roundedPercentComplete}%, #fff ${roundedPercentComplete}%)`
        }}
        className="flex h-4/5 w-4/5 items-center justify-center rounded-full"
      >
        {roundedPercentComplete === 100 && (
          <SVGIcon height="14" width="14" name="icon-checkmark" fill="white" />
        )}
      </div>
    </div>
  )
}

export default ProgressCircle
