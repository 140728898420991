export const IDENTIFIER = 'highlight-id'
export const DATASET_SPLIT_METHOD = 'highlight-split-method'
export const ROOT_INDEX = -2

export interface DomMeta {
  parentTagName: string
  parentIndex: number
  textOffset: number
}

export interface HighlightMetadata {
  startMeta: DomMeta
  endMeta: DomMeta
  text: string
  id?: string
  allTextNodes: boolean
}

export interface HighlightRange {
  start: DomNode
  end: DomNode
  text: string
  id: string
  allTextNodes: boolean
}

export interface DomNode {
  $node: Node
  offset: number
}

export interface SelectedNode {
  $node: Node | Text
  type: SelectedNodeType
  splitType: SplitType
}

export enum SplitType {
  none = 'none',
  head = 'head',
  tail = 'tail',
  both = 'both'
}
export enum SelectedNodeType {
  text = 'text',
  span = 'span'
}
