import { FC, ReactElement, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'

import { QueryParamEventFilters } from 'pages/EventsPage'

import { EventCardsGrid } from 'domains/Event/EventCardsGrid/EventCardsGrid'
import { noPastElement } from 'domains/Event/EventNoneElement'
import { getSelectedEventTypes } from 'domains/Event/utils/getSelectedEventTypes'

import { ErrorMessage, Loading } from 'components'
import { VerticalSkeletonArtifactCardContainer } from 'components/skeletons/cards'
import SkeletonRfParagraph from 'components/skeletons/typography/SkeletonRfParagraph'

import {
  EventCurrentUserPartsFragment,
  EventFilters,
  EventPartsFragment,
  useEventsPastFeedQuery,
  useUserEventsPastFeedQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { cn } from 'utils/tailwind'

interface PastEventsListContainerProps {
  user: EventCurrentUserPartsFragment
  filters: { eventType: QueryParamEventFilters; input: string | undefined }
  maxCards?: number
  header?: ReactElement
  forUser?: boolean
}

const PastEventsListContainer: FC<PastEventsListContainerProps> = ({
  user,
  filters,
  maxCards,
  forUser = false,
  header
}) => {
  const selectedEventTypes = getSelectedEventTypes(filters?.eventType)
  const [pastEvents, setPastEvents] = useState<EventPartsFragment[]>([])
  const [count, setcount] = useState<number>(0)

  const variables = {
    userId: user?.id,
    limit: 20,
    offset: 0,
    filters: {
      kind: selectedEventTypes || [],
      userInputFilter: filters?.input || ''
    } as EventFilters
  }

  const {
    loading: loadingPastFeed,
    error: pastFeedError,
    data: eventPastFeedData,
    fetchMore: fetchMorePastFeed
  } = useEventsPastFeedQuery({
    variables,
    skip: forUser === true
  })

  const {
    loading: loadingUserPastFeed,
    error: userPastFeedError,
    data: userPastFeedData,
    fetchMore: fetchMoreUserPastFeed
  } = useUserEventsPastFeedQuery({
    variables,
    skip: forUser === false
  })

  async function handleLoadMore() {
    const variables = {
      userId: user?.id,
      limit: 20,
      offset: pastEvents.length
    }

    let results: any = {
      events: [],
      count: 0
    }

    if (forUser) {
      const {
        data: { userEventsPastFeed }
      } = await fetchMoreUserPastFeed({
        variables
      })
      results = userEventsPastFeed
    } else {
      const {
        data: { eventsPastFeed }
      } = await fetchMorePastFeed({
        variables
      })
      results = eventsPastFeed
    }

    setPastEvents((prev) => [...prev, ...results.events])
    setcount(results.count || 0)
  }

  useEffect(() => {
    if (userPastFeedData || eventPastFeedData) {
      const data = forUser
        ? userPastFeedData?.userEventsPastFeed
        : eventPastFeedData?.eventsPastFeed

      setPastEvents(data?.events || [])
      setcount(data?.count || 0)
    }
  }, [userPastFeedData, eventPastFeedData, forUser])

  if (loadingPastFeed || loadingUserPastFeed) {
    return (
      <>
        <div className="flex items-center">
          <SkeletonRfParagraph className="mb-4 mr-4 h-5 max-w-[200px]" />
          <SkeletonRfParagraph className="mb-4 h-3 w-[80px]" />
        </div>

        <div className="grid grid-cols-[repeat(auto-fill,minmax(252px,1fr))] gap-[30px] gap-y-[40px] px-0 pb-0 xl:grid-cols-[repeat(auto-fill,minmax(360px,1fr))]">
          {[...Array(maxCards || 12)].map((_, index) => (
            <VerticalSkeletonArtifactCardContainer key={index} />
          ))}
        </div>
      </>
    )
  }

  if (pastFeedError || userPastFeedError) {
    return <ErrorMessage error={pastFeedError || userPastFeedError} />
  }

  return (
    <>
      {header}
      <PastEventsList
        events={pastEvents || []}
        eventsTotalCount={count}
        user={user}
        fetchMore={handleLoadMore}
        maxCards={maxCards}
      />
    </>
  )
}

const PastEventsList = ({
  events,
  eventsTotalCount,
  user,
  fetchMore,
  maxCards
}: {
  events: EventPartsFragment[]
  eventsTotalCount: number
  user: EventCurrentUserPartsFragment
  fetchMore: any
  maxCards?: number
}) => {
  const { isLoggedIn } = useCurrentUser()

  if (!events.length) return noPastElement
  const preSelectedEvents = events.slice(0, maxCards)

  const WithInfiniteScroll = () => (
    <InfiniteScroll
      loadMore={fetchMore}
      hasMore={eventsTotalCount > events.length}
      loader={<Loading key={0} />}
      useWindow={false}
    >
      <EventCardsGrid events={events} user={user} />
    </InfiniteScroll>
  )
  // infinite scroll requires a container else it will load every single record
  if (maxCards) {
    return <EventCardsGrid events={preSelectedEvents} user={user} />
  } else {
    return (
      <div
        className={cn('h-screen overflow-auto', !isLoggedIn && '!h-[90vh] w-full pb-14')}
      >
        <WithInfiniteScroll />
      </div>
    )
  }
}

export default PastEventsListContainer
