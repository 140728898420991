import PropTypes from 'prop-types'
import { useEffect, useReducer } from 'react'
import UIkit from 'uikit'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'
import StyledCheckbox from 'components/StyledCheckbox'

import { StripePaymentMethod } from 'gql'

import { sendData } from 'utils/sendData'
import { capitalizeFirstLetter } from 'utils/stringUtils'

import { LegacyUpdatePaymentMethodPayload } from 'typings/payloads'

export default function EditPaymentMethodModal({
  setIsOpenAddPaymentMethodModal,
  paymentMethod,
  afterUpdate
}: {
  setIsOpenAddPaymentMethodModal: (isOpen: boolean) => void
  paymentMethod: StripePaymentMethod | null
  afterUpdate?: () => void
}) {
  const initialState = {
    loading: false,
    defaultPaymentMethod: false,
    expiryDate: '',
    paymentMethodUpdated: false,
    errorMessage: null
  }
  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    initialState
  )

  useEffect(() => {
    const modal = document.getElementById('modal-edit-payment-method')
    if (modal) {
      const { util } = UIkit as any

      util.on(modal, 'hidden', function () {
        setState(initialState)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updatePaymentMethod = () => {
    setState({ loading: true })
    const data: LegacyUpdatePaymentMethodPayload = {
      expiry_date: state.expiryDate,
      default_payment_method: state.defaultPaymentMethod,
      payment_method_id: paymentMethod?.id
    }
    sendData(
      `/api/v1/stripe_payments/payment_methods/${paymentMethod?.id}`,
      'PATCH',
      data,
      (errorMessage: any, responseText: any) => {
        if (errorMessage) {
          setState({ loading: false, errorMessage: errorMessage.errors })
          return
        }
        if (responseText) {
          if (afterUpdate) afterUpdate()
          setState({ loading: false, paymentMethodUpdated: true, errorMessage: null })
        }
      }
    )
  }

  const openAddPaymentMethodModal = () => {
    setIsOpenAddPaymentMethodModal(true)
  }

  const canUpdateCard = () => {
    if (!paymentMethod?.defaultPaymentMethod) {
      return (
        !state.loading &&
        (state.defaultPaymentMethod || (state.expiryDate && state.expiryDate.length > 0))
      )
    } else {
      return !state.loading && state.expiryDate && state.expiryDate.length > 0
    }
  }

  const thClassName =
    'tl:!py-[7px] tl:!px-3 !py-1 !px-1.5 !text-xs !font-medium !text-rb-gray-300 !tracking-widest'
  const tdClassName = 'tl:!py-[7px] tl:!px-3 !py-1 !px-1.5'

  return (
    <div id="modal-edit-payment-method" uk-modal={'{"container": false}'}>
      {paymentMethod && (
        <div className="uk-modal-dialog uk-modal-body">
          {!state.paymentMethodUpdated && (
            <>
              <h4 className="text-lg font-medium text-black tl:text-2xl">
                Update payment method
              </h4>
              <p className="m-0 text-m-small font-medium sm:text-m-medium">
                Enter a new expiration date, change primary payment method, or{' '}
                <a className="uk-modal-close" onClick={openAddPaymentMethodModal}>
                  add a new card
                </a>
                .
              </p>
              <table className="uk-table uk-table-striped uk-table-small uk-margin-small text-m-small text-rb-gray-300 sm:text-m-medium tl:text-sm">
                <thead>
                  <tr>
                    <th className={thClassName}>Type</th>
                    <th className={thClassName}>Brand</th>
                    <th className={thClassName}>Last&nbsp;4</th>
                    <th className={thClassName}>Expires</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={tdClassName}>
                      {capitalizeFirstLetter(paymentMethod?.type || '')}
                    </td>
                    <td className={tdClassName}>
                      {capitalizeFirstLetter(paymentMethod?.card?.brand || '')}
                    </td>
                    <td className={tdClassName}>{paymentMethod.card?.last4}</td>
                    <td className={tdClassName}>
                      <input
                        type="text"
                        className="uk-input"
                        name="expiry-date-input"
                        style={{ width: '80px', height: '30px' }}
                        placeholder="MM/YYYY"
                        value={state.expiryDate}
                        onChange={(e) => setState({ expiryDate: e.target.value })}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              {state.errorMessage && (
                <div className="uk-alert uk-alert-danger uk-margin-small">
                  {state.errorMessage}
                </div>
              )}
              <div className="uk-margin-top flex-end flex flex-wrap items-center justify-end">
                {!paymentMethod.defaultPaymentMethod && (
                  <label
                    className="mb-2.5 flex-auto flex-1 lg:flex lg:items-center"
                    onClick={() => {
                      setState({ defaultPaymentMethod: !state.defaultPaymentMethod })
                    }}
                  >
                    <StyledCheckbox checked={state.defaultPaymentMethod} />
                    Set as primary payment method
                  </label>
                )}

                <Button
                  size="small"
                  variant="text-only"
                  disabled={state.loading}
                  className="uk-modal-close"
                >
                  Cancel
                </Button>

                <Button
                  size="small"
                  disabled={!canUpdateCard()}
                  onClick={updatePaymentMethod}
                >
                  Update Card
                </Button>
              </div>
            </>
          )}
          {state.paymentMethodUpdated && (
            <>
              <div className="flex justify-end">
                <a
                  className="uk-icon-link uk-modal-close-default"
                  uk-icon="icon: close;"
                />
              </div>
              <div className="uk-text-center mt-[40px] text-xl text-rb-gray-300 tl:mt-[30px]">
                <SVGIcon
                  name="check-slim"
                  height="42"
                  width="55"
                  fill="#000000"
                  className="uk-margin-small-bottom uk-margin-top mx-auto"
                />
                <div className="uk-margin" style={{ marginBottom: '40px' }}>
                  <p>Your payment method has been updated</p>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

EditPaymentMethodModal.propTypes = {
  paymentMethod: PropTypes.object,
  afterUpdate: PropTypes.func
}
