import React, { useState } from 'react'
import { twJoin } from 'tailwind-merge'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { identify } from 'utils/tracking/analytics'
import { trackMarketingActionCompleted } from 'utils/tracking/generated/events/marketingActionCompleted'
import { trackMarketingFormSubmitted } from 'utils/tracking/generated/events/marketingFormSubmitted'

import ButtonMarketingCTA from '../ButtonMarketingCTA'
import MarketingInput from '../MarketingInput'

const BlogSubscribeEmailSection = () => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { isLoggedIn } = useCurrentUser()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setErrorMessage(null)
    setSuccessMessage(null)
    e.preventDefault()
    const emailInput = document.querySelector(
      'input[name="email"]'
    ) as HTMLInputElement | null
    const email = emailInput?.value || ''

    if (!email) {
      setErrorMessage('Please enter an email address.')
      return
    }

    const response = await fetch(
      `//links.iterable.com/lists/publicAddSubscriberForm?publicIdString=${process.env.REACT_APP_ITERABLE_NEWSLETTER_ID}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({ email }).toString()
      }
    )
    if (response.ok) {
      setSuccessMessage('Success! Thank you for subscribing.')
      if (emailInput) {
        emailInput.value = ''
      }
      trackFormSubmission(email)
    } else {
      setErrorMessage('Something went wrong. Please try again.')
    }
  }

  const trackFormSubmission = (email: string) => {
    const formFields = { email }

    // legacy Segment event -- need to keep around for LinkedIn and other fun things
    // must keep around until data & marketing states that we're allowed to remove it
    trackMarketingActionCompleted({
      action_type: 'subscribe',
      email,
      list: process.env.REACT_APP_ITERABLE_NEWSLETTER_ID!,
      location: '/blog',
      page_url: window.location.href,
      type: 'newsletter sign up form'
    })

    // NEW - generic "Marketing Form Submitted"
    trackMarketingFormSubmitted({
      form_fields: formFields,
      email,
      form_id: process.env.REACT_APP_ITERABLE_NEWSLETTER_ID!,
      form_type: 'iterable',
      form_name: 'subscribe',
      location: '/blog',
      url: window.location.href,
      source: 'marketing site',
      type: 'page'
    })

    // identify users who haven't been identified yet
    if (!isLoggedIn) {
      identify(undefined, { email: email })
    }
  }
  return (
    <section className="mb-[40px] flex flex-col items-center bg-rb-background-beige px-[16px] pt-[40px] pb-[15px] text-center md:px-[4vw]">
      <h4 className="mb-[32px] !font-serif font-light tracking-normal fluid-text-2xl">
        Stay Up to Date with Reforge
      </h4>

      <p className="mb-[32px] fluid-text-base">
        Get access to our experts, weekly drops of new content and exclusive industry
        insights—all delivered straight to your inbox.
      </p>

      <form
        name="iterable_optin"
        action={`//links.iterable.com/lists/publicAddSubscriberForm?publicIdString=${process.env.REACT_APP_ITERABLE_NEWSLETTER_ID}`}
        target="_blank"
        method="POST"
        className="flex w-full max-w-[380px] flex-col items-start"
        onSubmit={handleSubmit}
      >
        <label htmlFor="subscription-form" className="mb-[10px] font-serif fluid-text-sm">
          Email Address <span className="fluid-text-xs">(required)</span>
        </label>

        {/* <div className="flex"> */}
        <MarketingInput
          type="email"
          name="email"
          id="subscription-form"
          className="mb-[16px] transition-opacity duration-200"
        />
        <ButtonMarketingCTA type="submit">Sign Up</ButtonMarketingCTA>
        {/* </div> */}
        <p
          className={twJoin(
            'mt-4 h-[16px] fluid-text-sm',
            successMessage && 'text-rb-success',
            errorMessage && 'text-rb-destructive'
          )}
        >
          {successMessage || errorMessage || ''}
        </p>
      </form>
    </section>
  )
}

export default BlogSubscribeEmailSection
