import { twMerge } from 'tailwind-merge'

import { currencyOptions, locale } from 'components/CheckoutModal/utils'

interface OrderRecapPriceProps {
  price: number
  className?: string
}

export const OrderRecapPrice = ({ price, className }: OrderRecapPriceProps) => {
  return (
    <span className={twMerge('text-right', className)}>
      {price.toLocaleString(locale, currencyOptions)}
    </span>
  )
}

export default OrderRecapPrice
