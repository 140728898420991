import { CclGuide, RecommendedContentResults, Unit } from 'gql'

import DefaultThumbnailSrc from 'assets/images/guide-default.svg'

export const guideThumbnailSrc = (guide: Unit | RecommendedContentResults | CclGuide) => {
  if (
    guide.__typename === 'RecommendedContentResults' ||
    guide.__typename === 'CclGuide'
  ) {
    return guide.thumbnailUrl || DefaultThumbnailSrc
  }
  return guide.heroImage?.imageUrl || DefaultThumbnailSrc
}
