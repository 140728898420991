import { FC, useEffect } from 'react'

import {
  trackMarketingActionCompleted,
  trackMarketingFormSubmitted
} from 'utils/tracking/analytics'

export const OptInMonsterGlobalCampaign: FC = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      /* This site is converting visitors into subscribers and customers with OptinMonster - https://optinmonster.com */
      const script = document.createElement('script')
      script.src = 'https://a.omappapi.com/app/js/api.min.js'
      script.type = 'text/javascript'
      script.async = true
      script.dataset.user = '33761'
      script.dataset.account = '24398'
      document.body.appendChild(script)
    }
  }, [])

  useEffect(() => {
    const onSubmitHandler = (event: any) => {
      // Grab the email address submitted by the user.
      const email = event.detail.Optin.data.fields.email
      const campaignId = event.detail.Campaign.data.id
      const campaignName = event.detail.Campaign.data.name
      const location = `/${window.location.pathname.split('/')?.[1] || ''}`

      trackMarketingActionCompleted({
        action_type: 'subscribe',
        email,
        list: process.env.REACT_APP_ITERABLE_NEWSLETTER_ID,
        location: `${location} optinmonster popup (campaign ID: ${campaignId})`,
        page_url: window.location.href,
        type: 'newsletter sign up form',
        name: campaignName
      })

      // NEW - generic "Marketing Form Submitted"
      trackMarketingFormSubmitted({
        form_fields: { email },
        email,
        form_id: process.env.REACT_APP_ITERABLE_NEWSLETTER_ID || '',
        form_type: 'iterable',
        form_name: campaignId,
        location: `${location} optinmonster popup (campaign ID: ${campaignId})`,
        source: 'marketing site',
        type: 'page',
        url: window.location.pathname
      })
    }

    document.addEventListener('om.Optin.init.submit', onSubmitHandler)

    return () => {
      document.removeEventListener('om.Optin.init.submit', onSubmitHandler)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
