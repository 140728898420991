import { Link } from 'react-router-dom'

import CalendarIcon from 'components/icons/CalendarIcon'
import ChevronRightThinIcon from 'components/icons/ChevronRightThinIcon'

import { FULL_DATE_FORMAT, formatInTimezone } from 'utils/date'

interface IFullWidthBlogPostCard {
  seasonName: string
  startDate: string
  linkAction: () => void
  timezone: string | null | undefined
}

const FullWidthBlogPostCard = ({
  seasonName,
  startDate,
  linkAction,
  timezone
}: IFullWidthBlogPostCard) => {
  return (
    <div
      className="full-width-card rf-rb-card-interactive flex flex-row"
      onClick={linkAction}
    >
      <div style={{ width: '7px' }} className="bg-rb-green-400" />
      <div className="mx-4 mt-5">
        <CalendarIcon className="h-6 w-6 fill-current" />
      </div>
      <div className="my-5 mr-5 flex-1">
        <div className="font-bold">Coming soon to Reforge</div>
        <div className="my-1 text-sm">{seasonName} Cohort</div>
        <div className="flex flex-row justify-between">
          <div className="text-sm">
            Begins {formatInTimezone(startDate, timezone, FULL_DATE_FORMAT)}
          </div>
          <div className="flex flex-row items-center">
            <Link
              target="_blank"
              rel="noreferrer"
              to="/courses"
              className="mr-3 text-xs font-medium uppercase tracking-widest"
            >
              Learn More
            </Link>
            <ChevronRightThinIcon className="h-4 w-2 fill-current" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullWidthBlogPostCard
