import ButtonMarketingCTA from 'domains/Sanity/marketingSite/ButtonMarketingCTA'
import { MarketingH2 } from 'domains/Sanity/marketingSite/textHelpers'

import { HomepageContent } from 'gql'

const JoinCtaSection = ({ content }: { content: HomepageContent }) => {
  return (
    <section className="bg-rb-background-beige px-4 md:px-[4vw] py-[50px] sm:py-[75px] lg:py-[100px] text-center">
      <div className="mx-auto max-w-[1280px] flex flex-col items-center gap-9">
        <MarketingH2 className="w-full mb-0 sm:max-w-[440px] md:max-w-none lg:max-w-[940px] 2xl:max-w-none">
          {content.bottomTitle}
        </MarketingH2>

        <ButtonMarketingCTA
          href={content.bottomCta.ctaLink}
          location="marketing_homepage__expert_cta_section"
        >
          {content.bottomCta.ctaText}
        </ButtonMarketingCTA>
      </div>
    </section>
  )
}
export default JoinCtaSection
