import { HeaderTooltip } from '../HeaderTooltip'

interface TeamMetricsProps {
  contentViewedCount: number
  insightsSavedCount: number
  liveCourseEnrollmentCount: number
  coursesWorkedOnCount: number
}

// team-engagement/summary

export const TeamMetrics = ({
  contentViewedCount,
  insightsSavedCount,
  liveCourseEnrollmentCount,
  coursesWorkedOnCount
}: TeamMetricsProps) => {
  return (
    <div className="flex flex-wrap justify-between">
      <TeamMetricCard
        count={liveCourseEnrollmentCount}
        copy="Live Course Enrollments"
        tooltip="# of live course enrollments within past year"
      />
      <TeamMetricCard
        count={coursesWorkedOnCount}
        copy="Courses Worked on"
        tooltip="# of courses started within past year"
      />
      <TeamMetricCard
        count={contentViewedCount}
        copy="Content Viewed"
        tooltip="# of content items viewed within past year"
      />
      <TeamMetricCard
        count={insightsSavedCount}
        copy="Insights Saved"
        tooltip="# of content items bookmarked within past year"
      />
    </div>
  )
}

interface TeamMetricCardProps {
  count: number
  copy: string
  tooltip?: string
}

const TeamMetricCard = ({ count, copy, tooltip }: TeamMetricCardProps) => {
  return (
    <div className="relative mb-6 flex h-32 w-[calc(50%-12px)] items-center rounded-lg border px-4 lg:w-[calc(25%-16px)] lg:px-8">
      <div>
        <h1 className="mb-0 text-4xl font-medium text-rb-teal-600 lg:text-[44px]">
          {count}
        </h1>
        <div className="m-0 w-11/12 text-xs lg:mt-2 lg:w-full">
          {copy}
          {!!tooltip && <HeaderTooltip tooltip={tooltip} />}
        </div>
      </div>
    </div>
  )
}
