import dayjs from 'dayjs'
import { ReactNode } from 'react'

import Image from 'domains/Sanity/Image'

import Button from 'components/Button'

import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'

import useMediaQuery from 'hooks/useMediaQuery'

import { trackCtaClicked } from 'utils/tracking/analytics'

import { SEOTrackingRelatedIdentifiers } from '../helpers'

export interface SeoPageHeroProps {
  title: string | ReactNode
  subtitle: string | ReactNode
  updatedAt?: string | null
  ctaText: string
  ctaHref: string
  bgImage: string
  bgImageMobile: string
  tracking?: SEOTrackingRelatedIdentifiers
}

export const SeoPageHero = ({
  title,
  subtitle,
  updatedAt,
  ctaText,
  ctaHref,
  bgImage,
  bgImageMobile,
  tracking
}: SeoPageHeroProps) => {
  const isMobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)

  const onCtaClick = () => {
    trackCtaClicked({
      cta_type: 'button',
      destination: ctaHref,
      cta_location: 'SEO Hero',
      text: ctaText,
      related_identifiers: {
        sanity_type: tracking?.sanityType,
        sanity_id: tracking?.sanityId,
        sanity_name: tracking?.sanityName
      }
    })
  }

  return (
    <section className="overflow-hidden pt-8 md:pt-10">
      <div className="flex flex-col items-center px-4 text-center md:px-[4vw]">
        <h1 className="mb-2 text-2xl font-normal leading-[1.2] tracking-[0.24px] text-rb-gray-400 md:text-5xl md:tracking-[0.48px]">
          {title}
        </h1>

        <h2 className="mb-2 font-sans text-base font-normal leading-[1.5] text-rb-gray-300 md:text-xl md:font-medium md:leading-[1.4]">
          {subtitle}
        </h2>

        {updatedAt && (
          <p className="m-0 text-sm font-normal leading-[1.5] text-rb-gray-300">
            Updated {dayjs(updatedAt).format('MMMM D, YYYY')}
          </p>
        )}

        <Button
          color="teal"
          shape="rounded"
          size="small"
          className="mt-10 md:mt-16"
          href={ctaHref}
          onClick={onCtaClick}
        >
          {ctaText}
        </Button>
      </div>

      <div className="bg-[linear-gradient(180deg,transparent_68%,#FFF_100%)] md:bg-[linear-gradient(180deg,transparent_55%,#FFF_95%)]">
        <div className="relative mx-auto mt-8 w-full max-w-[1024px] md:mt-16">
          <Image
            src={isMobileView ? bgImageMobile : bgImage}
            alt=""
            sizes="100vw, (min-width: 1024px) 1200px"
            priority
            className="relative -z-1 h-auto w-[calc(100%+600px)] max-w-none -translate-x-[300px] md:w-[calc(100%+120px)] md:-translate-x-[60px]"
          />
        </div>
      </div>
    </section>
  )
}

export default SeoPageHero
