import React from 'react'

import reactionSmileyUrl from '../../assets/images/add-reaction-smiley.svg'

interface ButtonAddReactionProps {
  id?: string
  onClick?: () => void
}

const WrappedButtonAddReaction = React.forwardRef(function ButtonAddReaction(
  { id = '', onClick = () => {} }: ButtonAddReactionProps,
  ref: React.RefObject<HTMLInputElement>
) {
  return (
    <div
      data-for={id}
      data-tip="Add reaction"
      className="h-[26px] w-[38px] min-w-[36px] cursor-pointer overflow-hidden rounded-full border border-rb-gray-100 bg-rb-white py-0 px-[6px] hover:border-rb-teal-300 hover:bg-rb-gray-50"
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={0}
      role="button"
      ref={ref}
    >
      <div className="flex h-full w-full flex-row items-center justify-center">
        <img src={reactionSmileyUrl} alt="Add reaction" width="12" height="12" />
      </div>
    </div>
  )
})

export default WrappedButtonAddReaction
