import { differenceInMinutes, parseISO } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

import AddToCalendar from 'components/AddToCalendar'

import {
  CourseAddToCalendarPartsFragment,
  CourseEventAddToCalendarPartsFragment,
  CourseSessionAddToCalendarPartsFragment,
  EventPerCourseSession
} from 'gql'

import { trackAddToCalendar } from 'utils/tracking/generated/events/addToCalendar'

interface AddCourseEventToCalendarProps {
  event:
    | CourseEventAddToCalendarPartsFragment
    | (EventPerCourseSession & {
        title: string | null | undefined
        shortDescription: string | null | undefined
        sanityId: string | null | undefined
      })
  course?: CourseAddToCalendarPartsFragment
  courseBlockTitle?: string | null
  courseSession?: CourseSessionAddToCalendarPartsFragment | null
  sourceCtaText?: string
  timezone?: string | undefined | null
  onClickCallback?: () => void
}

const AddCourseEventToCalendar = ({
  event,
  course,
  courseBlockTitle,
  courseSession,
  sourceCtaText,
  timezone,
  onClickCallback
}: AddCourseEventToCalendarProps) => {
  if (!event.startsAt || !event.endsAt || !event.joinLink) {
    return null
  }

  const handleOnClickCallback = () => {
    const { startsAt, endsAt, title, sanityId: eventSanityId } = event
    const duration =
      differenceInMinutes(parseISO(endsAt || ''), parseISO(startsAt || '')) / 60

    trackAddToCalendar({
      location: 'course_page',
      source_cta_text: sourceCtaText,
      course_id: course?.id,
      course_title: course?.title,
      course_session_id: courseSession?.id,
      content_block_title: courseBlockTitle || '',
      event_sanity_id: eventSanityId || '',
      event_title: title || '',
      event_day_of_week: formatInTimeZone(startsAt || '', timezone!, 'EEEE'),
      event_starts_at: startsAt || '',
      event_time_of_day: formatInTimeZone(startsAt || '', timezone!, 'h:mm a'),
      event_ends_at: endsAt || '',
      event_duration_hrs: duration || 0,
      event_timezone: timezone || '',
      add_type: 'single'
    })

    onClickCallback && onClickCallback()
  }

  return (
    <AddToCalendar
      startsAtUtc={event.startsAt}
      endsAtUtc={event.endsAt}
      eventDescription={event.shortDescription}
      eventTitle={event.title}
      joinLink={event.joinLink}
      timezone={timezone}
      onClickCallback={handleOnClickCallback}
    />
  )
}

export default AddCourseEventToCalendar
