import CarouselSection from 'components/pagination/CarouselSection/CarouselSection'

import { CourseCheckoutPartsFragment } from 'gql'

import CourseCheckoutTestimonial from './CourseCheckoutTestimonial'

interface CourseCheckoutTestimonialCarouselProps {
  course: CourseCheckoutPartsFragment
}

const CourseCheckoutTestimonialCarousel = ({
  course
}: CourseCheckoutTestimonialCarouselProps) => {
  if (!course.testimonials) {
    return null
  }

  return (
    <div>
      <CarouselSection
        slideWidth={370}
        visibleSlides={1}
        innerClassName="h-full"
        arrowButtonsOnSides={true}
      >
        {course.testimonials.map((testimonial, index) => (
          <CourseCheckoutTestimonial
            key={testimonial?.id}
            testimonial={testimonial}
            course={course}
            position={index + 1}
          />
        ))}
      </CarouselSection>
    </div>
  )
}

export default CourseCheckoutTestimonialCarousel
