import { publicApolloClient } from 'apolloClient'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { boolean, object, string } from 'yup'

import { ProfileCardData } from 'pages/CreateAccountPage/CreateAccountPage'

import Button from 'components/Button'
import { displayToast } from 'components/Toast'
import ToastCard, { toastOptions } from 'components/ToastCard'
import { Checkbox, Form, SelectWithForm as Select } from 'components/forms'
import { InputText } from 'components/forms/InputText'
import withContainer from 'components/forms/enhancers/withContainer'
import withDescription from 'components/forms/enhancers/withDescription'
import withLabel from 'components/forms/enhancers/withLabel'
import withValidation from 'components/forms/enhancers/withValidation'
import withValidationIcon from 'components/forms/enhancers/withValidationIcon'

import { COMPANY_NAME_PLACEHOLDER } from 'constants/companyNames'

import {
  ClearbitCompany,
  useActivateSubscriptionMutation,
  useCreateAccountMutation,
  useCreateProgramApplicationMutation,
  useGetCountryCodeQuery,
  useSearchCompanyWithClearbitLazyQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useQueryParams } from 'hooks/useQueryParams'

import { getCookie } from 'utils/cookieUtils'
import {
  identify,
  trackApplicationFormAction,
  trackApplicationSubmitted
} from 'utils/tracking/analytics'

import { CompanyCombobox } from './CompanyCombobox'
import { HOW_YOU_HEARD_ABOUT_US, JOB_FUNCTIONS } from './constants'
import { useAnalyticsTracker } from './helpers'

interface FormValues {
  email: string
  companyName?: string
  transitioningCompanies?: boolean
  jobTitle: string
  referralSource: string
  emailConsent?: boolean
  jobFunction: string
  mtoken?: string | null
}

const formLabels: Record<keyof Omit<FormValues, 'mtoken'>, string> = {
  email: 'Email',
  companyName: 'Company Name',
  transitioningCompanies: 'Currently transitioning between companies',
  jobTitle: 'Job Title',
  referralSource: 'How did you hear about us?',
  emailConsent: '',
  jobFunction: 'What is your function?'
}

const labelClassNames = 'text-base font-semibold'
const ComboboxWithValidation = withLabel(withValidation(CompanyCombobox), labelClassNames)

const InputTextWithCustomLabels = withValidation(
  withLabel(
    withDescription(withContainer(withValidationIcon(InputText))),
    labelClassNames
  )
)

const formValidationSchema = object().shape({
  companyName: string()
    .when('transitioningCompanies', {
      is: true,
      then: string().nullable()
    })
    .when('transitioningCompanies', {
      is: false,
      then: string().required('Company name is required')
    }),
  transitioningCompanies: boolean()
    .label(formLabels.transitioningCompanies)
    .default(false),
  jobTitle: string().trim().required('Job title is required').label(formLabels.jobTitle),
  referralSource: string()
    .trim()
    .required('Please select an option')
    .label(formLabels.referralSource),
  jobFunction: string()
    .trim()
    .required('Please select an option')
    .label(formLabels.jobFunction)
})

interface FormInputsContainerProps {
  profileCardData: ProfileCardData
  setProfileCardData: (profileCardData: ProfileCardData) => void
}

const FormInputsContainer = ({
  profileCardData,
  setProfileCardData
}: FormInputsContainerProps) => {
  const [isChecked, setIsChecked] = useState(false)
  const [, setCompanyName] = useState<string | null>(null)
  const { registerEmailConsentClick } = useAnalyticsTracker()
  const [companyData, setCompanyData] = useState<ClearbitCompany[]>([])
  const [searchCompany] = useSearchCompanyWithClearbitLazyQuery({
    onCompleted: (data) => {
      setCompanyData(data?.searchCompanyWithClearbit ?? [])
    },
    client: publicApolloClient
  })
  const params = new URLSearchParams(window.location.search)
  const mtoken = params.get('mtoken')

  const { data: countryCodeData } = useGetCountryCodeQuery({ client: publicApolloClient })

  const {
    getValues,
    setValue,
    register,
    errors,
    triggerValidation,
    formState: { isSubmitting, isValid }
  } = useFormContext()

  const handleTransitioningCompaniesChange = async (checked: boolean) => {
    setIsChecked(checked)
    if (checked) {
      setValue('companyName', null)
      setProfileCardData({ ...profileCardData, companyName: '', isTransitioning: true })
    }
    if (!checked) {
      setProfileCardData({ ...profileCardData, isTransitioning: false })
    }
    await triggerValidation('companyName')
  }

  const isSubmitDisabled = () => {
    return isSubmitting || !isValid || Object.keys(errors).length > 0
  }

  const handleWhereDoYouWorkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCompanyName = event?.target.value
    setCompanyName(newCompanyName)

    if (newCompanyName) {
      searchCompany({
        variables: {
          search: newCompanyName
        }
      })
    }
  }

  return (
    <>
      <input
        className="hidden"
        value={mtoken || undefined}
        ref={register}
        name="mtoken"
      />
      <div className="mb-8 hidden w-full"></div>
      <div className="flex w-full flex-col">
        <ComboboxWithValidation
          // we use defaultValue as a workaround to avoid
          // losing the pre-filled data
          defaultValue={(isChecked && '') || ''}
          disabled={isChecked}
          data-test="companyName"
          label="Where do you work?*"
          companies={companyData}
          placeholder={COMPANY_NAME_PLACEHOLDER}
          name="companyName"
          register={register}
          onChange={(company: string) => {
            setProfileCardData({
              ...profileCardData,
              companyName: company,
              companyLogoUrl: companyData.find((c) => c.name === company)?.logo || null
            })
          }}
          onInputChange={handleWhereDoYouWorkChange}
          isValid={!errors.companyName}
          hasError={errors && errors.companyName}
        />
      </div>
      <div className="mb-8 mt-4 flex w-full text-rb-black">
        <Checkbox
          className="mr-3"
          name="transitioningCompanies"
          data-test="transitioningCompanies"
          showLabel={false}
          onChange={(checked) => handleTransitioningCompaniesChange(checked)}
        />
        <span>Currently transitioning between companies</span>
      </div>
      <div className="mb-8 flex w-full flex-col tl:flex-row">
        <Select
          options={JOB_FUNCTIONS.map((jobFunction: string) => ({
            id: jobFunction,
            option: jobFunction
          }))}
          data-test="jobFunction"
          name="jobFunction"
          label="What is your function? *"
          placeholder="Select an option"
          labelClassNames={labelClassNames}
          showLabel
          classNameAdditions="bg-rb-white"
          dropdownClassName="sm:w-full"
          onChange={() =>
            setProfileCardData({
              ...profileCardData,
              jobFunction: getValues('jobFunction')
            })
          }
        />
      </div>
      <div className="mb-8 flex w-full flex-col tl:flex-row">
        <div className="mb-2 flex w-full flex-col tl:flex-row">
          <InputTextWithCustomLabels
            placeholder="Product Manager, Data Analyst, Head of Product..."
            name="jobTitle"
            data-test="jobTitle"
            label="What's your title? *"
            autoComplete="off"
            onChange={() =>
              setProfileCardData({ ...profileCardData, role: getValues('jobTitle') })
            }
          />
        </div>
      </div>

      <div className="mb-8 flex w-full flex-col tl:flex-row">
        <Select
          options={HOW_YOU_HEARD_ABOUT_US.map((referralSource: string) => ({
            id: referralSource,
            option: referralSource
          }))}
          data-test="referralSource"
          name="referralSource"
          label="How did you hear about us? *"
          placeholder="Select an option"
          labelClassNames={labelClassNames}
          showLabel
          classNameAdditions="bg-rb-white"
          dropdownClassName="sm:w-full"
        />
      </div>
      {countryCodeData?.countryCode !== 'US' && (
        <div className="flex w-full text-rb-gray-400">
          <Checkbox
            className="mr-3"
            name="emailConsent"
            onChange={registerEmailConsentClick}
            label="I consent to receive Reforge newsletters and updates via email"
            labelClasses="font-normal"
          />
        </div>
      )}
      <Button
        type="submit"
        disabled={isSubmitDisabled()}
        className="mt-12 mb-6 self-end"
        size="small"
        color="teal"
      >
        {isSubmitting ? 'Submitting...' : 'Next'}
      </Button>
    </>
  )
}

interface CreateAccountFormProps {
  profileCardData: ProfileCardData
  setProfileCardData: (profileCardData: ProfileCardData) => void
}

const CreateAccountForm = ({
  setProfileCardData,
  profileCardData
}: CreateAccountFormProps) => {
  const { currentUser, refetchCurrentUser, currentUserLoading } = useCurrentUser()
  const [activateSubscriptionMutation] = useActivateSubscriptionMutation()

  const [
    createProgramApplication,
    { loading: createProgramApplicationLoading, error: programApplicationError }
  ] = useCreateProgramApplicationMutation()

  const [createAccount, { loading: createAccountLoading, error: createAccountError }] =
    useCreateAccountMutation()

  const isSubmitting =
    createProgramApplicationLoading || createAccountLoading || currentUserLoading

  const genericErrorMessage =
    'An error occurred when submitting your form. Contact hello@reforge.com'

  const registerSubmitClick = (formData: FormValues) => {
    identify(currentUser?.id, {
      company_name: formData.companyName,
      logged_in: true // this should always be true now because no one can see this form without authing
    })

    trackApplicationFormAction({
      action: 'submit_application_click'
    })
    trackApplicationSubmitted({
      company: formData.companyName,
      email: formData.email
    })
  }

  const { queryParams } = useQueryParams()

  const submitForm = async (formData: FormValues) => {
    if (isSubmitting) {
      return
    }

    const { mtoken, ...originalFormData } = formData
    await createAccount({
      variables: {
        input: {
          companyName: originalFormData.companyName,
          transitioningCompanies: originalFormData.transitioningCompanies,
          jobFunction: originalFormData.jobFunction,
          role: originalFormData.jobTitle,
          referralSource: originalFormData.referralSource,
          emailConsent: originalFormData.emailConsent,
          onboardingStep: mtoken ? 8 : 1
        }
      }
    })

    if (mtoken) {
      const result = await activateSubscriptionMutation({
        variables: { input: { mtoken } }
      })
      const teamJoinSuccess = !result.data?.activateSubscription?.errors
      if (teamJoinSuccess) {
        const teamJoinParam = 'joined-team=true'
        const currentUrl = window.location.href
        const separator = currentUrl.includes('?') ? '&' : '?'
        const newUrl = currentUrl + separator + teamJoinParam
        window.history.pushState({ path: newUrl }, '', newUrl)
      }
    }

    const { data } = await createProgramApplication({
      variables: {
        input: {
          ...originalFormData,
          email: currentUser?.contact.primaryEmail || '',
          source: queryParams.get('source') || getCookie('source_global'),
          utmSource: queryParams.get('utm_source') || getCookie('utm_source_global'),
          utmMedium: queryParams.get('utm_medium') || getCookie('utm_medium_global'),
          utmCampaign:
            queryParams.get('utm_campaign') || getCookie('utm_campaign_global'),
          utmContent: queryParams.get('utm_content') || getCookie('utm_content_global'),
          utmTerm: queryParams.get('utm_term') || getCookie('utm_term_global'),
          internalRef: queryParams.get('internal_ref')
        }
      }
    })

    if (data?.createProgramApplication?.errors?.length) {
      const { errors } = data.createProgramApplication
      const errorMessage = errors.length > 1 ? genericErrorMessage : errors[0]
      displayToast(<ToastCard type="warning" message={errorMessage} />, toastOptions)
    } else {
      registerSubmitClick(originalFormData)
    }

    await refetchCurrentUser()
  }

  useEffect(() => {
    if (programApplicationError || createAccountError) {
      displayToast(
        <ToastCard type="warning" message={genericErrorMessage} />,
        toastOptions
      )
    }
  }, [programApplicationError, createAccountError])

  return (
    <Form
      className="flex w-full flex-col"
      submit={submitForm}
      validationMode="onChange"
      submitButton={false}
      validationSchema={formValidationSchema}
    >
      <FormInputsContainer
        profileCardData={profileCardData}
        setProfileCardData={setProfileCardData}
      />
    </Form>
  )
}

export default CreateAccountForm
