import MessageMe from 'domains/Profile/MessageMe'
import ProfileInfo from 'domains/Profile/ProfileInfo'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'
import BasicInfoIcon from 'components/icons/BasicInfoIcon'

import { ALUMNI_TOOLTIP } from 'constants/memberDirectory'

import {
  CurrentUserPartsFragment,
  MemberProfileSlackAccountFragment,
  MemberProfileUserFieldsFragment
} from 'gql'

import { ReactComponent as ReforgeSymbol } from 'images/reforge-logo-symbol.svg'

interface BaseballCardProps {
  areasOfExpertiseTags: string | null | undefined
  communityInterestTags: string | null | undefined
  currentUser: CurrentUserPartsFragment
  joinSlackHref?: string | null
  handleEditClick: () => void
  slackResponseTime: string | null
  threeRecentSlackJoins?: MemberProfileSlackAccountFragment[]
  user: MemberProfileUserFieldsFragment
}

const BaseballCard = ({
  areasOfExpertiseTags,
  communityInterestTags,
  currentUser,
  joinSlackHref,
  handleEditClick,
  slackResponseTime,
  threeRecentSlackJoins,
  user
}: BaseballCardProps) => {
  const previousCompanyNames = user.previousCompanies
    .map((userCompany) => userCompany.company.name)
    .join(', ')

  const baseballCardButton = () => {
    if (currentUser.id === user.id) {
      return (
        <div className="member-profile__headfoot-button w-full pt-2.5 pr-2.5 sm:w-auto sm:pr-2">
          <div className="bg-white p-3 sm:ml-3 sm:mb-5 sm:p-0">
            <Button
              size="small"
              shape="rounded-full"
              className="w-full md:w-60"
              dataTest="member-profile-edit-cta"
              onClick={handleEditClick}
            >
              <SVGIcon
                className="-mt-0.5 mr-2"
                name="pencil"
                width="16"
                height="16"
                fill="#fff"
              />
              <span>Edit My Profile</span>
            </Button>
          </div>
        </div>
      )
    }
    if (user.is.expired) {
      return (
        <div
          className="ml-2 w-40"
          uk-tooltip={`${ALUMNI_TOOLTIP}; cls: uk-active rf-tooltip-left-arrow; pos: right`}
        >
          <Button
            variant="fill"
            size="small"
            shape="rounded-full"
            className="w-40 border-none bg-rb-gray-100 text-rb-white hover:bg-rb-gray-100 hover:text-rb-white"
            disabled
            iconBefore={<BasicInfoIcon />}
          >
            ALUM
          </Button>
        </div>
      )
    }
    if (
      currentUser.id !== user.id &&
      user.slackUserId &&
      user.profile.allowDirectMessages
    ) {
      return (
        <div className="member-profile__headfoot-button w-full pt-2.5 pr-2.5 sm:w-auto sm:pr-2">
          <div className="flex flex-col justify-center bg-white p-3 sm:ml-3 sm:mb-3 sm:inline-flex sm:p-0">
            <MessageMe
              user={user}
              joinSlackHref={joinSlackHref}
              threeRecentSlackJoins={threeRecentSlackJoins || []}
            />
            {slackResponseTime && (
              <div className="pt-2 text-center text-xs tracking-widest text-rb-gray-400">
                {slackResponseTime}
              </div>
            )}
          </div>
        </div>
      )
    }
  }

  return (
    <div
      className="-m-2.5 mb-5 grid grid-cols-1 rounded-t border-t-16 border-solid border-white
                    shadow-shoutout sm:m-0 sm:mb-7 sm:grid-cols-2 sm:border-t-73 sm:p-3"
      data-test="member-profile-baseball-card"
    >
      <div className="-mt-3 flex flex-col justify-between sm:-mt-16">
        <ProfileInfo user={user} variation="baseball" />
        {baseballCardButton()}
      </div>
      <div className="p-3 text-sm font-light text-rb-gray-400 sm:mt-16">
        {user.previousCompanies && user.previousCompanies.length > 0 && (
          <div className="mt-4 flex items-start">
            <div className="mr-3 flex h-7 w-7 flex-none items-center justify-center rounded-full bg-rb-gray-50">
              <SVGIcon name="home" width="16" height="13" fill="#000000" />
            </div>
            <div className="pt-1">
              Previously at <span className="font-semibold">{previousCompanyNames}</span>
            </div>
          </div>
        )}

        {areasOfExpertiseTags && (
          <div className="mt-4 flex items-start">
            <div className="mr-3 flex h-7 w-7 flex-none items-center justify-center rounded-full bg-rb-gray-50">
              <SVGIcon name="discussion-sidebar" width="13" height="15" />
            </div>
            <div className="pt-1">
              Ask me about{' '}
              <span className="font-semibold">
                {areasOfExpertiseTags.split(',').join(', ')}
              </span>
            </div>
          </div>
        )}

        <div className="mt-4 flex items-start">
          <div className="mr-3 flex h-7 w-7 flex-none items-center justify-center rounded-full bg-rb-gray-50">
            <ReforgeSymbol width="16" className="fill-current text-rb-black" />
          </div>
          <div className="pt-1">
            Member since{' '}
            <span className="font-semibold">
              {user.monthJoined} {user.yearJoined}
            </span>
          </div>
        </div>

        {communityInterestTags && communityInterestTags !== 'Not Interested' && (
          <div className="mt-4 flex items-start">
            <div className="mr-3 flex h-7 w-7 flex-none items-center justify-center rounded-full bg-rb-gray-50">
              <SVGIcon name="members" width="16" height="16" />
            </div>
            <div className="pt-1">
              I’m here to{' '}
              <span className="font-semibold">
                {communityInterestTags.split(',').join(', ')}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default BaseballCard
