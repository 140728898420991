import InputFilter from './InputFilter'
import { FilterProps } from './helpers'

const Company = ({ filters, setFilters }: FilterProps) => (
  <InputFilter
    label="company"
    placeholder="Google, Slack, Shopify, etc."
    name="companyContains"
    filters={filters}
    setFilters={setFilters}
  />
)

export default Company
