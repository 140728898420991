import { useEffect, useState } from 'react'

interface TextSwitcherProps {
  intervalMilliseconds: number
  items: string[]
  className?: string
  isChromatic?: boolean
}

const TextSwitcher = ({
  intervalMilliseconds,
  items,
  className,
  isChromatic = false
}: TextSwitcherProps) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0)

  useEffect(() => {
    if (!items) return

    function switchItem() {
      if (activeItemIndex >= items.length - 1) {
        setActiveItemIndex(0)
      } else {
        setActiveItemIndex(activeItemIndex + 1)
      }
    }

    let timer: number | null = null
    if (!isChromatic) {
      timer = window.setInterval(switchItem, intervalMilliseconds)
    }

    return () => {
      timer && clearInterval(timer)
    }
  }, [items, activeItemIndex, setActiveItemIndex, intervalMilliseconds, isChromatic])

  if (!items) return null

  const textItem = items[activeItemIndex]
  return (
    <span key={textItem} className={className}>
      {textItem}
    </span>
  )
}

export default TextSwitcher
