import { Link } from 'react-router-dom'

import Image from 'domains/Sanity/Image'

import { ArtifactAuthor } from 'gql'

import { trackNavigationClicked } from 'utils/tracking/analytics'

export interface CoursesSeoExpertCardProps {
  expert: ArtifactAuthor
}

const onNavigationClick = (expert: ArtifactAuthor) => {
  trackNavigationClicked({
    type: 'card',
    text: expert.name || '',
    location: 'SEO course experts carousel'
  })
}

export const CoursesSeoExpertCard = ({ expert }: CoursesSeoExpertCardProps) => {
  const companies = [expert.company?.name, expert.pastCompanies].flat().filter(Boolean)

  return (
    <Link
      to={`/profiles/${expert.slug}`}
      className="h-[434px] w-[317px] overflow-hidden"
      onClick={() => onNavigationClick(expert)}
    >
      <div className="relative">
        <Image
          src={expert.avatarPhoto?.imageUrl || ''}
          alt={expert.avatarPhoto?.imageAlt || `Photo of ${expert.name}`}
          className="w-full rounded-xl"
        />

        <div className="absolute bottom-0 left-0 h-10 w-full bg-gradient-to-t from-rb-black via-transparent to-transparent" />
      </div>

      <div className="min-h-[7.25rem] rounded-b-xl bg-rb-black p-4">
        <h4 className="mb-1 text-3xl font-normal leading-[1.1] text-rb-white line-clamp-2">
          {expert.name}
        </h4>
        <p className="mb-0 h-[2.875rem] text-xl font-medium leading-[1.15] text-rb-white line-clamp-2">
          {companies.join(', ')}
        </p>
      </div>
    </Link>
  )
}

export default CoursesSeoExpertCard
