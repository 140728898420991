const SkinnyInfoIcon = ({ className }: { className?: string }) => (
  <svg className={className} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 10.15C8.29198 10.15 10.15 8.29198 10.15 6C10.15 3.70802 8.29198 1.85 6 1.85C3.70802 1.85 1.85 3.70802 1.85 6C1.85 8.29198 3.70802 10.15 6 10.15ZM6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
    />
    <path d="M6.44269 8.62433V4.68033H5.55469V8.62433H6.44269ZM6.44269 4.21633V3.28833H5.55469V4.21633H6.44269Z" />
  </svg>
)

export default SkinnyInfoIcon
