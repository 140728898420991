import { FC } from 'react'

import { ReactComponent as AlertIcon } from 'images/icon--alert.svg'

export interface EmptySearchItemProps {
  message: string
  searchQuery: string
}

export const EmptySearchItem: FC<EmptySearchItemProps> = ({ message, searchQuery }) => {
  return (
    <div className="border-gray-light flex border-2 p-4 text-rb-gray-300">
      <AlertIcon height="16" width="16" />
      <p className="m-0 ml-2">
        {message} <b>{searchQuery}</b>
      </p>
    </div>
  )
}

export default EmptySearchItem
