import { ReactComponent as BookmarkEmptyStateGraphic } from 'images/bookmark-empty-state-graphic.svg'

const AllHighlightsListEmptyState = () => (
  <div className="flex flex-col items-center justify-center rounded-xl bg-rb-gray-20 p-8">
    <div className="flex flex-col items-center">
      <div className="mb-8">
        <BookmarkEmptyStateGraphic
          className="my-8 self-center"
          width="185"
          height="123"
        />
      </div>
      <div className="flex flex-col items-center">
        <div className="mb-1 font-semibold">You don&apos;t have any highlights yet</div>
        <div className="max-w-[480px] text-center text-rb-gray-300">
          Create your first highlight when reading materials by dragging on text or click
          an image.
        </div>
      </div>
    </div>
  </div>
)

export default AllHighlightsListEmptyState
