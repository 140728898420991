import { useEffect, useRef, useState } from 'react'

import { Alert } from 'components/Alert'
import Button from 'components/Button'
import PaymentInfoForm, {
  PaymentInfoFormExposedMethods
} from 'components/PaymentMethods/PaymentInfoForm'

import { BillingAddressFragment } from 'gql'

type AddPaymentMethodProps = {
  onCancel: () => void
  onSave: (paymentMethodId: string) => void
  billingAddress?: BillingAddressFragment | null
  setAsDefaultOverride?: boolean
}

export function AddPaymentMethod({
  onSave,
  onCancel,
  billingAddress,
  setAsDefaultOverride
}: AddPaymentMethodProps) {
  const [isFormReady, setIsFormReady] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const mounted = useRef(true)

  const paymentInfoFormRef = useRef<PaymentInfoFormExposedMethods>(null)

  useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])

  const handleSave = async () => {
    setIsLoading(true)
    try {
      const paymentMethodId = await paymentInfoFormRef.current?.save()
      paymentMethodId && onSave(paymentMethodId)
    } catch (error) {
      setError(error.message)
    } finally {
      mounted.current && setIsLoading(false)
    }
  }

  return (
    <>
      <PaymentInfoForm
        ref={paymentInfoFormRef}
        billingAddress={billingAddress}
        handlePaymentInfoChange={(e) => setIsFormReady(e.complete)}
        setAsDefaultOverride={setAsDefaultOverride}
      />
      <div className="flex items-center">
        <Button
          disabled={!isFormReady}
          onClick={handleSave}
          isLoadingSpinner={isLoading}
          size="small"
        >
          Save
        </Button>

        <Button variant="text-only" onClick={onCancel} className="ml-2" size="small">
          Cancel
        </Button>
      </div>
      {error && <Alert className="mt-4">{error}</Alert>}
    </>
  )
}
