import { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'

import { TabsType } from 'components/Tabs/Tabs'

import { cn } from 'utils/tailwind'

import Tabs from '../Tabs'

const IDS = {
  mainElement: 'page-header',
  title: 'page-title',
  topRightContent: 'page-top-right-content',
  tabs: 'page-tabs'
}

interface PageHeaderProps {
  title?: string | ReactNode | null
  headerTopRight?: ReactNode | null
  headerTopLeft?: ReactNode | null
  dataTest?: string
  tabs?: TabsType | null
}

export const PageHeaderH1 = ({
  children,
  className
}: {
  children: ReactNode
  className?: string
}) => (
  <h1
    className={cn(
      'mb-0 font-polysans text-[32px] font-normal leading-[1.2] tracking-[-4%] text-rb-gray-400 lg:text-5xl',
      className
    )}
    data-test="page-header-title"
  >
    {children}
  </h1>
)

const PageHeader = ({
  title,
  headerTopRight,
  headerTopLeft,
  tabs,
  dataTest
}: PageHeaderProps) => {
  const location = useLocation()
  const isEventDetailPage = /events\/\d+-/.test(location.pathname)

  if (!title && !tabs) return null

  return (
    <div data-test={dataTest} id={IDS.mainElement} className="flex flex-col">
      {title && (
        <div
          className={cn(
            'mb-6 flex items-start justify-between md:mb-8',
            isEventDetailPage && 'flex-col items-center md:flex-row md:items-start'
          )}
        >
          <div className="flex items-center gap-6 w-full">
            {typeof title === 'string' ? <PageHeaderH1>{title}</PageHeaderH1> : title}
            {headerTopLeft}
          </div>
          {headerTopRight}
        </div>
      )}
      {tabs && (
        <div className="mb-4">
          <Tabs tabs={tabs} />
        </div>
      )}
    </div>
  )
}

export default PageHeader
