import { CarouselProvider } from 'pure-react-carousel'

import CourseDetailTestimonial from 'domains/CourseDetail/CourseDetailTestimonial'
import { CarouselWithStore } from 'domains/Seo/SeoPageCarousel/SeoPageCarousel'
import useSeoPageSlidesCount from 'domains/Seo/useSeoPageSlidesCount'

import {
  CourseDetailPartsFragment,
  SanityTestimonial,
  TestimonialPartsFragment
} from 'gql'

export interface CoursesSeoCarouselSectionProps {
  testimonials: (SanityTestimonial & {
    course: { __typename: string; id: string; title: string }
  })[]
}

export const CoursesSeoCarouselSection = ({
  testimonials
}: CoursesSeoCarouselSectionProps) => {
  const slideWidth = 290
  const visibleSlides = useSeoPageSlidesCount({ fourthSlide: true })

  return (
    <CarouselProvider
      naturalSlideWidth={slideWidth}
      naturalSlideHeight={400}
      totalSlides={testimonials.length + 1} // +1 for the empty element
      visibleSlides={visibleSlides}
      isIntrinsicHeight={true}
      className="mt-[104px] mb-[72px] w-full"
    >
      <CarouselWithStore
        isSmallGap={true}
        slideWidth={slideWidth}
        visibleSlides={visibleSlides}
        title="What people are saying about Reforge"
        navigationLocation="SEO testimonials carousel"
      >
        {testimonials.map((testimonial, index) => (
          <CourseDetailTestimonial
            key={testimonial?.id}
            testimonial={
              {
                ...testimonial,
                title: testimonial.header,
                subTitle: testimonial.subheader,
                company: {
                  ...testimonial.company,
                  logoUrl: testimonial.company?.logo?.imageUrl,
                  __typename: 'CclCompany'
                },
                __typename: 'CclReview'
              } as TestimonialPartsFragment
            }
            position={index + 1}
            courseDetail={testimonial.course as CourseDetailPartsFragment}
            className="mr-0"
          />
        ))}
      </CarouselWithStore>
    </CarouselProvider>
  )
}

export default CoursesSeoCarouselSection
