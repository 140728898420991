import React, { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

interface PopupMenuItemProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  className?: string
}

const PopupMenuItem = ({
  onClick,
  children,
  className
}: PropsWithChildren<PopupMenuItemProps>) => (
  <button
    className={twMerge(
      'prevent-default cursor-pointer bg-rb-gray-100 p-2 text-left text-rb-gray-400 hover:bg-rb-gray-100 focus:outline-none',
      className
    )}
    onClick={onClick}
    type="button"
  >
    {children}
  </button>
)

export default PopupMenuItem
