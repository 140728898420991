import { twMerge } from 'tailwind-merge'

import { Alert } from 'components/Alert'
import Button from 'components/Button'

import { BillingAddressFragment } from 'gql'

import { ReactComponent as PlusIcon } from 'images/icon--plus-sign.svg'

import { AddPaymentMethod } from './AddPaymentMethod'
import StoredPaymentMethods from './StoredPaymentMethods'
import { usePaymentMethods } from './usePaymentMethods'

export type PaymentMethodsProps = {
  selectedPaymentMethodId?: string | null
  setSelectedPaymentMethodId?: (id: string) => void
  className?: string
  billingAddress?: BillingAddressFragment | null
  showIsRequiredError?: boolean
}

const noop = () => {}

export const PaymentMethods = (props: PaymentMethodsProps) => {
  const {
    selectedPaymentMethodId,
    setSelectedPaymentMethodId,
    className,
    billingAddress,
    showIsRequiredError
  } = props

  const { paymentMethods, loading, isFormShown, setIsFormShown, onSave } =
    usePaymentMethods()

  const setAsDefaultOverride = paymentMethods.length === 0

  return (
    <div className={twMerge('w-full', className)}>
      {loading && <PaymentMethodsSkeleton />}
      {!loading && !isFormShown && paymentMethods.length > 0 && (
        <>
          <StoredPaymentMethods
            paymentMethods={paymentMethods}
            paymentMethodsLoading={loading}
            selectedPaymentMethodId={selectedPaymentMethodId}
            setSelectedPaymentMethodId={setSelectedPaymentMethodId || noop}
          />

          <Button
            size="x-small"
            variant="text-only"
            className="mt-2 px-2 normal-case"
            iconBefore={<PlusIcon />}
            onClick={() => setIsFormShown(true)}
          >
            Add a Payment Method
          </Button>
        </>
      )}
      {isFormShown && (
        <AddPaymentMethod
          onSave={(paymentMethodId) => {
            setSelectedPaymentMethodId?.(paymentMethodId)
            onSave()
          }}
          onCancel={() => setIsFormShown(false)}
          billingAddress={billingAddress}
          setAsDefaultOverride={setAsDefaultOverride}
        />
      )}
      {showIsRequiredError && (
        <Alert className="mt-4">Payment information required</Alert>
      )}
    </div>
  )
}

function PaymentMethodsSkeleton() {
  return (
    <div className="flex w-full animate-pulse flex-col gap-2.5">
      <div className="h-9 bg-rb-gray-100" />
      <div className="h-9 bg-rb-gray-100" />
      <div className="h-9 bg-rb-gray-100" />
    </div>
  )
}
