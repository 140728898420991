import { ApolloCache, ApolloQueryResult } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import { ReactComponent as DiscussionIcon } from 'icon--discussion.svg'
import produce from 'immer'
import React, { useEffect, useReducer, useState } from 'react'
import Pluralize from 'react-pluralize'
import { twMerge } from 'tailwind-merge'
import { debounce } from 'throttle-debounce'
import Tribute, { TributeItem } from 'tributejs'

import ConfirmDelete from 'domains/Cms/ConfirmDelete'
import InlineCommentsFroalaWrapper from 'domains/Cms/InlineCommentsFroalaWrapper'
import usePost from 'domains/Cms/Post/usePost'
import CmsReply from 'domains/Cms/Reply'

import Button from 'components/Button'
import ReactionButton from 'components/ReactionButton'
import { useSideDrawer } from 'components/SideDrawer/SideDrawer'
import Tooltip from 'components/Tooltip/Tooltip'

import {
  CmsContentDocument,
  CmsContentQuery,
  CmsContentQueryVariables,
  InlinePost,
  InlinePostReply,
  InlinePostsDocument,
  InlinePostsQuery,
  Reaction,
  ReactionKind,
  UserMention,
  useDeleteTeamPostMutation,
  useTeamMentionSearchLazyQuery,
  useUpdateTeamPostMutation
} from 'gql'

import { isMobileDevice } from 'utils/device.utils'
import { autoExpand } from 'utils/domManipulation'
import notifyError from 'utils/errorNotifier'
import { hasReactionOfKind, isReactionPresent } from 'utils/reactionUtils'
import { trackPostAction } from 'utils/tracking/generated/events/postAction'
import { trackReplyAction } from 'utils/tracking/generated/events/replyAction'

import { ReactComponent as PencilIcon } from 'images/icon--cohort-pencil.svg'
import { ReactComponent as TrashIcon } from 'images/icon--trash.svg'

export interface CmsPostProps {
  post: InlinePost
  type?: 'summary' | 'expanded'
  showAuthorPosition?: boolean
  setPostsSidebarDisplay?: (display: string, callback: () => void) => void
  sidebar?: React.ForwardedRef<HTMLDivElement>
  modifier?: string
  postSidebarDisplay?: string
  trackEvent: (action: string, anchor: string) => void
  currentUserId: string
  updatePost: () => Promise<ApolloQueryResult<InlinePostsQuery>>
  userTagFound?: boolean
  setUserTagFound?: (value: boolean) => void
  cmsSectionId?: string
}

interface CmsPostState {
  reply: string
  title: string
  body: string
  posting: boolean
  deleting: boolean
  editing: boolean
  reactions: Reaction[]
}
interface UpdateInputType {
  id: string
  title?: string
  body: string
  substituteNewlines: boolean
}

const CmsPost = ({
  post,
  showAuthorPosition,
  setPostsSidebarDisplay,
  sidebar,
  modifier,
  postSidebarDisplay,
  trackEvent,
  currentUserId,
  updatePost,
  userTagFound,
  setUserTagFound,
  cmsSectionId
}: CmsPostProps) => {
  const initialState: CmsPostState = {
    reply: '',
    title: post.title || '',
    body: post.bodyText,
    posting: false,
    deleting: false,
    editing: false,
    reactions: post.reactions
  }

  const [state, setState] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    initialState
  )

  const { filteredItemId, setActiveFilterId, hoveredItemId, setHoveredItemId } =
    useSideDrawer()
  const [displayReplies, setDisplayReplies] = useState(false)
  const [isReplying, setIsReplying] = useState(false)

  const updateOnDelete = (cache: ApolloCache<any>) => {
    const existingData = cache.readQuery<CmsContentQuery, CmsContentQueryVariables>({
      query: CmsContentDocument,
      variables: { cmsSectionId: cmsSectionId as string }
    })

    if (!existingData) return

    const newData = produce(existingData, (draft) => {
      const idx = draft?.cmsContent?.inlinePostAnchors.indexOf(
        post.anchor as string
      ) as number
      if (idx !== -1) draft?.cmsContent?.inlinePostAnchors.splice(idx, 1)
    })

    cache.writeQuery<CmsContentQuery, CmsContentQueryVariables>({
      query: CmsContentDocument,
      variables: { cmsSectionId: cmsSectionId as string },
      data: newData
    })
  }
  const [deleteTeamPostMutation] = useDeleteTeamPostMutation({
    refetchQueries: [InlinePostsDocument],
    update: updateOnDelete
  })
  const [updateTeamPostMutation] = useUpdateTeamPostMutation()

  const descriptionTextarea = React.createRef<HTMLTextAreaElement>()
  const el = React.createRef<HTMLDivElement>()
  const replyRef = React.createRef<HTMLDivElement>()
  const { createReaction, destroyReaction, createTeamReply } = usePost()

  const totalReplies = () => {
    if (post.replies.length === 0) {
      return 0
    }
    return post.replies.reduce((a, b) => a + 1 + (b.comments ? b.comments.length : 0), 0)
  }

  const submitReply = async (e: React.FormEvent) => {
    e.preventDefault()
    if (post.type === 'Post') return

    setState({ posting: true })
    setIsMounted(false)

    const reply = await createTeamReply({ id: post.id.toString(), body: state.reply })

    if (reply) {
      const postCopy = { ...post }

      const inlineReply: InlinePostReply = {
        __typename: 'InlinePostReply',
        id: reply.id,
        replyableId: reply.parentId || '',
        userAvatar: reply.userAvatar,
        userFullName: reply.userName || '',
        userRoleWithCompany: reply.userRoleWithCompany,
        body: reply.body,
        bodyText: reply.bodyText,
        userId: reply.userId,
        comments: []
      }
      postCopy.replies = [...postCopy.replies, inlineReply]

      updatePost()

      setPostsSidebarDisplay?.('show', function () {
        if (typeof sidebar !== 'function' && sidebar?.current) {
          sidebar.current.scrollTop = sidebar.current.scrollHeight
        }
      })
      trackEvent('comment-post', post.anchor)

      trackReplyAction({
        action: 'create',
        reply_id: reply.id,
        reply_to_id: reply.parentId,
        reply_to_type: 'post',
        comment_filter: 'Team',
        team_tagged: userTagFound
      })

      setUserTagFound?.(false)
      setIsReplying(false)

      setState({
        reply: '',
        posting: false
      })
    }
  }

  const showPostForm = () => {
    setIsReplying(true)

    if (!isMobileDevice()) {
      const input = document.querySelector<HTMLElement>('.js-add-reply__textarea')

      if (input) {
        input.focus()
      }
    }
  }

  const showDeleteConfirmation = (e: React.MouseEvent) => {
    e.stopPropagation()
    setState({ deleting: true })
  }

  const hideDeleteConfirmation = (e: React.MouseEvent) => {
    e.stopPropagation()
    setState({ deleting: false })
  }

  const deleteCurrentPost = async (e: React.MouseEvent) => {
    e.stopPropagation()
    if (!post || post.type === 'Post') return

    setState({ posting: true })

    try {
      await deleteTeamPostMutation({ variables: { input: { id: post.id } } })
    } catch (error: unknown) {
      notifyError(error)
    }
    const tagFound = !!document
      .getElementById(`inline-post--${post.id}`)
      ?.getElementsByClassName('fr-tribute').length

    trackPostAction({
      action: 'delete',
      group_ids: post.groups?.map((group) => group.id) || [],
      topic_ids: post.topics?.map((topic) => topic.id) || [],
      post_id: post.id,
      comment_filter: 'Team',
      team_tagged: tagFound
    })

    datadogRum.addAction('Delete comment')

    setState({ posting: false })
  }

  const enterEditMode = (e: React.MouseEvent) => {
    e.stopPropagation()
    setState({ editing: true })

    if (descriptionTextarea.current) {
      autoExpand(descriptionTextarea.current)
    }
  }

  const exitEditMode = (e: React.MouseEvent) => {
    e.stopPropagation()
    setState({ editing: false })
  }

  const updateCurrentPost = async (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (post.type === 'Post') return

    const { body } = state
    setState({ posting: true })

    const input = {
      id: post.id,
      body: body.split('\n').join('<br />'),
      substituteNewlines: true
    } as UpdateInputType

    const { errors } = await updateTeamPostMutation({
      variables: {
        input: input
      }
    })

    if (errors) {
      notifyError(`Error updating the post, got errors ${errors}`)
    } else {
      setState({
        editing: false,
        posting: false
      })

      trackPostAction({
        action: 'update',
        group_ids: post.groups?.map((group) => group.id) || [],
        topic_ids: post.topics?.map((topic) => topic.id) || [],
        post_id: post.id,
        comment_filter: 'Team',
        team_tagged: userTagFound
      })
      updatePost()
      setUserTagFound?.(false)
    }
  }

  const updateReply = (updatedReply: InlinePostReply) => {
    const postCopy = { ...post }
    const repliesCopy = [...postCopy.replies]
    const index = repliesCopy.findIndex((reply) => reply.id === updatedReply.id)
    repliesCopy[index] = updatedReply
    postCopy.replies = repliesCopy

    trackReplyAction({
      action: 'update',
      reply_id: updatedReply.id,
      reply_to_id: updatedReply.replyableId,
      reply_to_type: 'post',
      comment_filter: 'Team',
      team_tagged: userTagFound
    })

    setUserTagFound?.(false)
    updatePost()
  }

  const deleteReply = (replyId: string, postId: string) => {
    const postCopy = { ...post }
    const updatedReplies = postCopy.replies.filter((reply) => reply.id !== replyId)
    postCopy.replies = updatedReplies

    const tagFound = !!document
      .getElementById(`inline-reply--${replyId}`)
      ?.getElementsByClassName('fr-tribute').length

    trackReplyAction({
      action: 'delete',
      reply_id: replyId,
      reply_to_id: postId,
      reply_to_type: 'post',
      comment_filter: 'Team',
      team_tagged: tagFound
    })

    setUserTagFound?.(false)
    updatePost()
  }

  const removeReaction = async (id: string | number) => {
    if (!isReactionPresent({ reactions: state.reactions, id })) {
      return
    }

    setState({
      posting: true
    })

    const response = await destroyReaction(id.toString(), undefined, post)

    const filteredData = state.reactions.filter(
      (reaction: Reaction) => reaction.id !== response
    )

    setState({
      posting: false,
      reactions: filteredData
    })

    updatePost()
  }

  const addReaction = async (kind: ReactionKind) => {
    if (hasReactionOfKind({ currentUserId, reactions: state.reactions, kind })) {
      return
    }

    setState({
      posting: true
    })

    const payload = {
      kind,
      reactableId: post.id.toString(),
      reactableType: post.type,
      reactableInlinePost: post
    }

    const response = await createReaction(payload)

    if (response) {
      const data = response && [...state.reactions, response]

      setState({
        posting: false,
        reactions: data
      })

      updatePost()
    }
  }
  const { reply, posting, deleting, editing } = state
  const disabled = reply.length === 0 || posting
  const [isMounted, setIsMounted] = useState(false)

  const [getUserMentions] = useTeamMentionSearchLazyQuery()
  const mentions = async (query: string, callback: (users: UserMention[]) => void) => {
    try {
      const response = await getUserMentions({
        variables: {
          nameQuery: query
        }
      })

      const userMentions = (response.data && response.data.teamMentionSearch) || []

      callback(userMentions)
    } catch (e) {
      notifyError(e)
    }
  }

  const debouncedMentions = debounce(500, mentions)

  useEffect(() => {
    if (!disabled && replyRef.current !== null && !isMounted) {
      const tribute = new Tribute({
        trigger: '@',
        allowSpaces: true,
        values: function (query, callback) {
          debouncedMentions(query, callback)
        },
        lookup: 'name',
        fillAttr: 'name',
        containerClass: 'tribute-container !relative !top-[-34px] z-10',
        menuContainer: replyRef.current.parentElement || replyRef.current,
        positionMenu: false,
        // @ts-expect-error
        menuItemLimit: 10,
        selectClass: 'highlight',
        menuItemTemplate: function (
          item: TributeItem<{ string: string; title: string }>
        ) {
          return `${item.string} <small>${item.original.title}</small>`
        },
        selectTemplate: function (
          item: TributeItem<{ id: number; slug: string; name: string }>
        ) {
          setUserTagFound?.(true)
          return `<span class="fr-deletable fr-tribute" contenteditable="false"><a data-mention="mention" data-id="${item.original.id}" href="https://reforge.com/directory/${item.original.slug}" target="_blank">@${item.original.name}</a></span>`
        }
      })

      setIsMounted(true)
      tribute.attach(replyRef.current)
    }
  }, [
    debouncedMentions,
    disabled,
    isMounted,
    replyRef,
    setUserTagFound,
    descriptionTextarea
  ])

  const tagFound = state.posting
    ? !!document // Update post
        .getElementById(`inline-post--${post.id}`)
        ?.getElementsByClassName('fr-tribute').length
    : !!document // New/update reply
        .getElementById(`inline-post--${post.id}`)
        ?.getElementsByClassName('js-add-reply__textarea')[0]
        ?.getElementsByClassName('fr-tribute').length

  useEffect(() => {
    if (!tagFound) setUserTagFound?.(false)
  }, [setUserTagFound, tagFound])

  if (post === undefined) {
    return <div />
  }

  const tootipBody = displayReplies
    ? '<div>Hide Responses</div>'
    : '<div>Show Responses</div>'
  const canManagePost = post.userId === currentUserId

  const scrollToAnchor = (event: React.MouseEvent | React.KeyboardEvent) => {
    const target = event.target as HTMLElement
    const hash = post.anchor

    setActiveFilterId?.(hash)

    const cannotScroll =
      (target.tagName !== 'DIV' && target.tagName !== 'P' && target.tagName !== 'LI') ||
      hash === '' ||
      editing

    if (cannotScroll) return

    const element = document.getElementById(hash)
    if (!element) return

    const rect = element.getBoundingClientRect()
    document.getElementById('page')?.scrollTo({
      top: window.scrollY + rect.top - 90,
      behavior: 'smooth'
    })
  }

  return (
    <div
      className={twMerge(
        'mb-4 rounded border',
        modifier && `inline-post--${modifier}`,
        post.anchor === hoveredItemId
          ? 'border-rb-gray-250 bg-rb-gray-100'
          : post.anchor === filteredItemId
            ? 'border-rb-green-75 bg-rb-green-50'
            : ' border-rb-gray-250 bg-rb-white'
      )}
      id={`inline-post--${post.id}`}
      ref={el}
    >
      <div
        className={`p-6 ${editing ? 'hover:cursor-auto' : ''}`}
        onClick={(event) => scrollToAnchor(event)}
        onMouseEnter={() => setHoveredItemId(post.anchor)}
        onMouseLeave={() => setHoveredItemId('')}
        tabIndex={0}
        onKeyUp={(event) => scrollToAnchor(event)}
        role="button"
        data-dd-action-name="Side drawer comment"
      >
        <div className="flex flex-wrap items-center gap-0">
          <div className="w-auto">
            <img
              alt="avatar"
              className="inline-block !h-[25px] !w-[25px] rounded-[50%] border-none align-middle text-[8px]"
              src={post.userAvatar}
            />
          </div>
          <div className="flex-1">
            <h6 className="mb-0 inline-block pl-2 text-xs font-semibold">
              {post.userFullName}
              {showAuthorPosition && post.userRoleWithCompany
                ? `, ${post.userRoleWithCompany}`
                : ''}
            </h6>
            {post.createdAtDate && (
              <span className="pl-2 text-sm text-rb-gray-300">{post.createdAtDate}</span>
            )}
          </div>
        </div>
        <div className="flex flex-wrap gap-0">
          <div className="w-full flex-1 break-words">
            {!editing && (
              <>
                <h5
                  className={twMerge(
                    'mt-2 mb-2.5 font-semibold leading-6',
                    modifier && `inline-post__title--${modifier}`
                  )}
                >
                  {post.title}
                </h5>
                <div dangerouslySetInnerHTML={{ __html: post.body }} />
              </>
            )}
            {editing && (
              <form
                className="edit-post box-border bg-rb-white text-[15px] text-rb-black"
                onSubmit={(e) => {
                  e.stopPropagation()
                }}
              >
                <div id="edit-description" className="uk-width-expand mt-5 mb-2">
                  <label
                    htmlFor="description"
                    className="uk-form-label font-sans text-xs font-medium text-rb-black"
                  >
                    Description
                  </label>
                  <InlineCommentsFroalaWrapper
                    model={state.body}
                    updateModel={(body: string) => setState({ body: body })}
                    placeholder="Provide detail as necessary..."
                    menuContainerId="edit-description"
                    tributeOffset="!top-[-45px]"
                  />
                </div>
                <div className="flex justify-between">
                  <Button
                    disabled={posting}
                    className="mr-3 w-1/2 py-2"
                    onClick={updateCurrentPost}
                  >
                    Save
                  </Button>
                  <Button
                    disabled={posting}
                    variant="outline"
                    className="w-1/2"
                    onClick={exitEditMode}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            )}
            <span className="align-center flex justify-between">
              <Tooltip
                html={true}
                tooltipBody={tootipBody}
                className="!z-[1011] !opacity-100"
                contentWrapperClassname="hover:no-underline"
              >
                <Button
                  variant="text-only"
                  size="x-small"
                  className="px-1"
                  iconBefore={
                    <DiscussionIcon
                      className="text-rb-gray-500"
                      name="discussions"
                      width="16"
                      height="16"
                      fill="currentColor"
                    />
                  }
                  onClick={() => setDisplayReplies(!displayReplies)}
                >
                  <span className="flex">
                    <span>{totalReplies()}</span>
                    <span className="ml-1 hidden sm:block">
                      <Pluralize
                        singular={'response'}
                        plural={'responses'}
                        count={totalReplies()}
                        showCount={false}
                      />
                    </span>
                  </span>
                </Button>
              </Tooltip>

              <ReactionButton
                kind={ReactionKind.UPVOTE}
                isInlinePost={true}
                currentUserId={currentUserId.toString()}
                reactions={state.reactions}
                removeReaction={removeReaction}
                addReaction={addReaction}
                isActive={!posting}
                disabled={posting}
                className={
                  state.reactions.find(
                    // eslint-disable-next-line eqeqeq
                    (reaction: any) => reaction?.user?.id == currentUserId
                  )
                    ? 'text-rb-jade-200 no-underline'
                    : ''
                }
              />
            </span>
            {postSidebarDisplay !== 'post-answer' && (
              <div
                className={`inline-post__meta ${
                  modifier ? `inline-post__meta--${modifier}` : ''
                }`}
              >
                {!deleting && !editing && (
                  <>
                    {canManagePost && (
                      <div className="mr-4 flex items-center justify-end gap-2">
                        <button onClick={enterEditMode}>
                          <PencilIcon width="16" height="16" />
                        </button>
                        <button onClick={showDeleteConfirmation}>
                          <TrashIcon width="16" height="16" />
                        </button>
                      </div>
                    )}
                  </>
                )}

                {deleting && (
                  <ConfirmDelete
                    disabled={posting}
                    confirmAction={deleteCurrentPost}
                    cancelAction={hideDeleteConfirmation}
                    type="comment"
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {displayReplies && !deleting && (
        <>
          {post.replies && post.replies.length > 0 && (
            <div>
              {post.replies.map((reply) => (
                <CmsReply
                  key={`reply${reply.id}`}
                  reply={reply}
                  updateReply={updateReply}
                  deleteReply={deleteReply}
                  currentUserId={currentUserId}
                />
              ))}
            </div>
          )}
          {!isReplying && (
            <div className="flex-1 p-5">
              <Button type="submit" onClick={showPostForm}>
                Reply
              </Button>
            </div>
          )}
        </>
      )}
      {displayReplies && isReplying && (
        <div
          id="add-reply"
          className="add-reply box-border bg-rb-white p-5 text-[15px] text-rb-black"
        >
          <div className="mb-2 flex-1">
            <label
              htmlFor="answer"
              className="uk-form-label font-sans text-xs font-medium text-rb-black"
            >
              Reply<span className="text-red-500">*</span>
            </label>
            <InlineCommentsFroalaWrapper
              model={state.reply}
              updateModel={(reply: string) => setState({ reply: reply })}
              placeholder="Write your reply here..."
              menuContainerId="add-reply"
              tributeOffset="!top-[-105px]"
            />
          </div>
          <div className="flex-1">
            <Button
              disabled={disabled}
              type="submit"
              className={disabled ? '!border-rb-gray-100 !bg-rb-gray-100' : ''}
              onClick={submitReply}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default React.forwardRef<HTMLDivElement, CmsPostProps>((props, ref) => (
  <CmsPost {...props} sidebar={ref} />
))
