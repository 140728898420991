import React, { useState } from 'react'
import { string } from 'yup'

import Button from 'components/Button'
import { WarningIcon } from 'components/icons'

import {
  TeamMembersPageSubscriptionFragment,
  TeamMembersPageSubscriptionSeatFragment,
  useAssignSeatMutation
} from 'gql'

import { displayErrorToast, displaySuccessToast } from 'utils/toastService'

import { PanelContentProps } from '.'

const REQUIRED_EMAIL = 'Email required'
const isValidEmail = (email: string): boolean => {
  return !!email && string().trim().email(REQUIRED_EMAIL).required().isValidSync(email)
}

export interface AssignSeatPanelContentProps extends PanelContentProps {
  seat: TeamMembersPageSubscriptionSeatFragment
  subscription: TeamMembersPageSubscriptionFragment
  onAssignCtaClick: () => void
}

export const AssignSeatPanelContent = ({
  seat,
  subscription,
  onActionSuccess,
  onAssignCtaClick,
  onCancelCtaClick
}: AssignSeatPanelContentProps) => {
  const [email, setEmail] = useState('')
  const [hasValidationError, setHasValidationError] = useState(false)
  const [assignSeat, { loading }] = useAssignSeatMutation()

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const emailInput = e.currentTarget.value
    setEmail(emailInput)
    setHasValidationError(Boolean(emailInput && !isValidEmail(emailInput)))
  }

  const handleSubmit = async () => {
    const result = await assignSeat({
      variables: {
        input: {
          seatId: seat.id,
          email,
          subscriptionId: subscription.id
        }
      }
    })
    if (result.errors || result.data?.assignSeat?.errors?.length) {
      displayErrorToast({
        message: result.data?.assignSeat?.errors?.join(' ') || 'Unable to send invite.'
      })
    } else {
      displaySuccessToast({ message: "You've successfully sent an invite." })
      onActionSuccess()
      onAssignCtaClick()
    }
  }

  const submitDisabled = Boolean(hasValidationError || !email)

  return (
    <>
      <div className="mt-12 mb-6">Invite a new member to join your team.</div>
      <form>
        <div className="mb-6 ">
          <input
            id="email"
            name="email"
            type="email"
            placeholder="jane@yourcompany.com"
            className={`${
              hasValidationError
                ? 'hover:border-2 hover:border-rb-destructive focus-visible:outline-none'
                : ''
            } w-full rounded-sm border-2 p-2 shadow-lg transition-all  placeholder-shown:italic`}
            data-test="invite-user-to-seat-input"
            value={email}
            onChange={handleInputChange}
            required
          />
          {hasValidationError ? (
            <>
              <WarningIcon className="pointer-events-none absolute ml-[225px] -mt-[38px] h-8 w-8 fill-rb-destructive 2xs:ml-[250px]" />
              <div className="mt-1 text-xs text-rb-destructive">Enter a valid email</div>
            </>
          ) : null}
        </div>
        <Button
          type="submit"
          disabled={submitDisabled}
          className="mb-4 mt-6 w-full"
          onClick={handleSubmit}
          isLoadingSpinner={loading}
        >
          Send Invite
        </Button>
        <Button className="w-full" variant="outline" onClick={onCancelCtaClick}>
          Cancel
        </Button>
      </form>
    </>
  )
}
