import { useGlobalModal } from 'components/GlobalModal'

import { useUserSubscriptionModalQuery } from 'gql'

import { trackModalDismissed, trackModalDisplayed } from 'hooks/useModalTracking'

import { getPrettyFullDate } from 'utils/date'

import CohortPassOptInSuccessModalContent from '../CohortPassOptInSuccessModalContent'
import CohortPassOptInModalContent from './CohortPassOptInModalContent'

export const useCohortPassOptIn = () => {
  const { openGlobalModal, closeGlobalModal } = useGlobalModal()
  const { loading, error, data } = useUserSubscriptionModalQuery()
  const activeSubscription = data?.currentUser?.subscriptions?.active

  if (loading) {
    return { openCohortPassOptInModal: () => {}, errors: error, loading: true }
  }
  if (error) {
    return { openCohortPassOptInModal: () => {}, errors: error, loading: false }
  }
  if (!activeSubscription) {
    return {
      openCohortPassOptInModal: () => {},
      errors: 'No active subscription for user.',
      loading: false
    }
  }

  const prettyExpirationDate = getPrettyFullDate(activeSubscription!.expiresAt)

  // Main modal
  const openCohortPassOptInModal = () => {
    trackModalDisplayed({
      modal_group: 'cohort_pass_discount_flow',
      modal_name: 'cohort_pass_addition_confirmation'
    })

    openGlobalModal(
      <CohortPassOptInModalContent
        handleClose={handleCohortPassOptInModalClose}
        onPassOptIn={showSuccessModal}
        upcomingChargeDate={prettyExpirationDate}
      />,
      {
        className: 'mx-2 max-w-[646px] rounded-lg',
        handleClose: handleCohortPassOptInModalClose
      }
    )
  }

  const handleCohortPassOptInModalClose = () => {
    trackModalDismissed({
      modal_group: 'cohort_pass_discount_flow',
      modal_name: 'cohort_pass_addition_confirmation'
    })

    closeGlobalModal()
  }

  const handleOptInSuccessClose = () => {
    trackModalDismissed({
      modal_group: 'cohort_pass_discount_flow',
      modal_name: 'cohort_pass_addition_success'
    })

    closeGlobalModal()
    window.location.reload()
  }

  // Follow-up success modal
  const showSuccessModal = () => {
    trackModalDisplayed({
      modal_group: 'cohort_pass_discount_flow',
      modal_name: 'cohort_pass_addition_success'
    })
    openGlobalModal(
      <CohortPassOptInSuccessModalContent
        handleClose={handleOptInSuccessClose}
        upcomingChargeDate={prettyExpirationDate}
      />,
      {
        className: 'mx-2 max-w-[646px] rounded-lg',
        handleClose: handleOptInSuccessClose
      }
    )
  }

  return {
    openCohortPassOptInModal,
    errors: error,
    loading: false
  }
}
