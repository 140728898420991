import React, { useEffect, useRef, useState } from 'react'

import { SimilarityContentFragment, useTrackServerEventMutation } from 'gql'

import { getAnonymousId } from 'utils/tracking/segment'

import ArtifactItem from './ArtifactItem'
import CourseItem from './CourseItem'
import GuideItem from './GuideItem'
import LessonItem from './LessonItem'

export interface SimilaritySearchResultItemProps {
  searchDocument?: SimilarityContentFragment
  searchQuery: string
  searchPosition: number
  userCan: {
    linkToSearchResults?: boolean | null
  }
  handleResultClick: (
    urL: string,
    path: string,
    searchPosition: number,
    resultComponent?: string,
    contentType?: string,
    subType?: string | null,
    doc?: object | null
  ) => void
  impressionTrackingProperties: { [key: string]: any }
}

export interface ContentResultItemProps {
  searchDocument: SimilarityContentFragment
  searchQuery: string
  userCan: {
    linkToSearchResults?: boolean | null
  }
  onResultClick: () => void
  impressionRef: React.RefObject<HTMLDivElement>
}

const ContentResultItems = {
  Course: CourseItem,
  Guide: GuideItem,
  Artifact: ArtifactItem,
  Lesson: LessonItem
}

const SimilaritySearchResultItem = ({
  searchDocument,
  searchQuery,
  userCan,
  handleResultClick,
  searchPosition,
  impressionTrackingProperties
}: SimilaritySearchResultItemProps) => {
  const [hasEnteredViewport, setHasEnteredViewport] = useState(false)
  const impressionRef = useRef<HTMLDivElement>(null)
  const [trackServerEvent] = useTrackServerEventMutation()

  useEffect(() => {
    if (!impressionTrackingProperties) return

    const impressionElement = impressionRef.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasEnteredViewport) {
          setHasEnteredViewport(true)

          const trackProperties: Record<string, any> = {
            title: searchDocument?.title,
            content_type: searchDocument?.contentType,
            location: impressionTrackingProperties.location,
            section: impressionTrackingProperties.section,
            section_index: impressionTrackingProperties.sectionIndex,
            section_impression_index: impressionTrackingProperties.sectionImpressionIndex
          }

          if (['Artifact', 'Guide'].includes(searchDocument?.contentType || '')) {
            trackProperties.sanity_id = searchDocument?.id
          } else if (searchDocument?.contentType === 'Course') {
            trackProperties.ccl_entity_id = searchDocument?.id
          } else if (searchDocument?.contentType === 'Lesson') {
            trackProperties.cms_lesson_id = searchDocument?.id
          }

          trackServerEvent({
            variables: {
              input: {
                anonymousId: getAnonymousId(),
                event: 'impressionViewed',
                properties: trackProperties
              }
            }
          })
        }
      },
      {
        threshold: 0.75 // trigger at 75% of card shown
      }
    )

    if (impressionElement) {
      observer.observe(impressionElement)
    }

    // Cleanup the observer on component unmount
    return () => {
      if (impressionElement) {
        observer.unobserve(impressionElement)
      }
    }
  }, [impressionTrackingProperties, hasEnteredViewport, searchDocument, trackServerEvent])

  if (!searchDocument) return null

  const ContentResultItem = ContentResultItems[searchDocument.contentType] || LessonItem
  const onResultClick = (searchDocument: any) => {
    const url = new URL(searchDocument.url, window.location.href)

    handleResultClick(
      url.href,
      url.pathname,
      searchPosition,
      'SimilaritySearchResultItem',
      searchDocument.contentType,
      searchDocument.subType,
      searchDocument
    )
  }

  return (
    <li className="list-none">
      <ContentResultItem
        searchDocument={searchDocument}
        searchQuery={searchQuery}
        userCan={userCan}
        onResultClick={() => onResultClick(searchDocument)}
        impressionRef={impressionRef}
      />
    </li>
  )
}

export default SimilaritySearchResultItem
