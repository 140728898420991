import * as React from 'react'
import { useHistory } from 'react-router-dom'

import Popover from 'components/Popover/Popover'
import ProgramIcon from 'components/cards/shared/ProgramIcon'
import TopProgressBar from 'components/cards/shared/TopProgressBar'
import { ChevronRightThinIcon } from 'components/icons'
import BookmarkIcon from 'components/icons/BookmarkIcon'
import EventsIcon from 'components/icons/EventsIcon'
import PlayCircleBorderIcon from 'components/icons/PlayCircleBorderIcon'

import { Maybe } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import { formatInTimezone } from 'utils/date'
import { onEnterKeyPress } from 'utils/keyboard'

export interface FullWidthCohortCardProps {
  nextModuleHref?: string
  weekCount?: number
  eventCount: number
  startsAt?: Maybe<string>
  endsAt?: Maybe<string>
  seasonName?: string
  programName: string
  lessonCount?: number
  numBookmarks?: number
  progressPercent?: number
  programIconImageUrl?: string
  cohortViewerHref: string
  onModifyClick: () => void
  openForEnrollment: boolean
  showMaterialIcons?: boolean
  isLive?: boolean
}

const FullWidthCohortCard = ({
  nextModuleHref,
  programName,
  seasonName,
  eventCount,
  weekCount,
  startsAt,
  endsAt,
  lessonCount,
  progressPercent,
  programIconImageUrl,
  cohortViewerHref,
  onModifyClick,
  openForEnrollment,
  numBookmarks = 0,
  showMaterialIcons = false,
  isLive = false
}: FullWidthCohortCardProps) => {
  const currentUser = useAssertCurrentUser()
  const history = useHistory()

  const navigateToCohortViewer = () => {
    if (isLive) {
      window.open(cohortViewerHref, '_blank')
    } else {
      history.push(cohortViewerHref)
    }
  }

  const navigateToNextModule = (
    e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>
  ) => {
    e.stopPropagation()
    if (nextModuleHref) {
      history.push(nextModuleHref)
    } else {
      history.push(cohortViewerHref)
    }
  }

  const navigateToBookmarks = (
    e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>
  ) => {
    e.stopPropagation()
    history.push(`${cohortViewerHref}/bookmarks`)
  }

  const onModify = (
    e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>
  ) => {
    e.stopPropagation()
    onModifyClick()
  }

  return (
    <div
      data-test="cohort-card"
      className="full-width-card rf-rb-card-interactive flex flex-col"
      onClick={navigateToCohortViewer}
      onKeyUp={onEnterKeyPress(navigateToCohortViewer)}
      tabIndex={-1}
      role="button"
    >
      <TopProgressBar progressPercent={progressPercent || 0} />
      <div className="flex flex-col pb-5 pr-5 sm:flex-row">
        <div className="mx-4 mt-3 flex flex-row items-center sm:items-start">
          {programIconImageUrl && (
            <ProgramIcon height={30} width={30} iconImageUrl={programIconImageUrl} />
          )}
        </div>
        <div className="flex w-full flex-col pt-2 pl-4 sm:pt-4 sm:pl-0">
          {seasonName && (
            <div className="text-xs font-medium uppercase tracking-widest text-rb-gray-300">
              {seasonName}
            </div>
          )}
          <div className="text-lg font-medium">{programName}</div>
          <div>
            {weekCount && (
              <span className="text-xs font-medium tracking-widest text-rb-gray-500">
                {weekCount} WEEKS &#8226;{' '}
              </span>
            )}
            <span className="text-xs font-medium tracking-widest">
              <span>
                {startsAt && formatInTimezone(startsAt, currentUser.timezone, 'MMM d')}{' '}
                &ndash;{' '}
              </span>
              <span>
                {endsAt && formatInTimezone(endsAt, currentUser.timezone, 'MMM d, yyyy')}
              </span>
            </span>
          </div>
          {showMaterialIcons && (
            <div className="mt-3 flex flex-row items-center justify-between">
              <div className="flex flex-row">
                <Popover
                  text="View Material"
                  tooltipClassName="text-xs left-0 p-1 py-1.5 w-28 text-center -left-[35px]"
                  offsetTop="-top-[40px]"
                  caretPosition="center"
                >
                  <div
                    className="flex flex-row items-center text-xs font-medium tracking-widest text-rb-gray-300 hover:text-rb-teal-300"
                    tabIndex={0}
                    role="button"
                  >
                    <PlayCircleBorderIcon className="h-5 w-5 fill-current" />
                    <span className="ml-2">{lessonCount}</span>
                  </div>
                </Popover>
                {eventCount > 0 && (
                  <Popover
                    text={`${eventCount} ${eventCount > 1 ? 'events' : 'event'}`}
                    containerClassName="ml-4 flex flex-row items-center text-xs font-medium tracking-widest text-rb-gray-300 hover:text-rb-teal-300"
                    tooltipClassName="text-xs left-0 p-1 py-1.5 w-24 text-center -left-[35px]"
                    offsetTop="-top-[40px]"
                    caretPosition="center"
                  >
                    <EventsIcon className="h-4 w-4 fill-current" />
                    <span className="ml-1">{eventCount}</span>
                  </Popover>
                )}
                {numBookmarks > 0 && (
                  <Popover
                    text={`View ${numBookmarks} ${
                      numBookmarks > 1 ? 'bookmarks' : 'bookmark'
                    }`}
                    tooltipClassName="text-xs left-0 p-1 py-1.5 w-28 text-center -left-[30px]"
                    offsetTop="-top-[54px]"
                    caretPosition="center"
                  >
                    <div
                      className="ml-4 flex flex-row items-center text-xs font-medium tracking-widest text-rb-gray-300 hover:text-rb-teal-300"
                      onClick={navigateToBookmarks}
                      onKeyUp={onEnterKeyPress(navigateToBookmarks)}
                      role="button"
                      tabIndex={0}
                    >
                      <span style={{ marginTop: '2px' }}>
                        <BookmarkIcon className="h-4 w-4 stroke-current" />
                      </span>
                      <span className="ml-1">{numBookmarks}</span>
                    </div>
                  </Popover>
                )}
              </div>
              {!openForEnrollment && Boolean(nextModuleHref) && (
                <div
                  className="flex flex-row items-center text-xs font-bold tracking-widest hover:text-rb-green-100"
                  onClick={navigateToNextModule}
                  onKeyUp={onEnterKeyPress(navigateToNextModule)}
                  tabIndex={0}
                  role="button"
                >
                  <div>CONTINUE</div>
                  <ChevronRightThinIcon className="ml-3 h-4 w-4 fill-current" />
                </div>
              )}
              {openForEnrollment && (
                <div
                  className="flex flex-row items-center text-xs font-bold tracking-widest text-rb-green-100 hover:underline"
                  onClick={onModify}
                  onKeyUp={onEnterKeyPress(onModify)}
                  tabIndex={0}
                  role="button"
                >
                  <div>Modify</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FullWidthCohortCard
