import { ArtifactWithWorkEntry } from 'gql'

import DefaultIcon from 'images/artifact-seo-no-company-icon.jpg'

export const getCompanyLogo = (artifact: ArtifactWithWorkEntry) => {
  const companyLogoAlt =
    artifact?.companyName ||
    (artifact.workEntry?.specificCompany
      ? artifact.workEntry.company?.logo?.imageAlt || artifact.workEntry.company?.name
      : '')
  const companyLogoUrl =
    artifact?.companyLogoUrl ||
    (artifact.workEntry?.specificCompany
      ? artifact.workEntry.company?.logo?.imageUrl
      : DefaultIcon)

  return {
    companyLogoAlt: companyLogoAlt || '',
    companyLogoUrl: companyLogoUrl || ''
  }
}
