import clsx from 'clsx'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { CmsSectionData } from 'domains/Cms/Toc'

import CardTooltip from 'components/CardTooltip'
import UpgradeUserCard from 'components/cards/UpgradeUserCard'

import { TocUserFragment } from 'gql'

import { track, trackContentStarted } from 'utils/tracking/analytics'

import { ReactComponent as LockIcon } from 'images/icon--lock.svg'

function hasAccessToLesson({
  isPremember,
  sectionPreviewable
}: {
  isPremember: boolean
  cmsModuleId?: string
  sectionId?: string
  sectionPreviewable: boolean
}) {
  if (isPremember && sectionPreviewable) return true
  if (!isPremember) return true
  return false
}

type Section = Pick<
  CmsSectionData,
  | 'id'
  | 'name'
  | 'completed'
  | 'current'
  | 'contentTypeIcon'
  | 'parentId'
  | 'estimatedTimeCaption'
  | 'href'
  | 'previewable'
  | 'isUnit'
  | 'slug'
  | 'contentSubtype'
  | 'contentType'
>

interface LessonLinkNoAccessProps {
  section: Section
  isParentOpen: boolean
  accessPolicyKind: string
  hasApplied: boolean
}

const LessonLinkNoAccess = ({
  section,
  isParentOpen,
  accessPolicyKind,
  hasApplied
}: LessonLinkNoAccessProps) => {
  const { name, completed, current, contentTypeIcon, estimatedTimeCaption, parentId } =
    section
  const derivedClassNames = []

  const content = (
    <UpgradeUserCard
      message="View this entire program or join a live cohort when you become a member."
      hasApplied={hasApplied}
      accessPolicyKind={accessPolicyKind}
    />
  )
  if (completed) {
    derivedClassNames.push(
      'cms-sidebar__lesson--completed !border-rb-green-100 !text-rb-gray-500'
    )
  }
  if (current) {
    derivedClassNames.push(
      'cms-sidebar__lesson--current relative font-normal !text-rb-gray-500 bg-rb-green-50 bg-opacity-20 !no-underline hover:bg-rb-gray-100 hover:bg-opacity-[35%]'
    )
  }
  return (
    <li className={isParentOpen && parentId ? 'pl-2.5 xs:pl-4 md:pl-8' : ''}>
      <CardTooltip
        content={content}
        delay={0}
        direction="top"
        className="-translate-x-1/2 -translate-y-full"
        yOffset={'0'}
        useRelPos
      >
        <div
          className={`uk-grid uk-grid-collapse cms-sidebar__lesson bg-[rgba(229, 229, 229, 0.5)] mb-[1px] border-l-[5px] border-solid border-rb-gray-100 pt-2.5 pr-0 pb-2.5 pl-[9px] leading-[1.2] !text-rb-gray-500 hover:bg-rb-gray-100 hover:bg-opacity-[35%] hover:!text-rb-gray-500 ${derivedClassNames.join(
            ' '
          )}`}
        >
          <div
            className="cms-sidebar__icon uk-width-auto mt-[3px] block"
            uk-icon={contentTypeIcon}
          />
          <div className="uk-width-expand uk-text-muted uk-visible-toggle  pl-[6px] text-xs !leading-4">
            {name}
          </div>
          <LockIcon className="mx-1 w-2.5 self-center text-rb-gray-300" />
          {estimatedTimeCaption && (
            <div className="uk-width-auto self-center pr-2.5 font-medium xs:pr-4 md:pr-8">
              {estimatedTimeCaption}
            </div>
          )}
        </div>
      </CardTooltip>
    </li>
  )
}

interface LessonLinkProps {
  section: Section
  numSectionBookmarks: number
  isParentOpen: boolean
  currentLessonId: string
  cohortViewer?: boolean
  cohortSlug?: string
}

const LessonLink = ({
  numSectionBookmarks,
  section,
  currentLessonId,
  isParentOpen,
  cohortViewer,
  cohortSlug
}: LessonLinkProps) => {
  const history = useHistory()
  const {
    name,
    completed,
    href,
    contentTypeIcon,
    estimatedTimeCaption,
    parentId,
    contentSubtype,
    contentType
  } = section
  const current = currentLessonId === section.id
  const derivedClassNames = []
  const shouldDisplayUnits = ['Unit', 'Resource'].includes(contentType || '')

  if (completed) {
    derivedClassNames.push(
      'cms-sidebar__lesson--completed !border-rb-green-100 !text-rb-gray-300'
    )
  }
  if (current) {
    derivedClassNames.push(
      'cms-sidebar__lesson--current relative font-medium !text-rb-gray-500 !no-underline'
    )
  }

  const handleClick = () => {
    // @ts-ignore - 'Content Viewer - Action' event is not defined in Segment JIRA#REF-5159
    track('Content Viewer - Action', {
      action: 'ToC__click_item',
      location: window.location.pathname,
      item: name,
      cms_section_id: section.id
    })

    if (cohortViewer && cohortSlug) {
      const cohortLessonViewerHref = `/cohorts/${cohortSlug}${href}`
      history.push(cohortLessonViewerHref)
    } else {
      history.push(href)
    }
  }

  return (
    <li className={isParentOpen && parentId ? 'pl-2.5 xs:pl-4 md:pl-8' : ''}>
      <div
        onClick={handleClick}
        className="my-1 cursor-pointer font-sans !text-[15px] text-rb-gray-500"
      >
        <div
          className={`uk-grid uk-grid-collapse cms-sidebar__lesson bg-[rgba(229,  229, 229, 0.5)] mb-[1px] items-center border-solid border-rb-gray-100 pr-0 leading-[1.2] !text-rb-gray-500 ${derivedClassNames.join(
            ' '
          )}`}
        >
          <div
            className={clsx(
              'uk-width-auto mr-1 w-[5px] rounded border-l-[5px]',
              completed && 'border-rb-green-100',
              shouldDisplayUnits ? 'h-[66px]' : 'h-[30px]'
            )}
          ></div>
          <div
            className={clsx(
              'uk-width-expand uk-grid uk-grid-collapse items-center rounded-l pl-1 hover:!text-rb-gray-500',
              current
                ? 'bg-rb-green-85 bg-opacity-60 hover:bg-rb-green-85 hover:bg-opacity-[65%]'
                : 'hover:bg-rb-gray-100 hover:bg-opacity-[35%]',
              shouldDisplayUnits ? 'h-[66px]' : 'h-[30px]'
            )}
          >
            {!shouldDisplayUnits && (
              <div
                className="cms-sidebar__icon uk-width-auto block"
                uk-icon={contentTypeIcon}
              />
            )}
            <div
              className={clsx(
                'uk-width-expand pl-[6px] text-xs',
                shouldDisplayUnits ? '!leading-[18px]' : '!leading-4'
              )}
            >
              {shouldDisplayUnits && <div className="font-normal">{contentSubtype}</div>}
              <div className={clsx(shouldDisplayUnits && 'font-semibold')}>{name}</div>
            </div>
            {numSectionBookmarks > 0 && (
              <div className="uk-width-auto pr-[5px] pl-[5px] text-right text-xs font-normal text-rb-gray-500 opacity-0 2xs:opacity-100">
                {numSectionBookmarks}
                <div
                  className="bookmark-toggle h-2.5 w-2.5"
                  data-bookmarked="true"
                  uk-icon="icon: bookmark; ratio: 0.7"
                />
              </div>
            )}
            {estimatedTimeCaption !== '' && (
              <div
                className={clsx(
                  'uk-width-auto pr-2.5 text-xs xs:pr-4 md:pr-8',
                  current && shouldDisplayUnits && 'font-semibold'
                )}
              >
                {estimatedTimeCaption}
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  )
}

interface CmsTocLessonProps {
  numSectionBookmarks: number
  section: Section
  isParentSection?: boolean
  isOpen: boolean
  onToggle?: () => void
  cmsModuleId?: string
  currentUser?: TocUserFragment
  currentLessonId: string
  cohortViewer?: boolean
  cohortSlug?: string
}

const CmsTocLesson = ({
  numSectionBookmarks,
  isParentSection = false,
  cmsModuleId,
  section,
  isOpen,
  currentUser,
  currentLessonId,
  onToggle = () => {},
  cohortViewer = false,
  cohortSlug
}: CmsTocLessonProps) => {
  const { id, name, contentType, completed, estimatedTimeCaption, current } = section

  const isPremember = currentUser?.is.premember || false

  const lessonLink = hasAccessToLesson({
    isPremember,
    cmsModuleId,
    sectionId: section.id,
    sectionPreviewable: section.previewable
  }) ? (
    <LessonLink
      numSectionBookmarks={numSectionBookmarks}
      section={section}
      isParentOpen={isOpen}
      currentLessonId={currentLessonId}
      cohortViewer={cohortViewer}
      cohortSlug={cohortSlug}
    />
  ) : (
    <LessonLinkNoAccess
      section={section}
      isParentOpen={isOpen}
      accessPolicyKind={currentUser?.accessPolicyKind || ''}
      hasApplied={currentUser?.hasApplied || false}
    />
  )

  useEffect(() => {
    // check if the rendered section is the current section
    if (current) {
      // track content started, TocLesson is only rendered for Programs
      trackContentStarted({
        content_id: id,
        content_name: name,
        content_type: contentType,
        path: window.location.pathname
      })
    }
  }, [id, name, current, contentType])

  const history = useHistory()

  const handleClick = () => {
    // @ts-ignore - 'Content Viewer - Action' event is not defined in Segment JIRA#REF-5159
    track('Content Viewer - Action', {
      action: 'ToC__click_item',
      location: window.location.pathname,
      item: name,
      cms_section_id: section.id
    })

    if (cohortViewer && cohortSlug) {
      if (contentType !== 'Section') {
        const cohortLessonViewerHref = `/cohorts/${cohortSlug}${section.href}`
        history.push(cohortLessonViewerHref)
      }
    } else {
      history.push(section.href)
    }

    onToggle()
  }

  return (
    <>
      {isParentSection && (
        <li onClick={handleClick}>
          <div
            className={`uk-grid uk-grid-collapse uk-accordion-title cms-sidebar__title px-0 py-2 !text-sm font-medium !text-rb-gray-500 hover:bg-rb-gray-100 hover:bg-opacity-[35%] ${
              completed
                ? 'cms-sidebar__title--completed decoration-[rgba(130, 130, 130, 0.5)] !border-rb-green-100 !text-rb-gray-300'
                : ''
            } ${
              isOpen ? 'ml-[5px] rounded-l !border-l-0' : 'cms-sidebar__title--closed'
            }`}
          >
            <i
              className="uk-width-auto"
              uk-icon={isOpen ? 'triangle-down' : 'triangle-right'}
            />
            <div className="uk-width-expand">{name}</div>
            {!isOpen && numSectionBookmarks > 0 && (
              <div className="uk-width-auto pr-[5px] pl-[5px] text-right text-xs font-normal text-rb-gray-500 opacity-0 2xs:opacity-100">
                {numSectionBookmarks}
                <div
                  className="bookmark-toggle h-2.5 w-2.5"
                  data-bookmarked="true"
                  uk-icon="icon: bookmark; ratio: 0.7"
                />
              </div>
            )}
            {!isOpen && estimatedTimeCaption !== '' && (
              <div className="uk-width-auto pr-2.5 text-xs font-normal text-rb-gray-500 xs:pr-4 md:pr-8  ">
                {estimatedTimeCaption}
              </div>
            )}
          </div>
        </li>
      )}
      {!isParentSection && lessonLink}
    </>
  )
}

export default CmsTocLesson
