/* eslint-disable react/prop-types */
import { Fragment, useRef, useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'

import GroupsList from 'domains/Group/GroupsList'
import JoinGroupPrompt from 'domains/Group/JoinGroupPrompt'
import ArchivedBanner from 'domains/Post/ArchivedBanner'
import PostList from 'domains/Post/PostList'
import MobileFilters from 'domains/Program/MobileFilters'

import Filters from 'components/Filters'
import { CheckboxFilterSet } from 'components/Filters/CheckboxFilter'
import { PeopleFilterSet } from 'components/Filters/PeopleFilter'
import { TagFilterSet } from 'components/Filters/TagFilter'
import { ShareableSnapshotModal } from 'components/ShareableSnapshotModal'

import { PostSource, useIndustriesListQuery, useUserGroupMembershipQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useScrollToTopOnLocationChange from 'hooks/useScrollToTopOnLocationChange'

import { SortByFilterSet } from '../components/Filters/SortBy'

type RouteParams = {
  topic?: string
  group?: string
  slug?: string
  followId?: string
  followType?: string
}

const PostsAll = () => {
  const { currentUser, currentUserLoading } = useCurrentUser()
  const { data: industryData } = useIndustriesListQuery()
  const industries = industryData?.industries
  const { data: groupMembershipData, loading: groupMembershipLoading } =
    useUserGroupMembershipQuery()
  const userIsMemberOfAGroup = (groupMembershipData?.groups?.groups || []).some(
    (g) => g.userIsMember
  )

  const filterKeys = [
    'page',
    'sort_by',
    'post_type',
    'contributors',
    'audience',
    'industries'
  ] // initial state will be set from url variables
  const filterOptions = [
    {
      key: 'sort_by',
      type: 'sortby',
      options: [
        ['recent', 'Recent'],
        ['popularity', 'Popular']
      ],
      defaultOptionIndex: 1
    } as SortByFilterSet,
    {
      key: 'post_type',
      type: 'checkbox',
      title: 'Post Type',
      options: [
        ['following', 'Following'],
        ['myPosts', 'My posts'],
        ['unansweredPosts', 'Unanswered posts']
      ]
    } as CheckboxFilterSet,
    {
      key: 'contributors',
      type: 'people',
      title: 'Contributors',
      explainer: 'Find posts and responses by peers, experts, and the Reforge team'
    } as PeopleFilterSet,
    {
      key: 'audience',
      type: 'checkbox',
      title: 'Company Audience',
      explainer: 'Browse posts that pertain to specific audiences and business types',
      options: [
        ['B2B', 'B2B'],
        ['B2C', 'B2C'],
        ['B2B2C', 'B2B2C']
      ]
    } as CheckboxFilterSet,
    {
      key: 'industries',
      type: 'tag',
      title: 'Industries',
      explainer: 'Discover conversations about specific verticals and business domains',
      options: industries
        ? industries.map((industry) => [industry.slug, industry.name])
        : []
    } as TagFilterSet
  ]

  const [filters, setFilters] = useState(
    {}
  ) /* read by the posts list component set by the filters component. Defaults to url params */
  const hashChecked = useRef(false)
  const [busy, setBusy] = useState(false)
  const history = useHistory()
  const urlParams = useParams<RouteParams>()
  const topicFromUrl = urlParams.topic
    ?.split('-')
    .map((word: string) =>
      word.length ? `${word[0].toUpperCase()}${word.slice(1)}` : ''
    )
    .join(' ')

  useScrollToTopOnLocationChange()

  if (!currentUserLoading && (!currentUser || !currentUser.can.viewDiscussionIndex)) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <ArchivedBanner currentUser={currentUser} />
      <ShareableSnapshotModal page="Posts" />
      <div className="py-0 xs:py-4 md:py-8">
        <div className="ml-0" uk-grid="">
          <div className="uk-margin-top w-full pl-0 lg:w-3/4">
            <div className="lg:pr-8">
              <MobileFilters />
              {!groupMembershipLoading &&
                !userIsMemberOfAGroup &&
                hashChecked.current && <JoinGroupPrompt />}
              {hashChecked.current && (
                <PostList
                  filters={filters}
                  topic={topicFromUrl}
                  setFilters={setFilters}
                  setBusy={setBusy}
                  postSource={PostSource.DISCUSSION}
                />
              )}
            </div>
          </div>
          <div className="uk-margin-top hidden pl-0 lg:flex lg:w-1/4">
            <div className="w-full">
              <Filters
                filterKeys={filterKeys}
                filters={filters}
                setFilters={setFilters}
                history={history}
                filterOptions={filterOptions}
                busy={busy}
              />
              {hashChecked.current && <GroupsList />}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default PostsAll
