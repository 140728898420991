import { useState } from 'react'

import { getModuleProgress } from 'domains/Concepts/ConceptCardList/utils'

import CollapsibleSectionCardBody from 'components/CollapsibleSectionCard/CollapsibleSectionCardBody'
import CollapsibleSectionCardHeader from 'components/CollapsibleSectionCard/CollapsibleSectionCardHeader'
import SectionCardContainer from 'components/CollapsibleSectionCard/SectionCardContainer'

import {
  CohortDashboardCmsSectionPartsFragment,
  CohortDashboardLessonPartsFragment,
  CohortDashboardSectionCardCmsModulePartsFragment,
  CohortDashboardUserProgramPartsFragment
} from 'gql'

import { isAfterDate } from 'utils/date'

import { ModuleProgress } from 'typings/scalars'

export interface CollapsibleSectionCardProps {
  module: CohortDashboardSectionCardCmsModulePartsFragment
  section: CohortDashboardCmsSectionPartsFragment
  number: number
  activeProgram: CohortDashboardUserProgramPartsFragment
  slug: string
  defaultIsExpanded?: boolean
  onTrack?: (lesson: CohortDashboardLessonPartsFragment) => void
}

const CollapsibleSectionCard = ({
  module,
  number,
  activeProgram,
  section,
  slug,
  defaultIsExpanded = false,
  onTrack
}: CollapsibleSectionCardProps) => {
  // automatically expand the first section listed in each week
  // for the kickoff week (scheduledWeekIndex of 0), that would be the gettingStarted module
  const [isExpanded, setIsExpanded] = useState(defaultIsExpanded)

  const moduleProgress = getModuleProgress(activeProgram, module.id)
  const userProgress = ((module.project
    ? moduleProgress[`${module.project.id}d`]
    : moduleProgress) || {}) as ModuleProgress

  const lockLesson = !module.published || !isAfterDate(module.publishAt)

  return (
    <SectionCardContainer darkenBorderOnHover={!isExpanded}>
      <CollapsibleSectionCardHeader
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        userProgress={userProgress}
        section={section}
        number={number}
      />
      <CollapsibleSectionCardBody
        isExpanded={isExpanded}
        userProgress={userProgress}
        section={section}
        slug={slug}
        lockLesson={lockLesson}
        onTrack={onTrack}
      />
    </SectionCardContainer>
  )
}

export default CollapsibleSectionCard
