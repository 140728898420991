import { useEffect, useState } from 'react'
import UIkit from 'uikit'

import ConnectedAccounts from 'domains/User/ConnectedAccounts'
import EmailAddForm from 'domains/User/EmailAddForm'

import { SVGIcon } from 'components/Icon'
import Link from 'components/Link'
import Loading from 'components/Loading'

import {
  UserOauthProvider,
  useDeleteEmailMutation,
  useMakeEmailPrimaryMutation,
  useSendEmailVerifyMutation,
  useUserEmailsQuery
} from 'gql'

interface EmailFormProps {
  oauthProviders?: UserOauthProvider[] | null
}

const EmailsForm = ({ oauthProviders }: EmailFormProps) => {
  const [loading, setLoading] = useState(false)
  const { data, refetch } = useUserEmailsQuery()
  const [deleteEmailMutation, { error: deleteError }] = useDeleteEmailMutation()
  const [makeEmailPrimaryMutation, { error: makePrimaryError }] =
    useMakeEmailPrimaryMutation()
  const [sendEmailVerifyMutation, { error: verifyError }] = useSendEmailVerifyMutation()

  useEffect(() => {
    const message =
      deleteError?.message || makePrimaryError?.message || verifyError?.message || null

    if (message) {
      UIkit.notification(`Error: ${message}`, {
        status: 'danger',
        pos: 'top-right'
      })
    }
  }, [deleteError, makePrimaryError, verifyError])

  const onDelete = async (id: string) => {
    setLoading(true)
    await deleteEmailMutation({ variables: { input: { id } } })
    UIkit.notification('Deleted', { status: 'success', pos: 'top-right' })
    await refetch()
    setLoading(false)
  }

  const makePrimary = async (id: string) => {
    setLoading(true)
    await makeEmailPrimaryMutation({ variables: { input: { id } } })
    UIkit.notification('Marked as primary', { status: 'success', pos: 'top-right' })
    await refetch()
    setLoading(false)
  }

  const sendVerifyEmail = async (id: string) => {
    setLoading(true)
    await sendEmailVerifyMutation({ variables: { input: { id } } })
    UIkit.notification('Sent', { status: 'success', pos: 'top-right' })
    await refetch()
    setLoading(false)
  }

  const thClassName =
    'tl:!py-[7px] tl:!px-3 !py-1 !px-1.5 !text-xs !font-medium !text-rb-gray-300 !tracking-widest'
  const tdClassName = 'tl:!py-[7px] tl:!px-3 !py-1 !px-1.5'

  return (
    <div data-test="account-connected-accounts" className="rf-rb-card mb-5 bg-white p-6">
      <h3 className="text-xl font-medium text-black">Connected Accounts</h3>
      <div className="my-4">
        <span className="text-rb-gray-400">
          Use your credentials for the sites below to login to Reforge quickly, easily,
          and securely
        </span>
      </div>

      <div>
        <ConnectedAccounts oauthProviders={oauthProviders} />
      </div>

      <span className="text-rb-gray-300">
        Change your email address here to update all communications sent to you by Reforge
      </span>

      {loading && <Loading />}

      {!loading && (
        <table className="uk-table uk-table-small uk-text-left uk-table-middle uk-table-striped text-[13px] text-rb-black tl:text-sm">
          <thead>
            <tr>
              <th className={thClassName}>EMAIL</th>
              <th className={thClassName}>PREFERENCES</th>
            </tr>
          </thead>
          <tbody>
            {data?.userEmails.map((email) => (
              <tr key={`sh${email.id}`}>
                <td className={tdClassName}>{email.email}</td>
                <td className={tdClassName}>
                  {email.isPrimaryEmail && (
                    <span className="uk-badge mr-2.5 bg-rb-gray-100 text-xs font-medium tracking-widest">
                      PRIMARY
                    </span>
                  )}
                  <span>
                    {!email.confirmedByUser && (
                      <Link onClick={() => sendVerifyEmail(email.id)}>
                        Resend Verify Email
                      </Link>
                    )}
                    {email.confirmedByUser && !email.isPrimaryEmail && (
                      <a onClick={() => makePrimary(email.id)}>Make Primary</a>
                    )}
                  </span>
                </td>
                <td className={tdClassName}>
                  {!email.isPrimaryEmail && (
                    <a
                      className="uk-margin-small-left block"
                      onClick={() => onDelete(email.id)}
                    >
                      <SVGIcon
                        className="cursor-pointer"
                        name="trash"
                        with="16"
                        height="16"
                        fill="#A2A1A2"
                      />
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <EmailAddForm fetchData={refetch} />
    </div>
  )
}

export default EmailsForm
