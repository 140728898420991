import { object, string } from 'yup'

import { Form, InputText } from 'components/forms'

import { sendFormData } from 'utils/sendData'

const formValidation = object().shape({
  email: string().trim().email('Invalid email.').required().label('Email')
})

function DeveloperLogin({ loginParams }: { loginParams: Record<string, unknown> }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { fwd, ...restLoginParams } = loginParams

  return (
    <div className="my-4">
      <Form
        submit={({ email }: { email: string }) => {
          sendFormData('/auth/developer/callback', 'POST', {
            email,
            name: 'Goat',
            ...restLoginParams
          }).then((response: Response) => {
            window.location.assign(response.url)
          })
        }}
        validationMode="onBlur"
        validationSchema={formValidation}
        submitButtonText="Login"
        submitButtonProps={{ size: 'x-small' }}
        submitButtonClassname="mt-[-2rem]"
      >
        <InputText
          type="email"
          name="email"
          label="Developer login"
          placeholder="goats@reforge.com"
        />
      </Form>
    </div>
  )
}

export default DeveloperLogin
