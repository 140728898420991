import ReactDOM from 'react-dom'

import MarkCompleteButton from 'domains/Cms/MarkCompleteButton'
import { NextPhaseButton } from 'domains/Cms/NextPhaseButton'

import { CmsSectionProgress, ProgressStatus } from 'typings/scalars'

interface ICtaButtons {
  cmsContentId: string
  progress: CmsSectionProgress
  onCtaStatusChange: (a: string, b: ProgressStatus) => void
}

const CtaButtons = ({ cmsContentId, progress, onCtaStatusChange }: ICtaButtons) => {
  const ctaBlocks = Array.from(document.querySelectorAll('.cms-cta'))
  let currButtonNum = 0
  const nextSectionBlocks = Array.from(document.querySelectorAll('.cms-next-section'))
  let nextPhaseNum = 0

  return (
    <>
      {ctaBlocks.map((ctaBlock) => {
        const ctaInner = ctaBlock.getElementsByTagName('P')
        if (ctaInner.length === 0) {
          return null
        }

        for (let i = 0; i < ctaInner.length; i++) {
          const ctaEl = ctaInner[i]

          // do not remove the below classes
          const completeButton = ctaEl.querySelectorAll<HTMLElement>(
            '.rf-button-mark-complete'
          )
          const completeButtonText = ctaEl.getElementsByClassName(
            'rf-button-mark-complete__text'
          )
          const blockId = ctaBlock.getAttribute('data-parent-id')

          if (completeButton.length === 0 || completeButtonText.length === 0) {
            continue
          }

          completeButton[0].style.display = 'none'
          const status = blockId ? progress[blockId] : 0

          currButtonNum += 1
          return ReactDOM.createPortal(
            <MarkCompleteButton
              buttonNum={currButtonNum}
              cmsContentId={cmsContentId}
              blockId={blockId}
              status={status}
              onCtaStatusChange={onCtaStatusChange}
            />,
            ctaEl
          )
        }

        return null
      })}

      {nextSectionBlocks.map((nextSectionBlock) => {
        // do not remove this class
        const nextSectionButton = nextSectionBlock.querySelectorAll<HTMLElement>(
          '.rf-button-next-section'
        )
        const button = nextSectionButton[0]
        let buttonText
        if (button) {
          buttonText = button.innerText
        }
        button.style.display = 'none'
        nextPhaseNum += 1
        return ReactDOM.createPortal(
          <NextPhaseButton nextPhaseNum={nextPhaseNum} buttonText={buttonText} />,
          nextSectionBlock
        )
      })}
    </>
  )
}

export default CtaButtons
