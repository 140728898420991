import Lottie from 'lottie-react'
import { ReactElement, useEffect, useState } from 'react'

import ButtonMarketingCTA from 'domains/Sanity/marketingSite/ButtonMarketingCTA'

import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'

import useMediaQuery from 'hooks/useMediaQuery'

import { cn } from 'utils/tailwind'

import { ReactComponent as PlusIcon } from 'images/icon--plus-sign.svg'
import { ReactComponent as ChromeLogo } from 'images/logo--chrome.svg'
import expertAndrew from 'images/marketing-extension/expert-andrew.png'
import expertCasey from 'images/marketing-extension/expert-casey.png'
import expertCrystal from 'images/marketing-extension/expert-crystal.png'
import expertDan from 'images/marketing-extension/expert-dan.png'
import expertElena from 'images/marketing-extension/expert-elena.png'
import expertJiaona from 'images/marketing-extension/expert-jiaona.png'
import expertKira from 'images/marketing-extension/expert-kira.png'
import expertRavi from 'images/marketing-extension/expert-ravi.png'
import expertShaun from 'images/marketing-extension/expert-shaun.png'
import logoAmplitude from 'images/marketing-extension/logo-amplitude.png'
import logoCoda from 'images/marketing-extension/logo-coda.png'
import logoConfluence from 'images/marketing-extension/logo-confluence.png'
import logoGoogleDocs from 'images/marketing-extension/logo-google-docs.png'
import logoGrubhub from 'images/marketing-extension/logo-grubhub.png'
import logoMeta from 'images/marketing-extension/logo-meta.png'
import logoNotion from 'images/marketing-extension/logo-notion.png'
import logoToggl from 'images/marketing-extension/logo-toggl.png'
import { ReactComponent as PromptInterface } from 'images/marketing-extension/prompt-interface.svg'
import testimonialChris from 'images/marketing-extension/testimonial-chris.jpg'
import testimonialEryn from 'images/marketing-extension/testimonial-eryn.jpg'
import testimonialMargo from 'images/marketing-extension/testimonial-margo.jpg'

import animationDocumentStack from './animation-document-stack.json'

const AddToChromeButton = () => {
  return (
    <ButtonMarketingCTA
      href="https://chromewebstore.google.com/detail/reforge-extension/iheecpmppehgghpjbhdpgjdllemcmdnk"
      location="/chrome page"
      ctaText="Add to chrome"
      target="_blank"
    >
      <ChromeLogo width={24} height={24} className="mr-4" />
      <span>Add to Chrome</span>
    </ButtonMarketingCTA>
  )
}

const questionsAndAnswers = [
  {
    question: 'What data do you use and store?',
    answer: (
      <p>
        The extension only uses the content of the page you&apos;re viewing to tailor
        advice, without accessing or storing any other personal or company information.
        All data processing is temporary, ensuring your privacy during each session.
      </p>
    )
  },
  {
    question: 'How do you ensure privacy with your providers?',
    answer: (
      <p>
        Our technology partners process data temporarily and adhere to strict privacy
        policies that prohibit retaining or using your information beyond providing
        immediate feedback. This ensures your data&apos;s security and confidentiality.
      </p>
    )
  },
  {
    question: 'How does this tool differ from other AI tools like ChatGPT?',
    answer: (
      <p>
        Our responses are based on expert insights from Reforge, offering higher quality
        and more targeted advice than general AI tools. Links to in-depth Reforge material
        provide further learning opportunities.
      </p>
    )
  },
  {
    question: 'Is a paid Reforge membership required?',
    answer: (
      <p>
        No paid membership is required to use the extension. Access to certain linked
        Reforge materials may require a membership.
      </p>
    )
  },
  {
    question: 'Which platforms and browsers does this extension support?',
    answer: (
      <div>
        <p className="mb-4">
          The extension works in Chrome, Arc, Edge, and Brave but not in Safari. It
          integrates with Notion, Coda, Confluence, and Google Docs.
        </p>
      </div>
    )
  }
]

const Experts = () => {
  const experts = [
    {
      headshotImage: expertShaun
    },
    {
      headshotImage: expertCasey
    },
    {
      headshotImage: expertCrystal
    },
    {
      headshotImage: expertDan
    },
    {
      headshotImage: expertRavi
    },
    {
      headshotImage: expertJiaona
    },
    {
      headshotImage: expertAndrew
    },
    {
      headshotImage: expertKira
    },
    {
      headshotImage: expertElena
    }
  ]

  /* negative margin matches <main /> element */
  return (
    <div className="overflow-hidden -mx-4 md:-mx-[4vw]">
      <ul className="list-none pl-0 w-full flex justify-center">
        {experts.map((expert, i) => (
          <li key={i} className="shrink-0 mx-4">
            <img
              src={expert.headshotImage}
              alt="Expert Avatar"
              width="280"
              height="340"
              className="rounded-2xl"
            />
          </li>
        ))}
      </ul>
    </div>
  )
}

const DocumentTypes = () => {
  const documentTypes = [
    'User persona',
    'Job description',
    'Product requirements document',
    'Competitive analysis',
    'Go to market strategy',
    'Product roadmap',
    'Interview hire process',
    'Project timeline',
    'Technical specifications document',
    'Employee onboarding plan',
    'User research testing plan',
    'Career growth plan',
    '+ more'
  ]
  const maxShown = 6
  const isMobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)
  const [limitItemsShown, setLimitItemsShown] = useState<number | undefined>(
    isMobileView ? maxShown : undefined
  )
  const showMore = () => {
    setLimitItemsShown(undefined)
  }

  useEffect(() => {
    if (isMobileView) {
      setLimitItemsShown(maxShown)
    } else {
      setLimitItemsShown(undefined)
    }
  }, [isMobileView])

  return (
    <div>
      <ul className="list-none pl-0 w-full flex flex-wrap gap-4 justify-center mb-4">
        {documentTypes.slice(0, limitItemsShown).map((documentType) => (
          <li
            key={documentType}
            className="shrink-0 leading-[150%] tracking-[-0.198px] rounded-full bg-black/[0.03] px-6 py-2"
          >
            {documentType}
          </li>
        ))}
      </ul>
      <div className="flex justify-center w-full">
        <button
          onClick={showMore}
          className={cn(
            'leading-[150%] tracking-[-0.198px] rounded-full bg-black text-white px-6 py-2 transition-all opacity-0',
            limitItemsShown && '!opacity-100'
          )}
        >
          Show more
        </button>
      </div>
    </div>
  )
}
const QuestionAndAnswer = ({
  question,
  answer
}: {
  question: string
  answer: ReactElement
  key: number
}) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const toggle = () => {
    setExpanded(!expanded)
  }
  return (
    <div className="border-b border-black/10 mb-6 last:mb-0">
      <div className="flex justify-between items-start mb-6">
        <h3 className="!font-sans font-semibold text-base leading-[130%] w-full grow text-[#0C0F0D] mb-0 mr-4">
          <button onClick={toggle} className="block text-left">
            {question}
          </button>
        </h3>
        <button
          onClick={toggle}
          className={cn('transition-all duration-500', expanded && 'rotate-45')}
        >
          <PlusIcon width={16} height={16} />
        </button>
      </div>
      <div
        className={cn(
          'max-h-0 overflow-hidden transition-all duration-500 max-w-[700px]',
          expanded && 'max-h-[150px]'
        )}
      >
        <div className="mb-0 pb-6 leading-[150%]">{answer}</div>
      </div>
    </div>
  )
}
export const ExtensionMarketingPageContent = () => {
  useEffect(() => {
    document.querySelectorAll<HTMLElement>('.marketing-site')[0].style.background =
      'linear-gradient(173deg, #F0FFFC 0.38%, #FAFFFD 93.26%)'
  }, [])

  return (
    <main className="px-[16px] py-[6.6vmax] md:px-[4vw] flex flex-col items-center text-[#0C0F0D]">
      <section className="max-w-[1048px] w-full flex flex-col items-center mb-16">
        <aside className="mb-7 flex items-center">
          <span className="leading-[120%] font-semibold mr-3">Chrome extension</span>
          <span className="text-[#757B74] bg-black/5 text-xs leading-none bg-red rounded py-0.5 px-1.5">
            Beta
          </span>
        </aside>
        <h1 className="text-5xl leading-[120%] md:text-[64px] md:leading-[130%] mb-3 font-polysans text-center text-[#0C0F0D] font-normal">
          An expert by your side, as you work
        </h1>
        <p className="mb-12 text-center text-lg leading-[150%]">
          Instant feedback from those who have done it before in the places you work.
        </p>
        <div className="mb-16">
          <AddToChromeButton />
        </div>
        <div className="w-full">
          <Lottie
            animationData={animationDocumentStack}
            loop={true}
            className="chromatic-ignore"
          />
        </div>
      </section>

      <section className="max-w-[560px] w-full flex flex-col items-center mb-32 md:mb-40">
        <h2 className="!font-sans font-normal text-[#0C0F0D] text-lg leading-[150%] text-center mb-8">
          Helping teams do better work
        </h2>
        {/* <ul className="list-none pl-0 w-full grid gap-y-8 grid-cols-2 sm:grid-cols-4"> */}
        {/* <ul className="list-none pl-0flex flex-col md:flex-row md:w-full md:justify-between gap-y-10 mb-14 rounded-xl bg-black/[0.03]"></ul> */}
        <ul className="list-none pl-0 w-full flex flex-wrap gap-x-12 gap-y-8 justify-center items-center">
          <li className="flex justify-center">
            <img src={logoGrubhub} width="114" height="32" alt="Grubhub Logo" />
          </li>
          <li className="flex justify-center">
            <img src={logoMeta} width="100" height="20" alt="Meta Logo" />
          </li>
          <li className="flex justify-center">
            <img src={logoToggl} width="64" height="32" alt="Toggl Logo" />
          </li>
          <li className="flex justify-center">
            <img src={logoAmplitude} width="130" height="32" alt="Amplitude Logo" />
          </li>
        </ul>
      </section>

      <section className="w-full flex flex-col items-center mb-32 md:mb-40">
        <h2 className="text-[#0C0F0D] text-[32px] md:text-[48px] leading-[105%] md:leading-[120%] font-polysans font-normal text-center mb-3">
          Grounded in the work from leading experts
        </h2>
        <p className="leading-[150%] tracking-[-0.176px] text-center text-lg mb-10">
          Make better decisions with the expertise from those who have done it before.
        </p>
        <Experts />
      </section>

      <section className="max-w-[1048px] w-full flex flex-col items-center mb-32 md:mb-40">
        <h2 className="text-[#0C0F0D] text-[32px] md:text-[48px] leading-[105%] md:leading-[120%] font-polysans font-normal text-center mb-3">
          Keep your projects moving
        </h2>
        <p className="leading-[150%] tracking-[-0.176px] text-center text-lg mb-10">
          Get specific or ask anything to get the exact feedback you need.
        </p>
        <div className="flex w-full justify-center items-center gap-4">
          <div className="grid grid-cols-1 gap-y-6 grow transition-opacity overflow-hidden h-0 md:h-auto opacity-0 md:opacity-100 duration-500 max-w-[300px]">
            <div className="p-6 rounded-xl bg-black/[0.03]">
              <h3 className="text-[#0C0F0D] text-[24px] leading-[120%] font-polysans font-normal mb-3">
                Help me improve this
              </h3>
              <p className="leading-[120%]">
                Strategic and tactical feedback to refine your strategy.
              </p>
            </div>
            <div className="p-6 rounded-xl bg-black/[0.03]">
              <h3 className="text-[#0C0F0D] text-[24px] leading-[120%] font-polysans font-normal mb-3">
                Ask anything
              </h3>
              <p className="leading-[120%]">
                Find actionable solutions for the challenges you face.
              </p>
            </div>
          </div>
          <div className="w-[320px] shrink-0 flex justify-center items-center">
            <PromptInterface width="320" height="378" />
          </div>
          <div className="grid grid-cols-1 gap-y-6 grow transition-opacity overflow-hidden h-0 md:h-auto opacity-0 md:opacity-100 duration-500 max-w-[300px]">
            <div className="p-6 rounded-xl bg-black/[0.03]">
              <h3 className="text-[#0C0F0D] text-[24px] leading-[120%] font-polysans font-normal mb-3">
                Show me examples
              </h3>
              <p className="leading-[120%]">
                See real work from those who have done it before.
              </p>
            </div>
            <div className="p-6 rounded-xl bg-black/[0.03]">
              <h3 className="text-[#0C0F0D] text-[24px] leading-[120%] font-polysans font-normal mb-3">
                Draft this for me
              </h3>
              <p className="leading-[120%]">
                Apply the feedback to your documents with suggested edits.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="max-w-[1048px] w-full flex flex-col items-center px-4 md:p-0 mb-32 md:mb-40">
        <h2 className="text-[#0C0F0D] text-[32px] md:text-[48px] leading-[105%] md:leading-[120%] font-polysans font-normal text-center mb-3">
          Works where you work
        </h2>
        <p className="leading-[150%] tracking-[-0.176px] text-center text-lg text-lg mb-10">
          For a seamless workflow.
        </p>
        <ul className="list-none pl-0 flex flex-row flex-wrap items-center justify-center gap-8 mb-14">
          <li className="flex flex-col items-center justify-center rounded-xl bg-black/[0.03] w-48 h-48">
            <img
              src={logoGoogleDocs}
              alt="Google Docs Logo"
              width="48"
              height="48"
              className="mb-6 shrink-0"
            />
            Google Docs
          </li>
          <li className="flex flex-col items-center justify-center rounded-xl bg-black/[0.03] w-48 h-48">
            <img
              src={logoNotion}
              alt="Notion Logo"
              width="48"
              height="48"
              className="mb-6 shrink-0"
            />
            Notion
          </li>
          <li className="flex flex-col items-center justify-center rounded-xl bg-black/[0.03] w-48 h-48">
            <img
              src={logoCoda}
              alt="Coda Logo"
              width="48"
              height="48"
              className="mb-6 shrink-0"
            />
            Coda
          </li>
          <li className="flex flex-col items-center justify-center rounded-xl bg-black/[0.03] w-48 h-48">
            <img
              src={logoConfluence}
              alt="Confluence Logo"
              width="48"
              height="48"
              className="mb-6 shrink-0"
            />
            Confluence
          </li>
        </ul>
        <AddToChromeButton />
      </section>

      <section className="max-w-[1048px] w-full flex flex-col items-center mb-32 md:mb-40">
        <h2 className="text-[#0C0F0D] text-[32px] md:text-[48px] leading-[105%] md:leading-[120%] font-polysans font-normal text-center mb-3">
          An expert for every situation
        </h2>
        <p className="leading-[150%] tracking-[-0.176px] text-center text-lg text-lg mb-10">
          Real-time feedback, no matter what you&apos;re working on.
        </p>
        <DocumentTypes />
      </section>

      <section className="max-w-[1048px] w-full flex flex-col items-center mb-32 md:mb-40">
        <h2 className="text-[#0C0F0D] text-[32px] md:text-[48px] leading-[105%] md:leading-[120%] font-polysans font-normal text-center mb-10">
          Teams doing better work
        </h2>
        <ul className="list-none pl-0 w-full grid gap-8 grid-cols-1 md:grid-cols-3">
          <li>
            <div className="p-6 rounded-lg bg-black/[0.03]">
              <blockquote className="not-italic text-base">
                <p className="leading-[150%] mb-4 text-[#0C0F0D] !mb-12">
                  I love having Reforge in Notion, helping to suggest and draft
                  improvements grounded in the industry&apos;s best knowledge.
                </p>
                <footer className="flex before:content-['']">
                  <img
                    className="w-12 h-12 mr-4 bg-black rounded-full"
                    width="48px"
                    height="48px"
                    alt="Christopher Ming Avatar"
                    src={testimonialChris}
                  />
                  <div>
                    <h3 className="!font-sans font-semibold text-base mb-0 leading-[150%] text-[#0C0F0D]">
                      Christopher Ming
                    </h3>
                    <p className="text-sm leading-[150%] text-[#717171]">
                      Principal PM at Persefoni
                    </p>
                  </div>
                </footer>
              </blockquote>
            </div>
          </li>

          <li>
            <div className="p-6 rounded-lg bg-black/[0.03]">
              <blockquote className="not-italic text-base">
                <p className="leading-[150%] mb-4 text-[#0C0F0D] !mb-12">
                  Provides valuable feedback, like your peers or boss would, challenging
                  your briefs. And when it comes to drafting something, it gives you a
                  great head start, with a more concise and authentic tone than other LLMs
                  out there.
                </p>
                <footer className="flex before:content-['']">
                  <img
                    className="w-12 h-12 mr-4 bg-black rounded-full"
                    width="48"
                    height="48"
                    alt="Margo Rey's Avatar"
                    src={testimonialMargo}
                  />
                  <div>
                    <h3 className="!font-sans font-semibold text-base mb-0 leading-[150%] text-[#0C0F0D]">
                      Margo Rey
                    </h3>
                    <p className="text-sm leading-[150%] text-[#717171]">
                      Senior PM at MadKudu
                    </p>
                  </div>
                </footer>
              </blockquote>
            </div>
          </li>

          <li>
            <div className="p-6 rounded-lg bg-black/[0.03]">
              <blockquote className="not-italic text-base">
                <p className="leading-[150%] mb-4 text-[#0C0F0D] !mb-12">
                  Easy to use and helpful for finding product artifacts and AI tips to
                  make your work life easier.
                </p>
                <footer className="flex before:content-['']">
                  <img
                    className="w-12 h-12 mr-4 bg-black rounded-full"
                    width="48px"
                    height="48px"
                    alt="Eryn Hernandez Avatar"
                    src={testimonialEryn}
                  />
                  <div>
                    <h3 className="!font-sans font-semibold text-base mb-0 leading-[150%] text-[#0C0F0D]">
                      Eryn Hernandez
                    </h3>
                    <p className="text-sm leading-[150%] text-[#717171]">
                      Principal PM at Twilio
                    </p>
                  </div>
                </footer>
              </blockquote>
            </div>
          </li>
        </ul>
      </section>

      <section className="max-w-[1048px] w-full flex flex-col items-center mb-32 md:mb-40">
        <h2 className="text-[#0C0F0D] text-[32px] leading-[120%] md:text-[48px] font-polysans font-normal text-center mb-10">
          Questions and answers
        </h2>
        <ul className="list-none pl-0 w-full">
          {questionsAndAnswers.map((qa, i) => (
            <QuestionAndAnswer key={i} question={qa.question} answer={qa.answer} />
          ))}
        </ul>
      </section>

      <section className="max-w-[1048px] w-full flex flex-col items-center mb-32">
        <h2 className="text-[#0C0F0D] text-[32px] md:text-[48px] leading-[130%] font-polysans font-normal text-center mb-8 md:mb-12">
          Start working with an expert by your side
        </h2>
        <div>
          <AddToChromeButton />
        </div>
      </section>
    </main>
  )
}
export default ExtensionMarketingPageContent
