import { Link } from 'react-router-dom'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'

import { useAddPostModal } from './AddPostModal'

interface INoResults {
  filters: string[]
  resetFilters: () => void
}

export const NoResults = (props: INoResults) => {
  const { showAddPostModal } = useAddPostModal()
  const { filters, resetFilters } = props
  const filter = filters?.[0]

  const addPost = () => {
    const addPostButton = document.getElementById('add-post')
    if (addPostButton) {
      addPostButton.click()
      return
    }

    showAddPostModal()
  }

  if (filter === 'myPosts') {
    return (
      <div className="bg-rb-gray-50 p-2.5 text-center xs:p-4 md:p-8 tl:px-36">
        <SVGIcon
          name="turn-to-peers-cta"
          width="201"
          height="52"
          fill="#A2A1A2"
          className="mx-auto mb-2.5"
        />

        <p className="text-2xl leading-[1.5] text-rb-gray-400">
          Turn to peers and experts for industry-related advice and help applying Reforge
          concepts in your work.
        </p>

        <Button
          onClick={addPost}
          variant="outline"
          fullWidth
          className="mt-5"
          size="small"
        >
          Add your first post
        </Button>
      </div>
    )
  } else if (filter === 'myResponses') {
    return (
      <div className="bg-rb-gray-50 p-2.5 text-center xs:p-4 md:p-8 tl:px-36">
        When you <Link to="/posts">add a response to a post</Link>, it will show up here.
      </div>
    )
  } else if (filter === 'following') {
    return (
      <div className="bg-rb-gray-50 p-2.5 text-center xs:p-4 md:p-8 tl:px-36">
        <SVGIcon
          name="follow-threads"
          width="201"
          height="52"
          fill="#A2A1A2"
          className="mx-auto mb-2.5"
        />

        <p className="text-2xl leading-[1.5] text-rb-gray-400">
          Follow threads to stay up-to-date on topics that inform your work
        </p>

        <Button
          onClick={resetFilters}
          variant="outline"
          fullWidth
          className="mt-5"
          size="small"
        >
          Find a thread to follow
        </Button>
      </div>
    )
  } else {
    return (
      <div
        data-test="no-results"
        className="bg-rb-gray-50 p-2.5 text-center xs:p-4 md:p-8 tl:px-36"
      >
        <SVGIcon
          name="add-post-cta"
          width="201"
          height="52"
          fill="#A2A1A2"
          className="mx-auto mb-2.5"
        />

        <p className="text-2xl leading-[1.5] text-rb-gray-400">
          It looks like there are aren&apos;t any posts here yet.
          <br />
          <a onClick={addPost}>Add one now</a> or try searching for something else.
        </p>
      </div>
    )
  }
}
