import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import ButtonNoteMarker from './ButtonNoteMarker'

const HotspotMarker = ({
  hotspot
}: {
  hotspot: {
    x: number
    y: number
    annotation: {
      id: string
    }
  }
}) => {
  const location = useLocation()
  const [activeHotspot, setActiveHotspot] = useState<string | null>(null)
  useEffect(() => {
    const hash = location.pathname.split('#')[1]
    setActiveHotspot(hash)
  }, [location.pathname])

  const isActive = Boolean(activeHotspot) && hotspot?.annotation?.id === activeHotspot

  if (!hotspot.annotation) return null

  return (
    <div
      style={{
        left: `${hotspot.x}%`,
        top: `${hotspot.y}%`
      }}
      className="absolute -ml-[11px] -mt-[11px]"
      data-hotspot-hash={hotspot.annotation.id}
    >
      <Link
        to={`#${hotspot.annotation.id}`}
        // TODO: Not sure how to do this yet with react router.
        // scroll={false}
        className="image-marker-link"
      >
        <ButtonNoteMarker active={isActive} />
      </Link>
    </div>
  )
}

export default HotspotMarker
