import { twMerge } from 'tailwind-merge'

import { CheckIcon, CloseIcon } from 'components/icons'

interface PlanOptionListItemProps {
  name: string
  isIncluded?: boolean
}

export const PlanOptionListItem = ({ isIncluded, name }: PlanOptionListItemProps) => {
  const iconPositioning = 'absolute left-1/2 -translate-x-1/2'
  return (
    <li className="mb-3 flex flex-row items-center">
      <div className="relative my-3 mr-4 flex w-7 shrink-0 items-center justify-center">
        {isIncluded ? (
          <CheckIcon className={twMerge('w-[22px] text-rb-success', iconPositioning)} />
        ) : (
          <CloseIcon className={twMerge('w-7 text-rb-destructive', iconPositioning)} />
        )}
      </div>
      <span className="text-sm text-rb-gray-500">{name}</span>
    </li>
  )
}

export default PlanOptionListItem
