import clsx from 'clsx'
import React from 'react'

import MemberStack from 'domains/Group/MemberStack'

import { MemberStackUserFragment } from 'gql'

type TLegacyMemberStackUser = Omit<MemberStackUserFragment, 'id' | '__typename'> & {
  id: number
}
type TUser = MemberStackUserFragment | TLegacyMemberStackUser

interface GroupMembersProps {
  memberStack: TUser[]
  membersCount: number
  userIsMember: boolean
  variation: string
}

const GroupMembers = (props: GroupMembersProps) => {
  const { memberStack, membersCount, userIsMember, variation = 'card' } = props

  const classes = clsx(
    'flex items-center tl:bg-transparent shrink-0',
    variation === 'card' && 'justify-between',
    variation === 'header' && 'p-2 rounded-3xl',
    userIsMember ? 'bg-rb-gray-50' : 'bg-rb-gray-50-light-blue'
  )

  return (
    <div className={classes}>
      <MemberStack
        variation={variation}
        memberStack={memberStack}
        membersCount={membersCount}
        userIsMember={userIsMember}
      />
    </div>
  )
}

export default GroupMembers
