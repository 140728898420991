export const GeneratingIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_513_16034)">
        <path
          d="M12 4.44699C13.4834 4.44699 14.9334 4.88686 16.1668 5.71097C17.4001 6.53508 18.3614 7.70642 18.9291 9.07687C19.4968 10.4473 19.6453 11.9553 19.3559 13.4102C19.0665 14.865 18.3522 16.2014 17.3033 17.2503C16.2544 18.2992 14.918 19.0135 13.4632 19.3029C12.0083 19.5923 10.5003 19.4437 9.12987 18.8761C7.75943 18.3084 6.58809 17.3471 5.76398 16.1138C4.93987 14.8804 4.5 13.4304 4.5 11.947"
          stroke="#BBBDBA"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.942 14.523C22.7085 15.5167 22.343 16.4745 21.855 17.371"
          stroke="#BBBDBA"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.8139 20.031C19.0748 20.735 18.2499 21.3431 17.3589 21.841"
          stroke="#BBBDBA"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9929 0.747009C14.0098 0.837097 15.0095 1.06658 15.9639 1.42901"
          stroke="#BBBDBA"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.8879 3.065C19.6976 3.68671 20.415 4.42 21.0189 5.243"
          stroke="#BBBDBA"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.6001 8.20001C22.9487 9.15954 23.1556 10.1648 23.2141 11.184"
          stroke="#BBBDBA"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.2501 22.972C12.8023 23.2675 11.3104 23.2749 9.85974 22.9938C8.40905 22.7127 7.02793 22.1487 5.79524 21.3338C3.30569 19.6882 1.57183 17.121 0.975097 14.197C0.37836 11.273 0.967623 8.23168 2.61325 5.74214C4.25889 3.25259 6.82609 1.51873 9.7501 0.921997"
          stroke="#BBBDBA"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_513_16034">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
