import { useState } from 'react'

import { ExampleCard } from 'components/cards/example_card'

import { DeliverableExamplePartsFragment } from 'gql'

interface IExamplesDropdown {
  cmsExamples: DeliverableExamplePartsFragment[]
}

export const ExamplesDropdown = ({ cmsExamples }: IExamplesDropdown) => {
  const [open, setOpen] = useState(false)

  const toggleOpen = () => {
    setOpen(!open)
  }

  return (
    <div className="uk-grid-collapse" uk-grid="">
      <div
        className="uk-width-1-1 uk-grid-collapse rounded-b border border-rb-gray leading-3"
        uk-grid=""
      >
        <div
          className="uk-width-1-1 uk-grid-collapse p-4 pb-2.5 pr-2.5 hover:shadow-default"
          uk-grid=""
          onClick={toggleOpen}
        >
          <div className="text-xs font-medium tracking-widest text-rb-gray-400">
            Project Examples
          </div>
          <div className="uk-width-expand" />
          <span uk-icon={`icon: ${open ? 'chevron-down' : 'chevron-up'}; ratio: 1.0`} />
        </div>
        {open &&
          cmsExamples.map((cmsExample, idx) => {
            if (idx >= 2) return null

            return (
              <ExampleCard
                key={`del-example${cmsExample.id}`}
                exampleId={cmsExample.id}
                name={cmsExample.name}
                companyName={cmsExample.company?.name || ''}
                companyImgUrl={cmsExample.company?.cardLogoUrl || ''}
                deliverableTitle={cmsExample.title}
                deliverableUrl={cmsExample.url}
              />
            )
          })}
      </div>
    </div>
  )
}
