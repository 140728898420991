import { CreatePostFormProps } from 'domains/CohortConversation/Post/CreatePostForm'
import { PostFormButton } from 'domains/CohortConversation/Post/PostFormButton'

import { SVGIcon } from 'components/Icon'
import Modal, { ModalContent, ModalHeader } from 'components/Modal'

import { onEnterKeyPress } from 'utils/keyboard'

import CohortFroalaWrapper from './CohortFroalaWrapper'

interface CreateCohortPostOrReplyModalProps extends CreatePostFormProps {
  isModalOpen: boolean
  handleModalClose: () => void
  setContent: (nextModel: string) => void
  submitForm(): void
  type: 'reply' | 'post'
  postId?: string
  loading?: boolean
  content?: string
  menuContainerId?: string
  postButtonOpacity?: number
}

const CreatePostOrReplyFormModal = ({
  isModalOpen,
  handleModalClose,
  setContent,
  content,
  menuContainerId,
  type,
  submitForm,
  loading,
  postButtonOpacity
}: CreateCohortPostOrReplyModalProps) => {
  return (
    <Modal
      isOpen={isModalOpen}
      handleClose={handleModalClose}
      header={false}
      className="my-0 h-screen max-h-full w-full max-w-[658px] overflow-hidden md:mx-4 md:max-h-[calc(100%-40px)] md:rounded-2xl"
    >
      <ModalHeader className="py-2 md:px-6">
        <div className="flex items-center justify-between">
          <div
            tabIndex={0}
            role="button"
            className="hover:bg-default cursor-pointer py-3 pl-3 text-xl md:py-4 md:pl-4"
            onClick={handleModalClose}
            onKeyUp={onEnterKeyPress(handleModalClose)}
          >
            <SVGIcon name="thin-chevron-left" />
          </div>
          <PostFormButton
            loading={loading}
            submitForm={submitForm}
            disabled={content === ''}
            className={`opacity-${postButtonOpacity} transition-[opacity]`}
            label={type === 'post' ? 'Post' : 'Reply'}
          />
        </div>
      </ModalHeader>
      <ModalContent className="overflow-hidden pb-6">
        <CohortFroalaWrapper
          model={content}
          updateModel={setContent}
          menuContainerId={menuContainerId}
          placeholder="Write something..."
          className={`${
            type === 'post' ? 'create-post' : 'create-reply'
          } w-0 min-w-full grow`}
          autofocus={true}
          heightMax={200}
        />
      </ModalContent>
    </Modal>
  )
}

export default CreatePostOrReplyFormModal
