import pluralize from 'pluralize'

import { ErrorMessage, Loading } from 'components'

import {
  AvailableTeamSubscriptionFragment,
  useCancelSubscriptionJoinRequestMutation,
  useCurrentUserAvailableTeamsQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { cn } from 'utils/tailwind'

import {
  AddEmailLink,
  InfoBox,
  JoinableTeam,
  JoinedTeam,
  PendingTeam,
  RejectedTeam
} from './components'
import { sortAvailableSubscriptionsByRequestStatus } from './utils'

export interface DiscoverableTeamsProps {
  activeSubscriptionMembershipCreatedAt?: string
  availableTeamSubscriptions?: AvailableTeamSubscriptionFragment[]
  activeSubscription?: AvailableTeamSubscriptionFragment | null
  refetchAvailableTeams: () => void
  canJoinTeam?: boolean
}

export function DiscoverableTeams({
  activeSubscriptionMembershipCreatedAt,
  availableTeamSubscriptions,
  activeSubscription,
  refetchAvailableTeams,
  canJoinTeam
}: DiscoverableTeamsProps) {
  const teamsByRequestStatus = sortAvailableSubscriptionsByRequestStatus({
    availableTeamSubscriptions: availableTeamSubscriptions || []
  })

  const { refetchCurrentUser } = useCurrentUser()

  const [cancelRequest] = useCancelSubscriptionJoinRequestMutation()

  const { draftTeams, joinableTeams, rejectedTeams, requestedTeams } =
    teamsByRequestStatus
  const shouldShowNoTeamsTryADifferentEmailPrompt =
    !activeSubscription && availableTeamSubscriptions?.length === 0

  const onRequestedToJoin = () => {
    refetchAvailableTeams()
    refetchCurrentUser()
  }

  const onCancelRequest = (subscriptionId: string) => async () => {
    await cancelRequest({
      variables: {
        input: {
          subscriptionId
        }
      }
    })
    refetchAvailableTeams()
    refetchCurrentUser()
  }

  return (
    <div className="rf-rb-card mb-6 bg-white p-6">
      <h3 className={cn('mb-4 text-xl font-medium', activeSubscription && 'mb-5')}>
        My team
      </h3>

      {shouldShowNoTeamsTryADifferentEmailPrompt && (
        <p className="text-base leading-[1.6] mb-6">
          Find and join your team’s plan to take advantage of the team subscription.
        </p>
      )}

      {!activeSubscription && (availableTeamSubscriptions || []).length > 0 && (
        <p className="text-base leading-[1.6] mb-6">
          Based on the email you provided, we found {availableTeamSubscriptions?.length}{' '}
          {pluralize('team', availableTeamSubscriptions?.length)} for your company. If
          you’d like to join a team, we will send a request on your behalf. If you can’t
          find your team, try <AddEmailLink>adding a different email</AddEmailLink>.
        </p>
      )}

      <div data-test="account-my-team-individual" className="flex flex-col gap-4">
        {shouldShowNoTeamsTryADifferentEmailPrompt && (
          <InfoBox>
            There is no team associated with your email.{' '}
            <AddEmailLink>Try adding a different email.</AddEmailLink>
          </InfoBox>
        )}
        {activeSubscription && (
          <JoinedTeam
            joinedAt={activeSubscriptionMembershipCreatedAt}
            team={activeSubscription}
          />
        )}
        {requestedTeams.map((team) => (
          <PendingTeam
            key={team.id}
            team={team}
            onCancelRequest={onCancelRequest(team.id)}
          />
        ))}
        {draftTeams.map((team) => (
          <JoinableTeam
            key={team.id}
            team={team}
            onRequestedToJoin={onRequestedToJoin}
            ctaDisabled={!canJoinTeam}
          />
        ))}
        {joinableTeams.map((team) => (
          <JoinableTeam
            key={team.id}
            team={team}
            onRequestedToJoin={onRequestedToJoin}
            ctaDisabled={!canJoinTeam}
          />
        ))}
        {rejectedTeams.map((team) => (
          <RejectedTeam key={team.id} team={team} />
        ))}
      </div>
    </div>
  )
}

export function DiscoverableTeamsWrapper() {
  const { loading, data, error, refetch } = useCurrentUserAvailableTeamsQuery()

  if (loading) return <Loading />
  if (error) return <ErrorMessage error={error} />
  if (!data || !data.currentUser) {
    return <ErrorMessage error={'Data retrieval for Team Card failed'} />
  }

  const { currentUser } = data
  const { activeSubscriptionMembership, subscriptions, availableTeamSubscriptions } =
    currentUser

  return (
    <DiscoverableTeams
      activeSubscriptionMembershipCreatedAt={activeSubscriptionMembership?.createdAt}
      activeSubscription={subscriptions?.active}
      availableTeamSubscriptions={availableTeamSubscriptions}
      refetchAvailableTeams={refetch}
      canJoinTeam={currentUser.can.joinTeam || undefined}
    />
  )
}

export default DiscoverableTeamsWrapper
