import React from 'react'

import { SuggestedPromptsWidget } from 'domains/Ai/SuggestedPrompts/SuggestedPromptsWidget'

import {
  Collection,
  ProgramBookmarkPartsFragment,
  SavedArtifactsAndGuidesForUserQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import FeedHeader from '../FeedHeader'
import Swimlane from './Swimlane'

interface SwimlanesProps {
  setIsFocusUpdate: (isFocusUpdate: boolean) => void
  openPersonalizeModal: () => void
  hasFocusAreas: boolean
  pageLocation: string
  recommendedCollections?: Collection[]
  savedData?: SavedArtifactsAndGuidesForUserQuery
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
}

const Swimlanes = ({
  setIsFocusUpdate,
  openPersonalizeModal,
  hasFocusAreas,
  recommendedCollections,
  savedData,
  openAddToBookmarkFolderModal,
  pageLocation
}: SwimlanesProps) => {
  const { currentUser } = useCurrentUser()
  const { is: userIs } = currentUser || {}
  return (
    <div className="w-full flex flex-col">
      <FeedHeader
        setIsFocusUpdate={setIsFocusUpdate}
        openPersonalizeModal={openPersonalizeModal}
        hasFocusAreas={hasFocusAreas}
      />
      {recommendedCollections?.map((recommendedCollection, i) => (
        <React.Fragment key={i}>
          <Swimlane
            pageLocation={pageLocation}
            recommendedCollection={recommendedCollection}
            savedData={savedData}
            highlighted={recommendedCollection.title.includes('live course')}
            index={i}
            openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
            userIsPremember={userIs?.premember}
          />
          {i === 1 && currentUser && (
            <div className="py-8">
              <SuggestedPromptsWidget premiumUser={!!currentUser && !userIs?.premember} />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default Swimlanes
