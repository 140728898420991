import { useNavigate } from 'react-router-dom-v5-compat'

import SimpleModal from 'components/SimpleModal'

interface OutOfSeatsModalProps {
  onUpgradePlan: () => void
  onCancel: () => void
}

export default function OutOfSeatsModal({
  onUpgradePlan,
  onCancel
}: OutOfSeatsModalProps) {
  const navigate = useNavigate()

  return (
    <SimpleModal
      renderBodyText={() => (
        <div>You&apos;re out of seats on your plan. Upgrade your plan to add more.</div>
      )}
      renderFooterActionButtons={() => [
        {
          size: 'small',
          variant: 'text-only',
          className: 'uppercase',
          onClick: onCancel,
          children: 'Cancel'
        },
        {
          size: 'small',
          className: 'uppercase',
          onClick: () => {
            navigate('/manage-plan')
            onUpgradePlan()
          },
          children: 'Manage Plan'
        }
      ]}
    />
  )
}
