import { closing, opening } from './sharedMarkdown'

const programName = 'User Insights for Product Decisions'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://www.reforge.com/user-insights-product-decisions), because I will learn to make key product decisions based on a deeper understanding of customers. This course was built with [Behzod Sirjani](https://www.reforge.com/experts/behzod-sirjani), and covers:
* **Decision-First Planning.** Smart product decisions require smarter customer insights. We’ll start by defining and scoping the product decision, identifying the type of evidence you need, and planning your research approach.   
* **Conversational Research.** No matter the decision, you’ll likely need to talk to users. But how you talk to them can vary widely depending on the product decision you need to make. You will learn about different conversational research methods, and how to execute each in a thoughtful and unbiased way. 
* **Testing & Surveys.** Informed product decisions require both qualitative and quantitative evidence. You’ll learn how to run usability tests and surveys with depth and precision, when to use each method, and how they map to different product decisions.
* **Synthesis and Decision-Making.** After gathering raw evidence, you need to synthesize it into a compelling storyline. You will learn techniques for synthesis, documentation, and cross-functional communication to help facilitate product decisions.

&NewLine;

${closing}
`

export default { markdown, title: programName }
