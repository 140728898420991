import { CohortDashboardSidebarProps } from 'domains/CohortDashboard/CohortDashboardSidebar'
import { useProfileEditModal } from 'domains/Profile/ProfileEditModal'

import { Loading } from 'components'
import Button from 'components/Button'
import Modal, { ModalContent, ModalHeaderWithClose } from 'components/Modal'
import SquarishAvatarImage from 'components/SquarishAvatarImage/SquarishAvatarImage'
import RfHeader2 from 'components/typography/RfHeader/RfHeader2'
import RfParagraphLargeSemiBold from 'components/typography/RfParagraph/RfParagraphLargeSemiBold'
import RfParagraphMediumSemiBold from 'components/typography/RfParagraph/RfParagraphMediumSemiBold'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import { useCohortUserQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { linkedInInputToUrl } from 'utils/social'

import { ReactComponent as LinkedInIcon } from 'images/linkedin.svg'
import { ReactComponent as SlackIcon } from 'images/slack.svg'
import { ReactComponent as TwitterIcon } from 'images/twitter.svg'

interface ProfileModalProps extends CohortDashboardSidebarProps {
  userId: string
}

const ProfileModal = ({ isOpen, onClose, userId }: ProfileModalProps) => {
  const { currentUser } = useCurrentUser()
  const { openProfileEditModal } = useProfileEditModal()
  const { data, loading } = useCohortUserQuery({
    variables: {
      userId: userId
    }
  })

  if (loading) return <Loading />

  if (!data?.user?.profile) return null

  const user = data.user
  const linkedInUrl = user.linkedin && linkedInInputToUrl(user.linkedin)

  const handleSlackClick = () => {
    window.open(`slack://user?team=T01LD82F0M8&id=${user.slackUserId}`)
  }

  const handleEditClick = () => {
    openProfileEditModal()
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={onClose}
      header={false}
      className="max-w-[800px] overflow-hidden rounded-md"
    >
      <ModalHeaderWithClose handleClose={onClose} className="mb-0 py-6 px-6 md:px-12">
        <RfParagraphMediumSemiBold>Profile</RfParagraphMediumSemiBold>
      </ModalHeaderWithClose>
      <ModalContent className="flex w-full flex-col overflow-auto px-6 py-6 pt-4 md:px-12 xl:flex-row xl:gap-8">
        <div className="flex flex-col xl:flex-row">
          <div className="min-w-[240px] max-w-[288px]">
            <SquarishAvatarImage
              className="h-[152px] w-[152px] rounded-[10px]"
              avatarUrl={user.profile.avatarUrl}
              alt={user.profile.fullName || 'Profile Picture'}
            />
            <RfHeader2 className="mt-7 !mb-1">
              <span className="font-bold">{user.profile.fullName}</span>
            </RfHeader2>
            <RfParagraphSmall className="mb-4 sm:mb-5">
              {user.profile.role} @ {user.profile.companyName}
            </RfParagraphSmall>
            <RfParagraphSmall className="mb-4 sm:mb-5">
              {user.profile.location}
            </RfParagraphSmall>

            <div className="item-center mb-4 flex gap-4 xl:mb-7">
              {linkedInUrl && (
                <a
                  href={linkedInUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="h-[16px] w-[16px] text-rb-black"
                >
                  <LinkedInIcon />
                </a>
              )}
              {user.twitter && (
                <a
                  href={user.twitter}
                  target="_blank"
                  rel="noreferrer"
                  className="h-[16px] w-[16px] text-rb-black"
                >
                  <TwitterIcon />
                </a>
              )}
              {user.slackUserId && user.profile.allowDirectMessages && (
                <button
                  onClick={handleSlackClick}
                  className="h-[16px] w-[16px] text-rb-black"
                >
                  <SlackIcon />
                </button>
              )}
            </div>
            <div className="flex items-end justify-between xl:flex-col xl:items-start xl:gap-4">
              <RfParagraphMini color={ColorOptions.gray}>
                Joined {user.monthJoined} {user.yearJoined}
              </RfParagraphMini>

              {user.id === currentUser?.id && (
                <Button variant="outline" size="x-small" onClick={handleEditClick}>
                  Edit Profile
                </Button>
              )}
            </div>
          </div>

          <hr className="my-8 block border-t-1.25 border-solid border-rb-gray-50 xl:hidden" />
        </div>
        <div className="flex flex-1 flex-col pb-5">
          <div>
            {user.profile.aboutMeText && (
              <Section
                header="About me"
                body={user.profile.aboutMeText}
                divider={false}
              />
            )}

            {user.profile.previousCompanies &&
              user.profile.previousCompanies?.length > 0 && (
                <>
                  <RfParagraphMediumSemiBold className="pb-2">
                    Previous experience
                  </RfParagraphMediumSemiBold>

                  <RfParagraphSmall>
                    {user.profile.previousCompanies
                      ?.map((company) => company.company.name)
                      .join(', ')}
                  </RfParagraphSmall>
                </>
              )}

            <hr className="my-8 block border-t-1.25 border-solid border-rb-gray-50" />
          </div>

          {(user.profile.companyProductText || user.profile.companyAudienceTags) && (
            <Section
              header="What I'm working on"
              body={user.profile.companyProductText}
              tags={user.profile.companyAudienceTags}
            />
          )}

          {(user.profile.communityInterestText || user.profile.communityInterestTags) && (
            <Section
              header="I'm here to"
              body={user.profile.communityInterestText}
              tags={user.profile.communityInterestTags}
            />
          )}

          {user.profile.learningGoalsText && (
            <Section
              header="What I hope to learn"
              body={user.profile.learningGoalsText}
            />
          )}

          {(user.profile.askMeAboutText || user.profile.areasOfExpertiseTags) && (
            <Section
              header="Ask me about"
              body={user.profile.askMeAboutText}
              tags={user.profile.areasOfExpertiseTags}
              divider={false}
            />
          )}
        </div>
      </ModalContent>
    </Modal>
  )
}

const Section = ({
  header,
  body,
  tags,
  divider = true
}: {
  header: string
  body?: string | null
  tags?: string | null
  divider?: boolean
}) => (
  <div>
    <RfParagraphLargeSemiBold className="pb-4">{header}</RfParagraphLargeSemiBold>

    {tags && tags.length > 0 && (
      <div className="mb-4 sm:mb-5">
        {tags.split(',').map((tag) => (
          <div key={tag} className="mr-2.5 inline-flex rounded-xl bg-teal-50 px-4 py-0.5">
            <RfParagraphMini className="text-rb-black">{tag}</RfParagraphMini>
          </div>
        ))}
      </div>
    )}

    <RfParagraphSmall className="mb-4 sm:mb-5">{body}</RfParagraphSmall>

    {divider && (
      <hr className="my-8 block border-t-1.25 border-solid border-rb-gray-50" />
    )}
  </div>
)

export default ProfileModal
