import { useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import CourseContentBlockNavigation from 'pages/CoursesPage/CourseContentBlockNavigation'
import { COURSE_DASHBOARD_HEADER_ID } from 'pages/CoursesPage/CoursePage'

import CourseContentBlock from 'domains/Course/CourseContentBlock'
import CourseDashboardGettingStarted from 'domains/Course/CourseDashboardGettingStarted'
import CourseDashboardHeader from 'domains/Course/CourseDashboardHeader'
import CourseDashboardWrapUp from 'domains/Course/CourseDashboardWrapUp'

import { Loading } from 'components'

import {
  CourseDashboardCourseBlockPartsFragment,
  CourseDashboardCourseSessionPartsFragment,
  useCourseDetailQuery
} from 'gql'

import { useScrollToHashAfterDataIsLoaded } from 'hooks/useScrollToHashAfterDataIsLoaded'

import { isAfterDate } from 'utils/date'

export interface CourseLandingPageProps {
  slug: string
  courseSessionId: string
}

export function isPostCourseSession(
  courseSession?: CourseDashboardCourseSessionPartsFragment | null,
  courseBlocks?: CourseDashboardCourseBlockPartsFragment[] | null
): boolean {
  return (
    (courseSession?.endsAt &&
      courseBlocks &&
      courseBlocks[0]?.startDate &&
      (isAfterDate(courseSession?.endsAt, courseBlocks[0]?.startDate) ||
        isAfterDate(courseBlocks[0]?.endDate, courseSession?.startsAt))) ||
    false
  )
}

const CourseDashboard = ({ slug, courseSessionId }: CourseLandingPageProps) => {
  const { loading, data } = useCourseDetailQuery({
    variables: { slug, courseSessionId }
  })

  const hasCourseDatesOutsideCourseSession = isPostCourseSession(
    data?.courseSession,
    data?.course?.courseBlocks
  )

  const isCourseCompleted = useMemo(() => {
    return data?.course?.courseBlocks
      ?.flatMap((block) => block?.units)
      .every((unit) => unit?.completed)
  }, [data?.course?.courseBlocks])

  const findCurrentCourseBlockHash = () => {
    if (!data?.course?.courseBlocks || isCourseCompleted) return

    if (window.location.hash) return window.location.hash

    let nextCourseBlock = data?.course?.courseBlocks?.[0]
    let foundNextGuide = false

    data?.course?.courseBlocks?.forEach((block) => {
      const nextUnit = block?.units?.find((unit) => !unit.completed)
      if (nextUnit && !foundNextGuide) {
        nextCourseBlock = block
        foundNextGuide = true
      }
    })

    return nextCourseBlock && nextCourseBlock.id ? `#${nextCourseBlock.id}` : undefined
  }

  const currentCourseBlockHash = findCurrentCourseBlockHash()

  useScrollToHashAfterDataIsLoaded(!!data && !loading, currentCourseBlockHash)

  if (loading) return <Loading />

  if (
    !data?.course ||
    !data?.courseSession ||
    data?.course?.id !== data?.courseSession?.courseId
  ) {
    return <Redirect to="/" />
  }

  const { cclCourse, course, courseSession } = data
  const hasCourseEnded = isAfterDate(courseSession?.endsAt)

  const renderCourseContentBlocks = () => {
    if (cclCourse?.blocks && course?.courseBlocks) {
      return course.courseBlocks.map((courseBlock, index) => {
        const cclCourseBlock = cclCourse?.blocks?.[index]
        if (!cclCourseBlock) return null

        return (
          <div key={courseBlock.id} className="w-full">
            <CourseContentBlock
              cclCourseBlock={cclCourseBlock}
              courseBlock={courseBlock}
              courseSession={courseSession}
              course={course}
              courseBlockIndex={index}
              hasCourseDatesOutsideCourseSession={hasCourseDatesOutsideCourseSession}
            />
          </div>
        )
      })
    }

    return null
  }

  return (
    <>
      <CourseDashboardHeader
        course={data?.course}
        courseBlocks={data?.course?.courseBlocks}
        courseSession={data?.courseSession}
        id={COURSE_DASHBOARD_HEADER_ID}
        showOffset={!hasCourseDatesOutsideCourseSession}
      />
      <div className="flex flex-col items-center px-4 pb-32 sm:px-7">
        <div
          className={twMerge(
            'flex w-full flex-col items-center lg:gap-4',
            !hasCourseDatesOutsideCourseSession && 'max-w-[820px] lg:items-end'
          )}
        >
          <div className="flex flex-1 flex-col justify-center lg:flex-row">
            {!hasCourseDatesOutsideCourseSession && (
              <div className="flex flex-row py-8 lg:flex-col lg:pr-8">
                {!!course?.courseBlocks && course.courseBlocks.length > 0 && (
                  <CourseContentBlockNavigation
                    course={course}
                    courseSession={courseSession}
                    courseBlocks={course?.courseBlocks}
                    cclCourseBlocks={cclCourse?.blocks}
                  />
                )}
              </div>
            )}

            <div className="flex w-full max-w-[600px] flex-1 flex-col items-center justify-center gap-9 lg:py-9 lg:pb-56">
              {courseSession?.gettingStartedBlock && (
                <CourseDashboardGettingStarted
                  course={course}
                  courseSession={courseSession}
                />
              )}

              {hasCourseEnded && (
                <CourseDashboardWrapUp
                  course={course}
                  courseSession={courseSession}
                  userCourse={data?.userCourse}
                />
              )}

              {renderCourseContentBlocks()}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CourseDashboard
