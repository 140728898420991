import { useEffect } from 'react'
import SlackImage from 'slack.png'
import UIkit from 'uikit'

import BadgeableAvatar from 'components/BadgeableAvatar'
import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'

import { MemberProfileSlackAccountFragment, MemberProfileUserFieldsFragment } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import { track } from 'utils/tracking/segment'

import { ReactComponent as ReforgeSymbol } from 'images/reforge-logo-symbol.svg'

interface IMessageMe {
  user: MemberProfileUserFieldsFragment
  joinSlackHref?: string | null
  threeRecentSlackJoins?: MemberProfileSlackAccountFragment[]
}

const MessageMe = ({ user, joinSlackHref, threeRecentSlackJoins = [] }: IMessageMe) => {
  const currentUser = useAssertCurrentUser()

  useEffect(() => {
    // @ts-ignore - 'Slack - Action' event is not defined in Segment JIRA#REF-5159
    track('Slack - Action', {
      action: 'message_me_displayed',
      target_user: user.id
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = () => {
    if (currentUser.slackUserId) {
      // @ts-ignore - 'Slack - Action' event is not defined in Segment JIRA#REF-5159
      track('Slack - Action', {
        action: 'message_me_clicked',
        target_user: user.id,
        is_current_user_connected_to_slack: true
      })

      window.open(`slack://user?team=T01LD82F0M8&id=${user.slackUserId}`)
    } else {
      // @ts-ignore - 'Slack - Action' event is not defined in Segment JIRA#REF-5159
      track('Slack - Action', {
        action: 'message_me_clicked',
        target_user: user.id,
        is_current_user_connected_to_slack: false
      })

      const joinSlackMessageMeModal = document.getElementById(
        'join-slack-message-me-modal'
      )
      if (joinSlackMessageMeModal) {
        // @ts-ignore - 'Slack - Action' event is not defined in Segment JIRA#REF-5159
        track('Slack - Action', {
          action: 'join_slack_cta_displayed',
          location: 'user_profile'
        })

        UIkit.modal(joinSlackMessageMeModal).show()
      }
    }
  }

  const handleInviteClick = () => {
    // @ts-ignore - 'Slack - Action' event is not defined in Segment JIRA#REF-5159
    track('Slack - Action', {
      action: 'join_slack_cta_clicked',
      location: 'user_profile'
    })

    joinSlackHref && window.open(joinSlackHref)
  }

  return (
    <>
      <Button
        size="small"
        shape="rounded-full"
        className="w-full bg-rb-slack-purple hover:border-rb-slack-purple hover:bg-rb-gray-500 sm:w-48 md:w-60"
        onClick={handleClick}
      >
        <img
          className="mr-2"
          src={SlackImage}
          style={{ height: '18px' }}
          height="18"
          width="18"
          alt=""
        />
        Message Me
      </Button>

      <div id="join-slack-message-me-modal" uk-modal={'{"container": true}'}>
        <div className="uk-modal-dialog uk-modal-body w-3/4 lg:w-1/2">
          <button className="uk-modal-close-default" type="button" uk-close="true" />
          <h3 className="text-xl">Send {user.fullName} a message on Slack</h3>
          <div className="mb-8">
            We’ve integrated Slack to facilitate connections between members &#38;
            seamlessly provide updates on events, content, &#38; more. Join today to
            enable direct messaging with all Reforge members.
          </div>
          <div className="border p-8">
            <div className="mb-6 flex justify-center">
              <ReforgeSymbol width="36" />

              <SVGIcon
                className="mx-2"
                name="double-arrow"
                height="11"
                width="29"
                fill="none"
              />
              <img src={SlackImage} style={{ height: '32px' }} height="32" width="32" />
            </div>
            <h3 className="text-center text-xl">Join 5,687 Reforge Members on Slack</h3>
            <div className="flex flex-col items-center justify-between lg:flex-row">
              <div className="flex flex-col items-center lg:flex-row">
                <div className="mr-2 flex items-center">
                  {threeRecentSlackJoins.map(({ user }, i) => (
                    <BadgeableAvatar
                      key={`mm-badge-${i}`}
                      avatarUrl={user.profile.avatarUrl}
                      width="24"
                      height="24"
                      fullName={user.profile.fullName}
                    />
                  ))}
                </div>
                {threeRecentSlackJoins.length >= 3 && (
                  <span
                    className="align-middle text-rb-gray-300"
                    style={{ fontSize: '14px' }}
                  >
                    {threeRecentSlackJoins[0].user.profile.fullName},{' '}
                    {threeRecentSlackJoins[1].user.profile.fullName}, &#38;{' '}
                    {threeRecentSlackJoins[2].user.profile.fullName} recently joined
                  </span>
                )}
              </div>

              <Button
                onClick={handleInviteClick}
                size="x-small"
                shape="rounded-full"
                variant="outline"
                className="mt-2 shrink-0 lg:mt-0 lg:ml-4"
              >
                Accept Invite
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MessageMe
