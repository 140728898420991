import { useMemo } from 'react'

import {
  ActivityFacepileCmsActivityPartsFragment,
  ActivityFacepileCollectionActivityPartsFragment,
  UserAvatarPartsFragment
} from 'gql'

import { ActivityFacepileProps, UserProps } from './ActivityFacepile'

type Activity =
  | ActivityFacepileCollectionActivityPartsFragment
  | ActivityFacepileCmsActivityPartsFragment

interface useActivityFacepileInfoProps {
  creator?: UserAvatarPartsFragment
  users: UserAvatarPartsFragment[]
  avatarDisplayCount?: number
  activities: Activity[]
}

export const useActivityFacepileInfo = ({
  creator,
  users,
  activities,
  avatarDisplayCount = 4
}: useActivityFacepileInfoProps) => {
  const numAvatarsToDisplay =
    users.length < avatarDisplayCount ? users.length : avatarDisplayCount

  const activityUserMap = useMemo(
    () =>
      activities.reduce(
        (acc, activity) => {
          acc[activity.user.id] = activity
          return acc
        },
        {} as { [key: string]: Activity }
      ),
    [activities]
  )

  const { usersWithActivities, usersWithoutActivities } = useMemo(
    () =>
      splitUsersWithActivities({
        users,
        activities,
        activityUserMap
      }),
    [users, activities, activityUserMap]
  )

  const usersWithActivityInFacepile = usersWithActivities.slice(0, numAvatarsToDisplay)
  const usersWithoutActivityInFacepile = usersWithoutActivities.slice(
    0,
    usersWithActivities.length < numAvatarsToDisplay
      ? numAvatarsToDisplay - usersWithActivities.length
      : 0
  )

  const avatarsToDisplay = [
    ...usersWithActivityInFacepile,
    ...usersWithoutActivityInFacepile
  ]

  const avatarsNotShown = users.filter(
    (u) => !avatarsToDisplay.map((a) => a.id).includes(u.id)
  )

  const {
    usersWithActivities: usersWithActivityNotInFacepile,
    usersWithoutActivities: usersWithoutActivityNotInFacepile
  } = useMemo(
    () =>
      splitUsersWithActivities({
        users: avatarsNotShown,
        activities,
        activityUserMap
      }),
    [avatarsNotShown, activities, activityUserMap]
  )

  const onlyCreatorActivity =
    !!creator &&
    (!activities ||
      activities.map((a) => a.user.id).filter((id) => id === creator?.id).length ===
        activities.length)

  const usersNotInFacepile = users.filter(
    (u) =>
      ![...usersWithActivityInFacepile, ...usersWithoutActivityInFacepile]
        .map((a) => a.id)
        .includes(u.id)
  )

  return {
    activityUserMap,
    onlyCreatorActivity,
    usersWithActivityInFacepile,
    usersWithoutActivityInFacepile,
    usersWithActivityNotInFacepile,
    usersWithoutActivityNotInFacepile,
    usersNotInFacepile
  }
}

const splitUsersWithActivities = ({
  users,
  activities,
  activityUserMap
}: {
  users: ActivityFacepileProps['users']
  activities: Activity[]
  activityUserMap: { [key: string]: Activity }
}) => {
  const usersMap = users.reduce((map: { [key: string]: UserProps }, user: UserProps) => {
    map[user.id] = user
    return map
  }, {})

  const usersWithActivitiesIds = activities.map((a) => a.user.id)

  const usersWithActivities = usersWithActivitiesIds
    .map((id) => usersMap[id])
    .filter(Boolean)

  const usersWithoutActivities = users.filter((u: UserProps) => !activityUserMap[u.id])

  return { usersWithActivities, usersWithoutActivities }
}
