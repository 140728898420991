import Pluralize from 'react-pluralize'
import { useHistory } from 'react-router-dom'
import SlackImage from 'slack.png'

import { ModifyEnrollment } from 'components'
import { SVGIcon } from 'components/Icon'
import Popover from 'components/Popover/Popover'
import Progress from 'components/Progress'
import { Tag } from 'components/Tag'
import { CheckIcon } from 'components/icons'

import { CurrentUserPartsFragment, useGetEnrollmentSeasonQuery } from 'gql'

import { canUserCohortEnrollmentBeModified } from 'utils/cohortUtils'
import { formatInTimezone, isAfterDate, numDaysBetweenDates } from 'utils/date'
import { onEnterKeyPress } from 'utils/keyboard'

interface ICohortViewerHeader {
  programCode: string
  heroImageUrl: string
  iconImageUrl: string
  seasonName: string
  seasonYear: string
  programName: string
  slackChannelId: string
  kickOffStartsAt?: string | null
  endsAt: string
  currentUser: CurrentUserPartsFragment
  joinSlackHref: string
  percentCompleted: number
  cohortSeasonId: string
}

const getNumProgramWeeks = (programCode: string) => {
  if (['mp', 'rs', 'et', 'gs', 'ags'].includes(programCode)) return 6
  if (
    ['ps', 'ui', 'spd', 'pl', 'pmf', 'mpm', 'ts', 'ms', 'el', 'gm', 'dfpm'].includes(
      programCode
    )
  ) {
    return 4
  }
}

const CohortViewerHeader = ({
  programCode,
  heroImageUrl,
  iconImageUrl,
  seasonName,
  seasonYear,
  programName,
  slackChannelId,
  kickOffStartsAt,
  endsAt,
  percentCompleted,
  currentUser,
  joinSlackHref,
  cohortSeasonId
}: ICohortViewerHeader) => {
  const history = useHistory()
  const { data } = useGetEnrollmentSeasonQuery()
  const enrollmentSeason = data?.enrollmentSeason
  const isCompleted = percentCompleted === 100
  const numDaysBetween = numDaysBetweenDates(kickOffStartsAt) || 0

  const redirectToPrograms = () => {
    history.push('/courses')
  }

  return (
    <div id="cohortViewerHeader" className="relative">
      <div className="mt-[72px] bg-white" />
      <div
        className="absolute inset-0 h-full w-full bg-rb-gray-50 bg-opacity-35 bg-right bg-no-repeat opacity-75"
        style={{
          backgroundImage: `url(${heroImageUrl})`,
          backgroundSize: 'auto 100%'
        }}
      />
      {!isCompleted && percentCompleted !== 0 && (
        <Progress
          classNames="h-2 w-full relative"
          percentComplete={percentCompleted}
          backgroundClass="bg-white"
        />
      )}
      <div className="relative flex flex-col p-4 sm:py-6 md:px-7 xl:px-10">
        {isCompleted && (
          <div className="flex items-center justify-between pb-4">
            <div
              tabIndex={0}
              role="button"
              className="ml-2 flex cursor-pointer items-center stroke-current text-xs font-medium uppercase tracking-widest hover:text-rb-teal-200"
              onClick={redirectToPrograms}
              onKeyUp={onEnterKeyPress(redirectToPrograms)}
            >
              <SVGIcon className="mr-3" name="thin-chevron-left" />
              Back to all programs
            </div>
          </div>
        )}
        <div className="flex flex-1">
          <div className="ml-2 mr-4 flex items-center justify-center">
            <div className="flex w-12 items-center justify-center rounded-full bg-white p-2 sm:w-full sm:p-3">
              <img
                className="w-[50px]"
                src={iconImageUrl}
                alt={programName || 'program name'}
              />
            </div>
          </div>
          <div className="ml-1 flex flex-1 flex-col justify-center">
            {!isCompleted && (
              <div
                className="pb-2 text-xs font-medium uppercase tracking-widest"
                data-test="cohort-header-season"
              >
                {seasonName && seasonYear && `${seasonName} ${seasonYear}`}
                {kickOffStartsAt && !isAfterDate(kickOffStartsAt) && (
                  <span
                    className="text-rb-blue-200"
                    data-test="cohort-header-start-countdown"
                  >
                    {' '}
                    &#8226; BEGINS IN <Pluralize singular="day" count={numDaysBetween} />
                  </span>
                )}
              </div>
            )}
            <div
              className="flex items-center pb-2 text-lg font-medium sm:text-2xl"
              data-test="cohort-header-name"
            >
              {programName}
              {isCompleted && (
                <Tag
                  variant="green"
                  text="Completed"
                  className="ml-4"
                  contentBefore={<CheckIcon className="mr-1 w-3 fill-current" />}
                />
              )}
            </div>
            <div className="flex items-center">
              <div className="text-xs font-medium uppercase tracking-widest">
                {programCode && (
                  <span>{getNumProgramWeeks(programCode)} weeks &#8226; </span>
                )}
                {kickOffStartsAt && (
                  <>
                    <span>
                      {formatInTimezone(kickOffStartsAt, currentUser.timezone, 'MMM d')}{' '}
                      &ndash;{' '}
                    </span>
                    <span>
                      {formatInTimezone(endsAt, currentUser.timezone, 'MMM d, yyyy')}
                    </span>
                  </>
                )}
              </div>
              {canUserCohortEnrollmentBeModified(
                enrollmentSeason?.id,
                cohortSeasonId
              ) && <ModifyEnrollment id="cohort-viewer-header" />}
            </div>
          </div>
          <div className="flex">
            <div className="hidden sm:flex">
              <Popover
                text="Launch Slack channel"
                containerClassName="h-12 w-12"
                tooltipClassName="text-xs w-32 max-w-max -left-[39px] p-1.5"
                offsetBottom="-bottom-[40px]"
                caretPosition="center"
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    currentUser.slackUserId
                      ? `https://slack.com/app_redirect?channel=${slackChannelId}&team=T01LD82F0M8`
                      : joinSlackHref
                  }
                >
                  <div className="flex h-12 w-12 items-center justify-center rounded-full border-rb-gray-100 bg-white shadow-default hover:border">
                    <img alt="slack icon" height="26" width="26" src={SlackImage} />
                  </div>
                </a>
              </Popover>
              <Popover
                text="Get support"
                containerClassName="ml-4 h-12 w-12"
                tooltipClassName="text-xs w-32 max-w-max p-1.5 -left-[10px]"
                offsetBottom="-bottom-[40px]"
                caretPosition="center"
              >
                <a target="_blank" rel="noreferrer" href="mailto:hello@reforge.com">
                  <div className="flex h-12 w-12 items-center justify-center rounded-full border-rb-gray-100 bg-white shadow-default hover:border">
                    <SVGIcon name="question-icon" height="25" width="25" />
                  </div>
                </a>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CohortViewerHeader
