import { isTextN } from './dom'
import { DomNode, HighlightMetadata, HighlightRange, ROOT_INDEX } from './types'

const queryElementNode = (
  $root: HTMLElement,
  hs: HighlightMetadata
): { start: Node; end: Node } => {
  const start =
    hs.startMeta.parentIndex === ROOT_INDEX
      ? $root
      : $root.getElementsByTagName(hs.startMeta.parentTagName)[hs.startMeta.parentIndex]
  const end =
    hs.endMeta.parentIndex === ROOT_INDEX
      ? $root
      : $root.getElementsByTagName(hs.endMeta.parentTagName)[hs.endMeta.parentIndex]

  return { start, end }
}

const getTextChildByOffset = ($parent: Node, offset: number): DomNode => {
  const nodeStack: Node[] = [$parent]

  let $curNode
  let curOffset = 0
  let startOffset = 0

  while (($curNode = nodeStack.pop())) {
    const children = $curNode.childNodes

    for (let i = children.length - 1; i >= 0; i--) {
      nodeStack.push(children[i])
    }

    if (isTextN($curNode)) {
      startOffset = offset - curOffset
      curOffset += $curNode.textContent?.length || 0

      if (curOffset >= offset) {
        break
      }
    }
  }

  if (!$curNode) {
    $curNode = $parent
  }

  return {
    $node: $curNode,
    offset: startOffset
  }
}

const formatDomNode = (n: DomNode): DomNode => {
  // this should never be null but good safeguard
  if (!n.$node) return n

  if (
    isTextN(n.$node) ||
    // CDATASection
    n.$node.nodeType === 4 ||
    // Comment
    n.$node.nodeType === 8
  ) {
    return n
  }

  return {
    $node: n.$node.childNodes[n.offset],
    offset: 0
  }
}

/**
 *  Conversion to node to iterate through
 */
export const toRange = (
  $root: HTMLElement,
  metadata: HighlightMetadata
): HighlightRange | null => {
  const { start, end } = queryElementNode($root, metadata)

  if (!start || !end) {
    return null
  }

  const startInfo = getTextChildByOffset(start, metadata.startMeta.textOffset)
  const endInfo = getTextChildByOffset(end, metadata.endMeta.textOffset)

  return {
    start: formatDomNode(startInfo),
    end: formatDomNode(endInfo),
    text: metadata.text,
    id: metadata.id!,
    allTextNodes: metadata.allTextNodes
  }
}
