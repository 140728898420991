import { useMemo } from 'react'

// Sanity doens't support multidimensional arrays, so we use an object with 4 key/value pairs instead
interface StatsProps {
  statsObject: {
    value1: string
    label1: string
    value2: string
    label2: string
    value3: string
    label3: string
    value4: string
    label4: string
  }
}
const Stats = ({ statsObject }: StatsProps) => {
  // convert the object to an array of key/value pairs
  const statsArray = useMemo(() => {
    const arr = []
    for (let i = 1; i <= 4; i++) {
      const value = statsObject[`value${i}` as keyof typeof statsObject]
      const label = statsObject[`label${i}` as keyof typeof statsObject]

      if (value || label) {
        arr.push({ i, value, label })
      }
    }
    return arr
  }, [statsObject])

  return (
    <div className="flex w-[calc(100vw-2rem)] flex-wrap items-center pt-[6.6vmax] pb-[3.3vmax] md:w-[calc(100vw-5rem)]">
      {statsArray.map((stat) => (
        <div
          className={`w-full sm:w-1/2 md:w-1/${
            statsArray.length < 3 ? 3 : statsArray.length
          } mb-[50px] shrink-0`}
          key={stat.i}
        >
          <h2 className="max-w-[75%] font-normal fluid-text-4xl">{stat.value}</h2>
          <p className="mt-[4%] max-w-[75%] !leading-[1.2] fluid-text-sm">{stat.label}</p>
        </div>
      ))}
    </div>
  )
}

export default Stats
