import { ReactComponent as PReceiptIcon } from 'images/p-receipt.svg'

interface OrderRecapFreeTrialFooterProps {
  showReimbursementComponent?: boolean
}

const OrderRecapFreeTrialFooter = ({
  showReimbursementComponent = false
}: OrderRecapFreeTrialFooterProps) => {
  return (
    <>
      <p className="text-xs leading-[1.5] mt-4">
        Your card on file will be charged at the end of the trial period. You can cancel
        at any time during the trial period from your Account page.
      </p>

      {showReimbursementComponent && (
        <div className="px-8 py-6 bg-rb-info-50 flex gap-6 rounded-xl items-center mt-8 lg:mt-4">
          <PReceiptIcon className="w-12 h-12 shrink-0" />
          <p className="mb-0 text-xs leading-[1.5]">
            90% of Reforge members get reimbursed by their company. Ask you manager about
            expensing your membership.
          </p>
        </div>
      )}
    </>
  )
}

export default OrderRecapFreeTrialFooter
