import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { getArtifactAuthorPositionAtCompany } from 'domains/Profile/utils'

import BadgeableAvatar from 'components/BadgeableAvatar'

import type { AuthorBylineProps } from './artifact-types'

export const AuthorByline = ({ author }: AuthorBylineProps) => {
  const [authorTopics, setAuthorTopics] = useState<string[]>([])
  const [artifactCount, setArtifactCount] = useState(0)

  const user = {
    ...author,
    kind: author?.isCollectiveMember ? 'collective' : ''
  }

  useEffect(() => {
    let count = 0
    let topics: string[] = []
    const workEntries: any = author?.allEntries || []
    workEntries.forEach((workEntry: { artifactContributions: any }) => {
      if (!workEntry.artifactContributions?.length) {
        return
      }

      const { artifactContributions } = workEntry
      count += artifactContributions.length

      artifactContributions.forEach(({ artifact }: any) => {
        topics = [...topics, ...artifact.topics]
      })
    })

    const sortedTopics: string[] = [...new Set(topics)].sort()

    setAuthorTopics(sortedTopics)
    setArtifactCount(count)
  }, [author])

  function formatList(items: string[]) {
    // Handle special cases for zero or one item
    if (items.length === 0) {
      return '' // Return an empty string if the array is empty
    }
    if (items.length === 1) {
      return items[0] // Return the single item directly
    }

    // Extract all items except the last one and join them with commas
    const allButLast = items.slice(0, -1).join(', ')

    // Add the last item with the ampersand
    const lastItem = items[items.length - 1]

    return `${allButLast} & ${lastItem}`
  }

  const avatarUrl = author.user?.avatarUrl
  const authorName = author.user?.fullName

  const BylineComponent = (
    <div className="flex hover:underline hover:decoration-rb-teal-300">
      <div className="mr-[16px] shrink-0 lg:mb-0">
        {avatarUrl && (
          <div style={{ width: '60px', height: '60px' }}>
            <BadgeableAvatar
              avatarUrl={avatarUrl}
              user={user}
              alt={`${authorName} avatar`}
            />
          </div>
        )}
      </div>
      <div className="flex max-w-[450px] flex-col justify-center gap-1 text-sm">
        <div>
          <div className="font-normal">{authorName}</div>
          <div className="text-balance font-normal">
            {getArtifactAuthorPositionAtCompany(author)}
          </div>
        </div>
        {(author?.isCollectiveMember || artifactCount >= 3) && !!authorTopics.length && (
          <div className="font-sans text-xs font-normal text-rb-gray-300">
            {`Top creator in ${formatList(authorTopics)}`}
          </div>
        )}
      </div>
    </div>
  )

  return (
    <Link to={`/profiles/${author.slug}`} className="mb-4 text-sm !text-rb-gray-400">
      {BylineComponent}
    </Link>
  )
}

export default AuthorByline
