export const BUSINESS_OUTCOME = 'Drive a business outcome'
export const LEVEL_UP = 'Level up as a team'
export const DEVELOP_CORE = 'Develop core competencies'
export const GOALS = [BUSINESS_OUTCOME, LEVEL_UP, DEVELOP_CORE]
export interface ReforgeCollection {
  __typename: 'BookmarkFolder'
  id: string
  name: string
  user: {
    __typename: 'User'
    id: string
    fullName?: string | null | undefined
  }
  isWhiteGlove: boolean
}

export const GOAL_FILTERS: { id: string; name: string }[] = [
  { id: BUSINESS_OUTCOME, name: BUSINESS_OUTCOME },
  { id: LEVEL_UP, name: LEVEL_UP },
  { id: DEVELOP_CORE, name: DEVELOP_CORE }
]
