import { Link } from 'react-router-dom'

import { generateOrderedEventSpeakers } from 'domains/CohortDashboard/CohortDashboardProgram/CohortDashboardWeeklyBlock'
import { CohortEvent } from 'domains/CohortViewer/utils'

import SectionCardContainer from 'components/CollapsibleSectionCard/SectionCardContainer'
import { MultipleHostImages, userProfileToHostImage } from 'components/HostImages'
import { SquarishAvatarImage } from 'components/SquarishAvatarImage'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'

interface PrereadCardProps {
  event: CohortEvent
  onClick: () => void
}

export const PrereadCard = ({ onClick, event }: PrereadCardProps) => {
  const speakers = generateOrderedEventSpeakers(event.speakers)

  const hostImages = () => {
    if (!speakers.length) {
      return null
    }

    const singleSpeaker = userProfileToHostImage(speakers[0].user.profile)
    const twoSpeakers = speakers.map((speaker) =>
      userProfileToHostImage(speaker.user.profile)
    )

    return (
      <div className="absolute flex">
        {speakers.length > 1 ? (
          <MultipleHostImages
            containerClassName="hidden md:block"
            imgClassName="w-[39px] h-[43px]"
            secondImageClassName="mt-[21px] ml-[25px]"
            hosts={twoSpeakers}
          />
        ) : (
          <SquarishAvatarImage
            avatarUrl={singleSpeaker.thumbnailUrl || ''}
            alt={singleSpeaker.name || 'Host picture'}
            className="hidden h-[64px] w-[58px] rounded md:block"
          />
        )}
      </div>
    )
  }

  return (
    <Link
      to={{ pathname: event.preread || '' }}
      target="_blank"
      className="hover:no-underline"
      onClick={onClick}
    >
      <SectionCardContainer darkenBorderOnHover={false}>
        <div className="relative flex flex-row px-5 pt-5 pb-8">
          {hostImages()}
          <div className="flex flex-col space-y-2 pt-2 md:ml-20">
            <div role="button">
              <RfParagraphMedium>Event pre-read</RfParagraphMedium>
            </div>
            <RfParagraphMini className="text-rb-gray-300">{event.name}</RfParagraphMini>
          </div>
        </div>
      </SectionCardContainer>
    </Link>
  )
}

export default PrereadCard
