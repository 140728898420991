import { useState } from 'react'

import { SearchComboboxOptions, type SearchOption } from './SearchCombobox'

export function SearchInitialContext() {
  const popularSearchOptions = usePopularSearches()

  return (
    <>
      <p className="mb-2 text-base font-semibold text-rb-black">
        Solve your toughest product challenges ✨
      </p>
      <p className="text-sm text-rb-gray-300">
        Go beyond keywords and ask Reforge a question about a problem you’re working on.
      </p>
      <SearchComboboxOptions title="Popular Searches" options={popularSearchOptions} />
    </>
  )
}

const popularSearchOptions = [
  'How should I measure retention?',
  'How do I plan for a new feature launch?',
  'What are strategies to drive activation for a b2b product?'
]
// TBD: fetch from API
function usePopularSearches(): SearchOption[] {
  const [searchOptions] = useState(
    popularSearchOptions.map((value) => ({ value, origin: 'popular-search' as const }))
  )

  return searchOptions
}
