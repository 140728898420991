import TimeShift from 'timeshift-js'

import getCsrfToken from 'utils/getCsrfToken'

const initMockTime = async () => {
  const csrfToken = getCsrfToken()
  const response = await window.fetch('/time_machine/current_time', {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': csrfToken
    }
  })
  const body = await response.json()
  // eslint-disable-next-line no-global-assign
  Date = TimeShift.Date
  TimeShift.setTime(body.currentTime * 1000)

  return Date.now()
}
export default initMockTime
