import { useParams } from 'react-router-dom'

import Invoice from 'domains/Invoice/Invoice'

import { ErrorMessage, Loading } from 'components'

import { useInvoiceQuery } from 'gql'

const InvoicePage = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>()
  const { data, loading, error } = useInvoiceQuery({ variables: { invoiceId } })

  if (loading) return <Loading />
  if (error) return <ErrorMessage error={error} />
  if (!data) return <ErrorMessage error={'Error fetching invoice data.'} />

  return <Invoice invoice={data.invoice} />
}

export default InvoicePage
