import { PropsWithChildren, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { CloseIcon } from 'components/icons'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import { onEnterKeyPress } from 'utils/keyboard'

export interface ProductTourProps {
  description: string
  title: string
  handleClose: () => void
  reference: HTMLElement
  position?: 'absolute' | 'fixed'
  wait?: number
  darkMode?: boolean
}

const ProductTour = ({
  title,
  description,
  reference,
  handleClose,
  position = 'absolute',
  wait,
  darkMode
}: PropsWithChildren<ProductTourProps>) => {
  const ref = useRef<HTMLDivElement>(null)
  const [style, setStyle] = useState({ top: 0, left: 0 })
  const [visibility, setVisibility] = useState(
    wait ? 'invisible opacity-0' : 'opacity-100'
  )
  const [caretStyle, setCaretStyle] = useState<{ left: string | number }>({ left: '50%' })

  const updateCaretPosition = () => {
    if (ref.current) {
      const tourRect = ref.current.getBoundingClientRect()
      const targetRect = reference.getBoundingClientRect()
      const caretWidth = 14 // The width of the caret.
      const padding = 20 // The padding from the edges.

      // Calculate the left position of the caret based on the reference.
      let caretLeft =
        targetRect.left + targetRect.width / 2 - tourRect.left - caretWidth / 2

      // If the reference element is wider than the ProductTour, keep the caret in the middle of the ProductTour.
      if (targetRect.width > tourRect.width) {
        caretLeft = tourRect.width / 2 - caretWidth / 2
      }

      // Ensure the caret is within the bounds of the ProductTour and respects the padding.
      if (caretLeft < padding) {
        caretLeft = padding
      } else if (caretLeft > tourRect.width - caretWidth - padding) {
        caretLeft = tourRect.width - caretWidth - padding
      }

      setCaretStyle({ left: caretLeft })
    }
  }

  const handleResize = () => {
    const targetRect = reference.getBoundingClientRect()
    let targetMidX, targetBottomY

    if (position === 'absolute' && reference.offsetParent) {
      const parentBoundingRect = reference.offsetParent.getBoundingClientRect()
      targetMidX = targetRect.left - parentBoundingRect.left + targetRect.width / 2
      targetBottomY = targetRect.top - parentBoundingRect.top + targetRect.height
    } else {
      targetMidX = targetRect.left + targetRect.width / 2
      targetBottomY = targetRect.top + targetRect.height
    }

    // Calculate the left position of the ProductTour, ensuring it's centered on the reference element
    // This defaults to 0 if ref.current is not yet defined
    let absoluteLeft = targetMidX - (ref.current?.offsetWidth || 0) / 2
    // The top position is simply the bottom of the reference element + 10px offset
    const absoluteTop = targetBottomY + 10

    // If the ProductTour would go off the left edge of the screen, snap it to the left edge
    if (absoluteLeft < 0) {
      absoluteLeft = 0
      // If the ProductTour would go off the right edge of the screen, snap it to the right edge
    } else if (absoluteLeft + (ref.current?.offsetWidth || 0) > window.innerWidth) {
      absoluteLeft = window.innerWidth - (ref.current?.offsetWidth || 0)
    }
    updateCaretPosition()

    setStyle({
      top: absoluteTop + 10,
      left: absoluteLeft
    })
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // account for shifts in layout
    setTimeout(() => {
      handleResize()
    }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Handle delayed reveal if applicable
  useEffect(() => {
    if (wait && visibility !== 'opacity-100') {
      const timeout = setTimeout(() => {
        setVisibility('opacity-100')
      }, wait)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [visibility, wait])

  return (
    <div
      ref={ref}
      className={`${position} ${visibility} z-[1001] w-screen max-w-sm px-5 transition-opacity duration-300 ease-in`}
      style={style}
    >
      <div
        className={`rounded border ${
          darkMode ? 'bg-black' : 'border-rb-green-75 bg-rb-green-50'
        } shadow-default`}
      >
        <div className="flex">
          <div
            tabIndex={0}
            role="button"
            aria-label="close-tour-button"
            data-test="close-tour-button"
            data-testid="close-tour-button"
            className="hover:bg-default ml-auto pr-3 pt-3"
            onClick={handleClose}
            onKeyUp={onEnterKeyPress(handleClose)}
          >
            <CloseIcon className={`h-3 w-3 ${darkMode && 'stroke-white'}`} />
          </div>
        </div>
        <h3
          className={`!mx-6 !mt-1 !text-lg !font-semibold ${
            darkMode ? '!mb-4 !text-white' : '!rf-h3'
          }`}
        >
          {title}
        </h3>
        <RfParagraphMedium className={`!mx-6 !mb-8 ${darkMode && '!text-white'}`}>
          {description}
        </RfParagraphMedium>
      </div>
      <div
        className={twMerge(
          `absolute -top-[7px] z-[1101] h-[14px] w-[14px] rotate-45 border-t border-l ${
            darkMode ? 'bg-black' : 'border-rb-green-75 bg-rb-green-50'
          }`
        )}
        style={caretStyle}
      />
    </div>
  )
}

export default ProductTour
