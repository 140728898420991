import { renderToString } from 'react-dom/server'
import Pluralize from 'react-pluralize'

import { SVGIcon } from 'components/Icon'

import { PostReactionFragment, ReactionKind } from 'gql'

import {
  kindToEnglish,
  kindToSvg,
  mapReactionKinds,
  sortedReactions
} from 'utils/reactionUtils'

const reactionSummaryTooltip = (reactions: PostReactionFragment[]) => {
  const reactionKinds = mapReactionKinds(reactions)
  let outputHTML = ''

  sortedReactions.map(
    (kind: ReactionKind) =>
      (outputHTML += renderToString(
        <div key={kind} className="mb-2 flex items-center">
          <SVGIcon
            className="rf-icon mr-2"
            name={kindToSvg[kind]}
            height="16"
            width="16"
            fill="#ffffff"
          />
          <span>
            <Pluralize
              singular={'person '}
              plural={'people '}
              count={reactionKinds[kind].length}
            />
            reacted with &lsquo;{kindToEnglish[kind]}&rsquo;
          </span>
        </div>
      ))
  )

  return `<div class='py-1 px-2.5' style='padding-bottom: 2px'>${outputHTML}</div>`
}

interface IReactionSummary {
  reactions: PostReactionFragment[]
}

const ReactionSummary = ({ reactions }: IReactionSummary) => {
  // This is the summary on the index page, Only called if reactions.length > 0
  return (
    <div
      uk-tooltip={`title: ${reactionSummaryTooltip(
        reactions
      )}; cls: uk-active rf-tooltip-down-arrow; pos: top`}
    >
      <div className="group ml-2.5 flex cursor-pointer items-center leading-4 text-rb-gray-300 hover:text-rb-teal-200 hover:no-underline">
        <span className="flex justify-center">
          <SVGIcon
            className="mr-1 text-rb-gray-300 group-hover:text-rb-teal-200"
            name="simple-smile"
            fill="currentColor"
            height="16"
            width="16"
          />
        </span>
        <span className="tl:hidden">{reactions.length}</span>
        <span className="hidden tl:block">
          <Pluralize
            singular={'reaction'}
            plural={'reactions'}
            count={reactions.length}
          />
        </span>
      </div>
    </div>
  )
}

export default ReactionSummary
