import { useHistory } from 'react-router-dom'

import EventPage from 'pages/EventPage/EventPage'

import Button from 'components/Button'

import { ReactComponent as ChevronLeft } from 'images/chevron-left.svg'

interface CohortDashboardEventPageProps {
  eventId: string
  cohortSlug: string
}

export const CohortDashboardEventPage = ({
  eventId,
  cohortSlug
}: CohortDashboardEventPageProps) => {
  const history = useHistory()

  const handleRedirect = () => history.push(`/cohorts/${cohortSlug}`)

  return (
    <div className="flex w-full flex-col overflow-auto">
      <Button
        variant="text-only"
        size="small"
        onClick={handleRedirect}
        iconBefore={<ChevronLeft width={8} height={16} />}
        className="px-4 pt-4"
      >
        Back
      </Button>
      <EventPage eventId={eventId} cohortSlug={cohortSlug} isCohortDashboard />
    </div>
  )
}
