import { twMerge } from 'tailwind-merge'

import { Alert } from 'components/Alert'
import Button from 'components/Button'
import { CheckoutFlowContext, OrderRecapType } from 'components/CheckoutModal/utils'
import Loading from 'components/Loading'
import { Tag } from 'components/Tag'
import Tooltip from 'components/Tooltip/Tooltip'
import { InfoIcon } from 'components/icons'

import { TaxIdReverseChargeStatus } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import { FULL_DATE_ABBREV_MONTH_FORMAT, formatInTimezone } from 'utils/date'
import { capitalizeFirstLetter, pluralize } from 'utils/stringUtils'

import { SectionHeading } from '../components/Headings'
import { SectionBorder } from '../components/SectionBorder'
import { getSalesTaxLabelDetails } from '../useTaxInfo'
import OrderRecapLabel from './components/OrderRecapLabel'
import OrderRecapPrice from './components/OrderRecapPrice'
import { TermsAndConditions } from './components/TermsAndConditions'

export interface OrderRecapProps {
  orderRecap: OrderRecapType
  total: number
  loading?: boolean
  handlePurchaseClick: () => void
  checkoutPurchaseLoading: boolean
  error: string | null
  taxIdReverseChargeStatus?: TaxIdReverseChargeStatus | null
  taxIdVerificationCallback: () => void
  flowContext?: CheckoutFlowContext
}

const OrderRecap = ({
  orderRecap,
  total,
  loading,
  handlePurchaseClick,
  error,
  checkoutPurchaseLoading,
  taxIdReverseChargeStatus,
  taxIdVerificationCallback,
  flowContext
}: OrderRecapProps) => {
  const { timezone } = useAssertCurrentUser()
  const {
    plan,
    cohortPass,
    discountedCohortPassAmount,
    proratedAdjustment,
    subTotal,
    startingBalance,
    taxInfo
  } = orderRecap
  const hasPlan = !!plan?.name

  const cohortPassCount = cohortPass.quantity
  const cohortPassPrice = cohortPass.price
  const cohortPassLabel = cohortPass.label
  const cohortPassSubTotal = cohortPassCount * cohortPassPrice

  return (
    <div className="sticky top-0">
      <SectionHeading>Order Recap</SectionHeading>
      <SectionBorder>
        {loading && <Loading ghosted className="bg-[rgba(255,255,255,0.6)] pt-[50%]" />}
        <div className="w-full">
          <div className="mb-4 grid grid-flow-row grid-cols-[1fr_auto] gap-y-2.5">
            {/* plan */}
            {plan && (
              <>
                <OrderRecapLabel
                  label={`${capitalizeFirstLetter(plan.name)} Subscription`}
                  details={`${plan.seatCount} ${pluralize(
                    'seat',
                    'seats',
                    plan.seatCount
                  )}`}
                />
                <OrderRecapPrice price={plan?.price} />
              </>
            )}

            {/* prorated adjustment */}
            {plan && proratedAdjustment && (
              <>
                <OrderRecapLabel
                  label={
                    <span className="flex items-center">
                      Prorated adjustment
                      <Tooltip
                        tooltipBody={`Unused time on current plan + Remaining time on ${
                          plan.name
                        } plan after ${formatInTimezone(
                          proratedAdjustment.start,
                          timezone,
                          FULL_DATE_ABBREV_MONTH_FORMAT
                        )}`}
                        contentWrapperClassname="ml-2"
                        className="max-w-xs text-center"
                      >
                        <InfoIcon fill="currentColor" className="w-3.5" />
                      </Tooltip>
                    </span>
                  }
                  details={`${formatInTimezone(
                    proratedAdjustment.start,
                    timezone,
                    FULL_DATE_ABBREV_MONTH_FORMAT
                  )} - ${formatInTimezone(
                    proratedAdjustment.end,
                    timezone,
                    FULL_DATE_ABBREV_MONTH_FORMAT
                  )}`}
                />
                <OrderRecapPrice price={proratedAdjustment.price} />
              </>
            )}

            {/* discounted cohort pass */}
            {discountedCohortPassAmount !== null && (
              <>
                <OrderRecapLabel
                  label={
                    <>
                      <span className="mr-2">Live Course Pass x 2</span>
                      <Tag text="Discounted" variant="green" />
                    </>
                  }
                />
                <OrderRecapPrice price={discountedCohortPassAmount} />
              </>
            )}

            {/* cohort passes */}
            {(cohortPassCount > 0 || flowContext === 'cohortPassPurchase') && (
              <>
                <OrderRecapLabel
                  label={`${cohortPassLabel} x ${cohortPassCount}`}
                  details={
                    <>
                      <OrderRecapPrice price={cohortPassPrice} className="inline-block" />{' '}
                      each
                    </>
                  }
                />
                <OrderRecapPrice price={cohortPassSubTotal} />
              </>
            )}
          </div>

          <div className="mb-4 grid grid-flow-row grid-cols-[1fr_auto] gap-y-2.5 border-t border-rb-gray-100 pt-4">
            {/* subtotal */}
            <OrderRecapLabel label="Subtotal" className="text-base" />
            <OrderRecapPrice price={subTotal.price} />

            {!taxInfo && (
              <>
                <OrderRecapLabel label="Sales Tax" />
                <span className="text-right">TBD</span>
              </>
            )}

            {/* tax */}
            {taxInfo && (
              <>
                <OrderRecapLabel
                  label="Sales Tax"
                  details={getSalesTaxLabelDetails(
                    taxIdReverseChargeStatus,
                    taxIdVerificationCallback
                  )}
                />

                <OrderRecapPrice price={taxInfo.price || 0} />
              </>
            )}

            {/* account credit / starting balance */}
            {!!startingBalance.price && (
              <>
                <OrderRecapLabel label="Account Credit" />
                <OrderRecapPrice price={startingBalance.price} />
              </>
            )}
          </div>

          <div className="mt-3 flex flex-row justify-between border-t border-rb-gray-100 pt-4 text-xl font-semibold">
            <OrderRecapLabel label="Total" className="text-xl" />
            <OrderRecapPrice price={total} />
          </div>

          <Button
            className={twMerge('mt-6 h-12 w-full rounded', 'bg-rb-black text-white')}
            onClick={handlePurchaseClick}
            isLoadingSpinner={checkoutPurchaseLoading}
            data-test="purchase-button"
          >
            Purchase
          </Button>

          <Alert className="mt-5">{error}</Alert>

          <TermsAndConditions hasPlan={hasPlan} />
        </div>
      </SectionBorder>
    </div>
  )
}

export default OrderRecap
