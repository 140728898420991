import { Link } from 'react-router-dom'

import { useCohortDashboardTrackingContext } from 'domains/CohortDashboard/hooks/useCohortDashboardTrackingContext'
import { CohortEvent } from 'domains/CohortViewer/utils'

import { SquarishAvatarImage } from 'components/SquarishAvatarImage'
import MultiTimeAttendButton from 'components/cards/EventCard/MultiTimeAttendButton'
import RfHeader3 from 'components/typography/RfHeader/RfHeader3'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import RfParagraphSmallBold from 'components/typography/RfParagraph/RfParagraphSmallBold'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import {
  CohortDashboardCurrentUserPartsFragment,
  CohortEventSpeakersPartsFragment
} from 'gql'

import { prettyJoin } from 'utils/arrayUtils'
import { eventForLink } from 'utils/cohortUtils'
import { trackCtaClicked } from 'utils/tracking/analytics'
import { makeCohortEventDetailsUrl } from 'utils/url'

export interface WeeklyBlockEventCardProps {
  slug: string
  event: CohortEvent
  currentUser: CohortDashboardCurrentUserPartsFragment
  index: number
  scheduledWeekTitle: string
  scheduledWeekIndex: number
  programName: string
  rsvpCallback: (event: CohortEvent) => void
}

export const generateOrderedEventSpeakers = (
  speakers?: CohortEventSpeakersPartsFragment[] | null
): CohortEventSpeakersPartsFragment[] => {
  if (!speakers || !speakers.length) {
    return []
  }

  // order is first host, first featured guest, rest of hosts, rest of featured guests
  const hostSpeakers = speakers.filter((speaker) => speaker.kind === 'Host')
  const featuredGuestSpeakers = speakers.filter(
    (speaker) => speaker.kind === 'Featured Guest'
  )

  return [
    hostSpeakers[0],
    featuredGuestSpeakers[0],
    ...hostSpeakers.slice(1, hostSpeakers.length),
    ...featuredGuestSpeakers.slice(1, hostSpeakers.length)
  ].filter((speaker) => !!speaker)
}

export const WeeklyBlockEventCard = ({
  event,
  currentUser,
  index,
  scheduledWeekTitle,
  scheduledWeekIndex,
  programName,
  slug,
  rsvpCallback
}: WeeklyBlockEventCardProps) => {
  const eventIsCaseStudy = index === 0
  const isKickoffWeek = scheduledWeekIndex === 0

  const generateFirstTitle = () => {
    if (isKickoffWeek) {
      return `${programName} Kickoff`
    }
    if (scheduledWeekTitle) {
      if (eventIsCaseStudy) {
        // First event in a scheduled week is always a core event (not an ask an Expert)
        return scheduledWeekTitle
      } else {
        return 'Ask an Expert'
      }
    }

    return null
  }

  const firstTitle = generateFirstTitle()
  const firstThreeOrderedEventSpeakers = generateOrderedEventSpeakers(
    event.speakers || []
  ).slice(0, 3)

  const eventForDetailsLink = eventForLink(event)

  const eventDetailsLink = makeCohortEventDetailsUrl(
    slug,
    eventForDetailsLink.id,
    eventForDetailsLink.slug
  )

  const { cohort, userCohortId } = useCohortDashboardTrackingContext()

  const onLinkClick = () => {
    trackCtaClicked({
      cta_location: 'event_card_preread_link',
      cta_type: 'link',
      text: 'Event pre-read',
      destination: event.preread,
      related_identifiers: {
        cohort_id: cohort.id,
        cohort_name: cohort.name,
        user_cohort_id: userCohortId
      }
    })
  }

  const isAttendingEvent = event.sameEventInfo.find(
    (eventInfo) => eventInfo.attendingStatus === 'Attending'
  )

  return (
    <div className="flex h-[375px] w-full flex-col justify-between rounded-lg bg-rb-black hover:border-rb-gray-500 xl:max-w-[312px]">
      <Link to={eventDetailsLink} className="hover:no-underline">
        <div className="overflow-hidden rounded-tr-lg rounded-tl-lg">
          <div data-testid="event-speaker-images" className="flex flex-row">
            {firstThreeOrderedEventSpeakers.map((speaker, i) => (
              <div
                key={speaker.id}
                data-testid={`event-speaker-image-${i}`}
                className="flex-1"
              >
                <SquarishAvatarImage
                  className="h-[180px] min-w-[101%] rounded-none"
                  avatarUrl={speaker.user.profile.avatarUrl}
                  alt={speaker.user.profile.fullName || ''}
                />
              </div>
            ))}
          </div>
          <div className="p-5" data-testid="event-title">
            {firstTitle && (
              <RfHeader3 className="!mb-1.5 p-0 !text-rb-white line-clamp-2">
                {firstTitle}
              </RfHeader3>
            )}
            <RfParagraphSmall className="truncate" color={ColorOptions.gray}>
              With{' '}
              {prettyJoin(
                firstThreeOrderedEventSpeakers.map(
                  (speaker) => speaker.user.profile.fullName
                ),
                ', ',
                ' & '
              )}
            </RfParagraphSmall>
          </div>
        </div>
      </Link>
      <div className="flex flex-row items-center justify-between p-5 pt-0">
        <div className="relative">
          {/* keep this as relative so MultiTimeAttendButton calendar dropdown mounts relative to this */}
          <MultiTimeAttendButton
            dataTestId={`event-attend-button-${event.id}`}
            timezone={currentUser.timezone}
            event={event}
            rsvpCallback={rsvpCallback}
            eventUrlOverride={eventDetailsLink}
            rsvpButtonProps={{
              size: 'medium',
              className: isAttendingEvent
                ? 'bg-black text-white border border-white'
                : 'bg-white text-black'
            }}
            threeDotIconFill="#FFFFFF"
            joinNowButtonProps={{ size: 'medium', variant: 'fill' }}
            replayButtonProps={{
              size: 'medium',
              className: 'bg-black text-white border border-white'
            }}
          />
        </div>
        {event.preread && (
          <Link
            to={{ pathname: event.preread }}
            target="_blank"
            className="hover:no-underline"
            onClick={onLinkClick}
          >
            <RfParagraphSmallBold color={ColorOptions.white}>
              Event pre-read
            </RfParagraphSmallBold>
          </Link>
        )}
      </div>
    </div>
  )
}

export default WeeklyBlockEventCard
