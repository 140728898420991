import addDays from 'date-fns/addDays'
import mapsAndTemplateIcon from 'icon--map.svg'
import { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useGlobalState } from 'state'

import WeekBlock from 'domains/CohortViewer/WeekBlock'
import { buildTodoTasks } from 'domains/CohortViewer/utils'
import RsvpAllEventsModal from 'domains/Event/RsvpAllEventsModal/RsvpAllEventsModal'

import CommitmentModal from 'components/CommitmentModal'
import { SVGIcon } from 'components/Icon'
import { useModal } from 'components/Modal'
import {
  ShareableSnapshotModal,
  useShareableSnapshotModal
} from 'components/ShareableSnapshotModal'
import SimpleCardWithAction from 'components/cards/SimpleCardWithAction'
import ProgressCircle from 'components/cards/shared/ProgressCircle'
import { WorksheetIcon } from 'components/icons'

import { RSVP_ALL_EVENTS_IN_PROGRAM_ID } from 'constants/elementIds'

import {
  CohortViewerMaterialCmsModulePartsFragment,
  CohortViewerMaterialParentSectionPartsFragment,
  CohortViewerMaterialSectionPartsFragment,
  CohortViewerPartsFragment,
  CurrentUserPartsFragment,
  useCohortViewerMaterialQuery,
  useUpdateUserCohortMutation
} from 'gql'

import {
  DAY_OF_MONTH_FORMAT,
  FULL_DATE_ABBREV_MONTH_FORMAT,
  FULL_DATE_FORMAT,
  MONTH_ABBREV_FORMAT,
  formatInTimezone,
  isBetweenDates
} from 'utils/date'
import { onEnterKeyPress } from 'utils/keyboard'

const EIRMessage = ({
  text,
  dismissMessage
}: {
  text: string
  dismissMessage: () => void
}) => (
  <div className="rf-tooltip-caret rf-tooltip-caret-right rf-rb-card relative mb-8 flex p-4">
    {text}
    <div
      className="ml-auto cursor-pointer stroke-current text-xl text-rb-gray-300 hover:text-rb-gray-500"
      onClick={dismissMessage}
      role="button"
      tabIndex={0}
      onKeyUp={onEnterKeyPress(dismissMessage)}
    >
      <SVGIcon name="close" height="24" width="24" strokeWidth="2" />
    </div>
  </div>
)

const UpcomingProgramContentAlert = ({
  contentReleaseDate,
  firstName,
  hasDismissedNewContentEirMessage,
  userCohortId
}: {
  contentReleaseDate: string
  firstName: string
  hasDismissedNewContentEirMessage: boolean
  userCohortId: string
}) => {
  const [isAlertOpen, setIsAlertOpen] = useState(!hasDismissedNewContentEirMessage)
  const [updateUserCohort] = useUpdateUserCohortMutation()

  const onClose = () => {
    setIsAlertOpen(false)
    updateUserCohort({
      variables: {
        input: {
          id: userCohortId,
          hasDismissedNewContentEirMessage: true
        }
      }
    })
  }

  const welcomeMessage = `Welcome${firstName ? `, ${firstName}` : ''}`

  return isAlertOpen ? (
    <EIRMessage
      text={`${welcomeMessage}! This is a brand new program. Material will become available the week of ${contentReleaseDate}.`}
      dismissMessage={onClose}
    />
  ) : null
}

interface WeeklyListProps {
  currentUser: CurrentUserPartsFragment
  userCohort: CohortViewerPartsFragment
  cohortStart?: string
  endsAt?: string
  slug: string
  joinSlackHref: string
  isLiveProgramCompleted: boolean
  hasDismissedPostCohortEirMessage: boolean
}

const WeeklyList = ({
  currentUser,
  userCohort,
  cohortStart,
  endsAt,
  slug,
  joinSlackHref,
  isLiveProgramCompleted,
  hasDismissedPostCohortEirMessage
}: WeeklyListProps) => {
  const history = useHistory()
  const { canShowSnapshotModal, showShareableSnapshotModal } = useShareableSnapshotModal()

  const [hasSignedStatementOfCommitment, setHasSignedStatementOfCommitment] = useState(
    userCohort.hasSignedStatementOfCommitment
  )
  const [eirMessageDismissed, setEirMessageDismissed] = useState(
    hasDismissedPostCohortEirMessage
  )
  const [hasClickedShareSnapshotCta, setHasClickedShareSnapshotCta] = useGlobalState(
    'hasClickedShareSnapshotCta'
  )

  const [updateUserCohort] = useUpdateUserCohortMutation()
  const {
    isModalOpen: isRsvpAllEventsModalOpen,
    setIsModalOpen: setIsRsvpAllEventsModalOpen
  } = useModal()

  const [mapsAndTemplatesModule, setMapsAndTemplatesModule] = useState<
    CohortViewerMaterialCmsModulePartsFragment | undefined
  >(undefined)

  const { data } = useCohortViewerMaterialQuery({
    variables: { programId: userCohort.cohort.cmsProgram.id.toString() }
  })

  useEffect(() => {
    const weekBlock = document.getElementById('currentWeek')
    const cohortHeader = document.getElementById('cohort-viewer-header-wrapper')

    if (weekBlock && cohortHeader && !isLiveProgramCompleted) {
      const positioningOffset = parseInt(weekBlock.dataset.weeknumber || '1') * 100
      document.getElementById('page')?.scrollTo({
        left: 0,
        top: weekBlock.offsetTop - cohortHeader.offsetHeight - positioningOffset,
        behavior: 'smooth'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data) {
      const mapsAndTemplates = data.cmsProgram?.cmsModules.find((modules: any) =>
        modules.slug.startsWith('maps')
      )

      setMapsAndTemplatesModule(mapsAndTemplates)
    }
  }, [isLiveProgramCompleted, data])

  useEffect(() => {
    setHasClickedShareSnapshotCta(userCohort.hasClickedShareSnapshotCta)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCohort])

  const [hasClickedMeetCohortParticipantsCta, setHasClickedMeetCohortParticipantsCta] =
    useState(
      userCohort.hasClickedMeetCohortParticipantsCta.includes(userCohort.cohort.id)
    )

  const handleHasClickedMeetCohortParticipantsCta = () => {
    setHasClickedMeetCohortParticipantsCta(true)
    const clickedInCohortIds = [
      ...userCohort.hasClickedMeetCohortParticipantsCta,
      userCohort.cohort.id
    ]
    updateUserCohort({
      variables: {
        input: {
          id: userCohort.id,
          hasClickedMeetCohortParticipantsCta: clickedInCohortIds
        }
      }
    })
  }

  const openRsvpModal = () => {
    setIsRsvpAllEventsModalOpen(true)
  }

  const { todoTasks, completionCount } = buildTodoTasks(
    userCohort,
    cohortStart,
    hasSignedStatementOfCommitment,
    currentUser,
    handleHasClickedMeetCohortParticipantsCta,
    hasClickedMeetCohortParticipantsCta,
    openRsvpModal,
    joinSlackHref
  )

  const dismissEirMessage = async () => {
    setEirMessageDismissed(true)
    // we don't need a response from this query
    updateUserCohort({
      variables: {
        input: {
          id: userCohort.id,
          hasDismissedPostCohortEirMessage: true
        }
      }
    })
  }

  const getProgramDates = () => {
    let programDates = ''

    if (cohortStart) {
      programDates = `${formatInTimezone(cohortStart, currentUser.timezone, 'MMM d')} `
    }

    if (endsAt) {
      programDates += ` - ${formatInTimezone(
        endsAt,
        currentUser.timezone,
        'MMM d, yyyy'
      )}`
    }

    return programDates
  }

  const getMapsAndMaterials = () => {
    if (mapsAndTemplatesModule) {
      let mapsAndTemplates: Array<
        | CohortViewerMaterialParentSectionPartsFragment
        | CohortViewerMaterialSectionPartsFragment
      > = mapsAndTemplatesModule.cmsSections

      // maps and templates for growth series are wrapped under a section, handle cases like that
      if (
        mapsAndTemplates[0] &&
        'children' in mapsAndTemplates[0] &&
        mapsAndTemplates[0].children.length > 0
      ) {
        mapsAndTemplates = mapsAndTemplates[0].children
      }

      return mapsAndTemplates.slice(-3).map((cmsSection, i: number) => (
        <div
          className="mb-1 flex items-center hover:text-rb-teal-200"
          key={i}
          role="link"
          tabIndex={0}
          onKeyUp={(e) => {
            e.stopPropagation()
            history.push(cmsSection.href)
          }}
          onClick={(e) => {
            e.stopPropagation()
            history.push(cmsSection.href)
          }}
        >
          <div className="mr-1 flex items-center">
            <WorksheetIcon className="h-4 w-4" />
          </div>
          <div>{cmsSection.name}</div>
        </div>
      ))
    } else return <> </>
  }

  const getMapsAndMaterialsCount = () => {
    if (!mapsAndTemplatesModule) return 0

    return mapsAndTemplatesModule ? mapsAndTemplatesModule.cmsSections.length : 0
  }

  const getWeekBlocks = () => {
    const weekBlocks: any[] = [
      <Fragment key="pre-kickoff">
        {isLiveProgramCompleted && <hr className="mt-10" />}
        <div
          className={`${
            isLiveProgramCompleted && 'mt-8'
          } mb-4 text-xl font-medium capitalize`}
        >
          {isLiveProgramCompleted
            ? `Your program schedule • ${getProgramDates()}`
            : 'Before the Cohort Starts'}
        </div>
        <WeekBlock
          dataTestId="pre-kickoff"
          title="Cohort checklist"
          description="If you're excited to get started before your kickoff call, take care of a few items ahead of time."
          numLessons={3}
          estimatedTimeInMinutes={15}
          todoTasks={todoTasks}
          currentUser={currentUser}
          userCohort={userCohort}
          startsCollapsed={false}
          progressPercent={(completionCount / todoTasks.length) * 100}
          isTasks={true}
          weekNumber={0}
        />
      </Fragment>
    ]

    userCohort.cohort.scheduledWeeks.map((scheduledWeek, i) => {
      const startsAt =
        i !== 0 && scheduledWeek.startsAt && userCohort.cohort.cmsProgram.code !== 'gff'
          ? addDays(new Date(scheduledWeek.startsAt), 1)
          : scheduledWeek.startsAt

      const weeklyHeaderText =
        i === 0 ? (
          <span>Prepare for Kickoff Week</span>
        ) : (
          <>
            <span>
              {startsAt &&
                formatInTimezone(
                  startsAt,
                  currentUser.timezone,
                  MONTH_ABBREV_FORMAT
                )}{' '}
            </span>
            <span>
              {startsAt &&
                formatInTimezone(
                  startsAt,
                  currentUser.timezone,
                  DAY_OF_MONTH_FORMAT
                )}{' '}
              &ndash;{' '}
            </span>
            <span>
              {formatInTimezone(
                scheduledWeek.endsAt,
                currentUser.timezone,
                FULL_DATE_ABBREV_MONTH_FORMAT
              )}
            </span>
          </>
        )

      const weekBlock = (
        <Fragment key={i}>
          {scheduledWeek.postCohort && <hr className="mt-10" />}
          <div
            id={
              isBetweenDates(scheduledWeek.startsAt, scheduledWeek.endsAt)
                ? 'currentWeek'
                : ''
            }
            data-weeknumber={i + 1}
            className="mt-8 mb-6 text-xl font-medium capitalize"
          >
            {!scheduledWeek.bonus &&
              !isLiveProgramCompleted &&
              !scheduledWeek.postCohort && (
                <>
                  {isBetweenDates(scheduledWeek.startsAt, scheduledWeek.endsAt) && (
                    <span>This week: </span>
                  )}
                  {weeklyHeaderText}
                </>
              )}
            {!isLiveProgramCompleted && scheduledWeek.bonus && (
              <span>Bonus Material</span>
            )}
            {scheduledWeek.postCohort && <span>Suggested Next Steps</span>}
          </div>
          <WeekBlock
            dataTestId={`week-${i + 1}`}
            currentUser={currentUser}
            events={scheduledWeek.events}
            cmsModules={scheduledWeek.orderedCmsModules}
            startsCollapsed={false}
            userCohort={userCohort}
            title={scheduledWeek.title || ''}
            description={scheduledWeek.description || ''}
            numLessons={scheduledWeek.numLessons || 0}
            estimatedTimeInMinutes={scheduledWeek.estimatedTimeInMinutes || 0}
            progressPercent={scheduledWeek.progressPercent || 0}
            weekNumber={i + 1}
            numScheduledWeeks={userCohort.cohort.scheduledWeeks.length}
            isProject={scheduledWeek.postCohort}
            contentReleaseDate={formatInTimezone(
              scheduledWeek.orderedCmsModules[0]?.publishAt,
              currentUser.timezone,
              FULL_DATE_ABBREV_MONTH_FORMAT
            )}
          />
        </Fragment>
      )

      if (isLiveProgramCompleted && (scheduledWeek.postCohort || scheduledWeek.bonus)) {
        weekBlocks.unshift(weekBlock)
      } else {
        weekBlocks.push(weekBlock)
      }
    })

    return weekBlocks
  }

  const showSnapshotModal = canShowSnapshotModal(userCohort.id)
  const isNewProgramWithUnreleasedContent = userCohort.cohort.cmsProgram.notLaunched
  const redirectToMapsAndTemplates = () => {
    history.push(`/cohorts/${slug}/material?templates=true`)
  }

  return (
    <div className="mb-40 p-4 lg:p-8 lg:pt-6 xl:p-10 xl:pt-6">
      {isNewProgramWithUnreleasedContent && (
        <UpcomingProgramContentAlert
          contentReleaseDate={formatInTimezone(
            userCohort.cohort.cmsProgram.releaseStartDate,
            currentUser.timezone,
            FULL_DATE_FORMAT
          )}
          firstName={currentUser.profile.firstName || ''}
          hasDismissedNewContentEirMessage={userCohort.hasDismissedNewContentEirMessage}
          userCohortId={userCohort.id}
        />
      )}
      {isLiveProgramCompleted && (
        <>
          {!eirMessageDismissed && (
            <EIRMessage
              text={`Congrats, ${currentUser.profile.firstName}! You’ve done outstanding work, and we’re
              thrilled you could join us.`}
              dismissMessage={dismissEirMessage}
            />
          )}

          <div className="flex flex-wrap">
            {showSnapshotModal && (
              <div className="w-full pb-4 sm:w-3/6 sm:pb-0">
                <SimpleCardWithAction
                  icon={
                    <div className="h-8 w-8">
                      <ProgressCircle
                        percentComplete={hasClickedShareSnapshotCta ? 100 : 0}
                      />
                    </div>
                  }
                  title="Celebrate Your Accomplishment"
                  description="View this snapshot of your program highlights and celebrate your hard work with your peers."
                  linkText="🎉 View + Share"
                  disableActionButton={false}
                  linkAction={() => {
                    showShareableSnapshotModal('Cohort Viewer')
                  }}
                />
                <ShareableSnapshotModal page="Cohort Viewer" />
              </div>
            )}
            {mapsAndTemplatesModule && (
              <div
                className={`w-full sm:w-3/6 ${showSnapshotModal ? 'sm:ml-3' : ''} flex-1`}
              >
                <SimpleCardWithAction
                  icon={
                    <div className="flex h-8 w-8 items-center justify-center rounded-full bg-rb-green-75/25">
                      <img src={mapsAndTemplateIcon} alt="maps and templates" />
                    </div>
                  }
                  title="View Maps & Templates"
                  description={getMapsAndMaterials()}
                  linkText={`View All (${getMapsAndMaterialsCount()})`}
                  disableActionButton={getMapsAndMaterialsCount() === 0}
                  linkAction={redirectToMapsAndTemplates}
                />
              </div>
            )}
          </div>
        </>
      )}

      {getWeekBlocks()}

      {!userCohort.hasSignedStatementOfCommitment && (
        <CommitmentModal
          userCohortId={userCohort.id}
          setShowCommitmentCard={() => setHasSignedStatementOfCommitment(true)}
        />
      )}

      {cohortStart &&
        userCohort.cohort.batchRsvpTimeData &&
        userCohort.cohort.batchRsvpTimeData.length > 0 && (
          <RsvpAllEventsModal
            id={RSVP_ALL_EVENTS_IN_PROGRAM_ID}
            cohortEventTimeData={{
              cohortEventTimes: userCohort.cohort.batchRsvpTimeData,
              timezone: currentUser.timezone,
              kickoffEvents: userCohort.cohort.kickoffEvents
            }}
            name={userCohort.cohort.season.name}
            year={userCohort.cohort.season.year}
            programName={userCohort.cohort.cmsProgram.name}
            timeGroupingSelected={userCohort.timeGroupingSelected}
            cohortId={`${userCohort.cohort.id}`}
            isOpen={isRsvpAllEventsModalOpen}
            handleClose={() => {
              setIsRsvpAllEventsModalOpen(false)
            }}
          />
        )}
    </div>
  )
}

export default WeeklyList
