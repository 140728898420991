import { HTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

import { TestimonialFieldsFragment } from 'gql'

type Props = {
  testimonial: TestimonialFieldsFragment
} & HTMLAttributes<HTMLDivElement>

export default function Testimonial({ testimonial, className }: Props) {
  if (!testimonial) return null

  return (
    <div
      className={twMerge(
        'hidden w-72 flex-col rounded-lg bg-rb-gray-50 p-6 lg:block',
        className
      )}
    >
      <p className="text-xs font-medium italic">&quot;{testimonial.copy}&quot;</p>
      <div className="flex">
        {!!testimonial.thumbnailUrl && (
          <img
            src={testimonial.thumbnailUrl}
            alt="program-testimonial"
            className="mr-2 h-9 w-9 rounded-full"
          />
        )}
        <span className="text-xs">
          {testimonial.name}, {testimonial.role}
        </span>
      </div>
    </div>
  )
}
