import { Redirect, Route, Switch, useParams } from 'react-router-dom'

import ArtifactPage from 'pages/Artifacts/ArtifactPage'
import CourseDetailsPage from 'pages/CourseDetailsPage/CourseDetailsPage'
import CourseEventPage from 'pages/CourseEventPage'
import { UnitLandingPage } from 'pages/UnitLandingPage'

import { Loading } from 'components'

import { useCourseDetailEntityPreviewQuery } from 'gql'

import { buildCourseDetailsUrl } from 'utils/courseUtils'

type CourseDetailsPageRouterParams = {
  courseSlug: string
  guideSlug?: string
  artifactSlug?: string
  sanityEventId?: string
}

const CourseDetailsPageRouter = () => {
  const { courseSlug, guideSlug, artifactSlug, sanityEventId } =
    useParams<CourseDetailsPageRouterParams>()

  const isPreviewPage = window.location.pathname.includes('preview')

  const { data, loading } = useCourseDetailEntityPreviewQuery({
    variables: {
      slug: courseSlug
    },
    skip: !isPreviewPage
  })

  if (loading) return <Loading />

  if (isPreviewPage && !data?.cclCourse) {
    return <Redirect to="/courses" />
  }

  return (
    <div className="relative flex flex-col">
      <Switch>
        <Route
          exact
          path={[`/courses/${courseSlug}/details`]}
          render={() => <CourseDetailsPage courseSlug={courseSlug} />}
        />

        <Route
          exact
          path={`/courses/${courseSlug}/guides/${guideSlug}/preview`}
          render={() => (
            <UnitLandingPage
              unitSlug={guideSlug}
              course={data?.course}
              showCourseBreadcrumb
              backToCourseLink={buildCourseDetailsUrl({ courseSlug })}
              backToCourseLabel="Course Home"
              preview
              courseDetails={data?.cclCourse}
              upcomingCourseSessions={data?.upcomingCourseSessions}
            />
          )}
        />

        <Route
          exact
          path={`/courses/${courseSlug}/events/${sanityEventId}/preview`}
          render={() => (
            <CourseEventPage
              preview
              sanityEventId={sanityEventId}
              courseDetails={data?.cclCourse}
              upcomingCourseSessions={data?.upcomingCourseSessions}
            />
          )}
        />

        <Route
          exact
          path={`/courses/${courseSlug}/artifacts/${artifactSlug}/preview`}
          render={() => (
            <ArtifactPage
              artifactSlug={artifactSlug}
              courseIdentifiers={{
                course: {
                  id: data?.cclCourse?.id || '',
                  title: data?.cclCourse?.title || '',
                  __typename: 'Course'
                }
              }}
            />
          )}
        />
      </Switch>
    </div>
  )
}

export default CourseDetailsPageRouter
