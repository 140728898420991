export interface ToggleSwitchProps {
  toggled?: boolean
  handleClick: () => void
  canEdit?: boolean
  className?: string
}

/** Description of ToggleSwitch */
export const ToggleSwitch = ({
  toggled = false,
  handleClick,
  canEdit = true,
  className
}: ToggleSwitchProps) => {
  return (
    <div className={className}>
      <label className="relative inline-flex cursor-pointer">
        <input
          readOnly
          type="checkbox"
          value=""
          className="peer sr-only"
          checked={toggled}
          disabled={!canEdit}
          onClick={handleClick}
        />
        <div
          className="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-0.5 after:left-[2px] after:h-5 after:w-5
          after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-teal-600
          peer-checked:after:translate-x-full peer-checked:after:border-white dark:border-gray-600 dark:bg-gray-700"
        />
        <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300" />
      </label>
    </div>
  )
}

export default ToggleSwitch
