import { useFeatureFlags } from 'hooks/useFeatureFlags'

import RealTimeEventReminder from './RealTimeEventReminder'

const RealTimeEventReminderContainer = () => {
  const { showRealTimeEventReminder } = useFeatureFlags()

  if (!showRealTimeEventReminder) {
    return null
  }

  return <RealTimeEventReminder />
}

export default RealTimeEventReminderContainer
