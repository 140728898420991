import { useLocation, useParams } from 'react-router-dom'

import Button from 'components/Button'
import RfHeader2 from 'components/typography/RfHeader/RfHeader2'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import { useCourseSnapshotQuery } from 'gql'

import { ReactComponent as ReforgeLogo } from 'images/reforge-logo-black.svg'

const CourseSnapshotHeaderSkeleton = () => (
  <div className="flex flex-col gap-6 md:flex-row md:gap-12">
    <div className="min-h-[223px] w-full max-w-[418px] animate-pulse rounded bg-rb-gray-100" />

    <div className="flex-1">
      <div className="h-[40px] w-full animate-pulse rounded bg-rb-gray-100" />
      <div className="mt-4 h-[25px] w-full animate-pulse rounded bg-rb-gray-100" />
      <div className="mt-8 h-[34px] w-[218px] animate-pulse rounded-full bg-rb-gray-100" />
    </div>
  </div>
)

const CourseSnapshotHeader = () => {
  const { token } = useParams<{ token: string; type: string }>()
  const { pathname } = useLocation()

  const snapshotType = pathname.includes('enrollment') ? 'enrollment' : 'completion'

  const { data, loading } = useCourseSnapshotQuery({
    variables: {
      token,
      snapshotType
    }
  })

  const getHeaderCopy = () => {
    if (!data?.courseSnapshot?.firstName || !data?.courseSnapshot?.courseName) return ''

    if (snapshotType === 'enrollment') {
      return `See what ${data?.courseSnapshot.firstName} will learn in ${data?.courseSnapshot.courseName}`
    } else {
      return `See what ${data?.courseSnapshot.firstName} learned in ${data?.courseSnapshot.courseName}`
    }
  }

  const onCTAClick = () => {
    // Can't use history.push because we need to refresh the page to prompt the user to login
    window.location.href = `/courses/${data?.courseSnapshot?.courseSlug}/details`
  }

  return (
    <>
      <header className="flex h-[72px] items-center justify-between bg-white px-8 py-4 shadow-default">
        <a href="/" className="hover:cursor-pointer">
          <ReforgeLogo className="w-24 fill-current text-rb-black lg:w-36" />
        </a>

        <div className="flex gap-4 bg-rb-white">
          <Button
            className="px-4 py-2.5 font-semibold"
            size="small"
            variant="text-only"
            href="/login"
          >
            Login
          </Button>

          <Button
            className="px-4 py-2.5 font-semibold"
            size="small"
            variant="fill"
            href="/sign-up"
          >
            Sign up
          </Button>
        </div>
      </header>

      <div className="w-full bg-rb-orange-25 p-6 md:p-12">
        {loading ? (
          <CourseSnapshotHeaderSkeleton />
        ) : (
          <div className="flex flex-col gap-6 md:flex-row md:items-center md:gap-12">
            {data?.courseSnapshot?.imageUrl && (
              <img
                src={data.courseSnapshot.imageUrl}
                className="h-auto w-auto md:max-w-[418px]"
                alt="Course snapshot"
              />
            )}

            <div className="flex-1">
              <RfHeader2 className="font-polysans">{getHeaderCopy()}</RfHeader2>
              <RfParagraphMedium>
                {data?.courseSnapshot?.courseDescription}
              </RfParagraphMedium>
              {data?.courseSnapshot?.courseSlug && (
                <Button
                  variant="fill"
                  shape="rounded-full"
                  size="small"
                  className="mt-8"
                  onClick={onCTAClick}
                >
                  Preview Course for Free →
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default CourseSnapshotHeader
