import { type PlanItems } from 'constants/plans'

import { PlanOptionListItem } from '../PlanOptionListItem'

export const FeatureBreakdown = ({
  features,
  showExcludedFeatures
}: {
  features: PlanItems
  showExcludedFeatures: boolean
}) => (
  <ul className="list-none p-0">
    {features.included.map((feature, i) => (
      <PlanOptionListItem name={feature} isIncluded key={`i${i}`} />
    ))}
    {showExcludedFeatures &&
      features.excluded?.map((feature, i) => (
        <PlanOptionListItem name={feature} key={`e${i}`} />
      ))}
  </ul>
)
