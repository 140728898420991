import clsx from 'clsx'
import { useContext, useRef, useState } from 'react'

import { BookmarkCardControls, BookmarkNote } from 'domains/Bookmarks/BookmarkCard'
import { WistiaPlayerContext } from 'domains/LessonViewer/LessonViewerContainer'

import { ContentCardHeader } from 'components/ContentCard'
import VideoTimeElement from 'components/VideoTimeElement'

import { ProgramBookmarkPartsFragment } from 'gql'

import { lessonViewerState, useSnapshot } from 'stores'

import { ReactComponent as ExpandIcon } from 'images/icon--expand.svg'

import { removeHtmlTags } from '../utils'

interface BookmarkCardProps {
  className?: string
  bookmark: ProgramBookmarkPartsFragment
  showSectionLink?: boolean
  showProgramInBreadcrumb?: boolean
  track?: () => void
  openAddToBookmarkFolderModal: (bookmark: ProgramBookmarkPartsFragment) => void
  isActiveBookmark: boolean
  hasProductTour?: boolean
}

const BookmarkCardImage = ({ src, bookmark }: any) =>
  src ? (
    <div
      className="uk-visible-toggle group relative mt-4 max-h-[383px] max-w-[680px]"
      uk-lightbox=""
    >
      <img className="max-h-[383px]" alt="bookmark" src={src} />
      <div className="absolute left-6 bottom-[15px]">
        {bookmark && (
          <VideoTimeElement
            bookmark={bookmark}
            type="Dashboard"
            onSelectBookmark={() => {}}
          />
        )}
      </div>
      <a
        className="absolute right-6 top-6 mb-2 ml-2 flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg bg-rb-gray-400 opacity-0 hover:bg-rb-teal-200 group-hover:opacity-100 touch:opacity-100"
        href={src}
      >
        <ExpandIcon width={16} height={16} />
        <img className="hidden" alt="bookmark" src={src} />
      </a>
    </div>
  ) : null

const BookmarkCard = ({
  className,
  bookmark,
  showSectionLink,
  showProgramInBreadcrumb,
  track,
  openAddToBookmarkFolderModal,
  bookmark: {
    updated,
    noteBody,
    basedOn,
    anchor,
    cmsSection,
    cmsModule,
    cmsProgram,
    type,
    seconds,
    referenceImageUrl
  },
  isActiveBookmark,
  hasProductTour
}: BookmarkCardProps) => {
  const isVideoBookmark = type.toLowerCase() === 'videobookmark'
  const [showNote, setShowNote] = useState(!!noteBody)
  const [hasFocus, setHasFocus] = useState(false)
  const noteInputRef = useRef<HTMLTextAreaElement>(null)

  const lessonViewerSnapshot = useSnapshot(lessonViewerState)

  const { wistiaPlayer: videoInstance } = useContext(WistiaPlayerContext)

  const bookmarkContentPath = `/${cmsSection?.path || ''}${anchor ? `#${anchor}` : ''}${
    isVideoBookmark ? `?seconds=${seconds}` : ''
  }`

  const breadcrumbs = showProgramInBreadcrumb
    ? `${cmsProgram?.name} / ... / ${cmsSection?.name}`
    : `${cmsModule?.name} / ${cmsSection?.name}`

  const sectionLink = showSectionLink ? (
    cmsModule?.name === 'Projects' || cmsModule?.name === 'Concepts' ? (
      <a
        className="overflow-hidden overflow-ellipsis whitespace-nowrap text-rb-gray-300 hover:text-rb-gray-500"
        href={bookmarkContentPath}
        onClick={() => track?.()}
      >
        {cmsModule?.name} / {cmsSection?.name}
      </a>
    ) : (
      <a
        className="overflow-hidden overflow-ellipsis whitespace-nowrap text-rb-gray-300 hover:text-rb-gray-500"
        href={bookmarkContentPath}
        onClick={() => track?.()}
      >
        {breadcrumbs}
      </a>
    )
  ) : null

  const scrollSmoothly = (anchor: string) => {
    let targetEl = null

    if (anchor) {
      targetEl = document.getElementById(anchor)
    } else if (type === 'VideoBookmark') {
      videoInstance?.time(bookmark?.seconds!)

      // Video bookmarks do not have anchors, look for first wistia wrapper
      targetEl = document.querySelector('.wistia_responsive_wrapper')
    }

    if (targetEl === null) return

    /*
     * if lesson viewer is being accessed from cohortviewer
     * cohort menu (68) + sticky cms (62) = 130
     * Else just use sticky height
     */
    const floatingMenuHeight = lessonViewerSnapshot.fromCohortViewer ? 130 : 62
    const targetPosition = (targetEl as HTMLElement).offsetTop
    const top = targetPosition - floatingMenuHeight

    // Check if the current scroll position is already the target position
    if (window.scrollY === top) return

    document.getElementById('page')?.scrollTo({
      top,
      behavior: 'smooth'
    })
  }

  return (
    <div
      className={clsx(
        'flex cursor-pointer flex-wrap rounded-md border border-rb-gray-250 p-6 hover:shadow-content-card',
        className
      )}
      onClick={() => scrollSmoothly(anchor!)}
      data-dd-action-name="Side drawer bookmark"
    >
      <div className="flex h-4 w-full items-center justify-between text-xs">
        <ContentCardHeader typename="Bookmark" headerClassName="pb-0 mr-[8px]" />
        {sectionLink}
        <div className="flex items-center space-x-2">
          <span className="flex-shrink-0 cursor-default text-rb-gray-300">{`${updated} ago`}</span>
          <BookmarkCardControls
            bookmark={bookmark}
            position={['left']}
            toggleNote={(show: boolean) => {
              setShowNote(show)
              setHasFocus(true)
            }}
            openAddToBookmarkFolderModal={() => openAddToBookmarkFolderModal(bookmark)}
            hasProductTour={hasProductTour}
          />
        </div>
      </div>
      <div className="w-full">
        {basedOn && (
          <div
            className="mt-4 border-l-4 border-rb-gray-250 px-4 font-sans text-base leading-[1.5] text-rb-gray-500"
            onMouseDown={() => {}}
            dangerouslySetInnerHTML={{ __html: removeHtmlTags(basedOn) }}
          />
        )}
        <BookmarkCardImage src={referenceImageUrl} />
      </div>
      <BookmarkNote
        ref={noteInputRef}
        className="mt-4 w-full"
        show={showNote}
        hasFocus={hasFocus}
        setShowNote={setShowNote}
        bookmark={bookmark}
        startActive={isActiveBookmark}
        onFocusedBookmark={() => {
          setShowNote(true)
          setHasFocus(true)
        }}
      />
    </div>
  )
}

export default BookmarkCard
