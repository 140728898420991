import { Section, SectionTitle } from 'components/Section'

import { ProgramCollaboratorsFragment } from 'gql'

export interface ProgramCollaboratorsProps {
  title: string
  programCollaborators: ProgramCollaboratorsFragment[]
  className?: string
}

const ProgramCollaborators = ({
  title,
  programCollaborators,
  className
}: ProgramCollaboratorsProps) => {
  if (!programCollaborators?.length) return null

  return (
    <div className={className}>
      <Section>
        <SectionTitle className="mb-2">{title}</SectionTitle>
        <div className="max-w-5xl">
          {programCollaborators.map((collaborator) => {
            return (
              <div key={collaborator.id} className="mb-6 flex">
                <img
                  src={collaborator.thumbnailUrl}
                  className="mr-2 h-14 w-14 rounded-full sm:mr-4 sm:h-20 sm:w-20"
                  alt="collaborator-thumbnail"
                />
                <div>
                  <h4 className="mb-0 lg:text-xl">{collaborator.name}</h4>
                  <p className="mb-0 text-sm sm:mb-1">
                    {collaborator.role} at {collaborator.companyName}
                  </p>
                  <p className="mb-0 text-[10.5px] lg:text-xs">{collaborator.bio}</p>
                </div>
              </div>
            )
          })}
        </div>
      </Section>
    </div>
  )
}

export default ProgramCollaborators
