import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'

import Button from 'components/Button'

import { CookieConsent } from 'gql'

interface CookieBarProps {
  data: CookieConsent
}

const CookieBar = React.memo(({ data }: CookieBarProps) => {
  const { enabled, message, url } = data
  const { acceptedCookies, onAcceptCookies } = useAcceptCookies()

  if (!data || !enabled || !message || acceptedCookies) return null

  return (
    <div
      role="dialog"
      aria-live="polite"
      className="fixed bottom-0 right-0 z-[1050] w-full max-w-7xl p-4"
    >
      <div className="grid animate-fadeIn items-center gap-6 rounded-xl border border-rb-white bg-rb-black p-6 pb-4 text-rb-white md:flex md:p-6">
        <div className="flex-1 text-center md:pr-8 md:text-left">
          <p className="mb-0 text-sm">
            {message.split('\n').map((text, i) => {
              // using React.fragment to parse line breaks
              return (
                <React.Fragment key={i}>
                  {text}
                  {message.split('\n')[i + 1] && <br className="hidden md:block" />}
                </React.Fragment>
              )
            })}
          </p>
        </div>

        <div className="flex flex-col-reverse md:flex-row">
          {url && (
            <Button
              size="small"
              variant="text-only"
              className="w-full text-rb-white hover:bg-transparent hover:text-rb-white hover:underline md:w-auto"
              href={url}
            >
              Learn more
            </Button>
          )}

          <Button
            size="small"
            className="w-full border-rb-white bg-rb-white text-rb-black hover:border-rb-white hover:bg-rb-white hover:text-rb-black md:w-auto"
            onClick={() => onAcceptCookies()}
          >
            Accept
          </Button>
        </div>
      </div>
    </div>
  )
})

function useAcceptCookies(cookieName = 'accept_cookies') {
  const [acceptedCookies, setAcceptedCookies] = useState(true)

  useEffect(() => {
    if (!Cookies.get(cookieName)) {
      setAcceptedCookies(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const acceptCookies = () => {
    setAcceptedCookies(true)
    Cookies.set(cookieName, 'accepted', { expires: 365 })
  }

  return {
    acceptedCookies,
    onAcceptCookies: acceptCookies
  }
}

CookieBar.displayName = 'CookieBar'

export default CookieBar
