import { useEffect, useState } from 'react'
import { isChrome } from 'react-device-detect'
import { Link } from 'react-router-dom'
import TimeShift from 'timeshift-js'

import { ErrorMessage, Loading } from 'components'
import Button from 'components/Button'
import ButtonGoogle from 'components/ButtonGoogle'
import ButtonLinkedIn from 'components/ButtonLinkedIn'
import ButtonMicrosoft from 'components/ButtonMicrosoft'
import DeveloperLogin from 'components/DeveloperLogin'
import { EmbeddedBrowserMessage } from 'components/EmbeddedBrowserMessage'
import { FacePile, FacePileContainer, FacePileUser } from 'components/FacePile'
import SsoLogin from 'components/SsoLogin'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'

import { useSubscriptionTeamPreviewQuery } from 'gql'

import useInterTabCommunication from 'hooks/useInterTabCommunication'
import useURLParams from 'hooks/useURLParams'

import { isCoursePreviewUrl } from 'utils/url/isCoursePreviewUrl'
import { LoginParams, getLoginUrl } from 'utils/url/loginUrl'

import { ReactComponent as ReforgeLogoLarge } from 'images/reforge-logo-black.svg'
import { ReactComponent as ReforgeLogo } from 'images/reforge-logo-symbol-black.svg'

const LoginContainer = () => {
  const { getParam, deleteParam, getQueryString } = useURLParams()

  const token = getParam('token')
  const mtoken = getParam('mtoken')
  const fwd = getParam('fwd')
  const tos = getParam('tos')
  const reforgeLogin = !!getParam('reforgeLogin')
  const referer = getParam('referer')
  const marketingNav = !!getParam('marketingNav')
  const courseType = getParam('courseType')
  const cmsProgramSlug = getParam('cmsProgramSlug')
  const forEventRSVP = getParam('forEventRSVP') === 'true'
  const eventId = getParam('eventId')
  const userTimezone = getParam('userTimezone')
  const happening = getParam('happening') === 'true'
  const logoutRequest = getParam('logout_request') === 'true'

  const { postMessage } = useInterTabCommunication()

  useEffect(() => {
    if (logoutRequest) {
      // Refresh the page in all tabs when the user logs out to clear the session
      postMessage('reload')
      deleteParam('logout_request')

      const queryString = getQueryString()
      window.history.replaceState(
        null,
        '',
        `/login${queryString ? `?${queryString}` : ''}`
      )
    }
  }, [deleteParam, getQueryString, logoutRequest, postMessage])

  const {
    data: teamPreviewData,
    loading: teamPreviewLoading,
    error: teamPreviewError
  } = useSubscriptionTeamPreviewQuery({
    variables: {
      token: mtoken,
      loginParams: {
        cmsProgramSlug,
        courseType,
        eventId,
        forEventRSVP,
        fwd,
        happening,
        marketingNav,
        mtoken,
        referer,
        reforgeLogin,
        token,
        tos,
        userTimezone
      }
    },
    skip: !mtoken
  })

  if (teamPreviewLoading) return <Loading />
  if (teamPreviewError) {
    return <ErrorMessage error={teamPreviewError} />
  }

  return (
    <Login
      token={token}
      mtoken={mtoken}
      teamName={teamPreviewData?.subscriptionTeamPreview?.name}
      teamMembers={teamPreviewData?.subscriptionTeamPreview?.members}
      teamMemberCount={teamPreviewData?.subscriptionTeamPreview?.totalMembers}
      ssoAuthorizationUrl={
        teamPreviewData?.subscriptionTeamPreview?.ssoAuthorization?.authorizationUrl
      }
      // NOTE: This fallback is treating this issue: https://reforge.atlassian.net/browse/REF-6345
      fwd={fwd || encodeURIComponent(window.location.origin)}
      tos={tos}
      reforgeLogin={reforgeLogin}
      referer={referer}
      marketingNav={marketingNav}
      courseType={courseType}
      cmsProgramSlug={cmsProgramSlug}
      forEventRSVP={forEventRSVP}
      eventId={eventId}
      userTimezone={userTimezone}
      happening={happening}
    />
  )
}

interface TeamMemberInvitedProps {
  teamName: string
  teamMembers: FacePileUser[]
  teamMemberCount: number
  handleClick: () => void
}

const TeamMemberInvited = ({
  teamName,
  teamMembers,
  teamMemberCount,
  handleClick
}: TeamMemberInvitedProps) => {
  return (
    <div
      id="team_welcome"
      className="flex min-h-[100vh] flex-col bg-apply bg-cover bg-no-repeat"
    >
      <a className="mx-16 mt-12 mb-8 self-center xs:self-start" href="/" rel="noreferrer">
        <ReforgeLogoLarge className="w-[200px] min-w-[200px]" />
      </a>
      <div className="flex w-96 flex-col gap-12 self-center rounded-lg bg-rb-white py-20 px-16 shadow-rb-hover lg:w-[575px]">
        <div className="flex flex-col gap-4">
          <h1 className="font-medium leading-8 tracking-wide md:text-[28px]">
            You&#8217;ve been invited to join the{' '}
            <span className="font-semibold">{teamName}</span> membership!
          </h1>
          <FacePileContainer>
            <FacePile users={teamMembers} />
            <div className="text-xs sm:ml-1 sm:text-sm ">{teamMemberCount} members</div>
          </FacePileContainer>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <div className="text-xl font-semibold">
              Unlock step-change growth in your career
            </div>
            <p className="text-sm text-rb-gray-300">
              Make the leap from product manager to product leader, or from beginner to
              growth expert
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-xl font-semibold">Level up on new skills or topics</div>
            <p className="text-sm text-rb-gray-300">
              From learning about experimentation or strategy, to advanced approaches on
              retention & engagement
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-xl font-semibold">
              Solve your toughest work challenges
            </div>
            <p className="text-sm text-rb-gray-300">
              Learn how to analyze your newest feature, improve your activation flow, grow
              your audiece, and more
            </p>
          </div>
        </div>
        <Button fullWidth={true} shape="rounded-full" onClick={handleClick}>
          Join team
        </Button>
      </div>
    </div>
  )
}

const SocialLoginForm = ({
  loginParams,
  handleSSOSignInClick
}: {
  loginParams: LoginParams
  handleSSOSignInClick: () => void
}) => {
  return (
    <>
      <div className="mb-6 flex flex-col gap-6 md:gap-4">
        <ButtonGoogle href={getLoginUrl('google', loginParams)} />
        <ButtonLinkedIn href={getLoginUrl('linkedin', loginParams)} />
        <ButtonMicrosoft href={getLoginUrl('microsoft', loginParams)} />
      </div>
      <div className="flex flex-col mt-8 md:mt-6 text-center">
        <button onClick={handleSSOSignInClick} className="text-rb-teal-200">
          Sign in with SSO
        </button>
      </div>
    </>
  )
}

const SSOLoginForm = ({
  loginParams,
  handleSSOSignInClick
}: {
  loginParams: LoginParams
  handleSSOSignInClick: () => void
}) => {
  return (
    <>
      <SsoLogin loginParams={loginParams} />
      <div className="flex flex-col mt-8 md:mt-6 text-center">
        <button onClick={handleSSOSignInClick} className="text-rb-teal-200">
          Or continue with other options
        </button>
      </div>
    </>
  )
}

// Extends LoginParams so we aren't duplicating ourselves in terms of expected parameter names
export interface LoginProps extends LoginParams {
  teamName?: string | null
  teamMembers?: FacePileUser[]
  teamMemberCount?: number
  ssoAuthorizationUrl?: string | null
}

export const Login = ({
  token = '',
  mtoken = '',
  fwd = '',
  tos = '',
  reforgeLogin = false,
  referer,
  marketingNav = false,
  teamName,
  teamMembers,
  teamMemberCount,
  courseType = '',
  cmsProgramSlug = '',
  forEventRSVP = false,
  eventId,
  userTimezone,
  happening,
  ssoAuthorizationUrl
}: LoginProps) => {
  const loginParams = {
    token,
    fwd,
    tos,
    mtoken,
    reforgeLogin,
    referer,
    marketingNav,
    courseType,
    cmsProgramSlug,
    forEventRSVP,
    eventId,
    userTimezone,
    happening
  }

  const [showTeamMemberInvited, setShowTeamMemberInvited] = useState(!!mtoken)
  const [showSSOForm, setShowSSOForm] = useState(false)

  // ensure we show messaging for unsupported browsers (embedded)
  const isEmbeddedBrowser =
    /(iPhone|iPod|iPad).*AppleWebKit(?!.*Version)/i.test(navigator.userAgent) && !isChrome

  const isTimeTraveled =
    process.env.REACT_APP_I_AM_A_TIME_TRAVELER === 'true' &&
    Math.abs(TimeShift.OriginalDate.now() - Date.now()) > 300000

  if (isTimeTraveled) {
    return (
      <div className="flex min-h-[100vh] flex-col bg-apply bg-cover bg-no-repeat">
        <div className="flex flex-1 flex-col items-center justify-center">
          <div className="text-2xl font-semibold">Time Traveler Detected</div>
          <div className="text-lg font-semibold">
            Please reset the time before attempting to login
          </div>
        </div>
      </div>
    )
  }

  if (isEmbeddedBrowser) {
    return <EmbeddedBrowserMessage />
  }

  if (showTeamMemberInvited) {
    const handleClick = ssoAuthorizationUrl
      ? () => window.location.replace(ssoAuthorizationUrl)
      : () => setShowTeamMemberInvited(false)

    return (
      <TeamMemberInvited
        teamName={teamName || ''}
        teamMembers={teamMembers || []}
        teamMemberCount={teamMemberCount || 0}
        handleClick={handleClick}
      />
    )
  }

  return (
    <div
      id="login"
      className="flex min-h-[100vh] flex-col bg-apply bg-cover bg-no-repeat"
    >
      <div className="max-w-[19.75rem] md:max-w-[32rem] m-6 self-center rounded-md bg-rb-white p-8 shadow-lg sm:mx-16 md:mx-0 md:p-16">
        <a href="/" rel="noreferrer">
          <ReforgeLogo className="w-[45px] h-[35px] mx-auto mb-10" />
        </a>
        <h1 className="mb-1 text-xl font-medium tracking-tight md:text-[28px] text-center">
          Welcome to Reforge
        </h1>
        {forEventRSVP ? (
          <p className="mb-14 text-base font-normal">
            Create an account to register for this event and gain
            <br />
            access to our entire free library of Artifacts.
          </p>
        ) : (
          <p className="mb-6 text-center text-xs md:text-base">
            {isCoursePreviewUrl()
              ? 'Create an account to access your free course preview.'
              : 'Experts, courses, and resources to supercharge your career'}
          </p>
        )}
        {!showSSOForm && (
          <SocialLoginForm
            loginParams={loginParams}
            handleSSOSignInClick={() => setShowSSOForm(true)}
          />
        )}
        {showSSOForm && (
          <SSOLoginForm
            loginParams={loginParams}
            handleSSOSignInClick={() => setShowSSOForm(false)}
          />
        )}

        {process.env.NODE_ENV === 'development' && (
          <DeveloperLogin loginParams={loginParams} />
        )}

        <div className="mt-10 text-center">
          <RfParagraphMini className="mb-6 text-rb-gray-300">
            We use LinkedIn, Google, and Microsoft to authorize your account and for no
            other purpose. Your contacts are not imported or messaged.
          </RfParagraphMini>

          <RfParagraphMini className="mb-6 text-rb-gray-300">
            By creating an account, you agree to{' '}
            <Link
              to="/terms-of-service"
              target="_blank"
              rel="noreferrer"
              className="text-rb-teal-200"
            >
              Terms & Conditions
            </Link>
            .
          </RfParagraphMini>
        </div>
      </div>
    </div>
  )
}

export default LoginContainer
