import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { Loading } from 'components'
import ContactUs from 'components/MarketingSite/ContactUs'

import { useContactUsQuery } from 'gql'

const ContactUsPage = () => {
  const { data, loading } = useContactUsQuery()

  return (
    <MarketingLayout page={data?.contactUs}>
      {loading ? <Loading /> : <ContactUs />}
    </MarketingLayout>
  )
}

export default ContactUsPage
