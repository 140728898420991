import { RefObject, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { getModuleProgress } from 'domains/Concepts/ConceptCardList/utils'
import ModuleCardIndex from 'domains/Program/ModuleCardIndex'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'
import Popover from 'components/Popover/Popover'
import LegacyContentCard from 'components/cards/Content/LegacyContentCard'

import {
  CmsModuleGuestUserPartsFragment,
  CmsModulePartsFragment,
  CurrentUserQuery,
  ProgramBookmarkPartsFragment
} from 'gql'

import { FULL_DATE_ABBREV_MONTH_FORMAT, formatInTimezone } from 'utils/date'
import { onEnterKeyPress } from 'utils/keyboard'
import { trackCtaClicked } from 'utils/tracking/generated/events'
import { trackProgramPageAction } from 'utils/tracking/generated/events/programPageAction'

import { CmsSectionProgress } from 'typings/scalars'

const scrollToRef = (ref: RefObject<HTMLLIElement>, height: number) => {
  if (!ref.current) {
    return
  }

  const pixelsToModule = ref.current.offsetTop - height

  // Leave some overlap above the focused module to reveal content.
  const pixelsToScroll = pixelsToModule - 40

  document.getElementById('page')?.scrollTo(0, pixelsToScroll)
}

const getTimeInWords = (t: number) => {
  let timeInWords = ''
  if (t > 59) {
    const timeInHours = Math.round((t * 2) / 60) / 2
    timeInWords = `${timeInHours} hrs`
  } else if (t > 0) {
    timeInWords = `${t} min`
  }
  return timeInWords
}

interface ModuleProps {
  currentModuleId?: string
  cmsModule: CmsModulePartsFragment
  currentUser: NonNullable<CurrentUserQuery['currentUser']>
  featuredGuests: CmsModuleGuestUserPartsFragment[]
  showMemberContent: boolean
  firstHref: string
  cmsProgramId: string
  cmsProgramName?: string
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
}

const Module = ({
  currentModuleId,
  cmsModule,
  currentUser,
  featuredGuests,
  showMemberContent,
  firstHref,
  cmsProgramId,
  cmsProgramName,
  openAddToBookmarkFolderModal
}: ModuleProps) => {
  const history = useHistory()
  const isEnrolled = !!currentUser.activeProgram?.enrolled

  const moduleProgress = currentUser.activeProgram
    ? getModuleProgress(currentUser.activeProgram, cmsModule.id)
    : {}

  const userProgress = ((cmsModule.project
    ? moduleProgress[`${cmsModule.project.id}d`]
    : moduleProgress) || {}) as CmsSectionProgress

  const percentComplete = userProgress.percent_completed || 0

  const ref = useRef<HTMLLIElement>(null)
  const [moduleOpen, setModuleOpen] = useState(currentModuleId === cmsModule.id)

  useEffect(() => {
    if (moduleOpen) {
      const stickyTopElement = document.getElementById('sticky-top')
      if (stickyTopElement) {
        const rect = stickyTopElement.getBoundingClientRect()
        scrollToRef(ref, rect.height + 50)
      }
    }

    if (ref.current) {
      ref.current.classList.add('border-b-[1px]', 'border-rb-gray-100')
    }
  }, [moduleOpen, ref])

  const toggleModuleOpen = () => {
    if (cmsModule.published) {
      if (moduleOpen) {
        handleProgramPageTracking('collapse_module')
      } else {
        handleProgramPageTracking('expand_module')
      }
      setModuleOpen(!moduleOpen)
    }
  }

  const moduleTimeInWords = getTimeInWords(cmsModule.estimatedTime)

  const moduleOpenClass = moduleOpen ? '' : 'hover:bg-rb-gray-50 group'
  const modulePublishedClass = cmsModule.published ? '' : 'hover:bg-white'

  const featuredUsers: { [key: string]: boolean } = {}

  if (showMemberContent === undefined) {
    showMemberContent = !!currentUser?.is.member
  }

  const handleProgramPageTracking = (
    action: 'collapse_module' | 'expand_module' | 'collapse_section' | 'expand_section',
    cmsSectionId?: string,
    cmsSectionName?: string
  ) => {
    if (cmsSectionId && cmsSectionName) {
      trackProgramPageAction({
        action: action,
        cms_program_id: cmsProgramId,
        cms_program_name: cmsProgramName || '',
        cms_module_id: cmsModule.id,
        cms_module_name: cmsModule.name,
        cms_section_id: cmsSectionId,
        cms_section_name: cmsSectionName
      })
    } else {
      trackProgramPageAction({
        action: action,
        cms_program_id: cmsProgramId,
        cms_program_name: cmsProgramName || '',
        cms_module_id: cmsModule.id,
        cms_module_name: cmsModule.name
      })
    }
  }

  const handleBeginClick = () => {
    trackCtaClicked({
      text: 'begin program',
      cta_type: 'button',
      cta_location: 'program_page',
      related_identifiers: {
        cms_program_id: cmsProgramId,
        cms_program_name: cmsProgramName
      }
    })
    history.push(firstHref)
  }
  return (
    <li
      ref={ref}
      className={`mt-0 px-4 lg:px-8 ${moduleOpenClass} ${modulePublishedClass}`}
      data-test="module-dropdown"
      id={`program-index_module${cmsModule.id}`}
    >
      <div
        className="px-0 py-4"
        id={`program-index_modulediv${cmsModule.id}`}
        onClick={toggleModuleOpen}
        onKeyUp={onEnterKeyPress(toggleModuleOpen)}
        role="button"
        aria-expanded={moduleOpen}
        tabIndex={0}
      >
        <div
          className={`m-0 flex w-full flex-col justify-center ${
            cmsModule.published ? 'cursor-pointer' : ''
          }`}
        >
          <div className="flex h-12 items-center lg:my-1">
            <div className="w-[60%] pr-4">
              <div className="flex flex-row items-center gap-8">
                <div
                  className={`text-base font-medium leading-5 text-rb-gray-500 line-clamp-2 sm:text-[21px] sm:leading-[26px] ${
                    cmsModule.published ? '' : 'opacity-50'
                  }`}
                >
                  {cmsModule.name}
                </div>
                {firstHref && (
                  <Button
                    data-testid="begin-button"
                    size="small"
                    color="teal"
                    className="h-10 px-4 font-semibold"
                    onClick={handleBeginClick}
                  >
                    Begin
                  </Button>
                )}
              </div>
              {!cmsModule.published && cmsModule.publishAt && (
                <div className="text-sm text-rb-gray-300 sm:inline-block sm:w-[200px] sm:text-base sm:font-normal">
                  <span>
                    Coming{' '}
                    {formatInTimezone(
                      cmsModule.publishAt,
                      currentUser.timezone,
                      FULL_DATE_ABBREV_MONTH_FORMAT
                    )}
                  </span>
                </div>
              )}
            </div>
            {!!featuredGuests && !cmsModule.published && (
              <div className="flex w-[40%] justify-end">
                {featuredGuests.length > 0 &&
                  featuredGuests.map((featuredGuest) => {
                    if (featuredUsers[featuredGuest.id]) {
                      return null
                    } else {
                      featuredUsers[featuredGuest.id] = true
                      return (
                        <img
                          className="ml-2.5 h-[30px] w-[30px] rounded-[45px]"
                          key={`expert_${featuredGuest.id}`}
                          uk-tooltip={`title: <div class="uk-text-center"><div class="font-medium">Featured Guest</div><div>${featuredGuest.profile.fullName}</div></div>; cls: uk-active; pos: top`}
                          src={featuredGuest.profile.avatarUrl}
                          alt={featuredGuest.profile.fullName || ''}
                        />
                      )
                    }
                  })}
              </div>
            )}
            {cmsModule.published && (
              <>
                <div className="font-sm sm:font-base ml-auto hidden text-rb-gray-300 sm:inline-block sm:w-[75px] sm:font-normal">
                  {moduleTimeInWords}
                </div>
                {!!cmsModule.numBookmarks && showMemberContent && (
                  <div
                    className={twMerge(
                      'group flex w-[76px] justify-center pr-4 text-sm text-rb-gray-300 group-hover:text-rb-gray-500',
                      moduleOpen ? 'text-rb-gray-500' : ''
                    )}
                  >
                    <>
                      <SVGIcon
                        name="bookmark"
                        width="18"
                        height="18"
                        className="fill-current text-rb-gray-500"
                      />
                      <span>{cmsModule.numBookmarks}</span>
                    </>
                  </div>
                )}
                {cmsModule.anyCountsTowardsCompletion && showMemberContent && (
                  <Popover
                    text="Bonus material does not count towards your progress"
                    containerClassName="w-1/4 min-w-[55px] shrink-0 cursor-pointer text-right text-sm font-normal"
                    tooltipClassName="text-xs left-0 right-0 p-2 max-w-[290px] text-center mx-auto "
                    offsetTop="-top-[80px] md:-top-[55px]"
                    offsetBottom="-bottom:[70px] md:-bottom-[45px]"
                    zIndex="z-[1011]"
                    caretPosition="center"
                  >
                    <progress
                      className={twMerge(
                        'uk-progress mb-0 !h-[5px] opacity-50 group-hover:opacity-100',
                        moduleOpen ? 'opacity-100' : ''
                      )}
                      value={percentComplete}
                      max="100"
                    />
                    <div className="mt-[3px] text-sm font-medium text-rb-black">
                      {percentComplete}%
                    </div>
                  </Popover>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {cmsModule.published && (
        <div className={`uk-section-small pt-0 ${moduleOpen ? '' : 'uk-hidden'}`}>
          <div className="uk-container items-start px-0">
            <ModuleCardIndex
              cmsSections={cmsModule.cmsSections}
              userProgress={moduleProgress}
              currentUser={currentUser}
              isEnrolled={isEnrolled}
              handleProgramPageTracking={handleProgramPageTracking}
              openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
            />
            {cmsModule.project && (
              <div className="mb-5 w-full sm:mr-[30px] sm:w-[252px]">
                <LegacyContentCard
                  content={cmsModule.project}
                  openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </li>
  )
}

export default Module
