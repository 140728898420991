import { ReforgeAiButton } from 'domains/Ai/ReforgeAiButton'
import { SearchField } from 'domains/Search/SearchField'

import { LeftSidebarToggle } from 'components/LeftSidebar/LeftSidebarToggle'
import Logo from 'components/LeftSidebar/Logo'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import AccountDropdown from './AccountDropdown'
import { useLeftSidebar } from './LeftSidebar/useLeftSidebar'

const TopNavBar = () => {
  const { aiBeta, freeAi } = useFeatureFlags()
  const { isLeftSidebarOpen } = useLeftSidebar()

  if (isLeftSidebarOpen) return null

  return (
    <>
      <div
        id="top-nav"
        className="sticky top-0 z-[1010] flex h-14 w-full items-center justify-between bg-white px-4 md:px-6 lg:h-[72px] lg:pl-8 lg:pr-0"
      >
        <div className="flex shrink-0 items-center lg:ml-[-4px] lg:w-[calc(88px-2rem+4px)] 2xl:w-[calc(240px-2rem+4px)]">
          <LeftSidebarToggle className="mr-2" />
          <Logo />
        </div>

        <div className="mx-auto flex max-w-[1280px] flex-1 items-center justify-between gap-4 lg:px-8">
          <div className="flex flex-1 shrink-0 justify-end lg:justify-start">
            <SearchField className="w-auto min-w-[40px] lg:w-full lg:max-w-[480px]" />
          </div>
          {(aiBeta || freeAi) && <ReforgeAiButton />}
          <div className="flex items-center">
            {/* temporarily hiding notifications */}
            {/* {currentUser?.can?.viewActivitySidebar && <ActivityLink />} */}
            <AccountDropdown />
          </div>
        </div>
      </div>
    </>
  )
}

export default TopNavBar
