export default function SkeletonCohortPostCard() {
  return (
    <div className="w-full animate-pulse rounded-[8px] pl-[26px] pt-[19px] pr-[76px] pb-[27px] shadow-card">
      <div className="flex items-center">
        <div className="h-[40px] w-[40px] rounded-[8px] bg-rb-gray-100 p-7" />
        <div className="ml-[10px] mr-2.5 h-3 w-[20%] rounded-full bg-rb-gray-100" />
        <div className="h-2 w-[10%] rounded-full bg-rb-gray-100" />
      </div>
      <div className="pl-[65px]">
        <div className="flex h-full w-full flex-col gap-3">
          <div className="h-3 w-full rounded-full bg-rb-gray-100" />
          <div className="h-3 w-full rounded-full bg-rb-gray-100" />
          <div className="h-3 w-full rounded-full bg-rb-gray-100" />
          <div className="mt-2.5 h-2 w-[20%] rounded-full bg-rb-gray-100" />
          <div className="flex items-center">
            <div className="mt-2.5 h-2 w-[20%] rounded-full bg-rb-gray-100" />
          </div>
        </div>
      </div>
    </div>
  )
}

export const SkeletonCohortPostCardContainer = () => (
  <>
    {[...Array(2)].map((_, index) => (
      <div className="mb-5" key={index}>
        <SkeletonCohortPostCard />
      </div>
    ))}
  </>
)
