import clsx from 'clsx'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import { SVGIcon } from 'components/Icon'

export interface CalloutProps {
  'title': string
  'leftChip'?: React.ReactNode
  'children': React.ReactNode
  'className'?: string
  'innerContainerClassName'?: string
  'buttonClassName'?: string
  'data-test': string
  closeCallback(): void
}

const Callout = ({
  title,
  closeCallback,
  leftChip,
  children,
  className = '',
  innerContainerClassName = '',
  buttonClassName = '',
  'data-test': dataTest
}: CalloutProps) => (
  <div
    data-test={dataTest}
    className={twMerge(
      'w-80 max-w-full rounded bg-neutral-100 p-4 shadow-callout',
      className
    )}
  >
    <div className={clsx(innerContainerClassName || 'flex items-center')}>
      {leftChip && <div>{leftChip}</div>}
      <button
        type="button"
        data-test={`${dataTest}-close-button`}
        className={clsx(
          buttonClassName ||
            'ml-auto cursor-pointer stroke-current text-xl text-rb-gray-300 hover:text-rb-gray-500'
        )}
        onClick={closeCallback}
        aria-label="close"
      >
        <SVGIcon name="close" height="24" width="24" strokeWidth="2" />
      </button>
    </div>
    <div className="mt-3 mb-1 text-lg font-bold">{title}</div>
    <div>{children}</div>
  </div>
)

export default Callout
