import { twMerge } from 'tailwind-merge'

import { SVGIcon } from 'components/Icon'
import { Modal, useModal } from 'components/Modal'
import { getReactionNames } from 'components/ReactionButton/reactionButtonUtils'
import DropdownContextMenu from 'components/dropdowns/Dropdown/DropdownContextMenu'
import { ReactComponent as IconThreeDotsCircleWhite } from 'components/dropdowns/Dropdown/three-dots-circle-white.svg'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import RfParagraphSmallBold from 'components/typography/RfParagraph/RfParagraphSmallBold'

import { CohortPostReactionPartsFragment, ReactionKind } from 'gql'

import { prettyJoin } from 'utils/arrayUtils'
import { onEnterKeyPress } from 'utils/keyboard'
import {
  TLegacyOrGraphQLReaction,
  kindToSvg,
  mapReactionKinds,
  sortedReactions
} from 'utils/reactionUtils'

import DropdownOptions from './DropdownOptions'

export interface CohortPostCardDropdownProps {
  onEditPost?: () => void
  onDeletePost?: () => void
  onTogglePinPost?: () => void
  onTogglePostNotification: () => void
  onReportPost: () => void
  canUserEditPost: boolean
  canUserDeletePost: boolean
  canUserPinPost: boolean
  disabled?: boolean
  isPostPinned?: boolean
  isUserFollowingPost: boolean
  label: string
  postId?: string
  reactions?: CohortPostReactionPartsFragment[]
  currentUserId?: string
}

export const CohortPostCardDropdown = ({
  onEditPost,
  onDeletePost,
  onTogglePinPost,
  onTogglePostNotification,
  onReportPost,
  canUserEditPost,
  canUserDeletePost,
  canUserPinPost,
  disabled,
  isPostPinned,
  isUserFollowingPost,
  label,
  postId,
  reactions,
  currentUserId
}: CohortPostCardDropdownProps) => {
  const { isModalOpen, closeModal, openModal } = useModal()

  const handleClose = (e?: any) => {
    e?.stopPropagation()
    closeModal()
  }

  const handleCallback = (callback?: () => void) => {
    callback?.()
    closeModal()
  }

  const dropdownProps = {
    onEditPost: () => handleCallback(onEditPost),
    onDeletePost: () => handleCallback(onDeletePost),
    onTogglePinPost: () => handleCallback(onTogglePinPost),
    onTogglePostNotification: () => handleCallback(onTogglePostNotification),
    onReportPost: () => handleCallback(onReportPost),
    canUserEditPost: canUserEditPost,
    canUserDeletePost: canUserDeletePost,
    canUserPinPost: canUserPinPost,
    disabled: disabled,
    isPostPinned: isPostPinned,
    isUserFollowingPost: isUserFollowingPost,
    label: label,
    currentUserId: currentUserId
  }

  const reactionKinds = reactions && mapReactionKinds(reactions)

  return (
    <div className="text-md" data-testid={`cohort-post-card-dropdown-${postId}`}>
      <div
        tabIndex={0}
        role="button"
        className="hidden h-full hover:rounded-full hover:bg-rb-gray-100 sm:block"
        onClick={(e) => e.stopPropagation()}
        onKeyUp={onEnterKeyPress((e) => e.stopPropagation())}
      >
        <DropdownContextMenu
          positions={['left']}
          triggerClassName="h-[25px] w-[25px]"
          className="rounded-xl border-none p-0 shadow-default"
          dataTest={`cohort-post-card-dropdown-${postId}`}
        >
          <DropdownOptions {...dropdownProps} />
        </DropdownContextMenu>
      </div>

      <div className="block h-[25px] w-[25px] hover:rounded-full hover:bg-rb-gray-100 sm:hidden">
        <IconThreeDotsCircleWhite
          data-test="icon-three-dots-circle-white"
          className={isModalOpen ? 'text-rb-teal-200' : ''}
          width="25"
          height="25"
          onClick={(e) => {
            e.stopPropagation()
            openModal()
          }}
        />

        <Modal
          isOpen={isModalOpen}
          className="absolute bottom-0 rounded-t-2xl px-3 pb-4"
          handleClose={handleClose}
          header={true}
        >
          <DropdownOptions {...dropdownProps} />

          {reactionKinds && reactions.length > 0 && (
            <div className="flex flex-col px-4">
              <div className="my-5 w-full border-b border-rb-gray-100 px-6" />

              <RfParagraphSmallBold className="mb-5">
                <span className="text-[14px] font-semibold">Reactions</span>
              </RfParagraphSmallBold>

              {sortedReactions.map((kind: ReactionKind, index: number) => {
                if (reactionKinds[kind].length === 0) return null

                return (
                  <div key={index} className="mb-4 flex items-center gap-6">
                    <div className="box-content flex flex-row items-center justify-center rounded-full border-1.4 border-rb-gray-100 py-1 px-4">
                      <span className="flex h-4 w-4">
                        <SVGIcon
                          name={kindToSvg[kind]}
                          className={twMerge('text-rb-gray-300')}
                          height="16"
                          width="16"
                          fill="currentColor"
                        />
                      </span>
                      <span className="ml-2">{reactionKinds[kind].length}</span>
                    </div>
                    <div className="flex-grow">
                      <RfParagraphSmall>
                        {getReactionNamesForDropdown(reactionKinds[kind], currentUserId)}
                      </RfParagraphSmall>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </Modal>
      </div>
    </div>
  )
}

function getReactionNamesForDropdown(
  reactionKind: TLegacyOrGraphQLReaction[],
  currentUserId?: string
) {
  const shownReactionLimit = 3
  const reactionNames = getReactionNames(reactionKind, currentUserId)

  if (reactionNames.length <= shownReactionLimit) {
    return prettyJoin(reactionNames, ', ', ' and ')
  }

  const otherText = reactionNames.length === shownReactionLimit + 1 ? 'other' : 'others'

  const limitedReactions = [
    ...reactionNames.slice(0, shownReactionLimit),
    `${reactionNames.length - shownReactionLimit} ${otherText}`
  ]

  return prettyJoin(limitedReactions, ', ', ' and ')
}

export default CohortPostCardDropdown
