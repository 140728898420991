import pluralize from 'pluralize'

import { ErrorMessage, Loading } from 'components'
import { CardVariants } from 'components/cards/Content/BaseCard'
import CourseCard from 'components/cards/Content/CourseCard'
import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import RfParagraphSmallBold from 'components/typography/RfParagraph/RfParagraphSmallBold'

import { CourseBookmarkPartsFragment, useCoursesIncludingGuideQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { ReactComponent as GuidesIcon } from 'images/icon--guides.svg'

export interface LiveCoursesIncludingGuideProps {
  guideSlug: string
  guideTitle: string
  openAddToBookmarkFolderModal: (bookmark: CourseBookmarkPartsFragment) => void
}

export const LiveCoursesIncludingGuide = ({
  guideSlug,
  guideTitle,
  openAddToBookmarkFolderModal
}: LiveCoursesIncludingGuideProps) => {
  const { isLoggedIn } = useCurrentUser()

  const { loading, error, data } = useCoursesIncludingGuideQuery({
    variables: {
      guideSlug
    }
  })

  const liveCoursesIncludingGuide = data?.liveCoursesIncludingGuide
  const courseBookmarks = data?.currentUser?.courseBookmarks || []
  const hasUseableCoursePass = !!data?.currentUser?.hasUseableCohortCredits

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <ErrorMessage error={error} />
  }

  if (!liveCoursesIncludingGuide || liveCoursesIncludingGuide.length === 0) {
    return null
  }

  return (
    <div>
      <RfHeader3SemiBold>Learn about this live</RfHeader3SemiBold>
      <div className="flex flex-col space-y-6">
        {liveCoursesIncludingGuide?.map((cclCourse) => {
          const bookmark = courseBookmarks.find(
            (courseBookmark) => courseBookmark.cclCourse?.id === cclCourse.id
          )

          // related guide count is the total guide count minus this one
          const relatedGuideCount = (cclCourse?.guideCount || 1) - 1

          return (
            <div className="scroll-mt-16" key={cclCourse.id}>
              <div className="flex flex-col sm:flex-row space-x-1 mb-4">
                <RfParagraphSmall>This course features </RfParagraphSmall>
                <div className="flex flex-row space-x-1">
                  <GuidesIcon width={16} height={16} />
                  <RfParagraphSmallBold>{guideTitle}</RfParagraphSmallBold>
                </div>
                {relatedGuideCount > 0 ? (
                  <RfParagraphSmall>
                    + {relatedGuideCount} related {pluralize('guide', relatedGuideCount)}
                  </RfParagraphSmall>
                ) : null}
              </div>
              <CourseCard
                course={cclCourse}
                courseSession={cclCourse.upcomingSessions?.[0]}
                variant={CardVariants.Horizontal}
                hideBookmarkButton={!isLoggedIn}
                openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                bookmark={bookmark}
                showFreeWithCoursePass={hasUseableCoursePass}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default LiveCoursesIncludingGuide
