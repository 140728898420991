import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import { ModalContent, ModalFooter } from 'components/Modal'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import { useCancelSubscriptionMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import {
  trackCtaClicked,
  trackModalDisplayed,
  trackSurveyAnswerSubmitted
} from 'utils/tracking/analytics'

type WhatWeCanDoBetterType = {
  whatWeCanDoBetter: string
}

export interface WhatWeCanDoBetterModalContentProps {
  onSubmitFeedback: (inputs: string) => void
  showSubscriptionCancelSuccessModal: () => void
  handleClose: () => void
}

export const WhatWeCanDoBetterModalContent = ({
  onSubmitFeedback,
  showSubscriptionCancelSuccessModal,
  handleClose
}: WhatWeCanDoBetterModalContentProps) => {
  const { currentUser } = useCurrentUser()
  const [cancelSubscription, mutationRes] = useCancelSubscriptionMutation()
  const { register, handleSubmit } = useForm<WhatWeCanDoBetterType>()
  const [errorValue, setErrorValue] = useState('')

  useEffect(() => {
    trackModalDisplayed({
      category: 'app',
      modal_group: 'cancelation_flow',
      modal_name: 'what_we_can_do_better'
    })
  }, [])

  useEffect(() => {
    if (!mutationRes.called || mutationRes.loading) {
      return
    }

    if (mutationRes.error || mutationRes.data?.cancelSubscription?.errors?.[0]) {
      setErrorValue(
        'There was an error processing your request. Please reach out to hello@reforge.com.'
      )
      trackModalDisplayed({
        category: 'app',
        modal_group: 'cancelation_flow',
        modal_name: 'cancelation_error'
      })
    } else {
      showSubscriptionCancelSuccessModal()
    }
  }, [mutationRes, showSubscriptionCancelSuccessModal])

  const submitFeedback = async (data: WhatWeCanDoBetterType) => {
    trackCtaClicked({
      cta_location: 'cancellation_survey',
      cta_type: 'button',
      text: 'confirm cancelation'
    })
    trackSurveyAnswerSubmitted({
      question_name: 'what_we_can_do_better',
      modal_group: 'cancelation_flow',
      modal_name: 'what_we_can_do_better'
    })
    onSubmitFeedback(data.whatWeCanDoBetter)
    await cancelSubscription({
      variables: { input: { userId: currentUser?.id } }
    })
    showSubscriptionCancelSuccessModal()
  }

  return (
    <>
      <ModalContent className="px-3 sm:px-8" dataTest="what-we-can-do-better-modal">
        {errorValue !== '' ? (
          <>
            <h1 className="mb-4 text-lg font-semibold sm:text-2xl">
              Something went wrong
            </h1>
            <ErrorMessage error={errorValue} />
          </>
        ) : (
          <>
            <div className="mb-1 text-lg font-semibold leading-3 sm:mb-4 sm:text-2xl sm:leading-7">
              What could we do better?
            </div>
            <div>
              <RfParagraphMedium className="mb-4">
                Your feedback is important to us, and it will help us shape Reforge.
              </RfParagraphMedium>
              <div>
                <form
                  id="what-we-can-do-better-form"
                  onSubmit={handleSubmit(submitFeedback)}
                >
                  <textarea
                    name="whatWeCanDoBetter"
                    id="what-we-can-do-better"
                    rows={8}
                    autoFocus={true}
                    maxLength={4000}
                    className="w-full border border-rb-gray-100 p-4 outline-none"
                    ref={register}
                  />
                </form>
              </div>
            </div>
          </>
        )}
      </ModalContent>

      <ModalFooter>
        {errorValue !== '' ? (
          <Button variant="outline" size="small" className="mr-4" onClick={handleClose}>
            Got It
          </Button>
        ) : (
          <Button
            type="submit"
            dataTest="confirm-cancelation-btn"
            size="small"
            form="what-we-can-do-better-form"
            isLoadingSpinner={mutationRes.loading}
          >
            Confirm Cancelation
          </Button>
        )}
      </ModalFooter>
    </>
  )
}
