import { useEffect, useState } from 'react'

export default function useExpandCollapse(isMobile: boolean) {
  const [expandedDescription, setExpandedDescription] = useState(false)

  useEffect(() => {
    setExpandedDescription(!isMobile)
  }, [isMobile])

  return [expandedDescription, setExpandedDescription] as const
}
