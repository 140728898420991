import { ArtifactSubtopic } from 'gql/index'

import { trackNavigationClicked } from 'utils/tracking/analytics'

import SeoPageSectionTitle from '../SeoPageSectionTitle'
import SeoSectionLink from './SeoTopicSectionLink'

const CHUNK_SIZE = 6

interface TopicSectionEntry {
  id: string
  title: string
  slug: string
  topic?: { slug: string }
}

interface CourseTopicsAndFunctions {
  id: string
  title: string
  slug: string
}

export interface SeoTopicSectionProps {
  title: string
  courseTopicsAndFunctions?: CourseTopicsAndFunctions[]
  subtopics?: ArtifactSubtopic[]
  type: 'courseTopics' | 'subtopics'
}

export const SeoTopicSection = ({
  title,
  subtopics,
  courseTopicsAndFunctions,
  type
}: SeoTopicSectionProps) => {
  const trackNavigation = (path: string, topicSectionEntry: any) => {
    trackNavigationClicked({
      location: path,
      text: topicSectionEntry.title.toLowerCase(),
      type: 'hyperlink text',
      related_identifiers: {
        sanityType: (topicSectionEntry as ArtifactSubtopic) ? 'subtopic' : 'courseTopics',
        sanityId: topicSectionEntry.id,
        sanityName: topicSectionEntry.title.toLowerCase()
      }
    })
  }

  const topicSectionEntries: Array<TopicSectionEntry | undefined | ArtifactSubtopic> =
    type === 'courseTopics' ? courseTopicsAndFunctions ?? [] : subtopics ?? []

  if (!topicSectionEntries) {
    return null
  }

  const chunkedEntries = Array.from(
    { length: Math.ceil(topicSectionEntries.length / CHUNK_SIZE) },
    (_, i) => topicSectionEntries.slice(i * CHUNK_SIZE, i * CHUNK_SIZE + CHUNK_SIZE)
  )
  return (
    <section className="mb-[72px]">
      <SeoPageSectionTitle className="mb-4">{title}</SeoPageSectionTitle>

      <div className="flex flex-wrap overflow-hidden sm:-mx-2 md:-mx-2 lg:-mx-2 xl:-mx-2">
        {chunkedEntries.map((entryChunk, index) => (
          // probably need to take another look at this once there are more subtopics then 24 (4 chunks) to make sure we are still showing them all on the page.
          <div
            key={index}
            className="my-2 w-full overflow-hidden px-2 sm:my-2 sm:w-1/2 sm:px-2 md:my-2 md:w-1/3 lg:my-2 lg:w-1/4 xl:my-2"
          >
            {entryChunk.map((entry) => {
              if (!entry) return null // Add null check for 'item'
              const newPath =
                type === 'courseTopics'
                  ? `/course-categories/${entry.slug}`
                  : `/artifacts/c/${(entry as ArtifactSubtopic).topic?.slug}/${
                      entry.slug
                    }`
              return (
                <SeoSectionLink
                  key={entry.title}
                  subject={entry}
                  path={newPath}
                  trackNavigation={() => trackNavigation(newPath, entry)}
                />
              )
            })}
          </div>
        ))}
      </div>
    </section>
  )
}
