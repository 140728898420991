import LoggedOutCoursesIndexPage from 'pages/CoursesIndexPage/LoggedOutCoursesIndexPage'
import OldLoggedOutCoursesIndex from 'pages/CoursesPage/OldLoggedOutCoursesIndex'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

const LoggedOutCoursesIndexPageBridge = () => {
  const { showV2CoursesIndexPage } = useFeatureFlags()

  if (showV2CoursesIndexPage) {
    return <LoggedOutCoursesIndexPage />
  }

  return <OldLoggedOutCoursesIndex />
}

export default LoggedOutCoursesIndexPageBridge
