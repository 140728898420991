import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import SectionCardContainer from 'components/CollapsibleSectionCard/SectionCardContainer'
import { ChevronRightThinIcon } from 'components/icons'
import ColorCircleCheckIcon from 'components/icons/ColorCircleCheckIcon'

interface WeeklyBlockChecklistProps {
  titleComponent: ReactNode
  subtitleComponent?: ReactNode
  checked?: boolean
  clickable?: boolean
  className?: string
}

export const WeeklyBlockChecklistCard = ({
  titleComponent,
  subtitleComponent,
  checked = false,
  clickable = true,
  className
}: WeeklyBlockChecklistProps) => {
  return (
    <SectionCardContainer
      className={twMerge(
        'flex items-center p-6',
        !clickable && 'hover:cursor-default',
        className
      )}
      darkenBorderOnHover={clickable}
    >
      <div className="mr-4">
        {checked ? (
          <ColorCircleCheckIcon width="32" height="32" />
        ) : (
          <div className="flex h-8 w-8 rounded-full border border-rb-gray-100" />
        )}
      </div>
      <div className="flex w-full flex-col space-y-1">
        {titleComponent}
        {!!subtitleComponent && subtitleComponent}
      </div>
      {clickable && <ChevronRightThinIcon className="ml-auto h-4 w-4 fill-current" />}
    </SectionCardContainer>
  )
}

export default WeeklyBlockChecklistCard
