import { useState } from 'react'

import { ErrorMessage } from 'components'
import Button from 'components/Button'
import { ModalContent } from 'components/Modal'
import RfParagraphLarge from 'components/typography/RfParagraph/RfParagraphLarge'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import { useChoosePlanMutation } from 'gql'

import { trackCtaClicked } from 'utils/tracking/analytics'

export interface CohortPassOptInModalContentProps {
  handleClose: () => void
  upcomingChargeDate: string
  onPassOptIn: () => void
}

export const CohortPassOptInModalContent = ({
  handleClose,
  upcomingChargeDate,
  onPassOptIn
}: CohortPassOptInModalContentProps) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [isCtaDisabled, setIsCtaDisabled] = useState(false)
  const [choosePlan, { loading }] = useChoosePlanMutation()

  const handleConfirmClick = async () => {
    setIsCtaDisabled(true)
    trackCtaClicked({
      cta_location: 'cohort_pass_discount_modal',
      cta_type: 'button',
      text: 'confirm'
    })

    const result = await choosePlan({
      variables: {
        input: {
          newPlan: 'individual',
          numCohortPasses: 2,
          updateType: 'add_cohort_pass'
        }
      }
    })
    if (result.errors) {
      setShowErrorMessage(true)
    } else {
      onPassOptIn()
    }
  }

  return (
    <ModalContent dataTest="pass-opt-in-modal" className="mr-15 pb-10 pl-20 text-center">
      <RfParagraphLarge className="mb-6 flex flex-col items-center font-semibold">
        Add 2x Live Course Passes
      </RfParagraphLarge>
      <RfParagraphMedium className="mb-6 ml-10 text-left">
        By adding these passes, you will be charged an additional $995 on{' '}
        {upcomingChargeDate}.
      </RfParagraphMedium>
      <ul className="ml-5 mr-5 inline-block text-left text-sm text-rb-gray-300">
        <li>
          Live Course Passes must be used within one year of the purchase date. After one
          year, your passes will expire.
        </li>
        <li>You must have an active membership to use your Live Course Passes.</li>
      </ul>
      {showErrorMessage ? (
        <div className="pt-[30px]">
          <ErrorMessage error="Sorry, there was a problem. Please try again later or contact us at hello@reforge.com for assistance." />
        </div>
      ) : null}
      <div className="mt-10 flex justify-center text-center">
        <Button
          variant="outline"
          className="mr-[10px] inline w-[150px] hover:border-rb-black hover:bg-rb-white hover:text-rb-black"
          onClick={() => handleClose()}
          disabled={isCtaDisabled}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirmClick}
          className="ml-[10px] inline w-[250px] hover:bg-rb-black hover:text-rb-white"
          disabled={isCtaDisabled}
          isLoadingSpinner={loading}
        >
          Confirm
        </Button>
      </div>
    </ModalContent>
  )
}

export default CohortPassOptInModalContent
