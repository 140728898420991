import Button from 'components/Button'
import { ModalContent } from 'components/Modal'

import { formatMoney } from 'utils/moneyUtils'

import { ReactComponent as CheckIcon } from 'images/icon--checkmark-thin.svg'

export interface ConfirmPlanSuccessModalProps {
  handleClose: () => void
  amountDue: number | null
  renewalDate: string
}

const ConfirmPlanSuccessModalContent = ({
  amountDue,
  renewalDate,
  handleClose
}: ConfirmPlanSuccessModalProps) => {
  return (
    <div>
      <ModalContent className="flex flex-col justify-between">
        <div className="flex flex-col items-center">
          <CheckIcon className="w-14 fill-current text-rb-success-200" />
          <div className="pt-[20px] pb-[10px] text-xl font-semibold">
            Your subscription has been updated.
          </div>
          {amountDue !== null && (
            <div className="mb-3 max-w-md text-center text-sm">
              The changes to your plan will take effect on {renewalDate}, at which point
              you will be charged {formatMoney(amountDue / 100)} + sales tax. You can
              review and make updates to your plan from the{' '}
              <a href="/billing">Billing page</a>.
            </div>
          )}
        </div>
        <div className="my-10 flex justify-center">
          <Button size="small" className="w-[200px]" onClick={handleClose}>
            Done
          </Button>
        </div>
      </ModalContent>
    </div>
  )
}

export default ConfirmPlanSuccessModalContent
