import { useContext, useState } from 'react'
import { createPortal } from 'react-dom'

import { ArtifactContext } from 'domains/Artifact/contexts/ArtifactContext'
import { buildSrc, buildSrcSet } from 'domains/Sanity/lib/helpers'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { trackContentImageExpanded } from 'utils/tracking/analytics'

import HotspotMarker from './HotspotMarker'
import ImageZoomOverlay from './ImageZoomOverlay'

const InlineImage = ({
  caption = '',
  image,
  hotspots = [],
  srcSizes = [400, 600, 800, 950, 1100],
  sizes = '(min-width: 1024px) 65vw, 100vw',
  quality = 80,
  defaultWidth = 850
}: {
  caption?: string
  image: {
    url: string
  }
  hotspots: any[]
  srcSizes?: number[]
  sizes?: string
  quality?: number
  defaultWidth?: number
}) => {
  const [isOpenImageOverlay, setIsOpenImageOverlay] = useState(false)
  const { currentUser } = useCurrentUser()
  const artifact = useContext(ArtifactContext)

  const src = buildSrc(image.url, {
    width: defaultWidth,
    ...{ quality }
  })

  const srcset = buildSrcSet(image.url, {
    ...{ srcSizes },
    ...{ quality }
  })

  const isLoggedIn = useCurrentUser().isLoggedIn
  let hotSpotCount = 0

  return (
    <>
      <div className="mt-25 relative h-full w-full">
        <button
          type="button"
          onClick={() => {
            setIsOpenImageOverlay(true)

            trackContentImageExpanded({
              access_policy_kind: currentUser?.accessPolicyKind,
              content_sanity_id: artifact?.id,
              content_title: artifact?.title,
              content_type: 'artifact'
            })
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              setIsOpenImageOverlay(true)
            }
          }}
        >
          <picture>
            <img
              src={src}
              srcSet={srcset}
              sizes={sizes}
              alt={caption}
              className="featured-image h-full w-full cursor-pointer rounded-sm border border-rb-gray-100 object-cover"
            />
          </picture>
        </button>
        {Boolean(hotspots) &&
          hotSpotCount < 2 &&
          hotspots
            .slice(0, !isLoggedIn ? 2 - hotSpotCount : undefined)
            .map((hotspot: any) => {
              hotSpotCount += 1
              return <HotspotMarker key={hotspot._key} hotspot={hotspot} />
            })}
      </div>

      {isOpenImageOverlay && <div>Placeholder: ImageZoomOverlay</div>}

      {isOpenImageOverlay &&
        createPortal(
          <ImageZoomOverlay
            src={image.url}
            handleClose={() => setIsOpenImageOverlay(false)}
            artifact={artifact}
            hotspots={hotspots}
          />,
          document.body
        )}
    </>
  )
}

export default InlineImage
