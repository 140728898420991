import { ReactComponent as GreyMinusIcon } from './icon--minus-grey.svg'
import { ReactComponent as MinusIcon } from './icon--minus.svg'
import { ReactComponent as PlusIcon } from './icon--plus.svg'

interface CounterProps {
  onCountChange(value: number): void
  count: number
  dataTest: string
  ariaLabel: string
  ariaLabelledBy: string
  minCount?: number
}
export const Counter = ({
  onCountChange,
  count,
  dataTest,
  ariaLabel,
  ariaLabelledBy,
  minCount
}: CounterProps) => {
  const onIncrement = () => {
    onCountChange(increment(count))
  }
  const onDecrement = () => {
    onCountChange(decrement(count))
  }

  const disableDecrement = count === (minCount || 0)

  return (
    <>
      <div className="text-rf-gray-dark-2 border-color flex h-8 flex-row items-center justify-between space-x-1 self-center rounded-md border border-solid border-rb-gray-200 bg-white px-2 font-medium">
        <button
          tabIndex={-1}
          type="button"
          data-testid={`${dataTest}-decrement`}
          onClick={onDecrement}
          className={`h-3 w-3 ${disableDecrement ? 'cursor-default' : 'cursor-pointer'}`}
          aria-label={`decrement ${ariaLabel}`}
          disabled={disableDecrement}
        >
          {disableDecrement ? <GreyMinusIcon /> : <MinusIcon />}
        </button>
        <input
          className={`h-6 w-[3ch] cursor-default text-center ${
            count === 0 ? 'text-rb-gray-250' : ''
          }`}
          data-testid={dataTest}
          type="text"
          name="count"
          inputMode="numeric"
          pattern="[0-9]*"
          placeholder="0"
          value={count}
          onChange={(e) => {
            const validValue = e.target.value.replace(/[^0-9]/g, '')
            const value = ensureNotLowerThanZero(
              ensureNotHigherThanHundred(Number(validValue))
            )

            onCountChange(value)
          }}
          role="spinbutton"
          tabIndex={0}
          aria-valuenow={count}
          aria-labelledby={ariaLabelledBy}
          onKeyDown={(e) => {
            if (e.key === 'ArrowDown') {
              onDecrement()
            }
            if (e.key === 'ArrowUp') {
              onIncrement()
            }
          }}
        />
        <button
          tabIndex={-1}
          type="button"
          data-testid={`${dataTest}-increment`}
          onClick={onIncrement}
          className=" h-3 w-3 cursor-pointer"
          aria-label={`increment ${ariaLabel}`}
        >
          <PlusIcon />
        </button>
      </div>
    </>
  )
}

const increment = (count: number): number => {
  return ensureNotHigherThanHundred(count + 1)
}

const decrement = (count: number): number => {
  return ensureNotLowerThanZero(count - 1)
}

const ensureNotLowerThanZero = (num: number) => (num <= 0 ? 0 : num)
const ensureNotHigherThanHundred = (num: number) => (num >= 100 ? 100 : num)
