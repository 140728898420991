import Bookmarks from 'domains/Bookmarks/Bookmarks'
import { LOCATION_DASHBOARD_SAVED_ITEMS } from 'domains/Bookmarks/utils'

const CohortDashboardBookmarks = ({
  cmsProgramId,
  cmsProgramName,
  cohortSlug
}: {
  cmsProgramId: string
  cmsProgramName: string
  cohortSlug: string
}) => {
  return (
    <div className="w-full overflow-auto px-8 pb-8">
      <Bookmarks
        trackingLocation={LOCATION_DASHBOARD_SAVED_ITEMS}
        cmsProgramId={cmsProgramId}
        programName={cmsProgramName}
        isCohortDashboard={true}
        cohortSlug={cohortSlug}
      />
    </div>
  )
}

export default CohortDashboardBookmarks
