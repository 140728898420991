import BillingAddressUpdateModal from 'components/BillingAddressUpdateModal'
import Button from 'components/Button'
import { useGlobalModal } from 'components/GlobalModal'

const StripeBillingPortalButton = () => {
  const { openGlobalModal, closeGlobalModal } = useGlobalModal()

  const handleClick = async () => {
    openGlobalModal(
      <BillingAddressUpdateModal
        onConfirm={(isComplete) => isComplete && closeGlobalModal()}
      />,
      {
        handleClose: closeGlobalModal
      }
    )
  }

  return (
    <Button size="x-small" variant="fill" onClick={handleClick}>
      Update billing
    </Button>
  )
}

export default StripeBillingPortalButton
