import { CREATOR_TYPEFORM_URL, makeUrl } from 'utils/url'

export function createCreatorTypeformURL({
  internalRef,
  utmSource,
  utmMedium,
  utmCampaign,
  externalId,
  anonymousId
}: {
  internalRef?: string
  utmSource?: string
  utmMedium?: string
  utmCampaign?: string
  externalId?: string | null
  anonymousId?: string
}) {
  // Typeform utilizes "hidden fields" passed in with a # in the URL to pre-fill form fields
  const hiddenFields = []
  if (externalId) {
    hiddenFields.push(`external_id=${externalId}`)
  }
  if (anonymousId) {
    hiddenFields.push(`anonymous_id=${anonymousId}`)
  }
  if (internalRef) {
    hiddenFields.push(`internal_ref=${internalRef}`)
  }

  let hiddenFieldsString = hiddenFields.join('&')
  if (hiddenFieldsString.length > 0) {
    hiddenFieldsString = `#${hiddenFieldsString}`
  }

  // We expect to get here either from an internal link, which provides a internalRef,
  // or from an external link, which provides a utmSource, utmMedium, and utmCampaign.
  let url = ''
  if (internalRef) {
    url = CREATOR_TYPEFORM_URL
  } else {
    url = makeUrl(
      CREATOR_TYPEFORM_URL,
      {},
      {
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign
      }
    )
  }

  url = url + hiddenFieldsString

  return url
}
