import { InputHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

interface MarketingInputProps extends InputHTMLAttributes<HTMLInputElement> {}

const MarketingInput = ({ className, ...attributes }: MarketingInputProps) => {
  return (
    <input
      className={twMerge(
        'h-50 w-full border border-rb-gray-200 p-3 font-serif text-base opacity-70 hover:opacity-100 focus:bg-rb-white focus:opacity-100',
        className
      )}
      {...attributes}
    />
  )
}

export default MarketingInput
