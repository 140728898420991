import { useEffect, useState } from 'react'

import Loading from 'components/Loading'

import { cn } from 'utils/tailwind'

import { ReactComponent as CheckIcon } from 'images/icon--check.svg'

import settingsImage from './settings-image.png'

type checkState = 'checking' | 'fail' | 'success'
export interface AuthenticatedPageProps {
  startingState?: 'checking' | 'fail' | 'success'
}

const ExtensionAuthenticatedPage = ({
  startingState = 'checking'
}: AuthenticatedPageProps) => {
  const learnMoreUri =
    'https://reforge.helpscoutdocs.com/article/199-reforge-browser-extension#Troubleshooting-G5KNX'
  const [permissionsCheckState, setPermissionsCheckState] =
    useState<checkState>(startingState)

  useEffect(() => {
    if (startingState !== 'checking') return

    const timeout = setTimeout(() => {
      setPermissionsCheckState('fail')
    }, 5000)

    return () => {
      clearTimeout(timeout)
    }
  }, [startingState])

  interface variableSet {
    [key: string]: any
  }

  const stateVariables: variableSet = {
    checking: {
      hero: (
        <div className="h-10 w-10 mb-10">
          <Loading />
        </div>
      ),
      heading: <>Setting Up...</>,
      leading: <p>Hold tight!</p>
    },
    fail: {
      hero: (
        <img
          src={settingsImage}
          alt="Screenshot of what your settings should look like"
          className="w-full mb-6"
        />
      ),
      heading: (
        <span className="leading-[150%] inline-block px-10">
          Please update your permissions to use Reforge.
        </span>
      ),
      leading: (
        <div className="flex flex-col items-center">
          <p className="text-center text-[#0C0F0D] leading-[140%] mb-10">
            To update your permissions, enter <strong>chrome://extensions/</strong> in a
            new tab or navigate to the extensions management page. Then, click
            &lsquo;Details&rsquo; for the Reforge extension, and set Site access to
            &lsquo;On all sites&rsquo;. Reload the page you are working on when this is
            done.
          </p>
          <button
            className="px-6 py-4 border border-black mb-10 font-semibold text-black leading-[100%] rounded-lg"
            onClick={() => {
              window.location.reload()
            }}
          >
            I&apos;ve updated permissions
          </button>
          <p className="text-center text-sm text-[#717171] leading-[120%]">
            For more guidance and why we request this, check out our{' '}
            <a href={learnMoreUri} target="_blank" rel="noreferrer">
              help doc
            </a>
            .
          </p>
        </div>
      )
    },
    success: {
      hero: (
        <div className="h-10 w-10 mb-10">
          <CheckIcon />
        </div>
      ),
      heading: <>You&apos;re all set!</>,
      leading: <p>This will close in a few seconds.</p>
    }
  }

  return (
    <div className="w-full h-screen bg-white flex flex-col items-center justify-center">
      {Object.keys(stateVariables).map((stateName) => {
        const variables = stateVariables[stateName]
        return (
          <div
            key={stateName}
            id={`statuspanel_${stateName}`}
            className={cn(
              'js-statuspanel flex flex-col items-center justify-center antialiased max-w-[460px]',
              stateName !== permissionsCheckState && 'hidden'
            )}
          >
            {variables.hero}
            <h1 className="text-[#0C0F0D] text-2xl font-semibold leading-[40px] mb-4 text-center">
              {variables.heading}
            </h1>
            <div className="text-base text-[#717171] leading-[120%]">
              {variables.leading}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ExtensionAuthenticatedPage
