import { StripePaymentElementChangeEvent } from '@stripe/stripe-js'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'

import { usePurchaseFlowContext } from 'pages/PurchaseFlow/contexts/PurchaseFlowContext'

import BillingInfoForm from 'domains/User/BillingInfoForm/BillingInfoForm'
import { FormCompleteness, FormValue } from 'domains/User/BillingInfoForm/types'
import { useSaveBillingInfo } from 'domains/User/BillingInfoForm/utils'

import { Alert } from 'components/Alert'
import Button from 'components/Button'
import Loading from 'components/Loading'
import StoredPaymentMethods from 'components/PaymentMethods/StoredPaymentMethods/StoredPaymentMethods'
import { StripeElements } from 'components/StripeElements'

import {
  BillingAddressFragment,
  TaxIdsDocument,
  useCurrentUserPaymentMethodsQuery,
  useCustomerContactQuery,
  useDeleteCustomerTaxIdMutation,
  useTaxIdsQuery
} from 'gql'

import { ReactComponent as PlusIcon } from 'images/icon--plus-sign.svg'

import PaymentInfoForm, {
  PaymentInfoFormExposedMethods
} from '../PaymentMethods/PaymentInfoForm'

export interface BillingAndPaymentFormExposedMethods {
  saveBillingAndPayment: () => void
}

interface BillingAndPaymentFormProps {
  onSaveLoadingStateChange: (saveState: boolean) => void
  onSaveEnabledStateChange: (saveEnabled: boolean) => void
}

const BillingAndPaymentForm = (
  { onSaveLoadingStateChange, onSaveEnabledStateChange }: BillingAndPaymentFormProps,
  ref: React.MutableRefObject<BillingAndPaymentFormExposedMethods>
) => {
  /** Internal State */
  const [billingAddress, setBillingAddress] = useState<
    FormValue['billingAddress'] | null
  >(null)
  const [taxId, setTaxId] = useState<FormValue['taxId'] | null>(null)
  const [isB2BPurchase, setIsB2BPurchase] = useState(false)
  const [billingInfoComplete, setBillingInfoComplete] = useState(false)
  const [paymentInfoComplete, setPaymentInfoComplete] = useState(false)
  const [showAddPaymentMethodForm, setShowAddPaymentMethodForm] = useState(false)

  const [saveLoading, setSaveLoading] = useState(false)
  const [errorMessage, setError] = useState('')

  const paymentInfoFormRef = useRef<PaymentInfoFormExposedMethods>(null)

  const {
    selectedPaymentMethodId,
    setSelectedPaymentMethodId,
    setCountryCode,
    setError: setOrderRecapError
  } = usePurchaseFlowContext()

  const setErrorMessage = useCallback(
    (message?: string) => {
      setError(
        () =>
          message ||
          'Sorry, there was an issue saving your billing info. Please try again, or contact us at hello@reforge.com.'
      )
      setOrderRecapError(
        'There was an issue saving your payment details. Please try again.'
      )
    },
    [setOrderRecapError]
  )

  /** Prep queries */
  const {
    data: customerContactQueryData,
    loading: customerContactQueryLoading,
    refetch: refetchCustomerContactQuery
  } = useCustomerContactQuery({
    fetchPolicy: 'cache-and-network'
  })
  const { data: taxIdsQueryData, loading: taxIdsQueryLoading } = useTaxIdsQuery({
    fetchPolicy: 'cache-and-network'
  })
  const {
    data: paymentMethodsQuery,
    loading: paymentMethodsQueryLoading,
    refetch: refetchPaymentMethodsQuery
  } = useCurrentUserPaymentMethodsQuery({
    fetchPolicy: 'cache-and-network'
  })

  /** Prep mutations */
  const [saveAll] = useSaveBillingInfo()

  const [deleteTaxId, { loading: deleteTaxIdLoading }] = useDeleteCustomerTaxIdMutation({
    refetchQueries: [{ query: TaxIdsDocument }],
    awaitRefetchQueries: true
  })

  /**
   * Prep query data for render
   */
  const name = customerContactQueryData?.currentUser?.stripeCustomer?.name || undefined
  const savedBillingAddress =
    customerContactQueryData?.currentUser?.stripeCustomer?.address || undefined
  const savedTaxId = taxIdsQueryData?.taxIds?.[0]

  const billingInfoValues = { name, address: savedBillingAddress, taxId: savedTaxId }
  const isTaxIdRequired = isB2BPurchase && !billingInfoValues.taxId

  const paymentMethods = useMemo(
    () => paymentMethodsQuery?.userPaymentMethods,
    [paymentMethodsQuery]
  )

  const setAsDefaultOverride = paymentMethods?.length === 0

  useEffect(() => {
    setShowAddPaymentMethodForm(paymentMethods?.length === 0)
  }, [paymentMethods])

  /** Callbacks */
  const handleBillingInfoChange = (value: FormValue, isComplete: FormCompleteness) => {
    setBillingAddress(value.billingAddress)
    setCountryCode(value.billingAddress?.address.country || '')
    setTaxId(value.taxId)
    setIsB2BPurchase(value.isB2BPurchase)
    setBillingInfoComplete(
      isComplete.billingAddress && (value.isB2BPurchase ? isComplete.taxId : true)
    )
  }

  const handlePaymentInfoChange = (e: StripePaymentElementChangeEvent) => {
    setPaymentInfoComplete(e.complete)
  }

  const handleTaxIdDelete = useCallback(async () => {
    if (savedTaxId) {
      try {
        const deleteTaxIdResult = await deleteTaxId({
          variables: { input: { taxId: savedTaxId.id } }
        })

        if (deleteTaxIdResult?.errors?.length === 1) {
          if (
            // If for some reason the txi_* doesn't exist in Stripe,
            // assume there's nothing to delete and keep going.
            // Otherwise, alert the user.
            !deleteTaxIdResult.errors[0].message.includes(
              'does not have a Tax ID object txi_'
            )
          ) {
            setErrorMessage()
            console.error('deleteTaxId mutation failed', deleteTaxIdResult)
          }

          return
        }
      } catch {
        setErrorMessage()
      }
    }
  }, [deleteTaxId, savedTaxId, setErrorMessage])

  /** Validation */
  const validateForm = () => {
    if (isB2BPurchase && !taxId?.value) {
      return 'Tax ID is required'
    }
    return true
  }

  /** Expose methods to parent */
  useImperativeHandle(ref, () => ({
    saveBillingAndPayment
  }))

  const saveBillingAndPayment = async () => {
    setError(() => '')
    setSaveLoading(true)

    const errorMessage = validateForm()
    if (errorMessage !== true) {
      setErrorMessage(errorMessage)
      setSaveLoading(false)
      return false
    }

    try {
      if (showAddPaymentMethodForm) {
        const paymentMethodId = await paymentInfoFormRef.current?.save()
        paymentMethodId && setSelectedPaymentMethodId(paymentMethodId)
      }

      const [updateCustomerContactRes, saveTaxIdRes] = await saveAll({
        billingAddress: billingAddress,
        taxId: taxId,
        isB2BPurchase: isB2BPurchase
      })

      if (updateCustomerContactRes?.errors) {
        setErrorMessage()
        console.error('updateCustomerContact mutation failed', updateCustomerContactRes)
        return false
      }

      if (saveTaxIdRes?.errors) {
        let message

        if (saveTaxIdRes.errors.toString().includes(`Invalid value for ${taxId?.type}`)) {
          message =
            'Tax ID is invalid. Please try again, or contact us at hello@reforge.com.'
        }

        setErrorMessage(message)
        console.error('saveTaxId mutation failed', saveTaxIdRes)
        return false
      }

      if (showAddPaymentMethodForm) {
        // we only want to refetch data if we're adding a new one payment method
        refetchPaymentMethodsQuery()
        refetchCustomerContactQuery()
        setShowAddPaymentMethodForm(false)
      }

      return true
    } catch (e) {
      setErrorMessage(e.message)
      return false
    } finally {
      setSaveLoading(false)
    }
  }

  useEffect(() => {
    onSaveLoadingStateChange(saveLoading)
  }, [onSaveLoadingStateChange, saveLoading])

  useEffect(() => {
    const saveEnabled =
      billingInfoComplete && (paymentInfoComplete || !!selectedPaymentMethodId)
    onSaveEnabledStateChange(saveEnabled)
  }, [
    billingInfoComplete,
    onSaveEnabledStateChange,
    paymentInfoComplete,
    saveLoading,
    selectedPaymentMethodId
  ])

  /** Render */
  if (customerContactQueryLoading || taxIdsQueryLoading || paymentMethodsQueryLoading) {
    return <Loading />
  }

  return (
    <StripeElements>
      <div className="mb-6 text-xl">Enter your payment details</div>
      <div className="flex flex-col">
        <BillingInfoForm
          defaultValues={billingInfoValues}
          isTaxIdRequired={isTaxIdRequired}
          onChange={handleBillingInfoChange}
          onDeleteTaxId={handleTaxIdDelete}
          isTaxIdDeleteInProgress={deleteTaxIdLoading}
        />

        {!showAddPaymentMethodForm && paymentMethods && paymentMethods.length > 0 && (
          <>
            <StoredPaymentMethods
              paymentMethods={paymentMethods}
              paymentMethodsLoading={paymentMethodsQueryLoading}
              selectedPaymentMethodId={selectedPaymentMethodId}
              setSelectedPaymentMethodId={setSelectedPaymentMethodId}
            />

            <Button
              size="x-small"
              variant="text-only"
              className="mt-2 px-2 normal-case"
              iconBefore={<PlusIcon />}
              onClick={() => setShowAddPaymentMethodForm(true)}
            >
              Add a Payment Method
            </Button>
          </>
        )}

        {showAddPaymentMethodForm && (
          <>
            <PaymentInfoForm
              ref={paymentInfoFormRef}
              handlePaymentInfoChange={handlePaymentInfoChange}
              billingAddress={billingAddress?.address as BillingAddressFragment}
              setAsDefaultOverride={setAsDefaultOverride}
            />

            {paymentMethods && paymentMethods.length > 0 && (
              <Button
                size="x-small"
                variant="text-only"
                className="mt-2 px-2 normal-case"
                onClick={() => setShowAddPaymentMethodForm(false)}
              >
                {'<- Back'}
              </Button>
            )}
          </>
        )}

        {errorMessage && (
          <Alert type="error" className="mt-4">
            {errorMessage}
          </Alert>
        )}
      </div>
    </StripeElements>
  )
}

export default forwardRef(BillingAndPaymentForm)
