import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { CohortDashboardSeasonPartsFragment } from 'gql'

import { onEnterKeyPress } from 'utils/keyboard'

import { WeeklyBlockChecklistCard } from '../CohortDashboardWeeklyBlock/WeeklyBlockChecklist'

const getArtifactsCompletionFlag = (season: CohortDashboardSeasonPartsFragment) => {
  return `completed-artifacts-endblock-${season.name}-${season.year}`
}

interface CohortDashboardArtifactsProps {
  season: CohortDashboardSeasonPartsFragment
}

export const CohortDashboardArtifacts = ({ season }: CohortDashboardArtifactsProps) => {
  const history = useHistory()
  const artifactsCompletionFlag = getArtifactsCompletionFlag(season)
  const [artifactsClicked, setArtifactsClicked] = useState(
    window.localStorage.getItem(artifactsCompletionFlag) === '1'
  )

  const onArtifactsLinkClick = () => {
    if (!artifactsClicked) {
      window.localStorage.setItem(artifactsCompletionFlag, '1')
      setArtifactsClicked(true)
    }
    history.push('/artifacts')
  }

  return (
    <div
      tabIndex={0}
      role="button"
      className="w-full max-w-[890px] pb-20"
      onKeyDown={onEnterKeyPress(onArtifactsLinkClick)}
      onClick={onArtifactsLinkClick}
    >
      <WeeklyBlockChecklistCard
        titleComponent={
          <RfParagraphMediumBold>Check out Artifacts</RfParagraphMediumBold>
        }
        subtitleComponent={
          <RfParagraphSmall>
            Accelerate your career with real work from the best operators in tech.
          </RfParagraphSmall>
        }
        checked={artifactsClicked}
        className="mb-0"
      />
    </div>
  )
}

export default CohortDashboardArtifacts
