import { format } from 'date-fns'

import { ProgressBar } from 'pages/TeamMembersPage/ProgressBar/ProgressBar'

import Button from 'components/Button'
import { useCheckoutModal } from 'components/CheckoutModal/useCheckoutModal'

import { TeamMembersPageSubscriptionFragment } from 'gql'

import { FULL_DATE_FORMAT } from 'utils/date'
import { titleize } from 'utils/stringUtils'

export interface SeatSummaryProps {
  subscription: TeamMembersPageSubscriptionFragment
  totalSeatCount: number
  assignedSeatcount: number
  totalCohortCreditCount: number
  assignedCohortCreditCount: number
  isAssistantAdmin?: boolean | null
}

export const SeatSummary = ({
  subscription,
  totalSeatCount,
  assignedSeatcount,
  totalCohortCreditCount,
  assignedCohortCreditCount,
  isAssistantAdmin
}: SeatSummaryProps) => {
  const isIndividual = subscription.numSeats === 1
  const { openCheckoutModal } = useCheckoutModal()
  const isRenewing = subscription.status === 'active'
  const isExpired = subscription.planName === 'expired'
  const expiresAtFormatted = format(new Date(subscription.expiresAt), FULL_DATE_FORMAT)
  const planText =
    subscription.planName === 'expired'
      ? 'Your plan has expired'
      : `${titleize(subscription.planName)} Plan`
  const renewalText = (() => {
    if (isExpired) {
      return `Expired on ${expiresAtFormatted}`
    } else if (!isRenewing) {
      return `Expiring on ${expiresAtFormatted}`
    } else {
      return `Renews on ${expiresAtFormatted}`
    }
  })()

  return (
    <div className="w-full md:flex">
      <div
        className="mb-5 flex flex-col justify-between rounded border px-6 py-4 md:mb-0 md:w-1/2"
        data-test="plan-details"
      >
        <div className="flex justify-between">
          <div className="mt-1 mb-3 font-medium">{planText}</div>
          <div className="mt-2 mb-4 text-xs text-rb-gray-500">{renewalText}</div>
        </div>
        <div className="flex flex-col justify-between sm:flex-row md:flex-col lg:flex-row">
          <ProgressBar
            totalCount={totalSeatCount}
            usedCount={assignedSeatcount}
            countNoun="seat"
          />
          <div className="mt-2 flex flex-col justify-center lg:mt-0">
            <Button
              variant="outline"
              className="py-2.5"
              href="/manage-plan"
              disabled={isAssistantAdmin || false}
            >
              Manage plan
            </Button>
          </div>
        </div>
      </div>
      <div
        className="flex flex-col justify-between rounded border px-6 py-4 md:mx-2 md:w-1/2"
        data-test="cohort-details"
      >
        <div className="flex justify-start">
          <div className="mt-1 mb-3 font-medium">Live Course Passes</div>
        </div>
        <div className="flex flex-col justify-between sm:flex-row md:flex-col lg:flex-row">
          <ProgressBar
            totalCount={totalCohortCreditCount}
            usedCount={assignedCohortCreditCount}
            countNoun="pass"
          />
          <div className="mt-2 flex flex-col justify-center lg:mt-0">
            <Button
              variant="outline"
              className="py-2.5"
              onClick={() =>
                openCheckoutModal({ isIndividual, flowContext: 'cohortPassPurchase' })
              }
              disabled={isAssistantAdmin || false}
            >
              Buy Live Course Passes
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
