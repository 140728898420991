import React from 'react'
import { useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { useCohortDashboardTrackingContext } from 'domains/CohortDashboard/hooks/useCohortDashboardTrackingContext'

import ProfileModal from 'components/CohortDashboard/ProfileModal'
import SupportModal from 'components/CohortDashboard/SupportModal'
import { Modal, useModal } from 'components/Modal'
import { SquarishAvatarImage } from 'components/SquarishAvatarImage'
import DropdownContextMenu from 'components/dropdowns/Dropdown/DropdownContextMenu'

import { CohortPostUserPartsFragment, Season } from 'gql'

import { onEnterKeyPress } from 'utils/keyboard'
import { trackModalDisplayed } from 'utils/tracking/analytics'

import { AboutCohortModal } from './AboutCohortModal'

interface UserAvatarProps {
  currentUser: CohortPostUserPartsFragment
  cmsProgramId: string
  season: Season
}

export const UserAvatar = ({ currentUser, cmsProgramId, season }: UserAvatarProps) => {
  const {
    isModalOpen: isDropdownModalOpen,
    closeModal: closeDropdownModal,
    openModal: openDropdownModal
  } = useModal()
  const {
    isModalOpen: isProfileModalOpen,
    closeModal: closeProfileModal,
    openModal: openProfileModal
  } = useModal()
  const {
    isModalOpen: isCohortModalOpen,
    closeModal: closeCohortModal,
    openModal: openCohortModal
  } = useModal()
  const {
    isModalOpen: isSupportModalOpen,
    closeModal: closeSupportModal,
    openModal: openSupportModal
  } = useModal()

  const avatar = (
    <SquarishAvatarImage
      avatarUrl={currentUser?.profile?.avatarUrl || ''}
      alt={`${currentUser?.profile?.fullName} avatar`}
      className="ml-4 h-[40px] w-[40px] rounded"
    />
  )

  const { cohort, userCohortId } = useCohortDashboardTrackingContext()

  const onOpenSupportModal = () => {
    trackModalDisplayed({
      category: 'app',
      location: 'cohort_dashboard_support_modal',
      modal_group: 'cohort_dashboard',
      modal_name: 'support',
      related_identifiers: {
        cohort_id: cohort.id,
        cohort_name: cohort.slug,
        user_cohort_id: userCohortId
      }
    })
    openSupportModal()
  }

  const dropdownOptions = (
    <UserAvatarDropdownOptions
      openProfileModal={openProfileModal}
      openCohortModal={openCohortModal}
      openSupportModal={onOpenSupportModal}
    />
  )

  return (
    <>
      <div className="hidden h-full sm:block">
        <DropdownContextMenu
          positions={['bottom', 'right']}
          className="mt-2.5 w-[240px] rounded-xl border-none p-0 shadow-default"
          triggerElement={avatar}
          align="end"
        >
          {dropdownOptions}
        </DropdownContextMenu>
      </div>
      <div className="sm:hidden">
        <div
          onClick={openDropdownModal}
          onKeyUp={onEnterKeyPress(openDropdownModal)}
          role="button"
          tabIndex={0}
        >
          {avatar}
        </div>
        <Modal
          isOpen={isDropdownModalOpen}
          className="absolute bottom-0 rounded-t-2xl"
          handleClose={closeDropdownModal}
          header={true}
        >
          {dropdownOptions}
        </Modal>
      </div>
      {isProfileModalOpen && (
        <ProfileModal isOpen onClose={closeProfileModal} userId={currentUser.id} />
      )}
      {isCohortModalOpen && (
        <AboutCohortModal
          isOpen={isCohortModalOpen}
          onClose={closeCohortModal}
          cmsProgramId={cmsProgramId}
          season={season}
        />
      )}
      <SupportModal isOpen={isSupportModalOpen} onClose={closeSupportModal} />
    </>
  )
}

interface UserAvatarDropdownOptionsProps {
  openProfileModal: () => void
  openCohortModal: () => void
  openSupportModal: () => void
}

const UserAvatarDropdownOptions = ({
  openProfileModal,
  openCohortModal,
  openSupportModal
}: UserAvatarDropdownOptionsProps) => {
  const history = useHistory()

  const commonProps = {
    textClassName: 'text-[12px]',
    className: 'flex h-full w-full p-4'
  }

  return (
    <>
      <DropdownContextMenu.DropdownItem
        onClick={openProfileModal}
        text="My Profile"
        {...commonProps}
        className={twMerge(commonProps.className, 'pt-0 sm:pt-4')}
      />
      <DropdownContextMenu.DropdownItem
        onClick={openCohortModal}
        text="About this Course"
        {...commonProps}
      />
      <DropdownContextMenu.DropdownItem
        onClick={openSupportModal}
        text="Support & FAQs"
        {...commonProps}
      />
      <DropdownContextMenu.DropdownItem
        onClick={() => history.push('/')}
        text="Return to Reforge"
        {...commonProps}
      />
    </>
  )
}
