import { PropsWithChildren } from 'react'

import { cn } from 'utils/tailwind'

const SeoPageSectionTitle = ({
  children,
  className
}: PropsWithChildren<{ className?: string }>) => (
  <h2
    className={cn(
      'mb-0 text-lg font-medium !font-sans leading-[1.2] md:text-xl md:leading-[1.4]',
      className
    )}
  >
    {children}
  </h2>
)

export default SeoPageSectionTitle
