import { Link } from 'react-router-dom'

import CollectiveBadge from 'domains/Member/CollectiveBadge'

import RfHeader3 from 'components/typography/RfHeader/RfHeader3'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { CreatorPartsFragment } from 'gql'

interface CourseDetailCreatorProps {
  creator: CreatorPartsFragment
}

const CourseDetailCreator = ({ creator }: CourseDetailCreatorProps) => {
  return (
    <div className="flex gap-8">
      <div className="max-h-[110px] min-h-[110px] w-full min-w-[110px] max-w-[110px] overflow-hidden rounded-xl sm:max-h-[200px] sm:min-h-[200px] sm:min-w-[200px] sm:max-w-[200px]">
        <img
          className="h-auto w-full rounded-xl object-cover"
          alt={creator.name || ''}
          src={`${creator.avatarUrl}?auto=format&w=200` || ''}
        />
      </div>

      <div className="flex-grow space-y-2 sm:space-y-4">
        <Link
          to={`/profiles/${creator.slug}`}
          rel="noreferrer"
          className="flex items-center"
        >
          <RfHeader3 className="!mb-0 font-serif">
            <span className="text-[24px] font-normal">{creator?.name}</span>
          </RfHeader3>
          {creator.isCollectiveMember && <CollectiveBadge />}
        </Link>

        <RfParagraphSmall className="line-clamp-4 sm:line-clamp-none">
          {creator.bio}
        </RfParagraphSmall>
      </div>
    </div>
  )
}

export default CourseDetailCreator
