import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { CollectionActivityFacepile } from 'components/ActivityFacepile/CollectionActivityFacepile'
import { FacePileImage } from 'components/ActivityFacepile/FacePile'
import Button from 'components/Button'
import { useProductTour } from 'components/ProductTour'
import RfHeader1 from 'components/typography/RfHeader/RfHeader1'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import { BookmarkFolderPartsFragment, ProductTourKey } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import { onEnterKeyPress } from 'utils/keyboard'
import { deleteSearchParam } from 'utils/url'

import { useShareCollectionModal } from './ShareCollectionModal'
import UpdateBookmarkFolderModal from './UpdateBookmarkFolderModal'
import { DEFAULT_COLLECTION_NAME } from './collectionConstants'

interface CollectionHeaderProps {
  currentFolder?: BookmarkFolderPartsFragment | null
  editOnShow?: boolean
}

const CollectionHeader = ({
  currentFolder,
  editOnShow = false
}: CollectionHeaderProps) => {
  const history = useHistory()
  const currentUser = useAssertCurrentUser()
  const [isModalOpen, setIsModalOpen] = useState(editOnShow)
  const [isShareVersion, setIsShareVersion] = useState(editOnShow)
  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)

  const { portal, completeTour } = useProductTour({
    productTourKey: ProductTourKey.COLLECTION_SHARE,
    nodeRefOrSelector: '.tour-share-button',
    title: '👯 Share your collection with your team',
    disabled: !currentFolder,
    description:
      'You can share your collection with your whole team or a select few so they can edit and work with you.',
    wait: 2500
  })

  const { openShareCollectionModal } = useShareCollectionModal()

  const handleSetIsModalOpen = (isOpen: boolean) => {
    if (editOnShow) {
      deleteSearchParam('edit')
      setIsShareVersion(false)
    }
    setIsModalOpen(isOpen)
  }

  const handleShareClicked = () => {
    if (!currentFolder?.id) return

    completeTour()
    openShareCollectionModal({
      currentFolderId: currentFolder.id
    })
  }

  const canEdit = Boolean(!currentFolder?.reforgeCollection)

  return (
    <>
      {portal}
      <div className="mt-8 w-full">
        {currentFolder && (
          <div className="flex w-full flex-col justify-between lg:flex-row lg:items-center">
            <div className="mb-2 flex h-[14px] text-xs font-medium leading-[14px] tracking-widest text-rb-gray-300 lg:items-center lg:text-sm">
              {currentFolder && (
                <>
                  <span
                    role="button"
                    tabIndex={0}
                    className="hover:bg-transparent hover:underline"
                    onClick={() => history.push('/saved?tab=collections')}
                    onKeyUp={onEnterKeyPress(() =>
                      history.push('/saved?tab=collections')
                    )}
                  >
                    Saved
                  </span>
                  <span className="ml-1">/ {currentFolder.name}</span>
                </>
              )}
            </div>
            <div className="mb-2 flex flex-grow flex-row-reverse items-center justify-end lg:flex-row">
              {canEdit && (
                <>
                  <div className="mx-1 flex flex-row gap-4 lg:mx-3">
                    <CollectionActivityFacepile collectionId={currentFolder.id} />
                  </div>
                  {currentFolder.name !== DEFAULT_COLLECTION_NAME && (
                    <Button
                      variant="outline"
                      onClick={() => setIsModalOpen(true)}
                      dataTest="update-collection-modal"
                      className="mx-1 py-2.5 px-4 lg:mx-3"
                    >
                      Edit
                    </Button>
                  )}
                  {currentUser.hasTeam && currentFolder.user.id === currentUser.id && (
                    <Button
                      onClick={handleShareClicked}
                      className="tour-share-button mx-1 py-2.5 px-4 lg:mx-3"
                    >
                      Share
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        <div className="mb-2 flex flex-grow basis-3/4 flex-col">
          {currentFolder?.name && <RfHeader1>{currentFolder.name}</RfHeader1>}
          <div className="break-words pb-2">{currentFolder?.description}</div>
          {currentFolder?.user?.fullName && (
            <div className="ml-2 flex items-center">
              <FacePileImage
                zIndex={1}
                imageSize="small"
                src={currentFolder?.user?.profile?.avatarUrl || ''}
                className={`mr-2 ${isMobile ? 'h-8 w-8' : ''}`}
                fullName={currentFolder?.user?.fullName}
              />
              <RfParagraphMini>
                Created by
                {` ${
                  currentUser.id === currentFolder.user.id
                    ? 'You'
                    : currentFolder.user.fullName
                }`}
              </RfParagraphMini>
            </div>
          )}
        </div>
      </div>
      {currentFolder && canEdit && (
        <>
          {currentFolder.name !== DEFAULT_COLLECTION_NAME && (
            <UpdateBookmarkFolderModal
              bookmarkFolder={currentFolder}
              isModalOpen={isModalOpen}
              setIsModalOpen={handleSetIsModalOpen}
              isShareVersion={isShareVersion}
            />
          )}
        </>
      )}
    </>
  )
}

export default CollectionHeader
