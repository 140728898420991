import Button from 'components/Button'
import { ModalContent, ModalFooter } from 'components/Modal'
import { CheckIcon } from 'components/icons'

export interface CohortPassOptOutModalSuccessContentProps {
  handleClose: () => void
}

export const CohortPassOptOutModalSuccessContent = ({
  handleClose
}: CohortPassOptOutModalSuccessContentProps) => {
  return (
    <div className="px-[40px]" data-testid="pass-opt-out-success-modal">
      <ModalContent>
        <CheckIcon className="mx-auto mb-[50px] max-h-10 w-14 fill-current text-rb-success-100 sm:mb-10" />
        <h1 className="text-center text-xl font-semibold leading-7">
          You&apos;ve removed the Live Course Passes.
        </h1>
        <p className="text-center text-sm font-normal">
          The limited time discount will be available until your renewal date. You can add
          new Live Course Passes to your plan at any time.
        </p>
      </ModalContent>
      <ModalFooter className="flex justify-center">
        <Button className="w-[250px]" onClick={handleClose}>
          Done
        </Button>
      </ModalFooter>
    </div>
  )
}

export default CohortPassOptOutModalSuccessContent
