import { useState } from 'react'

import Button from 'components/Button'

import { PlanOptionFields } from 'constants/plans'

import { CohortPassPriceTier, PlanName, PricingPageContent } from 'gql'

import { cn } from 'utils/tailwind'
import { trackCtaClicked, trackNavigationElementToggled } from 'utils/tracking/analytics'

import MarketingPricingPlanCard from './MarketingPricingPlanCard'
import ReforgeComparisonTable from './ReforgeComparisonTable'

interface MembershipTypeSectionProps {
  content: PricingPageContent
  planOptionsData: PlanOptionFields[]
  cohortPassPriceTiers: CohortPassPriceTier[]
}

enum MembershipTypes {
  INDIVIDUAL = 'Individual',
  TEAM = 'Team'
}

const MembershipTypeSection = ({
  content,
  planOptionsData,
  cohortPassPriceTiers
}: MembershipTypeSectionProps) => {
  const [type, setType] = useState(MembershipTypes.INDIVIDUAL)

  const individualPlan = planOptionsData.find((plan) => plan.name === PlanName.INDIVIDUAL)

  const LEARN_MORE_CTA = 'Learn more ->'
  const handleReimbursementCtaClick = () => {
    trackCtaClicked({
      cta_location: '/pricing',
      cta_type: 'button',
      destination: '/reimbursement',
      text: LEARN_MORE_CTA.toLowerCase()
    })
  }

  const onToggleClick = (membershipType: MembershipTypes) => () => {
    setType(membershipType)

    trackNavigationElementToggled({
      location: '/pricing',
      text: membershipType,
      type: 'slider'
    })
  }

  return (
    <section className="px-4 md:px-[4vw] w-full pb-[100px]">
      <div className="mx-auto max-w-[1280px] flex flex-col items-center">
        <div className="w-full text-center uppercase text-sm lg:text-lg !leading-[1.35] tracking-[1.4px] lg:tracking-[1.8px] mb-6">
          Membership type
        </div>

        <div className="flex items-center justify-center gap-2 p-2 rounded-xl bg-rb-gray-100">
          {Object.values(MembershipTypes).map((membershipType) => (
            <button
              key={membershipType}
              className={cn(
                'px-6 py-4 rounded-xl text-sm lg:text-lg !leading-[1.35] font-semibold text-rb-black',
                type === membershipType && 'bg-rb-white'
              )}
              onClick={onToggleClick(membershipType)}
            >
              {membershipType}
            </button>
          ))}
        </div>

        {type === MembershipTypes.INDIVIDUAL && (
          <div className="my-12 p-3 sm:p-4 bg-rb-gray-100 flex flex-col gap-3 sm:gap-4 w-full rounded-xl">
            <MarketingPricingPlanCard
              plan={individualPlan!}
              cohortPassPriceTier={cohortPassPriceTiers[0]}
            />
            <ReforgeComparisonTable content={content} />
          </div>
        )}

        {type === MembershipTypes.TEAM && (
          <div className="my-12 p-3 sm:p-4 bg-rb-gray-100 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-4 w-full rounded-xl">
            {planOptionsData
              .filter((plan) => plan.name !== PlanName.INDIVIDUAL)
              .map((plan, i) => (
                <MarketingPricingPlanCard
                  key={plan.name}
                  plan={plan}
                  cohortPassPriceTier={cohortPassPriceTiers[i + 1]}
                  prevPlan={i === 0 ? individualPlan : planOptionsData[i]}
                  className={cn(
                    plan.name === PlanName.ENTERPRISE && 'sm:col-span-2 lg:col-span-1'
                  )}
                />
              ))}
          </div>
        )}

        <div className="flex flex-col items-center text-center">
          <p className="my-3 text-lg lg:text-2xl !leading-[1.35]">
            Want to expense your Reforge membership?
          </p>

          <Button
            variant="text-only"
            href="/reimbursement"
            className="p-0 hover:bg-transparent underline hover:underline"
            onClick={handleReimbursementCtaClick}
          >
            {LEARN_MORE_CTA}
          </Button>
        </div>
      </div>
    </section>
  )
}

export default MembershipTypeSection
