import { useEffect } from 'react'

import { usePage } from 'components/PageHeader/usePage'
import { useFilter as usePillFilter } from 'components/Pill'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useURLParams from 'hooks/useURLParams'

export enum SearchLinkType {
  TOP_RESULTS = 'TopResults',
  CMS_SECTION = 'CmsSection',
  ARTIFACT = 'Artifact',
  COURSE = 'Course',
  GUIDE = 'Guide',
  USER = 'User'
}

const useSearchResultsFilters = ({
  searchType,
  userCan,
  searchQuery
}: {
  searchType: string
  userCan: {
    viewSearchTabs?: boolean | null
  }
  searchQuery: string
}) => {
  const { getQueryString, deleteParam, setParam } = useURLParams()
  const { setPageTabs } = usePage()
  const { currentUser } = useCurrentUser()

  const formatLinkUrl = (linkType: string) => {
    if (linkType === SearchLinkType.TOP_RESULTS) {
      deleteParam('type')
    } else {
      setParam('type', linkType)
    }
    if (linkType !== SearchLinkType.CMS_SECTION) {
      deleteParam('program')
    }
    // Delete this param if it is present as it was related to Discussion searches which
    // are now gone.
    deleteParam('topic')

    return `/search?${getQueryString()}`
  }

  const searchFilterTabs = [
    {
      title: 'All',
      href: formatLinkUrl(SearchLinkType.TOP_RESULTS),
      id: SearchLinkType.TOP_RESULTS
    },
    {
      title: 'Courses',
      href: formatLinkUrl(SearchLinkType.COURSE),
      id: SearchLinkType.COURSE
    },
    {
      title: 'Artifacts',
      href: formatLinkUrl(SearchLinkType.ARTIFACT),
      id: SearchLinkType.ARTIFACT
    },
    {
      title: 'Guides',
      href: formatLinkUrl(SearchLinkType.GUIDE),
      id: SearchLinkType.GUIDE
    },
    {
      title: 'Program Content',
      href: formatLinkUrl(SearchLinkType.CMS_SECTION),
      id: SearchLinkType.CMS_SECTION
    }
  ]

  if (userCan.viewSearchTabs && !currentUser?.is.trialing) {
    searchFilterTabs.push({
      title: 'Directory',
      href: formatLinkUrl(SearchLinkType.USER),
      id: SearchLinkType.USER
    })
  }

  const { title: activePill = 'All' } =
    searchFilterTabs.find(({ id }) => id === searchType) || {}

  const { setActivePill } = usePillFilter(activePill)

  useEffect(() => {
    setActivePill(activePill)

    const tabs = searchFilterTabs.map(({ title, href }) => ({
      label: title,
      href,
      isActive: title === activePill
    }))

    setPageTabs(tabs)

    return () => {
      setPageTabs(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchType, searchQuery])

  return null
}

export default useSearchResultsFilters
