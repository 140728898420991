import MuxPlayer from '@mux/mux-player-react'
import { useEffect } from 'react'

import { onEnterKeyPress } from 'utils/keyboard'

import { CloseIcon } from './icons'

interface FullScreenVideoModalOptionProps {
  thumbnailTime?: number
}

interface FullScreenVideoModalProps {
  videoType: 'mux' | 'src'
  videoSrc: string
  onPlay?: () => void
  onClose: () => void
  opts?: FullScreenVideoModalOptionProps
  autoplay?: boolean
}
const FullScreenVideoModal = ({
  videoSrc,
  videoType,
  opts,
  onPlay,
  onClose,
  autoplay = false
}: FullScreenVideoModalProps) => {
  useEffect(() => {
    const handleEscapeKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }

    const handleClickOutside = (event: MouseEvent) => {
      const modal = document.getElementById('full-screen-video-modal')
      if (modal && modal === event.target) {
        onClose()
      }
    }

    document.addEventListener('keydown', handleEscapeKeyPress)
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress)
      document.removeEventListener('click', handleClickOutside)
    }
  }, [onClose])

  return (
    <div
      id="full-screen-video-modal"
      className="z-[9999] fixed top-0 bottom-0 left-0 right-0 h-full w-full flex items-center justify-center bg-black/[0.7]"
    >
      <div
        onClick={onClose}
        onKeyUp={onEnterKeyPress(onClose)}
        role="button"
        tabIndex={0}
      >
        <CloseIcon className="fixed top-8 right-8 h-12 w-12 cursor-pointer text-white" />
      </div>
      <div className="2xl:w-[1280px] xl:w-[1204px] lg:w-[960px] md:w-[640px] sm:w-[480px] w-[335px]">
        {videoType === 'mux' && (
          <MuxPlayer
            playbackId={videoSrc || undefined}
            onPlay={onPlay}
            autoPlay={autoplay}
            streamType="on-demand"
            thumbnailTime={opts?.thumbnailTime || 0}
          />
        )}
        {videoType === 'src' && <video src={videoSrc} controls autoPlay={autoplay} />}
      </div>
    </div>
  )
}

export default FullScreenVideoModal
