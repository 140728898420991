import { createContext, useContext } from 'react'

import { CohortViewerNewCohortPartsFragment, PostShowFieldsFragment } from 'gql'

interface CohortConversationContextValue {
  cohort: CohortViewerNewCohortPartsFragment
  topicId: string
  groupId: string
  post?: PostShowFieldsFragment
}

export const CohortConversationTrackingContext =
  createContext<CohortConversationContextValue>({
    cohort: {} as CohortViewerNewCohortPartsFragment,
    topicId: '',
    groupId: '',
    post: {} as PostShowFieldsFragment
  })

export function useCohortConversationTrackingContext() {
  return useContext(CohortConversationTrackingContext)
}
