import { RefObject } from 'react'
import { twMerge } from 'tailwind-merge'

import { PrereadCard } from 'domains/CohortDashboard/CohortDashboardProgram/CohortDashboardWeeklyBlock'
import RecapLessonCard from 'domains/CohortDashboard/CohortDashboardProgram/CohortDashboardWeeklyBlock/RecapLessonCard'
import { useCohortDashboardTrackingContext } from 'domains/CohortDashboard/hooks/useCohortDashboardTrackingContext'
import { CohortEvent } from 'domains/CohortViewer/utils'

import CollapsibleSectionCard from 'components/CollapsibleSectionCard/CollapsibleSectionCard'
import { SectionCardContainerSkeleton } from 'components/CollapsibleSectionCard/SectionCardContainer'
import SkeletonRfParagraph from 'components/skeletons/typography/SkeletonRfParagraph'
import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'

import {
  CohortCmsModulePartsFragment,
  CohortDashboardCmsSectionPartsFragment,
  CohortDashboardLessonPartsFragment,
  CohortDashboardScheduledWeekCurrentUserPartsFragment,
  CohortDashboardScheduledWeekPartsFragment,
  UserProgram
} from 'gql'

import {
  cohortDashboardStandardizedModuleWithSections,
  getLessonToDisplay
} from 'utils/cohortUtils'
import { trackCtaClicked } from 'utils/tracking/analytics'

interface WeeklyBlockModulesSkeletonProps {
  className?: string
}

export const WeeklyBlockModulesSkeleton = ({
  className
}: WeeklyBlockModulesSkeletonProps) => (
  <div className={twMerge('flex animate-pulse flex-col xl:w-2/3', className)}>
    <SkeletonRfParagraph className="h-10 w-2/3" />
    <SkeletonRfParagraph className="mt-8 h-3 w-full" />
    <SkeletonRfParagraph className="mt-1 h-3 w-2/3" />
    <SkeletonRfParagraph className="mt-8 h-5 w-1/3" />
    <SectionCardContainerSkeleton className="mt-8" />
    <SectionCardContainerSkeleton className="mt-6" />
    <SectionCardContainerSkeleton className="mt-6" />
    <SectionCardContainerSkeleton className="mt-6" />
  </div>
)

export interface WeeklyBlockModulesProps {
  scheduledWeek: CohortDashboardScheduledWeekPartsFragment
  scheduledWeekIndex: number
  currentUser: CohortDashboardScheduledWeekCurrentUserPartsFragment
  slug: string
  gettingStartedModule?: CohortCmsModulePartsFragment | null
  events: CohortEvent[]
  sectionOrLessonRef: RefObject<HTMLDivElement>
}

export const WeeklyBlockModules = ({
  scheduledWeek,
  scheduledWeekIndex,
  currentUser,
  slug,
  gettingStartedModule,
  events,
  sectionOrLessonRef
}: WeeklyBlockModulesProps) => {
  const { parentSlug, lessonSlug } = getLessonToDisplay(
    scheduledWeek,
    currentUser.activeProgram
  )

  const activeProgram = currentUser?.activeProgram as UserProgram

  const { cohort, userCohortId } = useCohortDashboardTrackingContext()

  if (!activeProgram) {
    return null
  }

  const isKickoffWeek = scheduledWeekIndex === 0
  const showGettingStartedModule = !!gettingStartedModule && isKickoffWeek

  const modulesWithSections = scheduledWeek.orderedCmsModules.filter(
    (module) => !!module.cmsSections.length
  )

  const caseStudyEvent = events[0]
  const getModuleHeaderText = (moduleName: string) => {
    if (isKickoffWeek) {
      return null
    }

    return modulesWithSections.length > 1 ? moduleName : "This week's material"
  }

  const trackPrereadClick = () => {
    trackCtaClicked({
      cta_location: 'cohort_dashboard',
      cta_type: 'card',
      text: 'Event pre-read',
      destination: caseStudyEvent?.preread,
      related_identifiers: {
        cohort_id: cohort.id,
        cohort_name: cohort.name,
        user_cohort_id: userCohortId
      }
    })
  }

  const trackGettingStartedClick = (lesson: CohortDashboardLessonPartsFragment) => {
    trackCtaClicked({
      cta_location: 'cohort_dashboard_getting_started_module_lesson',
      cta_type: 'card',
      text: lesson.name,
      related_identifiers: {
        cohort_id: cohort.id,
        cohort_name: cohort.name,
        user_cohort_id: userCohortId
      }
    })
  }

  return (
    <div className="mb-16 flex flex-col xl:mb-0">
      {isKickoffWeek && (
        <RfHeader3SemiBold className="pb-4">About your Course</RfHeader3SemiBold>
      )}
      {showGettingStartedModule &&
        gettingStartedModule.cmsSections.map((section, index) => (
          <div key={section.id}>
            <CollapsibleSectionCard
              section={section}
              module={gettingStartedModule}
              defaultIsExpanded
              number={index + 1}
              activeProgram={activeProgram}
              slug={slug}
              onTrack={(lesson: CohortDashboardLessonPartsFragment) =>
                trackGettingStartedClick(lesson)
              }
            />
          </div>
        ))}
      {modulesWithSections.map((moduleWithSections) => {
        const result = cohortDashboardStandardizedModuleWithSections(
          moduleWithSections,
          true
        )
        const module = result.module

        const moduleHeaderText = getModuleHeaderText(module.name)
        const cmsSections = module.cmsSections as CohortDashboardCmsSectionPartsFragment[]
        const recapLesson = result.recapLesson as CohortDashboardCmsSectionPartsFragment

        return (
          <div key={module.id}>
            <div className="flex flex-col">
              {moduleHeaderText && (
                <RfHeader3SemiBold className="pb-3">{moduleHeaderText}</RfHeader3SemiBold>
              )}
              <div className="flex flex-col space-y-1 pb-10">
                {cmsSections.map((section, index) => (
                  <div
                    key={section.id}
                    ref={parentSlug === section.slug ? sectionOrLessonRef : null}
                  >
                    <CollapsibleSectionCard
                      section={section}
                      module={module}
                      number={index + (showGettingStartedModule ? 2 : 1)}
                      activeProgram={activeProgram}
                      slug={slug}
                      defaultIsExpanded={!isKickoffWeek && parentSlug === section.slug}
                    />
                  </div>
                ))}
              </div>
              {recapLesson && (
                <div
                  className="mb-8"
                  ref={lessonSlug === recapLesson.slug ? sectionOrLessonRef : null}
                >
                  <RecapLessonCard
                    activeProgram={activeProgram}
                    lesson={recapLesson}
                    slug={slug}
                    module={module}
                    numModules={modulesWithSections.length}
                    scheduledWeekIndex={scheduledWeekIndex}
                  />
                </div>
              )}
            </div>
          </div>
        )
      })}
      {caseStudyEvent?.preread && (
        <PrereadCard onClick={trackPrereadClick} event={caseStudyEvent} />
      )}
    </div>
  )
}

export default WeeklyBlockModules
