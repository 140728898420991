import { useCallback, useEffect, useState } from 'react'

import SelectFilter, { OptionWithCategories } from 'components/SelectFilter'

import { SKILLS } from 'constants/onboarding'

import { UserSearchFilters } from 'gql'

interface AreasOfExpertiseProps {
  filters: UserSearchFilters
  setFilters: any
}

const AreasOfExpertise = ({ filters, setFilters }: AreasOfExpertiseProps) => {
  const name = 'areasOfExpertise'
  const [selection, setSelection] = useState(filters[name])

  useEffect(() => {
    setSelection(filters[name])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters[name]])

  const getOptions = useCallback(() => {
    const options: OptionWithCategories[] = []

    SKILLS.map((group: { title: string; suggestions: string[] }) => {
      const items = group.suggestions.map((suggestion) => ({
        id: suggestion,
        name: suggestion
      }))
      const category = group.title
      options.push({ category, items })
    })
    return options
  }, [])

  return (
    <SelectFilter
      name={name}
      label="Areas of Expertise"
      selection={selection}
      filters={filters}
      setFilters={setFilters}
      options={getOptions()}
    />
  )
}

export default AreasOfExpertise
