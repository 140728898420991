import { closing, opening } from './sharedMarkdown'

const programName = 'Growth Leadership'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://www.reforge.com/programs/growth-leadership), because I believe it will help me drive [your company]‘s growth immediately by organizing and operating a growth team and managing growth goals for the organization. This course was built with [Adam Fishman](https://www.reforge.com/experts/adam-fishman) and [Elena Verna](https://www.reforge.com/experts/elena-verna), and covers:
* **Planning Your Growth Career.** Think of your career as a growth opportunity. We’ll cover how you can grow, how you can evaluate new opportunities, and how to be successful quickly in a new role.
* **Balancing Growth Goals.** You don’t want to be accountable for every KPI at your company, but you need to be accountable for the right ones to drive impact. Learn how to share growth goals successfully.
* **Organizing a Growth Team.** We’ll talk about how to build a growth team that addresses your company’s unique growth problems and operates collaboratively with cross-functional teams.
* **Expanding a Growth Team.** We’ll cover advocating for new roles, creating job descriptions, and interviewing and hiring the right people for your scaling growth team.

&NewLine;

${closing}
`

export default { markdown, title: programName }
