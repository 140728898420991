import { useCallback, useEffect, useReducer, useState } from 'react'

import { StandaloneBillingInfoForm } from 'domains/User/BillingInfoForm/StandaloneBillingInfoForm'
import { IS_ADDRESS_EDITING_ERROR_MESSAGE } from 'domains/User/BillingInfoForm/utils'

import { ErrorMessage, Loading } from 'components'
import { SelectedPlan } from 'components/CheckoutModal/SelectedPlan'
import { CloseButton, ModalContent } from 'components/Modal'
import { PaymentMethods } from 'components/PaymentMethods'
import { StripeElements } from 'components/StripeElements'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import {
  CurrentUserDocument,
  ManagePlanDocument,
  PlanName,
  TeamMembersPageDocument,
  useCheckoutModalQuery,
  useCheckoutMutation,
  usePreviewUpcomingInvoiceQuery,
  useUserPaymentMethodsQuery
} from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import { trackCtaClicked, trackSubscriptionPaid } from 'utils/tracking/analytics'

import {
  initialState,
  reducer,
  setBillingAddress,
  setCohortPassCount,
  setError,
  setSelectedPaymentMethodId,
  taxIdDeleted,
  taxIdLoaded,
  taxIdSaved
} from './CheckoutModalContent.state'
import OrderRecap from './OrderRecap'
import { CohortPassSection } from './components/CohortPassSection'
import { Column } from './components/Column'
import { SectionHeading } from './components/Headings'
import { SectionBorder } from './components/SectionBorder'
import { useTaxInfo } from './useTaxInfo'
import {
  CheckoutFlowContext,
  OrderRecapType,
  ProductItemCodes,
  calculateOrderTotal,
  calculateTaxPrice,
  getCohortPassPrice,
  getDefaultPassCount
} from './utils'

const CHECKOUT_MODAL_TRACKING_LOCATION = 'checkout_modal'

export interface CheckoutModalContentProps {
  onPurchaseSuccess: ({ cohortPassCount }: { cohortPassCount: number }) => void
  selectedPlanName?: PlanName
  numCohortPasses?: number
  handleClose: () => void
  flowContext: CheckoutFlowContext
}

export const CheckoutModalContent = ({
  selectedPlanName,
  numCohortPasses,
  onPurchaseSuccess,
  handleClose,
  flowContext
}: CheckoutModalContentProps) => {
  return (
    <CheckoutModalInnerContent
      onPurchaseSuccess={onPurchaseSuccess}
      numCohortPasses={numCohortPasses}
      selectedPlanName={selectedPlanName}
      handleClose={handleClose}
      flowContext={flowContext}
    />
  )
}

interface CheckoutModalInnerContentProps extends CheckoutModalContentProps {}

/** Used to purchase subscriptions and cohort credits */
const CheckoutModalInnerContent = ({
  selectedPlanName,
  onPurchaseSuccess,
  handleClose,
  numCohortPasses,
  flowContext
}: CheckoutModalInnerContentProps) => {
  const [state, dispatch] = useReducer(reducer, initialState({ flowContext }))
  const [isAddressEditing, setIsAddressEditing] = useState(false)
  const isMobileScreenSize = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
  const isPlanChange = !!selectedPlanName
  const error = state.error?.message || ''
  const defaultPassCount = selectedPlanName ? getDefaultPassCount(selectedPlanName) : 0

  useEffect(() => {
    if (flowContext !== 'cohortPassPurchase') {
      dispatch(
        setCohortPassCount({
          count: flowContext === 'upgradeSubscription' ? 0 : defaultPassCount
        })
      )
    }
  }, [defaultPassCount, flowContext])

  const {
    id: currentUserId,
    contact: { primaryEmail: currentUserEmail }
  } = useAssertCurrentUser()

  const trackSubscriptionPaymentSuccess = () => {
    if (currentUser?.subscriptions?.active) {
      const subscription = currentUser.subscriptions.active
      trackSubscriptionPaid({
        stripe_customer_id: subscription.stripeCustomerId,
        discount: 0,
        invoice_id: undefined,
        payment_type: 'expand',
        plan_billing_amount: selectedPlanDetails?.pricePerYear,
        plan_name: selectedPlanName,
        current_plan_name: subscription.planName,
        includes_subscription_purchase: isPlanChange,
        includes_cohort_purchase: state.cohortPassCount > 0,
        cohort_pass_count: state.cohortPassCount,
        plan_start_date: subscription.startsAt,
        plan_state: subscription.status || '',
        stripe_subscription_id: subscription.stripeSubscriptionId || '',
        subscription_id: subscription.id,
        email: currentUserEmail
      })
    }
  }

  function showGenericPurchaseError() {
    dispatch(
      setError(
        'Sorry, something went wrong while processing your payment. Please try again, or contact us at hello@reforge.com.'
      )
    )
  }

  const [checkoutPurchase, { loading: checkoutPurchaseLoading }] = useCheckoutMutation({
    onCompleted: (response) => {
      if (response.checkout?.success) {
        onPurchaseSuccess({ cohortPassCount: state.cohortPassCount })
        trackSubscriptionPaymentSuccess()
      } else {
        showGenericPurchaseError()
        console.error('Error upgrading subscription', response.checkout?.errors)
      }
    },
    onError: (error: any) => {
      showGenericPurchaseError()
      console.log('Error upgrading subscription', error)
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: TeamMembersPageDocument,
        variables: {
          id: currentUserId
        }
      },
      {
        query: ManagePlanDocument,
        variables: {
          id: currentUserId
        }
      },
      {
        query: CurrentUserDocument,
        variables: {
          id: currentUserId
        }
      }
    ]
  })

  const { error: paymentMethodsError, data: paymentMethodsData } =
    useUserPaymentMethodsQuery({
      variables: { userId: currentUserId }
    })

  const { loading, data } = useCheckoutModalQuery()
  const { plansForSale, cohortPassPriceTiers, currentUser } = data || {}
  const subscriptionId = currentUser?.subscriptions?.active?.id
  const selectedPlanDetails = plansForSale?.find((plan) => plan.name === selectedPlanName)
  const {
    data: previewUpcomingInvoiceData,
    loading: previewUpcomingInvoiceLoading,
    error: previewUpcomingInvoiceError,
    refetch: refetchPreviewUpcomingInvoice
  } = usePreviewUpcomingInvoiceQuery({
    skip: !isPlanChange || !subscriptionId,
    notifyOnNetworkStatusChange: true,
    variables: {
      planName: selectedPlanName!, // we skip if this isn't available
      subscriptionId: subscriptionId!,
      numCohortPasses
    }
  })

  const {
    discountedCohortPassAmount,
    proratedAdjustment,
    subtotal: subscriptionSubtotal,
    tax: subscriptionTax,
    startingBalance
  } = previewUpcomingInvoiceData?.previewUpcomingInvoice || {}

  const { taxInfo, taxIdReverseChargeStatus, refetchTaxInfo, taxInfoLoading } =
    useTaxInfo({
      productKeys: [ProductItemCodes.COHORT_PASS_ITEM_CODE],
      onTaxIdVerified: useCallback(() => {
        refetchPreviewUpcomingInvoice()
        refetchTaxInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
    })

  const cohortPassTaxInfo = taxInfo?.find(
    (item) => item.productItemCode === ProductItemCodes.COHORT_PASS_ITEM_CODE
  )

  const cohortPassPrice = loading
    ? 0
    : getCohortPassPrice({
        cohortPassPriceTiers: data?.cohortPassPriceTiers || [],
        count: state.cohortPassCount
      })

  const subscriptionSubtotalPrice = (subscriptionSubtotal || 0) / 100
  const subscriptionTaxPrice = (subscriptionTax || 0) / 100

  const cohortPassSubtotalPrice = cohortPassPrice * state.cohortPassCount
  const cohortPassTaxPrice = calculateTaxPrice({
    price: cohortPassSubtotalPrice,
    taxItem: cohortPassTaxInfo
  })

  const subTotal = subscriptionSubtotalPrice + cohortPassSubtotalPrice
  const taxTotal = subscriptionTaxPrice + cohortPassTaxPrice
  const startingBalancePrice = startingBalance ? startingBalance / 100 : 0
  const orderTotal = calculateOrderTotal({
    subTotal,
    taxTotal,
    startingBalance: startingBalancePrice
  })

  const handleEditStateChange = useCallback((editState) => {
    dispatch(setError(''))
    setIsAddressEditing(editState)
  }, [])

  if (loading && !data) {
    return <Loading className="my-20" />
  }

  if (!data || !plansForSale || !cohortPassPriceTiers || previewUpcomingInvoiceError) {
    return <ErrorMessage error="Something went wrong while loading the checkout modal" />
  }

  if (!paymentMethodsData) {
    return <ErrorMessage error={paymentMethodsError} />
  }

  if (!subscriptionId) {
    return <ErrorMessage error="Something went wrong while loading your subscription" />
  }

  const orderRecap: OrderRecapType = {
    ...(isPlanChange && selectedPlanDetails
      ? {
          plan: {
            price: selectedPlanDetails.pricePerYear,
            seatCount: selectedPlanDetails.maxSeatCount,
            name: selectedPlanName
          }
        }
      : {}),
    ...(proratedAdjustment
      ? {
          proratedAdjustment: {
            price: proratedAdjustment.amount / 100,
            start: proratedAdjustment.startDate,
            end: proratedAdjustment.endDate
          }
        }
      : {}),
    discountedCohortPassAmount: discountedCohortPassAmount
      ? discountedCohortPassAmount / 100
      : null,
    cohortPass: {
      label: 'Live Course Pass',
      quantity: state.cohortPassCount,
      price: cohortPassPrice
    },
    startingBalance: {
      price: startingBalancePrice
    },
    subTotal: {
      price: subTotal
    },
    ...(subscriptionTax || cohortPassTaxInfo
      ? {
          taxInfo: {
            price: taxTotal
          }
        }
      : {})
  }

  const isOrderRecapLoading = previewUpcomingInvoiceLoading || taxInfoLoading

  const handlePurchaseClick = async () => {
    dispatch(setError(''))
    trackCtaClicked({
      cta_location: CHECKOUT_MODAL_TRACKING_LOCATION,
      text: 'purchase',
      cta_type: 'button'
    })

    const cohortSelected = state.cohortPassCount > 0

    if (!selectedPlanName && !cohortSelected) {
      dispatch(setError('Please select a Live Course Pass', { field: 'cohort' }))
      return
    }

    if (isAddressEditing || !state.isBillingInfoFormComplete) {
      dispatch(setError(IS_ADDRESS_EDITING_ERROR_MESSAGE, { field: 'billing' }))
      return
    }

    if (!state.selectedPaymentMethodId) {
      dispatch(setError('Your payment information is incomplete', { field: 'payment' }))
      return
    }

    checkoutPurchase({
      variables: {
        input: {
          paymentMethodId: state.selectedPaymentMethodId,
          planName: selectedPlanName,
          numCohortPasses: state.cohortPassCount,
          addCohortPassDiscount: discountedCohortPassAmount
            ? discountedCohortPassAmount > 0
            : false
        }
      }
    })
  }

  return (
    <>
      <ModalContent className="h-full w-full rounded-lg bg-white xs:p-6 sm:p-8 md:p-10 lg:h-auto lg:w-auto">
        <div className="mr-[-14px] flex items-center justify-between pb-4">
          <div className="text-2xl">Checkout</div>
          <CloseButton handleClose={handleClose} />
        </div>

        <div className="flex flex-col justify-between md:flex-row md:space-x-6">
          <Column className="w-full">
            {selectedPlanDetails && (
              <SelectedPlan
                name={
                  /* Ideally we'd update the plansForSale GQL field type,
                      but this should be fine for the short-term */
                  selectedPlanDetails.name as PlanName
                }
                pricePerYear={selectedPlanDetails.pricePerYear}
                maxSeatCount={selectedPlanDetails.maxSeatCount}
              />
            )}
            <CohortPassSection
              planName={selectedPlanName || null}
              defaultPassCount={defaultPassCount}
              location="checkout-modal"
              titleText={selectedPlanName ? 'Add-ons' : 'Your cart'}
              subtitleText="Select the number of Live Course Passes you would like to add."
              cohortPassPriceTiers={cohortPassPriceTiers}
              count={state.cohortPassCount}
              showIsRequiredError={state.error?.field === 'cohort'}
              onCountChange={(count) => {
                dispatch(setError(''))
                dispatch(setCohortPassCount({ count }))
              }}
            />
            {/* We wrap stripe elements here to avoid rerendering the CheckoutModal component when a new intent is created */}
            <StripeElements>
              <div>
                <SectionHeading>Billing Information</SectionHeading>
                <SectionBorder hasError={state.error?.field === 'billing'}>
                  <StandaloneBillingInfoForm
                    trackingLocation={CHECKOUT_MODAL_TRACKING_LOCATION}
                    showIsRequiredError={state.error?.field === 'billing'}
                    onSave={({ billingAddress, taxId }, isComplete) => {
                      if (billingAddress) {
                        dispatch(setError(''))
                        dispatch(setBillingAddress({ billingAddress, isComplete }))
                      }

                      if (taxId) {
                        dispatch(taxIdSaved({ taxId, isComplete }))
                      }

                      refetchPreviewUpcomingInvoice()
                      refetchTaxInfo()
                    }}
                    onTaxIdDelete={() => {
                      dispatch(taxIdDeleted())
                      refetchTaxInfo()
                      refetchPreviewUpcomingInvoice()
                    }}
                    onLoad={({ billingAddress, taxId }, isComplete) => {
                      if (billingAddress) {
                        dispatch(setBillingAddress({ billingAddress, isComplete }))
                      }

                      if (taxId) {
                        dispatch(taxIdLoaded({ taxId, isComplete }))
                      }
                    }}
                    onEditStateChange={handleEditStateChange}
                  />
                </SectionBorder>
              </div>

              <div>
                <SectionHeading>Payment</SectionHeading>
                <SectionBorder
                  className="justify-center"
                  hasError={state.error?.field === 'payment'}
                >
                  <PaymentMethods
                    selectedPaymentMethodId={state.selectedPaymentMethodId}
                    billingAddress={state.billingAddress}
                    showIsRequiredError={state.error?.field === 'payment'}
                    setSelectedPaymentMethodId={(id) => {
                      dispatch(setError(''))
                      dispatch(setSelectedPaymentMethodId({ id }))
                    }}
                  />
                </SectionBorder>
              </div>
            </StripeElements>

            {isMobileScreenSize && (
              <OrderRecap
                orderRecap={orderRecap}
                loading={isOrderRecapLoading}
                total={orderTotal}
                checkoutPurchaseLoading={checkoutPurchaseLoading}
                handlePurchaseClick={handlePurchaseClick}
                error={error}
                taxIdReverseChargeStatus={taxIdReverseChargeStatus}
                taxIdVerificationCallback={refetchTaxInfo}
                flowContext={flowContext}
              />
            )}
          </Column>

          {!isMobileScreenSize && (
            <Column className="min-w-[250px] md:w-80 lg:shrink-0">
              <OrderRecap
                orderRecap={orderRecap}
                loading={isOrderRecapLoading}
                total={orderTotal}
                checkoutPurchaseLoading={checkoutPurchaseLoading}
                handlePurchaseClick={handlePurchaseClick}
                error={error}
                taxIdReverseChargeStatus={taxIdReverseChargeStatus}
                taxIdVerificationCallback={refetchTaxInfo}
                flowContext={flowContext}
              />
            </Column>
          )}
        </div>
      </ModalContent>
    </>
  )
}
