/**
 * CohortAccessInfoCard
 * presentational component
 * used in ManageSeatSidePanel/CohortAccessPanelContent
 * */
export default function CohortAccessInfoCard() {
  return (
    <div
      className="rounded-md text-base font-normal"
      data-testid="cohort-access-info-card"
    >
      <p>
        Once you assign this Live Course Pass, this member will be able to enroll in a
        Cohort.
      </p>
    </div>
  )
}
