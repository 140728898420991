import { formatInTimeZone } from 'date-fns-tz'

import Image from 'domains/Sanity/Image'

import RfHeader3 from 'components/typography/RfHeader/RfHeader3'

import { CheckoutCourseSessionFragment } from 'gql'

import { getTimezoneAbbreviation } from 'utils/date'

import { ReactComponent as SelectCircleSelected } from 'images/select-circle-selected.svg'
import { ReactComponent as SelectCircleUnselected } from 'images/select-circle-unselected.svg'

interface CourseCheckoutStartDateSectionProps {
  courseSessions: CheckoutCourseSessionFragment[]
  selectedCourseSessionId: string
  setSelectedCourseSessionId: (id: string) => void
  timezone?: string
}

export const CourseCheckoutStartDateSection = ({
  courseSessions,
  selectedCourseSessionId,
  setSelectedCourseSessionId,
  timezone
}: CourseCheckoutStartDateSectionProps) => {
  const userTimezone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  const dateFormat = 'EEEE, LLL d'

  return (
    <div className="mb-1">
      <RfHeader3>1. Choose your start date</RfHeader3>
      <div className="mt-8 w-full" />
      {courseSessions.map((session: any) => {
        if (session.id === selectedCourseSessionId) {
          return (
            <div
              key={`session_${session.id}`}
              className="mb-4 w-full rounded-xl border-2 p-6"
            >
              <div className="flex w-full justify-between">
                <div className="font-semibold">
                  {formatInTimeZone(session.startsAt, userTimezone, dateFormat)}
                </div>
                <SelectCircleSelected width={24} height={24} />
              </div>

              <div className="mt-4 text-sm">
                <div className="mb-2">
                  hosted by {session.experts.map((expert: any) => expert.name).join(', ')}
                </div>
                <div className="flex flex-row">
                  {session.experts.map((expert: any) => {
                    return (
                      <div key={`expert_${expert.id}`} className="mr-2">
                        <Image
                          src={expert.avatarUrl}
                          alt={expert.name}
                          width={105}
                          height={105}
                          className="rounded-md"
                        />
                      </div>
                    )
                  })}
                </div>

                <div className="mt-4 leading-6">
                  <div className="font-semibold">Live Events</div>
                  {session.events && session.events.length > 0 ? (
                    <>
                      {session.events.map((event: any) => {
                        return (
                          <div key={`event_${event.id}`}>
                            <span className="mr-6">
                              {formatInTimeZone(event.startsAt, userTimezone, dateFormat)}
                            </span>
                            <span className="text-gray-400">
                              {formatInTimeZone(
                                event.startsAt || '',
                                userTimezone!,
                                'h:mma'
                              )}{' '}
                              -{' '}
                              {formatInTimeZone(
                                event.endsAt || '',
                                userTimezone!,
                                'h:mma'
                              )}{' '}
                              {getTimezoneAbbreviation(userTimezone, event.startsAt)}
                            </span>
                          </div>
                        )
                      })}
                    </>
                  ) : (
                    <div>Coming soon</div>
                  )}
                </div>
              </div>
            </div>
          )
        }

        // Collapsed version of the session if not selected
        return (
          <button
            key={session.id}
            className="mb-4 flex w-full justify-between rounded-xl border-2 p-6"
            onClick={() => setSelectedCourseSessionId(session.id)}
          >
            <div className="font-semibold">
              {formatInTimeZone(session.startsAt, userTimezone, dateFormat)}
            </div>
            <SelectCircleUnselected width={24} height={24} />
          </button>
        )
      })}
    </div>
  )
}
