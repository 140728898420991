import { ReactNode, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Button from 'components/Button'
import OrderRecapLabel from 'components/CheckoutModal/OrderRecap/components/OrderRecapLabel'
import OrderRecapPrice from 'components/CheckoutModal/OrderRecap/components/OrderRecapPrice'
import { SectionBorder } from 'components/CheckoutModal/components/SectionBorder'
import { getSalesTaxLabelDetails } from 'components/CheckoutModal/useTaxInfo'
import {
  ProductItemCodes,
  calculateTaxPrice,
  currencyOptions,
  locale
} from 'components/CheckoutModal/utils'

import {
  TaxIdReverseChargeStatus,
  TaxInfoItem,
  useCreateCourseEnrollmentMutation,
  useTrackCourseEnrollmentConfirmedMutation
} from 'gql'

import { COURSE_PAYMENT_METHODS } from 'utils/courseUtils'
import { MONTH_ABBREV_DATE_FORMAT, getFormattedStartDate } from 'utils/date'
import { capitalizeFirstLetter } from 'utils/stringUtils'
import { trackCtaClicked } from 'utils/tracking/analytics'

import { getCourseEnrollmentSuccessPath } from './CoursePaymentPage'

const Divider = () => <div className="my-4 border-t border-rb-gray-100" />

export interface CourseOrderSummaryRefExposedMethods {
  refetchTax: () => void
}

export const OrderItemGroup = ({ children }: { children: ReactNode }) => (
  <div className="grid grid-flow-row grid-cols-[1fr_auto] gap-y-2.5 gap-x-4">
    {children}
  </div>
)

export interface CourseOrderSummaryProps {
  courseSession: any
  courseSlug: string
  startDate: string
  canPurchase: boolean
  taxInfo?: TaxInfoItem[]
  taxIdReverseChargeStatus?: TaxIdReverseChargeStatus
  refetchTaxInfo: () => void
  selectedPaymentMethodId: string | null
  setErrorMessage: (errorMessage: string) => void
}

export const CourseOrderSummary = ({
  courseSession,
  courseSlug,
  startDate,
  selectedPaymentMethodId,
  canPurchase,
  taxInfo,
  taxIdReverseChargeStatus,
  refetchTaxInfo,
  setErrorMessage
}: CourseOrderSummaryProps) => {
  const history = useHistory()
  const [isPurchaseProcessing, setIsPurchaseProcessing] = useState(false)
  const [createCourseEnrollment] = useCreateCourseEnrollmentMutation()
  const [trackCourseEnrollmentConfirmed] = useTrackCourseEnrollmentConfirmedMutation()

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const dateSummary = `${getFormattedStartDate(
    courseSession.startsAt,
    userTimeZone,
    MONTH_ABBREV_DATE_FORMAT
  )} - ${getFormattedStartDate(
    courseSession.endsAt,
    userTimeZone,
    MONTH_ABBREV_DATE_FORMAT
  )}`

  const handlePurchaseClick = async () => {
    const ctaLocation = 'review_purchase_page'

    setErrorMessage('')
    trackCtaClicked({
      cta_location: ctaLocation,
      text: 'complete purchase',
      cta_type: 'button'
    })

    trackCourseEnrollmentConfirmed({
      variables: {
        input: {
          courseSessionId: courseSession?.id,
          courseSlug: courseSlug,
          ctaLocation: ctaLocation,
          paymentMethod: COURSE_PAYMENT_METHODS.stripePayment
        }
      }
    })

    setIsPurchaseProcessing(true)
    const response = await createCourseEnrollment({
      variables: {
        input: {
          courseSlug: courseSlug,
          startDate: startDate,
          paymentMethodId: selectedPaymentMethodId
        }
      }
    })
    setIsPurchaseProcessing(false)

    if (
      response.data?.createCourseEnrollment?.errors &&
      response.data.createCourseEnrollment.errors.length > 0
    ) {
      const error = response.data.createCourseEnrollment.errors[0]
      setErrorMessage(error)
      // TODO: Determine if we need this handling.
      // if (error === 'requires_action') {
      //   setCustomerActionRequired(true)
      //   handlePaymentThatRequiresCustomerAction({
      //     clientSecret: response.checkout?.clientSecret || undefined,
      //     stripeSubscriptionId: response.checkout?.stripeSubscriptionId || undefined
      //   })
      //   return
      // }
    } else if (
      response.data?.createCourseEnrollment?.id &&
      response.data.createCourseEnrollment.courseSessionId
    ) {
      history.push(
        getCourseEnrollmentSuccessPath(
          courseSlug,
          response.data.createCourseEnrollment.courseSessionId
        )
      )
    }
  }

  const taxInfoItem = taxInfo?.find(
    (item) => item.productItemCode === ProductItemCodes.COURSE_ITEM_CODE
  )
  const taxTotal = calculateTaxPrice({ price: courseSession.price, taxItem: taxInfoItem })

  return (
    <>
      <div className="mb-3 text-xl" data-test="order-recap-section">
        Order Recap
      </div>

      <SectionBorder className="flex flex-col md:w-96">
        <OrderItemGroup>
          <>
            <OrderRecapLabel
              label={capitalizeFirstLetter(courseSession.courseTitle)}
              details={dateSummary}
            />
            <span className="text-right">
              {courseSession.price.toLocaleString(locale, currencyOptions)}
            </span>
          </>

          {taxInfo && canPurchase ? (
            <>
              <OrderRecapLabel
                label="Sales Tax"
                details={getSalesTaxLabelDetails(
                  taxIdReverseChargeStatus,
                  refetchTaxInfo
                )}
              />

              <OrderRecapPrice price={taxTotal} />
            </>
          ) : (
            <>
              <OrderRecapLabel label="Sales Tax" />
              <span className="text-right">TBD</span>
            </>
          )}
        </OrderItemGroup>

        <Divider />

        <OrderItemGroup>
          <OrderRecapLabel label="Total" className="text-xl" />
          <OrderRecapPrice
            price={canPurchase ? courseSession.price + taxTotal : courseSession.price}
          />
        </OrderItemGroup>

        <Button
          className="mt-6 h-12 w-full"
          onClick={handlePurchaseClick}
          disabled={!canPurchase}
          isLoadingSpinner={isPurchaseProcessing}
        >
          Complete Purchase
        </Button>
        {/* <TermsAndConditions collapsible hasPlan /> */}
      </SectionBorder>
    </>
  )
}
