import { useState } from 'react'
import { debounce } from 'throttle-debounce'

type DebouncedStateReturn = [string, (value: string) => void]

const useDebouncedStringState = (initialValue = ''): DebouncedStateReturn => {
  const [search, setSearch] = useState<string>(initialValue)

  const debouncedSearch = debounce(500, (value: string) => setSearch(value))

  const handleSearch = (value: string) => {
    debouncedSearch(value || '')
  }

  return [search, handleSearch]
}

export default useDebouncedStringState
