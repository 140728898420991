import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

import { SVGIcon } from 'components/Icon'

import { BaseFilterSet } from './Filter.types'

export type Topic = {
  id: string | number
  kind?: string | null
  title: string
  topicId?: string | number | null
}

export interface TwostepFilterSet extends BaseFilterSet {
  explainer?: string
  title: string
  groupTitle: string
  groupTopics: Array<Topic>
  type: 'twostep'
}

type TwostepTaxonomy = { [key: string]: string[] }
type TwostepLookupTable = { [key: string]: Topic }

export interface ITwostepFilter {
  filterSet: TwostepFilterSet
  replaceFilters: (filterKey: string, newValue: string[]) => void
  resetFilters: (filterKey: string) => void
  updateFilters: (filterKey: string, value: string, remove: boolean) => void
  values: string | number | string[]
  startOpen?: Boolean
}

const TwostepFilter = (props: ITwostepFilter) => {
  const { filterSet, values, startOpen } = props
  const { groupTitle } = filterSet
  const [taxonomy, setTaxonomy] = useState<TwostepTaxonomy>({})
  const [topicLookupTable, setTopicLookupTable] = useState<TwostepLookupTable>({})
  const [isOpen, setOpen] = useState(!!(values || startOpen))

  useEffect(() => {
    const topicTree: TwostepTaxonomy = {}
    const idsToTopics: TwostepLookupTable = {}

    filterSet.groupTopics.forEach((topic) => {
      idsToTopics[topic.id.toString()] = topic
    })

    filterSet.groupTopics.forEach((topic) => {
      if (!topic.topicId) {
        topicTree[topic.id.toString()] = []
      } else {
        topicTree[topic.topicId.toString()].push(topic.id.toString())
      }
    })

    setTaxonomy(topicTree)
    setTopicLookupTable(idsToTopics)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateFilters = (value: string, remove: boolean) => {
    props.updateFilters(filterSet.key, value, remove)
  }

  const reset = () => {
    props.resetFilters(filterSet.key)
  }

  const handleSelectChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    // set the first level to this topic.
    const selectedId = event.target.value
    if (selectedId !== '0') {
      props.replaceFilters(filterSet.key, [selectedId])
    } else {
      props.resetFilters(filterSet.key)
    }
  }

  const relatedConcepts = () => {
    if (Array.isArray(values)) {
      const conceptIds = taxonomy[values[0]]
      return conceptIds.map((conceptId: string) => {
        const concept = topicLookupTable[conceptId]
        const selected = values.includes(conceptId)
        return (
          <span
            key={`concept_${conceptId}`}
            className={`concept-filter  uk-inline-block mb-[10px] mr-[10px] cursor-pointer rounded-[.5px] px-[5px] text-m-small leading-5 text-rb-gray-300 sm:text-m-medium ${
              selected
                ? 'concept-filter--selected bg-[rgba(179,208,237,0.5)]'
                : 'bg-rb-gray-50'
            }`}
            onClick={() => {
              updateFilters(conceptId, selected)
            }}
          >
            <span>{concept.title}</span>
            {selected && (
              <a
                className="ml-[5px] inline-block [&_svg]:h-2 [&_svg]:w-2 [&_svg]:text-rb-gray-400"
                uk-close=""
              ></a>
            )}
          </span>
        )
      })
    }
  }

  const onToggle = () => {
    setOpen(!isOpen)
  }

  const value = Array.isArray(values) ? values[0] : values

  const isFilterHidden = !values

  return (
    <div
      className={clsx(
        'mb-8 -ml-4 rounded-sm border p-0 hover:border-rb-gray-250 tl:mb-4 tl:ml-0',
        isFilterHidden && 'filter--hidden'
      )}
      data-selected={!!values}
      id={`filter-${filterSet.key}`}
    >
      <div
        className="flex cursor-pointer py-3 px-[15px]"
        onClick={onToggle}
        uk-toggle={`target: #filter-${filterSet.key}; cls: filter--hidden`}
      >
        <h3 className="mb-0 grow cursor-pointer text-sm font-medium capitalize text-rb-gray-500">
          {filterSet.title}
        </h3>
        {!!values && (
          <div
            className="mr-1.5 cursor-pointer text-sm leading-5 text-rb-teal-200 hover:underline hover:decoration-rb-teal-300 focus:outline-none"
            onClick={reset}
          >
            Reset
          </div>
        )}
        <div className="hidden cursor-pointer leading-5 [.filter--hidden_&]:block">
          <SVGIcon name="reveal-plus" height="20" width="20" fill="#A2A1A2" />
        </div>
        <div className="hidden cursor-pointer leading-5 tl:block [.filter--hidden_&]:tl:hidden">
          <SVGIcon name="hide-minus" height="20" width="20" fill="#000000" />
        </div>
      </div>
      <div className="px-[15px] pt-0 pb-[5px] [.filter--hidden_>_&]:block [.filter--hidden_>_&]:tl:hidden">
        {filterSet.explainer && (
          <p className="uk-margin-small-bottom text-m-small sm:text-m-medium">
            {filterSet.explainer.replace('[GROUP_NAME]', groupTitle)}
          </p>
        )}
        <select
          value={!values ? '0' : value}
          className={`uk-select mb-2.5 ${
            values ? 'bg-rb-white' : 'bg-rb-gray-50 bg-opacity-35'
          }`}
          onChange={handleSelectChange}
        >
          <option value="0">Select a topic</option>
          {Object.keys(taxonomy).map((topicId) => {
            const topic = topicLookupTable[topicId]
            return (
              <option key={`prog${topic.id}`} value={topic.id}>
                {topic.title}
              </option>
            )
          })}
        </select>
        {!!values && taxonomy[value] && taxonomy[value].length > 0 && (
          <div>
            <div
              className={clsx(
                'filter__title uk-text-uppercase text-xs text-rb-gray-300',
                values && 'text-rb-gray-300 tl:text-rb-gray-500'
              )}
              style={{ marginBottom: '10px' }}
            >
              Related Concepts
            </div>
            {relatedConcepts()}
          </div>
        )}
      </div>
    </div>
  )
}

export default TwostepFilter
