import { KeyboardEvent, MouseEvent } from 'react'
import { Link } from 'react-router-dom'

import { getTableOfContentsSlug } from 'domains/Sanity/lib/helpers'

export interface ITableOfContents {
  markDef: {
    id: string
    tocTitle?: string | null
  }
  children: Partial<{
    marks: string[]
    text: string
  }>[]
}

interface TableOfContentsProps {
  data: ITableOfContents[]
}

const TableOfContents = ({ data }: TableOfContentsProps) => {
  const getTocTitle = (item: ITableOfContents) => {
    if (item.markDef.tocTitle) {
      return item.markDef.tocTitle
    }

    const child = item.children.find((i) => i.marks?.includes(item.markDef.id))
    return child?.text || ''
  }

  const getSlug = (item: ITableOfContents) => {
    return getTableOfContentsSlug({
      title: getTocTitle(item),
      id: item.markDef.id
    })
  }

  const handleOnClick =
    (item: ITableOfContents) =>
    (e: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) => {
      e.preventDefault()

      const slug = getSlug(item)
      const el = document.getElementById(slug)
      const newUrl = `${window.location.pathname}#${slug}`
      window.history.pushState(
        { ...window.history.state, as: newUrl, url: newUrl },
        '',
        newUrl
      )

      el?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }

  return (
    <div className="mb-[40px] md:sticky md:top-[110px]">
      <h4 className="mt-0 mb-[16px] font-semibold !leading-6 fluid-text-lg">
        Table of Contents
      </h4>

      {data.map((item) => {
        return (
          <p key={item.markDef.id} className="mb-[16px] text-base leading-5">
            <Link
              to={`#${getSlug(item)}`}
              className="text-rb-black underline"
              onClick={handleOnClick(item)}
            >
              {getTocTitle(item)}
            </Link>
          </p>
        )
      })}
    </div>
  )
}

export default TableOfContents
