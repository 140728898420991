import CohortPassOptOutSuccessModalContent from 'domains/Subscription/CohortPassOptOutSuccessModalContent'

import { useGlobalModal } from 'components/GlobalModal'

import { trackModalDismissed, trackModalDisplayed } from 'hooks/useModalTracking'

import CohortPassOptOutModalContent from './CohortPassOptOutModalContent'

export const useCohortPassOptOut = () => {
  const { openGlobalModal, closeGlobalModal } = useGlobalModal()

  const handleCohortPassOptOutModalClose = () => {
    trackModalDismissed({
      modal_group: 'cohort_pass_removal_flow',
      modal_name: 'cohort_pass_removal_confirmation'
    })
    closeGlobalModal()
  }

  const handleOptOutSuccessClose = () => {
    trackModalDismissed({
      modal_group: 'cohort_pass_removal_flow',
      modal_name: 'cohort_pass_removal_success'
    })
    closeGlobalModal()
    window.location.reload()
  }

  const showSuccessModal = () => {
    trackModalDisplayed({
      modal_group: 'cohort_pass_removal_flow',
      modal_name: 'cohort_pass_removal_success'
    })
    openGlobalModal(
      <CohortPassOptOutSuccessModalContent handleClose={handleOptOutSuccessClose} />,
      {
        className: 'mx-2 max-w-[646px] rounded-lg',
        handleClose: handleOptOutSuccessClose
      }
    )
  }

  const openCohortPassOptOutModal = () => {
    trackModalDisplayed({
      modal_group: 'cohort_pass_removal_flow',
      modal_name: 'cohort_pass_removal_confirmation'
    })
    openGlobalModal(
      <CohortPassOptOutModalContent
        handleClose={handleCohortPassOptOutModalClose}
        onPassOptOut={showSuccessModal}
      />,
      {
        className: 'mx-2 max-w-[646px] rounded-lg',
        handleClose: handleCohortPassOptOutModalClose
      }
    )
  }

  return {
    openCohortPassOptOutModal
  }
}
