import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import CohortFroalaWrapper from 'domains/CohortConversation/Post/CohortFroalaWrapper'
import { PostFormButton } from 'domains/CohortConversation/Post/PostFormButton'
import useCreateCohortReply from 'domains/CohortConversation/hooks/useCreateCohortReply'
import useUpdateCohortReply from 'domains/CohortConversation/hooks/useUpdateCohortReply'

import BadgeableAvatar from 'components/BadgeableAvatar'
import { useModal } from 'components/Modal'
import RfParagraphSmallBold from 'components/typography/RfParagraph/RfParagraphSmallBold'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import { CohortPostReplyPartsFragment } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import CreatePostOrReplyFormModal from './CreatePostOrReplyFormModal'

export interface ReplyPostFormProps {
  refetch?(): Promise<unknown>
  postId: string
  noStyling?: boolean
  reply?: CohortPostReplyPartsFragment
  onSaveCallback?(): void
}

const ReplyPostForm = ({
  refetch,
  postId,
  reply,
  noStyling,
  onSaveCallback
}: ReplyPostFormProps) => {
  const [content, setContent] = useState(reply?.body || '')
  const [loading, setLoading] = useState(false)
  const { createCohortReply } = useCreateCohortReply()
  const { updateCohortReply } = useUpdateCohortReply()
  const { isModalOpen, openModal, closeModal } = useModal()
  const currentUser = useAssertCurrentUser()

  const mentionContainerId = 'reply-post-mention-container'

  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)

  const submitForm = async () => {
    if (loading) {
      return
    }

    setLoading(true)

    if (reply && reply.id) {
      await updateCohortReply({
        body: content,
        replyId: reply.id,
        replyToId: postId
      })
    } else {
      await createCohortReply({ body: content, replyToId: postId })
    }

    await refetch?.()
    setContent('')
    setLoading(false)
    onSaveCallback?.()
    isModalOpen && closeModal()
  }

  return (
    <div
      className={twMerge(
        !noStyling &&
          'bottom-[24px] m-6 mb-20 flex max-h-[500px] flex-col overflow-visible rounded-[8px] p-6 shadow-cohort-post-card '
      )}
    >
      <div id={mentionContainerId} className="relative top-[-150px]" />

      {isMobile ? (
        <div className="box-border flex w-full max-w-full items-center">
          <BadgeableAvatar
            avatarUrl={currentUser.profile.avatarUrl}
            user={currentUser}
            className="mr-[25px]"
            width="40"
            height="40"
            borderClassNames="rounded"
            fullName={currentUser.profile.firstName}
          />
          <div role="button" onClick={() => openModal()}>
            <RfParagraphSmallBold>Reply...</RfParagraphSmallBold>
          </div>
        </div>
      ) : (
        <div className="flex w-full flex-col">
          <CohortFroalaWrapper
            model={content}
            updateModel={setContent}
            menuContainerId={mentionContainerId}
            placeholder="Reply..."
            className="reply-post w-0 min-w-full grow"
            heightMax={200}
          />

          <PostFormButton
            loading={loading}
            submitForm={submitForm}
            disabled={content === ''}
            label={reply ? 'Save' : 'Reply'}
          />
        </div>
      )}
      <CreatePostOrReplyFormModal
        postId={postId}
        isModalOpen={isModalOpen}
        setContent={setContent}
        handleModalClose={() => closeModal()}
        isMobile={isMobile}
        type="reply"
        content={content}
        menuContainerId={mentionContainerId}
        loading={loading}
        submitForm={submitForm}
      />
    </div>
  )
}

export default ReplyPostForm
