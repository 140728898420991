import { ChangeEventHandler, FormEventHandler } from 'react'
import { twMerge } from 'tailwind-merge'

import DebouncedInput from 'components/forms/DebouncedInput'

import { ReactComponent as MagnifyingGlassIcon } from 'images/icon--magnifying-glass.svg'

export interface FilterInputProps {
  handleSearchSubmit: FormEventHandler<HTMLFormElement>
  handleSearchTextChanged: ChangeEventHandler<HTMLInputElement>
  searchText: string
  className?: string
}
export const SEARCH_DEBOUNCE_MS = 1000

/**
 * FilterInput
 * returns a styled input for filtering Saved Items (Bookmarks)
 */
export default function FilterInput({
  className = '',
  handleSearchSubmit,
  handleSearchTextChanged,
  searchText
}: FilterInputProps) {
  return (
    <form
      className={twMerge(
        'flex h-9 w-full items-center gap-2 rounded-md border border-rb-gray-100 pl-3',
        className
      )}
      onSubmit={handleSearchSubmit}
    >
      <MagnifyingGlassIcon className="h-4 w-4" />
      <DebouncedInput
        className="w-full placeholder-rb-gray-300 focus:outline-none"
        type="text"
        placeholder="Filter by keyword"
        onChange={handleSearchTextChanged}
        autoComplete="off"
        debounceMs={SEARCH_DEBOUNCE_MS}
        value={searchText}
      />
    </form>
  )
}
