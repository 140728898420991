import { useEffect, useState } from 'react'

import { StandaloneBillingInfoForm } from 'domains/User/BillingInfoForm'

import Button from 'components/Button'
import Modal, { ModalHeaderWithClose } from 'components/Modal'
import { StripeElementsWithoutIntent } from 'components/StripeElements'
import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import { useCustomerContactLazyQuery } from 'gql/index'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useLocalStorage from 'hooks/useLocalStorage'

import { ReactComponent as BookAddressImg } from 'images/book_address.svg'

const CONFIRM_PLAN_MODAL_TRACKING_LOCATION = 'collect_billing_address_modal'

/**
 * BillingAddressCollectionModal
 * @returns a modal that encourages users to add their billing address, so we can bill & charge them correctly
 * Rendered globally, so keep the queries short & sweet please <3
 * */
export const BillingAddressCollectionModal = () => {
  const [step, setStep] = useState(1)
  const { currentUser } = useCurrentUser()
  // check local storage to see if we've already dismissed this modal
  const [hasDismissedCallout, setHasDismissedCallout] = useLocalStorage(
    'has-dismissed-billing-confirmation-modal',
    false
  )
  // if we already have the user's billing address, don't continue to query contact info until their next session
  const [hasUpdatedBillingInfo, setHasUpdatedBillingInfo] = useLocalStorage(
    'has-updated-billing-info',
    false
  )
  const [fetchCustomerContact] = useCustomerContactLazyQuery()
  const [isLoading, setIsLoading] = useState(false)
  const onClose = () => setHasDismissedCallout(true)

  const onConfirm = (isComplete: boolean) => {
    // update local storage so we don't continue to query
    setHasUpdatedBillingInfo(isComplete)
  }

  useEffect(() => {
    // check to see if this user has a saved address
    const getCustomerContact = async () => {
      const result = await fetchCustomerContact()
      // if they already have a saved address, note that and carry on
      if (result?.data?.currentUser?.stripeCustomer?.address?.line1) {
        setHasUpdatedBillingInfo(true)
      }
      setIsLoading(false)
    }
    // but only if they haven't already dismissed this or updated their info
    if (!hasDismissedCallout && !hasUpdatedBillingInfo) {
      setIsLoading(true)
      getCustomerContact()
    }
  }, [
    hasDismissedCallout,
    hasUpdatedBillingInfo,
    fetchCustomerContact,
    setHasUpdatedBillingInfo
  ])

  // don't render if dismissed, has previously saved billing address, isn't a subscription owner, or the feature flag isn't on
  if (
    hasDismissedCallout ||
    hasUpdatedBillingInfo ||
    isLoading ||
    !currentUser ||
    !currentUser.is.member ||
    !currentUser.is.subscriptionOwner
  ) {
    return null
  }

  return (
    <Modal
      isOpen={true}
      header={false}
      handleClose={onClose}
      data-testid="billing-address-collection-modal"
      className="!max-h-full rounded-[8px] p-8"
    >
      <ModalHeaderWithClose className="mt-10 mb-2 flex" handleClose={onClose}>
        {step !== 1 && (
          <RfHeader3SemiBold className="text-center">
            Update your Billing Address
          </RfHeader3SemiBold>
        )}
      </ModalHeaderWithClose>
      <div className="overflow-auto">
        {step === 1 ? (
          <div className="flex flex-col items-center justify-center">
            <BookAddressImg height={60} width={60} className="mb-8" />
            <RfHeader3SemiBold className="text-center">Welcome back!</RfHeader3SemiBold>
            <RfParagraphMedium className="text-center">
              Please update your billing address so that you can continue to access
              Reforge.
            </RfParagraphMedium>
            <Button className="mt-20" onClick={() => setStep(2)}>
              Update billing address
            </Button>
          </div>
        ) : (
          <div className="pl-8 pr-4 md:pl-11">
            <StripeElementsWithoutIntent>
              <StandaloneBillingInfoForm
                trackingLocation={CONFIRM_PLAN_MODAL_TRACKING_LOCATION}
                onSave={(_, isComplete) => onConfirm(isComplete)}
              />
            </StripeElementsWithoutIntent>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default BillingAddressCollectionModal
