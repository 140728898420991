import { MutationHookOptions } from '@apollo/client'

import {
  CreateBookmarkInput,
  CreateBookmarkMutation,
  CreateBookmarkMutationFn,
  CreateBookmarkMutationVariables,
  DeleteBookmarkMutation,
  DeleteBookmarkMutationFn,
  DeleteBookmarkMutationVariables,
  ProgramBookmarkPartsFragment,
  UpdateBookmarkMutation,
  UpdateBookmarkMutationFn,
  UpdateBookmarkMutationVariables,
  useCreateBookmarkMutation,
  useDeleteBookmarkMutation,
  useUpdateBookmarkMutation
} from 'gql'

import notifyError from 'utils/errorNotifier'

interface BookmarkCard {
  bookmark: ProgramBookmarkPartsFragment
  onDeleteBookmark?: (bookmark: ProgramBookmarkPartsFragment) => void
  onUpdateBookmarkNote?: () => void
}

const deleteBookmark = async ({
  bookmark,
  onDeleteBookmark,
  removeBookmarkMutation,
  options
}: {
  bookmark: ProgramBookmarkPartsFragment
  onDeleteBookmark?: (bookmark: ProgramBookmarkPartsFragment) => void
  removeBookmarkMutation: DeleteBookmarkMutationFn
  options?: any
}) => {
  if (!bookmark || !bookmark.id) {
    notifyError(`invalid bookmark, cannot delete ${JSON.stringify(bookmark)}`)
    return
  }
  const { errors } = await removeBookmarkMutation({
    variables: {
      input: {
        bookmarkId: bookmark.id
      }
    },
    ...options
  })
  if (errors) {
    notifyError(
      `Error deleting bookmark, got errors ${errors} for bookmark ${JSON.stringify(
        bookmark
      )}`
    )
  } else {
    onDeleteBookmark?.(bookmark)
  }
}

const restoreBookmark = async ({
  bookmark,
  createBookmarkMutation,
  options
}: {
  bookmark: ProgramBookmarkPartsFragment
  createBookmarkMutation: CreateBookmarkMutationFn
  options?: MutationHookOptions<CreateBookmarkMutation, CreateBookmarkMutationVariables>
}) => {
  const {
    anchor,
    basedOn,
    cmsProgram,
    cmsModule,
    cmsSection,
    noteBody,
    seconds,
    referenceImageUrl,
    type,
    title,
    externalUrl
  } = bookmark
  const bookmarkInput: CreateBookmarkInput = {
    anchor,
    basedOn,
    cmsProgramId: cmsProgram?.id,
    cmsModuleId: cmsModule?.id,
    cmsSectionId: cmsSection?.id,
    noteBody,
    seconds,
    referenceImageUrl,
    type,
    title,
    externalUrl
  }
  try {
    await createBookmarkMutation({
      variables: {
        input: bookmarkInput
      },
      ...options
    })
  } catch (error: unknown) {
    notifyError(error)
  }
}

const updateBookmarkNote = async ({
  bookmark,
  note,
  updateBookmarkMutation,
  onUpdateBookmarkNote,
  options
}: {
  bookmark: ProgramBookmarkPartsFragment
  note: string
  updateBookmarkMutation: UpdateBookmarkMutationFn
  onUpdateBookmarkNote?: () => void
  options: any
}) => {
  if (!bookmark?.id) {
    notifyError(`invalid bookmark, cannot update ${JSON.stringify(bookmark)}`)
    return
  }

  const updatedBookmark = {
    ...bookmark,
    note
  }

  const { errors } = await updateBookmarkMutation({
    variables: {
      input: {
        bookmarkId: bookmark.id,
        noteBody: note
      }
    },
    ...options
  })

  if (errors) {
    notifyError(
      `Error saving dashboard bookmark, got errors ${errors} for bookmark ${JSON.stringify(
        updatedBookmark
      )}`
    )
  } else {
    onUpdateBookmarkNote?.()
  }
}

export const useBookmarkCard = ({
  bookmark,
  onDeleteBookmark,
  onUpdateBookmarkNote
}: BookmarkCard) => {
  const [removeBookmarkMutation] = useDeleteBookmarkMutation()
  const [updateBookmarkMutation] = useUpdateBookmarkMutation()
  const [createBookmarkMutation] = useCreateBookmarkMutation()

  return {
    deleteBookmark: (
      options?: MutationHookOptions<
        DeleteBookmarkMutation,
        DeleteBookmarkMutationVariables
      >
    ) =>
      deleteBookmark({
        bookmark,
        onDeleteBookmark,
        removeBookmarkMutation,
        options
      }),
    updateBookmarkNote: ({
      note,
      options
    }: {
      note: string
      options?: MutationHookOptions<
        UpdateBookmarkMutation,
        UpdateBookmarkMutationVariables
      >
    }) =>
      updateBookmarkNote({
        bookmark,
        note,
        updateBookmarkMutation,
        onUpdateBookmarkNote,
        options
      }),
    restoreBookmark: (
      options?: MutationHookOptions<
        CreateBookmarkMutation,
        CreateBookmarkMutationVariables
      >
    ) => restoreBookmark({ bookmark, createBookmarkMutation, options })
  }
}
