import { getUserPositionAtCompany } from 'domains/Profile/utils'

import { FacePile, FacePileUser } from 'components/FacePile'

import { EventPartsFragment } from 'gql'

export const EventCardHeader = ({ hosts }: { hosts: EventPartsFragment['hosts'] }) => {
  if (!hosts.length) return null

  const facepileUsers = hosts.map((host) => {
    return {
      id: host.id,
      avatarUrl: host.user.profile.avatarUrl
    } as FacePileUser
  })

  const hostsView = (hostList: EventPartsFragment['hosts']) => {
    if (hostList.length === 1) {
      const host = hostList[0]
      return (
        <div className="flex w-full truncate">
          <div className="shrink-0">
            <FacePile users={facepileUsers} imageSize="small" className="mr-1" />
          </div>

          <span>{`hosted by ${host.user.profile.fullName} `} &nbsp;</span>

          <span className="truncate text-rb-gray-300">
            {` ${getUserPositionAtCompany(host.user.profile)}`}
          </span>
        </div>
      )
    }

    const allNames = hostList.map((host) => host.user.profile.fullName)

    return (
      <div className="flex w-full truncate">
        <span> hosted by &nbsp;</span>

        <div className="max-w-1/5 flex">
          <FacePile
            users={facepileUsers}
            imageSize="small"
            limit={3}
            showExtraCount={false}
          />
        </div>

        <span>&nbsp;</span>
        <span className="truncate">
          {allNames.map((name, index) => {
            if (index === 0) {
              return name
            }

            if (allNames.length - 1 === index) {
              return ` & ${name}`
            }

            return `, ${name}`
          })}
        </span>
      </div>
    )
  }

  return <div className="flex flex-wrap">{hostsView(hosts)}</div>
}
