export function normalize(html: string) {
  const newHtml = new DOMParser().parseFromString(html, 'text/html')

  const el = newHtml.body
  // combines text nodes into a single string
  el.normalize()
  // remove empty spaces around tags
  const cleanedHtml = el.innerHTML.replace(/>\s+</g, '><')
  return cleanedHtml
}

// This is slightly hacky, but Projects and Concepts are fundamentally structured
// differently than Lessons. Instead of the '.commentable_block' CSS class
// being attached to a node or its parent, it's attached to a sibling of the
// node containing the text and references the parent through `data-id`. So
// in order to get the right ID to use for an anchor, we have to skip the ID
// on the node that contains the text, which is of the form
// "content_project_<project id>_<sequence number>", and use the ID of the parent
// which will be of the expected form "project_<project id>_<sequence number>
export function convertProjectId(id: string) {
  if (/^content_(project|concept)_/.exec(id)) {
    return id.slice(8)
  }
  return id
}

export function findNearestId(selection: Selection | null, maxIterations = 4) {
  const range = selection!.getRangeAt(0)
  const $startN = range.startContainer
  const $endN = range.endContainer

  // Traverse up from the start node, up to 4 levels
  let currentElement = $startN.parentElement
  let iterations = 1
  while (currentElement && iterations <= maxIterations) {
    if (currentElement.id) {
      return convertProjectId(currentElement.id)
    }
    currentElement = currentElement.parentElement
    iterations++
  }

  // If not found, traverse up from the end node, up to maxIterations levels
  currentElement = $endN.parentElement
  iterations = 1
  while (currentElement && iterations <= maxIterations) {
    if (currentElement.id) {
      return convertProjectId(currentElement.id)
    }
    currentElement = currentElement.parentElement
    iterations++
  }

  // If still not found, return null
  return undefined
}

export function sanitizeAnchor(anchor: string) {
  return (anchor || '').replace(/[./%+:]+/g, '').toLowerCase()
}
