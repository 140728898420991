import { useEffect, useMemo, useRef, useState } from 'react'
import Pluralize from 'react-pluralize'
import { Redirect, useHistory } from 'react-router-dom'

import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import { getPercentCompleteBasedOnStartAndEndDate } from 'domains/CohortViewer/utils'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'

import { ErrorMessage } from 'components'
import Loading from 'components/Loading'
import { ShareableSnapshotModal } from 'components/ShareableSnapshotModal'
import NewProgramBanner from 'components/banners/TopBanner/NewProgramBanner'
import FullWidthBlogPostCard from 'components/cards/FullWidthBlogPostCard'
import FullWidthCohortCard from 'components/cards/FullWidthCohortCard'
import FullWidthCourseCard from 'components/cards/FullWidthCourseCard'
import FullWidthProgramCard from 'components/cards/FullWidthProgramCard'
import { Select } from 'components/forms/Select'
import { SkeletonProgramsPage, SkeletonWrapper } from 'components/skeletons'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import {
  CmsProgram,
  CmsProgramFilterPartsFragment,
  CmsProgramListPartsFragment,
  Maybe,
  Season,
  useBookmarkFoldersQuery,
  useProgramIndexQuery
} from 'gql'

import useCanUserEnrollAfterCoreCohortWeeks from 'hooks/enrollment/useCanUserEnrollAfterCoreCohortWeeks'
import { useAssertCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'
import useScrollToTopOnLocationChange from 'hooks/useScrollToTopOnLocationChange'

import { isFirstPilotCourseSession } from 'utils/courseUtils'
import { isAfterDate, isBeforeDate, numDaysBetweenDates } from 'utils/date'
import { onEnterKeyPress } from 'utils/keyboard'
import { getPageFromPath } from 'utils/location'
import { track, trackNavigationClicked } from 'utils/tracking/analytics'

export interface ProgramsProps {
  containerRef: any
  cmsPrograms: CmsProgramListPartsFragment[]
  season?: Maybe<Season>
  lastActiveSeason?: Maybe<Season>
  topicFilter: string
  statusFilter: string
  programCardContentHeight: number | null
  recommendationRanking?: number
  onEnrollClick: (programId: number | string) => void
  currentUserId: string
  isAUserCohortOngoing?: boolean
  canUserFinishedWithCoreCohortWeeksEnroll?: boolean
  isEnrolledInSeasonProgram: boolean
}

const TOPIC_FILTER_SESSION_KEY = 'programs-topic-filter'
const STATUS_FILTER_SESSION_KEY = 'programs-status-filter'
export const TOPIC_FILTERS: string[] = [
  'All',
  'Product',
  'Growth',
  'Engineering',
  'Marketing'
]
export const TOPIC_MAP: { [key: string]: string[] } = {
  Product: [
    'Product Management Foundations',
    'Product Leadership',
    'Product',
    'User Insights',
    'Mastering Product Management',
    'Scaling Product Delivery',
    'Data for Product Managers'
  ],
  Growth: [
    'Growth',
    'Retention',
    'Advanced Growth Strategy',
    'Monetization',
    'Experimentation',
    'Growth for Founders'
  ],
  Engineering: [
    'Technical Strategy',
    'Scaling Product Delivery',
    'Engineering Management'
  ],
  Marketing: ['Marketing', 'Growth Marketing', 'Marketing Strategy']
}
const STATUS_FILTERS: string[] = [
  'Any Status',
  'In Progress',
  'Recommended',
  'Saved',
  'Completed',
  'Coming Soon'
]

const PROGRAM_KINDS = {
  live: 'live',
  onDemand: 'onDemand'
}

const populateFilterCounts = (cmsPrograms: CmsProgramFilterPartsFragment[]) => {
  const filterCounts: { [key: string]: number } = {}
  cmsPrograms.forEach((program) => {
    if (program.progressPercent && ![0, 100].includes(program.progressPercent)) {
      filterCounts['In Progress']
        ? (filterCounts['In Progress'] += 1)
        : (filterCounts['In Progress'] = 1)
    }
    if ([100].includes(program.progressPercent)) {
      filterCounts.Completed
        ? (filterCounts.Completed += 1)
        : (filterCounts.Completed = 1)
    }
    if (program.recommendationRanking) {
      filterCounts.Recommended
        ? (filterCounts.Recommended += 1)
        : (filterCounts.Recommended = 1)
    }
    if (program.contentBookmarkId) {
      filterCounts.Saved ? (filterCounts.Saved += 1) : (filterCounts.Saved = 1)
    }
    if (program.notLaunched) {
      filterCounts['Coming Soon']
        ? (filterCounts['Coming Soon'] += 1)
        : (filterCounts['Coming Soon'] = 1)
    }
  })
  return filterCounts
}

const ProgramsPage = () => {
  const history = useHistory()
  const currentUser = useAssertCurrentUser()
  const [filterCounts, setFilterCounts] = useState<any>({})
  const [programCardContentHeight, setProgramCardContentHeight] = useState(null)
  const [programKindSelected, setProgramKindSelected] = useState(PROGRAM_KINDS.onDemand)
  const [topicFilter, setTopicFilter] = useState<any>(
    window.sessionStorage.getItem(TOPIC_FILTER_SESSION_KEY) || 'All'
  )
  const [statusFilter, setStatusFilter] = useState<any>(
    window.sessionStorage.getItem(STATUS_FILTER_SESSION_KEY) || 'Any Status'
  )
  const [errorMessage, setErrorMessage] = useState('')
  const [enrollmentCardDisplayedTracked, setEnrollmentCardDisplayedTracked] =
    useState(false)
  const { data } = useProgramIndexQuery()

  const isEnrolledInAnyCurrentProgram = !!data?.currentLivePrograms?.length

  useEffect(() => {
    const enrollInProgramId = new URLSearchParams(history.location.search).get(
      'enrollInProgramId'
    )

    if (data && enrollInProgramId) {
      const enrolledInActiveCourse = !!data?.currentUser?.is.enrolledInActiveCourse
      const alreadyEnrolledInProgram = !!data?.currentLivePrograms?.some(
        (userCohort) => userCohort.cohort.cmsProgram.id === enrollInProgramId
      )

      if (enrolledInActiveCourse || isEnrolledInAnyCurrentProgram) {
        setErrorMessage(
          `You are already enrolled in ${
            alreadyEnrolledInProgram ? 'this' : 'another'
          } course`
        )
      } else {
        history.replace({ search: '' })
      }
    }
  }, [history, history.location.search, isEnrolledInAnyCurrentProgram, data])

  const handleSetTopicFilter = (filter: string) => {
    window.sessionStorage.setItem(TOPIC_FILTER_SESSION_KEY, filter)
    setTopicFilter(filter)
  }

  const handleSetStatusFilter = (filter: string) => {
    window.sessionStorage.setItem(STATUS_FILTER_SESSION_KEY, filter)
    setStatusFilter(filter)
  }

  const isInOpenEnrollmentForSeason = (seasonId: string) =>
    data?.enrollmentSeason?.id === seasonId

  const isSmallerTablet = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)

  const isInOpenEnrollment = !!data?.enrollmentSeason

  const isEnrolledInEnrollmentSeasonProgram = !!data?.currentLivePrograms.find(
    (program) => program.cohort.season.id === data?.enrollmentSeason?.id
  )
  const inEnrolledInProgramForSeason = (season: Season | undefined | null) =>
    !!data?.currentLivePrograms.find((program) => program.cohort.season.id === season?.id)

  const showMemberContent = !!currentUser?.is.member
  const containerRef = useRef(null)

  useScrollToTopOnLocationChange()

  useEffect(() => {
    if (!data) return

    setFilterCounts(populateFilterCounts(data?.cmsPrograms))

    const cards = document.getElementsByClassName('full-width-program-card-synopsis')
    const max = Math.max.apply(
      Math,
      Array.from(cards).map((card: HTMLElement) => {
        return card.offsetHeight
      })
    )
    setProgramCardContentHeight(max)

    if (
      isInOpenEnrollment &&
      !isEnrolledInAnyCurrentProgram &&
      showMemberContent &&
      isSmallerTablet
    ) {
      setProgramKindSelected(PROGRAM_KINDS.live)
    } else {
      setProgramKindSelected(PROGRAM_KINDS.onDemand)
    }
  }, [
    data,
    isInOpenEnrollment,
    isEnrolledInAnyCurrentProgram,
    isSmallerTablet,
    showMemberContent
  ])

  const hasBlogPostAnnouncement = (season: Season | null | undefined) => {
    return (
      Boolean(season?.blogPostAnnouncementUrl) &&
      Boolean(season?.showBlogPostAnnouncementAt) &&
      isAfterDate(season?.showBlogPostAnnouncementAt)
    )
  }

  const ongoingSeasonProgramHasStarted = !!data?.upcomingSeason?.hasStarted
  const enrollmentSeasonProgramHasStarted = !!data?.enrollmentSeason?.hasStarted
  const isAUserCohortOngoing = !!data?.currentLivePrograms.some(
    (program) => program?.cohort?.isOngoing
  )

  const currentProgramHasStarted = isInOpenEnrollment
    ? isEnrolledInEnrollmentSeasonProgram && enrollmentSeasonProgramHasStarted
    : ongoingSeasonProgramHasStarted

  const showEnrollmentCard = isInOpenEnrollment && !isEnrolledInEnrollmentSeasonProgram

  const canUserFinishedWithCoreCohortWeeksEnroll = useCanUserEnrollAfterCoreCohortWeeks({
    currentLivePrograms: data?.currentLivePrograms
  })

  const showUpcomingCohort =
    !!data && // this check is to avoid flashing UPCOMING COHORT while loading page
    showMemberContent &&
    !currentProgramHasStarted &&
    currentUser?.can?.enrollInCohort &&
    !isAUserCohortOngoing &&
    canUserFinishedWithCoreCohortWeeksEnroll &&
    (isInOpenEnrollment ||
      isEnrolledInEnrollmentSeasonProgram ||
      hasBlogPostAnnouncement(data?.enrollmentSeason))

  const upcomingLivePrograms =
    data?.currentLivePrograms.filter((program) => !program.cohort.season.hasStarted) || []

  const startedLivePrograms =
    data?.currentLivePrograms.filter((program) => program.cohort.season.hasStarted) || []

  // If we are showing the UPCOMING COHORT section, then any current started live programs need to be shown
  // in PAST LIVE PROGRAMS since MY CURRENT LIVE PROGRAM section will not be shown
  const currentLiveProgramsToDisplayAsPast = showUpcomingCohort ? startedLivePrograms : []
  const userCourses = [...(data?.currentUser?.userCourses?.all || [])].sort((a, b) =>
    isAfterDate(a.courseSession?.startsAt, b.courseSession?.startsAt) ? 1 : -1
  )
  const activeUserCourses = userCourses.filter(
    (userCourse) => !userCourse.courseSession.isPast
  )
  const pastUserCourses = userCourses.filter(
    (userCourse) =>
      userCourse.courseSession.isPast &&
      !isFirstPilotCourseSession(userCourse.courseSession) // filter out courses from pilot 1 since they don't have the right format
  )

  const getParsedDate = (startsAt?: string | null) => {
    if (!startsAt) {
      return 0
    }

    return Date.parse(startsAt)
  }

  const toDisplayAsPastLivePrograms = [
    ...currentLiveProgramsToDisplayAsPast,
    ...(data?.pastLivePrograms || [])
  ].sort((a, b) => getParsedDate(b.cohort?.startsAt) - getParsedDate(a.cohort?.startsAt))

  // only show MY CURRENT LIVE PROGRAM section if we are not showing UPCOMING COHORT section
  const showMyCurrentLiveProgram =
    (!!startedLivePrograms.length && !showUpcomingCohort) || activeUserCourses.length > 0

  const userCanModifyEnrollment = isEnrolledInEnrollmentSeasonProgram

  const trackEnrollmentCardDisplayed = () => {
    if (showEnrollmentCard && !enrollmentCardDisplayedTracked) {
      // @ts-ignore - 'Program Index Enrollment Card Displayed' event is not defined Segment JIRA#REF-5159
      track('Program Index Enrollment Card Displayed', {
        source_flow: 'Program Card on Program Index Page',
        location: getPageFromPath(),
        season_name: `${data?.enrollmentSeason?.year}-${data?.enrollmentSeason?.name}`,
        user_id: currentUser.id
      })

      setEnrollmentCardDisplayedTracked(true)
    }
  }

  const handleClickTopicFilter = (filter: string) => {
    trackNavigationClicked({
      location: 'programs_page',
      type: 'hyperlink text',
      text: filter
    })

    return handleSetTopicFilter(filter)
  }

  if (
    !currentUser.can.viewPrograms ||
    !currentUser.is.paidMember ||
    !currentUser.is.planManager
  ) {
    return <Redirect to="/" />
  }

  const numDaysBetween =
    numDaysBetweenDates(upcomingLivePrograms[0]?.cohort?.startsAt) || 0

  trackEnrollmentCardDisplayed()

  // Show modified content on the programs page to accepted users vs paid members.

  const seasonForPrograms = () => {
    if (!data?.enrollmentSeason) {
      return data?.upcomingSeason
    }

    if (isAUserCohortOngoing || !canUserFinishedWithCoreCohortWeeksEnroll) {
      return data?.upcomingSeason
    }

    return data?.enrollmentSeason
  }

  return (
    <>
      <NewProgramBanner hasBorder />

      <ShareableSnapshotModal page="Programs" />
      <div ref={containerRef} style={{ minHeight: '100vh' }}>
        {errorMessage && <ErrorMessage error={errorMessage} />}
        <div className="flex flex-col justify-between px-4 pt-5 md:flex-row tl:px-7 lg:hidden">
          {showMemberContent && (
            <div className="mb-4 flex h-[42px] flex-row items-center md:mb-0">
              <div
                className={`mr-4 cursor-pointer border-b-2 pb-1 font-medium hover:text-rb-gray-500 sm:mr-6 ${
                  programKindSelected === PROGRAM_KINDS.onDemand
                    ? 'border-rb-gray-500 text-rb-gray-500'
                    : 'border-transparent text-rb-gray-300'
                }`}
                onClick={() => setProgramKindSelected(PROGRAM_KINDS.onDemand)}
                onKeyUp={onEnterKeyPress(() =>
                  setProgramKindSelected(PROGRAM_KINDS.onDemand)
                )}
                role="button"
                tabIndex={0}
              >
                On-Demand
              </div>

              <div
                className={`cursor-pointer border-b-2 pb-1 font-medium hover:text-rb-gray-500 ${
                  programKindSelected === PROGRAM_KINDS.live
                    ? 'border-rb-gray-500 text-rb-gray-500'
                    : 'border-transparent text-rb-gray-300'
                }`}
                onClick={() => setProgramKindSelected(PROGRAM_KINDS.live)}
                onKeyUp={onEnterKeyPress(() =>
                  setProgramKindSelected(PROGRAM_KINDS.live)
                )}
                role="button"
                tabIndex={0}
              >
                Live
              </div>
            </div>
          )}

          {programKindSelected === PROGRAM_KINDS.onDemand && (
            <div className="flex flex-col md:flex-row">
              <div className="mb-4 w-full md:mb-0 md:w-44">
                <Select
                  name="Filter"
                  classNameAdditions="bg-white border"
                  showLabel={false}
                  options={TOPIC_FILTERS.map((c) => ({ id: c, option: c }))}
                  onChange={(newFilter) => handleSetTopicFilter(newFilter.toString())}
                  placeholder={topicFilter}
                  value={topicFilter}
                />
              </div>

              {showMemberContent && (
                <div className="w-full md:ml-2 md:w-44">
                  <Select
                    name="Filter2"
                    classNameAdditions="bg-white border"
                    showLabel={false}
                    options={STATUS_FILTERS.map((c) => ({
                      id: c,
                      option: `${c} ${filterCounts[c] ? ` (${filterCounts[c]})` : ''}`
                    }))}
                    onChange={(newFilter) => handleSetStatusFilter(newFilter.toString())}
                    placeholder={statusFilter}
                    value={statusFilter}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-row pt-5 lg:pt-8">
          <div
            className={`flex-1 flex-col px-4 tl:px-7 2xl:px-10 ${
              programKindSelected === PROGRAM_KINDS.onDemand ? 'flex' : 'hidden lg:flex'
            }`}
          >
            <div className="mb-6 hidden flex-row items-center justify-between lg:flex">
              <div className="flex flex-row items-center">
                {TOPIC_FILTERS.map((filter: string) => (
                  <span
                    key={`topic_${filter}`}
                    className={`mr-4 cursor-pointer border-b-2 pb-1 text-base font-medium sm:mr-6 ${
                      filter === topicFilter
                        ? ' border-rb-black text-rb-black'
                        : 'border-transparent text-rb-gray-300 hover:text-rb-black'
                    }`}
                    onClick={() => handleClickTopicFilter(filter)}
                    onKeyUp={onEnterKeyPress(() => handleClickTopicFilter(filter))}
                    role="button"
                    tabIndex={0}
                  >
                    {filter}
                  </span>
                ))}
              </div>
            </div>
            <SkeletonWrapper loading={!data} skeleton={<SkeletonProgramsPage />}>
              {data?.cmsPrograms && (
                <Programs
                  containerRef={containerRef}
                  cmsPrograms={data?.cmsPrograms}
                  topicFilter={topicFilter}
                  statusFilter={statusFilter}
                  programCardContentHeight={programCardContentHeight}
                  season={seasonForPrograms()}
                  lastActiveSeason={data?.lastActiveSeason}
                  onEnrollClick={() => {}}
                  currentUserId={currentUser.id}
                  isAUserCohortOngoing={isAUserCohortOngoing}
                  canUserFinishedWithCoreCohortWeeksEnroll={
                    canUserFinishedWithCoreCohortWeeksEnroll
                  }
                  isEnrolledInSeasonProgram={inEnrolledInProgramForSeason(
                    seasonForPrograms()
                  )}
                />
              )}
            </SkeletonWrapper>
          </div>
          <div
            className={`w-full flex-col px-4 tl:px-7 lg:w-2/5 lg:pr-6 lg:pl-0 xl:w-2/6 2xl:px-10 ${
              programKindSelected === PROGRAM_KINDS.live ? 'flex' : 'hidden lg:flex'
            }`}
          >
            {showMyCurrentLiveProgram && (
              <div data-test="my-current-live-program" className="mb-3">
                <div className="mb-5 font-medium tracking-widest text-rb-gray-400">
                  MY CURRENT LIVE COURSE
                </div>

                {activeUserCourses.map((userCourse) => (
                  <div className="mb-5" key={userCourse.id}>
                    <FullWidthCourseCard
                      pageLocation="programs_my_current_live_course"
                      showBetaBadge
                      showStartDate
                      showDuration
                      courseSession={userCourse.courseSession}
                    />
                  </div>
                ))}

                {startedLivePrograms.map((liveProgram) => {
                  const progressPercent = getPercentCompleteBasedOnStartAndEndDate(
                    liveProgram.cohort.startsAt,
                    liveProgram.cohort.endsAt
                  )
                  return (
                    <div key={liveProgram.id} className="mb-5">
                      <FullWidthCohortCard
                        nextModuleHref={liveProgram.cohort.thisWeek?.nextModule?.href}
                        eventCount={liveProgram.cohort.eventCount}
                        weekCount={liveProgram.cohort.weekCount}
                        startsAt={liveProgram.cohort.startsAt}
                        endsAt={liveProgram.cohort.endsAt}
                        seasonName={liveProgram.cohort.season.prettyNameSeasonFirst}
                        programName={liveProgram.cohort.cmsProgram.name}
                        lessonCount={liveProgram.cohort.cmsProgram.lessonCount}
                        numBookmarks={liveProgram.cohort.cmsProgram.numBookmarks || 0}
                        progressPercent={progressPercent}
                        programIconImageUrl={
                          liveProgram.cohort.cmsProgram.iconImageUrl || ''
                        }
                        cohortViewerHref={`/cohorts/${liveProgram.cohort.slug}`}
                        onModifyClick={() => {}}
                        openForEnrollment={isInOpenEnrollmentForSeason(
                          liveProgram.cohort.season.id
                        )}
                        isLive
                        showMaterialIcons
                      />
                    </div>
                  )
                })}
              </div>
            )}

            {showUpcomingCohort && isEnrolledInEnrollmentSeasonProgram && (
              <div data-test="upcoming-cohort" className="mb-3">
                <div className="mb-5 flex items-center justify-between">
                  <div className="text-lg font-medium tracking-normal text-rb-gray-400 lg:text-xl">
                    Upcoming Course
                  </div>
                  {isEnrolledInEnrollmentSeasonProgram && (
                    <div className="upper text-xs font-medium tracking-widest text-rb-gray-400">
                      BEGINS IN{' '}
                      <Pluralize singular="DAY" plural="DAYS" count={numDaysBetween} />
                    </div>
                  )}
                </div>
                <div className="mb-5">
                  {!isInOpenEnrollment &&
                    hasBlogPostAnnouncement(data?.upcomingSeason) &&
                    !isEnrolledInAnyCurrentProgram && (
                      <FullWidthBlogPostCard
                        seasonName={data?.upcomingSeason?.prettyNameSeasonFirst || ''}
                        startDate={data?.upcomingSeason?.startsAt || ''}
                        linkAction={() =>
                          window.open(
                            `${data?.upcomingSeason?.blogPostAnnouncementUrl}`,
                            '_blank'
                          )
                        }
                        timezone={currentUser.timezone}
                      />
                    )}
                  {!!upcomingLivePrograms?.length &&
                    isInOpenEnrollment &&
                    !currentProgramHasStarted &&
                    isEnrolledInEnrollmentSeasonProgram && (
                      <>
                        {upcomingLivePrograms.map((liveProgram) => {
                          const progressPercent =
                            getPercentCompleteBasedOnStartAndEndDate(
                              liveProgram.cohort.startsAt,
                              liveProgram.cohort.endsAt
                            )
                          return (
                            <div key={liveProgram.id} className="mb-5">
                              <FullWidthCohortCard
                                nextModuleHref={
                                  liveProgram.cohort.thisWeek?.nextModule?.href
                                }
                                eventCount={liveProgram.cohort.eventCount}
                                weekCount={liveProgram.cohort.weekCount}
                                startsAt={liveProgram.cohort.startsAt}
                                endsAt={liveProgram.cohort.endsAt}
                                seasonName={
                                  liveProgram.cohort.season.prettyNameSeasonFirst
                                }
                                programName={liveProgram.cohort.cmsProgram.name}
                                lessonCount={liveProgram.cohort.cmsProgram.lessonCount}
                                numBookmarks={
                                  liveProgram.cohort.cmsProgram.numBookmarks || 0
                                }
                                progressPercent={progressPercent}
                                programIconImageUrl={
                                  liveProgram.cohort.cmsProgram.iconImageUrl || ''
                                }
                                cohortViewerHref={`/cohorts/${liveProgram.cohort.slug}`}
                                onModifyClick={() => {}}
                                openForEnrollment={userCanModifyEnrollment}
                                showMaterialIcons
                              />
                            </div>
                          )
                        })}
                      </>
                    )}
                </div>
              </div>
            )}

            {(toDisplayAsPastLivePrograms.length > 0 || pastUserCourses.length > 0) && (
              <div data-test="programs-page-previous-cohorts-section">
                <div className="mb-5 text-lg font-medium tracking-normal text-rb-gray-400 lg:text-xl">
                  Past Live Courses
                </div>

                {pastUserCourses.map((course) => (
                  <div key={course.id} className="mb-5">
                    <FullWidthCourseCard
                      pageLocation="programs_past_live_courses"
                      showBetaBadge
                      showStartDate
                      showDuration
                      courseSession={course.courseSession}
                    />
                  </div>
                ))}

                {toDisplayAsPastLivePrograms.map((liveProgram) => (
                  <div key={liveProgram.id} className="mb-5">
                    <FullWidthCohortCard
                      eventCount={liveProgram.cohort.eventCount}
                      weekCount={liveProgram.cohort.weekCount}
                      startsAt={liveProgram.cohort.startsAt}
                      endsAt={liveProgram.cohort.endsAt}
                      seasonName={liveProgram.cohort.season.prettyNameSeasonFirst}
                      programName={liveProgram.cohort.cmsProgram.name}
                      lessonCount={liveProgram.cohort.cmsProgram.lessonCount}
                      numBookmarks={liveProgram.cohort.cmsProgram.numBookmarks || 0}
                      progressPercent={liveProgram.cohort.cmsProgram.progressPercent}
                      programIconImageUrl={
                        liveProgram.cohort.cmsProgram.iconImageUrl || ''
                      }
                      cohortViewerHref={`/cohorts/${liveProgram.cohort.slug}`}
                      onModifyClick={() => {}}
                      openForEnrollment={false}
                      showMaterialIcons
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const Programs = ({
  containerRef,
  cmsPrograms,
  topicFilter,
  statusFilter,
  programCardContentHeight,
  season,
  lastActiveSeason,
  onEnrollClick,
  currentUserId,
  isAUserCohortOngoing,
  canUserFinishedWithCoreCohortWeeksEnroll,
  isEnrolledInSeasonProgram
}: ProgramsProps) => {
  const filteredPrograms = useMemo(
    () =>
      cmsPrograms
        ?.slice()
        .sort((a) => (a.contentBookmarkId ? -1 : 1))
        .sort((a) => (a.notLaunched ? -1 : 1))
        .sort((a) => (a.recommendationRanking ? -1 : 1))
        .sort((a, b) => b.progressPercent - a.progressPercent)
        .filter((program) => {
          if (topicFilter === 'All' && statusFilter === 'Any Status') return true
          if (
            topicFilter !== 'All' &&
            !TOPIC_MAP[topicFilter].includes(program.topic || '')
          ) {
            return false
          }
          if (statusFilter !== 'Any Status') {
            if (
              statusFilter === 'In Progress' &&
              (!program.progressPercent || [0, 100].includes(program.progressPercent))
            ) {
              return false
            }
            if (
              statusFilter === 'Completed' &&
              ![100].includes(program.progressPercent)
            ) {
              return false
            }
            if (statusFilter === 'Recommended' && !program.recommendationRanking) {
              return false
            }
            if (statusFilter === 'Saved' && !program.contentBookmarkId) {
              return false
            }
            if (statusFilter === 'Coming Soon' && !program.notLaunched) {
              return false
            }
          }
          return true
        }),
    [cmsPrograms, statusFilter, topicFilter]
  )
  const {
    isAddToBookmarkFolderModalOpen,
    openAddToBookmarkFolderModal,
    closeAddToBookmarkFolderModal,
    currentBookmarkForDropdown
  } = useOpenAddToBookmarkFolderModal()
  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    closeCreateBookmarkFolderModal,
    openCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()
  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }

  const { data: bookmarkFolderData } = useBookmarkFoldersQuery()

  if (!cmsPrograms) return <Loading />

  const isProgramShown = (program: CmsProgram) => {
    // Not deprecated
    if (!program.deprecatedAt) return true

    // Program has not been deprecated
    if (isBeforeDate(program.deprecatedAt)) return true

    // If the user has started the deprecated program, continue to show it
    return program.progressPercent > 0
  }

  return (
    <div>
      {filteredPrograms.filter(isProgramShown).map((cmsProgram) => (
        <div
          data-test={`program-${cmsProgram.code}`}
          key={`prog${cmsProgram.id}`}
          className="pb-5"
        >
          <FullWidthProgramCard
            containerRef={containerRef}
            season={season}
            lastActiveSeason={lastActiveSeason}
            program={cmsProgram}
            contentHeight={programCardContentHeight}
            onEnrollClick={onEnrollClick}
            currentUserId={currentUserId}
            isAUserCohortOngoing={isAUserCohortOngoing}
            canUserFinishedWithCoreCohortWeeksEnroll={
              canUserFinishedWithCoreCohortWeeksEnroll
            }
            isEnrolledInSeasonProgram={isEnrolledInSeasonProgram}
            openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
          />
        </div>
      ))}
      <AddBookmarkToFolderModal
        isOpen={isAddToBookmarkFolderModalOpen}
        handleClose={closeAddToBookmarkFolderModal}
        bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders}
        openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdown}
        showCollectionsOnboardingInfo={
          !bookmarkFolderData?.currentUser?.hasSeenCollectionsPrompt
        }
      />
      <CreateBookmarkFolderModal
        isModalOpen={isCreateBookmarkFolderModalOpen}
        handleClose={closeCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
        trackingTriggerAction={'bookmark'}
      />
    </div>
  )
}

export default ProgramsPage
