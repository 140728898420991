import { Redirect, matchPath, useLocation } from 'react-router-dom'

import { Loading } from 'components'

import { useCoursesPageCurrentUserQuery } from 'gql'

import { isFirstPilotCourseSession } from 'utils/courseUtils'

import { COURSE_FILTER_LIVE, useCoursesFilters } from './CoursesFilters'
import LiveCourses from './LiveCourses'
import MarketplaceCoursesSection from './MarketplaceCoursesSection'

const CoursesPageSections = () => {
  const { data, loading } = useCoursesPageCurrentUserQuery()

  const user = data?.currentUser

  if (!user || loading) {
    return <Loading />
  }

  const userCourses =
    user.userCourses?.all?.filter(
      (userCourse) => !isFirstPilotCourseSession(userCourse.courseSession)
    ) || []

  const userCohorts = data.userCohorts

  const hasUserCourses = !!userCourses.length
  const hasUserCohorts = !!userCohorts.length
  const hasLiveCourses = hasUserCourses || hasUserCohorts

  return (
    <div className="flex flex-row md:space-x-12">
      <div className="w-full">
        <MarketplaceCoursesSection user={user} />
      </div>
      {hasLiveCourses && <LiveCourses courses={userCourses} cohorts={userCohorts} />}
    </div>
  )
}

export const OldCoursesIndexPage = () => {
  const { pathname } = useLocation()
  useCoursesFilters()

  if (matchPath(pathname, { path: '/courses', exact: true })) {
    return <Redirect to={`/courses/${COURSE_FILTER_LIVE}`} />
  }

  return (
    <div
      className="flex w-full min-w-[300px] max-w-[1280px] flex-col justify-start pb-32"
      data-test="courses-index-page"
      id="courses"
    >
      <CoursesPageSections />
    </div>
  )
}

export default OldCoursesIndexPage
