import clsx from 'clsx'
import { format } from 'date-fns'
import { useReducer, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import UIkit from 'uikit'

import AddPaymentMethodModal from 'domains/User/AddPaymentMethodModal'
import DeletePaymentMethodModal from 'domains/User/DeletePaymentMethodModal'
import DeletePaymentSourceModal, {
  Props as DeletePaymentSourceModalProps
} from 'domains/User/DeletePaymentSourceModal'
import EditPaymentMethodModal from 'domains/User/EditPaymentMethodModal'

import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import { SVGIcon } from 'components/Icon'
import Loading from 'components/Loading'

import {
  StripeAchCreditTransfer,
  StripeCollectionMethod,
  StripePaymentMethod,
  StripePaymentSources,
  StripeSource,
  useUserPaymentMethodsQuery
} from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import { capitalizeFirstLetter } from 'utils/stringUtils'
import { trackCtaClicked } from 'utils/tracking/analytics'
import { CtaClicked } from 'utils/tracking/generated/types'

type AchCreditTransfer = Omit<StripeSource, 'achCreditTransfer'> & {
  achCreditTransfer: StripeAchCreditTransfer
}

// TODO: Move into common file if needed elsewhere
const isAchCreditTransfer = (
  source: StripePaymentSources | AchCreditTransfer
): source is AchCreditTransfer =>
  source.__typename === 'StripeSource' && source.type === 'ach_credit_transfer'

const PaymentMethodsContainer = () => {
  const { id } = useAssertCurrentUser()
  const { loading, error, data, refetch } = useUserPaymentMethodsQuery({
    variables: { userId: id }
  })

  if (loading) return <Loading />
  if (error) return <ErrorMessage error={error} />

  const isExpired = data?.currentUser?.accessPolicyKind === 'expired'

  if (!data?.currentUser?.subscriptions?.active && !isExpired) return null

  const userPaymentMethods = data.userPaymentMethods || []
  const userPaymentSources = data.userPaymentSources || []
  const active = data?.currentUser?.subscriptions?.active
  const lastExpired = data?.currentUser?.subscriptions?.lastExpired
  const userIsOwner = active?.id ? active?.userIsOwner : lastExpired?.userIsOwner
  const isManualPayment =
    active?.stripeCollectionMethod === StripeCollectionMethod.SEND_INVOICE

  return (
    <PaymentMethods
      paymentMethods={userPaymentMethods}
      paymentSources={userPaymentSources}
      refetchPaymentMethods={refetch}
      userIsOwner={!!userIsOwner}
      isManualPayment={isManualPayment}
    />
  )
}

enum ModalType {
  ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD',
  DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD',
  DELETE_PAYMENT_SOURCE = 'DELETE_PAYMENT_SOURCE',
  EDIT_PAYMENT_METHOD = 'EDIT_PAYMENT_METHOD'
}

type ModalPropsUnion = Partial<DeletePaymentSourceModalProps>

type State = {
  modal: ModalType | null
  modalProps: ModalPropsUnion
}
const initialState = {
  modal: null,
  modalProps: {}
}

type Props = {
  paymentMethods: StripePaymentMethod[]
  paymentSources: StripePaymentSources[]
  refetchPaymentMethods: any
  userIsOwner: boolean
  isManualPayment: boolean
}

export const PaymentMethods = ({
  paymentMethods,
  paymentSources,
  refetchPaymentMethods,
  userIsOwner,
  isManualPayment
}: Props) => {
  const [isOpenAddPaymentMethodModal, setIsOpenAddPaymentMethodModal] = useState(false)

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<StripePaymentMethod | null>(null)

  const [state, setState] = useReducer(
    (state: State, newState: Partial<State>) => ({ ...state, ...newState }),
    initialState
  )

  const showDeletePaymentMethodModal = (paymentMethod: StripePaymentMethod) => {
    setSelectedPaymentMethod(paymentMethod)
    const modal = document.getElementById('modal-delete-payment-method')
    if (modal) {
      UIkit.modal(modal).show()
    }
  }

  const showEditPaymentMethodModal = (paymentMethod: StripePaymentMethod) => {
    setSelectedPaymentMethod(paymentMethod)

    const modal = document.getElementById('modal-edit-payment-method')
    if (modal) {
      UIkit.modal(modal).show()
    }
  }

  const showDeletePaymentSourceModal = (paymentSource: StripePaymentSources) => {
    setState({
      modal: ModalType.DELETE_PAYMENT_SOURCE,
      modalProps: { paymentSource }
    })
  }

  const handleClickAddPaymentMethodButton = (type: CtaClicked['cta_type']) => {
    trackCtaClicked({
      cta_location: 'payment methods section',
      cta_type: type,
      text: 'add a payment method'
    })

    return setIsOpenAddPaymentMethodModal(true)
  }

  const Card = ({ children }: { children: any }) => {
    return <div className="rf-rb-card mb-5 p-6">{children}</div>
  }

  const CardHeader = ({ children }: { children: any }) => {
    return (
      <h3 className="text-card-primary-title-color text-xl font-medium">{children}</h3>
    )
  }

  const TableHeader = ({
    children,
    className
  }: {
    children?: any
    className?: String
  }) => {
    return (
      <th
        className={twMerge(
          clsx(
            'font-md text-neutral-dark-3 py-1 px-2 text-left align-bottom text-xs uppercase tracking-widest md:py-2 md:px-3',
            className
          )
        )}
      >
        {children}
      </th>
    )
  }

  const TableData = ({ children, className }: { children?: any; className?: String }) => {
    return (
      <td className={twMerge(clsx('py-1 px-2 align-middle md:py-2 md:px-3', className))}>
        {children}
      </td>
    )
  }

  const IconButton = ({
    children,
    onClick,
    ariaLabel
  }: {
    children: any
    onClick: any
    ariaLabel: any
  }) => {
    return (
      <button aria-label={ariaLabel} type="button" onClick={onClick}>
        {children}
      </button>
    )
  }

  const TeamMemberPaymentMethodsCard = () => {
    return (
      <Card>
        <CardHeader>Payment Methods</CardHeader>
        <div>
          Somebody on your team is already paying for Reforge. For details, talk to your
          subscription owner.
        </div>
      </Card>
    )
  }

  const DisplayPaymentMethods = () => {
    if (
      (paymentMethods && paymentMethods.length) ||
      (paymentSources && paymentSources.length)
    ) {
      return (
        <Card>
          <CardHeader>Payment Methods</CardHeader>
          {isManualPayment && <p>You are billed via invoice.</p>}
          <table className="my-5 w-full table-auto text-sm">
            <thead>
              <tr>
                <TableHeader>Type</TableHeader>
                <TableHeader>Brand</TableHeader>
                <TableHeader>Last&nbsp;4</TableHeader>
                <TableHeader>Expires</TableHeader>
                <TableHeader className="hidden md:inline-block">Status</TableHeader>
              </tr>
            </thead>
            <tbody className="divide-y divide-rb-gray-100 border-t-2 border-rb-gray-100">
              {paymentMethods.map((paymentMethod) => {
                const { id, type, defaultPaymentMethod, card } = paymentMethod

                const expDate =
                  card?.expYear &&
                  card?.expMonth &&
                  format(new Date(card?.expYear, card?.expMonth - 1), 'LL/yyyy')

                return (
                  <tr
                    className="odd:border-y odd:bg-rb-gray-50"
                    key={`paymentMethod${id}`}
                  >
                    <TableData>{capitalizeFirstLetter(type || '')}</TableData>
                    <TableData>{capitalizeFirstLetter(card?.brand || '')}</TableData>
                    <TableData>x{card?.last4}</TableData>
                    <TableData>
                      <div className="flex items-center">
                        <div className="pr-1 md:hidden ">
                          {card?.status === 'Active' && (
                            <SVGIcon name="dot" width="8" height="8" fill="#00C361" />
                          )}
                          {card?.status === 'Expired' && (
                            <SVGIcon name="dot" width="8" height="8" fill="#F20606" />
                          )}
                        </div>
                        <div className="visible md:hidden">
                          {`${expDate?.toString().substring(0, 3)}${expDate
                            ?.toString()
                            .substring(5)}`}
                        </div>
                        <div className="hidden md:inline-block">{expDate}</div>
                      </div>
                    </TableData>
                    <TableData>
                      <div className="hidden items-center md:flex">
                        {paymentMethod.card?.status === 'Active' && (
                          <>
                            <SVGIcon name="dot" width="8" height="8" fill="#00C361" />
                            <span>&nbsp;Active</span>
                          </>
                        )}
                        {paymentMethod.card?.status === 'Expired' && (
                          <>
                            <SVGIcon name="dot" width="8" height="8" fill="#F20606" />
                            <span>&nbsp;Expired</span>
                          </>
                        )}
                      </div>
                    </TableData>
                    <TableData>
                      {defaultPaymentMethod && (
                        <span className="hidden h-4 rounded-lg bg-rb-gray-100 py-0 px-1.5 text-xs uppercase leading-4 tracking-widest text-white md:table-cell">
                          Primary
                        </span>
                      )}
                    </TableData>
                    <TableData className="align-right hidden items-center md:table-cell">
                      <IconButton
                        ariaLabel="edit"
                        onClick={() => {
                          showEditPaymentMethodModal(paymentMethod)
                        }}
                      >
                        <SVGIcon
                          className="mr-1 cursor-pointer"
                          name="pencil"
                          width="16"
                          height="16"
                          fill="#A2A1A2"
                          focusable="false"
                        />
                      </IconButton>

                      {!defaultPaymentMethod && (
                        <IconButton
                          ariaLabel="delete"
                          onClick={() => {
                            showDeletePaymentMethodModal(paymentMethod)
                          }}
                        >
                          <SVGIcon
                            className="cursor-pointer"
                            name="trash"
                            width="16"
                            height="16"
                            fill="#A2A1A2"
                            focusable="false"
                          />
                        </IconButton>
                      )}

                      {defaultPaymentMethod && <span className="inline-block w-5"></span>}
                    </TableData>
                  </tr>
                )
              })}
              {paymentSources.map((source) => {
                if (source && isAchCreditTransfer(source)) {
                  const {
                    id,
                    achCreditTransfer: { accountNumber, bankName },
                    status
                  } = source

                  return (
                    <tr key={`paymentSource-${id}`}>
                      <TableData>ACH</TableData>
                      <TableData>{bankName}</TableData>
                      <TableData>{`x${accountNumber.slice(-4)}`}</TableData>
                      <TableData></TableData>
                      <TableData>
                        <div className="flex hidden items-center md:flex">
                          {(status === 'chargeable' || status === 'pending') && (
                            <>
                              <SVGIcon name="dot" width="8" height="8" fill="#00C361" />
                              <span>&nbsp;Active</span>
                            </>
                          )}
                          {!(status === 'chargeable' || status === 'pending') && (
                            <>
                              <SVGIcon name="dot" width="8" height="8" fill="#F20606" />
                              <span>&nbsp;Expired</span>
                            </>
                          )}
                        </div>
                      </TableData>
                      <TableData></TableData>
                      <TableData className="hidden items-center text-right md:flex">
                        <IconButton
                          ariaLabel="delete"
                          onClick={() => {
                            showDeletePaymentSourceModal(source)
                          }}
                        >
                          <SVGIcon
                            className="cursor-pointer"
                            name="trash"
                            width="16"
                            height="16"
                            fill="#A2A1A2"
                            focusable="false"
                          />
                        </IconButton>
                      </TableData>
                    </tr>
                  )
                }
                return null
              })}
            </tbody>
          </table>
          <a
            onClick={() => handleClickAddPaymentMethodButton('hyperlink text')}
            className="flex items-center text-xs font-medium uppercase tracking-widest text-inherit text-rb-gray-500 hover:text-rb-gray-500"
          >
            <SVGIcon
              name="plus-in-square"
              fill="#000000"
              className="mr-1.5 ml-2.5 stroke-current"
            />
            Add a payment method
          </a>
        </Card>
      )
    } else {
      return (
        <>
          <div className="mb-5 bg-rb-gray-50 p-2.5 text-center tl:px-36 tl:py-10">
            <p className="pt-4 text-base sm:pt-0 sm:text-lg">
              Add a debit or credit card to quickly and easily make payments on Reforge.
              <br />
              <Button
                size="small"
                variant="outline"
                className="mt-8 inline-block normal-case"
                onClick={() => handleClickAddPaymentMethodButton('button')}
              >
                Add a Payment Method
              </Button>
            </p>
          </div>
        </>
      )
    }
  }

  return (
    <div>
      <div>
        {userIsOwner ? <DisplayPaymentMethods /> : <TeamMemberPaymentMethodsCard />}
      </div>

      <AddPaymentMethodModal
        isOpen={isOpenAddPaymentMethodModal}
        setIsOpen={setIsOpenAddPaymentMethodModal}
        onComplete={refetchPaymentMethods}
      />

      <DeletePaymentMethodModal
        paymentMethod={selectedPaymentMethod}
        afterDelete={refetchPaymentMethods}
      />

      <DeletePaymentSourceModal
        afterDelete={refetchPaymentMethods}
        isOpen={state.modal === ModalType.DELETE_PAYMENT_SOURCE}
        onClose={() => setState({ modal: null, modalProps: {} })}
        paymentSource={state.modalProps.paymentSource as StripePaymentSources}
      />

      <EditPaymentMethodModal
        setIsOpenAddPaymentMethodModal={setIsOpenAddPaymentMethodModal}
        paymentMethod={selectedPaymentMethod}
        afterUpdate={refetchPaymentMethods}
      />
    </div>
  )
}

export default PaymentMethodsContainer
