import { RefObject, useEffect, useState } from 'react'

interface IConsumeProgress {
  ref?: RefObject<HTMLElement>
  id?: string
  scrollableId: string
}

const useConsumeProgress = ({ ref, id, scrollableId }: IConsumeProgress) => {
  const [consumeProgress, setConsumeProgress] = useState(0)

  useEffect(() => {
    const scrollable = document.getElementById(scrollableId)
    if (!scrollable) {
      console.error(
        `ConsumeProgress could not find scrollable element with id ${scrollableId}`
      )
      return
    }

    function updateOnScroll() {
      if (!scrollable) return

      let contentElement
      if (id) {
        contentElement = document.getElementById(id)
      } else if (ref) {
        contentElement = ref.current
      }
      if (!contentElement) return

      const contentHeight =
        contentElement.clientHeight - contentElement.offsetTop - window.innerHeight
      const scrollableScrollTop = scrollable.scrollTop || 0

      if (scrollableScrollTop === 0) return

      if (scrollableScrollTop > contentHeight) {
        return setConsumeProgress(100)
      }

      const contentPercent = Math.ceil((scrollableScrollTop / contentHeight) * 100)
      setConsumeProgress(contentPercent)
    }

    scrollable.addEventListener('scroll', updateOnScroll)
    return () => {
      scrollable.removeEventListener('scroll', updateOnScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return consumeProgress
}

export default useConsumeProgress
