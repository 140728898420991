import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { PageProvider } from 'components/PageHeader/usePage'

import { PageSeo, PageTypeEnum } from 'gql'

import ArtifactsIndexPage from './ArtifactsIndexPage/ArtifactsIndexPage'

const LoggedOutArtifactsIndexPage = () => {
  const page: GenericPageType = {
    title: 'Explore Artifacts on Reforge',
    seo: {
      metaTitle: 'Explore Artifacts on Reforge',
      metaDesc:
        "With Reforge Artifacts, you'll never start from scratch again. Get access to real work from the best operators in tech and accelerate your career.",
      type: 'website' as PageTypeEnum,
      shareTitle: 'Explore Artifacts on Reforge',
      shareDesc:
        "With Reforge Artifacts, you'll never start from scratch again. Get access to real work from the best operators in tech and accelerate your career.",
      shareGraphic: {
        asset:
          'https://cdn.sanity.io/images/pghoxh0e/production/5baa30e446b6d84d02462e68d9a5b5fd31e8eae4-1200x630.jpg?w=1200&h=630'
      },
      shareGraphicAlt: 'Explore Artifacts on Reforge'
    } as PageSeo
  }

  return (
    <MarketingLayout page={page} scrollToTop={false}>
      <PageProvider
        showHeader
        headerWrapperClassName="px-4 md:px-[4vw] pt-4 max-w-7xl mx-auto"
      >
        <div className="pb-8 max-w-7xl mx-auto">
          <ArtifactsIndexPage />
        </div>
      </PageProvider>
    </MarketingLayout>
  )
}

export default LoggedOutArtifactsIndexPage
