import { PropsWithChildren } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useRouteMatch } from 'react-router-dom'

import LeftSidebar, { LeftSidebarProvider } from 'components/LeftSidebar'

import { cn } from 'utils/tailwind'

import { ReactComponent as ChevronLeft } from 'images/chevron-left.svg'

import { OptInMonsterGlobalCampaign } from './OptInMonsterGlobalCampaign'
import { PageProvider } from './PageHeader/usePage'
import TopNavBar from './TopNavBar'
import TopBanner from './banners/TopBanner/TopBanner'

const Layout = ({
  pageTitle,
  children,
  showHeader = true,
  showSideBar = true,
  headerDataTest
}: PropsWithChildren<{
  pageTitle?: string
  showHeader?: boolean
  showSideBar?: boolean
  headerDataTest?: string
}>) => {
  const isProfilePage = useRouteMatch('/profiles/:slug')

  const getTabTitle = (pageTitle?: string) =>
    pageTitle ? `Reforge - ${pageTitle}` : 'Reforge'
  const getPageTitle = (pageTitle?: string) => {
    if (pageTitle === 'Manage Plan') {
      return (
        <Link
          to="/billing"
          className="text-xl text-rb-black hover:text-rb-black hover:no-underline"
        >
          <ChevronLeft className="mb-1 mr-4 inline-block w-2.5 fill-current" />
          Back to Billing
        </Link>
      )
    }
    return pageTitle
  }

  return (
    <>
      <Helmet>
        <title>{getTabTitle(pageTitle)}</title>
      </Helmet>
      <div className="h-screen w-screen">
        <LeftSidebarProvider>
          {showHeader && <TopBanner />}

          <div className="relative">
            {showHeader && <TopNavBar />}
            <div
              className={cn(
                'flex w-full',
                showHeader ? 'h-[calc(100vh-56px)] lg:h-[calc(100vh-72px)]' : 'h-full'
              )}
            >
              {showSideBar && <LeftSidebar dataTest="left-sidebar" />}

              <div className={cn('w-full', showSideBar && 'lg:pl-[88px] 2xl:pl-0')}>
                <main
                  id="page"
                  className={cn(
                    'h-full flex-1 overflow-y-auto overflow-x-hidden scroll-smooth',
                    isProfilePage && 'md:overflow-y-hidden'
                  )}
                >
                  <div
                    id="page-content"
                    className={cn(
                      showSideBar && 'mx-auto w-full max-w-[1280px] p-4 md:p-6 lg:p-8'
                    )}
                  >
                    <PageProvider
                      showHeader={showHeader}
                      pageTitle={getPageTitle(pageTitle)}
                      dataTest={headerDataTest}
                    >
                      {children}
                    </PageProvider>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </LeftSidebarProvider>
      </div>
      <OptInMonsterGlobalCampaign />
    </>
  )
}

export default Layout
