import Pluralize from 'react-pluralize'
import { useHistory } from 'react-router-dom'

import { CohortResourcesProps } from 'domains/CohortDashboard/CohortDashboardProgram/CohortDashboardWeeklyBlock/CohortResources'
import { useCohortDashboardTrackingContext } from 'domains/CohortDashboard/hooks/useCohortDashboardTrackingContext'

import Modal from 'components/Modal'
import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import { CohortCmsModulePartsFragment, CohortDashboardCmsSectionPartsFragment } from 'gql'

import useElementSize from 'hooks/useElementSize'

import { onEnterKeyPress } from 'utils/keyboard'
import { trackCtaClicked } from 'utils/tracking/analytics'

import { ReactComponent as TemplatesIcon } from 'images/icon--cohort-template.svg'

export interface TemplatesModalProps extends CohortResourcesProps {
  isOpen: boolean
  onClose: () => void
  templates: CohortCmsModulePartsFragment
}

const TemplatesModal = ({
  isOpen,
  onClose,
  templates,
  programName,
  cohortSlug
}: TemplatesModalProps) => {
  const templateLessons = templates.cmsSections.filter(
    (cmsSection) => cmsSection.contentType === 'Lesson'
  )

  return (
    <Modal
      isOpen={isOpen}
      handleClose={onClose}
      fullWidth={true}
      className="overflow-hidden rounded-xl sm:mx-[40px] md:max-w-[689px]"
    >
      <div className="overflow-auto p-12 pt-0">
        <RfHeader3SemiBold className="!mb-2">
          <Pluralize singular="Template" count={templateLessons.length} showCount />
        </RfHeader3SemiBold>
        <RfParagraphMedium className="mb-12">
          All the templates used in {programName}.
        </RfParagraphMedium>
        {templateLessons.length > 0 && (
          <div className="space-y-6">
            {templateLessons.map((lesson) => (
              <TemplateItem
                key={`template_${lesson.id}`}
                lesson={lesson}
                cohortSlug={cohortSlug}
              />
            ))}
          </div>
        )}
      </div>
    </Modal>
  )
}

interface TemplateItemProps {
  lesson: CohortDashboardCmsSectionPartsFragment
  cohortSlug: string
}

const TemplateItem = ({ lesson, cohortSlug }: TemplateItemProps) => {
  const [ref, { width }] = useElementSize()
  const history = useHistory()

  const { cohort, userCohortId } = useCohortDashboardTrackingContext()

  const handleClick = (lesson: CohortDashboardCmsSectionPartsFragment) => {
    trackCtaClicked({
      cta_type: 'card',
      cta_location: 'cohort_dashboard_template_modal_template_card',
      text: lesson.name,
      related_identifiers: {
        cohort_id: cohort.id,
        cohort_name: cohort.name,
        user_cohort_id: userCohortId
      }
    })

    history.push(`/cohorts/${cohortSlug}${lesson.href}`)
  }

  return (
    <div
      className="mt-4 flex h-16 w-full items-center justify-between rounded-xl border border-rb-gray-100 px-[20px] hover:border-rb-gray-500"
      role="button"
      onClick={() => handleClick(lesson)}
      tabIndex={0}
      onKeyUp={onEnterKeyPress(() => handleClick(lesson))}
      ref={ref}
    >
      <div className="flex">
        <TemplatesIcon className="mr-[20px] w-6" />
        <span
          className="overflow-hidden text-ellipsis whitespace-nowrap"
          style={{ width: width - 86 }}
        >
          {lesson.name}
        </span>
      </div>
    </div>
  )
}

export default TemplatesModal
