import { Link } from 'react-router-dom'

import Image from 'domains/Sanity/Image'
import { ComplexPortableText } from 'domains/Sanity/PortableText/ComplexPortableText/ComplexPortableText'

import Button from 'components/Button'

import { CourseForTopicAndFunction } from 'gql'

import { trackNavigationClicked } from 'utils/tracking/analytics'

export interface CourseSeoLargeCardProps {
  course: CourseForTopicAndFunction
}
const onNavigationClick = (course: CourseForTopicAndFunction) => {
  trackNavigationClicked({
    type: 'card',
    text: course.title.toLocaleLowerCase(),
    location: 'SEO most popular courses list'
  })
}

export const CourseSeoLargeCard = ({ course }: CourseSeoLargeCardProps) => {
  const creatorsLength = course.creators?.length || 0

  return (
    <Link
      to={`/courses/${course.slug}`}
      className="mb-8 flex flex-col rounded-xl border border-rb-gray-100 p-4 hover:no-underline hover:shadow-seo-page-card md:flex-row md:p-6"
      onClick={() => onNavigationClick(course)}
    >
      <div className="mb-4 flex h-64 w-full shrink-0 overflow-hidden rounded-xl md:mr-6 md:h-[225px] md:w-[240px]">
        {course.creators?.map((creator) => (
          <Image
            key={creator.id}
            src={creator.avatarPhoto?.imageUrl || ''}
            alt={creator.avatarPhoto?.imageAlt || creator.name || ''}
            className="h-full object-cover"
            style={{ width: `${100 / creatorsLength}%` }}
          />
        ))}
      </div>

      <div className="flex flex-col">
        <p className="mb-2 text-lg font-medium leading-[1.2] text-rb-gray-400 md:text-[28px]">
          {course.title}
        </p>

        <p className="hidden text-xl font-medium leading-[1.4] text-rb-gray-400 md:block">
          {course.shortDescription}
        </p>

        <p className="mb-0 text-xs leading-[1.5] text-rb-gray-300 md:text-sm">
          Created by{' '}
          {course.creators?.map((creator, i) => {
            return `${i === creatorsLength - 1 && i !== 0 ? ' & ' : ''}${creator.name}${
              i < creatorsLength - 2 ? ', ' : ''
            }`
          })}
        </p>

        <p className="mb-0 mt-2 text-base leading-[1.5] text-rb-gray-400 md:hidden">
          {course.shortDescription}
        </p>

        <div className="mt-4 mb-2 line-clamp-5 md:line-clamp-7 [&_*]:text-sm [&_*]:leading-[1.5] [&_*]:text-rb-gray-400 md:[&_*]:text-base md:[&_*]:leading-[1.6] [&>p:last-child]:mb-0 [&>ul]:my-0 [&>ul]:block [&>ul]:gap-0">
          <p className="mb-1 block text-sm leading-[1.6] md:text-base">
            What you’ll learn:
          </p>
          <ComplexPortableText content={course.whatYouWillLearn} />
        </div>

        <Button
          variant="text-only"
          size="small"
          className="p-0 font-normal text-rb-gray-300 underline hover:bg-transparent"
        >
          Read more
        </Button>
      </div>
    </Link>
  )
}

export default CourseSeoLargeCard
