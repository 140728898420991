import { useState } from 'react'

import Button from 'components/Button'
import Modal from 'components/Modal'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { useReportCohortPostOrReplyMutation } from 'gql'

export interface CohortPostReportConfirmationModalProps {
  isOpen: boolean
  onClose: () => void
  label: string
  postOrReplyId: string
}

export const CohortPostReportConfirmationModal = ({
  isOpen,
  onClose,
  label,
  postOrReplyId
}: CohortPostReportConfirmationModalProps) => {
  const [reportCohortPostOrReply, { loading }] = useReportCohortPostOrReplyMutation()
  const [wasReported, setWasReported] = useState(false)

  const handleReportClick = () => {
    const input =
      label === 'post' ? { postId: postOrReplyId } : { replyId: postOrReplyId }

    reportCohortPostOrReply({ variables: { input } }).then((response) => {
      if (response?.data?.reportCohortPostOrReply?.success) {
        setWasReported(true)
      }
    })
  }

  const handleClose = () => {
    onClose()
    setWasReported(false)
  }

  if (!postOrReplyId) return null

  return (
    <Modal
      isOpen={isOpen}
      handleClose={onClose}
      header={false}
      dataTest="cohort-post-report-confirmation-modal"
      className="max-w-[312px] rounded-2xl p-8"
      closeOnEscape
    >
      <RfParagraphMediumBold className="mb-3">
        {wasReported ? 'Thanks for reporting' : 'Report this post'}
      </RfParagraphMediumBold>
      <RfParagraphSmall className="mb-10">
        A member of the Reforge team will look into this as soon as possible.
      </RfParagraphSmall>
      <Button
        variant="fill"
        fullWidth
        onClick={wasReported ? handleClose : handleReportClick}
        className="mb-4"
        disabled={!wasReported && loading}
      >
        {wasReported ? 'Ok' : 'Report'}
      </Button>
      {!wasReported && (
        <Button variant="outline" fullWidth onClick={onClose} disabled={loading}>
          Cancel
        </Button>
      )}
    </Modal>
  )
}
