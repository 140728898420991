import LinkTags from 'components/LinkTags'

import {
  BIO_AND_EXPERIENCE_TAB,
  FREE_TEXT_CLASSES,
  GENERAL_TAB,
  HEADING_CLASSES,
  SECTION_CLASSES,
  SUBHEADING_CLASSES,
  TOOLTIP_STRING,
  WHAT_IM_SEEKING_TAB
} from 'constants/profile'

import EditableSection from './EditableSection'

interface AboutMeSectionProps {
  aboutMeText?: string | null
  areasOfExpertiseTags?: string | null
  askMeAboutText?: string | null
  communityInterestText?: string | null
  handleEditClick: (tab: number, field: string) => void
  canEdit: boolean
  communityInterestTags?: string | null
}

const AboutMeSection = ({
  aboutMeText,
  areasOfExpertiseTags,
  askMeAboutText,
  communityInterestText,
  handleEditClick,
  canEdit,
  communityInterestTags
}: AboutMeSectionProps) => {
  const showAboutMeSection =
    canEdit ||
    aboutMeText ||
    areasOfExpertiseTags ||
    askMeAboutText ||
    communityInterestTags
  const showAskMeAboutSubsection = canEdit || areasOfExpertiseTags || askMeAboutText
  const showImHereToSubsection =
    canEdit ||
    communityInterestText ||
    (communityInterestTags && communityInterestTags !== 'Not Interested')

  if (!showAboutMeSection) return null

  return (
    <div className={SECTION_CLASSES} data-test="member-profile-about-me-section">
      <div className={HEADING_CLASSES}>About me</div>
      {aboutMeText && <div className={FREE_TEXT_CLASSES}>{aboutMeText}</div>}
      {!aboutMeText && canEdit && (
        <EditableSection
          handleEditClick={() => handleEditClick(GENERAL_TAB, 'bio_textarea')}
          heading="You haven’t added your bio yet"
          text="Help members connect with you by telling them about your professional journey"
          ctaText="Add Bio"
          dataTest="member-profile-add-bio-cta"
        />
      )}
      {showAskMeAboutSubsection && (
        <>
          {' '}
          <div className={SUBHEADING_CLASSES}>Ask me about</div>
          <LinkTags
            input={areasOfExpertiseTags || ''}
            filterName="areas_of_expertise"
            tooltipString={TOOLTIP_STRING}
          />
          {askMeAboutText && <div className={FREE_TEXT_CLASSES}>{askMeAboutText}</div>}
          {!askMeAboutText && canEdit && (
            <EditableSection
              handleEditClick={() =>
                handleEditClick(BIO_AND_EXPERIENCE_TAB, 'ask_me_about_textarea')
              }
              text="Tell us more about your experience"
              ctaText="Edit Section"
              dataTest="member-profile-edit-ask-me-about-cta"
            />
          )}
        </>
      )}
      {showImHereToSubsection && (
        <>
          {' '}
          <div className={SUBHEADING_CLASSES}>I’m here to</div>
          <LinkTags
            input={communityInterestTags || ''}
            filterName="professional_relationship[]"
            tooltipString={TOOLTIP_STRING}
          />
          {communityInterestText && (
            <div className={FREE_TEXT_CLASSES}>{communityInterestText}</div>
          )}{' '}
          {!communityInterestText && canEdit && (
            <EditableSection
              handleEditClick={() =>
                handleEditClick(WHAT_IM_SEEKING_TAB, 'connections_textarea')
              }
              text="Tell us more about the connections you’re interested in making"
              ctaText="Edit Section"
            />
          )}
        </>
      )}
    </div>
  )
}

export default AboutMeSection
