export const businessAndOperationsImageAlt =
  'Artifact entitled Business hypothesis canvas at Reforge, submitted by Brian Balfour, Founder/CEO @ Reforge. 957 saves. 19 comments.'
export const dataImageAlt =
  'Artifact entitled U.S. Growth Model at Quizlet, submitted by Phil Carter, Growth Advisor @ Elemental Growth. 254 saves. 9 comments.'
export const designImageAlt =
  'Artifact entitled User journey Figma template at CloudBees, submitted by Amber Rucker, Advisor @ Aikido Security. 144 saves. 4 comments.'
export const engineeringImageAlt =
  'Breakdown of B2B MarTech at Notion, submitted by Austin Hay, Head of Marketing Tech @ Ramp. 70 saves. 4 comments.'
export const executiveAndFounderImageAlt =
  'Company update at Reforge, submitted by Brian Balfour, Founder/CEO @ Reforge. 349 saves. 7 comments.'
export const learningAndDevelopmentImageAlt =
  'Product competencies "at a glance" at Tripadvisor, submitted by Ravi Mehta, Co-founder & CEO @ Outpace. 95 saves. 2 comments.'
export const marketingImageAlt =
  'Interactive video GTM strategy at Vimeo, submitted by Zoe Black, VP of Product Marketing @ Vimeo. 338 saves. 2 comments.'
export const salesAndPartnershipsImageAlt =
  'Measuring B2B customer satisfaction at Dashlane, submitted by Tangi Gouez, Senior Product Data Analyst @ Dashlane. 113 saves. 3 comments.'
export const defaultImageAlt =
  'Product management functional strategy at Eventbrite, submitted by Casey Winters, Advisor @ Whatnot & Eventbrite. 378 saves. 2 comments.'
