import { twMerge } from 'tailwind-merge'

import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import { pluralize } from 'utils/stringUtils'

interface UnitReadInfoProps {
  readTime: number
  numberOfSteps: number
  className?: string
}

export const UnitReadInfo = ({
  readTime,
  numberOfSteps,
  className
}: UnitReadInfoProps) => {
  if (!readTime || readTime < 0) return null
  if (!numberOfSteps || numberOfSteps < 0) return null

  return (
    <div
      className={twMerge(
        'flex h-9 w-fit flex-row text-nowrap items-center justify-center space-x-2 rounded-full bg-rb-orange-25 p-4 font-medium',
        className
      )}
    >
      <RfParagraphMedium>
        {readTime} {pluralize('min', 'mins', readTime)}{' '}
        <span className="mx-1 align-middle text-[6px]">{String.fromCharCode(9679)}</span>{' '}
        {numberOfSteps} {pluralize('step', 'steps', numberOfSteps)}
      </RfParagraphMedium>
    </div>
  )
}

export default UnitReadInfo
