import { displayToast } from 'components/Toast'
import ToastCard, { toastOptions } from 'components/ToastCard'

export const showAddedToast = () => {
  displayToast(<ToastCard type="success" message="Your comment has been added." />, {
    ...toastOptions,
    toastId: 'reforge-added-comment-toast'
  })
}

export const showEditedToast = () => {
  displayToast(<ToastCard type="success" message="Your comment has been edited." />, {
    ...toastOptions,
    toastId: 'reforge-edited-comment-toast'
  })
}

export const showDeletedToast = () => {
  displayToast(<ToastCard type="success" message="Your comment has been deleted." />, {
    ...toastOptions,
    toastId: 'reforge-deleted-comment-toast'
  })
}

export const showErrorToast = () => {
  displayToast(
    <ToastCard
      type="warning"
      message="Something went wrong, your comment could not be added."
    />,
    {
      ...toastOptions,
      toastId: 'reforge-deleted-comment-toast'
    }
  )
}
