import React from 'react'
import ReactDOM from 'react-dom'
import 'react-rater/lib/react-rater.css'
import { BrowserRouter } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'

import { asyncLaunchDarklyProvider } from 'components/LaunchDarklyProvider'
import WithTimeMachine from 'components/WithTimeMachine'

// Import necessary CSS files
import 'stylesheets/application_v3.css'

import App from './App'

// remove when program pages are no more

const attachReact = (el: any, cb: any) => {
  const node = document.getElementById(el)
  if (node) {
    cb(node)
  }
}

const WrappedApp =
  process.env.REACT_APP_I_AM_A_TIME_TRAVELER === 'true'
    ? WithTimeMachine(App as any)
    : App

document.addEventListener('DOMContentLoaded', async () => {
  const LaunchDarklyProvider = await asyncLaunchDarklyProvider()

  attachReact('react-spa-root', function (node: any) {
    ReactDOM.render(
      <BrowserRouter>
        <CompatRouter>
          <LaunchDarklyProvider>
            <WrappedApp {...JSON.parse(node.getAttribute('data'))} />
          </LaunchDarklyProvider>
        </CompatRouter>
      </BrowserRouter>,
      node
    )
  })
})
