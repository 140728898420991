import clsx from 'clsx'
import { useMemo, useState } from 'react'

import ContentBookmark from 'domains/Cms/ContentBookmark'

import { SVGIcon } from 'components/Icon'
import CardProgressBar from 'components/cards/CardProgressBar'

import { CmsSectionContentType, useCmsSectionQuery } from 'gql'

import notifyError from 'utils/errorNotifier'

interface ContentPopoverCardProps {
  url: string
  cmsModuleId?: string
  cmsProgramId: string
}

export const ContentPopoverCard = ({
  url,
  cmsModuleId,
  cmsProgramId
}: ContentPopoverCardProps) => {
  const [isInSavedItems, setIsInSavedItems] = useState<boolean>(false)
  const urlParts = url ? url.split('/') : []
  const slug = urlParts.pop() || ''
  const urlContentType = urlParts.pop()
  const contentType =
    urlContentType && urlContentType.charAt(0).toUpperCase() + urlContentType.slice(1)

  const { data, loading } = useCmsSectionQuery({
    variables: { contentType: contentType as CmsSectionContentType, slug },
    onError: () => {
      notifyError('There was an error Fetching section')
    }
  })
  const {
    id,
    name,
    publishAt,
    status,
    shortDescription,
    estimatedTime,
    topic,
    cmsProgram
  } = data?.cmsSection || {}

  const published = status === 'published'

  const contentClass = useMemo(() => {
    if (!contentType) return ''
    else if (contentType === 'Concept') {
      return "bg-[url('/app/assets/images/icon--concept-popover.svg')] bg-left-center bg-no-repeat pl-4"
    }
    return "bg-[url('/app/assets/images/icon--project-popover.svg')] bg-left-center bg-no-repeat pl-4"
  }, [contentType])

  return (
    <>
      <div className="block h-[150px] w-80 bg-rb-gray-400 px-4 pt-4 pb-2.5 font-normal text-white">
        {!loading && (
          <div className="relative h-full w-full">
            <div
              className={`h-5 text-xs leading-4 text-white opacity-50 ${contentClass} uk-grid-collapse`}
              uk-grid=""
            >
              {contentType && (
                <>
                  <span className="opacity-75">{contentType}</span>
                  {topic && (
                    <>
                      <span>&nbsp;in&nbsp;</span>
                      <span className="opacity-75">{topic.title}</span>
                    </>
                  )}
                </>
              )}
              <div className="uk-width-expand" />
              <div>
                <SVGIcon name="icon-arrow-up-right" />
              </div>
            </div>

            <div className="mt-5 w-[277px]">
              <div className="text-base font-bold leading-4 text-white">{name}</div>
              <div
                className={clsx(
                  'mt-1 text-sm font-normal leading-4 text-rb-gray-50 line-clamp-2',
                  !published && 'italic'
                )}
              >
                {published && (shortDescription || '')}
                {!published &&
                  `This ${urlContentType} is currently being produced by Reforge and our subject matter experts.`}
              </div>
            </div>

            <div className="uk-position-bottom uk-grid-collapse mt-2.5 h-3.5" uk-grid="">
              {published && contentType === 'Concept' && estimatedTime && (
                <div className="text-xs font-medium uppercase leading-4 tracking-widest text-white opacity-75">
                  {estimatedTime} Min
                </div>
              )}
              {!published && (
                <div className="bg-[url('/app/assets/images/icon--clock-popover.svg')] bg-left-center bg-no-repeat pl-4 text-xs font-medium uppercase leading-4 tracking-widest text-rb-green-75">
                  COMING {publishAt || 'SOON'}
                </div>
              )}
              <div className="uk-width-expand" />
              {id && (
                <div
                  className={`flex leading-4 ${
                    isInSavedItems === null ? 'fill-transparent' : 'fill-rb-gray-300'
                  }`}
                >
                  <ContentBookmark
                    cmsContentId={id}
                    cmsModuleId={cmsModuleId}
                    cmsProgramId={cmsProgramId}
                    contentType={contentType as CmsSectionContentType}
                    bookmarkId={null}
                    isInSavedItems={isInSavedItems}
                    setIsInSavedItems={setIsInSavedItems}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <CardProgressBar
        className="h-1 bg-rb-green-100"
        progressPercent={cmsProgram?.progressPercent}
      />
    </>
  )
}
