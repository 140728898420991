import { useRef } from 'react'
import ReactTooltip from 'react-tooltip'

import { CohortEvent } from 'domains/CohortViewer/utils'

import AvatarStack from 'components/AvatarStack'
import MultiTimeAttendButton from 'components/cards/EventCard/MultiTimeAttendButton'
import SpeakerList from 'components/cards/EventCard/SpeakerList'
import EventCardDateCircle from 'components/cards/elements/EventDateCircle'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import {
  CohortCurrentUserPartsFragment,
  CurrentUserPartsFragment,
  EventSpeaker
} from 'gql'

import { formatInTimezone, getCurrentTimezone, getTimezoneAbbreviation } from 'utils/date'

export interface FullWidthEventCardProps {
  event: CohortEvent
  currentUser:
    | CurrentUserPartsFragment
    | Pick<CohortCurrentUserPartsFragment, 'id' | 'timezone'>
  rsvpCallback: (event: CohortEvent) => void
  isSmallSize?: boolean
  dataTestId?: string
}

export const FullWidthEventCard = ({
  event,
  currentUser,
  rsvpCallback,
  isSmallSize,
  dataTestId
}: FullWidthEventCardProps) => {
  const userTimezone = getCurrentTimezone(currentUser.timezone)

  const hasTooltip =
    event.sameEventInfo &&
    event.sameEventInfo.some(
      (sameEvent) => sameEvent.past && sameEvent.attendingStatus === 'Attending'
    ) &&
    event.willBeRecorded &&
    (!event.summaryWistiaCode || event.summaryWistiaCode === '')

  const tooltipRef: any = useRef(null)

  const toggleTooltip = (show: boolean) => {
    if (!hasTooltip) return false

    return show
      ? ReactTooltip.show(tooltipRef.current)
      : ReactTooltip.hide(tooltipRef.current)
  }

  const eventRsvped =
    event.sameEventInfo &&
    event.sameEventInfo.find((eventInfo) => eventInfo.attendingStatus === 'Attending')

  const orderedSpeakers: EventSpeaker[] = ([] as EventSpeaker[])
    .concat(event.speakers as any)
    .sort((speaker: EventSpeaker) => (speaker.kind === 'host' ? -1 : 1))

  const onEventCardClick = () => {
    if (event.sameEventInfo) {
      const eventRsvped = event.sameEventInfo.find(
        (eventInfo) => eventInfo.attendingStatus === 'Attending'
      )

      const allEventsPast = event.sameEventInfo.every((eventInfo) => eventInfo.past)
      const nonePastEvent = event.sameEventInfo.find((eventInfo) => !eventInfo.past)

      if (allEventsPast && event.summaryWistiaCode) {
        window.open(`/events/${event.id}-${event.slug}/summary`)
      } else if (eventRsvped) {
        window.open(`/events/${eventRsvped.id}-${eventRsvped.slug}`)
      } else if (nonePastEvent) {
        window.open(`/events/${nonePastEvent.id}-${nonePastEvent.slug}`)
      } else {
        window.open(`/events/${event.id}-${event.slug}`)
      }
    } else {
      if (event.summaryWistiaCode) {
        window.open(`/events/${event.id}-${event.slug}/summary`)
      } else {
        window.open(`/events/${event.id}-${event.slug}`)
      }
    }
  }

  return (
    <div
      onClick={onEventCardClick}
      className={`rf-rb-card-interactive flex w-full flex-col pb-2 ${
        isSmallSize && 'sm:max-h-[110px] sm:max-h-full'
      }`}
      data-test={dataTestId}
      data-testid={dataTestId}
    >
      <div style={{ height: '5px' }} className="w-full bg-rb-blue-100" />

      <div className={`flex ${!isSmallSize ? 'p-3' : ''}`}>
        <div className="flex justify-center p-3">
          <EventCardDateCircle
            startsAtUtc={eventRsvped?.startsAtUtc || event.startsAtUtc}
            currentUser={currentUser}
          />
        </div>
        <div className="flex grow flex-col pt-5 pr-4">
          <div className="flex flex-row items-center">
            <p
              className="mb-1 text-lg font-medium leading-6"
              data-test={`${dataTestId}-name`}
            >
              {event.name}
            </p>
            <div className="ml-4 hidden grow justify-end text-base sm:flex">
              <AvatarStack
                members={orderedSpeakers.map((speaker) => ({
                  ...speaker,
                  avatarUrl: speaker.user.profile.avatarUrl,
                  hasBadge: false,
                  fullName: speaker.user.fullName
                }))}
                totalCount={orderedSpeakers.length}
                maximumAvatars={3}
                avatarSize={30}
                indent={4}
                direction={'left'}
              />
            </div>
          </div>
          {event.caseCompany?.name && event.kind === 'Cohort Event' && (
            <RfParagraphSmall className="pb-0.5">
              <span className="font-semibold">{event.caseCompany.name} Case Study</span>
            </RfParagraphSmall>
          )}
          <div className="flex grow flex-col justify-between">
            <div className="block flex-row justify-between pb-1 sm:flex">
              <div>
                <div className="hidden sm:flex">
                  <div
                    className={`mb-2 text-sm leading-4 text-rb-gray-400 ${
                      isSmallSize ? 'line-clamp-1 ' : 'line-clamp-2 '
                    }`}
                  >
                    <SpeakerList
                      orderedSpeakers={orderedSpeakers}
                      textColor="text-rb-gray-500"
                      maxNames={3}
                    />
                  </div>
                </div>
                <div className="block flex-row items-center justify-between pb-1 sm:flex">
                  <div
                    className="ml-0.5 mr-2 text-xs capitalize leading-4 text-rb-gray-300"
                    data-test={`${dataTestId}-time`}
                    data-testid={`${dataTestId}-time`}
                  >
                    {event.sameEventInfo &&
                      event.sameEventInfo.length > 1 &&
                      !eventRsvped && (
                        <>
                          {formatInTimezone(
                            event.startsAtUtc,
                            userTimezone,
                            'EEE MMM d, y'
                          )}{' '}
                          &#8226; {event.sameEventInfo.length} sessions
                        </>
                      )}
                    {event.sameEventInfo &&
                      event.sameEventInfo.length > 1 &&
                      eventRsvped && (
                        <>
                          {formatInTimezone(
                            eventRsvped.startsAtUtc,
                            userTimezone,
                            'EEE MMM d, y h:mm a'
                          )}{' '}
                          -{' '}
                          {formatInTimezone(
                            eventRsvped.endsAtUtc,
                            userTimezone,
                            'h:mm a'
                          )}{' '}
                          {getTimezoneAbbreviation(userTimezone, eventRsvped.startsAtUtc)}
                        </>
                      )}
                    {(!event.sameEventInfo || event.sameEventInfo.length < 2) && (
                      <>
                        {formatInTimezone(
                          event.startsAtUtc,
                          userTimezone,
                          'EEE MMM d, y h:mm a'
                        )}{' '}
                        - {formatInTimezone(event.endsAtUtc, userTimezone, 'h:mm a')}{' '}
                        {getTimezoneAbbreviation(userTimezone, event.startsAtUtc)}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-end justify-end pl-4">
                <MultiTimeAttendButton
                  dataTestId={dataTestId}
                  timezone={userTimezone}
                  event={event}
                  hasTooltip={hasTooltip}
                  tooltipRef={tooltipRef}
                  toggleTooltip={toggleTooltip}
                  rsvpCallback={rsvpCallback}
                />
              </div>
            </div>
          </div>
        </div>

        {hasTooltip && (
          <ReactTooltip effect="solid" backgroundColor="#4D4D4D" multiline={true}>
            A recording of this session will be <br /> added within 72 hours of the event
          </ReactTooltip>
        )}
      </div>
    </div>
  )
}

export default FullWidthEventCard
