import React, { type ChangeEvent, useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { useCurrentUser } from 'hooks/useCurrentUser'

import AvatarImage from './AvatarImage'

type Mode = 'collapsed' | 'expanded'

function CancelButton({
  disabled,
  onClick
}: {
  disabled?: boolean
  onClick: () => void
}) {
  return (
    <button
      className="disabled:border-rb-slate-200 mr-[10px] cursor-pointer select-none rounded border border-rb-gray-500 bg-rb-white px-[10px] py-[6px] text-sm font-semibold hover:border-rb-teal-200 focus:border-rb-teal-300 focus:bg-rb-gray-50 active:border-rb-teal-300 active:bg-rb-gray-50 disabled:pointer-events-none disabled:border-slate-300 disabled:text-slate-300"
      onClick={onClick}
      disabled={disabled}
    >
      Cancel
    </button>
  )
}

function PostButton({
  label = 'Post',
  disabled
}: {
  label: string | undefined
  disabled: boolean
}) {
  return (
    <button
      className={twMerge(
        'disable:cursor-not-allowed flex h-[32px] w-fit cursor-pointer select-none items-center justify-center rounded bg-rb-teal-200 bg-rb-teal-200 px-3 py-2 text-sm font-semibold text-rb-white hover:bg-rb-teal-300 focus:bg-rb-teal-300 active:bg-rb-teal-300 disabled:bg-rb-disabled-light disabled:text-rb-disabled-dark'
      )}
      type="submit"
      disabled={disabled}
    >
      {label}
    </button>
  )
}

const CommentTextarea = React.forwardRef(function CommentTextarea(
  {
    formMode,
    value,
    placeholder,
    onFocus,
    onBlur,
    onChange,
    disabled
  }: {
    formMode: Mode
    value: string
    placeholder: string
    onFocus: () => void
    onBlur: () => void
    onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
    disabled: boolean
  },
  textareaRef: React.Ref<HTMLTextAreaElement>
) {
  return (
    <textarea
      ref={textareaRef}
      name="message"
      value={value}
      onChange={onChange}
      className={twMerge(
        'm-0 max-h-[200px] min-h-[30px] w-full resize-none overflow-auto border-0 bg-transparent p-0 pl-[2px] font-sans text-base transition-[height,min-height] placeholder:pt-2 placeholder:text-xs placeholder:text-rb-gray-300 focus:outline-0 focus-visible:outline-0 disabled:text-slate-300 md:pl-0',
        formMode === 'collapsed' && 'max-h-[30px]',
        formMode === 'expanded' && 'min-h-[90px]'
      )}
      tabIndex={0}
      placeholder={placeholder}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
    />
  )
})

const useAutosizeTextArea = ({
  textAreaRef,
  value
}: {
  textAreaRef: HTMLTextAreaElement | null
  value: string
}) => {
  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = '0px'
      const scrollHeight = textAreaRef.scrollHeight

      // If there is no value, we want to set the height to 0px
      // This works best when textarea has min-height set
      if (!value) {
        textAreaRef.style.height = '0px'
      }

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.style.height = `${scrollHeight}px`
    }
  }, [textAreaRef, value])
}

interface CommentFormProps {
  mode: Mode
  value: string
  placeholder?: string
  postButtonLabel?: string
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void
  onCancel?: () => void
  onBlur?: () => void
  onFocus?: () => void
  isSubmitting: boolean
  focusOnMount?: boolean
}

const CommentForm = ({
  mode,
  value,
  placeholder,
  postButtonLabel,
  onSubmit = async () => {},
  onChange = () => {},
  onCancel = () => {},
  onBlur = () => {},
  onFocus = () => {},
  isSubmitting,
  focusOnMount = false
}: CommentFormProps) => {
  const { currentUser: user } = useCurrentUser()

  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useAutosizeTextArea({
    textAreaRef: textareaRef.current,
    value
  })

  useEffect(() => {
    if (focusOnMount) {
      if (textareaRef.current) {
        textareaRef.current.focus()
        textareaRef.current.selectionStart = textareaRef.current?.value.length
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div id="artifact-comment-form" className="flex w-full flex-row">
      <form
        onSubmit={onSubmit}
        className={twMerge(
          'group relative flex w-full gap-2 rounded-md border border-rb-gray-250 bg-rb-white p-[13px]',
          mode === 'expanded' &&
            'flex-grow flex-col pb-[10px] text-rb-black focus-within:border-rb-teal-200 active:border-rb-teal-200'
        )}
      >
        <div className="flex flex-grow gap-2">
          <AvatarImage user={user} size="mini" />
          <CommentTextarea
            ref={textareaRef}
            formMode={mode}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={isSubmitting}
            placeholder={placeholder ?? ''}
          />
        </div>

        <div
          className={twMerge(
            'mt-[10px] flex flex-shrink flex-row justify-end',
            mode === 'collapsed' && 'mt-0'
          )}
        >
          {mode === 'expanded' && (
            <CancelButton disabled={isSubmitting} onClick={onCancel} />
          )}
          <PostButton label={postButtonLabel} disabled={!value || isSubmitting} />
        </div>
      </form>
    </div>
  )
}

export default CommentForm
