import { ReactComponent as ChevronLeft } from 'chevron-left.svg'
import { useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import Button from 'components/Button'
import CollapsibleHeader from 'components/CollapsibleHeader'
import RfHeader2 from 'components/typography/RfHeader/RfHeader2'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import RfParagraphSmallSemiBold from 'components/typography/RfParagraph/RfParagraphSmallSemiBold'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import {
  ArtifactAuthor,
  CourseDashboardCourseBlockPartsFragment,
  CourseDashboardCoursePartsFragment,
  CourseOnDemandCmsProgramPartsFragment
} from 'gql'

import {
  trackCourseDashboardContentClicked,
  useContentMode
} from 'utils/contentTrackingUtils'
import { buildCourseDashboardLinkFromPath } from 'utils/courseUtils'
import { trackCtaClicked } from 'utils/tracking/generated/events'

import { ReactComponent as CheckIcon } from 'images/icon--checkmark-thin.svg'

import CourseOnDemandBanner from './CourseOnDemandBanner'

interface CourseOnDemandPageDashboardHeaderProps {
  id: string
  showBackButton?: boolean
  course: CourseDashboardCoursePartsFragment
  courseBlocks?: CourseDashboardCourseBlockPartsFragment[] | null
  creators: ArtifactAuthor[]
  cmsProgram?: CourseOnDemandCmsProgramPartsFragment | null
}

const CourseOnDemandPageDashboardHeader = ({
  id,
  showBackButton = false,
  course,
  courseBlocks,
  creators,
  cmsProgram
}: CourseOnDemandPageDashboardHeaderProps) => {
  const contentMode = useContentMode({}) || ''
  const history = useHistory()

  const trackProfileClick = () => {
    trackCtaClicked({
      cta_type: 'hyperlink text',
      cta_location: 'course_on_demand_page',
      text: 'profile',
      related_identifiers: {
        course_id: course.id
      }
    })
  }

  const onNextGuideClick = () => {
    let nextGuide = courseBlocks?.[0]?.units?.[0]
    let nextCourseBlock = courseBlocks?.[0]
    let foundNextGuide = false

    courseBlocks?.forEach((block) => {
      const nextUnit = block?.units?.find((unit) => !unit.completed)
      if (nextUnit && !foundNextGuide) {
        nextGuide = nextUnit
        nextCourseBlock = block
        foundNextGuide = true
      }
    })

    if (nextGuide && nextCourseBlock) {
      trackCourseDashboardContentClicked({
        contentType: 'guide',
        contentTitle: nextGuide?.title || '',
        contentSanityId: nextGuide?.id || '',
        course,
        courseBlock: nextCourseBlock,
        contentMode
      })

      const destination = buildCourseDashboardLinkFromPath({
        courseSlug: course.slug,
        guideSlug: nextGuide.slug
      })

      history.push(destination)
    }
  }

  const hasStartedCourse = useMemo(() => {
    return courseBlocks?.flatMap((block) => block?.units).some((unit) => unit?.completed)
  }, [courseBlocks])

  const isCourseCompleted = useMemo(() => {
    return courseBlocks?.flatMap((block) => block?.units).every((unit) => unit?.completed)
  }, [courseBlocks])

  if (showBackButton) {
    return (
      <div
        id="course-dashboard-header"
        className="sticky top-0 z-10 flex w-full items-center bg-white p-3"
      >
        <Link
          to={`/courses/${course.slug}/on-demand`}
          className="hover:no-underline"
          onClick={trackProfileClick}
        >
          <RfParagraphMini>
            <span className="flex items-center justify-center gap-2 text-rb-gray-300">
              <span className="rounded-full border border-rb-gray-100 p-2 text-rb-gray-100">
                <ChevronLeft className="h-[17px] w-[17px] stroke-current text-rb-gray-100" />
              </span>
              Course Home
            </span>
          </RfParagraphMini>
        </Link>
      </div>
    )
  }

  const courseTitle = () => (
    <RfHeader2>
      <span className="my-auto font-normal">{course.title}</span>
    </RfHeader2>
  )

  const courseProgress = ({ className }: { className?: string }) => {
    return (
      <div className={twMerge('flex items-center gap-4', className)}>
        <div className="w-[218px] bg-[#E3CCBD4D] rounded h-[5px] overflow-hidden">
          <div
            className={twMerge(
              'bg-rb-success-100 h-full',
              course.completionPercentage === 100 && 'rounded-r-full'
            )}
            style={{ width: `${course.completionPercentage}%` }}
          />
        </div>

        <RfParagraphSmallSemiBold color={ColorOptions.success200}>
          {course.completionPercentage}%
        </RfParagraphSmallSemiBold>
      </div>
    )
  }

  const courseSubtitle = () => (
    <RfParagraphSmall className="my-auto" color={ColorOptions.gray400}>
      with{' '}
      {creators?.map((creator: ArtifactAuthor, index: number) => (
        <span key={creator.id}>
          {creator.name}
          {creators?.length && creators.length - 1 === index ? '' : ' and '}
        </span>
      ))}
    </RfParagraphSmall>
  )
  const collapsedHeader = () => (
    <div
      id="course-dashboard-header-collapsed"
      className="flex flex-col items-center bg-rb-orange-25 px-7 py-4 lg:gap-4"
    >
      <div className="flex w-full max-w-[820px] flex-col items-center lg:items-end">
        <div className="flex w-full max-w-[600px] flex-1 flex-col justify-between gap-2 sm:flex-row sm:items-center">
          <div className="flex flex-col flex-1">
            {courseTitle()}
            <div className="hidden md:block">{courseSubtitle()}</div>
            {courseProgress({})}
          </div>
        </div>
      </div>
    </div>
  )

  const uncollapsedHeader = () => (
    <div className="flex flex-col items-center bg-rb-orange-25 px-7 py-8 sm:py-16 lg:gap-4">
      <div className="flex w-full max-w-[820px] flex-col items-center">
        <div className="flex w-full max-w-[600px] flex-1 items-center justify-between gap-16">
          <div className="w-full">
            {courseTitle()}

            <RfParagraphSmall className="mb-4">
              <span className="text-rb-gray-400">{course.shortDescription}</span>
            </RfParagraphSmall>

            {courseSubtitle()}

            {courseProgress({ className: 'mb-4' })}

            <div className="flex items-center gap-4">
              <Button
                onClick={onNextGuideClick}
                size="small"
                disabled={isCourseCompleted}
              >
                {isCourseCompleted && (
                  <CheckIcon className="h-[16px] w-[16px] text-[#B5B8B4] fill-current mr-2" />
                )}

                {isCourseCompleted
                  ? 'Complete'
                  : hasStartedCourse
                    ? 'Continue'
                    : 'Start Course'}
              </Button>
            </div>
          </div>

          <div className="hidden w-full max-w-fit flex-col items-center justify-center gap-2 sm:flex">
            {creators?.map((creator: ArtifactAuthor) => (
              <img
                key={creator.id}
                className={twMerge(
                  'h-full w-full rounded-2xl object-cover',
                  creators?.length === 1
                    ? 'max-h-[219px] max-w-[219px]'
                    : 'max-h-[125px] max-w-[125px]'
                )}
                alt={creator.avatarPhoto?.imageAlt || ''}
                src={creator.avatarPhoto?.imageUrl || ''}
              />
            ))}
          </div>
        </div>
        {cmsProgram && (
          <div className="mt-4 max-w-[600px] w-full">
            <CourseOnDemandBanner cmsProgram={cmsProgram} />
          </div>
        )}
      </div>
    </div>
  )

  return (
    <CollapsibleHeader
      collapsedHeaderNode={collapsedHeader()}
      uncollapsedHeaderNode={uncollapsedHeader()}
      uncollapsedHeaderId={id}
      uncollapsedOutOfViewportOffset={360}
      collapsedTopOffset="top-0"
    />
  )
}

export default CourseOnDemandPageDashboardHeader
