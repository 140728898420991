import { Dispatch, SetStateAction, useState } from 'react'

import { CohortEvent } from 'domains/CohortViewer/utils'

import Button from 'components/Button'
import { InterventionCard } from 'components/InterventionCard'
import { Modal } from 'components/Modal'
import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import {
  isBeginningOfScheduledWeek,
  startsAtTo1DayAfterPacificAtMidnight
} from 'utils/cohortUtils'
import { formatInTimezone, getTimezoneAbbreviation, isBeforeDate } from 'utils/date'

import JoinButton from './JoinButton'
import {
  EventSummary,
  getInterventionSummary,
  getRelevantEventSummary,
  lastRelevantEventIsOver
} from './interventionUtils'

const eventType = (eventSummary: EventSummary, isKickoffWeek: boolean) => {
  if (isKickoffWeek) {
    return 'kickoff'
  }

  return eventSummary.type
}

const happeningTodayOrTomorrowSubtitleComponent = (
  eventHappening: EventSummary,
  isKickoffWeek: boolean
) => {
  if (isKickoffWeek) {
    return (
      <RfParagraphMedium>
        This week you&apos;ll meet your EIR and other members of the course. You&apos;ll
        also get a preview of the program and learn how to make the most of your course
        experience.
      </RfParagraphMedium>
    )
  }

  if (eventHappening.type === 'Ask an Expert') {
    return null
  }

  const prereadLink = eventHappening?.event?.preread

  if (!prereadLink) {
    return null
  }

  return (
    <RfParagraphMedium>
      Take a few minutes to review the event
      <a className="mx-1" href={prereadLink}>
        <span className="font-semibold text-rb-teal-200">pre-read</span>
      </a>
      so we can jump right into a great discussion.{' '}
    </RfParagraphMedium>
  )
}

const isHappening3orLessDaysFromTodaySubtitleComponent = (
  eventHappening: EventSummary,
  setKeyLessonsModalIsOpen: Dispatch<SetStateAction<boolean>>
) => {
  const openKeyLessonsModal = () => setKeyLessonsModalIsOpen(true)

  const prereadLink = eventHappening?.event?.preread

  const keyLessonsCta = (
    <span
      tabIndex={0}
      onKeyDown={openKeyLessonsModal}
      onClick={openKeyLessonsModal}
      className="font-semibold text-rb-teal-200"
      role="button"
    >
      Key Lessons
    </span>
  )

  if (!prereadLink) {
    return (
      <RfParagraphMedium>
        If you are pressed for time, focus on the {keyLessonsCta}.
      </RfParagraphMedium>
    )
  }

  return (
    <RfParagraphMedium>
      If you are pressed for time, focus on the {keyLessonsCta} and the event{' '}
      <a className="mx-1" href={prereadLink}>
        <span className="font-semibold text-rb-teal-200">pre-read</span>
      </a>
      .{' '}
    </RfParagraphMedium>
  )
}

interface BeginningOfWeekSubtitleComponentProps {
  isKickoffWeek: boolean
}

const BeginningOfWeekSubtitleComponent = ({
  isKickoffWeek
}: BeginningOfWeekSubtitleComponentProps) => {
  if (isKickoffWeek) {
    return (
      <RfParagraphMedium>
        This week you&apos;ll meet your EIR and other members of the course. You&apos;ll
        also get a preview of the program and learn how to make the most of your course
        experience.
      </RfParagraphMedium>
    )
  }

  return (
    <RfParagraphMedium>
      Start the week by diving into the week&apos;s content and sharing your perspective
      in the Slack channel.
    </RfParagraphMedium>
  )
}

export interface WeeklyBlockInterventionProps {
  scheduledWeekIndex: number
  timezone: string
  events: CohortEvent[]
  scheduledWeekStartsAt: string
  reachedThresholdForIntervention: boolean
}

export const WeeklyBlockIntervention = ({
  scheduledWeekIndex,
  timezone,
  events,
  scheduledWeekStartsAt,
  reachedThresholdForIntervention
}: WeeklyBlockInterventionProps) => {
  const isKickoffWeek = scheduledWeekIndex === 0

  const [keyLessonsModalIsOpen, setKeyLessonsModalIsOpen] = useState(false)

  let titleComponent = null
  let subtitleComponent = null
  let className = 'w-full'

  const summary = getInterventionSummary(events)
  const eventHappeningNow = getRelevantEventSummary(summary, 'isHappeningNow')
  const eventHappeningLaterToday = getRelevantEventSummary(
    summary,
    'isHappeningLaterToday'
  )
  const eventHappeningTomorrow = getRelevantEventSummary(summary, 'isHappeningTomorrow')
  const eventHappening3DaysFromToday = getRelevantEventSummary(
    summary,
    'isHappening3orLessDaysFromToday'
  )

  if (lastRelevantEventIsOver(events)) {
    return null
  } else if (eventHappeningNow) {
    const eventTypeNow = eventType(eventHappeningNow, isKickoffWeek)

    titleComponent = (
      <RfHeader3SemiBold>
        The {eventTypeNow === 'Core' ? 'weekly ' : `${eventTypeNow} `}event is happening
        now
      </RfHeader3SemiBold>
    )
    subtitleComponent = <JoinButton event={eventHappeningNow.event} />
  } else if (eventHappeningLaterToday || eventHappeningTomorrow) {
    const eventHappening = eventHappeningLaterToday || eventHappeningTomorrow
    const dayText = eventHappeningLaterToday ? 'today' : 'tomorrow'
    const eventTypeHappening = eventHappening && eventType(eventHappening, isKickoffWeek)

    titleComponent = (
      <RfHeader3SemiBold>
        The {eventTypeHappening === 'Core' ? 'weekly ' : `${eventTypeHappening} `}event is
        at{' '}
        {`${formatInTimezone(
          eventHappening && eventHappening.event?.startsAtUtc,
          timezone,
          'p'
        )} (${getTimezoneAbbreviation(timezone)}) ${dayText}`}
      </RfHeader3SemiBold>
    )
    subtitleComponent =
      eventHappening &&
      happeningTodayOrTomorrowSubtitleComponent(eventHappening, isKickoffWeek)
  } else if (
    eventHappening3DaysFromToday &&
    !reachedThresholdForIntervention &&
    eventHappening3DaysFromToday.type === 'Core' &&
    !isKickoffWeek
  ) {
    titleComponent = (
      <RfHeader3SemiBold>
        The{' '}
        {eventHappening3DaysFromToday.type === 'Core'
          ? 'weekly '
          : `${eventHappening3DaysFromToday.type} `}
        event is just around the corner!
      </RfHeader3SemiBold>
    )
    subtitleComponent = isHappening3orLessDaysFromTodaySubtitleComponent(
      eventHappening3DaysFromToday,
      setKeyLessonsModalIsOpen
    )
  } else if (
    isBeginningOfScheduledWeek(scheduledWeekStartsAt, scheduledWeekIndex === 0)
  ) {
    const weekText = isKickoffWeek ? 'kickoff week' : `Week ${scheduledWeekIndex}`
    titleComponent = <RfHeader3SemiBold>Welcome to {weekText}!</RfHeader3SemiBold>
    subtitleComponent = <BeginningOfWeekSubtitleComponent isKickoffWeek={isKickoffWeek} />
  } else if (
    isBeforeDate(
      startsAtTo1DayAfterPacificAtMidnight(scheduledWeekStartsAt).toUTCString()
    )
  ) {
    titleComponent = (
      <RfHeader3SemiBold className="m-0 p-0">
        Week starts{' '}
        {formatInTimezone(
          startsAtTo1DayAfterPacificAtMidnight(scheduledWeekStartsAt).toISOString(),
          timezone,
          'EEE, MMMM d'
        )}
      </RfHeader3SemiBold>
    )
    className = 'w-fit px-6 pt-4 pb-0'
  } else {
    return null
  }

  return (
    <div>
      <InterventionCard
        titleComponent={titleComponent}
        subtitleComponent={subtitleComponent}
        className={className}
      />
      <Modal
        isOpen={keyLessonsModalIsOpen}
        handleClose={() => setKeyLessonsModalIsOpen(false)}
        header={false}
        className="h-[298px] max-w-[312px] px-8 pt-8"
      >
        <div className="mb-3.5 w-fit rounded bg-[#d2e5e4]/30 px-2 py-0.5 text-rb-teal-200">
          Key Lesson
        </div>
        <RfParagraphSmall className="mb-7">
          If you&apos;re short on time before the event and can&apos;t engage with all the
          week&apos;s content, focus your attention on the Key Lessons, which will be
          those that are most relevant for the event.
        </RfParagraphSmall>
        <div className="grid w-full place-items-center">
          <Button
            size="small"
            shape="rounded"
            className="m-0 w-full normal-case"
            onClick={() => setKeyLessonsModalIsOpen(false)}
          >
            Ok, got it!
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default WeeklyBlockIntervention
