import PropTypes from 'prop-types'

export default function Role({ member, isMobile }) {
  const company = member.companyName ? `@ ${member.companyName}` : ''
  const classNames =
    'text-rb-gray-400 text-xs sm:text-sm leading-4 sm:leading-[21px] overflow-ellipsis whitespace-nowrap overflow-hidden'

  return isMobile ? (
    <>
      <div className={classNames}>{member.role}</div>
      <div className={classNames}>{company}</div>
    </>
  ) : (
    <div className={classNames}>
      {member.role} {company}
    </div>
  )
}

Role.propTypes = {
  member: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired
}
