import { format, parseISO } from 'date-fns'
import { useEffect, useReducer, useState } from 'react'
import Pluralize from 'react-pluralize'
import { twMerge } from 'tailwind-merge'
import UIkit from 'uikit'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'
import SeatSuccessModal, {
  SeatSuccessModalProps
} from 'components/SeatSuccessModal/SeatSuccessModal'
import { displayToast } from 'components/Toast'

import {
  UserAccountManageSeatsDocument,
  useReinstateSeatMutation,
  useRemoveSeatMutation,
  useRemoveSubscriptionMemberMutation
} from 'gql'

import { trackModalDisplayed } from 'hooks/useModalTracking'

import { isValidEmail } from 'utils/emailUtils'
import { sendData } from 'utils/sendData'
import { trackCtaClicked } from 'utils/tracking/analytics'

interface SeatProps {
  index: number
  userId: string | number
  userEmail: string
  subscriptionMember: any
  seatId: string
  scheduledForDeletionDate?: string
  subscriptionId: string
  readonly?: boolean
  manageSeats?: () => void
  updateSeats?: (index: number, subscriptionMember: any) => void
  termEndsAt: string | undefined | null
  numSeats: number
  expiringSeats: number
  allUserEmails: string[]
}

interface SeatState {
  email: string
  subscriptionMember: any
  loading: boolean
  validEmail: boolean
  inviteSent: boolean
  inviteResent: boolean
  releaseOrTransfer: string
}

export default function Seat(props: SeatProps) {
  const {
    allUserEmails,
    index,
    userId,
    subscriptionId,
    readonly,
    manageSeats,
    updateSeats,
    seatId
  } = props

  const [hoverStates, setHoverStates] = useState({
    trash: false,
    envelopeInvite: false,
    envelopeResentInvite: false,
    transfer: false
  })

  const handleMouseEnter = (type: string) => {
    setHoverStates({
      ...hoverStates,
      [type]: true
    })
  }

  const handleMouseLeave = (type: string) => {
    setHoverStates({
      ...hoverStates,
      [type]: false
    })
  }

  const [seatSuccessModalIsOpen, setSeatSuccessModalIsOpen] = useState(false)
  const [modalName, setModalName] =
    useState<SeatSuccessModalProps['modalType']>('removed')

  const [removeSubscriptionMember] = useRemoveSubscriptionMemberMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: UserAccountManageSeatsDocument,
        variables: { id: userId }
      }
    ]
  })

  const initialState: SeatState = {
    email: '',
    subscriptionMember: props.subscriptionMember,
    loading: false,
    validEmail: false,
    inviteSent: false,
    inviteResent: false,
    releaseOrTransfer: 'release'
  }

  const [state, setState] = useReducer(
    (state: SeatState, newState: Partial<SeatState>) => ({ ...state, ...newState }),
    initialState
  )

  const [removeSeat] = useRemoveSeatMutation()
  const [reinstateSeat] = useReinstateSeatMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: UserAccountManageSeatsDocument,
        variables: { id: userId }
      }
    ]
  })

  useEffect(() => {
    setState({ subscriptionMember: props.subscriptionMember })
  }, [props.subscriptionMember])

  const subscriptionSeatStatus =
    'before:content-[" "] relative inline-block px-[15px] before:absolute before:top-[7px] before:left-0 before:h-2 before:w-2 before:rounded-full'

  const { subscriptionMember, email } = state

  const setEmail = (email: string) => {
    setState({ email: email, validEmail: isValidEmail(email) })
  }

  const openTransferModal = () => {
    UIkit.modal(`#modal-transfer-seat-${index}`).show()
    trackModalDisplayed({
      modal_group: 'manage seats',
      modal_name: 'transfer or release seat'
    })
  }

  const openRemoveSeatModal = () => {
    UIkit.modal(`#modal-remove-seat-${index}`).show()
    trackModalDisplayed({
      modal_group: 'manage seats',
      modal_name: 'remove seat'
    })
  }

  const getModalElement = () => {
    return document.getElementById(`modal-transfer-seat-${index}`)
  }

  const canReleaseOrTransferSeat = () => {
    if (state.releaseOrTransfer === 'release' && !state.loading) {
      return true
    }
    if (
      state.releaseOrTransfer === 'transfer' &&
      state.validEmail &&
      !state.loading &&
      props.userEmail !== state.email
    ) {
      return true
    }
    return false
  }

  const handleReleaseOrTransferSeat = async () => {
    const modal = getModalElement()

    setState({ loading: true })
    const result = await removeSubscriptionMember({
      variables: {
        input: {
          id: subscriptionMember.id,
          transferToEmail: state.email
        }
      }
    })

    setState({ loading: false })

    if (result?.data?.removeSubscriptionMember?.success) {
      const modalType = state.releaseOrTransfer === 'release' ? 'released' : 'transferred'
      if (modal) {
        UIkit.modal(modal).hide()
      }
      setModalName(modalType)
      setSeatSuccessModalIsOpen(true)
    } else {
      const errors = result?.data?.removeSubscriptionMember?.errors?.join('. ')
      const errorText = errors ? `: ${errors}` : '.'
      displayToast(`Unable to ${state.releaseOrTransfer} seat${errorText}`)
    }
  }

  const handleRemoveSeat = async () => {
    if (!seatId) return

    setState({ loading: true })

    const result = await removeSeat({
      variables: { input: { id: seatId } }
    })

    setState({ loading: false })

    if (result?.data?.removeSeat?.success) {
      UIkit.modal(`#modal-remove-seat-${index}`).hide()
      setModalName('removed')
      setSeatSuccessModalIsOpen(true)
    } else {
      const errors = result?.data?.removeSeat?.errors?.join('. ')
      const errorText = errors ? `: ${errors}` : '.'
      displayToast(`Unable to remove seat${errorText}`)
    }
  }

  const handleReinstateSeat = async () => {
    setState({ loading: true })

    const result = await reinstateSeat({
      variables: { input: { id: seatId } }
    })

    setState({ loading: false })

    if (result?.data?.reinstateSeat?.success) {
      UIkit.modal(`#modal-remove-seat-${index}`).hide()
      setModalName('reinstated')
      setSeatSuccessModalIsOpen(true)
    } else {
      const errors = result?.data?.reinstateSeat?.errors?.join('. ')
      const errorText = errors ? `: ${errors}` : '.'
      displayToast(`Unable to reinstate seat${errorText}`)
    }
  }

  const createSeatAssignment = () => {
    if (!state.validEmail || state.loading) {
      return
    }

    setState({ loading: true })

    const data = {
      subscription_members: { subscription_id: subscriptionId },
      subscription_id: subscriptionId,
      email: email,
      seat_id: seatId
    }

    sendData(
      '/api/v1/subscription_members/',
      'POST',
      data,
      (errorMessage: { errors: string }, responseText: any) => {
        setState({ loading: false })
        if (errorMessage) {
          UIkit.notification(errorMessage.errors, { status: 'danger', pos: 'top-right' })
          return
        }
        setState({ inviteSent: true })

        // Show 'Invite Sent' copy in the button for a second before updating the available icons
        setTimeout(function () {
          setState({
            subscriptionMember: responseText,
            inviteResent: false,
            email: '',
            validEmail: false
          })
          updateSeats?.(index, responseText)
        }, 1000)
      }
    )
  }

  const sendInviteEmail = () => {
    setState({ loading: true })

    sendData(
      `/api/v1/subscription_members/${subscriptionMember.id}/send_invite_email`,
      'POST',
      {},
      (errorMessage: { errors: string }) => {
        setState({ loading: false })
        if (errorMessage) {
          UIkit.notification('Error sending invite email', {
            status: 'danger',
            pos: 'top-right'
          })
          return
        }
        setState({ inviteResent: true })
      }
    )
  }

  const handleClickManageSeatLink = () => {
    trackCtaClicked({
      cta_location: 'actions column',
      cta_type: 'hyperlink text',
      text: 'manage seat'
    })

    return manageSeats?.()
  }

  const Status = () => {
    let className

    if (subscriptionMember) {
      if (subscriptionMember.status === 'active') {
        className = 'before:bg-rb-green-100'
      } else if (subscriptionMember.status === 'pending') {
        className = 'before:bg-rb-orange-100'
      }
    }

    if (props.scheduledForDeletionDate) {
      return (
        <span className={`${subscriptionSeatStatus} ${className}`}>
          <span className="uk-visible@m">
            Seat expires on {props.scheduledForDeletionDate}
          </span>
          <span className="uk-hidden@m">Expiring</span>
        </span>
      )
    }

    if (subscriptionMember) {
      if (subscriptionMember.status === 'active') {
        return <span className={`${subscriptionSeatStatus} ${className}`}>Active</span>
      } else if (subscriptionMember.status === 'pending') {
        return (
          <span className={`${subscriptionSeatStatus} ${className}`}>
            <span className="uk-visible@m">Invited {subscriptionMember.createdAt}</span>
            <span className="uk-hidden@m">Invited</span>
          </span>
        )
      } else {
        return null
      }
    } else {
      return (
        <span className={twMerge(subscriptionSeatStatus, 'before:bg-rb-blue-100')}>
          Prepaid Seat
        </span>
      )
    }
  }

  const EffectOnSubscription = () => {
    const removingSeat = !props.scheduledForDeletionDate
    const termEndsAt = props.termEndsAt
      ? format(parseISO(props.termEndsAt), 'MMMM do, yyyy')
      : null
    return (
      <>
        <h6 className="mb-1 font-medium text-rb-gray-300">
          How does this affect my subscription?
        </h6>
        <p className="m-0 whitespace-normal text-rb-gray-300">
          {removingSeat && (
            <>
              {props.numSeats - (props.expiringSeats + 1) === 0 && (
                <>
                  Removing all seats from your plan will result in your subscription being
                  canceled{termEndsAt ? ` on ${termEndsAt}` : ''}.
                </>
              )}
            </>
          )}
          {!removingSeat && (
            <>
              By keeping this seat in your plan, you will be charged for{' '}
              <Pluralize
                singular={'seat'}
                count={props.numSeats - (props.expiringSeats - 1)}
              />
              {termEndsAt ? ` on ${termEndsAt}` : ''}.
            </>
          )}
        </p>
      </>
    )
  }

  const tdClassName = 'tl:!py-[7px] tl:!px-3 !py-1 !px-1.5 text-sm'

  return (
    <tr data-test={subscriptionMember ? 'occupied-seat' : 'open-seat'}>
      <td className={`${tdClassName} uk-visible@l`} style={{ whiteSpace: 'nowrap' }}>
        {index + 1}
      </td>
      <td
        className={twMerge(tdClassName, 'tl:!py-1')}
        style={
          !subscriptionMember
            ? {
                paddingTop: '1px',
                paddingBottom: 0,
                paddingLeft: !readonly ? '0' : 'auto'
              }
            : {}
        }
      >
        {subscriptionMember && (
          <>
            <span className="uk-hidden@m">
              {userId === subscriptionMember.userId && (
                <b className="font-medium">{subscriptionMember.email}</b>
              )}
              {userId !== subscriptionMember.userId && subscriptionMember.email}
            </span>
            <span className="uk-visible@m">
              {subscriptionMember.email}{' '}
              {userId === subscriptionMember.userId && <span>(You)</span>}
            </span>
          </>
        )}
        {!subscriptionMember && !readonly && (
          <input
            type="text"
            name="seat-invite-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="uk-input"
            placeholder="sample@example.com"
            style={{ height: '29px' }}
          />
        )}
        {!subscriptionMember && readonly && (
          <span className="text-rb-gray-300">Invite a colleague</span>
        )}
      </td>
      {readonly && (
        <td className={`${tdClassName} uk-visible@l`} style={{ whiteSpace: 'nowrap' }}>
          {subscriptionMember && subscriptionMember.role}
        </td>
      )}
      <td className={tdClassName} style={{ whiteSpace: 'nowrap' }}>
        <Status />
      </td>
      <td className={tdClassName} style={{ whiteSpace: 'nowrap' }}>
        {readonly && (
          <button
            onClick={handleClickManageSeatLink}
            data-test="user-manage-seat-button-link"
          >
            Manage <span className="uk-visible@m">seat</span>
          </button>
        )}

        {!readonly && (
          <div className="flex">
            {!props.scheduledForDeletionDate &&
              props.numSeats - props.expiringSeats === 1 && (
                <span
                  className="uk-margin-small-right items-center"
                  style={{ width: '20px' }}
                  uk-tooltip={
                    'title: Cancel your subscription to remove the last seat; cls: uk-active rf-tooltip-down-arrow; pos: top'
                  }
                >
                  <SVGIcon name="trash" width="16" height="16" fill="#d3d2d3" />
                </span>
              )}
            {(props.scheduledForDeletionDate ||
              props.numSeats - props.expiringSeats > 1) && (
              <button
                data-test="remove-seat"
                className="uk-margin-small-right items-center"
                onClick={openRemoveSeatModal}
                onMouseEnter={() => handleMouseEnter('trash')}
                onMouseLeave={() => handleMouseLeave('trash')}
                style={{ width: '20px' }}
                uk-tooltip={`title: ${
                  props.scheduledForDeletionDate ? 'Reinstate seat' : 'Remove seat'
                }; cls: uk-active rf-tooltip-down-arrow; pos: top`}
              >
                <SVGIcon
                  className="cursor-pointer"
                  name="trash"
                  width="16"
                  height="16"
                  fill={hoverStates.trash ? '#1B726F' : '#A2A1A2'}
                />
              </button>
            )}
            {subscriptionMember && subscriptionMember.status === 'pending' && (
              <button
                className="uk-margin-small-right items-center"
                style={{ width: '20px' }}
                onClick={() => {
                  if (!state.inviteResent) {
                    sendInviteEmail()
                  }
                }}
                onMouseEnter={() => handleMouseEnter('envelopeResentInvite')}
                onMouseLeave={() => handleMouseLeave('envelopeResentInvite')}
                uk-tooltip={`title: ${
                  state.inviteResent ? 'Invite resent' : 'Resend invite'
                }; cls: uk-active rf-tooltip-down-arrow; pos: top`}
              >
                <SVGIcon
                  className={state.inviteResent ? '' : 'cursor-pointer'}
                  name="envelope-sharp"
                  width="16"
                  height="16"
                  fill={
                    state.inviteResent
                      ? '#d3d2d3'
                      : hoverStates.envelopeResentInvite
                        ? '#1B726F'
                        : '#A2A1A2'
                  }
                />
              </button>
            )}
            {subscriptionMember && (
              <button
                data-test={'transfer-or-release-seat-icon'}
                className="uk-margin-small-right items-center"
                style={{ width: '20px' }}
                onClick={openTransferModal}
                onMouseEnter={() => handleMouseEnter('transfer')}
                onMouseLeave={() => handleMouseLeave('transfer')}
                uk-tooltip={
                  'title: Transfer or release seat; cls: uk-active rf-tooltip-down-arrow; pos: top'
                }
              >
                <SVGIcon
                  className="cursor-pointer"
                  name="transfer"
                  width="16"
                  height="16"
                  fill={hoverStates.transfer ? '#1B726F' : '#A2A1A2'}
                />
              </button>
            )}
            {!subscriptionMember && (
              <>
                <button
                  className="uk-margin-small-right uk-hidden@m items-center"
                  style={{ width: '20px' }}
                  onClick={createSeatAssignment}
                  onMouseEnter={() => handleMouseEnter('envelopeInvite')}
                  onMouseLeave={() => handleMouseLeave('envelopeInvite')}
                  uk-tooltip={
                    'title: Send Invite; cls: uk-active rf-tooltip-down-arrow; pos: top'
                  }
                >
                  <SVGIcon
                    className={!state.validEmail ? '' : 'cursor-pointer'}
                    name="envelope-sharp"
                    width="16"
                    height="16"
                    fill={
                      !state.validEmail
                        ? '#d3d2d3'
                        : hoverStates.envelopeInvite
                          ? '#1B726F'
                          : '#A2A1A2'
                    }
                  />
                </button>
                <span className="uk-margin-small-right uk-visible@m items-center">
                  {!state.inviteSent && (
                    <Button
                      disabled={!state.validEmail}
                      onClick={createSeatAssignment}
                      className="h-5 px-2.5 py-0"
                      color="teal"
                      variant="outline"
                      shape="rounded-full"
                    >
                      {allUserEmails.includes(state.email)
                        ? 'Assign Seat'
                        : 'Send Invite'}
                    </Button>
                  )}
                  {state.inviteSent && (
                    <Button
                      disabled={true}
                      className="h-5 px-2.5 py-0"
                      variant="outline"
                      shape="rounded-full"
                    >
                      {allUserEmails.includes(state.email)
                        ? 'Seat Assigned'
                        : 'Invite Sent'}
                    </Button>
                  )}
                </span>
              </>
            )}
          </div>
        )}

        {subscriptionMember && (
          <div
            id={`modal-transfer-seat-${index}`}
            className="uk-modal-container"
            uk-modal={'{"container": false}'}
          >
            <div className="uk-modal-dialog uk-modal-body lg:!w-[970px]">
              <>
                <h3 className="mb-1 text-xl font-medium text-black xl:text-2xl">
                  Transfer or Release Seat
                </h3>
                <p className="text-rb-gray-300" style={{ margin: '0' }}>
                  Are you sure you want to release this seat to assign it to someone else?
                  Once you do, this person will not be part of your plan.
                </p>

                <table className="uk-table uk-table-striped uk-table-small uk-margin-small-top text-[13px] text-rb-gray-500 tl:text-sm">
                  <thead>
                    <tr>
                      <th className="!text-sm !font-medium">Email</th>
                      <th className="!text-sm !font-medium">Status</th>
                      <th className="!text-sm !font-medium sm:w-[160px]">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className={tdClassName}>{subscriptionMember.email}</td>
                      <td className={tdClassName}>
                        <Status />
                      </td>
                      <td className={tdClassName}>
                        <span className="text-rb-gray-300">
                          {state.releaseOrTransfer === 'transfer'
                            ? 'Transferring seat'
                            : 'Releasing seat'}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  Who would you like to assign this seat to? This will send an email
                  invitation for this person to join your plan.
                </p>
                <table className="uk-table uk-table-striped uk-table-small uk-margin-small-top text-[13px] text-rb-gray-500 tl:text-sm">
                  <tbody>
                    <tr>
                      <td className={`${tdClassName} flex`}>
                        <button
                          style={{ paddingTop: '3px', paddingBottom: '3px' }}
                          onClick={() => {
                            setState({ releaseOrTransfer: 'transfer' })
                          }}
                        >
                          <SVGIcon
                            name={
                              state.releaseOrTransfer === 'transfer'
                                ? 'radio-on'
                                : 'radio-off'
                            }
                            fill="#0F0F0F"
                            className="uk-margin-small-right"
                          />
                        </button>
                        <input
                          type="text"
                          aria-label="email"
                          name="seat-transfer-input"
                          value={email}
                          onClick={() => {
                            setState({ releaseOrTransfer: 'transfer' })
                          }}
                          onChange={(e) => {
                            setState({ releaseOrTransfer: 'transfer' })
                            setEmail(e.target.value)
                          }}
                          className="h-7 w-2/4 border-2 border-[#e5e5e5] px-2.5"
                          placeholder="sample@example.com"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className={`${tdClassName} flex`}>
                        <button
                          onClick={() => {
                            setState({ releaseOrTransfer: 'release' })
                          }}
                        >
                          <SVGIcon
                            name={
                              state.releaseOrTransfer === 'release'
                                ? 'radio-on'
                                : 'radio-off'
                            }
                            fill="#0F0F0F"
                            className="mr-2.5"
                          />
                          <span>I&apos;ll do this later</span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="my-5 flex items-center justify-end">
                  <Button
                    className="uk-modal-close"
                    variant="text-only"
                    onClick={() => {}}
                  >
                    Cancel
                  </Button>

                  <Button
                    disabled={!canReleaseOrTransferSeat()}
                    onClick={handleReleaseOrTransferSeat}
                    size="small"
                  >
                    {state.releaseOrTransfer === 'release' ? 'Release' : 'Transfer'} Seat
                  </Button>
                </div>
              </>
            </div>
          </div>
        )}
        <div
          id={`modal-remove-seat-${index}`}
          className="uk-modal-container"
          uk-modal={'{"container": false}'}
        >
          <div className="uk-modal-dialog uk-modal-body lg:!w-[970px]">
            <>
              {props.scheduledForDeletionDate && (
                <>
                  <h3 className="mb-1 font-medium text-black">Cancel Seat Removal</h3>
                  <p className="m-0 whitespace-normal text-rb-gray-300">
                    This seat is scheduled to be removed from your subscription when its
                    current term ends on {props.scheduledForDeletionDate}. Do you want to
                    revert this removal and retain the seat in your plan?
                  </p>
                </>
              )}
              {!props.scheduledForDeletionDate && (
                <>
                  <h3 className="mb-1 font-medium text-black">Remove Seat</h3>
                  <p className="m-0 whitespace-normal text-rb-gray-300">
                    Are you sure you want to remove this seat from your subscription? Once
                    you do, this {subscriptionMember ? 'person' : 'seat'} will no longer
                    be a part of your plan. Since your plan is paid in full, you can
                    invite a colleague to use this seat until it expires.
                    {subscriptionMember && (
                      <>
                        &nbsp;Keep this seat in your subscription by{' '}
                        <button onClick={openTransferModal}>
                          releasing &amp; assigning it to someone else
                        </button>{' '}
                        instead.
                      </>
                    )}
                  </p>
                </>
              )}
              <table className="uk-table uk-table-striped uk-table-small uk-margin-small-top w-full text-[13px] text-rb-gray-500 tl:text-sm">
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Status</th>
                    <th className="sm:w-[160px]">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={`${tdClassName} whitespace-normal break-all`}>
                      {subscriptionMember ? (
                        subscriptionMember.email
                      ) : (
                        <em className="text-rb-gray-400">Unassigned Seat</em>
                      )}
                    </td>
                    <td className={tdClassName}>
                      <Status />
                    </td>
                    <td className={tdClassName}>
                      <span className="text-rb-gray-300">
                        {props.scheduledForDeletionDate
                          ? 'Reinstating seat'
                          : 'Removing seat'}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <EffectOnSubscription />
              <div className="my-5 flex items-center justify-end">
                <Button className="uk-modal-close" variant="text-only" onClick={() => {}}>
                  Cancel
                </Button>

                {props.scheduledForDeletionDate ? (
                  <Button
                    disabled={state.loading}
                    onClick={handleReinstateSeat}
                    size="small"
                    dataTest="user-seat-reinstate-seat-button"
                  >
                    Reinstate Seat
                  </Button>
                ) : (
                  <Button
                    disabled={state.loading}
                    onClick={handleRemoveSeat}
                    size="small"
                    dataTest="user-seat-remove-seat-button"
                  >
                    Remove Seat
                  </Button>
                )}
              </div>
              <button
                className="uk-modal-close-default"
                type="button"
                uk-close="true"
                aria-label="close"
              />
            </>
          </div>
        </div>
        <SeatSuccessModal
          isOpen={seatSuccessModalIsOpen}
          handleClose={() => setSeatSuccessModalIsOpen(false)}
          modalType={modalName}
          dataTest={`${modalName}-seat-modal`}
          closeModalTime={3000}
          isLegacyUser={true} // if you're seeing this, you're a legacy user
        />
      </td>
    </tr>
  )
}
