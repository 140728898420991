import { Link } from 'react-router-dom'

import { FREE_TRIAL_DAYS } from 'pages/PurchaseFlow/constants'

import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { Loading } from 'components'

import { Page, useTermsOfServicePageQuery } from 'gql'

const TermsOfServicePage = () => {
  const { data, loading } = useTermsOfServicePageQuery()
  const page = (data?.termsOfServicePage || {}) as Page

  return (
    <MarketingLayout page={page}>
      {loading ? <Loading /> : <TermsOfService />}
    </MarketingLayout>
  )
}

export const TermsOfService = () => {
  return (
    <div className="grid grid-cols-1 px-[16px] py-[24px] md:grid-cols-2 md:justify-between md:px-[4vw] md:pt-[96px] md:pb-[72px]">
      <h1 className="mb-[72px] font-normal fluid-text-8xl md:mr-[4vw]">
        Reforge Contract, Terms, Conditions, and Refund Policy
      </h1>
      <div className="block-content">
        <p>
          You agree that by accessing, using or registering an account with our Services
          (defined below) or by entering into an order form to use the Services, you or,
          if you are entering into these terms on behalf of your organization, such
          organization (“you” or “User”) is agreeing to enter into a legally binding
          contract with Reforge, Inc. (“Reforge”) by which you agree to abide by these
          terms of service (these “Terms”) and our{' '}
          <Link to="/privacy-policy">Privacy Policy</Link> (the “Privacy Policy” and,
          together with the Terms, the “Contract”) unless you have entered into another
          binding agreement with us. If you do not agree to the terms and conditions of
          this Contract, you may not access or use our Services. If you are entering into
          these Terms on behalf of an organization, you represent and warrant that you
          have all necessary authority to bind such organization to these Terms and that,
          from and after the date you enter into these Terms, these Terms will be a
          binding obligation of such organization, enforceable against such organization
          in accordance with the terms hereof.
        </p>
        <p>
          If you are an individual User and are using the Services as part of a team plan
          (a “Team Plan”) purchased by your employer or other affiliated organization,
          your use of the Services is contingent on payment by your employer or other
          organization. You remain bound by the restrictions on use, license to User
          Submissions and limitation of liability provisions contained in these Terms, but
          you are not subject to the payment obligations contained herein.
        </p>
        <p>
          From time to time, we may modify these Terms and our Privacy Policy. If we make
          material changes to these Terms or our Privacy Policy, we will seek to provide
          you notice through our Services, or by other means, to provide you the
          opportunity to review the changes before the date by which they become effective
          as indicated in the notice (the “Effective Date”). Your continued use of our
          Services after we publish or send notice about changes to the Contract means
          that you are consenting to the updated Contract as of the Effective Date.
          Notwithstanding the foregoing, if we modify these Terms in a way that materially
          and adversely affects your rights to access and use the Services, we will, at
          your request, negotiate with you in good faith to terminate any then-outstanding
          order form or to issue you a partial refund.
        </p>
        <p>
          If you wish to terminate this Contract at any time, you can do so by closing
          your account and no longer accessing or using our Services or Materials (as
          defined below); however, the foregoing termination right does not apply to
          organizations that purchased a Team Plan that sets forth a minimum contract
          term. In the event of a Team Plan with a minimum contract term, You will be
          obligated to pay for the Services for the duration of such minimum contract
          term. All provisions that, by their nature, should survive termination of this
          Contract shall survive termination, including provisions regarding ownership of
          intellectual property, limitations of liability and terms regarding the disputes
          between you and Reforge.
        </p>
        <p>
          If you are an organization that purchases a Team Plan, these Terms incorporate
          the{' '}
          <Link to="/dpa" target="_blank">
            Data Processing Addendum
          </Link>{' '}
          (the “DPA”) to the extent applicable to our processing of personal data in
          connection with providing you the Services. In the event of any conflict between
          these Terms and the DPA, the conflicting term of the DPA shall supersede with
          respect to its subject matter.
        </p>
        <h2>Services</h2>
        <p>
          This Contract applies to Reforge.com, other Reforge-related websites,
          applications, communications, programs and other services that Reforge provides
          to you from time to time (“Services”), including any events or programs that
          take place in person or virtually. Anyone who uses or accesses the Services,
          registers an account with Reforge, or agrees to be bound by this Contract, is a
          User subject to these Terms.
        </p>
        <h2>Material</h2>
        <p>
          We respect the intellectual property of others, so we ask you to do the same.
          The video, text, software, photos, graphics, images, live events, audio,
          designs, trademarks, proprietary information and other content of, or related
          to, the Services (collectively, “Materials”) are protected by copyright,
          trademark and other laws. REFORGE® is a registered trademark of Reforge, Inc.
          and Reforge and applicable third parties, such as Reforge’s licensors, own
          copyright, trademark, and other rights in the Services and Materials, and
          reserve all rights in them.
        </p>
        <h2>User Submissions</h2>
        <p>
          Anything you post, upload, share, store, or otherwise provide through the
          Services or in connection with your use of the Services is your “User
          Submission”. You are solely responsible for all User Submissions you contribute.
          You represent that all User Submissions submitted by you comply with all
          applicable laws, rules and regulations and are not otherwise offensive or
          inappropriate for the Services (as determined by Reforge in its sole
          discretion).
        </p>
        <p>
          By submitting User Submissions, you hereby grant Reforge a worldwide,
          non-exclusive, perpetual, royalty-free, fully paid, sub-licensable and
          transferable license to use, edit, modify, truncate, aggregate, reproduce,
          distribute, prepare derivative works of, display, perform, and otherwise fully
          exploit the User Submissions in connection with this site, the Services and our
          (and our successors’ and assigns’) businesses.
        </p>
        <h2>Refunds</h2>
        <p>
          Reforge offers a {FREE_TRIAL_DAYS}-day free trial for new users to experience
          the membership. If you decide not to continue with your membership, you may
          cancel at any time during the trial period, and you will not be charged. At the
          end of your trial period, your annual subscription will automatically begin, and
          you will be billed in full for the year.
        </p>
        <p>
          Reforge does not offer refunds once you have been charged. To ask a question
          about your subscription, please email our support team at{' '}
          <a href="mailto:hello@reforge.com">hello@reforge.com</a>
          .&nbsp;
        </p>
        <h2>Eligibility to Use or Access Services and Materials</h2>
        <p>
          You are solely responsible for activity that occurs on your account and shall be
          responsible for maintaining the confidentiality of your password. If you are an
          organization that has purchased a Team Plan, you remain responsible for all acts
          and omissions of users affiliated with your organization.
        </p>
        <p>
          You shall not share login credentials with others on the platform. You will
          immediately notify us in writing through an email to hello@reforge.com of any
          unauthorized use of your account, or any other known account-related security
          breach. If you are an organization that has purchased a Team Plan, you will
          immediately notify us of any unauthorized use or other security issue related to
          one of your Team Plan accounts or any other activity occurring through a Team
          Plan account that violates these Terms.
        </p>
        <p>
          If you are an organization that has purchased a Team Plan, you may request a
          transfer of an individual User’s membership through an email to
          hello@reforge.com.
        </p>
        <p>
          If you manage a team plan on Reforge, you may not transfer seats between Users
          on your subscription unless the User is either (A) no longer employed by your
          company or (B) on a formal, extended leave of absence. Reforge reserves the
          right to restrict, suspend or terminate your use of the Services if you breach
          this Contract or the law, or if Reforge determines in its discretion that you
          are misusing the Services in any way.
        </p>
        <h2>Use of Services and Materials</h2>
        <p>
          You may use the Services and Materials only in the manner and for the purposes
          specified in this Contract.
        </p>
        <p>
          Except as provided in this Contract, you may not access, use, download, modify,
          publish, transmit, participate in the transfer or sale of, reproduce, create new
          or derivative works from, distribute, perform, display (including framing and
          inline linking), communicate to the public or in any way exploit, any of the
          Services and Materials in whole or in part. Without limiting the foregoing, you
          may not share or otherwise provide links to live events with third-parties
          (including other members of the Reforge community).
        </p>
        <p>
          You may download and copy Materials clearly designated as a “downloadable”
          within the Services and you may take notes summarizing the Materials within the
          Services, in each case solely for your personal use unless explicitly stated
          otherwise. If you wish to use other Materials in other ways, you may do so only
          if you request and receive specific prior written authorization from Reforge.
        </p>
        <p>
          You may use and access the Services only through platforms authorized by
          Reforge, such as the Reforge.com website and Reforge’s in-person programs, and
          only in a manner that comports with the User Conduct Policies and other terms
          set forth in this Contract.
        </p>
        <p>
          The Services may contain links to third-party websites or services that are not
          owned or controlled by Reforge. When you access third-party websites or use
          third-party services, you accept that there are risks in doing so, and that
          Reforge is not responsible for such risks. In particular, Reforge will not and
          cannot monitor, verify, censor or edit the content of any third-party site or
          service.
        </p>
        <h2>User Conduct Policies</h2>
        <p>
          Your eligibility to use or access the Services and Materials is contingent on
          remaining in good standing as a member of the Reforge community. These policies,
          which we continue to develop, provide guidance and rules for the use of our
          Services and Materials:
        </p>
        <ul data-rte-list="default">
          <li>
            <p>
              Do not violate Reforge’s rights in its intellectual property or proprietary
              information, including by suggesting that you are affiliated with,
              authorized by or approved by Reforge when you are not.
            </p>
          </li>
          <li>
            <p>
              Do not use the Services or Materials to compete with Reforge, or to solicit
              Reforge’s Users or potential customers for commercial purposes.
            </p>
          </li>
          <li>
            <p>
              Do not publicly communicate information about another User obtained through
              the Services.
            </p>
          </li>
          <li>
            <p>
              Do not communicate false, disparaging or defamatory information about
              another User of the Services.
            </p>
          </li>
          <li>
            <p>
              Do not use the Services to abuse, harass, intimidate or shock another User.
            </p>
          </li>
          <li>
            <p>
              Do not attempt, in any manner, to obtain the password, account, or other
              security information from any other User.
            </p>
          </li>
          <li>
            <p>
              Do not copy or store any significant portion of the Materials (except as
              expressly permitted herein or authorized by Reforge in writing).
            </p>
          </li>
          <li>
            <p>
              Do not use the Services to share graphic, obscene, or pornographic content.
            </p>
          </li>
          <li>
            <p>
              Do not use the Services to promote fraudulent schemes, multi-level marketing
              (MLM) schemes, get rich quick schemes, online gaming and gambling, cash
              gifting, work from home businesses, or any other dubious money-making
              ventures.
            </p>
          </li>
          <li>
            <p>
              Do not use robotic automation to access the Services or to collect
              Materials.
            </p>
          </li>
          <li>
            <p>
              Do not use the Services for illegal activities, promotion of illegal
              products or activities, or violation of the rights of others.
            </p>
          </li>
          <li>
            <p>
              Do not use the Services to distribute viruses, worms, or other software that
              can destroy or interrupt others’ data or computer systems or devices.
            </p>
          </li>
          <li>
            <p>Do not interfere with or disrupt the Services.</p>
          </li>
        </ul>
        <p>
          Any violation of these policies shall constitute a breach of the Contract, which
          may result in immediate termination of your account.
        </p>
        <h2>Billing</h2>
        <p>
          We offer a {FREE_TRIAL_DAYS}-day free trial for new users. During this period,
          you will have full access to our membership. To sign up for the free trial, you
          must provide a valid credit card. Your card will not be charged during the trial
          period unless you choose to continue with a paid subscription after the trial
          ends. At the end of the {FREE_TRIAL_DAYS}-day free trial, your account will
          automatically convert to a paid subscription, and your credit card will be
          charged the applicable subscription fee, unless you cancel your free trial
          before the end of the {FREE_TRIAL_DAYS}-day period. You may cancel your free
          trial at any time during the {FREE_TRIAL_DAYS}-day period by going to your
          account page and completing the cancellation flow. If you cancel your free
          trial, you will continue to have access to the full service until the end of
          your {FREE_TRIAL_DAYS}-day free trial period. After the trial period ends, your
          account will be closed, and you will no longer be able to access the service. By
          signing up for the free trial, you agree to these Terms and Conditions and
          authorize us to charge your credit card for the applicable subscription fee if
          you choose to continue with a paid subscription after the free trial ends. If
          you enroll in a live course your free trial will be converted into a membership
          and you will be charged immediately.
        </p>
        <p>
          Your use of the Services is subject to your payment of the subscription fees
          that were presented to you at the time of enrollment. We use a third-party
          payment processor (the “Payment Processor”) to bill you through a payment
          account linked to your account on the Services. The processing of payments will
          be subject to the terms, conditions and privacy policies of the Payment
          Processor. Currently, we use Stripe, Inc. as our Payment Processor. We are not
          responsible for the errors or omissions of our Payment Processor. By enrolling
          in the Services, you agree to pay us, through the Payment Processor, all charges
          at the prices communicated to you at the time of enrollment, and you authorize
          us, through the Payment Processor, to charge your chosen payment provider.
        </p>
        <p>
          If you have enrolled in a subscription with an auto-renewing payment plan, you
          accept responsibility for all recurring charges prior to cancellation. Your
          subscription will automatically renew for successive renewal periods of the same
          duration as the original subscription term at the then-current rate. WE MAY
          SUBMIT PERIODIC CHARGES IN ACCORDANCE WITH YOUR SUBSCRIPTION PLAN WITHOUT
          FURTHER AUTHORIZATION FROM YOU, UNTIL YOU TERMINATE YOUR AUTHORIZATION OR CHANGE
          YOUR PAYMENT METHOD. SUCH NOTICE WILL NOT AFFECT CHARGES SUBMITTED BEFORE WE
          REASONABLY COULD ACT. TO TERMINATE YOUR AUTHORIZATION OR CHANGE YOUR PAYMENT
          METHOD, GO TO YOUR ACCOUNT SETTINGS.
        </p>
        <h2>Notices and Communications</h2>
        <p>
          We will provide notices and communications to you in the following ways: (1)
          through the Services, or (2) sent to the contact information you provided us
          (e.g., email or physical address). You agree to keep your contact information up
          to date.
        </p>
        <h2>AI Features</h2>
        <p>
          We may make available to you, within our website or through other products or
          features (including a browser extension) certain interactive features powered by
          artificial intelligence (“AI”, and such features, the “AI Features”). The AI
          Features may generate suggestions, highlight resources or otherwise provide
          output designed to assist you with tasks you are performing or content you are
          viewing. In order to function properly, the AI Features may access and process
          information generated through programs you are using or webpages or other
          content that you are viewing. By using the AI Features, you acknowledge and
          agree that we may have access to such programs or content to the extent
          necessary to provide and optimize the AI Features. We will not retain such
          content longer than is necessary to provide you with suggestions or other
          output; however, we may retain certain de-identified data that is not capable of
          revealing information about you or your data.
        </p>
        <p>
          We make no representations or warranties whatsoever regarding the AI Features,
          which are provided “as is” and should be used at your own risk. You should
          evaluate the accuracy of any information produced from an AI Feature as
          appropriate for your use case.
        </p>
        <p>
          Your use of the AI Features may be subject to additional fees and charges, which
          we will communicate to you at the time you access or subscribe to the AI
          Features.
        </p>
        <h2>Miscellaneous</h2>
        <p>
          Reforge makes no representations or warranties concerning the Services,
          including without limitation regarding any Materials contained in or accessed
          through the Services, and will not be responsible or liable for the accuracy,
          quality or suitability of any Materials for your intended purpose.
        </p>
        <p>
          TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND
          UNDER NO LEGAL THEORY SHALL REFORGE BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR
          (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY
          KIND, (B) ANY SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY, (C) ANY AMOUNT, IN THE
          AGGREGATE, IN EXCESS OF THE GREATER OF (I) ONE-HUNDRED ($100) DOLLARS OR (II)
          THE AMOUNTS PAID AND/OR PAYABLE BY YOU TO REFORGE IN CONNECTION WITH THE
          SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM OR (D)
          ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION
          OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER DAMAGES, SO THE
          ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
        </p>
        <p>
          You may not assign, delegate or transfer these Terms or your rights or
          obligations hereunder, or your account, in any way (by operation of law or
          otherwise) without Reforge’s prior written consent. We may transfer, assign, or
          delegate these Terms and our rights and obligations without consent.
        </p>
        <p>
          These Terms are governed by and will be construed under the laws of the State of
          California, without regard to the conflicts of laws provisions thereof. All
          conflicts arising out of these Terms will be subject to the exclusive
          jurisdiction of the state and federal courts located in Santa Clara County,
          California, and you hereby submit to the jurisdiction of such courts. YOU AND
          REFORGE WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A
          TRIAL IN FRONT OF A JUDGE OR JURY. ALL CLAIMS RELATED TO THESE TERMS MUST BE
          LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN
          ONE CUSTOMER OR USER CANNOT BE LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF
          ANY OTHER CUSTOMER OR USER.
        </p>
        <p>
          The failure of either you or us to exercise, in any way, any right herein shall
          not be deemed a waiver of any further rights hereunder. If any provision of
          these Terms are found to be unenforceable or invalid, that provision will be
          limited or eliminated, to the minimum extent necessary, so that these Terms
          shall otherwise remain in full force and effect and enforceable. You and Reforge
          agree that these Terms are the complete and exclusive statement of the mutual
          understanding between you and Reforge, and that these Terms supersede and cancel
          all previous written and oral agreements, communications and other
          understandings relating to the subject matter of these Terms. You hereby
          acknowledge and agree that you are not an employee, agent, partner, or joint
          venture of Reforge, and you do not have any authority of any kind to bind
          Reforge in any respect whatsoever. These Terms and any order form you enter into
          for the Services constitute the entire agreement between you and us with respect
          to your use of the Services. In the event of any conflict between an order form
          and these Terms, the conflicting term in the order form will supersede with
          respect to such conflict.
        </p>
      </div>
    </div>
  )
}

export default TermsOfServicePage
