import React, { useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import ContentProgressTracker from 'domains/Cms/ContentProgressTracker'
import ExpertCollaborators from 'domains/Cms/ExpertCollaborators'
import ModalCelebration from 'domains/Cms/ModalCelebration'
import getSectionDataForTracking from 'domains/Cms/Toc/utils/getSectionDataForTracking'
import CmsTocLesson from 'domains/Cms/TocLesson'
import { useCohortDashboardTrackingContext } from 'domains/CohortDashboard/hooks/useCohortDashboardTrackingContext'

import { SVGIcon } from 'components/Icon'
import { useSideDrawer } from 'components/SideDrawer'
import { LessonContentLocation } from 'components/cards/Content/utils'
import { CloseIcon } from 'components/icons'

import { MIN_WIDTH_TAILWIND_TL } from 'constants/breakpoints'

import {
  CmsSectionContentType,
  CohortViewerNewCohortPartsFragment,
  ExpertUserPartsFragment,
  LessonParentSectionFragment,
  LessonSectionPartsFragment,
  LessonTocPartsFragment,
  TocUserFragment
} from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { cn } from 'utils/tailwind'
import { trackContentCompleted, trackCtaClicked } from 'utils/tracking/analytics'

import { ModuleProgress } from 'typings/scalars'

type ProgressUserData = {
  currentLessonId?: string
  userProgress?: ModuleProgress
  numSectionBookmarks: Record<number | string, number>
}

type UserData = { sections: LessonParentSectionFragment[] } & ProgressUserData

export type CmsSectionData = LessonParentSectionFragment & {
  current: boolean
  completed: boolean
  estimatedTimeCaption?: string
  numSectionBookmarks: number
  children?: CmsSectionData[]
}

function mergeCommonSectionsData({
  sections,
  currentLessonId,
  userProgress,
  numSectionBookmarks
}: { sections: LessonSectionPartsFragment[] } & ProgressUserData) {
  const mergedSections: CmsSectionData[] = []

  if (currentLessonId === undefined && sections && sections.length) {
    mergedSections.push({
      ...sections[0],
      current: true,
      numSectionBookmarks: 0,
      completed: false,
      children: []
    })
  }

  sections.forEach((section, idx) => {
    const mergedSection: CmsSectionData = {
      ...section,
      current: !!mergedSections[idx]?.current,
      numSectionBookmarks: 0,
      completed: false,
      children: []
    }
    mergedSection.current = section.id === currentLessonId
    mergedSection.completed = userProgress?.[section.id] === 'completed'
    mergedSection.estimatedTimeCaption =
      section.estimatedTime === 0 ? '' : `${section.estimatedTime} min`
    mergedSection.numSectionBookmarks = numSectionBookmarks[section.id]
      ? numSectionBookmarks[section.id]
      : 0

    mergedSections[idx] = mergedSection
  })

  return mergedSections
}

function mergeUserDataIntoCachedData({
  sections,
  currentLessonId,
  userProgress,
  numSectionBookmarks
}: UserData) {
  const mergedSections: CmsSectionData[] = mergeCommonSectionsData({
    sections,
    currentLessonId,
    userProgress,
    numSectionBookmarks
  })

  sections.forEach((section, idx) => {
    if (section.children && section.children.length > 0) {
      let parentCompleted = true
      let parentEstimatedTime = 0
      let parentNumSectionBookmarks = 0
      section.children.forEach((sectionChild: LessonSectionPartsFragment) => {
        if (`${userProgress?.[sectionChild.id]}` !== 'completed') parentCompleted = false
        parentEstimatedTime += sectionChild.estimatedTime
        parentNumSectionBookmarks += numSectionBookmarks[sectionChild.id]
          ? numSectionBookmarks[sectionChild.id]
          : 0
      })
      mergedSections[idx] = {
        ...mergedSections[idx],
        completed: parentCompleted,
        estimatedTimeCaption:
          parentEstimatedTime === 0 ? '' : `${parentEstimatedTime} min`,
        numSectionBookmarks: parentNumSectionBookmarks,
        children: mergeCommonSectionsData({
          sections: section.children,
          userProgress,
          numSectionBookmarks,
          currentLessonId
        })
      }
    }

    return section
  })

  return mergedSections
}

interface SectionProps {
  smallScreenOnClick?: () => void
  numSectionBookmarks: number
  cmsModuleId?: string
  section: CmsSectionData
  openSectionId?: string
  currentLessonId: string
  currentUser?: TocUserFragment
  cohortViewer?: boolean
  cohortSlug?: string
}

const Section = ({
  smallScreenOnClick,
  numSectionBookmarks,
  cmsModuleId,
  section,
  openSectionId,
  currentLessonId,
  currentUser,
  cohortViewer = false,
  cohortSlug
}: SectionProps) => {
  const hasChildren = section.children.length > 0
  const [isOpen, setIsOpen] = useState(section.id === openSectionId && hasChildren)
  const isSmallScreen = useMediaQuery(`(max-width: ${MIN_WIDTH_TAILWIND_TL})`)

  const onToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div
      className={`${isOpen ? 'uk-open' : ''} ${hasChildren ? 'mt-[18px] mb-[1px]' : ''}`}
      onClick={isSmallScreen ? smallScreenOnClick : undefined}
    >
      <CmsTocLesson
        numSectionBookmarks={numSectionBookmarks}
        cmsModuleId={cmsModuleId}
        section={section}
        isParentSection={hasChildren}
        isOpen={isOpen}
        onToggle={onToggle}
        currentUser={currentUser}
        currentLessonId={currentLessonId}
        cohortViewer={cohortViewer}
        cohortSlug={cohortSlug}
      />
      {hasChildren && (
        <ul className="uk-accordion-content mt-0 mb-0 list-none p-0">
          {section.children.map((lesson: CmsSectionData) => (
            <CmsTocLesson
              key={`tocless${lesson.id}`}
              numSectionBookmarks={lesson.numSectionBookmarks}
              cmsModuleId={cmsModuleId}
              section={lesson}
              isOpen={isOpen}
              currentUser={currentUser}
              currentLessonId={currentLessonId}
              cohortViewer={cohortViewer}
              cohortSlug={cohortSlug}
            />
          ))}
        </ul>
      )}
    </div>
  )
}

interface CmsTocProps {
  cmsModuleId?: string
  moduleName?: string
  programName?: string
  programSlug?: string
  nextSectionDetails?: string[]
  programWithoutLessons: boolean
  currentLessonId: string
  userProgress?: ModuleProgress
  currentUser?: TocUserFragment
  programLaunched: boolean
  releaseDate?: string | null
  sections: LessonParentSectionFragment[]
  experts: ExpertUserPartsFragment[]
  goBackLink: string
  goBackLabel?: string
  closeDrawer: () => void
  cohortViewer?: boolean
  cohortSlug?: string
  cohort?: CohortViewerNewCohortPartsFragment
  userCohortId?: string | null
}

interface CmsTocState {
  sections: CmsSectionData[]
  experts: ExpertUserPartsFragment[]
  modalCelebrationOnComplete: () => void
}

export class CmsToc extends React.Component<CmsTocProps, CmsTocState> {
  constructor(props: CmsTocProps, context: any) {
    super(props, context)

    const mergedSections = mergeUserDataIntoCachedData({
      sections: props.sections,
      userProgress: props.userProgress,
      numSectionBookmarks: props.currentUser?.numSectionBookmarks || {},
      currentLessonId: props.currentLessonId
    })

    this.state = {
      sections: mergedSections,
      experts: props.experts || [],
      modalCelebrationOnComplete: () => {}
    }
  }

  setSectionAsCompleted = (
    sectionId: string,
    callback: (a: CmsSectionData) => void = () => {}
  ) => {
    const copyOfSections: CmsSectionData[] = Object.assign([], this.state.sections)
    copyOfSections.forEach((copiedSection: CmsSectionData, idx: number) => {
      const section: CmsSectionData = copiedSection

      if (section.id === sectionId) {
        copyOfSections[idx].completed = true
        callback(copyOfSections[idx])
      }
      section.children.forEach(function (
        child: LessonSectionPartsFragment,
        childIdx: number
      ) {
        if (child.id === sectionId) {
          section.children[childIdx].completed = true
          callback(section.children[childIdx])
        }
      })
    })

    this.setState({ sections: copyOfSections })
  }

  // Find the section in the state object and change its value.
  changeNumSectionBookmarks = (sectionId: string, increment: number) => {
    const copyOfSections: CmsSectionData[] = Object.assign([], this.state.sections)
    copyOfSections.forEach((section: CmsSectionData, idx: number) => {
      if (section.id === sectionId) {
        copyOfSections[idx].numSectionBookmarks += increment
      }
      section.children.forEach((child: CmsSectionData, childIdx: number) => {
        if (child.id === sectionId) {
          section.children[childIdx].numSectionBookmarks += increment
        }
      })
    })

    this.setState({ sections: copyOfSections })
  }

  markSectionComplete = (cmsSectionId?: string) => {
    cmsSectionId &&
      this.setSectionAsCompleted(cmsSectionId, (updatedSection) => {
        // tracks when a Program Lesson is completed
        trackContentCompleted({
          content_id: parseInt(`${updatedSection.id}`, 10),
          content_name: updatedSection.name,
          content_type: updatedSection.contentType || 'Lesson',
          path: window.location.pathname
        })
      })
  }

  onContentProgressComplete = () => {
    this.markSectionComplete(this.props.currentLessonId)

    this.state.modalCelebrationOnComplete()
  }

  mountGlobalMethods() {
    window.changeNumSectionBookmarks = this.changeNumSectionBookmarks
    window.markSectionComplete = this.markSectionComplete
  }

  unmountGlobalMethods() {
    window.changeNumSectionBookmarks = undefined
    window.markSectionComplete = undefined
  }

  componentDidMount() {
    setTimeout(function () {
      const openSection = document.querySelector<HTMLElement>('.cms-sidebar .uk-open')
      const sidenavigation = document.getElementById('sidenav')
      if (
        openSection &&
        openSection !== openSection.parentNode?.firstElementChild &&
        typeof sidenavigation?.scrollTo === 'function'
      ) {
        const sidebarUtilities = document.querySelector<HTMLElement>(
          '.cms-sidebar__utilities'
        )
        const sidebarUtilitiesOffset = sidebarUtilities
          ? sidebarUtilities.offsetHeight
          : 0
        sidenavigation.scrollTo({
          top: openSection.offsetTop - sidebarUtilitiesOffset,
          left: 0,
          behavior: 'smooth'
        })
      }
    }, 500)

    this.mountGlobalMethods()
  }

  componentWillUnmount() {
    this.unmountGlobalMethods()
  }

  getOpenSectionId = (sections: CmsSectionData[]): string | undefined => {
    let openSectionId
    if (sections) {
      // foreach can't break recursion
      for (var i = 0; i < sections.length && openSectionId === undefined; i++) {
        var section = sections[i] // Local var to prevent multiple array lookups
        var children = section.children
        if (section.current) {
          openSectionId = section.parentId || section.id
        } else {
          openSectionId = this.getOpenSectionId(children)
        }
      }
    }
    return openSectionId
  }

  closeSidebarClicked = () => {
    this.props.closeDrawer()
  }

  onBackClick = () => {
    trackCtaClicked({
      cta_type: 'link',
      cta_location: 'cohort_dashboard_lesson_viewer_back_to_today',
      text: 'Back to today',
      related_identifiers: {
        cohort_id: this.props.cohort?.id,
        cohort_name: this.props.cohort?.name,
        user_cohort_id: this.props.userCohortId
      }
    })
  }

  render() {
    const {
      programSlug,
      programWithoutLessons,
      moduleName,
      programName,
      nextSectionDetails,
      userProgress,
      currentUser,
      currentLessonId,
      programLaunched,
      releaseDate,
      cmsModuleId,
      goBackLink,
      goBackLabel = 'Overview'
    } = this.props
    const { sections, experts } = this.state
    const openSectionId = this.getOpenSectionId(sections)
    const currentSectionProgress = userProgress?.[`${currentLessonId}d`]
    const startingProgress =
      currentSectionProgress &&
      typeof currentSectionProgress === 'object' &&
      currentSectionProgress.content_percent
        ? currentSectionProgress.content_percent
        : 0

    const [lessonContentProgramId, lessonContentName, lessonContentSubType] =
      getSectionDataForTracking(currentLessonId || '', this.state.sections)

    const currentParentSection =
      this.props?.sections?.find((s) => s.id === openSectionId) || null
    const currentSection =
      currentParentSection?.children?.find((c) => c.id === currentLessonId) ||
      currentParentSection

    return (
      <div>
        {cmsModuleId && currentLessonId && (
          <>
            <ModalCelebration
              cmsModuleId={cmsModuleId}
              cmsSectionId={currentLessonId}
              moduleName={moduleName}
              programName={programName}
              programSlug={programSlug}
              nextSectionDetails={nextSectionDetails}
              programLaunched={programLaunched}
              releaseDate={releaseDate}
              getOnComplete={(modalCelebrationOnComplete) => {
                this.setState({ modalCelebrationOnComplete })
              }}
              cohortViewer={this.props.cohortViewer}
              cohortSlug={this.props.cohortSlug}
            />
            <ContentProgressTracker
              cmsSectionId={currentLessonId}
              onComplete={this.onContentProgressComplete}
              startingProgress={startingProgress}
              cmsProgramId={lessonContentProgramId}
              cmsProgramName={programName}
              path={location.pathname}
              location={LessonContentLocation.ContentViewer}
              viewedInModal={false}
              contentType={currentSection?.contentType || CmsSectionContentType.LESSON}
              contentName={lessonContentName}
              contentSubtype={lessonContentSubType}
            />
          </>
        )}
        {moduleName && (
          <>
            <div
              className={twMerge(
                'cms-sidebar__header flex h-11 pt-3 pb-2 pr-3 md:pt-8 md:pb-8 md:pr-8',
                moduleName === 'Units' ? 'justify-end' : 'justify-between'
              )}
            >
              {moduleName !== 'Units' && (
                <div className="flex cursor-pointer items-center">
                  <Link
                    className="cms-sidebar__left-nav-hide flex items-center"
                    to={goBackLink}
                  >
                    <SVGIcon name="thin-chevron-left" />
                    <span className="pl-2 text-[14px] text-rb-gray-300 hover:text-rb-gray-400">
                      {goBackLabel}
                    </span>
                  </Link>
                </div>
              )}

              {currentUser && (
                <div
                  className="flex items-center"
                  uk-tooltip="title: Close Sidebar; pos: left; cls: uk-active rf-tooltip-right-arrow"
                >
                  <a
                    className="cms-sidebar__left-nav-hide flex items-center"
                    onClick={this.closeSidebarClicked}
                  >
                    <span className="hidden items-center text-rb-gray-300 hover:text-rb-gray-400 sm:flex">
                      <SVGIcon name="thin-chevron-left" />
                      <SVGIcon name="thin-chevron-left" />
                    </span>
                    <span className="flex sm:hidden">
                      <CloseIcon className="h-5 w-5 text-rb-gray-300 hover:text-rb-gray-400" />
                    </span>
                  </a>
                </div>
              )}
            </div>

            {currentUser && (
              <>
                <div className="pr-2.5 xs:pr-4 md:pr-8 ">
                  <hr className="cms-sidebar__divider mt-0" />
                </div>
                <h1 className="cms-sidebar__title m-0 py-5 font-sans text-base font-medium leading-[24px] tracking-normal !text-rb-gray-500">
                  Contents
                </h1>
              </>
            )}
          </>
        )}

        {currentUser && (
          <ul
            className="list-none p-0"
            uk-accordion={programWithoutLessons ? null : 'multiple: true'}
          >
            {sections.map((section: CmsSectionData) => (
              <Section
                smallScreenOnClick={this.closeSidebarClicked}
                key={`tossec${section.id}`}
                numSectionBookmarks={section.numSectionBookmarks}
                cmsModuleId={cmsModuleId}
                section={section}
                openSectionId={openSectionId}
                currentLessonId={currentLessonId}
                currentUser={currentUser}
                cohortViewer={this.props.cohortViewer}
                cohortSlug={this.props.cohortSlug}
              />
            ))}
          </ul>
        )}
        {!this.props.cohortViewer && moduleName && experts.length > 0 && (
          <>
            <div
              className={cn(
                'cms-sidebar__experts mt-[60px] pt-2.5 xs:pt-4',
                !currentUser && 'mt-0'
              )}
            >
              <div className="pr-2.5 xs:pr-4 md:pr-8 ">
                <hr className="cms-sidebar__divider" />
              </div>
              <h1 className="cms-sidebar__title m-0 mt-4 font-sans text-base font-medium leading-[24px] tracking-normal !text-rb-gray-500">
                Expert Collaborators
              </h1>
              <ExpertCollaborators experts={experts} />
            </div>
          </>
        )}
      </div>
    )
  }
}

export const getGoBackLinkHref = ({
  lessonViewer,
  currentUser,
  cohortSlug
}: CmsTocContainerProps) => {
  if (!lessonViewer) return '/'

  if (cohortSlug) {
    return `/cohorts/${cohortSlug}`
  }

  if (lessonViewer.cmsProgram?.upcoming) {
    return '/'
  }

  if (
    !currentUser ||
    (lessonViewer.cmsSection?.previewable && currentUser?.is.premember)
  ) {
    return `/programs/${lessonViewer.cmsProgram?.slug}/preview`
  }

  return `/programs/${lessonViewer.cmsProgram?.slug}`
}

export interface CmsTocContainerProps {
  currentUser?: TocUserFragment
  lessonViewer: LessonTocPartsFragment | null
  currentLessonId: string
  cohortViewer?: boolean
  cohortSlug?: string
}

const CmsTocContainer = ({
  currentUser,
  lessonViewer,
  currentLessonId,
  cohortViewer = false,
  cohortSlug
}: CmsTocContainerProps) => {
  const { closeDrawer } = useSideDrawer()
  const { cmsSectionParentSlug, cmsSectionSlug } = useParams<{
    cmsSectionParentSlug: string
    cmsSectionSlug: string
  }>()

  const cmsModule = lessonViewer?.cmsModule
  const cmsProgram = lessonViewer?.cmsProgram
  const userProgramProgress = lessonViewer?.userProgram?.progress || {}

  const { cohort, userCohortId } = useCohortDashboardTrackingContext()

  // Used to show a singular Unit for Units beta
  // If a member is viewing a Unit, only show that Unit and its resources
  // Otherwise show all programs sections for a module
  const shouldDisplayUnits = cmsModule?.name === 'Units'
  const unitSectionSlug = cmsSectionParentSlug || cmsSectionSlug
  const sections = shouldDisplayUnits
    ? cmsModule?.cmsSections?.filter((section) => section?.slug === unitSectionSlug)
    : cmsModule?.cmsSections

  const goBackLabel = useMemo(() => {
    if (cohortViewer) return 'Back to Today'

    return lessonViewer?.cmsSection?.previewable && currentUser?.is.premember
      ? 'Explore Course'
      : 'Overview'
  }, [cohortViewer, lessonViewer, currentUser])

  const goBackLink = useMemo(
    () =>
      getGoBackLinkHref({
        lessonViewer,
        currentUser,
        currentLessonId,
        cohortSlug
      }),
    [lessonViewer, currentUser, currentLessonId, cohortSlug]
  )

  return (
    <CmsToc
      cmsModuleId={cmsModule?.id}
      moduleName={cmsModule?.name}
      programName={cmsProgram?.name}
      programSlug={cmsProgram?.slug}
      nextSectionDetails={lessonViewer?.cmsSection?.nextSectionNameAndPath}
      programWithoutLessons={!!cmsProgram?.noLessons}
      userProgress={cmsModule ? userProgramProgress[cmsModule.id] : {}}
      currentLessonId={currentLessonId}
      currentUser={currentUser}
      programLaunched={!!cmsProgram?.launched}
      releaseDate={cmsProgram?.releaseStartDate}
      sections={sections || []}
      experts={lessonViewer?.expertUsers || []}
      closeDrawer={closeDrawer}
      goBackLabel={goBackLabel}
      goBackLink={goBackLink}
      cohortViewer={cohortViewer}
      cohortSlug={cohortSlug}
      cohort={cohort}
      userCohortId={userCohortId}
    />
  )
}

export default CmsTocContainer
