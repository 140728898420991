const BookmarkFolderEmptyState = () => (
  <div className="flex flex-col items-center justify-center rounded-xl bg-rb-gray-20 p-8">
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center">
        <div className="mb-1 font-semibold">Create your first collection</div>
        <div className="max-w-[480px] text-center text-rb-gray-300">
          Organize your saved items into a collection. Add to a collection by clicking on
          the menu on your saved items and bookmarks.
        </div>
      </div>
    </div>
  </div>
)

export default BookmarkFolderEmptyState
