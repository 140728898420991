import 'froala-editor/js/plugins.pkgd.min.js'
import React, { useState } from 'react'

import ConfirmDelete from 'domains/Cms/ConfirmDelete'
import InlineCommentsFroalaWrapper from 'domains/Cms/InlineCommentsFroalaWrapper'

import Button from 'components/Button'

import { InlinePostReply, useRemoveReplyMutation, useUpdateReplyMutation } from 'gql'

export interface CmsReplyProps {
  reply: InlinePostReply
  updateReply?: (updatedReply: InlinePostReply) => void
  deleteReply?: (replyId: string, postId: string) => void
  currentUserId?: string
}

const CmsReply = ({ reply, updateReply, deleteReply, currentUserId }: CmsReplyProps) => {
  const [showEditing, setShowEditing] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [replyText, setReplyText] = useState(reply.body)
  const [removeReplyMutation, { loading: isDeleting }] = useRemoveReplyMutation()
  const [updateReplyMutation, { loading: isUpdating }] = useUpdateReplyMutation()
  const canManageReply = reply.userId === currentUserId
  const menuContainerId = 'edit-reply'

  const onDeleteReply = async () => {
    await removeReplyMutation({
      variables: {
        input: {
          id: reply.id.toString()
        }
      }
    })

    deleteReply?.(reply.id, reply.replyableId)
  }

  const onSaveClick = async (e: React.MouseEvent | React.KeyboardEvent) => {
    e.preventDefault()
    try {
      await updateReplyMutation({
        variables: {
          input: {
            id: reply.id.toString(),
            body: replyText,
            substituteNewlines: true
          }
        }
      })

      const updatedReply = { ...reply, body: replyText.split('\n').join('<br />') }
      updateReply?.(updatedReply)
      setShowEditing(false)
    } catch (e) {
      // handled in component
    }
  }

  return (
    <div id={`inline-reply--${reply.id}`} className="inline-reply bg-white">
      <div className="p-5 pb-7.5">
        <div className="uk-grid uk-grid-collapse">
          <div className="uk-width-auto">
            <img
              className="inline-block !h-[25px] !w-[25px] rounded-[50%] border-none align-middle text-[8px]"
              src={reply.userAvatar}
              alt=""
            />
          </div>
          <div className="uk-width-expand">
            <h6 className="inline-block pl-2 align-bottom font-sans text-xs font-medium text-black">
              {reply.userFullName}
              {reply.userRoleWithCompany ? `, ${reply.userRoleWithCompany}` : ''}
            </h6>
          </div>
        </div>
        {!showEditing && (
          <div className="uk-grid uk-grid-collapse">
            <div
              className="uk-width-expand inline-reply__body mt-[15px]"
              dangerouslySetInnerHTML={{ __html: reply.body }}
            />
          </div>
        )}
        {showEditing && (
          <div id={menuContainerId}>
            <div className="mt-[5px] mb-2">
              <InlineCommentsFroalaWrapper
                model={replyText}
                updateModel={setReplyText}
                placeholder="Write your reply here..."
                menuContainerId={menuContainerId}
              />
            </div>

            <div className="flex">
              <Button
                disabled={replyText.length === 0 || isUpdating}
                size="x-small"
                className="mr-4"
                onClick={onSaveClick}
                color="teal"
              >
                Save
              </Button>
              <Button
                disabled={isUpdating}
                variant="outline"
                size="x-small"
                onClick={() => setShowEditing(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
        {!showDelete && !showEditing && canManageReply && (
          <div className="uk-clearfix uk-margin-small-top">
            <button
              uk-icon="icon: trash"
              className="float-right hover:text-rb-teal-400"
              onClick={() => setShowDelete(true)}
            />
            <button
              uk-icon="icon: pencil"
              className="float-right mr-1 hover:text-rb-teal-400"
              onClick={() => setShowEditing(true)}
            />
          </div>
        )}
        {showDelete && (
          <ConfirmDelete
            disabled={isDeleting}
            confirmAction={onDeleteReply}
            cancelAction={() => setShowDelete(false)}
            type="reply"
          />
        )}
      </div>
      {reply.comments && reply.comments.length > 0 && (
        <div className="inline-reply__children">
          {reply.comments.map((comment) => {
            const commentAsReply: InlinePostReply = {
              __typename: 'InlinePostReply',
              id: comment.id,
              replyableId: comment.parentId || '',
              body: comment.body || '',
              bodyText: comment.bodyText || '',
              userId: comment.user.id,
              userFullName: comment.user.fullName || '',
              userAvatar: comment.user.avatarUrl,
              userRoleWithCompany: comment.user.roleWithCompany || '',
              comments: []
            }

            return <CmsReply key={`comment${comment.id}`} reply={commentAsReply} />
          })}
        </div>
      )}
    </div>
  )
}

export default CmsReply
