import { useState } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import AccountLayout from 'domains/Account/AccountLayout'

import { ErrorMessage, Loading } from 'components'
import DropdownSelect from 'components/dropdowns/DropdownSelect/DropdownSelect'

import { useEngagementReportSummaryQuery, useEngagementReportTeamQuery } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import { trackNavigationClicked } from 'utils/tracking/analytics'

import { ContentEngagement } from './ContentEngagement/ContentEngagement'
import { TeamMemberTable } from './TeamMemberTable'
import { TeamMetrics } from './TeamMetrics/TeamMetrics'
import { TopCourses } from './TopCourses/TopCourses'

export const TeamEngagementPage = () => {
  const currentUser = useAssertCurrentUser()
  const history = useHistory()
  const defaultViewName = window.location.pathname.includes('summary')
    ? 'summary'
    : 'team'
  const [viewName, setViewName] = useState<'summary' | 'team'>(defaultViewName)

  if (!currentUser.can.manageMyTeam) {
    return <Redirect to="/account" />
  }

  if (window.location.pathname === '/team-engagement') {
    return <Redirect to="/team-engagement/summary" />
  }
  const options = [
    { label: 'summary', value: 'Summary view' },
    { label: 'team', value: 'Team view' }
  ]

  const handleViewSelection = (selection: 'summary' | 'team') => {
    const newRoute = selection === 'summary' ? 'summary' : 'team'
    setViewName(newRoute)
    history.push(newRoute)

    trackNavigationClicked({
      location: 'team_engagement_summary_page',
      type: 'button',
      text: `${selection} view`
    })
  }

  return (
    <div className="flex flex-col pb-12">
      <AccountLayout>
        <div className="flex justify-start items-start mb-5">
          <DropdownSelect
            data={options}
            value={viewName}
            onChange={handleViewSelection}
            className={'justify-start items-start flex'}
            optionsClassName="w-auto min-w-[200px]"
          />
        </div>
        <Switch>
          <Route exact path="/team-engagement/summary">
            <SummaryView />
          </Route>
          <Route exact path="/team-engagement/team">
            <TeamView />
          </Route>
        </Switch>
      </AccountLayout>
    </div>
  )
}

const SummaryView = () => {
  const { data, error, loading } = useEngagementReportSummaryQuery()
  if (loading) return <Loading />
  if (error || !data?.engagementReportSummary) return <ErrorMessage error={error} />

  const {
    contentViewedCount,
    insightsSavedCount,
    liveCourseEnrollmentCount,
    coursesWorkedOnCount,
    topCourses,
    topLessons,
    recentLessons,
    recentSavedItems
  } = data.engagementReportSummary

  return (
    <div>
      <TeamMetrics
        contentViewedCount={contentViewedCount}
        insightsSavedCount={insightsSavedCount}
        liveCourseEnrollmentCount={liveCourseEnrollmentCount}
        coursesWorkedOnCount={coursesWorkedOnCount}
      />
      <TopCourses topCourses={topCourses} />
      <ContentEngagement
        topLessons={topLessons}
        recentLessons={recentLessons}
        recentSavedItems={recentSavedItems}
      />
    </div>
  )
}

const TeamView = () => {
  const { data, error, loading } = useEngagementReportTeamQuery()
  if (loading) return <Loading />
  if (error) return <ErrorMessage error={error} />
  if (
    !data?.engagementReportTeam?.teamMembers ||
    data.engagementReportTeam.teamMembers.length < 1
  ) {
    return <h3>No team data available.</h3>
  }

  return (
    <div>
      <TeamMemberTable teamMembers={data.engagementReportTeam.teamMembers} />
    </div>
  )
}
