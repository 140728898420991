import type { ComponentProps } from 'react'
import { useState } from 'react'
import Pluralize from 'react-pluralize'

import AuthorDropdownContent from './AuthorDropdownContent'
import FacePile from './Facepile'
import type { AuthorBylineProps } from './artifact-types'

type FacePileDropdownProps = Omit<ComponentProps<typeof FacePile>, 'users'> & {
  label: string
  bylines: AuthorBylineProps[]
}
/**
 * This is a face pile of authors that, when clicked, opens a list of extended info about each individual in the pile.
 *
 * Note: This leverages the {@link AuthorDropdownContent}, which is eerily similar to the {@link #components/artifacts-viewer/contributor-dropdown/ContributorDropdown}.
 * The two can't be easily combined due to the way authors and contributors are modeled in sanity.
 * That modeling issue should likely be fixed before attempting to generalize this component to both authors and contributors.
 */
const FacePileDropdown = ({
  bylines,
  label,
  ...facePileProps
}: FacePileDropdownProps) => {
  const [showContents, setShowContents] = useState(false)
  const toggleContents = () => setShowContents(!showContents)
  return (
    <div className="relative">
      <button
        className="flex items-center bg-white hover:cursor-pointer hover:text-rb-teal-300"
        onClick={toggleContents}
      >
        <FacePile
          users={bylines.map(({ author }) => ({
            id: author.id,
            avatarUrl: author.avatarUrl || '',
            avatarPhotoAlt: `${author.name} avatar`
          }))}
          {...facePileProps}
        />
        <div className="pl-10 text-xs font-semibold">
          <Pluralize count={bylines.length} singular={label} />
        </div>
      </button>
      {showContents && (
        <AuthorDropdownContent
          authors={bylines}
          isOpen={showContents}
          setIsOpen={setShowContents}
        />
      )}
    </div>
  )
}

export default FacePileDropdown
