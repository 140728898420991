import { Link } from 'react-router-dom'

import Image from 'domains/Sanity/Image'

import { cn } from 'utils/tailwind'
import { trackNavigationClicked } from 'utils/tracking/analytics'

import LogoImage from 'images/reforge-logo-centered.svg'
import LogoSymbol from 'images/reforge-logo-symbol-black.svg'

const Logo = ({ mobileNavOpen }: { mobileNavOpen?: boolean }) => {
  return (
    <Link
      to="/"
      className="flex items-center lg:mr-4"
      onClick={() =>
        trackNavigationClicked({
          location: 'marketing_site__top_nav',
          type: 'top navigation',
          destination: '/',
          text: '-logo-'
        })
      }
    >
      <Image
        src={LogoSymbol}
        alt="Reforge Logo"
        height={40}
        width={40}
        className={cn(
          'max-h-5 w-auto max-w-full',
          mobileNavOpen ? 'block sm:hidden' : 'hidden'
        )}
      />

      <Image
        src={LogoImage}
        alt="Reforge Logo"
        height={40}
        width={168}
        className={cn(
          'max-h-6 xs:max-h-8 lg:max-h-10 w-auto max-w-full',
          mobileNavOpen && 'hidden sm:block'
        )}
      />
    </Link>
  )
}
export default Logo
