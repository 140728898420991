import { closing, opening } from './sharedMarkdown'

const programName = 'General Membership'

const markdown = `
${opening}

${closing}
`

export default { markdown, title: programName }
