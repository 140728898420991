import { useUserBookmarksFeedQuery } from 'gql'

interface UserBookmarks {
  cmsModuleId: string
  cmsProgramId: string
  cmsSectionId?: string
  userId: string
}

export const useUserBookmarks = ({
  cmsModuleId,
  cmsProgramId,
  cmsSectionId = '',
  userId
}: UserBookmarks) => {
  const bookmarks: { count?: number; items?: any } | null = {}
  const variables = {
    userId,
    filters: {
      cmsModuleId,
      cmsProgramId,
      cmsSectionId
    }
  }

  const { data, loading, error } = useUserBookmarksFeedQuery({
    variables
  })

  if (!loading) {
    bookmarks.count = data?.user?.bookmarksFeed.total || 0
    bookmarks.items = data?.user?.bookmarksFeed.bookmarks || []
  }

  return {
    bookmarks,
    hasBookmarks: !!bookmarks.count,
    isLoading: loading,
    error: error
  }
}
