import { Link, useLocation, useParams } from 'react-router-dom'

import Image from 'domains/Sanity/Image'
import BlogPostCard from 'domains/Sanity/marketingSite/BlogPostCard'
import BlogSubscribeEmailSection from 'domains/Sanity/marketingSite/BlogSubscribeEmailSection'
import FeaturedBlogPostCard from 'domains/Sanity/marketingSite/FeaturedBlogPostCard'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { Loading } from 'components'

import { BlogIndex, BlogPost, useBlogIndexQuery } from 'gql'

import BluePinkGradientHeader from 'images/blue-pink-gradient-header.png'
import { ReactComponent as ChevronLeftIcon } from 'images/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from 'images/chevron-right.svg'

const PAGINATION_LIMIT = 20

const BlogIndexPage = () => {
  const { category } = useParams<{ category: string }>()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const lastId = query.get('lastId')
  const reverse = query.get('reverse')
  const isReverse = reverse === 'true'
  const { data, loading } = useBlogIndexQuery({
    variables: {
      category,
      lastId,
      limit: PAGINATION_LIMIT,
      reverse: isReverse
    }
  })

  const { page } = data?.blogIndex || {}

  const getPageTitleWithCategory = () => {
    if (page?.seo?.metaTitle && category) {
      return `${category.charAt(0).toUpperCase() + category.slice(1)}  ${
        page.seo.metaTitle
      }`
    }
  }

  return (
    <MarketingLayout page={page} pageTitle={getPageTitleWithCategory()}>
      {loading || !data?.blogIndex ? (
        <Loading />
      ) : (
        <BlogIndexContent
          data={data.blogIndex}
          isReverse={isReverse}
          lastIdParam={lastId || ''}
        />
      )}
    </MarketingLayout>
  )
}

export const BlogIndexContent = ({
  data,
  isReverse,
  lastIdParam,
  isChromatic = false
}: {
  data: BlogIndex
  isReverse: boolean
  lastIdParam: string
  isChromatic?: boolean
}) => {
  const { blogPosts, featuredBlogPosts, nextBlogPostsCount, prevBlogPostsCount } = data
  const firstId = blogPosts[0]?.id
  const lastId = blogPosts[blogPosts.length - 1]?.id
  const hasNext = isReverse
    ? nextBlogPostsCount + 1 > 0
    : nextBlogPostsCount > PAGINATION_LIMIT
  const hasPrev =
    lastIdParam &&
    (isReverse
      ? prevBlogPostsCount > PAGINATION_LIMIT
      : prevBlogPostsCount + 1 >= PAGINATION_LIMIT)

  return (
    <>
      <section className="relative mb-[40px] flex min-h-[33rem] flex-col justify-center px-[16px] md:px-[4vw]">
        <Image
          src={BluePinkGradientHeader}
          fill
          alt=""
          priority
          className="-z-1 object-cover object-[center_bottom]"
        />
        <h1 className="mb-[32px] font-normal leading-none tracking-tight fluid-text-8xl">
          The Reforge Blog
        </h1>
        <h3 className="max-w-[60rem] font-normal leading-[1.2] tracking-tight fluid-text-3xl">
          Where top product and growth leaders challenge the status quo.
        </h3>
      </section>

      {featuredBlogPosts?.length > 0 && (
        <>
          <section className="mb-[40px] flex flex-col-reverse px-[16px] md:mb-[80px] md:flex-row md:items-center md:justify-between md:px-[4vw]">
            <h3 className="font-normal leading-[1.2] tracking-tight fluid-text-3xl">
              Featured Blog Posts
            </h3>
          </section>

          <section className="mb-[40px] flex w-full flex-wrap px-[16px] md:-ml-[1.5vw] md:w-[calc(100%+3vw)] md:px-[4vw]">
            {featuredBlogPosts?.map((blogPost: BlogPost) => (
              <FeaturedBlogPostCard key={blogPost.id} {...blogPost} />
            ))}
          </section>
        </>
      )}

      <BlogSubscribeEmailSection />

      <section className="px-[16px] pt-[4vw] md:px-[4vw]">
        {blogPosts.map((blogPost) => (
          <BlogPostCard key={blogPost.id} {...blogPost} isChromatic={isChromatic} />
        ))}
      </section>

      <nav className="mb-[75px] flex items-center justify-between px-[16px] md:px-[4vw]">
        {hasPrev && (
          <Link
            to={`${window.location.pathname}?lastId=${firstId}&reverse=true`}
            className="flex items-center font-serif text-rb-black no-underline fluid-text-base visited:text-rb-black"
          >
            <ChevronLeftIcon className="mr-[16px] w-[10px]" /> Newer Posts
          </Link>
        )}
        {hasNext && (
          <Link
            to={`${window.location.pathname}?lastId=${lastId}`}
            className="ml-auto flex items-center font-serif text-rb-black no-underline fluid-text-base visited:text-rb-black"
          >
            Older Posts <ChevronRightIcon className="ml-[16px] w-[10px]" />
          </Link>
        )}
      </nav>
    </>
  )
}

export default BlogIndexPage
