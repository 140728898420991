import InfiniteScroll from 'react-infinite-scroller'
import { Link } from 'react-router-dom'

import { Loading } from 'components'
import DefaultAvatar from 'components/ActivityFacepile/DefaultAvatar'
import UserPositionAndCompany from 'components/SearchModal/UserPositionAndCompany'

import { User } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { cn } from 'utils/tailwind'

const SearchModalList = ({
  hasMoreUsers,
  loadMore,
  users
}: {
  hasMoreUsers: boolean
  loadMore: () => void
  users: any
}) => {
  const { currentUser } = useCurrentUser()

  const linkToDirectoryProfile = currentUser?.is.paidMember

  return (
    <InfiniteScroll
      loadMore={loadMore}
      hasMore={hasMoreUsers}
      loader={<Loading key={0} />}
      getScrollParent={() => document.querySelector('#rf-modal-content')}
      useWindow={false}
    >
      <div className="mt-8 grid grid-flow-row gap-0">
        {users.map((user: User) => {
          const isDefaultAvatar =
            !!(user.profile?.avatarUrl?.includes('default_avatar') && user.fullName) ||
            !user.profile?.avatarUrl

          const userListItem = (
            <div className="flex items-center">
              <div className="mr-4 h-12 w-12">
                {isDefaultAvatar && user.fullName ? (
                  <DefaultAvatar
                    fullName={user.fullName}
                    className="h-12 w-12 rounded-full"
                  />
                ) : (
                  <img
                    className="h-12 w-12 rounded-full"
                    src={user.profile?.avatarUrl || ''}
                    alt={''}
                  />
                )}
              </div>

              <div className="text-base">
                <div className="mb-1 font-bold text-rb-gray-400">
                  {user.profile?.fullName}
                </div>
                <div className="text-rb-gray-300">
                  <UserPositionAndCompany
                    position={user?.profile?.role}
                    companyName={user?.profile?.companyName}
                  />
                </div>
              </div>
            </div>
          )

          return (
            <div
              key={`user-feed-${user.id}`}
              className={cn(
                'block px-8 py-4 transition duration-200 ease-in-out md:px-11',
                linkToDirectoryProfile &&
                  'cursor-pointer hover:bg-rb-gray-50 hover:no-underline'
              )}
            >
              {linkToDirectoryProfile ? (
                <Link to={`/directory/${user.id}`}>{userListItem}</Link>
              ) : (
                userListItem
              )}
            </div>
          )
        })}
      </div>
    </InfiniteScroll>
  )
}

export default SearchModalList
