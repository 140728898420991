import ExpertCard from 'domains/Sanity/marketingSite/ExpertCard'
import MarketingHero from 'domains/Sanity/marketingSite/MarketingHero'

import Button from 'components/Button'
import ColumnsOfCheckboxesAndContent from 'components/MarketingSite/ColumnsOfCheckboxesAndContent'
import PreFooterCTA from 'components/MarketingSite/PreFooterCTA'
import ThreeColumnsWithIcons from 'components/MarketingSite/ThreeColumnSectionWithIcons'
import ArrowUpRight from 'components/icons/ArrowUpRight'

import { useArtifactAuthorProfilesQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useURLParams from 'hooks/useURLParams'

import { trackCtaClicked, trackNavigationClicked } from 'utils/tracking/analytics'
import { getAnonymousId } from 'utils/tracking/segment'

import YellowOrange from 'images/Yellow-Orange.png'
import CreatorIcon from 'images/marketing-round-icons/creator.png'
import DocsIcon from 'images/marketing-round-icons/docs.png'
import SubscriptionIcon from 'images/marketing-round-icons/subscription.png'
import ReforgeCreators from 'images/reforge-creators.png'

import { createCreatorTypeformURL } from '../utils'
import JoinExpertCommunitySection from './JoinExpertCommunitySection/JoinExpertCommunitySection'

export const CreatorLandingPageContent = () => {
  const { isLoggedIn, currentUser } = useCurrentUser()
  const { getParam } = useURLParams()
  const anonymousId = getAnonymousId()
  const expertSlugs = ['div-manickam', 'adam-fishman', 'jess-joyce', 'ben-williams']
  const { data } = useArtifactAuthorProfilesQuery({
    variables: {
      slug: expertSlugs
    }
  })
  const creatorCtaText = 'View profile'

  function sortData(authors: any) {
    const authorData = [...authors]

    authorData.sort((a: any, b: any) => {
      // Get the order index of the author slugs
      const indexA = expertSlugs.indexOf(a.artifactAuthor!.slug!)
      const indexB = expertSlugs.indexOf(b.artifactAuthor!.slug!)

      // If an author slug is not found in the order array, put it at the end
      if (indexA === -1 && indexB === -1) return 0 // Both are not in the order array
      if (indexA === -1) return 1 // A is not in the order array, sort B before A
      if (indexB === -1) return -1 // B is not in the order array, sort A before B

      return indexA - indexB
    })

    return authorData.map((obj) => obj.artifactAuthor)
  }

  const typeformUrl = createCreatorTypeformURL({
    internalRef: getParam('internal_ref'),
    utmCampaign: getParam('utm_campaign'),
    utmMedium: getParam('utm_medium'),
    utmSource: getParam('utm_source'),
    externalId: currentUser?.externalId,
    anonymousId: anonymousId
  })

  const handleApplyClick = () => {
    trackCtaClicked({
      text: 'apply',
      cta_type: 'button',
      cta_location: 'creator_landing_page__get_started_section',
      destination: typeformUrl,
      logged_in: isLoggedIn
    })

    // If we handle this with an href, the tracking doesn't fire
    // before the window destination changes.
    window.open(typeformUrl, '_blank')
  }

  const handleViewAllClick = () => {
    trackCtaClicked({
      cta_location: 'creator_landing_page__current_creators_section',
      cta_type: 'button',
      text: 'view all creators',
      logged_in: isLoggedIn
    })

    window.location.href = '/experts'
  }

  const handleCreatorProfileClick = ({
    href,
    slug,
    name
  }: {
    href: string
    slug: string
    name: string
  }) => {
    trackNavigationClicked({
      destination: href,
      type: 'card',
      location: 'creator_landing_page__current_creators_section',
      text: creatorCtaText?.toLowerCase(),
      logged_in: isLoggedIn,
      related_identifiers: {
        author_name: name,
        author_slug: slug
      }
    })
  }

  return (
    <>
      <section>
        <MarketingHero
          headerText="Become a member of the Reforge expert network"
          description="Share examples of your work to unlock new opportunities."
          bgImage={{
            url: YellowOrange,
            alt: 'gradient background image'
          }}
          heroImage={{
            url: ReforgeCreators,
            alt: ''
          }}
          ctas={[
            {
              href: typeformUrl,
              text: 'Apply',
              location: 'creator_landing_page__hero',
              target: '_blank',
              includeExternalLinkArrow: true
            }
          ]}
          noCtaMargin
          contentClassName="[&_.button-marketing-cta]:mt-[2vw]"
        />
      </section>
      <section className="bg-rb-background-beige px-[16px] py-[6.6vmax] md:px-[4vw]">
        <ThreeColumnsWithIcons
          headerText="Why should you become a creator?"
          icon1={{
            url: CreatorIcon,
            alt: 'creator icon'
          }}
          column1Header="Join an exclusive network"
          column1Text="Build your brand as part of the Reforge creator program and collective network, an exclusive group of experts."
          icon2={{
            url: DocsIcon,
            alt: 'docs icon'
          }}
          column2Header="Share your hard-won learnings"
          column2Text="Contribute your voice and shape the conversation with the top talent in tech."
          icon3={{
            url: SubscriptionIcon,
            alt: 'subscription icon'
          }}
          column3Header="Earn free access and more"
          column3Text="Receive up to a year of Reforge access, including courses and AI tools, plus opportunities for revenue share."
        />
      </section>
      <section className="px-[16px] py-16 md:px-[4vw]">
        <JoinExpertCommunitySection typeformUrl={typeformUrl} />
      </section>
      <section id="get_started" className="bg-rb-orange-25 px-[16px] p-12 md:px-[4vw]">
        <h2 className="font-normal tracking-tight fluid-text-6xl">
          Get started publishing with Reforge
        </h2>
        <p className="mb-12 font-normal tracking-tight fluid-text-2xl sm:mb-8 xl:fluid-text-3xl">
          Partner with the Reforge team to create top-tier content
        </p>
        <ColumnsOfCheckboxesAndContent
          column1Content={
            <>
              <p className="mb-2 font-normal text-2xl text-rb-gray-300">Step 1</p>
              <p className="mb-2 font-normal text-3xl text-rb-gray-400">
                Submit your artifacts
              </p>
              <p className="mb-0 font-normal text-base text-rb-gray-400">
                Artifacts should be real work you&apos;ve created, like presentations,
                strategy docs, PRDs, dashboards, or even frameworks and demos. The team
                will review to see if they&apos;re a fit for the Reforge audience.
              </p>
            </>
          }
          column2Content={
            <>
              <p className="mb-2 font-normal text-2xl text-rb-gray-300">Step 2</p>
              <p className="mb-2 font-normal text-3xl text-rb-gray-400">
                Collaborate with Reforge
              </p>
              <p className="mb-0 font-normal text-base text-rb-gray-400">
                The Reforge team will ask you detailed questions about your artifacts to
                get the behind-the-scenes story and capture your unique insights. Then,
                we&apos;ll draft author notes that highlight this context and bring your
                artifacts to life.
              </p>
            </>
          }
          column3Content={
            <>
              <p className="mb-2 font-normal text-2xl text-rb-gray-300">Step 3</p>
              <p className="mb-2 font-normal text-3xl text-rb-gray-400">
                Review and publish
              </p>
              <p className="mb-0 font-normal text-base text-rb-gray-400">
                You&apos;ll review final drafts of your artifacts. The Reforge team will
                make any needed edits and then publish and promote your artifact to our
                members.
              </p>
            </>
          }
        />
        <div className="my-12 flex flex-row items-center justify-center space-x-6">
          <Button size="large" shape="rounded-none" onClick={handleApplyClick}>
            Apply
            <ArrowUpRight className="ml-2" width={16} height={16} />
          </Button>
        </div>
      </section>
      {data?.artifactAuthorProfiles?.length && (
        <section id="current-creators" className="bg-white flex-col flex p-12">
          <h2 className="font-normal tracking-tight fluid-text-6xl mb-12">
            Current creators on Reforge
          </h2>
          <div className="grid grid-cols-1 items-stretch gap-[20px] sm:grid-cols-2 md:grid-cols-3 lgr:grid-cols-4">
            {sortData(data.artifactAuthorProfiles).map((expert, index) => (
              <ExpertCard
                onCardClick={() => handleCreatorProfileClick(expert)}
                onCtaClick={() => handleCreatorProfileClick(expert)}
                key={index}
                expert={expert}
                ctaText={creatorCtaText}
                href={`/profiles/${expert.slug}`}
              />
            ))}
          </div>

          <div className="flex w-full justify-center mt-12">
            <Button onClick={handleViewAllClick}>View all creators</Button>
          </div>
        </section>
      )}
      <PreFooterCTA
        headerText="Interested in becoming a creator with Reforge?"
        description="Share your first artifact to join our network of experts."
        cta={{
          href: typeformUrl,
          text: 'Apply',
          location: 'creator_landing_page__prefooter',
          includeExternalLinkArrow: true
        }}
      />
    </>
  )
}

export default CreatorLandingPageContent
