import SkeletonRfParagraph from 'components/skeletons/typography/SkeletonRfParagraph'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import { BookmarkType, ProgramBookmarkPartsFragment } from 'gql'

const SkeletonPresentationItem = () => {
  return (
    <div className="rf-rb-card-interactive flex h-[250px] w-full flex-col p-5 opacity-75 sm:p-6">
      <div className="flex h-full animate-pulse flex-col justify-between gap-4 rounded-md border border-rb-gray-100 bg-white px-2 pt-4">
        <div className="w-1/3 rounded-md bg-rb-gray-100 py-2" />
        <div className="w-full rounded-xl bg-rb-gray-100 py-4" />
        <div className="flex flex-col gap-2">
          <div className="w-full rounded-xl bg-rb-gray-100 py-[6px]" />
          <div className="w-full rounded-xl bg-rb-gray-100 py-[6px]" />
          <div className="w-full rounded-xl bg-rb-gray-100 py-[6px]" />
        </div>
        <SkeletonRfParagraph className="mt-4 h-4 w-3/12" />
      </div>
    </div>
  )
}

const TextPresentationItem = ({
  bookmark
}: {
  bookmark: ProgramBookmarkPartsFragment
}) => {
  return (
    <RfParagraphMedium>
      <span dangerouslySetInnerHTML={{ __html: bookmark.noteBody || '' }} />
    </RfParagraphMedium>
  )
}

const CollectionPresentationItem = ({
  bookmark
}: {
  bookmark: ProgramBookmarkPartsFragment
}) => {
  if (bookmark.type === BookmarkType.TEXTBLOCKBOOKMARK) {
    return <TextPresentationItem bookmark={bookmark} />
  } else {
    return <SkeletonPresentationItem />
  }
}

export default CollectionPresentationItem
