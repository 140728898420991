import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom-v5-compat'

import { Modal, ModalContent, ModalHeader } from 'components/Modal'
import CheckmarkBorderlessIcon from 'components/icons/CheckmarkBorderlessIcon'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

export interface SeatSuccessModalProps {
  isOpen: boolean
  handleClose: () => void
  modalType: 'removed' | 'transferred' | 'released' | 'assigned' | 'reinstated'
  dataTest: string
  closeModalTime?: number
  isLegacyUser: boolean
}

const SeatSuccessModal = ({
  isOpen,
  handleClose,
  modalType,
  dataTest,
  closeModalTime,
  isLegacyUser
}: SeatSuccessModalProps) => {
  const [shouldRedirect, setShouldRedirect] = useState(false)

  useEffect(() => {
    if (isOpen && closeModalTime) {
      setTimeout(handleClose, closeModalTime)
    }
  }, [isOpen, handleClose, closeModalTime])

  // NOTE: `accessAllCohortEventReplays` basically means they are a pre(P&P) legacy user
  const teamMembersDestination = isLegacyUser ? '/my_team/members' : '/team_members'

  return (
    <>
      {shouldRedirect && <Navigate to={teamMembersDestination} />}
      <Modal
        isOpen={isOpen}
        handleClose={() => {
          handleClose()
          if (window.location.pathname !== teamMembersDestination) {
            setShouldRedirect(true)
          }
        }}
        className="max-w-3xl"
        dataTest={dataTest}
      >
        <div className="flex flex-col items-center">
          <ModalHeader className="mb-5">
            <CheckmarkBorderlessIcon />
          </ModalHeader>
        </div>
        <ModalContent className="mt-2 pb-16 text-center" scrollContent={false}>
          <RfParagraphMedium>{`You've successfully ${modalType} the seat`}</RfParagraphMedium>
        </ModalContent>
      </Modal>
    </>
  )
}

export default SeatSuccessModal
