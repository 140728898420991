import React from 'react'

import LogoPicker from 'domains/Profile/LogoPicker'

import { Modal, ModalContent, ModalHeader, ModalTitle } from 'components/Modal'

interface TeamLogoModalProps {
  logoUrl: string | null | undefined
  isModalOpen: boolean
  handleCloseModal: () => void
  setLogoInForm: (url: string | null) => void
}

const TeamLogoModal = ({
  logoUrl,
  isModalOpen,
  handleCloseModal,
  setLogoInForm
}: TeamLogoModalProps) => {
  return (
    <Modal isOpen={isModalOpen} handleClose={handleCloseModal} className="max-w-2xl">
      <ModalHeader>
        <ModalTitle>Edit Team Logo</ModalTitle>
      </ModalHeader>
      <ModalContent className="overflow-y-auto">
        <LogoPicker
          logoUrl={logoUrl}
          handleCloseModal={handleCloseModal}
          setLogoInForm={setLogoInForm}
        />
      </ModalContent>
    </Modal>
  )
}

export default TeamLogoModal
