const DragHandleIcon = ({ fill, className }: { fill?: string; className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    className={className}
    fill={fill || '#080A0A'}
  >
    <circle cx="15" cy="12" r="1.5"></circle>
    <circle cx="15" cy="24" r="1.5"></circle>
    <circle cx="21" cy="12" r="1.5"></circle>
    <circle cx="21" cy="24" r="1.5"></circle>
    <circle cx="21" cy="18" r="1.5"></circle>
    <circle cx="15" cy="18" r="1.5"></circle>
    <rect x="0" y="0" width="36" height="36" fillOpacity="0" />
  </svg>
)

export default DragHandleIcon
