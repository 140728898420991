import { ComplexPortableTextBlockProp } from '../../types'

const renderListItem = ({ children, value }: ComplexPortableTextBlockProp) => (
  <li id={value?._key}>{children}</li>
)

const listItem = {
  bullet: renderListItem,
  number: renderListItem
}

export default listItem
