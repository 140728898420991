const EndOfModuleOverlay = ({ currentModuleName }: { currentModuleName: string }) => (
  <div className="-mt-10 flex flex-col justify-center gap-3 text-center">
    <span className="font-sans text-base uppercase text-white">
      You&apos;ve completed
    </span>
    <span className="font-sans text-xl font-medium text-white">{currentModuleName}!</span>
  </div>
)

export default EndOfModuleOverlay
