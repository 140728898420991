import { KeyboardEvent, MouseEvent } from 'react'
import { useHistory } from 'react-router-dom'

import Button from 'components/Button'

import { EventPartsFragment } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { getCurrentTimezone } from 'utils/date'
import { isEventInSession } from 'utils/eventUtils'
import { trackCtaClicked } from 'utils/tracking/analytics'
import { EVENT_PAGE_URL, makeUrl } from 'utils/url'

export const EventIndexAttendButton = ({ event }: { event: EventPartsFragment }) => {
  const history = useHistory()
  const dataTestId = `event-card-button-${event.id}`
  const isAttending = event.attendingStatus === 'Attending'
  const { isLoggedIn } = useCurrentUser()

  const onAttendClick = (
    e:
      | MouseEvent<HTMLButtonElement | HTMLAnchorElement>
      | KeyboardEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault()

    if (!isLoggedIn) {
      trackCtaClicked({
        cta_type: 'button',
        cta_location: 'upcoming_event_card',
        related_identifiers: {
          event_id: event.id,
          event_title: event.name,
          event_kind: event.kind,
          is_completed_event: event.past
        },
        text: 'Attend'
      })

      const referer = `${makeUrl(EVENT_PAGE_URL, {
        eventID: event.id,
        eventSlug: event.slug
      })}?rsvp=true`

      return history.push(
        makeUrl(
          '/login',
          {},
          {
            forEventRSVP: true,
            referer,
            eventId: event.id,
            userTimezone: getCurrentTimezone(null),
            happening: isEventInSession(event.startsAtUtc, event.endsAtUtc)
          }
        )
      )
    }

    const eventShowPagePath = `/events/${event.id}-${event.slug}`

    if (event.past) {
      return history.push(`${eventShowPagePath}/summary`)
    }

    if (isAttending) {
      return history.push(eventShowPagePath)
    }

    trackCtaClicked({
      cta_type: 'button',
      cta_location: 'upcoming_event_card',
      related_identifiers: {
        event_id: event.id,
        event_title: event.name,
        event_kind: event.kind,
        is_completed_event: event.past
      },
      text: 'Attend'
    })

    history.push(`${eventShowPagePath}?rsvp=upcoming_event_card`)
  }

  if (event.past) {
    return (
      <Button
        className="ml-0 h-10 w-fit gap-x-2 border-1.5 font-sans text-xs font-semibold"
        variant="outline"
        color="default"
        shape="rounded"
        size="x-small"
        iconClassName="!mr-1"
        onClick={onAttendClick}
      >
        View Recording
      </Button>
    )
  }

  return isAttending ? (
    <Button
      dataTest={dataTestId}
      className={
        'ml-0 h-10 w-fit gap-x-2 border-1.5 bg-rb-success-50 font-sans text-xs font-semibold'
      }
      variant="outline"
      color="teal"
      shape="rounded"
      size="x-small"
      iconClassName="!mr-1"
      onClick={onAttendClick}
    >
      <span className="font-sans text-sm font-semibold text-rb-teal-600">
        {"You're in!"}
      </span>
    </Button>
  ) : (
    <Button
      dataTest={dataTestId}
      className={'h-10 w-[78px] gap-x-2  border-1.5 font-sans text-xs font-semibold'}
      variant="fill"
      shape="rounded"
      size="x-small"
      iconClassName="!mr-1"
      onClick={onAttendClick}
    >
      Attend
    </Button>
  )
}
