import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import Button, { ButtonVariant } from 'components/Button'

interface ISimpleCardWithAction {
  title: string
  linkText: string
  description: string | React.ReactNode
  linkAction: () => void
  disableActionButton: boolean
  icon: React.ReactNode
  minHeight?: string
  buttonVariant?: ButtonVariant
}

const SimpleCardWithAction = ({
  title,
  linkText,
  icon,
  linkAction,
  description,
  disableActionButton,
  minHeight,
  buttonVariant = 'text-only'
}: ISimpleCardWithAction) => {
  return (
    <div
      style={{ minHeight: minHeight || '210px' }}
      onClick={disableActionButton ? undefined : linkAction}
      className={`flex h-full p-4 ${
        disableActionButton ? 'rf-rb-card' : 'rf-rb-card-interactive'
      }`}
    >
      <div className="mr-4">
        <div className="h-8 w-8">{icon}</div>
      </div>
      <div className="flex flex-1 flex-col">
        <div className="mb-3 text-lg font-medium">{title}</div>
        <div className="mb-3 text-rb-gray-400">{description}</div>
        <div className="mt-2 flex h-full w-full justify-end">
          <Button
            variant={buttonVariant}
            size="x-small"
            shape="rounded-full"
            className={twMerge(
              'h-8 self-end',
              buttonVariant === 'text-only' ? 'font-medium' : ''
            )}
            disabled={disableActionButton}
            onClick={linkAction}
            style={{
              minWidth: '8rem'
            }}
          >
            {linkText}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SimpleCardWithAction
