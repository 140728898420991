import WeeklyBlockEventCard from 'domains/CohortDashboard/CohortDashboardProgram/CohortDashboardWeeklyBlock/WeeklyBlockEventCard'
import WeeklyBlockEventTimes from 'domains/CohortDashboard/CohortDashboardProgram/CohortDashboardWeeklyBlock/WeeklyBlockEventTimes'
import { CohortEvent } from 'domains/CohortViewer/utils'

import { CohortDashboardCurrentUserPartsFragment } from 'gql'

export interface WeeklyBlockEventProps {
  combinedEvent: CohortEvent
  index: number
  slug: string
  currentUser: CohortDashboardCurrentUserPartsFragment
  scheduledWeekTitle: string
  scheduledWeekIndex: number
  programName: string
  rsvpCallback: (event: CohortEvent) => void
}

export const WeeklyBlockEvent = ({
  combinedEvent,
  index,
  slug,
  currentUser,
  scheduledWeekTitle,
  scheduledWeekIndex,
  programName,
  rsvpCallback
}: WeeklyBlockEventProps) => {
  return (
    <div
      key={`event_${index}`}
      className="flex w-[calc(50%-.75rem)] min-w-[312px] flex-col justify-between sm:min-w-[unset] xl:w-full"
    >
      <WeeklyBlockEventTimes
        timezone={currentUser.timezone || ''}
        event={combinedEvent}
      />
      <WeeklyBlockEventCard
        event={combinedEvent}
        currentUser={currentUser}
        slug={slug}
        index={index}
        scheduledWeekTitle={scheduledWeekTitle}
        scheduledWeekIndex={scheduledWeekIndex}
        programName={programName}
        rsvpCallback={rsvpCallback}
      />
    </div>
  )
}

export default WeeklyBlockEvent
