import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { ComplexPortableText } from 'domains/Sanity/PortableText/ComplexPortableText/ComplexPortableText'

import { ChevronDownThin } from 'components/icons'

import { MarketingFaq } from 'gql'

import { cn } from 'utils/tailwind'

import { MarketingH2 } from './marketingSite/textHelpers'

interface PricingFaqSectionProps {
  faqs: MarketingFaq[]
}

const PricingFaqSection = ({ faqs }: PricingFaqSectionProps) => {
  const [activeAccordion, setActiveAccordion] = useState<string | null>(null)
  const onToggle = (id: string, status: boolean) => {
    setActiveAccordion(status ? id : null)
  }

  return (
    <section className="px-4 md:px-[4vw] py-[50px] lg:py-[100px]">
      <div className="mx-auto max-w-[1280px]">
        <MarketingH2 className="mb-10">FAQs</MarketingH2>

        {faqs.map(({ id, answer, question }, index) => (
          <div
            key={id}
            id={id}
            className={cn(
              'w-full border-t border-rb-gray-100',
              index === 0 && 'border-none'
            )}
          >
            <button
              onClick={() => onToggle(id, !(activeAccordion === id))}
              aria-expanded={activeAccordion === id}
              aria-controls={`accordion-${id}`}
              className="flex w-full items-center justify-between py-6 text-left text-sm lg:text-lg font-medium !leading-[1.35]"
            >
              {question}
              <div
                className={twMerge(
                  'relative ml-2 block h-3 w-3 flex-shrink-0',
                  activeAccordion === id && 'rotate-180'
                )}
              >
                <ChevronDownThin />
              </div>
            </button>
            <div
              className={`m-0 h-0 overflow-hidden ${activeAccordion === id && 'h-max'}`}
            >
              <div className="block-content pb-6 pr-8 [&_*]:!text-sm lg:[&_*]:!text-lg [&_*]:!leading-[1.35]">
                <ComplexPortableText content={answer} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default PricingFaqSection
