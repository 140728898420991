import pluralize from 'pluralize'

import AvatarStack from 'components/AvatarStack'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import RfParagraphMiniSemiBold from 'components/typography/RfParagraph/RfParagraphMiniSemiBold'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import { CohortPostPartsFragment, CohortPostReplyPartsFragment } from 'gql'

import { getSortedRepliesByUserKind } from './utils'

interface CohortPostCardRepliesProps {
  post: CohortPostPartsFragment
  replies: CohortPostReplyPartsFragment[]
  lastRespondedAt: string
  showThread: (post: CohortPostPartsFragment) => void
}

const CohortPostCardReplies = ({
  post,
  replies,
  lastRespondedAt,
  showThread
}: CohortPostCardRepliesProps) => {
  const lengthOfReplies = replies.length
  const hasReplies = lengthOfReplies > 0

  return (
    <div className="flex items-center pt-5">
      {hasReplies && (
        <div>
          <AvatarStack
            members={getSortedRepliesByUserKind({ replies })}
            totalCount={3}
            maximumAvatars={3}
            avatarSize={30}
            indent={4}
            direction="left"
            borderClassName="static rounded"
            avatarClassName="static mr-1 ml-0"
            zIndex={-1}
          />
        </div>
      )}
      <div className="flex flex-1 items-center">
        <button
          className="cursor-pointer pr-2.5 hover:underline"
          onClick={() => showThread(post)}
        >
          <RfParagraphMiniSemiBold color={ColorOptions.black}>
            {hasReplies ? (
              <span className="pl-2.5">{pluralize('Reply', lengthOfReplies, true)}</span>
            ) : (
              'Reply in thread'
            )}
          </RfParagraphMiniSemiBold>
        </button>

        {hasReplies && (
          <RfParagraphMini color={ColorOptions.gray}>
            <span className="hidden sm:inline">Last reply</span> {lastRespondedAt} ago
          </RfParagraphMini>
        )}
      </div>
    </div>
  )
}

export default CohortPostCardReplies
