import { ReactNode } from 'react'

import { useAccountLayoutNav } from 'domains/Account/useAccountLayoutNav'

interface AccountLayoutProps {
  children: ReactNode
}

const AccountLayout = ({ children }: AccountLayoutProps) => {
  useAccountLayoutNav()

  return (
    <div className="mb-5 px-0">
      <div className="max-w-6xl">{children}</div>
    </div>
  )
}

export default AccountLayout
