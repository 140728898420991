import { KeyboardEvent, MouseEvent } from 'react'

import { getAddEventTime } from 'utils/eventUtils'
import { cn } from 'utils/tailwind'

interface AddToCalendarProps {
  timezone: string | undefined | null
  startsAtUtc: string
  endsAtUtc: string
  joinLink: string
  eventTitle?: string | null
  eventDescription?: string | null
  className?: string
  onClickCallback?: () => void
}

const AddToCalendar = ({
  startsAtUtc,
  endsAtUtc,
  eventTitle,
  eventDescription,
  joinLink,
  timezone,
  className,
  onClickCallback
}: AddToCalendarProps) => {
  const startTime = getAddEventTime(startsAtUtc, timezone)
  const endTime = getAddEventTime(endsAtUtc, timezone)
  const handleOnClick = (
    e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>
  ) => {
    onClickCallback && onClickCallback()
    e.stopPropagation()
  }

  return (
    <div
      title="Add to Calendar"
      className={cn('addeventatc bg-white shadow-calendar', className)}
      data-render="inline-buttons"
      onClick={handleOnClick}
      onKeyUp={handleOnClick}
      role="button"
      tabIndex={0}
    >
      <span className="start">{startTime}</span>
      <span className="end">{endTime}</span>
      <span className="timezone">{timezone}</span>
      <span className="title">{eventTitle}</span>
      <span className="description">{eventDescription}</span>
      <span className="location">{joinLink}</span>
      <span className="organizer">Reforge</span>
      <span className="organizer_email">hello@reforge.com</span>
      <span className="all_day_event">false</span>
    </div>
  )
}

export default AddToCalendar
