import { useState } from 'react'
import { object, string } from 'yup'

import { Form, InputText } from 'components/forms'

import { useSsoAuthorizationQuery } from 'gql'

import { LoginParams } from 'utils/url/loginUrl'

const formValidation = object().shape({
  email: string().trim().email('Invalid email.').required().label('Email')
})

function SsoLogin({ loginParams }: { loginParams: LoginParams }) {
  const [email, setEmail] = useState<string>('')
  const { data, loading } = useSsoAuthorizationQuery({
    variables: {
      email,
      loginParams
    },
    fetchPolicy: 'network-only',
    skip: email.length < 1
  })

  const authorizationUrl = data?.ssoAuthorization?.isSsoActive
    ? data?.ssoAuthorization?.authorizationUrl
    : null
  const ssoUnavailable =
    email.length > 1 && !loading && !data?.ssoAuthorization?.isSsoActive

  if (authorizationUrl) {
    window.location.replace(authorizationUrl)
  }

  return (
    <div className="my-4">
      <Form
        submit={({ email }: { email: string }) => {
          setEmail(email)
        }}
        validationMode="onBlur"
        validationSchema={formValidation}
        submitButtonText="Sign in with SSO"
        submitButtonProps={{ size: 'x-small' }}
        submitButtonClassname="mt-[-2rem] py-3 px-4 w-full"
      >
        <InputText
          type="email"
          name="email"
          label="Email"
          placeholder="email@domain.com"
        />
      </Form>
      {ssoUnavailable && (
        <p className="my-4 text-rb-destructive-100">
          SSO is not available for this email address.
        </p>
      )}
    </div>
  )
}

export default SsoLogin
