import React from 'react'

import Button from 'components/Button'

export interface ConfirmDeleteProps {
  type: string
  confirmAction: (e: React.MouseEvent) => void
  cancelAction: (e: React.MouseEvent) => void
  disabled?: boolean
}

export default function ConfirmDelete(props: ConfirmDeleteProps) {
  const { confirmAction, cancelAction, type, disabled = false } = props

  return (
    <>
      <p>
        Are you share you want to delete this {type}?<br />
        This cannot be undone.
      </p>
      <div className="uk-child-width-1-2 uk-grid-small" uk-grid="">
        <div>
          <Button disabled={disabled} onClick={confirmAction} color="destructive">
            Yes, Delete
          </Button>
        </div>
        <div>
          <Button disabled={disabled} onClick={cancelAction}>
            Never Mind
          </Button>
        </div>
      </div>
    </>
  )
}
