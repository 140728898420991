const MEMBERSHIP_COST = '$1,995'

export function ReimbursementOverview() {
  return (
    <div>
      <h2 className="mb-4 text-base font-semibold lg:text-xl">
        Expense through your company
      </h2>
      <p>
        Reforge membership costs <span className="font-semibold">{MEMBERSHIP_COST}</span>{' '}
        per year, and there are discounts for teams. The majority of our members expense
        their membership through their companies. That&apos;s because companies know
        investing in your career progressions expands your ability to move the business
        forward. Every company&apos;s policy is different, so we created email templates
        to send to your manager.
      </p>
    </div>
  )
}
