import BaseBreadcrumbs from 'components/Breadcrumbs/BaseBreadcrumbs'
import { ReactComponent as ChevronLeftIcon } from 'components/Breadcrumbs/BaseBreadcrumbs/base-chevron-left.svg'
import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'
import PageHeader from 'components/PageHeader'

import { usePublicEventsQuery, useUserEventsQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { cn } from 'utils/tailwind'

import { UpcomingEventContainer } from './components/UpcomingEventContainer'

const EventUpcoming = () => {
  const { currentUser, isLoggedIn } = useCurrentUser()
  const {
    loading: publicQueryLoading,
    error: publicQueryError,
    data: publicQueryData
  } = usePublicEventsQuery({
    skip: isLoggedIn
  })

  const { loading, error, data } = useUserEventsQuery({
    variables: { userId: currentUser?.id as any },
    skip: !isLoggedIn
  })

  if (loading || publicQueryLoading) return <Loading />
  if (error || publicQueryError) return <ErrorMessage error={error || publicQueryError} />

  const upcomingEventData = isLoggedIn
    ? data?.user?.upcomingEligibleEvents
    : publicQueryData?.publicEvents

  return (
    <div
      className={cn(
        'flex h-full flex-col',
        !isLoggedIn && 'px-4 md:px-[4vw] lg:pl-[4vw]'
      )}
    >
      <BaseBreadcrumbs
        oneLevel
        className="mb-5"
        breadcrumbPages={[{ title: 'events', path: '/events' }]}
        backIcon={
          <ChevronLeftIcon width={16} height={16} className="mr-4 fill-rb-black" />
        }
      />

      <PageHeader title="Upcoming events" />

      <UpcomingEventContainer events={upcomingEventData || []} />
    </div>
  )
}

export default EventUpcoming
