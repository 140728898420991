import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import { useState } from 'react'

import ReforgeCardElement from 'domains/User/ReforgeCardElement'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'
import { Modal, ModalContent, ModalTitle } from 'components/Modal'
import StyledCheckbox from 'components/StyledCheckbox'

import { useStripeSetupIntentMutation, useUpdatePaymentMethodMutation } from 'gql'

import useModalTracking from 'hooks/useModalTracking'

interface Props {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  onComplete: () => void
}

const AddPaymentMethodModalContainer = ({ isOpen, setIsOpen, onComplete }: Props) => {
  if (!isOpen) return null

  return (
    <>
      <AddPaymentMethodModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onComplete={onComplete}
      />
    </>
  )
}

const AddPaymentMethodModal = ({ isOpen, setIsOpen, onComplete }: Props) => {
  const stripe = useStripe() as any
  const elements = useElements() as any
  const [loading, setLoading] = useState(false)
  const [stripeSetupIntent, { error: stripeSetupIntentError }] =
    useStripeSetupIntentMutation()
  const [updatePaymentMethod, { error: updatePaymentMethodError }] =
    useUpdatePaymentMethodMutation()
  const [isDefaultPaymentMethod, setIsDefaultPaymentMethod] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [errorMessage, setErrorMessage] = useState<null | string>(null)
  const [success, setSuccess] = useState(false)

  useModalTracking(isOpen, {
    modal_group: 'add payment method',
    modal_name: 'add payment method'
  })

  const submitPaymentMethod = async () => {
    try {
      if (!stripe || !elements) return

      setLoading(true)

      const { data } = await stripeSetupIntent({
        variables: { input: { userId: null } }
      })
      if (
        stripeSetupIntentError ||
        updatePaymentMethodError ||
        data?.stripeSetupIntent?.errors?.length
      ) {
        setErrorMessage(
          stripeSetupIntentError?.message ||
            updatePaymentMethodError?.message ||
            data?.stripeSetupIntent?.errors?.[0] ||
            'Something went wrong while updating your payment method.'
        )
        return setLoading(false)
      }

      const { setupIntent, error: stripeJsError } = await stripe.confirmCardSetup(
        data?.stripeSetupIntent?.clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement)
          }
        }
      )

      if (stripeJsError?.message) {
        setErrorMessage(stripeJsError.message)
        return setLoading(false)
      }

      if (isDefaultPaymentMethod) {
        await updatePaymentMethod({
          variables: {
            input: { id: setupIntent.payment_method, isDefaultPaymentMethod: true }
          }
        })
      }

      if (onComplete) await onComplete()
      setSuccess(true)
      return setLoading(false)
    } catch (e) {
      setErrorMessage(e.message)
      return setLoading(false)
    }
  }

  const handleCardElementChange = (response: StripeCardElementChangeEvent) => {
    if (response?.error) {
      setErrorMessage(response.error.message)
      setIsValid(false)
    } else {
      setErrorMessage(null)
      setIsValid(response.complete)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={() => setIsOpen(false)}
      className="max-w-2xl"
      scrollContent={false}
    >
      <div className="flex flex-col pb-8 md:pb-11">
        {!success && (
          <ModalTitle className="px-8 pb-5 md:px-11">Add Payment Method</ModalTitle>
        )}

        <ModalContent scrollContent={false}>
          {success && <SuccessContent />}

          {!success && (
            <>
              <ReforgeCardElement
                onChange={handleCardElementChange}
                errorMessage={errorMessage}
              />

              <div className="mt-16 flex items-center">
                <label
                  className="flex items-center"
                  onClick={() => {
                    setIsDefaultPaymentMethod(!isDefaultPaymentMethod)
                  }}
                >
                  <StyledCheckbox checked={isDefaultPaymentMethod} />
                  Set as primary payment method
                </label>

                <div className="ml-2 grid grid-cols-2 items-center">
                  <Button
                    variant="text-only"
                    disabled={loading}
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </Button>

                  <Button
                    disabled={!isValid || loading}
                    onClick={submitPaymentMethod}
                    variant="outline"
                  >
                    Add Card
                  </Button>
                </div>
              </div>
            </>
          )}
        </ModalContent>
      </div>
    </Modal>
  )
}

const SuccessContent = () => {
  useModalTracking(true, {
    modal_group: 'add payment method',
    modal_name: 'add payment method success'
  })

  return (
    <>
      <div className="flex justify-end">
        <a className="uk-icon-link uk-modal-close-default" uk-icon="icon: close;" />
      </div>
      <div className="text-center text-xl text-rb-gray-300">
        <SVGIcon
          name="check-slim"
          height="42"
          width="55"
          fill="#000000"
          className="mx-auto mb-2.5"
        />
        <div className="mt-5 mb-10">
          <p>Payment Method added</p>
        </div>
      </div>
    </>
  )
}

export default AddPaymentMethodModalContainer
