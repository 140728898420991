import { useCallback, useRef, useState } from 'react'

import AllHighlightsListEmptyState from 'domains/Collections/AllHighlightsListEmptyState'
import {
  CollectionFiltersHeader,
  CollectionFiltersSidebar,
  DEFAULT_SORT
} from 'domains/Collections/CollectionsFilters'
import CollectionsList from 'domains/Collections/CollectionsList'

import { Loading } from 'components'
import { FiltersProp } from 'components/Filters'

import {
  BookmarkFolderPartsFragment,
  BookmarkType,
  ProgramBookmarkPartsFragment,
  useBookmarksFeedQuery,
  useUserBookmarksFeedTotalQuery
} from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import { trackFilterApplied } from 'utils/tracking/analytics'

interface AllHighlightsListProps {
  bookmarkFolders?: BookmarkFolderPartsFragment[]
  openCreateBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment | null) => void
}

const WITHIN_CONTENT_BOOKMARK_TYPES = [
  BookmarkType.VIDEOBOOKMARK,
  BookmarkType.IMAGEBOOKMARK,
  BookmarkType.TEXTBOOKMARK
]
const PAGINATION_DEFAULTS = {
  limit: 30,
  offset: 0
} as const

const AllHighlightsList = ({
  bookmarkFolders,
  openCreateBookmarkFolderModal
}: AllHighlightsListProps) => {
  const currentUser = useAssertCurrentUser()
  const { data, loading, variables, error, fetchMore, refetch } = useBookmarksFeedQuery({
    variables: {
      ...PAGINATION_DEFAULTS,
      sort: DEFAULT_SORT.queryValue,
      filters: {
        types: WITHIN_CONTENT_BOOKMARK_TYPES
      }
    }
  })
  const skipCallback = useRef(true)
  const { data: countData } = useUserBookmarksFeedTotalQuery({
    variables: {
      userId: currentUser.id,
      filters: { types: WITHIN_CONTENT_BOOKMARK_TYPES }
    }
  })
  const totalCount = countData?.user?.bookmarksTotal || 0
  const queryCount = data?.bookmarksFeed.total || 0
  const bookmarks = data?.bookmarksFeed.bookmarks.filter((b) => b) || []
  const currentOffset = variables?.offset || PAGINATION_DEFAULTS.offset
  const currentLimit = variables?.limit || PAGINATION_DEFAULTS.limit
  const [currentPage, setCurrentPage] = useState(currentOffset / currentLimit + 1)

  const setFilters = useCallback(
    (values: FiltersProp) => {
      if (skipCallback.current) {
        skipCallback.current = false
      } else {
        delete values.page
        refetch({
          filters: { ...values, types: WITHIN_CONTENT_BOOKMARK_TYPES },
          offset: 0
        })
        trackFilterApplied({
          filter_location: 'saved_highlights',
          filter_changed: 'cmsProgramId',
          filters: values.cmsProgramId
            ? { program_ids: [String(values.cmsProgramId)] }
            : {},
          active_filter_count: values.cmsProgramId ? 1 : 0
        })
      }
    },
    [refetch]
  )

  const setSort = useCallback(
    (sort) => {
      refetch({
        sort,
        offset: 0
      })
    },
    [refetch]
  )

  const handlePagination = (page: number) => {
    if (page !== currentPage) {
      const newOffset = (page - 1) * currentLimit
      fetchMore({
        variables: {
          offset: newOffset
        }
      })
      setCurrentPage(newOffset / currentLimit + 1)
      document.getElementById('page')?.scrollTo(0, 0)
    }
  }

  if (loading) {
    return <Loading />
  }

  if (totalCount === 0) {
    return (
      <div className="pt-8">
        <AllHighlightsListEmptyState />
      </div>
    )
  }

  return (
    <div className="mx-auto w-full gap-x-8 pb-6 lg:flex">
      <div className="lg:w-3/4">
        {totalCount > 0 && (
          <div className="align-center justify flex justify-between gap-x-4 lg:block">
            <CollectionFiltersHeader
              loading={loading}
              count={queryCount}
              setSort={setSort}
              selectedSort={
                (variables?.sort || DEFAULT_SORT.queryValue) as
                  | 'notes_old_to_new'
                  | 'notes_new_to_old'
              }
            />
          </div>
        )}
        {bookmarks.length === 0 ? (
          <div className="pt-8">
            <AllHighlightsListEmptyState />
          </div>
        ) : (
          <CollectionsList
            bookmarkFolders={bookmarkFolders}
            bookmarksCount={queryCount}
            filters={variables?.filters || {}}
            bookmarks={bookmarks}
            loading={loading}
            error={error}
            openCreateBookmarkFolderModal={openCreateBookmarkFolderModal}
            handlePagination={handlePagination}
            currentPage={currentPage}
          />
        )}
      </div>
      <div className="hidden w-1/4 lg:block">
        {totalCount > 0 && (
          <CollectionFiltersSidebar
            filters={variables?.filters || {}}
            setFilters={setFilters}
          />
        )}
      </div>
    </div>
  )
}

export default AllHighlightsList
