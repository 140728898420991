import { useEffect, useMemo, useState } from 'react'
import { FormContextValues, useForm } from 'react-hook-form'

import TeamLogoModal from 'domains/Profile/TeamLogoModal'
import SamlCard from 'domains/Team/SSO/SamlCard'
import ScimCard from 'domains/Team/SSO/ScimCard'
import { AddEmailModal } from 'domains/Team/TeamCard/AddEmailModal'
import FallbackTeamLogo from 'domains/Team/TeamCard/FallbackTeamLogo'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'
import { useModal } from 'components/Modal'
import { displayToast } from 'components/Toast/index'

import { useEnterpriseSsoStatusQuery, useUpdateTeamMutation } from 'gql'

interface TeamDiscoverabilityProps {
  userEmailDomains: string[] | []
  register: FormContextValues['register']
  isDiscoverable?: boolean
  onAddEmailCtaClick: () => void
}

const TeamDiscoverability = ({
  userEmailDomains,
  register,
  isDiscoverable,
  onAddEmailCtaClick
}: TeamDiscoverabilityProps) => {
  const addEmailCta = (
    <button
      className="text-rb-teal-200"
      role="link"
      type="button"
      onClick={onAddEmailCtaClick}
    >
      add it by adding a new email address.
    </button>
  )

  return (
    <>
      <div className="mb-6">
        <div className="mb-1 text-lg font-medium">Team Discoverability</div>
        <div>
          Allow your teammates to find your team when they log in with an email address
          from the selected domain. If you can’t find the desired domain, {addEmailCta}
        </div>
        <div className="mt-3 w-60">
          <label className="text-md font-medium" htmlFor="domainNameSelector">
            Team Domain
          </label>
          <div className="border border-neutral-300">
            <select
              name="domainName"
              id="domainNameSelector"
              required={isDiscoverable}
              className="w-full border-r-8 border-transparent px-3 py-2 text-neutral-900"
              ref={register}
            >
              <option value="" disabled>
                Select an email domain
              </option>
              {userEmailDomains.map((domain) => (
                <option key={domain} value={domain}>
                  {domain}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  )
}

const LoginProvisioningSection = () => {
  const { data } = useEnterpriseSsoStatusQuery()

  return (
    <div className="flex flex-col gap-10">
      <SamlCard status={data?.enterpriseSsoStatus?.saml?.status} />
      <ScimCard status={data?.enterpriseSsoStatus?.scim?.status} />
    </div>
  )
}

interface MyTeamSettingsForm {
  domainName?: string | null
  isDiscoverable?: boolean | null
  name: string | null
  logoDataUrl?: string | null
}

export interface MyTeamSettingsProps {
  domainName?: string | null
  isDiscoverable?: boolean | null
  name?: string | null
  fullName?: string | null
  userEmailDomains: string[]
  logoUrl?: string | null
  teamId: string
}

export const MyTeamSettings = ({
  domainName,
  isDiscoverable,
  name,
  userEmailDomains,
  logoUrl,
  teamId
}: MyTeamSettingsProps) => {
  const { register, handleSubmit, watch, setValue, formState, reset } =
    useForm<MyTeamSettingsForm>({
      defaultValues: {
        domainName: domainName,
        isDiscoverable: isDiscoverable,
        name: name,
        logoDataUrl: null
      },
      mode: 'onChange'
    })

  const [sliderFocused, setSliderFocused] = useState(false)
  const handleOnFocus = () => setSliderFocused(true)
  const handleOnBlur = () => setSliderFocused(false)

  const [updateTeam, { loading: updateTeamLoading, error: updateTeamError }] =
    useUpdateTeamMutation()

  const {
    isModalOpen: isLogoModalOpen,
    openModal: openLogoModal,
    closeModal: closeLogoModal
  } = useModal()

  const displayErrorToast = () => {
    displayToast("We couldn't save your information.", {
      theme: 'colored',
      type: 'error'
    })
  }
  useEffect(() => {
    if (updateTeamError) displayErrorToast()
  }, [updateTeamError])

  const formValues = watch()

  const logoIsDirty = formState.dirtyFields.has('logoDataUrl')
  const isFormDirty = useMemo(() => {
    const allFieldsUnchanged =
      domainName === formValues.domainName &&
      isDiscoverable === formValues.isDiscoverable &&
      name === formValues.name &&
      !logoIsDirty
    return !allFieldsUnchanged
  }, [domainName, isDiscoverable, name, logoIsDirty, formValues])

  const [isOpenAddEmailModal, setIsOpenAddEmailModal] = useState(false)
  const submitForm = async (formData: MyTeamSettingsForm) => {
    const { logoDataUrl, ...rest } = formData
    const inputs = logoIsDirty ? { ...rest, logoDataUrl } : { ...rest }
    const result = await updateTeam({
      variables: {
        input: {
          ...inputs,
          id: teamId
        }
      }
    })
    if (result.data?.updateTeam?.success) {
      displayToast('Your information has been saved!')

      reset({
        domainName: result.data.updateTeam.team?.domainName,
        name: result.data.updateTeam.team?.name,
        isDiscoverable: result.data.updateTeam.team?.isDiscoverable
      })
    } else {
      displayErrorToast()
    }
  }

  const currentlyShowingUrl = logoIsDirty ? formValues.logoDataUrl : logoUrl
  return (
    <>
      <AddEmailModal isOpen={isOpenAddEmailModal} setIsOpen={setIsOpenAddEmailModal} />
      <div className="mb-2 text-xl font-medium">My Team</div>
      <div data-test="account-my-team" className="rf-rb-card mb-5 bg-white p-6">
        <form
          data-test="team-settings-form"
          className="flex flex-1 flex-col"
          onSubmit={handleSubmit(submitForm)}
        >
          <div className="mb-5">
            <label htmlFor="teamName" className="mb-1 text-lg font-medium">
              Team Name
            </label>
            <div className="mb-2">
              Choose a name that represents your company and function.
            </div>
            <div className="my-1 w-60">
              <input
                data-test="teamName-input"
                type="text"
                className="w-full border border-neutral-300 px-3 py-2 text-neutral-900"
                placeholder="Company, Team"
                autoComplete="off"
                required
                name="name"
                id="teamName"
                ref={register}
              />
            </div>
          </div>

          <div className="mb-6">
            <div className="mb-1 ">
              <span className="text-lg font-medium">Team Logo&nbsp;</span>
              <span className="text-sm font-light">(Optional)</span>
            </div>
            <div className="relative">
              {currentlyShowingUrl ? (
                <img
                  src={currentlyShowingUrl}
                  alt="Team Logo"
                  height="60"
                  width="60"
                  className="rounded"
                />
              ) : (
                <FallbackTeamLogo />
              )}
              <button
                onClick={openLogoModal}
                aria-label={currentlyShowingUrl ? 'edit team logo' : 'upload team logo'}
                className="absolute top-12 left-12 inline-block rounded-full bg-white p-1 drop-shadow"
                type="button"
                data-test="upload-logo-modal-button"
              >
                <SVGIcon name="pencil" width="12" height="12" />
              </button>
            </div>
          </div>
          <TeamDiscoverability
            userEmailDomains={userEmailDomains}
            register={register}
            isDiscoverable={Boolean(formValues.isDiscoverable)}
            onAddEmailCtaClick={() => setIsOpenAddEmailModal(true)}
          />
          <div>
            <div className="mb-1">
              Make your team discoverable so team members are able to find your team and
              join.
            </div>
            <div>
              <label htmlFor="isDiscoverableToggle">
                <div className="relative my-2 inline-block h-6 w-[50px]">
                  <input
                    type="checkbox"
                    className="h-0 w-0 opacity-0"
                    ref={register}
                    id="isDiscoverableToggle"
                    name="isDiscoverable"
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                  />
                  <span
                    className={`absolute inset-0 cursor-pointer rounded-[15px] ${
                      formValues.isDiscoverable
                        ? 'bg-rb-blue-100 before:translate-x-[26px]'
                        : 'bg-rb-gray-100'
                    } ${
                      sliderFocused ? 'outline-reforge-blue shadow-slider' : ''
                    } duration-400 before:absolute before:bottom-0.5 before:left-0.5 before:h-5 before:w-5 before:rounded-[50%] before:bg-white before:duration-400 before:content-['']`}
                  />
                </div>
                <span className="pl-2 text-sm font-medium">Make team discoverable</span>
              </label>
            </div>
          </div>
          <input hidden ref={register} name="logoDataUrl" />
          <div className="flex justify-end">
            <Button
              type="submit"
              className="w-20 py-1.5"
              isLoadingSpinner={updateTeamLoading}
              disabled={!isFormDirty}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
      <div className="mb-2 text-xl font-medium">Login and Provisioning</div>
      <div
        data-test="account-login-provisioning"
        className="rf-rb-card mb-5 bg-white p-6"
      >
        <LoginProvisioningSection />
      </div>
      <TeamLogoModal
        setLogoInForm={(url: string) => {
          setValue('logoDataUrl', url)
        }}
        logoUrl={currentlyShowingUrl}
        isModalOpen={isLogoModalOpen}
        handleCloseModal={closeLogoModal}
      />
    </>
  )
}

export default MyTeamSettings
