import BaseCard, { CardVariant, CardVariants } from 'components/cards/Content/BaseCard'

import { EventPartsFragment } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import {
  HOUR_MIN_FORMAT,
  HOUR_MIN_ONLY_FORMAT,
  MONTH_ABBREV_DATE_FORMAT,
  formatInTimezone,
  getCurrentTimezone
} from 'utils/date'
import { cn } from 'utils/tailwind'
import { trackNavigationClicked } from 'utils/tracking/analytics'

import { EventCardHeader } from './EventCardHeader'
import { EventIndexAttendButton } from './EventIndexAttendButton'

type UpcomingEventContainerProps = {
  event: EventPartsFragment
  variant?: CardVariant
  pageLocation?: string
}

export function UpcomingEventCard({
  event,
  pageLocation = 'event_index',
  variant = CardVariants.Horizontal
}: UpcomingEventContainerProps) {
  const { currentUser } = useCurrentUser()
  const userTimezone = getCurrentTimezone(currentUser?.timezone)
  const { name, startsAtUtc, endsAtUtc, attendeesCount, featuredImageUrl, hosts } = event
  const startTime = formatInTimezone(startsAtUtc, userTimezone, HOUR_MIN_ONLY_FORMAT)
  const endTime = formatInTimezone(endsAtUtc, userTimezone, HOUR_MIN_FORMAT)
  const monthDate = formatInTimezone(startsAtUtc, userTimezone, MONTH_ABBREV_DATE_FORMAT)

  const handleTracking = () => {
    trackNavigationClicked({
      location: pageLocation,
      type: 'horizontal_card',
      text: 'upcoming event',
      related_identifiers: {
        content_id: event.id,
        content_title: event.name,
        content_type: 'event',
        event_type: event.kind
      }
    })
  }

  function escapeHtml(htmlString: any) {
    if (htmlString) {
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = htmlString

      // Now you can access the new element as a DOM element
      return tempDiv.firstChild?.textContent
    }

    return htmlString
  }
  return (
    <div key={event.id} className="w-full max-w-[1000px] flex-col">
      <BaseCard
        eventId={Number(event.id)}
        trackCardClick={handleTracking}
        variant={variant}
        contentType="Event"
        destination={`/events/${event.id}-${event.slug}`}
        hideBookmarkButton={true}
        header={<EventCardHeader hosts={hosts} />}
        body={escapeHtml(event?.description)}
        title={name}
        horizontalThumbnail={
          <div
            className={cn(
              'relative h-full w-full max-w-[240px] rounded-lg p-3',
              featuredImageUrl
                ? 'bg-cover bg-center bg-no-repeat'
                : 'max-h-max bg-event-fallback'
            )}
            style={{
              backgroundImage: featuredImageUrl ? `url(${featuredImageUrl})` : undefined // Set background image using inline styles
            }}
          >
            <div className="absolute flex h-6 items-center justify-center rounded-full bg-white">
              <span className="px-3 font-sans text-xs font-semibold">Event</span>
            </div>
          </div>
        }
        verticalThumbnail={
          <div
            className={cn(
              'relative h-full w-full rounded-xl p-3',
              featuredImageUrl
                ? 'bg-cover bg-center bg-no-repeat'
                : 'max-h-max bg-event-fallback'
            )}
            style={{
              backgroundImage: featuredImageUrl ? `url(${featuredImageUrl})` : undefined // Set background image using inline styles
            }}
          >
            <div className="absolute flex h-6 items-center justify-center rounded-full bg-white">
              <span className="px-3 font-sans text-xs font-semibold">Event</span>
            </div>
          </div>
        }
        footer={
          <div className="flex items-center justify-between">
            <div className="flex font-sans text-xs">
              <span className="font-semibold text-rb-gray-400">
                {`${monthDate}, ${startTime} - ${endTime}`}
              </span>

              {!!attendeesCount && (
                <div className="hidden sm:flex">
                  <div className="mx-2 flex items-center justify-center">
                    <div className="h-[2px] w-[2px] rounded-full bg-rb-gray-300"></div>
                  </div>
                  <span className="text-rb-gray-300">
                    {` ${attendeesCount} attendee${attendeesCount > 1 ? 's' : ''}`}
                  </span>
                </div>
              )}
            </div>
            <EventIndexAttendButton event={event} />
          </div>
        }
      />
    </div>
  )
}
