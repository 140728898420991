import WorkEntryAvatar from 'domains/CreatorProfile/components/WorkEntryAvatar'

import ArtifactCard from 'components/cards/Content/ArtifactCard'
import { CardVariants } from 'components/cards/Content/BaseCard'

import { ArtifactAuthor, ExpertUserProfileFragmentFragment, WorkEntry } from 'gql'

import { cn } from 'utils/tailwind'

import WorkEntryCompany from './WorkEntryCompany'

export interface ExperienceTabProps {
  authorProfile: ArtifactAuthor
  userProfile?: ExpertUserProfileFragmentFragment | null
}

const ExperienceTab = ({ authorProfile, userProfile }: ExperienceTabProps) => {
  const { workEntries } = authorProfile

  const getTimeFrame = ({ workEntry }: { workEntry: WorkEntry }) => {
    return workEntry.specificTimeframe !== false ? (
      <>
        {workEntry.start} – {workEntry.end}
      </>
    ) : (
      <> {workEntry.generalTimeframe}</>
    )
  }

  return (
    <div className="flex flex-col">
      {workEntries?.map((entry, index) => {
        const { artifactContributions } = entry

        return (
          <div
            key={index}
            className="p-6 flex flex-col rounded-md border-[1px] border-rb-gray-100 mb-6"
          >
            <div className="mb-4 flex items-center">
              <WorkEntryAvatar
                workEntry={entry}
                avatarUrl={userProfile?.avatarUrl || ''}
                avatarAlt="profile photo"
              />

              <WorkEntryCompany
                workEntry={entry}
                firstName={userProfile?.firstName || ''}
              />
            </div>
            <div className="text-base font-sans text-rb-gray-300 font-normal">
              {getTimeFrame({ workEntry: entry })}
            </div>

            {Boolean(artifactContributions?.length) && (
              <div className="my-6">
                {artifactContributions?.map((contribution, index) => {
                  if (contribution.status !== 'confirmed') {
                    return null
                  }
                  const artifact = contribution.artifact

                  return (
                    <div
                      key={index}
                      className={cn(artifactContributions.length - 1 !== index && 'mb-4')}
                    >
                      <ArtifactCard
                        pageLocation="expert_profile__experience"
                        artifact={artifact}
                        variant={CardVariants.Mini}
                        hideBookmarkButton
                      />
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default ExperienceTab
