import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { SVGIcon } from 'components/Icon'

import { BaseFilterSet } from './Filter.types'

export interface CheckboxFilterSet extends BaseFilterSet {
  explainer?: string
  options: string[][]
  title: string
  type: 'checkbox'
}

export interface ICheckboxFilter {
  filterSet: CheckboxFilterSet
  resetFilters: (filterKey: string) => void
  startOpen?: boolean
  updateFilters: (filterKey: string, value: string, remove: boolean) => void
  values: string | number | null | string[]
}

interface ICheckboxes {
  filterSet: CheckboxFilterSet
  updateFilters: (filterKey: string, value: string, remove: boolean) => void
  values: string | number | null | string[]
}

const Checkboxes = ({ filterSet, updateFilters, values }: ICheckboxes) => {
  const getOptionFontClasses = (isActive = false) =>
    isActive
      ? 'text-black hover:text-black font-medium'
      : 'text-rb-gray-500 hover:text-rb-gray-500'

  const update = (value: string, remove: boolean) => {
    updateFilters(filterSet.key, value, remove)
  }

  return (
    <>
      {filterSet.options.map((filterOption) => {
        const filterVariable = filterOption[0]
        const displayName = filterOption[1]
        const active =
          !!values && Array.isArray(values) && values.includes(filterVariable)

        const svgColorClass = active ? 'text-rb-gray-400 active' : 'text-rb-gray-400'
        return (
          <label
            key={`option_${filterVariable}`}
            className={twMerge(
              'relative mt-[20px] block flex cursor-pointer items-center text-sm font-normal leading-5 first:mt-0 focus:outline-none',
              getOptionFontClasses(active)
            )}
          >
            <input
              type="checkbox"
              onChange={() => update(filterVariable, active)}
              className="h-0 w-0 appearance-none"
            />
            <SVGIcon
              className={twMerge(
                'svg-icon-hover mr-2 min-h-[20px] min-w-[20px] cursor-pointer fill-current',
                svgColorClass
              )}
              name={active ? 'checkbox-ticked' : 'checkbox-empty'}
              width="20"
              height="20"
            />
            {displayName}
          </label>
        )
      })}
    </>
  )
}

const CheckboxFilter = ({
  filterSet,
  values,
  startOpen,
  updateFilters,
  resetFilters
}: ICheckboxFilter) => {
  const [isOpen, setOpen] = useState(!!(values || startOpen))

  const getHiddenClass = (shouldHideWhenOpen = false) => {
    const shouldHide = shouldHideWhenOpen ? isOpen : !isOpen
    return shouldHide ? 'hidden' : ''
  }

  const onToggle = () => {
    setOpen(!isOpen)
  }

  const reset = (event: React.MouseEvent) => {
    event.stopPropagation()
    resetFilters(filterSet.key)
  }

  const borderColorClass = 'border-rb-gray-100 hover:border-rb-gray-250'

  return (
    <div
      className={twMerge('mb-4 rounded-sm border border-solid', borderColorClass)}
      id={`filter-${filterSet.key}`}
    >
      <div className="flex cursor-pointer px-4 py-3" onClick={onToggle}>
        <h3
          className={twMerge(
            'mb-0 grow cursor-pointer text-sm font-medium text-rb-gray-500'
          )}
        >
          {filterSet.title}
        </h3>
        {!!values && (
          <button
            type="button"
            className="mr-1.5 cursor-pointer text-sm leading-5 text-rb-teal-200 hover:underline hover:decoration-rb-teal-300 focus:outline-none"
            onClick={reset}
          >
            Reset
          </button>
        )}
        <div className={twMerge('cursor-pointer leading-5', getHiddenClass(true))}>
          <SVGIcon name="reveal-plus" height="20" width="20" fill="#A2A1A2" />
        </div>
        <div className={twMerge('cursor-pointer leading-5', getHiddenClass())}>
          <SVGIcon name="hide-minus" height="20" width="20" fill="#000000" />
        </div>
      </div>
      <div className={twMerge('px-4 py-5', getHiddenClass())}>
        {filterSet.explainer && <p className="mb-1 text-sm">{filterSet.explainer}</p>}
        <Checkboxes filterSet={filterSet} values={values} updateFilters={updateFilters} />
      </div>
    </div>
  )
}

export default CheckboxFilter
