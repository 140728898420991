import { useHistory } from 'react-router-dom'

import { EventRecordingCard } from 'pages/EventRecordings/components/EventRecordingCard'
import { UpcomingEventCard } from 'pages/EventsUpcoming/components/UpcomingEventCard'

import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'
import PageHeader from 'components/PageHeader'
import Tabs from 'components/Tabs'
import { CardVariants } from 'components/cards/Content/BaseCard'

import { useUserEventsPastFeedQuery, useUserEventsQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { trackCtaClicked, trackNavigationClicked } from 'utils/tracking/analytics'

export function MyEvents() {
  const { currentUser } = useCurrentUser()
  const router = useHistory()
  const { loading, error, data } = useUserEventsQuery({
    variables: { userId: currentUser!.id },
    fetchPolicy: 'network-only'
  })

  const TopicFilters = {
    Discover: 'Discover',
    MyEvents: 'My Events'
  }

  const {
    loading: myPastEventLoading,
    error: errorMyPastEvents,
    data: myPastEventData
  } = useUserEventsPastFeedQuery({
    variables: {
      filters: { kind: [], userInputFilter: '' }
    }
  })

  if (loading || myPastEventLoading) {
    return <Loading />
  }
  if (error || errorMyPastEvents) {
    return <ErrorMessage error={error || errorMyPastEvents} />
  }

  const eventsImAttending = data?.user?.eventsAttending || []
  const pastEvents = myPastEventData?.userEventsPastFeed?.events || []

  const handleEventIndexTabsClick = (filter: string) => {
    if (filter === TopicFilters.MyEvents) return
    trackNavigationClicked({
      location: 'event_index',
      text: filter.toLowerCase(),
      type: 'button'
    })

    router.push('/events')
  }

  const handleClickAndTrack = () => {
    trackCtaClicked({
      cta_location: 'my_events_page',
      text: 'explore events',
      cta_type: 'button'
    })

    router.push('/events')
  }

  if (!pastEvents.length && !eventsImAttending.length) {
    return (
      <>
        <PageHeader title="Events" />
        <div className="mb-4">
          <Tabs
            tabs={Object.values(TopicFilters).map((filter) => ({
              label: filter,
              onClick: () => handleEventIndexTabsClick(filter),
              isActive: filter === TopicFilters.MyEvents
            }))}
          />
        </div>
        <div className="mt-[44px] flex  h-[379px] max-w-5xl flex-col items-center justify-center rounded-md bg-rb-gray-50 px-16">
          <div className="ext-rb-gray-400 mb-[10px] font-semibold">
            No events to display... yet
          </div>
          <div className="mb-6 flex flex-col items-center text-rb-gray-300">
            <span>Any upcoming events you RSVP to, or past events you</span>
            <span>attend, will automatically appear here.</span>
          </div>
          <Button variant="outline" onClick={handleClickAndTrack}>
            Explore Events
          </Button>
        </div>
      </>
    )
  }

  const eventsImAttendingIds = eventsImAttending.map((event) => event.id)

  const allEvents = [...eventsImAttending, ...pastEvents]
  const variants = ['teal', 'blue', 'pink', 'green', 'yellow']

  return (
    <div className="flex w-full flex-col">
      <PageHeader title="Events" />

      <div className="mb-4">
        <Tabs
          tabs={Object.values(TopicFilters).map((filter) => ({
            label: filter,
            onClick: () => handleEventIndexTabsClick(filter),
            isActive: filter === TopicFilters.MyEvents
          }))}
        />
      </div>
      <div className="mt-[44px] mb-4 font-sans text-xl font-semibold">All my events</div>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
        {allEvents.map((event, index) => {
          if (eventsImAttendingIds.includes(event.id)) {
            return (
              <UpcomingEventCard
                key={event.id}
                variant={CardVariants.Vertical}
                event={event}
              />
            )
          }

          return (
            <EventRecordingCard
              key={event.id}
              event={event}
              imgVariants={variants[index % variants.length]}
            />
          )
        })}
      </div>
    </div>
  )
}
