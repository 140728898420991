import { Redirect, Route, Switch, useParams } from 'react-router-dom'

import ArtifactPage from 'pages/Artifacts/ArtifactPage'
import CourseBlockContentBlockLandingPage from 'pages/CourseBlockContentBlockLandingPage/CourseBlockContentBlockLandingPage'
import CourseEventPage from 'pages/CourseEventPage'
import CourseDashboard from 'pages/CoursesPage/CourseDashboard'
import CourseWelcome from 'pages/CoursesPage/CourseWelcome'
import { UnitLandingPage } from 'pages/UnitLandingPage'

import { Loading } from 'components'

import { useCourseDetailPageQuery, useCoursePageAccessQuery } from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { CourseIdentifiersType } from 'utils/courseUtils'

export const COURSE_DASHBOARD_HEADER_ID = 'course-dashboard-header'

export type CoursePageParams = {
  courseBlockIndex?: string
  contentBlockSlug?: string
  courseSlug: string
  courseSessionId: string
  guideSlug?: string
  artifactSlug?: string
  sanityEventId?: string
}

export const CoursePage = () => {
  const {
    courseBlockIndex,
    contentBlockSlug,
    courseSlug,
    courseSessionId,
    guideSlug,
    artifactSlug,
    sanityEventId
  } = useParams<CoursePageParams>()

  const { showCourseContentNow, userIdentified } = useFeatureFlags()

  const { loading: accessQueryLoading, data: accessQueryData } = useCoursePageAccessQuery(
    {
      variables: { slug: courseSlug, courseSessionId }
    }
  )

  const { data: courseDetailData, loading: courseDetailLoading } =
    useCourseDetailPageQuery({
      variables: {
        slug: courseSlug
      }
    })

  if (!courseSessionId || !courseSlug) {
    return <Redirect to="/courses" />
  }

  if (!userIdentified || accessQueryLoading || courseDetailLoading) {
    return <Loading />
  }

  if (!accessQueryData?.course || !courseDetailData?.cclCourse) {
    return <Redirect to="/courses" />
  }

  const showContent =
    accessQueryData?.courseSession?.isContentOpen ||
    accessQueryData?.courseSession?.isHostOrCreator ||
    showCourseContentNow

  const courseIdentifiers: CourseIdentifiersType = {
    course: accessQueryData?.course,
    courseSession: accessQueryData?.courseSession
  }

  return (
    <div className="relative flex flex-col" id="course-page">
      {showContent ? (
        <Switch>
          <Route
            exact
            path={[`/courses/${courseSlug}/sessions/${courseSessionId}`]}
            render={() => (
              <CourseDashboard slug={courseSlug} courseSessionId={courseSessionId} />
            )}
          />
          <Route
            exact
            path={[
              `/courses/${courseSlug}/sessions/${courseSessionId}/events/${sanityEventId}`
            ]}
            render={() => (
              <CourseEventPage
                course={accessQueryData?.course}
                courseSessionId={accessQueryData?.courseSession?.id}
                sanityEventId={sanityEventId}
              />
            )}
          />
          <Route
            exact
            path={[
              `/courses/${courseSlug}/sessions/${courseSessionId}/course-blocks/${courseBlockIndex}/content-blocks/${contentBlockSlug}`
            ]}
            render={() => (
              <CourseBlockContentBlockLandingPage
                backToCourseLink={`/courses/${courseSlug}/sessions/${courseSessionId}#${courseBlockIndex}-${contentBlockSlug}`}
                backToCourseLabel="Course Home"
                cclCourseBlock={
                  courseBlockIndex
                    ? courseDetailData?.cclCourse?.blocks?.[Number(courseBlockIndex)]
                    : null
                }
                contentBlockSlug={contentBlockSlug}
              />
            )}
          />
          <Route
            exact
            path={[
              `/courses/${courseSlug}/sessions/${courseSessionId}/guides/${guideSlug}`
            ]}
            render={() => (
              <UnitLandingPage
                unitSlug={guideSlug}
                course={accessQueryData?.course}
                courseSession={accessQueryData?.courseSession}
                backToCourseLink={`/courses/${courseSlug}/sessions/${courseSessionId}`}
                backToCourseLabel="Course Home"
                showCourseBreadcrumb
              />
            )}
          />
          <Route
            exact
            path={`/courses/${courseSlug}/sessions/${courseSessionId}/artifacts/${artifactSlug}`}
            render={() => (
              <ArtifactPage
                artifactSlug={artifactSlug}
                courseIdentifiers={courseIdentifiers}
              />
            )}
          />
        </Switch>
      ) : (
        <CourseWelcome
          course={accessQueryData?.course}
          courseSession={accessQueryData?.courseSession}
          enrollmentSnapshot={accessQueryData?.userCourse?.courseEnrollmentSnapshot}
        />
      )}
    </div>
  )
}
