interface BlockProps {
  value?: any // @todo: define type
  children?: any // @todo: define type
}

const listItem = {
  bullet: ({ children }: BlockProps) => <li>{children}</li>,
  number: ({ children }: BlockProps) => <li>{children}</li>
}

export default listItem
