import React, { useEffect, useState } from 'react'

import { ErrorMessage } from 'components'
import Button from 'components/Button'
import { Modal, ModalContent, ModalHeader, ModalTitle } from 'components/Modal'

import { useAddEmailToUserMutation } from 'gql'

import { trackModalDismissed } from 'hooks/useModalTracking'

import { isValidEmail } from 'utils/emailUtils'

import AddEmailSuccessModal from './AddEmailSuccess'

export const AddEmailModal = ({
  isOpen,
  setIsOpen
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) => {
  const CTA_TEXT = 'Add Email'

  const [success, setSuccess] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [email, setEmail] = useState('')

  const [addEmailToUser, { error }] = useAddEmailToUserMutation()

  useEffect(() => {
    setHasError(!!error)
  }, [error])

  const handleClose = () => {
    trackModalDismissed({
      modal_group: 'add email address',
      modal_name: 'add email address'
    })
    setIsOpen(false)
    setHasError(false)
  }
  const handleCloseSuccess = () => {
    setSuccess(false)
  }

  const handleInputChange = (e: any) => {
    setHasError(false)
    setEmail(e.target.value)
  }

  const handleSubmitEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      await addEmailToUser({
        variables: { input: { email } }
      })

      handleClose()
      setSuccess(true)
      setEmail('')
    } catch (e) {
      // error handled in component
    }
  }

  return (
    <>
      <Modal
        data-test={'add-email-modal'}
        isOpen={isOpen}
        handleClose={handleClose}
        className="border"
      >
        {hasError && <ErrorMessage error={error?.message} />}
        <div className="flex flex-col items-center">
          <ModalHeader className="mb-0">
            <ModalTitle className="font-semibold leading-6">
              <label htmlFor="email">Add Email Address</label>
            </ModalTitle>
          </ModalHeader>
          <ModalContent className="items-center" scrollContent={false}>
            <p className="text-center text-base leading-5 text-rb-gray-400">
              We suggest using your work email.
            </p>

            <form onSubmit={handleSubmitEmail}>
              <input
                id="email"
                name="email"
                type="email"
                placeholder=" jane@yourcompany.com"
                className="mb-6 w-72 rounded border-2 p-2 outline-none placeholder-shown:italic"
                onChange={handleInputChange}
                required
              />
              <div className="mb-3 flex items-center justify-center">
                <Button
                  variant="text-only"
                  className="mr-4 h-8 rounded outline-none"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  className="h-8 rounded border-0"
                  data-test="add-email"
                  type="submit"
                  disabled={!isValidEmail(email)}
                >
                  {CTA_TEXT}
                </Button>
              </div>
            </form>
          </ModalContent>
        </div>
      </Modal>
      <AddEmailSuccessModal success={success} handleClose={handleCloseSuccess} />
    </>
  )
}

export default AddEmailModal
