import { useCohortDashboardTrackingContext } from 'domains/CohortDashboard/hooks/useCohortDashboardTrackingContext'

import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { useUserCohortQuery } from 'gql'

import { downloadCohortCertificateOfCompletion } from 'utils/cohortUtils'
import { onEnterKeyPress } from 'utils/keyboard'
import { trackCtaClicked } from 'utils/tracking/analytics'

import { ReactComponent as Download } from 'images/download--arrow.svg'
import { ReactComponent as Tada } from 'images/tada.svg'

interface CompletionCertificateProps {
  slug: string
}

const CompletionCertificate = ({ slug }: CompletionCertificateProps) => {
  const { cohort } = useCohortDashboardTrackingContext()

  const { data, loading, error } = useUserCohortQuery({ variables: { slug } })

  if (!data?.userCohort || loading || error) {
    return null
  }

  const downloadCtaTitle = 'Download your certificate'
  const downloadCtaSubtitle = 'Congratulations on completing the program!'

  const handleDownloadCertificateClick = () => {
    downloadCohortCertificateOfCompletion(data?.userCohort)
    trackCtaClicked({
      cta_location: 'completion_certificate_card',
      cta_type: 'card',
      text: `${downloadCtaTitle} ${downloadCtaSubtitle}`,
      related_identifiers: {
        cohort_id: cohort.id,
        cms_program_id: cohort.cmsProgram.id
      }
    })
  }

  return (
    <div
      className="group mt-12 mb-6 flex w-full max-w-[890px] items-center rounded-lg bg-white px-8 py-6 hover:cursor-pointer"
      onClick={handleDownloadCertificateClick}
      onKeyUp={onEnterKeyPress(handleDownloadCertificateClick)}
      tabIndex={-1}
      role="link"
    >
      <div className="pr-5">
        <div
          className="h-[48px] w-[48px] rounded-full bg-[#F4F8DA]
            p-[13px] group-hover:bg-[#E2EC9A]"
        >
          <Download className="hidden h-[22px] w-[22px] group-hover:block" />
          <Tada className="block h-[22px] w-[22px] group-hover:hidden" />
        </div>
      </div>

      <div>
        <RfParagraphMediumBold className="leading-6">
          {downloadCtaTitle}
        </RfParagraphMediumBold>
        <RfParagraphSmall className="leading-5">{downloadCtaSubtitle}</RfParagraphSmall>
      </div>
    </div>
  )
}

export default CompletionCertificate
