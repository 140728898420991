import clsx from 'clsx'
import { Fragment } from 'react'

import SkeletonRfHeader from 'components/skeletons/typography/SkeletonRfHeader'
import SkeletonRfParagraph from 'components/skeletons/typography/SkeletonRfParagraph'

interface SkeletonSearchResultItemProps {
  className?: string
}
export const SkeletonSearchResultItem = ({
  className
}: SkeletonSearchResultItemProps) => (
  <div className={clsx('rounded border border-rb-gray-100 p-6 pt-4', className)}>
    <SkeletonRfHeader className="mb-2 h-8 w-1/4" />
    <SkeletonRfHeader className="mb-4 h-5 w-1/3" />
    {Array.from({ length: 3 }).map((_, i) => (
      <Fragment key={i}>
        <SkeletonRfParagraph className="h-[96px] rounded-sm" />
        {i !== 2 && <div className="my-5 h-px border-b border-gray-100"></div>}
      </Fragment>
    ))}
  </div>
)
