import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom-v5-compat'
import { twMerge } from 'tailwind-merge'

import { useCohortDashboardNotificationsBadgeQuery } from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

interface CohortDashboardNotificationsBadgeProps {
  slug: string
  className?: string
}

export const CohortDashboardNotificationsBadge = ({
  slug,
  className
}: CohortDashboardNotificationsBadgeProps) => {
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const { pollForCohortNotificationsBadge } = useFeatureFlags()

  const { loading, data, stopPolling, refetch } =
    useCohortDashboardNotificationsBadgeQuery({
      variables: { slug },
      // Poll every 1 minute IF the feature flag to poll is on - setting pollInterval to 0 turns off polling https://www.apollographql.com/docs/react/data/queries/#polling
      pollInterval: pollForCohortNotificationsBadge ? 1000 * 60 : 0
    })

  const latestNotificationsCount = data?.currentUser?.unseenCohortNotificationsCount || 0

  useEffect(() => {
    if (
      pathname.includes('notifications') ||
      searchParams.get('refreshBadgeCount') === 'true'
    ) {
      refetch()
    }
  }, [pathname, searchParams, refetch])

  useEffect(() => {
    if (!pollForCohortNotificationsBadge) {
      return
    }

    const timer = setTimeout(
      () => {
        stopPolling()
      },
      1000 * 60 * 10
    ) // Stop polling after 10 minutes

    return () => {
      clearTimeout(timer)
    }
  }, [pollForCohortNotificationsBadge, stopPolling, slug])

  if (loading || latestNotificationsCount === 0) {
    return null
  }

  return (
    <div
      className={twMerge(
        className,
        '!min-w-5 grid !h-5 !place-items-center !rounded-full bg-rb-teal-200 px-2 !text-center !text-m-mini text-white'
      )}
    >
      {latestNotificationsCount}
    </div>
  )
}

export default CohortDashboardNotificationsBadge
