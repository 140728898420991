import Pluralize from 'react-pluralize'
import { useHistory } from 'react-router-dom'

import CohortActivity from 'domains/CohortDashboard/CohortActivity'

import RfHeader2 from 'components/typography/RfHeader/RfHeader2'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'

import {
  useCohortDashboardNotificationsQuery,
  useMarkActivitiesAsReadMutation,
  useSetUserCohortPreferencesMutation
} from 'gql'

interface CohortDashboardNotificationsProps {
  slug: string
}

const CohortDashboardNotifications = ({ slug }: CohortDashboardNotificationsProps) => {
  const history = useHistory()

  const {
    loading,
    data,
    refetch: refetchNotifications
  } = useCohortDashboardNotificationsQuery({
    fetchPolicy: 'no-cache',
    variables: { slug }
  })

  const [markActivitiesAsRead] = useMarkActivitiesAsReadMutation()
  const [setUserCohortPreferences] = useSetUserCohortPreferencesMutation()

  const updateCohortPreferences = async () => {
    await setUserCohortPreferences({
      variables: {
        input: {
          slug: slug,
          setActivitiesLastViewedAt: true
        }
      }
    })
  }

  const refreshNotifications = async () => {
    updateCohortPreferences()
    refetchNotifications()
  }

  if (loading || !data) {
    return null // return loading later
  }

  const activities = data.currentUser?.cohortAllActivitiesWithMetadata.activities || []
  const unreadCount = data.currentUser?.cohortAllActivitiesWithMetadata.unreadCount || 0

  const markAllAsRead = async () => {
    // mark as read the specific activities on this page so that we do not accidentally mark
    // other activities as read outside of cohorts dashboard
    const ids = activities.map((activity) => activity.unreadActivityIds || []).flat()

    if (!ids.length) {
      return
    }

    await markActivitiesAsRead({
      variables: {
        input: {
          ids
        }
      }
    })

    await refreshNotifications()
    history.push(`/cohorts/${slug}/notifications?refreshBadgeCount=true`)
  }

  return (
    <div className="relative w-full overflow-hidden overflow-y-auto">
      <div className="m-8">
        <div className="flex flex-row justify-between">
          <RfHeader2>
            {unreadCount ? (
              <Pluralize singular={'unread notification'} count={unreadCount} />
            ) : (
              'You are all caught up!'
            )}
          </RfHeader2>
          {unreadCount ? (
            <div
              role="button"
              className="mt-3"
              tabIndex={0}
              onKeyDown={markAllAsRead}
              onClick={markAllAsRead}
            >
              <RfParagraphMediumBold className="text-rb-teal-600 hover:underline">
                Mark all as read
              </RfParagraphMediumBold>
            </div>
          ) : null}
        </div>
        <div className="mt-4 flex flex-col space-y-6">
          {activities.map((activity) => {
            return (
              <CohortActivity
                cohortSlug={slug}
                key={activity.activityIds.toString()}
                activity={activity}
                refetchActivities={refreshNotifications}
                isNotificationsFeed={true}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default CohortDashboardNotifications
