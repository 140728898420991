import { MouseEvent } from 'react'
import Pluralize from 'react-pluralize'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { getTimeInWords } from 'domains/CohortViewer/ModuleSection'

import ProgressThumbnail from 'components/ProgressThumbnail/ProgressThumbnail'
import LockIcon from 'components/icons/LockIcon'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import { CohortDashboardLessonPartsFragment } from 'gql'

import { getLessonProgressPercent, isLessonComplete } from 'utils/cohortUtils'
import { TAGS } from 'utils/lessonUtils'

import { ModuleProgress } from 'typings/scalars'

interface LessonCardProps {
  lesson: CohortDashboardLessonPartsFragment
  userProgress: ModuleProgress
  slug: string
  onTrack?: (lesson: CohortDashboardLessonPartsFragment) => void
  className?: string
  thumbnailClassname?: string
  number?: number
  nameOverride?: string
  largeTitle?: boolean
  lockLesson?: boolean
}

const LessonCard = ({
  lesson,
  userProgress,
  slug,
  onTrack,
  className,
  number,
  nameOverride,
  thumbnailClassname,
  largeTitle = false,
  lockLesson = false
}: LessonCardProps) => {
  const getLessonTimeInWords = (
    lessonEstimatedTime: number,
    lessonProgressPercent: number,
    lessonComplete: boolean
  ) => {
    if (lessonProgressPercent > 0 && !lessonComplete) {
      const lessonTimeLeft =
        lessonEstimatedTime -
        Math.floor((lessonProgressPercent / 100) * lessonEstimatedTime)
      return (
        <Pluralize
          singular="minute left"
          plural="minutes left"
          count={lessonTimeLeft}
          showCount
        />
      )
    }
    return getTimeInWords(lessonEstimatedTime)
  }

  const lessonProgressPercent = getLessonProgressPercent(userProgress, lesson)
  const lessonComplete = isLessonComplete(userProgress, lesson)
  const lessonTag = lesson.tags?.[0] // only handling one lesson tag at a time for now

  const handleLessonCardClick = (e: MouseEvent<HTMLAnchorElement>) => {
    onTrack?.(lesson)
    lockLesson && e.preventDefault()
  }

  return (
    <Link
      className={twMerge('hover:no-underline', lockLesson ? 'cursor-default' : '')}
      to={`/cohorts/${slug}${lockLesson ? '' : lesson.href}`}
      onClick={handleLessonCardClick}
      key={lesson.id}
    >
      <div
        data-testid={`lesson-${lesson.id}`}
        className={twMerge(
          `flex flex-row space-x-4 px-6 ${lockLesson ? '' : 'hover:bg-rb-gray-20'}`,
          className
        )}
      >
        {number && (
          <RfParagraphSmall color={ColorOptions.gray} className="mt-6">
            {number}
          </RfParagraphSmall>
        )}
        <ProgressThumbnail
          className={thumbnailClassname}
          heroImageUrl={lesson.heroImageUrl}
          progressComplete={lessonComplete}
          progressPercent={lessonProgressPercent as number}
        />
        <div className="mt-2 flex flex-col">
          {largeTitle ? (
            <RfParagraphMedium>{nameOverride || lesson.name}</RfParagraphMedium>
          ) : (
            <RfParagraphSmall>{nameOverride || lesson.name}</RfParagraphSmall>
          )}
          <div className="mt-2 flex items-center">
            {lockLesson && <LockIcon className="mr-1 h-4 w-3 fill-[#757B74]" />}
            <RfParagraphMini className="text-rb-gray-300">
              {getLessonTimeInWords(
                lesson.estimatedTime,
                lessonProgressPercent,
                lessonComplete
              )}
            </RfParagraphMini>
            {Object.keys(TAGS).map((tag, index) => {
              if (lessonTag) {
                return (
                  <div
                    key={index}
                    className={twMerge(
                      'ml-2.5 w-fit rounded px-2 py-0.5',
                      TAGS[tag].tagColor
                    )}
                  >
                    <RfParagraphMini color={TAGS[tag].textColor}>{tag}</RfParagraphMini>
                  </div>
                )
              }
            })}
          </div>
        </div>
      </div>
    </Link>
  )
}

export default LessonCard
