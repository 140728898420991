import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'
import { guideThumbnailSrc } from 'domains/Unit/helpers'

import { CardVariants } from 'components/cards/Content/BaseCard'
import GuideCard from 'components/cards/Content/GuideCard'

import { Unit, useUserBookmarksQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

interface SeoPageGuideCardProps {
  guide: Unit
  location?: string
}

const SeoPageGuideCard = ({ guide, location }: SeoPageGuideCardProps) => {
  const { isLoggedIn } = useCurrentUser()

  const { data: allBookmarksData } = useUserBookmarksQuery({
    skip: !isLoggedIn,
    fetchPolicy: 'network-only'
  })
  const bookmarks = allBookmarksData?.currentUser?.guideBookmarks

  const {
    currentBookmarkForDropdown,
    isAddToBookmarkFolderModalOpen,
    closeAddToBookmarkFolderModal
  } = useOpenAddToBookmarkFolderModal()
  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    closeCreateBookmarkFolderModal,
    openCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()

  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }

  return (
    <>
      <GuideCard
        overrideImage={guideThumbnailSrc(guide)}
        guide={guide}
        variant={CardVariants.Vertical}
        pageLocation={location}
        additionalRelatedIdentifiers={{
          display_type: 'vertical'
        }}
        bookmark={bookmarks?.find((obj) => obj.sanityId === guide.id)}
      />

      <AddBookmarkToFolderModal
        isOpen={isAddToBookmarkFolderModalOpen}
        handleClose={closeAddToBookmarkFolderModal}
        bookmarkFolders={allBookmarksData?.currentUser?.bookmarkFolders}
        openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdown}
        showCollectionsOnboardingInfo={
          !allBookmarksData?.currentUser?.hasSeenCollectionsPrompt
        }
      />
      <CreateBookmarkFolderModal
        isModalOpen={isCreateBookmarkFolderModalOpen}
        handleClose={closeCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
        trackingTriggerAction={'bookmark'}
      />
    </>
  )
}

export default SeoPageGuideCard
