import { twMerge } from 'tailwind-merge'

import Image from 'domains/Sanity/Image'

interface FacePileImageProps {
  className?: string
  src?: string
  zIndex: number
  imageSize: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge'
}

const FacePileImage = ({ className, src, zIndex, imageSize }: FacePileImageProps) => {
  const getImageSize = () => {
    if (imageSize === 'small') return 'w-5 h-5'
    if (imageSize === 'medium') return 'w-6 h-6'
    if (imageSize === 'large') return 'w-5 h-5 sm:w-8 sm:h-8'
    if (imageSize === 'xlarge') return 'w-8 h-8'
    if (imageSize === 'xxlarge') return 'w-12 h-12'

    return ''
  }

  return (
    <div className={twMerge('-ml-2', getImageSize(), className)}>
      <Image
        style={{ zIndex }}
        className={twMerge(
          'h-full w-full rounded-full border border-white bg-white object-cover'
        )}
        src={src}
        data-test="facepile-image"
        alt=""
      />
    </div>
  )
}

export default FacePileImage
