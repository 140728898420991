import { twMerge } from 'tailwind-merge'

import { BaseFilterSet } from './Filter.types'

export interface SortByFilterSet extends BaseFilterSet {
  explainer?: string
  options: string[][]
  type: 'sortby'
}

export interface ISortByFilter {
  filterSet: SortByFilterSet
  replaceFilters: (filterKey: string, newValue: string) => void
  val: string | number | null | string[]
}

const SortBy = (props: ISortByFilter) => {
  const { filterSet, val } = props

  const setSortBy = (value: string) => {
    props.replaceFilters(filterSet.key, value)
  }

  return (
    <ul className="mb-5 cursor-pointer list-none rounded-sm p-0 text-sm font-medium capitalize tracking-widest text-rb-gray-300">
      {filterSet.options.map((filterOption, index) => {
        const curFilterDisplay = filterOption[1]
        const curFilterValue = filterOption[0]
        return (
          <li
            key={`sort-option-${index}`}
            className={twMerge(
              'inline-block w-1/2 border-2 border-rb-gray-50 text-center leading-9 first:rounded-l-sm last:rounded-r-sm',
              val === curFilterValue
                ? 'z-[1] border-2 border-rb-gray-400 bg-rb-gray-50 text-rb-gray-500'
                : ''
            )}
            onClick={() => {
              setSortBy(curFilterValue)
            }}
          >
            {curFilterDisplay}
          </li>
        )
      })}
    </ul>
  )
}

export default SortBy
