import { useEffect } from 'react'

import { CohortPassSection } from 'components/CheckoutModal/components/CohortPassSection'
import { getCohortPassPrice, getDefaultPassCount } from 'components/CheckoutModal/utils'

import { trackCtaClicked } from 'utils/tracking/analytics'

import { OrderRecapSectionWrapper } from './OrderRecapSection'
import { TwoColumnLayout } from './components/TwoColumnLayout'
import { usePurchaseFlowContext } from './contexts/PurchaseFlowContext'

export const SelectAddOnsPage = () => {
  const {
    cohortPassQuantity,
    cohortPassPriceTiers,
    goToNextStep,
    planName,
    setCohortPassPrice,
    setCohortPassQuantity
  } = usePurchaseFlowContext()
  const defaultPassCount = getDefaultPassCount(planName)

  // Set default pass count and cohort-pass subtotal
  useEffect(() => {
    setCohortPassQuantity(defaultPassCount)
    const pricePerPass = getCohortPassPrice({
      count: defaultPassCount,
      cohortPassPriceTiers
    })
    setCohortPassPrice(pricePerPass)
  }, [defaultPassCount, cohortPassPriceTiers, setCohortPassPrice, setCohortPassQuantity])

  const handleNextClick = () => {
    trackCtaClicked({
      cta_location: 'choose_addons_page',
      cta_type: 'button',
      text: 'next'
    })
    goToNextStep()
  }

  const handleCountChange = (count: number) => {
    setCohortPassQuantity(count)
    const pricePerPass = getCohortPassPrice({ count, cohortPassPriceTiers })
    setCohortPassPrice(pricePerPass)
  }

  return (
    <TwoColumnLayout>
      <section className="max-w-[500px] lg:mr-12">
        <CohortPassSection
          defaultPassCount={defaultPassCount}
          onCountChange={handleCountChange}
          location="select_add_ons_page"
          count={cohortPassQuantity}
          cohortPassPriceTiers={cohortPassPriceTiers}
          titleText="Buy Access to Live Courses for Your Team"
          subtitleText="Each Live Course Pass allows one person to enroll in any upcoming Live Course hosted by top experts, offering direct access to the knowledge and insights from their experiences in tech."
          planName={planName}
        />
        <p className="mt-6 text-sm text-rb-gray-300">
          Live Course Passes must be used within one year of today. After one year, your
          passes will expire. You must have an active membership to use your Live Course
          Passes.
        </p>
      </section>
      <section className="my-12 w-full max-w-[420px] lg:my-0 lg:ml-12 lg:w-auto">
        <OrderRecapSectionWrapper
          onNextClick={handleNextClick}
          nextButtonText="Go to Payment"
        />
      </section>
    </TwoColumnLayout>
  )
}
