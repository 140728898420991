import { useCohortConversationTrackingContext } from 'domains/CohortConversation/hooks/useCohortConversationsTrackingContext'
import { CohortPostCreateProps } from 'domains/CohortConversation/hooks/useCreateCohortPost'

import { useUpdateCohortPostMutation } from 'gql'

import { trackPostAction } from 'utils/tracking/generated/events'

interface CohortPostUpdateProps extends CohortPostCreateProps {
  postId: string
}

interface _TogglePinPostProps extends CohortPostUpdateProps {
  isPinned: boolean
  refetch?(): Promise<unknown>
}

type TogglePinPostProps = Omit<_TogglePinPostProps, 'body'>
type TrackProps = Omit<CohortPostUpdateProps, 'body'>

export const useUpdateCohortPost = () => {
  const [updateCohortPostMutation, { loading }] = useUpdateCohortPostMutation()
  const { cohort, topicId, groupId } = useCohortConversationTrackingContext()

  const track = ({ postId }: TrackProps) => {
    trackPostAction({
      action: 'update',
      group_ids: [groupId],
      topic_ids: [topicId],
      post_id: postId,
      cms_program_id: cohort?.cmsProgram?.id,
      cohort_id: cohort?.id,
      season_id: cohort?.season?.id
    })
  }

  const updateCohortPost = async ({ postId, body }: CohortPostUpdateProps) => {
    if (postId) {
      const response = await updateCohortPostMutation({
        variables: { input: { id: postId, body } }
      })

      const responsePostId = response?.data?.updateCohortPost?.id
      if (responsePostId) {
        track({ postId: responsePostId })
      }
    }
  }

  const onTogglePinPost = async ({ postId, isPinned, refetch }: TogglePinPostProps) => {
    if (postId) {
      const response = await updateCohortPostMutation({
        variables: { input: { id: postId, isPinned } }
      })

      const responsePostId = response?.data?.updateCohortPost?.id
      if (responsePostId) {
        track({ postId: responsePostId })
      }

      refetch?.()
    }
  }

  return { updateCohortPost, onTogglePinPost, isUpdatingPost: loading }
}

export default useUpdateCohortPost
