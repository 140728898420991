import Button from 'components/Button'

import { getCookie, setCookie } from 'utils/cookieUtils'

const setJoinGroupCookie = () => {
  setCookie('joinGroupPromptShown', '1', 365)
}

const shouldShowJoinGroupPrompt = () => {
  return getCookie('joinGroupPromptShown') === null
}

const JoinGroupPrompt = () => {
  if (shouldShowJoinGroupPrompt()) {
    return (
      <div
        uk-alert=""
        id="join-group-prompt"
        className="flex flex-col items-center bg-rb-gray-500 p-11 text-white md:hidden"
      >
        <button
          className="uk-alert-close"
          type="button"
          uk-close=""
          onClick={setJoinGroupCookie}
        ></button>
        <h3 className="mb-1 text-lg font-semibold text-white">New! Reforge Groups</h3>
        <p className="uk-text-center m-0">
          Meet experts, support peers, and stay up-to-date on the latest discussion.
        </p>

        <Button href="/groups" className="mt-2.5" variant="outline" size="small">
          Join a Group
        </Button>
      </div>
    )
  } else {
    return null
  }
}

export default JoinGroupPrompt
