import ReactDOM from 'react-dom'

import CardTooltip from 'components/CardTooltip'
import { ContentPopoverCard } from 'components/cards/ContentPopoverCard'
import UpgradeUserCard from 'components/cards/UpgradeUserCard'

interface ContentLinksProps {
  cmsProgramId?: string
  cmsModuleId?: string
  isPremember: boolean
  links: HTMLAnchorElement[]
  pageType: string
  accessPolicyKind: string
  hasApplied: boolean
}

export default function ContentLinks(props: ContentLinksProps) {
  const { cmsProgramId, cmsModuleId, isPremember, links, accessPolicyKind, hasApplied } =
    props

  return (
    <>
      {links.map((el, idx) => {
        let content
        if (isPremember) {
          content = (
            <UpgradeUserCard
              message="All Reforge Projects & Concepts are available with membership."
              hasApplied={hasApplied}
              accessPolicyKind={accessPolicyKind}
            />
          )
          const newEl = document.createElement('span')
          newEl.className = 'relative inline font-bold text-rb-teal-200'
          newEl.innerText = el.innerText
          el.parentNode && el.parentNode.replaceChild(newEl, el)
          return ReactDOM.createPortal(
            <CardTooltip
              key={`card-tooltip-${idx}`}
              content={content}
              delay={0}
              direction="top"
              yOffset={accessPolicyKind === 'account_created' ? '-90px' : '-110px'}
            >
              <span />
            </CardTooltip>,
            newEl
          )
        }
        if (!cmsProgramId) {
          return
        }
        content = (
          <ContentPopoverCard
            url={el.href}
            cmsProgramId={cmsProgramId}
            cmsModuleId={cmsModuleId}
          />
        )
        return ReactDOM.createPortal(
          <CardTooltip key={`card-tooltip-${idx}`} content={content} direction="top">
            <span />
          </CardTooltip>,
          el
        )
      })}
    </>
  )
}
