import { useHistory } from 'react-router-dom'

import { useRestartPlanNowSuccessModal } from 'domains/Subscription/RestartPlanNowSuccessModal/useRestartPlanNowSuccessModal'

import { SubscriptionUpdatedModalContent } from 'components/CheckoutModal/postCheckoutModals/SubscriptionUpdatedModal/SubscriptionUpdatedModalContent'
import { useGlobalModal } from 'components/GlobalModal'

import { PlanName } from 'gql'

import { trackModalDismissed, trackModalDisplayed } from 'hooks/useModalTracking'

import { CheckoutModalContent } from './CheckoutModalContent'
import { CohortPassPurchasedModalContent } from './postCheckoutModals/CohortPassPurchasedModal/CohortPassPurchasedModalContent'
import { CheckoutFlowContext } from './utils'

export const modalStyleProps = {
  className: 'mx-2 max-w-[880px] rounded-lg',
  header: false
}

export const useCheckoutModal = () => {
  const { openGlobalModal, closeGlobalModal } = useGlobalModal()
  const { openRestartPlanNowSuccessModal } = useRestartPlanNowSuccessModal()
  const history = useHistory()

  const openCheckoutModal = ({
    flowContext = 'upgradeSubscription',
    planName,
    numCohortPasses,
    isIndividual
  }: {
    flowContext?: CheckoutFlowContext
    planName?: PlanName
    numCohortPasses?: number
    isIndividual?: boolean
  }) => {
    trackModalDisplayed({
      modal_group: 'purchase_flow',
      modal_name: 'checkout_modal'
    })

    openGlobalModal(
      <CheckoutModalContent
        selectedPlanName={planName}
        numCohortPasses={numCohortPasses}
        onPurchaseSuccess={({ cohortPassCount }: { cohortPassCount: number }) => {
          if (flowContext === 'cohortPassPurchase') {
            openCohortPassPurchasedModal(cohortPassCount, isIndividual)
          } else if (flowContext === 'restartSubscription') {
            openRestartPlanNowSuccessModal({ restartedWhen: 'now' })
          } else {
            openSubscriptionUpdatedModal()
          }
        }}
        handleClose={handleCheckoutModalClose}
        flowContext={flowContext}
      />,
      {
        ...modalStyleProps,
        handleClose: handleCheckoutModalClose
      }
    )
  }

  const handleCheckoutModalClose = () => {
    trackModalDismissed({
      modal_group: 'purchase_flow',
      modal_name: 'checkout_modal'
    })

    closeGlobalModal()
  }

  const openCohortPassPurchasedModal = (
    cohortPassCount: number,
    isIndividual?: boolean
  ) => {
    trackModalDisplayed({
      modal_group: 'purchase_flow',
      modal_name: 'cohort_purchase_success'
    })

    openGlobalModal(
      <CohortPassPurchasedModalContent
        handleClose={handleCohortPassPurchasedModalClose}
        cohortPassCount={cohortPassCount}
        isIndividual={isIndividual}
      />,
      {
        className: 'mx-2 max-w-[880px] rounded-lg',
        handleClose: handleCohortPassPurchasedModalClose
      }
    )
  }

  const handleCohortPassPurchasedModalClose = ({
    trackClose = false,
    nextPagePath
  }: {
    trackClose?: boolean
    nextPagePath?: string
  }) => {
    if (trackClose) {
      trackModalDismissed({
        modal_group: 'purchase_flow',
        modal_name: 'cohort_purchase_success'
      })
    }

    closeGlobalModal()

    if (nextPagePath) {
      history.push(nextPagePath)
    } else {
      window.location.reload()
    }
  }

  const openSubscriptionUpdatedModal = () => {
    trackModalDisplayed({
      modal_group: 'purchase_flow',
      modal_name: 'subscription_purchase_success'
    })

    openGlobalModal(
      <SubscriptionUpdatedModalContent
        handleClose={handleSubscriptionUpdatedModalClose}
      />,
      {
        className: 'mx-2 max-w-[880px] rounded-lg',
        handleClose: handleSubscriptionUpdatedModalClose
      }
    )
  }

  const handleSubscriptionUpdatedModalClose = ({
    trackClose = false,
    nextPagePath
  }: {
    trackClose?: boolean
    nextPagePath?: string
  }) => {
    if (trackClose) {
      trackModalDismissed({
        modal_group: 'purchase_flow',
        modal_name: 'subscription_purchase_success'
      })
    }

    closeGlobalModal()

    if (nextPagePath) {
      history.push(nextPagePath)
    } else {
      window.location.reload()
    }
  }

  return {
    openCheckoutModal
  }
}
