import { Redirect } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom-v5-compat'

import CollectionShow from 'domains/Collections/CollectionShow'

import { CurrentUserPartsFragment } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

export const useSavedItemsPage = (currentUser?: CurrentUserPartsFragment | null) => ({
  // This is the same permission we require to access Program bookmarks
  canAccessSavedItemsPage: !!currentUser?.is?.member
})

const CollectionShowPage = () => {
  const currentUser = useAssertCurrentUser()
  const { canAccessSavedItemsPage } = useSavedItemsPage(currentUser)
  const [searchParams] = useSearchParams()
  const editOnShow = !!searchParams.get('edit')

  if (!canAccessSavedItemsPage) {
    return <Redirect to="/" />
  }

  return (
    <div className="box-content">
      <CollectionShow editOnShow={editOnShow} />
    </div>
  )
}

export default CollectionShowPage
