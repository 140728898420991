import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useCohortConversationPostsQuery } from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

const BATCH_SIZE = 10

export const useCohortConversationLoader = (
  slug: string,
  topicSlug: string,
  sortBy: string
) => {
  const { pollForCohortPosts } = useFeatureFlags()
  const history = useHistory()

  const { loading, data, error, refetch, stopPolling, fetchMore } =
    useCohortConversationPostsQuery({
      variables: {
        slug: slug,
        topicSlug: topicSlug,
        sortBy: sortBy,
        limit: BATCH_SIZE,
        offset: 0
      },
      // Poll Every 30 seconds IF feature flag on
      pollInterval: pollForCohortPosts ? 30000 : 0
    })

  useEffect(() => {
    if (error) {
      history.push(`/cohorts/${slug}`)
    }
  }, [error, history, slug])

  useEffect(() => {
    if (!pollForCohortPosts) {
      return
    }

    const timer = setTimeout(
      () => {
        stopPolling()
      },
      1000 * 60 * 5
    ) // Stop polling after 5 minutes

    return () => {
      clearTimeout(timer)
    }
  }, [pollForCohortPosts, stopPolling, topicSlug, sortBy, slug])

  return {
    topic: data?.cohortPosts.topic,
    posts: data?.cohortPosts.posts,
    pinnedPosts: data?.cohortPosts.pinnedPosts,
    postsLoading: loading,
    postsError: error,
    refetchPosts: refetch,
    fetchMorePosts: fetchMore,
    postCount: data?.cohortPosts.count
  }
}
