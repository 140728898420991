import { useInlinePostsQuery } from 'gql'

import { track } from 'utils/tracking/segment'

interface UseDiscussionsPanel {
  cmsSectionId: string
  userId: string
  cmsProgramId: string
  cmsModuleId: string
}

const useDiscussions = ({
  cmsSectionId,
  userId,
  cmsProgramId,
  cmsModuleId
}: UseDiscussionsPanel) => {
  const {
    data,
    loading,
    error: errors,
    refetch
  } = useInlinePostsQuery({
    variables: {
      cmsSectionId,
      userId
    }
  })

  const trackContentAction = (anchor: string) => {
    // @ts-ignore - 'Content Viewer - Action' event is not defined in Segment JIRA#REF-5159
    track('Content Viewer - Action', {
      user_id: userId,
      cms_program_id: cmsProgramId,
      module_id: cmsModuleId,
      lesson_id: cmsSectionId,
      anchor: anchor,
      action: 'click_comment_content_viewer_drawer'
    })
  }

  return {
    inlinePosts: data?.inlinePosts || [],
    loading,
    errors,
    refetch,
    trackContentAction
  }
}

export default useDiscussions
