import { useState } from 'react'

import AccountLayout from 'domains/Account/AccountLayout'
import UnenrollModal from 'domains/CohortHistory/UnenrollModal'
import ProgramSelectorModal from 'domains/Enrollment/ProgramSelectorModal'

import { ErrorMessage, Loading } from 'components'
import {
  ShareableSnapshotModal,
  useShareableSnapshotModal
} from 'components/ShareableSnapshotModal'

import {
  CohortHistoryUserFragment,
  CurrentUserCohortFragment,
  PreviousUserCohortFragment,
  Season,
  UserCohort,
  UserCourse,
  useUserCohortHistoryQuery
} from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import { downloadCohortCertificateOfCompletion } from 'utils/cohortUtils'
import { MONTH_ABBREV_YEAR_FORMAT, getFormattedStartDate, isAfterDate } from 'utils/date'

const AccountCohortHistoryContainer = () => {
  const { id: currentUserId } = useAssertCurrentUser()

  const { loading, error, data } = useUserCohortHistoryQuery({
    variables: { userId: currentUserId }
  })

  if (loading) return <Loading />
  if (error) return <ErrorMessage error={error} />

  if (!data?.user) {
    return null
  }

  const userIsMember = data?.user?.is.member || false
  const previousUserCohorts =
    (data.user &&
      data.user.cohorts &&
      data.user.cohorts.previous &&
      [...data.user.cohorts.previous].reverse()) ||
    []
  const currentUserCohorts = data.user.cohorts?.current || []
  const unorderedUserCourses = data.user.userCourses.all || []
  const orderedUserCourses = [...unorderedUserCourses].sort((a, b) =>
    isAfterDate(a.courseSession?.startsAt, b.courseSession?.startsAt) ? 1 : -1
  )

  return (
    <AccountCohortHistory
      currentUser={data.user}
      userIsMember={userIsMember}
      previousUserCohorts={previousUserCohorts}
      currentUserCohorts={currentUserCohorts}
      userCourses={orderedUserCourses}
      enrollmentSeason={data.enrollmentSeason as Season}
    />
  )
}

export interface AccountCohortHistoryProps {
  currentUser: CohortHistoryUserFragment
  previousUserCohorts: PreviousUserCohortFragment[]
  currentUserCohorts: CurrentUserCohortFragment[]
  userIsMember: boolean
  enrollmentSeason: Season
  userCourses: any[]
  currentUserId?: string
}

export const AccountCohortHistory = ({
  currentUser,
  userIsMember,
  previousUserCohorts,
  currentUserCohorts,
  userCourses,
  enrollmentSeason
}: AccountCohortHistoryProps) => {
  const { canShowSnapshotModal, showShareableSnapshotModal } = useShareableSnapshotModal()

  const [selectedUserCohort, setSelectedUserCohort] = useState(
    currentUserCohorts.length > 0 ? currentUserCohorts[0] : null
  )
  const [enrolledUserCohorts, setEnrolledUserCohorts] = useState(currentUserCohorts)
  const [maxActions, setMaxActions] = useState(1)
  const [showProgramSelectorModal, setShowProgramSelectorModal] = useState(false)
  const [isUnenrollModalOpen, setIsUnenrollModalOpen] = useState(false)

  const { timezone } = useAssertCurrentUser()

  const thClassName =
    'tl:!py-[7px] tl:!px-3 !py-1 !px-1.5 !text-xs !font-medium !text-rb-gray-300 !tracking-widest'
  const tdClassName = 'tl:!py-[7px] tl:!px-3 !py-1 !px-1.5'

  const openProgramSelector = (enrolledUserCohort: UserCohort) => {
    setSelectedUserCohort(enrolledUserCohort)
    setShowProgramSelectorModal(true)
  }

  const openRemoveProgramConfirmation = (enrolledUserCohort: UserCohort) => {
    setSelectedUserCohort(enrolledUserCohort)
    setIsUnenrollModalOpen(true)
  }

  const onProgramSelectorModalClose = () => {
    setShowProgramSelectorModal(false)
  }

  const afterUnenrollment = (userCohortId: string) => {
    // remove the current enrolled program from the list of enrolled programs.
    setEnrolledUserCohorts(
      enrolledUserCohorts.filter((userCohort) => userCohort.id !== userCohortId)
    )
  }

  const getCertificatePdfPath = (cmsProgramId: string) => {
    return `/letters/${cmsProgramId}/pdf.pdf`
  }

  const handleOnEnroll = () => {
    setShowProgramSelectorModal(false)
    setIsUnenrollModalOpen(true)
  }

  const getCourseRow = (userCourse: UserCourse) => {
    return (
      <tr key={`user_course_${userCourse.id}`}>
        <td className={tdClassName}>
          {`${getFormattedStartDate(
            userCourse.courseSession?.startsAt,
            timezone,
            MONTH_ABBREV_YEAR_FORMAT
          )} • ${userCourse.course.title}`}
        </td>
        <td />
        <td />
      </tr>
    )
  }

  const getCohortRow = (userCohort: UserCohort, status: string) => {
    const actionElements = []

    if (userCohort.canBeUpdated) {
      actionElements.push(
        <td className={tdClassName} key={`modify-${userCohort.id}`}>
          <button
            className="text-rb-gray-300 focus:outline-none"
            onClick={() => {
              openProgramSelector(userCohort)
            }}
          >
            Modify
          </button>
        </td>
      )
      actionElements.push(
        <td
          data-test="unenrollment-button"
          className={tdClassName}
          key={`unenroll-${userCohort.id}`}
        >
          <button
            className="focus:outline-none"
            onClick={() => {
              openRemoveProgramConfirmation(userCohort)
            }}
          >
            Unenroll
          </button>
        </td>
      )
    }

    if (canShowSnapshotModal(userCohort.id)) {
      actionElements.push(
        <td className={tdClassName} key={`view-snapshot-${userCohort.id}`}>
          <button
            className="rf-margin-5-right text-rb-gray-300 focus:outline-none"
            data-test="cohort-history-snapshot-entrypoint"
            onClick={() => {
              showShareableSnapshotModal('Cohort History')
            }}
          >
            View Snapshot
          </button>
        </td>
      )
    }

    if (userCohort.canViewCertificate) {
      actionElements.push(
        <td className={tdClassName} key={`view-certificate-${userCohort.id}`}>
          {userCohort.cohortCompletionCertificate ? (
            <button
              onClick={() => downloadCohortCertificateOfCompletion(userCohort)}
              className="text-teal-700 hover:underline"
            >
              Certificate of Completion
            </button>
          ) : (
            <a
              href={getCertificatePdfPath(userCohort.programId)}
              target="_blank"
              rel="noreferrer"
            >
              View Letter of Completion
            </a>
          )}
        </td>
      )
    }

    if (actionElements.length > maxActions) {
      setMaxActions(actionElements.length)
    }
    while (actionElements.length < maxActions) {
      actionElements.push(
        <td className={tdClassName} key={`no-action-${actionElements.length}`}></td>
      )
    }

    return (
      <tr key={`user_cohort${userCohort.id}`}>
        <td
          className={tdClassName}
        >{`${userCohort.season} • ${userCohort.programName}`}</td>
        <td className={tdClassName}>{status}</td>
        {actionElements}
      </tr>
    )
  }

  return (
    <>
      <AccountLayout>
        <div className="rf-rb-card mb-5 bg-white p-10">
          <div className="text-xl text-rb-gray-400">Live Programs</div>
          <table className="uk-table uk-table-striped uk-table-small text-[13px] text-rb-gray-500 tl:text-sm">
            <thead>
              <tr>
                <th className={thClassName}>Course</th>
                <th className={thClassName}>Status</th>
                <th className={thClassName}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {userCourses.map((userCourse) => getCourseRow(userCourse as UserCourse))}
              {enrolledUserCohorts.map((enrolledUserCohort) =>
                getCohortRow(enrolledUserCohort as UserCohort, 'Enrolled')
              )}
              {previousUserCohorts.map((previouslyenrolledUserCohort) =>
                getCohortRow(previouslyenrolledUserCohort as UserCohort, 'Ended')
              )}
            </tbody>
          </table>
          {showProgramSelectorModal && (
            <ProgramSelectorModal
              isOpen={showProgramSelectorModal}
              onClose={onProgramSelectorModalClose}
              postPayment={userIsMember}
              sourceFlow="Cohort History"
              selectedProgramId={selectedUserCohort?.programId}
              canUnenroll={!!(enrollmentSeason && selectedUserCohort?.programId)}
              onUnenroll={handleOnEnroll}
            />
          )}
          <UnenrollModal
            isOpen={isUnenrollModalOpen}
            handleClose={() => setIsUnenrollModalOpen(false)}
            selectedUserCohort={selectedUserCohort as UserCohort}
            afterUnenrollment={afterUnenrollment}
            postPayment={userIsMember}
            currentUserId={currentUser.id}
          />
        </div>
        <ShareableSnapshotModal page="Cohort History" />
      </AccountLayout>
    </>
  )
}

export default AccountCohortHistoryContainer
