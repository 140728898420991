import { ReactComponent as BookmarkEmptyStateGraphic } from 'images/bookmark-empty-state-graphic.svg'
import { ReactComponent as CommentEmptyStateGraphic } from 'images/comment-empty-state-graphic.svg'

export interface EmptyStateCardProps {
  title: string
  body: string
  show?: boolean
  variant?: EmptyStateVariant
}

export enum EmptyStateVariant {
  bookmark,
  comment
}

/** Card displayed when no bookmarks or team comments exist in the Content Viewer drawer */
export const EmptyStateCard = ({
  title,
  body,
  show = false,
  variant = EmptyStateVariant.bookmark
}: EmptyStateCardProps) => {
  const EmptyStateGraphic =
    variant === EmptyStateVariant.comment
      ? CommentEmptyStateGraphic
      : BookmarkEmptyStateGraphic

  return show ? (
    <div
      className="mt-6 flex w-[298px] flex-col rounded-[20px] bg-gray-50 p-6"
      data-testid="bookmark-drawer-empty-state-card"
    >
      <div className="text-xl font-medium">{title}</div>
      <EmptyStateGraphic className="my-8 self-center" width="185" height="123" />
      <div className="font-light">{body}</div>
    </div>
  ) : null
}
