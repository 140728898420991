import { UserSubscriptionCancelModalQuery } from 'gql'

import { formatInTimezone } from 'utils/date'

export const getCancelationDetails = (
  data?: UserSubscriptionCancelModalQuery,
  timezone?: string | null
) => {
  const isDunning = !!data?.user?.subscriptions?.active?.isDunning
  const expiresAtDate = data?.user?.subscriptions?.active?.expiresAt || ''
  return isDunning
    ? 'You can reactivate your subscription at any time from your Account page.'
    : `
    You will have access to Reforge content until your subscription expires on
    ${formatInTimezone(expiresAtDate, timezone, 'MMMM do, yyyy')}. You can reactivate
    your subscription at any time from your Account page.
      `
}
