import { useState } from 'react'

import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import ProfilePage from './ProfilePage'

const LoggedOutProfilePage = () => {
  const [page, setPage] = useState<GenericPageType>()

  return (
    <MarketingLayout page={page} contentClassName="overflow-auto mb-10">
      <ProfilePage setPage={setPage} />
    </MarketingLayout>
  )
}

export default LoggedOutProfilePage
