const LockIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.98756 0.0350342C4.0572 0.0350342 2.48755 1.60469 2.48755 3.53503V5.53501H0.487549V6.03502V14.035H11.4876V5.53501H9.48756V3.53503C9.48756 1.60469 7.91791 0.0350342 5.98756 0.0350342V0.0350342ZM5.98756 1.03505C7.38121 1.03505 8.48756 2.14139 8.48756 3.53503V5.53501H3.48755V3.53503C3.48755 2.14139 4.5939 1.03505 5.98756 1.03505ZM1.48755 6.53502H10.4876V13.035H1.48755V6.53502ZM5.98756 7.53504C5.16505 7.53504 4.48755 8.21251 4.48755 9.03501C4.48755 9.68159 4.90889 10.2326 5.48755 10.4413V12.035H6.48756V10.4413C7.06622 10.2326 7.48756 9.68159 7.48756 9.03501C7.48756 8.21251 6.81006 7.53504 5.98756 7.53504ZM5.98756 8.53506C6.26962 8.53506 6.48756 8.75294 6.48756 9.03501C6.48756 9.31707 6.26962 9.53502 5.98756 9.53502C5.70549 9.53502 5.48755 9.31707 5.48755 9.03501C5.48755 8.75294 5.70549 8.53506 5.98756 8.53506Z" />
  </svg>
)

export default LockIcon
