import { formatInTimeZone } from 'date-fns-tz'
import { ReactNode, useState } from 'react'

import { COURSE_DASHBOARD_HEADER_ID } from 'pages/CoursesPage/CoursePage'
import CourseSnapshotShareModal from 'pages/CoursesPage/CourseSnapshotShareModal'

import AddCourseEventToCalendar from 'domains/Course/AddCourseEventToCalendar'
import CourseDashboardHeader from 'domains/Course/CourseDashboardHeader'
import { ComplexPortableText } from 'domains/Sanity/PortableText/ComplexPortableText/ComplexPortableText'

import Button from 'components/Button'
import CalendarDropdownMenu, {
  useCalendarDropDown
} from 'components/CalendarDropdownMenu'
import RfHeader3 from 'components/typography/RfHeader/RfHeader3'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import {
  CourseBlock,
  CourseEnrollmentSnapshot,
  CourseEvent,
  CoursePageAccessPartsFragment,
  CourseSessionAccessPartsFragment,
  EventPerCourseSession,
  Maybe,
  useCourseBlocksQuery
} from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import { getCurrentTimezone } from 'utils/date'

import { ReactComponent as CalendarIcon } from 'images/icon--calendar.svg'
import { ReactComponent as ShareIcon } from 'images/share.svg'

export interface CourseWelcomeProps {
  course: CoursePageAccessPartsFragment
  courseSession?: CourseSessionAccessPartsFragment | null
  enrollmentSnapshot?: CourseEnrollmentSnapshot | null
}

interface CourseEventPerCourseSession extends EventPerCourseSession {
  title: Maybe<string>
  sanityId: Maybe<string>
  shortDescription: Maybe<string>
}

const doesEventHaveEventPerCourseSession = (event: CourseEvent) =>
  !!event?.eventPerCourseSession

const mergeEventAttrWithEventPerCourseSession = ({
  eventPerCourseSession,
  title = '',
  sanityId = '',
  shortDescription = ''
}: CourseEvent): CourseEventPerCourseSession[] =>
  eventPerCourseSession?.map((ecs) => ({
    ...ecs,
    title,
    sanityId,
    shortDescription
  })) || []

const extractEventsPerSession = (courseBlocks: CourseBlock[] | undefined | null) =>
  courseBlocks?.flatMap(({ events }) => {
    const validEvents = events?.filter(doesEventHaveEventPerCourseSession) || []

    return validEvents.flatMap(mergeEventAttrWithEventPerCourseSession)
  }) || []

const CourseWelcome = ({
  course,
  courseSession,
  enrollmentSnapshot
}: CourseWelcomeProps) => {
  const { timezone: userTimezone } = useAssertCurrentUser()
  // The user's timezone may be null, fallback to browser
  const timezone = userTimezone || getCurrentTimezone()
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)

  const { isAddToCalendarOpen, setIsAddToCalendarOpen } = useCalendarDropDown()
  const { data, loading } = useCourseBlocksQuery({
    variables: { slug: course?.slug || '', courseSessionId: courseSession?.id || '' }
  })
  const [lastClickedAddButtonIndex, setLastClickedAddButtonIndex] = useState(-1)
  const events = extractEventsPerSession(data?.courseBlocks?.courseBlocks)

  const onClickOutside = () => {
    setLastClickedAddButtonIndex(-1)
    setIsAddToCalendarOpen(false)
  }
  const onAddToCalendarClick = (index: number) => {
    setLastClickedAddButtonIndex(index)
    setIsAddToCalendarOpen(true)
  }

  const renderAddCalendarPopup = (index: number) => {
    if (lastClickedAddButtonIndex === index && isAddToCalendarOpen) {
      const event = events[lastClickedAddButtonIndex]

      if (!event) {
        return
      }

      return (
        <CalendarDropdownMenu
          containerClassName="absolute bg-white bottom-full"
          offset="left-10"
          onClose={onClickOutside}
        >
          <AddCourseEventToCalendar
            event={event}
            timezone={timezone}
            course={course}
            courseBlockTitle="Mark your calendar"
            courseSession={courseSession}
            sourceCtaText="Add"
          />
        </CalendarDropdownMenu>
      )
    }
  }

  return (
    <>
      <CourseDashboardHeader
        course={course}
        courseSession={courseSession}
        id={COURSE_DASHBOARD_HEADER_ID}
        showOffset={false}
        welcomePage
      />
      <div className="flex flex-col items-center justify-center px-7 py-9">
        <div className="flex w-full max-w-[600px] flex-col gap-9">
          <BlockContainer>
            <RfHeader3>
              <span className="font-medium">✅ You&apos;re enrolled!</span>
            </RfHeader3>
            <RfParagraphMedium>
              Check back one week before the start of the course for information about
              course content, events, and more.
            </RfParagraphMedium>

            {enrollmentSnapshot?.imageUrl && (
              <div className="mt-6">
                <RfParagraphMedium>
                  In the meantime, let your peers know that you’ve enrolled so they can
                  follow along with your learning.
                </RfParagraphMedium>
                <div className="relative">
                  <Button
                    color="teal"
                    shape="rounded-full"
                    className="absolute right-5 top-4 font-semibold text-rb-white"
                    size="x-small"
                    iconBefore={
                      <ShareIcon
                        width={16}
                        height={16}
                        className="fill-current text-rb-white"
                      />
                    }
                    onClick={() => setIsShareModalOpen(true)}
                  >
                    Share
                  </Button>
                  <img
                    src={enrollmentSnapshot.imageUrl}
                    className="mt-4 h-auto w-full rounded"
                    alt="Course enrollment snapshot"
                  />
                </div>
              </div>
            )}
          </BlockContainer>

          {!loading && (courseSession?.markYourCalendar || events.length > 0) && (
            <BlockContainer>
              <RfHeader3>
                <span className="font-medium">🗓️ Mark your calendar</span>
              </RfHeader3>
              {events.length > 0 ? (
                <>
                  <RfParagraphMedium>
                    Save these dates and times for live events for your course:
                  </RfParagraphMedium>

                  <ul className="list-disc pl-5">
                    {events.map((event, index) => (
                      <li key={event?.startsAt} className="mt-4 flex justify-between">
                        <span>
                          {formatInTimeZone(
                            event?.startsAt || '',
                            timezone!,
                            'EEEE, MMMM do, h:mm a'
                          )}{' '}
                          - {formatInTimeZone(event?.endsAt || '', timezone!, 'h:mm a')}
                        </span>
                        <span className="relative">
                          <Button
                            onClick={() => onAddToCalendarClick(index)}
                            className="w-full border-none bg-transparent normal-case text-teal-600 lg:w-auto"
                            size="x-small"
                          >
                            <div className="flex flex-row gap-1">
                              <CalendarIcon className="h-5 w-5 fill-current" />
                              Add
                            </div>
                          </Button>
                          {renderAddCalendarPopup(index)}
                        </span>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <ComplexPortableText
                  content={JSON.parse(courseSession?.markYourCalendar)}
                />
              )}
            </BlockContainer>
          )}

          <BlockContainer>
            <RfParagraphSmall className="mb-1.5">
              <span className="font-semibold text-rb-gray-300">Coming soon</span>
            </RfParagraphSmall>
            <RfParagraphSmall className="mb-6">
              <span className="text-rb-gray-300">
                Course content and schedule will become available one week before your
                course start date.
              </span>
            </RfParagraphSmall>

            {[...Array(3)].map((_, index) => (
              <Skeleton key={index} />
            ))}
          </BlockContainer>
        </div>

        <CourseSnapshotShareModal
          isOpen={isShareModalOpen}
          onClose={() => setIsShareModalOpen(false)}
          snapshot={enrollmentSnapshot}
          courseTitle={course?.title || ''}
          courseId={course?.id || ''}
          courseSessionId={courseSession?.id || ''}
          page="course_welcome"
        />
      </div>
    </>
  )
}

const Skeleton = () => (
  <div className="mb-6 flex items-center gap-6">
    <div className="h-[50px] w-[50px] rounded-xl bg-rb-gray-100"></div>
    <div className="flex flex-col gap-1">
      <div className="h-[11px] w-[107px] rounded-xl bg-rb-gray-100"></div>
      <div className="h-[11px] w-[51px] rounded-xl bg-rb-gray-100"></div>
    </div>
  </div>
)

const BlockContainer = ({ children }: { children: ReactNode }) => (
  <div className="w-full rounded-xl border border-rb-gray-100 p-7.5">{children}</div>
)

export default CourseWelcome
