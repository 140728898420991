import { CopyToClipboard } from 'react-copy-to-clipboard'

import Reactions from 'domains/Post/Reactions'
import UnreadBadge from 'domains/Post/UnreadBadge'
import UserHeading from 'domains/User/UserHeading'

import { PostReplyCommentFragment, PostShowFieldsFragment } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

interface CommentProps {
  comment: PostReplyCommentFragment
  unread: boolean
  post: PostShowFieldsFragment
}

const Comment = ({ comment, unread, post }: CommentProps) => {
  const { id: currentUserId } = useAssertCurrentUser()

  const copyPermalink = () => {
    const tooltip = document.querySelector('.uk-tooltip')
    if (tooltip) {
      tooltip.innerHTML =
        'Copied to clipboard <span uk-icon="icon:check; ratio:0.75"></span>'
    }
  }

  const getPermalinkUrl = () => {
    const id = comment.id
    if (window.location.hash) {
      return window.location.href.replace(window.location.hash, `#comment${id}`)
    } else {
      return `${window.location.href}#comment${id}`
    }
  }

  const mentionContainerId = `edit-comment-mention-container${comment.id}`

  return (
    <div id={`comment${comment.id}`}>
      <article className="uk-comment mt-5">
        <div className="uk-margin-bottom flex">
          <div className="flex-auto justify-start">
            <UserHeading user={comment.user.profile} imgWidth="48" imgHeight="48" />
          </div>

          <div className="uk-text-right uk-text-muted uk-text-nowrap flex-auto justify-end">
            <CopyToClipboard
              uk-tooltip="title: Click to copy link; cls: uk-active rf-tooltip-down-arrow"
              onCopy={copyPermalink}
              text={getPermalinkUrl()}
            >
              <span className="cursor-pointer text-rb-gray-300">
                {comment.createdAtInWords} ago
              </span>
            </CopyToClipboard>
            &nbsp;{unread && <UnreadBadge />}
          </div>
        </div>

        <div id={mentionContainerId} style={{ position: 'relative', top: '-150px' }} />

        <div className="uk-comment-body font-sans text-rb-gray-300">
          <div className="uk-child-width-1-1" uk-grid="margin: uk-margin-small">
            <p dangerouslySetInnerHTML={{ __html: comment.body || '' }} />
          </div>
        </div>
        <Reactions
          reactableType="Comment"
          reactableId={comment.id}
          reactions={comment.reactions}
          currentUserId={currentUserId}
          reactablePost={post}
        />
      </article>
    </div>
  )
}

export default Comment
