import { Link } from 'react-router-dom'

import Image from 'domains/Sanity/Image'

import Button from 'components/Button'

import { CaseStudy } from 'gql'

const CaseStudyCard = ({ previewTitle, slug, contributors }: CaseStudy) => {
  return (
    <li className="overflow-hidden rounded-xl border border-rb-gray-100 bg-rb-white">
      <Link
        to={`/case-studies/${slug}`}
        className="flex h-full flex-col text-black no-underline hover:text-black hover:no-underline"
      >
        <div className="flex w-full">
          {contributors.map((contributor) => (
            <div className="relative h-[250px] w-full" key={contributor.id}>
              <Image
                src={contributor.avatarPhoto.imageUrl || ''}
                alt={contributor.avatarPhoto.imageAlt || contributor.name}
                fill
                className="object-cover"
              />
            </div>
          ))}
        </div>

        <div className="flex flex-grow flex-col justify-between px-4 pt-8 pb-6">
          <p className="mb-4 text-base font-semibold leading-[1.4] line-clamp-2">
            {previewTitle}
          </p>

          <div>
            <div className="mb-4">
              {contributors.map((contributor, i) => (
                <p
                  className="mb-0 text-xs leading-[1.5] line-clamp-1 md:text-sm"
                  key={contributor.id}
                  data-id={contributor.id}
                >
                  {`${contributor.name}, ${contributor.position}, ${contributor.company}`}
                  {(contributors.length > 1 && i < contributors.length - 2 && ', ') ||
                    (i === contributors.length - 2 && ' and ') ||
                    (i > contributors.length - 1 && '')}
                </p>
              ))}
            </div>

            <Button size="x-small" tabIndex={-1} shape="rounded" variant="outline">
              Read Case Study
            </Button>
          </div>
        </div>
      </Link>
    </li>
  )
}
export default CaseStudyCard
