import { MouseEvent } from 'react'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { cn } from 'utils/tailwind'

import { ReactComponent as ChevronLeft } from 'images/p-chevron-left.svg'

interface BackButtonProps {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
  skipTab?: boolean
}

const BackButton = ({ onClick, skipTab }: BackButtonProps) => {
  const { showLoggedOutSearch } = useFeatureFlags()
  return (
    <li className="my-4 flex w-full justify-center lg:hidden">
      <button
        className={cn(
          'flex w-full cursor-pointer items-center bg-transparent text-xl !leading-[1.4] hover:text-rb-teal-600',
          showLoggedOutSearch && 'font-medium'
        )}
        onClick={onClick}
        tabIndex={skipTab ? -1 : 0}
      >
        <ChevronLeft height={24} className="mr-2.5 lg:hidden" />
        Back
      </button>
    </li>
  )
}

export default BackButton
