import { useEffect, useRef, useState } from 'react'
import { debounce } from 'throttle-debounce'

import { ContentPercentComplete } from 'components/cards/Content/utils'

import { CmsSectionContentType } from 'gql'

import useConsumeProgress from 'hooks/useConsumeProgress'

import { sendData } from 'utils/sendData'
import { trackTextContentProgressRecorded } from 'utils/tracking/analytics'

import { UserProgramUpdateContentProgressPayload } from 'typings/payloads'

const sendProgressUpdate = (
  cmsSectionId: number | string,
  contentPercent: number,
  onComplete: () => void
) => {
  const data: UserProgramUpdateContentProgressPayload = {
    cms_section_id: cmsSectionId,
    content_percent: contentPercent
  }
  sendData('/api/v1/user_programs/update_content_progress', 'POST', data, () => {
    // no user facing error display

    // Check if we completed the module
    onComplete?.()
  })
}

interface IContentProgressTracker {
  cmsSectionId: string
  onComplete?: () => void
  startingProgress: number
  path: string
  cmsProgramId?: string
  cmsProgramName?: string
  contentType: string
  contentSubtype?: string
  contentName: string
  viewedInModal: boolean
  location: string
}

const ContentProgressTracker = ({
  cmsSectionId,
  onComplete,
  startingProgress,
  path,
  cmsProgramId,
  cmsProgramName,
  contentType,
  contentSubtype,
  contentName,
  viewedInModal,
  location
}: IContentProgressTracker) => {
  const debouncedSendProgressUpdate = useRef(debounce(750, sendProgressUpdate)).current
  const [completed, setCompleted] = useState(false)
  const [consumeProgress, setConsumeProgress] = useState(startingProgress)

  const currentConsumeProgress = useConsumeProgress({
    id: 'content',
    scrollableId: 'page'
  })
  const [firstQuartile, setFirstQuartile] = useState(
    startingProgress >= ContentPercentComplete.FirstQuartile
  )
  const [secondQuartile, setSecondQuartile] = useState(
    startingProgress >= ContentPercentComplete.SecondQuartile
  )
  const [thirdQuartile, setThirdQuartile] = useState(
    startingProgress >= ContentPercentComplete.ThirdQuartile
  )

  const trackContentProgress = (progress: number) => {
    trackTextContentProgressRecorded({
      cms_program_id: cmsProgramId,
      cms_program_name: cmsProgramName,
      content_id: cmsSectionId,
      content_name: contentName,
      content_subtype: contentSubtype,
      content_type: contentType,
      location: location,
      path: path,
      progress: progress,
      viewed_in_content_modal: viewedInModal
    })
  }

  useEffect(() => {
    if (currentConsumeProgress > consumeProgress) {
      setConsumeProgress(currentConsumeProgress)
      // only send when complete for now
      // debouncedSendProgressUpdate(cmsSectionId, currentConsumeProgress)
    }

    // track completion progress
    if (!completed && contentType !== CmsSectionContentType.PROJECT) {
      if (
        !firstQuartile &&
        currentConsumeProgress >= ContentPercentComplete.FirstQuartile
      ) {
        debouncedSendProgressUpdate(cmsSectionId, currentConsumeProgress)
        setFirstQuartile(true)
        trackContentProgress(ContentPercentComplete.FirstQuartile)
      } else if (
        !secondQuartile &&
        currentConsumeProgress >= ContentPercentComplete.SecondQuartile
      ) {
        debouncedSendProgressUpdate(cmsSectionId, currentConsumeProgress)
        setSecondQuartile(true)
        trackContentProgress(ContentPercentComplete.SecondQuartile)
      } else if (
        !thirdQuartile &&
        currentConsumeProgress >= ContentPercentComplete.ThirdQuartile
      ) {
        debouncedSendProgressUpdate(cmsSectionId, currentConsumeProgress)
        setThirdQuartile(true)
        trackContentProgress(ContentPercentComplete.ThirdQuartile)
      } else if (currentConsumeProgress > ContentPercentComplete.NinetyPercent) {
        debouncedSendProgressUpdate(cmsSectionId, currentConsumeProgress, onComplete)
        setCompleted(true)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConsumeProgress])

  return <span />
}

export default ContentProgressTracker
