import { FacePileButton, FacePileUser } from 'components/FacePile'

import { useUserCohortUsersCountQuery, useUserCohortUsersQuery } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

interface FacePileButtonContainerProps {
  onClick: () => void
  slug: string
}

const FacePileButtonContainer = ({ onClick, slug }: FacePileButtonContainerProps) => {
  const currentUser = useAssertCurrentUser()

  const {
    loading: cohortUsersLoading,
    error: cohortUsersError,
    data: cohortUsersData
  } = useUserCohortUsersQuery({
    variables: {
      limit: 3,
      random: true,
      onlyWithAvatar: true,
      includeCurrentUser: false,
      slug: slug
    }
  })

  const {
    loading: cohortCountLoading,
    error: cohortCountError,
    data: cohortCountData
  } = useUserCohortUsersCountQuery({
    variables: { slug: slug }
  })

  if (cohortUsersLoading || cohortCountLoading) return <span>...</span>
  if (cohortUsersError || cohortCountError) {
    return <span>{cohortUsersError?.message || cohortCountError?.message}</span>
  }
  const users = (cohortUsersData?.userCohortUsers || []) as FacePileUser[]
  const count = cohortCountData?.userCohortUsersCount || 0

  return (
    <FacePileButton
      currentUser={currentUser}
      users={users}
      count={count}
      toolTip="View all live program participants"
      onClick={onClick}
      isAttending
    />
  )
}

export default FacePileButtonContainer
