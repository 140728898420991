import { closing, opening } from './sharedMarkdown'

const programName = 'Marketing Technology'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://www.reforge.com/courses/marketing-technology), because I will learn how to build resilient, powerful, and flexible martech systems for **[your company]**. This course was built with [Austin Hay](https://www.reforge.com/experts/austin-hay), and covers:
* **Set Your Stack Up for Success.** Conducting a thorough audit of your existing martech capabilities will provide you with a critical understanding of where it’s great and where there’s room for improvement.
* **Improve Your Martech Stack.** To excel at the cross-functional collaboration and strategic decision-making that working with martech requires, it’s critical to have a baseline of sound technical knowledge. Once developed, you’ll learn best practices in vendor selection and critical build vs buy decisions.
* **Utilizing Martech for Measurement and Performance Evaluation.** Learn what data to measure, how to work with messy data, how to measure complex or offline data points, and how to visualize the data to maximize its usage in decision making.
* **Utilizing Martech for Growth Objectives.** Understand three highly advanced use cases in Martech, enabling you to optimize your conversion at each step of the funnel.

&NewLine;

${closing}
`

export default { markdown, title: programName }
