import { closing, opening } from './sharedMarkdown'

const programName = 'Advanced Growth Strategy'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://www.reforge.com/advanced-growth-strategy-series), because I believe it will help me accelerate **[your company]**‘s growth by creating a system of compounding growth loops. This course was built with [Casey Winters](https://www.reforge.com/experts/casey-winters) and [Kevin Kwok](https://www.reforge.com/experts/kevin-kwok), and covers:
* **Understanding Different Types of Growth Loops.** Learn the 20+ different types of growth loops, their variations, key execution factors, and how to identify them for all different types of products through modern examples and cases.
* **Building Your Growth Model.** Answer the question, “How does our product grow?” by building a detailed qualitative growth model that pieces together your current and future growth loops.
* **Identifying Growth Constraints.** Once you have a qualitative growth model, identify the point in the model that is constraining growth. Build a quantitative growth model and use it to analyze your different growth loops, scenarios, and goals. 
* **Week 4: Improving Your Growth Loops.** Learn methods to diagnose problems early at various steps in your loop and unlock the most common constraints. Explore the step-by-step processes used by elite growth leaders to optimize the different levers of growth loops via loop expansion and loop sequencing.
* **Implementing Your Growth Strategy.** Step out of your silo and connect your growth strategy to the larger scope of the business. Learn the best tactics for setting expectations and aligning stakeholders. Gain insights from top leaders on how to build presentations and future qualitative models. 
* **Evolving Your Growth Strategy.** As your customer base and business changes, your strategy will need adjusting. But how do you know when to make the change? Learn systems and best practices for evaluating change and identifying constraints, leverage points, and ceilings to your growth.

&NewLine;

${closing}
`

export default { markdown, title: programName }
