import { MutableRefObject, useLayoutEffect, useState } from 'react'

const useIntersection = (
  element: MutableRefObject<HTMLDivElement | null>,
  rootMargin: string
) => {
  const [isVisible, setState] = useState(false)

  useLayoutEffect(() => {
    const currEl = element.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting)
      },
      { rootMargin }
    )

    if (currEl) {
      observer.observe(currEl)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      if (currEl) {
        observer.unobserve(currEl)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isVisible
}

export default useIntersection
