import { ProgramBookmarkPartsFragment } from 'gql'

import { ReactComponent as PlayIcon } from 'images/icon--play.svg'

export interface VideoTimeElementProps {
  bookmark: ProgramBookmarkPartsFragment
  type: string
  onSelectBookmark?: () => void
}

/** Displays time on video bookmark */
const VideoTimeElement = ({
  bookmark,
  type,
  onSelectBookmark
}: VideoTimeElementProps) => {
  const padInteger = (num: number, padLength: number) => {
    const numStr = `${num}`
    const numLen = numStr.length
    const numPadNeeded = padLength - numStr.length + 1
    return numLen >= padLength ? num : new Array(numPadNeeded).join('0') + num
  }

  const getBookmarkNoteTime = (seconds?: number | null) => {
    return typeof seconds === 'number'
      ? `${padInteger(Math.floor(seconds / 60), 1)}:${padInteger(seconds % 60, 2)}`
      : null
  }

  const bookmarkTimeElement = (
    <div
      data-testid="video-time-element"
      className="inline-block h-8 w-16 rounded-lg bg-rb-gray-400 pr-1.5 hover:bg-rb-teal-200"
    >
      <div className="flex hover:cursor-pointer" onClick={onSelectBookmark}>
        <span className="w-12 py-1.5 pl-2 text-left text-sm font-light text-rb-white">
          {getBookmarkNoteTime(bookmark.seconds)}
        </span>
        <PlayIcon width={12} height={12} className="text-rb-white" />
      </div>
    </div>
  )

  return (
    <>
      {type === 'Video' || (type === 'Dashboard' && bookmark.type === 'VideoBookmark')
        ? bookmarkTimeElement
        : null}
    </>
  )
}

export default VideoTimeElement
