import { useHistory } from 'react-router-dom'

import Button from 'components/Button'
import CountdownBar, { useCountdownSeconds } from 'components/CountdownBar'
import { PlayIcon, PlayIconBlack } from 'components/icons'

import useMediaQuery from 'hooks/useMediaQuery'

export interface NextLessonData {
  estimatedTime: string
  image: string
  lessonCount: number
  name: string
  path: string
}

interface NextLessonOverlayProps {
  contentType: string
  nextLessonData: NextLessonData
  onClose: () => void
}

const MAX_LESSON_NAME_CHARACTERS = 25
const AUTOPLAY_IN_SECONDS = 15

const NextLessonOverlay = ({
  contentType,
  nextLessonData,
  onClose
}: NextLessonOverlayProps) => {
  const { estimatedTime, image, lessonCount, name, path: nextLessonPath } = nextLessonData

  const isDesktop = useMediaQuery('(min-width: 400px)')

  const truncatedLessonName =
    name.length > MAX_LESSON_NAME_CHARACTERS
      ? `${name.substring(0, MAX_LESSON_NAME_CHARACTERS)}...`
      : name

  return (
    <div className="pointer-events-auto mt-14 flex w-[30%] min-w-[200px] flex-col items-center justify-center sm:mt-0">
      <span className="mb-1 hidden w-full font-sans text-base font-semibold text-white sm:block">
        Up Next
      </span>
      <div
        className="relative w-full bg-cover sm:h-[150px]"
        // Uses style prop because tailwind can't handle dynamic classnames 🤷‍♂️
        style={{ backgroundImage: isDesktop ? `url(${image}` : '' }}
      >
        <div className="h-full w-full bg-transparent sm:bg-white/70">
          <div className="absolute bottom-3 left-0 w-[175px] sm:left-3">
            <span className="mb-1 block whitespace-nowrap font-sans font-medium uppercase  leading-[14px] tracking-widest  text-rb-gray-300 sm:mb-0 sm:text-[12px] sm:text-lg sm:text-rb-gray-300">
              <span className="sm:hidden">Up Next: </span>Lesson {lessonCount}
            </span>
            <span className="block max-w-[50%] font-sans text-base font-semibold text-white sm:max-w-[75%] sm:text-black">
              {truncatedLessonName}
            </span>
          </div>
        </div>
        <div className="absolute right-0 bottom-3 sm:right-3">
          <div className="justify-middle flex h-6 items-center gap-1 p-1">
            <>
              <span className="hidden sm:inline">
                <PlayIconBlack />
              </span>
              <span className="sm:hidden">
                <PlayIcon />
              </span>
              <span className="font-sans text-base font-normal text-white sm:text-black">
                {estimatedTime} min
              </span>
            </>
          </div>
        </div>
      </div>

      <NextLessonOverlayActions
        contentType={contentType}
        nextLessonPath={nextLessonPath}
        onClose={onClose}
      />
    </div>
  )
}

const NextLessonOverlayActions = ({
  contentType,
  nextLessonPath,
  onClose
}: {
  contentType: string
  nextLessonPath: string
  onClose: () => void
}) => {
  const history = useHistory()

  const handleGoToNextSection = () => {
    history.push(nextLessonPath)
  }

  if (['Unit', 'Resource'].includes(contentType)) {
    return (
      <Button
        className="w-full bg-rb-gray-500 font-sans text-base font-semibold tracking-widest"
        onClick={handleGoToNextSection}
        size="small"
      >
        Next section
      </Button>
    )
  }

  return <AutoPlayNextSectionActions nextLessonPath={nextLessonPath} onClose={onClose} />
}

const AutoPlayNextSectionActions = ({
  nextLessonPath,
  onClose
}: {
  nextLessonPath: string
  onClose: () => void
}) => {
  const history = useHistory()

  // Needs to be triggered when overlay has been open for 15 seconds.
  const autoPlayNextLesson = () => {
    window.localStorage.setItem('autoplay', '1')
    history.push(nextLessonPath)
  }

  const { countdownProgress, countdownSeconds, resetCountdownSeconds } =
    useCountdownSeconds({
      countdownSeconds: AUTOPLAY_IN_SECONDS,
      onCountdownEnd: autoPlayNextLesson
    })

  const onCancel = () => {
    resetCountdownSeconds()
    onClose()
  }

  const seconds = countdownSeconds.toString().padStart(2, '0')

  return (
    <>
      <CountdownBar percentCompleted={countdownProgress} />
      <Button
        className="w-full bg-rb-gray-500 font-sans text-base font-semibold tracking-widest"
        iconBefore={<PlayIcon />}
        onClick={autoPlayNextLesson}
        size="small"
      >
        Play in {seconds}
      </Button>
      <span
        className="mt-3 cursor-pointer font-sans text-base font-semibold uppercase tracking-widest text-white"
        onClick={onCancel}
        onKeyDown={onCancel}
        role="button"
        tabIndex={0}
      >
        Cancel
      </span>
    </>
  )
}

export default NextLessonOverlay
