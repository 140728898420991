import * as React from 'react'
import { Link } from 'react-router-dom'

import Button from 'components/Button'
import Popover from 'components/Popover/Popover'

import { Maybe, Season } from 'gql'

import { capitalizeFirstLetter } from 'utils/stringUtils'

interface IEnrollInProgramCallout {
  children: React.ReactNode
  isDisabled?: boolean
  season?: Maybe<Season>
  onEnrollClick: (cmsProgramId: number | string) => void
  onPopoverDisplayed: () => void
  cmsProgramId: number | string
  showMemberContent: boolean
  containerRef?: any
}

const EnrollInProgramCallout = ({
  children,
  isDisabled,
  season,
  cmsProgramId,
  onEnrollClick,
  onPopoverDisplayed,
  showMemberContent,
  containerRef
}: IEnrollInProgramCallout) => {
  const onEnroll = () => {
    onEnrollClick(cmsProgramId)
  }

  if (!showMemberContent || !season) return <>{children}</>

  return (
    <Popover
      scrollableContainerRef={containerRef}
      offsetBottom="bottom-[-100px] md:bottom-[-118px]"
      offsetTop="top-[-100px] md:top-[-118px]"
      cta={
        <>
          <div data-test={`enroll-program-callout-${cmsProgramId}`} className="px-2">
            <Link
              target="_blank"
              rel="noreferrer"
              to="/courses"
              className="text-sm font-medium uppercase tracking-wider text-white hover:text-rb-green-75 hover:no-underline"
              onClick={(e) => e.stopPropagation()}
            >
              Learn more
            </Link>
          </div>
          <Button
            onClick={onEnroll}
            size="small"
            shape="rounded-full"
            className="h-6 bg-white px-2 text-sm font-medium text-rb-gray-400 sm:px-4 md:px-10 tl:h-10"
          >
            ENROLL
          </Button>
        </>
      }
      isDisabled={isDisabled}
      onPopoverDisplayed={onPopoverDisplayed}
      text={`Live program enrollment for ${capitalizeFirstLetter(season.name)} ${
        season.year
      } is now open.`}
    >
      {children}
    </Popover>
  )
}

export default EnrollInProgramCallout
