import Button from 'components/Button'

const Logout = () => {
  const handleLogout = (e: any) => {
    e.preventDefault()
    window.location.href = '/logout'
  }

  return (
    <div className="rf-rb-card rf-rb-card mb-5 flex flex-col bg-white p-6">
      <h3 className="font-sans text-xl font-semibold text-black">Log Out</h3>
      <div className="flex flex-wrap items-start justify-between">
        <span className="mb-5 text-base font-normal md:mb-0">
          Once you log out, you will have to log in again.
        </span>
        <Button
          size="x-small"
          variant="outline"
          shape="rounded-full"
          color="teal"
          className="font-sans"
          onClick={handleLogout}
        >
          Log out
        </Button>
      </div>
    </div>
  )
}

export default Logout
