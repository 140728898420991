import { BookmarkHighlighter } from 'components/BookmarkHighlighter/BookmarkHighlighter'

import { ProgramBookmarkPartsFragment } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

interface ICmsSectionDescription {
  descriptionHTML?: string | null
  cmsSection?: any
  setActiveDiscoveryDrawerPanel?: (type: string) => void
  trackActionButtonClick?: (type: 'discussions' | 'bookmarks') => void
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
}

const CmsSectionDescription = ({
  descriptionHTML,
  cmsSection,
  setActiveDiscoveryDrawerPanel,
  trackActionButtonClick,
  openAddToBookmarkFolderModal
}: ICmsSectionDescription) => {
  const { currentUser } = useCurrentUser()
  const showHighlighting =
    currentUser?.is?.member || currentUser?.is?.paidMember || currentUser?.is?.planManager

  if (!descriptionHTML) {
    return null
  }

  // highlight dev to not disturb other teams that might be working in this file
  if (showHighlighting && cmsSection) {
    return (
      <BookmarkHighlighter
        originalHtml={descriptionHTML}
        cmsSection={cmsSection}
        setActiveDiscoveryDrawerPanel={setActiveDiscoveryDrawerPanel}
        trackActionButtonClick={trackActionButtonClick}
        openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
      />
    )
  }

  return (
    <div
      data-test="lesson-viewer-content-full"
      dangerouslySetInnerHTML={{ __html: descriptionHTML }}
    />
  )
}

export default CmsSectionDescription
