import Image from 'domains/Sanity/Image'
import { ComplexPortableText } from 'domains/Sanity/PortableText/ComplexPortableText/ComplexPortableText'

import { Review } from 'gql'

interface ReviewsSectionProps {
  reviews: Review[]
}

const ReviewsSection = ({ reviews }: ReviewsSectionProps) => {
  return (
    <section className="bg-rb-gray-100 px-4 md:px-[4vw] py-5 sm:py-[50px] lg:py-[75px] xl:py-[100px]">
      <div className="mx-auto max-w-[1280px] grid grid-cols-1 lg:grid-cols-3 gap-4 sm:gap-6">
        {reviews.map((review) => (
          <div
            className="flex flex-col gap-4 lg:gap-6 p-4 lg:p-6 bg-rb-white rounded-xl shadow-[0px_4px_9px_0px_rgba(15,15,15,0.10)]"
            key={review.id}
          >
            <div className="flex items-center gap-4">
              <Image
                src={review.image?.imageUrl || ''}
                alt={review.image?.imageAlt || `Image of ${review.authorName}`}
                className="w-[72px] h-[72px] rounded-full"
              />

              <div>
                <p className="mb-0 text-lg lg:text-2xl !leading-[1.35] font-semibold text-ellipsis overflow-hidden">
                  {review.authorName}
                </p>
                <p className="mb-0 text-sm lg:text-lg !leading-[1.35] font-semibold text-rb-gray-300">
                  {review.authorPosition}
                </p>
              </div>
            </div>

            <p className="mb-0 [&_*]:!leading-[1.35] [&_*]:text-lg lg:[&_*]:text-2xl">
              <ComplexPortableText content={review.complexContent} />
            </p>
          </div>
        ))}
      </div>
    </section>
  )
}
export default ReviewsSection
