import { useEffect } from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'

import { Breadcrumbs } from 'domains/Post/Breadcrumbs'

import { usePage } from 'components/PageHeader/usePage'

export const Guidelines = () => {
  const { setPageTitle } = usePage()
  const pageTitle = 'Community Guidelines'

  useEffect(() => {
    setPageTitle(
      <Breadcrumbs>
        <Link to="/posts" className="hover:no-underline">
          <h3 className="m-0 text-2xl font-semibold text-rb-gray-500 hover:text-rb-green-400">
            Discussions
          </h3>
        </Link>
        <Link to="/posts/guidelines" className="hover:no-underline">
          <h5 className="m-0 pt-1 text-inherit text-rb-gray-400 hover:text-rb-green-400">
            Community Guidelines
          </h5>
        </Link>
      </Breadcrumbs>
    )

    return () => {
      setPageTitle(null)
    }
  }, [pageTitle, setPageTitle])

  return (
    <div className="py-0 xs:py-4 md:py-8">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="py-5 tl:text-lg lg:text-xl">
        <p className="pb-6">
          We are excited to have you as part of the Reforge community. We want everyone to
          get the most value out of Discussions.
        </p>

        <p className="pb-6">
          <a href={'/posts/?sort_by=popularity'}>Discussions</a> is our asynchronous
          community platform where you can engage with conversations on a wide range of
          topics across our membership. Our expert network, including Executives in
          Residence (EIRs) and the larger community, has written hundreds of insightful
          responses over the years and getting oriented with what&apos;s already posted in
          Discussions is a great place to start.
        </p>

        <div className="pb-8">
          <h3>How To Use Discussions</h3>
          <ol>
            <li>
              Pose and respond to questions related to topics covered in Reforge material.
            </li>
            <li>
              Use the search tool to see if your question was already answered previously
              - a ton of information is saved.
            </li>
            <li>
              Be sure to tag your post with the program name that your question is
              referencing. This will ensure that the right subject matter expert will be
              notified.
            </li>
            <li>
              Upvote any unanswered posts you&apos;re interested in. Our expert network
              will prioritize replying to posts with the most upvotes and questions about
              the program material.
            </li>
          </ol>
        </div>

        <h3>What Is Not Allowed</h3>
        <ol>
          <li>
            Job Postings - Everyone in this community has probably experienced the deluge
            of recruiting emails and announcements. Let&apos;s not add to it.
          </li>
          <li>
            Product or event Promotion - Please no promoting your own
            products/services/events. This discussion board is not to be used for
            marketing purposes.
          </li>
          <li>
            Content Promotion - Please no promoting your own content unless it is highly
            relevant to a questions or discussion.
          </li>
        </ol>
      </div>
    </div>
  )
}

export const GuidelinesLink = () => (
  <p className="mp-4">
    Please review our <Link to="/posts/guidelines">Community Guidelines</Link>.
  </p>
)
