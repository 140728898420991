import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { ReactNode, useState } from 'react'

import DragHandle from 'components/DragAndDrop/DragHandle'

interface SortableCollectionItemProps {
  id: string
  children: ReactNode
  isSortable?: boolean
}

const SortableCollectionItem = ({
  id,
  children,
  isSortable = true
}: SortableCollectionItemProps) => {
  const [showHandle, setShowHandle] = useState(false)
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: id
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  if (!isSortable) {
    return <>{children}</>
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="relative"
      id={`collection-item-${id}`}
      onMouseEnter={() => setShowHandle(true)}
      onMouseLeave={() => setShowHandle(false)}
    >
      {showHandle && (
        <DragHandle
          className="absolute left-[-30px] flex h-full items-center"
          {...listeners}
          {...attributes}
        />
      )}
      {children}
    </div>
  )
}

export default SortableCollectionItem
