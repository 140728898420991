import Lottie from 'lottie-react'
import { useEffect, useState } from 'react'

import { Select } from 'components/forms/Select'

import { ARTIFACT_COMMENTS_CLASSNAME } from 'constants/artifacts'

import { ArtifactAnnotation, ArtifactComment } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { getListByMostHelpful, getListByNewest } from 'utils/artifactUtils'
import { trackSortSelected } from 'utils/tracking/analytics'

import noCommentsAnimation from 'assets/animations/no-comments.json'

import AddCommentForm from './AddCommentForm'
import AnnotationViewer from './AnnotationViewer'
import ArtifactCommentItem from './ArtifactCommentItem'
import { useCommentQueryParamPresentOnLoad } from './hooks/useCommentQueryParamPresentOnLoad'

// global logic
//   - sort by - show if logged in, "all" is set as the sideDrawerTab, and "all" list has items
//   - comment form - show if logged in

// all sideDrawerTab
//   - show all annotations and comments
//   - default sort by most helpful (reaction count)
//   - if a new comment is submitted, sort the list by most recent and set the dropdown to most recent
//   - if most helpful is selected, and there are no reactions, sort the annotations by the default order,
//     and the comments by date with annotations being first.

// notes filter
//   - show all annotations
//   - sort by default sanity order (should be the way the annotations are coming in from the api)

// comments filter
//   - show all comments
//   - if new comment is submitted, retain sort order of newest to oldest

const ArtifactSidebarLists = ({
  artifact,
  annotations,
  activeAnnotationId,
  comments,
  primaryAuthor,
  sideDrawerTab,
  setSideDrawerTab
}: {
  artifact: any
  annotations: ArtifactAnnotation[]
  activeAnnotationId: string | null
  comments: ArtifactComment[]
  primaryAuthor: any
  sideDrawerTab: string
  setSideDrawerTab: (tab: string) => void
}) => {
  const { currentUser: user } = useCurrentUser()
  const [sortBy, setSortBy] = useState('mostHelpful' as 'mostHelpful' | 'mostRecent')
  const [all, setAll] = useState<(ArtifactAnnotation | ArtifactComment)[]>([])
  const [submittedNewComment, setSubmittedNewComment] = useState(false)
  const [activeComment, setActiveComment] = useState<string | null>(null)

  const showCommentForm = !!user
  const showSortBy = user && sideDrawerTab === 'All' && all?.length
  const showEmptyCommentsBlock = user && sideDrawerTab === 'Comments' && !all?.length

  // How to set the list and sort.
  useEffect(() => {
    if (sideDrawerTab === 'All') {
      if (!user) return setAll([...annotations])

      return sortBy === 'mostHelpful'
        ? setAll(getListByMostHelpful(annotations, comments))
        : setAll(getListByNewest([...annotations, ...comments]))
    } else if (sideDrawerTab === 'Notes') {
      setAll([...annotations])
    } else if (sideDrawerTab === 'Comments') {
      setAll(getListByNewest([...comments]))
    }
  }, [sideDrawerTab, sortBy, annotations, comments, user])

  // Actions when adding a new comment.
  useEffect(() => {
    if (submittedNewComment) {
      if (sideDrawerTab === 'All') {
        setSortBy('mostRecent')
        setAll(getListByNewest([...annotations, ...comments]))
      }

      if (sideDrawerTab === 'Notes') {
        setSideDrawerTab('Comments')
      }

      setSubmittedNewComment(false)
    }
  }, [sideDrawerTab, setSideDrawerTab, submittedNewComment, annotations, comments])

  // Highlights the "active" comment when the query param is present witht the comment id
  useCommentQueryParamPresentOnLoad({
    onQueryParamPresentOnLoad: (queryParamValue) => {
      setActiveComment(queryParamValue)

      // Wait for state to update before scrolling
      setTimeout(() => {
        const commentEl = document.querySelector(
          `.${ARTIFACT_COMMENTS_CLASSNAME} [data-comment-id="${queryParamValue}"]`
        )
        commentEl?.scrollIntoView({ behavior: 'smooth' })
      }, 0)
    }
  })

  const handleSortByChange = (value: string | number) => {
    trackSortSelected({
      location: 'artifacts_right_sidebar',
      sort_by: value === 'mostHelpful' ? 'Most helpful' : 'Most recent'
    })

    setSortBy(value as 'mostHelpful' | 'mostRecent')

    return value === 'mostHelpful'
      ? setAll(getListByMostHelpful(annotations, comments))
      : setAll(getListByNewest([...annotations, ...comments]))
  }

  return (
    <div className="mb-[30px]">
      {showCommentForm && (
        <div className="mb-6">
          <AddCommentForm
            artifact={artifact}
            onSubmitSucceeded={() => setSubmittedNewComment(true)}
          />
        </div>
      )}

      {showEmptyCommentsBlock ? (
        <EmptyCommentsBlock />
      ) : (
        <>
          {showSortBy && (
            <div className="pb-5">
              <label className="text-sm font-semibold" htmlFor="allFilter">
                Sort by
              </label>
              <Select
                name="allFilter"
                onChange={handleSortByChange}
                value={sortBy}
                options={[
                  { id: 'mostRecent', option: 'Most recent' },
                  { id: 'mostHelpful', option: 'Most helpful' }
                ]}
                classNameAdditions="border border-rb-gray rounded w-full max-w-[160px]"
              />
            </div>
          )}

          {all.map((item) => (
            <div key={`${item.__typename}-${item.id}`}>
              {item.__typename === 'ArtifactAnnotation' ? (
                <AnnotationViewer
                  artifact={artifact}
                  primaryAuthor={primaryAuthor}
                  annotation={item}
                  active={item.id === activeAnnotationId}
                />
              ) : (
                <div className="pt-[15px]">
                  <ArtifactCommentItem
                    comment={item}
                    artifact={artifact}
                    isActive={activeComment === item.id}
                  />
                </div>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  )
}

const EmptyCommentsBlock = () => {
  return (
    <div className="flex flex-col items-center bg-rb-white px-[20px] pb-[39px] lg:px-[46px]">
      <div className="w-full text-center">
        <div className="mb-[12px] text-base font-semibold lg:text-lg">
          Got something to say? <br className="sm:hidden" />
          Let&apos;s hear it!
        </div>
        <div className="text-sm lg:text-base">
          Ask a question or add a comment to get the conversation started
        </div>
        <div className="mt-[17px] mb-[28px]">
          <Lottie
            animationData={noCommentsAnimation}
            style={{
              height: '229px'
            }}
            className="chromatic-ignore"
          />
        </div>
      </div>
    </div>
  )
}

export default ArtifactSidebarLists
