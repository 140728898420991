import AudioPlayer from '../../shared/AudioPlayer'
import BeigeBlock from '../../shared/BeigeBlock'
import Grid from '../../shared/Grid'
import PodcastEmbed from '../../shared/PodcastEmbed'
import Stats from '../../shared/Stats'
import VideoEmbed from '../../shared/VideoEmbed'
import {
  renderInlineImage,
  renderPhoto,
  renderTable,
  renderVideo
} from '../../shared/componentTypes/Types'
import { ComplexPortableTextBlockValueProp } from '../../types'

const renderStats = ({ value }: ComplexPortableTextBlockValueProp) => (
  <Stats statsObject={value} />
)

const renderStepNumber = ({ value }: ComplexPortableTextBlockValueProp) => {
  return (
    <span className="step-number mt-[72px] mb-[10px] flex h-[54px] w-[54px] items-center justify-center rounded-full bg-rb-black text-2xl font-medium text-white">
      {value.value}
    </span>
  )
}

const renderVideoEmbed = ({ value }: ComplexPortableTextBlockValueProp) => (
  <VideoEmbed src={value.src} title={value.title} />
)

const renderBeigeBlock = ({ value }: ComplexPortableTextBlockValueProp) => (
  <BeigeBlock value={value} />
)

const renderAudio = ({ value }: ComplexPortableTextBlockValueProp) => (
  <AudioPlayer src={value.audio.url} title={value.title} />
)

const renderPodcastEmbed = ({ value }: ComplexPortableTextBlockValueProp) => (
  <PodcastEmbed src={value.embedUrl} title={value.title} />
)

const renderGrid = ({ value }: ComplexPortableTextBlockValueProp) => <Grid data={value} />

const renderBlockquote = ({ value }: ComplexPortableTextBlockValueProp) => {
  const { quote, author } = value

  return (
    <div className="mb-4 rounded-2xl bg-white p-6">
      <blockquote className="text-base leading-6">{quote}</blockquote>
      {author ? (
        <div className="mt-2 text-xs">
          <strong>- {author}</strong>
        </div>
      ) : null}
    </div>
  )
}

const types = {
  photo: renderPhoto,
  inlineImage: renderInlineImage,
  muxVideo: renderVideo,
  blockquote: renderBlockquote,
  horizontalRule: () => <hr />,
  stats: renderStats,
  stepNumber: renderStepNumber,
  videoEmbed: renderVideoEmbed,
  beigeBlock: renderBeigeBlock,
  audio: renderAudio,
  podcastEmbed: renderPodcastEmbed,
  grid: renderGrid,
  table: renderTable
}

export default types
