interface ITopProgressBar {
  progressPercent: number
}

const TopProgressBar = ({ progressPercent }: ITopProgressBar) => (
  <div style={{ height: '5px' }} className="flex flex-row">
    <div style={{ width: `${progressPercent}%` }} className="bg-rb-green-100"></div>
    <div
      style={{ width: `${100 - progressPercent}%` }}
      className="rounded bg-white"
    ></div>
  </div>
)

export default TopProgressBar
