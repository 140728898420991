interface IProgramIcon {
  iconImageUrl: string
  height: number
  width: number
}

const ProgramIcon = ({ iconImageUrl, height, width }: IProgramIcon) => (
  <div className="flex h-10 w-10 items-center justify-center rounded-full p-1 shadow-default">
    <img alt="" height={height} width={width} src={iconImageUrl} />
  </div>
)

export default ProgramIcon
