import { SVGIcon } from 'components/Icon'

export const FallbackTeamLogo = () => {
  return (
    <span className="flex h-20 w-20 items-center justify-center rounded bg-rb-gray-100">
      <SVGIcon name="team" fill="white" width="60" height="60" />
    </span>
  )
}

export default FallbackTeamLogo
