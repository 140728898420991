import { cn } from 'utils/tailwind'

import BackButton from './BackButton'
import NavItem, { DropdownColumnType, NavItemType } from './NavItem'

interface ItemWithDropdownProps {
  item: NavItemType
  isMobileView: boolean
  mobileNavOpen: boolean
  activeDropdownUrl?: string | null
  dropdown: NavItemType | null
  setDropdown: (item: NavItemType | null) => void
  setActiveDropdownUrl: (url?: string | null) => void
}

const ItemWithDropdown = ({
  item,
  isMobileView,
  mobileNavOpen,
  activeDropdownUrl,
  dropdown,
  setDropdown,
  setActiveDropdownUrl
}: ItemWithDropdownProps) => {
  const dropdownHasColumns =
    !Array.isArray(item.dropdown) &&
    item.dropdown?.columns &&
    item.dropdown.columns.length > 0

  return (
    <NavItem
      item={item}
      key={item.url || item.title}
      linkClassName="peer"
      onClick={
        item.dropdown && isMobileView
          ? (e) => {
              e.preventDefault()
              setDropdown(item)
              setActiveDropdownUrl(item.url)
            }
          : undefined
      }
      skipTab={mobileNavOpen && !isMobileView && !!item.dropdown}
    >
      <div
        className={cn(
          'absolute bg-white top-0 bottom-0 left-0 right-0 -z-1 translate-x-[calc(100%+1rem)] md:translate-x-[calc(100%+4vw)] px-4 md:px-[4vw] h-[calc(100dvh-72px)]',
          'lg:top-[calc(100%-4px)] lg:bottom-auto lg:left-auto lg:-right-4 lg:translate-x-[9999px] lg:px-0 lg:focus-within:translate-x-0 lg:hover:translate-x-0 lg:peer-hover:translate-x-0 lg:z-0 lg:bg-transparent lg:h-auto',
          dropdownHasColumns && 'lg:left-0 lg:right-auto',
          activeDropdownUrl === item.url && 'z-1',
          dropdown?.title === item.title && 'z-2'
        )}
      >
        {item.dropdown && (
          <>
            <div className="lg:h-4" />
            <div className="bg-rb-white lg:rounded-xl w-[calc(100%+2rem)] md:w-[calc(100%+8vw)] lg:w-auto lg:min-w-[164px] lg:shadow-[0_4px_9px_0_rgba(15,15,15,0.10)]">
              <ul
                className={cn(
                  'flex h-full list-none flex-col items-end p-0 lg:px-8 lg:py-4',
                  dropdownHasColumns && 'lg:py-8'
                )}
              >
                <BackButton
                  onClick={() => {
                    setDropdown(null)
                    setTimeout(() => {
                      setActiveDropdownUrl(null) // remove the dropdown container z-index(1) after the dropdown close animation completes
                    }, 300)
                  }}
                  skipTab={!dropdown}
                />

                {Array.isArray(item.dropdown) ? (
                  item.dropdown.map((subItem, i) => (
                    <NavItem
                      item={subItem}
                      key={i}
                      skipTab={mobileNavOpen && isMobileView && !dropdown}
                      linkClassName="lg:px-0 lg:text-base"
                      containerClassName="lg:ml-0"
                    />
                  ))
                ) : dropdownHasColumns ? (
                  <div className="flex flex-col lg:flex-row w-full lg:w-auto pb-12 lg:pb-0">
                    {item.dropdown.columns.map((column, i) => {
                      const columnsCount = (item.dropdown as DropdownColumnType).columns
                        .length

                      return (
                        <div
                          className={cn(
                            'flex flex-col lg:min-w-[164px]',
                            i !== columnsCount - 1 &&
                              'border-b lg:border-b-0 lg:border-r pb-4 lg:pb-0 mb-4 lg:mb-0 lg:pr-8 lg:mr-8'
                          )}
                          key={i}
                        >
                          <span className="my-4 text-xl font-semibold leading-[1.4] lg:mt-0 lg:mb-6">
                            {column.title}
                          </span>

                          {column.items.map((subItem, j) => (
                            <NavItem
                              item={subItem}
                              key={j}
                              skipTab={mobileNavOpen && isMobileView && !dropdown}
                              linkClassName="lg:px-0 lg:text-base lg:py-2 lg:leading-[1.6]"
                              containerClassName="lg:ml-0"
                            />
                          ))}
                        </div>
                      )
                    })}
                  </div>
                ) : null}
              </ul>
            </div>
          </>
        )}
      </div>
    </NavItem>
  )
}

export default ItemWithDropdown
