import {
  renderCodeBlock,
  renderH2,
  renderH3,
  renderNormal
} from '../../shared/componentTypes/Blocks'

const block = {
  h2: renderH2,
  h3: renderH3,
  codeBlock: renderCodeBlock,
  normal: renderNormal
}

export default block
