import { ReactComponent as BookmarkCircleIcon } from 'icon--bookmark-circle.svg'

export const NoneElement = () => (
  <div className="mt-5 bg-rb-gray-50 p-7 font-bold">
    <div className="flex items-center">
      <BookmarkCircleIcon width="50" height="50" />
      <div className="pl-3">There are no highlights for the active filters.</div>
    </div>
  </div>
)
