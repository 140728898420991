import { ReactNode } from 'react'

import { cn } from 'utils/tailwind'

export const OrderRecapItemGroup = ({
  children,
  className
}: {
  children: ReactNode
  className?: string
}) => (
  <div
    className={cn('grid grid-flow-row grid-cols-[1fr_auto] gap-y-2.5 gap-x-4', className)}
  >
    {children}
  </div>
)
