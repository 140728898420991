import { FacePileImage } from 'components/ActivityFacepile/FacePile'

import { CollectionActivityPartsFragment } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import { onEnterKeyPress } from 'utils/keyboard'

import useHandleRestoreCollectionActivity from '../hooks/useHandleRestoreCollectionActivity'

interface CollectionActivityCardProps {
  activity: CollectionActivityPartsFragment
}

const CollectionActivityCard = ({ activity }: CollectionActivityCardProps) => {
  const [restoreCollectionActivity] = useHandleRestoreCollectionActivity()
  const { id: currentUserId } = useAssertCurrentUser()

  const handleRestore = () => {
    restoreCollectionActivity({
      variables: {
        input: {
          collectionActivityId: activity.id
        }
      }
    })
  }

  const handleView = () => {
    if (!activity.filedBookmark?.bookmarkId) return
    const elementToView = document.getElementById(
      `collection-item-${activity.filedBookmark?.bookmarkId}`
    )

    if (!elementToView) return

    const rect = elementToView?.getBoundingClientRect()

    document.getElementById('page')?.scrollTo({
      top: window.scrollY + rect.top - 90,
      behavior: 'smooth'
    })
  }

  return (
    <div className="flex">
      <FacePileImage
        src={activity.user.profile.avatarUrl}
        fullName={activity.user.fullName}
        imageSize="small"
        zIndex={1}
        className="ml-0 shrink-0"
      />
      <div className="ml-2 text-xs leading-[18px]">
        <div className="pb-1 line-clamp-3">
          <span className="font-semibold">{`${
            currentUserId === activity.user.id ? 'You' : activity.user.fullName
          }`}</span>{' '}
          {activity.actionText}
        </div>
        <div className="flex text-rb-gray-300">
          {activity.createdAtInWords}
          {activity.hasRestoreAction && (
            <span className="ml-2">
              •
              <span
                role="button"
                tabIndex={0}
                onKeyUp={onEnterKeyPress(handleRestore)}
                onClick={handleRestore}
                className="ml-2 cursor-pointer hover:text-rb-blue-100"
              >
                Undo
              </span>
            </span>
          )}
          {!activity.hasRestoreAction && !!activity.filedBookmark?.bookmarkId && (
            <span className="ml-2">
              •
              <span
                className="ml-2 cursor-pointer hover:text-rb-blue-100"
                onClick={handleView}
                onKeyUp={onEnterKeyPress(handleView)}
                role="button"
                tabIndex={0}
              >
                View
              </span>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default CollectionActivityCard
