const SkeletonCourseSnapshotCard = () => {
  return (
    <div className="flex w-[350px] cursor-pointer flex-col justify-center rounded-[5px] bg-rb-white p-4 shadow-[0_0_5px_rgba(0,0,0,.15)]">
      <div className="min-h-[223px] w-full max-w-[418px] animate-pulse rounded bg-rb-gray-100" />

      <div className="mt-6 h-[40px] w-full animate-pulse rounded bg-rb-gray-100" />

      <div className="mt-3 h-[25px] w-full animate-pulse rounded bg-rb-gray-100" />
      <div className="mt-1 h-[25px] w-1/2 animate-pulse rounded bg-rb-gray-100" />
    </div>
  )
}

export default SkeletonCourseSnapshotCard
