import MuxPlayer from '@mux/mux-player-react'

import { ComplexPortableTextBlockValueProp } from '../types'

export const Video = ({
  value,
  onPlay
}: ComplexPortableTextBlockValueProp & { onPlay?: () => void }) => (
  <div className="my-6" id={value?._key}>
    <MuxPlayer
      onPlay={onPlay}
      className="max-h-2xl w-full max-w-2xl"
      playbackId={value.video?.asset?.playbackId || value.video?.playbackId}
      streamType="on-demand"
    />
  </div>
)
