import { BookmarkType, ProgramBookmarkPartsFragment } from 'gql'

export const LOCATION_GLOBAL_SAVED_ITEMS = 'global_saved_items'
export const LOCATION_LOCAL_SAVED_ITEMS = 'local_saved_items'
export const LOCATION_DASHBOARD_SAVED_ITEMS = 'cohort_dashboard_saved_items'

export const useBookmarkFiltersForTracking = () => {
  const typeFilterForTracking = (selectedTypeOption: string) =>
    selectedTypeOption === 'All Types' ? null : selectedTypeOption
  const programFilterForTracking = (selectedProgramOption: string) =>
    selectedProgramOption === 'All Programs' ? null : selectedProgramOption
  const moduleFilterForTracking = (selectedModuleOption: string) =>
    selectedModuleOption === 'All Modules' ? null : selectedModuleOption
  const programsFilterIsActive = (selectedProgramName: string, cmsProgramId?: string) =>
    cmsProgramId ? false : selectedProgramName !== 'All Programs'

  return {
    typeFilterForTracking,
    programFilterForTracking,
    moduleFilterForTracking,
    programsFilterIsActive
  }
}

export const getContentInformationFromBookmark = (
  bookmark: ProgramBookmarkPartsFragment
) => {
  if (bookmark.type === BookmarkType.PROGRAMBOOKMARK) {
    return {
      name: bookmark.cmsProgram?.name,
      id: bookmark.cmsProgram?.id
    }
  } else if (
    [
      BookmarkType.CONCEPTBOOKMARK,
      BookmarkType.PROJECTBOOKMARK,
      BookmarkType.LESSONBOOKMARK,
      BookmarkType.IMAGEBOOKMARK,
      BookmarkType.VIDEOBOOKMARK,
      BookmarkType.TEXTBOOKMARK,
      BookmarkType.SECTIONBOOKMARK
    ].indexOf(bookmark.type) > -1
  ) {
    return {
      name: bookmark.cmsSection?.name,
      id: bookmark.cmsSection?.id
    }
  } else if (bookmark.type === BookmarkType.EXTERNALURLBOOKMARK) {
    return {
      name: bookmark.externalUrl,
      id: null
    }
  } else if (bookmark.type === BookmarkType.TEXTBLOCKBOOKMARK) {
    return {
      name: bookmark.noteBody,
      id: null
    }
  }
}

export const removeHtmlTags = (htmlText: string) => {
  return htmlText.replace(/<\/?[^>]+(>|$)/gi, '')
}
