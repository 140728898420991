import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { Loading } from 'components'

import { Page, useTeamsMarketingPageQuery } from 'gql'

import TeamsMarketingPageContent from './TeamsMarketingPageContent'

const TeamsMarketingPage = () => {
  const { data, loading } = useTeamsMarketingPageQuery()

  return (
    <MarketingLayout page={(data?.teamsMarketingPage || {}) as Page}>
      {loading ? <Loading /> : <TeamsMarketingPageContent />}
    </MarketingLayout>
  )
}

export default TeamsMarketingPage
