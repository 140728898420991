export const PAGINATION_LIMIT = 24

export const ARTIFACTS_INDEX_MODES = {
  INDEX: 'INDEX',
  INDEX_WITH_TOPIC: 'INDEX_WITH_TOPIC',
  INDEX_WITH_SUBTOPIC: 'INDEX_WITH_SUBTOPIC',
  INDEX_WITH_FUNCTION: 'INDEX_WITH_FUNCTION',
  FOR_YOU: 'FOR_YOU',
  RECENTLY_VIEWED: 'RECENTLY_VIEWED'
} as const
