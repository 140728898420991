import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { getQueryParamValue } from 'utils/url'

export function useQueryParamPresentOnLoad({
  queryParam,
  onQueryParamPresentOnLoad
}: {
  queryParam: string
  onQueryParamPresentOnLoad: (queryParamValue: string) => void
}) {
  const location = useLocation()

  useEffect(() => {
    const queryParamValue = getQueryParamValue(location.search, queryParam)
    if (queryParamValue) {
      onQueryParamPresentOnLoad?.(queryParamValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
