import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
  WithStore
} from 'pure-react-carousel'
import React, {
  Children,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  RefObject,
  forwardRef
} from 'react'
import { twMerge } from 'tailwind-merge'

import ChevronRightThinIcon from 'components/icons/ChevronRightThinIcon'
import RfCardTitle from 'components/typography/RfCard/RfCardTitle'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

interface CarouselSectionCarouselState {
  currentSlide: number
  totalSlides: number
  visibleSlides: number
}

export interface CarouselSectionProps extends CarouselProps {
  slideWidth: number
  visibleSlides: number
}

export interface CarouselProps {
  title?: ReactNode
  subtitle?: ReactNode
  ctaButton?: ReactElement<HTMLAnchorElement>
  hideArrowButtons?: Boolean
  arrowButtonsOnSides?: Boolean
  centerCtaButton?: Boolean
  innerClassName?: string // for overriding pure-react-carousel inner slide classname
}

const BUTTON_STYLINGS =
  'focus:outline-none text-rb-gray-300 disabled:text-rb-white hover:text-rb-gray-500 active:outline-none border-rb-gray-400 float-right ml-auto flex h-8 min-h-8 w-8 min-w-8 items-center justify-center rounded-full'

const Carousel = forwardRef(
  (
    {
      title,
      subtitle,
      visibleSlides,
      totalSlides,
      ctaButton,
      centerCtaButton,
      currentSlide,
      children,
      innerClassName,
      hideArrowButtons = false,
      arrowButtonsOnSides = false
    }: PropsWithChildren<CarouselProps> & CarouselSectionCarouselState,
    ref: RefObject<HTMLDivElement>
  ) => {
    const isLastSlideVisible = totalSlides - visibleSlides === currentSlide
    const isFirstSlideVisible = currentSlide === 0
    const slides = Children.toArray(children)
    const hideArrows = hideArrowButtons || totalSlides <= visibleSlides
    const showCtaAndArrows = !hideArrows && ctaButton

    return (
      <div ref={ref} className="flex flex-col">
        <div
          className={twMerge(
            'flex items-center pb-4',
            !centerCtaButton && 'justify-between',
            ctaButton && 'items-center',
            showCtaAndArrows && 'items-start pb-0'
          )}
        >
          {title && (
            <div className="flex flex-col">
              <div className={twMerge(ctaButton && hideArrows && 'pb-0')}>
                <RfCardTitle>{title}</RfCardTitle>
              </div>
              <RfParagraphSmall>{subtitle}</RfParagraphSmall>
            </div>
          )}
          <div
            className={twMerge(
              'mr-4 xs:flex',
              ctaButton && !hideArrows && 'flex-col gap-2'
            )}
          >
            {ctaButton && ctaButton}
            {!hideArrows && !arrowButtonsOnSides && (
              <div className="flex justify-end">
                <ButtonBack className={BUTTON_STYLINGS} disabled={isFirstSlideVisible}>
                  <ChevronRightThinIcon
                    className={`h-4 w-4 rotate-180 fill-current ${isFirstSlideVisible ? 'invisible' : ''}`}
                  />
                </ButtonBack>
                <ButtonNext
                  className={twMerge(BUTTON_STYLINGS, 'ml-2 ')}
                  disabled={isLastSlideVisible}
                >
                  <ChevronRightThinIcon
                    className={`h-4 w-4 fill-current ${isLastSlideVisible ? 'invisible' : ''}`}
                  />
                </ButtonNext>
              </div>
            )}
          </div>
        </div>

        {!hideArrows && arrowButtonsOnSides ? (
          <div className="flex justify-center items-center">
            <ButtonBack
              className={twMerge(BUTTON_STYLINGS, 'mr-4')}
              disabled={isFirstSlideVisible}
            >
              <ChevronRightThinIcon className="h-4 w-4 rotate-180 fill-current" />
            </ButtonBack>
            <Slider className="relative overflow-hidden">
              {slides?.map(
                (slide, index) =>
                  React.isValidElement(slide) && (
                    <Slide index={index} key={index} innerClassName={innerClassName}>
                      {React.cloneElement(slide, slide.props, slide?.props?.children)}
                    </Slide>
                  )
              )}
            </Slider>
            <ButtonNext
              className={twMerge(BUTTON_STYLINGS, 'ml-4')}
              disabled={isLastSlideVisible}
            >
              <ChevronRightThinIcon className="h-4 w-4 fill-current" />
            </ButtonNext>
          </div>
        ) : (
          <Slider className="relative overflow-hidden">
            {slides?.map(
              (slide, index) =>
                React.isValidElement(slide) && (
                  <Slide index={index} key={index} innerClassName={innerClassName}>
                    {React.cloneElement(slide, slide.props, slide?.props?.children)}
                  </Slide>
                )
            )}
          </Slider>
        )}
      </div>
    )
  }
)
Carousel.displayName = 'Carousel'

const CarouselWithStore = WithStore<CarouselProps, CarouselSectionCarouselState>(
  Carousel,
  (state) => ({
    currentSlide: state.currentSlide,
    totalSlides: state.totalSlides,
    visibleSlides: state.visibleSlides
  })
)

const CarouselSection = (props: PropsWithChildren<CarouselSectionProps>) => {
  return (
    <CarouselProvider
      naturalSlideWidth={props.slideWidth}
      naturalSlideHeight={500}
      totalSlides={Children.toArray(props.children).length}
      visibleSlides={props.visibleSlides}
      isIntrinsicHeight={true}
      className="w-full"
    >
      <CarouselWithStore {...props} />
    </CarouselProvider>
  )
}

export default CarouselSection
