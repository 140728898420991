import { twMerge } from 'tailwind-merge'

import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'

import { useSideDrawer } from 'components/SideDrawer'
import LegacyContentCard from 'components/cards/Content/LegacyContentCard'

import {
  CurrentUserPartsFragment,
  LessonSectionPartsFragment,
  useBookmarkFoldersQuery
} from 'gql'

export interface SectionPageProps {
  cmsSectionName?: string
  lessons: LessonSectionPartsFragment[]
  currentUser?: CurrentUserPartsFragment
  isEnrolled: boolean
}

/** SectionPage
 * Displays a list of lessons cards for section
 */
export const SectionPage = ({
  cmsSectionName,
  lessons,
  currentUser
}: SectionPageProps) => {
  const {
    isAddToBookmarkFolderModalOpen,
    openAddToBookmarkFolderModal,
    closeAddToBookmarkFolderModal,
    currentBookmarkForDropdown
  } = useOpenAddToBookmarkFolderModal()
  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    closeCreateBookmarkFolderModal,
    openCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()
  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }
  const { data: bookmarkFolderData } = useBookmarkFoldersQuery({
    skip: !currentUser
  })

  const { isDrawerOpen } = useSideDrawer()
  const leftDrawerId = 'lessonContentsDrawer'
  return (
    <div
      data-testid="section-page"
      className={twMerge(
        'm-auto max-w-[970px] pt-16',
        isDrawerOpen(leftDrawerId) && 'lg:px-8',
        !isDrawerOpen(leftDrawerId) && 'px-4 lg:px-0'
      )}
    >
      <div className="pb-8 text-[28px] leading-8">{cmsSectionName}</div>
      <div className="m-0 grid grid-cols-[repeat(auto-fill,minmax(252px,auto))] gap-[41px] p-0 lg:mr-9">
        {lessons.map((cmsSection) => {
          return (
            <div key={`sec${cmsSection.id}`} className="">
              <LegacyContentCard
                content={cmsSection}
                openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
              />
            </div>
          )
        })}
      </div>
      <AddBookmarkToFolderModal
        isOpen={isAddToBookmarkFolderModalOpen}
        handleClose={closeAddToBookmarkFolderModal}
        bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders}
        openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdown}
        showCollectionsOnboardingInfo={
          !bookmarkFolderData?.currentUser?.hasSeenCollectionsPrompt
        }
      />
      <CreateBookmarkFolderModal
        isModalOpen={isCreateBookmarkFolderModalOpen}
        handleClose={closeCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
        trackingTriggerAction={'bookmark'}
      />
    </div>
  )
}

export default SectionPage
