import clsx from 'clsx'

import SkeletonRfParagraph from 'components/skeletons/typography/SkeletonRfParagraph'

export default function SkeletonProgramIndexCard({
  className = ''
}: {
  className?: string
}) {
  return (
    <div
      className={clsx(
        'flex animate-pulse gap-3 rounded-md border border-rb-gray-100 bg-white px-4 py-3',
        className
      )}
    >
      <div className="flex w-full space-x-4">
        <div className="h-10 w-10 rounded-full bg-rb-gray-100" />
        <div className="flex h-full w-full flex-col justify-between">
          <div className="flex w-full flex-col gap-3">
            <div className="h-4 w-1/2 rounded-full bg-rb-gray-100" />
            <div className="h-2 w-4/5 rounded-full bg-rb-gray-100" />
            <div className="h-2 w-4/5 rounded-full bg-rb-gray-100" />
            <div className="h-2 w-1/6 rounded-full bg-rb-gray-100" />
          </div>
          <div className="flex justify-between">
            <SkeletonRfParagraph className="mb-0 w-7/12" />
            <SkeletonRfParagraph className="mb-0 w-1/12" />
          </div>
        </div>
      </div>
    </div>
  )
}
