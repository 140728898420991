import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useQueryParamPresentOnLoad } from 'domains/Artifact/hooks/useQueryParamPresentOnLoad'
import WistiaPlayer from 'domains/Cms/WistiaPlayer'
import { useTrackConversionPageViewed } from 'domains/Event/hooks/useTrackConversionPageViewed'

import { ErrorMessage, Loading } from 'components'
import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'
import { CircleCheckIcon } from 'components/icons'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import {
  Event,
  EventQuery,
  UserEventActivity,
  useCreateUserEventActivityMutation,
  useEventRsvpQuery,
  useEventVideoRecordingQuery,
  useRsvpMutation,
  useTrackServerEventMutation
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import { getCurrentTimezone } from 'utils/date'
import { isEventInSession } from 'utils/eventUtils'
import { trackCtaClicked, trackVideoPlayed } from 'utils/tracking/analytics'
import { getAnonymousId } from 'utils/tracking/segment'

type EventForEventSummary = Pick<
  EventQuery['event'],
  'id' | 'name' | 'kind' | 'endsAtUtc' | 'timezone' | 'startsAtUtc'
>

interface EventSummaryContainerProps {
  userEmail?: string
  event: EventForEventSummary
  canAccessEvent: Boolean
}

export interface EventSummaryProps {
  userEmail?: string
  event: EventForEventSummary
  canAccessEvent: Boolean
  summaryWistiaCode: Event['summaryWistiaCode']
  summaryTranscript: Event['summaryTranscript']
}

type GetRecordingButtonVariant = 'anon' | 'free' | 'paid' | 'activated'

function GetRecordingButton({ event }: { event: EventForEventSummary }) {
  const { currentUser } = useCurrentUser()
  const timezone = getCurrentTimezone(currentUser?.timezone || event.timezone)

  const {
    data: currentRsvpData,
    loading: currentRsvpLoading,
    error: currentRsvpError
  } = useEventRsvpQuery({
    variables: {
      eventId: event.id
    }
  })

  const currEventRsvpStatus = currentRsvpData?.eventRsvp?.status

  const [
    createRsvp,
    { data: createRsvpData, loading: createRsvpLoading, error: createRsvpError }
  ] = useRsvpMutation({
    variables: {
      input: {
        happening: isEventInSession(event.startsAtUtc, event.endsAtUtc),
        id: event.id,
        timezone,
        recordingOnly: true
      }
    }
  })

  const newEventRsvpStatus = createRsvpData?.rsvp?.attendee?.status

  const variant: GetRecordingButtonVariant = currentUser
    ? currEventRsvpStatus === 'Recording' ||
      currEventRsvpStatus === 'Attending' ||
      newEventRsvpStatus === 'Recording' ||
      newEventRsvpStatus === 'Attending'
      ? 'activated'
      : ('free' as const)
    : ('anon' as const)

  const trackClick = () => {
    trackCtaClicked({
      cta_location: 'event_details_page',
      cta_type: 'button',
      text: !currentUser
        ? 'create account and get recording notification'
        : 'get recording notification',
      related_identifiers: {
        event_id: event.id,
        event_title: event.name,
        event_kind: event.kind,
        is_completed_event: event.endsAtUtc < new Date().toISOString()
      }
    })
  }

  const handleMemberClick = () => {
    trackClick()
    createRsvp()
  }

  const handleAnonClick = () => {
    trackClick()
  }

  useQueryParamPresentOnLoad({
    queryParam: 'rsvpRecording',
    onQueryParamPresentOnLoad: () => {
      handleMemberClick()
    }
  })

  const textOrLoadingText = (text: string) =>
    createRsvpLoading ? 'Subscribing...' : text

  const sharedButtonProps = {
    color: 'teal' as const,
    size: 'small' as const
  }

  if (currentRsvpError || createRsvpError) {
    return <span>We apologize, an error has occured.</span>
  }

  if (currentRsvpLoading) {
    return (
      <Button {...sharedButtonProps} disabled>
        ...
      </Button>
    )
  }

  if (variant === 'activated') {
    return (
      <button
        disabled
        className="flex items-center gap-1 rounded-md border border-rb-teal-600 bg-rb-success-50 px-6 py-3 text-sm font-semibold text-rb-teal-600"
      >
        <CircleCheckIcon /> <span>We&apos;ll email you the recording</span>
      </button>
    )
  }

  if (variant === 'anon') {
    return (
      <Button
        {...sharedButtonProps}
        href={`/login?reforgeLogin=true&referer=${encodeURIComponent(
          `${window.location.pathname}?rsvpRecording=true`
        )}`}
        onClick={handleAnonClick}
      >
        {textOrLoadingText('Join for free to get the recording')}
      </Button>
    )
  }

  return (
    <Button {...sharedButtonProps} onClick={handleMemberClick}>
      {textOrLoadingText('Be notified when available')}
    </Button>
  )
}

const EventSummaryContainer = ({
  event,
  userEmail,
  canAccessEvent
}: EventSummaryContainerProps) => {
  useTrackConversionPageViewed({ event })

  const { data, error, loading } = useEventVideoRecordingQuery({
    variables: { eventId: event?.id },
    skip: !event?.id
  })

  const { currentUser } = useCurrentUser()
  const [trackServerEvent] = useTrackServerEventMutation()

  useEffect(() => {
    if (!data?.event?.summaryWistiaCode) return
    if (event.startsAtUtc > new Date().toISOString()) return

    trackServerEvent({
      variables: {
        input: {
          event: 'Content Viewed - Server',
          anonymousId: getAnonymousId(),
          properties: {
            access_policy_kind: currentUser?.accessPolicyKind,
            content_id: event?.id,
            content_type: 'event',
            content_title: event?.name, // TODO
            trial_status: currentUser?.trialStatus
          }
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading || !data) return <Loading />
  if (error) return <ErrorMessage error={error} />

  const {
    event: { summaryTranscript, summaryWistiaCode }
  } = data

  return (
    <EventSummary
      event={event}
      userEmail={userEmail}
      canAccessEvent={canAccessEvent}
      summaryTranscript={summaryTranscript}
      summaryWistiaCode={summaryWistiaCode}
    />
  )
}

export const EventSummary = ({
  summaryTranscript,
  summaryWistiaCode,
  userEmail,
  event,
  canAccessEvent
}: EventSummaryProps) => {
  const [createUserEventActivity] = useCreateUserEventActivityMutation()
  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
  const headerWrapper = document.getElementById('page-header')
  const headerHeight = headerWrapper?.offsetHeight || 0
  const history = useHistory()

  const currentUser = useCurrentUser()

  const handleTrackingPlayingActivity = () => {
    createUserEventActivity({
      variables: {
        input: {
          eventId: event.id,
          activityType: UserEventActivity.STARTEDWATCHING
        }
      }
    })

    if (!currentUser) {
      trackCtaClicked({
        cta_location: 'event_details_page',
        cta_type: 'video' as const,
        text: 'event recording for anonymous user',
        related_identifiers: {
          event_id: event.id,
          event_title: event.name,
          event_kind: event.kind,
          is_completed_event: event.endsAtUtc < new Date().toISOString()
        }
      })
    } else {
      trackVideoPlayed({
        object_type: 'event_recording',
        object_id: summaryWistiaCode,
        related_identifiers: {
          event_id: event.id,
          event_title: event.name,
          event_kind: event.kind,
          is_completed_event: event.endsAtUtc < new Date().toISOString()
        }
      })
    }
  }

  const annonRegistrationRedirect = () => {
    trackCtaClicked({
      cta_location: 'event_details_page',
      cta_type: 'button',
      text: 'join for free',
      related_identifiers: {
        event_id: event.id,
        event_title: event.name,
        event_kind: event.kind,
        is_completed_event: event.endsAtUtc < new Date().toISOString()
      }
    })
    history.push(
      `/login?reforgeLogin=true&referer=${encodeURIComponent(window.location.pathname)}`
    )
  }

  return (
    <>
      {summaryWistiaCode && canAccessEvent && (
        <div className="w-11/12 py-7">
          <h4 className="text-xl">Event Recording</h4>
          <div
            id="wistia-wrapper"
            // Add a border in mobile for a smoother transition between transcript and player
            className="wistia_responsive_wrapper border-b-[20px] border-white sm:static sm:border-0"
            style={isMobile ? { position: 'sticky', top: `${headerHeight}px` } : {}}
          >
            {!currentUser.isLoggedIn ? (
              <div
                data-testid="event_summary_replay"
                className="my-7 mx-auto flex min-h-[18rem] w-full flex-col items-center justify-center bg-rb-gray-50 p-10"
              >
                <div className="flex flex-col items-center">
                  <SVGIcon name="play-circle" width="50" height="50" fill="#A2A1A2" />
                  <br />
                  <div className="text-center leading-8 lg:text-xl">
                    View event replays with a Reforge account.
                  </div>
                  <div className="mt-6 mb-3">
                    <Button color="teal" onClick={annonRegistrationRedirect}>
                      Join for free
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <WistiaPlayer
                onVideoFirstPlay={handleTrackingPlayingActivity}
                code={summaryWistiaCode}
                userEmail={userEmail}
                objectType="Event"
                objectId={event.id}
                isMobile={isMobile}
              />
            )}
          </div>
          {summaryTranscript && (
            <div
              className="mt-7"
              dangerouslySetInnerHTML={{ __html: summaryTranscript }}
            />
          )}
        </div>
      )}

      {!summaryWistiaCode && (
        <div
          data-testid="event_summary_replay"
          className="my-7 mx-auto flex min-h-[18rem] w-full flex-col items-center justify-center bg-rb-gray-50 p-5 lg:p-10"
        >
          <div className="flex flex-col items-center">
            <SVGIcon name="play-circle" width="50" height="50" fill="#A2A1A2" />
            <br />
            <div className="text-center leading-8 lg:text-xl">
              After the event, a recording of the session will live here. <br />
              Videos will be posted within 72 hours of the event.
            </div>
            <div className="mt-6 mb-3">
              <GetRecordingButton event={event} />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default EventSummaryContainer
