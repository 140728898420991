import { Component } from 'react'

import PostReply from 'domains/Post/PostReply'

import { PostReplyFragment, PostShowFieldsFragment, PostUnreadElementFragment } from 'gql'

interface IPostRepliesListProps {
  replies: PostReplyFragment[]
  unreadElements: PostUnreadElementFragment[]
  post: PostShowFieldsFragment
}

interface IPostRepliesListState {
  scrollToAnchor: boolean
}

export default class PostRepliesList extends Component<
  IPostRepliesListProps,
  IPostRepliesListState
> {
  constructor(props: IPostRepliesListProps) {
    super(props)

    this.state = {
      scrollToAnchor: true
    }
  }

  componentDidUpdate() {
    if (this.state.scrollToAnchor) {
      const hashref = window.location.hash.replace('#', '')
      if (hashref) {
        var element = document.getElementById(hashref)
        setTimeout(function () {
          if (element) element.scrollIntoView()
        }, 300)
      }
    }
  }

  render() {
    const { replies, unreadElements } = this.props
    const sortedReplies = [...replies].sort((r1, r2) =>
      r1.createdAt > r2.createdAt ? -1 : 1
    )

    return (
      <div>
        <div className="uk-comment-list">
          {sortedReplies.map((reply) => (
            <PostReply
              post={this.props.post}
              key={`reply${reply.id}`}
              reply={reply}
              unreadElements={unreadElements}
            />
          ))}
        </div>
      </div>
    )
  }
}
