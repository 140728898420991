import { ChangeEvent, ChangeEventHandler, HTMLInputTypeAttribute, useState } from 'react'
import { debounce } from 'throttle-debounce'

import { CloseIcon } from 'components/icons'

interface DebouncedInputProps {
  className: string
  type: HTMLInputTypeAttribute
  autoComplete: string
  placeholder: string
  value: string
  debounceMs: number
  onChange: ChangeEventHandler<HTMLInputElement>
}

const DebouncedInput = ({
  className,
  type,
  placeholder,
  value,
  autoComplete,
  debounceMs,
  onChange
}: DebouncedInputProps) => {
  const debouncedOnChange = debounce(debounceMs, onChange)

  const [inputValue, setInputValue] = useState(value)
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    debouncedOnChange(e)
  }

  return (
    <>
      <input
        className={className}
        type={type}
        value={inputValue}
        placeholder={placeholder}
        onChange={handleChange}
        autoComplete={autoComplete}
      />
      {inputValue && (
        <button
          type="button"
          className="margin-0 h-10 pr-2"
          onClick={() => {
            handleChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>)
          }}
          data-testid="search-field-clear-btn"
        >
          <CloseIcon className="h-4 w-4" />
        </button>
      )}
    </>
  )
}

export default DebouncedInput
