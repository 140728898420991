import { useCallback, useEffect, useState } from 'react'

import { UserCohortFilter } from 'pages/MemberDirectoryPage/helpers'

import SelectFilter, { OptionWithCategories } from 'components/SelectFilter'

import { FilterProps } from './helpers'

interface CohortProps {
  userCohorts: UserCohortFilter[]
  userCohortSeasons: string[]
}

const Cohort = ({
  userCohorts,
  userCohortSeasons,
  filters,
  setFilters
}: FilterProps & CohortProps) => {
  const name = 'cohortId'

  const getProgramName = (cohortId: typeof filters.cohortId) => {
    const cohort = userCohorts.find(({ id }) => id === cohortId)
    return cohort ? cohort.name : ''
  }

  const [selection, setSelection] = useState(getProgramName(filters[name]))

  useEffect(() => {
    setSelection(getProgramName(filters[name]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters[name]])

  const getOptions = useCallback(
    (userCohorts: UserCohortFilter[]) => {
      const options: OptionWithCategories[] = []
      userCohortSeasons.map((season) => {
        const cohorts = userCohorts.filter((userCohort) => userCohort.season === season)
        options.push({ category: season.split('-').reverse().join(' '), items: cohorts })
      })
      return options
    },
    [userCohortSeasons]
  )

  return (
    <SelectFilter
      name={name}
      dataId={'member-directory-cohort-filter'}
      label="Course"
      selection={selection}
      filters={filters}
      setFilters={setFilters}
      options={getOptions(userCohorts)}
    />
  )
}

export default Cohort
