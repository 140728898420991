import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { PageProvider } from 'components/PageHeader/usePage'

import { PageSeo, PageTypeEnum } from 'gql'

import EventUpcoming from './EventUpcoming'

const LoggedOutEventUpcoming = () => {
  const page: GenericPageType = {
    title: 'Reforge upcoming events',
    seo: {
      metaTitle: 'Reforge upcoming events',
      type: 'website' as PageTypeEnum,
      shareTitle: 'Reforge upcoming events'
    } as PageSeo
  }
  return (
    <MarketingLayout page={page}>
      <PageProvider showHeader headerWrapperClassName="px-4 md:px-[4vw] pt-4">
        <div className="max-w-7xl pb-14">
          <EventUpcoming />
        </div>
      </PageProvider>
    </MarketingLayout>
  )
}

export default LoggedOutEventUpcoming
