import { Link } from 'react-router-dom'

import SimpleModal from 'components/SimpleModal'

import { formatMoney } from 'utils/moneyUtils'

interface RestartPlanNowModalProps {
  restartedWhen: 'now' | 'later'
  onDone: () => void
  renewalDate?: string
  amountDue?: number
}

export default function RestartPlanNowModal({
  restartedWhen,
  onDone,
  renewalDate,
  amountDue
}: RestartPlanNowModalProps) {
  return (
    <SimpleModal
      renderHeaderIcon={() => 'CheckIcon'}
      renderHeaderText={() => 'You subscription has restarted.'}
      renderBodyText={() => {
        if (restartedWhen === 'now') {
          return (
            <div>
              You can check out the details of your updated plan and make further changes
              on the <Link to="/billing">Billing Page</Link>.
            </div>
          )
        }

        if (restartedWhen === 'later' && amountDue) {
          return (
            <div>
              The changes to your plan will take effect on {renewalDate}, at which point
              you will be charged {formatMoney(amountDue / 100)} + sales tax. You can
              review and make updates to your plan from the{' '}
              <Link to="/billing">Billing Page</Link>.
            </div>
          )
        }

        return ''
      }}
      renderFooterActionButtons={() => [
        {
          size: 'small',
          className: 'w-[200px]',
          onClick: onDone,
          children: 'Done'
        }
      ]}
    />
  )
}
