import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom-v5-compat'

import PreviewModal from 'domains/Collections/PreviewModal'

import { ErrorMessage, Loading } from 'components'
import TabFilters from 'components/TabFilters'
import { Tag } from 'components/Tag'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'
import { CONTACT_SUPPORT_ERROR_MESSAGE } from 'constants/errorMessages'

import { useLearningGoalsQuery } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'
import useURLParams from 'hooks/useURLParams'

import {
  trackCollectionAction,
  trackModalDisplayed,
  trackNavigationClicked
} from 'utils/tracking/analytics'

import { ReactComponent as ReforgeLogo } from 'images/icon--reforge-logo-black-circle.svg'

import { GOALS, GOAL_FILTERS } from './reforgeCollectionsConstants'
import { filterCollections } from './reforgeCollectionsUtils'

const ReforgeCollectionsPage = () => {
  const currentUser = useAssertCurrentUser()
  const { getParam } = useURLParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const collectionId = searchParams.get('collectionId')
  const goalParam = getParam('goal')
  const [activeFilter, setActiveFilter] = useState(goalParam || GOAL_FILTERS[0].id)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalCollectionId, setModalCollectionId] = useState<string>()
  const { loading, data, error } = useLearningGoalsQuery()

  useEffect(
    function trackDirectlyAccessedCollection() {
      if (collectionId && !loading && !error && data?.reforgeCollections) {
        const collectionName = data.reforgeCollections.find(
          (collection) => collection.id === collectionId
        )?.name

        trackModalDisplayed({
          category: 'app',
          location: 'collections page',
          modal_name: 'preview_collection_modal',
          modal_group: 'collections',
          related_identifiers: {
            collection_id: collectionId,
            collection_name: collectionName
          }
        })

        setModalCollectionId(collectionId)
        setIsModalOpen(true)
      }
    },
    // only want it to happen on first load, so not adding collectionId as dependency
    // this allows us to set the collectionId in search params so that it can be copied and shared, but without retriggering this useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, error, data]
  )

  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
  if (!currentUser.can.viewCollectionsIndex) {
    return <Redirect to="/" />
  }

  if (loading) return <Loading />
  if (!data || error) return <ErrorMessage error={CONTACT_SUPPORT_ERROR_MESSAGE} />
  if (!data.reforgeCollections) return null

  const handleSetFilter = (filter: string) => {
    trackNavigationClicked({
      location: 'learning_goals_page',
      text: filter.toLowerCase(),
      type: 'hyperlink text'
    })
    setActiveFilter(filter)
  }

  const handleClick = (collectionId: string, collectionName: string, index: number) => {
    trackCollectionAction({
      action: 'collection_clicked',
      location: window.location.pathname,
      collection_id: collectionId,
      collection_name: collectionName,
      is_reforge_collection: true
    })
    trackModalDisplayed({
      category: 'app',
      location: 'collections page',
      modal_name: 'preview_collection_modal',
      modal_group: 'collections',
      related_identifiers: {
        collection_id: collectionId,
        position: index
      }
    })
    setModalCollectionId(collectionId)
    searchParams.set('collectionId', collectionId)
    setSearchParams(searchParams)
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    // TODO: Add any necessary tracking code
    searchParams.delete('collectionId')
    setSearchParams(searchParams)
    setIsModalOpen(false)
  }

  const { reforgeCollections } = data
  const forkedFolderIds = data.currentUser?.forkedFolderIds || []
  const filteredCollections = filterCollections(activeFilter, reforgeCollections, GOALS)
  return (
    <div className="px-3 py-6 sm:px-8">
      <TabFilters
        className="mb-[43px] flex"
        tabClassName="mr-[30px]"
        mobileFilterClassName="border rounded"
        filters={GOAL_FILTERS.map(({ id, name }) => ({
          id: id,
          option: <div className="text-base font-medium">{name}</div>
        }))}
        activeFilter={activeFilter}
        setFilter={handleSetFilter}
        preventDropdown={!isMobile}
      />
      {filteredCollections?.map((collection, index) => {
        return (
          <div
            key={collection.id}
            className="group mb-4 flex justify-between rounded border border-rb-gray-250 p-6 hover:border-rb-black hover:shadow-rb-hover"
            onClick={() => handleClick(collection.id, collection.name, index)}
            onKeyDown={() => handleClick(collection.id, collection.name, index)}
            role="button"
            tabIndex={0}
          >
            <div>
              <div className="pb-2 text-lg text-rb-black sm:text-xl">
                {collection.name}
              </div>
              <div className="flex items-center">
                <ReforgeLogo className="mr-[11px] w-6 sm:w-8" />
                <div className="text-sm text-rb-gray-300 sm:text-base">
                  Curated by Reforge
                </div>
              </div>
            </div>
            {forkedFolderIds.includes(collection.id) && (
              <div className="hidden sm:block">
                <Tag variant="green" text="Already Saved" />
              </div>
            )}
          </div>
        )
      })}
      <PreviewModal
        currentUser={currentUser}
        isOpen={isModalOpen}
        collectionId={modalCollectionId}
        saveDisabled={!modalCollectionId}
        handleClose={handleModalClose}
      />
    </div>
  )
}

export default ReforgeCollectionsPage
