import { Fragment } from 'react'
import { Link } from 'react-router-dom'

import UserHeading from 'domains/User/UserHeading'

import { Maybe, PostActivityFragment, UserStatus } from 'gql'

interface Person {
  activities: PostActivityFragment[]
  hasBadge?: Maybe<boolean>
  id?: string
  userCompany?: Maybe<string>
  userLocation?: Maybe<string>
  userName?: Maybe<string>
  userPublicImageUrl?: string
  userRole?: Maybe<string>
  userSlug?: Maybe<string>
  userStatus?: UserStatus
  kind?: Maybe<string>
}

interface PeopleInPostProps {
  people: Person[]
}

const PeopleInPost = (props: PeopleInPostProps) => {
  const { people } = props

  return (
    <Fragment>
      <div className="mb-2.5 flex items-center">
        <span className="text-xl font-semibold text-black">People in this thread</span>
        <span className="ml-1"> ({people.length})</span>
      </div>
      {people.map((person) => (
        <div key={person.id} className="rf-rb-card mb-5 p-5">
          <UserHeading
            user={{
              slug: person.userSlug,
              fullName: person.userName,
              avatarUrl: person.userPublicImageUrl || '',
              role: person.userRole,
              companyName: person.userCompany,
              location: person.userLocation,
              status: person.userStatus || UserStatus.PRIVATE,
              hasBadge: person.hasBadge,
              kind: person.kind
            }}
            type="small"
            imgWidth="48"
            imgHeight="48"
          />
          {person.activities.length > 0 && (
            <ul className="uk-list uk-list-striped mt-5 text-rb-gray-300">
              {person.activities.map((activity) => (
                <li
                  key={activity.id}
                  style={{ padding: '5px', background: 'transparent' }}
                >
                  <div uk-grid="" className="uk-grid-collapse">
                    <div
                      className="uk-width-3-4"
                      style={{ height: '50px', overflow: 'hidden' }}
                    >
                      {activity.action}{' '}
                      <Link
                        to={activity.trackableSlug}
                        className="text-rb-teal-200 hover:text-rb-teal-600 hover:no-underline"
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: activity.trackableTitle || ''
                          }}
                        />
                      </Link>
                    </div>
                    <div className="uk-width-1-4 uk-text-right text-xs text-rb-gray-300">
                      {activity.createdAtInWords}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </Fragment>
  )
}

export default PeopleInPost
