import UpgradeHeroCard from 'domains/Upgrade/UpgradeHeroCard'

const UpgradePage = () => {
  return (
    <div className="py-8">
      <UpgradeHeroCard />
    </div>
  )
}

export default UpgradePage
