import { useCohortConversationTrackingContext } from 'domains/CohortConversation/hooks/useCohortConversationsTrackingContext'

import { useDeleteCohortPostMutation } from 'gql'

import { trackPostAction } from 'utils/tracking/generated/events'

export interface CohortPostDeleteProps {
  postId: string
}

export const useDeleteCohortPost = () => {
  const [deleteCohortPostMutation] = useDeleteCohortPostMutation()
  const { cohort, topicId, groupId } = useCohortConversationTrackingContext()

  const deleteCohortPost = async ({ postId }: CohortPostDeleteProps) => {
    const response = await deleteCohortPostMutation({
      variables: { input: { id: postId } }
    })

    const responsePostId = response?.data?.deleteCohortPost?.id

    if (responsePostId) {
      trackPostAction({
        action: 'delete',
        group_ids: [groupId],
        topic_ids: [topicId],
        post_id: responsePostId,
        cms_program_id: cohort?.cmsProgram?.id,
        cohort_id: cohort?.id,
        season_id: cohort?.season?.id
      })
    }
  }

  return { deleteCohortPost }
}

export default useDeleteCohortPost
