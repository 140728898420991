export default function SkeletonEventRelatedResourceCard() {
  return (
    <div className="chromatic-ignore mb-8 flex w-full max-w-[600px] animate-pulse flex-col rounded-xl border border-rb-gray-100 bg-rb-white md:max-w-none md:flex-row md:gap-6 md:p-4">
      <div className="aspect-[16/9] w-full shrink-0 rounded-t-xl bg-rb-gray-100 md:aspect-none md:h-[190px] md:w-[240px] md:rounded-b-xl" />
      <div className="flex w-full flex-col p-4 md:py-2 md:px-0">
        <div className="mb-4 flex items-center">
          <div className="mr-2 h-3 w-[14px] rounded-md bg-rb-gray-100" />
          <div className="h-3 w-[178px] rounded-md bg-rb-gray-100" />
        </div>

        <div className="mb-2 h-[18px] w-full rounded-md bg-rb-gray-100 md:w-[280px]" />
        <div className="mb-2 h-3 w-full rounded-md bg-rb-gray-100" />
        <div className="mb-2 h-3 w-full rounded-md bg-rb-gray-100 md:w-3/4" />
        <div className="mt-12 h-3 w-[178px] rounded-md bg-rb-gray-100 md:mt-auto" />
      </div>
    </div>
  )
}
