import {
  ComplexPortableTextBlockChildrenProp,
  ComplexPortableTextMarkProp
} from '../../types'
import CustomLink from '../CustomLink'

export const renderEm = ({ children }: ComplexPortableTextBlockChildrenProp) => (
  <i className="italic">{children}</i>
)

export const renderUnderline = ({ children }: ComplexPortableTextBlockChildrenProp) => (
  <u>{children}</u>
)

export const renderStrong = ({ children, value }: ComplexPortableTextMarkProp) => (
  <strong className="font-semibold" id={value?._key}>
    {children}
  </strong>
)

export const renderCode = ({ children, value }: ComplexPortableTextMarkProp) => (
  <code className="whitespace-normal" id={value?._key}>
    <span className="font-mono bg-gray-100">{children}</span>
  </code>
)

export const renderLink = ({ children, value }: ComplexPortableTextMarkProp) => (
  <CustomLink link={{ ...value }}>{children}</CustomLink>
)

export const renderBlockquoteMark = ({
  children
}: ComplexPortableTextBlockChildrenProp) => <blockquote>{children}</blockquote>
