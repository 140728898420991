import { useState } from 'react'

import Button from 'components/Button'
import CohortAccessInfoCard from 'components/cards/CohortAccessInfoCard'
import { LosingCohortAccessInfoCard } from 'components/cards/LosingCohortAccessInfoCard'
import { NoRemainingCohortAccessInfoCard } from 'components/cards/NoRemainingCohortAccessInfoCard'
import Dropdown from 'components/forms/Dropdown'

import { PanelContentProps } from '.'

enum CohortAccessOptions {
  NO = 1,
  YES = 2
}

export interface CohortAccessPanelContentProps
  extends Omit<PanelContentProps, 'onActionSuccess'> {
  isLoading: boolean
  hasCohortAccess: boolean
  onSave: (cohortAccess: boolean) => void
  remainingCohortAccessCount: number
  userEnrolledInCohort: boolean
  firstName?: string | null
}

export const CohortAccessPanelContent = ({
  isLoading,
  hasCohortAccess,
  onSave,
  remainingCohortAccessCount,
  userEnrolledInCohort,
  onCancelCtaClick,
  firstName
}: CohortAccessPanelContentProps) => {
  const cohortAccessOptions = [
    { id: 2, label: 'Yes', value: true, enum: CohortAccessOptions.YES },
    { id: 1, label: 'No', value: false, enum: CohortAccessOptions.NO }
  ]
  const defaultSelectedOption = cohortAccessOptions.find(
    (opt) => opt.value === hasCohortAccess
  )!

  const [selectedOption, setSelectedOption] = useState(defaultSelectedOption)

  const selectedEnumValue = cohortAccessOptions.find(
    (opt) => opt.enum === selectedOption.enum
  )!.enum

  const selectedYesButHasNoCohortPasses =
    selectedEnumValue === CohortAccessOptions.YES && !remainingCohortAccessCount

  const disableCtaButton =
    selectedYesButHasNoCohortPasses ||
    (hasCohortAccess && selectedEnumValue === CohortAccessOptions.YES) ||
    (!hasCohortAccess && selectedEnumValue === CohortAccessOptions.NO)

  return (
    <>
      <Dropdown
        dataTest="cohort-access-dropdown"
        label="Live Course Pass"
        containerClassName="mt-12 mb-6"
        selection={selectedOption.id}
        options={cohortAccessOptions}
        setSelection={(id) => {
          const selection = cohortAccessOptions.find((opt) => opt.id === id)!
          setSelectedOption(selection)
        }}
      />
      {selectedEnumValue === CohortAccessOptions.YES && !remainingCohortAccessCount && (
        <NoRemainingCohortAccessInfoCard firstName={firstName} />
      )}
      {selectedEnumValue === CohortAccessOptions.YES && !hasCohortAccess && (
        <CohortAccessInfoCard />
      )}
      {selectedEnumValue === CohortAccessOptions.NO && hasCohortAccess && (
        <LosingCohortAccessInfoCard
          userEnrolledInCohort={userEnrolledInCohort}
          firstName={firstName}
        />
      )}
      <Button
        disabled={disableCtaButton}
        className="mb-4 mt-6 w-full"
        onClick={() => onSave(selectedOption.value)}
        isLoadingSpinner={isLoading}
      >
        Save
      </Button>
      <Button className="w-full" variant="outline" onClick={onCancelCtaClick}>
        Cancel
      </Button>
    </>
  )
}
