import produce from 'immer'
import { Dispatch, SetStateAction, useRef, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import LinkModal from 'domains/Collections/LinkModal'

import SVGIcon from 'components/Icon'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import {
  BookmarkFolderPartsFragment,
  BookmarkType,
  BookmarksFromFolderDocument,
  BookmarksFromFolderQuery,
  BookmarksFromFolderQueryVariables,
  CollectionActivityDocument,
  CollectionActivityQuery,
  useCreateBookmarkMutation
} from 'gql'

import useOnClickOutside from 'hooks/useOnClickOutside'

import { onEnterKeyPress } from 'utils/keyboard'
import { trackCollectionAction } from 'utils/tracking/analytics'

import CollectionTextBlockInput from './CollectionTextBlockInput'

interface CollectionTextBlockInsertProps {
  currentFolder: BookmarkFolderPartsFragment
  position: number
  handleTextBlockTracking?: () => void
}

interface SelectInsertTypeFormProps {
  setInsertType: Dispatch<SetStateAction<string>>
  setFormOpen: Dispatch<SetStateAction<boolean>>
}

const SelectInsertTypeForm = ({
  setInsertType,
  setFormOpen
}: SelectInsertTypeFormProps) => {
  return (
    <div
      className="relative z-100 w-[150px] bg-white outline outline-1 outline-rb-gray-100"
      onBlur={() => {
        setTimeout(() => setFormOpen(false), 300)
      }}
    >
      <button
        onClick={() => setInsertType('text')}
        className="w-full hover:bg-rb-gray-50"
      >
        <div className="flex p-2">
          <SVGIcon name={'pencil'} />
          <div className="pl-2">Text block</div>
        </div>
      </button>

      <button
        onClick={() => setInsertType('link')}
        className="w-full hover:bg-rb-gray-50"
      >
        <div className="flex p-2">
          <SVGIcon name={'link'} />
          <div className="pl-2">External Link</div>
        </div>
      </button>
    </div>
  )
}

const CollectionTextBlockInsert = ({
  currentFolder,
  position,
  handleTextBlockTracking
}: CollectionTextBlockInsertProps) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const [insertType, setInsertType] = useState('')
  const [showButtons, setShowButtons] = useState(false)
  const [formOpen, setFormOpen] = useState(false)
  const [createBookmark] = useCreateBookmarkMutation({
    update(cache, { data }) {
      const existingData = cache.readQuery<
        BookmarksFromFolderQuery,
        BookmarksFromFolderQueryVariables
      >({ query: BookmarksFromFolderDocument, variables: { folderId: currentFolder.id } })
      const newBookmark = data?.createBookmark?.bookmark

      if (existingData && newBookmark) {
        const newData = produce(existingData, (draft) => {
          draft?.bookmarksFromFolder.splice(position, 0, newBookmark)
        })

        cache.writeQuery<BookmarksFromFolderQuery, BookmarksFromFolderQueryVariables>({
          query: BookmarksFromFolderDocument,
          variables: { folderId: currentFolder.id },
          data: newData
        })
      }

      const existingCollectionActivities = cache.readQuery<CollectionActivityQuery>({
        query: CollectionActivityDocument,
        variables: {
          collectionId: currentFolder.id,
          page: 1
        }
      })
      const newCollectionActivity = data?.createBookmark?.collectionActivity

      if (existingCollectionActivities && newCollectionActivity) {
        const newCollectionActivityData = produce(
          existingCollectionActivities,
          (draft) => {
            draft?.collectionActivity?.collectionActivities?.unshift(
              newCollectionActivity
            )
          }
        )

        cache.writeQuery<CollectionActivityQuery>({
          query: CollectionActivityDocument,
          variables: {
            collectionId: currentFolder.id,
            page: 1
          },
          data: newCollectionActivityData
        })
      }
    }
  })

  const openForm = () => {
    setFormOpen(true)
    setShowButtons(true)
    // Timeout needed because onClick focuses the cursor on the div clicked after openForm is called
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus()
      }
    })
  }

  const submitTextBlock = (inputText: string) => {
    if (inputText === '') return

    createBookmark({
      variables: {
        input: {
          bookmarkFolderId: currentFolder.id,
          position: position,
          type: BookmarkType.TEXTBLOCKBOOKMARK,
          noteBody: inputText
        }
      }
    })

    setInsertType('')
    handleTextBlockTracking?.()
    setFormOpen(false)
  }

  const submitExternalLinkBlock = (title: string, externalUrl: string) => {
    if (title === '' || externalUrl === '') return

    const httpUrl =
      externalUrl?.indexOf('http') === 0 ? externalUrl : `https://${externalUrl}`

    createBookmark({
      variables: {
        input: {
          bookmarkFolderId: currentFolder.id,
          position: position,
          type: BookmarkType.EXTERNALURLBOOKMARK,
          title: title,
          externalUrl: httpUrl
        }
      }
    })

    trackCollectionAction({
      action: 'add_link_to_collection',
      collection_id: currentFolder.id,
      collection_name: currentFolder.name,
      is_reforge_collection:
        currentFolder.reforgeCollection || !!currentFolder?.forkedFromId,
      is_shared_collection: currentFolder?.sharedFolders[0]?.status === 'shared',
      location: window.location.pathname
    })

    setInsertType('')
    setFormOpen(false)
  }

  const closeForms = () => {
    setFormOpen(false)
    setShowButtons(false)
    setInsertType('')
  }

  const ref = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(ref, closeForms, { enabled: formOpen || !!insertType })

  return (
    <>
      <div ref={ref}>
        <div
          className="relative -my-1.5 flex min-h-[4px] w-full cursor-pointer items-center"
          onMouseEnter={() => setShowButtons(true)}
          onMouseLeave={() => setShowButtons(false)}
          onClick={openForm}
          onKeyUp={onEnterKeyPress(openForm)}
          role="textbox"
          tabIndex={0}
        >
          <div
            className={`${
              showButtons && !insertType ? '' : 'invisible'
            } flex w-full space-x-2`}
          >
            <div className="top-[-9.5px]">
              <div
                className="justify-center rounded-sm hover:bg-rb-gray-50"
                data-for="external-content-tooltip"
                data-tip
              >
                <SVGIcon name={'plus-hoverable'} className="p-[0.5px]" />
              </div>
            </div>
            <div className="mb-2 w-full border-b-[1px] border-rb-gray-100" />
          </div>
          {formOpen && (
            <div className="absolute top-[30px] left-[-30px]">
              <SelectInsertTypeForm
                setFormOpen={setFormOpen}
                setInsertType={setInsertType}
              />
            </div>
          )}
        </div>
        <ReactTooltip
          place="top"
          id="external-content-tooltip"
          effect="solid"
          className="relative !z-[1011]"
        >
          <RfParagraphMini color={ColorOptions.white}>Add text or link</RfParagraphMini>
        </ReactTooltip>
        <div className={'flex items-center'}>
          {insertType === 'text' && (
            <CollectionTextBlockInput
              onClose={() => {}}
              onSubmit={submitTextBlock}
              onDelete={() => setInsertType('')}
            />
          )}
          {insertType === 'link' && (
            <LinkModal
              isModalOpen={insertType === 'link'}
              handleSubmit={submitExternalLinkBlock}
              handleClose={() => setInsertType('')}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default CollectionTextBlockInsert
