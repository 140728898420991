import { ReactComponent as DoubleChevronRightIcon } from 'icon--double-chevron-right.svg'
import React, { useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

import { DRAWER_PADDING } from 'constants/artifacts'

import { artifactState } from 'stores'

export interface SideDrawerProps {
  className?: string
  children: React.ReactNode
  onCloseClick: () => void
  open: boolean
}

const SideDrawer = ({
  className,
  children,
  onCloseClick = () => {},
  open = false
}: SideDrawerProps) => {
  useEffect(() => {
    const handleScroll = () => {
      if (open && window.innerWidth < 960) {
        const toggleButton = document.getElementById('side-drawer-toggle')

        if (!toggleButton) return

        el && el.scrollTop > toggleButton?.offsetTop
          ? (artifactState.scrollingMenuActive = true)
          : (artifactState.scrollingMenuActive = false)
      }
    }

    const el = document.getElementById('side-drawer')

    el?.addEventListener('scroll', handleScroll)

    return () => {
      el?.removeEventListener('scroll', handleScroll)
    }
  }, [open])

  return (
    <div
      id="side-drawer"
      className={twMerge(
        className,
        'pointer-events-auto h-[calc(100vh-40px)] touch-pan-y overflow-y-auto border-l border-rb-gray-100 pb-[280px] lg:border-none'
      )}
    >
      <div
        id="side-drawer-toggle"
        className={twMerge(
          'mb-[36px] border-t border-rb-gray-100 pt-[30px] lg:border-none lg:pt-[5px]',
          DRAWER_PADDING
        )}
      >
        <button
          className="cursor-pointer rounded bg-inherit p-2 hover:bg-rb-gray-100 active:bg-rb-gray-100 lg:hidden"
          onClick={onCloseClick}
        >
          <DoubleChevronRightIcon width={16} height={16} />
        </button>
      </div>
      <>{children}</>
    </div>
  )
}

export default SideDrawer
