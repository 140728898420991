import clsx from 'clsx'

import { useLeftSidebar } from 'components/LeftSidebar/useLeftSidebar'

import { ReactComponent as HamburgerIcon } from 'images/icon--p-hamburger.svg'

export function LeftSidebarToggle({ className }: { className?: string }) {
  const { setIsLeftSidebarOpen } = useLeftSidebar()
  return (
    <button
      className={clsx(
        'hamburger -ml-2 h-10 w-10 outline-none focus:outline-none lg:hidden',
        className
      )}
      type="button"
      onClick={() => {
        setIsLeftSidebarOpen((isLeftSidebarOpen) => !isLeftSidebarOpen)
      }}
      title="Navigation Menu"
    >
      <HamburgerIcon className="mx-auto h-6 w-6" />
    </button>
  )
}
