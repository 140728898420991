import Image from 'domains/Sanity/Image'

import SearchIcon from 'images/marketing-round-icons/MagnifyingGlass.png'

import { ComplexPortableText } from '../ComplexPortableText/ComplexPortableText'

interface BeigeBlockProps {
  value: {
    contentBlock: unknown[]
    searchIcon: boolean
  }
}

const BeigeBlock = ({ value }: BeigeBlockProps) => {
  return (
    <div className="block-content z-0 mb-[32px] bg-rb-background-beige p-[24px]">
      {value.searchIcon && (
        <div className="mb-[24px] flex items-center">
          <div className="mr-[16px]">
            <Image src={SearchIcon} alt="Search Icon" width={50} height={50} />
          </div>
        </div>
      )}

      <ComplexPortableText content={value.contentBlock} />
    </div>
  )
}

export default BeigeBlock
