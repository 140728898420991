import { closing, opening } from './sharedMarkdown'

const programName = 'Mastering Product Management'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://www.reforge.com/mastering-product-management), because I will learn how to identify strategic product work while building internal alignment behind it, how to consistently develop needle-moving roadmaps and high-leverage product specs, and how to empower my team to build a winning product. This course was built with [Sachin Rekhi](https://www.reforge.com/experts/sachin-rekhi), and covers:
* **Mapping Product Strategy and Getting Vision Buy-In.** You will learn a step-by-step process for deepening your product strategy understanding to better identify needle-moving work. We will also teach you how to create and get buy-in on a clear scope-specific vision using the Stakeholder-Forum-Style Framework.
* **Creating Feedback Systems.** You will learn a new way of approaching feedback inputs and a step-by-step process for creating a constant feedback system in which to improve products and features. You'll also learn how to create a product lever dashboard helping you identify your highest leverage metrics.
* **Building 4D Roadmaps and Effective OKR Loops.** You learn how to create impactful 4D roadmaps pulling together product strategy, vision, feedback, and lever dashboards, and how to create effective OKR loops that build your product intuition for what drives outcomes.
* **Empowering Product Specs and Key Decision Architecting.** You will learn how to write product specs that remove you as a bottleneck and unleash the creative power of your team. You will also learn how to be an effective decision architect, enabling yourself to make both faster and more aligned decisions.

&NewLine;

${closing}
`

export default { markdown, title: programName }
