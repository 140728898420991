import HighlightsCard from 'components/cards/HighlightsCard'
import { ChevronRightThinIcon } from 'components/icons'

import { ClipPartsFragment } from 'gql'

export interface HighlightsCardsProps {
  className?: string
  headerName?: string
  headerTextClassName?: string
  chevronButtonVisibilityClassName?: string
  headerDividerClassName?: string
  clips: {
    thisWeekClips?: ClipPartsFragment[]
    allClips?: ClipPartsFragment[]
  }
  onClipSelect: (clip: ClipPartsFragment) => void
  navigateToHighlightsTab?: () => void
}

const Highlights = ({
  className = '',
  clips,
  onClipSelect,
  headerName,
  chevronButtonVisibilityClassName = '',
  navigateToHighlightsTab,
  headerTextClassName = 'text-sm font-medium tracking-widest uppercase',
  headerDividerClassName = ''
}: HighlightsCardsProps) => {
  const hasClips = !!clips.allClips?.length

  if (!hasClips) return null

  const clipsToShow =
    clips.thisWeekClips?.length && clips.thisWeekClips?.length > 0
      ? clips.thisWeekClips
      : clips.allClips?.slice(0, 4)
  return (
    <>
      <div className={`${className} w-full pr-4`}>
        <div className="mt-6 flex items-center justify-between">
          <span className={headerTextClassName}>Expert Highlights</span>
          {navigateToHighlightsTab && (
            <div className={chevronButtonVisibilityClassName}>
              <button
                className="flex cursor-pointer items-center"
                onClick={navigateToHighlightsTab}
              >
                <span className="text-xs font-medium uppercase tracking-widest">
                  View All
                </span>
                <ChevronRightThinIcon className="ml-3 h-3 w-3 fill-current" />
              </button>
            </div>
          )}
        </div>
        <div className={headerDividerClassName} />
        <span className="mt-4 flex">{headerName}</span>
        <div className="flex flex-col">
          {clipsToShow?.map((clip, i) => (
            <div key={i} className="my-2 w-full">
              <HighlightsCard
                clip={clip}
                isWide
                onClick={() => {
                  onClipSelect(clip)
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Highlights
