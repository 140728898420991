import clsx from 'clsx'

import SkeletonRfParagraph from 'components/skeletons/typography/SkeletonRfParagraph'

export default function SkeletonContentCard() {
  return (
    <div className="max-h-[500px] min-h-[375px] min-w-[250px] max-w-[560px] lg:aspect-w-3 lg:aspect-h-4">
      <div className="flex h-full animate-pulse flex-col justify-between gap-4 rounded-md border border-rb-gray-100 bg-white px-2 pt-4">
        <div className="w-1/3 rounded-md bg-rb-gray-100 py-2" />
        <div className="aspect-w-16 aspect-h-9">
          <div className="h-full w-full rounded-md bg-rb-gray-100 py-2" />
        </div>
        <div className="w-full rounded-xl bg-rb-gray-100 py-4" />
        <div className="flex flex-col gap-2">
          <div className="w-full rounded-xl bg-rb-gray-100 py-[6px]" />
          <div className="w-full rounded-xl bg-rb-gray-100 py-[6px]" />
          <div className="w-full rounded-xl bg-rb-gray-100 py-[6px]" />
        </div>
        <SkeletonRfParagraph className="mt-4 w-3/12" />
      </div>
    </div>
  )
}

type SkeletonContentCardContainerProps = {
  cardCount?: number
  className?: string
}
export const SkeletonContentCardContainer = ({
  cardCount = 24,
  className
}: SkeletonContentCardContainerProps) => (
  <div
    className={clsx(
      'm-0 mr-9 grid grid-cols-[repeat(auto-fill,minmax(252px,auto))] gap-[20px] p-0',
      className
    )}
  >
    {Array.from({ length: cardCount }).map((_, i) => (
      <SkeletonContentCard key={i} />
    ))}
  </div>
)
