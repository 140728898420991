import { ReactNode } from 'react'

import AuthorByline from './AuthorByline'
import FacePileDropdown from './FacepileDropdown'
import type { AuthorBylineProps } from './artifact-types'

export const AuthorBylines = ({ bylines }: { bylines: AuthorBylineProps[] }) => {
  if (bylines.length <= 2) {
    const Wrapper = bylines.length === 1 ? EmptyWrapper : ColumnWrapper
    return (
      <Wrapper>
        {bylines.map(({ author, source }) => (
          <AuthorByline key={author.id} author={author} source={source} />
        ))}
      </Wrapper>
    )
  }

  return (
    <FacePileDropdown
      size={60}
      limit={5}
      label="author"
      showExtraCount={false}
      bylines={bylines}
    />
  )
}

const EmptyWrapper = ({ children }: { children: ReactNode }) => <>{children}</>
const ColumnWrapper = ({ children }: { children: ReactNode }) => (
  <div className="flex flex-col gap-2">{children}</div>
)

export default AuthorBylines
