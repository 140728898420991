import AdvancedGrowthStrategy from './AdvancedGrowthStrategy'
import BrandMarketing from './BrandMarketing'
import DataForProductManagers from './DataForProductManagers'
import EngineeringManagement from './EngineeringManagement'
import ExperimentationAndTesting from './ExperimentationAndTesting'
import FindingProductMarketFit from './FindingProductMarketFit'
import GeneralMembership from './GeneralMembership'
import GrowthLeadership from './GrowthLeadership'
import GrowthMarketing from './GrowthMarketing'
import GrowthSeries from './GrowthSeries'
import MarketingLeadership from './MarketingLeadership'
import MarketingStrategy from './MarketingStrategy'
import MarketingTechnology from './MarketingTechnology'
import MasteringProductManagement from './MasteringProductManagement'
import MonetizationAndPricing from './MonetizationAndPricing'
import ProductLeadership from './ProductLeadership'
import ProductLedGrowth from './ProductLedGrowth'
import ProductManagementFoundations from './ProductManagementFoundations'
import ProductMarketing from './ProductMarketing'
import ProductStrategy from './ProductStrategy'
import RetentionAndEngagement from './RetentionAndEngagement'
import ScalingProductDelivery from './ScalingProductDelivery'
import TechnicalStrategy from './TechnicalStrategy'
import UserInsightsForProductDecisions from './UserInsightsForProductDecisions'

export const templates = [
  AdvancedGrowthStrategy,
  BrandMarketing,
  DataForProductManagers,
  EngineeringManagement,
  ExperimentationAndTesting,
  FindingProductMarketFit,
  GeneralMembership,
  GrowthLeadership,
  GrowthMarketing,
  GrowthSeries,
  MarketingLeadership,
  MarketingStrategy,
  MarketingTechnology,
  MasteringProductManagement,
  MonetizationAndPricing,
  ProductLeadership,
  ProductLedGrowth,
  ProductManagementFoundations,
  ProductMarketing,
  ProductStrategy,
  RetentionAndEngagement,
  ScalingProductDelivery,
  TechnicalStrategy,
  UserInsightsForProductDecisions
]
