import { Fragment } from 'react'

interface IBasedOn {
  postId: number | string
  basedOn: string
  cmsSectionName: string
  cmsSectionLink: string
  referenceImageUrl: string
}

const BasedOn = ({
  postId,
  basedOn,
  cmsSectionName,
  cmsSectionLink,
  referenceImageUrl
}: IBasedOn) => {
  const basedOnInside = (
    <Fragment>
      <h3 className="mb-4 text-base font-medium">
        Posted in <span className="text-rb-teal-200">{cmsSectionName}</span> in response
        to:
      </h3>
      <div>
        <div className="post__based-on__copy">
          {basedOn && (
            <em
              className="text-rb-gray-500"
              dangerouslySetInnerHTML={{
                __html: `“${basedOn.replace(/&amp;/g, '&')}”`
              }}
            />
          )}
          {referenceImageUrl && (
            <img
              className="border border-rb-gray-50 bg-white p-4 sm:w-1/2"
              src={referenceImageUrl}
            />
          )}
        </div>
      </div>
    </Fragment>
  )

  return basedOn || referenceImageUrl ? (
    <div className="uk-margin-small-top uk-margin-bottom">
      {cmsSectionLink && (
        <a
          className="block border-l-[3px] border-rb-teal-200 bg-white p-5 hover:no-underline"
          href={cmsSectionLink.replace('#', `?referring-post=${postId}#`)}
          target="_blank"
          rel="noreferrer"
          data-test="post-based-on"
        >
          {basedOnInside}
        </a>
      )}

      {!cmsSectionLink && <div className="post__based-on">{basedOnInside}</div>}
    </div>
  ) : null
}

export default BasedOn
