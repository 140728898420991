import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import useMediaQuery from 'hooks/useMediaQuery'

const NoneElementImage = (
  <svg
    width="207"
    height="40"
    viewBox="0 0 207 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20.5937" cy="11.25" r="8.75" stroke="#A2A1A2" strokeWidth="3" />
    <path
      d="M38.0937 37.5C38.0937 27.835 30.0139 20 20.0469 20C10.0799 20 2 27.835 2 37.5"
      stroke="#A2A1A2"
      strokeWidth="3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.4752 28.063C105.669 28.063 110.69 23.0419 110.69 16.8482C110.69 10.6544 105.669 5.63334 99.4752 5.63334C93.2814 5.63334 88.2604 10.6544 88.2604 16.8482C88.2604 23.0419 93.2814 28.063 99.4752 28.063ZM99.4752 30.8963C107.234 30.8963 113.523 24.6067 113.523 16.8482C113.523 9.08957 107.234 2.8 99.4752 2.8C91.7166 2.8 85.4271 9.08957 85.4271 16.8482C85.4271 24.6067 91.7166 30.8963 99.4752 30.8963Z"
      fill="#A2A1A2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116.581 36.5988L107.936 27.3775L109.884 25.304L118.529 34.5253L116.581 36.5988Z"
      fill="#A2A1A2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M167.596 7.5H203.642V10.5H167.596V7.5Z"
      fill="#A2A1A2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M171.344 18.5H199.896V21.5H171.344V18.5Z"
      fill="#A2A1A2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M174.912 29.678H196.326V32.678H174.912V29.678Z"
      fill="#A2A1A2"
    />
    <path
      d="M48.5051 19.4543H75.0051M131.005 19.4543H157.505"
      stroke="#D3D2D3"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

interface NoneElementProps {
  handleResetAll: () => void
  setShowModalFilter: (show: boolean) => void
}

const NoneElement = ({ handleResetAll, setShowModalFilter }: NoneElementProps) => {
  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
  const handleReset = () => {
    handleResetAll()
    isMobile && setShowModalFilter(true)
  }

  return (
    <div className="flex flex-col items-center bg-rb-gray-50 p-8">
      {NoneElementImage}
      <p className="mb-0 mt-11 text-center text-sm xl:w-1/2 xl:text-xl">
        It looks like there aren&apos;t any members matching that criteria. Try another
        search by
        <span className="cursor-pointer text-rb-blue-100" onClick={handleReset}>
          {' '}
          resetting all filters
        </span>
        .
      </p>
    </div>
  )
}

export default NoneElement
