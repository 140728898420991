import { type SelectedPlanProps } from 'components/CheckoutModal/SelectedPlan'
import { useCheckoutModal } from 'components/CheckoutModal/useCheckoutModal'
import { useConfirmPlan } from 'components/ConfirmPlanFlow/useConfirmPlan'
import { useGlobalModal } from 'components/GlobalModal'

import RestartPlanNowModal from './RestartPlanNowModal'

export function useRestartPlanNowModal() {
  const { openGlobalModal, closeGlobalModal } = useGlobalModal()
  const { openConfirmPlanModal } = useConfirmPlan()
  const { openCheckoutModal } = useCheckoutModal()

  const handleRestartPlanNowModalClose = () => {
    closeGlobalModal()
  }

  const openRestartPlanNowModal = ({
    selectedPlan,
    numCohortPasses,
    isIndividual
  }: {
    selectedPlan: SelectedPlanProps
    numCohortPasses?: number
    isIndividual?: boolean
  }) => {
    const handleRestartOnRenewalClick = () => {
      closeGlobalModal()
      openConfirmPlanModal({
        flowContext: 'restartSubscription',
        selectedPlan,
        numCohortPasses
      })
    }

    const handleRestartNowClick = () => {
      closeGlobalModal()
      openCheckoutModal({
        flowContext: 'restartSubscription',
        planName: selectedPlan.name,
        numCohortPasses,
        isIndividual
      })
    }

    openGlobalModal(
      <RestartPlanNowModal
        planName={selectedPlan.name}
        onRestartNowClick={handleRestartNowClick}
        onRestartOnRenewalClick={handleRestartOnRenewalClick}
      />,
      {
        className: 'mx-2 max-w-[646px] rounded-lg',
        handleClose: handleRestartPlanNowModalClose
      }
    )
  }

  return {
    openRestartPlanNowModal
  }
}
