import { addDays } from 'date-fns'
import { useContext } from 'react'

import OrderRecapLabel from 'components/CheckoutModal/OrderRecap/components/OrderRecapLabel'
import OrderRecapPrice from 'components/CheckoutModal/OrderRecap/components/OrderRecapPrice'

import { ChromaticContext } from 'utils/chromatic'
import { getPrettyFullDate } from 'utils/date'

import { FREE_TRIAL_DAYS } from '../constants'

interface OrderRecapFreeTrialGroupProps {
  subTotal: number
  showDueOnDate?: boolean
}

const OrderRecapFreeTrialGroup = ({
  subTotal,
  showDueOnDate
}: OrderRecapFreeTrialGroupProps) => {
  const { isChromatic } = useContext(ChromaticContext)
  const paymentDueDate = isChromatic
    ? new Date('2024-06-10')
    : addDays(new Date(), FREE_TRIAL_DAYS)

  return (
    <>
      <OrderRecapLabel
        label={showDueOnDate ? `Due ${getPrettyFullDate(paymentDueDate)}` : 'Subtotal'}
      />
      <OrderRecapPrice price={subTotal} />

      <OrderRecapLabel
        label={
          <>
            <span className="mr-2 inline-block">Due Today</span>
            <span className="text-rb-success text-base whitespace-nowrap">{`(${FREE_TRIAL_DAYS} days free)`}</span>
          </>
        }
        className="text-base lg:text-xl font-semibold text-rb-black leading-[1.4]"
      />
      <OrderRecapPrice
        price={0}
        className="text-base lg:text-xl font-semibold text-rb-black leading-[1.4]"
      />
    </>
  )
}

export default OrderRecapFreeTrialGroup
