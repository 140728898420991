import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'

type ButtonMicrosoftProps = {
  href: string
}

const ButtonMicrosoft = ({ href }: ButtonMicrosoftProps) => (
  <Button
    className="relative h-[46px] w-full text-sm font-medium normal-case"
    size="medium"
    variant="outline"
    href={href}
    data-method="post"
    title="Continue with Microsoft"
    iconBefore={<SVGIcon name="microsoft-minimal" />}
  >
    Continue with Microsoft
  </Button>
)

export default ButtonMicrosoft
