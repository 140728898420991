import formatInTimeZone from 'date-fns-tz/formatInTimeZone'

import { CardVariants } from 'components/cards/Content/BaseCard'
import CourseCard, {
  COURSE_CARD_DESTINATION_TYPE_COURSE_DASHBOARD,
  COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD
} from 'components/cards/Content/CourseCard'
import CourseInProgressCard from 'components/cards/Content/CourseInProgressCard'
import CourseInProgressLegacyCourseCard from 'components/cards/Content/CourseInProgressLegacyCourseCard'
import LegacyCohortCard from 'components/cards/Content/LegacyCohortCard'
import LegacyCourseCard, {
  COURSE_CARD_DESTINATION_TYPE_COURSE,
  COURSE_CARD_DESTINATION_TYPE_PROGRAM
} from 'components/cards/Content/LegacyCourseCard'

import {
  MyCourseCclCoursePartsFragment,
  MyCourseUserCohortPartsFragment,
  MyCoursesUserCoursePartsFragment,
  MyCoursesUserProgramPartsFragment
} from 'gql'

import { getCurrentTimezone } from 'utils/date'
import { SortableByDatetimeType } from 'utils/userCourseDataUtils'

interface RenderCourseCardForDeprecatedUserCourseParams {
  userCourse: MyCoursesUserCoursePartsFragment
  marketplaceCoursesByCourseSanityId: Record<string, any>
  user: any
  isHomeFeedCard?: boolean
  variant?: CardVariants
  additionalRelatedIdentifiers?: {} | undefined
}
const TRACKING_LOCATION = 'premium_user_home'
const renderCourseCardForDeprecatedUserCourse = ({
  userCourse,
  marketplaceCoursesByCourseSanityId,
  user,
  isHomeFeedCard = false,
  variant = CardVariants.Horizontal,
  additionalRelatedIdentifiers
}: RenderCourseCardForDeprecatedUserCourseParams) => {
  const marketplaceCourse =
    userCourse.course.sanityId &&
    marketplaceCoursesByCourseSanityId[userCourse.course.sanityId]

  if (!marketplaceCourse) {
    return null
  }

  const startsAt = userCourse.courseSession.startsAt
  const endsAt = userCourse.courseSession.endsAt
  const isSingleDaySession = new Date(startsAt).getDate() === new Date(endsAt).getDate()
  const timezone = user?.timezone || getCurrentTimezone()

  const courseFooterOverrideText = isSingleDaySession
    ? formatInTimeZone(startsAt, timezone, 'MMMM d, yyyy')
    : `${formatInTimeZone(startsAt, timezone, 'MMM d')} - ${formatInTimeZone(
        endsAt,
        timezone,
        'MMM d, yyyy'
      )}`

  if (isHomeFeedCard) {
    return (
      <CourseInProgressLegacyCourseCard
        key={marketplaceCourse.id}
        userCourse={userCourse}
        course={marketplaceCourse}
        user={user}
        variant={variant}
        showHostInThumbnail={false}
        showHostInByline
        cardType="live"
        customHoverMiniCard={true}
        legacyCourseSessionId={userCourse.courseSession.id}
        destinationType={COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD}
        pageLocation={TRACKING_LOCATION}
        hideBookmarkButton
        additionalRelatedIdentifiers={{
          section: 'home_feed_recommendation',
          ...additionalRelatedIdentifiers
        }}
      />
    )
  }

  return (
    <LegacyCourseCard
      key={marketplaceCourse.id}
      course={marketplaceCourse}
      user={user}
      showHostInThumbnail={false}
      showHostInByline
      courseFooterOverrideText={courseFooterOverrideText}
      variant={variant}
      cardType="live"
      legacyCourseSessionId={userCourse.courseSession.id}
      destinationType={COURSE_CARD_DESTINATION_TYPE_COURSE}
      pageLocation="/saved"
      hideBookmarkButton
    />
  )
}

interface RenderSortableParams {
  isHomeFeedCard?: boolean
  variant?: CardVariants
  sortable: SortableByDatetimeType
  legacyCoursesByProgramSlug: Record<string, any>
  marketplaceCoursesByCourseSanityId: Record<string, any>
  user: any
  additionalRelatedIdentifiers?: {} | undefined
}

export const renderSortable = ({
  isHomeFeedCard = false,
  variant = CardVariants.Horizontal,
  sortable,
  legacyCoursesByProgramSlug,
  marketplaceCoursesByCourseSanityId,
  user,
  additionalRelatedIdentifiers
}: RenderSortableParams) => {
  if (sortable.entity.__typename === 'UserCourse') {
    const userCourse = sortable.entity as MyCoursesUserCoursePartsFragment
    // for now, all CourseCards are rendered as live (aka not on-demand)
    // Courses since we are not showing on-demand cards for marketplace
    //  (aka CclCourses) in MyCourses yet
    // for live courses in MyCourses, we show the session host information
    // and pass in the courseSession
    if (userCourse.cclCourse) {
      const course = userCourse.cclCourse
      if (isHomeFeedCard) {
        return (
          <CourseInProgressCard
            variant={variant}
            courseSession={userCourse.cclCourseSession}
            legacyCourseSessionId={userCourse.courseSession.id}
            pageLocation={TRACKING_LOCATION}
            course={course}
            key={course.id}
            customHoverMiniCard={true}
            destinationType={COURSE_CARD_DESTINATION_TYPE_COURSE_DASHBOARD}
            showSessionHostInThumbnail
            showSessionHostInByline
            hideBookmarkButton
            additionalRelatedIdentifiers={{
              section: 'home_feed_recommendation',
              ...additionalRelatedIdentifiers
            }}
          />
        )
      }
      return (
        <CourseCard
          pageLocation="/saved"
          variant={variant}
          legacyCourseSessionId={userCourse.courseSession.id}
          destinationType={COURSE_CARD_DESTINATION_TYPE_COURSE_DASHBOARD}
          course={userCourse.cclCourse}
          showSessionHostInThumbnail
          showSessionHostInByline
          courseSession={userCourse.cclCourseSession}
          key={userCourse.cclCourse.id}
          hideBookmarkButton
        />
      )
    }

    return renderCourseCardForDeprecatedUserCourse({
      userCourse,
      marketplaceCoursesByCourseSanityId,
      user,
      isHomeFeedCard,
      variant
    })
  }

  if (sortable.entity.__typename === 'CclCourse') {
    const cclCourse = sortable.entity as MyCourseCclCoursePartsFragment

    if (isHomeFeedCard) {
      return (
        <CourseInProgressCard
          variant={variant}
          course={cclCourse}
          key={cclCourse.id}
          customHoverMiniCard={true}
          hideBookmarkButton
          additionalRelatedIdentifiers={{
            section: 'home_feed_recommendation',
            ...additionalRelatedIdentifiers
          }}
          destinationType={COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD}
          pageLocation={TRACKING_LOCATION}
        />
      )
    }

    return (
      <CourseCard
        pageLocation="/saved"
        variant={variant}
        course={cclCourse}
        key={cclCourse.id}
        hideBookmarkButton
        destinationType={COURSE_CARD_DESTINATION_TYPE_ON_DEMAND_DASHBOARD}
        CourseFooterOverride={() => <>{cclCourse.completionPercentage}% Complete</>}
      />
    )
  }

  if (sortable.entity.__typename === 'UserProgram') {
    const userProgram = sortable.entity as MyCoursesUserProgramPartsFragment

    const course = legacyCoursesByProgramSlug[userProgram.cmsProgram.slug]
    if (!course) {
      return null
    }

    if (isHomeFeedCard) {
      return (
        <CourseInProgressLegacyCourseCard
          key={course.slug}
          userCourse={undefined}
          course={course}
          user={user}
          progressPercent={userProgram.progressPercent || 0}
          variant={variant}
          cardType="on-demand"
          customHoverMiniCard={true}
          destinationType={COURSE_CARD_DESTINATION_TYPE_PROGRAM}
          pageLocation={TRACKING_LOCATION}
          hideBookmarkButton
          additionalRelatedIdentifiers={{
            section: 'home_feed_recommendation',
            ...additionalRelatedIdentifiers
          }}
        />
      )
    }

    return (
      <LegacyCourseCard
        key={course.slug}
        course={course}
        user={user}
        progressPercent={userProgram.progressPercent || 0}
        variant={variant}
        cardType="on-demand"
        destinationType={COURSE_CARD_DESTINATION_TYPE_PROGRAM}
        pageLocation="/saved"
        hideBookmarkButton
      />
    )
  }

  if (sortable.entity.__typename === 'UserCohort') {
    const userCohort = sortable.entity as MyCourseUserCohortPartsFragment

    return (
      <LegacyCohortCard
        cohort={userCohort.cohort}
        variant={variant}
        key={userCohort.id}
      />
    )
  }

  return null
}

export default renderSortable
