import { getTableOfContentsSlug, slugify } from 'utils/url'

import AnnotationHighlight from '../components/AnnotationHighlight'
import CustomLink from '../components/CustomLink'

interface BlockProps {
  value?: any // @todo: define type
  children?: any // @todo: define type
}

export default {
  blockquote: ({ children }: BlockProps) => {
    return (
      <blockquote
        style={{
          padding: '10px',
          backgroundColor: '#F5F6F5',
          fontStyle: 'italic',
          lineHeight: '1.65rem',
          color: '#080A0A'
        }}
      >
        {children}
      </blockquote>
    )
  },

  quoteAttribution: ({ children }: BlockProps) => {
    const { 0: text } = children
    return (
      <span
        style={{
          marginTop: '-15px',
          float: 'right',
          fontWeight: 'bold',
          backgroundColor: '#F5F6F5',
          width: '100%',
          textAlign: 'right',
          padding: '0 10px 10px 0',
          marginBottom: '15px',
          color: '#080A0A'
        }}
      >
        —{text}
      </span>
    )
  },

  color: ({ children, value }: BlockProps) => {
    return <span style={{ color: value.hex }}>{children}</span>
  },

  highlight: ({ children }: BlockProps) => {
    return <span style={{ backgroundColor: 'yellow' }}>{children}</span>
  },

  link: ({ children, value }: BlockProps) => {
    const { 0: title } = children

    return <CustomLink link={{ ...value, title }} />
  },

  internalLink: ({ children, value }: BlockProps) => {
    const { 0: text } = children
    if (value._hideNote === true) {
      return <span>{text}</span>
    } else {
      return <AnnotationHighlight href={value?.reference?._ref} text={text} />
    }
  },

  tableOfContents: ({ children, value }: BlockProps) => {
    const id = getTableOfContentsSlug({
      title: value.tocTitle || children[0],
      id: value._key
    })

    return (
      <span id={id} className="relative -z-1 pt-24">
        {children}
      </span>
    )
  },

  contentId: ({ children, value }: { children: any; value: any }) => {
    const id = slugify(value.idAttribute)
    return (
      <span id={id} className="relative -z-1 pt-24">
        {children}
      </span>
    )
  }
}
