import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ProgramPreviewPage from 'pages/ProgramPreviewPage/ProgramPreviewPage'

import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'

import { ErrorMessage, Loading } from 'components'

import { PageSeo, useCmsProgramPreviewQuery } from 'gql'

type Params = {
  slug: string
}

interface ProgramPreviewPageContainerProps {
  setPage?: (page: GenericPageType) => void
}

const ProgramPreviewPageContainer = ({ setPage }: ProgramPreviewPageContainerProps) => {
  const { slug } = useParams<Params>()

  const { loading, error, data } = useCmsProgramPreviewQuery({ variables: { slug } })

  useEffect(() => {
    if (setPage && data) {
      const title = `${data.cmsProgram?.name} - Reforge`
      const description = data.cmsProgram?.synopsis

      setPage({
        title,
        seo: {
          metaTitle: title,
          metaDesc: description,
          shareTitle: title,
          shareDesc: description,
          type: 'website',
          shareGraphic: {
            asset: data.cmsProgram?.iconImageUrl
          }
        } as PageSeo
      })
    }
  }, [data, setPage])

  if (loading) return <Loading />
  if (error) return <ErrorMessage error={error} />

  if (!data || !data.cmsProgram) {
    return (
      <ErrorMessage error="Sorry, there was an issue loading the program. Please refresh and try again." />
    )
  }

  const cmsProgram = data.cmsProgram

  return <ProgramPreviewPage program={cmsProgram} programSlug={slug} />
}

export default ProgramPreviewPageContainer
