import { StripeTaxId } from 'domains/User/BillingInfoForm/types'

import { BillingAddressFragment } from 'gql'

import { CheckoutFlowContext } from './utils'

type Fields = 'payment' | 'billing' | 'cohort'
type CheckoutModalState = {
  cohortPassCount: number
  orderTotal: number
  selectedPaymentMethodId?: string
  billingAddress?: BillingAddressFragment
  error?: {
    message: string
    field: Fields | null
  }
  taxId: StripeTaxId | null
  isBillingInfoFormComplete: boolean
}

export const initialState = ({
  flowContext
}: {
  flowContext?: CheckoutFlowContext
}): CheckoutModalState => ({
  cohortPassCount: flowContext === 'cohortPassPurchase' ? 1 : 0,
  orderTotal: 0,
  taxId: null,
  isBillingInfoFormComplete: false
})

export const reducer = (
  state: CheckoutModalState,
  action: Action
): CheckoutModalState => {
  switch (action.type) {
    case 'SET_SELECTED_PAYMENT_METHOD_ID': {
      return {
        ...state,
        selectedPaymentMethodId: action.payload.id
      }
    }
    case 'SET_BILLING_ADDRESS': {
      return {
        ...state,
        billingAddress: action.payload.billingAddress,
        isBillingInfoFormComplete: action.payload.isComplete
      }
    }
    case 'SET_COHORT_PASS_COUNT': {
      const newCount = action.payload.count
      return {
        ...state,
        cohortPassCount: newCount
      }
    }
    case 'SET_ERROR': {
      return {
        ...state,
        error: {
          message: action.payload.message,
          field: action.payload.field || null
        }
      }
    }
    case 'TAX_ID_LOADED':
    case 'TAX_ID_SAVED': {
      return {
        ...state,
        taxId: action.payload.taxId,
        isBillingInfoFormComplete: action.payload.isComplete
      }
    }
    case 'TAX_ID_DELETED': {
      return {
        ...state,
        taxId: null
      }
    }

    default: {
      const exhaustiveCheckAction: never = action
      console.log('Unhandled action type', exhaustiveCheckAction, state)
      return state
    }
  }
}

export const setCohortPassCount = (payload: { count: number }) => {
  return {
    type: 'SET_COHORT_PASS_COUNT' as const,
    payload
  }
}

export const setSelectedPaymentMethodId = (payload: { id: string }) => {
  return {
    type: 'SET_SELECTED_PAYMENT_METHOD_ID' as const,
    payload
  }
}

export const setBillingAddress = (payload: {
  billingAddress: BillingAddressFragment
  isComplete: boolean
}) => {
  return {
    type: 'SET_BILLING_ADDRESS' as const,
    payload
  }
}

export const setError = (message: string, options?: { field: Fields | null }) => {
  return {
    type: 'SET_ERROR' as const,
    payload: {
      message,
      field: options?.field
    }
  }
}

export const taxIdLoaded = (payload: { taxId: StripeTaxId; isComplete: boolean }) => {
  return {
    type: 'TAX_ID_LOADED' as const,
    payload
  }
}

export const taxIdSaved = (payload: { taxId: StripeTaxId; isComplete: boolean }) => {
  return {
    type: 'TAX_ID_SAVED' as const,
    payload
  }
}

export const taxIdDeleted = () => {
  return {
    type: 'TAX_ID_DELETED' as const
  }
}

type Action =
  | ReturnType<typeof setCohortPassCount>
  | ReturnType<typeof setSelectedPaymentMethodId>
  | ReturnType<typeof setBillingAddress>
  | ReturnType<typeof setError>
  | ReturnType<typeof taxIdLoaded>
  | ReturnType<typeof taxIdSaved>
  | ReturnType<typeof taxIdDeleted>
