import { closing, opening } from './sharedMarkdown'

const programName = 'Growth Series'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://www.reforge.com/programs/growth-series), because it is regarded as the most comprehensive overview of growth, spanning from acquisition to user psychology.  This course was built with [Brian Balfour](https://www.reforge.com/experts/brian-balfour) and [Andrew Chen](https://www.reforge.com/experts/andrew-chen), and covers:
* **Retention & Engagement.** Retention is the foundation of growth. We’ll define your use cases and retention metric, analyze your retention, and scope habit-building strategies.
* **Acquisition.** Once you build a firm foundation, you need a compounding growth strategy. We’ll cover the different types of Growth Loops, understand which ones work for your product, and build an acquisition strategy.
* **Monetization.** The third pillar of growth is your monetization strategy. We’ll identify how your monetization strategy is enabling or disabling your growth, how to analyze it, and identify improvements you can make. 
* **Growth Models.** We’ll bring the first few weeks together in a qualitative and quantitative growth model to answer the most important question for your team: “How does our product grow?” We’ll use that model to identify opportunities, prioritize efforts, and set goals. 
* **User Psychology.** Once you understand how your product grows, you need to understand your customers’ hidden motivations and behavioral triggers that drive their actions. We’ll identify the different emotions, motivations, and friction in a structured and analytical framework.
* **Experiments.** Growth is about finding the truth of how our product grows and what drives our user’s behaviors. Experiments are your tool to uncover that truth. You will take ideas from previous weeks and structure them into well-formed experiments to implement and execute.

&NewLine;

${closing}
`

export default { markdown, title: programName }
