import { useGlobalModal } from 'components/GlobalModal'

import { useWhatWeCanDoBetterMutation } from 'gql'

import { trackModalDismissed } from 'hooks/useModalTracking'

import { trackCtaClicked } from 'utils/tracking/analytics'

import SubscriptionCancelModalContent from './SubscriptionCancelModalContent/SubscriptionCancelModalContent'
import SubscriptionCancelSuccessModalContent from './SubscriptionCancelSuccessModalContent'
import { WhatWeCanDoBetterModalContent } from './WhatWeCanDoBetterModalContent'

export const useSubscriptionCancel = () => {
  const { openGlobalModal, closeGlobalModal } = useGlobalModal()
  const [whatWeCanDoBetterMutation] = useWhatWeCanDoBetterMutation()

  const handleSubscriptionCancelModalClose = () => {
    trackModalDismissed({
      modal_group: 'cancelation_flow',
      modal_name: 'cancel'
    })

    closeGlobalModal()
  }

  const handleCancelSubscriptionButtonClick = (
    ctaType?: 'button' | 'hyperlink text' | 'link' | 'remove tag' | 'select tag'
  ) => {
    trackCtaClicked({
      cta_location: 'subscription section',
      cta_type: ctaType || 'hyperlink text',
      text: 'cancel subscription'
    })

    openGlobalModal(
      <SubscriptionCancelModalContent
        onCancelSubscription={() => {
          showWhatWeCanDoBetterModal()
        }}
        handleClose={handleSubscriptionCancelModalClose}
      />,
      {
        className: 'mx-2 max-w-[825px]',
        handleClose: handleSubscriptionCancelModalClose
      }
    )
  }

  const handleWhatWeCanDoBetterClose = () => {
    trackModalDismissed({
      modal_group: 'cancelation_flow',
      modal_name: 'what_we_can_do_better'
    })

    closeGlobalModal()
  }

  const showWhatWeCanDoBetterModal = () => {
    openGlobalModal(
      <WhatWeCanDoBetterModalContent
        onSubmitFeedback={(whatWeCanDoBetter: string) => {
          whatWeCanDoBetterMutation({
            variables: {
              input: { whatWeCanDoBetter }
            }
          })
        }}
        showSubscriptionCancelSuccessModal={showSubscriptionCancelSuccessModal}
        handleClose={handleWhatWeCanDoBetterClose}
      />,
      {
        className: 'mx-2 max-w-[825px]',
        handleClose: handleWhatWeCanDoBetterClose
      }
    )
  }

  const showSubscriptionCancelSuccessModal = () => {
    openGlobalModal(
      <SubscriptionCancelSuccessModalContent
        handleClose={handleSubscriptionCancelSuccessModalClose}
      />,
      {
        className: 'max-w-[825px]',
        handleClose: handleSubscriptionCancelSuccessModalClose
      }
    )
  }

  const handleSubscriptionCancelSuccessModalClose = () => {
    trackModalDismissed({
      modal_group: 'cancelation_flow',
      modal_name: 'cancel success'
    })

    closeGlobalModal()

    // allow some time for tracking calls to finish before reloading, otherwise they get cancelled
    setTimeout(() => {
      window.location.reload()
    }, 300)
  }

  return {
    handleCancelSubscriptionButtonClick
  }
}
