import { useEffect, useState } from 'react'

import { ConfirmRequestAcceptedModal } from 'pages/AccountOverviewPage/ConfirmRequestAcceptedSeatModal'

import { ErrorMessage, Loading } from 'components'

import {
  useApproveSubscriptionJoinRequestMutation,
  useDeclineSubscriptionJoinRequestMutation,
  useGetPendingJoinRequestsQuery
} from 'gql'

import { displayErrorToast, displaySuccessToast } from 'utils/toastService'

import { ConfirmRequestDeniedModal } from '../ConfirmRequestDeniedModal'
import JoinTeamRequestItem from './JoinTeamRequestItem'

interface JoinTeamRequestsProps {
  subscriptionId: string
  subscriptionHasDomainName: boolean
  hasSeatsAvailable: boolean
}

export const JoinTeamRequests = ({
  subscriptionId,
  subscriptionHasDomainName,
  hasSeatsAvailable
}: JoinTeamRequestsProps) => {
  const avatarSize = 28
  const [modalState, setModalState] = useState<{
    type: 'assign' | 'deny' | null
    requestId: string | null
  }>({ type: null, requestId: null })

  const { data, loading, error, refetch } = useGetPendingJoinRequestsQuery({
    variables: { subscriptionId }
  })

  const [requests, setRequests] = useState(data?.pendingJoinRequests || [])

  useEffect(() => {
    if (data?.pendingJoinRequests) {
      setRequests(data.pendingJoinRequests)
    }
  }, [data])

  const [
    approveJoinRequest,
    { loading: approveJoinRequestLoading, error: approveJoinRequestError }
  ] = useApproveSubscriptionJoinRequestMutation()

  const [
    declineJoinRequest,
    { loading: denyJoinRequestLoading, error: denyJoinRequestError }
  ] = useDeclineSubscriptionJoinRequestMutation()

  const handleApproveRequest = (requestId: string) => {
    setModalState({ type: 'assign', requestId })
  }

  const handleDeclineRequest = (requestId: string) => {
    setModalState({ type: 'deny', requestId })
  }

  const handleAssignSeat = async () => {
    if (!modalState.requestId) return
    try {
      const { data } = await approveJoinRequest({
        variables: { input: { id: modalState.requestId } }
      })

      const joinRequest = data?.approveSubscriptionJoinRequest?.subscriptionJoinRequest

      if (joinRequest?.status === 'accepted') {
        setRequests((prevRequests) =>
          prevRequests.filter((request) => request.id !== modalState.requestId)
        )
        displaySuccessToast({ message: 'Request successfully approved' })
        refetch()
      } else if (data?.approveSubscriptionJoinRequest?.error) {
        displayErrorToast({ message: data.approveSubscriptionJoinRequest.error })
      }
      setModalState({ type: null, requestId: null })
    } catch (error) {
      console.error('Error approving join request:', error)
    }
  }

  const handleDenyRequest = async () => {
    if (!modalState.requestId) return
    try {
      const { data } = await declineJoinRequest({
        variables: { input: { id: modalState.requestId } }
      })

      const joinRequest = data?.declineSubscriptionJoinRequest?.subscriptionJoinRequest

      if (joinRequest?.status === 'rejected') {
        setRequests((prevRequests) =>
          prevRequests.filter((request) => request.id !== modalState.requestId)
        )
        displaySuccessToast({ message: 'Request successfully denied.' })
        refetch()
      } else if (data?.declineSubscriptionJoinRequest?.error) {
        displayErrorToast({ message: data.declineSubscriptionJoinRequest.error })
      }
      setModalState({ type: null, requestId: null })
    } catch (error) {
      console.error('Error denying join request:', error)
    }
  }

  if (loading || approveJoinRequestLoading || denyJoinRequestLoading) return <Loading />
  if (error) return <ErrorMessage error={error} />
  if (approveJoinRequestError) return <ErrorMessage error={approveJoinRequestError} />
  if (denyJoinRequestError) return <ErrorMessage error={denyJoinRequestError} />

  const selectedRequest = requests.find((req) => req.id === modalState.requestId)

  return (
    <>
      {requests.length > 0 && (
        <div data-testid="join-request-section" className="w-full">
          <ConfirmRequestAcceptedModal
            isOpen={modalState.type === 'assign'}
            joiningUserName={selectedRequest?.user?.fullName}
            onAssignSeat={handleAssignSeat}
            handleClose={() => setModalState({ type: null, requestId: null })}
          />
          <ConfirmRequestDeniedModal
            isModalOpen={modalState.type === 'deny'}
            onDenyRequest={handleDenyRequest}
            onClose={() => setModalState({ type: null, requestId: null })}
          />
          <p className="font-semibold leading-[25.6px] pb-2 flex mb-0">
            Join Team Requests
          </p>
          <div className="lg:flex-col mb-4 border-t-1.5 border-gray-100 pt-2.5 pr-2 pb-2.5 pl-10">
            {requests.map((request) => (
              <JoinTeamRequestItem
                key={request.id}
                user={request.user}
                avatarSize={avatarSize}
                subscriptionHasDomainName={subscriptionHasDomainName}
                onApprove={() => handleApproveRequest(request.id)}
                onDeny={() => handleDeclineRequest(request.id)}
                hasSeatsAvailable={hasSeatsAvailable}
              />
            ))}
          </div>
        </div>
      )}
    </>
  )
}
