import React from 'react'

import { Tag } from './Tag'

interface TagHeaderProps {
  tagObjects: TagObject[]
}

interface TagObject {
  text: string
  href?: string
  onClick: (e: React.MouseEvent) => void
}

const TagHeader = ({ tagObjects }: TagHeaderProps) => {
  if (tagObjects.length === 0) {
    return <span />
  }

  return (
    <>
      <div className="hidden flex-initial md:inline-block">
        {tagObjects.map((tagObject, i) => (
          <Tag
            key={`tagObject_${i}`}
            {...tagObject}
            className="mb-2 mr-2"
            variant="light"
          />
        ))}
      </div>
      <div className="flex-initial md:hidden">
        <Tag {...tagObjects[0]} className="mb-2 mr-2" variant="light" />
        {tagObjects.length > 1 && (
          <Tag onClick={tagObjects[0].onClick} className="mb-2 mr-2" variant="light">
            + {tagObjects.length - 1}
          </Tag>
        )}
      </div>
    </>
  )
}

export default TagHeader
