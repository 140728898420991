import clsx from 'clsx'
import 'froala-editor/js/plugins.pkgd.min.js'
import FroalaEditor from 'react-froala-wysiwyg'
import { useGlobalState } from 'state'

import { FroalaHash, useFroalaConfig } from 'domains/Post/FroalaWrapper/helpers'

interface CohortFroalaWrapperProps {
  model?: string
  updateModel: (nextModel: string) => void
  className?: string
  placeholder?: string
  menuContainerId?: string
  autofocus?: boolean
  heightMax?: number
}

const CohortFroalaWrapper = ({
  placeholder,
  menuContainerId,
  autofocus,
  heightMax,
  updateModel,
  className,
  model
}: CohortFroalaWrapperProps) => {
  const [envVariables] = useGlobalState('envVariables')
  const froalaKey = envVariables.froalaKey || ''
  const froalaHash: FroalaHash = JSON.parse(envVariables.froalaHash) || {}

  const { config } = useFroalaConfig({
    froalaKey,
    placeholder,
    froalaHash,
    menuContainerId,
    autofocus,
    disableMentions: true,
    configOverwrites: {
      toolbarButtons: [
        [
          'bold',
          'italic',
          'strikeThrough',
          '|',
          'insertLink',
          '|',
          'formatOL',
          'formatUL',
          '|',
          'insertImage',
          'quote',
          'emoticons'
        ]
      ],
      linkList: [],
      listAdvancedTypes: false,
      emoticonsUseImage: false,
      quickInsertEnabled: false,
      height: null,
      heightMin: 70,
      heightMax
    }
  })

  const handleModelChange = (model: string) => {
    updateModel(model)
  }

  return (
    <div className={clsx('cohort-froala-wrapper', className)}>
      <FroalaEditor
        tag="textarea"
        config={config}
        model={model}
        onModelChange={handleModelChange}
      />
    </div>
  )
}

export default CohortFroalaWrapper
