import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { useExploreTopicsQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { cn } from 'utils/tailwind'
import { trackNavigationClicked } from 'utils/tracking/analytics'

import { CONTENT_TYPES, NUM_LIST_SUBTOPICS, TOPICS } from '../consts'

function ContentTypeCard({
  title,
  subtitle,
  icon,
  destination,
  listIndex
}: {
  title: string
  subtitle: string
  icon: React.ReactNode
  destination: string
  listIndex: number
}) {
  const location = useLocation()
  const { isLoggedIn } = useCurrentUser()
  return (
    <Link
      className="block border border-rb-gray-100 hover:border-rb-gray-200 p-8 rounded shadow hover:no-underline text-black hover:text-black"
      to={destination}
      onClick={() => {
        trackNavigationClicked({
          type: 'content_type_card',
          text: title.toLowerCase(),
          location: location.pathname,
          location_type: 'index',
          logged_in: isLoggedIn,
          destination: destination,
          section_index: 0,
          section_impression_index: listIndex
        })
      }}
    >
      <div className="mb-3">{icon}</div>
      <h2 className="text-xl font-semibold">{title}</h2>
      <div className="text-rb-gray-300">{subtitle}</div>
    </Link>
  )
}

function TopicCard({
  title,
  subtitle,
  icon,
  slug,
  listIndex,
  id,
  numSubtopics
}: {
  title: string
  subtitle: string
  icon: React.ReactNode
  slug: string
  listIndex: number
  id: string
  numSubtopics: number
}) {
  const location = useLocation()
  const { isLoggedIn } = useCurrentUser()

  const destination = `/explore/${slug}`

  return (
    <Link
      className={cn(
        'flex justify-between flex-col md:flex-row items-center border border-rb-gray-100 hover:border-rb-gray-200 p-4 md:p-8 rounded shadow hover:no-underline text-black hover:text-black'
      )}
      to={destination}
      onClick={() => {
        trackNavigationClicked({
          type: 'topic_card',
          text: title.toLowerCase(),
          location: location.pathname,
          location_type: 'topic index',
          logged_in: isLoggedIn,
          destination,
          related_identifiers: {
            destination_ccl_filter_tag_id: id,
            destination_kind: 'topic'
          },
          section_index: 1,
          section_impression_index: listIndex
        })
      }}
    >
      <div className="md:hidden p-7">{icon}</div>
      <div className="max-w-[320px] flex flex-col gap-4">
        <div className="text-sm md:text-xl font-semibold">{title}</div>
        <div className="hidden md:block text-rb-gray-300">
          {numSubtopics - NUM_LIST_SUBTOPICS > 0
            ? `${subtitle}, ${numSubtopics - NUM_LIST_SUBTOPICS}+`
            : subtitle}
        </div>
      </div>
      <div className="hidden md:block p-7">{icon}</div>
    </Link>
  )
}

function SkeletonTopicCard() {
  return (
    <div className="chromatic-ignore mb-8 flex w-full max-w-[600px] animate-pulse flex-col justify-center rounded border border-rb-gray-100 bg-rb-white md:max-w-none md:flex-row md:gap-6 md:p-4">
      <div className="flex w-full p-4 md:py-2 md:px-0 gap-4 md:flex-row flex-col-reverse">
        <div className="flex flex-col w-full justify-center">
          <div className="mb-4 h-[18px] w-full rounded-md bg-rb-gray-100" />
          <div className="hidden md:block mb-2 h-3 w-full rounded-md bg-rb-gray-100" />
          <div className="hidden md:block mb-2 h-3 w-full rounded-md bg-rb-gray-100 md:w-3/4" />
        </div>
        <div className="flex items-center justify-center">
          <div className="aspect-[9/16] shrink-0 rounded-xl bg-rb-gray-100 h-[100px] w-[80px] md:h-[120px] md:w-[100px]" />
        </div>
      </div>
    </div>
  )
}

function LoadingTopics() {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 gap-4">
      <SkeletonTopicCard />
      <SkeletonTopicCard />
      <SkeletonTopicCard />
      <SkeletonTopicCard />
      <SkeletonTopicCard />
      <SkeletonTopicCard />
    </div>
  )
}

export default function ExplorePage() {
  const { data: exploreTopicsData, loading } = useExploreTopicsQuery()

  return (
    <div className="flex flex-col gap-6">
      <div className="flex gap-6 flex-col md:flex-row">
        {CONTENT_TYPES.map((contentType, idx) => (
          <ContentTypeCard
            key={`content-type-${contentType.title}`}
            title={contentType.title}
            subtitle={contentType.subtitle}
            icon={contentType.icon}
            destination={contentType.destination}
            listIndex={idx}
          />
        ))}
      </div>
      <h2 className="text-3xl mb-0 mt-4">Top content by topic</h2>
      {loading ? (
        <LoadingTopics />
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 gap-4">
          {TOPICS.map((topic, idx) => {
            const exploreTopic = exploreTopicsData?.exploreTopics.find((exploreTopic) => {
              return exploreTopic.slug === topic.slug
            })

            if (!exploreTopic) {
              return null
            }

            return (
              <TopicCard
                key={`topic-${topic.title}`}
                title={topic.title}
                subtitle={topic.subtitle}
                icon={topic.icon}
                slug={exploreTopic.slug}
                id={exploreTopic.id}
                listIndex={idx}
                numSubtopics={exploreTopic.numSubtopics}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
