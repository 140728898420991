import { useEffect } from 'react'

// Disable auto scroll restoration since we do not load back previous pages
// or search values when the user clicks back button to return to viewing
// members.
export default function useManualScrollRestoration(previousScrollRestorationRef) {
  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      previousScrollRestorationRef.current = window.history.scrollRestoration
      window.history.scrollRestoration = 'manual'
    }
    return () => {
      if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = previousScrollRestorationRef.current
      }
    }
  }, [previousScrollRestorationRef])
}
