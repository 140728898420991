import { useEffect, useState } from 'react'

import { CohortPostPartsFragment, useCohortConversationPostLazyQuery } from 'gql'

export const useThreadPostLoader = (threadPostId?: string) => {
  const [threadPost, setThreadPost] = useState<CohortPostPartsFragment>()

  const [getCohortConversationPost, { data, refetch, loading }] =
    useCohortConversationPostLazyQuery()

  useEffect(() => {
    if (threadPostId) {
      // thread view is open
      getCohortConversationPost({ variables: { postId: threadPostId } })
    } else {
      // thread view is closed
      setThreadPost(undefined)
    }
  }, [getCohortConversationPost, threadPostId])

  useEffect(() => {
    if (!loading && data?.cohortPost) {
      // post data has been retrieved, update the state
      setThreadPost(data?.cohortPost)
    }
  }, [data, loading])

  return {
    threadPost,
    setThreadPost,
    threadPostLoading: loading,
    refetchThreadPost: refetch
  }
}
