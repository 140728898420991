import { PortableText } from '@portabletext/react'

import block from './componentTypes/Blocks'
import listItem from './componentTypes/ListItem'
import list from './componentTypes/Lists'
import marks from './componentTypes/Marks'
import types from './componentTypes/Types'

export interface ComplexPortableTextProps {
  content: any
  components?: typeof defaultComponents
}

export const defaultComponents = {
  block,
  list,
  marks,
  listItem,
  types
}

export const ComplexPortableText = ({
  content,
  components
}: ComplexPortableTextProps) => (
  <PortableText value={content} components={components || defaultComponents} />
)
