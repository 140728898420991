import { StatusProps, StatusTypes } from './Status'

export function getStatusData({ statusType, name, email }: StatusProps) {
  const headerCopy = getHeaderCopy(statusType)
  const subHeaderCopy = getSubHeaderCopy({ statusType, email, name })
  const iconName = getIconName(statusType)
  const shouldShowLink = shouldShowAccountLink(statusType)
  return {
    headerCopy,
    subHeaderCopy,
    iconName,
    shouldShowLink
  }
}

export function getIconName(statusType: StatusTypes): 'bang' | 'tree' {
  switch (statusType) {
    case StatusTypes.TEAM_REQUEST_DECLINED:
      return 'bang'
    case StatusTypes.TEAM_REQUEST_ALREADY_DECLINED:
      return 'bang'
    case StatusTypes.TEAM_REQUEST_ALREADY_APPROVED:
      return 'tree'
    case StatusTypes.SUBSCRIPTION_ALREADY_ADDED:
      return 'tree'
    case StatusTypes.SUBSCRIPTION_ALREADY_ACTIVE:
      return 'tree'
    case StatusTypes.PATRONAGE_USER_NOT_FOUND:
      return 'bang'
    case StatusTypes.PATRONAGE_USER_NOT_ACCEPTED:
      return 'bang'
    default:
      return 'bang'
  }
}

export function getHeaderCopy(statusType: StatusTypes) {
  switch (statusType) {
    case StatusTypes.TEAM_REQUEST_DECLINED:
      return 'You declined this request'
    case StatusTypes.TEAM_REQUEST_ALREADY_DECLINED:
      return 'This request has already been declined.'
    case StatusTypes.TEAM_REQUEST_ALREADY_APPROVED:
      return 'This request has already been approved!'
    case StatusTypes.SUBSCRIPTION_ALREADY_ADDED:
      return 'This user is already part of a team!'
    case StatusTypes.SUBSCRIPTION_ALREADY_ACTIVE:
      return 'This subscription is already active.'
    case StatusTypes.PATRONAGE_USER_NOT_FOUND:
      return 'Unable to find a user with this link.'
    case StatusTypes.PATRONAGE_USER_NOT_ACCEPTED:
      return 'This person’s application is still under review. Please check back soon.'
    default:
      return 'Something went wrong. Please try again.'
  }
}

export function getSubHeaderCopy({ statusType, email, name }: StatusProps) {
  switch (statusType) {
    case StatusTypes.TEAM_REQUEST_DECLINED:
      return `We’ll let ${
        email || 'the requester'
      } know that you declined their request to join your team.`
    case StatusTypes.TEAM_REQUEST_ALREADY_DECLINED:
      return 'You’ve already declined this request. If this was an error, you can grant access from your account.'
    case StatusTypes.TEAM_REQUEST_ALREADY_APPROVED:
      return `You've already approved this request. ${
        name || 'This person'
      } is in your team.`
    case StatusTypes.SUBSCRIPTION_ALREADY_ADDED:
      return ''
    case StatusTypes.SUBSCRIPTION_ALREADY_ACTIVE:
      return 'This subscription has already been paid for.'
    case StatusTypes.PATRONAGE_USER_NOT_FOUND:
      return 'Verify link and try again.'
    default:
      return ''
  }
}

export function shouldShowAccountLink(statusType: StatusTypes) {
  return [
    StatusTypes.TEAM_REQUEST_DECLINED,
    StatusTypes.TEAM_REQUEST_ALREADY_APPROVED,
    StatusTypes.TEAM_REQUEST_ALREADY_DECLINED
  ].includes(statusType)
}
