import { Redirect, useParams } from 'react-router-dom'

import { OldCourseDetailsPage } from 'pages/OldCourseDetailsPage/OldCourseDetailsPage'

import Content404 from 'domains/Sanity/marketingSite/Content404'
import LegacyCoursePage from 'domains/Sanity/marketingSite/LegacyCoursePage'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { Loading } from 'components'

import {
  CourseDetails,
  MarketingCourse,
  UnifiedCourse,
  useMarketingCourseDetailsQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

export type LegacyCourse = Omit<UnifiedCourse, keyof MarketingCourse> &
  Pick<
    MarketingCourse,
    | 'isLive'
    | 'title'
    | 'subtitle'
    | 'hosts'
    | 'creators'
    | 'backgroundImage'
    | 'courseFaq'
    | 'previewImage'
  >
export type MarketplaceCourse = Omit<MarketingCourse, '__typename' | 'isLive'>

const OldLoggedOutCourseDetails = () => {
  const { isLoggedIn } = useCurrentUser()
  const { courseSlug } = useParams<{ courseSlug: string }>()
  const { data, loading } = useMarketingCourseDetailsQuery({
    variables: {
      slug: courseSlug
    }
  })

  const course = data?.marketingCourseDetails?.course as UnifiedCourse
  const pageTitle =
    course && course.courseType === 'marketplace'
      ? course.course?.title || ''
      : course?.title || ''

  const page = { ...((data?.marketingCourseDetails || {}) as CourseDetails) }

  if (pageTitle) {
    page.title = pageTitle
  }

  if (isLoggedIn && course?.courseType === 'marketplace') {
    return <Redirect to={`/courses/${courseSlug}/details`} />
  }

  return (
    <MarketingLayout page={page}>
      {loading ? (
        <Loading />
      ) : course ? (
        course.courseType === 'legacy' ? (
          <LegacyCoursePage course={course as LegacyCourse} />
        ) : (
          <MarketplaceCoursePage
            course={course as MarketplaceCourse}
            courseSlug={courseSlug}
          />
        )
      ) : (
        <Content404 />
      )}
    </MarketingLayout>
  )
}

export interface MarketplaceCoursePageProps {
  course: MarketplaceCourse
  courseSlug: string
}

export const MarketplaceCoursePage = ({
  course,
  courseSlug
}: MarketplaceCoursePageProps) => {
  return <OldCourseDetailsPage course={course} courseSlug={courseSlug} />
}

export default OldLoggedOutCourseDetails
