import { useMemo } from 'react'

import { FacePileUser } from 'components/FacePile'

import { getRandom } from 'utils/arrayUtils'

const useSelectRandomUsersFromList = (
  users: FacePileUser[] | null | undefined,
  numberSelected: number = users?.length || 0
) => {
  const randomUsers = useMemo(() => {
    if (Array.isArray(users) && users.length > numberSelected) {
      return getRandom(users || [], numberSelected)
    }
    return users || []
  }, [users, numberSelected])

  return randomUsers
}

export default useSelectRandomUsersFromList
