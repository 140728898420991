import { privateApolloClient } from 'apolloClient'

import CommentBlock from 'domains/Cms/CommentsBlock/CommentBlock'
import DeprecatedCommentsBlock from 'domains/Cms/DeprecatedCommentsBlock'
import { AddPostModalProps, useAddPostModal } from 'domains/Post/AddPostModal'

import { useSideDrawer } from 'components/SideDrawer/SideDrawer'

import {
  InlinePost,
  InlinePostsDocument,
  UserBookmarksFeedDocument,
  useCreateBookmarkMutation,
  useDeleteBookmarkMutation,
  useUpdateBookmarkMutation
} from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

interface CommentsBlockProps {
  id: string
  userId?: string
  cmsSectionId?: string
  cmsProgramId?: string
  cmsModuleId?: string
  referenceImageUrl: string
  synopsis: string
  bookmarksByAnchor: any
  inlinePostAnchors: string[]
  inlinePosts: InlinePost[]
  initialGroupId: string
  initialTopicId?: string | null
  accessPolicyKind: string
  hasApplied: boolean
  setActivePanel: (type: string) => void
}

export default function CommentsBlock({
  id,
  userId,
  cmsSectionId,
  cmsProgramId,
  cmsModuleId,
  referenceImageUrl,
  synopsis,
  bookmarksByAnchor,
  inlinePostAnchors,
  inlinePosts,
  initialGroupId,
  initialTopicId,
  accessPolicyKind,
  hasApplied,
  setActivePanel
}: CommentsBlockProps) {
  const { setModalData, showAddPostModal } = useAddPostModal()
  const {
    openDrawer,
    filteredItemId,
    setActiveFilterId,
    hoveredItemId,
    setHoveredItemId
  } = useSideDrawer()
  const { showHighlighting } = useFeatureFlags()

  const onCommentBlockOpen = (
    anchor: string,
    basedOn?: string,
    referenceImageUrl?: string
  ) => {
    const data: AddPostModalProps = {}
    data.cmsSectionId = cmsSectionId
    data.anchor = anchor
    data.basedOn = basedOn ? basedOn.replace(/&amp;/g, '&') : ''
    data.referenceImageUrl = referenceImageUrl

    const initialGroupsTopics = []
    if (initialGroupId !== null && initialGroupId !== undefined) {
      initialGroupsTopics.push({
        type: 'group',
        id: initialGroupId.toString()
      })
    }
    if (initialTopicId !== null && initialTopicId !== undefined) {
      initialGroupsTopics.push({
        type: 'topic',
        id: initialTopicId.toString()
      })
    }
    data.initialGroupsTopics = initialGroupsTopics
    data.afterPostCallback = addPostCallback
    setModalData(data)
    showAddPostModal()
  }

  const addPostCallback = () => {
    privateApolloClient.refetchQueries({
      include: [InlinePostsDocument]
    })
    openDrawer('discoveryDrawer')
    setActivePanel('discussions')
  }

  const [createBookmark] = useCreateBookmarkMutation({
    update: (cache, mutationResult) => {
      const newBookmark = mutationResult?.data?.createBookmark?.bookmark
      const cmsContentId = newBookmark?.cmsSection?.id
      if (!newBookmark || !cmsContentId) return

      cache.modify({
        id: `CmsContent:${cmsContentId}`,
        fields: {
          bookmarks(existingBookmarkRefs) {
            const newBookmarkRef = { __ref: `Bookmark:${newBookmark.id}` }
            return [...existingBookmarkRefs, newBookmarkRef]
          }
        }
      })
    }
  })

  const [updateBookmark] = useUpdateBookmarkMutation()
  const [deleteBookmark] = useDeleteBookmarkMutation({
    update: (cache, mutationResult) => {
      const deletedBookmarkId = mutationResult?.data?.deleteBookmark?.id
      if (!deletedBookmarkId) return

      const normalizedId = cache.identify({
        id: deletedBookmarkId,
        __typename: 'Bookmark'
      })
      cache.evict({ id: normalizedId })
      cache.gc()
    }
  })

  const saveBookmark = async (bookmark: any, callback: any) => {
    const { data, errors } = await createBookmark({
      variables: {
        input: {
          cmsProgramId: bookmark.cms_program_id,
          cmsModuleId: bookmark.cms_module_id,
          cmsSectionId: bookmark.cms_section_id,
          basedOn: bookmark.based_on,
          anchor: bookmark.anchor,
          referenceImageUrl: bookmark.reference_image_url,
          type: bookmark.type
        }
      },
      refetchQueries: [UserBookmarksFeedDocument]
    })
    // open the bookmark panel in the discovery drawer
    openDrawer('discoveryDrawer')
    setActivePanel('bookmarks')
    callback(errors, data?.createBookmark?.bookmark?.id)
  }

  const removeBookmark = async (bookmark: any, callback: any) => {
    const { errors } = await deleteBookmark({
      variables: {
        input: {
          bookmarkId: bookmark.id
        }
      }
    })
    callback(errors)
  }

  const saveExistingBookmark = async (bookmark: any, callback: any) => {
    const { errors } = await updateBookmark({
      variables: {
        input: {
          bookmarkId: bookmark.id,
          noteBody: bookmark.note_body
        }
      }
    })
    callback(errors)
  }

  const scrollDrawerToPost = (anchor: string) => {
    setActivePanel('discussions')

    // TODO: update to find the first post in content order, instead of first one found
    const post = inlinePosts.find((post) => post.anchor === anchor)
    if (!post) return

    const element = document.getElementById(`inline-post--${post.id}`)
    const container = document.getElementById('discovery-drawer-discussions-panel')
    if (!element || !container) return

    const rect = element.getBoundingClientRect()

    container.scrollTo({
      top: container.scrollTop + rect.top - 100,
      behavior: 'smooth'
    })
  }

  if (showHighlighting) {
    return (
      <CommentBlock
        elementId={id}
        userId={userId}
        cmsSectionId={cmsSectionId}
        cmsProgramId={cmsProgramId}
        cmsModuleId={cmsModuleId}
        referenceImageUrl={referenceImageUrl}
        synopsis={synopsis}
        bookmarksByAnchor={bookmarksByAnchor}
        inlinePostAnchors={inlinePostAnchors}
        accessPolicyKind={accessPolicyKind}
        hasApplied={hasApplied}
        saveBookmark={saveBookmark}
        removeBookmark={removeBookmark}
        updateBookmark={saveExistingBookmark}
        setActivePanel={setActivePanel}
        openDrawer={openDrawer}
        scrollDrawerToPost={scrollDrawerToPost}
        setActiveFilterId={setActiveFilterId}
        filteredItemId={filteredItemId}
        onCommentBlockOpen={onCommentBlockOpen}
        hoveredItemId={hoveredItemId}
        setHoveredItemId={setHoveredItemId}
      />
    )
  }

  return (
    <DeprecatedCommentsBlock
      elementId={id}
      userId={userId}
      cmsSectionId={cmsSectionId}
      cmsProgramId={cmsProgramId}
      cmsModuleId={cmsModuleId}
      referenceImageUrl={referenceImageUrl}
      synopsis={synopsis}
      bookmarksByAnchor={bookmarksByAnchor}
      inlinePostAnchors={inlinePostAnchors}
      accessPolicyKind={accessPolicyKind}
      hasApplied={hasApplied}
      saveBookmark={saveBookmark}
      removeBookmark={removeBookmark}
      updateBookmark={saveExistingBookmark}
      setActivePanel={setActivePanel}
      openDrawer={openDrawer}
      setActiveFilterId={setActiveFilterId}
      onCommentBlockOpen={onCommentBlockOpen}
    />
  )
}
