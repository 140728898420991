import { ColorOptions } from 'components/typography/TypographyColorOptions'

interface Tag {
  tagColor: string
  textColor: ColorOptions
}

export const TAGS: Record<string, Tag> = {
  'Key Lesson': { tagColor: 'bg-[#d2e5e4]/30', textColor: ColorOptions.brandTeal200 }
}
