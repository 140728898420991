import { useState } from 'react'

import BookmarkControls from 'domains/Bookmarks/BookmarkControls'
import LinkModal from 'domains/Collections/LinkModal'

import { SVGIcon } from 'components/Icon'

import {
  BookmarkFolderPartsFragment,
  ProgramBookmarkPartsFragment,
  useDeleteBookmarkMutation
} from 'gql'

import notifyError from 'utils/errorNotifier'
import { trackCollectionAction } from 'utils/tracking/analytics'

interface CollectionLinkCardProps {
  bookmark: ProgramBookmarkPartsFragment
  currentFolder?: BookmarkFolderPartsFragment | null
  restoreBookmark?: (bookmark: ProgramBookmarkPartsFragment) => void
}

const CollectionLinkCard = ({
  bookmark,
  currentFolder,
  restoreBookmark
}: CollectionLinkCardProps) => {
  const { title, externalUrl, updated } = bookmark
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [deleteBookmark] = useDeleteBookmarkMutation({
    update: (cache, mutationResult) => {
      const deletedBookmarkId = mutationResult?.data?.deleteBookmark?.id
      if (!deletedBookmarkId) return

      const normalizedId = cache.identify({
        id: deletedBookmarkId,
        __typename: 'Bookmark'
      })
      cache.evict({ id: normalizedId })
      cache.gc()
    }
  })

  const handleDeleteBookmark = async () => {
    const { errors } = await deleteBookmark({
      variables: {
        input: {
          bookmarkId: bookmark.id
        }
      }
    })
    if (errors) {
      notifyError(
        `Error deleting ExternalUrlBookmark, got errors ${errors} for bookmark ${JSON.stringify(
          bookmark
        )}`
      )
    }
  }

  const handleEditBookmark = () => {
    setIsModalOpen(true)
  }

  const handleEditLinkTracking = () => {
    trackCollectionAction({
      action: 'edit_link',
      collection_id: currentFolder?.id,
      collection_name: currentFolder?.name,
      is_reforge_collection:
        currentFolder?.reforgeCollection || !!currentFolder?.forkedFromId,
      is_shared_collection: currentFolder?.sharedFolders[0]?.status === 'shared',
      location: window.location.pathname
    })
  }

  return (
    <>
      <LinkModal
        isModalOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        bookmark={bookmark}
        handleEditLinkTracking={handleEditLinkTracking}
      />
      <button
        type="button"
        className="flex w-full flex-col flex-wrap items-start rounded-md border border-rb-gray-250 p-6 hover:shadow-content-card"
        onClick={() => {
          externalUrl && window.open(externalUrl, '_blank')
        }}
      >
        <div className="mb-2 flex h-[18px] w-full items-center text-xs">
          <SVGIcon name="external-url" />
          <div className="justify-center pl-2 text-xs font-medium">Link</div>
          <div className="ml-auto mr-2 flex-shrink-0 cursor-default pl-4 text-rb-gray-300">
            {updated} ago
          </div>
          <BookmarkControls
            bookmark={bookmark}
            onDeleteBookmark={handleDeleteBookmark}
            currentFolder={currentFolder}
            handleEditBookmark={handleEditBookmark}
            restoreBookmark={restoreBookmark}
          />
        </div>
        {title && <div className="mb-2 text-xl font-medium">{title}</div>}
        {externalUrl && (
          <a href={externalUrl} target="_blank" rel="noreferrer">
            {externalUrl}
          </a>
        )}
      </button>
    </>
  )
}

export default CollectionLinkCard
