import { useCallback, useEffect, useMemo, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useHistory, useLocation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import CustomPageTitle from 'pages/CustomPageTitle'

import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'

import Button from 'components/Button'
import { usePage } from 'components/PageHeader/usePage'
import PremiumBadge from 'components/PremiumBadge'
import ArtifactCard from 'components/cards/Content/ArtifactCard'
import { CloseIcon } from 'components/icons'

import { ARTIFACTS_INDEX_TRACK_LOCATION } from 'constants/artifacts'
import { MAX_WIDTH_TAILWIND_LG, MIN_WIDTH_TAILWIND_XL } from 'constants/breakpoints'

import {
  type AllTopicsAndFunctionsQuery,
  Artifact,
  ArtifactFilters,
  FilteredArtifactsFragmentFragment,
  useAllTopicsAndFunctionsQuery,
  useArtifactSubtopicsQuery,
  useArtifactsIndexPageViewedArtifactsForUserQuery,
  useBookmarkFoldersQuery,
  useFilteredArtifactsQuery,
  useSavedArtifactsForUserQuery,
  useSavedCountPerArtifactQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import { trackCtaClicked, trackFilteredResultsReturned } from 'utils/tracking/analytics'

import FilterMenuContainer from './components/FilterMenuContainer/FilterMenuContainer'
import LoadingSkeleton from './components/LoadingSkeleton'
import NoResults from './components/NoResults'
import { FilterSettingIconTeal } from './components/icons'
import { ARTIFACTS_INDEX_MODES, PAGINATION_LIMIT } from './constants'
import {
  getArtifactIndexModeFromLocation,
  getFilterTrackingValues,
  getSanityFilterMap,
  removeLastIdParam,
  topicMapping
} from './helper'
import { useArtifactIndexFilter, useTrackChangeAndReact } from './hooks'
import { ArtifactIndexArtifactsQueryMetadata } from './types'

export default function ArtifactsIndexPage() {
  const [showMobileTopicFilterMenu, setShowMobileTopicFilterMenu] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [artifacts, setArtifacts] = useState<
    FilteredArtifactsFragmentFragment['artifacts']
  >([])
  const [artifactsQueryMeta, setArtifactsQueryMeta] =
    useState<ArtifactIndexArtifactsQueryMetadata>()

  const [newFilterLoading, setNewFilterLoading] = useState(false)
  const [topicList, setTopicList] = useState<
    AllTopicsAndFunctionsQuery['allTopicsAndFunctions'] | undefined
  >([])
  const [functionList, setFunctionList] = useState<
    AllTopicsAndFunctionsQuery['allTopicsAndFunctions'] | undefined
  >([])
  const location = useLocation()
  const history = useHistory()
  const gridMode = getArtifactIndexModeFromLocation(location)
  const isGreaterThanEqualXl = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_XL})`)
  const { currentUser: user, isLoggedIn } = useCurrentUser()
  const { setPageTitle, setPageHeaderTopLeftContent } = usePage()
  const maxWidthLG = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_LG})`)
  const gridClasses =
    'grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4'
  const searchParams = new URLSearchParams(location.search)
  const { data: subtopicData } = useArtifactSubtopicsQuery({
    variables: { topicSlug: null, subtopicSlug: null }
  })

  useTrackChangeAndReact(
    isGreaterThanEqualXl,
    (prevIsGreaterThanEqualXl, newIsGreaterThanEqualXl) => {
      if (!prevIsGreaterThanEqualXl && newIsGreaterThanEqualXl) {
        setShowMobileTopicFilterMenu(false)
      }
    }
  )
  const {
    currentBookmarkForDropdown,
    isAddToBookmarkFolderModalOpen,
    closeAddToBookmarkFolderModal,
    openAddToBookmarkFolderModal
  } = useOpenAddToBookmarkFolderModal()
  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    closeCreateBookmarkFolderModal,
    openCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()

  const { data: bookmarkFolderData } = useBookmarkFoldersQuery({
    skip: !user?.id
  })

  const {
    filters: initialFilters,
    lastId,
    handleFilterSelection,
    lastChangedFilter,
    filterCount
  } = useArtifactIndexFilter()

  const sanityFilterMap = useMemo(
    () =>
      getSanityFilterMap(
        topicList || [],
        functionList || [],
        subtopicData?.subtopics || []
      ),
    [topicList, functionList, subtopicData?.subtopics]
  )

  const modifyFilters = useCallback(
    (filters: ArtifactFilters) => {
      const filterKeys: (keyof ArtifactFilters)[] = [
        'subtopicIds',
        'topicIds',
        'functionIds'
      ]

      return filterKeys.reduce<ArtifactFilters>(
        (modifiedFilters, filterKey) => {
          const filterItems = filters[filterKey] || []
          const mapToUse = sanityFilterMap.get(filterKey)

          const matchedItems = Array.isArray(filterItems)
            ? filterItems
                .map((item: string) => mapToUse?.get(item)) // Add null check for mapToUse
                .filter(Boolean) // Filter out undefined values
            : []

          return { ...modifiedFilters, [filterKey]: matchedItems }
        },
        { ...filters }
      )
    },
    [sanityFilterMap]
  )

  const [filters, setFilters] = useState<ArtifactFilters>(initialFilters)

  useEffect(() => {
    const modifiedFilters = modifyFilters(initialFilters)
    setFilters(modifiedFilters)
  }, [initialFilters, modifyFilters])

  const { data: filteredArtifactsData, loading: filteredArtifactsLoading } =
    useFilteredArtifactsQuery({
      variables: {
        filters,
        lastId,
        limit: PAGINATION_LIMIT
      }
    })

  const { data: saveCounts } = useSavedCountPerArtifactQuery()

  const {
    data: artifactsIndexPageArtifactFilters,
    loading: artifactsIndexPageArtifactFiltersLoading
  } = useAllTopicsAndFunctionsQuery()

  const { data: artifactsIndexPageViewedArtifactsForUser } =
    useArtifactsIndexPageViewedArtifactsForUserQuery({
      skip: !isLoggedIn,
      variables: {
        userId: user?.id as string
      }
    })

  const { data: savedData } = useSavedArtifactsForUserQuery({
    skip: !user?.id,
    variables: {
      userId: user?.id as string
    }
  })

  const data = useMemo(
    () => ({
      filterOptions: artifactsIndexPageArtifactFilters?.allTopicsAndFunctions
    }),
    [artifactsIndexPageArtifactFilters?.allTopicsAndFunctions]
  )

  const { filterOptions } = data

  useEffect(() => {
    if (showMobileTopicFilterMenu) {
      setPageTitle(null)
    } else {
      if (!user || user?.is.freeUser) {
        setPageTitle(
          <CustomPageTitle title="Artifacts">
            <PremiumBadge />
          </CustomPageTitle>
        )
      } else {
        setPageTitle('Artifacts')
      }

      if (!isLoggedIn) {
        setPageHeaderTopLeftContent(
          <Button
            variant="outline"
            shape="rounded"
            size="x-small"
            href="/creators?internal_ref=artifacts"
            className="mt-[3px]"
            onClick={() => {
              trackCtaClicked({
                cta_location: 'artifact_index_page',
                cta_type: 'button',
                destination: '/creators?internal_ref=artifacts',
                logged_in: isLoggedIn,
                text: 'share your work'
              })
            }}
          >
            Share your work
          </Button>
        )
      }
    }

    return () => {
      setPageTitle(null)
      setPageHeaderTopLeftContent(null)
    }
  }, [
    isLoggedIn,
    setPageHeaderTopLeftContent,
    setPageTitle,
    showMobileTopicFilterMenu,
    user
  ])

  useEffect(() => {
    if (showMobileTopicFilterMenu) {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [showMobileTopicFilterMenu])

  const artifactSavedCountByArtifactId = useMemo(() => {
    return saveCounts?.savedCountPerArtifact?.reduce(
      (acc, savedCountRecord) => ({
        ...acc,
        [savedCountRecord.artifactId]: savedCountRecord.count
      }),
      {} as Record<string, number>
    )
  }, [saveCounts?.savedCountPerArtifact])

  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }
  const loadMore = () => {
    trackCtaClicked({
      cta_location: ARTIFACTS_INDEX_TRACK_LOCATION,
      cta_type: 'button',
      text: 'view more artifacts',
      logged_in: isLoggedIn
    })
    setLoadingMore(true)
    const lastArtifact = artifacts[artifacts.length - 1]
    handleFilterSelection('last-id', lastArtifact.id)
  }

  useEffect(() => {
    if (!filteredArtifactsData?.filteredArtifacts) {
      return setLoadingMore(false)
    }
    const searchParams = new URLSearchParams(location.search)
    const {
      artifacts,
      nextArtifactsCount,
      prevArtifactsCount,
      totalArtifactsCount
    }: ArtifactIndexArtifactsQueryMetadata = {
      ...filteredArtifactsData.filteredArtifacts
    }

    setArtifactsQueryMeta({
      artifacts,
      nextArtifactsCount,
      prevArtifactsCount,
      totalArtifactsCount
    })

    const isFirstRender = !searchParams.has('last-id')

    if (isFirstRender) {
      setArtifacts(artifacts || [])
    } else if (
      nextArtifactsCount > 0 ||
      (nextArtifactsCount === 0 && prevArtifactsCount > 0)
    ) {
      setArtifacts((prevData) => [...prevData, ...artifacts])
    } else {
      setArtifacts([])
    }

    setLoadingMore(false)

    setTimeout(() => {
      setNewFilterLoading(false)
    }, 300)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredArtifactsData])

  useEffect(() => {
    if (filterCount > 0 && lastChangedFilter !== 'sortBy') {
      const filterTrackingValues = getFilterTrackingValues(
        filters,
        topicList || [],
        functionList || [],
        subtopicData?.subtopics || []
      )
      trackFilteredResultsReturned({
        filter_location: ARTIFACTS_INDEX_TRACK_LOCATION,
        filters: filterTrackingValues,
        filter_changed: topicMapping[lastChangedFilter],
        results_count: artifactsQueryMeta?.totalArtifactsCount || 0,
        active_filter_count: filterCount,
        logged_in: isLoggedIn
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artifactsQueryMeta?.totalArtifactsCount])

  useEffect(() => {
    /*
     * this is to ensure we reset pagination on a full refresh.
     * Currently we aren't tracking individual pages by numbers
     * We are saving previous artifacts  in a state object
     * as each API call will only return the limit. If user was on page
     * 3 there's no way to inform the api I need the previous 40 records
     * assuming the pagination limit is 20
     */

    // Replace the current history entry with the new URL without 'last-id'
    // Maintains any other existing parameters, including 'topics'
    removeLastIdParam(history)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const topicList = filterOptions?.filter((item: any) => item.type === 'topic')
    const functionList = filterOptions?.filter(
      (item: any) => item.type === 'functionFilter'
    )
    setTopicList(topicList)
    setFunctionList(functionList)
  }, [filterOptions])

  return (
    <div
      className={twMerge(
        !isLoggedIn && 'px-4 pl-4 sm:pl-4 sm:pr-4 md:px-[4vw] lg:pl-[4vw]'
      )}
    >
      {maxWidthLG ? (
        <button
          className={twMerge(
            'mb-[26px] flex h-[50px] w-full cursor-pointer items-center justify-center rounded-full border border-rb-teal-600 xl:hidden',
            showMobileTopicFilterMenu && 'hidden'
          )}
          onClick={() => setShowMobileTopicFilterMenu((currentVal) => !currentVal)}
        >
          {!!filterCount && (
            <span className="mr-[10px] flex h-5 w-5 items-center justify-center rounded-full bg-rb-teal-600 font-semibold text-white ">
              {filterCount}
            </span>
          )}
          <span className="mr-[10px] font-sans text-base font-semibold text-rb-teal-600">
            Sort & filter
          </span>
          <FilterSettingIconTeal />
        </button>
      ) : null}
      {showMobileTopicFilterMenu && (
        <div
          className={twMerge(
            'fixed left-0 top-0 z-[1011] h-[56px] w-full bg-rb-black opacity-30 lg:h-[70px]',
            !isLoggedIn && 'h-[56px] lg:h-[76px]'
          )}
        ></div>
      )}
      <div
        className={twMerge(
          showMobileTopicFilterMenu &&
            'fixed left-0 top-[56px] z-2 h-[100dvh] w-full overflow-x-hidden bg-white px-4 pb-[150px] lg:top-[72px] lg:z-[201]',
          !isLoggedIn && showMobileTopicFilterMenu && 'top-[56px] z-[201] lg:top-[76px]'
        )}
      >
        {showMobileTopicFilterMenu && (
          <div
            className={twMerge('mb-6 flex items-center justify-between pl-4 pr-2 pt-4')}
          >
            <span className="font-sans text-xl font-semibold text-rb-gray-300">
              Sort & filter
            </span>
            <button
              className={twMerge(
                'flex h-8 w-8 shrink-0 grow-0 items-center justify-end text-black'
              )}
              onClick={() => setShowMobileTopicFilterMenu(false)}
            >
              <CloseIcon className={twMerge('h-11 w-11 text-rb-gray-300')} />
            </button>
          </div>
        )}
        <FilterMenuContainer
          data={{
            topicList,
            subtopicList: subtopicData?.subtopics,
            filterCount,
            filteredArtifactsLoading,
            artifactsQueryMeta,
            functionList,
            handleFilterSelection,
            artifactsIndexPageArtifactFiltersLoading,
            setNewFilterLoading,
            showMobileTopicFilterMenu,
            setShowMobileTopicFilterMenu,
            artifactsIndexPageViewedArtifactsForUser
          }}
        />
      </div>
      <section className="flex-col-4 relative flex gap-4 md:gap-7 tl:flex-row ">
        <ErrorBoundary
          fallback={<div>There was an error, please refresh and try again.</div>}
        >
          <div className="w-full flex-auto">
            {artifactsIndexPageArtifactFiltersLoading ||
            newFilterLoading ||
            (filteredArtifactsLoading && !searchParams.has('last-id')) ? (
              <LoadingSkeleton />
            ) : (
              <>
                {gridMode === ARTIFACTS_INDEX_MODES.FOR_YOU && (
                  <div className="mt-[20px] flex h-[45px] w-full items-center justify-start tl:mt-[40px]">
                    <h1 className="flex text-lg font-semibold leading-relaxed text-rb-black">
                      <span>Top 10 artifacts for you&nbsp;</span>
                      <span className="hidden sm:block">
                        based on your role and focus areas
                      </span>
                    </h1>
                  </div>
                )}

                <div className="collection--grid mx-auto grid bg-rb-white">
                  {!artifactsIndexPageArtifactFiltersLoading &&
                    artifactsQueryMeta?.totalArtifactsCount === 0 && (
                      <NoResults
                        data={{
                          artifactSavedCountByArtifactId,
                          savedData,
                          openAddToBookmarkFolderModal
                        }}
                      />
                    )}

                  <div className={gridClasses}>
                    {artifacts.map((artifact: Artifact) => (
                      <ArtifactCard
                        key={artifact.id}
                        artifact={artifact}
                        bookmark={savedData?.savedArtifactsForUser?.find(
                          (bookmark) => bookmark.sanityId === artifact.id
                        )}
                        openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                        additionalRelatedIdentifiers={{
                          is_filtered_reference: !!filterCount,
                          referrer_name: ARTIFACTS_INDEX_TRACK_LOCATION,
                          is_empty_index_result:
                            artifactsQueryMeta?.totalArtifactsCount === 0
                        }}
                        hideBookmarkButton={!isLoggedIn}
                      />
                    ))}
                  </div>
                </div>

                {gridMode === ARTIFACTS_INDEX_MODES.FOR_YOU && (
                  <div className="mb-[32px] flex justify-center md:py-[32px]">
                    <Button color="teal" onClick={() => history.push('/artifacts')}>
                      Explore all artifacts
                    </Button>
                  </div>
                )}

                {(artifactsQueryMeta?.nextArtifactsCount || 0) > 0 &&
                  gridMode !== ARTIFACTS_INDEX_MODES.FOR_YOU && (
                    <div className="relative flex justify-center p-[16px] text-center md:py-[32px]">
                      <Button
                        isLoadingSpinner={loadingMore || filteredArtifactsLoading}
                        color="teal"
                        onClick={loadMore}
                      >
                        View more artifacts
                      </Button>
                    </div>
                  )}
              </>
            )}
          </div>
        </ErrorBoundary>
      </section>
      <AddBookmarkToFolderModal
        isOpen={isAddToBookmarkFolderModalOpen}
        handleClose={closeAddToBookmarkFolderModal}
        bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders}
        openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdown}
        showCollectionsOnboardingInfo={
          !bookmarkFolderData?.currentUser?.hasSeenCollectionsPrompt
        }
      />
      <CreateBookmarkFolderModal
        isModalOpen={isCreateBookmarkFolderModalOpen}
        handleClose={closeCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
        trackingTriggerAction={'bookmark'}
      />
    </div>
  )
}
