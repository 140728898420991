import Button, { ButtonVariant } from 'components/Button'

const ManageTeamButton = ({
  variant,
  className,
  handleClick
}: {
  variant?: ButtonVariant
  className?: string
  handleClick(text: string, destination: string): void
}) => {
  const text = 'Manage Team'
  const destination = '/team_members'

  return (
    <Button
      className={className}
      shape="rounded-full"
      size="small"
      href={destination}
      variant={variant || 'fill'}
      onClick={() => handleClick(text, destination)}
    >
      {text}
    </Button>
  )
}

export default ManageTeamButton
