import { BreadcrumbHistoryEntry } from 'components/Breadcrumbs'

interface UseBackButtonProps {
  history: BreadcrumbHistoryEntry[]
}

const useBackButton = ({ history }: UseBackButtonProps) => {
  const previousPathIndex = history.length - 2

  const previousPath = history[previousPathIndex].path

  return { previousPath }
}

export default useBackButton
