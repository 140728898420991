import { ReactNode } from 'react'

import DragHandleIcon from 'components/icons/DragHandleIcon'

interface DragHandleProps {
  className?: string
  children?: ReactNode
}

const DragHandle = ({
  className = '',
  children = <DragHandleIcon />,
  ...listenersAndAttributes
}: DragHandleProps) => {
  return (
    <div className={`cursor-grab ${className}`} {...listenersAndAttributes}>
      {children}
    </div>
  )
}

export default DragHandle
