import CohortPostContentPreview from 'components/CohortPostCard/CohortPostContentPreview'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import { CohortPostPartsFragment } from 'gql'

interface CohortPostProps {
  post: CohortPostPartsFragment
  cohortSlug: string
}

const CohortPost = ({ post, cohortSlug }: CohortPostProps) => {
  return (
    <div>
      {post.title && (
        <RfParagraphMediumBold className="mb-2 text-rb-black">
          {post.title}
        </RfParagraphMediumBold>
      )}
      <RfParagraphSmall color={ColorOptions.black}>
        <span dangerouslySetInnerHTML={{ __html: post.body || '' }} />
      </RfParagraphSmall>

      <CohortPostContentPreview post={post} cohortSlug={cohortSlug} />
    </div>
  )
}

export default CohortPost
