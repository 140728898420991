// Artifact and comment lists
// if most helpful is selected, and there are no reactions, sort by date.
// if on the "all" list, and there are no reactions, sort the annotations by the default order,
import { ArtifactAnnotation, ArtifactComment } from 'gql'

// and the comments by date with annotations being first.
export function getListByMostHelpful(
  annotations: ArtifactAnnotation[],
  comments: ArtifactComment[]
) {
  const all = [...annotations, ...comments]

  const totalCountAllReactions = all.reduce((total, item) => {
    return total + getItemTotalReactionCount(item)
  }, 0)

  if (totalCountAllReactions === 0) {
    return [...annotations, ...sortAllByDate(comments)]
  }

  return sortAllByReactionCount(all)
}

// Artifact and comment lists
export function getListByNewest(all: (ArtifactAnnotation | ArtifactComment)[]) {
  return sortAllByDate(all)
}

// (stu) possibly move to the api if wanted elsewhere.
export function getItemTotalReactionCount(item: ArtifactAnnotation | ArtifactComment) {
  const reactions = item.reactionsGroupedByName as { name: string; count: number }[]

  if (!reactions?.length) return 0

  return reactions.reduce((total, reaction) => {
    return total + reaction.count
  }, 0)
}

// Artifact and comment lists
export function sortAllByReactionCount(all: (ArtifactAnnotation | ArtifactComment)[]) {
  return all.sort((a, b) => {
    const aTotalReactionCount = getItemTotalReactionCount(a)
    const bTotalReactionCount = getItemTotalReactionCount(b)

    if (aTotalReactionCount > bTotalReactionCount) return -1
    if (aTotalReactionCount < bTotalReactionCount) return 1
    return 0
  })
}

// Artifact and comment lists
export function sortAllByDate(
  all: (ArtifactAnnotation | ArtifactComment)[],
  newestFirst = true
) {
  if (newestFirst) {
    return all.sort((a, b) => {
      const aDate = new Date(a.createdAt)
      const bDate = new Date(b.createdAt)

      if (aDate > bDate) return -1
      if (aDate < bDate) return 1
      return 0
    })
  } else {
    return all.sort((a, b) => {
      const aDate = new Date(a.createdAt)
      const bDate = new Date(b.createdAt)

      if (aDate > bDate) return 1
      if (aDate < bDate) return -1
      return 0
    })
  }
}
