import { useMemo } from 'react'

import {
  TagGroup,
  Unit,
  useGuidesBySanityTagGroupQuery,
  useGuidesForDiscoveryQuery,
  useSanityTagGroupQuery
} from 'gql'

interface UseSwimlaneDataResult {
  tagGroup: TagGroup | null | undefined
  guides: Unit[] | null | undefined
  freeGuidesLoading: boolean
  loadMore: () => void
  isLoading: boolean
}

const useSwimlaneData = (slug: string, isSwimlaneAll: boolean): UseSwimlaneDataResult => {
  const { data: tagGroupData, loading: tagGroupLoading } = useSanityTagGroupQuery({
    variables: {
      slug: slug
    },
    skip: isSwimlaneAll
  })

  const { data: guidesData, loading: guidesLoading } = useGuidesBySanityTagGroupQuery({
    variables: {
      slug: slug
    },
    skip: isSwimlaneAll
  })

  const { data: allGuidesData, loading: allGuidesLoading } = useGuidesForDiscoveryQuery({
    skip: !isSwimlaneAll
  })

  const tagGroup = tagGroupData?.sanityTagGroup

  const guides = useMemo(() => {
    return (
      (isSwimlaneAll
        ? allGuidesData?.guidesForDiscovery
        : guidesData?.guidesBySanityTagGroup) || []
    )
  }, [allGuidesData, guidesData, isSwimlaneAll])

  const isLoading = guidesLoading || allGuidesLoading || tagGroupLoading

  return {
    tagGroup,
    guides,
    isLoading
  } as UseSwimlaneDataResult
}

export default useSwimlaneData
