import { useEffect } from 'react'

import { usePage } from 'components/PageHeader/usePage'

import { capitalizeFirstLetter } from 'utils/stringUtils'

interface CollectionsTabsProps {
  showTabs: boolean
  currentTab: string
  handleTabChange: (tab: string) => void
}

const useCollectionsTabs = ({
  showTabs,
  currentTab,
  handleTabChange
}: CollectionsTabsProps) => {
  const { setPageTabs } = usePage()

  useEffect(() => {
    const tabs = ['saved_items', 'collections', 'highlights']

    if (showTabs) {
      setPageTabs(
        tabs.map((tab) => ({
          label: capitalizeFirstLetter(tab.replace('_', ' ')),
          onClick: () => handleTabChange(tab),
          isActive: tab === currentTab
        }))
      )
    }

    return () => {
      setPageTabs(null)
    }
  }, [showTabs, setPageTabs, currentTab, handleTabChange])

  return null
}

export default useCollectionsTabs
