import Banner from 'components/Banner'
import Button from 'components/Button'
import ArrowUpRight from 'components/icons/ArrowUpRight'

import { CurrentUserPartsFragment } from 'gql'

const ArchivedBanner = ({
  className,
  currentUser
}: {
  className?: string
  currentUser?: CurrentUserPartsFragment | null
}) => (
  <Banner title="Archived Content" dismissable={false} className={className}>
    <div className="flex flex-row items-center">
      <div className="grow">
        This content has been archived. You will not be able to create new posts, reply,
        follow, or comment.
      </div>
      <Button
        className="h-8 text-[14px] font-semibold leading-5"
        href={currentUser?.joinSlackHref || 'https://reforge-members.slack.com/'}
        shape="rounded"
        target="_blank"
        variant="outline"
        size="x-small"
        iconAfter={<ArrowUpRight className="mr-3 h-3 w-3 fill-current" />}
      >
        View Reforge Slack
      </Button>
    </div>
  </Banner>
)

export default ArchivedBanner
