import { ReactComponent as ArtifactsIcon } from './icons/artifacts.svg'
import { ReactComponent as CoursesIcon } from './icons/courses.svg'
import { ReactComponent as GuidesIcon } from './icons/guides.svg'
import { ReactComponent as ArtificialIntelligenceIcon } from './icons/topic-artificial-intelligence.svg'
import { ReactComponent as CareerDevelopmentIcon } from './icons/topic-career-development.svg'
import { ReactComponent as DataAnalysisIcon } from './icons/topic-data-analysis.svg'
import { ReactComponent as GrowthIcon } from './icons/topic-growth.svg'
import { ReactComponent as HiringIcon } from './icons/topic-hiring.svg'
import { ReactComponent as LeadershipIcon } from './icons/topic-leadership.svg'
import { ReactComponent as MarketingIcon } from './icons/topic-marketing.svg'
import { ReactComponent as ProductDevelopmentIcon } from './icons/topic-product-development.svg'
import { ReactComponent as StrategyIcon } from './icons/topic-strategy.svg'
import { ReactComponent as TeamOperationsIcon } from './icons/topic-team-operations.svg'
import { ReactComponent as UserResearchIcon } from './icons/topic-user-research.svg'

export const NUM_LIST_SUBTOPICS = 3

export const CONTENT_TYPES = [
  {
    title: 'Courses',
    subtitle: 'Gain the expertise to unlock step-change career growth',
    icon: <CoursesIcon width={40} height={40} />,
    destination: '/courses'
  },
  {
    title: 'Guides',
    subtitle: 'Follow quick step-by-step guides created by top experts',
    icon: <GuidesIcon width={40} height={40} />,
    destination: '/guides'
  },
  {
    title: 'Artifacts',
    subtitle: 'Get inspiration from real-world examples & helpful templates',
    icon: <ArtifactsIcon width={40} height={40} />,
    destination: '/artifacts'
  }
]

export const TOPICS = [
  {
    title: 'Product development',
    subtitle: 'Design guidelines, Design review, Release plan',
    icon: <ProductDevelopmentIcon width={132} />,
    slug: 'product-development'
  },
  {
    title: 'Growth',
    subtitle: 'Network effect, Experiment design, User onboarding',
    icon: <GrowthIcon width={132} />,
    slug: 'growth'
  },
  {
    title: 'Artificial intelligence (AI)',
    subtitle: 'Prompt engineering, Conversational AI, Artificial intelligence',
    icon: <ArtificialIntelligenceIcon width={132} />,
    slug: 'ai'
  },
  {
    title: 'Hiring',
    subtitle: 'Canidate evaluation, Reverse interviewing, Job description',
    icon: <HiringIcon width={132} />,
    slug: 'hiring'
  },
  {
    title: 'Leadership',
    subtitle: 'Fundraising playbook, Org chart, Team evaluation',
    icon: <LeadershipIcon width={132} />,
    slug: 'leadership'
  },
  {
    title: 'Career development',
    subtitle: 'Job leveling, Performance review, Career plan',
    icon: <CareerDevelopmentIcon width={132} />,
    slug: 'career-development'
  },
  {
    title: 'Data analysis',
    subtitle: 'Attribution model, Correlation analysis, Tracking plan',
    icon: <DataAnalysisIcon width={132} />,
    slug: 'data-analysis'
  },
  {
    title: 'Marketing',
    subtitle: 'Brand marketing, Business development, Paid acquisition',
    icon: <MarketingIcon width={132} />,
    slug: 'marketing'
  },
  {
    title: 'User research',
    subtitle: 'User friction, Research report, User segmentation',
    icon: <UserResearchIcon width={132} />,
    slug: 'user-research'
  },
  {
    title: 'Team operations',
    subtitle: 'Process diagram, Knowledge base, Expense policy',
    icon: <TeamOperationsIcon width={132} />,
    slug: 'team-operations'
  },
  {
    title: 'Strategy',
    subtitle: 'Monetization, Solution tree, Investment strategy',
    icon: <StrategyIcon width={132} />,
    slug: 'strategy'
  }
]
