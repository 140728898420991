import { useEffect } from 'react'

const scrollIntoElement = (hash: string) => {
  const element = document.getElementById(hash.slice(1))

  element?.scrollIntoView({ behavior: 'smooth' })
}

const setMutationObserver = (hash: string) => {
  const unloadedImages = document.querySelectorAll('img[data-loaded="false"][src]')
  let loadedImages = 0

  if (!unloadedImages || unloadedImages.length === 0) {
    scrollIntoElement(hash)
    return
  }

  const handleDataLoadedMutation = () => {
    if (++loadedImages === unloadedImages.length) {
      scrollIntoElement(hash)
      observer.disconnect()
    }
  }

  const observer = new MutationObserver(handleDataLoadedMutation)

  unloadedImages.forEach((img) =>
    observer.observe(img, { attributes: true, attributeFilter: ['data-loaded'] })
  )
}

export const useScrollToHashAfterDataIsLoaded = (
  dataLoaded: boolean,
  defaultHash?: string
) => {
  useEffect(() => {
    const hash = window.location.hash || defaultHash

    if (!(hash && dataLoaded)) return

    setMutationObserver(hash)
  }, [dataLoaded, defaultHash])
}
