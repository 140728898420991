import { getSelectedNodes, isMatchSelector, isTextN, stringToHtml } from './dom'
import { getNodeMetadata } from './metadata'
import { toRange } from './range'
import { DomNode, HighlightMetadata } from './types'
import {
  isHighlightedNode,
  isNodeEmpty,
  wrapNewNode,
  wrapOverlapNode,
  wrapPartialNode
} from './wrapper'

interface HighlighterOptions {
  classList?: string[]
  tag?: string
  ignoreSelectors?: string[]
}

export class Highlighter {
  private static config: {
    classes: string[]
    tag: string
    ignoreSelectors: string[]
  }

  static init(options?: HighlighterOptions) {
    const defaultClasses = [
      'reforge-bookmark-highlighted',
      'cursor-pointer',
      'bg-rb-teal-50',
      'select-none'
    ]

    Highlighter.config = {
      classes: options?.classList || defaultClasses,
      tag: options?.tag || 'span',
      ignoreSelectors: options?.ignoreSelectors || []
    }
  }

  static highlightLegacy(html: HTMLElement, id: string) {
    const { tag, classes } = Highlighter.config
    const el = document.createElement(tag)
    el.classList.add(...classes)
    el.dataset.highlight = id

    const isExcepted = ($e: HTMLElement) =>
      Highlighter.config.ignoreSelectors?.some((s) => isMatchSelector($e, s))

    html.childNodes.forEach(($node) => {
      const $nodeEl = $node as HTMLElement

      // this is to skip any unwanted elements so we don't parse them
      if (isExcepted($nodeEl)) return

      const wrapper = el.cloneNode() as HTMLElement

      if ($nodeEl.classList.contains('inline-posts__content-block')) {
        /*
         * This element is guaranteed to be a paragraph
         * see commentable_block.rb line: 15 fragment
         */
        const $p = $nodeEl.childNodes[0] as HTMLElement

        // childnodes because it can be nested with a, strong tags
        $p.childNodes.forEach((child) => {
          wrapper.appendChild(child.cloneNode(true))
        })

        $p.replaceChildren(wrapper)
      } else {
        // wrap everything and we'll handle legacy cases as they arrise but i don't anticipate any

        $nodeEl.childNodes.forEach((child) => {
          wrapper.appendChild(child.cloneNode(true))
        })

        $nodeEl.replaceChildren(wrapper)
      }
    })

    return html.outerHTML
  }

  static getMetadata(range: Range, html: HTMLElement) {
    const { endContainer, startContainer } = range
    const start: DomNode = {
      $node: range.startContainer as Text,
      offset: range.startOffset
    }

    const end: DomNode = {
      $node: range.endContainer as Text,
      offset: range.endOffset
    }

    const text = range.toString()

    const startMeta = getNodeMetadata(start.$node as Text, start.offset, html)
    const endMeta = getNodeMetadata(end.$node as Text, end.offset, html)

    const hRange = {
      startMeta,
      endMeta,
      text,
      allTextNodes: isTextN(startContainer) && isTextN(endContainer)
    }

    return hRange
  }

  static highlight(sources: HighlightMetadata[], html: string) {
    const clonedRoot = stringToHtml(html).cloneNode(true) as HTMLElement

    sources.forEach((source) => {
      const range = toRange(clonedRoot, source)

      if (!range) {
        return
      }

      const nodeList = getSelectedNodes(
        clonedRoot,
        range.start,
        range.end,
        Highlighter.config.ignoreSelectors
      )

      let totalChar = source.text.length

      return nodeList.map((currentNode) => {
        const $parentN = currentNode.$node?.parentNode as HTMLElement
        const $prevN = currentNode.$node?.previousSibling
        const $nextN = currentNode.$node?.nextSibling
        const { tag, classes } = Highlighter.config

        const currentChar = currentNode.$node.textContent?.length || 0

        let elNode: HTMLElement

        /*
         * in the event the endNode doesn't match the start node
         * we need to use a character counter to ensure we don't excess highlight
         */

        if (!range.allTextNodes) {
          if (totalChar === 0) {
            // text has been highlighted return
            return
          } else if (totalChar > currentChar || totalChar === currentChar) {
            totalChar -= currentChar
          } else {
            totalChar = 0
          }
        }

        if (!isHighlightedNode($parentN)) {
          elNode = wrapNewNode(currentNode, range, classes, tag)
        } else if (
          isHighlightedNode($parentN) &&
          (!isNodeEmpty($prevN) || !isNodeEmpty($nextN))
        ) {
          /*
           * text node, in a highlight wrap in the event we try to overlap over an existing highlight
           * this shouldn't happen because we disable highlighting over existing highlights
           */

          elNode = wrapPartialNode(currentNode, range, classes, tag)
        } else {
          // edge case: completely overlap a highlight
          elNode = wrapOverlapNode(currentNode, range, classes)
        }

        return elNode
      })
    })

    return clonedRoot.innerHTML
  }
}
