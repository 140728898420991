import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { NoResults } from 'domains/Post/NoResults'
import { TruncatedPostCard } from 'domains/Post/PostContent'

import { Loading, ResponseCount } from 'components'
import Paginator from 'components/Paginator'
import ReactionSummary from 'components/ReactionSummary'
import SkeletonPost from 'components/skeletons/SkeletonPost/SkeletonPost'

import { PostSource, usePostListQuery } from 'gql'

import notifyError from 'utils/errorNotifier'
import { track } from 'utils/tracking/segment'

const allReactions = (post: any) => {
  return post.reactions
    .concat(
      post.comments
        .map((comment: any) => {
          return comment.reactions
        })
        .concat(
          post.replies.map((reply: any) => {
            return reply.reactions
          })
        )
    )
    .flat(1)
}

const PostContentSkeletonLoader = () => (
  <>
    {[...Array(5)].map((_, index) => (
      <div className={index > 0 ? 'uk-margin-medium-top' : ''} key={index}>
        <SkeletonPost />
      </div>
    ))}
  </>
)

const getPostTypeArray = (postType: string | string[] | undefined) => {
  if (Array.isArray(postType)) {
    return postType
  } else if (typeof postType === 'string') {
    return postType === '' ? [] : [postType]
  } else {
    return []
  }
}
// TODO: quick refactor to camelcase can be dangerous - needs to be deeply checked
/* eslint-disable camelcase */
interface PostFilters {
  audience?: string[]
  business_model?: string[]
  contributors?: string[]
  filter?: string
  industries?: string[]
  intersecting_groups?: string[]
  page?: string
  post_type?: string | string[]
  sort_by?: string
  topics?: string[]
}
/* eslint-enable camelcase */

export interface IPostList {
  filters: PostFilters
  group?: string
  postSource: PostSource
  setBusy: React.Dispatch<React.SetStateAction<boolean>>
  setFilters: React.Dispatch<React.SetStateAction<{}>>
  showAddPostModal?: boolean
  source?: string
  topic?: string
}

const PostList = (props: IPostList) => {
  const { filters, group, topic, postSource, setBusy, setFilters } = props

  const [isRefetching, setIsRefetching] = useState(false)
  const { hash } = useLocation()

  const baseVariables = useMemo(
    () => ({
      audience: filters.audience || [],
      businessModels: filters.business_model || [],
      contributors: filters.contributors || [],
      industries: filters.industries || [],
      intersectingGroups: filters.intersecting_groups || [],
      filter: filters.filter || '',
      group: group || '',
      page: +(filters.page || 1),
      postSource: postSource,
      postType: getPostTypeArray(filters.post_type),
      sortBy: filters.sort_by || '',
      topics: topic ? [topic] : filters.topics || []
    }),
    [filters, group, postSource, topic]
  )
  const { data, error, loading, refetch } = usePostListQuery({ variables: baseVariables })
  const posts = data?.discussionPosts?.posts || []
  const unreadPostIds = data?.discussionPosts?.unreadPostIds || []
  const meta = data?.discussionPosts?.pagination || { currentPage: 1, totalPages: 1 }

  useEffect(() => {
    setBusy(true)

    const fromPostsBack = window.location.search.indexOf('backButton=true') > -1
    if (fromPostsBack) {
      setTimeout(() => {
        document
          .getElementById('page')
          ?.scrollTo(0, window.localStorage.previousScrollLocation)
      }, 200)
    } else {
      document.getElementById('page')?.scrollTo(0, 0)
    }
  }, [setBusy])

  useEffect(() => {
    if (!data?.discussionPosts) return

    setBusy(false)

    const queryParams = Object.keys(baseVariables).reduce(
      (acc: { [key: string]: string }, curKey: keyof typeof baseVariables) => {
        const curVal = baseVariables[curKey]
        Array.isArray(curVal)
          ? (acc[curKey] = curVal.join(','))
          : (acc[curKey] = curVal.toString())
        return acc
      },
      {}
    )

    // @ts-ignore - 'Post Pagination' event is not defined in Segment JIRA#REF-5159
    track('Post Pagination', {
      query: new URLSearchParams(queryParams).toString()
    })
  }, [data, baseVariables, setBusy])

  useEffect(() => {
    if (error) {
      notifyError(error)
    }
  }, [error])

  useEffect(() => {
    const refetchPosts = async () => {
      setIsRefetching(true)
      await refetch()
      setIsRefetching(false)

      const url = window.location.pathname + window.location.search
      window.history.replaceState({}, document.title, url)
    }

    if (hash === '#reload') refetchPosts()
  }, [hash, refetch])

  const handlePagination = (page: number) => {
    setFilters((prevFilters) => ({ ...prevFilters, page }))
  }

  const resetPostTypeFilter = () => {
    setFilters((prevFilters) => ({ ...prevFilters, post_type: [] }))
  }

  return (
    <div className="uk-position-relative">
      <div id="welcome-placeholder"></div>
      {posts.length === 0 && (
        <React.Fragment>
          {(loading || isRefetching) && <PostContentSkeletonLoader />}
          {!loading && (
            <NoResults
              filters={baseVariables.postType}
              resetFilters={resetPostTypeFilter}
            />
          )}
        </React.Fragment>
      )}
      {posts.length > 0 &&
        (loading || isRefetching ? (
          <Loading />
        ) : (
          <React.Fragment>
            <div className="uk-comment-list">
              {posts.map((post, index) => {
                const allPostReactions = allReactions(post)

                return (
                  <div
                    className={index > 0 ? 'uk-margin-medium-top' : ''}
                    key={`post${post.id}`}
                  >
                    <TruncatedPostCard
                      post={post}
                      isUnread={unreadPostIds.includes(post.id)}
                      source={props.source}
                      group={props.group}
                    >
                      <div
                        className="uk-margin-small-top uk-margin-bottom flex items-center"
                        data-test="truncated-post-card"
                      >
                        <div className="inline-flex flex-auto items-center justify-start">
                          <ResponseCount
                            count={post.responseCount}
                            replies={[...post.replies, ...post.comments]}
                            showWord={true}
                          />
                          {allPostReactions.length > 0 && (
                            <ReactionSummary reactions={allPostReactions} />
                          )}
                        </div>
                      </div>
                    </TruncatedPostCard>
                  </div>
                )
              })}
            </div>
            <div className="uk-text-center">
              <div className="my-8">
                <Paginator meta={meta} handlePagination={handlePagination} />
              </div>
            </div>
          </React.Fragment>
        ))}
    </div>
  )
}

export default PostList
