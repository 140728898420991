import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { Link, useLocation } from 'react-router-dom'

import ConfettiAnimationWithLoader from 'domains/Cms/ModalCelebration/components/ConfettiAnimationWithLoader'

import Button from 'components/Button'
import RfParagraphLargeBold from 'components/typography/RfParagraph/RfParagraphLargeBold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

const CourseEnrollConfirmationPage = () => {
  const history = useHistory()
  const queryParams = new URLSearchParams(useLocation().search)
  const redirectPath = queryParams.get('redirectPath') || ''

  useEffect(() => {
    if (redirectPath) {
      setTimeout(() => {
        window.location.href = redirectPath
      }, 3000)
    }
  }, [redirectPath, history])

  return (
    <div className="flex min-h-[100vh] w-full flex-col items-center justify-center bg-apply bg-cover bg-no-repeat">
      <ConfettiAnimationWithLoader className="h-20 w-36" percentComplete={100} />
      <RfParagraphLargeBold>You&apos;re enrolled!</RfParagraphLargeBold>
      <RfParagraphSmall>
        {redirectPath
          ? "You'll be redirected to your course in a few seconds..."
          : "We'll share more details via email closer to your course start date."}
      </RfParagraphSmall>
      {redirectPath ? (
        <RfParagraphSmall>
          Click <Link to={redirectPath}>here</Link> if you&apos;re not redirected
        </RfParagraphSmall>
      ) : (
        <Button href="/" size="medium" className="mt-4">
          Return to Your Reforge Dashboard
        </Button>
      )}
    </div>
  )
}

export default CourseEnrollConfirmationPage
