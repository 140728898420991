import clsx from 'clsx'
import { useRef, useState } from 'react'

import { SVGIcon } from 'components/Icon'

import { UserSearchFilters } from 'gql'

import useOnClickOutside from 'hooks/useOnClickOutside'

import { onSpaceKeyPress } from 'utils/keyboard'

const ChevronUp = <SVGIcon name="thin-chevron-up" height="7" width="12" fill="#0f0f0f" />
const ChevronDown = (
  <SVGIcon name="thin-chevron-down" height="7" width="12" fill="#0f0f0f" />
)

type SimpleOption = {
  id: string
  label: string
}

export type OptionWithCategories = {
  category: string
  items: OptionCategoryItem[]
}

type OptionCategoryItem = {
  id: string
  name: string
}

function isOptionWithCategories(
  option: SimpleOption | OptionWithCategories
): option is OptionWithCategories {
  return (option as OptionWithCategories).category !== undefined
}

interface SelectFilterProps {
  filters: UserSearchFilters
  label: string
  name: keyof UserSearchFilters
  options: Array<SimpleOption | OptionWithCategories>
  selection?: string | null
  setFilters: (object: UserSearchFilters) => void
  dataId?: string
}

const SelectFilter = ({
  filters,
  label,
  name,
  options,
  selection,
  setFilters,
  dataId
}: SelectFilterProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleClick = (id: string) => {
    setFilters({
      ...filters,
      [name]: id
    })
    setIsExpanded(false)
  }

  return (
    <>
      <div data-test={dataId} className="mb-3 rounded-sm">
        <div className="mb-[9px] flex items-center justify-between">
          <div className="mb-0 block text-sm font-medium leading-[14px]">{label}</div>
          {filters[name] && (
            <div
              className="cursor-pointer px-[7px] text-sm text-rb-gray-300 hover:text-rb-teal-200"
              onClick={() => setFilters({ ...filters, [name]: '' })}
              onKeyUp={onSpaceKeyPress(() => setFilters({ ...filters, [name]: '' }))}
              role="button"
              tabIndex={0}
            >
              Reset
            </div>
          )}
        </div>
        <div
          className={clsx(
            'flex h-[46px] items-center justify-between bg-rb-gray-50 px-[11px] text-sm italic text-rb-gray-300',
            isExpanded && 'border-[1.25px] border-rb-gray-100',
            selection && 'not-italic text-rb-gray-500'
          )}
          onClick={() => setIsExpanded(!isExpanded)}
          onMouseDown={(e) => e.stopPropagation()}
          onTouchStart={(e) => e.stopPropagation()}
          onKeyUp={onSpaceKeyPress(() => setIsExpanded(!isExpanded))}
          role="listbox"
          tabIndex={0}
        >
          {selection || 'Select one'}
          {isExpanded ? ChevronUp : ChevronDown}
        </div>
        {isExpanded && (
          <Options
            selection={selection || ''}
            handleClick={handleClick}
            options={options}
            setIsExpanded={setIsExpanded}
          />
        )}
      </div>
    </>
  )
}

interface OptionsProps {
  selection: string
  handleClick: (value: string) => void
  options: Array<SimpleOption | OptionWithCategories>
  setIsExpanded: (value: boolean) => void
}

const Options = ({ selection, handleClick, options, setIsExpanded }: OptionsProps) => {
  const ref = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(ref, () => setIsExpanded(false))

  return (
    <div
      ref={ref}
      className="mb-5 border-x-2 border-[rgba(96,96,96,0.1)] bg-white pt-2.5 pb-[11px] shadow-headerTab"
    >
      {options.map((option, idx) => (
        <div key={idx}>
          {isOptionWithCategories(option) ? (
            <>
              <div className="cursor-default p-[11px] text-xs font-semibold uppercase not-italic leading-[14px] tracking-widest">
                {option.category}
              </div>
              {option.items.map((item: OptionCategoryItem) => (
                <div
                  key={item.id}
                  className="my-auto mx-[5px] h-[30px] cursor-pointer rounded-sm pl-1.5 text-sm leading-[29px] hover:bg-rb-gray-50"
                  onClick={() => handleClick(item.id)}
                  onKeyUp={onSpaceKeyPress(() => handleClick(item.id))}
                  role="option"
                  aria-selected={selection === item.name}
                  tabIndex={0}
                >
                  {item.name}
                </div>
              ))}
            </>
          ) : (
            <div
              key={option.id}
              className="my-auto mx-[5px] h-[30px] cursor-pointer rounded-sm pl-1.5 text-sm leading-[29px] hover:bg-rb-gray-50 "
              onClick={() => handleClick(option.id)}
              onKeyUp={onSpaceKeyPress(() => handleClick(option.id))}
              role="option"
              aria-selected={selection === option.label}
              tabIndex={0}
            >
              {option.label}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default SelectFilter
