import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { ChevronDownThin } from 'components/icons'

import { ComplexPortableText } from '../ComplexPortableText/ComplexPortableText'

interface QuestionAndAnswerSectionProps {
  data: {
    heading: string
    items: {
      id: string
      question: string
      answer: unknown[] // block content
      customId?: string
    }[]
  }
}

export const QuestionAndAnswerSection = ({ data }: QuestionAndAnswerSectionProps) => {
  const [activeAccordion, setActiveAccordion] = useState<string | null>(null)

  const onToggle = (id: string, status: boolean) => {
    setActiveAccordion(status ? id : null)
  }

  useEffect(() => {
    let hash = window.location.hash
    if (hash) {
      hash = hash.replace('#', '')
      const el = document.querySelector<HTMLDivElement>(`[data-custom-id="${hash}"]`)
      if (el) {
        onToggle(el.id, true)

        setTimeout(() => {
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        }, 100)
      }
    }
  }, [])

  return (
    <div className="first:-mt-[100px]">
      <h3 className="mt-[100px] mb-[24px] font-normal fluid-text-3xl">{data?.heading}</h3>

      {data?.items.map(({ id, answer, question, customId }) => (
        <div
          key={id}
          id={id}
          className="w-full border-t border-black last:border-b"
          data-custom-id={customId}
        >
          <button
            onClick={() => onToggle(id, !(activeAccordion === id))}
            aria-expanded={activeAccordion === id}
            aria-controls={`accordion-${id}`}
            className="flex w-full items-center justify-between bg-transparent py-8 px-0 text-left font-serif font-light fluid-text-xl"
          >
            {question}
            <div
              className={twMerge(
                'relative ml-2 block h-3 w-3 flex-shrink-0',
                activeAccordion === id && 'rotate-180'
              )}
            >
              <ChevronDownThin />
            </div>
          </button>
          <div
            className={`m-0 h-0 max-w-2xl overflow-hidden opacity-0 transition-all duration-500 ease-in-out sm:max-w-[70%]
              ${activeAccordion === id && 'h-max opacity-100'}`}
          >
            <div className="block-content pr-8 pb-4">
              <ComplexPortableText content={answer} />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default QuestionAndAnswerSection
