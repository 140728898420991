import React, { useState } from 'react'
import UIkit from 'uikit'

import Button from 'components/Button'
import ThankYouModal from 'components/ThankYouModal'
import RfHeader1 from 'components/typography/RfHeader/RfHeader1'

import { COMMITMENT_MODAL_ID, THANK_YOU_MODAL_ID } from 'constants/elementIds'

import { hideModal, showModal } from 'utils/modal'
import { sendData } from 'utils/sendData'

import { SignCommitmentPayload } from 'typings/payloads'

interface ICommitmentModal {
  userCohortId: string
  setShowCommitmentCard?: (a: boolean) => void
}

type CheckedItem = {
  [key: string]: boolean
}

export default function CommitmentModal({
  userCohortId,
  setShowCommitmentCard = () => {}
}: ICommitmentModal) {
  const [error, setError] = useState<unknown>(null)
  const [signature, setSignature] = useState('')
  const [checkedItems, setCheckedItems] = useState<CheckedItem>({})

  const heading = 'Statement of Commitment'
  const subheading =
    "Please check each item below to indicate how you'll engage with the content & community."
  const item1 = {
    id: 1,
    text: 'I will actively participate by contributing my knowledge, ideas, and questions throughout the Course.'
  }

  const item2 = {
    id: 2,
    text: 'I will treat anything shared in this community (discussions, events, etc.) by its members, hosts, and guests, as private and confidential.'
  }
  const item3 = {
    id: 3,
    text: 'I have blocked off a minimum of 3-5 hours each week: 90 minutes for live events, and additional time to review the material and engage asynchronously.'
  }
  const item4 = {
    id: 4,
    text: 'I will attend all events in the program.'
  }

  const checkableItems = [item1, item2, item3, item4]
  const signatureHeading = 'YOUR SIGNATURE'
  const signatureBoxPlaceholder = 'Type your first and last name'

  const handleCheck = (e: React.MouseEvent<HTMLInputElement>) => {
    setCheckedItems({ ...checkedItems, [e.currentTarget.name]: e.currentTarget.checked })
  }

  const allBoxesChecked = () => {
    if (JSON.stringify(checkedItems) === '{}') {
      return false
    }
    const keys = Object.keys(checkedItems)
    const sameLength = keys.length === checkableItems.length
    const allChecked = keys.every((key) => checkedItems[key] === true)
    return sameLength && allChecked
  }

  const canSubmit = allBoxesChecked() && signature !== ''

  const handleSubmit = (userCohortId: string) => {
    const data: SignCommitmentPayload = { has_signed_statement_of_commitment: true }
    sendData(
      `/api/v1/user_cohorts/${userCohortId}`,
      'PATCH',
      data,
      (errorMessage: { errors: unknown }) => {
        if (errorMessage) {
          setError(errorMessage.errors)
          UIkit.notification(`Errors: ${error}`, { status: 'danger', pos: 'top-right' })
        } else {
          setShowCommitmentCard(false)
          hideModal(COMMITMENT_MODAL_ID)
          showModal(THANK_YOU_MODAL_ID)
          hideModal(THANK_YOU_MODAL_ID, 4000)

          // Reload the page to fetch latest data.
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        }
      }
    )
  }

  return (
    <>
      <ThankYouModal />
      <div id={COMMITMENT_MODAL_ID} uk-modal={'{"container": false}'}>
        <div className="uk-modal-dialog uk-modal-body h-auto w-full py-[25px] px-[20px] sm:w-[620px] tl:w-[825px] tl:p-[45px]">
          <RfHeader1>
            <span data-test="commitment-modal-heading">{heading}</span>
          </RfHeader1>
          <div
            className="my-2.5 text-sm sm:text-[16px] tl:mb-[37px]"
            data-test="commitment-modal-subheading"
          >
            {subheading}
          </div>
          {checkableItems.map((item) => (
            <div key={item.id} className="my-[7px] flex sm:my-[15px]">
              <input
                type="checkbox"
                name={`item-${item.id}`}
                id={`item-${item.id}`}
                data-test={`commitment-modal-item-${item.id}`}
                className="uk-checkbox uk-align-left mt-1 mr-2.5 h-5 w-5"
                onClick={handleCheck}
              />
              <label
                htmlFor={`item-${item.id}`}
                className="mb-2.5 w-11/12 text-sm sm:text-base"
              >
                {item.text}
              </label>
            </div>
          ))}
          <div className=" text-xs font-medium tracking-[1px] sm:mt-[30px]">
            {signatureHeading}
          </div>
          <input
            type="text"
            className="uk-input"
            name="commitment-modal-name"
            autoComplete="off"
            placeholder={signatureBoxPlaceholder}
            data-test="commitment-modal-name"
            value={signature}
            onChange={(e) => setSignature(e.target.value)}
          />
          <Button
            className="float-right mt-[68px] sm:mt-[45px]"
            disabled={!canSubmit}
            onClick={() => handleSubmit(userCohortId)}
            dataTest="commitment-modal-submit-button"
          >
            SUBMIT
          </Button>
        </div>
      </div>
    </>
  )
}
