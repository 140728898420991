import { SVGIcon } from 'components/Icon'

import { ProgramBookmarkPartsFragment } from 'gql'

interface ExternalUrlCardProps {
  bookmark: ProgramBookmarkPartsFragment
}

const ExternalUrlCard = ({ bookmark }: ExternalUrlCardProps) => {
  return (
    <button
      type="button"
      className="flex w-full flex-col flex-wrap items-start rounded-md border border-rb-gray-250 p-6 hover:shadow-content-card"
      onClick={() => {
        bookmark.externalUrl && window.open(bookmark.externalUrl, '_blank')
      }}
    >
      <div className="mb-2 flex h-[18px] w-full items-center text-xs">
        <SVGIcon name="external-url" />
        <div className="justify-center pl-2 text-xs font-medium">Link</div>
        <div className="ml-auto mr-2 flex-shrink-0 cursor-default pl-4 text-rb-gray-300">
          {bookmark.updated} ago
        </div>
      </div>
      {bookmark.title && <div className="mb-2 text-xl font-medium">{bookmark.title}</div>}
      {bookmark.externalUrl && (
        <a href={bookmark.externalUrl} target="_blank" rel="noreferrer">
          {bookmark.externalUrl}
        </a>
      )}
    </button>
  )
}

export default ExternalUrlCard
