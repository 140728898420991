import { useEffect } from 'react'

import { Tag } from 'components/Tag'

import { PaymentMethodFragment, StripePaymentMethod } from 'gql'

import { onEnterKeyPress } from 'utils/keyboard'

import { ReactComponent as CreditCardIcon } from 'images/icon--credit-card.svg'
import { ReactComponent as RadioIcon } from 'images/icon--radio.svg'

import { PaymentMethodsProps } from '../PaymentMethods'

interface StoredPaymentMethodsProps {
  paymentMethods: StripePaymentMethod[]
  paymentMethodsLoading: boolean
  selectedPaymentMethodId?: string | null
  setSelectedPaymentMethodId: (id: string) => void
  className?: string
}

const StoredPaymentMethods = ({
  paymentMethods,
  paymentMethodsLoading,
  selectedPaymentMethodId,
  setSelectedPaymentMethodId,
  className
}: StoredPaymentMethodsProps) => {
  useSelectDefaultPaymentMethod({
    paymentMethods,
    selectedPaymentMethodId,
    setSelectedPaymentMethodId,
    paymentMethodsLoading
  })

  return (
    <div className={className}>
      <h3 className="mt-4 text-m-small font-medium sm:text-m-medium ">
        Stored Payment Methods
      </h3>
      {paymentMethods.map((paymentMethod) => (
        <div
          key={`paymentMethod_${paymentMethod.id}`}
          className={`bg-card-primary-background border-card-primary-background mb-2.5 flex h-9 cursor-pointer items-center border border-solid py-0 px-3  ${
            paymentMethod.id === selectedPaymentMethodId
              ? 'bg-card-primary-background border-blue mb-2.5 h-9 border border-solid py-0 px-3'
              : ''
          }`}
          role="button"
          data-test="stored-payment-method"
          tabIndex={0}
          onClick={() => {
            setSelectedPaymentMethodId(paymentMethod.id)
          }}
          onKeyUp={onEnterKeyPress(() => {
            setSelectedPaymentMethodId(paymentMethod.id)
          })}
        >
          <RadioIcon
            className={`mr-2 w-4 ${
              paymentMethod.id === selectedPaymentMethodId
                ? 'fill-rb-gray-500'
                : 'fill-white'
            }`}
          />
          <CreditCardIcon className="mr-1 w-4 text-rb-black" />
          <div className="flex-1 text-m-small sm:text-m-medium ">
            {paymentMethod?.card?.brand.charAt(0).toUpperCase()}
            {paymentMethod?.card?.brand.slice(1)} ending in {paymentMethod?.card?.last4} •{' '}
            {paymentMethod?.card?.expMonth}/{paymentMethod?.card?.expYear}
          </div>
          {paymentMethod.defaultPaymentMethod && <Tag text="Primary" variant="gray" />}
        </div>
      ))}
    </div>
  )
}

const useSelectDefaultPaymentMethod = ({
  paymentMethodsLoading,
  paymentMethods,
  selectedPaymentMethodId,
  setSelectedPaymentMethodId
}: {
  paymentMethodsLoading: boolean
  paymentMethods: PaymentMethodFragment[]
  selectedPaymentMethodId?: PaymentMethodsProps['selectedPaymentMethodId']
  setSelectedPaymentMethodId: PaymentMethodsProps['setSelectedPaymentMethodId']
}) => {
  useEffect(() => {
    if (!paymentMethodsLoading && paymentMethods.length > 0 && !selectedPaymentMethodId) {
      const defaultPaymentMethod = paymentMethods.find(
        (paymentMethod) => paymentMethod.defaultPaymentMethod
      )
      if (defaultPaymentMethod && setSelectedPaymentMethodId) {
        setSelectedPaymentMethodId(defaultPaymentMethod.id)
      }
    }
  }, [
    paymentMethodsLoading,
    paymentMethods,
    selectedPaymentMethodId,
    setSelectedPaymentMethodId
  ])
}

export default StoredPaymentMethods
