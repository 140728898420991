import { datadogRum } from '@datadog/browser-rum'
import { ReactComponent as DiscussionIcon } from 'icon--discussion.svg'
import { ReactComponent as TrashIcon } from 'icon--trash-white.svg'
import { RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import useDiscussions from 'domains/Cms/hooks/useDiscussions'
import { useAddPostModal } from 'domains/Post/AddPostModal'

import { Loading } from 'components'
import { useProductTour } from 'components/ProductTour'
import { useSideDrawer } from 'components/SideDrawer'
import { displayToast } from 'components/Toast'
import ToastCard, { toastOptions } from 'components/ToastCard'
import HighlightIcon from 'components/icons/HighlightIcon'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import {
  BookmarkFragment,
  BookmarkType,
  CmsSection,
  CreateBookmarkInput,
  InlinePost,
  ProductTourKey,
  ProgramBookmarkPartsFragment,
  UserBookmarksFeedDocument,
  UserBookmarksFeedQuery,
  useCreateBookmarkMutation,
  useDeleteBookmarkMutation,
  useInlinePostsQuery,
  useUserBookmarksFeedQuery
} from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import notifyError from 'utils/errorNotifier'
import { lightboxImages } from 'utils/lightbox'
import { trackModalDisplayed } from 'utils/tracking/generated/events'

import { highlightingState } from 'stores'

import { Highlighter } from './highlighter'
import { HighlightMetadata } from './highlighter/types'
import { convertProjectId, findNearestId, normalize, sanitizeAnchor } from './utils'

const rightDrawerId = 'discoveryDrawer'
interface BookmarkHighlighterProps {
  originalHtml: string
  cmsSection: CmsSection
  setActiveDiscoveryDrawerPanel?: (type: string) => void
  trackActionButtonClick?: (type: 'discussions' | 'bookmarks') => void
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
}

const SpinnerIcon = () => (
  <svg
    className="h-4 w-4 animate-spin text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
)

const useScrollToHashElement = (
  loading: boolean,
  discussionsLoading: boolean,
  userBookmarksFeed: UserBookmarksFeedQuery | undefined,
  inlinePosts: InlinePost[] | undefined,
  openDrawer: (panel: string) => void
) => {
  const isMobileScreenSize = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
  useEffect(() => {
    if (!location.hash || loading || discussionsLoading) return

    let panel = 'discussions'

    const originalHash = location.hash.substring(1)
    let hash = originalHash
    if (hash.endsWith('-panel-bookmarks')) {
      hash = hash.replace('-panel-bookmarks', '')
      panel = 'bookmarks'
    }

    // Handle a special case where the hash does not reference a block but indicates that
    // a panel should be opened. Never open drawer on mobile
    if (
      !isMobileScreenSize &&
      (originalHash === '-panel-comments' || originalHash === '-panel-bookmarks')
    ) {
      openDrawer(panel)
      return
    }

    const elementToView = document.getElementById(hash)

    if (!elementToView) return

    const rect = elementToView?.getBoundingClientRect()

    document.getElementById('page')?.scrollTo({
      top: window.scrollY + rect.top - 150
    })

    // Highlight the text
    try {
      const range = document.createRange()
      range.selectNodeContents(elementToView as Node)
      window.getSelection()?.addRange(range)
    } catch (e) {
      // do nothing
    }

    // Never open drawer on mobile, do not open drawer if anchor does not match a bookmark or post (e.g. from search)
    if (!isMobileScreenSize) {
      const foundAnchor =
        panel === 'discussions'
          ? (inlinePosts ?? []).some((p: any) => p.anchor === hash)
          : (userBookmarksFeed?.user?.bookmarksFeed?.bookmarks ?? []).some(
              (b: any) => b.anchor === hash
            )
      if (foundAnchor) openDrawer(panel)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, discussionsLoading])
}

export const BookmarkHighlighter = ({
  originalHtml,
  cmsSection,
  setActiveDiscoveryDrawerPanel,
  trackActionButtonClick,
  openAddToBookmarkFolderModal
}: BookmarkHighlighterProps) => {
  const originalHtmlNormalized = normalize(originalHtml)

  const tooltipRef: RefObject<HTMLDivElement> = useRef(null)
  const containerRef: RefObject<HTMLDivElement> = useRef(null)
  const cmsRef: RefObject<HTMLDivElement> = useRef(null)
  const [event, setEvent] = useState<MouseEvent>()
  const [selectedText, setSelectedText] = useState<string | undefined>()
  const [selectedImg, setSelectedImg] = useState<string | undefined>()
  const [hoveringBookmarkId, setHoveringBookmarkId] = useState<string | undefined>()

  const { openDrawer, setActiveBookmarkId, unsetActiveBookmarkId } = useSideDrawer()

  const [isManaging, setIsManaging] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const [hideCarrot, setHideCarrot] = useState(false)
  const [highlightedHtml, setHighlightedHtml] = useState(originalHtmlNormalized)
  const [anchor, setAnchor] = useState<string | undefined | null>()
  const [userBookmarks, setUserBookmarks] = useState<BookmarkFragment[]>([])
  const [elPosition, setElPosition] = useState<any>()
  const [bookmarkQueryCompleted, setBookmarkQueryCompleted] = useState(false)
  const [hovering, setHovering] = useState(false)
  const [highlightMetadata, setHighlightMetadata] = useState<HighlightMetadata | null>()
  const [contentState, setContentState] = useState<
    null | 'content-enhanced' | 'content-selected'
  >(null)

  const { setModalData, showAddPostModal } = useAddPostModal()
  const currentUser = useAssertCurrentUser()
  const history = useHistory()

  const metadata = {
    cmsModuleId: cmsSection.cmsModuleId,
    cmsProgramId: cmsSection.cmsProgramId,
    cmsSectionId: cmsSection.id
  }

  const { refetch: refetchPosts } = useInlinePostsQuery({
    variables: {
      cmsSectionId: cmsSection.id,
      userId: currentUser.id
    }
  })

  const { data: userBookmarksFeed, loading } = useUserBookmarksFeedQuery({
    variables: {
      userId: currentUser!.id,
      filters: {
        ...metadata
      }
    }
  })
  const [createBookmark, { loading: isCreatingBookmark }] = useCreateBookmarkMutation()
  const [deleteBookmark, { loading: isDeletingBookmark }] = useDeleteBookmarkMutation()

  const { loading: discussionsLoading, inlinePosts } = useDiscussions({
    cmsSectionId: cmsSection.id,
    cmsModuleId: cmsSection.cmsModuleId,
    cmsProgramId: cmsSection.cmsProgramId,
    userId: currentUser.id
  })

  useScrollToHashElement(
    loading,
    discussionsLoading,
    userBookmarksFeed,
    inlinePosts as InlinePost[],
    (panel = 'discussions') => {
      openDrawer(rightDrawerId)
      setActiveDiscoveryDrawerPanel?.(panel)
    }
  )

  const eventCallback = useCallback((e: MouseEvent) => {
    setEvent(e)
  }, [])

  const {
    portal,
    isCompleted: isBookmarkTourCompleted,
    completeTour: completeBookmarkTour
  } = useProductTour({
    productTourKey: ProductTourKey.BOOKMARK_CREATE,
    nodeRefOrSelector: 'span.tour',
    disabled: contentState !== 'content-selected',
    title: '🔖  Create your first highlight',
    description: 'You can create a highlight by highlighting text or clicking an image.',
    wait: 1 // Set this to a small value so we use the effect and keep it sync'd with the selection creation
  })

  useEffect(() => {
    if (cmsRef.current && highlightedHtml && contentState === 'content-enhanced') {
      const firstParagraph = cmsRef.current.querySelector('span.tour')
      const range = document.createRange()

      if (!firstParagraph) return

      const timeout = window.setTimeout(() => {
        range.selectNodeContents(firstParagraph as Node)
        window.getSelection()?.addRange(range)

        handleTextSelection(true)
        setContentState('content-selected')
      }, 2500)
      return () => {
        window.clearTimeout(timeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentState, highlightedHtml])

  useEffect(() => {
    const bookmarks = userBookmarksFeed?.user?.bookmarksFeed?.bookmarks
    if (!loading && bookmarkQueryCompleted === false) {
      if (bookmarks?.length) {
        setUserBookmarks(bookmarks)
      }
      setBookmarkQueryCompleted(true)
    } else if (bookmarkQueryCompleted === true) {
      setUserBookmarks(bookmarks || [])
    }
  }, [userBookmarksFeed, bookmarkQueryCompleted, loading])

  const hideTooltip = () => {
    setShowTooltip(false)
    setElPosition(undefined)
    setIsManaging(false)
    setHideCarrot(false)
    setSelectedText(undefined)
    setSelectedImg(undefined)
    setAnchor(undefined)
    setHoveringBookmarkId(undefined)
    setHighlightMetadata(undefined)
  }

  const resetTooltipContainer = () => {
    const container = document.querySelector('#highlight-tooltip-container')
    const wrapper = containerRef.current!

    const articleContainer = document.querySelector('#cms-section-desc-container')
    const tooltip = tooltipRef.current!

    if (container) {
      const fragment = document.createDocumentFragment()
      while (container.firstChild) {
        fragment.appendChild(container.firstChild)
      }
      container.replaceWith(fragment)
    }

    // makes sure tooltip always starts within the relative container
    wrapper?.insertBefore(tooltip, articleContainer)

    setShowTooltip(false)
  }

  const handleTextSelection = (onProductTour = false) => {
    const textSelected = !!window.getSelection()?.toString()
    const mainContainer = document.getElementById('cms-section-desc-container')

    if (window.getSelection()?.isCollapsed) {
      setShowTooltip(false)
      setSelectedText(undefined)
    }
    if (textSelected) {
      setHoveringBookmarkId(undefined)
      setSelectedImg(undefined)
      setHideCarrot(false)
      setIsManaging(false)

      const selection = window.getSelection()

      const existInContainer =
        mainContainer!.contains(selection!.anchorNode) &&
        mainContainer!.contains(selection!.focusNode)

      // validate highlighted text exists in the main container
      if (mainContainer && !existInContainer) {
        return
      }

      const range = selection!.getRangeAt(0)
      setHighlightMetadata(Highlighter.getMetadata(range, mainContainer!))

      const trimedText = selection!.toString().trim()

      // if text contains new lines means more than one paragraph was highlighted
      if (trimedText.length <= 2 || trimedText === selectedText) {
        return
      }

      if (!onProductTour) datadogRum.addAction('Select bookmarkable text')

      resetTooltipContainer()

      setSelectedText(trimedText)

      const containerId = findNearestId(selection)
      setAnchor(containerId)

      const textPosition = selection!.getRangeAt(0).getBoundingClientRect()
      const containerRect = containerRef.current!.getBoundingClientRect()

      const top = textPosition.top - containerRect.top - 40 // 40 is the height of the toolbar
      const left = textPosition.left - containerRect.left + textPosition.width / 2 - 40

      setElPosition({ top, left })
      setShowTooltip(true)
    }
  }

  // THIS useEffect can be removed if we ever get rid of the project content type
  // that uses hidden phase divs
  useEffect(() => {
    const phaseElement = document.getElementById('phase-0')
    if (phaseElement) {
      phaseElement.classList.remove('uk-hidden')
      const phases = document.querySelectorAll('.phase')
      const relatedContentDiv = document.getElementById('js-related-content')
      const feedbackDiv = document.getElementById('js-content-feedback')
      const summaryDiv = document.getElementById('js-content-summary')
      if (phases[phases.length - 1].id === phaseElement.id) {
        if (relatedContentDiv) {
          relatedContentDiv.classList.remove('uk-hidden')
        }
        if (feedbackDiv) {
          feedbackDiv.classList.remove('uk-hidden')
        }
        if (summaryDiv) {
          summaryDiv.classList.remove('uk-hidden')
        }
      } else {
        if (relatedContentDiv) {
          relatedContentDiv.classList.add('uk-hidden')
        }
        if (feedbackDiv) {
          feedbackDiv.classList.add('uk-hidden')
        }
        if (summaryDiv) {
          summaryDiv.classList.add('uk-hidden')
        }
      }
    }
  }, [highlightedHtml])

  useEffect(() => {
    const displayContainer = (target: HTMLElement) => {
      resetTooltipContainer()
      const tooltip: HTMLDivElement = tooltipRef.current!
      const el = document.createElement('div')
      el.id = 'highlight-tooltip-container'
      el.className = 'relative'
      // Check added for Brave browser compatibility to prevent fatal error
      if (!target.parentElement) {
        console.error('Bookmark highlighting not available in this browser.')
        return
      }
      target.parentElement!.insertBefore(el, target)
      el.appendChild(target)
      el.appendChild(tooltip)

      setShowTooltip(true)
    }

    const manageBookmark = (e: MouseEvent) => {
      const target = e.target as HTMLElement
      if (!target) {
        return
      }

      let recordId = target.dataset.highlight

      const isImage = target.nodeName === 'IMG'

      if (!isImage) {
        const { highlight, highlightId } = target.dataset
        // keeping two seperate so we know which are legacy
        recordId = highlight || highlightId
      }
      setHoveringBookmarkId(recordId)

      if (isImage) {
        setHideCarrot(true)
        displayContainer(target.parentElement || target)
      } else {
        resetTooltipContainer()
        setHideCarrot(false)

        const textPosition = target.getBoundingClientRect()
        const containerRect = containerRef.current!.getBoundingClientRect()

        const top = textPosition.top - containerRect.top - 40 // 40 is the height of the toolbar
        const left = textPosition.left - containerRect.left + textPosition.width / 2 - 40

        setElPosition({
          top,
          left
        })
        setShowTooltip(true)
      }

      setIsManaging(true)
    }

    const handleImgSelection = ({ target }: any) => {
      if (target.nodeName !== 'IMG') {
        return
      }

      const container = document.querySelector('#highlight-tooltip-container')

      if (container?.contains(target)) {
        setShowTooltip(true)
        return
      }
      const imgSelected = target.getAttribute('src') || undefined

      setHoveringBookmarkId(undefined)
      setHighlightMetadata(undefined)
      setSelectedImg(imgSelected)
      setSelectedText(undefined)
      setHideCarrot(true)
      setIsManaging(false)

      const elementId = target!.id!

      const anchor = convertProjectId(
        elementId || sanitizeAnchor(target.getAttribute('src')!)
      )
      setAnchor(anchor)
      displayContainer(target.parentElement)
    }

    if (event?.type === 'mouseup') {
      const { target } = event as any
      if (target && target.nodeName === 'BUTTON') {
        return
      }
      handleTextSelection()
    } else if (event?.type === 'mouseleave') {
      // to prevent the screen from flickering
      setTimeout(() => {}, 500)
      if (hovering) {
        return
      }
      resetTooltipContainer()
    } else if (event?.target) {
      // remove text when hover crosses images
      const { target }: any = event
      const isBookmarked = target.classList.contains('reforge-bookmark-highlighted')
      setElPosition(undefined)

      isBookmarked ? manageBookmark(event) : handleImgSelection(event)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, selectedText, hovering])

  useEffect(() => {
    const highlightDocument = (bookmarksToHighlight: BookmarkFragment[]) => {
      resetTooltipContainer()
      hideTooltip()
      let htmlDocument: any = originalHtmlNormalized

      // highlight image bookmarks
      const bookmarksWithMetadata = bookmarksToHighlight
        .filter(
          (bookmark) =>
            bookmark.type === BookmarkType.TEXTBOOKMARK &&
            bookmark?.metadata &&
            Object.keys(bookmark?.metadata).length > 0
        )
        .map((b) => ({ ...b.metadata, id: b.id }))

      htmlDocument = Highlighter.highlight(bookmarksWithMetadata, htmlDocument)

      // highlight image bookmarks
      bookmarksToHighlight
        .filter((bookmark) => bookmark.type === BookmarkType.IMAGEBOOKMARK)
        .forEach((bookmark) => {
          const htmlId = bookmark.anchor

          const id = bookmark.id
          const tempHtml = new DOMParser().parseFromString(htmlDocument, 'text/html')

          if (!htmlId) {
            return
          }

          if (bookmark.referenceImageUrl) {
            const el = tempHtml.getElementById(htmlId)

            const img = el?.nodeName === 'IMG' ? el : el?.getElementsByTagName('img')[0]

            if (img) {
              img.dataset.highlight = id
              img.classList.add(
                'reforge-bookmark-highlighted',
                '!border-4',
                '!border-solid',
                '!border-rb-teal-50',
                'select-none'
              )
              htmlDocument = tempHtml.body.innerHTML
            }
          }
        })

      // legacy bookmarks
      bookmarksToHighlight
        .filter((bookmark) => {
          if (bookmark.type !== BookmarkType.TEXTBOOKMARK) return

          return (
            (bookmark?.metadata && Object.keys(bookmark?.metadata).length === 0) ||
            !bookmark?.metadata
          )
        })
        .forEach((bookmark) => {
          const htmlId = bookmark.anchor

          const id = bookmark.id
          const tempHtml = new DOMParser().parseFromString(htmlDocument, 'text/html')

          if (!htmlId) {
            return
          }
          if (!bookmark.basedOn) {
            return
          }

          const container = tempHtml.getElementById(htmlId)

          if (!container) {
            return
          }

          container.innerHTML = Highlighter.highlightLegacy(container, id)

          htmlDocument = tempHtml.body.innerHTML
        })

      if (htmlDocument) {
        setHighlightedHtml(htmlDocument)
      }
    }

    if (!bookmarkQueryCompleted) {
      return
    }

    const wrapTour = (htmlString: string): string => {
      if (isBookmarkTourCompleted) {
        return htmlString
      }

      const tempHtml = new DOMParser().parseFromString(htmlString, 'text/html')

      const firstParagraph = tempHtml.querySelector('p')
      if (!firstParagraph) {
        return htmlString
      }

      const inlinePost = firstParagraph.closest('.inline-posts')
      if (inlinePost !== null) {
        const newDiv = document.createElement('div')
        newDiv.id = `portal-${ProductTourKey.BOOKMARK_CREATE}`
        newDiv.classList.add('relative')
        newDiv.classList.add('w-full')
        inlinePost.insertAdjacentElement('beforebegin', newDiv)
      }
      const content = firstParagraph.innerHTML
      let endOfFirstSentence = content.indexOf('.')

      if (endOfFirstSentence === -1) {
        endOfFirstSentence = content.indexOf('!')
      }

      if (endOfFirstSentence === -1) {
        endOfFirstSentence = content.indexOf('?')
      }

      if (endOfFirstSentence === -1) {
        endOfFirstSentence = content.length
      }

      const firstSentence = content.slice(0, endOfFirstSentence + 1)
      firstParagraph.innerHTML = content.replace(
        firstSentence,
        `<span class="tour">${firstSentence}</span>`
      )

      return tempHtml.body.innerHTML
    }

    if (userBookmarks.length > 0) {
      highlightDocument(userBookmarks)
    } else {
      setHighlightedHtml(wrapTour(originalHtmlNormalized))
    }
    contentState !== 'content-selected' && setContentState('content-enhanced')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userBookmarks, bookmarkQueryCompleted, isBookmarkTourCompleted])

  useEffect(() => {
    document.addEventListener('mouseup', eventCallback)
    lightboxImages('#cms-section-desc-container img')
    Highlighter.init({
      ignoreSelectors: [
        '.rf-lightbox-container',
        '.rf-lightbox-anchor',
        '.commentable_block',
        'img',
        '#highlight-tooltip-container',
        '#highlight-tooltip',
        'data-reference-image-url'
      ]
    })

    return () => {
      document.removeEventListener('mouseup', eventCallback)
      highlightingState.eventCount = 0
    }
  }, [eventCallback])

  useEffect(() => {
    const container = document.getElementById('cms-section-desc-container')
    const list = document.getElementsByClassName('reforge-bookmark-highlighted')
    lightboxImages('#cms-section-desc-container img')

    for (let i = 0; i < list.length; i++) {
      const element = list.item(i)
      element?.addEventListener('mouseenter', eventCallback)
    }

    const images = container?.getElementsByTagName('img') || []

    for (let i = 0; i < images.length; i++) {
      const element = images[i]
      element?.addEventListener('mouseenter', eventCallback)
      element?.addEventListener('mouseleave', eventCallback)
    }

    ++highlightingState.eventCount

    return () => {
      // remove image listeners
      for (let i = 0; i < list.length; i++) {
        const element = list.item(i)
        element?.removeEventListener('mouseenter', eventCallback)
      }
      // remove image listeners
      for (let i = 0; i < images.length; i++) {
        const element = images[i]
        element?.removeEventListener('mouseenter', eventCallback)
        element?.removeEventListener('mouseleave', eventCallback)
      }
    }
  }, [highlightedHtml, eventCallback])

  const handleActionButtonClick = (type: 'discussions' | 'bookmarks') => {
    openDrawer(rightDrawerId)
    setActiveDiscoveryDrawerPanel?.(type)
    trackActionButtonClick?.(type)
  }

  const handleCreateBookmark = async () => {
    if (selectedText === undefined && selectedImg === undefined) return

    completeBookmarkTour()

    history.replace({ hash: '' })
    const { data, errors } = await createBookmark({
      variables: {
        input: {
          ...metadata,
          anchor,
          basedOn: selectedText || null,
          type: selectedText ? BookmarkType.TEXTBOOKMARK : BookmarkType.IMAGEBOOKMARK,
          referenceImageUrl: selectedImg,
          metadata: highlightMetadata || undefined
        }
      },
      refetchQueries: [UserBookmarksFeedDocument]
    })

    if (errors) {
      return notifyError(`Error creating highlighted bookmark, got errors: ${errors}`)
    }

    const createdBookmark = data?.createBookmark?.bookmark

    if (createdBookmark) {
      const toastCardProps = {
        ctaText: 'Save to Collection',
        onCtaClick: () => {
          if (!openAddToBookmarkFolderModal) return

          trackModalDisplayed({
            category: 'app',
            location: 'save_to_collection_toast',
            modal_group: 'collections',
            modal_name: 'save_to_collection_modal',
            related_identifiers: {
              path: window.location.pathname,
              cms_section_id: cmsSection.id,
              cms_program_id: cmsSection.cmsProgramId,
              cms_module_id: cmsSection.cmsModuleId,
              cms_section_name: cmsSection.name,
              cms_program_name: cmsSection.cmsProgram?.name,
              cms_module_name: cmsSection.cmsModule?.name
            }
          })
          openAddToBookmarkFolderModal(createdBookmark)
        }
      }
      const temp = [...userBookmarks, createdBookmark as any]
      setUserBookmarks(temp)
      displayToast(
        <ToastCard type="success" message="Highlight created" {...toastCardProps} />,
        { ...toastOptions, toastId: 'reforge-toast' }
      )

      setActiveBookmarkId(createdBookmark.id)
      handleActionButtonClick('bookmarks')
    }

    hideTooltip()
  }

  const restoreBookmark = async (bookmark: ProgramBookmarkPartsFragment) => {
    history.replace({ hash: '' })

    // TODO: consolidate with useBookmarkCard restoreBookmark
    const {
      anchor,
      basedOn,
      noteBody,
      seconds,
      referenceImageUrl,
      type,
      title,
      externalUrl
    } = bookmark
    const bookmarkInput: CreateBookmarkInput = {
      anchor,
      basedOn,
      ...metadata,
      noteBody,
      seconds,
      referenceImageUrl,
      type,
      title,
      externalUrl
    }

    try {
      await createBookmark({
        variables: {
          input: bookmarkInput
        },
        refetchQueries: [UserBookmarksFeedDocument]
      })
    } catch (error: unknown) {
      notifyError(error)
    }
  }

  const handleDeleteBookmark = async () => {
    if (!hoveringBookmarkId) {
      return notifyError(
        'could not delete highlighted bookmark. hoveringBookmarkId undefined'
      )
    }
    history.replace({ hash: '' })

    const { errors } = await deleteBookmark({
      variables: {
        input: {
          bookmarkId: hoveringBookmarkId
        }
      },
      refetchQueries: [UserBookmarksFeedDocument]
    })

    if (errors) {
      notifyError(
        `Error deleting bookmark, got errors ${errors} for bookmark ${JSON.stringify(
          hoveringBookmarkId
        )}`
      )
      hideTooltip()
    } else {
      const temp = userBookmarks!.filter(
        (bookmark) => String(bookmark!.id) !== String(hoveringBookmarkId)
      )

      const bookmark: any = userBookmarks!.find(
        (bookmark) => String(bookmark!.id) === String(hoveringBookmarkId)
      )

      displayToast(
        <ToastCard
          type="destructive"
          message="Your highlight has been deleted"
          onUndo={() => restoreBookmark(bookmark)}
        />,
        toastOptions
      )

      hideTooltip()
      resetTooltipContainer()
      setUserBookmarks(temp)
      unsetActiveBookmarkId()
    }
  }

  const handleAddPost = () => {
    let basedOn = selectedText ? selectedText.replace(/&amp;/g, '&') : undefined
    let referenceImageUrl = selectedImg
    let anchorVal = anchor
    const initialGroupsTopics = cmsSection.groups.edges?.map((group) => {
      return { type: 'group', id: group.node.id }
    })

    if (cmsSection.topic) {
      initialGroupsTopics.push({ type: 'topic', id: cmsSection.topic!.id })
    }

    if (hoveringBookmarkId) {
      const bookmark: any = userBookmarks!.find(
        (bookmark) => String(bookmark!.id) === String(hoveringBookmarkId)
      )
      basedOn = bookmark.basedOn
      referenceImageUrl = bookmark.referenceImageUrl
      anchorVal = bookmark.anchor
    }

    setModalData({
      anchor: anchorVal,
      basedOn,
      cmsSectionName: cmsSection.name,
      cmsSectionContentType: cmsSection.contentType,
      initialGroupsTopics,
      ...metadata,
      referenceImageUrl,
      afterPostCallback: () => {
        handleActionButtonClick('discussions')
        ++highlightingState.eventCount
      },
      refetch: refetchPosts
    })
    showAddPostModal()
  }

  const getImageTooltipPosition = (cmsSectionType: string) => {
    switch (cmsSectionType) {
      case 'Lesson':
        return 'top-2 right-11'
      case 'Concept':
      case 'Project':
        return 'top-2 right-2'
      default:
        return 'top-2 right-11'
    }
  }

  const carrotPointer = {
    borderTop: '6px solid #0F172A',
    borderRight: '6px solid transparent',
    borderLeft: '6px solid transparent'
  }

  return (
    <div ref={containerRef} className="relative">
      {portal}
      {loading && <Loading />}
      <div
        id="highlight-tooltip"
        ref={tooltipRef}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => {
          setHovering(false)
        }}
        className={twMerge(
          'absolute z-[1011]',
          hideCarrot ? getImageTooltipPosition(cmsSection.contentType || '') : '',
          showTooltip ? '' : 'hidden'
        )}
        style={hideCarrot && !elPosition ? {} : { ...elPosition }}
      >
        <div
          className={'absolute left-[45%] bottom-[-6px]'}
          style={hideCarrot ? {} : carrotPointer}
        ></div>
        <div className="relative flex h-8 w-20 items-center justify-evenly rounded bg-slate-900 !text-white">
          {(isCreatingBookmark || isDeletingBookmark) && (
            <button className="flex w-8 items-center justify-center ">
              <SpinnerIcon />
            </button>
          )}

          {isManaging && !isDeletingBookmark && (
            <button
              className="flex w-8  items-center justify-center"
              onClick={handleDeleteBookmark}
              data-testid="bookmark-delete-button"
              data-dd-action-name="Delete bookmark"
            >
              <TrashIcon width={16} height={16} className="pointer-events-none" />
            </button>
          )}

          {!isManaging && !isCreatingBookmark && (
            <button
              className="flex w-8  items-center justify-center"
              onClick={handleCreateBookmark}
              data-testid="bookmark-button"
              data-dd-action-name="Create bookmark"
            >
              <HighlightIcon className="pointer-events-none h-4 w-4 fill-white" />
            </button>
          )}

          <button
            className="flex w-8 items-center justify-center"
            onClick={handleAddPost}
            data-testid="comment-button"
            data-dd-action-name="Open add comment modal"
          >
            <DiscussionIcon
              width={16}
              height={16}
              className="pointer-events-none fill-white"
            />
          </button>
        </div>
      </div>

      <div
        ref={cmsRef}
        id="cms-section-desc-container"
        data-test="lesson-viewer-content-full"
        dangerouslySetInnerHTML={{ __html: highlightedHtml }}
      />
    </div>
  )
}
