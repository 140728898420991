import { UserSearchFilters } from 'gql'

import { onSpaceKeyPress } from 'utils/keyboard'

interface InputFilterProps {
  name: keyof UserSearchFilters
  filters: UserSearchFilters
  setFilters: (filters: UserSearchFilters) => void
  label: string
  placeholder: string
}

const InputFilter = ({
  name,
  label,
  placeholder,
  filters,
  setFilters
}: InputFilterProps) => {
  const handleInputChange = (value: string | null, isReset = false) => {
    setFilters({
      ...filters,
      [name]: isReset ? '' : value
    })
  }

  const canReset = Boolean(filters[name])

  return (
    <div>
      <div className="mb-[8px] flex items-center justify-between">
        <label
          className="mb-0 block align-baseline text-sm font-medium capitalize"
          htmlFor={label}
        >
          {label}
        </label>
        {canReset && (
          <div
            className="m-0 cursor-pointer px-[7px] text-sm text-rb-gray-300 hover:text-rb-teal-200"
            onClick={() => handleInputChange(null, true)}
            onKeyUp={onSpaceKeyPress(() => handleInputChange(null, true))}
            tabIndex={0}
            role="button"
          >
            Reset
          </div>
        )}
      </div>
      <input
        type="text"
        name={name}
        onChange={(e) => handleInputChange(e.target.value, false)}
        placeholder={placeholder}
        value={filters[name] || ''}
        autoComplete="off"
        className="mb-[13px] box-border h-[46px] w-full rounded-sm border-[1.25px] border-rb-gray-100 pl-[11px] text-sm text-rb-gray-300 placeholder:italic focus:border-rb-gray-100 focus:outline-none active:border-rb-gray-100"
      />
    </div>
  )
}

export default InputFilter
