import { SkeletonMemberCard } from 'components/skeletons/cards'
import SkeletonRfParagraph from 'components/skeletons/typography/SkeletonRfParagraph'

const SkeletonMembersPage = () => (
  <div className="flex w-full flex-col">
    <SkeletonRfParagraph className="my-4 w-5/12" />
    <div className="mt-3">
      {[...Array(12)].map((_, index) => (
        <SkeletonMemberCard key={index} className="mb-5" />
      ))}
    </div>
  </div>
)

export default SkeletonMembersPage
