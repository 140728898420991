import { VerticalSkeletonArtifactCardContainer } from 'components/skeletons/cards'

export default function LoadingSkeleton() {
  return (
    <div className="mx-auto mt-6 mb-4 w-full">
      <div className="grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
        <VerticalSkeletonArtifactCardContainer items={16} />
      </div>
    </div>
  )
}
