import InputFilter from './InputFilter'
import { FilterProps } from './helpers'

const Name = ({ filters, setFilters }: FilterProps) => (
  <InputFilter
    label="name"
    placeholder="Type a name"
    name="contains"
    filters={filters}
    setFilters={setFilters}
  />
)

export default Name
