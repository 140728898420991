import { useState } from 'react'

import { ProductTourKey } from 'gql'

import { cn } from 'utils/tailwind'

import { BANNER_KEY_DATA_MAP } from '../AnnouncementWrapper'
import { AnnouncementBanner } from './AnnouncementBanner'
import { CarouselDot } from './CarouselDot'

export interface AnnouncementBannerProps {
  activeComponents: string[]
  setActiveComponents: (prevState: string[]) => void
  onClick?: (identifier: ProductTourKey | string) => void
}

const AnnouncementBannerCarousel = ({
  onClick,
  activeComponents,
  setActiveComponents
}: AnnouncementBannerProps) => {
  const [indexVisible, setIndexVisible] = useState(0)

  if (!activeComponents || !activeComponents.length) return null

  return (
    <div
      className={cn(
        'flex flex-col items-center gap-4 mb-12 self-stretch text-rb-gray-400'
      )}
    >
      <div className="flex w-full font-polysans">
        {activeComponents.map((componentKey, i) => (
          <AnnouncementBanner
            key={componentKey}
            data={BANNER_KEY_DATA_MAP[componentKey]}
            identifier={componentKey}
            isVisible={indexVisible === i}
            onClick={onClick}
            setActiveComponents={setActiveComponents}
          />
        ))}
      </div>
      {activeComponents.length > 1 && (
        <div className="flex w-full justify-center gap-2">
          {activeComponents.map((_, i) => (
            <CarouselDot
              key={i}
              active={indexVisible === i}
              onClick={() => setIndexVisible(i)}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default AnnouncementBannerCarousel
