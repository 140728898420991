import { useEffect } from 'react'

import { EventQuery, useTrackServerEventMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { getAnonymousId } from 'utils/tracking/segment'

export function useTrackConversionPageViewed({
  event
}: {
  event: Pick<EventQuery['event'], 'id' | 'name' | 'kind' | 'startsAtUtc' | 'endsAtUtc'>
}) {
  const { currentUser } = useCurrentUser()
  const [trackServerEvent] = useTrackServerEventMutation()

  useEffect(() => {
    function isConversionEligible() {
      // true when anonymous user (user_id is not known)
      if (!currentUser) return true

      // true when event is for membership AND user’s access policy is not plan_manager or member
      if (!currentUser?.is.planManager && !currentUser.is.paidMember) {
        return true
      }

      // true when event is for live course sessions
      if (event.kind === 'Public Event: Course Creator') return true

      // false when event is for an artifact or general Reforge knowledge
      return false
    }

    if (!currentUser?.is.paidMember || event.startsAtUtc > new Date().toISOString()) {
      trackServerEvent({
        variables: {
          input: {
            event: 'Conversion Page Viewed - Server',
            anonymousId: getAnonymousId(),
            properties: {
              access_policy_kind: currentUser?.accessPolicyKind,
              page_type: 'event_details',
              path: window.location.pathname,
              referrer: document.referrer,
              url: window.location.href,
              logged_in: !!currentUser,
              is_conversion_eligible: isConversionEligible(),
              related_identifiers: {
                event_id: event.id,
                event_title: event.name,
                event_kind: event.kind,
                is_completed_event: event.endsAtUtc < new Date().toISOString()
              },
              trial_status: currentUser?.trialStatus
            }
          }
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
