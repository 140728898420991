import { History } from 'history'
import React, { useEffect, useRef, useState } from 'react'

import CheckboxFilter, { CheckboxFilterSet } from 'components/Filters/CheckboxFilter'
import ListFilter, { ListFilterSet } from 'components/Filters/ListFilter'
import PeopleFilter, { PeopleFilterSet } from 'components/Filters/PeopleFilter'
import SortBy, { SortByFilterSet } from 'components/Filters/SortBy'
import TagFilter, { TagFilterSet } from 'components/Filters/TagFilter'
import TwostepFilter, { TwostepFilterSet } from 'components/Filters/TwostepFilter'

import RadioFilter, { RadioFilterSet } from './RadioFilter'

export type FilterOption =
  | CheckboxFilterSet
  | ListFilterSet
  | PeopleFilterSet
  | SortByFilterSet
  | TagFilterSet
  | TwostepFilterSet
  | RadioFilterSet

export type FiltersProp = { [key: string]: FiltersPropValue }
export type FiltersPropValue = string | number | null | string[]

export interface IFilters {
  filterKeys: string[]
  setFilters: React.Dispatch<React.SetStateAction<FiltersProp>>
  filterOptions: FilterOption[]
  filters: FiltersProp
  history?: History
  busy?: boolean
  headerText?: string
  startOpen?: boolean
}

const Filters = (props: IFilters) => {
  const {
    history,
    filters,
    filterOptions,
    headerText = 'Filter & Sort',
    startOpen
  } = props
  const isComponentMounted = useRef(false)
  const [busy, setBusy] = useState(false)

  // Set filters from URL parameters
  // 'partner' components will pull from the server when the filters change, setting page: 1 always triggers a change on first load
  const setFiltersFromParams = () => {
    const defaultFilterValues: FiltersProp = { page: 1 }
    const sortOptions = filterOptions.find((options) => options.key === 'sort_by')
    const options = sortOptions?.options
    if (options) {
      defaultFilterValues.sort_by = sortOptions.defaultOptionIndex
        ? options[sortOptions.defaultOptionIndex][0]
        : options[0][0]
    }

    const urlParams = new window.URLSearchParams(window.location.search)
    props.filterKeys.forEach(function (filterKey) {
      if (urlParams.has(filterKey)) {
        const paramValue = urlParams.get(filterKey)
        if (
          typeof paramValue === 'string' &&
          filterKey !== 'sort_by' &&
          filterKey !== 'page' &&
          filterKey !== 'filter'
        ) {
          defaultFilterValues[filterKey] = paramValue.split(',')
        } else {
          defaultFilterValues[filterKey] = paramValue
        }
      }
    })
    props.setFilters(defaultFilterValues)
  }

  const setParamsFromFilters = () => {
    const searchParams = new URLSearchParams()
    Object.keys(filters).forEach(function (filterKey) {
      const filterValue = filters[filterKey]
      // only set query param if not null or if page !== 1
      if (filterValue && !(filterKey === 'page' && filterValue === 1)) {
        const value = Array.isArray(filterValue)
          ? filterValue.join(',')
          : filterValue.toString()
        searchParams.set(filterKey, value)
      }
    })
    if (history) {
      history.replace(`?${searchParams.toString()}`)
    }
  }

  const updateFilters = (filterKey: string, value: string, remove: boolean) => {
    const currentValues = filters[filterKey]
    const filtersCopy = Object.assign({}, props.filters)

    if (remove && currentValues && Array.isArray(currentValues)) {
      const newValues = currentValues.filter((v: string) => v !== value)

      if (newValues.length === 0) {
        delete filtersCopy[filterKey]
      } else {
        filtersCopy[filterKey] = newValues
      }
    } else {
      filtersCopy[filterKey] = Array.isArray(currentValues)
        ? currentValues.concat([value])
        : [value]
    }
    props.setFilters(filtersCopy)
  }

  const replaceFilters = (filterKey: string, newValue: string | string[]) => {
    props.setFilters({
      ...props.filters,
      [filterKey]: newValue
    })
  }

  const resetFilters = (filterKey: string) => {
    const filtersCopy = Object.assign({}, props.filters)
    delete filtersCopy[filterKey]
    props.setFilters(filtersCopy)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setFiltersFromParams, []) // call this only once, on first load
  useEffect(() => {
    if (isComponentMounted.current) {
      setParamsFromFilters()
    } else {
      isComponentMounted.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]) // call this any time the filters change, except on first load before filters are set from url params

  const getNumActiveFilters = () => {
    let count = 0
    Object.keys(filters).forEach(function (key) {
      if (key !== 'page' && key !== 'sort_by' && filters[key]) {
        // if the key has a value, add to counter
        count++
      }
    })
    return count
  }

  useEffect(() => {
    setBusy(!!props.busy)
  }, [props.busy])

  const reset = () => {
    props.setFilters({ sort_by: filters.sort_by, page: 1 }) // Dont reset the sort_by or page if present, it should always have a value.
  }

  const numActiveFilters = getNumActiveFilters()
  const hideControls = filterOptions.some((option) => option.type === 'list') // Dont show the reset controls for list filters
  return (
    <div id="filters-placeholder">
      <div id="filters" className="filters mb-7 pt-5 lg:pt-0">
        <div className="mb-5 flex items-center">
          <h3 className="m-0 flex grow items-center text-lg font-medium leading-6 text-rb-gray-300">
            <span>{headerText}</span>
          </h3>
          {!hideControls && numActiveFilters > 0 && (
            <button
              className="cursor-pointer text-sm leading-3 text-rb-teal-200 hover:underline hover:decoration-rb-teal-300"
              onClick={reset}
            >
              Reset <span className="lg:visible">all</span>
            </button>
          )}
        </div>
        {filterOptions.length > 0 &&
          filterOptions.map((filterSet, index) => (
            <div className="relative" key={`filterset_${index}`}>
              {busy && <div className="absolute z-10 h-full w-full"></div>}
              {filterSet.type === 'sortby' && (
                <SortBy
                  filterSet={filterSet}
                  val={filters[filterSet.key]}
                  replaceFilters={replaceFilters}
                />
              )}
              {filterSet.type === 'list' && (
                <ListFilter
                  filterSet={filterSet}
                  val={filters[filterSet.key]}
                  replaceFilters={replaceFilters}
                />
              )}
              {filterSet.type === 'checkbox' && (
                <CheckboxFilter
                  filterSet={filterSet}
                  values={filters[filterSet.key]}
                  updateFilters={updateFilters}
                  resetFilters={resetFilters}
                  startOpen={startOpen}
                />
              )}
              {filterSet.type === 'people' && (
                <PeopleFilter
                  filterSet={filterSet}
                  values={filters[filterSet.key]}
                  updateFilters={updateFilters}
                  resetFilters={resetFilters}
                  startOpen={startOpen}
                />
              )}
              {filterSet.type === 'twostep' && (
                <TwostepFilter
                  filterSet={filterSet}
                  values={filters[filterSet.key] || ''}
                  updateFilters={updateFilters}
                  resetFilters={resetFilters}
                  replaceFilters={replaceFilters}
                  startOpen={startOpen}
                />
              )}
              {filterSet.type === 'tag' && (
                <TagFilter
                  filterSet={filterSet}
                  values={filters[filterSet.key]}
                  updateFilters={updateFilters}
                  resetFilters={resetFilters}
                  startOpen={startOpen}
                />
              )}
              {filterSet.type === 'radio' && (
                <RadioFilter
                  filterSet={filterSet}
                  value={filters[filterSet.key]}
                  replaceFilters={replaceFilters}
                  resetFilters={resetFilters}
                  startOpen={startOpen}
                />
              )}
            </div>
          ))}
      </div>
    </div>
  )
}

export default Filters
