import React from 'react'

import { useGlobalModal } from 'components/GlobalModal'

import RestartPlanNowSuccessModal from './RestartPlanNowSuccessModal'

export function useRestartPlanNowSuccessModal() {
  const { openGlobalModal, closeGlobalModal } = useGlobalModal()

  const openRestartPlanNowSuccessModal = ({
    restartedWhen,
    renewalDate,
    amountDue
  }: Omit<React.ComponentProps<typeof RestartPlanNowSuccessModal>, 'onDone'>) => {
    const handleClose = () => {
      closeGlobalModal()
      window.location.reload()
    }

    openGlobalModal(
      <RestartPlanNowSuccessModal
        restartedWhen={restartedWhen}
        renewalDate={renewalDate}
        amountDue={amountDue}
        onDone={handleClose}
      />,
      {
        className: 'mx-2 max-w-[646px] rounded-lg',
        handleClose
      }
    )
  }

  return {
    openRestartPlanNowSuccessModal
  }
}
