import { useState } from 'react'

import { EventCardsGrid } from 'domains/Event/EventCardsGrid/EventCardsGrid'

import { CurrentUserQuery, EventPartsFragment } from 'gql'

import { onEnterKeyPress } from 'utils/keyboard'

interface EventModuleProps {
  events: EventPartsFragment[]
  currentUser: NonNullable<CurrentUserQuery['currentUser']>
  showMemberContent: boolean
}

const EventModule = ({ events, currentUser, showMemberContent }: EventModuleProps) => {
  const [moduleOpen, setModuleOpen] = useState(false)

  const toggleModuleOpen = () => {
    setModuleOpen(!moduleOpen)
  }

  if (showMemberContent === undefined) {
    showMemberContent = !!currentUser?.is.member
  }

  return (
    <div
      className={`mt-0 border-b-[1px] px-4 lg:px-8 ${
        moduleOpen ? '' : 'group hover:bg-rb-gray-50'
      }`}
    >
      <div
        className="px-0 py-4"
        onClick={toggleModuleOpen}
        onKeyUp={onEnterKeyPress(toggleModuleOpen)}
        role="button"
        aria-expanded={moduleOpen}
        tabIndex={0}
      >
        <div className="!flex h-12 w-[60%] items-center text-base font-medium leading-5 text-rb-gray-500 line-clamp-2 sm:text-[21px] sm:leading-[26px] lg:my-1">
          Events
        </div>
      </div>
      <div className={`${moduleOpen ? '' : 'hidden'}`}>
        <EventCardsGrid events={events} user={currentUser} />
      </div>
    </div>
  )
}

export default EventModule
