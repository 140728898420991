import clsx from 'clsx'

import { SkeletonTags } from 'components/skeletons/foundation/SkeletonTags/SkeletonTags'
import SkeletonRfParagraph from 'components/skeletons/typography/SkeletonRfParagraph'

interface SkeletonGroupCardProps {
  className?: string
}
export const SkeletonGroupCard = ({ className }: SkeletonGroupCardProps) => (
  <div
    className={clsx(
      'rf-rb-card-interactive mb-5 flex  animate-pulse flex-col p-4',
      className
    )}
  >
    <SkeletonRfParagraph className="mb-2 h-3 w-[25%]" />
    <SkeletonRfParagraph className="mb-2 h-4 w-full" />
    <SkeletonRfParagraph className="mb-3 h-4 w-[50%]" />
    <SkeletonTags tagsCount={4} />
    <div className="flex flex-grow items-end">
      <div className="mt-auto flex w-full items-center justify-between">
        <div className="h-7 w-20 rounded-full bg-rb-gray-100" />
        <div className="h-8 w-20 rounded-full bg-rb-gray-100" />
      </div>
    </div>
  </div>
)
