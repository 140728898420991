import { Link } from 'react-router-dom'

export interface IEmptySearchResults {
  searchType: string
  searchQuery: string
}

const EmptySearchResults = ({ searchType, searchQuery }: IEmptySearchResults) => {
  const CommonPlaceholder = (
    <>
      <p>Some recommendations for what to search for:</p>
      <ul className="uk-list uk-list-bullet">
        <li>
          <Link to="/search?q=monetization">monetization</Link>
        </li>
        <li>
          <Link to="/search?q=aha+moment">aha moment</Link>
        </li>
        <li>
          <Link to="/search?q=resurrection">resurrection</Link>
        </li>
        <li>
          <Link to="/search?q=growth+team+structure">growth team structure</Link>
        </li>
        <li>
          <Link to="/search?q=virality">virality</Link>
        </li>
        <li>
          <Link to="/search?q=interview">interview</Link>
        </li>
        <li>
          Members at a company, ex: <Link to="/search?q=uber&type=User">uber</Link>,{' '}
          <Link to="/search?q=google&type=User">google</Link>,{' '}
          <Link to="/search?q=hubspot&type=User">hubspot</Link>
        </li>
      </ul>
    </>
  )

  return (
    <div className="uk-placeholder uk-margin-top" data-test="empty-state-placeholder">
      {searchQuery ? (
        <span className="uk-text-middle">
          {searchType !== 'User' && (
            <>
              <span className="uk-margin-small-right" uk-icon="warning"></span>
              No results found. Is this a person?{' '}
              <Link to={`/search?q=${searchQuery}&type=User`}>
                Click here to search for {searchQuery}{' '}
              </Link>
              in our member directory.
              <hr />
            </>
          )}

          {CommonPlaceholder}
        </span>
      ) : (
        <>
          <p>
            You can search our program content, posts in the forum, and our member
            directory by searching here.
          </p>
          {CommonPlaceholder}
        </>
      )}
    </div>
  )
}

export default EmptySearchResults
