import { Alert } from 'components/Alert'
import Button from 'components/Button'
import OrderRecapLabel from 'components/CheckoutModal/OrderRecap/components/OrderRecapLabel'
import OrderRecapPrice from 'components/CheckoutModal/OrderRecap/components/OrderRecapPrice'
import { SectionHeading } from 'components/CheckoutModal/components/Headings'
import { SectionBorder } from 'components/CheckoutModal/components/SectionBorder'
import { currencyOptions, locale } from 'components/CheckoutModal/utils'

import { PlanName } from 'gql'

import { capitalizeFirstLetter, pluralize } from 'utils/stringUtils'

import OrderRecapFreeTrialFooter from './components/OrderRecapFreeTrialFooter'
import OrderRecapFreeTrialGroup from './components/OrderRecapFreeTrialGroup'
import { OrderRecapItemGroup } from './components/OrderRecapItemGroup'
import {
  DISCOUNTED_COHORT_PASS_SAVINGS,
  usePurchaseFlowContext
} from './contexts/PurchaseFlowContext'

export interface OrderRecapItemsProps {
  planName: PlanName | null
  planPrice: number
  planSeatCount: number
  cohortPassPrice: number
  cohortPassSubTotal: number
  cohortPassLabel: string
  cohortPassQuantity: number
  discountedCohortPassQuantity: number
  discountedCohortPassPrice: number
}

export const OrderRecapItems = ({
  planName,
  planPrice,
  planSeatCount,
  cohortPassPrice,
  cohortPassSubTotal,
  cohortPassLabel,
  cohortPassQuantity,
  discountedCohortPassQuantity,
  discountedCohortPassPrice
}: OrderRecapItemsProps) => {
  return (
    <OrderRecapItemGroup>
      {/* plan */}

      {planName && (
        <>
          <OrderRecapLabel
            label={`${capitalizeFirstLetter(planName || '')} Subscription`}
            details={`${planSeatCount} ${pluralize('seat', 'seats', planSeatCount)}`}
          />
          <OrderRecapPrice price={planPrice} />
        </>
      )}

      {/* discounted cohort passes (now Live Course Passes) */}
      {discountedCohortPassQuantity > 0 && (
        <>
          <OrderRecapLabel
            label={
              <>
                <span className="mr-2">{`${cohortPassLabel} x ${discountedCohortPassQuantity}`}</span>
              </>
            }
          />
          <OrderRecapPrice
            price={
              (discountedCohortPassPrice + DISCOUNTED_COHORT_PASS_SAVINGS) *
              discountedCohortPassQuantity
            }
            className="text-rb-green-300 line-through"
          />
          <OrderRecapLabel
            label={
              <span className="text-rb-green-300 mr-2 text-xs font-semibold">
                New member limited offer (-
                {DISCOUNTED_COHORT_PASS_SAVINGS.toLocaleString(locale, currencyOptions)})
              </span>
            }
            className="mt-[-8px]"
          />
          <OrderRecapPrice
            price={discountedCohortPassPrice * discountedCohortPassQuantity}
            className="mt-[-8px]"
          />
        </>
      )}

      {/* cohort passes (now Live Course Passes) */}
      {cohortPassQuantity > 0 && (
        <>
          <OrderRecapLabel
            label={`${cohortPassLabel} x ${cohortPassQuantity}`}
            details={
              <>
                <OrderRecapPrice price={cohortPassPrice} className="inline-block" /> each
              </>
            }
          />
          <OrderRecapPrice price={cohortPassSubTotal} />
        </>
      )}
    </OrderRecapItemGroup>
  )
}

export interface OrderRecapSectionWrapperProps {
  onNextClick: () => void
  nextButtonText: string
  nextButtonLoading?: boolean
  nextButtonDisabled?: boolean
}

export const OrderRecapSectionWrapper = ({
  onNextClick,
  nextButtonText,
  nextButtonLoading,
  nextButtonDisabled
}: OrderRecapSectionWrapperProps) => {
  const {
    planName,
    planPrice,
    planSeatCount,
    cohortPassQuantity,
    cohortPassPrice,
    discountedCohortPassPrice,
    discountedCohortPassQuantity,
    subTotal,
    isFreeTrialPurchase,
    error
  } = usePurchaseFlowContext()

  return (
    <OrderRecapSection
      onNextClick={onNextClick}
      nextButtonText={nextButtonText}
      nextButtonLoading={nextButtonLoading}
      nextButtonDisabled={nextButtonDisabled}
      planName={planName}
      planPrice={planPrice}
      planSeatCount={planSeatCount}
      cohortPassQuantity={cohortPassQuantity}
      cohortPassPrice={cohortPassPrice}
      discountedCohortPassPrice={discountedCohortPassPrice}
      discountedCohortPassQuantity={discountedCohortPassQuantity}
      subTotal={subTotal}
      isFreeTrialPurchase={isFreeTrialPurchase}
      error={error}
    />
  )
}

export interface OrderRecapSectionProps {
  onNextClick: () => void
  nextButtonText: string
  planName: PlanName | null
  planPrice: number
  planSeatCount: number
  cohortPassQuantity: number
  cohortPassPrice: number
  discountedCohortPassPrice: number
  discountedCohortPassQuantity: number
  subTotal: number
  error: string | null
  nextButtonLoading?: boolean
  nextButtonDisabled?: boolean
  isFreeTrialPurchase?: boolean
}

export const OrderRecapSection = ({
  onNextClick,
  nextButtonText,
  planName,
  planPrice,
  planSeatCount,
  cohortPassQuantity,
  cohortPassPrice,
  discountedCohortPassPrice,
  discountedCohortPassQuantity,
  subTotal,
  error,
  nextButtonLoading,
  nextButtonDisabled,
  isFreeTrialPurchase
}: OrderRecapSectionProps) => {
  const cohortPassLabel = 'Live Course Pass'
  const cohortPassSubTotal = cohortPassQuantity * cohortPassPrice

  return (
    <div className="sticky top-0 ">
      <SectionHeading>Order Recap</SectionHeading>
      <SectionBorder>
        <div className="w-full">
          <OrderRecapItems
            planName={planName}
            planPrice={planPrice}
            planSeatCount={planSeatCount}
            cohortPassPrice={cohortPassPrice}
            cohortPassSubTotal={cohortPassSubTotal}
            cohortPassLabel={cohortPassLabel}
            cohortPassQuantity={cohortPassQuantity}
            discountedCohortPassQuantity={discountedCohortPassQuantity}
            discountedCohortPassPrice={discountedCohortPassPrice}
          />
          {isFreeTrialPurchase ? (
            <div className="mt-4 border-t border-rb-gray-100 pt-4">
              <OrderRecapItemGroup>
                <OrderRecapFreeTrialGroup subTotal={subTotal} />
              </OrderRecapItemGroup>
            </div>
          ) : (
            <div className="mt-3 flex w-full flex-row justify-between border-t border-rb-gray-100 pt-4 text-xl font-semibold">
              <OrderRecapLabel label="Subtotal" className="text-xl" />
              <OrderRecapPrice price={subTotal} />
            </div>
          )}
          <Button
            className="mt-6"
            onClick={onNextClick}
            fullWidth
            isLoadingSpinner={nextButtonLoading}
            disabled={nextButtonDisabled}
          >
            {nextButtonText}
          </Button>
          <Alert className="mt-5">{error}</Alert>
        </div>
      </SectionBorder>

      {isFreeTrialPurchase && <OrderRecapFreeTrialFooter showReimbursementComponent />}
    </div>
  )
}
