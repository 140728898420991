import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Redirect, useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { CohortConversationThreadSidebar } from 'domains/CohortConversation'
import CohortConversationSortButtonGroup from 'domains/CohortConversation/CohortConversationSortButtonGroup'
import CohortPostOrReplyFormModal from 'domains/CohortConversation/Post/CohortPostOrReplyFormModal'
import CreatePostForm from 'domains/CohortConversation/Post/CreatePostForm'
import { useCohortConversationLoader } from 'domains/CohortConversation/hooks/useCohortConversationLoader'
import { CohortConversationTrackingContext } from 'domains/CohortConversation/hooks/useCohortConversationsTrackingContext'
import { useThreadPostLoader } from 'domains/CohortConversation/hooks/useThreadPostLoader'

import { Loading } from 'components'
import ProfileModal from 'components/CohortDashboard/ProfileModal'
import { CohortPostCardContainer } from 'components/CohortPostCard'
import { InterventionCard } from 'components/InterventionCard'
import { useModal } from 'components/Modal'
import { SkeletonCohortPostCardContainer } from 'components/skeletons/cards'
import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'
import RfParagraphLargeSemiBold from 'components/typography/RfParagraph/RfParagraphLargeSemiBold'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import {
  CohortPostPartsFragment,
  CohortPostReplyPartsFragment,
  CohortPostUserPartsFragment,
  CohortViewerNewCohortPartsFragment,
  useUpdateTopicActivityMutation
} from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import useMediaQuery from 'hooks/useMediaQuery'

import { makeConversationUrl } from 'utils/url'

const BATCH_SIZE = 10

interface CohortConversationContainerProps {
  slug: string
  topicSlug: string
  threadPostId?: string
  cohort: CohortViewerNewCohortPartsFragment
}

const CohortConversationContainer = ({
  slug,
  topicSlug,
  threadPostId,
  cohort
}: CohortConversationContainerProps) => {
  const { showInProductCommunication } = useFeatureFlags()

  if (!showInProductCommunication) {
    return <Redirect to={`/cohorts/${slug}`} />
  }

  return (
    <CohortConversations
      slug={slug}
      topicSlug={topicSlug}
      threadPostId={threadPostId}
      cohort={cohort}
    />
  )
}

const CohortConversations = ({
  slug,
  topicSlug,
  threadPostId,
  cohort
}: CohortConversationContainerProps) => {
  const [activeSortBy, setActiveSortBy] = useState('Recent')
  const [isProfileSidebarOpen, setIsProfileSidebarOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<CohortPostUserPartsFragment>()
  const history = useHistory()
  const currentUser = useAssertCurrentUser()
  const { isModalOpen, openModal, closeModal } = useModal()
  const [postToEdit, setPostToEdit] = useState<CohortPostPartsFragment>()
  const [replyToEdit, setReplyToEdit] = useState<CohortPostReplyPartsFragment>()
  const [updateTopicActivity] = useUpdateTopicActivityMutation({
    update: (cache, { data }) => {
      if (!data?.updateTopicActivity?.success) return

      cache.modify({
        id: `TopicActivity:${data.updateTopicActivity.id}`,
        fields: {
          lastViewedAt() {
            return data.updateTopicActivity?.lastViewedAt
          }
        }
      })
    }
  })

  const {
    topic,
    posts = [],
    pinnedPosts,
    postsLoading,
    postCount,
    refetchPosts,
    fetchMorePosts
  } = useCohortConversationLoader(slug, topicSlug, activeSortBy.toLowerCase())

  useEffect(() => {
    refetchPosts()
  }, [activeSortBy, topicSlug, refetchPosts])

  useEffect(() => {
    if (topic?.id) {
      updateTopicActivity({
        variables: {
          input: { topicId: topic.id, setLastViewedAt: true }
        }
      })
    }
  }, [topic?.id, updateTopicActivity])

  const { threadPost, setThreadPost, refetchThreadPost } =
    useThreadPostLoader(threadPostId)

  const isThreadOpen = !!threadPostId
  const groupId = topic?.groupId || ''
  const topicId = topic?.id || ''
  const canCreatePost =
    (['staff', 'collective'].includes(currentUser.profile.kind || '') ||
      !topic?.restrictedToStaff) &&
    !topic?.isContentClarification

  const refetchPostsData = async () => {
    await refetchPosts()
  }

  const refetchThreadData = async () => {
    await refetchThreadPost()
  }

  const showProfile = (user: CohortPostUserPartsFragment) => {
    setIsProfileSidebarOpen(true)
    setSelectedUser(user)
  }

  const showThread = (post: CohortPostPartsFragment) => {
    setThreadPost(post)
    history.push(makeConversationUrl(slug, topicSlug, post.id))
  }

  const closeThread = () => {
    history.push(makeConversationUrl(slug, topicSlug))
    isModalOpen && closeModal()
  }

  const handleModalOpen = (
    post: CohortPostPartsFragment | CohortPostReplyPartsFragment
  ) => {
    if (post.__typename === 'Post') {
      setPostToEdit(post)
    } else if (post.__typename === 'Reply') {
      setReplyToEdit(post)
    }

    openModal()
  }

  const handleModalClose = () => {
    setPostToEdit(undefined)
    setReplyToEdit(undefined)
    closeModal()
  }

  const defaultPostsProps = {
    className: 'mb-5',
    currentUser: currentUser,
    includeInteractions: true,
    showProfile: showProfile,
    showThread: showThread,
    closeThread: closeThread,
    refetch: refetchPostsData,
    cohortSlug: cohort.slug
  }

  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)

  const isPostEmpty =
    (!posts || posts.length === 0) && (!pinnedPosts || pinnedPosts.length === 0)

  const count = postCount || 0
  const postsCount = posts?.length || 0

  const hasMorePosts = count > postsCount
  const isAfterSpring2023Season = parseInt(cohort?.season?.id || '0') > 40
  const isEmailCohortTopic = topic?.title === 'Announcements' && isAfterSpring2023Season

  return (
    <CohortConversationTrackingContext.Provider value={{ cohort, topicId, groupId }}>
      <div className="relative flex w-full overflow-hidden">
        <div
          className={twMerge(
            'flex h-full w-full flex-1 flex-col overflow-auto px-6 py-8 sm:px-12 lg:w-1/2',
            isThreadOpen && 'hidden lg:flex'
          )}
        >
          <div className="flex pb-6 lg:hidden">
            <RfParagraphLargeSemiBold>{topic?.title}</RfParagraphLargeSemiBold>
          </div>

          {topic?.isContentClarification && (
            <InterventionCard
              className="w-full max-w-[458px]"
              titleComponent={
                <RfHeader3SemiBold>
                  Questions asked from within the content
                </RfHeader3SemiBold>
              }
              subtitleComponent={
                <RfParagraphMedium>
                  To ask a question, highlight any bit of text or image in a lesson and
                  leave a comment.
                </RfParagraphMedium>
              }
            />
          )}

          {isEmailCohortTopic && (
            <InterventionCard
              className="w-full"
              titleComponent={<RfHeader3SemiBold>Email the cohort</RfHeader3SemiBold>}
              subtitleComponent={
                <RfParagraphMedium>
                  Send an email to all cohort members to inform them about any changes or
                  updates.
                </RfParagraphMedium>
              }
            />
          )}

          {canCreatePost && (
            <div className="pb-9">
              <CreatePostForm
                refetch={refetchPostsData}
                isMobile={isMobile}
                placeholder={isEmailCohortTopic ? 'Create an email...' : null}
              />
            </div>
          )}

          <CohortConversationSortButtonGroup
            activeSortBy={activeSortBy}
            setActiveSortBy={setActiveSortBy}
          />

          {postsLoading && isPostEmpty && <SkeletonCohortPostCardContainer />}

          {!postsLoading && isPostEmpty && (
            <div className="mt-4 flex h-full w-full flex-col items-center sm:mt-8">
              <RfHeader3SemiBold className="text-rb-black">
                There are no posts yet
              </RfHeader3SemiBold>
              <RfParagraphSmall className="text-rb-black">
                {topic?.isContentClarification &&
                  'Comments from the lesson content will show up here.'}
                {topic?.restrictedToStaff && 'Important announcements will show up here.'}
                {!topic?.isContentClarification &&
                  !topic?.restrictedToStaff &&
                  'Be the first to create a post.'}
              </RfParagraphSmall>
            </div>
          )}

          {pinnedPosts?.map((post, index) => (
            <CohortPostCardContainer
              key={`cohort-pinned-post-card-${index}`}
              post={post}
              openEditModal={() => handleModalOpen(post)}
              {...defaultPostsProps}
            />
          ))}

          <InfiniteScroll
            loadMore={() =>
              fetchMorePosts({
                variables: {
                  slug: slug,
                  topicSlug: topicSlug,
                  sortBy: activeSortBy.toLowerCase(),
                  limit: BATCH_SIZE,
                  offset: posts.length
                }
              })
            }
            hasMore={hasMorePosts}
            useWindow={false}
            loader={<Loading key={0} />}
          >
            {posts.map((post, index) => (
              <CohortPostCardContainer
                key={`cohort-post-card-${index}`}
                post={post}
                openEditModal={() => handleModalOpen(post)}
                {...defaultPostsProps}
              />
            ))}
          </InfiniteScroll>
        </div>

        <CohortPostOrReplyFormModal
          post={postToEdit}
          reply={replyToEdit}
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          refetch={refetchPosts}
          postId={threadPost?.id || ''}
          type={postToEdit ? 'post' : 'reply'}
        />

        <CohortConversationThreadSidebar
          isOpen={isThreadOpen}
          onClose={closeThread}
          post={threadPost}
          refetchThread={refetchThreadData}
          refetchPosts={refetchPostsData}
          currentUserId={currentUser.id}
          openEditPostModal={handleModalOpen}
          openProfileModal={showProfile}
          topic={topic}
          cohortSlug={cohort.slug}
        />

        {!isThreadOpen && !isAfterSpring2023Season && (
          <div className="m-10 hidden h-fit w-full max-w-[576px] flex-col rounded bg-rb-gray-20 p-12 2xl:flex">
            <RfHeader3SemiBold>{topic?.title}</RfHeader3SemiBold>
            <RfParagraphSmall>{topic?.description}</RfParagraphSmall>
          </div>
        )}
      </div>

      {isProfileSidebarOpen && selectedUser?.id && (
        <ProfileModal
          isOpen
          onClose={() => setIsProfileSidebarOpen(false)}
          userId={selectedUser?.id}
        />
      )}
    </CohortConversationTrackingContext.Provider>
  )
}

export default CohortConversationContainer
