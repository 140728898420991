import { Link } from 'react-router-dom-v5-compat'
import { twMerge } from 'tailwind-merge'

import { trackCtaClicked } from 'utils/tracking/analytics'

type SearchSuggestionProps = {
  suggestions: Array<string>
  className?: string
}
export function SearchSuggestionsBox({ suggestions, className }: SearchSuggestionProps) {
  if (!suggestions.length) return null

  return (
    <div className={twMerge('rounded-md border border-rb-gray-100 py-4 px-6', className)}>
      <p className="text-xl font-semibold">More questions</p>
      <ul className="list-none p-0">
        {suggestions.map((suggestion) => (
          <li key={suggestion} className="border-t border-rb-gray-100 py-4">
            <Link
              className="text-md text-rb-teal-200 hover:text-rb-teal-300"
              onClick={() => {
                trackCtaClicked({
                  text: suggestion,
                  cta_location: 'search_suggestions',
                  cta_type: 'link'
                })
              }}
              to={`/search?${new URLSearchParams({ q: suggestion }).toString()}`}
            >
              {suggestion}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
