import Image from 'domains/Sanity/Image'
import { SEOTrackingRelatedIdentifiers } from 'domains/Seo/helpers'

import Button from 'components/Button'

import { trackCtaClicked } from 'utils/tracking/analytics'

import icon1 from 'images/icon--courses-login-key.svg'
import icon2 from 'images/icon--courses-monitor-chart.svg'
import icon3 from 'images/icon--courses-task-list-edit.svg'

const VALUE_PROPS = [
  {
    title: 'Access to the top experts in tech',
    description:
      'Each course is led by seasoned experts in their field, bringing their experience from launching and scaling products at companies such as Meta, Lyft, Amazon, and more.',
    icon: icon1
  },
  {
    title: 'Frameworks grounded in real experience',
    description:
      'Our unique approach uncovers the challenges you face at work, gathers solutions from experts actively tackling them, and breaks it all down into repeatable playbooks you can act on.',
    icon: icon2
  },
  {
    title: 'Accountability and structure',
    description:
      'Through relevant cases, weekly priorities, and a group of peers solving similar challenges, our live course experience is designed to get you to the next level in your career.',
    icon: icon3
  }
]

export interface ValuePropSectionProps {
  tracking: SEOTrackingRelatedIdentifiers
}

export const ValuePropSection = ({ tracking }: ValuePropSectionProps) => {
  const ctaText = 'Explore all courses'
  const ctaHref = '/courses'
  const ctaLocation = 'value prop section'

  const onCtaClick = () => {
    trackCtaClicked({
      cta_type: 'button',
      destination: ctaHref,
      cta_location: ctaLocation,
      text: ctaText.toLowerCase(),
      related_identifiers: {
        sanity_type: tracking.sanityType,
        sanity_id: tracking.sanityId,
        sanity_name: tracking.sanityName
      }
    })
  }

  return (
    <section className="mb-[104px] bg-rb-orange-25 px-4 py-10">
      <div className="mx-auto max-w-[1024px]">
        <h3 className="mb-2 text-2xl font-normal leading-[1.2] md:text-4xl md:leading-[1.4]">
          Get help with your hardest problems from people who’ve been there before
        </h3>

        <div className="mb-6 grid grid-cols-1 gap-4 md:grid-cols-3">
          {VALUE_PROPS.map((valueProp, index) => (
            <ValuePropItem
              key={index}
              title={valueProp.title}
              description={valueProp.description}
              icon={valueProp.icon}
            />
          ))}
        </div>

        <Button color="teal" href={ctaHref} size="small" onClick={onCtaClick}>
          {ctaText}
        </Button>
      </div>
    </section>
  )
}

interface ValuePropItemProps {
  title: string
  description: string
  icon: string
}

const ValuePropItem = ({ title, description, icon }: ValuePropItemProps) => {
  return (
    <div className="flex flex-col rounded-xl border border-rb-gray-100 bg-rb-white px-8 pb-10 pt-6 md:px-10">
      <Image src={icon} className="mb-6 h-16 w-16 self-center p-2 md:h-20 md:w-20" />

      <p className="mb-4 text-xl font-medium leading-[1.4]">{title}</p>
      <p className="mb-0 text-sm leading-[1.5] md:text-base md:leading-[1.6]">
        {description}
      </p>
    </div>
  )
}

export default ValuePropSection
