import BadgeableAvatar from 'components/BadgeableAvatar'
import { SVGIcon } from 'components/Icon'

import { SpeakerFieldsFragment } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { cn } from 'utils/tailwind'
import { LINKEDIN_URL, TWITTER_URL, makeUrl } from 'utils/url'

export interface SpeakerCardProps {
  speaker: {
    profile: SpeakerFieldsFragment
  }
  host?: boolean
  forEventRSVP?: boolean
}

export default function SpeakerCard({
  host,
  speaker,
  forEventRSVP = false
}: SpeakerCardProps) {
  const { isLoggedIn, currentUser } = useCurrentUser()
  const { avatarUrl, profileUrl, fullName, companyName, role, twitter, linkedin } =
    speaker.profile

  function getProfileLink(profileUrl: string | null | undefined): string | null {
    if (!isLoggedIn || !currentUser) {
      if (forEventRSVP) {
        return makeUrl(
          '/login',
          {},
          {
            forEventRSVP: true,
            referer: window.location.pathname
          }
        )
      }

      return null
    }

    if (currentUser.is.paidMember) return profileUrl ?? null

    return null
  }

  const profileLink = getProfileLink(profileUrl)

  const speakerCardContent = (
    <>
      <div className={cn('mr-2 h-14 w-14')}>
        <BadgeableAvatar avatarUrl={avatarUrl} user={speaker} />
      </div>
      <div>
        <div className="flex items-center leading-snug">
          <div className={cn('mr-2 text-xl text-sm font-medium text-rb-gray-500')}>
            {fullName || ''}
          </div>
          {host ? (
            <div className="rounded-md bg-rb-success-50 py-0.5 px-1 text-xs font-normal">
              Host
            </div>
          ) : (
            <SocialIcons linkedInHandle={linkedin} twitterHandle={twitter} />
          )}
        </div>
        <div>
          <div className="text-base leading-4 text-rb-gray-300">
            <div className="text-sm font-normal text-rb-gray-300 sm:text-xs">
              <div>
                {role} {companyName && <span>at</span>}
              </div>
              <div>{companyName}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  return profileLink ? (
    <a className="mr-2 flex pb-4 hover:no-underline" href={profileLink}>
      {speakerCardContent}
    </a>
  ) : (
    <div className="mr-2 flex pb-4">{speakerCardContent}</div>
  )
}

function SocialIcons({
  twitterHandle,
  linkedInHandle
}: {
  twitterHandle?: string | null
  linkedInHandle?: string | null
}) {
  return (
    <>
      {linkedInHandle && (
        <a
          target="_blank"
          rel="noreferrer"
          href={makeUrl(LINKEDIN_URL, { linkedInHandle })}
        >
          <SVGIcon name="linkedin" className="mr-2" />
        </a>
      )}
      {twitterHandle && (
        <a
          target="_blank"
          rel="noreferrer"
          href={makeUrl(TWITTER_URL, { twitterHandle })}
        >
          <SVGIcon name="twitter" className="mr-2" />
        </a>
      )}
    </>
  )
}
