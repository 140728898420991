import { matchPath } from 'react-router-dom'

interface IsPathActiveProps {
  pathname: string
  path: string
  exact?: boolean
  isPathActiveOverride?: (pathname: string) => boolean
}

export function isPathActive({
  pathname,
  path,
  exact,
  isPathActiveOverride
}: IsPathActiveProps) {
  if (isPathActiveOverride) {
    return isPathActiveOverride(pathname)
  }

  // TODO: remove these special cases below for accountpages and learning-goals into
  // their own isPathActiveOverride (or clean up if no longer used)
  if (path === 'accountpages') {
    return ['/account', '/cohort-history'].includes(pathname)
  }

  if (path === 'learning-goals') {
    return pathname.startsWith('/learning-goals')
  }

  return !!matchPath(pathname, {
    path: path,
    exact: exact
  })
}

export default isPathActive
