import { twMerge } from 'tailwind-merge'

import withCharacterCounter from 'components/forms/enhancers/withCharacterCounter'
import withContainer from 'components/forms/enhancers/withContainer'
import withDescription from 'components/forms/enhancers/withDescription'
import withLabel from 'components/forms/enhancers/withLabel'
import withValidation from 'components/forms/enhancers/withValidation'
import { IFormElementBase } from 'components/forms/formTypes'

export const Textarea = ({
  name,
  register,
  rows,
  className,
  ...rest
}: IFormElementBase) => (
  <textarea
    className={twMerge(
      '-mb-1.5 min-h-48 border-0 pl-2 text-black shadow-none outline-none',
      className
    )}
    id={name}
    name={name}
    ref={register}
    rows={rows}
    {...rest}
  />
)

const TextareaWithEnhancers = withValidation(
  withCharacterCounter(withLabel(withDescription(withContainer(Textarea))))
)

export default TextareaWithEnhancers
