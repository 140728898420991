import { useEffect, useState } from 'react'

import SearchModal from 'components/SearchModal'

import { useTeamMembersQuery } from 'gql'

import useDebouncedStringState from 'hooks/useDebouncedStringState'

interface TeamMemberModalProps {
  onClose?: () => void
  isOpen: boolean
  totalMemberCount: number
}

const TeamMemberModal = ({ onClose, isOpen, totalMemberCount }: TeamMemberModalProps) => {
  const [search, setSearch] = useDebouncedStringState()
  const [users, setUsers] = useState<any[]>([])

  const { loading, error, data, fetchMore } = useTeamMembersQuery({
    variables: {
      limit: 40,
      offset: 0,
      search
    },
    nextFetchPolicy: 'network-only',
    skip: !isOpen
  })

  useEffect(() => {
    if (data?.currentUser?.subscriptions?.active?.teamMembers) {
      setUsers(data.currentUser.subscriptions.active.teamMembers.users || [])
    }
  }, [data?.currentUser?.subscriptions?.active?.teamMembers, setUsers])

  const queryCount = data?.currentUser?.subscriptions?.active?.teamMembers?.count || 0
  const hasMoreUsers = queryCount > users.length

  const loadMore = async () => {
    // Hack due to this base query not being on the query_type, thus not being able to really use
    // an effective merge strategy. I'm preventing the fetchMore from firing when the modal is
    // not very populated, since I need to manually merge here
    if (users.length < 5) return

    const { data } = await fetchMore({
      variables: {
        limit: 20,
        offset: users.length,
        search
      }
    })
    if (data.currentUser?.subscriptions?.active?.teamMembers?.users) {
      setUsers([...users, ...data.currentUser?.subscriptions?.active?.teamMembers.users])
    }
  }

  const handleClose = () => {
    // Clear search filter when modal is closed.
    setSearch('')
    onClose?.()
  }

  return (
    <SearchModal
      isOpen={isOpen}
      onClose={handleClose}
      users={users}
      count={totalMemberCount}
      modalTitle="Team Members"
      handleSearch={setSearch}
      hasMoreUsers={hasMoreUsers}
      loadMore={loadMore}
      isLoading={loading}
      error={error}
    />
  )
}

export default TeamMemberModal
