import { closing, opening } from './sharedMarkdown'

const programName = 'Product Management Foundations'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://www.reforge.com/product-management-foundations), because I believe it will teach me valuable tools and processes so I can own products end-to-end. This course was built with [Jiaona Zhang](https://www.reforge.com/experts/jiaona-zhang) and [Anand Subramani](https://www.reforge.com/experts/anand-subramani), and covers:
* **Identifying Opportunities that Create Value.** Learn the three parts of a great product opportunity - strategic fit, customer value, and business impact, and walk through tools to identify and validate them.
* **Designing Features.** Define constraints for your feature/solution based on a validated opportunity, collaborate with design to brainstorm on possible solutions, and leverage user research to arrive at a high-fidelity prototype of what you're going to build. You will also learn how to run a design review to ensure alignment before development.
* **Develop Product Features.** Learn how to define milestones and sequence work to meet business/customer goals, while de-risking future work and learning from each development cycle. You'll also learn how to identify and partner with engineering, design, and other stakeholders through the development process.
* **Launching & Learning.** Learn best practices for launches and how best to work with customer-facing teams to ensure your feature is marketed and adopted. You'll also get a comprehensive overview of the tools needed for effective launches, such as defining success metrics, how to analyze feature performance, and how to communicate learnings.

&NewLine;

${closing}
`

export default { markdown, title: programName }
