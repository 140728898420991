import { useState } from 'react'

import { useCohortConversationTrackingContext } from 'domains/CohortConversation/hooks/useCohortConversationsTrackingContext'

import { useTogglePostFollowMutation } from 'gql'

import { trackFollowAction } from 'utils/tracking/analytics'

interface UseTogglePostFollowProps {
  postId: string
  following: boolean
  refetch?(): Promise<unknown>
  groupIds?: Array<string>
  topicIds?: Array<string>
}
export const useTogglePostFollow = ({
  following,
  postId,
  refetch,
  groupIds = [],
  topicIds = []
}: UseTogglePostFollowProps) => {
  const [isFollowing, setIsFollowing] = useState(following)
  const [loading, setLoading] = useState(false)
  const [togglePostFollow] = useTogglePostFollowMutation()
  const { cohort, topicId, groupId } = useCohortConversationTrackingContext()

  const toggleFollow = async () => {
    setLoading(true)
    const response = await togglePostFollow({
      variables: {
        input: {
          id: postId
        }
      }
    })
    setIsFollowing(response?.data?.togglePostFollow?.following || false)

    trackFollowAction({
      action: response?.data?.togglePostFollow?.following ? 'create' : 'delete',
      cms_program_id: cohort?.cmsProgram?.id,
      cohort_id: cohort?.id,
      group_ids: groupId ? [groupId] : groupIds,
      post_id: postId,
      season_id: cohort?.season?.id,
      topic_ids: topicId ? [topicId] : topicIds
    })

    refetch?.()
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  return { loading, isFollowing, toggleFollow }
}
