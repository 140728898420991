import {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useLocation } from 'react-router-dom'
import { debounce } from 'throttle-debounce'

import {
  IFilterData,
  SORT_LESSON_FIRST_TO_LAST,
  SORT_LESSON_LAST_TO_FIRST,
  SORT_NOTES_NEW_TO_OLD,
  SORT_NOTES_OLD_TO_NEW,
  filtersForQuery
} from 'domains/Bookmarks/BookmarksList'
import { useBookmarkFiltersForTracking } from 'domains/Bookmarks/utils'

import { useUserBookmarksFeedFiltersQuery } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import { trackFilterSavedItems } from 'utils/tracking/generated/events/filterSavedItems'
import { trackSearchSavedItems } from 'utils/tracking/generated/events/searchSavedItems'
import { trackSortSavedItems } from 'utils/tracking/generated/events/sortSavedItems'

import { SortOptionSelection } from './BookmarksFilterSidebar'

export const SEARCH_DEBOUNCE_MS = 1000

interface UseBookmarkFiltersProps {
  cmsProgramId?: string
  cmsProgramName?: string
  filterData: IFilterData
  initialModuleId?: string
  initialModuleName?: string
  initialSectionId?: string
  initialSectionName?: string
  isProjectsConceptsViewer?: boolean
  recentlyDeletedCount: number
  searchText: string
  setSearchText: (text: string) => void
  showProgramsFilter: boolean
  trackingLocation: string
  updateFilterData: (newFilterData: Partial<IFilterData>) => void
}

const FILTER_KEYS = ['Type', 'Program', 'Module'] as const
export type BookmarkFilterKey = (typeof FILTER_KEYS)[number]

const useBookmarkFilters = ({
  cmsProgramId,
  cmsProgramName,
  filterData,
  initialModuleId,
  initialModuleName,
  initialSectionId,
  initialSectionName,
  isProjectsConceptsViewer,
  recentlyDeletedCount,
  searchText,
  setSearchText,
  showProgramsFilter,
  trackingLocation,
  updateFilterData
}: UseBookmarkFiltersProps) => {
  const currentUser = useAssertCurrentUser()
  const [isReloadingModules, setIsReloadingModules] = useState(true)

  const {
    typeFilterForTracking,
    programFilterForTracking,
    moduleFilterForTracking,
    programsFilterIsActive
  } = useBookmarkFiltersForTracking()

  const { pathname } = useLocation()
  const commonTrackingAttrs = useMemo(
    () => ({
      location: trackingLocation,
      path: pathname,
      cms_program_name: cmsProgramName || null,
      cms_program_id: cmsProgramId || null,
      cms_module_name: initialModuleName || null,
      cms_module_id: initialModuleId || null,
      cms_section_name: initialSectionName || null,
      cms_section_id: initialSectionId || null
    }),
    [
      trackingLocation,
      pathname,
      cmsProgramName,
      cmsProgramId,
      initialModuleName,
      initialModuleId,
      initialSectionName,
      initialSectionId
    ]
  )

  const [filters, setFilters] = useState<SortOptionSelection>({})

  const getSelectedProgramId = () => {
    if (cmsProgramId) return cmsProgramId

    if (filterData?.selectedProgramName !== 'All Programs') {
      return filterData?.programOptions?.[filterData?.selectedProgramName]
    }
  }
  const selectedProgramId = getSelectedProgramId()

  const typeOptions = isProjectsConceptsViewer
    ? ['All Types', 'Text', 'Image']
    : ['All Types', 'Text', 'Image', 'Video']
  const { moduleOptions, programOptions } = filterData

  const { data, loading, error } = useUserBookmarksFeedFiltersQuery({
    variables: {
      userId: currentUser.id,
      filters: filtersForQuery({
        filterData,
        cmsProgramId,
        searchText,
        initialModuleId,
        initialSectionId
      }),
      moduleFilters: { cmsProgramId: selectedProgramId, cmsModuleId: initialModuleId }
    }
  })

  const bookmarksCount = data?.user?.bookmarksTotal || 0
  const trueCount = bookmarksCount - recentlyDeletedCount

  const moduleList = data?.user?.bookmarkCmsModules || []
  const programList = data?.user?.bookmarkCmsPrograms || []

  useEffect(() => {
    if (moduleList.length === 0 && programList.length === 0) {
      return
    }

    const moduleOptions = moduleList.reduce(
      (moduleOptions, cmsModule) => {
        moduleOptions[cmsModule.name] = cmsModule.id
        return moduleOptions
      },
      {} as Record<string, string>
    )

    const programOptions = programList.reduce(
      (programOptions, cmsProgram) => {
        programOptions[cmsProgram.name] = cmsProgram.id
        return programOptions
      },
      {} as Record<string, string>
    )

    updateFilterData({
      moduleOptions,
      programOptions
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleList, programList])

  const handleFilterOptionChange = useCallback(
    (selectedOptions: SortOptionSelection) => {
      let sidebarFilterRefreshToggle = filterData?.sidebarFilterRefreshToggle
      let selectedSortOption = filterData?.selectedSortOption
      let selectedModuleOption = selectedOptions.Module || 'All Modules'
      const selectedProgramOption = selectedOptions.Program || 'All Programs'
      const selectedTypeOption = selectedOptions.Type || 'All Types'

      if (
        filterData.selectedModuleName !== selectedModuleOption ||
        filterData.selectedProgramName !== selectedProgramOption ||
        filterData.selectedType !== selectedTypeOption
      ) {
        if (!cmsProgramId) {
          const resettingProgram = selectedProgramOption === 'All Programs'
          const programChanged = filterData?.selectedProgramName !== selectedProgramOption
          const isLessonSort =
            selectedSortOption === SORT_LESSON_LAST_TO_FIRST ||
            selectedSortOption === SORT_LESSON_FIRST_TO_LAST

          if (programChanged || resettingProgram) {
            selectedModuleOption = 'All Modules'
            sidebarFilterRefreshToggle = !filterData.sidebarFilterRefreshToggle
            setIsReloadingModules(true)
          }

          if (resettingProgram && isLessonSort) {
            selectedSortOption = SORT_NOTES_NEW_TO_OLD
          }
        }

        updateFilterData({
          selectedType: selectedTypeOption,
          selectedModuleName: selectedModuleOption,
          selectedProgramName: selectedProgramOption,
          sidebarFilterRefreshToggle,
          selectedSortOption,
          currentPage: 1
        })
      }

      trackFilterSavedItems({
        ...commonTrackingAttrs,
        type_filter: typeFilterForTracking(selectedTypeOption),
        program_filter: showProgramsFilter
          ? programFilterForTracking(selectedProgramOption)
          : null,
        module_filter: initialModuleName || moduleFilterForTracking(selectedModuleOption)
      })
    },
    [
      cmsProgramId,
      commonTrackingAttrs,
      filterData,
      initialModuleName,
      moduleFilterForTracking,
      programFilterForTracking,
      showProgramsFilter,
      typeFilterForTracking,
      updateFilterData
    ]
  )

  useEffect(() => {
    handleFilterOptionChange(filters)
  }, [filters, handleFilterOptionChange])

  const options: Record<BookmarkFilterKey, string[][]> = {
    Type: typeOptions.filter((x) => x !== 'All Types').map((x) => [x, x]),
    Program: Object.keys(programOptions).map((x) => [x, x]),
    Module: Object.keys(moduleOptions).map((x) => [x, x])
  }

  // The ternary for type exists due to limitations with filtering on bookmark type
  // We currently don't support multi-select based on bookmark type
  // It can be changed to only 'checkbox' as soon as multi-select is supported
  const filterOptions = FILTER_KEYS.map((key) => ({
    key,
    type: key === 'Type' ? ('radio' as const) : ('checkbox' as const),
    title: key,
    options: options[key]
  }))
    .filter((x) => !(x.title === 'Module' && (isReloadingModules || !filters.Program)))
    .filter((x) => {
      const shouldOnlyDisplayTypeFilter =
        pathname.includes('/programs/') ||
        pathname.includes('/c/') ||
        pathname.includes('/cohorts/') ||
        pathname.includes('/m/')
      if (shouldOnlyDisplayTypeFilter) {
        return x.key === FILTER_KEYS['0'] // REF-6988: Remove Program/Module filter at Program/Cohort level
      } else return x
    })

  const getActiveFilterCount = () => {
    let activeFilterCount = 0
    if (filterData.selectedType !== 'All Types') activeFilterCount += 1
    if (filterData.selectedModuleName !== 'All Modules') activeFilterCount += 1
    if (programsFilterIsActive(filterData.selectedProgramName, cmsProgramId)) {
      activeFilterCount += 1
    }
    if (searchText.trim().length > 0) activeFilterCount += 1
    return activeFilterCount
  }
  const activeFilterCount = getActiveFilterCount()
  const handleSearchSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
  }

  const updateSearchText = (updatedSearchText: string = '') => {
    setSearchText(updatedSearchText.toLowerCase())
    updateFilterData({ currentPage: 1 })
  }

  const debouncedUpdateSearchText = debounce(SEARCH_DEBOUNCE_MS, updateSearchText)
  const handleSearchTextChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    debouncedUpdateSearchText(e.target.value)
  }

  const prevSearchText = useRef('')

  useEffect(() => {
    if (searchText.length > 1 && prevSearchText.current !== searchText) {
      trackSearchSavedItems({
        ...commonTrackingAttrs,
        search_query: searchText
      })
    }
    prevSearchText.current = searchText
  }, [commonTrackingAttrs, searchText])

  const handleSortOptionChange = (selected: string) => {
    if (filterData.selectedSortOption !== selected) {
      updateFilterData({ selectedSortOption: selected })
    }

    trackSortSavedItems({
      ...commonTrackingAttrs,
      sort_by: selected
    })
  }

  const sortOptions =
    isProjectsConceptsViewer || !selectedProgramId
      ? [SORT_NOTES_NEW_TO_OLD, SORT_NOTES_OLD_TO_NEW]
      : [
          SORT_NOTES_NEW_TO_OLD,
          SORT_NOTES_OLD_TO_NEW,
          SORT_LESSON_FIRST_TO_LAST,
          SORT_LESSON_LAST_TO_FIRST
        ]

  const { selectedSortOption } = filterData

  useEffect(() => {
    if (!loading) setIsReloadingModules(false)
  }, [loading])

  return {
    error,
    loading,
    trueCount,
    filterProps: {
      activeFilterCount,
      filters,
      setFilters,
      filterKeys: FILTER_KEYS,
      filterOptions
    },
    sortProps: {
      sortOptions,
      selectedSortOption
    },
    handlers: {
      handleSearchSubmit,
      handleSearchTextChanged,
      handleSortOptionChange
    }
  }
}

export default useBookmarkFilters
