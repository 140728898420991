import ReactionButtons from 'components/ReactionButtons'

import { PostShowFieldsFragment } from 'gql'

import { useReactions } from 'hooks/useReactions'

import { TLegacyOrGraphQLReaction } from 'utils/reactionUtils'

interface ReactionsProps {
  reactions: TLegacyOrGraphQLReaction[]
  currentUserId: string
  reactableId: string
  reactableType: string
  reactablePost: PostShowFieldsFragment
}

const Reactions = ({
  reactions,
  currentUserId,
  reactableId,
  reactableType,
  reactablePost
}: ReactionsProps) => {
  const { savedReactions } = useReactions({
    reactions,
    currentUserId,
    reactableId,
    reactableType,
    reactablePost
  })

  return (
    <div className="uk-margin-top">
      <div className="uk-width-1-1 uk-margin-bottom ml-[-7.5px] flex flex-row justify-between">
        <ReactionButtons
          reactions={savedReactions}
          currentUserId={currentUserId}
          removeReaction={() => {}}
          addReaction={() => {}}
          reactableType={reactableType}
          reactableId={reactableId}
          disabled={true}
          readOnly={true}
        />
      </div>
    </div>
  )
}

export default Reactions
