import * as React from 'react'

import { ContentTocItemPartsFragment } from 'gql'

import { track } from 'utils/tracking/segment'

const scrollToItem = (e: React.MouseEvent<HTMLElement>, item: ItemWithProgress) => {
  e.preventDefault()
  const phaseElement = document.getElementById(`phase-${item.phaseNumber}`)

  if (phaseElement) {
    phaseElement.classList.remove('uk-hidden')
    const phases = document.querySelectorAll('.phase')
    const relatedContentDiv = document.getElementById('js-related-content')
    const feedbackDiv = document.getElementById('js-content-feedback')
    const summaryDiv = document.getElementById('js-content-summary')
    const endContentDiv = document.getElementById('end-center-content')
    phases.forEach((phEl) => {
      if (phEl.id !== phaseElement.id) {
        phEl.classList.add('uk-hidden')
      }
    })

    if (phases[phases.length - 1].id === phaseElement.id) {
      if (relatedContentDiv) {
        relatedContentDiv.classList.remove('uk-hidden')
      }
      if (feedbackDiv) {
        feedbackDiv.classList.remove('uk-hidden')
      }
      if (summaryDiv) {
        summaryDiv.classList.remove('uk-hidden')
      }
      if (endContentDiv) {
        endContentDiv.classList.remove('uk-hidden')
      }
    } else {
      if (relatedContentDiv) {
        relatedContentDiv.classList.add('uk-hidden')
      }
      if (feedbackDiv) {
        feedbackDiv.classList.add('uk-hidden')
      }
      if (summaryDiv) {
        summaryDiv.classList.add('uk-hidden')
      }
      if (endContentDiv) {
        endContentDiv.classList.remove('uk-hidden')
      }
    }
  }

  if (item.href && window.location.hash !== item.href) {
    let topOffset = 0
    const stickyTop = document.getElementById('sticky-top')
    if (stickyTop) {
      const boundingRect = stickyTop.getBoundingClientRect()
      topOffset = boundingRect.height
    }
    window.location.hash = item.href
    window.scrollBy(0, -topOffset)
  }
}
interface IItemLink {
  item: ItemWithProgress
  isParentOpen: boolean
}

const ItemLink = ({ item, isParentOpen }: IItemLink) => {
  const {
    name,
    completed,
    current,
    numBookmarks,
    contentTypeIcon,
    estimatedTime,
    parentId
  } = item
  const derivedClassNames = []

  if (completed) {
    derivedClassNames.push(
      'cms-sidebar__lesson--completed !border-rb-green-100 !text-rb-gray-300 !opacity-100'
    )
  }
  if (current) {
    derivedClassNames.push(
      'cms-sidebar__lesson--current relative font-medium !text-rb-gray-500 bg-white border-rb-gray-100 opacity-60 !no-underline hover:bg-white'
    )
  }

  return (
    <li
      className={`!my-1 cursor-pointer font-sans !text-[15px] text-rb-gray-500 ${
        isParentOpen && parentId ? 'pl-2.5 xs:pl-4 md:pl-8' : ''
      }`}
    >
      <div className="uk-grid uk-grid-collapse items-center">
        <div
          className={`uk-width-auto mr-1 h-[30px] rounded border-l-[5px] ${
            completed ? 'border-rb-green-100' : ''
          }`}
        ></div>
        <a
          href={item.href}
          onClick={(e) => {
            // @ts-ignore - 'Content Viewer - Action' event is not defined in Segment JIRA#REF-5159
            track('Content Viewer - Action', {
              action: 'ToC__click_item',
              location: window.location.pathname,
              item: name,
              cms_section_id: item.id
            })
            scrollToItem(e, item)
          }}
          className="uk-width-expand block rounded-l hover:bg-rb-gray-100 hover:bg-opacity-[35%] hover:!text-rb-gray-50"
        >
          <div
            className={`uk-grid uk-grid-collapse cms-sidebar__lesson bg-[rgba(229, 229, 229, 0.5)] mb-[1px] h-[30px] items-center border-solid border-rb-gray-100 pr-0 leading-[1.2] !text-rb-gray-500 hover:!text-rb-gray-500 ${derivedClassNames.join(
              ' '
            )}`}
          >
            <div
              className="cms-sidebar__icon uk-width-auto mt-[3px] block"
              uk-icon={contentTypeIcon}
            />
            <div className="uk-width-expand pl-[6px] !text-xs leading-4">{name}</div>
            {!!numBookmarks && (
              <div className="uk-width-auto flex pr-[5px] pl-[5px] text-right text-xs font-medium text-rb-gray-500 opacity-0 2xs:opacity-100">
                {numBookmarks}
                <div
                  className="bookmark-toggle flex"
                  data-bookmarked="true"
                  uk-icon="icon: bookmark; ratio: 0.7"
                />
              </div>
            )}
            {estimatedTime !== '' && (
              <div className="uk-width-auto pr-2.5 text-xs xs:pr-4 md:pr-8  ">
                {estimatedTime}
              </div>
            )}
          </div>
        </a>
      </div>
    </li>
  )
}

type ItemWithProgress = Omit<ContentTocItemPartsFragment, 'href'> & {
  href: string
  completed: boolean
}

interface ITocItem {
  item: ItemWithProgress
  isParent?: boolean
  isOpen?: boolean
  onToggle?: () => void
}

const TocItem = ({
  item,
  isParent = false,
  isOpen = false,
  onToggle = () => {}
}: ITocItem) => {
  const { name, completed, numBookmarks, estimatedTime } = item

  return (
    <>
      {isParent && item.href && (
        <li>
          <a
            href={item.href}
            onClick={(e) => {
              // @ts-ignore - 'Content Viewer - Action' event is not defined in Segment JIRA#REF-5159
              track('Content Viewer - Action', {
                action: 'ToC__click_item',
                location: window.location.pathname,
                item: name,
                cms_section_id: item.id
              })
              scrollToItem(e, item)
            }}
          >
            <div
              className={`uk-grid uk-grid-collapse uk-accordion-title cms-sidebar__title px-0 py-2 !text-sm font-medium !text-rb-gray-500 hover:bg-rb-gray-100 hover:bg-opacity-[35%] ${
                completed
                  ? 'cms-sidebar__title--completed decoration-[rgba(130, 130, 130, 0.5)] !border-rb-green-100 !text-rb-gray-300'
                  : ''
              } ${
                isOpen ? 'ml-[5px] rounded-l !border-l-0' : 'cms-sidebar__title--closed'
              }`}
            >
              <i
                onClick={onToggle}
                className="uk-width-auto"
                uk-icon={isOpen ? 'triangle-down' : 'triangle-right'}
              />
              <div className="uk-width-expand">{name}</div>
              {!isOpen && !!numBookmarks && (
                <div className="uk-width-auto flex pr-[5px] pl-[5px] text-right text-xs font-medium text-rb-gray-500 opacity-0 2xs:opacity-100">
                  {numBookmarks}
                  <div
                    className="bookmark-toggle flex"
                    data-bookmarked="true"
                    uk-icon="icon: bookmark; ratio: 0.7"
                  />
                </div>
              )}
              {!isOpen && !!estimatedTime && (
                <div className="uk-width-auto pr-2.5 text-sm xs:pr-4 md:pr-8  ">
                  {estimatedTime}
                </div>
              )}
            </div>
          </a>
        </li>
      )}
      {!isParent && <ItemLink item={item} isParentOpen={isOpen} />}
    </>
  )
}

export default TocItem
