import { useLocation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { Alert } from 'components/Alert'
import { Counter } from 'components/CheckoutModal/components/Counter/Counter'
import Tooltip from 'components/Tooltip/Tooltip'
import { BasicInfoIcon } from 'components/icons'

import { CohortPassPriceTier, PlanName } from 'gql'

import { formatMoney } from 'utils/moneyUtils'

import { getCohortPassPrice } from '../utils'
import { InnerSectionHeading, SectionHeading } from './Headings'
import { SectionBorder } from './SectionBorder'

const PlusSalesTaxLabel = ({ className }: { className?: string }) => {
  return (
    <div className={twMerge('text-base font-medium text-rb-gray-300 ', className)}>
      + sales tax
    </div>
  )
}

const CohortPassPriceTierSection = ({
  cohortPassPriceTiers
}: {
  cohortPassPriceTiers: CohortPassPriceTier[]
}) => {
  return (
    <div className="flex flex-col whitespace-nowrap text-base">
      {cohortPassPriceTiers?.map(({ minCount, maxCount, pricePerPass }) => (
        <div key={maxCount}>{cohortPriceInfo({ minCount, maxCount, pricePerPass })}</div>
      ))}
    </div>
  )
}

type PlanMapType = {
  [PlanName.INDIVIDUAL]: 0
  [PlanName.STARTER]: number
  [PlanName.SCALE]: number
  [PlanName.ENTERPRISE]: 0
  [PlanName.EXPIRED]: 0
  [PlanName.UNKNOWN]: 0
  [PlanName.UNLIMITED]: 0
}
// TODO: This would be better in elsewhere
const planNameToMinPassMap: PlanMapType = {
  [PlanName.INDIVIDUAL]: 0,
  [PlanName.STARTER]: 2,
  [PlanName.SCALE]: 6,
  [PlanName.ENTERPRISE]: 0,
  [PlanName.EXPIRED]: 0,
  [PlanName.UNKNOWN]: 0,
  [PlanName.UNLIMITED]: 0
}

export interface CohortPassSectionProps {
  isOptingInToDiscount?: boolean
  cohortPassPriceTiers: CohortPassPriceTier[]
  onCountChange(value: number): void
  count: number
  location: 'checkout-modal' | 'confirm-plan-modal' | 'select_add_ons_page'
  showIsRequiredError?: boolean
  subtitleText?: string
  titleText?: string
  planName: PlanName | null
  defaultPassCount: number
}

export const CohortPassSection = ({
  isOptingInToDiscount,
  cohortPassPriceTiers,
  count,
  onCountChange,
  location,
  showIsRequiredError,
  subtitleText,
  titleText,
  planName,
  defaultPassCount
}: CohortPassSectionProps) => {
  const { pathname } = useLocation()
  const bundlePasses = !pathname.includes('manage-plan')

  const pricePerPass = getCohortPassPrice({ cohortPassPriceTiers, count })

  const amountToDisplay = (() => {
    if (location === 'checkout-modal' || location === 'select_add_ons_page') {
      return (
        <>
          {formatMoney(pricePerPass)} <span className="text-black"> / pass</span>
        </>
      )
    }
    if (location === 'confirm-plan-modal') return formatMoney(pricePerPass * count)

    return formatMoney(pricePerPass)
  })()

  const getInfoBoxContent = (planName: PlanName | null) => {
    if (!planName) return null
    if (bundlePasses) {
      return `${
        planName?.charAt(0).toUpperCase() + planName?.slice(1)
      } plans include ${defaultPassCount} Live Course Passes.`
    }

    if (planName === PlanName.INDIVIDUAL) {
      return 'Individuals typically buy at least 1 Live Course Pass.'
    } else {
      return `Teams on the ${planName?.charAt(0).toUpperCase() + planName?.slice(1)} plan
        typically buy at least ${defaultPassCount} Live Course Passes.`
    }
  }

  return (
    <div>
      <SectionHeading>{titleText}</SectionHeading>
      <p
        className={twMerge(
          'text-sm',
          location === 'select_add_ons_page' ? 'mb-6' : 'mb-2'
        )}
      >
        {subtitleText}
      </p>
      <SectionBorder className="block" hasError={showIsRequiredError}>
        <div className="flex flex-col gap-4">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col space-y-4">
              <div className="flex flex-row items-center">
                <InnerSectionHeading>
                  <span id="cohort-passes">Live Course Passes</span>
                </InnerSectionHeading>
                <Tooltip
                  tooltipBody={
                    <div className="w-[212px]">
                      Live Course Passes are required to enroll an in upcoming live
                      course.
                    </div>
                  }
                  place="bottom"
                  contentWrapperClassname="text-rb-gray-300 hover:text-rb-gray-300"
                >
                  <BasicInfoIcon className="ml-2 fill-rb-black" fill="0F0F0F" />
                </Tooltip>
              </div>

              {!isOptingInToDiscount && (
                <CohortPassPriceTierSection cohortPassPriceTiers={cohortPassPriceTiers} />
              )}
              <div className="inline-flex md:hidden">
                <Counter
                  count={count}
                  onCountChange={onCountChange}
                  dataTest="cohort-pass-counter-md"
                  ariaLabel="Live Course Passes"
                  ariaLabelledBy="cohort-passes"
                  minCount={planName && bundlePasses ? planNameToMinPassMap[planName] : 0}
                />
              </div>
            </div>

            {isOptingInToDiscount ? (
              <div className="pl-8">
                <div className="pb-0.5 text-right font-semibold xs:text-left">
                  <span className="text-rb-teal-200">$995</span>
                  <PlusSalesTaxLabel className="text-sm" />
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-between">
                {count === 0 ? (
                  <div className="h-[38px]" />
                ) : (
                  <div className="h-[38px] text-right text-xl font-semibold text-rb-teal-200 xs:text-left">
                    {amountToDisplay}
                    <PlusSalesTaxLabel />
                  </div>
                )}
                <div className="hidden justify-end md:inline-flex">
                  <Counter
                    count={count}
                    onCountChange={onCountChange}
                    dataTest="cohort-pass-counter"
                    ariaLabel="Live Course Passes"
                    ariaLabelledBy="cohort-passes"
                    minCount={
                      planName && bundlePasses ? planNameToMinPassMap[planName] : 0
                    }
                  />
                </div>
              </div>
            )}
          </div>
          {!!planName && (
            <div
              className={`flex w-full rounded-md ${
                bundlePasses
                  ? 'bg-rb-blue-50'
                  : 'border border-rb-green-75 bg-rb-green-50'
              } p-2 text-sm`}
            >
              {getInfoBoxContent(planName)}
            </div>
          )}
        </div>
        {showIsRequiredError && (
          <Alert className="mt-4">Please add a Live Course Pass</Alert>
        )}
      </SectionBorder>
    </div>
  )
}

// helpers

const cohortPriceInfo = ({
  minCount,
  maxCount,
  pricePerPass
}: {
  minCount: number
  maxCount: number
  pricePerPass: number
}): string => {
  if (maxCount === 1) {
    return `${maxCount} = ${formatMoney(pricePerPass)} per pass`
  } else {
    return `${minCount} – ${maxCount} = ${formatMoney(pricePerPass)} per pass`
  }
}
