import CareersBottomCards from 'domains/Sanity/marketingSite/Careers/CareersBottomCards'
import CareersOpenPositions from 'domains/Sanity/marketingSite/Careers/CareersOpenPositions'
import MarketingHero from 'domains/Sanity/marketingSite/MarketingHero'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { Loading } from 'components'

import { Page, useOpenPositionsPageQuery } from 'gql'

import TopProgPinkOrange from 'images/Top_Prog_Pink-Orange.png'

const OpenPositionsPage = () => {
  const { data, loading } = useOpenPositionsPageQuery()
  const page = (data?.openPositionsPage || {}) as Page

  return (
    <MarketingLayout page={page}>
      {loading ? <Loading /> : <OpenPositionsPageContent />}
    </MarketingLayout>
  )
}

export const OpenPositionsPageContent = () => {
  return (
    <>
      <MarketingHero
        headerText="Open Positions"
        bgImage={{
          url: TopProgPinkOrange,
          alt: ''
        }}
      />

      <section className="px-[16px] pt-[4vmax] md:px-[4vw]">
        <CareersOpenPositions pathname="open-positions" />
      </section>

      <CareersBottomCards />
    </>
  )
}

export default OpenPositionsPage
