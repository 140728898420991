import produce from 'immer'

import {
  BookmarkFoldersDocument,
  BookmarkFoldersQuery,
  useForkBookmarkFolderMutation
} from 'gql'

export const useForkBookmarkFolder = () => {
  const [forkBookmarkFolder] = useForkBookmarkFolderMutation({
    update: (cache, mutationResult) => {
      const forkedBookmarkFolder = mutationResult.data?.forkBookmarkFolder?.bookmarkFolder
      if (!forkedBookmarkFolder) return

      const existingCollections = cache.readQuery<BookmarkFoldersQuery>({
        query: BookmarkFoldersDocument
      })
      if (!existingCollections) return

      const newCollectionsData = produce(existingCollections, (draft) => {
        draft.currentUser?.bookmarkFolders.unshift(forkedBookmarkFolder)
      })

      cache.writeQuery<BookmarkFoldersQuery>({
        query: BookmarkFoldersDocument,
        data: newCollectionsData
      })
    }
  })
  return { forkBookmarkFolder }
}
