import { useGlobalState } from 'state'

import Button from 'components/Button'
import Loading from 'components/Loading'
import { ModalContent } from 'components/Modal'
import FullScreenConfetti from 'components/animations/FullScreenConfetti'
import { LinkedIn, TwitterBird } from 'components/icons'

import { CompletionSnapshot, useUpdateUserCohortMutation } from 'gql'

import { capitalizeFirstLetter } from 'utils/stringUtils'
import { track } from 'utils/tracking/segment'

export interface ShareableSnapshotModalContentProps {
  snapshotData?: CompletionSnapshot
  page: string
  showConfetti?: boolean
}

export const ShareableSnapshotModalContent = ({
  snapshotData,
  page,
  showConfetti = false
}: ShareableSnapshotModalContentProps) => {
  const [, setHasClickedShareSnapshotCta] = useGlobalState('hasClickedShareSnapshotCta')
  const [updateUserCohort] = useUpdateUserCohortMutation()

  const handleLinkedInClick = () => {
    if (snapshotData) {
      updateSnapshotAsShared()
      const utmData =
        'utm_campaign=completion_snapshot&utm_medium=social&utm_source=linkedin'
      const urlWithUtmData = encodeURIComponent(
        `${snapshotData.snapshotShareUrl}?${utmData}`
      ) // LinkedIn needs an encoded URI if params are present
      // @ts-ignore - 'Shareable Snapshot Button Clicked' event is not defined in Segment JIRA#REF-5159
      track('Shareable Snapshot Button Clicked', {
        page: page,
        user_cohort_id: snapshotData.userCohortId,
        platform: 'LinkedIn',
        cohort_id: snapshotData.cohortId
      })
      window.open(
        `https://www.linkedin.com/sharing/share-offsite/?url=${urlWithUtmData}`,
        '_blank',
        'width=500,height=730,toolbar=no,location=no,status=no,menubar=no'
      )
    }
  }

  const handleTwitterClick = () => {
    updateSnapshotAsShared()
    if (snapshotData?.snapshotShareUrl) {
      const utmData =
        'utm_campaign=completion_snapshot&utm_medium=social&utm_source=twitter'
      const urlWithUtmData = `${snapshotData.snapshotShareUrl}?${utmData}`
      // @ts-ignore - 'Shareable Snapshot Button Clicked' event is not defined in Segment JIRA#REF-5159
      track('Shareable Snapshot Button Clicked', {
        page: page,
        user_cohort_id: snapshotData.userCohortId,
        platform: 'Twitter',
        cohort_id: snapshotData.cohortId
      })

      const twitterShareText = encodeURIComponent(
        `That’s a wrap! I completed the Reforge ${snapshotData.programName} Program! ${snapshotData.hashtag} ${urlWithUtmData}`
      )
      window.open(`https://twitter.com/intent/tweet?text=${twitterShareText}`)
    }
  }

  const updateSnapshotAsShared = () => {
    setHasClickedShareSnapshotCta(true)
    if (snapshotData) {
      updateUserCohort({
        variables: {
          input: {
            id: snapshotData.userCohortId,
            hasClickedShareSnapshotCta: true
          }
        }
      })
    }
  }

  return (
    <ModalContent className="w-[320px] px-2 pb-8 pt-0 text-center tl:w-[800px] tl:px-8 tl:pt-0">
      <div data-tes="shareable-snapshot" className="flex flex-col items-center">
        <div className="mb-1 mt-0 flex flex-col font-semibold leading-6 tl:mb-2.5 tl:flex-row tl:text-2xl">
          <div>Congrats!&nbsp;</div>
          {snapshotData?.seasonName && (
            <div>
              You completed the{' '}
              {capitalizeFirstLetter(
                snapshotData.seasonName.split('-').reverse().join(' ')
              )}{' '}
              Course.
            </div>
          )}
        </div>
        <div className="mb-6 text-[10px] uppercase tracking-widest text-rb-gray-400 tl:text-base">
          {!snapshotData && <span>&nbsp;</span>}
          {snapshotData && <span>Share your achievement with your peers.</span>}
        </div>
        <div
          id="shareable-snapshot"
          className="relative mb-20 h-[148px] w-[278px] border-white p-2 shadow-card tl:mb-0 tl:h-[396px] tl:w-[745px] tl:p-4"
          style={{
            background: `url(${snapshotData?.imagePath}) no-repeat`
          }}
        >
          {!snapshotData && <Loading />}
          {snapshotData && !snapshotData.snapshotShareUrl && (
            <Loading ghosted className="mt-2.5 tl:mt-[140px]" />
          )}
        </div>
        <div className="tl:mt-7 tl:flex tl:flex-row tl:items-center tl:justify-center">
          <Button
            data-test="shareable-snapshot-linkedin-button"
            className="mb-4 tl:mb-0 tl:mr-4"
            size="small"
            iconBefore={<LinkedIn fill="white" />}
            onClick={handleLinkedInClick}
            disabled={!snapshotData?.snapshotShareUrl}
          >
            Share on LinkedIn
          </Button>
          <Button
            data-test="shareable-snapshot-twitter-button"
            className="w-full tl:w-auto"
            size="small"
            iconBefore={<TwitterBird fill="white" />}
            onClick={handleTwitterClick}
            disabled={!snapshotData?.snapshotShareUrl}
          >
            Share on Twitter
          </Button>
        </div>
      </div>
      {showConfetti && <FullScreenConfetti />}
    </ModalContent>
  )
}
