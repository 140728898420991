import Banner from 'components/Banner'
import Button from 'components/Button'

import { isBetweenDates } from 'utils/date'
import { cn } from 'utils/tailwind'

const NewProgramIcons = () => (
  <svg
    width="347"
    height="138"
    viewBox="0 0 347 138"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>New Programs</title>
    <g filter="url(#filter0_d_238_46663)">
      <circle cx="68.059" cy="65.059" r="60.059" fill="white" />

      <rect x="64.58" y="53.887" width="24.3612" height="46.0157" fill="#D3D2D3" />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.65">
        <rect x="43.8276" y="49.8268" width="25.2635" height="39.6998" fill="#1E87F0" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <rect
          x="96.6104"
          y="42.6087"
          width="18.0454"
          height="60.452"
          transform="rotate(90 96.6104 42.6087)"
          fill="#1E87F0"
        />
      </g>
    </g>
    <g filter="url(#filter2_d_238_46663)">
      <circle cx="174.059" cy="64.059" r="60.059" fill="white" />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path d="M203 44.2573L203 91.9477H142.454L203 44.2573Z" fill="#D3D2D3" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path d="M142.454 52.966L142.454 91.9477H203L142.454 52.966Z" fill="#6DB1F5" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M187.573 43.6821L184.04 72.0071L177.843 61.0635L165.274 61.5025L187.573 43.6821Z"
          fill="#1E87F0"
        />
      </g>
    </g>
    <g filter="url(#filter3_d_238_46663)">
      <circle cx="278.059" cy="64.059" r="60.059" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M278.947 43.9381C267.893 43.9381 258.933 52.8985 258.933 63.9518C258.933 75.0051 267.893 83.9655 278.947 83.9655C290 83.9655 298.96 75.0051 298.96 63.9518H314.875C314.875 83.7945 298.789 99.8801 278.947 99.8801C259.104 99.8801 243.018 83.7945 243.018 63.9518C243.018 44.1092 259.104 28.0236 278.947 28.0236V43.9381Z"
        fill="#91BDEA"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M294.085 58.9536L274.451 78.3569L262.957 66.9807C260.107 69.9104 255.403 74.5726 251.163 78.7451C248.647 81.2211 246.285 83.5347 244.551 85.2291C243.684 86.0764 242.975 86.769 242.482 87.2498C242.235 87.4902 242.043 87.6777 241.912 87.8051L241.712 87.9999C241.712 87.9999 241.712 88.0001 240.366 86.6179C239.021 85.2358 239.021 85.2356 239.021 85.2356L239.021 85.235L239.22 85.0419C239.35 84.9149 239.542 84.7278 239.788 84.4878C240.28 84.0078 240.989 83.3162 241.854 82.4701C243.586 80.7777 245.945 78.4674 248.457 75.9953C253.494 71.038 259.104 65.4702 261.519 62.9088L262.875 61.4708L274.453 72.9306L291.374 56.2094L294.085 58.9536Z"
          fill="#D3D2D3"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M279.048 43.9383C290.055 43.9928 298.961 52.9323 298.961 63.9518C298.961 75.005 290 83.9655 278.947 83.9655V99.88C298.79 99.88 314.875 83.7944 314.875 63.9518C314.875 44.1429 298.844 28.0783 279.048 28.0237V43.9383Z"
          fill="#1B79D7"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_238_46663"
        x="0"
        y="1"
        width="136.118"
        height="136.118"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0588235 0 0 0 0 0.0588235 0 0 0 0 0.0588235 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_238_46663"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_238_46663"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_238_46663"
        x="106"
        y="0"
        width="137.118"
        height="137.118"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0588235 0 0 0 0 0.0588235 0 0 0 0 0.0588235 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_238_46663"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_238_46663"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_238_46663"
        x="106"
        y="0"
        width="136.118"
        height="136.118"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0588235 0 0 0 0 0.0588235 0 0 0 0 0.0588235 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_238_46663"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_238_46663"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d_238_46663"
        x="210"
        y="0"
        width="136.118"
        height="136.118"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0588235 0 0 0 0 0.0588235 0 0 0 0 0.0588235 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_238_46663"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_238_46663"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

const NewProgramBannerContent = () => (
  <div className="flex flex-row items-center lg:grid-cols-2">
    <div className="grow">
      <p className="mb-2 text-sm font-medium uppercase tracking-widest text-black">
        Spring 2022 Cohort
      </p>
      <h4 className="mb-2 text-rb-gray-500 xs:text-lg sm:text-2xl">
        3 brand new programs are coming to Reforge!
      </h4>
      <p className="font-normal text-rb-gray-400">
        Live programs begin the week of March 21. Enrollment starts in early February.
      </p>
      <Button
        onClick={() => {
          window.open('https://www.reforge.com/blog/announcing-spring-22-cohort')
        }}
        size="small"
        shape="rounded-full"
        className="h-8 bg-rb-gray-500 text-white hover:border-none hover:border-white hover:bg-rb-blue-100 hover:text-white tl:h-11"
      >
        Learn More
      </Button>
    </div>
    <div className="hidden flex-none lg:block">
      <NewProgramIcons />
    </div>
  </div>
)

export interface NewProgramBannerProps {
  hasBorder?: boolean
  startDate?: string
  endDate?: string
  className?: string
}

export default function NewProgramBanner({
  hasBorder,
  startDate = 'Fri, 03 Dec 2021 00:00:00 GMT',
  endDate = 'Fri, 31 Dec 2021 23:59:59 GMT',
  className
}: NewProgramBannerProps) {
  const isBannerDisplayed = isBetweenDates(startDate, endDate)
  const bannerClasses = `${hasBorder ? 'border-b-2 border-white' : ''}`

  return isBannerDisplayed ? (
    <Banner className={cn(bannerClasses, className)} dismissable={false}>
      <NewProgramBannerContent />
    </Banner>
  ) : null
}
