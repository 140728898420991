import WistiaPlayer from 'domains/Cms/WistiaPlayer'

import AvatarWithBadge from 'components/AvatarWithBadge'
import Link from 'components/Link'
import { Modal, ModalContent, ModalHeader } from 'components/Modal'
import { CloseIcon } from 'components/icons'

import { ClipPartsFragment } from 'gql'

import { onEnterKeyPress } from 'utils/keyboard'

interface ClipViewerModalProps {
  clip: ClipPartsFragment | null
  handleClose: () => void
  isOpen: boolean
}

export default function ClipViewerModal({
  clip,
  handleClose,
  isOpen
}: ClipViewerModalProps) {
  if (!clip) return null
  const { users, title, summaryWistiaCode, summary, id } = clip
  return (
    <div data-test="clip-viewer-modal">
      <Modal
        className={'relative lgr:w-4/6'}
        header={false}
        scrollContent={false}
        isOpen={isOpen}
        fullHeight={false}
        handleClose={handleClose}
      >
        <ModalHeader>
          <div
            onClick={handleClose}
            onKeyUp={onEnterKeyPress(handleClose)}
            className="hover:bg-default absolute right-3 top-3 cursor-pointer md:right-6 md:top-6 2xl:text-xl"
            data-test="clip-viewer-close-button"
            role="button"
            tabIndex={0}
          >
            <CloseIcon className="h-6 w-6" />
          </div>
          <div>
            <div className="flex pb-3 pt-7 font-bold lg:gap-8 lg:text-xl">{title}</div>

            {users?.map((user, i) => (
              <Link key={user.profile.profileUrl} href={user.profile.profileUrl || '#'}>
                <div key={i} className="mb-1 flex items-center">
                  <div className="mr-4 flex h-8 w-8 items-center">
                    <AvatarWithBadge
                      avatarUrl={user.avatarUrl}
                      imgClasses="w-full h-full"
                      hasBadge={false}
                    />
                  </div>
                  <div className="text-sm text-black hover:text-rb-teal-200">
                    <span className="font-bold">{user.fullName}</span>, {user.role} @{' '}
                    {user.companyName}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </ModalHeader>
        <ModalContent scrollContent={false} className={'pt-2 pb-5'}>
          <div className="aspect-w-9 aspect-h-5" data-test="clip-video-player">
            <WistiaPlayer
              code={summaryWistiaCode}
              wrapperClasses=""
              objectType={'Clip'}
              objectId={id}
            />
          </div>
          <p className="pt-6" dangerouslySetInnerHTML={{ __html: summary || '' }} />
        </ModalContent>
      </Modal>
    </div>
  )
}
