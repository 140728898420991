import React from 'react'
import { Link } from 'react-router-dom'

import { BreadcrumbHistoryEntry } from 'components/Breadcrumbs'
import { ReactComponent as IconChevronLeftBold } from 'components/Breadcrumbs/BackButton/chevron-left-bold.svg'
import useBackButton from 'components/Breadcrumbs/BackButton/useBackButton'
import RfHeader3 from 'components/typography/RfHeader/RfHeader3'

interface BackButtonProps {
  history: BreadcrumbHistoryEntry[]
  removeHistoryEntry: () => void
}

const BackButton = ({ history, removeHistoryEntry }: BackButtonProps) => {
  const { previousPath } = useBackButton({ history })

  return (
    <div className="flex items-center">
      <div className="mr-2">
        <IconChevronLeftBold width={7} height={12} />
      </div>
      <Link to={previousPath} onClick={removeHistoryEntry} key={previousPath}>
        <RfHeader3>Back</RfHeader3>
      </Link>
    </div>
  )
}

export default BackButton
