import CohortDashboardTeams from 'domains/CohortDashboard/CohortDashboardProgram/CohortDashboardEndBlock/CohortDashboardTeams'

import {
  CohortDashboardCurrentUserPartsFragment,
  CohortDashboardSeasonPartsFragment
} from 'gql'

import CohortDashboardArtifacts from './CohortDashboardArtifacts'
import CohortDashboardInlineSurvey from './CohortDashboardInlineSurvey'
import CohortOffboardingFaqs from './CohortOffboardingFaqs'

interface CohortDashboardEndBlockBottomSectionProps {
  season: CohortDashboardSeasonPartsFragment
  programName: string
  currentUser: CohortDashboardCurrentUserPartsFragment
  shouldShowNpsSurvey: boolean
}

const CohortDashboardEndBlockBottomSection = ({
  season,
  programName,
  currentUser,
  shouldShowNpsSurvey
}: CohortDashboardEndBlockBottomSectionProps) => (
  <div className="mt-10 flex w-full flex-col xl:items-center">
    {shouldShowNpsSurvey && (
      <CohortDashboardInlineSurvey
        season={season}
        programName={programName}
        currentUser={currentUser}
      />
    )}
    {<CohortDashboardArtifacts season={season} />}
    <CohortOffboardingFaqs />
    {!currentUser.hasTeam && (
      <div className="mt-[56px]">
        <CohortDashboardTeams />
      </div>
    )}
  </div>
)

export default CohortDashboardEndBlockBottomSection
