import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import InputFuzzy from 'react-fuzzy'
import { Controller, useFormContext } from 'react-hook-form'

import withDefaultEnhancers from 'components/forms/enhancers/withDefaultEnhancers'
import { IFormElementBase } from 'components/forms/formTypes'

import withValidationIcon from './enhancers/withValidationIcon'

const inputStyle = {
  border: 0,
  fontSize: '16px',
  padding: '8px 12px',
  color: '#333333'
}

const inputWrapperStyle = {
  boxShadow: 0,
  padding: 0
}

const listWrapperStyle = {
  backgroundColor: 'white',
  border: '1px solid #d3d2d3',
  borderRadius: '2px',
  borderTop: '1px solid #d3d2d3', // specify top to explicitly override.
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  left: 0,
  maxWidth: '100%',
  padding: '12px 8px',
  position: 'absolute',
  top: 48, // input height + 4
  zIndex: 99999
}

export interface IInputAutocomplete extends IFormElementBase {
  keyForDisplayName: string
  keysToSearch: Array<string>
  list: Array<object>
  listItemClassName?: string
  disabled?: boolean
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onSelect?: (newSelection: object) => void
}

interface IListItem {
  className?: string
  isSelected: boolean
  onClick: () => void
  text: string
}

const ListItem = ({ className, isSelected, onClick, text }: IListItem) => (
  <div
    tabIndex={0}
    // isSelected supports keyboard event styling.
    className={clsx(
      `cursor-pointer bg-white p-2 hover:bg-rb-gray-50 ${isSelected && 'bg-rb-gray-50'}`,
      className
    )}
    onClick={onClick}
  >
    {text}
  </div>
)

interface IControlledFocus {
  name: string
  defaultValue: string
}

// Maintain focus through key refresh
export const useControlledFocus = ({ name, defaultValue }: IControlledFocus) => {
  const focusRef: any = useRef(null)
  const [isFocused, setIsFocused] = useState(false)
  const { triggerValidation } = useFormContext()

  const getInputElement = () => focusRef.current?.querySelector('input')

  useEffect(() => {
    const input = getInputElement()
    const focusEventHandler = () => {
      setIsFocused(true)
    }
    const blurEventHandler = () => {
      setIsFocused(false)
    }

    input.addEventListener('focus', focusEventHandler)
    input.addEventListener('blur', blurEventHandler)

    return () => {
      input.removeEventListener('focus', focusEventHandler)
      input.removeEventListener('blur', blurEventHandler)
    }
  }, [])

  useEffect(() => {
    if (isFocused) {
      getInputElement().focus()

      requestAnimationFrame(() => {
        triggerValidation(name)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  return focusRef
}

export const InputAutocomplete = ({
  defaultValue,
  keyForDisplayName,
  keysToSearch,
  list,
  disabled,
  listItemClassName,
  name,
  onBlur,
  onSelect,
  placeholder
}: IInputAutocomplete) => {
  const focusRef = useControlledFocus({ name, defaultValue })
  // Using setValue is needed because InputFuzzy is a controlled component but React Hook Form expects an
  // uncontrolled component
  const { setValue, control } = useFormContext()

  useEffect(() => setValue(name, defaultValue), [setValue, name, defaultValue])

  const handleSelect = (result: { [key: string]: string }) => {
    onSelect?.(result)
    setValue(name, result[keyForDisplayName])
  }

  return (
    <div className="relative" ref={focusRef}>
      <Controller
        name={name}
        control={control}
        as={
          <InputFuzzy
            width={'100%'}
            isDropdown={true}
            key={defaultValue}
            inputProps={{
              autoComplete: 'off',
              name,
              disabled,
              onBlur,
              onChange: (e: React.FormEvent<HTMLInputElement>) =>
                setValue(name, e.currentTarget.value),
              defaultValue: defaultValue || ''
            }}
            inputStyle={inputStyle}
            inputWrapperStyle={inputWrapperStyle}
            listWrapperStyle={listWrapperStyle}
            list={list}
            keys={keysToSearch}
            threshold={0.3}
            keyForDisplayName={keyForDisplayName}
            onSelect={handleSelect}
            placeholder={placeholder}
            resultsTemplate={(
              _props: any,
              state: { results: Array<object>; selectedIndex: number },
              _styles: string,
              clickHandler: (index: number) => void
            ) =>
              state.results.map((val: any, i: number) => (
                <ListItem
                  className={listItemClassName}
                  isSelected={state.selectedIndex === i}
                  key={i}
                  onClick={() => clickHandler(i)}
                  text={val[keyForDisplayName]}
                />
              ))
            }
          />
        }
      />
    </div>
  )
}

export const InputAutocompleteWithEnhancers = withDefaultEnhancers(InputAutocomplete)

const InputAutocompleteWithForm = ({ ...props }: IInputAutocomplete) => {
  return (
    <div
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault()
        }
      }}
    >
      <InputAutocomplete {...props} />
    </div>
  )
}

export const InputAutocompleteWithFormAndValidationIcon = withValidationIcon(
  InputAutocompleteWithForm
)

const InputAutocompleteWithFormAndEnhancers = withDefaultEnhancers(
  InputAutocompleteWithFormAndValidationIcon
)

export default InputAutocompleteWithFormAndEnhancers
