import { Link } from 'react-router-dom'

import WeeklyBlockChecklistCard from 'domains/CohortDashboard/CohortDashboardProgram/CohortDashboardWeeklyBlock/WeeklyBlockChecklist/WeeklyBlockChecklistCard'
import {
  formattedEventDate,
  formattedEventTimeRange
} from 'domains/CohortDashboard/CohortDashboardProgram/CohortDashboardWeeklyBlock/WeeklyBlockEventTimes'
import { CohortEvent } from 'domains/CohortViewer/utils'

import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import { ATTENDED_STATUS, eventForLink } from 'utils/cohortUtils'
import { isBetweenDates } from 'utils/date'
import { makeCohortEventDetailsUrl } from 'utils/url'

// Note about ATTENDED_STATUS: users who attended the event are determined by whether or not they visited the event link while in session (not the event details page, but the actual event link to go to the zoom event)
// This is uploaded after the event in admin (not by engineering), so the 'Attended' status will not get updated automatically post-event as of now, but will get updated once
// the event attended import job was run https://reforgehq.slack.com/archives/C02TTGY8U4B/p1676647164064779?thread_ts=1676645282.672879&cid=C02TTGY8U4B
// Note that they might already have 'Attended' as a status if they joined the event from the event details page or single event card (not combined event like the one we have in the
// Cohort Dashboard) when not already RSVPd

interface EventChecklistCardProps {
  eventTypeText?: string
  event: CohortEvent
  cohortSlug: string
  timezone: string
}

export const EventChecklistCard = ({
  eventTypeText,
  event,
  cohortSlug,
  timezone
}: EventChecklistCardProps) => {
  const eventForDetailsLink = eventForLink(event)
  const eventDetailsLink = makeCohortEventDetailsUrl(
    cohortSlug,
    eventForDetailsLink.id,
    eventForDetailsLink.slug
  )

  const didAttendEvent = event.sameEventInfo.some(
    (eventInfo) => eventInfo.attendingStatus === ATTENDED_STATUS
  )

  const titleComp = (
    <RfParagraphMedium>
      Attend{didAttendEvent ? 'ed' : ''} the{' '}
      {eventTypeText ? `${eventTypeText} ` : 'weekly '}
      event
    </RfParagraphMedium>
  )

  // show time that user actually attending/attended if possible
  const eventInfoForTimes = eventForDetailsLink

  const subtitleText = isBetweenDates(
    eventInfoForTimes.startsAtUtc,
    eventInfoForTimes.endsAtUtc
  )
    ? 'Your event is happening now'
    : `${formattedEventTimeRange(eventInfoForTimes, timezone)} - ${formattedEventDate(
        eventInfoForTimes,
        timezone
      )}`

  const subtitleComp = (
    <RfParagraphMini color={ColorOptions.gray}>{subtitleText}</RfParagraphMini>
  )

  return (
    <Link to={eventDetailsLink} className="hover:no-underline">
      <WeeklyBlockChecklistCard
        titleComponent={titleComp}
        subtitleComponent={subtitleComp}
        checked={didAttendEvent}
      />
    </Link>
  )
}

export default EventChecklistCard
