import { twMerge } from 'tailwind-merge'

import { CourseLinkCurrentUserPartsFragment } from 'gql'

import { isFirstPilotCourseSession } from 'utils/courseUtils'
import { addWeeksToUtc, isBeforeDate } from 'utils/date'

import CourseLink from './CourseLink'

export const getUserCoursesForCourseLinks = (
  user?: CourseLinkCurrentUserPartsFragment | null
) => {
  const currentUserCourse = user?.userCourses?.current
  const upcomingUserCourses = user?.userCourses?.upcoming || []
  const pastUserCourses = user?.userCourses?.previous || []
  const nonPilotPastUserCourses = pastUserCourses.filter(
    (userCourse) => !isFirstPilotCourseSession(userCourse.courseSession)
  )

  // for members, display Course Links for the current Course and for any past courses that finished within a month ago
  if (user?.is?.member) {
    const coursesEndedWithinOneMonthAgo = nonPilotPastUserCourses.filter((userCourse) => {
      const endsAt = userCourse?.courseSession?.endsAt
      return !!endsAt && isBeforeDate(addWeeksToUtc(endsAt, 4))
    })

    return currentUserCourse
      ? [currentUserCourse, ...upcomingUserCourses, ...coursesEndedWithinOneMonthAgo]
      : [...upcomingUserCourses, ...coursesEndedWithinOneMonthAgo]
  }

  // for nonmembers, display Course Links for the current course and for all past courses
  return currentUserCourse
    ? [currentUserCourse, ...upcomingUserCourses, ...nonPilotPastUserCourses]
    : [...upcomingUserCourses, ...nonPilotPastUserCourses]
}

interface CourseLinkSectionProps {
  isCollapsed: boolean
  user: CourseLinkCurrentUserPartsFragment
}

const CourseLinkSection = ({ user, isCollapsed }: CourseLinkSectionProps) => {
  const userCoursesForCourseLinkSection = getUserCoursesForCourseLinks(user)

  if (!userCoursesForCourseLinkSection.length) return null

  return (
    <div className="mb-8" data-testid="course-links">
      <li
        className={twMerge(
          isCollapsed ? 'h-[18px] opacity-0' : '',
          'mt-4 mr-0 ml-[10px] mb-4 text-xs font-medium leading-[1.5] tracking-widest text-rb-gray-300'
        )}
      >
        My Course{userCoursesForCourseLinkSection.length > 1 ? 's' : null}
      </li>
      <div className={twMerge('space-y-2')}>
        {userCoursesForCourseLinkSection.map((userCourse) => (
          <CourseLink
            isCollapsed={isCollapsed}
            key={userCourse.id}
            userCourse={userCourse}
          />
        ))}
      </div>
    </div>
  )
}

export default CourseLinkSection
