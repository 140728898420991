import { closing, opening } from './sharedMarkdown'

const programName = 'Data for Product Managers'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://www.reforge.com/data-for-product-managers), because I believe it will give me the skills required to create a repeatable system informed by data that delivers compounding product wins. This course was built with [Shaun Clowes](https://www.reforge.com/experts/shaun-clowes) and [Crystal Widjaja](https://www.reforge.com/experts/crystal-widjaja), and covers:
* **Altitude Maps.** Discover how to build an Altitude Map of your product area. Learn how to use data effectively and identify the right metrics to drive impact.
* **Instrumentation and Generating Data Insights.** Learn how to create an event tracking dictionary. Examine the iterative process required to generate data insights.
* **Key Analytical Tools.** Deepen your understanding of segmentation, relative impact and correlation analysis, and analysis in practice.
* **Communicating Persuasively and Leveraging Data.** Data is essential to decision-making. Learn how to build credibility in your organization by communicating persuasively with data.

&NewLine;

${closing}
`

export default { markdown, title: programName }
