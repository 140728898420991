import {
  trackContentCompleted as generatedTrackContentCompleted,
  trackContentStarted as generatedTrackContentStarted,
  trackTextContentProgressRecorded as generatedTrackTextContentProgressRecorded
} from './generated/events'

export * from './segment'
export * from './generated/events'

/**
 * This fn is an override of the generated fn.
 */
export function trackContentCompleted(
  event: Parameters<typeof generatedTrackContentCompleted>[0]
) {
  event.content_type = event.content_type === 'Section' ? 'Lesson' : event.content_type
  generatedTrackContentCompleted(event)
}

/**
 * This fn is an override of the generated fn.
 */
export function trackContentStarted(
  event: Parameters<typeof generatedTrackContentStarted>[0]
) {
  event.content_type = event.content_type === 'Section' ? 'Lesson' : event.content_type
  generatedTrackContentStarted(event)
}

/**
 * This fn is an override of the generated fn.
 */
export function trackTextContentProgressRecorded(
  event: Parameters<typeof generatedTrackTextContentProgressRecorded>[0]
) {
  event.content_type = event.content_type === 'Section' ? 'Lesson' : event.content_type
  generatedTrackTextContentProgressRecorded(event)
}
