import { useEffect, useState } from 'react'

import SelectFilter from 'components/SelectFilter'

import { FUNCTION_OPTIONS } from 'constants/onboarding'

import { FilterProps } from './helpers'

const Function = ({ filters, setFilters }: FilterProps) => {
  const name = 'function'
  const filter = filters[name]

  const [selection, setSelection] = useState(filter)

  useEffect(() => {
    const selected: { label: string } = FUNCTION_OPTIONS.find(
      (option: { id: string }) => option.id === filter
    )
    const selection = selected ? selected.label : ''
    setSelection(selection)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  return (
    <SelectFilter
      name={name}
      label="Function"
      selection={selection}
      filters={filters}
      setFilters={setFilters}
      options={FUNCTION_OPTIONS}
    />
  )
}

export default Function
