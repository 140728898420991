import { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

import { SVGIcon } from 'components/Icon'

import { ActivityAction, ActivityListItem, ActivitySubject } from 'gql'

import notifyError from 'utils/errorNotifier'
import {
  trackNotificationClicked,
  trackNotificationViewed
} from 'utils/tracking/analytics'

const handleTracking = (activity: ActivityListItem) => {
  trackNotificationClicked({
    notification_id: activity.activityIds[0],
    notification_type: 'activity feed',
    context_page_title: document.title,
    location: 'activity_page',
    object_id: activity.trackedId as string,
    object_type: activity.trackedType
  })
}

const tooltipCopy = (remainingNames: string[]) => {
  const names = [...new Set(remainingNames)]
  return `<div class='py-1 px-2.5'>${names.join('<br />')}</div>`
}

const linkifySubject = (subject: ActivitySubject) => {
  if (subject.slug) {
    return `<a class='font-semibold text-rb-gray-500' href='/directory/${subject.slug}' target='_blank'>${subject.name}</a>`
  } else {
    if (subject.remainingNames) {
      return `<span uk-tooltip="title: ${tooltipCopy(
        subject.remainingNames
      )}; cls: uk-active rf-tooltip-down-arrow; pos: top">${subject.name}<span>`
    } else {
      return subject.name
    }
  }
}

const linkifyNoun = (activity: ActivityListItem) => {
  const { noun, trackedId, trackedPath, trackedAnchor } = activity
  if (noun.asString) {
    if (!trackedPath) return noun.asString
    let url = trackedPath
    if (trackedId) url = `${url}?tid=${trackedId}`
    if (trackedAnchor) url = `${url}#${trackedAnchor}`
    return (
      <Link
        to={url}
        className="font-semibold text-rb-gray-500"
        onClick={() => handleTracking(activity)}
      >
        {noun.asString}
      </Link>
    )
  } else {
    const patternParts = (noun?.pattern || '').split('[RECIPIENT_LINK]')
    return patternParts.map((part, index) => (
      <span key={`nouns${index}`}>
        {part}
        {patternParts.length - 1 !== index && (
          <Link
            to={`/directory/${noun.slug}`}
            className="font-semibold text-rb-gray-500"
            onClick={() => handleTracking(activity)}
            target="_blank"
          >
            {noun.name || ''}
          </Link>
        )}
      </span>
    ))
  }
}

const linkifyTracked = (activity: ActivityListItem) => {
  const {
    trackedId,
    trackedPath,
    trackedAnchor,
    trackedTitle,
    trackedSlug,
    excerpt,
    cohortChannelHref
  } = activity
  let formattedTitle
  let contents

  if (trackedSlug) {
    formattedTitle = (
      <strong className="uk-link-text font-semibold">{trackedTitle}</strong>
    )
  } else {
    formattedTitle = <em className="uk-link-text font-semibold">{trackedTitle}</em>
  }

  if (excerpt) {
    contents = (
      <Fragment>
        {formattedTitle}
        <div
          className="mt-2.5 text-rb-gray-300"
          dangerouslySetInnerHTML={{ __html: excerpt }}
        />
      </Fragment>
    )
  } else {
    contents = formattedTitle
  }

  if (trackedSlug !== null || cohortChannelHref !== null) {
    const pathname = trackedPath || cohortChannelHref || `/posts/${trackedSlug}`
    const search = trackedId ? `tid=${trackedId}` : ''
    const hash = trackedAnchor || undefined
    return (
      <HashLink
        to={{ pathname, hash, search, state: { from: 'activity' } }}
        className="mt-2.5 ml-4 block border-l border-l-rb-gray-100 p-0 pl-4 leading-4 text-rb-gray-500 hover:no-underline"
        onClick={() => handleTracking(activity)}
      >
        {contents}
      </HashLink>
    )
  } else {
    return null
  }
}

const formatSubjects = ({ subjects }: ActivityListItem) => {
  if (subjects.length === 1) {
    return linkifySubject(subjects[0])
  } else if (subjects.length === 2) {
    return `${linkifySubject(subjects[0])} and ${linkifySubject(subjects[1])}`
  } else if (subjects.length === 3) {
    return `${linkifySubject(subjects[0])}, ${linkifySubject(
      subjects[1]
    )} and ${linkifySubject(subjects[2])}`
  }
}

const formatAction = (action: ActivityAction) => {
  if (action.reactions) {
    // this is a reacted with action
    const output = []
    for (var i = 0; i < action.reactions.length; i += 1) {
      if (action.reactions[i].includes('Insightful')) {
        output.push(
          <span key="insighful">
            <span
              className="uk-inline-block"
              style={{ marginTop: '-2px', marginRight: '-1px' }}
            >
              <SVGIcon
                className="rf-icon uk-inline-block"
                name="lightbulb"
                height="16"
                width="16"
                fill="#000000"
              />
            </span>{' '}
            (Insightful)
          </span>
        )
      }
      if (action.reactions[i].includes('I agree')) {
        output.push(
          <span key="agree">
            <span
              className="uk-inline-block"
              style={{ marginLeft: '2px', marginRight: '2px', marginTop: '-1px' }}
            >
              <SVGIcon
                className="rf-icon"
                name="100"
                height="16"
                width="16"
                fill="#000000"
              />
            </span>{' '}
            (I agree)
          </span>
        )
      }
      if (action.reactions[i].includes('Thank you')) {
        output.push(
          <span key="thank_you">
            {action.reactions[i].replace("'Thank you'", '')}
            <span
              className="uk-inline-block"
              style={{ marginLeft: '1px', marginRight: '1px', marginTop: '-3px' }}
            >
              <SVGIcon
                className="rf-icon"
                name="pressed-hands"
                height="16"
                width="16"
                fill="#000000"
              />
            </span>{' '}
            (Thank you)
          </span>
        )
      }
      if (action.reactions[i].includes('Upvote')) {
        output.push(
          <span key="upvote">
            {action.reactions[i].replace("'Upvote'", '')}
            <span
              className="uk-inline-block"
              style={{ marginLeft: '1px', marginRight: '1px', marginTop: '-3px' }}
            >
              <SVGIcon
                className="rf-icon"
                name="thin-chevron-up"
                height="16"
                width="16"
                fill="#000000"
              />
            </span>{' '}
            (Upvote)
          </span>
        )
      }
      const reactionCount = action.reactions.length
      if (reactionCount === 2 && i === 0) {
        output.push(' and ')
      } else if (reactionCount > 2) {
        if (i < reactionCount - 1) {
          output.push(', ')
        }
        if (i === reactionCount - 2) {
          output.push(' and ')
        }
      }
    }
    return <span key="actions">reacted with {output} on</span>
  } else {
    return action.actionString
  }
}

export interface ActivityProps {
  activity: ActivityListItem
}

const Activity = (props: ActivityProps) => {
  const activity = props.activity
  const activityId = activity.activityIds[0]

  useEffect(() => {
    trackNotificationViewed({
      notification_id: activityId,
      notification_type: 'activity feed',
      context_page_title: document.title,
      location: 'activity_page',
      object_id: activity.trackedId as string,
      object_type: activity.trackedType
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const actionText = formatAction(activity.action)

  return (
    <div
      className={`border-b border-b-rb-gray-100/50 tl:pl-12 ${
        !activity.read ? 'bg-rb-gray-50' : ''
      } p-4`}
      data-testid={`activity${activityId}`}
    >
      <div uk-grid="">
        <div className="uk-width-expand text-base">
          <div>
            <span
              key="subjects"
              dangerouslySetInnerHTML={{
                __html: formatSubjects(activity) || ''
              }}
            />{' '}
            {actionText}{' '}
            {activity.action.type === 'team_member_completes_lesson' ? (
              linkifyCollectionContentCompletion(activity)
            ) : activity.cohortChannelHref && activity.noun.asString ? (
              <Link
                to={activity.cohortChannelHref}
                className="font-semibold text-rb-gray-500"
                onClick={() => handleTracking(activity)}
              >
                {linkifyNoun(activity)}
              </Link>
            ) : (
              linkifyNoun(activity)
            )}
          </div>
          {linkifyTracked(activity)}
        </div>
        <div className="w-auto text-right">
          <div className="text-rb-gray-300">{activity.timestamp}</div>
        </div>
      </div>
    </div>
  )
}

const linkifyCollectionContentCompletion = (activity: ActivityListItem) => {
  if (!activity.trackedPath || !activity.collectionPath) {
    notifyError('Error - Activity missing trackedPath or collectionPath')
    return 'a lesson in your shared collection'
  }

  return (
    <>
      <Link
        to={activity.trackedPath}
        className="font-semibold text-rb-gray-500"
        onClick={() => handleTracking(activity)}
      >
        {activity.trackedTitle}
      </Link>{' '}
      {"in your team's "}
      <Link
        to={activity.collectionPath}
        className="font-semibold text-rb-gray-500"
        onClick={() => handleTracking(activity)}
      >
        shared collection
      </Link>
    </>
  )
}

export default Activity
