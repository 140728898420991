import Lottie from 'lottie-react'
import { ComponentType, FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { object, string } from 'yup'

import Image from 'domains/Sanity/Image'

import { Form } from 'components/forms'
import { InputText } from 'components/forms/InputText'
import withContainer from 'components/forms/enhancers/withContainer'
import withDescription from 'components/forms/enhancers/withDescription'
import withLabel from 'components/forms/enhancers/withLabel'
import withValidation from 'components/forms/enhancers/withValidation'
import withValidationIcon from 'components/forms/enhancers/withValidationIcon'

import { onEnterKeyPress } from 'utils/keyboard'
import { trackSignupFormSubmitted } from 'utils/tracking/analytics'

import BackgroundImage from 'images/bg-embedded-browser-login.svg'
import { ReactComponent as EnvelopeIcon } from 'images/icon--envelope.svg'
import { ReactComponent as ReforgeLogo } from 'images/reforge-logo-black.svg'

import animationData from './animationData.json'

/**
 * EmbeddedBrowserMessage
 * Displays when attempting to sign in through an embedded browser (not currently supported)
 * */
export const EmbeddedBrowserMessage = () => {
  const [emailSet, setEmailSet] = useState<boolean>(false)
  const [email, setEmail] = useState<string>()
  const href = window.location.href

  const url = href.includes('%3Frsvp%3Dtrue')
    ? href.replace('%3Frsvp%3Dtrue', '?rsvp=true')
    : href

  const onSubmit = ({ email }: { email: string }) => {
    trackSignupFormSubmitted({
      email,
      form_id: process.env.REACT_APP_ITERABLE_SIGNUP_FORM_LIST_ID || '',
      form_name: 'Artifact LinkedIn Lead Gen Signups',
      form_type: 'iterable',
      url
    })

    setEmailSet(true)
    setEmail(email)
  }

  const onReenterAddress = () => {
    setEmailSet(false)
    setEmail(undefined)
  }

  const labelClassName = 'text-sm'
  const InputTextWithCustomLabels = withValidation(
    withLabel(
      withDescription(
        withContainer(withValidationIcon(withCustomIconInputEnhancer(InputText)))
      ),
      labelClassName
    )
  )

  return (
    <div
      id="login"
      className="relative flex min-h-[100vh] flex-col bg-cover bg-no-repeat py-16 px-6"
    >
      <Image
        src={BackgroundImage}
        fill
        alt=""
        className="-z-1 object-cover object-right"
      />
      <Link className="mb-12 self-center" to="/" rel="noreferrer">
        <ReforgeLogo className="w-[180px]" />
      </Link>

      {!emailSet && (
        <div className="max-w-96 self-center rounded-lg bg-rb-white p-12 shadow-card-large">
          <h1 className="mb-2 text-[28px] font-semibold leading-[1.2] tracking-[-1.12px] text-rb-gray-400">
            Enter your email to continue
          </h1>

          <p className="mb-6 text-base leading-[1.6]">
            We’ll send you an email with a link to sign up or log in
          </p>

          <Form
            submit={onSubmit}
            submitButtonClassname="mt-8"
            submitButtonProps={{ size: 'small' }}
            validationSchema={object().shape({
              email: string()
                .trim()
                .email('Please enter a valid email address')
                .required()
                .label('Email')
            })}
          >
            <InputTextWithCustomLabels
              placeholder="email@address.com"
              name="email"
              type="email"
              className="pl-0"
            />
          </Form>
        </div>
      )}

      {emailSet && (
        <div className="max-w-96 self-center rounded-lg bg-rb-white p-12 shadow-card-large">
          <Lottie
            animationData={animationData}
            loop={false}
            className="chromatic-ignore mx-auto mb-6 h-16 w-16"
          />

          <h1 className="mb-2 text-[28px] font-semibold leading-[1.2] tracking-[-1.12px] text-rb-gray-400">
            Please check your inbox
          </h1>

          <p className="mb-6 text-base leading-[1.6]">We’ve sent a link to {email}</p>

          <p className="mb-2 text-xs leading-[1.5] text-rb-gray-300">
            Can’t see the email? Please check your spam folder.
          </p>

          <p className="mb-0 text-xs leading-[1.5] text-rb-gray-300">
            Wrong email?{' '}
            <span
              className="underline"
              role="button"
              onClick={onReenterAddress}
              tabIndex={0}
              onKeyUp={onEnterKeyPress(onReenterAddress)}
            >
              Re-enter your address
            </span>
          </p>
        </div>
      )}
    </div>
  )
}

export default EmbeddedBrowserMessage

const withCustomIconInputEnhancer = <P extends object>(
  Element: ComponentType<P>
): FC<P> => {
  const withCustomIconInputEnhancer = ({ ...props }) => (
    <div className="flex items-center">
      <EnvelopeIcon width={16} className="ml-4 mr-3 w-4 shrink-0" />
      <Element {...(props as P)} />
    </div>
  )
  return withCustomIconInputEnhancer
}
