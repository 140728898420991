import { Link, useLocation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import Image from 'domains/Sanity/Image'
import ExpertCard from 'domains/Sanity/marketingSite/ExpertCard'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'
import PodcastCard from 'domains/Sanity/marketingSite/PodcastCard'
import PodcastSubscribeSection from 'domains/Sanity/marketingSite/PodcastSubscribeSection'

import { Loading } from 'components'

import { PodcastIndex, usePodcastIndexQuery } from 'gql'

import { trackCtaClicked } from 'utils/tracking/analytics'

import PodcastHeaderBackground from 'images/PodcastHeaderBackground.png'
import { ReactComponent as ChevronLeftIcon } from 'images/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from 'images/chevron-right.svg'

const PAGINATION_LIMIT = 20

const PodcastIndexPage = () => {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const lastId = query.get('lastId')
  const reverse = query.get('reverse')
  const isReverse = reverse === 'true'
  const { data, loading } = usePodcastIndexQuery({
    variables: {
      lastId,
      limit: PAGINATION_LIMIT,
      reverse: isReverse
    }
  })

  const { page } = data?.podcastIndex || {}

  return (
    <MarketingLayout page={page}>
      {loading || !data?.podcastIndex ? (
        <Loading />
      ) : (
        <PodcastIndexContent
          data={data.podcastIndex}
          isReverse={isReverse}
          lastIdParam={lastId || ''}
        />
      )}
    </MarketingLayout>
  )
}

export const PodcastIndexContent = ({
  data,
  isReverse,
  lastIdParam,
  isChromatic = false
}: {
  data: PodcastIndex
  isReverse: boolean
  lastIdParam: string
  isChromatic?: boolean
}) => {
  const { page, podcasts, nextPodcastsCount, prevPodcastsCount } = data
  const { content } = page
  const firstId = podcasts[0]?.id
  const lastId = podcasts[podcasts.length - 1]?.id
  const hasNext = isReverse
    ? nextPodcastsCount + 1 > 0
    : nextPodcastsCount > PAGINATION_LIMIT
  const hasPrev =
    lastIdParam &&
    (isReverse
      ? prevPodcastsCount > PAGINATION_LIMIT
      : prevPodcastsCount + 1 >= PAGINATION_LIMIT)

  return (
    <>
      <section className="relative">
        <div className="relative py-[4vmax] px-[16px] pb-[260px] md:px-[4vw] md:pb-[4vmax]">
          <Image
            src={PodcastHeaderBackground}
            alt=""
            fill
            className="-z-1 object-cover"
            priority
          />

          <h1 className="font-normal tracking-tight fluid-text-6xl md:w-[50%] xl:fluid-text-7xl">
            {content.heading}
          </h1>
        </div>

        <div className="right-0 top-0 z-1 flex h-full flex-col items-center justify-start bg-rb-teal-75 px-[16px] py-[2vmax] md:absolute md:w-[50%] md:bg-transparent md:pl-[2vw] md:pr-[4vw]">
          <Image
            src={content.mainImage.imageUrl || ''}
            alt={content.mainImage.imageAlt || ''}
            width={550}
            height={550 * (content.mainImage.aspectRatio || 1)}
            className="mx-auto mb-[2vw] -mt-[240px] block h-auto w-full max-w-[430px] md:mt-0 md:max-w-[550px]"
            priority
          />

          <div className="mx-auto">
            <p className="mb-[1vw] !leading-tight fluid-text-base">
              Subscribe to the show:
            </p>

            <div className="grid grid-cols-1 gap-[4vw] sm:grid-cols-3">
              {content.platforms.map((platform) => (
                <a
                  href={platform.platformLink}
                  key={platform.id}
                  onClick={() => {
                    trackCtaClicked({
                      text: platform.title,
                      cta_type: 'button',
                      cta_location: '/podcast/unsolicited-feedback',
                      destination: platform.platformLink
                    })
                  }}
                >
                  <Image
                    src={platform.platformImage.imageUrl || ''}
                    alt={platform.platformImage.imageAlt || ''}
                    width={280}
                    height={280 * (platform.platformImage.aspectRatio || 1)}
                    className="h-auto w-full max-w-[280px]"
                    priority
                  />
                </a>
              ))}
            </div>
          </div>
        </div>

        <div className="bg-rb-teal-75 py-[4vmax] px-[16px] pb-[520px] md:px-[4vw] md:pb-[260px] lg:pb-[320px]">
          <p className="!leading-snug fluid-text-base md:w-[45%]">
            {content.description}
          </p>
        </div>
      </section>

      <section className="relative z-0 -mt-[480px] px-[16px] py-[6.6vmax] md:-mt-[220px] md:px-[4vw] lg:-mt-[300px]">
        <h3 className="mb-[1vw] font-normal tracking-tight fluid-text-3xl">
          Meet the Hosts
        </h3>

        <div
          className={twMerge(
            'grid grid-cols-1 items-stretch gap-[30px] md:grid-cols-2',
            content.hosts.length >= 3 && 'md:grid-cols-3'
          )}
        >
          {content.hosts.map((host) => (
            <ExpertCard
              key={host.id}
              expert={{
                ...host.artifactAuthor,
                pastCompanies: [host.shortBio]
              }}
              ctaText="See Artifact Portfolio ↗"
              href={`/profiles/${host.artifactAuthor.slug}`}
              horizontal={content.hosts.length < 3}
              ctaClassName="w-auto"
            />
          ))}
        </div>
      </section>

      <PodcastSubscribeSection formTitle={content.formTitle} />

      <section className="px-[16px] pt-[4vw] md:px-[4vw]">
        <h3 className="mb-[4vw] font-normal tracking-tight fluid-text-3xl">Episodes</h3>

        {podcasts.map((podcast) => (
          <PodcastCard key={podcast.id} {...podcast} isChromatic={isChromatic} />
        ))}
      </section>

      <nav className="mb-[75px] flex items-center justify-between px-[16px] md:px-[4vw]">
        {hasPrev && (
          <Link
            to={`${window.location.pathname}?lastId=${firstId}&reverse=true`}
            className="flex items-center font-serif text-rb-black no-underline fluid-text-base visited:text-rb-black"
          >
            <ChevronLeftIcon className="mr-[16px] w-[10px]" /> Newer Posts
          </Link>
        )}
        {hasNext && (
          <Link
            to={`${window.location.pathname}?lastId=${lastId}`}
            className="ml-auto flex items-center font-serif text-rb-black no-underline fluid-text-base visited:text-rb-black"
          >
            Older Posts <ChevronRightIcon className="ml-[16px] w-[10px]" />
          </Link>
        )}
      </nav>
    </>
  )
}

export default PodcastIndexPage
