import { ReactNode } from 'react'

import RfParagraphLarge from 'components/typography/RfParagraph/RfParagraphLarge'

interface ChildrenProps {
  children: ReactNode
  className?: string
}

const RfParagraphLargeSemiBold = ({ children, className }: ChildrenProps) => (
  <RfParagraphLarge className={className}>
    <span className="font-semibold">{children}</span>
  </RfParagraphLarge>
)

export default RfParagraphLargeSemiBold
