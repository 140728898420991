import { useLocation } from 'react-router-dom'

import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'

import BaseBreadcrumbs from 'components/Breadcrumbs/BaseBreadcrumbs'
import { ReactComponent as ChevronLeftIcon } from 'components/Breadcrumbs/BaseBreadcrumbs/base-chevron-left.svg'
import PageHeader from 'components/PageHeader'

import { EventPartsFragment, useBookmarkFoldersQuery, useUserBookmarksQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { cn } from 'utils/tailwind'

import { EventRecordingCard } from './EventRecordingCard'

type EventPastRecordingProps = {
  events: EventPartsFragment[]
  className?: string
}

export function EventPastRecordingGrid({
  events = [],
  className
}: EventPastRecordingProps) {
  const location = useLocation()
  const isRecordingPage = location.pathname.includes('recordings')
  const { isLoggedIn } = useCurrentUser()
  const { data: allBookmarksData } = useUserBookmarksQuery({
    skip: !isLoggedIn,
    fetchPolicy: 'network-only'
  })
  const eventBookmarks = allBookmarksData?.currentUser?.eventBookmarks || []

  const { data: bookmarkFolderData } = useBookmarkFoldersQuery({
    skip: !isLoggedIn
  })

  const {
    currentBookmarkForDropdown,
    isAddToBookmarkFolderModalOpen,
    closeAddToBookmarkFolderModal,
    openAddToBookmarkFolderModal
  } = useOpenAddToBookmarkFolderModal()
  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    closeCreateBookmarkFolderModal,
    openCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()

  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }
  const variants = ['teal', 'blue', 'pink', 'green', 'yellow']

  return (
    <div className="flex flex-col">
      {isRecordingPage && (
        <div className={cn(!isLoggedIn && 'px-4 md:px-[4vw] lg:pl-[4vw]')}>
          <BaseBreadcrumbs
            className="mb-5"
            oneLevel
            breadcrumbPages={[{ title: 'events', path: '/events' }]}
            backIcon={
              <ChevronLeftIcon width={16} height={16} className="mr-4 fill-rb-black" />
            }
          />
          <PageHeader title="Event replays" />
        </div>
      )}

      <div
        className={cn(
          'grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3',
          !isLoggedIn && isRecordingPage && 'px-4 md:px-[4vw] lg:pl-[4vw] xl:grid-cols-4',

          className
        )}
      >
        {events?.map((event, index) => {
          const bookmark = eventBookmarks.find(
            (eventBookmark) => eventBookmark.eventId === event.id
          )
          return (
            <EventRecordingCard
              key={event.id}
              bookmark={bookmark}
              event={event}
              imgVariants={variants[index % variants.length]}
              openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
            />
          )
        })}
        <AddBookmarkToFolderModal
          isOpen={isAddToBookmarkFolderModalOpen}
          handleClose={closeAddToBookmarkFolderModal}
          bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders}
          openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
          currentBookmarkForDropdown={currentBookmarkForDropdown}
          showCollectionsOnboardingInfo={
            !bookmarkFolderData?.currentUser?.hasSeenCollectionsPrompt
          }
        />
        <CreateBookmarkFolderModal
          isModalOpen={isCreateBookmarkFolderModalOpen}
          handleClose={closeCreateBookmarkFolderModal}
          currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
          trackingTriggerAction={'bookmark'}
        />
      </div>
    </div>
  )
}
