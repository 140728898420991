import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import ProgramCardTopSection from 'domains/Program/ProgramCardTopSection'

import AvatarStack from 'components/AvatarStack/AvatarStack'
import CardProgressBar from 'components/cards/CardProgressBar'

import {
  BookmarkFolderPartsFragment,
  ProgramBookmarkPartsFragment,
  ProgramCardActiveSeasonPartsFragment,
  ProgramCardPartsFragment,
  ProgramCardUserPartsFragment,
  UserCohortsPartsFragment
} from 'gql'

import { ModuleProgress } from 'typings/scalars'

import HoverContent from './HoverContent'

export interface ProgramCardProps {
  program: ProgramCardPartsFragment
  currentUser: ProgramCardUserPartsFragment
  userCohorts: UserCohortsPartsFragment['cohorts']
  postCohort?: boolean
  sourceFlow: string
  userProgress?: ModuleProgress | null
  enrollmentSeason?: ProgramCardActiveSeasonPartsFragment | null
  enrolledInEnrollmentSeason?: boolean
  showEnrolledBadge: boolean
  onProgramHoverEnrollClick?: (selectedProgramId: number | string) => void
  bookmark?: ProgramBookmarkPartsFragment
  bookmarkFolders?: BookmarkFolderPartsFragment[]
  restoreBookmark?: (bookmark: ProgramBookmarkPartsFragment) => void
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  handleRemoveFromFolder?: (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => Promise<string | null | undefined>
  currentFolder?: BookmarkFolderPartsFragment | null
  reforgeCollection?: boolean
  inNewTab?: boolean
  hideUpdatedAgo?: boolean
}

export interface ProgramCardHostNamesProps {
  hosts: ProgramCardPartsFragment['collaborators']
  className?: string
}

const ProgramCardHostNames = ({ hosts, className }: ProgramCardHostNamesProps) => {
  const names = hosts.map((host) => host.name).join(', ')
  return (
    <p className={twMerge('text-xs line-clamp-2', className)}>
      Created by <strong>{names}</strong>
    </p>
  )
}

const ProgramCard = ({
  program,
  currentUser,
  postCohort,
  userCohorts,
  sourceFlow,
  enrollmentSeason,
  enrolledInEnrollmentSeason,
  showEnrolledBadge,
  userProgress,
  onProgramHoverEnrollClick,
  bookmark,
  restoreBookmark,
  bookmarkFolders,
  currentFolder,
  openAddToBookmarkFolderModal,
  handleRemoveFromFolder,
  reforgeCollection = false,
  inNewTab = false
}: ProgramCardProps) => {
  const history = useHistory()
  const type = 'course'
  const [hovering, setHovering] = useState(false)

  const isClickableCard =
    ((currentUser.shouldSeeTrialMessaging || currentUser.is.member) &&
      !program.upcoming) ||
    (program.purchased && !program.upcoming)

  const handleCardClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (currentUser && isClickableCard) {
      const programPageRoute = `/programs/${program.slug}`

      e.metaKey || e.ctrlKey || inNewTab
        ? window.open(programPageRoute)
        : history.push(programPageRoute)
    }
  }

  const latestCurrentProgram = userCohorts?.latestCurrent
  const enrollOpenForLatestCohort = latestCurrentProgram
    ? latestCurrentProgram.cohort.postCoreWeeksEnrollmentIsOpen
    : true

  const hoverable = () => {
    if (
      enrolledInEnrollmentSeason ||
      !enrollOpenForLatestCohort ||
      !currentUser.can.enrollInCohort
    ) {
      return false
    }

    if (!enrollmentSeason || !enrollmentSeason.openForEnrollment) {
      return currentUser.is.paidMember && program.upcoming
    }

    if (currentUser.is.paidMember) {
      return program.upcoming || !program.enrolled
    } else {
      return (
        (!currentUser.shouldSeeTrialMessaging && !program.purchased) || program.upcoming
      )
    }
  }

  const HoverableContainer: FC = ({ children }) => (
    <div
      data-testid={`hoverable-program-card-${program.id}`}
      data-test={`hoverable-program-card-${program.id}`}
      onClick={handleCardClick}
      className={`uk-card uk-card-default ${
        isClickableCard ? 'rf-rb-card-interactive' : 'rf-rb-card'
      } uk-flex uk-flex-column uk-flex-1 group relative h-full min-h-[396px] justify-between`}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      {children}
    </div>
  )

  const Container: FC = ({ children }) => (
    <div
      data-testid={`program-card-${program.id}`}
      data-test={`program-card-${program.id}`}
      onClick={handleCardClick}
      className={`uk-card uk-card-default ${
        isClickableCard ? 'rf-rb-card-interactive' : 'rf-rb-card'
      } uk-flex uk-flex-column uk-flex-1 group h-full ${
        bookmark ? '' : 'min-h-[396px]'
      } justify-between`}
    >
      {children}
    </div>
  )

  const baseContent = (
    <>
      <ProgramCardTopSection
        postCohort={!!postCohort}
        program={program}
        showEnrolledBadge={showEnrolledBadge}
        isTrial={currentUser.shouldSeeTrialMessaging}
        type={type}
        bookmark={bookmark}
        restoreBookmark={restoreBookmark}
        bookmarkFolders={bookmarkFolders}
        currentFolder={currentFolder}
        openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
        handleRemoveFromFolder={handleRemoveFromFolder}
        reforgeCollection={reforgeCollection}
      />
      <div>
        <AvatarStack
          members={program.collaborators.map((c) => ({ ...c, hasBadge: false }))} // no badges for program cards
          totalCount={program.collaborators.length}
          avatarSize={56}
          className="mt-8 mb-4 px-4"
        />
        <ProgramCardHostNames
          hosts={program.collaborators}
          className={twMerge(
            'mb-2 min-h-[32px] px-4',
            !program.progressPercent && 'mb-8' // add space when progress bar isn't rendered
          )}
        />
        {program.progressPercent > 0 && (
          <CardProgressBar
            className="mb-6 rounded-full px-4"
            sectionId={program.id}
            userProgress={userProgress}
            progressPercent={program.progressPercent}
          />
        )}
      </div>
    </>
  )

  if (!hoverable()) {
    return <Container>{baseContent}</Container>
  }

  return (
    <HoverableContainer>
      {baseContent}
      {hovering && (
        <HoverContent
          currentUser={currentUser}
          program={program}
          enrollmentSeason={enrollmentSeason}
          onProgramHoverEnrollClick={onProgramHoverEnrollClick}
          sourceFlow={sourceFlow}
        />
      )}
    </HoverableContainer>
  )
}

export default ProgramCard
