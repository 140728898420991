interface BlockProps {
  value?: any // @todo: define type
  children?: any // @todo: define type
}

const list = {
  bullet: ({ children }: BlockProps) => <ul>{children}</ul>,
  number: ({ children }: BlockProps) => <ol className="list-decimal">{children}</ol>
}

export default list
