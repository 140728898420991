import { ReactNode } from 'react'

import PaymentBanner from 'components/banners/TopBanner/PaymentBanner'

import { useCurrentUser } from 'hooks/useCurrentUser'

interface ProgramHeaderProps {
  thumbnail?: string | null
  title: string
  description?: string | null
  lessonCount?: number
  hours?: number
  participantCount?: number
  testimonial?: ReactNode
}

const ProgramHeader = ({
  thumbnail,
  title,
  description,
  lessonCount,
  hours,
  participantCount,
  testimonial
}: ProgramHeaderProps) => {
  const { currentUser } = useCurrentUser()
  const isAccepted = currentUser && currentUser.accessPolicyKind === 'accepted'

  return (
    <>
      {isAccepted && <PaymentBanner />}

      <div className="flex p-4 sm:p-6 lg:px-8">
        {thumbnail && (
          <img
            src={thumbnail}
            className="mr-4 hidden h-20 w-20 rounded-lg sm:flex lg:mr-6 lg:h-[154px] lg:w-[154px]"
            alt="program-logo"
          />
        )}

        <div className="mr-auto lg:max-w-[492px]">
          <h1 className="mb-0 text-lg md:mb-2 md:text-2xl">{title}</h1>
          <p className="text-sm md:text-base">{description}</p>
          <p className="text-xs font-semibold lg:text-sm">
            {!!lessonCount && (
              <span className="mr-4">
                {lessonCount} lesson{lessonCount > 1 ? 's ' : ' '}
              </span>
            )}

            {hours && (
              <span className="mr-4">
                {hours} hour{hours > 1 ? 's ' : ' '}
              </span>
            )}

            {!!participantCount && participantCount > 1 && (
              <span className="hidden sm:inline-block">
                {/* adds comma to large numbers */}
                {participantCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                participants
              </span>
            )}
          </p>
        </div>
        {testimonial}
      </div>
    </>
  )
}

export default ProgramHeader
