import { ReactNode } from 'react'

import { ColorOptions } from 'components/typography/TypographyColorOptions'

interface RfMetaMiniProps {
  children: ReactNode
  color?: ColorOptions
}

const RfMetaMini = ({ children, color = ColorOptions.gray400 }: RfMetaMiniProps) => (
  <p
    className={`leading-3.5 mb-0 text-m-mini font-normal uppercase tracking-widest sm:text-xs sm:font-medium text-${color}`}
  >
    {children}
  </p>
)

export default RfMetaMini
