import { useCallback } from 'react'

import {
  BookmarkFolderPartsFragment,
  SharedFolderFragment,
  SharedFolderStatus,
  useShareBookmarkFolderMutation,
  useUnshareBookmarkFolderMutation
} from 'gql'

import notifyError from 'utils/errorNotifier'
import { trackCollectionAction } from 'utils/tracking/analytics'

const useShareCollection = () => {
  const [unshareFolder] = useUnshareBookmarkFolderMutation()
  const [shareFolder] = useShareBookmarkFolderMutation()

  const shareWithUsers = useCallback(
    async (
      bookmarkFolder: BookmarkFolderPartsFragment,
      selectedUsers: { id: string }[]
    ) => {
      const response = await shareFolder({
        variables: {
          input: {
            bookmarkFolderId: bookmarkFolder.id,
            userIds: selectedUsers.map((u) => u.id)
          }
        }
      })
      const bookmarkFolderResponse = response?.data?.shareBookmarkFolder?.bookmarkFolder
      if (bookmarkFolderResponse) {
        trackCollectionAction({
          action: 'collection_shared',
          location: window.location.pathname,
          collection_name: bookmarkFolderResponse.name,
          collection_id: bookmarkFolderResponse.id,
          is_reforge_collection:
            bookmarkFolderResponse.reforgeCollection ||
            !!bookmarkFolderResponse.forkedFromId
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const unshareWithUser = useCallback(async (sharedFolder?: SharedFolderFragment) => {
    if (!sharedFolder) return

    const response = await unshareFolder({
      variables: {
        input: {
          sharedFolderId: sharedFolder?.id
        }
      }
    })
    const bookmarkFolder = response?.data?.unshareBookmarkFolder?.bookmarkFolder
    if (bookmarkFolder) {
      trackCollectionAction({
        action: 'collection_unshared',
        location: window.location.pathname,
        collection_name: bookmarkFolder.name,
        collection_id: bookmarkFolder.id,
        is_reforge_collection:
          bookmarkFolder.reforgeCollection || !!bookmarkFolder.forkedFromId
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleToggleShare = useCallback(
    async (bookmarkFolder?: BookmarkFolderPartsFragment | null) => {
      if (!bookmarkFolder) return null

      const sharedFolder =
        (bookmarkFolder?.sharedFolders || []).filter(
          (sf) => sf.sharedPartyType === 'Subscription'
        )[0] || null

      if (sharedFolder?.status === 'shared') {
        try {
          trackCollectionAction({
            action: 'collection_unshared',
            location: window.location.pathname,
            collection_name: bookmarkFolder.name,
            collection_id: bookmarkFolder.id,
            is_reforge_collection:
              bookmarkFolder.reforgeCollection || !!bookmarkFolder.forkedFromId,
            trigger_action: 'collection_toggle_switch'
          })
          await unshareFolder({
            variables: {
              input: {
                sharedFolderId: sharedFolder.id
              }
            },
            optimisticResponse: {
              __typename: 'Mutation',
              unshareBookmarkFolder: {
                __typename: 'UnshareBookmarkFolderPayload',
                bookmarkFolder: null,
                errors: null,
                sharedFolder: {
                  ...sharedFolder,
                  status: SharedFolderStatus.PRIVATE
                }
              }
            }
          })
        } catch (error: unknown) {
          notifyError(error)
        }
      } else {
        try {
          trackCollectionAction({
            action: 'collection_shared',
            location: window.location.pathname,
            collection_name: bookmarkFolder.name,
            collection_id: bookmarkFolder.id,
            is_reforge_collection:
              bookmarkFolder.reforgeCollection || !!bookmarkFolder.forkedFromId,
            trigger_action: 'collection_toggle_switch'
          })
          await shareFolder({
            variables: {
              input: {
                bookmarkFolderId: bookmarkFolder.id
              }
            },
            optimisticResponse: sharedFolder
              ? {
                  __typename: 'Mutation',
                  shareBookmarkFolder: {
                    __typename: 'ShareBookmarkFolderPayload',
                    bookmarkFolder: null,
                    errors: null,
                    sharedFolders: [
                      {
                        ...sharedFolder,
                        status: SharedFolderStatus.SHARED
                      }
                    ]
                  }
                }
              : undefined
          })
        } catch (error: unknown) {
          notifyError(error)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return { handleToggleShare, unshareWithUser, shareWithUsers }
}

export default useShareCollection
