import { useContext, useEffect, useMemo } from 'react'
import { v4 as uuid } from 'uuid'

import PageLoadContext from 'domains/Sanity/marketingSite/Contexts/PageLoadContext'

import { trackVideoPlayed } from 'utils/tracking/analytics'

interface YoutubeEmbedProps {
  title?: string
  videoId: string
}

declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void
    YT: any
  }
}

const YoutubeEmbed = ({ title, videoId }: YoutubeEmbedProps) => {
  const uniqueId = useMemo(() => uuid(), [])
  const { youtubeIframeScriptLoaded } = useContext(PageLoadContext)

  const trackVideoPlay = (event: any) => {
    event.target.isMuted()
    trackVideoPlayed({
      object_id: videoId,
      object_type: window.location.pathname.includes('podcast/unsolicited-feedback')
        ? 'podcast'
        : 'youtube',
      path: window.location.pathname,
      related_identifiers: {
        muted: event.target.isMuted(),
        playback_speed: event.target.getPlaybackRate()
      }
    })
  }

  const onPlayerStateChange = (event: any) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      trackVideoPlay(event)
    }
  }

  const onPlayerRateChange = (event: any) => {
    if (event.target.getPlayerState() === window.YT.PlayerState.PLAYING) {
      trackVideoPlay(event)
    }
  }

  useEffect(() => {
    let player: any = null

    if (youtubeIframeScriptLoaded) {
      player = new window.YT.Player(`js-player-${uniqueId}`, {
        events: {
          onStateChange: onPlayerStateChange,
          onPlaybackRateChange: onPlayerRateChange
        }
      })
    }

    return () => {
      player?.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueId, videoId, youtubeIframeScriptLoaded])

  return (
    <iframe
      className="absolute top-0 left-0 h-full w-full"
      src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1&origin=${window.location.origin}`}
      title={title}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      id={`js-player-${uniqueId}`}
    />
  )
}

export default YoutubeEmbed
