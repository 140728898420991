interface PodcastEmbedProps {
  src: string
  title: string
}

const PodcastEmbed = ({ src, title }: PodcastEmbedProps) => {
  return (
    <div className="podcast-embed chromatic-ignore">
      <iframe height="200" src={src} width="100%" title={title} />
    </div>
  )
}

export default PodcastEmbed
