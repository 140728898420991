import OverallLessonProgress from 'components/OverallLessonProgress/OverallLessonProgress'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import {
  CohortDashboardScheduledWeekPartsFragment,
  CohortDashboardUserProgramPartsFragment
} from 'gql'

import { getScheduledWeekProgressSummary } from 'utils/cohortUtils'

import WeeklyBlockChecklistCard from './WeeklyBlockChecklistCard'

interface MaterialChecklistCardProps {
  scheduledWeek: CohortDashboardScheduledWeekPartsFragment
  activeProgram: CohortDashboardUserProgramPartsFragment
  scrollToSectionWithRelevantLesson: () => void
}

export const MaterialChecklistCard = ({
  scheduledWeek,
  activeProgram,
  scrollToSectionWithRelevantLesson
}: MaterialChecklistCardProps) => {
  const {
    totalEstimatedTime,
    totalLessons,
    totalCompleteLessons,
    totalLessonProgressPercent
  } = getScheduledWeekProgressSummary(scheduledWeek, activeProgram)

  const materialCompleted = totalLessons === totalCompleteLessons
  const titleComp = (
    <RfParagraphMedium>
      {materialCompleted ? 'Material completed' : 'Complete the material'}
    </RfParagraphMedium>
  )

  const subtitleComp = (
    <OverallLessonProgress
      overallProgressPercent={totalLessonProgressPercent}
      totalLessons={totalLessons}
      completeLessonCount={totalCompleteLessons}
      totalEstimatedTime={totalEstimatedTime}
      uniqueId={scheduledWeek.id}
      hideCompletedCheck
    />
  )
  return (
    <div
      onClick={scrollToSectionWithRelevantLesson}
      onKeyDown={scrollToSectionWithRelevantLesson}
      tabIndex={0}
      role="button"
    >
      <WeeklyBlockChecklistCard
        titleComponent={titleComp}
        subtitleComponent={subtitleComp}
        checked={materialCompleted}
      />
    </div>
  )
}

export default MaterialChecklistCard
