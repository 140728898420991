import { useEffect, useState } from 'react'

import {
  CohortDashboardCohortPartsFragment,
  CohortViewerNewCohortPartsFragment
} from 'gql'

import { scheduledWeekIdToDisplay } from 'utils/cohortUtils'

interface UseCurrentScheduledWeekProps {
  cohort?: CohortDashboardCohortPartsFragment | CohortViewerNewCohortPartsFragment
  ignoreUrlParams?: boolean
  loaded?: boolean
  showCohortOffboarding?: boolean
  hasCohortOffboardingStarted?: boolean
}

export const useCurrentScheduledWeekId = ({
  cohort,
  ignoreUrlParams,
  loaded = false,
  showCohortOffboarding = false,
  hasCohortOffboardingStarted = false
}: UseCurrentScheduledWeekProps) => {
  const [weekId, setWeekId] = useState('')
  const [isSettingWeekId, setIsSettingWeekId] = useState(true)

  useEffect(() => {
    if (loaded && !weekId && !!cohort?.scheduledWeeks?.length) {
      setIsSettingWeekId(true)
      const weekId = scheduledWeekIdToDisplay(
        cohort,
        ignoreUrlParams,
        showCohortOffboarding,
        hasCohortOffboardingStarted
      )
      setWeekId(weekId)
      setIsSettingWeekId(false)
    }
  }, [
    loaded,
    setWeekId,
    weekId,
    cohort,
    ignoreUrlParams,
    showCohortOffboarding,
    hasCohortOffboardingStarted
  ])

  return { weekId, setWeekId, isSettingWeekId }
}
