import { Link } from 'react-router-dom-v5-compat'
import { twMerge } from 'tailwind-merge'

import { useCurrentUser } from 'hooks/useCurrentUser'

import BadgeableAvatar from './BadgeableAvatar'

export function AccountLink({
  className,
  onClick,
  withName = false,
  animate = false,
  avatarSize = 40
}: {
  className?: string
  onClick?: () => void
  animate?: boolean
  withName?: boolean
  avatarSize?: number
}) {
  const { currentUser } = useCurrentUser()

  return (
    <Link
      className={twMerge(
        'relative flex h-10 w-10 items-center p-0 no-underline hover:no-underline',
        className
      )}
      to="/account"
      onClick={onClick}
    >
      {animate && (
        <div className="absolute left-2 top-2 h-6 w-6 animate-ping rounded-full bg-rb-teal-200" />
      )}
      <BadgeableAvatar
        className="ml-2 shrink-0"
        avatarUrl={currentUser?.profile?.avatarUrl || ''}
        width={avatarSize}
        height={avatarSize}
        fullName={currentUser?.fullName || ''}
      />
      {withName && (
        <span className="overflow-x-hidden whitespace-nowrap pl-3 text-base font-semibold text-rb-black">
          {currentUser?.fullName}
        </span>
      )}
    </Link>
  )
}
