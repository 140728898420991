import { useHistory } from 'react-router-dom'

import { SubscriptionManagementCard } from 'domains/Subscription/SubscriptionCard/SubscriptionManagementCards'

import { getPrettyFullDate } from 'utils/date'
import { trackCtaClicked } from 'utils/tracking/analytics'

interface NewPlansAvailableCardProps {
  isSubscriptionOwner: boolean
  expiresAt: string
}

export const NewPlansAvailableCard = ({
  isSubscriptionOwner,
  expiresAt
}: NewPlansAvailableCardProps) => {
  const history = useHistory()
  const prettyExpirationDate = getPrettyFullDate(expiresAt)

  let secondaryButton = {
    copy: 'See New Plans',
    onclick: () => {
      trackCtaClicked({
        cta_location: 'subscription_card',
        cta_type: 'button',
        text: 'see new plans',
        destination: '/manage-plan'
      })
      history.push('/manage-plan')
    }
  }
  if (!isSubscriptionOwner) {
    secondaryButton = {
      copy: 'View Plans',
      onclick: () => {
        trackCtaClicked({
          cta_location: 'subscription_card',
          cta_type: 'button',
          text: 'view plans',
          destination: '/manage-plan'
        })
        history.push('/manage-plan')
      }
    }
  }

  return (
    <SubscriptionManagementCard
      dataTestId="new-plans-available-card"
      topBarColorClass="gradient-to-r from-rb-pink-50 to-rb-teal-50"
      heading="We have new plans available!"
      details={
        <p className="mt-1">
          Reforge is now offering more flexible plans. Make your selection before{' '}
          {prettyExpirationDate}.
        </p>
      }
      secondaryButton={secondaryButton}
    />
  )
}
