import { ReactNode } from 'react'

import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

interface ChildrenProps {
  children: ReactNode
  className?: string
}

const RfParagraphMediumSemiBold = ({ children, className }: ChildrenProps) => (
  <RfParagraphMedium className={className}>
    <span className="font-semibold">{children}</span>
  </RfParagraphMedium>
)

export default RfParagraphMediumSemiBold
