import { useRouteMatch } from 'react-router-dom'

import Button from 'components/Button'
import { ModalContent, ModalHeader } from 'components/Modal'
import { CheckmarkBorderlessIcon } from 'components/icons'
import RfParagraphLarge from 'components/typography/RfParagraph/RfParagraphLarge'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { trackCtaClicked } from 'utils/tracking/analytics'

export interface CohortPassPurchasedModalContentProps {
  handleClose: ({
    trackClose,
    nextPagePath
  }: {
    trackClose?: boolean
    nextPagePath?: string
  }) => void
  cohortPassCount: number
  isIndividual?: boolean
}

export const CohortPassPurchasedModalContent = ({
  handleClose,
  cohortPassCount,
  isIndividual
}: CohortPassPurchasedModalContentProps) => {
  const isTeamMembersPage = useRouteMatch().path.startsWith('/team_members')

  return (
    <ModalContent className="pb-8 text-center">
      <div className="flex flex-col items-center">
        <ModalHeader>
          <CheckmarkBorderlessIcon />
        </ModalHeader>
      </div>

      <RfParagraphLarge className="flex flex-col items-center pb-4 font-semibold">
        {`You purchased ${cohortPassCount} Live Course Pass${
          cohortPassCount > 1 ? 'es' : ''
        }!`}
      </RfParagraphLarge>
      {isIndividual ? (
        <RfParagraphSmall className="px-12 text-center">
          {`The Live Course Pass${
            cohortPassCount > 1 ? 'es are' : ' is'
          } now available for use.`}
        </RfParagraphSmall>
      ) : (
        <RfParagraphSmall className="px-12 text-center">
          {`The Live Course Pass${
            cohortPassCount > 1 ? 'es are' : ' is'
          } now available for use. You can assign Live Course Passes to members of your team.`}
        </RfParagraphSmall>
      )}
      {isTeamMembersPage || isIndividual ? (
        <Button
          variant="fill"
          size="small"
          className="mt-16 inline w-[300px] items-center"
          dataTest="cohort-pass-purchased-modal-done-button"
          onClick={() => {
            handleClose({ trackClose: true })
          }}
        >
          Done
        </Button>
      ) : (
        <div className="mt-16 flex justify-center text-center">
          <Button
            variant="outline"
            className="mr-[10px] inline w-[150px]"
            dataTest="cohort-pass-purchased-modal-done-button"
            onClick={() => {
              handleClose({ trackClose: true })
            }}
          >
            Done
          </Button>
          <Button
            className="ml-[10px] inline w-[250px]"
            onClick={() => {
              trackCtaClicked({
                cta_location: 'cohort_pass_purchase_success_modal',
                cta_type: 'button',
                text: 'assign passes'
              })

              handleClose({ nextPagePath: '/team_members' })
            }}
          >
            Assign Passes
          </Button>
        </div>
      )}
    </ModalContent>
  )
}
