import { useReducer, useState } from 'react'

import BookmarksList, {
  IFilterData,
  SORT_NOTES_NEW_TO_OLD,
  filtersForQuery
} from 'domains/Bookmarks/BookmarksList'
import { useBookmarkFiltersForTracking } from 'domains/Bookmarks/utils'
import MobileFilters from 'domains/Program/MobileFilters'

import { ErrorMessage } from 'components'
import Loading from 'components/Loading'

import { useUserBookmarksFeedTotalQuery } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import BookmarksFilterSidebar from './BookmarksFilterSidebar'
import BookmarksFiltersHeader from './BookmarksFiltersHeader'
import useBookmarkFilters from './useBookmarkFilters'

interface BookmarksProps {
  initialModuleName?: string
  isProjectsConceptsViewer?: boolean
  initialModuleId?: string
  cmsContentId?: string
  contentName?: string
  cmsProgramId?: string
  programName?: string
  trackingLocation: string
  hideFilters?: boolean
  isCohortDashboard?: boolean
  cohortSlug?: string
}

const Bookmarks = ({
  cmsProgramId,
  programName,
  cmsContentId,
  contentName,
  initialModuleName,
  initialModuleId,
  trackingLocation,
  isProjectsConceptsViewer,
  hideFilters = false,
  isCohortDashboard = false,
  cohortSlug
}: BookmarksProps) => {
  const [recentlyDeletedCount, setRecentlyDeletedCount] = useState(0)
  const bumpRecentlyDeletedCount = () => setRecentlyDeletedCount(recentlyDeletedCount + 1)
  const resetRecentlyDeletedCount = () => setRecentlyDeletedCount(0)
  // if module passed in, use it unless url already has one set
  const { programsFilterIsActive } = useBookmarkFiltersForTracking()

  const [searchText, setSearchText] = useState('')

  const [filterData, setFilterData] = useReducer(
    (filterData: IFilterData, newFilterData: Partial<IFilterData>) => ({
      ...filterData,
      ...newFilterData
    }),
    {
      currentPage: 1,
      selectedModuleName: initialModuleName || 'All Modules',
      selectedProgramName: programName || 'All Programs',
      selectedType: 'All Types',
      searchText: '',
      selectedSortOption: SORT_NOTES_NEW_TO_OLD,
      moduleOptions: {},
      programOptions: {},
      topOffset: 0,
      sidebarFilterRefreshToggle: false
    }
  )

  const { id: currentUserId } = useAssertCurrentUser()

  const updateFilterData = (newFilterData: Partial<IFilterData>) =>
    setFilterData({ ...filterData, ...newFilterData })

  const isFilterActive =
    filterData.selectedType !== 'All Types' ||
    filterData.selectedModuleName !== 'All Modules' ||
    programsFilterIsActive(filterData.selectedProgramName, cmsProgramId) ||
    filterData.searchText !== '' ||
    searchText !== ''

  const { data, loading, error } = useUserBookmarksFeedTotalQuery({
    variables: {
      userId: currentUserId,
      filters: filtersForQuery({ cmsProgramId, initialModuleId })
    }
  })

  const bookmarksCountUnfiltered = data?.user?.bookmarksTotal || 0
  const trueUnfilteredCount = bookmarksCountUnfiltered - recentlyDeletedCount
  const initialProgramIdProvided = !!cmsProgramId

  const { trueCount, ...bookmarkFilters } = useBookmarkFilters({
    cmsProgramId,
    cmsProgramName: programName,
    filterData,
    initialModuleId,
    initialModuleName,
    initialSectionId: cmsContentId,
    initialSectionName: contentName,
    isProjectsConceptsViewer,
    recentlyDeletedCount,
    searchText,
    setSearchText,
    showProgramsFilter: initialProgramIdProvided,
    trackingLocation,
    updateFilterData
  })
  const { activeFilterCount, filterKeys, filterOptions, filters, setFilters } =
    bookmarkFilters?.filterProps
  const { handleSearchSubmit, handleSearchTextChanged, handleSortOptionChange } =
    bookmarkFilters?.handlers
  const { sortOptions, selectedSortOption } = bookmarkFilters?.sortProps

  if (loading) {
    return <Loading />
  } else if (error) {
    return <ErrorMessage />
  }

  return (
    <div className="mx-auto w-full gap-x-8 py-6 lg:flex">
      <div className="w-full">
        {!hideFilters && bookmarkFilters && trueUnfilteredCount > 0 && (
          <div className="align-center justify flex justify-between gap-x-4 lg:block">
            <BookmarksFiltersHeader
              loading={loading}
              trueCount={trueCount}
              activeFilterCount={activeFilterCount}
              handleSortOptionChange={handleSortOptionChange}
              sortOptions={sortOptions}
              selectedSortOption={selectedSortOption}
            />
            <div className="mt-[10px] h-10 self-start sm:mt-0 sm:self-center lg:hidden">
              <MobileFilters showFilterByKeyword />
            </div>
          </div>
        )}
        <BookmarksList
          trackingLocation={trackingLocation}
          currentUserId={currentUserId}
          sort={filterData.selectedSortOption}
          filterData={filterData}
          updateFilterData={updateFilterData}
          cmsProgramId={cmsProgramId}
          searchText={searchText}
          filterableByProgram={!initialProgramIdProvided}
          isFilterActive={isFilterActive}
          initialModuleId={initialModuleId}
          initialModuleName={initialModuleName}
          initialSectionId={cmsContentId}
          recentlyDeletedCount={recentlyDeletedCount}
          resetRecentlyDeletedCount={resetRecentlyDeletedCount}
          bumpRecentlyDeletedCount={bumpRecentlyDeletedCount}
          isCohortDashboard={isCohortDashboard}
          cohortSlug={cohortSlug}
        />
      </div>
      <div className="hidden w-1/4 lg:block">
        {!hideFilters && bookmarkFilters && trueUnfilteredCount > 0 && (
          <BookmarksFilterSidebar
            activeFilterCount={activeFilterCount}
            filterKeys={filterKeys}
            filterOptions={filterOptions}
            filters={filters}
            handleSearchSubmit={handleSearchSubmit}
            handleSearchTextChanged={handleSearchTextChanged}
            searchText={searchText}
            setFilters={setFilters}
            className=""
          />
        )}
      </div>
    </div>
  )
}

export default Bookmarks
