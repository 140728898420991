import { Line } from 'rc-progress'
import { useEffect, useMemo, useState } from 'react'

interface CountdownBarProps {
  percentCompleted: number
}

const CountdownBar = ({ percentCompleted }: CountdownBarProps) => (
  <Line
    className="w-full stroke-rb-gray-500"
    trailColor="#D3D2D3"
    strokeLinecap="square"
    strokeWidth={3}
    trailWidth={0}
    percent={percentCompleted}
  />
)

const POLLING_INTERVAL_IN_MILLISECONDS = 50

interface CountdownSeconds {
  countdownSeconds: number
  onCountdownEnd: () => void
}

const useCountdownSeconds = ({
  countdownSeconds: countdownSecondsInitial,
  onCountdownEnd
}: CountdownSeconds) => {
  const INITIAL_COUNTDOWN_MILLISECONDS = countdownSecondsInitial * 1000

  const [countdownMilliSeconds, setCountdownMilliSeconds] = useState(
    INITIAL_COUNTDOWN_MILLISECONDS
  )
  const countdownSeconds = useMemo(
    () => Math.ceil(countdownMilliSeconds / 1000),
    [countdownMilliSeconds]
  )

  const getCountdownProgress = (countdownMilliSeconds: number) =>
    ((INITIAL_COUNTDOWN_MILLISECONDS - countdownMilliSeconds) /
      INITIAL_COUNTDOWN_MILLISECONDS) *
    100

  const countdownProgress = getCountdownProgress(countdownMilliSeconds)

  useEffect(() => {
    const interval = setInterval(() => {
      if (countdownMilliSeconds === 0) {
        clearInterval(interval)
        onCountdownEnd()
      } else {
        setCountdownMilliSeconds(countdownMilliSeconds - POLLING_INTERVAL_IN_MILLISECONDS)
      }
    }, POLLING_INTERVAL_IN_MILLISECONDS)

    return () => {
      clearInterval(interval)
    }
  })

  const resetCountdownSeconds = () => {
    setCountdownMilliSeconds(INITIAL_COUNTDOWN_MILLISECONDS)
  }

  return {
    countdownProgress,
    countdownSeconds,
    resetCountdownSeconds
  }
}

export default CountdownBar
export { useCountdownSeconds }
