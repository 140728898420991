import { ComplexPortableTextBlockProp } from '../../types'

const LIST_DECIMAL = 'list-decimal'
const LIST_DISC = 'list-disc'
export const LIST_LOWER_ALPHA = 'list-[lower-alpha]'
export const LIST_CIRCLE = 'list-[circle]'
export const LIST_LOWER_ROMAN = 'list-[lower-roman]'
export const LIST_SQUARE = 'list-[square]'

export const getListSymbolClass = (level: number, type: 'number' | 'bullet') => {
  if ([1, 4, 7].includes(level)) {
    return type === 'number' ? LIST_DECIMAL : LIST_DISC
  } else if ([2, 5, 8].includes(level)) {
    return type === 'number' ? LIST_LOWER_ALPHA : LIST_CIRCLE
  } else if ([3, 6, 9].includes(level)) {
    return type === 'number' ? LIST_LOWER_ROMAN : LIST_SQUARE
  }
  return ''
}

export const renderBulletList = ({
  children,
  value: { level }
}: ComplexPortableTextBlockProp) => (
  <ul
    className={`my-4 flex flex-col ${getListSymbolClass(level, 'bullet')} ${
      level === 1 ? 'gap-4' : ''
    }`}
    data-level={level}
  >
    {children}
  </ul>
)

export const renderNumberList = ({
  children,
  value: { level }
}: ComplexPortableTextBlockProp) => (
  <ul
    className={`my-4 flex flex-col ${getListSymbolClass(level, 'number')} ${
      level === 1 ? 'gap-4' : ''
    }`}
    data-level={level}
  >
    {children}
  </ul>
)
