import RfHeader1 from 'components/typography/RfHeader/RfHeader1'
import RfParagraphLarge from 'components/typography/RfParagraph/RfParagraphLarge'

import { CohortDashboardScheduledWeekPartsFragment } from 'gql'

interface WeeklyBlockHeaderProps {
  programName: string
  programDescription: string
  isKickoffWeek: boolean
  scheduledWeek: CohortDashboardScheduledWeekPartsFragment
}

export const WeeklyBlockHeader = ({
  programName,
  programDescription,
  isKickoffWeek,
  scheduledWeek
}: WeeklyBlockHeaderProps) => {
  return (
    <div className="mt-16 mb-10 flex flex-col space-y-2">
      <RfHeader1>
        {isKickoffWeek ? `Welcome to ${programName}` : scheduledWeek?.title}
      </RfHeader1>
      <RfParagraphLarge>
        {isKickoffWeek ? programDescription : scheduledWeek?.description}
      </RfParagraphLarge>
    </div>
  )
}
