import { ErrorMessage } from 'components'

import { useCompletionSnapshotQuery } from 'gql'

import { ShareableSnapshotModal } from './ShareableSnapshotModal'

export interface ShareableSnapshotModalContainerProps {
  page: string
}

const ShareableSnapshotModalContainer = ({
  page
}: ShareableSnapshotModalContainerProps) => {
  const { data, loading, error } = useCompletionSnapshotQuery()
  if (loading) return null
  if (error) return <ErrorMessage error={error} />

  if (!data) {
    return (
      <ErrorMessage error="Sorry, there was an issue loading the snapshot. Please refresh and try again." />
    )
  }

  if (data.completionSnapshot) {
    return <ShareableSnapshotModal page={page} data={data.completionSnapshot} />
  }

  return null
}

export default ShareableSnapshotModalContainer
