import SimpleModal from 'components/SimpleModal'

import { PLAN_OPTIONS } from 'constants/plans'

import { PlanName } from 'gql'

interface UpdatePlanNowModalProps {
  planName: PlanName
  onUpdateOnRenewalClick: () => void
  onUpdateNowClick: () => void
}

export default function UpdatePlanNowModal({
  planName,
  onUpdateOnRenewalClick,
  onUpdateNowClick
}: UpdatePlanNowModalProps) {
  const plan = PLAN_OPTIONS.find((option) => option.name === planName)

  return (
    <SimpleModal
      renderHeaderText={() =>
        plan
          ? `Continue update to the ${plan.title} Subscription?`
          : 'Continue update to the new subscription?'
      }
      renderBodyText={() => (
        <div>
          <div>
            By doing so, you will immediately update into the new subscription with
            prorated fees. You can also choose to wait until your renewal date to update.
          </div>

          <div className="pt-[10px] text-sm">
            <button className="link" onClick={onUpdateOnRenewalClick}>
              Update at renewal --{'>'}
            </button>
          </div>
        </div>
      )}
      renderFooterActionButtons={() => [
        {
          size: 'small',
          className: 'w-[200px]',
          dataTest: 'update-plan-now-button',
          onClick: onUpdateNowClick,
          children: 'Update Now'
        }
      ]}
    />
  )
}
