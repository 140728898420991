import { useState } from 'react'

import ManageBookmarkButton from 'domains/Collections/ManageBookmarkButton'

import LegacyContentCard from 'components/cards/Content/LegacyContentCard'

import {
  BookmarkType,
  CurrentUserQuery,
  ProgramBookmarkPartsFragment,
  ProgramsCmsSectionPartsFragment
} from 'gql'

import { onEnterKeyPress } from 'utils/keyboard'

import { ModuleProgress } from 'typings/scalars'

type CardIndexSection = {
  userProgress: ModuleProgress
  currentUser: NonNullable<CurrentUserQuery['currentUser']>
  isEnrolled: boolean
}

type ParentSectionCardIndexType = CardIndexSection & {
  cmsSection: ProgramsCmsSectionPartsFragment
  handleProgramPageTracking: (
    action: string,
    cmsSectionId?: string,
    cmsSectionName?: string
  ) => void
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
}

const ParentSectionCardIndex = ({
  cmsSection,
  handleProgramPageTracking,
  openAddToBookmarkFolderModal
}: ParentSectionCardIndexType) => {
  const [sectionOpen, setSectionOpen] = useState(true)
  const [isInSavedItems, setIsInSavedItems] = useState(
    !!cmsSection.contentIsInMySavedItems
  )

  const toggleSectionOpen = () => {
    if (sectionOpen) {
      handleProgramPageTracking('collapse_section', cmsSection.id, cmsSection.name)
    } else {
      handleProgramPageTracking('expand_section', cmsSection.id, cmsSection.name)
    }
    setSectionOpen(!sectionOpen)
  }

  const bookmarkInfo = {
    cmsSectionId: cmsSection.id.toString(),
    cmsModuleId: cmsSection.cmsModuleId.toString(),
    cmsProgramId: cmsSection.cmsProgramId.toString(),
    anchor: `content-header-bookmark-${cmsSection.id}`,
    type: BookmarkType.SECTIONBOOKMARK
  }

  const svgOverride = (
    <div
      className={`pr-4 text-sm font-normal leading-[18px] text-rb-gray-300 ${
        sectionOpen ? 'cursor-pointer' : ''
      }`}
    >
      {isInSavedItems ? 'Unsave' : 'Save'}
    </div>
  )

  return (
    <div
      data-test="module-card"
      className={`uk-width-1-1 uk-grid-collapse -mx-1 mb-2 p-1 ${
        sectionOpen ? 'hover:bg-gray-100' : ''
      }`}
      uk-grid=""
    >
      <div
        role="row"
        onKeyUp={onEnterKeyPress(() => !sectionOpen && toggleSectionOpen())}
        tabIndex={0}
        className={`uk-width-1-1 uk-margin-small-bottom flex items-center ${
          !sectionOpen ? 'cursor-pointer' : ''
        }`}
        onClick={() => !sectionOpen && toggleSectionOpen()}
      >
        <div
          role="row"
          className="flex w-full justify-between"
          tabIndex={0}
          onKeyUp={onEnterKeyPress(() => sectionOpen && toggleSectionOpen())}
          onClick={() => sectionOpen && toggleSectionOpen()}
        >
          <div className={`flex items-center ${sectionOpen ? 'cursor-pointer' : ''}`}>
            <span
              uk-icon={`icon: ${
                sectionOpen ? 'triangle-down' : 'triangle-right'
              }; ratio: 1.0`}
              className="uk-margin-small-right"
            />
            <span className="text-sm font-medium leading-[14px] text-rb-gray-500 sm:text-[15px] sm:leading-[18px]">
              {cmsSection.name?.toUpperCase()}
            </span>
            {!!cmsSection.estimatedTime && (
              <span className="ml-3 text-sm font-normal leading-[18px] text-rb-gray-300">{`${cmsSection.estimatedTime} min`}</span>
            )}
          </div>
          <ManageBookmarkButton
            contentBookmarkId={cmsSection.contentBookmarkId}
            openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
            isInSavedItems={isInSavedItems}
            setIsInSavedItems={setIsInSavedItems}
            dropdownPosition="left"
            fetchOptions={{}}
            bookmarkInfo={bookmarkInfo}
            svgOverride={svgOverride}
          />
        </div>
      </div>
      <div className={`${sectionOpen ? '' : 'uk-hidden'}`}>
        <div className="uk-grid-collapse" uk-grid="">
          {cmsSection.children.map((childSection) => (
            <div
              key={`sec${childSection.id}`}
              className="mb-5 w-full pb-2.5 sm:mr-[30px] sm:min-h-[355px] sm:w-[264px]"
            >
              <LegacyContentCard
                content={childSection}
                openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

type ModuleCardIndexType = CardIndexSection & {
  cmsSections: ProgramsCmsSectionPartsFragment[]
  handleProgramPageTracking: (
    action: string,
    cmsSectionId?: string,
    cmsSectionName?: string
  ) => void
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
}

const ModuleCardIndex = ({
  cmsSections,
  userProgress,
  currentUser,
  isEnrolled,
  handleProgramPageTracking,
  openAddToBookmarkFolderModal
}: ModuleCardIndexType) => {
  // lessonNum could be undefined if the section content is not of 'Lesson' type
  // in this case lessonNum wouldn't be relevant
  let lessonNum: number | undefined = 0

  return (
    <div className="uk-grid-collapse" uk-grid="">
      {cmsSections.map((section) => {
        if (section.children.length > 0) {
          return (
            <ParentSectionCardIndex
              key={`sec${section.id}`}
              currentUser={currentUser}
              cmsSection={section}
              userProgress={userProgress}
              isEnrolled={isEnrolled}
              handleProgramPageTracking={handleProgramPageTracking}
              openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
            />
          )
        }

        const contentType = section.contentType || 'Lesson'
        lessonNum = contentType === 'Lesson' ? (lessonNum || 0) + 1 : undefined

        return (
          <div
            data-test="module-card"
            key={`sec${section.id}`}
            className="mb-5 w-full pb-2.5 sm:mr-[30px] sm:w-[252px]"
          >
            <LegacyContentCard
              content={section}
              openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
            />
          </div>
        )
      })}
    </div>
  )
}

export default ModuleCardIndex
