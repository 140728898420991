import { twMerge } from 'tailwind-merge'

interface ButtonEmojiProps {
  count: number
  emojiId: string
  onClick?: any
  selected?: boolean
}

const ButtonEmoji = ({ count, emojiId, onClick, selected = false }: ButtonEmojiProps) => {
  return (
    <button
      className={twMerge(
        'mx-[5px] h-[28px] min-w-[38px] rounded-full border border-rb-gray-100 bg-rb-white py-0 px-[7px] hover:border-rb-teal-300 hover:bg-rb-gray-50',
        selected ? 'border-rb-teal-200' : ''
      )}
      id={emojiId}
      onClick={onClick}
    >
      <div className="flex h-full w-full flex-row items-center">
        <div className="flex h-full flex-col items-center justify-center">
          {/* @ts-ignore */}
          <em-emoji id={emojiId} size="1.15em" />
        </div>
        <div className="ml-[5px] text-xs font-semibold text-rb-black">{count}</div>
      </div>
    </button>
  )
}

export default ButtonEmoji
