import CourseFeatureGrid from 'domains/Course/CourseFeatureGrid'
import CourseDetailWhyTakeCourse from 'domains/CourseDetail/CourseDetailWhyTakeCourse'
import LogoSection from 'domains/CoursesSeo/LogoSection'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'
import SeoPageHero from 'domains/Seo/SeoPageHero'

import { Loading } from 'components'

import { useCoursesIndexSeoQuery } from 'gql'

import BackgroundImageMobile from 'images/course-topic-banner-mobile.png'
import BackgroundImage from 'images/course-topic-banner.png'

import CoursesList from './CoursesList'

export const CoursesIndexPage = () => {
  const { data, loading } = useCoursesIndexSeoQuery()

  if (!data || loading) {
    return <Loading />
  }

  return (
    <MarketingLayout
      page={data.marketingCoursesIndex.page}
      scrollToTop={false}
      contentClassName="flex flex-col space-y-8 pb-16"
    >
      <SeoPageHero
        title="Online courses taught by tech experts"
        subtitle="Reforge courses provide the product, growth, and marketing expertise you need to unlock step-change growth in your career."
        ctaText="Explore all courses"
        ctaHref="#courses"
        bgImage={BackgroundImage}
        bgImageMobile={BackgroundImageMobile}
      />
      <LogoSection />
      <div className="flex flex-col items-center px-4 pt-4 md:px-[4vw]">
        <div className="min-w-[343px] max-w-[343px] xs:max-w-[475px] md:max-w-[750px] lg:max-w-[1150px]">
          <CourseDetailWhyTakeCourse headerOverride="Enroll in seasonal live courses to fully grasp new and evolving topics" />
          <div id="courses" className="invisible block relative top-[-76px]" />
          <div className="pt-8">
            <h2>Explore courses</h2>
            <CoursesList />
          </div>
          <CourseFeatureGrid
            className="md:p-10 p-4 bg-rb-orange-25 mt-8"
            tableClassName="md:m-10 m-4 mr-0 md:mr-0"
            theme="black"
          />
        </div>
      </div>
    </MarketingLayout>
  )
}

export default CoursesIndexPage
