import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import BadgeableAvatar from 'components/BadgeableAvatar'
import Carousel from 'components/Carousel/Carousel'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import { ArtifactAuthor, CclExpert } from 'gql'

interface UnitExpertCollaboratorsPreviewProps {
  experts?: any[] | null
  linkToAuthProfiles?: boolean
  avatarImageClassName?: string
  carouselItemWidth?: number
  containerClassName?: string
  expertNameClassName?: string
  mainClassName?: string
  trackAuthorClick?: (author: ArtifactAuthor) => void
}

interface ExpertPreviewProps {
  expert: ArtifactAuthor | CclExpert
  avatarImageClassName?: string
  expertNameClassName?: string
  containerClassName?: string
}
export const ExpertPreview = ({
  expert,
  expertNameClassName,
  containerClassName
}: ExpertPreviewProps) => {
  const getAvatarPhotoUrl = (): string => {
    if (expert.__typename === 'CclExpert') {
      return `${expert.avatarUrl}?auto=format&w=200` || ''
    } else {
      return expert.avatarPhoto?.imageUrl || ''
    }
  }

  const getAltText = (): string => {
    if (expert.__typename === 'CclExpert') {
      return expert.name || ''
    } else {
      return expert.avatarPhoto?.imageAlt || ''
    }
  }

  const getCompanyName = (): string | undefined => {
    if (expert.__typename === 'CclExpert') {
      return expert.companyName || undefined
    } else {
      return expert.company?.name || undefined
    }
  }

  const createProfile = () => {
    const isCollective =
      (expert.__typename === 'ArtifactAuthor' || expert.__typename === 'CclExpert') &&
      !!expert?.isCollectiveMember

    return {
      ...expert,
      kind: isCollective ? 'collective' : ''
    }
  }

  return (
    <div className={twMerge('flex flex-row gap-3.5', containerClassName)}>
      <BadgeableAvatar
        className="h-14 w-14"
        alt={getAltText()}
        avatarUrl={getAvatarPhotoUrl()}
        user={createProfile()}
      />
      <div className="flex flex-col justify-center flex-wrap max-w-[175px]">
        <RfParagraphSmall>
          <span className={expertNameClassName}>{expert.name}</span>
        </RfParagraphSmall>
        <RfParagraphMini color={ColorOptions.gray}>
          {expert.position}
          {getCompanyName() && ` at ${getCompanyName()}`}
        </RfParagraphMini>
      </div>
    </div>
  )
}

export const UnitExpertCollaboratorsPreview = ({
  experts,
  linkToAuthProfiles = false,
  avatarImageClassName,
  carouselItemWidth = 270,
  containerClassName,
  expertNameClassName,
  mainClassName,
  trackAuthorClick
}: UnitExpertCollaboratorsPreviewProps) => {
  if (!experts || experts.length === 0) return null

  return (
    <Carousel height={100} itemWidth={carouselItemWidth}>
      <div
        className={twMerge(
          'my-6 flex min-w-max flex-col gap-6 sm:flex-row',
          mainClassName
        )}
      >
        {experts.map((expert) => (
          <span
            className={twMerge('flex-none hover:no-underline', containerClassName)}
            key={expert.slug}
          >
            {linkToAuthProfiles ? (
              <Link
                to={`/profiles/${expert.slug}`}
                rel="noreferrer"
                onClick={() => {
                  trackAuthorClick && trackAuthorClick(expert)
                }}
              >
                <ExpertPreview
                  expert={expert}
                  avatarImageClassName={avatarImageClassName}
                  expertNameClassName={expertNameClassName}
                />
              </Link>
            ) : (
              <ExpertPreview
                expert={expert}
                avatarImageClassName={avatarImageClassName}
                expertNameClassName={expertNameClassName}
              />
            )}
          </span>
        ))}
      </div>
    </Carousel>
  )
}

export default UnitExpertCollaboratorsPreview
