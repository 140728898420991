import { LoginParamsInput } from 'gql'

// TODO: With the introduction of the LoginParamsInput type, this type is redundant, but I don't want to change a
// bunch of references in the current PR.
export interface LoginParams extends LoginParamsInput {}

function getKindPath(kind: string) {
  if (kind === 'google') return 'google_oauth2'
  if (kind === 'microsoft') return 'azure_activedirectory_v2'
  if (kind === 'linkedin') return 'linkedin'
  return kind
}

export const getLoginUrl = (
  kind: 'google' | 'linkedin' | 'microsoft',
  params: LoginParams
) => {
  const kindPath = getKindPath(kind)

  const {
    token,
    fwd,
    tos,
    reforgeLogin,
    referer,
    marketingNav,
    courseType,
    cmsProgramSlug,
    forEventRSVP,
    eventId,
    userTimezone,
    happening
  } = params

  const base = `/auth/${kindPath}?token=${token}&fwd=${fwd}&from=login&tos=${tos}&reforgeLogin=${reforgeLogin}&referer=${encodeURIComponent(
    referer || ''
  )}&marketingNav=${marketingNav}&forEventRSVP=${forEventRSVP}&eventId=${eventId}&userTimezone=${userTimezone}&happening=${happening}`

  // On Demand courses
  if (courseType && cmsProgramSlug) {
    return `${base}&courseType=${courseType}&cmsProgramSlug=${cmsProgramSlug}`
  }
  // Live courses
  if (courseType) {
    return `${base}&courseType=${courseType}`
  }
  // Non-course URLs
  return base
}
