import { Link } from 'react-router-dom'

import RfParagraphMediumSemiBold from 'components/typography/RfParagraph/RfParagraphMediumSemiBold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { ProgramPageCclCoursePartsFragment, useUpdateUserMutation } from 'gql'

import { trackNavigationClicked } from 'utils/tracking/analytics'

interface ProgramCourseOnDemandBannerProps {
  cmsProgramId: string
  cclCourse: ProgramPageCclCoursePartsFragment
}

const ProgramCourseOnDemandBanner = ({
  cclCourse,
  cmsProgramId
}: ProgramCourseOnDemandBannerProps) => {
  const [updateUser] = useUpdateUserMutation()

  const handleCourseLinkOnClick = () => {
    updateUser({
      variables: {
        input: {
          preferredCourseView: 'new'
        }
      }
    })

    trackNavigationClicked({
      location: 'program_dashboard',
      location_id: cmsProgramId,
      text: 'go_to_course',
      destination: `/courses/${cclCourse.slug}/on-demand`,
      type: 'hyperlink text',
      path: window.location.pathname,
      url: window.location.href,
      related_identifiers: {
        course_id: cclCourse?.legacyCourseId,
        ccl_course_id: cclCourse?.id,
        ccl_static_course_id: cclCourse?.staticId,
        cms_program_id: cmsProgramId,
        course_title: cclCourse?.title,
        content_mode: 'on-demand'
      }
    })
  }

  return (
    <div className="px-6 py-4 w-full bg-rb-orange-25 flex flex-col lg:flex-row justify-start lg: gap-2.5 rounded-md lg:items-center lg:justify-between">
      <div className="md:max-w-[500px]">
        <RfParagraphMediumSemiBold>
          You&apos;re viewing the legacy version of this course
        </RfParagraphMediumSemiBold>
        <RfParagraphSmall>
          Go to the latest version of this course to experience actionable learning like
          never before, featuring step-by-step guides, practical real-world examples, and
          Reforge AI for advice that maps to your job.
        </RfParagraphSmall>
      </div>
      <div>
        <Link
          onClick={handleCourseLinkOnClick}
          to={`/courses/${cclCourse.slug}/on-demand`}
          className="text-rb-gray-400 text-sm font-medium"
        >
          Go to latest course experience →
        </Link>
      </div>
    </div>
  )
}

export default ProgramCourseOnDemandBanner
