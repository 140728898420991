import { useEffect } from 'react'

import { CurrentUserPartsFragment } from 'gql'

import { trackCopiedText } from 'utils/tracking/analytics'

export const useAntiCopy = ({
  fullLength,
  currentUser
}: {
  fullLength: number
  currentUser: CurrentUserPartsFragment | null | undefined
}) => {
  useEffect(() => {
    const source = document.querySelector('body')
    const handleCopy = () => {
      const selection = window.getSelection()
      const selectionNode = selection?.anchorNode
      const chatComponent = document.querySelector('#ai-chat-container')

      // Check if the selection is inside the chat component
      if (selectionNode && chatComponent?.contains(selectionNode)) {
        // If the selection is inside the chat, do not trigger anti-copy logic
        return
      }

      const selectionLength = selection?.toString().length || 0
      const copiedPercent = 100 * (selectionLength / fullLength)
      trackCopiedText({
        length: selectionLength,
        full_length: fullLength,
        copied_perc: copiedPercent
      })

      if (copiedPercent > 49) {
        document.getElementById('anticopy-button')?.click()
      }
    }

    source?.addEventListener('copy', handleCopy)

    return () => {
      source?.removeEventListener('copy', handleCopy)
    }
  }, [currentUser, fullLength])
}

export default useAntiCopy
