import { cn } from 'utils/tailwind'

interface HamburgerButtonProps {
  onClick: () => void
  mobileNavOpen: boolean
}

const HamburgerButton = ({ onClick, mobileNavOpen }: HamburgerButtonProps) => {
  return (
    <button
      type="button"
      className="cursor-pointer bg-transparent lg:hidden"
      onClick={onClick}
    >
      <span className="hidden">{mobileNavOpen ? 'Close' : 'Open'} Menu</span>
      <div className="relative h-[32px] w-[32px]">
        <div
          className={cn(
            'absolute top-1/2 left-[3px] h-[1px] w-[26px] translate-y-[-6px] bg-rb-black transition-all duration-[250ms] ease-[cubic-bezier(.2,.6,.3,1)]',
            mobileNavOpen && 'w-[28px] translate-x-[-1px] translate-y-0 -rotate-[135deg]'
          )}
        />
        <div
          className={cn(
            'absolute top-1/2 left-[3px] h-[1px] w-[26px] translate-y-[6px] bg-rb-black transition-all duration-[250ms] ease-[cubic-bezier(.2,.6,.3,1)]',
            mobileNavOpen && 'w-[28px] translate-x-[-1px] translate-y-0 rotate-[135deg]'
          )}
        />
      </div>
    </button>
  )
}

export default HamburgerButton
