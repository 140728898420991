import { MyCoursesUserCoursePartsFragment } from 'gql'

import { formatDateRange } from 'utils/date'

import { LegacyCourseCardProps } from './LegacyCourseCard'
import LegacyCourseCardWithCustomThumbnail from './LegacyCourseCardWithCustomThumbnail'

interface ExtendedLegacyCourseCardProps extends LegacyCourseCardProps {
  userCourse?: MyCoursesUserCoursePartsFragment
}

const CourseInProgressLegacyCourseCard = (props: ExtendedLegacyCourseCardProps) => {
  const { startsAt, endsAt } = props.userCourse?.courseSession || {}

  const CourseInProgressLegacyCardFooter = () => {
    if (props.cardType !== 'live') {
      if (!props.progressPercent) {
        return (
          <div>
            <span>On-demand course</span>
          </div>
        )
      } else {
        return (
          <span className="text-rb-gray-300 text-xs">
            On-demand course • {props.progressPercent}% complete
          </span>
        )
      }
    } else {
      return (
        <div>
          <span>Live course • {formatDateRange(startsAt, endsAt)}</span>
        </div>
      )
    }
  }

  return (
    <LegacyCourseCardWithCustomThumbnail
      {...props}
      CourseFooterOverride={CourseInProgressLegacyCardFooter}
    />
  )
}

export default CourseInProgressLegacyCourseCard
