import React from 'react'

import Button from 'components/Button'

import { titleize } from 'utils/stringUtils'
import { cn } from 'utils/tailwind'

import { ReactComponent as CheckmarkIcon } from 'images/p-checkmark.svg'

import { PlanAndPricingInfo } from './components/PlanAndPricingInfo'

interface PlanCardProps {
  planName: string
  ctaLabel: string
  price?: number | null
  isCurrentPlan?: boolean
  isMostPopular?: boolean
  firstSubheader: string | React.ReactElement<any> | null
  secondSubheader?: string | React.ReactElement<any> | null
  features: string[]
  prevPlanName: string | null
  onCtaClick: () => void
  showCta?: boolean
  ctaDisabled?: boolean
  className?: string
  footnote?: string
}

const PricingPlanCard = ({
  planName,
  ctaLabel,
  price,
  isCurrentPlan,
  isMostPopular,
  firstSubheader,
  secondSubheader,
  features,
  prevPlanName,
  onCtaClick,
  showCta,
  ctaDisabled = false,
  className,
  footnote
}: PlanCardProps) => {
  return (
    <div
      className={cn(
        'relative flex w-full flex-col gap-6 rounded-xl border border-rb-gray-200 py-6 px-4 font-sans sm:w-[calc(50%-12px)]',
        {
          'bg-rb-blue-50': isMostPopular
        },
        className
      )}
    >
      {isMostPopular && (
        <div className="absolute top-[-8px] left-[-1px] flex h-5 w-[calc(100%+2px)] items-center justify-center rounded-none rounded-t-full bg-rb-blue-200 text-xs text-white">
          Most popular
        </div>
      )}
      {isCurrentPlan && (
        <div className="absolute top-[-10px] left-1/2 flex h-5 w-[248px] -translate-x-1/2 items-center justify-center rounded bg-rb-green-75 text-xs">
          Your current plan
        </div>
      )}
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <h2 className="mb-0 text-[22px] font-semibold leading-[26.4px] text-rb-gray-500 md:text-[28px] md:leading-[33.6px]">
            {titleize(planName)}
          </h2>
          <p className="m-0 text-xs text-rb-gray-400">{firstSubheader}</p>
        </div>

        <div className="flex flex-col gap-2">
          <PlanAndPricingInfo price={price} />
          <p className="m-0 text-xs text-rb-gray-400">{secondSubheader}</p>
        </div>
      </div>

      {showCta && (
        <Button size="small" shape="rounded" onClick={onCtaClick} disabled={ctaDisabled}>
          {ctaLabel}
        </Button>
      )}

      <div className="flex flex-col gap-3">
        {prevPlanName && (
          <p className="mb-0 text-sm font-semibold leading-[1.5]">
            Everything in {prevPlanName}, plus
          </p>
        )}

        <ul className="flex list-none flex-col gap-2 p-0">
          {features?.map((feature, i) => (
            <li className="flex items-start gap-4 text-sm leading-[1.5]" key={i}>
              <CheckmarkIcon className="h-5 w-5 shrink-0" />
              {feature}
            </li>
          ))}
        </ul>
      </div>

      {footnote && <p className="text-xs text-rb-gray-300 italic mb-0">{footnote}</p>}
    </div>
  )
}

export default PricingPlanCard
