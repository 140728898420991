import { useState } from 'react'

import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import EventPage from './EventPage'

const LoggedOutEventPage = () => {
  const [page, setPage] = useState<GenericPageType>()

  return (
    <MarketingLayout
      page={page}
      showFooter={false}
      scrollToTop={(location) => !/\/events\/.*(summary)?$/.test(location.pathname)}
    >
      <EventPage setPage={setPage} />
    </MarketingLayout>
  )
}

export default LoggedOutEventPage
