import { useCheckoutModal } from 'components/CheckoutModal/useCheckoutModal'

export interface NoRemainingCohortAccessInfoCardProps {
  firstName?: string | null
}

/**
 * NoRemainingCohortAccessInfoCard
 * presentational component
 * used in ManageSeatSidePanel/CohortAccessPanelContent
 * */
export function NoRemainingCohortAccessInfoCard({
  firstName
}: NoRemainingCohortAccessInfoCardProps) {
  const { openCheckoutModal } = useCheckoutModal()
  const firstNameOrGeneric = firstName
    ? `${' '}give one to ${firstName}`
    : `${' '}assign one to this member`

  return (
    <div
      className="rounded-md text-base font-normal"
      data-testid="no-remaining-cohort-access-info-card"
    >
      <p className="font-bold">You&apos;re out of Live Course Passes</p>
      <p className="m-0">
        You need to{' '}
        <button
          className="text-teal-600 hover:underline"
          onClick={() => openCheckoutModal({ isIndividual: false })}
        >
          purchase more Live Course Passes.
        </button>{' '}
        {`before you can ${firstNameOrGeneric}.`}
      </p>
    </div>
  )
}
