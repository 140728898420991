import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { debounce } from 'throttle-debounce'

import Filter from 'domains/Member/Filter'
import List from 'domains/Member/List'

import { ShareableSnapshotModal } from 'components/ShareableSnapshotModal'

import { MAX_WIDTH_TAILWIND_XS, MIN_WIDTH_TAILWIND_TL } from 'constants/breakpoints'
import { FUNCTION_OPTIONS } from 'constants/onboarding'

import {
  FilteredUserSearchFieldsFragment,
  MembersUserCohortPartFragment,
  UserSearchFilters,
  useFilteredUserSearchQuery
} from 'gql'

import useInfiniteScroll from 'hooks/useInfiniteScroll'
import useManualScrollRestoration from 'hooks/useManualScrollRestoration'
import useMediaQuery from 'hooks/useMediaQuery'

import { isEmptyArrayParameter } from 'utils/arrayUtils'
import { trackMemberIndexFilter } from 'utils/tracking/analytics'

import { UserCohortFilter, getUserCohortFilters } from './helpers'

const storeInURL = (filters: UserSearchFilters) => {
  const searchParams = new URLSearchParams()
  for (const [key, value] of Object.entries(filters)) {
    if (Array.isArray(value)) {
      for (const item of value) {
        searchParams.append(key, item)
      }
    } else {
      searchParams.append(key, `${value}`)
    }
  }
  window.history.pushState(null, '', `?${searchParams.toString()}`)
}

export default function MemberDirectoryContainer() {
  const urlParams = new window.URLSearchParams(window.location.search)
  const defaultFilters: UserSearchFilters = {
    areasOfExpertise: urlParams.get('areasOfExpertise') || '',
    contains: urlParams.get('contains') || '',
    companyContains: urlParams.get('companyContains') || '',
    locationContains: urlParams.get('locationContains') || '',
    titleContains: urlParams.get('titleContains') || '',
    cohortId: urlParams.get('cohortId') || '',
    function: urlParams.get('function') || '',
    companyAudience: urlParams.getAll('companyAudience'),
    professionalRelationship: urlParams.getAll('professionalRelationship'),
    businessModel: urlParams.getAll('businessModel'),
    kind: urlParams.getAll('kind')
  }

  const { data, loading, fetchMore, variables, refetch } = useFilteredUserSearchQuery({
    variables: {
      filters: defaultFilters
    }
  })

  const filters = variables?.filters || defaultFilters
  const members = data?.filteredUsers?.members || []
  const totalEntries = data?.filteredUsers.pagination.totalEntries || 0
  const nextPage = data?.filteredUsers.pagination.nextPage || 0

  const setFilters = useCallback(
    (newFilters: UserSearchFilters) => {
      storeInURL(newFilters)
      refetch({ filters: newFilters })
      window.scroll({ top: 0, behavior: 'smooth' })
    },
    [refetch]
  )

  const onNextPage = useCallback(() => {
    if (nextPage) {
      fetchMore({ variables: { page: nextPage } })
    }
  }, [fetchMore, nextPage])

  useInfiniteScroll(onNextPage, 'page')

  return (
    <>
      <ShareableSnapshotModal page="Members" />
      <MemberDirectory
        members={members}
        loading={loading}
        setFilters={setFilters}
        filters={filters}
        totalEntries={totalEntries}
        userCohortsData={data?.userCohorts}
      />
    </>
  )
}

interface MemberDirectoryProps {
  members: FilteredUserSearchFieldsFragment[]
  loading: boolean
  totalEntries: number
  filters: UserSearchFilters
  setFilters: (filters: UserSearchFilters) => void
  userCohortsData?: MembersUserCohortPartFragment[]
}

export function MemberDirectory({
  members,
  loading,
  totalEntries,
  filters,
  setFilters,
  userCohortsData
}: MemberDirectoryProps) {
  const [internalFilters, setInternalFilters] = useState(filters)
  const isMounted = useRef(false)
  const { userCohorts, userCohortSeasons } = getUserCohortFilters(userCohortsData)

  const debouncedSearch = useMemo(
    () =>
      debounce(300, (internalFilters: UserSearchFilters) => {
        setFilters(internalFilters)

        if (!isMounted.current) {
          isMounted.current = true
          return
        }

        trackMemberIndexFilter({
          filter: {
            name: internalFilters.contains,
            title: internalFilters.titleContains,
            company: internalFilters.companyContains,
            location: internalFilters.locationContains,
            business_model: internalFilters.businessModel,
            areas_of_expertise: internalFilters.areasOfExpertise,
            reforge_collective: internalFilters.kind?.[0] === 'collective' ? true : null,
            cohort: {
              id: internalFilters.cohortId,
              name:
                userCohorts.find(
                  (option: UserCohortFilter) => option.id === internalFilters.cohortId
                )?.name || ''
            },
            function: FUNCTION_OPTIONS.find(
              (option: UserCohortFilter) => option.id === internalFilters.function
            ),
            company_audience: internalFilters.companyAudience,
            community_interests: internalFilters.professionalRelationship
          }
        })
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFilters, userCohortsData]
  )

  useEffect(() => {
    debouncedSearch(internalFilters)
  }, [debouncedSearch, internalFilters])

  const previousScrollRestoration = useRef()
  useManualScrollRestoration(previousScrollRestoration)

  const [showModalFilter, setShowModalFilter] = useState(false)
  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
  const showSidebarFilter = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_TL})`)
  const numberOfFilters = Object.values(internalFilters).filter(
    (value) => value !== '' && !isEmptyArrayParameter(value)
  ).length

  const handleResetAll = () => {
    setInternalFilters({
      areasOfExpertise: '',
      contains: '',
      companyContains: '',
      locationContains: '',
      titleContains: '',
      cohortId: '',
      function: '',
      companyAudience: [],
      professionalRelationship: [],
      businessModel: [],
      kind: []
    })
  }

  return (
    <div id="members">
      <div className="flex py-3 tl:py-8 ">
        {!showModalFilter && (
          <List
            filters={internalFilters}
            handleResetAll={handleResetAll}
            isFetching={loading}
            isMobile={isMobile}
            members={members}
            numberOfFilters={numberOfFilters}
            numberOfRecords={totalEntries}
            showSidebarFilter={showSidebarFilter}
            setShowModalFilter={setShowModalFilter}
            userCohorts={userCohorts}
          />
        )}

        <Filter
          userCohorts={userCohorts}
          userCohortSeasons={userCohortSeasons}
          filters={internalFilters}
          handleResetAll={handleResetAll}
          numberOfFilters={numberOfFilters}
          setFilters={setInternalFilters}
          setShowModalFilter={setShowModalFilter}
          showModalFilter={showModalFilter}
        />
      </div>
    </div>
  )
}
