import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export const useResetToFalseAfter = (
  delayMs?: number
): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [value, setValue] = useState(false)

  const setValueToFalse = () => setValue(false)

  useEffect(() => {
    if (delayMs && value) {
      const timeoutId = setTimeout(setValueToFalse, delayMs)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [value, delayMs])

  return [value, setValue]
}
