export const autoExpand = (field: HTMLElement) => {
  field.style.height = 'inherit'
  const computed = window.getComputedStyle(field)
  const height =
    parseInt(computed.getPropertyValue('border-top-width'), 10) +
    field.scrollHeight +
    parseInt(computed.getPropertyValue('border-bottom-width'), 10)
  if (height === 0) return

  field.setAttribute('style', `height: ${height}px !important`)
}
