import { useState } from 'react'

import usePost from 'domains/Cms/Post/usePost'

import { CohortPostPartsFragment, PostShowFieldsFragment, ReactionKind } from 'gql'

import {
  TLegacyOrGraphQLReaction,
  hasReactionOfKind,
  isReactionPresent
} from 'utils/reactionUtils'

interface useReactionsProps {
  currentUserId: string
  reactions: TLegacyOrGraphQLReaction[]
  reactableId: string
  reactableType: string
  reactablePost: PostShowFieldsFragment | CohortPostPartsFragment
  isFromCohort?: boolean
}

export const useReactions = ({
  reactions,
  currentUserId,
  reactableId,
  reactableType,
  reactablePost,
  isFromCohort
}: useReactionsProps) => {
  const [savedReactions, setSavedReactions] = useState(reactions || [])
  const [posting, setPosting] = useState(false)
  const { createReaction, destroyReaction } = usePost()

  const addReaction = async (kind: ReactionKind) => {
    if (hasReactionOfKind({ currentUserId, reactions, kind })) {
      return
    }

    const payload = {
      kind: kind,
      reactableId: reactableId,
      reactableType: reactableType,
      isFromCohort: true,
      reactablePost: reactablePost
    }

    setPosting(true)
    const response = await createReaction(payload)
    if (response) {
      setPosting(false)
      setSavedReactions([...reactions, response])
    }
  }

  const removeReaction = async (id: string | number) => {
    if (!isReactionPresent({ reactions, id })) {
      return
    }

    setPosting(true)
    const response = await destroyReaction(
      id.toString(),
      reactablePost,
      undefined,
      reactableId,
      reactableType,
      isFromCohort
    )
    const filteredData = reactions.filter((reaction) => reaction.id !== response)
    setSavedReactions(filteredData)
    setPosting(false)
  }

  return { posting, addReaction, removeReaction, savedReactions }
}
