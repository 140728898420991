import { ReactComponent as DownloadIcon } from 'download.svg'
import { SetStateAction, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { ReactComponent as ChevronDown } from 'thin-chevron-down.svg'
import { ReactComponent as ChevronUp } from 'thin-chevron-up.svg'

import CourseSnapshotShareModal from 'pages/CoursesPage/CourseSnapshotShareModal'
import { ARTIFACTS_HOST } from 'pages/UnitLandingPage/UnitArtifacts'

import { UnitComplexPortableText } from 'domains/Sanity/PortableText/UnitComplexPortableText/UnitComplexPortableText'

import Button from 'components/Button'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import RfParagraphSmallBold from 'components/typography/RfParagraph/RfParagraphSmallBold'

import {
  CourseDashboardCoursePartsFragment,
  CourseDashboardCourseSessionPartsFragment,
  CourseDashboardUserCoursePartsFragment,
  useCourseCompletionCertificateLazyQuery,
  useUpdateUserCourseMutation
} from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import { downloadCourseCertificateOfCompletion } from 'utils/courseUtils'
import { isAfterDate, isBeforeDate } from 'utils/date'
import { scrollToElementTop } from 'utils/scrollToElementTop'
import { trackCtaClicked } from 'utils/tracking/generated/events'

import { ReactComponent as ShareIcon } from 'images/share.svg'

import WeeklyBlockChecklistCard from '../CohortDashboard/CohortDashboardProgram/CohortDashboardWeeklyBlock/WeeklyBlockChecklist/WeeklyBlockChecklistCard'

export interface CourseDashboardWrapUpProps {
  course: CourseDashboardCoursePartsFragment
  courseSession: CourseDashboardCourseSessionPartsFragment
  userCourse?: CourseDashboardUserCoursePartsFragment | null
  isExpanded?: boolean
}

const WRAP_UP_BLOCK = 'wrap-up-block'

const CourseDashboardWrapUp = ({
  course,
  courseSession,
  userCourse,
  isExpanded = false
}: CourseDashboardWrapUpProps) => {
  const [expanded, setExpanded] = useState(isExpanded)
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)
  const [hasClickedOnSurveyLink, setHasClickedOnSurveyLink] = useState(
    userCourse?.hasClickedOnSurveyLink || false
  )

  const currentUser = useAssertCurrentUser()
  const history = useHistory()

  const [updateUserCourse] = useUpdateUserCourseMutation()

  const [fetchCourseCompletionCertificate] = useCourseCompletionCertificateLazyQuery()

  const setExpandedSync = (prev: SetStateAction<boolean>) => {
    if (prev) {
      scrollToElementTop(WRAP_UP_BLOCK)
    }

    return !prev
  }

  const onExpandClick = (e: any) => {
    e.stopPropagation()
    setExpanded(setExpandedSync)
  }

  useEffect(() => {
    // is after course session end date and less than 2 weeks after
    const twoWeeksAfterEndDate = new Date(courseSession?.endsAt)
    twoWeeksAfterEndDate.setDate(twoWeeksAfterEndDate.getDate() + 14)

    if (isAfterDate(courseSession?.endsAt) && isBeforeDate(twoWeeksAfterEndDate)) {
      setExpanded(true)
    }
  }, [courseSession?.endsAt, setExpanded])

  const onArtifactClick = () => {
    trackCtaClicked({
      cta_type: 'hyperlink text',
      cta_location: 'course_page',
      text: 'Explore',
      related_identifiers: {
        course_id: course.id,
        course_name: course.title,
        course_session_id: courseSession?.id
      }
    })

    window.open(`${ARTIFACTS_HOST}/artifacts`, '_blank')
  }

  const onJoinNowClick = () => {
    trackCtaClicked({
      cta_type: 'hyperlink text',
      cta_location: 'course_page',
      text: 'Join',
      related_identifiers: {
        course_id: course.id,
        course_name: course.title,
        course_session_id: courseSession?.id
      }
    })

    history.push('/subscribe/choose-plan')
  }

  const onDownloadClick = async () => {
    trackCtaClicked({
      cta_type: 'hyperlink text',
      cta_location: 'course_page',
      text: 'Download your certificate',
      related_identifiers: {
        course_id: course.id,
        course_name: course.title,
        course_session_id: courseSession?.id
      }
    })

    const response = await fetchCourseCompletionCertificate({
      variables: {
        courseSessionId: courseSession.id // input: { courseSessionId: courseSession.id }
      }
    })
    if (response?.data?.userCourse) {
      downloadCourseCertificateOfCompletion(response.data.userCourse)
    }
  }

  const onSurveyClick = () => {
    if (!courseSession.surveyLink) return

    if (userCourse?.id && !hasClickedOnSurveyLink) {
      updateUserCourse({
        variables: {
          input: {
            id: userCourse.id,
            hasClickedOnSurveyLink: true
          }
        }
      })

      setHasClickedOnSurveyLink(true)
    }

    trackCtaClicked({
      cta_type: 'hyperlink text',
      cta_location: 'course_page',
      text: 'Course Survey',
      related_identifiers: {
        course_id: course.id,
        course_name: course.title,
        course_session_id: courseSession?.id
      }
    })

    window.open(courseSession.surveyLink, '_blank')
  }

  const completionSnapshot = userCourse?.courseCompletionSnapshot

  return (
    <div
      id={WRAP_UP_BLOCK}
      className={twMerge(
        'flex w-full scroll-mt-32 flex-col rounded-xl px-8 transition-all duration-500 ease-in-out bg-rb-gray-50',
        expanded ? 'py-8' : 'py-4'
      )}
    >
      <div
        className={twMerge(
          'flex cursor-pointer items-center justify-between overflow-hidden'
        )}
        onKeyUp={onExpandClick}
        onClick={onExpandClick}
        role="button"
        tabIndex={0}
      >
        <RfParagraphMediumBold className="w-full">Course Wrap-Up</RfParagraphMediumBold>

        {!expanded && <ChevronDown className="h-[22px] w-[22px] text-rb-gray-200" />}
      </div>

      <div
        className={twMerge(
          'overflow-hidden transition-all duration-500 ease-in-out',
          expanded ? 'max-h-fit' : 'max-h-0'
        )}
      >
        <RfParagraphMedium className="pt-4">
          Congratulations on completing {course.title}! Let’s celebrate what you achieved:
        </RfParagraphMedium>
        {completionSnapshot?.imageUrl && (
          <div className="relative">
            <Button
              color="teal"
              shape="rounded-full"
              className="absolute right-5 top-4 font-semibold text-rb-white"
              size="x-small"
              iconBefore={
                <ShareIcon
                  width={16}
                  height={16}
                  className="fill-current text-rb-white"
                />
              }
              onClick={() => setIsShareModalOpen(true)}
            >
              Share
            </Button>
            <img
              src={completionSnapshot.imageUrl}
              className="mt-4 h-auto w-full rounded"
              alt="Course enrollment snapshot"
            />
          </div>
        )}

        {courseSession.wrapUp && (
          <div className="pt-4">
            <UnitComplexPortableText content={JSON.parse(courseSession.wrapUp)} />
          </div>
        )}

        {courseSession.surveyLink && (
          <div
            onClick={onSurveyClick}
            onKeyDown={onSurveyClick}
            tabIndex={0}
            role="button"
          >
            <WeeklyBlockChecklistCard
              className="mt-6"
              titleComponent={
                <RfParagraphSmallBold>Complete course survey</RfParagraphSmallBold>
              }
              subtitleComponent={<RfParagraphMini>5 min • 6 questions</RfParagraphMini>}
              clickable={true}
              checked={hasClickedOnSurveyLink}
            />
          </div>
        )}

        <Button variant="text-only" className="my-6 px-4" onClick={onDownloadClick}>
          <DownloadIcon className="mr-2" width={16} height={16} />
          Download your certificate
        </Button>

        {currentUser.hasMemberAccess ? (
          <WrapUpBlockCTA
            onClick={onArtifactClick}
            header="Accelerate your work with artifacts"
            description="Leverage Reforge artifacts to move faster and avoid reinventing the wheel."
            buttonText="Explore"
          />
        ) : (
          <WrapUpBlockCTA
            onClick={onJoinNowClick}
            header="Level up with a Reforge membership"
            description="Sign up for on-demand access to actionable frameworks and insights from the best operators in tech."
            buttonText="Join"
          />
        )}

        <RfParagraphMini className="pt-7 text-rb-gray-300">
          You will maintain access to this course’s content and Slack channel for six
          months after the end of the course.
        </RfParagraphMini>

        <div className="pt-7">
          <button
            onClick={onExpandClick}
            className="flex w-full items-center justify-center"
          >
            <ChevronUp className="h-[22px] w-[22px] text-rb-gray-200" />
          </button>
        </div>
      </div>

      <CourseSnapshotShareModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        snapshot={completionSnapshot}
        courseTitle={course?.title || ''}
        courseId={course?.id || ''}
        courseSessionId={courseSession?.id || ''}
        page="course_dashboard"
      />
    </div>
  )
}

interface WrapUpBlockCTAProps {
  header: string
  description: string
  buttonText: string
  onClick: () => void
}

const WrapUpBlockCTA = ({
  header,
  description,
  buttonText,
  onClick
}: WrapUpBlockCTAProps) => {
  return (
    <div className="flex flex-col gap-6 rounded-2xl bg-white p-6 sm:flex-row sm:items-center sm:justify-between sm:p-8">
      <div className="flex flex-col justify-between gap-1">
        <RfParagraphMediumBold>{header}</RfParagraphMediumBold>
        <RfParagraphSmall>{description}</RfParagraphSmall>
      </div>
      <Button onClick={onClick} size="small" className="w-full sm:w-fit">
        {buttonText}
      </Button>
    </div>
  )
}

export default CourseDashboardWrapUp
