import { useSearchParams } from 'react-router-dom-v5-compat'

import { ErrorMessage, Loading } from 'components'
import Button from 'components/Button'
import Status, { StatusTypes } from 'components/Status'

import { useDeclineSubscriptionJoinRequestMutation } from 'gql'

const Confirmation = ({ declineSubscription }: { declineSubscription: () => void }) => {
  return (
    <div className="flex h-screen flex-col items-center justify-center space-y-6">
      <h2 className="mt-10 text-center text-xl font-semibold sm:text-2xl">
        {"Are you sure you'd like to decline this request?"}
      </h2>
      <Button
        dataTest="confirm-deny-join-request"
        size="small"
        onClick={declineSubscription}
      >
        YES, DENY THIS REQUEST
      </Button>
    </div>
  )
}

export const DeclineJoinTeamRequestStatus = () => {
  const [searchParams] = useSearchParams()
  const joinRequestId = searchParams.get('join_request_id')

  const [
    declineSubscriptionJoinRequest,
    { data, loading, error: gqlError, called: mutationCalled }
  ] = useDeclineSubscriptionJoinRequestMutation({
    variables: { input: { id: joinRequestId } }
  })

  if (!mutationCalled) {
    return (
      <Confirmation
        declineSubscription={() => {
          declineSubscriptionJoinRequest({
            variables: { input: { id: joinRequestId } }
          })
        }}
      />
    )
  }

  if (loading) return <Loading />

  if (gqlError?.message) {
    return <ErrorMessage error={gqlError.message} />
  }

  if (!data?.declineSubscriptionJoinRequest) {
    return <ErrorMessage error="Something went wrong while trying to deny join request" />
  }

  const declineSuccesful = data.declineSubscriptionJoinRequest.success
  if (declineSuccesful) {
    return <Status statusType={StatusTypes.TEAM_REQUEST_DECLINED} />
  }

  const joinRequest = data.declineSubscriptionJoinRequest.subscriptionJoinRequest
  switch (joinRequest.status) {
    case 'accepted':
    case 'accepted_unassigned':
      return <Status statusType={StatusTypes.SUBSCRIPTION_ALREADY_ADDED} />
    case 'rejected':
      return <Status statusType={StatusTypes.TEAM_REQUEST_ALREADY_DECLINED} />
    case 'canceled':
      return <Status statusType={StatusTypes.TEAM_REQUEST_ALREADY_APPROVED} />
    default:
      if (data?.declineSubscriptionJoinRequest?.error) {
        return <ErrorMessage error={data.declineSubscriptionJoinRequest.error} />
      } else {
        return (
          <ErrorMessage error="Join request in unrecognized state. Engineering team has been notified" />
        )
      }
  }
}
