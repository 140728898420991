import Button from 'components/Button'
import { ModalContent, ModalHeader } from 'components/Modal'
import CheckmarkBorderlessIcon from 'components/icons/CheckmarkBorderlessIcon'
import RfParagraphLarge from 'components/typography/RfParagraph/RfParagraphLarge'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

export interface CohortPassOptInSuccessModalContentProps {
  handleClose: () => void
  upcomingChargeDate: string
}

export const CohortPassOptInSuccessModalContent = ({
  handleClose,
  upcomingChargeDate
}: CohortPassOptInSuccessModalContentProps) => {
  return (
    <div className="px-[40px]">
      <ModalContent dataTest="pass-opt-in-success-modal">
        <div className="flex flex-col items-center">
          <ModalHeader>
            <CheckmarkBorderlessIcon />
          </ModalHeader>
        </div>
        <RfParagraphLarge className="mb-3 mt-2 flex flex-col items-center font-semibold">
          You’ve added 2 Live Course Passes!
        </RfParagraphLarge>
        <RfParagraphSmall className="mb-20 text-left">
          The Live Course Passes will be purchased on {upcomingChargeDate} and will be
          available for use at that time.
        </RfParagraphSmall>
        <div className="my-10 flex justify-center text-center">
          <Button
            className="ml-[10px] inline w-[250px] hover:bg-rb-black hover:text-rb-white"
            onClick={handleClose}
          >
            Done
          </Button>
        </div>
      </ModalContent>
    </div>
  )
}

export default CohortPassOptInSuccessModalContent
