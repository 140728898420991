import pluralize from 'pluralize'
import React, { useState } from 'react'

import AvatarStack from 'components/AvatarStack'
import Button from 'components/Button'

import {
  AvailableTeamSubscriptionFragment,
  useCreateSubscriptionJoinRequestMutation
} from 'gql'

import { FULL_DATE_FORMAT, formatInTimezone, getCurrentTimezone } from 'utils/date'
import { cn } from 'utils/tailwind'
import { trackCtaClicked, trackModalDisplayed } from 'utils/tracking/analytics'

import { JoinRequestSentModal } from '../TeamModal/JoinRequestSentModal'
import AddEmailModal from './AddEmailModal'
import FallbackTeamLogo from './FallbackTeamLogo'
import { getSubscriptionMembers } from './utils'

export function JoinedTeam({
  team,
  joinedAt
}: {
  team: AvailableTeamSubscriptionFragment
  joinedAt?: string
}) {
  return (
    <TeamRow>
      <TeamLogo logoUrl={team.logoUrl} />
      <TeamInfoColumn>
        <TeamInfo team={team} showDetails />
        <AcceptedRequestStatus joinedAt={joinedAt} />
      </TeamInfoColumn>
    </TeamRow>
  )
}

export function PendingTeam({
  team,
  onCancelRequest
}: {
  team: AvailableTeamSubscriptionFragment
  onCancelRequest: () => void
}) {
  const [isLoading, setIsLoading] = useState(false)

  const onCtaClick = () => {
    setIsLoading(true)
    onCancelRequest()
  }

  return (
    <TeamRow>
      <TeamLogo logoUrl={team.logoUrl} />
      <TeamInfoColumn>
        <TeamInfo team={team} showDetails />
        <div className="flex flex-col gap-2 sm:items-center">
          <RequestPendingStatus />
          <Button
            size="x-small"
            variant="outline"
            onClick={onCtaClick}
            className="w-[140px]"
            isLoadingSpinner={isLoading}
          >
            Cancel request
          </Button>
        </div>
      </TeamInfoColumn>
    </TeamRow>
  )
}

export function JoinableTeam({
  team,
  isSignUpFlow,
  onRequestedToJoin,
  ctaDisabled
}: {
  team: AvailableTeamSubscriptionFragment
  isSignUpFlow?: boolean
  onRequestedToJoin?: () => void
  ctaDisabled?: boolean
}) {
  return (
    <TeamRow>
      <TeamLogo logoUrl={team.logoUrl} />
      <TeamInfoColumn>
        <TeamInfo team={team} showDetails={false} />
        <RequestToJoinButton
          teamId={team.id}
          isSignUpFlow={isSignUpFlow}
          onRequestedToJoin={onRequestedToJoin}
          disabled={ctaDisabled}
        />
      </TeamInfoColumn>
    </TeamRow>
  )
}

export function RejectedTeam({ team }: { team: AvailableTeamSubscriptionFragment }) {
  return (
    <TeamRow>
      <div className="flex flex-col w-full gap-6">
        <div className="flex flex-row w-full gap-6">
          <div className="opacity-50">
            <TeamLogo />
          </div>
          <TeamInfoColumn>
            <div className="opacity-50">
              <TeamInfo team={team} showDetails />
            </div>
            <DeniedRequestStatus />
          </TeamInfoColumn>
        </div>

        <InfoBox>
          Contact the team manager at your company to get added to this team plan.
        </InfoBox>
      </div>
    </TeamRow>
  )
}

export function InfoBox({ children }: { children: React.ReactNode | string }) {
  return (
    <div className="rounded bg-rb-disabled-light px-4 py-2 text-sm leading-[1.5]">
      {children}
    </div>
  )
}

function StatusDot({ colorClass }: { colorClass: string }) {
  return <div className={cn('h-2 w-2 rounded-full mr-2 inline-block', colorClass)} />
}

export function TeamRow({ children }: { children: React.ReactNode | string }) {
  return (
    <div className="p-6 flex flex-row w-full gap-6 rounded-lg border border-e-rb-gray-100">
      {children}
    </div>
  )
}

function TeamMembershipStatus({ children }: { children: React.ReactNode | string }) {
  return (
    <div className="items-start justify-self-end text-sm leading-[1.5] mt-2 sm:mt-0">
      {children}
    </div>
  )
}

export function TeamInfoColumn({ children }: { children: React.ReactNode | string }) {
  return (
    <div className="flex w-full flex-col sm:flex-row sm:items-center sm:justify-between">
      {children}
    </div>
  )
}

export function TeamLogo({ logoUrl }: { logoUrl?: string | null }) {
  return (
    <div className="flex shrink-0 h-[72px] w-[72px] flex-none items-center justify-center rounded-lg overflow-hidden">
      {logoUrl ? <img src={logoUrl} alt="" className="w-full" /> : <FallbackTeamLogo />}
    </div>
  )
}

export function TeamInfo({
  team,
  showDetails
}: {
  team: Pick<AvailableTeamSubscriptionFragment, 'user' | 'teamName' | 'seats'>
  showDetails: boolean
}) {
  const { teamName, user, seats } = team
  const subscriptionMembers = seats ? getSubscriptionMembers(seats) : []

  return (
    <div className="flex grow flex-col items-start justify-center">
      <div className="text-base font-semibold leading-[1.6]">{teamName}</div>
      {user?.fullName && (
        <div className="text-sm font-normal leading-[1.5]">
          Team Manager: {user.fullName}
        </div>
      )}

      {subscriptionMembers &&
        (showDetails ? (
          <div className="flex text-xs mt-2 items-center">
            <AvatarStack
              members={subscriptionMembers.map((member) => ({
                ...member?.user,
                hasBadge: false,
                avatarUrl: member.user.avatarUrl,
                fullName: member.user.fullName
              }))}
              avatarSize={20}
              maximumAvatars={5}
              totalCount={0}
            />
            <span className="pl-2 text-sm leading-[1.5]">
              {pluralize('member', subscriptionMembers.length, true)}
            </span>
          </div>
        ) : (
          <div className="text-sm font-normal leading-[1.5]">
            {`${subscriptionMembers.length} ${pluralize('team member', subscriptionMembers.length)}`}
          </div>
        ))}
    </div>
  )
}

function RequestPendingStatus() {
  return (
    <TeamMembershipStatus>
      <StatusDot colorClass="bg-rb-yellow-200" />
      Request pending
    </TeamMembershipStatus>
  )
}

function RequestToJoinButton({
  teamId,
  isSignUpFlow,
  onRequestedToJoin,
  disabled
}: {
  teamId: string
  isSignUpFlow?: boolean
  onRequestedToJoin?: () => void
  disabled?: boolean
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const closeModal = () => {
    setModalIsOpen(false)
    onRequestedToJoin?.()
  }
  const pathname = window.location.pathname
  const ctaText = 'Request to join'
  const [createSubscriptionJoinRequest] = useCreateSubscriptionJoinRequestMutation()

  const onJoinTeamRequested = () => {
    createSubscriptionJoinRequest({
      variables: {
        input: {
          subscriptionId: teamId
        }
      }
    })

    setModalIsOpen(true)
    trackCtaClicked({
      cta_location: pathname.includes('/account') ? 'account_page_team_card' : pathname,
      cta_type: 'button',
      related_identifiers: {
        team_id: teamId
      },
      text: ctaText.toLowerCase()
    })
  }

  return (
    <TeamMembershipStatus>
      <Button
        shape="rounded"
        variant="outline"
        size="x-small"
        type="button"
        onClick={onJoinTeamRequested}
        className="sm:self-end h-10 mt-2 sm:mt-0 w-[140px]"
        disabled={disabled}
      >
        {ctaText}
      </Button>
      <JoinRequestSentModal
        isModalOpen={modalIsOpen}
        isSignUpFlow={isSignUpFlow}
        onClose={closeModal}
      />
    </TeamMembershipStatus>
  )
}

function DeniedRequestStatus() {
  return (
    <TeamMembershipStatus>
      <StatusDot colorClass="bg-rb-destructive-100" />
      Denied
    </TeamMembershipStatus>
  )
}

function AcceptedRequestStatus({ joinedAt }: { joinedAt?: string }) {
  return (
    <TeamMembershipStatus>
      <div>
        <StatusDot colorClass="bg-rb-success-100" />
        Joined
      </div>
      {joinedAt && (
        <div className="text-rb-gray-300">
          {formatInTimezone(joinedAt, getCurrentTimezone(), FULL_DATE_FORMAT)}
        </div>
      )}
    </TeamMembershipStatus>
  )
}

export function AddEmailLink({ children }: { children: string }) {
  const [isOpenAddEmailModal, setIsOpenAddEmailModal] = useState(false)

  const handleOpenModal = () => {
    setIsOpenAddEmailModal(true)
    trackCtaClicked({
      cta_location: 'account_page_team_card',
      cta_type: 'hyperlink text',
      text: 'Try using a different email'
    })

    trackModalDisplayed({
      category: 'app',
      modal_group: 'add email address',
      modal_name: 'add email address'
    })
  }

  return (
    <>
      <button
        data-test={'open-add-email-modal-btn'}
        className="font-semibold text-rb-teal-600"
        onClick={handleOpenModal}
      >
        {children}
      </button>
      <AddEmailModal isOpen={isOpenAddEmailModal} setIsOpen={setIsOpenAddEmailModal} />
    </>
  )
}
