import { Menu } from '@headlessui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

interface DropdownMenuItemProps {
  children: any // @todo: define type
  href?: string
  onClick?: () => void
  textOnly?: boolean
}

const DropdownButtonItem = ({
  active,
  children,
  onClick,
  textOnly = false
}: {
  active: boolean
  children: React.ReactNode
  onClick: () => void
  textOnly?: boolean
}) => {
  return (
    <button
      className={twMerge(
        'text-rb-gray-3 group flex w-full items-center rounded-md px-[6px] py-[3px] text-base',
        active ? 'bg-rb-gray-50' : 'bg-rb-white',
        textOnly ? 'grow' : 'h-8 hover:cursor-pointer hover:bg-rb-gray-50'
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

const DropdownMenuItem = ({
  children = '',
  href = '#',
  onClick = () => {},
  textOnly = false
}: DropdownMenuItemProps) => {
  const handleClick = (close = () => {}) => {
    close()
    onClick()
  }
  return (
    <Menu.Item>
      {({ active, close }) => (
        <div>
          {textOnly && (
            <DropdownButtonItem
              active={active}
              onClick={() => {
                handleClick(close)
              }}
              textOnly
            >
              {children}
            </DropdownButtonItem>
          )}
          {!textOnly && href !== '#' && (
            <Link className="no-underline" to={href}>
              <DropdownButtonItem active={active} onClick={close}>
                {children}
              </DropdownButtonItem>
            </Link>
          )}
          {!textOnly && href === '#' && (
            <DropdownButtonItem
              active={active}
              onClick={() => {
                handleClick(close)
              }}
            >
              {children}
            </DropdownButtonItem>
          )}
        </div>
      )}
    </Menu.Item>
  )
}

export default DropdownMenuItem
