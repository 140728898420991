import { BreadcrumbHistoryEntry } from 'components/Breadcrumbs'
import useEditableHistory from 'components/Breadcrumbs/useBreadcrumbs/useEditableHistory'

interface UseBreadCrumbsProps {
  history: BreadcrumbHistoryEntry[]
}

const useBreadcrumbs = ({ history }: UseBreadCrumbsProps) => {
  const { editableHistory, lastEntryIndex, removeHistoryEntries, removeHistoryEntry } =
    useEditableHistory({
      history
    })

  const getOnBreadcrumbClick = (index: number) => () => {
    removeHistoryEntries(index)
  }

  // Grab all the entries except the last one.
  const historyEntriesWithChevrons = editableHistory.slice(0, lastEntryIndex)

  const lastHistoryEntry = editableHistory[lastEntryIndex]

  const hasMultipleCrumbs = editableHistory.length > 1

  return {
    editableHistory,
    getOnBreadcrumbClick,
    hasMultipleCrumbs,
    historyEntriesWithChevrons,
    lastHistoryEntry,
    removeHistoryEntry
  }
}

export default useBreadcrumbs
