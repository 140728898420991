import { useLocation } from 'react-router-dom'

import PastEventsList from 'domains/Event/PastEventsList'

import { useCurrentUser } from 'hooks/useCurrentUser'

export function EventPastRecordings() {
  const { currentUser } = useCurrentUser()
  const location = useLocation()
  const isRecordingPage = location.pathname.includes('events/recordings')

  return (
    <PastEventsList
      maxCards={isRecordingPage ? undefined : 3}
      user={currentUser!}
      filters={{ eventType: [] as any, input: '' }}
    />
  )
}
