import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom'

import BetaBadge from 'components/BetaBadge'

import { ProgramIndexUserCourseSessionFieldsFragment } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import { buildCourseSessionLink } from 'utils/courseUtils'
import { MONTH_FULL_YEAR_FORMAT, formatInTimezone } from 'utils/date'
import { onEnterKeyPress } from 'utils/keyboard'
import { trackCtaClicked } from 'utils/tracking/analytics'

interface FullWidthCourseCardProps {
  courseSession?: ProgramIndexUserCourseSessionFieldsFragment | null
  showBetaBadge?: boolean
  showDuration?: boolean
  showStartDate?: boolean
  pageLocation: string
}

const FullWidthCourseCard = ({
  courseSession,
  showBetaBadge,
  showDuration,
  showStartDate,
  pageLocation
}: FullWidthCourseCardProps) => {
  const history = useHistory()
  const currentUser = useAssertCurrentUser()

  if (!courseSession) return null

  const startDate = courseSession.startsAt ? Date.parse(courseSession.startsAt) : null
  const formattedStartDate = startDate
    ? moment(startDate).format(MONTH_FULL_YEAR_FORMAT)
    : null

  const onCourseClick = () => {
    const destination = buildCourseSessionLink({
      courseSlug: courseSession.courseSlug,
      sessionId: courseSession.id
    })

    trackCtaClicked({
      destination: destination,
      cta_location: `${pageLocation}_full_width_course_card`,
      text: courseSession.courseTitle,
      cta_type: 'card'
    })

    history.push(destination)
  }

  return (
    <div
      className="full-width-card rf-rb-card-interactive flex flex-col p-5"
      onClick={onCourseClick}
      data-test="course-card"
      role="link"
      tabIndex={0}
      onKeyUp={onEnterKeyPress(onCourseClick)}
    >
      <div className="flex items-center">
        {showBetaBadge && <BetaBadge className="w-7 text-[10px]" />}
        <div className="ml-4">
          {showStartDate && (
            <div className="text-xs font-medium uppercase tracking-widest text-rb-gray-300">
              {formattedStartDate}
            </div>
          )}
          <div className="text-lg font-medium">{courseSession.courseTitle}</div>
          <div className="mt-1 text-xs font-medium tracking-widest">
            <span className="uppercase text-rb-gray-500">
              {showDuration && courseSession.overallDuration && (
                <>{courseSession.overallDuration} &#8226; </>
              )}
            </span>
            {courseSession.startsAt &&
              formatInTimezone(
                courseSession.startsAt,
                currentUser.timezone,
                'MMM d'
              )}{' '}
            &ndash;{' '}
            {courseSession.endsAt &&
              formatInTimezone(courseSession.endsAt, currentUser.timezone, 'MMM d, yyyy')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullWidthCourseCard
