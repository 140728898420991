import React from 'react'
import { twMerge } from 'tailwind-merge'

import useUpdateCohortPost from 'domains/CohortConversation/hooks/useUpdateCohortPost'

import BadgeableAvatar from 'components/BadgeableAvatar'
import { CohortPostCardDropdown } from 'components/CohortPostCard'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import RfParagraphMiniSemiBold from 'components/typography/RfParagraph/RfParagraphMiniSemiBold'
import RfParagraphSmallBold from 'components/typography/RfParagraph/RfParagraphSmallBold'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import { CohortPostPartsFragment, CohortPostUserPartsFragment } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useTogglePostFollow } from 'hooks/useTogglePostFollow'

import { isStaffOrCollective } from 'utils/cohortConversationUtils'
import { onEnterKeyPress } from 'utils/keyboard'

import { ReactComponent as PinIcon } from 'images/icon--cohort-pin.svg'

interface CohortPostCardHeaderProps {
  post: CohortPostPartsFragment
  user: CohortPostUserPartsFragment
  includeInteractions: boolean
  showProfile?: (user: CohortPostUserPartsFragment) => void
  openEditModal?: () => void
  onDeletePostClick?: () => void
  onReportPostClick: () => void
  refetch?(): Promise<unknown>
}

interface UserNameProps {
  includeInteractions: boolean
  user: CohortPostUserPartsFragment
  showProfile?: (user: CohortPostUserPartsFragment) => void
}

const UserName = ({
  includeInteractions,
  user,
  showProfile = () => {}
}: UserNameProps) => {
  const userName = (
    <RfParagraphSmallBold color={ColorOptions.black} className="pr-2.5">
      {user.profile.fullName}
    </RfParagraphSmallBold>
  )

  const onUserNameClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation()
    showProfile(user)
  }

  if (includeInteractions) {
    return (
      <span
        className="cursor-pointer hover:underline"
        role="button"
        tabIndex={0}
        onKeyDown={onEnterKeyPress(() => showProfile(user))}
        onClick={onUserNameClick}
      >
        {userName}
      </span>
    )
  }

  return userName
}

export const CohortPostCardHeader = ({
  post,
  user,
  includeInteractions = false,
  showProfile,
  openEditModal,
  onDeletePostClick,
  onReportPostClick,
  refetch
}: CohortPostCardHeaderProps) => {
  const { currentUser } = useCurrentUser()
  const { onTogglePinPost, isUpdatingPost: posting } = useUpdateCohortPost()

  const { loading, toggleFollow } = useTogglePostFollow({
    postId: post.id,
    following: post.following,
    refetch
  })

  const onProfileClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    showProfile?.(post.user)
  }

  return (
    <div className="flex items-start justify-between">
      <div className="flex flex-col pr-[20px] pt-2">
        {includeInteractions && post.isPinned && (
          <div className="flex pb-4">
            <span className="pr-2.5">
              <PinIcon width={15} height={15} fill="#757B74" />
            </span>
            <RfParagraphMiniSemiBold color={ColorOptions.gray}>
              Pinned post
            </RfParagraphMiniSemiBold>
          </div>
        )}
        <div className="flex items-start">
          <button
            onClick={onProfileClick}
            className={twMerge(includeInteractions && 'cursor-pointer hover:underline')}
          >
            <BadgeableAvatar
              width={40}
              height={40}
              avatarUrl={user.profile.avatarUrl}
              borderClassNames="rounded"
              className="static"
              fullName={user.profile.fullName}
            />
          </button>
          <div className="flex flex-col items-baseline pl-[16px] sm:flex-row">
            <UserName
              showProfile={showProfile}
              user={user}
              includeInteractions={includeInteractions}
            />
            <RfParagraphMini color={ColorOptions.gray}>
              {post.createdAtInWords} ago
            </RfParagraphMini>
          </div>
        </div>
      </div>
      <div className="flex flex-1 items-center justify-end">
        <div className="flex w-1/2 justify-end">
          {includeInteractions && (
            <CohortPostCardDropdown
              disabled={posting || loading}
              onDeletePost={onDeletePostClick}
              onEditPost={openEditModal}
              isUserFollowingPost={post?.following || false}
              onTogglePostNotification={toggleFollow}
              onReportPost={onReportPostClick}
              isPostPinned={post.isPinned || false}
              onTogglePinPost={() =>
                onTogglePinPost({
                  postId: post.id,
                  isPinned: !post.isPinned,
                  refetch
                })
              }
              canUserPinPost={isStaffOrCollective(currentUser) || false}
              postId={post.id}
              canUserEditPost={currentUser?.id === user.id}
              canUserDeletePost={
                isStaffOrCollective(currentUser) || currentUser?.id === user.id
              }
              label="post"
              reactions={post.reactions}
              currentUserId={currentUser?.id}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default CohortPostCardHeader
