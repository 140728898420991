import * as React from 'react'
import { twMerge } from 'tailwind-merge'

export const Section = ({
  children,
  className
}: {
  children: React.ReactNode
  className?: string
}) => {
  return <section className={className}>{children}</section>
}

export const SectionTitle = ({
  children,
  className
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <h3 className={twMerge('mb-0 p-0 text-sm font-medium lg:text-lg', className)}>
      {children}
    </h3>
  )
}

export const SectionContent = ({
  children,
  className
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <div className={twMerge('text-sm font-normal lg:text-base', className)}>
      {children}
    </div>
  )
}
