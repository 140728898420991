import { Link } from 'react-router-dom'

import Image from 'domains/Sanity/Image'
import { ComplexPortableText } from 'domains/Sanity/PortableText/ComplexPortableText/ComplexPortableText'

import { ArtifactWithWorkEntry } from 'gql'

import { trackContentClicked } from 'utils/tracking/analytics'

import { getCompanyLogo } from '../helpers'

export interface ArtifactSeoCardProps {
  artifact: ArtifactWithWorkEntry
}

export const ArtifactSeoCard = ({ artifact }: ArtifactSeoCardProps) => {
  const { companyLogoAlt, companyLogoUrl } = getCompanyLogo(artifact)
  const author = artifact.authors?.[0]

  const authorBlock = (
    <p className="mb-6 text-xs leading-[1.5] text-rb-gray-300 line-clamp-2 md:mb-8 md:text-sm md:line-clamp-none">
      {author?.name?.trim()}
      {artifact.workEntry &&
        `, Created 
      ${
        artifact.workEntry.specificCompany
          ? `as ${artifact.workEntry.position} @ ${artifact.workEntry.company?.name}`
          : artifact.workEntry.generalRole
      }`}
    </p>
  )

  const trackContentClick = () => {
    trackContentClicked({
      content_sanity_id: artifact.id,
      content_title: artifact.title,
      content_type: 'artifact',
      related_identifiers: {
        display_type: 'vertical'
      }
    })
  }

  return (
    <Link
      className="relative flex w-[292px] flex-col rounded-lg border px-3 pt-3 pb-7 text-rb-black hover:text-rb-black hover:no-underline hover:shadow-seo-page-card md:w-[400px] md:px-6 md:pb-10 md:pt-6"
      to={`/artifacts/${artifact.slug}`}
      onClick={trackContentClick}
    >
      <div className="relative flex h-[7.5rem] flex-col rounded border bg-rb-white md:h-40 md:rounded-lg">
        <Image
          fill
          alt={`Image of ${artifact.title}`}
          src={artifact.thumbnailUrl || ''}
          className="h-full w-full object-cover"
        />
      </div>
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row space-x-2 pt-6">
          <img
            src={companyLogoUrl}
            alt={companyLogoAlt}
            className="mt-1 h-12 w-12 rounded object-contain"
          />
          <div className="w-full overflow-hidden text-ellipsis text-base font-semibold leading-[1.5] line-clamp-2 md:text-xl md:font-medium md:leading-[1.4]">
            {artifact.title}
          </div>
        </div>
        <div className="flex flex-col">
          {authorBlock}
          <div className="w-full gap-y-6 overflow-hidden text-ellipsis text-base font-normal line-clamp-4 [&_p:last-child]:mb-0">
            <ComplexPortableText content={artifact.description} />
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ArtifactSeoCard
