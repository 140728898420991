import { PostSummaryFieldsFragment } from 'gql'

interface IPostSummary {
  record: PostSummaryFieldsFragment
}

export default function PostSummary({ record }: IPostSummary) {
  const handleClick = () => {
    window.location.href = `/posts/${record.toParam}`
  }

  return (
    <div
      onClick={handleClick}
      className="group mt-2.5 pr-4 shadow-default sm:mt-4 sm:flex sm:cursor-pointer sm:py-8 sm:pr-4 sm:pl-8"
    >
      <div className="flex flex-grow overflow-hidden">
        <div style={{ flexGrow: 1 }}>
          <div className="flex text-rb-gray-300 sm:hidden">{record.createdAtInWords}</div>
          <div>
            Posted{' '}
            <span className="font-medium group-hover:text-rb-blue-200 group-focus:text-rb-blue-200 group-active:text-rb-blue-200">
              {record.title}
            </span>
          </div>
        </div>
        <div className="hidden text-rb-gray-300 sm:block sm:min-w-[100px] sm:text-right">
          {record.createdAtInWords}
        </div>
      </div>
    </div>
  )
}

// hover:text-rb-blue-200 focus:text-rb-blue-200 active:text-rb-blue-200
