import { Link } from 'react-router-dom'

import { UserCohortFilter } from 'pages/MemberDirectoryPage/helpers'

import Role from 'domains/Member/Role'

import BadgeableAvatar from 'components/BadgeableAvatar'

import { ALUMNI_TOOLTIP } from 'constants/memberDirectory'
import { FUNCTION_OPTIONS } from 'constants/onboarding'

import { FilteredUserSearchFieldsFragment, UserSearchFilters } from 'gql'

import { trackMemberProfileClicked } from 'utils/tracking/generated/events/memberProfileClicked'

interface CardProps {
  member: FilteredUserSearchFieldsFragment
  isMobile: boolean
  isCurrentUser?: boolean
  filtersToTrack: UserSearchFilters
  userCohorts: UserCohortFilter[]
}

const Card = ({
  member,
  isCurrentUser,
  isMobile,
  filtersToTrack,
  userCohorts
}: CardProps) => {
  const className =
    'hover:no-underline cursor-default flex items-center max-w-max py-1 px-1.5 h-6 mr-2.5 mb-2 text-sm tl:text-base text-rb-gray-400 bg-rb-gray-50'

  const trackProfileClick = () => {
    trackMemberProfileClicked({
      name: member.fullName || null,
      company: member.companyName || null,
      location: member.location || null,
      title: member.role || null,
      filters: {
        function: FUNCTION_OPTIONS.find(
          (option: UserCohortFilter) => option.id === filtersToTrack.function
        ),
        areas_of_expertise: filtersToTrack.areasOfExpertise,
        business_model: filtersToTrack.businessModel,
        cohort_id: userCohorts.find(
          (option: UserCohortFilter) => option.id === filtersToTrack.cohortId
        ),
        community_interests: filtersToTrack.professionalRelationship,
        reforge_collective: filtersToTrack.kind?.[0] === 'collective' ? true : null
      }
    })
  }

  return (
    <Link
      to={`/directory/${member.id}`}
      key={`user-${member.id}-container`}
      className="hover:no-underline"
      onClick={trackProfileClick}
    >
      <div data-test="member" className="rf-rb-card-interactive mt-2.5 h-[104px] tl:mt-4">
        <div className="flex p-5">
          <BadgeableAvatar
            user={member}
            avatarUrl={member.avatarUrl}
            width="60"
            height="60"
            fullName={member.fullName}
          />
          <div className="mt-[-5px] ml-4 flex w-full flex-col justify-center">
            <div className="flex">
              <div
                className="mb-1 mr-2.5 flex items-center overflow-ellipsis whitespace-nowrap text-sm font-medium leading-[21px] text-rb-gray-400 sm:text-base sm:leading-6 "
                {...(member.status === 'private'
                  ? {
                      'uk-tooltip':
                        'title: private; cls: uk-active rf-tooltip-down-arrow; pos: top;'
                    }
                  : {})}
              >
                {member.fullName}
              </div>
              {isCurrentUser && (
                <div className="ml-2.5 flex items-center rounded bg-rb-gray-50 px-1 text-xs text-rb-gray-400">
                  YOU!
                </div>
              )}
              {member.isExpired && (
                <div
                  style={{ position: 'relative', float: 'right' }}
                  className={className}
                  uk-tooltip={`${ALUMNI_TOOLTIP}; cls: uk-active rf-tooltip-down-arrow; pos: top`}
                >
                  Alum
                </div>
              )}
            </div>
            <Role member={member} isMobile={isMobile} />
            <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-xs leading-4 text-rb-gray-300 sm:text-sm sm:leading-[21px]">
              {member.location}
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default Card
