import SeoPageSectionSubtitle from 'domains/Seo/Section/SeoPageSectionSubtitle'
import SeoPageSectionTitle from 'domains/Seo/Section/SeoPageSectionTitle'
import { SEOTrackingRelatedIdentifiers } from 'domains/Seo/helpers'

import Button from 'components/Button'

import { ArtifactTopic } from 'gql'

import { cn } from 'utils/tailwind'
import { trackCtaClicked } from 'utils/tracking/analytics'

import { ArtifactSeoTopicLink } from '../ArtifactSeoTopicLink'

interface ArtifactSeoTopicLinkSectionProps {
  topics: ArtifactTopic[]
  title: string
  subtitle?: string
  tracking: SEOTrackingRelatedIdentifiers
}

const ArtifactSeoTopicLinkSection = ({
  title,
  subtitle,
  topics,
  tracking
}: ArtifactSeoTopicLinkSectionProps) => {
  const ctaText = 'Explore all artifacts'
  const destination = '/artifacts'

  const onCtaClick = () => {
    trackCtaClicked({
      cta_type: 'button',
      destination,
      cta_location: 'SEO explore all artifacts',
      text: ctaText.toLowerCase(),
      related_identifiers: {
        sanity_type: tracking.sanityType,
        sanity_id: tracking.sanityId,
        sanity_name: tracking.sanityName
      }
    })
  }

  return (
    <section className="mb-[104px]">
      <SeoPageSectionTitle className={cn({ 'mb-6': !subtitle })}>
        {title}
      </SeoPageSectionTitle>
      {subtitle && <SeoPageSectionSubtitle>{subtitle}</SeoPageSectionSubtitle>}

      <div className="mb-6 grid grid-cols-1 gap-4 md:grid-cols-3">
        {topics.map((topic) => (
          <ArtifactSeoTopicLink topic={topic} key={topic.slug} tracking={tracking} />
        ))}
      </div>

      <Button
        color="teal"
        shape="rounded"
        size="small"
        href={destination}
        onClick={onCtaClick}
      >
        {ctaText}
      </Button>
    </section>
  )
}

export default ArtifactSeoTopicLinkSection
