import React, { useState } from 'react'

import ButtonMarketingCTA from 'domains/Sanity/marketingSite/ButtonMarketingCTA'

import Loading from 'components/Loading'
import MarketingInput from 'components/MarketingSite/MarketingInput'
import MarketingSelect from 'components/MarketingSite/MarketingSelect'

import {
  useAvailableCoursesQueryForWaitlistQuery,
  useJoinCourseWaitingListMutation,
  useJoinPublicCourseWaitingListMutation
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { trackMarketingFormSubmitted } from 'utils/tracking/generated/events'

interface CourseWaitListProps {
  heading: string
}

const CourseWaitList = ({ heading }: CourseWaitListProps) => {
  const { data: courses, loading } = useAvailableCoursesQueryForWaitlistQuery()
  const [joinCourseWaitlist] = useJoinCourseWaitingListMutation()
  const [joinPublicCourseWaitlist] = useJoinPublicCourseWaitingListMutation()

  const { isLoggedIn, currentUser } = useCurrentUser()

  const [submitComplete, setSubmitComplete] = useState(false)
  const [error, setError] = useState<string>('')

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const email = (document.querySelector('input[name="email"]') as HTMLInputElement)
      .value
    const firstName = (
      document.querySelector('input[name="firstName"]') as HTMLInputElement
    ).value
    const lastName = (
      document.querySelector('input[name="lastName"]') as HTMLInputElement
    ).value
    const course = (document.querySelector('select[name="course"]') as HTMLSelectElement)
      .value

    if (course === 'default') {
      setError('Please select a course')
      return
    }

    // blob data for data team <3
    const formFields: any = {
      email,
      firstName,
      lastName,
      course
    }

    // NEW - generic "Marketing Form Submitted"
    trackMarketingFormSubmitted({
      form_fields: formFields,
      email,
      form_id: 'courses-waitlist',
      form_type: 'waitlist',
      form_name: `${course} Waitlist`,
      location: window.location.pathname,
      url: window.location.href,
      source: 'marketing site',
      type: 'page'
    })

    if (isLoggedIn) {
      joinCourseWaitlist({
        variables: {
          input: {
            courseTitle: course
          }
        }
      })
    } else {
      joinPublicCourseWaitlist({
        variables: {
          input: {
            courseTitle: course,
            email: email,
            firstName: firstName,
            lastName: lastName
          }
        }
      })
    }

    setSubmitComplete(true)
  }

  const availableCourses =
    courses?.marketingCourses?.reduce((acc: string[], course) => {
      // in Sanity, if the course is legacy, title comes from top-level title field.
      // otherwise it comes from the nested course
      const courseTitle = course?.course?.title || course.title
      // Remove nulls and duplicates
      if (!courseTitle || acc.indexOf(courseTitle) > -1) {
        return acc
      }

      return [...acc, courseTitle]
    }, []) || []

  // Alphabetize the course titles
  availableCourses.sort()

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <section
        className="flex flex-col justify-between px-[16px] py-[6.6vmax] md:flex-row md:px-[4vw]"
        data-test="courses-marketplace-waitlist"
      >
        <div className="mb-[64px] md:w-[45%]">
          <h1 className="mb-[32px] font-normal tracking-tight fluid-text-3xl">
            {heading}
          </h1>

          <p className="!leading-tight fluid-text-lg">
            Want to be notified when we run future sessions of these courses? Sign up on
            this page to ensure you’re the first to know.
          </p>
        </div>

        <div className="md:w-[50%]">
          {submitComplete ? (
            <p className="mt-[20px] !leading-tight fluid-text-base">
              Thank you for your interest! We’ll keep you informed when we run this course
              again.{' '}
            </p>
          ) : (
            <form className="chromatic-ignore flex flex-col" onSubmit={onSubmit}>
              <label className="mb-[1vw] font-serif fluid-text-base">
                Name{' '}
                <span className="tracking-tighter opacity-70 fluid-text-sm">
                  (required)
                </span>
              </label>

              <div className="mb-[20px] flex">
                <label
                  htmlFor="firstName-input"
                  className="mr-[4px] w-1/2 font-serif fluid-text-sm"
                >
                  First Name
                  <MarketingInput
                    className="bg-rb-gray-20"
                    name="firstName"
                    type="text"
                    defaultValue={currentUser?.firstName || ''}
                    required
                    id="firstName-input"
                  />
                </label>
                <label
                  htmlFor="lastName-input"
                  className="ml-[4px] w-1/2 font-serif fluid-text-sm"
                >
                  Last Name
                  <MarketingInput
                    className="bg-rb-gray-20"
                    name="lastName"
                    type="text"
                    defaultValue={currentUser?.lastName || ''}
                    required
                    id="lastName-input"
                  />
                </label>
              </div>

              <label className="mb-[20px] font-serif fluid-text-base">
                Email{' '}
                <span className="tracking-tighter opacity-70 fluid-text-sm">
                  (required)
                </span>
                <MarketingInput
                  className="bg-rb-gray-20"
                  name="email"
                  type="email"
                  defaultValue={currentUser?.contact?.primaryEmail || ''}
                  required
                />
              </label>

              <label className="mb-[20px] font-serif fluid-text-base">
                Course{' '}
                <span className="tracking-tighter opacity-70 fluid-text-sm">
                  (required)
                </span>
                <MarketingSelect
                  className="bg-rb-gray-20"
                  name="course"
                  defaultValue="default"
                  onChange={() => setError('')}
                  options={[
                    {
                      value: 'default',
                      label: 'Select a Course',
                      disabled: true
                    },
                    ...availableCourses
                  ]}
                  required
                />
              </label>

              <small className="mb-4 text-rb-destructive">{error}</small>

              <ButtonMarketingCTA type="submit">
                Add me to the waitlist ↗
              </ButtonMarketingCTA>
            </form>
          )}
        </div>
      </section>
    </>
  )
}

export default CourseWaitList
