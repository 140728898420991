import { ReactComponent as InfoIcon } from 'icon--info.svg'

import { TOPIC_FILTERS, TOPIC_MAP } from 'pages/ProgramsPage/ProgramsPage'

import BookmarkControls from 'domains/Bookmarks/BookmarkControls'

import { Tag } from 'components/Tag'
import Tooltip from 'components/Tooltip/Tooltip'

import {
  BookmarkFolderPartsFragment,
  ProgramBookmarkPartsFragment,
  ProgramCardTopSectionPartsFragment,
  UserBookmarksFeedDocument,
  useDeleteBookmarkMutation
} from 'gql'

import notifyError from 'utils/errorNotifier'

interface ProgramCardTopSectionProps {
  program: ProgramCardTopSectionPartsFragment
  isTrial: boolean
  type: string
  postCohort?: boolean
  showEnrolledBadge?: boolean
  bookmark?: ProgramBookmarkPartsFragment
  bookmarkFolders?: BookmarkFolderPartsFragment[]
  restoreBookmark?: (bookmark: ProgramBookmarkPartsFragment) => void
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  handleRemoveFromFolder?: (
    bookmarkId: string,
    BookmarkFolder: BookmarkFolderPartsFragment
  ) => Promise<string | null | undefined>
  currentFolder?: BookmarkFolderPartsFragment | null
  reforgeCollection?: boolean
}

const ProgramCardTopSection = ({
  program,
  isTrial,
  type,
  postCohort,
  showEnrolledBadge,
  bookmark,
  restoreBookmark,
  bookmarkFolders,
  currentFolder,
  openAddToBookmarkFolderModal,
  handleRemoveFromFolder,
  reforgeCollection = false
}: ProgramCardTopSectionProps) => {
  const [removeBookmark] = useDeleteBookmarkMutation({
    update: (cache, mutationResult) => {
      const deletedBookmarkId = mutationResult?.data?.deleteBookmark?.id
      if (!deletedBookmarkId) return

      const normalizedId = cache.identify({
        id: deletedBookmarkId,
        __typename: 'Bookmark'
      })
      cache.evict({ id: normalizedId })
      cache.gc()
    }
  })

  const deleteBookmark = async () => {
    if (!bookmark || !bookmark.id) {
      notifyError(`invalid bookmark, cannot delete ${JSON.stringify(bookmark)}`)
      return
    }
    const { errors } = await removeBookmark({
      variables: {
        input: {
          bookmarkId: bookmark.id
        }
      },
      refetchQueries: [UserBookmarksFeedDocument]
    })
    if (errors) {
      notifyError(
        `Error deleting program bookmark, got errors ${errors} for bookmark ${JSON.stringify(
          bookmark
        )}`
      )
    } else {
      window.changeNumSectionBookmarks?.(bookmark.cmsSection?.id || '', -1)
    }
  }

  const getTagText = () => {
    if (showEnrolledBadge && !isTrial && !postCohort) {
      return 'Enrolled'
    } else if (program.upcoming && !program.enrolled) {
      return 'Coming Soon'
    } else if (
      !isTrial &&
      !program.enrolled &&
      !program.upcoming &&
      !!program.showNewBadge
    ) {
      return 'New'
    } else {
      return null
    }
  }

  const tag = getTagText()
  const TooltipBody = (
    <div className="max-w-[215px]">
      <p className="m-0 text-sm font-semibold">About this course</p>
      <p className="m-0">{program.description}</p>
    </div>
  )

  // Hack to map the audience/content segments to the topic label
  // Ideally, this map should be moved to the database's cms_program table
  const productTopic = TOPIC_MAP[TOPIC_FILTERS[1]].includes(program.topic || '')
    ? TOPIC_FILTERS[1]
    : null
  const growthTopic = TOPIC_MAP[TOPIC_FILTERS[2]].includes(program.topic || '')
    ? TOPIC_FILTERS[2]
    : null
  const engineeringTopic = TOPIC_MAP[TOPIC_FILTERS[3]].includes(program.topic || '')
    ? TOPIC_FILTERS[3]
    : null
  const marketingTopic = TOPIC_MAP[TOPIC_FILTERS[4]].includes(program.topic || '')
    ? TOPIC_FILTERS[4]
    : null
  const topic: string | null =
    productTopic || growthTopic || engineeringTopic || marketingTopic || null

  return (
    <div className="flex flex-col p-6">
      {(program.iconImageUrl || tag) && (
        <div className="flex h-[18px] items-center justify-between">
          <div className="flex">
            {program.iconImageUrl && (
              <img
                alt=""
                src={program.iconImageUrl}
                style={{ height: '18px', width: '18px' }}
              />
            )}
            <p className="m-0 pl-1.5 text-xs font-medium leading-[18px] text-rb-gray-500">{`${
              type.charAt(0).toUpperCase() + type.slice(1)
            }`}</p>
            {tag ? (
              <Tag variant="teal" text={tag} className="ml-1.5 h-5" />
            ) : topic ? (
              <p className="m-0 px-1.5 text-xs leading-[18px] line-clamp-1" title={topic}>
                {topic}
              </p>
            ) : null}
          </div>
          {bookmark && !reforgeCollection ? (
            <BookmarkControls
              bookmark={bookmark}
              onDeleteBookmark={deleteBookmark}
              restoreBookmark={restoreBookmark}
              bookmarkFolders={bookmarkFolders}
              currentFolder={currentFolder}
              openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
              handleRemoveFromFolder={handleRemoveFromFolder}
            />
          ) : (
            <Tooltip tooltipBody={TooltipBody} place="right" backgroundColor="#080A0A">
              <InfoIcon
                width={16}
                height={16}
                className="self-end text-rb-black hover:text-rb-teal-200"
              />
            </Tooltip>
          )}
        </div>
      )}
      <p className="m-0 mt-[30px] text-[26px] font-semibold leading-[1.2]">
        {program.name}
      </p>
    </div>
  )
}

export default ProgramCardTopSection
