/* eslint-disable */
// This file was automatically generated using yarn segment:generate:prod.
// DO NOT MODIFY IT BY HAND. Instead, update segment tracking protocol,
// and run yarn segment:generate to regenerate this file.

import { MarketingFormSubmitted } from '../types'
import { track } from 'utils/tracking/segment'

export function trackMarketingFormSubmitted(event: MarketingFormSubmitted) {
  track('Marketing Form Submitted', event)
}
