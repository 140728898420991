import React, { useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'

import CmsCommentsBlock from 'domains/Cms/CommentsBlock/CommentsBlock'
import ContentLinks from 'domains/Cms/ContentLinks'

import { convertProjectId } from 'components/BookmarkHighlighter/utils'

import { useCmsContentQuery } from 'gql'
import { CmsSectionContentType } from 'gql/index'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { highlightingState, useSnapshot } from 'stores'

import { SharedType } from 'typings/sharedType'

import useDiscussions from './hooks/useDiscussions'

type ElementPair = [Element, Element]

const PortalCommentableBlock = ({
  node,
  children
}: {
  node: Element
  children: React.ReactChild
}) => {
  return ReactDOM.createPortal(children, node)
}

interface ICommentableBlockContainer {
  cmsContentEndpoint: string
  userId?: string
  cmsContentId?: string
  cmsProgramId?: string
  cmsModuleId?: string
  isPremember: boolean
  contentType: CmsSectionContentType | SharedType
  initialGroupId: string
  initialTopicId?: string | null
  accessPolicyKind: string
  hasApplied: boolean
  setActivePanel: (type: string) => void // accepts 'bookmarks' | 'discussions' | 'save'
}

const formatBookmarksByAnchor = (bookmarks: any[]) => {
  return bookmarks.reduce(
    (prevVal, curVal) => ({ ...prevVal, [curVal.anchor]: curVal }),
    {}
  )
}

export const CommentableBlockContainer = (props: ICommentableBlockContainer) => {
  const {
    cmsContentEndpoint,
    userId,
    cmsContentId,
    cmsProgramId,
    cmsModuleId,
    isPremember,
    contentType,
    accessPolicyKind,
    hasApplied,
    initialGroupId,
    initialTopicId,
    setActivePanel
  } = props
  const [nodes, setNodes] = useState<ElementPair[]>([])
  const [contentLinks, setContentLinks] = useState([])
  const { showHighlighting } = useFeatureFlags()
  const highlightingSnapshot = useSnapshot(highlightingState)

  const cmsSectionId = cmsContentEndpoint.split('/').pop()!

  const { loading: discussionsLoading, inlinePosts } = useDiscussions({
    cmsSectionId,
    cmsModuleId: cmsModuleId || '',
    cmsProgramId: cmsProgramId || '',
    userId: userId || ''
  })

  const { loading: cmsContentLoading, data: cmsContentData } = useCmsContentQuery({
    variables: { cmsSectionId }
  })

  const bookmarksByAnchor = cmsContentData?.cmsContent?.bookmarks?.length
    ? formatBookmarksByAnchor(cmsContentData.cmsContent.bookmarks)
    : {}

  // convertProjectId for legacy posts with content_ prefix
  const inlinePostAnchors = useMemo(() => {
    if (discussionsLoading) return []
    return inlinePosts.map((post) => convertProjectId(post.anchor))
  }, [discussionsLoading, inlinePosts])

  useEffect(() => {
    if (
      !discussionsLoading &&
      (highlightingSnapshot.eventCount > 0 || !showHighlighting)
    ) {
      // Find content elements/nodes based on inline post anchors
      const inlinePostNodes = (inlinePostAnchors || []).length
        ? Array.from(
            document.querySelectorAll(
              (inlinePostAnchors || []).map((id) => `#${id}`).join(', ')
            )
          )
        : []

      // Find parent portal nodes for child inline post nodes
      const nodes: ElementPair[] = inlinePostNodes.map((node) => {
        if (!node.classList.contains('inline-posts')) {
          const parentInlinePostNode = node.closest('.inline-posts')
          if (parentInlinePostNode) return [parentInlinePostNode, node]
        }
        return [node, node]
      })

      setNodes(nodes)
    }
  }, [
    discussionsLoading,
    highlightingSnapshot.eventCount,
    inlinePostAnchors,
    showHighlighting
  ])

  useEffect(() => {
    if (
      contentType === CmsSectionContentType.PROJECT ||
      contentType === CmsSectionContentType.CONCEPT
    ) {
      setContentLinks(Array.from(document.querySelectorAll('.content-link')))
    }
  }, [contentType])

  if (cmsContentLoading || discussionsLoading) return null

  const nodeComponents = (
    <>
      {nodes.map(([portalNode, dataNode], idx) => {
        const nodeData = dataNode.getAttribute('data') || '{}'

        return (
          <PortalCommentableBlock key={`commentnode${idx}`} node={portalNode}>
            <CmsCommentsBlock
              id={dataNode.getAttribute('id')}
              userId={userId}
              cmsSectionId={cmsContentId}
              cmsProgramId={cmsProgramId}
              cmsModuleId={cmsModuleId}
              referenceImageUrl={dataNode.getAttribute('data-reference-image-url')}
              synopsis={dataNode.getAttribute('data-synopsis')}
              bookmarksByAnchor={bookmarksByAnchor}
              inlinePostAnchors={inlinePostAnchors}
              inlinePosts={inlinePosts}
              initialGroupId={initialGroupId}
              initialTopicId={initialTopicId}
              accessPolicyKind={accessPolicyKind}
              hasApplied={hasApplied}
              setActivePanel={setActivePanel}
              {...JSON.parse(nodeData)}
            />
          </PortalCommentableBlock>
        )
      })}
      {contentLinks.length > 0 && (
        <ContentLinks
          cmsProgramId={cmsProgramId}
          cmsModuleId={cmsModuleId}
          isPremember={isPremember}
          links={contentLinks}
          accessPolicyKind={accessPolicyKind}
          pageType={contentType}
          hasApplied={hasApplied}
        />
      )}
    </>
  )

  return nodeComponents
}
