import { createContext, useContext } from 'react'

import { CohortViewerNewCohortPartsFragment } from 'gql'

interface CohortDashboardTrackingContextValue {
  cohort: CohortViewerNewCohortPartsFragment
  userCohortId: string | null
}

export const CohortDashboardTrackingContext =
  createContext<CohortDashboardTrackingContextValue>({
    cohort: {} as CohortViewerNewCohortPartsFragment,
    userCohortId: null as string | null
  })

export function useCohortDashboardTrackingContext() {
  return useContext(CohortDashboardTrackingContext)
}
