import Image from 'domains/Sanity/Image'

import { WorkEntry } from 'gql'

interface WorkEntryAvatarProps {
  workEntry: WorkEntry
  avatarUrl: string
  avatarAlt: string
}

const WorkEntryAvatar = ({ workEntry, avatarUrl, avatarAlt }: WorkEntryAvatarProps) => {
  const CompanyAvatar = () => {
    if (!workEntry?.company?.logo?.imageUrl) {
      return (
        <Image
          className="rounded-2xl"
          height={30}
          width={30}
          alt={'Avatar for artifact creator'}
          src={avatarUrl}
        />
      )
    }

    return (
      <Image
        className="border-rb-gray"
        height={30}
        width={30}
        alt={workEntry?.company.name || 'Avatar for artifact creator'}
        src={workEntry?.company.logo.imageUrl || avatarUrl}
      />
    )
  }

  return (
    <div className="mr-4 mt-[3px] flex-shrink-0">
      {workEntry.specificCompany ? (
        <CompanyAvatar />
      ) : (
        <Image
          className="rounded-full"
          src={avatarUrl}
          width={30}
          height={30}
          alt={avatarAlt || 'profile photo'}
        />
      )}
    </div>
  )
}

export default WorkEntryAvatar
