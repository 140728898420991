import Table from 'domains/Sanity/Table'

import InlineImage from '../../../InlineImage'
import { ComplexPortableTextBlockValueProp } from '../../types'
import Photo from '../Photo'
import { Video } from '../Video'

export const renderTable = ({ value }: ComplexPortableTextBlockValueProp) => {
  return <Table rows={value.rows} />
}

export const renderPhoto = ({ value }: ComplexPortableTextBlockValueProp) => (
  <Photo className="p-6" id={value._key} photo={value} useLightbox />
)

export const renderInlineImage = ({ value }: ComplexPortableTextBlockValueProp) => (
  <InlineImage id={value?._key} caption={value.caption} image={value.image} />
)

export const renderVideo = ({ value }: ComplexPortableTextBlockValueProp) => (
  <Video value={value} />
)
