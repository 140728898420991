import React from 'react'
import { useLocation } from 'react-router-dom'

import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import MySavedItemsEmptyState from 'domains/Bookmarks/MySavedItemsEmptyState'
import { LOCATION_GLOBAL_SAVED_ITEMS } from 'domains/Bookmarks/utils'

import { Loading } from 'components'

import {
  BookmarkFolderPartsFragment,
  CourseBookmarkPartsFragment,
  CreateBookmarkInput,
  CurrentUserPartsFragment,
  ProgramBookmarkPartsFragment,
  useBookmarksFromFolderQuery,
  useCreateBookmarkMutation,
  useUserCohortsQuery
} from 'gql'

import notifyError from 'utils/errorNotifier'
import { trackSavedItemClicked } from 'utils/tracking/analytics'

import MySavedItemsListByType from './MySavedItemsListByType'
import MySavedItemsListSorted from './MySavedItemsListSorted'
import useHandleRemoveFromFolder from './hooks/useHandleRemoveFromFolder'
import useOpenAddToBookmarkFolderModal from './hooks/useOpenAddToBookmarkFolderModal'

interface MySavedItemsListProps {
  mySavedItems: BookmarkFolderPartsFragment
  currentUser: CurrentUserPartsFragment
  currentToggle: string
  bookmarkFolders?: BookmarkFolderPartsFragment[]
  openCreateBookmarkFolderModal?: (
    bookmark: ProgramBookmarkPartsFragment | CourseBookmarkPartsFragment | null
  ) => void
}

const MySavedItemsList = ({
  mySavedItems,
  currentUser,
  currentToggle,
  bookmarkFolders,
  openCreateBookmarkFolderModal
}: MySavedItemsListProps) => {
  const { data, loading } = useBookmarksFromFolderQuery({
    variables: { folderId: mySavedItems.id }
  })

  const { pathname } = useLocation()
  const { RemoveBookmarkFromFolder } = useHandleRemoveFromFolder({})
  const { data: userCohortsData } = useUserCohortsQuery()
  const [createBookmark] = useCreateBookmarkMutation()
  const {
    currentBookmarkForDropdown,
    isAddToBookmarkFolderModalOpen,
    closeAddToBookmarkFolderModal,
    openAddToBookmarkFolderModal
  } = useOpenAddToBookmarkFolderModal()
  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal?.(currentBookmarkForDropdown)
  }

  if (loading) {
    return <Loading />
  }

  const handleRemoveFromFolder = async (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => {
    const { errors, data } = await RemoveBookmarkFromFolder({
      variables: {
        input: {
          bookmarkId: bookmarkId,
          bookmarkFolderId: bookmarkFolder.id
        }
      }
    })

    if (errors) {
      notifyError(errors)
      return null
    } else {
      return data?.deleteFiledBookmark?.filedBookmark?.bookmarkFolderId
    }
  }

  const restoreBookmark = async (bookmark: ProgramBookmarkPartsFragment) => {
    const {
      basedOn,
      cmsProgram,
      cmsModule,
      cmsSection,
      noteBody,
      seconds,
      referenceImageUrl,
      type,
      title,
      externalUrl
    } = bookmark
    const bookmarkInput: CreateBookmarkInput = {
      basedOn: basedOn,
      cmsProgramId: cmsProgram?.id,
      cmsModuleId: cmsModule?.id,
      cmsSectionId: cmsSection?.id,
      noteBody: noteBody,
      seconds: seconds,
      referenceImageUrl: referenceImageUrl,
      type: type,
      title: title,
      externalUrl: externalUrl
    }

    try {
      await createBookmark({
        variables: {
          input: bookmarkInput
        }
      })
    } catch (error: unknown) {
      notifyError(error)
    }
  }

  const handleBookmarkClickTracking = (
    bookmark: ProgramBookmarkPartsFragment,
    component?: string
  ) => {
    trackSavedItemClicked({
      location: LOCATION_GLOBAL_SAVED_ITEMS,
      path: pathname,
      cms_program_name: bookmark?.cmsProgram?.name,
      cms_program_id: bookmark?.cmsProgram?.id,
      cms_module_name: bookmark.cmsModule?.name,
      cms_module_id: bookmark.cmsModule?.id,
      cms_section_name: bookmark.cmsSection?.name,
      cms_section_id: bookmark.cmsSection?.id,
      component,
      bookmark_type: bookmark.type,
      collection_id: mySavedItems?.id,
      collection_name: mySavedItems?.name,
      is_reforge_collection: mySavedItems?.reforgeCollection,
      is_shared_collection: mySavedItems?.sharedFolders[0]?.status === 'shared'
    })
  }

  return (
    <div>
      <AddBookmarkToFolderModal
        isOpen={isAddToBookmarkFolderModalOpen}
        handleClose={closeAddToBookmarkFolderModal}
        bookmarkFolders={bookmarkFolders}
        openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdown}
        showCollectionsOnboardingInfo={!currentUser?.hasSeenCollectionsPrompt}
      />
      {!!data?.bookmarksFromFolder?.length && currentToggle === 'Type' && (
        <MySavedItemsListByType
          bookmarks={data.bookmarksFromFolder}
          handleRemoveFromFolder={handleRemoveFromFolder}
          handleBookmarkClickTracking={handleBookmarkClickTracking}
          restoreBookmark={restoreBookmark}
          openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
          currentUser={currentUser}
          mySavedItems={mySavedItems}
          userCohortsData={userCohortsData}
          bookmarkFolders={bookmarkFolders}
        />
      )}
      {!!data?.bookmarksFromFolder?.length &&
        ['Date Saved', 'Recently Viewed'].includes(currentToggle) && (
          <MySavedItemsListSorted
            bookmarks={data.bookmarksFromFolder}
            handleRemoveFromFolder={handleRemoveFromFolder}
            handleBookmarkClickTracking={handleBookmarkClickTracking}
            restoreBookmark={restoreBookmark}
            openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
            currentUser={currentUser}
            mySavedItems={mySavedItems}
            userCohortsData={userCohortsData}
            bookmarkFolders={bookmarkFolders}
            currentToggle={currentToggle}
          />
        )}
      {!data?.bookmarksFromFolder?.length && (
        <div className="pt-8">
          <MySavedItemsEmptyState />
        </div>
      )}
    </div>
  )
}

export default MySavedItemsList
