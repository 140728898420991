import {
  getNPSBannerDismissalFlag,
  getNPSCompletionFlag
} from 'components/banners/TopBanner/NpsBanner'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import {
  CohortDashboardCurrentUserPartsFragment,
  CohortDashboardSeasonPartsFragment
} from 'gql'

import { onEnterKeyPress } from 'utils/keyboard'

import { WeeklyBlockChecklistCard } from '../CohortDashboardWeeklyBlock/WeeklyBlockChecklist'

interface CohortDashboardInlineSurveyProps {
  season: CohortDashboardSeasonPartsFragment
  programName: string
  currentUser: CohortDashboardCurrentUserPartsFragment
}

export const CohortDashboardInlineSurvey = ({
  season,
  programName,
  currentUser
}: CohortDashboardInlineSurveyProps) => {
  const npsCompletionFlag = getNPSCompletionFlag(season)
  const npsBannerDismissalFlag = getNPSBannerDismissalFlag(season)

  const userCompletedNpsSurvey =
    window.localStorage.getItem(npsCompletionFlag) === '1' ||
    currentUser.shouldShowNpsSurvey ||
    false

  const onSurveyLinkClick = () => {
    window.localStorage.setItem(npsBannerDismissalFlag, '1')
    window.localStorage.setItem(npsCompletionFlag, '1')
    window.open(`${season.npsSurveyLink}?program=${programName}&pid=${currentUser.id}`)
    window.location.reload()
  }

  return (
    <div
      tabIndex={0}
      role="button"
      className="w-full max-w-[890px] pb-20"
      onKeyDown={onEnterKeyPress(onSurveyLinkClick)}
      onClick={onSurveyLinkClick}
    >
      <WeeklyBlockChecklistCard
        titleComponent={<RfParagraphMediumBold> How did it go?</RfParagraphMediumBold>}
        subtitleComponent={
          <RfParagraphSmall>
            Take our quick survey to help us shape future courses.
          </RfParagraphSmall>
        }
        checked={userCompletedNpsSurvey}
        className="mb-0"
      />
    </div>
  )
}

export default CohortDashboardInlineSurvey
