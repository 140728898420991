import { useCohortConversationTrackingContext } from 'domains/CohortConversation/hooks/useCohortConversationsTrackingContext'

import { useCreateCohortPostMutation } from 'gql'

import { trackPostAction } from 'utils/tracking/generated/events'

export interface CohortPostCreateProps {
  body: string
  notifyCohort?: boolean
}

export const useCreateCohortPost = () => {
  const [createCohortPostMutation] = useCreateCohortPostMutation()
  const { cohort, topicId, groupId } = useCohortConversationTrackingContext()

  const createCohortPost = async ({ body, notifyCohort }: CohortPostCreateProps) => {
    const response = await createCohortPostMutation({
      variables: {
        input: {
          cohortGroupId: groupId,
          body,
          topicId,
          notifyCohort,
          cohortId: cohort.id
        }
      }
    })

    const postId = response?.data?.createCohortPost?.id

    if (postId) {
      trackPostAction({
        action: 'create',
        group_ids: [groupId],
        topic_ids: [topicId],
        post_id: postId,
        cms_program_id: cohort?.cmsProgram?.id,
        cohort_id: cohort?.id,
        season_id: cohort?.season?.id
      })
    }
  }

  return { createCohortPost }
}

export default useCreateCohortPost
