import { ReactNode, useEffect } from 'react'
import { Link, matchPath, useHistory, useLocation } from 'react-router-dom'
import { useGlobalState } from 'state'
import { twMerge } from 'tailwind-merge'

import CohortDashboardNotificationsBadge from 'domains/CohortDashboard/CohortDashboardNotificationsBadge'
import { useCurrentScheduledWeekId } from 'domains/CohortDashboard/hooks/useCurrentScheduledWeekId'
import { useLessonNavInfo } from 'domains/CohortDashboard/hooks/useLessonNavInfo'

import Button from 'components/Button'
import { ChevronRightThinIcon } from 'components/icons'
import SkeletonRfParagraph from 'components/skeletons/typography/SkeletonRfParagraph'
import RfParagraphMediumSemiBold from 'components/typography/RfParagraph/RfParagraphMediumSemiBold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import {
  CohortViewerNewCohortPartsFragment,
  CohortViewerNewCohortTeamPartsFragment,
  useCohortConversationTopicsQuery,
  useCohortDashboardScheduledWeekLazyQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { isStaffOrCollective } from 'utils/cohortConversationUtils'
import { isAfterDate } from 'utils/date'
import { makeConversationUrl } from 'utils/url'

import { ReactComponent as ChevronLeftIcon } from 'images/chevron-left.svg'
import { ReactComponent as BookmarkIcon } from 'images/icon--bookmark.svg'
import { ReactComponent as NotificationIcon } from 'images/icon--cohort-notification.svg'
import { ReactComponent as CohortTeamsIcon } from 'images/icon--cohort-teams.svg'
import { ReactComponent as HomeIcon } from 'images/icon--home.svg'
import { ReactComponent as SlackIcon } from 'images/slack-icon.svg'

const LINK_TITLE_NOTIFICATIONS = 'Notifications'

interface CohortDashboardLeftSideNavProps {
  cohort: CohortViewerNewCohortPartsFragment
  slug: string
  postId?: string
  showSidebar: boolean
  isLessonViewer: boolean
  toggleSidebar: () => void
  cohortTeams: CohortViewerNewCohortTeamPartsFragment[]
}

const CohortDashboardLeftSideNav = ({
  cohort,
  slug,
  postId,
  showSidebar,
  isLessonViewer,
  toggleSidebar,
  cohortTeams
}: CohortDashboardLeftSideNavProps) => {
  const { showInProductCommunication } = useFeatureFlags()
  const [envVariables] = useGlobalState('envVariables')

  const { currentUser } = useCurrentUser()
  const history = useHistory()

  const baseRoute = `/cohorts/${slug}`
  const { loading, data } = useCohortConversationTopicsQuery({
    variables: { slug }
  })
  const { weekId } = useCurrentScheduledWeekId({ cohort, ignoreUrlParams: true })
  const [getCohortDashboardScheduledWeek, { data: scheduledWeekData }] =
    useCohortDashboardScheduledWeekLazyQuery({
      variables: { cohortSlug: cohort.slug, weekId }
    })

  useEffect(() => {
    if (weekId) getCohortDashboardScheduledWeek()
  }, [getCohortDashboardScheduledWeek, weekId])

  const topics = data?.cohort?.group?.topics

  const currentScheduledWeek = scheduledWeekData?.cohortDashboardScheduledWeek
  const lessonNavInfo = useLessonNavInfo({
    cohort,
    scheduledWeek: currentScheduledWeek,
    activeProgram: currentUser?.activeProgram
  })

  // 40 is the ID of Spring 2023 Season
  const isAfterSpring2023Season = parseInt(cohort?.season?.id || '0') > 40

  return (
    <div
      className={twMerge(
        'z-50 flex h-full max-w-[240px] shrink-0 flex-col justify-between bg-white py-6 pr-6 pl-5 lg:static',
        showSidebar ? 'absolute top-0 left-0' : 'hidden',
        isLessonViewer && 'fixed top-0 mt-[68px] h-screen lg:sticky lg:top-[68px] lg:mt-0'
      )}
    >
      <div className="flex w-[210px] flex-col gap-3">
        <CohortDashboardLink
          title="Back to Reforge"
          icon={<ChevronLeftIcon className="h-4 w-4 text-rb-gray-250" />}
          path="/"
        />

        <CohortDashboardLink
          title="Today"
          icon={<HomeIcon width={18} height={16.41} fill="#080A0A" />}
          path={`${baseRoute}`}
          onClick={toggleSidebar}
        />
        {!isAfterSpring2023Season && (
          <CohortDashboardLink
            onClick={() => {
              toggleSidebar()
            }}
            slug={slug}
            title={LINK_TITLE_NOTIFICATIONS}
            icon={<NotificationIcon width={14.65} height={18} fill="#080A0A" />}
            path={`${baseRoute}/notifications`}
          />
        )}
        <CohortDashboardLink
          title="Bookmarks"
          icon={<BookmarkIcon width={16.73} height={16.73} fill="#080A0A" />}
          path={`${baseRoute}/bookmarks`}
          onClick={toggleSidebar}
        />
        {cohortTeams.length > 0 && (
          <CohortDashboardLink
            title="My Team"
            icon={<CohortTeamsIcon width={16.73} height={18} fill="#080A0A" />}
            path={`${baseRoute}/my-team`}
            onClick={toggleSidebar}
          />
        )}
        {isAfterSpring2023Season && (
          <a
            target="_blank"
            rel="noreferrer"
            className="hover:no-underline"
            href={
              currentUser?.slackUserId
                ? `https://reforge-members.slack.com/app_redirect?channel=${cohort.slackChannelId}`
                : envVariables.joinSlackHref
            }
            onClick={toggleSidebar}
          >
            <div className="flex w-full cursor-pointer flex-row rounded-[8px] py-2.5 pl-3 hover:bg-gray-100">
              <RfParagraphMediumSemiBold className="mb-0">
                <span className="flex items-center">
                  <span className="mr-4">
                    <SlackIcon width={16.73} height={18} />
                  </span>
                  Slack channel
                </span>
              </RfParagraphMediumSemiBold>
            </div>
          </a>
        )}
        {showInProductCommunication && (
          <div className="mt-8">
            {loading && <ConversationSkeletonLoader />}

            {topics && (
              <>
                {(!isAfterSpring2023Season || isStaffOrCollective(currentUser)) && (
                  <RfParagraphSmall className="py-1.5 pl-2.5 pr-7.5 pb-4">
                    <span className="font-semibold">Channels</span>
                  </RfParagraphSmall>
                )}

                <div className="flex flex-col gap-1">
                  {topics.map((topic) => (
                    <CohortConversationLink
                      key={topic.id}
                      title={topic.title}
                      path={makeConversationUrl(slug, topic.slug, postId)}
                      topicSlug={topic.slug}
                      onClick={toggleSidebar}
                      latestPostCreatedAt={topic.latestPostCreatedAtForCohort}
                      lastViewedAt={topic.userTopicActivity.lastViewedAt}
                      isAfterSpring2023Season={isAfterSpring2023Season}
                      showForAdminsOnly={
                        topic.restrictedToStaff &&
                        isAfterSpring2023Season &&
                        !isStaffOrCollective(currentUser)
                      }
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {currentScheduledWeek?.isCurrentWeek && lessonNavInfo.label && (
        <Button
          variant="fill"
          size="x-small"
          shape="rounded-full"
          onClick={() => history.push(lessonNavInfo.url)}
          className="flex w-full py-2.5 px-2 md:hidden"
        >
          <span className="text-xs font-medium">{lessonNavInfo.label}</span>
          <ChevronRightThinIcon className="ml-3 h-3 w-3 fill-current" />
        </Button>
      )}
    </div>
  )
}

interface CohortLinkProps {
  title: string
  path: string
  icon?: ReactNode
  slug?: string
  onClick?: () => void
}

interface CohortConversationLinkProps extends CohortLinkProps {
  topicSlug: string
  latestPostCreatedAt?: string | null
  lastViewedAt?: string | null
  showForAdminsOnly?: boolean
  isAfterSpring2023Season?: boolean
}

const CohortDashboardLink = ({ onClick, title, path, slug, icon }: CohortLinkProps) => {
  const { pathname } = useLocation()
  const isActive = determineStartingActiveTab(pathname, path) === title.toLowerCase()
  const shouldIncludeNotificationsBadge = title === LINK_TITLE_NOTIFICATIONS && !!slug

  return (
    <Link to={path} className="hover:no-underline" onClick={onClick}>
      <div
        className={twMerge(
          'flex w-full cursor-pointer flex-row rounded-[8px] py-2.5 pl-3 hover:bg-gray-100',
          isActive && 'bg-gray-50'
        )}
      >
        <RfParagraphMediumSemiBold className="mb-0">
          <span className="flex items-center">
            {icon && <span className="mr-4 text-rb-black">{icon}</span>}
            {title}
          </span>
        </RfParagraphMediumSemiBold>
        {shouldIncludeNotificationsBadge && (
          <CohortDashboardNotificationsBadge slug={slug} className="ml-3 mt-1" />
        )}
      </div>
    </Link>
  )
}

const CohortConversationLink = ({
  title,
  path,
  topicSlug,
  onClick,
  latestPostCreatedAt,
  lastViewedAt,
  showForAdminsOnly,
  isAfterSpring2023Season = false
}: CohortConversationLinkProps) => {
  const { pathname } = useLocation()
  const isActive = matchPath(pathname, { path: `*/conversations/${topicSlug}` })
  const hasNewPosts = isAfterDate(lastViewedAt, latestPostCreatedAt)

  if (showForAdminsOnly) return null
  if (isAfterSpring2023Season && title === 'Content questions') return null

  const announcementsTitle =
    title === 'Announcements' && isAfterSpring2023Season ? 'Email cohort' : title

  return (
    <Link to={path} className="hover:no-underline" onClick={onClick}>
      <div
        className={twMerge(
          'mb-0.5 w-full cursor-pointer rounded-[8px] py-2 pl-3 hover:bg-gray-100',
          isActive && 'bg-gray-50'
        )}
      >
        <RfParagraphSmall className="mb-0" color={ColorOptions.black}>
          <span className={twMerge((isActive || hasNewPosts) && 'font-semibold')}>
            {announcementsTitle}
          </span>
        </RfParagraphSmall>
      </div>
    </Link>
  )
}

const ConversationSkeletonLoader = () => (
  <div className="flex flex-col items-center">
    <SkeletonRfParagraph className="mb-0 max-w-[109px]" />
    <div className="mt-4 flex flex-col gap-y-[10px]">
      {[...Array(5)].map((_, index) => (
        <div className="flex items-center" key={index}>
          <div className="mr-2 h-4 w-4 animate-pulse rounded bg-rb-gray-100" />
          <SkeletonRfParagraph className="mb-0 w-[100px]" />
        </div>
      ))}
    </div>
  </div>
)

const determineStartingActiveTab = (pathname: string, basePath: string) => {
  if (matchPath(pathname, { path: '*/notifications' })) {
    return 'notifications'
  }

  if (matchPath(pathname, { path: '*/bookmarks' })) {
    return 'bookmarks'
  }

  if (matchPath(pathname, { path: '*/highlights' })) {
    return 'highlights'
  }

  if (matchPath(pathname, { path: '*/my-team' })) {
    return 'my team'
  }

  if (matchPath(pathname, { path: '*/content-questions' })) {
    return 'content questions'
  }

  if (matchPath(pathname, { path: basePath, exact: true })) {
    return 'today'
  }

  return ''
}

export default CohortDashboardLeftSideNav
