import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import WeeklyBlockChecklistKickoffWeek from 'domains/CohortDashboard/CohortDashboardProgram/CohortDashboardWeeklyBlock/WeeklyBlockChecklist/WeeklyBlockChecklistKickoffWeek'
import WeeklyBlockChecklistNumberedWeek from 'domains/CohortDashboard/CohortDashboardProgram/CohortDashboardWeeklyBlock/WeeklyBlockChecklist/WeeklyBlockChecklistNumberedWeek'
import { CohortEvent } from 'domains/CohortViewer/utils'
import RsvpAllEventsModal from 'domains/Event/RsvpAllEventsModal/RsvpAllEventsModal'

import { SectionCardContainerSkeleton } from 'components/CollapsibleSectionCard/SectionCardContainer'
import ErrorMessage from 'components/ErrorMessage'
import SkeletonRfParagraph from 'components/skeletons/typography/SkeletonRfParagraph'

import { RSVP_ALL_EVENTS_IN_PROGRAM_ID } from 'constants/elementIds'

import {
  CohortDashboardCurrentUserPartsFragment,
  CohortDashboardScheduledWeekPartsFragment,
  useCohortDashboardScheduledWeekChecklistQuery
} from 'gql'

import { INTRO_TOPIC_SLUG } from 'utils/cohortUtils'

interface WeeklyBlockChecklistSkeletonProps {
  className?: string
}

export const WeeklyBlockChecklistSkeleton = ({
  className
}: WeeklyBlockChecklistSkeletonProps) => (
  <div className={twMerge('flex w-2/3 animate-pulse flex-col', className)}>
    <SkeletonRfParagraph className="mt-3 mb-2 h-6 w-1/3" />
    <SectionCardContainerSkeleton className="mt-8" />
    <SectionCardContainerSkeleton className="mt-6" />
  </div>
)

export interface WeeklyBlockChecklistProps {
  scheduledWeekIndex: number
  cohortSlug: string
  currentUser: CohortDashboardCurrentUserPartsFragment
  askAnExpertEventsForRsvp: CohortEvent[]
  events: CohortEvent[]
  scheduledWeek: CohortDashboardScheduledWeekPartsFragment
  scrollToSectionWithRelevantLesson: () => any
}

export const WeeklyBlockChecklist = ({
  scheduledWeekIndex,
  cohortSlug,
  currentUser,
  askAnExpertEventsForRsvp,
  events,
  scheduledWeek,
  scrollToSectionWithRelevantLesson
}: WeeklyBlockChecklistProps) => {
  const [rsvpAllOpen, setRsvpAllOpen] = useState(false)
  const handleRsvpClick = () => {
    setRsvpAllOpen(true)
  }

  const { data, loading, error } = useCohortDashboardScheduledWeekChecklistQuery({
    variables: {
      topicSlug: INTRO_TOPIC_SLUG,
      slug: cohortSlug,
      scheduledWeekId: scheduledWeek.id
    }
  })

  if (loading || !data || !currentUser.activeProgram) {
    return <WeeklyBlockChecklistSkeleton />
  }

  if (error) {
    return <ErrorMessage error="Something went wrong while loading the week checklist" />
  }

  const userCohort = data.userCohort

  return (
    <div className="mb-10">
      <div className="flex w-full flex-col">
        {scheduledWeekIndex === 0 ? (
          <WeeklyBlockChecklistKickoffWeek
            cohortSlug={cohortSlug}
            userMadeIntroPost={data.currentUserCohortPostsByTopicCount > 0}
            userCohort={data.userCohort}
            handleRsvpClick={handleRsvpClick}
            bulkRsvpChecked={!!data.bulkRsvpdAtForCohort}
          />
        ) : (
          <WeeklyBlockChecklistNumberedWeek
            cohortSlug={cohortSlug}
            bulkRsvpChecked={!!data.bulkRsvpdAtForCohort}
            handleRsvpClick={handleRsvpClick}
            events={events}
            timezone={currentUser.timezone || ''}
            activeProgram={currentUser.activeProgram}
            scheduledWeek={scheduledWeek}
            scrollToSectionWithRelevantLesson={scrollToSectionWithRelevantLesson}
          />
        )}
      </div>
      {userCohort && (
        <RsvpAllEventsModal
          id={RSVP_ALL_EVENTS_IN_PROGRAM_ID}
          cohortEventTimeData={{
            cohortEventTimes: userCohort.cohort.batchRsvpTimeData,
            timezone: currentUser.timezone,
            kickoffEvents: userCohort.cohort.kickoffEvents
          }}
          name={userCohort.cohort.season.name}
          year={userCohort.cohort.season.year}
          programName={userCohort.cohort.cmsProgram.name}
          timeGroupingSelected={userCohort.timeGroupingSelected}
          cohortId={`${userCohort.cohort.id}`}
          isOpen={rsvpAllOpen}
          handleClose={() => {
            setRsvpAllOpen(false)
          }}
          additionalAskAnExpertEventsToRsvp={askAnExpertEventsForRsvp}
        />
      )}
    </div>
  )
}

export default WeeklyBlockChecklist
