import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import DeleteCollectionConfirmationModal from 'domains/Collections/DeleteCollectionConfirmationModal'
import { DEFAULT_COLLECTION_NAME } from 'domains/Collections/collectionConstants'

import Button from 'components/Button'
import Modal, {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  useModal
} from 'components/Modal'
import { ToggleSwitch } from 'components/ToggleSwitch'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'

import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'

import {
  BookmarkFolderPartsFragment,
  useBookmarkFolderByIdQuery,
  useUpdateBookmarkFolderMutation
} from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import notifyError from 'utils/errorNotifier'
import { onEnterKeyPress } from 'utils/keyboard'
import { trackModalDismissed, trackModalDisplayed } from 'utils/tracking/generated/events'

import useShareCollection from './hooks/useShareCollection'

interface UpdateBookmarkFolderModalProps {
  isModalOpen: boolean
  bookmarkFolder: BookmarkFolderPartsFragment
  setIsModalOpen: (arg: boolean) => void
  isShareVersion?: boolean
}

const UpdateBookmarkFolderModal = ({
  isModalOpen,
  bookmarkFolder,
  setIsModalOpen,
  isShareVersion
}: UpdateBookmarkFolderModalProps) => {
  const currentUser = useAssertCurrentUser()
  const isSmallScreen = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)
  const { isModalOpen: isDeleteModalOpen, setIsModalOpen: setIsDeleteModalOpen } =
    useModal()
  const [name, setName] = useState(bookmarkFolder.name)
  const [desc, setDesc] = useState(bookmarkFolder.description)

  const [unique, setIsUnique] = useState(true)
  const [updateBookmarkFolder] = useUpdateBookmarkFolderMutation()

  const { data } = useBookmarkFolderByIdQuery({
    variables: { folderId: bookmarkFolder.id },
    fetchPolicy: 'cache-first'
  })
  const subSharedFolder = data?.bookmarkFolder?.sharedFolders?.find(
    (sf) => sf.sharedPartyType === 'Subscription'
  )
  const isShared = subSharedFolder?.status === 'shared'

  const { handleToggleShare } = useShareCollection()

  const updateFolder = async () => {
    if (name || desc) {
      try {
        const response = await updateBookmarkFolder({
          variables: {
            input: {
              name: name.trim(),
              bookmarkFolderId: bookmarkFolder.id,
              description: desc?.trim()
            }
          }
        })

        if (response?.data?.updateBookmarkFolder?.bookmarkFolder) {
          setIsModalOpen(false)
          setIsUnique(true)
        }

        if (response?.data?.updateBookmarkFolder?.errors) {
          setIsUnique(false)
        }
      } catch (error: unknown) {
        notifyError(error)
      }
    }
  }

  const handleClose = () => {
    trackModalDismissed({
      category: 'app',
      modal_group: 'collections',
      modal_name: 'edit_collection_modal',
      location: window.location.pathname
    })
    setName(bookmarkFolder.name)
    setDesc(bookmarkFolder.description)
    setIsModalOpen(false)
  }

  const onToggleShare = () => {
    handleToggleShare(data?.bookmarkFolder)
  }

  const openDeleteModal = () => {
    setIsModalOpen(false)
    setIsDeleteModalOpen(true)
  }

  useEffect(() => {
    if (isModalOpen) {
      trackModalDisplayed({
        category: 'app',
        location: window.location.pathname,
        modal_group: 'collections',
        modal_name: 'edit_collection_modal'
      })
    }
  }, [isModalOpen])

  const inputNameLength = bookmarkFolder.name.length > 20 ? 'w-full' : ''

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        handleClose={handleClose}
        className="max-w-lg rounded-2xl"
        header={false}
        closeOnEscape={true}
        closeOnOutsideClick={true}
      >
        <ModalHeader className="mt-8 flex md:px-8">
          <ModalTitle>Edit Collection</ModalTitle>
        </ModalHeader>
        <ModalContent className="px-8 md:px-8" scrollContent={false}>
          <div className="pb-6">
            <div className="flex flex-wrap justify-between">
              <div className="pt-3 pb-2 pr-2 text-sm font-medium">Collection Name</div>
              <input
                type="text"
                className={twMerge(
                  'h-12 rounded-sm border p-2 text-rb-gray-400 outline-none focus:border-blue-300',
                  inputNameLength
                )}
                id={bookmarkFolder.id}
                name="folderName"
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
                // eslint-disable-next-line
                autoFocus
              />
            </div>
            {!unique && (
              <div className="text-rb-warning">
                Please ensure the name of the collection folder is unique
              </div>
            )}
          </div>
          <div className="flex flex-col pb-10">
            <div className="pb-2 text-sm font-medium">Description</div>
            <textarea
              className="mr-3 h-12 w-full resize-none rounded-sm border pl-2 pr-2 pt-2 text-rb-gray-400 outline-none focus:border-blue-300"
              name="folderDesc"
              defaultValue={desc || ''}
              onChange={(e) => setDesc(e.target.value)}
              onKeyUp={(e) => {
                e.preventDefault()
                onEnterKeyPress(updateFolder)
              }}
              rows={5}
            />
          </div>
          {isShareVersion && currentUser.hasTeam ? (
            <div className="inline-flex justify-between">
              <div className="w-3/4">
                <div>Share with your team</div>
                <RfParagraphMini>
                  Sharing will give everyone on your Reforge subscription view and edit
                  access
                </RfParagraphMini>
              </div>
              <ToggleSwitch handleClick={onToggleShare} toggled={isShared} />
            </div>
          ) : (
            <>
              {bookmarkFolder.name !== DEFAULT_COLLECTION_NAME &&
                bookmarkFolder.user.id === currentUser.id && (
                  <div
                    className="inline-flex"
                    onClick={openDeleteModal}
                    role="button"
                    onKeyUp={onEnterKeyPress(openDeleteModal)}
                    tabIndex={0}
                  >
                    <div className="decoration-rb-warning-100 hover:cursor-pointer hover:underline">
                      <RfParagraphMini className="text-rb-warning-200 hover:text-rb-warning-100">
                        Delete this Collection
                      </RfParagraphMini>
                    </div>
                  </div>
                )}
            </>
          )}
        </ModalContent>
        <ModalFooter>
          <Button
            className="mr-3 w-32 border border-gray-300 bg-white font-normal text-black"
            onClick={() => setIsModalOpen(false)}
            dataTest="commitment-modal-submit-button"
            size="medium"
          >
            Cancel
          </Button>
          <Button
            className="w-32 font-normal"
            disabled={!name || name.trim().length === 0}
            onClick={updateFolder}
            dataTest="commitment-modal-submit-button"
            size="medium"
          >
            Update
          </Button>
        </ModalFooter>
      </Modal>
      <DeleteCollectionConfirmationModal
        collection={bookmarkFolder}
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        setOpenPreviousModal={setIsModalOpen}
        isSmallScreen={isSmallScreen}
      />
    </>
  )
}

export default UpdateBookmarkFolderModal
