import clsx from 'clsx'
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { twMerge } from 'tailwind-merge'

import useLocalStorage from 'hooks/useLocalStorage'

export interface SideDrawerProps {
  children: ReactNode
  className?: string
  id: string
  animate?: boolean
  openOnLoad?: boolean // REF-8874
  openClasses?: string // extra fancy classes for use when drawer is open (REF-12657)
}

export function SideDrawer({
  children,
  className,
  id,
  animate = false,
  openOnLoad = false,
  openClasses = ''
}: SideDrawerProps) {
  const initialOpenDrawer = openOnLoad ? id : ''
  const { isDrawerOpen } = useSideDrawer({ initialOpenDrawer })
  return (
    <div
      className={clsx(
        twMerge(
          'fixed top-[72px] left-0 z-[3] ml-0 h-screen w-0 tl:sticky tl:top-0 tl:ml-0',
          isDrawerOpen(id) ? 'w-full sm:w-[400px]' : 'overflow-auto',
          className,
          isDrawerOpen(id) && openClasses
        ),
        animate && 'transition-all duration-300 ease-out'
      )}
    >
      {children}
    </div>
  )
}

interface SideDrawerContextProps {
  openedDrawerId: string
  filteredItemId?: string
  bookmarkId: string
  setOpenedDrawerId: Dispatch<SetStateAction<string>>
  setFilteredItemId: Dispatch<SetStateAction<string>>
  setBookmarkId: Dispatch<SetStateAction<string>>
  hoveredItemId?: string
  setHoveredItemId: Dispatch<SetStateAction<string>>
}

export const SideDrawerContext = createContext<SideDrawerContextProps>({
  openedDrawerId: '',
  filteredItemId: '',
  bookmarkId: '',
  setOpenedDrawerId: () => '',
  setFilteredItemId: () => '',
  setBookmarkId: () => '',
  hoveredItemId: '',
  setHoveredItemId: () => ''
})

interface SideDrawerProviderProps {
  children: ReactNode
  localStorageKey?: string
  localFilterKey?: string
  onSetIsOpen?: (isOpen: boolean) => void
  openDrawerId?: string
  filteredItemId?: string
}

export function SideDrawerProvider({
  children,
  localStorageKey = 'sideDrawer',
  localFilterKey = 'filteredItems',
  openDrawerId = '',
  filteredItemId = ''
}: SideDrawerProviderProps) {
  const [openedDrawerLocalStorageState, setOpenedLocalStorageDrawerState] =
    useLocalStorage(localStorageKey, openDrawerId)

  const setOpenedDrawerId: SideDrawerContextProps['setOpenedDrawerId'] = useCallback(
    (value) => setOpenedLocalStorageDrawerState(value),
    [setOpenedLocalStorageDrawerState]
  )

  // For filtering lists of items within these sidedrawers
  const [
    openedFilteredDrawerLocalStorageState,
    setOpenedFilteredDrawerLocalStorageState
  ] = useLocalStorage(localFilterKey, filteredItemId)

  const setFilteredItemId: SideDrawerContextProps['setFilteredItemId'] = useCallback(
    (value) => setOpenedFilteredDrawerLocalStorageState(value),
    [setOpenedFilteredDrawerLocalStorageState]
  )

  const [bookmarkId, setBookmarkId] = useState<string>('')
  const [hoveredItemId, setHoveredItemId] = useState<string>('')

  return (
    <SideDrawerContext.Provider
      value={{
        openedDrawerId: openedDrawerLocalStorageState,
        setOpenedDrawerId,
        filteredItemId: openedFilteredDrawerLocalStorageState,
        setFilteredItemId,
        bookmarkId,
        setBookmarkId,
        hoveredItemId,
        setHoveredItemId
      }}
    >
      {children}
    </SideDrawerContext.Provider>
  )
}

interface UseSideDrawerProps {
  initialOpenDrawer?: string
}

export function useSideDrawer(props?: UseSideDrawerProps) {
  const {
    openedDrawerId,
    setOpenedDrawerId,
    filteredItemId,
    setFilteredItemId,
    bookmarkId,
    setBookmarkId,
    hoveredItemId,
    setHoveredItemId
  } = useContext(SideDrawerContext)

  useEffect(() => {
    if (props && props?.initialOpenDrawer) setOpenedDrawerId(props.initialOpenDrawer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeDrawer = () => setOpenedDrawerId('')
  const openDrawer = (drawerId: string) => setOpenedDrawerId(drawerId)
  const isDrawerOpen = (drawerId: string) => drawerId === openedDrawerId
  const hasOpenDrawers = openedDrawerId !== ''
  const toggleDrawer = (drawerId: string) => {
    if (isDrawerOpen(drawerId)) {
      closeDrawer()
      return
    }
    openDrawer(drawerId)
  }

  const setActiveFilterId = (filteredItemId: string) => setFilteredItemId(filteredItemId)
  const setActiveBookmarkId = (id: string) => setBookmarkId(id)
  const unsetActiveBookmarkId = () => setBookmarkId('')

  return {
    hasOpenDrawers,
    filteredItemId,
    setActiveFilterId,
    isDrawerOpen,
    toggleDrawer,
    closeDrawer,
    openDrawer: (drawerId: string) => openDrawer(drawerId),
    bookmarkId,
    setActiveBookmarkId,
    unsetActiveBookmarkId,
    hoveredItemId,
    setHoveredItemId
  }
}
