import { SetStateAction, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { ReactComponent as ChevronDown } from 'thin-chevron-down.svg'
import { ReactComponent as ChevronUp } from 'thin-chevron-up.svg'

import CourseBlockContentBlock from 'domains/Course/CourseBlockContentBlock'
import CourseContentBlockArtifact from 'domains/Course/CourseContentBlockArtifact'
import CourseContentBlockUnit from 'domains/Course/CourseContentBlockUnit'
import { UnitComplexPortableText } from 'domains/Sanity/PortableText/UnitComplexPortableText/UnitComplexPortableText'

import RfHeader3 from 'components/typography/RfHeader/RfHeader3'
import RfParagraphSmallSemiBold from 'components/typography/RfParagraph/RfParagraphSmallSemiBold'

import {
  CourseDashboardCoursePartsFragment,
  CourseDetailCourseBlockPartsFragment,
  CourseOnDemandDashboardCourseBlockPartsFragment
} from 'gql'

import { useContentMode } from 'utils/contentTrackingUtils'
import { scrollToElementTop } from 'utils/scrollToElementTop'
import { trackExpandClick } from 'utils/tracking/analytics'

import { ReactComponent as VideoIcon } from 'images/icon--video.svg'

interface CourseOnDemandContentBlockProps {
  courseBlock: CourseOnDemandDashboardCourseBlockPartsFragment
  cclCourseBlock: CourseDetailCourseBlockPartsFragment
  course: CourseDashboardCoursePartsFragment
  index: number
}

const CourseOnDemandContentBlock = ({
  cclCourseBlock,
  courseBlock,
  course,
  index
}: CourseOnDemandContentBlockProps) => {
  const contentMode = useContentMode() || ''
  const [expanded, setExpanded] = useState(true)

  const setExpandedSync = (prev: SetStateAction<boolean>) => {
    if (prev && courseBlock?.id) {
      scrollToElementTop(courseBlock.id)
    }

    return !prev
  }

  const handleChevronClick = () => {
    if (!expanded) {
      trackExpandClick({
        location: 'course_dashboard',
        expandable_section_title: courseBlock.title || '',
        expandable_section_type: 'course_block',
        related_identifiers: {
          content_block_id: courseBlock.id || '',
          content_block_title: courseBlock.title || '',
          content_block_number: `${index}`,
          content_mode: contentMode,
          course_id: course.id,
          course_title: course.title,
          sanity_course_id: course.sanityId,
          content_type: 'course'
        }
      })
    }
    setExpanded(setExpandedSync)
  }

  const renderCourseBlockContent = () => {
    if (!cclCourseBlock.contentBlocks?.length) {
      return null
    }

    return (
      <div
        className={`mt-3 ${!cclCourseBlock.guides?.length && !cclCourseBlock.artifacts?.length ? 'mb-0' : 'mb-7'}`}
      >
        <div className="flex flex-col gap-8">
          {cclCourseBlock.contentBlocks.map((block, contentBlockIndex) => (
            <CourseBlockContentBlock
              key={contentBlockIndex}
              courseBlockIndex={index}
              contentBlock={block}
              courseSlug={course.slug}
              cclCourseBlock={cclCourseBlock}
              isOnDemandCourse
            />
          ))}
        </div>
      </div>
    )
  }

  return (
    <div
      data-test="course_content_block"
      id={`${courseBlock.id}`}
      className={twMerge(
        'w-full scroll-mt-[136px] rounded-xl border border-rb-gray-100 p-7.5 pb-0 transition-all duration-500 ease-in-out'
      )}
    >
      <RfHeader3>
        {cclCourseBlock.hasVideoContent && (
          <VideoIcon className="inline-block w-[22px] h-[22px] md:w-[24px] md:h-[24px] mr-3" />
        )}
        <span className="font-medium">{courseBlock.title}</span>
      </RfHeader3>
      {courseBlock.description && (
        <UnitComplexPortableText
          content={JSON.parse(courseBlock.description)}
          forcePhotoLoad
        />
      )}

      <div
        data-test="course_content_block_expanded_section"
        className={twMerge(
          'overflow-hidden transition-all duration-500 ease-in-out',
          expanded ? '' : 'max-h-0'
        )}
      >
        {renderCourseBlockContent()}
        {courseBlock?.units && courseBlock.units.length > 0 && (
          <div className="mt-3">
            {courseBlock?.units && courseBlock.units.length > 0 && (
              <RfParagraphSmallSemiBold className="mb-2">
                Step-by-step guides
              </RfParagraphSmallSemiBold>
            )}
            <div className="flex flex-col gap-8">
              {courseBlock?.units?.map((unit, index) => (
                <CourseContentBlockUnit
                  course={course}
                  courseBlock={courseBlock}
                  key={unit.id}
                  unit={unit}
                  courseBlockIndex={index}
                  index={index}
                  size={courseBlock.units?.length}
                />
              ))}
            </div>
          </div>
        )}

        {courseBlock?.artifacts && courseBlock.artifacts.length > 0 && (
          <div className="mt-7">
            <RfParagraphSmallSemiBold className="mb-2.5">
              Real-world examples
            </RfParagraphSmallSemiBold>

            <div className="mb-2 flex flex-col gap-8">
              {courseBlock.artifacts.map((artifact, index) => (
                <CourseContentBlockArtifact
                  index={index}
                  courseBlockIndex={index}
                  artifact={artifact}
                  key={artifact.id}
                  courseBlock={courseBlock}
                  course={course}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="mt-7">
        {expanded ? (
          <button
            data-test="course_content_block_chevron"
            onClick={handleChevronClick}
            className="flex w-full items-center justify-center pt-2 pb-7.5"
          >
            <ChevronUp className="h-[22px] w-[22px] text-rb-gray-200" />
          </button>
        ) : (
          <button
            data-test="course_content_block_chevron"
            onClick={handleChevronClick}
            className="flex w-full items-center justify-center pt-2 pb-7.5"
          >
            <ChevronDown className="h-[22px] w-[22px] text-rb-gray-200" />
          </button>
        )}
      </div>
    </div>
  )
}

export default CourseOnDemandContentBlock
