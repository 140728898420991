import EnvelopeImage from 'reforge_envelope.png'

import RfHeader2 from 'components/typography/RfHeader/RfHeader2'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import { typeEvent, typeString } from 'constants/types'

import { EventPartsFragment } from 'gql'

import { formatInTimezone, getTimezoneAbbreviation } from 'utils/date'

interface SeatReservedModalProps {
  event: EventPartsFragment
  timezone: string
}

export const SeatReservedModal = ({ event, timezone }: SeatReservedModalProps) => {
  const tzAbbrev = getTimezoneAbbreviation(timezone, event.startsAtUtc)

  return (
    <div
      id="modal-seat-reserved"
      className="uk-modal-container"
      uk-modal={'{"container": false}'}
    >
      <div className="uk-modal-dialog uk-modal-body uk-text-center lg:!max-w-4xl">
        <button className="uk-modal-close-default" type="button" uk-close="true" />

        <img src={EnvelopeImage} width="60px" className="inline-block" />
        <RfHeader2>See you there!</RfHeader2>
        <RfParagraphMedium>
          Your seat for <a uk-toggle="target: #modal-seat-reserved">{event.name}</a> is
          reserved.
          <br />
          {formatInTimezone(
            event.startsAtUtc,
            timezone,
            "EEEE - MMMM d, Y 'at' h:mmaaa - "
          )}
          {formatInTimezone(
            event.endsAtUtc,
            timezone,
            `h:mmaaa '${tzAbbrev && `(${tzAbbrev})`}'`
          )}
        </RfParagraphMedium>
        <RfParagraphMedium>
          We just sent a confirmation link and calendar invitation to you via email.
        </RfParagraphMedium>
      </div>
    </div>
  )
}

SeatReservedModal.propTypes = {
  event: typeEvent.isRequired,
  timezone: typeString
}
