import { useCohortConversationTrackingContext } from 'domains/CohortConversation/hooks/useCohortConversationsTrackingContext'

import { useUpdateCohortReplyMutation } from 'gql'

import { trackReplyAction } from 'utils/tracking/analytics'

interface CohortReplyUpdateProps {
  body: string
  replyId: string
  replyToId: string
  replyToType?: string
}

export const useUpdateCohortReply = () => {
  const [updateReplyMutation] = useUpdateCohortReplyMutation()
  const { cohort, topicId, groupId } = useCohortConversationTrackingContext()

  const updateCohortReply = async ({
    body,
    replyId,
    replyToId,
    replyToType = 'post'
  }: CohortReplyUpdateProps) => {
    if (replyId) {
      const response = await updateReplyMutation({
        variables: { input: { id: replyId, body } }
      })

      const responseReplyId = response?.data?.updateCohortReply?.id
      if (responseReplyId) {
        trackReplyAction({
          action: 'update',
          cms_program_id: cohort?.cmsProgram?.id,
          cohort_id: cohort?.id,
          group_ids: [groupId],
          reply_id: replyId,
          reply_to_id: replyToId,
          reply_to_type: replyToType,
          season_id: cohort?.season?.id,
          topic_ids: [topicId]
        })
      }
    }
  }

  return { updateCohortReply }
}

export default useUpdateCohortReply
