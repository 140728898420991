import { CommentableBlockContainer } from 'domains/Cms/CommentableBlocksApp'

import {
  CmsSectionContentType,
  CurrentUserPartsFragment,
  LessonViewerCmsSectionPartsFragment
} from 'gql'

import { mapNodes } from 'utils/mapNodes'

interface LessonViewerRightSideNavProps {
  currentUser: CurrentUserPartsFragment
  cmsSection: LessonViewerCmsSectionPartsFragment
  setActivePanel: (type: string) => void
}

const LessonViewerRightSideNav = ({
  currentUser,
  cmsSection,
  setActivePanel
}: LessonViewerRightSideNavProps) => {
  const groups = mapNodes(cmsSection.groups)
  const lastGroup = groups[groups.length - 1]

  return (
    <div
      id="right-sidenav"
      className="uk-overflow-auto absolute top-0 right-0 h-full w-0 overflow-x-hidden bg-rb-gray-50 opacity-0"
    >
      <CommentableBlockContainer
        cmsContentEndpoint={`/api/v1/cms_sections/${cmsSection.id}`}
        // lesson fallback to satisfy ts
        contentType={cmsSection.contentType || CmsSectionContentType.LESSON}
        isPremember={currentUser.is.premember}
        accessPolicyKind={currentUser.accessPolicyKind}
        hasApplied={currentUser.hasApplied}
        initialGroupId={lastGroup?.id}
        initialTopicId={cmsSection?.topic?.id}
        setActivePanel={setActivePanel}
        userId={currentUser.id}
        cmsContentId={cmsSection.id}
        cmsProgramId={cmsSection.cmsProgramId}
        cmsModuleId={cmsSection.cmsModuleId}
      />
    </div>
  )
}

export default LessonViewerRightSideNav
