import { ReactComponent as InfoIcon } from 'icon--info.svg'
import ReactTooltip from 'react-tooltip'

export const HeaderTooltip = ({ tooltip }: { tooltip: string }) => {
  return (
    <>
      <span
        className="top-2.5 right-2.5 pl-1 xl:static xl:ml-1"
        data-tip={tooltip}
        data-for={`team-metric-card-tip${tooltip.replace(' ', '-')}`}
      >
        <InfoIcon width={10} height={10} className="mb-1 inline" />
      </span>
      <ReactTooltip
        id={`team-metric-card-tip${tooltip.replace(' ', '-')}`}
        effect="solid"
        backgroundColor="#4D4D4D"
        className="w-64 font-normal"
        multiline={true}
      />
    </>
  )
}
