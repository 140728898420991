import { ReactComponent as PaperAndPencilIcon } from 'paper-and-pencil-icon.svg'
import { useEffect, useRef, useState } from 'react'

import { useGlobalChat } from 'domains/Ai/GlobalChatProvider'
import { useGlobalChatTracking } from 'domains/Ai/GlobalChatTrackingProvider'

import Button from 'components/Button'
import CalloutV2 from 'components/CalloutV2'

import {
  ProductTourKey,
  useCompleteProductTourMutation,
  useCompletedProductToursQuery
} from 'gql'

interface StartDraftFromButtonProps {
  artifact: any // @todo: define type
  showCallout?: boolean
}

const StartDraftFromButton = ({
  artifact,
  showCallout = false
}: StartDraftFromButtonProps) => {
  const { trackChatDraftStarted } = useGlobalChatTracking()
  const { data: productTourData } = useCompletedProductToursQuery()
  const [completeProductTour] = useCompleteProductTourMutation()
  const calloutHasBeenShown = useRef(false)
  const [isCalloutShown, setIsCalloutShown] = useState(false)
  const { openChatAndSendMessage, setMode, endSession, messages, chatId } =
    useGlobalChat()

  useEffect(() => {
    if (
      showCallout &&
      productTourData &&
      !productTourData.currentUser?.completedProductTours?.startDraftFromButton &&
      !calloutHasBeenShown.current
    ) {
      setTimeout(() => {
        setIsCalloutShown(true)
      }, 2000)
      calloutHasBeenShown.current = true
    }
    completeProductTour({
      variables: {
        input: {
          productTourKey: ProductTourKey.START_DRAFT_FROM_BUTTON
        }
      }
    })
  }, [showCallout, productTourData, completeProductTour])

  const handleClick = () => {
    const templateName = artifact.draftTemplateName
    const labelName = artifact.draftLabelName
    if (messages.length > 0) endSession()

    trackChatDraftStarted({
      chatId: chatId,
      templateName: templateName,
      location: 'artifact-navigation'
    })
    setMode({
      mode: 'document_generation',
      modeOptions: { command: 'draft', template_name: templateName, label: labelName }
    })
    openChatAndSendMessage(`Help me draft a ${labelName} document`, 'artifact_viewer', {
      mode: 'document_generation',
      modeOptions: { command: 'draft', template_name: templateName, label: labelName }
    })
  }

  return (
    <div className="relative">
      <Button
        color="teal"
        size="medium"
        className="h-10 w-[170px] cursor-pointer justify-center font-semibold"
        onClick={handleClick}
        iconAfter={<PaperAndPencilIcon />}
      >
        Create draft
      </Button>
      {isCalloutShown && (
        <CalloutV2
          position="bottomLeft"
          ctaAction={() => {
            setIsCalloutShown(false)
            handleClick()
          }}
          betaTag={true}
          ctaText="Try it now"
          title="Create a draft"
          bodyText="Turn select artifacts into your own draft in seconds"
        />
      )}
    </div>
  )
}

export default StartDraftFromButton
