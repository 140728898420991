import { CSSProperties, ImgHTMLAttributes, useContext } from 'react'
import { twMerge } from 'tailwind-merge'

import { ChromaticContext } from 'utils/chromatic'
import { isSanityUrl } from 'utils/sanity/sanityUtils'

import { buildSrc, buildSrcSet } from './lib/helpers'

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  id?: string
  src?: string
  alt?: string
  width?: number | string
  height?: number | string
  className?: string
  priority?: boolean
  style?: CSSProperties
  fill?: boolean
  srcSizes?: number[]
  sizes?: string
  quality?: number
  defaultWidth?: number // if provided, the image will be downsized to this width, preserving aspect ratio
  preventOptimization?: boolean
}

/**
 * When using this component with a Sanity URL, it is automatically optimized for the web using Sanity's image optimization tools.
 * srcSizes and sizes can be updated to match the design requirements for your specific use-case.
 */
const Image = ({
  id,
  src,
  alt,
  width,
  height,
  className,
  priority,
  fill,
  style,
  srcSizes = [400, 600, 800, 1000, 1200, 1500, 1800, 2200],
  sizes = '100vw',
  quality = 80,
  defaultWidth = 1000,
  preventOptimization = false
}: ImageProps) => {
  const { isChromatic } = useContext(ChromaticContext)

  if (!src) {
    return null
  }

  if (preventOptimization || isChromatic) {
    return (
      <ImgElement
        {...{ id, alt, width, height, className, priority, fill, style, src }}
      />
    )
  }

  const imageSrc = isSanityUrl(src)
    ? buildSrc(src, {
        width: defaultWidth,
        ...{ quality }
      })
    : src // getFileSystemImageUrl({ src, quality, width: defaultWidth })

  const imageSrcSet = isSanityUrl(src)
    ? buildSrcSet(src, {
        ...{ srcSizes },
        ...{ quality }
      })
    : undefined // buildFileSystemSrcSet(src, srcSizes)

  return (
    <ImgElement
      {...{
        id,
        alt,
        width,
        height,
        className,
        priority,
        fill,
        style,
        src: imageSrc,
        srcSet: imageSrcSet,
        sizes: imageSrcSet ? sizes : undefined
      }}
    />
  )
}

const ImgElement = ({
  src,
  alt,
  width,
  height,
  className,
  priority,
  fill,
  style,
  srcSet,
  sizes
}: ImageProps) => (
  <img
    src={src}
    srcSet={srcSet}
    sizes={sizes}
    alt={alt || ''}
    width={width}
    height={height}
    className={twMerge(
      fill && 'absolute top-0 bottom-0 left-0 right-0 h-full w-full',
      className
    )}
    // @ts-ignore
    // eslint-disable-next-line react/no-unknown-property
    fetchpriority={priority ? 'high' : null}
    style={style}
  />
)

/**
 * Below not currently used because the round trip to fetch the Redis cached images takes around 400ms per image resulting in a 300% avg increase in image load time
 * link to slack thread https://reforgehq.slack.com/archives/C03BT3MT7B6/p1708599519612019
 * Leaving these here as these functions and server-side logic (in images-controller.rb) could be useful for a future optimization where we build the images up-front and serve them from CloudFront instead of fetching from Redis/optimizing them on demand
 */

// const buildFileSystemSrcSet = (imageUrl?: string, srcSizes?: number[]) => {
//   const sizes = srcSizes?.map((width: number) => {
//     const imgSrc = getFileSystemImageUrl({
//       src: imageUrl,
//       width
//     })

//     return `${imgSrc} ${width}w`
//   })

//   return sizes?.join(',')
// }

// const getFileSystemImageUrl = ({
//   src,
//   quality = 80,
//   width
// }: {
//   src?: string
//   quality?: number
//   width?: number
// }) => {
//   if (!src) {
//     return ''
//   }

//   if (
//     src.endsWith('.svg') ||
//     src.endsWith('.gif') ||
//     src.startsWith('data:') ||
//     src.startsWith('http') ||
//     src.startsWith('https') ||
//     src.startsWith('//') ||
//     src.startsWith('blob') ||
//     src.startsWith('file') ||
//     src.startsWith('data:') ||
//     !src.startsWith('/assets/packs/static/')
//   ) {
//     return src
//   }

//   src = src.replace('/assets/packs/static/', '')
//   const ext = src.split('.').pop()

//   const srcParts = src.split('-')
//   src = srcParts.slice(0, -1).join('-')
//   const hash = srcParts.slice(-1)[0].replace(`.${ext}`, '')

//   const format = isWebpSupported() ? 'webp' : null

//   return `/images/src/${`${src}.${ext}`}?format=${format}&quality=${quality}&width=${width}&pixel_ratio=${
//     window.devicePixelRatio
//   }&hash=${hash}`
// }

// export const isWebpSupported = () => {
//   const w: Window & {
//     WEBP_SUPPORT?: boolean
//   } = window

//   try {
//     if (typeof w.WEBP_SUPPORT !== 'boolean' && !w.WEBP_SUPPORT) {
//       const elem = document.createElement('canvas')

//       if (elem.getContext && elem.getContext('2d')) {
//         // was able or not to get WebP representation
//         const support = elem.toDataURL('image/webp').indexOf('data:image/webp') === 0

//         w.WEBP_SUPPORT = support
//         return support
//       } else {
//         // very old browser like IE 8, canvas not supported
//         w.WEBP_SUPPORT = false
//         return false
//       }
//     } else {
//       return w.WEBP_SUPPORT
//     }
//   } catch (e) {
//     return false
//   }
// }

export default Image
