import ReactTooltip from 'react-tooltip'

import { SVGIcon } from 'components/Icon'

import { getCurrentTimezone } from 'utils/date'
import { isEventInSession } from 'utils/eventUtils'

import { AttendButtonProps } from '../types'
import JoinCTA from './JoinCta'

const EventRsvpButton = ({
  event,
  timezone,
  attendingStatus,
  dataTestId,
  rsvpCancelHandler,
  rsvpConfirmHandler,
  buttonSize = 'small',
  disable = false
}: AttendButtonProps) => {
  const eventNoRsvp = !attendingStatus
  const eventAttending = attendingStatus === 'Attending'
  const currentTimezone = getCurrentTimezone(timezone)
  const eventIsInSession = isEventInSession(event.startsAtUtc, event.endsAtUtc)
  const hasPendingRecording =
    event.past && event.willBeRecorded && !event.summaryWistiaCodeOptional

  return (
    <>
      <JoinCTA
        dataTestId={dataTestId}
        event={event}
        eventAttending={eventAttending}
        eventNoRsvp={eventNoRsvp}
        timezone={currentTimezone}
        eventIsInSession={eventIsInSession}
        rsvpYes={rsvpConfirmHandler}
        rsvpCancel={rsvpCancelHandler}
        buttonSize={buttonSize}
        disable={disable}
      />
      {hasPendingRecording && (
        <>
          <ReactTooltip
            effect="solid"
            backgroundColor="#4D4D4D"
            multiline={true}
            id={`pending-recording-${event.id}`}
          >
            A recording of this session will be <br /> added within 72 hours of the event
          </ReactTooltip>
          <span
            data-for={`pending-recording-${event.id}`}
            data-tip="View all live program participants"
          >
            <SVGIcon name="three-dots" width="20" height="20" />
          </span>
        </>
      )}
    </>
  )
}

export default EventRsvpButton
