import React, { ReactNode } from 'react'

import Button, { ButtonColors } from 'components/Button'

import { cn } from 'utils/tailwind'

interface ContentGateProps {
  title: string | ReactNode
  description: string
  ctaText: string
  ctaHref: string
  onClick: () => void
  divClassName?: string
  buttonClassName?: string
  buttonColor?: ButtonColors
  IconComponent?: React.ComponentType<{ className: string }>
  children?: React.ReactNode
  descriptionClassName?: string
}

export const ContentGate = ({
  title,
  description,
  ctaText,
  ctaHref,
  onClick,
  divClassName,
  buttonClassName,
  buttonColor,
  IconComponent,
  children,
  descriptionClassName
}: ContentGateProps) => {
  return (
    <div
      className={cn(
        'flex flex-col items-center border border-rb-gray-100 bg-rb-white py-10 px-6 text-center md:px-[109px]',
        divClassName
      )}
    >
      <p className="!mb-4 !text-xl !font-medium !leading-[1.4] md:!text-[28px] md:!leading-[1.2]">
        {title}
      </p>

      <p className={cn('text-base leading-[1.6]', descriptionClassName)}>{description}</p>

      <Button
        size="medium"
        href={ctaHref}
        onClick={onClick}
        className={buttonClassName}
        color={buttonColor}
      >
        {ctaText}
        {IconComponent && (
          <IconComponent className="ml-2 h-4 w-4 text-rb-white shrink-0" />
        )}
      </Button>

      {children}
    </div>
  )
}

export default ContentGate
