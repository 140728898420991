import { NavLink } from 'react-router-dom'
import UIkit from 'uikit'

import FacePileButtonContainer from 'domains/Member/FacePileButtonContainer'

interface CohortViewerTabsProps {
  rootRoute: string
  slug: string
  setIsMemberModalOpen: (isMemberModalOpen: boolean) => void
  showHightlightsTab: boolean
}

const determineStartingActiveTab = (url: string) => {
  if (url.includes('material')) {
    return 'material'
  }
  if (url.includes('bookmarks')) {
    return 'bookmarks'
  }
  if (url.includes('overview')) {
    return 'overview'
  }
  if (url.includes('highlights')) {
    return 'highlights'
  }
}

const CohortViewerTabs = ({
  rootRoute,
  setIsMemberModalOpen,
  slug,
  showHightlightsTab
}: CohortViewerTabsProps) => {
  const setUIKitTab = (index: number) => {
    const cohortViewer = document.querySelector('#cohort-viewer')
    if (cohortViewer) {
      cohortViewer.scrollTo({ top: 0, left: 0 })
    }

    UIkit.tab('#cohort-viewer-tabs').show(index)
  }

  const activeTab = determineStartingActiveTab(window.location.href)

  return (
    <div
      id="cohort-viewer-tabs-container"
      className="px-3 pt-3 pb-4 xs:flex xs:items-center xs:px-8 xs:pt-6 xs:pb-7 lg:px-10"
    >
      <ul id="cohort-viewer-tabs" uk-tab="">
        <li
          id="cohort-viewer-tabs--overview"
          className={activeTab === 'overview' ? 'uk-active' : ''}
        >
          <a className="uk-hidden" />
          <NavLink onClick={() => setUIKitTab(0)} to={rootRoute}>
            <span className="uk-visible">Overview</span>
          </NavLink>
        </li>
        <li className={activeTab === 'material' ? 'uk-active' : ''}>
          <a className="uk-hidden" />
          <NavLink onClick={() => setUIKitTab(1)} to={`${rootRoute}/material`}>
            <span className="uk-visible">Material</span>
          </NavLink>
        </li>
        <li className={activeTab === 'bookmarks' ? 'uk-active' : ''}>
          <a className="uk-hidden" />
          <NavLink onClick={() => setUIKitTab(2)} to={`${rootRoute}/bookmarks`}>
            <span className="uk-visible">Bookmarks</span>
          </NavLink>
        </li>
        {showHightlightsTab && (
          <li
            data-test="cohort-viewer-highlights-tab"
            className={activeTab === 'highlights' ? 'uk-active' : ''}
          >
            <a className="uk-hidden" />
            <NavLink onClick={() => setUIKitTab(3)} to={`${rootRoute}/highlights`}>
              <span className="uk-visible">Highlights</span>
            </NavLink>
          </li>
        )}
      </ul>

      <div className="mt-4 hidden xs:mt-0 xs:ml-auto sm:flex">
        <FacePileButtonContainer
          slug={slug}
          onClick={() => {
            setIsMemberModalOpen(true)
          }}
        />
      </div>
    </div>
  )
}

export default CohortViewerTabs
