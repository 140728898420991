import CaseStudyCard from 'domains/Sanity/marketingSite/CaseStudyCard'
import MarketingHero from 'domains/Sanity/marketingSite/MarketingHero'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { Loading } from 'components'
import ErrorMessage from 'components/ErrorMessage'

import { CaseStudiesIndex, CaseStudy, useCaseStudiesIndexQuery } from 'gql'

import AngleGreenBlue from 'images/Angle_GreenBlue.png'
import CaseStudiesHeader from 'images/CaseStudiesHeader.png'

const CaseStudiesIndexPage = () => {
  const { data, loading } = useCaseStudiesIndexQuery()
  const { page, caseStudies } = (data?.caseStudies || {}) as CaseStudiesIndex

  if (!loading && !caseStudies) {
    return (
      <ErrorMessage
        error={'There was a problem loading the page. Please refresh to try again.'}
      />
    )
  }

  return (
    <MarketingLayout page={page}>
      {loading ? <Loading /> : <CaseStudiesIndexPageContent caseStudies={caseStudies} />}
    </MarketingLayout>
  )
}

export const CaseStudiesIndexPageContent = ({
  caseStudies
}: {
  caseStudies: CaseStudy[]
}) => {
  return (
    <>
      <MarketingHero
        headerText="Case Studies"
        bgImage={{
          url: AngleGreenBlue,
          alt: ''
        }}
        heroImage={{
          url: CaseStudiesHeader,
          alt: ''
        }}
        description="How Reforge powers change at some of the most exciting companies in tech."
        ctas={[
          {
            text: 'Explore Reforge for Teams ↗',
            href: '/teams'
          }
        ]}
        noCtaMargin
        contentClassName="pb-[10vw] md:pb-[10vw]"
      />

      <ul className="grid list-none grid-cols-1 items-stretch gap-[20px] p-0 px-[16px] pt-[1vmax] pb-[6vmax] sm:grid-cols-2 md:grid-cols-3 md:px-[4vw] lgr:grid-cols-4">
        {caseStudies.map((caseStudy) => (
          <CaseStudyCard key={caseStudy.id} {...caseStudy} />
        ))}
      </ul>
    </>
  )
}

export default CaseStudiesIndexPage
