import PolicyLink from 'domains/AntiPiracy/PolicyLink'

import Checkbox from 'components/forms/Checkbox'

const PolicyLinkCheckbox = () => (
  <Checkbox
    name="violationConfirmPolicy"
    label={
      <>
        <span>I understand and agree to the</span> <PolicyLink />
      </>
    }
  />
)

export default PolicyLinkCheckbox
