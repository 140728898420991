import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { isPathActive } from 'components/LeftSidebar/NavigationTab'
import Tooltip from 'components/Tooltip/Tooltip'
import RfParagraphSmallSemiBold from 'components/typography/RfParagraph/RfParagraphSmallSemiBold'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import { MAX_WIDTH_TAILWIND_TL } from 'constants/breakpoints'

import { CourseLinkUserCoursePartsFragment } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { buildCourseSessionLink } from 'utils/courseUtils'
import { trackNavigationClicked } from 'utils/tracking/analytics'

import { ReactComponent as MonitorPerson } from 'images/monitor_person.svg'

import { useLeftSidebar } from '..//useLeftSidebar'

interface CourseLinkProps {
  isCollapsed: boolean
  userCourse: CourseLinkUserCoursePartsFragment
}

const CourseLink = ({ userCourse, isCollapsed }: CourseLinkProps) => {
  const { setIsLeftSidebarOpen } = useLeftSidebar()
  const [isHovered, setIsHovered] = useState(false)
  const isToggled = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_TL})`)

  const { pathname } = useLocation()

  const course = userCourse.course
  const courseSession = userCourse.courseSession

  const trackLinkClick = () => {
    trackNavigationClicked({
      location: 'sidebar_navigation',
      type: 'sidebar link',
      destination,
      text: `My Course - ${course.title}`
    })
  }

  const destination = buildCourseSessionLink({
    courseSlug: course.slug,
    sessionId: courseSession.id
  })

  const isActive = isPathActive({
    pathname,
    path: destination
  })

  const handleLinkClick = () => {
    setIsLeftSidebarOpen(false)
    trackLinkClick()
  }

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <div className="relative flex flex-col" data-testid={`course-link-${course.id}`}>
      <Tooltip
        disable={!isCollapsed}
        offset={{ right: 10 }}
        backgroundColor="#080A0A"
        className="rounded-lg"
        tooltipBody={<div className="">{course.title}</div>}
        place="right"
        contentWrapperClassname={twMerge(
          'text-rb-gray-300 hover:text-rb-gray-300 flex items-center px-2 lg:pl-0 lg:pr-1 h-[40px]'
        )}
      >
        <Link
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={
            isHovered && !isCollapsed && course.title.length > 20 ? { width: '100%' } : {}
          }
          to={destination}
          className={twMerge(
            'flex h-full items-center overflow-x-hidden rounded text-base text-rb-gray-400 hover:bg-rb-gray-100 hover:text-rb-gray-400 hover:no-underline',
            isCollapsed ? 'w-[60px]' : 'w-[208px]',
            isActive ? 'bg-rb-green/60 hover:bg-rb-green/60' : '',
            isToggled ? 'px-2' : ''
          )}
          onClick={handleLinkClick}
        >
          <span
            className={twMerge(
              'flex-shrink-0 lg:transition-[margin] lg:duration-[400ms] lg:ease-in-out',
              isCollapsed ? 'ml-5' : 'lg:ml-[20px]'
            )}
          >
            <MonitorPerson className="h-5 w-5" />
          </span>
          {!isCollapsed && (
            <span
              className={twMerge(
                'opacity ml-4 w-full pr-1 capitalize text-rb-gray-400 transition duration-500 ease-in-out',
                !isHovered ? 'truncate' : ''
              )}
            >
              {course.title}
            </span>
          )}
        </Link>
      </Tooltip>
      <div
        className={twMerge(
          'flex items-center gap-2 pt-1 self-center',
          isCollapsed ? 'w-fit' : 'w-[208px] pl-2'
        )}
      >
        <RfParagraphSmallSemiBold color={ColorOptions.gray400}>
          {course.completionPercentage || 0}%
        </RfParagraphSmallSemiBold>
        {!isCollapsed && (
          <div className="w-full bg-rb-gray-50 rounded h-[5px] overflow-hidden mr-4">
            <div
              className={twMerge(
                'bg-rb-gray-400 h-full',
                course.completionPercentage === 100 && 'rounded-r-full'
              )}
              style={{ width: `${course.completionPercentage}%` }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default CourseLink
