import { publicApolloClient } from 'apolloClient'

import { SnapshotLandingPage } from 'pages/SnapshotLandingPage/SnapshotLandingPage'

import { ErrorMessage, Loading } from 'components'

import { useSnapshotLandingPageQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

const SnapshotLandingPageContainer = () => {
  const { currentUser } = useCurrentUser()
  const [, snapshotType, token] = window.location.pathname.split('/')
  const { data, loading, error } = useSnapshotLandingPageQuery({
    variables: { snapshotType, token },
    client: publicApolloClient
  })
  if (loading) return <Loading />
  if (error) return <ErrorMessage error={error} />

  if (!data || !data.snapshotLandingPage) {
    return (
      <ErrorMessage error="Sorry, there was an issue loading the snapshot page. Please refresh and try again." />
    )
  }

  return (
    <SnapshotLandingPage
      isLoggedIn={!!currentUser}
      accessPolicyKind={currentUser?.accessPolicyKind}
      data={data.snapshotLandingPage}
    />
  )
}

export default SnapshotLandingPageContainer
