import React from 'react'
import { twMerge } from 'tailwind-merge'

interface ContentCardContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  containerClassName?: string
}

const ContentCardContainer = ({
  children,
  containerClassName,
  ...others
}: ContentCardContainerProps) => (
  <div
    tabIndex={0}
    role="button"
    className={twMerge(
      'rf-rb-card-interactive flex h-full max-h-[500px] w-full flex-col p-5',
      containerClassName
    )}
    {...others}
  >
    {children}
  </div>
)

export default ContentCardContainer
