import { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import { ErrorMessage, Loading } from 'components'

import { useCancelRsvpMutation } from 'gql'

const CancelRsvp = () => {
  const { id } = useParams<{ id: string }>()
  const [cancelRsvp, { data, loading }] = useCancelRsvpMutation()

  useEffect(() => {
    cancelRsvp({ variables: { input: { id: id.toString() } } })
  }, [cancelRsvp, id])

  if (loading) {
    return <Loading />
  }

  if (!data) {
    return <ErrorMessage error={'Data for this page is null'} />
  }

  if (data.cancelRsvp?.errors?.length) {
    return <ErrorMessage error={data.cancelRsvp.errors[0]} />
  }

  return (
    <div className="mt-10 text-center">
      <h1>You are no longer RSVP&apos;d to this event</h1>

      <Link
        to="/events"
        className={`flex h-10 items-center bg-rb-blue-100
          px-7 font-medium uppercase tracking-wider text-white
          no-underline hover:bg-rb-blue-100 hover:text-white hover:no-underline`}
      >
        See other events
      </Link>
    </div>
  )
}

export default CancelRsvp
