import { cn } from '@/lib/utils'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { GoalSessionData, GoalStep } from 'pages/coaching/type'

import { useGlobalChat } from 'domains/Ai/GlobalChatProvider'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'

import { useUpdateCoachingSessionMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { sentenceCase } from 'utils/stringUtils'
import { trackAiChatSessionOpened } from 'utils/tracking/analytics'

import { ArrowRightIcon } from '../../../../components/icons/ArrowRightIcon'
import { DocumentIcon } from '../../../../components/icons/DocumentIcon'
import { ExpertSource } from './ExpertSource'

type TodoProps = {
  data: {
    sessionId: number
    stepIndex: number
    step: GoalStep
    activeSession: GoalSessionData
    refetchSessions: () => void
  }
}

export const Todo = ({ data }: TodoProps) => {
  const { sessionId, step, stepIndex, refetchSessions, activeSession } = data
  const [checked, setChecked] = useState(!!step?.checked)
  const [updateSession] = useUpdateCoachingSessionMutation()
  const { pathname } = useLocation()
  const { currentUser } = useCurrentUser()

  const {
    openChatAndSendMessage,
    setIsChatOpen,
    setMode,
    setIsExpanded,
    setChatId,
    setMenuSideBarIsOpen
  } = useGlobalChat()

  const UncheckedBox = (
    <SVGIcon name="checkbox-empty" fill="#d3d2d3" width="20" height="20" />
  )
  const CheckedBox = (
    <SVGIcon name="checkbox-ticked" fill="#080A0A" width="20" height="20" />
  )

  const toggleCheckbox = async (index: number) => {
    const value = !checked

    setChecked(value)

    const payload = JSON.stringify({
      setChecked: {
        value,
        index
      }
    })

    await updateSession({
      variables: {
        input: {
          id: activeSession.id,
          data: payload
        }
      }
    })
  }

  const handleDraftClick = () => {
    setMode({
      mode: 'coaching',
      modeOptions: {
        sessionId,
        stepIndex
      }
    })
    setIsExpanded(true)
    setMenuSideBarIsOpen(true)
    openChatAndSendMessage(`Draft: ${step.document}`, window.location.href)
    trackAiChatSessionOpened({
      mode: 'coaching',
      chat_session_type: 'new',
      access_policy_kind: currentUser?.accessPolicyKind,
      chat_session_id: '',
      is_draft: true,
      is_suggested_prompt: true,
      path: pathname,
      location: 'coaching',
      text: 'draft this for me'
    })
    setTimeout(() => {
      refetchSessions()
    }, 3000)
  }

  const handleViewDraft = (id: string) => {
    setChatId(id)
    setIsExpanded(true)
    setMenuSideBarIsOpen(true)
    setIsChatOpen(true)

    trackAiChatSessionOpened({
      mode: 'coaching',
      chat_session_type: 'existing',
      access_policy_kind: currentUser?.accessPolicyKind,
      chat_session_id: id,
      is_draft: false,
      is_suggested_prompt: true,
      path: pathname,
      location: 'coaching',
      text: 'view my draft'
    })
  }

  return (
    <div className="font-sans rounded-xl p-6 border bg-white border-rb-gray-100 w-full">
      <div
        className={cn(
          'text-rb-gray-400 font-semibold flex items-center',
          checked && 'text-[#B5B8B4]'
        )}
      >
        <div className="flex items-center">
          <div
            className="flex cursor-pointer items-center"
            role="checkbox"
            tabIndex={0}
            aria-checked={checked}
            onClick={() => toggleCheckbox(stepIndex)}
          >
            <span className="mr-2.5">{checked ? CheckedBox : UncheckedBox}</span>
          </div>
          {step.step}
        </div>
      </div>
      {!checked && (
        <div className="pl-7">
          <div className="text-rb-gray-400 text-sm font-normal mt-1">
            {sentenceCase(step.action)}
          </div>

          <div className="my-4 p-3 pl-6 flex items-center justify-between rounded-xl border border-rb-gray-100">
            <div className="flex items-center flex-1">
              <DocumentIcon />
              <div className="flex flex-col ml-3">
                <div className="text-sm font-semibold text-rb-gray-400">
                  {sentenceCase(step.document)}
                </div>
                <div className="text-rb-gray-300 text-xs">{step.documentDescription}</div>
              </div>
            </div>
            {step.draftId && (
              <div
                onClick={() => handleViewDraft(step.draftId!)}
                className="cursor-pointer w-[185px] text-sm font-semibold flex justify-center items-center text-rb-teal-600"
              >
                View my draft
                <ArrowRightIcon className="ml-3" fill={'#1A6C69'} />
              </div>
            )}
            {!step.draftId && (
              <Button
                className="w-[185px] rounded-2 py-3 ml-3 px-6 border-rb-gray-300 font-sans font-normal"
                variant="outline"
                iconAfter={<ArrowRightIcon />}
                onClick={handleDraftClick}
              >
                Draft for me
              </Button>
            )}
          </div>

          <ExpertSource session={activeSession} />
        </div>
      )}
    </div>
  )
}
