import Pluralize from 'react-pluralize'
import { twMerge } from 'tailwind-merge'

import OverallLessonProgressInWords from 'components/OverallLessonProgress/OverallLessonProgressInWords'
import Progress from 'components/Progress'
import ColorCircleCheckIcon from 'components/icons/ColorCircleCheckIcon'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'

import { CohortCmsSectionTagPartsFragment } from 'gql'

import { TAGS } from 'utils/lessonUtils'

export interface OverallLessonProgressProps {
  overallProgressPercent: number
  totalLessons: number
  completeLessonCount: number
  totalEstimatedTime: number
  uniqueId: string
  hideCompletedCheck?: boolean
  tags?: CohortCmsSectionTagPartsFragment[]
  includeEstimatedTime?: boolean
  includeTags?: boolean
  checkClassName?: string
  progressBarClassName?: string
  wordsSize?: 'mini' | 'small'
}

const OverallLessonProgress = ({
  overallProgressPercent,
  totalLessons,
  completeLessonCount,
  totalEstimatedTime,
  uniqueId,
  hideCompletedCheck = false,
  tags,
  includeEstimatedTime = true,
  includeTags = true,
  checkClassName,
  progressBarClassName,
  wordsSize = 'mini'
}: OverallLessonProgressProps) => {
  const showProgressBar =
    completeLessonCount !== 0 && completeLessonCount !== totalLessons
  const showFinishedCheck = !hideCompletedCheck && completeLessonCount === totalLessons

  return (
    <div className="flex flex-row items-center">
      {showFinishedCheck && (
        <ColorCircleCheckIcon
          name="color-circle-check"
          className={twMerge('mr-2 h-[14px] w-[14px]', checkClassName)}
        />
      )}
      <OverallLessonProgressInWords
        totalCompleteLessons={completeLessonCount}
        totalLessons={totalLessons}
        totalEstimatedTime={totalEstimatedTime}
        totalProgressPercent={overallProgressPercent}
        includeEstimatedTime={includeEstimatedTime}
        wordsSize={wordsSize}
      />
      {showProgressBar && (
        <div
          className={twMerge('h-1 w-20', progressBarClassName)}
          data-testid={`progress-bar-${uniqueId}`}
        >
          <Progress
            classNames="h-full w-full"
            progressBarClasses={{
              progress: 'rounded-l-xl',
              background: 'rounded-r-xl'
            }}
            percentComplete={overallProgressPercent as number}
            backgroundClass="bg-rb-gray-100"
          />
        </div>
      )}
      {includeTags &&
        Object.keys(TAGS).map((tag, index) => {
          const filteredTags = tags && tags.filter((obj) => obj.tag === tag)
          if (filteredTags && filteredTags.length > 0) {
            return (
              <div
                key={index}
                className={twMerge(
                  TAGS[tag].tagColor,
                  'ml-2.5 w-fit rounded  px-2 py-0.5'
                )}
              >
                <RfParagraphMini color={TAGS[tag].textColor}>
                  <Pluralize singular={tag} count={filteredTags.length} showCount />
                </RfParagraphMini>
              </div>
            )
          }
        })}
    </div>
  )
}

export default OverallLessonProgress
