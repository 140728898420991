import BulkRsvpChecklistCard from 'domains/CohortDashboard/CohortDashboardProgram/CohortDashboardWeeklyBlock/WeeklyBlockChecklist/BulkRsvpChecklistCard'
import EventChecklistCard from 'domains/CohortDashboard/CohortDashboardProgram/CohortDashboardWeeklyBlock/WeeklyBlockChecklist/EventChecklistCard'
import MaterialChecklistCard from 'domains/CohortDashboard/CohortDashboardProgram/CohortDashboardWeeklyBlock/WeeklyBlockChecklist/MaterialChecklistCard'
import { CohortEvent } from 'domains/CohortViewer/utils'

import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'

import {
  CohortDashboardScheduledWeekPartsFragment,
  CohortDashboardUserProgramPartsFragment
} from 'gql'

export interface WeeklyBlockChecklistNumberedWeekProps {
  bulkRsvpChecked?: boolean
  handleRsvpClick: () => void
  events: CohortEvent[]
  cohortSlug: string
  timezone: string
  scheduledWeek: CohortDashboardScheduledWeekPartsFragment
  activeProgram: CohortDashboardUserProgramPartsFragment
  scrollToSectionWithRelevantLesson: () => any
}

export const WeeklyBlockChecklistNumberedWeek = ({
  handleRsvpClick,
  bulkRsvpChecked = false,
  events,
  cohortSlug,
  timezone,
  activeProgram,
  scheduledWeek,
  scrollToSectionWithRelevantLesson
}: WeeklyBlockChecklistNumberedWeekProps) => {
  const caseStudyEvent = events[0]
  const askAnExpertEvent = events[1]

  return (
    <div>
      <div className="mb-8">
        <RfHeader3SemiBold className="py-4">This week&apos;s checklist</RfHeader3SemiBold>
        <div data-testid="material-checklist-card">
          <MaterialChecklistCard
            activeProgram={activeProgram}
            scheduledWeek={scheduledWeek}
            scrollToSectionWithRelevantLesson={scrollToSectionWithRelevantLesson}
          />
        </div>
        {bulkRsvpChecked ? (
          <div data-testid="event-checklist-card">
            <EventChecklistCard
              timezone={timezone}
              event={caseStudyEvent}
              cohortSlug={cohortSlug}
            />
            {askAnExpertEvent && (
              <EventChecklistCard
                cohortSlug={cohortSlug}
                eventTypeText="Ask an Expert"
                event={askAnExpertEvent}
                timezone={timezone}
              />
            )}
          </div>
        ) : (
          <div data-testid="bulk-rsvp-checklist-card">
            <BulkRsvpChecklistCard
              bulkRsvpChecked={bulkRsvpChecked}
              handleRsvpClick={handleRsvpClick}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default WeeklyBlockChecklistNumberedWeek
