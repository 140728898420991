import { useHistory } from 'react-router-dom'

import Button from 'components/Button'
import { Modal, ModalContent } from 'components/Modal'

import ConfettiAnimationWithLoader from './components/ConfettiAnimationWithLoader'
import { useCelebratoryText, useShouldModalOpen } from './helpers'

interface ModalCelebrationProps {
  cmsModuleId: string
  cmsSectionId: string
  isProgramComplete?: boolean
  getOnComplete: (onComplete: () => void) => void
  moduleName?: string
  programName?: string
  programPercentComplete?: number
  programSlug?: string
  nextSectionDetails?: string[]
  programLaunched: boolean
  releaseDate?: string | null
  cohortViewer?: boolean
  cohortSlug?: string
}

const ModalCelebration = ({
  // cmsModuleId,
  cmsSectionId,
  getOnComplete,
  moduleName,
  programName,
  programSlug,
  nextSectionDetails = [],
  programLaunched,
  releaseDate,
  cohortViewer = false,
  cohortSlug = ''
}: ModalCelebrationProps) => {
  const history = useHistory()
  const { closeModal, isModalOpen, programPercentComplete } = useShouldModalOpen({
    cmsSectionId,
    getOnComplete
  })

  const { celebrationTextPrimary, celebrationTextSecondary } = useCelebratoryText({
    moduleName,
    programName,
    programPercentComplete
  })

  // Temporarily disabling due to incorrect behavior described in this ticket: https://reforge.atlassian.net/browse/REF-6746
  // const { data: facepileData } = useUserCohortUsersQuery({
  //   variables: {
  //     cmsSectionId: `${cmsSectionId}`,
  //     limit: 3,
  //     random: true,
  //     onlyWithAvatar: true,
  //     includeCurrentUser: false
  //   }
  // })

  // const { data: countData } = useCompletedModuleUsersCountQuery({
  //   variables: {
  //     cmsModuleId: `${cmsModuleId}`
  //   }
  // })

  const hasNextLessonData = !!nextSectionDetails.length
  const [linkText, nextLessonPath] = nextSectionDetails
  const hasNextLesson = linkText !== 'Return to Dashboard'

  const handleContinue = () => {
    if (cohortViewer && cohortSlug) {
      history.push(`/cohorts/${cohortSlug}${nextLessonPath}`)
    } else {
      history.push(nextLessonPath)
    }
  }

  return (
    <>
      <Modal isOpen={isModalOpen} fullHeight={false} handleClose={closeModal}>
        <ModalContent>
          {!!programPercentComplete && (
            <ConfettiAnimationWithLoader percentComplete={programPercentComplete} />
          )}
          <div className="mb-4 text-center">
            <div className="text-2xl font-bold leading-8 ">{celebrationTextPrimary}</div>
            <div className="leading-6">
              {programLaunched
                ? celebrationTextSecondary
                : `New material will be published on ${releaseDate}. Keep it up!`}
            </div>
          </div>
          {hasNextLessonData && (
            <div className="mb-16 animate-fadeIn items-center justify-center gap-4 xs:flex">
              <a href={`/programs/${programSlug || ''}`} className="mb-2 block xs:mb-0">
                <Button
                  {...(hasNextLesson
                    ? { className: 'text-rb-gray-500', variant: 'outline' }
                    : {})}
                  fullWidth
                >
                  VIEW PROGRAM OVERVIEW
                </Button>
              </a>
              {hasNextLesson && (
                <div>
                  <Button fullWidth onClick={handleContinue}>
                    CONTINUE
                  </Button>
                </div>
              )}
            </div>
          )}
          {/* {!!countData?.completedModuleUsersCount && (
            <hr className="mx-4 mt-8 border-1.5 border-rb-gray-100" />
          )} */}
        </ModalContent>
        {/* Temporarily disabling due to incorrect behavior described in this ticket: https://reforge.atlassian.net/browse/REF-6746 */}

        {/* <ModalFooter>
          {countData && facepileData && programLaunched && (
            <SocialFooter
              count={countData.completedModuleUsersCount as number}
              users={(facepileData.userCohortUsers || []) as FacePileUserData[]}
            />
          )}
        </ModalFooter> */}
      </Modal>
    </>
  )
}

export default ModalCelebration
