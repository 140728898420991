import { useHistory } from 'react-router-dom'

import { SubscriptionManagementCard } from 'domains/Subscription/SubscriptionCard/SubscriptionManagementCards'

import { getPrettyFullDate } from 'utils/date'
import { trackCtaClicked } from 'utils/tracking/analytics'

export interface LegacyPlanCardProps {
  numTotalSeats: number
  renewalPriceCopy: string
  isSubscriptionOwner: boolean
  expiresAt: string
  isCanceled: boolean
}

export const LegacyPlanCard = ({
  numTotalSeats,
  renewalPriceCopy,
  isSubscriptionOwner,
  expiresAt,
  isCanceled
}: LegacyPlanCardProps) => {
  const history = useHistory()
  let note
  let primaryButton

  const handleClickManageTeamButton = () => {
    trackCtaClicked({
      cta_location: 'subscription_card',
      cta_type: 'button',
      text: 'manage team',
      destination: '/team_members'
    })

    history.push('/manage-plan')
  }

  const secondaryButton = {
    copy: 'Manage Team',
    onclick: handleClickManageTeamButton
  }

  const handleClickManagePlanButton = () => {
    trackCtaClicked({
      cta_location: 'subscription_card',
      cta_type: 'button',
      text: 'manage plan',
      destination: '/manage-plan'
    })
    history.push('/manage-plan')
  }

  if (isCanceled) {
    note = `Expires on ${getPrettyFullDate(expiresAt)}`
    primaryButton = {
      copy: 'Manage Plan',
      onclick: handleClickManagePlanButton
    }
  }

  if (numTotalSeats === 1 || isSubscriptionOwner) {
    primaryButton = {
      copy: 'Manage Plan',
      onclick: handleClickManagePlanButton
    }
  }

  return (
    <SubscriptionManagementCard
      dataTestId="legacy-plan-card"
      topBarColorClass="rb-green-50"
      heading="Current Plan"
      note={note}
      noteColor="red"
      contentLeft={renewalPriceCopy}
      contentRight={`${numTotalSeats} Seat${numTotalSeats > 1 ? 's' : ''}`}
      primaryButton={isSubscriptionOwner ? primaryButton : undefined}
      secondaryButton={isSubscriptionOwner ? secondaryButton : undefined}
      isLegacyPlan={true}
    />
  )
}
