import { closing, opening } from './sharedMarkdown'

const programName = 'Engineering Management'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://www.reforge.com/engineering-management), because I believe it will help me become an effective engineering leader who reliably delivers measurable business impact. This course was built with [Nick Caldwell](https://www.reforge.com/experts/nick-caldwell) and [Heidi Williams](https://www.reforge.com/experts/heidi-williams), and covers:
* **Managing Engineering Work.** Install an engineering production loop to improve your team's functioning over time. Learn how to evaluate and prioritize requests based on impact and team fit.
* **Managing Stakeholders.** Manage stakeholders more effectively. Learn how to build collaboration specs to align and partner with key stakeholders.
* **Managing Your Team.** Learn how to take a longer-term view of talent, develop your team effectively, and build team trust.
* **Managing Yourself.** Examine your own career development and define long-term success for yourself. 

&NewLine;

${closing}
`

export default { markdown, title: programName }
