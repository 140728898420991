import Lottie, { LottieRefCurrentProps } from 'lottie-react'
import { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

interface LottieInViewProps {
  animationData: any
  loop: boolean
  className?: string
}
const LottieInView = ({ animationData, loop = true, className }: LottieInViewProps) => {
  const lottieRef = useRef<LottieRefCurrentProps>(null)

  const { ref, inView } = useInView({
    threshold: 0.6,
    triggerOnce: true
  })

  useEffect(() => {
    if (inView) {
      lottieRef.current?.play()
    }
  }, [inView])

  return (
    <div ref={ref} className="chromatic-ignore">
      <Lottie
        animationData={animationData}
        className={className}
        loop={loop}
        autoplay={false}
        lottieRef={lottieRef}
      />
    </div>
  )
}

export default LottieInView
