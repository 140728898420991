import Pluralize from 'react-pluralize'

import SortByFilter from './SortByFilter'

export interface BookmarksFiltersHeaderProps {
  loading: boolean
  activeFilterCount: number
  trueCount?: number
  handleSortOptionChange: (selected: string) => void
  sortOptions: string[]
  selectedSortOption: string
}

/** Description of BookmarksFiltersHeader */
export default function BookmarksFiltersHeader({
  loading,
  activeFilterCount,
  trueCount,
  handleSortOptionChange,
  sortOptions = [],
  selectedSortOption = ''
}: BookmarksFiltersHeaderProps) {
  return (
    <div
      className="relative mb-4 flex w-full flex-col sm:flex-row sm:items-center sm:justify-between"
      data-testid="bookmarks-bookmarks-filters-header"
    >
      <div className="flex justify-between py-3 sm:py-0">
        {!loading && (
          <Pluralize
            className="text-base font-normal"
            showCount={true}
            singular={activeFilterCount > 0 ? 'Result' : 'Saved Item'}
            count={trueCount}
          />
        )}
      </div>
      <div className="flex">
        <SortByFilter
          handleSortOptionChange={handleSortOptionChange}
          sortOptions={sortOptions}
          selectedSortOption={selectedSortOption}
        />
      </div>
    </div>
  )
}
