import React from 'react'
import { twMerge } from 'tailwind-merge'

export const SectionBorder = ({
  className,
  children,
  hasError
}: {
  className?: string
  children: React.ReactNode
  hasError?: boolean
}) => (
  <div
    className={twMerge(
      'border-color flex flex-row justify-between rounded-sm border border-solid border-rb-gray-250 p-5',
      hasError ? 'border-rb-destructive-100' : '',
      className
    )}
  >
    {children}
  </div>
)
