import { privateApolloClient } from 'apolloClient'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { useActiveProgramLazyQuery } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

const useLatestActiveProgram = (triggerPaths: string[]) => {
  const { current: triggerPathsRef } = useRef(triggerPaths)
  const { pathname } = useLocation()
  const [getActiveProgram] = useActiveProgramLazyQuery({ fetchPolicy: 'no-cache' })
  const currentUser = useAssertCurrentUser()

  useEffect(() => {
    if (triggerPathsRef.includes(pathname)) {
      getActiveProgram().then((data) => {
        privateApolloClient.cache.modify({
          id: `User:${currentUser.id}`,
          fields: {
            activeProgram(currentActiveProgram) {
              if (data?.data?.currentUser?.activeProgram) {
                return data.data?.currentUser?.activeProgram
              } else {
                return currentActiveProgram
              }
            }
          }
        })
      })
    }
  }, [currentUser.id, getActiveProgram, pathname, triggerPathsRef])
}

export default useLatestActiveProgram
