import Image from 'domains/Sanity/Image'
import PricingFaqSection from 'domains/Sanity/PricingFAQSection'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'
import PricingFeatures from 'domains/Sanity/marketingSite/PricingFeatures'

import { Loading } from 'components'
import { useBuildPlanOptionsData } from 'components/PlanOptionCard/helpers'

import {
  CohortPassPriceTier,
  PlanForSale,
  PricingPage,
  PricingPageContent,
  useCohortPassPriceTiersQuery,
  usePlansForSaleQuery,
  usePricingPageQuery
} from 'gql'

import CaseStudiesSection from './components/CaseStudiesSection'
import LogoSection from './components/LogoSection'
import MembershipFeaturesSection from './components/MembershipFeaturesSection'
import MembershipTypeSection from './components/MembershipTypeSection'
import PricingPageHero from './components/PricingPageHero'
import ReviewsSection from './components/ReviewsSection'
import bottomSectionBg from './pricing-page-bottom-bg.svg'

const MarketingPricingPage = () => {
  const { data, loading } = usePricingPageQuery({
    fetchPolicy: 'no-cache'
  })
  const { data: plansForSaleData, loading: plansLoading } = usePlansForSaleQuery()
  const { data: cohortPassData, loading: passesLoading } = useCohortPassPriceTiersQuery()
  const page = (data?.pricingPage || {}) as PricingPage
  const { content } = page

  return (
    <MarketingLayout page={page} transparentNav>
      {loading || plansLoading || passesLoading || !content ? (
        <Loading className="mt-64" />
      ) : (
        <MarketingPricingPageContent
          content={content}
          plansForSale={plansForSaleData?.plansForSale || []}
          cohortPassPriceTiers={cohortPassData?.cohortPassPriceTiers || []}
        />
      )}
    </MarketingLayout>
  )
}

export const MarketingPricingPageContent = ({
  content,
  plansForSale,
  cohortPassPriceTiers
}: {
  content: PricingPageContent
  plansForSale: PlanForSale[]
  cohortPassPriceTiers: CohortPassPriceTier[]
}) => {
  const planOptionsData = useBuildPlanOptionsData({ plansForSale, onMarketingSite: true })

  return (
    <>
      <PricingPageHero content={content} />
      <LogoSection logos={content?.logos} />

      <MembershipTypeSection
        content={content}
        planOptionsData={planOptionsData}
        cohortPassPriceTiers={cohortPassPriceTiers}
      />

      {content.reviews && <ReviewsSection reviews={content.reviews} />}

      <PricingFeatures features={content.features} planOptionsData={planOptionsData} />

      <div className="relative">
        <Image
          src={bottomSectionBg}
          fill
          className="w-full h-full -z-1 object-cover mt-[-720px] sm:mt-[-210px] md:mt-0"
        />

        <MembershipFeaturesSection content={content} />

        <CaseStudiesSection content={content} />
      </div>

      <PricingFaqSection faqs={content.faqs || []} />
    </>
  )
}

export default MarketingPricingPage
