import { Link } from 'react-router-dom'

import Image from 'domains/Sanity/Image'
import { ComplexPortableText } from 'domains/Sanity/PortableText/ComplexPortableText/ComplexPortableText'

import { Artifact } from 'gql'

import { trackContentClicked } from 'utils/tracking/analytics'

interface SeoPageArtifactCardProps {
  artifact: Artifact
  location?: string
}

const SeoPageArtifactCard = ({ artifact, location }: SeoPageArtifactCardProps) => {
  const authorsLength = artifact.authors?.length || 0

  const trackContentClick = () => {
    trackContentClicked({
      content_sanity_id: artifact.id,
      content_title: artifact.title,
      content_type: 'artifact',
      location: location,
      path: window.location.pathname,
      related_identifiers: {
        display_type: 'vertical'
      }
    })
  }

  return (
    <Link
      to={`/artifacts/${artifact.slug}`}
      className="relative flex w-[288px] flex-col rounded-lg border p-4 text-rb-black hover:text-rb-black hover:no-underline hover:shadow-seo-page-card md:w-[352px]"
      onClick={trackContentClick}
    >
      <Image
        src={artifact.thumbnailUrl || ''}
        alt={artifact.thumbnailAlt || ''}
        className="mb-2 h-[176px] w-full rounded object-cover"
      />
      <p className="mb-1 text-base font-semibold leading-[1.6] line-clamp-2 md:text-xl md:font-medium">
        {artifact.title}
      </p>
      {authorsLength > 0 && (
        <p className="mb-2 text-xs leading-[1.5] md:text-sm">
          {'By '}
          {artifact.authors?.map((expert, i) => {
            return `${i === authorsLength - 1 && i !== 0 ? ' & ' : ''}${expert.name}${
              i < authorsLength - 2 ? ', ' : ''
            }`
          })}
        </p>
      )}

      {artifact.summary ? (
        <div className="mt-4 md:mt-6 w-full gap-y-6 overflow-hidden text-ellipsis text-base font-normal line-clamp-4 [&_p:last-child]:mb-0 [&_*]:text-rb-gray-400 [&>ul]:block">
          <ComplexPortableText content={artifact.summary} />
        </div>
      ) : null}
    </Link>
  )
}

export default SeoPageArtifactCard
