import { useState } from 'react'
import ReactTooltip from 'react-tooltip'

import LinkIcon from 'components/icons/LinkIcon'

import { trackCopyLinkClicked } from 'utils/tracking/analytics'

export function CopyLinkButton({
  tooltipId,
  tooltipTextInit,
  textToCopy,
  trackingData
}: {
  tooltipId: string
  tooltipTextInit: string
  textToCopy: string
  trackingData: Parameters<typeof trackCopyLinkClicked>[0]
}) {
  const [wasLinkCopied, setWasLinkCopied] = useState(false)

  const handleClick = async () => {
    trackCopyLinkClicked(trackingData)

    try {
      await navigator.clipboard.writeText(textToCopy)

      setWasLinkCopied(true)
      setTimeout(() => {
        setWasLinkCopied(false)
      }, 2000)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <button
        className="cursor-pointer rounded bg-transparent hover:bg-rb-white hover:text-rb-teal-300"
        onClick={handleClick}
        data-for={tooltipId}
        data-tip
      >
        <LinkIcon />
      </button>

      <ReactTooltip
        id={tooltipId}
        place="top"
        className="w-[175px] text-center text-xs font-normal"
        effect="solid"
        getContent={() => (wasLinkCopied ? 'Link copied!' : tooltipTextInit)}
      />
    </>
  )
}
