import { PortableText } from '@portabletext/react'

import block from './componentTypes/Blocks'
import listItem from './componentTypes/ListItem'
import list from './componentTypes/Lists'
import marks from './componentTypes/Marks'
import types from './componentTypes/Types'

// import Grid from './modules/grid'

export interface ArtifactsPortableTextProps {
  content: any
  className?: string
  renderContainerOnSingleChild?: boolean
}

const components = {
  block,
  list,
  marks,
  listItem,
  types
}

export const ArtifactsPortableText = ({
  content,
  ...rest
}: ArtifactsPortableTextProps) => (
  // @ts-ignore
  <PortableText value={content} components={components} {...rest} />
)
