import { useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

import { trackR4TGetInTouchSubmitted } from 'utils/tracking/analytics'
import { trackMarketingFormSubmitted } from 'utils/tracking/generated/events/marketingFormSubmitted'

interface TeamsHubspotContactFormProps {
  className?: string
}

/**
 * TeamHubspotContactForm
 * @param className string, helps style the form container
 * @returns an embedded Hubspot form for Teams support
 */
export const TeamsHubspotContactForm = ({ className }: TeamsHubspotContactFormProps) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '20491267',
          formId: process.env.REACT_APP_HUBSPOT_TEAMS_CONTACT_FORM_ID,
          target: '#teamsContactForm', // Attach the form to your custom container
          onFormSubmit: (formData: [HTMLFormElement]) => {
            const el = formData[0].elements
            const email = (el.namedItem('email') as HTMLInputElement).value
            // eslint-disable-next-line camelcase
            const first_name = (el.namedItem('firstname') as HTMLInputElement).value
            // eslint-disable-next-line camelcase
            const last_name = (el.namedItem('lastname') as HTMLInputElement).value
            // eslint-disable-next-line camelcase
            const job_function = (el.namedItem('function') as HTMLInputElement).value
            // eslint-disable-next-line camelcase
            const seniority_level = (el.namedItem('seniority_level') as HTMLInputElement)
              .value
            // eslint-disable-next-line camelcase
            const number_of_seats_wanted = (
              el.namedItem('number_of_seats_wanted') as HTMLInputElement
            ).value
            const questions = (
              el.namedItem(
                'what_questions_do_you_have_about_reforge_for_teams_'
              ) as HTMLInputElement
            ).value
            // blob data for data team <3
            // eslint-disable-next-line camelcase
            const form_fields = {
              email,
              first_name,
              last_name,
              job_function,
              seniority_level,
              number_of_seats_wanted,
              questions
            }

            // NEW - generic "Marketing Form Submitted"
            trackMarketingFormSubmitted({
              form_fields,
              email,
              form_id: process.env.REACT_APP_HUBSPOT_TEAMS_CONTACT_FORM_ID || '',
              form_type: 'hubspot',
              form_name: 'contact-teams',
              location: window.location.pathname,
              url: '/teams/get-in-touch',
              source: 'marketing site',
              type: 'page'
            })

            trackR4TGetInTouchSubmitted({
              company_name: '', // how was this populated before? There's no form field for this in Hubspot
              first_name,
              function: job_function,
              last_name,
              org_team_size: number_of_seats_wanted, // is this answering a different question than we're asking?
              seniority_level,
              work_email: email,
              questions
            })
          }
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div id="teamsContactForm" className={twMerge('', className)} />
}

export default TeamsHubspotContactForm
