import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import { ProgramBookmarkPartsFragment } from 'gql'

interface TextBlockCardProps {
  bookmark: ProgramBookmarkPartsFragment
  clickable?: boolean
}

const TextBlockCard = ({ bookmark, clickable = true }: TextBlockCardProps) => {
  return (
    <div className={`${clickable && 'cursor-pointer'} pr-2`}>
      <RfParagraphMedium>
        <span dangerouslySetInnerHTML={{ __html: bookmark.noteBody || '' }} />
      </RfParagraphMedium>
    </div>
  )
}

export default TextBlockCard
