import YoutubeEmbed from './YoutubeEmbed'

interface VideoEmbedProps {
  src: string
  title: string
}

const YOUTUBE_REGEX =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/
const WISTIA_REGEX =
  /https?:\/\/[^.]+\.(wistia\.com|wistia\.net|wi\.st)\/(medias|embed)\/.*/

const VideoEmbed = ({ src, title }: VideoEmbedProps) => {
  const matchYoutube = src.match(YOUTUBE_REGEX)
  const matchWistia = src.match(WISTIA_REGEX)

  if (matchYoutube) {
    const [, , , , , videoId] = matchYoutube

    return (
      <div className="video-embed chromatic-ignore relative aspect-[16/9]">
        <YoutubeEmbed title={title} videoId={videoId} />
      </div>
    )
  }

  if (matchWistia) {
    return (
      <div className="video-embed chromatic-ignore relative aspect-[16/9]">
        <iframe
          className="wistia_embed absolute top-0 left-0 h-full w-full"
          src={src}
          title={title}
          allow="autoplay; fullscreen;"
          allowTransparency
        />
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async />
      </div>
    )
  }

  return (
    <div className="video-embed chromatic-ignore relative aspect-[16/9]">
      <iframe
        className="absolute top-0 left-0 h-full w-full"
        src={src}
        title={title}
        allow="autoplay; fullscreen; encrypted-media; picture-in-picture;"
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default VideoEmbed
