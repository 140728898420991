import { Modal, ModalContent } from 'components/Modal'

const AddEmailSuccessModal = ({
  success,
  handleClose
}: {
  success: boolean
  handleClose: any
}) => {
  return (
    <Modal
      isOpen={success}
      handleClose={handleClose}
      scrollContent={false}
      className="h-40"
    >
      <ModalContent className="mt-2 flex items-center justify-center">
        <p className="mb-0 self-center" data-test="success-text">
          You’ve added a new email. Check your inbox to verify your email.
        </p>
      </ModalContent>
    </Modal>
  )
}

export default AddEmailSuccessModal
