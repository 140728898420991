import PostSummary from 'domains/Post/PostSummary'
import ReplySummary from 'domains/Post/ReplySummary'

import { ForumContributionFieldsFragment } from 'gql'

const renderForumObject = (contribution: ForumContributionFieldsFragment) => {
  switch (contribution.__typename) {
    case 'Post':
      return <PostSummary record={contribution} />
    case 'Reply':
      return <ReplySummary record={contribution} />
    case 'Comment':
      return <ReplySummary record={contribution} />
    default:
      return null
  }
}

interface ForumActivityProps {
  lastForumContributions: ForumContributionFieldsFragment[]
}

export default function ForumActivity(props: ForumActivityProps) {
  const { lastForumContributions } = props

  if (!lastForumContributions || lastForumContributions.length === 0) {
    return null
  }

  return (
    <div className="pr-0 pb-7 sm:pr-7">
      <div className="text-sm leading-4 sm:text-lg sm:font-semibold sm:leading-5">
        Recent Posts
      </div>
      {lastForumContributions.map((contribution) => (
        <div key={contribution.id}>{renderForumObject(contribution)}</div>
      ))}
    </div>
  )
}
