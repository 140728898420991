import { isTextN } from './dom'
import { DomMeta, IDENTIFIER, ROOT_INDEX } from './types'

// Identify the index of the element in the document by the tagname
const findNodeIndex = ($node: Node, $root: Document | HTMLElement): number => {
  const tagName = ($node as HTMLElement).tagName
  const $list = $root.getElementsByTagName(tagName)

  for (let i = 0; i < $list.length; i++) {
    if ($node === $list[i]) {
      return i
    }
  }

  // unknow root id
  return -1
}

/**
 *  Determine text total length in the full container
 */
const getTextPreOffset = ($root: Node, $text: Node): number => {
  const nodeStack: Node[] = [$root]

  let $currentNode: any = null
  let offset = 0

  while (($currentNode = nodeStack.pop())) {
    const children = $currentNode.childNodes

    for (let i = children.length - 1; i >= 0; i--) {
      nodeStack.push(children[i])
    }

    if (isTextN($currentNode) && $currentNode !== $text) {
      offset += $currentNode.textContent.length
    } else if (isTextN($currentNode)) {
      break
    }
  }

  return offset
}

const getOriginParent = ($node: HTMLElement | Text): HTMLElement => {
  if ($node instanceof HTMLElement && (!$node.dataset || !$node.dataset[IDENTIFIER])) {
    return $node
  }

  let $originParent = $node.parentNode as HTMLElement

  while ($originParent?.dataset[IDENTIFIER]) {
    $originParent = $originParent.parentNode as HTMLElement
  }

  return $originParent
}

export const getNodeMetadata = (
  $node: HTMLElement | Text,
  offset: number,
  $root: Document | HTMLElement
): DomMeta => {
  const $originParent = getOriginParent($node)
  const index = $originParent === $root ? ROOT_INDEX : findNodeIndex($originParent, $root)

  const preNodeOffset = getTextPreOffset($originParent, $node)
  const tagName = $originParent.tagName

  return {
    parentTagName: tagName,
    parentIndex: index,
    textOffset: preNodeOffset + offset
  }
}
