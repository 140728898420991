import { MembersUserCohortPartFragment } from 'gql'

export interface UserCohortFilter {
  id: string
  name: string
  season: string
}

export const getUserCohortFilters = (
  userCohorts: MembersUserCohortPartFragment[] = []
): { userCohorts: UserCohortFilter[]; userCohortSeasons: string[] } => {
  const userCohortSeasons = new Set<string>()
  const userCohortFilters =
    userCohorts.map(({ cohort }) => {
      const season = `${cohort.season.year}-${cohort.season.name.toLowerCase()}`
      userCohortSeasons.add(season)
      return {
        id: cohort.id,
        name: cohort.cmsProgram.name,
        season
      }
    }) || []

  return {
    userCohorts: userCohortFilters,
    userCohortSeasons: Array.from(userCohortSeasons)
  }
}
