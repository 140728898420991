import { useEffect, useRef, useState } from 'react'
import { debounce } from 'throttle-debounce'

import useListeningModeProgressListener from 'components/ListeningMode/useListeningModeProgressListener'
import { ContentPercentComplete } from 'components/cards/Content/utils'

import {
  CourseDetailDocument,
  CourseOnDemandDetailDocument,
  ProgressConsumerCoursePartsFragment,
  ProgressConsumerCourseSessionPartsFragment,
  ProgressConsumerUnitPartsFragment,
  ProgressConsumerUserPartsFragment,
  useCreateUserCmsActivityMutation,
  useTrackServerEventMutation
} from 'gql'

import useConsumeProgress from 'hooks/useConsumeProgress'

import { useContentMode } from 'utils/contentTrackingUtils'
import { getAnonymousId } from 'utils/tracking/segment'

interface UnitProgressConsumerProps {
  user?: ProgressConsumerUserPartsFragment | null
  unit?: ProgressConsumerUnitPartsFragment | null
  courseSession?: ProgressConsumerCourseSessionPartsFragment | null
  course?: ProgressConsumerCoursePartsFragment | null
  preview?: boolean
}

export const UnitProgressConsumer = ({
  user,
  unit,
  courseSession,
  course,
  preview
}: UnitProgressConsumerProps) => {
  const [consumeProgress, setConsumeProgress] = useState(0)
  const [completeTracked, setCompleteTracked] = useState(false)
  const [completeRecorded, setCompleteRecorded] = useState(false)

  const currentConsumeProgress = useConsumeProgress({
    id: 'guide-content-container',
    scrollableId: 'page'
  })

  const contentMode = useContentMode({
    courseSession
  })

  const refetchQueriesAfterCmsActivity = []
  if (course?.slug && courseSession?.id && !preview) {
    refetchQueriesAfterCmsActivity.push({
      query: CourseDetailDocument,
      variables: {
        slug: course.slug,
        courseSessionId: courseSession.id
      }
    })
  }

  if (course?.slug && !courseSession?.id && !preview) {
    refetchQueriesAfterCmsActivity.push({
      query: CourseOnDemandDetailDocument,
      variables: {
        slug: course.slug
      }
    })
  }

  const [trackServerEvent] = useTrackServerEventMutation()

  const [createUserCmsActivity] = useCreateUserCmsActivityMutation({
    refetchQueries: refetchQueriesAfterCmsActivity,
    awaitRefetchQueries: true
  })

  const TrackContentCompleted = (unit: any, progressChangeType: string) => {
    if (unit?.id) {
      trackServerEvent({
        variables: {
          input: {
            anonymousId: getAnonymousId(),
            event: 'Content Completed - Server',
            properties: {
              content_type: 'guide',
              content_title: unit?.title,
              content_sanity_id: unit?.id,
              completion_type: progressChangeType,
              related_identifiers: {
                content_mode: contentMode,
                course_id: course?.id,
                course_title: course?.title,
                course_session_id: courseSession?.id
              }
            }
          }
        }
      })
    }
  }

  const debouncedTrackContentCompleted = useRef(
    debounce(750, TrackContentCompleted)
  ).current
  const debouncedCreateUserCmsActivity = useRef(
    debounce(750, createUserCmsActivity)
  ).current

  const handleConsumeProgressChange = (
    consumeProgressVal: number,
    progressChangeType: string
  ) => {
    if (consumeProgressVal > consumeProgress) {
      setConsumeProgress(consumeProgressVal)
    }

    // track completion progress only when the user is logged in and the guide was not completed before
    if (!user || currentConsumeProgress < ContentPercentComplete.NinetyPercent) {
      if (!user || consumeProgressVal < ContentPercentComplete.NinetyPercent) {
        return
      }

      if (!completeRecorded) {
        debouncedCreateUserCmsActivity({
          variables: {
            input: {
              sanityId: unit?.id,
              action: 'completed',
              contentType: 'Guide',
              courseId: course?.id,
              courseSessionId: courseSession?.id
            }
          }
        })
        setCompleteRecorded(true)
      }
      // track progress update when the user is not a free user except for previewable units.
      const shouldTrackContentCompleted = user?.is.freeUser ? unit?.previewable : true
      if (!completeTracked && shouldTrackContentCompleted) {
        debouncedTrackContentCompleted(unit)
        debouncedTrackContentCompleted(unit, progressChangeType)
        setCompleteTracked(true)
      }
    }
  }

  useListeningModeProgressListener({
    onTrackEnded: () => {
      handleConsumeProgressChange(ContentPercentComplete.OneHundredPercent, 'audio')
    }
  })

  useEffect(() => {
    handleConsumeProgressChange(currentConsumeProgress, 'scroll')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConsumeProgress, user, unit])

  useEffect(() => {
    if (unit?.id && user) {
      createUserCmsActivity({
        variables: {
          input: {
            sanityId: unit?.id,
            action: 'viewed',
            contentType: 'Guide'
          }
        }
      })
    }
  }, [unit?.id, createUserCmsActivity, user])

  return <></>
}

export default UnitProgressConsumer
