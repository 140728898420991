import { closing, opening } from './sharedMarkdown'

const programName = 'Monetization + Pricing'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://www.reforge.com/monetization-pricing), because I will learn how to analyze monetization strategies, validate and test new pricing models, build business cases, and better communicate to teams and executives. This course was built with [Elena Verna](https://www.reforge.com/experts/elena-verna), [Dan Hockenmaier](https://www.reforge.com/experts/dan-hockenmaier), and [Patrick Campbell](https://www.reforge.com/experts/patrick-campbell), and covers:
* **Define and Measure Your Monetization Strategy.** Break down and define your monetization strategy from first principles. Understand how to align your monetization strategy to your use cases, growth model, and business model.  
* **Analyze and Identify Model Opportunities.** Your customer base is constantly evolving and shifts to your Monetization model are inevitable. You will learn the analyses you need to identify opportunities to improve your pricing, packaging, and other components of your monetization strategy. 
* **Model Strategies.** You will learn the different strategies to improve your monetization model across new pricing metrics, packaging strategies, adding new use cases, vertical strategies, and horizontal strategies. 
* **Analyze and Identify Optimization Improvements.** Learn to analyze the metrics that matter and identify opportunities to make game-changing optimizations quickly. Explore the best ways to approach prioritization, conversion flow, and optimization testing around your monetization model.  
* **Optimization Strategies.** You will create a roadmap of optimization improvements by deploying strategies such as increasing depth of use cases, moving or adding users to new use cases, creating anti-conversion flows, using incentives, and more.
* **Executing Monetization + Pricing Changes.** Executing monetization changes can be sensitive internally and externally. Learn how the best tech companies validate their hypothesis, drive organizational buy-in, test with live customers, and manage the full rollout.

&NewLine;

${closing}
`

export default { markdown, title: programName }
