import { ErrorMessage } from 'components'

import { useCollectionActivityFacepileQuery } from 'gql'

import { ActivityFacepile } from './ActivityFacepile'

export interface CollectionActivityFacepileProps {
  collectionId: string
  inCard?: boolean
  isShared?: boolean
}

export const CollectionActivityFacepile = ({
  collectionId,
  inCard
}: CollectionActivityFacepileProps) => {
  const { loading, error, data } = useCollectionActivityFacepileQuery({
    variables: { collectionId },
    fetchPolicy: 'cache-and-network'
  })

  if (loading) {
    return <div className="w-3/4 animate-pulse rounded-xl bg-rb-gray-100 py-[6px]" />
  }

  if (error) return <ErrorMessage error={error} />

  if (!data || !data.bookmarkFolder) return null

  const {
    bookmarkFolder: { usersSharedWith, user: creator, createdAtInWords }
  } = data

  return (
    <ActivityFacepile
      users={usersSharedWith}
      creator={creator}
      activities={data.mostRecentCollectionActivityPerUser}
      createdTimeAgoInWords={createdAtInWords}
      inCard={inCard}
      isShared={usersSharedWith.filter((u) => u.id === creator.id).length > 0}
    />
  )
}
