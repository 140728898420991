import { useEffect } from 'react'

import RfHeader2 from 'components/typography/RfHeader/RfHeader2'

import { ReactComponent as ReforgeLogo } from 'images/reforge-logo.svg'

export default function MaintenancePage() {
  useCheckMaintenance()

  return (
    <div className="flex h-screen w-screen items-center justify-center text-center">
      <div className="w-full max-w-md p-8">
        <ReforgeLogo className="mb-10 w-full fill-current text-rb-black" />
        <RfHeader2>Coming back soon</RfHeader2>
      </div>
    </div>
  )
}
const CHECK_MAINTENANCE_INTERVAL = 1000 * 60 * 1 // 5 minutes
function useCheckMaintenance() {
  useEffect(() => {
    const checkMaintenance = async () => {
      const { isMaintenanceMode } = await fetchMaintenance()
      if (!isMaintenanceMode) {
        window.location.assign('/')
      }
    }

    const interval = setInterval(checkMaintenance, CHECK_MAINTENANCE_INTERVAL)

    // in case of page refresh check immediately
    checkMaintenance()

    return () => clearInterval(interval)
  }, [])
}

function fetchMaintenance(): Promise<{ isMaintenanceMode: boolean }> {
  return fetch('/api/v1/maintenance').then((response) => response.json())
}
