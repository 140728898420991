import { ReactNode } from 'react'

import { ColorOptions } from 'components/typography/TypographyColorOptions'

interface RfMetaSmallProps {
  children: ReactNode
  color?: ColorOptions
}

const RfMetaSmall = ({ children, color = ColorOptions.gray400 }: RfMetaSmallProps) => (
  <p
    className={`mb-0 text-[15px] font-medium uppercase leading-[18px] tracking-widest text-${color}`}
  >
    {children}
  </p>
)

export default RfMetaSmall
