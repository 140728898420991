import { Link } from 'react-router-dom'

import { BreadcrumbsPageType } from 'components/Breadcrumbs/BaseBreadcrumbs'
import { ReactComponent as IconChevronRight } from 'components/Breadcrumbs/BaseBreadcrumbs/base-chevron-right.svg'

interface BreadcrumbLinkProps {
  hierarchialEntry: BreadcrumbsPageType
  isLast: boolean
}

const BaseBreadcrumbLink = ({ hierarchialEntry, isLast }: BreadcrumbLinkProps) => (
  <div className="mr-1.5 flex items-center sm:mr-3 md:h-12">
    <div className="mr-1.5 sm:mr-3">
      {hierarchialEntry.title === '...' || isLast ? (
        <p className="mb-0 text-sm font-normal capitalize text-rb-black md:text-base">
          <span className="no-underline">{hierarchialEntry.title}</span>
        </p>
      ) : (
        <Link to={hierarchialEntry.path}>
          <p className="mb-0 text-sm font-normal capitalize text-rb-black underline md:text-base">
            <span>{hierarchialEntry.title}</span>
          </p>
        </Link>
      )}
    </div>
    {!isLast && (
      <>
        <div className="text-rb-black">
          <IconChevronRight fill={'#080A0A'} width={14.54} height={16} />
        </div>
      </>
    )}
  </div>
)

export default BaseBreadcrumbLink
