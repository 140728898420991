import marketingLogos1 from 'images/marketing-round-icons/teams/marketing-logos-1.png'
import marketingLogos2 from 'images/marketing-round-icons/teams/marketing-logos-2.png'

export const ClientLogos = () => {
  return (
    <div className="relative top-0 left-1/2 right-0 grid -translate-y-[10%] -translate-x-1/2 transform grid-cols-1 grid-rows-3 gap-y-[80px] rounded-lg bg-rb-blue-50 px-[50px] py-[40px] drop-shadow-[0_0_5px_rgba(0,0,0,0.16)] sm:w-4/5 md:-translate-y-1/3 md:grid-cols-3 md:grid-rows-1 md:gap-x-[40px] md:gap-y-0 md:px-[25px] md:py-[20px] md:px-[40px] md:py-[40px] lg:gap-x-[20px]">
      <div className="text-center md:text-left">
        <h3 className="font-normal leading-8 fluid-text-2xl">1,000+</h3>
        <p className="!leading-7 fluid-text-lg">Teams using Reforge</p>
        <h3 className="mt-[40px] font-normal leading-8 fluid-text-2xl">76%</h3>
        <p className="!leading-7 fluid-text-lg">of Cloud 100 Companies</p>
      </div>
      <div className="relative h-full w-full">
        <img
          alt="Atlassian, Adobe, and Slack logos"
          src={marketingLogos1}
          width={424}
          height={461}
          className="absolute h-full w-full"
          style={{
            objectFit: 'contain'
          }}
        />
      </div>
      <div className="relative">
        <img
          alt="Microsoft, Shopify, and Canva logos"
          src={marketingLogos2}
          width={372}
          height={470}
          className="absolute h-full w-full"
          style={{
            objectFit: 'contain'
          }}
        />
      </div>
    </div>
  )
}

export default ClientLogos
