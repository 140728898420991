import { Link } from 'react-router-dom'

import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import RfParagraphSmallBold from 'components/typography/RfParagraph/RfParagraphSmallBold'

import { CohortPostPartsFragment } from 'gql'

interface CohortPostContentPreviewProps {
  post: CohortPostPartsFragment
  cohortSlug: string
}

const CohortPostContentPreview = ({
  post,
  cohortSlug
}: CohortPostContentPreviewProps) => (
  <>
    {(post.basedOn || post.referenceImageUrl) && (
      <Link
        to={`/cohorts/${cohortSlug}${post.cmsSectionLink}`}
        className="text-rb-gray-300 no-underline hover:no-underline"
      >
        <div className="mt-4 max-w-[550px] overflow-hidden rounded-[8px] border border-rb-gray-100 bg-white">
          {post.basedOn && (
            <RfParagraphSmall className="mb-4 p-4">
              <span
                dangerouslySetInnerHTML={{
                  __html: `“${post.basedOn.replace(/&amp;/g, '&')}”`
                }}
              />
            </RfParagraphSmall>
          )}

          {post.referenceImageUrl && (
            <img
              className="bg-whiten w-full"
              src={post.referenceImageUrl}
              alt={post.cmsSectionName || ''}
            />
          )}

          {post?.cmsSectionLink && (
            <div className="bg-rb-gray-50 px-4 py-2">
              <RfParagraphSmallBold>
                {post.cmsSectionParentName && `${post.cmsSectionParentName} / `}
                {post.cmsSectionName}
              </RfParagraphSmallBold>
            </div>
          )}
        </div>
      </Link>
    )}
  </>
)

export default CohortPostContentPreview
