import { CohortPostReplyPartsFragment } from 'gql'

interface getMembersProps {
  replies: CohortPostReplyPartsFragment[]
}

export const sortRepliesByUserKind = ({ replies }: getMembersProps) =>
  [...replies].sort((a: any, b: any) =>
    b.user.profile?.kind > a.user.profile?.kind ? -1 : 1
  )

export const getSortedRepliesByUserKind = ({ replies }: getMembersProps) => {
  const filteredData = replies.filter(
    (reply, i) => replies.findIndex((v2) => v2.user.id === reply.user.id) === i
  )

  const sortedReplies = sortRepliesByUserKind({ replies: filteredData })

  return sortedReplies.map((reply) => ({
    id: reply.user.id,
    hasBadge: false,
    avatarUrl: reply.user.profile.avatarUrl,
    fullName: reply.user.profile.fullName
  }))
}
