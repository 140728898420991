import { useApolloClient } from '@apollo/client'
import { publicApolloClient } from 'apolloClient'
import { Ref, RefObject, useEffect, useRef, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import PostCourseCallout, {
  shouldShowPostCourseCallout
} from 'domains/Course/PostCourseCallout'

import { AccountLink } from 'components/AccountLink'
import Button from 'components/Button'
import CourseLinkSection, {
  getUserCoursesForCourseLinks
} from 'components/LeftSidebar/CourseLinkSection/CourseLinkSection'
import LiveProgramLinksSection, {
  useShouldShowLiveProgramLinksSection
} from 'components/LeftSidebar/LiveProgramLinksSection'
import NavigationTab from 'components/LeftSidebar/NavigationTab'
import { useGetCohortFromPath } from 'components/LeftSidebar/useGetCohortFromPath'
import { useLeftSidebar } from 'components/LeftSidebar/useLeftSidebar'
import Tooltip from 'components/Tooltip/Tooltip'
import { CloseIcon } from 'components/icons'

import {
  MAX_WIDTH_TAILWIND_TL,
  MAX_WIDTH_TAILWIND_XL,
  MIN_WIDTH_TAILWIND_LG
} from 'constants/breakpoints'

import { CurrentUserPartsFragment, useGetEnrollmentSeasonQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import useMediaQuery from 'hooks/useMediaQuery'
import useOnClickOutside from 'hooks/useOnClickOutside'

import { showCohortDashboardForCohortSeason } from 'utils/cohortUtils'
import { trackCtaClicked } from 'utils/tracking/analytics'

import { ReactComponent as DiamondIconStreamline } from 'images/Diamond--Streamline-Ultimate.svg'
import { ReactComponent as DiamondIconPremium } from 'images/icon--solid-outline-premium-diamond.svg'

import Logo from './Logo'

export interface LinkProps {
  user: CurrentUserPartsFragment
  isCollapsed: boolean
}

const MembersLink = ({ user, isCollapsed }: LinkProps) => {
  if (!user.can.viewMemberIndex || user.is.trialing) {
    return null
  }

  return (
    <li className="relative py-[5px]">
      <NavigationTab
        id="members_link"
        link="/directory"
        name="directory"
        isCollapsed={isCollapsed}
      />
    </li>
  )
}

const UpgradeLink = ({
  user,
  setIsLeftSidebarOpen,
  isCollapsed
}: {
  user: CurrentUserPartsFragment
  setIsLeftSidebarOpen: (isOpen: boolean) => void
  isCollapsed: boolean
}) => {
  if (user.is.member || user.is.paidMember || user.is.planManager) {
    return null
  }

  const ctaText = user.is.eligibleForTrial ? 'Start 14-day free trial' : 'Become a member'
  const ctaHref = user.is.eligibleForTrial
    ? `/try-reforge?referer=${encodeURIComponent(window.location.pathname)}`
    : `/subscribe/choose-plan?referer=${encodeURIComponent(window.location.pathname)}`

  const onClick = () => {
    setIsLeftSidebarOpen(false)
    trackCtaClicked({
      destination: '/try-reforge',
      cta_location: 'leftsidebar',
      text: ctaText.toLowerCase(),
      cta_type: 'button'
    })
  }

  return (
    <>
      <div
        className={`wrap lg:rounded text-xs md:gap-8 lg:pl-0 ${isCollapsed ? 'hidden' : ''}`}
      >
        <div className="flex">
          <p className="mb-1 font-semibold"> Unlimited access</p>
          <span>
            <DiamondIconPremium className="w-4 h-4 ml-2" />
          </span>
        </div>
        <p className="leading-[18px] text-rb-black">
          Access rigorous courses, real work examples, and step-by-step guides.
        </p>
      </div>
      <Tooltip
        disable={!isCollapsed}
        offset={{ right: 10, bottom: 20 }}
        backgroundColor="#080A0A"
        className="rounded-lg"
        tooltipBody={<div>{ctaText}</div>}
        place="right"
        contentWrapperClassname={twMerge(
          'w-full text-rb-gray-300 hover:text-rb-gray-300 flex items-center',
          isCollapsed ? 'lg:pl-0' : ''
        )}
      >
        <Button
          onClick={onClick}
          color="premium"
          size="medium"
          href={ctaHref}
          variant="fill"
          className={twMerge(
            'gap-2 px-4 py-2.5',
            isCollapsed ? 'mt-10 mb-0 w-10 h-[40px] mr-10' : 'xl:text-rb-white  w-full'
          )}
        >
          {!isCollapsed && (
            <span className="pl-1 text-nowrap font-semibold">{ctaText}</span>
          )}
          {isCollapsed && <DiamondIconStreamline className="min-w-[20px]" />}
        </Button>
      </Tooltip>
    </>
  )
}

const HomeLink = ({ user, isCollapsed }: LinkProps) => {
  if (user.is.member || user.is.paidMember || user.is.planManager || user.is.freeUser) {
    return (
      <li className="relative py-[5px]">
        <NavigationTab
          id="home"
          link="/"
          matchExactPath={true}
          name="Home"
          iconName="home"
          isCollapsed={isCollapsed}
          iconClassName="stroke-current fill-current mr-0.5"
        />
      </li>
    )
  }
  return null
}

const GuidesLink = ({ user, isCollapsed }: LinkProps) => {
  if (user.is.member || user.is.paidMember || user.is.planManager || user.is.freeUser) {
    return (
      <li className="relative py-[5px]">
        <NavigationTab
          id="guides_link"
          link="/guides"
          name="guides"
          isCollapsed={isCollapsed}
        />
      </li>
    )
  }

  return null
}

const ArtifactsNavLink = ({ isCollapsed }: { isCollapsed: boolean }) => {
  return (
    <li className="relative py-[5px]">
      <NavigationTab
        id="artifacts"
        link="/artifacts"
        matchExactPath={false}
        name="artifacts"
        isCollapsed={isCollapsed}
        iconClassName="stroke-current fill-current mr-0.5"
      />
    </li>
  )
}

// TODO: move this?
const NoUser = ({
  isCollapsed,
  styles,
  sidebarContentRef
}: {
  isCollapsed: boolean
  styles: any
  sidebarContentRef: Ref<HTMLDivElement>
}) => {
  return (
    <div id={styles.id} className={styles.leftNavWrapper}>
      <div ref={sidebarContentRef} className={`flex flex-col ${styles.menuWrapper}`}>
        <div className={styles.scrollingSection} id="sidebar-scrolling-section">
          <div
            id="sidebar-header"
            className={twMerge(
              'w-[270px] pb-2.5 pt-2.5 pl-4 sm:pb-3.5 sm:pt-3.5 tl:pb-5 tl:pt-5 lg:w-[240px]',
              isCollapsed
                ? 'flex w-0'
                : 'fixed top-0 z-10 h-[88px] transition-[width] duration-[400ms] ease-in-out'
            )}
          >
            <Logo />
          </div>
        </div>
      </div>
    </div>
  )
}

interface LeftSidebarProps {
  dataTest?: string
}

const EventsLink = ({ isCollapsed }: LinkProps) => {
  return (
    <li className="relative py-[5px]">
      <NavigationTab
        id="events_link"
        link="/events"
        name="events"
        isCollapsed={isCollapsed}
      />
    </li>
  )
}

const ExploreLink = ({ isCollapsed }: { isCollapsed: boolean }) => {
  return (
    <li className="relative py-[5px]">
      <NavigationTab
        id="explore"
        link="/explore"
        name="explore"
        isCollapsed={isCollapsed}
        iconName="explore-compass"
        iconClassName="stroke-current fill-current"
      />
    </li>
  )
}

export default function LeftSidebar({ dataTest }: LeftSidebarProps) {
  const { pathname, search } = useLocation()
  const client = useApolloClient()
  const { data } = useGetEnrollmentSeasonQuery({
    skip: client === publicApolloClient
  })

  const { cohortData, cohortLoading, cohortSlug } = useGetCohortFromPath(pathname)

  const enrollmentSeason = data?.enrollmentSeason
  const { isLeftSidebarOpen, setIsLeftSidebarOpen } = useLeftSidebar()
  const sidebarContentRef = useRef<HTMLDivElement>(null)
  const scrollSectionRef = useRef<HTMLUListElement>(null)

  const { showCohortDashboard, aiPlayground, coachingPoc, ref16263TopicBasedBrowsing } =
    useFeatureFlags()

  useOnClickOutside(
    sidebarContentRef,
    () => {
      setIsLeftSidebarOpen(false)
    },
    {
      enabled: isLeftSidebarOpen
    }
  )

  const cohortEligibleForCohortDashboard = () => {
    if (!cohortSlug) {
      return false
    }

    if (!showCohortDashboard) {
      return false
    }

    const season = cohortData?.cohort?.season
    return season && showCohortDashboardForCohortSeason(season)
  }

  const minWidthLg = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_LG})`)
  const maxWidthTl = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_TL})`)
  const maxWidthXl = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XL})`)
  const isMobileView = maxWidthTl
  const isWithinCollapsibleWidth = minWidthLg && maxWidthXl
  const [isCollapsed, setIsCollapsed] = useState(isWithinCollapsibleWidth)
  const { currentUser } = useCurrentUser()

  useResetScrollOnToggle({
    ref: scrollSectionRef,
    toggle: isLeftSidebarOpen || isCollapsed
  })

  useEffect(() => {
    if (!isMobileView) return // Only apply this on mobile

    const handleResize = () => {
      if (sidebarContentRef.current) {
        sidebarContentRef.current.style.maxHeight = `${window.innerHeight}px`
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showLiveProgramLinksSection = useShouldShowLiveProgramLinksSection(currentUser)
  const showCourseLinkSectionSection =
    getUserCoursesForCourseLinks(currentUser)?.length > 0
  const mostRecentPastUserCourse = currentUser?.userCourses?.last

  const [cohortIsLoading, setCohortIsLoading] = useState(true)

  useEffect(() => {
    // If we happen to be on the cohort page, we need to collapse the LeftSidebar IF we are showning the
    // cohortdashboard.  Since we only show the cohortdashboard for cohorts that have showCohortViewerAt
    // set, we need to do a lookup of the cohort in the LeftSidebar to be able to determine whether that
    // cohort has showCohortViewerAt set on the season, and whether we have passed that timestamp.
    // Since the LeftSidebar does not have a loading state as of yet, this is a stand-in for that - basically this triggers
    // a rerender of the LeftSidebar once the cohort has been fetched
    if (cohortIsLoading !== cohortLoading) {
      setCohortIsLoading(cohortLoading)
    }
  }, [cohortLoading, cohortIsLoading, setCohortIsLoading])

  useEffect(() => {
    setIsCollapsed(isWithinCollapsibleWidth)
  }, [isWithinCollapsibleWidth])

  const handleMouseEnter = () => {
    if (!isWithinCollapsibleWidth) return
    setIsCollapsed(true)
  }

  const handleMouseLeave = () => {
    if (!isWithinCollapsibleWidth) return
    setIsCollapsed(true)
  }

  const mobileStyles = {
    id: 'mobile-left-nav',
    leftNavWrapper: twMerge(
      'z-[1002] fixed top-0 left-0 bottom-0 hidden',
      isLeftSidebarOpen && 'block w-full bg-black/10'
    ),
    menuWrapper:
      'border-box transition-[left] h-screen ease-out duration-[300ms] px-4 pb-4 bg-white',
    scrollingSection: 'overflow-y-scroll hide-scrollbar'
  }

  const desktopStyles = {
    id: 'left-nav',
    leftNavWrapper: twMerge(
      '2xl:sticky py-4 top-[72px] lg:shadow-md 2xl:shadow-none left-0 z-[200] shrink-0 h-[calc(100vh_-_72px)] overflow-hidden transition-[width,shadow] duration-[400ms] ease-in-out w-[240px] bg-white lg:absolute',
      isCollapsed ? 'w-[88px] lg:shadow-none pl-4 pr-3' : 'px-4'
    ),
    menuWrapper: 'overflow-x-hidden text-rb-gray-400 h-full',
    scrollingSection: twMerge(
      'overflow-x-hidden min-w-[60px]',
      isCollapsed
        ? 'w-auto transition-[width] duration-[400ms] ease-in-out'
        : 'w-full overflow-y-scroll pr-[17px] box-content'
    )
  }
  const styles = isMobileView ? mobileStyles : desktopStyles

  if (!currentUser?.id) {
    return (
      <NoUser
        isCollapsed={isCollapsed}
        styles={styles}
        sidebarContentRef={sidebarContentRef}
      />
    )
  }

  if (!!matchPath(pathname, { path: '/cohorts/*' }) && cohortIsLoading) return null

  // Hide sidebar on cohort dashboard page, but show it for the old cohort viewer
  if (
    !!matchPath(pathname, { path: '/cohorts/*' }) &&
    cohortEligibleForCohortDashboard()
  ) {
    return null
  }

  return (
    <div
      id={styles.id}
      data-test={dataTest}
      className={styles.leftNavWrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div ref={sidebarContentRef} className={`flex flex-col ${styles.menuWrapper}`}>
        <div className="box-content flex h-10 shrink-0 items-center gap-2 p-2 lg:hidden">
          <AccountLink
            onClick={() => setIsLeftSidebarOpen(false)}
            withName
            className="flex-1"
            avatarSize={24}
          />
          {/* temporarily disabling */}
          {/* <div className="ml-auto flex items-center">
            {currentUser?.can?.viewActivitySidebar && (
              <ActivityLink onClick={() => setIsLeftSidebarOpen(false)} />
            )}
          </div> */}
          <button
            onClick={() => {
              setIsLeftSidebarOpen(false)
            }}
          >
            <CloseIcon className="h-6 w-6" />
          </button>
        </div>
        <ul
          className={twMerge(
            'flex h-full list-none flex-col p-0',
            styles.scrollingSection
          )}
          ref={scrollSectionRef}
          id="subnav__toggle"
        >
          {showLiveProgramLinksSection && (
            <LiveProgramLinksSection
              pathname={pathname}
              isCollapsed={isCollapsed}
              enrollmentSeason={enrollmentSeason}
              currentUser={currentUser}
            />
          )}

          <HomeLink user={currentUser} isCollapsed={isCollapsed} />

          {ref16263TopicBasedBrowsing && <ExploreLink isCollapsed={isCollapsed} />}

          {!ref16263TopicBasedBrowsing && <ArtifactsNavLink isCollapsed={isCollapsed} />}

          {!ref16263TopicBasedBrowsing && (
            <GuidesLink user={currentUser} isCollapsed={isCollapsed} />
          )}

          {!ref16263TopicBasedBrowsing && (
            <div className="relative">
              <li className="relative py-[5px]">
                <NavigationTab
                  id="courses"
                  link={`/courses${search}`}
                  name="courses"
                  isCollapsed={isCollapsed}
                  isPathActiveOverride={(pathname: string) =>
                    pathname.startsWith('/courses') && !pathname.includes('sessions')
                  }
                  iconClassName="stroke-current fill-current mr-0.5"
                />
              </li>
              {!isWithinCollapsibleWidth &&
                shouldShowPostCourseCallout(currentUser) &&
                mostRecentPastUserCourse && (
                  <PostCourseCallout
                    userCourseId={mostRecentPastUserCourse.id}
                    courseName={mostRecentPastUserCourse.course.title}
                  />
                )}
            </div>
          )}

          {coachingPoc && (currentUser?.is?.staff || currentUser?.is?.member) && (
            <li className="relative py-[5px]">
              <NavigationTab
                id="coaching"
                link="/coaching"
                name="coaching"
                isCollapsed={isCollapsed}
                iconName="coaching"
                iconClassName="stroke-current fill-current"
              />
            </li>
          )}
          <li className="relative py-[5px]">
            <NavigationTab
              id="my_library"
              link="/saved"
              name="Saved"
              iconName="content-bookmark"
              isCollapsed={isCollapsed}
              iconClassName="stroke-current mr-0.5"
            />
          </li>
          <EventsLink user={currentUser} isCollapsed={isCollapsed} />
          <MembersLink user={currentUser} isCollapsed={isCollapsed} />

          <div>
            {showCourseLinkSectionSection && (
              <CourseLinkSection user={currentUser} isCollapsed={isCollapsed} />
            )}
          </div>
          {aiPlayground && (
            <li className="relative py-[5px]">
              <NavigationTab
                id="ai_playground"
                link="/ai/playground"
                name="AI Playground"
                isCollapsed={isCollapsed}
                iconClassName="stroke-current mr-0.5"
              />
            </li>
          )}
          <div className="mt-auto lg:mt-0 space-y-4">
            {currentUser?.is?.freeUser && (
              <li
                className={`relative p-0 mt-10 rounded-md ${!isCollapsed ? 'lg:bg-rb-blue-50 lg:h-[164px] lg:p-4' : 'pl-2.5'}`}
              >
                <UpgradeLink
                  isCollapsed={isCollapsed}
                  user={currentUser}
                  setIsLeftSidebarOpen={setIsLeftSidebarOpen}
                />
              </li>
            )}
          </div>
        </ul>
      </div>
    </div>
  )
}

function useResetScrollOnToggle<TElement extends Element>({
  ref,
  toggle
}: {
  ref: RefObject<TElement>
  toggle: boolean
}) {
  useEffect(() => {
    ref.current?.scrollTo(0, 0)
  }, [toggle, ref])
}

export { LeftSidebarProvider } from 'components/LeftSidebar/useLeftSidebar'
