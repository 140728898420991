import { ReactComponent as DoubleChevronRightIcon } from 'icon--double-chevron-right.svg'
import { ReactElement } from 'react'

import ActionButton from './ActionButton'

export interface DiscoveryDrawerActionButtonsProps {
  children: ReactElement[]
  closeDrawer?: () => void
}

interface CloseButtonsProps {
  handleOnClick?: () => void
}

const CloseButton = ({ handleOnClick }: CloseButtonsProps) =>
  handleOnClick ? (
    <ActionButton
      icon={<DoubleChevronRightIcon width={16} height={16} />}
      onClick={handleOnClick}
      data-testid="discovery-panel-close-button"
      aria-label="Close Sidebar"
      title="Close Sidebar"
      id="discovery-drawer-close-button"
    />
  ) : null

const DiscoveryDrawerActionButtons = ({
  closeDrawer,
  children
}: DiscoveryDrawerActionButtonsProps) => {
  return (
    <div className="flex justify-between gap-4 px-4 pt-3 pb-2.5">
      <CloseButton handleOnClick={closeDrawer} />
      <div className="flex" data-testid="discovery-panel-action-buttons">
        {children}
      </div>
    </div>
  )
}

export default DiscoveryDrawerActionButtons
