import { useHistory } from 'react-router-dom'

import Button from 'components/Button'
import CalendarDropdownMenu, {
  useCalendarDropDown
} from 'components/CalendarDropdownMenu'
import { PlayIcon } from 'components/icons'
import CalendarIcon from 'components/icons/CalendarIcon'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import RfParagraphMiniBold from 'components/typography/RfParagraph/RfParagraphMiniBold'

import {
  CourseDashboardCourseBlockEventPartsFragment,
  CourseDashboardCourseBlockPartsFragment,
  CourseDashboardCoursePartsFragment,
  CourseDashboardCourseSessionPartsFragment
} from 'gql'

import { buildCourseDashboardLinkFromPath } from 'utils/courseUtils'
import {
  formatInTimezone,
  getCurrentTimezone,
  getDayOfWeek,
  getPrettyFullDate,
  getTimezoneAbbreviation,
  isAfterDate,
  isBeforeDate
} from 'utils/date'
import { isEventInSession } from 'utils/eventUtils'
import { trackCtaClicked, trackEventJoinClick } from 'utils/tracking/generated/events'

import AddCourseEventToCalendar from './AddCourseEventToCalendar'

const TIME_FORMAT = 'h:mm a'

interface CourseContentBlockEventProps {
  event: CourseDashboardCourseBlockEventPartsFragment
  course: CourseDashboardCoursePartsFragment
  courseBlock: CourseDashboardCourseBlockPartsFragment
  courseSession: CourseDashboardCourseSessionPartsFragment
  hasCourseDatesOutsideCourseSession: boolean
  timezone?: string | null
}

export const CourseContentBlockEvent = ({
  event,
  course,
  courseBlock,
  courseSession,
  hasCourseDatesOutsideCourseSession,
  timezone
}: CourseContentBlockEventProps) => {
  const history = useHistory()
  const { isAddToCalendarOpen, setIsAddToCalendarOpen } = useCalendarDropDown()
  if (!event || !event.startsAt || !event.endsAt) return null

  const startsAtDate = new Date(event.startsAt as string)

  const isBeforeEvent = isBeforeDate(event.startsAt)
  const isAfterEvent = isAfterDate(event.endsAt)
  const eventIsInSession = isEventInSession(event.startsAt, event.endsAt)

  const onJoinClick = () => {
    trackEventJoinClick({
      event_sanity_id: event.sanityId,
      event_title: event.title,
      event_start: event.startsAt,
      event_end: event.endsAt,
      course_id: course.id,
      course_title: course.title,
      content_block_sanity_id: courseBlock.id,
      content_block_title: courseBlock.title,
      course_session_id: courseSession.id,
      location: 'course_page',
      destination_url: event.joinLink
    })

    if (event.joinLink) {
      window.open(event.joinLink, '_blank')
    }
  }

  const onWatchRecordingClick = () => {
    trackCtaClicked({
      cta_location: 'course_page',
      cta_type: 'button',
      text: 'Watch Recording',
      related_identifiers: {
        course_id: course.id,
        course_title: course.title,
        course_session_id: courseSession?.id,
        event_sanity_id: event.sanityId,
        event_title: event.title,
        content_block_sanity_id: courseBlock.id
      }
    })

    const destination = buildCourseDashboardLinkFromPath({
      courseSlug: course.slug,
      sessionId: courseSession?.id,
      eventId: event.sanityId
    })

    history.push(destination)
  }

  return (
    <div className="flex flex-col rounded-2xl border border-gray-200 bg-[url(/app/assets/images/bg-pink-angled-gradient.svg)] bg-cover bg-right bg-no-repeat py-6 px-6 sm:py-8 lg:flex-row lg:items-center lg:space-x-16">
      <div className="mb-6 flex flex-1 flex-col justify-center lg:mb-0">
        <RfParagraphMedium className="mb-4">{event.title}</RfParagraphMedium>
        <RfParagraphMini>{event.shortDescription}</RfParagraphMini>
      </div>
      <div className="flex flex-1 flex-col justify-center lg:text-center">
        {!isAfterEvent && !hasCourseDatesOutsideCourseSession && (
          <>
            <RfParagraphMiniBold className="mb-1">
              {getDayOfWeek(startsAtDate)}, {getPrettyFullDate(startsAtDate)}
            </RfParagraphMiniBold>
            <RfParagraphMini className="mb-4">
              {formatInTimezone(event.startsAt, getCurrentTimezone(), TIME_FORMAT)} -{' '}
              {formatInTimezone(event.endsAt, getCurrentTimezone(), TIME_FORMAT)} (
              {getTimezoneAbbreviation(getCurrentTimezone())})
            </RfParagraphMini>
          </>
        )}

        <div className="flex lg:justify-center">
          {eventIsInSession && (
            <Button
              data-test="course_content_block_event_join_event_button"
              onClick={onJoinClick}
              className="w-full py-1.5 lg:w-auto"
              size="x-small"
            >
              Join Event
            </Button>
          )}

          <div className="relative">
            {isBeforeEvent &&
              !hasCourseDatesOutsideCourseSession &&
              !eventIsInSession && (
                <Button
                  data-test="course_content_block_event_join_event_starts_in_button"
                  onClick={() => setIsAddToCalendarOpen(!isAddToCalendarOpen)}
                  className="w-full py-1.5 normal-case lg:w-auto"
                  size="x-small"
                >
                  <div className="flex flex-row gap-1">
                    <CalendarIcon className="h-5 w-5 fill-current" />
                    Add to Calendar
                  </div>
                </Button>
              )}
            {isAddToCalendarOpen && (
              <CalendarDropdownMenu
                containerClassName="absolute bg-white bottom-full"
                offset="right-2"
                onClose={() => isAddToCalendarOpen && setIsAddToCalendarOpen(false)}
              >
                <AddCourseEventToCalendar
                  onClickCallback={() => setIsAddToCalendarOpen(false)}
                  event={event}
                  timezone={timezone}
                  course={course}
                  courseBlockTitle={courseBlock?.title}
                  courseSession={courseSession}
                  sourceCtaText="Add to Calendar"
                />
              </CalendarDropdownMenu>
            )}
          </div>
          {isAfterEvent && !event.recording && (
            <div
              className="flex items-center opacity-50"
              data-test="course_content_block_event_recording_coming_soon"
            >
              <PlayIcon className="mr-1" fill="currentColor" />
              <RfParagraphMediumBold>Recording Coming Soon</RfParagraphMediumBold>
            </div>
          )}
          {(isAfterEvent || hasCourseDatesOutsideCourseSession) && event.recording && (
            <Button
              data-test="course_content_block_event_watch_recording_button"
              onClick={onWatchRecordingClick}
              iconBefore={<PlayIcon fill="currentColor" />}
              variant="text-only"
              className="w-full py-1.5 lg:w-auto"
              size="x-small"
            >
              Watch Recording
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
