import { compareAsc, compareDesc } from 'date-fns'

import {
  MarketingCourseCourseCardPartsFragment,
  MyCourseCclCourseInProgressPartsFragment,
  MyCourseCclCoursePartsFragment,
  MyCourseUserCohortPartsFragment,
  MyCoursesCurrentUserPartsFragment,
  MyCoursesUserCohortsPartsFragment,
  MyCoursesUserCoursePartsFragment,
  MyCoursesUserCoursesPartsFragment,
  MyCoursesUserProgramPartsFragment
} from 'gql'

import { isFirstPilotCourseSession } from 'utils/courseUtils'

export const processUserCoursesData = (
  userCourses?: MyCoursesUserCoursesPartsFragment | null,
  userCohorts?: MyCoursesUserCohortsPartsFragment | null,
  userProgramsInProgress?: MyCoursesUserProgramPartsFragment[] | null,
  inProgressCourses?: MyCourseCclCourseInProgressPartsFragment[] | null,
  legacyMarketingCourses?: MarketingCourseCourseCardPartsFragment[] | null,
  marketplaceMarketingCourses?: MarketingCourseCourseCardPartsFragment[] | null,
  user?: MyCoursesCurrentUserPartsFragment | null
) => {
  const legacyCoursesByProgramSlug =
    legacyMarketingCourses?.reduce(
      (acc, legacyCourse) => {
        if (!legacyCourse.cmsProgramSlug) {
          return acc
        }

        acc[legacyCourse.cmsProgramSlug] = legacyCourse
        return acc
      },
      {} as { [index: string]: MarketingCourseCourseCardPartsFragment }
    ) || {}

  const marketplaceCoursesByCourseSanityId =
    marketplaceMarketingCourses?.reduce(
      (acc, marketplaceCourse) => {
        const courseSanityId = marketplaceCourse?.course?.id
        if (!courseSanityId) {
          return acc
        }

        acc[courseSanityId] = marketplaceCourse
        return acc
      },
      {} as { [index: string]: MarketingCourseCourseCardPartsFragment }
    ) || {}

  const currentUserCourses =
    userCourses?.all?.filter((userCourse) => userCourse.courseSession.isCurrent) || []
  const upcomingUserCourses = userCourses?.upcoming || []
  const previousUserCourses =
    userCourses?.previous?.filter(
      (userCourse) => !isFirstPilotCourseSession(userCourse.courseSession)
    ) || []
  const previousUserCohorts = userCohorts?.previous || []

  const completedLegacyOnDemand: MyCoursesUserProgramPartsFragment[] = []
  const inProgressLegacyOnDemand: MyCoursesUserProgramPartsFragment[] = []
  const inProgressMarketplaceOnDemand: MyCourseCclCourseInProgressPartsFragment[] = []

  const programIdsInCohorts = previousUserCohorts.reduce(
    (acc: { [index: string]: boolean }, userCohort: MyCourseUserCohortPartsFragment) => {
      acc[userCohort.cohort.cmsProgram.id] = true
      return acc
    },
    {} as { [index: string]: boolean }
  )

  userProgramsInProgress?.forEach((userProgram) => {
    if (programIdsInCohorts[userProgram.cmsProgram.id]) {
      return
    }

    if (userProgram.progressPercent === 100) {
      completedLegacyOnDemand.push(userProgram)
    } else {
      inProgressLegacyOnDemand.push(userProgram)
    }
  })

  inProgressCourses?.forEach((inProgressCourse) => {
    if (
      !currentUserCourses.find(
        (userCourse) =>
          userCourse.cclCourse?.staticId === inProgressCourse.cclCourse?.staticId
      )
    ) {
      inProgressMarketplaceOnDemand.push(inProgressCourse)
    }
  })

  const sortedCurrentUserCourseSortables = currentUserCourses
    .map((userCourse) => ({
      entity: userCourse,
      sortDatetime: userCourse.cclCourseSession?.startsAt
    }))
    .sort(sortByDatetimeAscFn)

  const sortedUpcomingUserCourseSortables = upcomingUserCourses
    .map((userCourse) => ({
      entity: userCourse,
      sortDatetime: userCourse.cclCourseSession?.startsAt
    }))
    .sort(sortByDatetimeAscFn)

  // Sortables for in-progress legacy on-demand courses
  const inProgressLegacyOnDemandSortables = inProgressLegacyOnDemand
    .map((userProgram) =>
      legacyCoursesByProgramSlug[userProgram.cmsProgram.slug]
        ? {
            entity: userProgram,
            sortDatetime: userProgram.updatedAt
          }
        : null
    )
    .filter(Boolean) as SortableByDatetimeType[]

  // Sortables for in-progress marketplace on-demand courses
  const inProgressMarketplaceOnDemandSortables = inProgressMarketplaceOnDemand.map(
    (inProgressCourse) => ({
      entity: inProgressCourse.cclCourse,
      sortDatetime: inProgressCourse.latestActivityAt
    })
  )

  // Combine and sort in-progress sortables
  const sortedInProgressMarketplaceOnDemandAndLegacySortables = [
    ...inProgressLegacyOnDemandSortables,
    ...inProgressMarketplaceOnDemandSortables
  ].sort(sortByDatetimeDescFn)

  // Previous user courses sortables
  const previousUserCoursesSortables = previousUserCourses.map((userCourse) => ({
    entity: userCourse,
    sortDatetime: userCourse.cclCourseSession?.endsAt || userCourse.courseSession?.endsAt
  }))

  // Completed legacy on-demand sortables
  const completedLegacyOnDemandSortables = completedLegacyOnDemand.map((userProgram) => ({
    entity: userProgram,
    sortDatetime: userProgram.updatedAt
  }))

  // Previous user cohorts sortables
  const previousUserCohortsSortables = previousUserCohorts.map((userCohort) => ({
    entity: userCohort,
    sortDatetime: userCohort.cohort.endsAt
  }))

  // Combine sorted sortables for in-progress and completed
  const sortedInProgressSortables = [
    ...sortedCurrentUserCourseSortables,
    ...sortedUpcomingUserCourseSortables,
    ...sortedInProgressMarketplaceOnDemandAndLegacySortables
  ]

  const sortedCompletedSortables = [
    ...previousUserCoursesSortables,
    ...completedLegacyOnDemandSortables,
    ...previousUserCohortsSortables
  ].sort(sortByDatetimeDescFn)

  return {
    legacyCoursesByProgramSlug,
    marketplaceCoursesByCourseSanityId,
    currentUserCourses,
    upcomingUserCourses,
    previousUserCourses,
    previousUserCohorts,
    completedLegacyOnDemand,
    inProgressLegacyOnDemand,
    inProgressMarketplaceOnDemand,
    sortedInProgressSortables,
    sortedCompletedSortables,
    user
  }
}

// enables sorting different types of entities by datetime
export type SortableByDatetimeType = {
  entity:
    | MyCoursesUserCoursePartsFragment
    | MyCoursesUserProgramPartsFragment
    | MyCourseUserCohortPartsFragment
    | MyCourseCclCoursePartsFragment
  sortDatetime?: string | null
}

export const sortByDatetimeDescFn = (
  a: SortableByDatetimeType,
  b: SortableByDatetimeType
) => {
  if (!a.sortDatetime || !b.sortDatetime) {
    return 0
  }

  return compareDesc(new Date(a.sortDatetime), new Date(b.sortDatetime))
}

export const sortByDatetimeAscFn = (
  a: SortableByDatetimeType,
  b: SortableByDatetimeType
) => {
  if (!a.sortDatetime || !b.sortDatetime) {
    return 0
  }

  return compareAsc(new Date(b.sortDatetime), new Date(a.sortDatetime))
}
