import { SVGIcon } from 'components/Icon'

import { THANK_YOU_MODAL_ID } from 'constants/elementIds'

export default function ThankYouModal() {
  return (
    <div
      id={THANK_YOU_MODAL_ID}
      uk-modal='{"container": false, "bg-close": false, "esc-close": false}'
    >
      <div className="uk-modal-dialog flex h-auto w-11/12 flex-col items-center py-[30px] sm:w-[600px] tl:w-[825px]">
        <SVGIcon
          className="mb-[35px] stroke-[0.2px]"
          name="check"
          fill={'#00C361'}
          width="45"
          height="33"
          strokeWidth="1px"
        />
        <div className="font-medium">Thank you!</div>
      </div>
    </div>
  )
}
