import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { Loading } from 'components'

import { Page, usePrivacyPolicyPageQuery } from 'gql'

const PrivacyPolicyPage = () => {
  const { data, loading } = usePrivacyPolicyPageQuery()
  const page = (data?.privacyPolicyPage || {}) as Page

  return (
    <MarketingLayout page={page}>
      {loading ? <Loading /> : <PrivacyPolicy />}
    </MarketingLayout>
  )
}

export const PrivacyPolicy = () => {
  return (
    <div className="grid grid-cols-1 px-[16px] py-[24px] md:grid-cols-2 md:justify-between md:px-[4vw] md:pt-[96px] md:pb-[72px]">
      <h1 className="mb-[72px] font-normal fluid-text-8xl md:mr-[4vw]">
        Reforge&apos;s Privacy Policy
      </h1>
      <div className="block-content">
        <h2 className="font-medium fluid-text-7xl md:mb-[20px]">Privacy Policy</h2>
        <p>
          The Reforge Inc. Privacy Policy helps you to understand how we collect and use
          the personal information you provide to us and to assist you in making informed
          decisions when using our site and our products and services.
        </p>
        <p>
          <strong>What Information Do We Collect?</strong>
        </p>
        <p>
          When you visit our website you may provide us with different types information
          such as: information about yourself that you knowingly choose to disclose that
          is collected on an individual basis, website use information, and email
          engagement information.
        </p>
        <p>1. PERSONAL INFORMATION YOU CHOOSE TO PROVIDE</p>
        <p>
          You will provide us your name, email address, LinkedIn account and profile
          information, work experience and other information when you apply or sign up for
          a Reforge program.&nbsp;
        </p>
        <p>2. WEBSITE USE AND EMAIL ENGAGEMENT INFORMATION</p>
        <p>
          Similar to other commercial websites, this site utilizes a standard technology
          called “cookies” and Web server logs to collect information about how this site
          is used. Information gathered through cookies and web server logs may include
          the date and time of visits, IP address, the pages viewed, time spent at this
          site, and the websites visited just before and just after our site. We, our
          advertisers and ad serving companies may also use small technology or pieces of
          code to determine which advertisements and promotions users have seen and how
          users responded to them.
        </p>
        <p>
          We also track email engagement data including which emails you open and which
          links you click on.&nbsp;
        </p>
        <p>3. OTHER INFORMATION</p>
        <p>
          In addition, we may collect other information. This includes non-digital
          engagement data such as which events you attend; company information such as
          your company name, size, and industry. We may also obtain data from third party
          data providers with information that includes your job title, location, gender,
          links to your social media profile, and company information.&nbsp;
        </p>
        <p>
          <strong>How Do We Use the Information That You Provide to Us?</strong>
        </p>
        <p>We never sell your information to anyone.</p>
        <p>
          Broadly speaking, we use personal information for purposes of administering and
          expanding our business activities, providing customer service and making
          available other products and services to our customers and prospective
          customers.
        </p>
        <p>
          Examples of how we use this information include: suggesting the most relevant
          program and content for you based on your experiences and profile; personalizing
          communications; changing the frequency of communications based on your profile
          and preferences.
        </p>
        <p>
          <strong>Sharing Information with Third Parties</strong>
        </p>
        <p>
          The information we collect is used to improve the content of this site and the
          quality of our service, and is not shared with or sold to other organizations
          for commercial purposes. That being said, your information could be shared under
          the following circumstances:
        </p>
        <p>
          We use third parties to facilitate our business, including, but not limited to,
          sending email, processing payments, processing customer support requests, and
          managing our website. In connection with these offerings and business
          operations, these third parties may have access to your personal information for
          use in connection with those business activities. Third parties include, but is
          not limited to, Autopilot, Segment, Zapier, Squarespace, SendGrid, Google, Help
          Scout, and Stripe.&nbsp;
        </p>
        <p>
          If it becomes necessary to share information in order to investigate, prevent,
          or take action regarding illegal activities, suspected fraud, situations
          involving potential threats to the physical safety of any person, violations of
          our Contract, or as otherwise required by law.
        </p>
        <p>
          We may disclose your personal information if required to do so by law or
          subpoena or if we believe that such action is necessary to (a) conform to the
          law or comply with legal process served on us or affiliated parties; (b) protect
          and defend our rights and property, our site, the users of our site, and/or our
          affiliated parties; (c) act under circumstances to protect the safety of users
          of our site, us, or third parties.&nbsp;
        </p>
        <p>
          <strong>How Do We Secure Information Transmissions?</strong>
        </p>
        <p>
          Email is not recognized as a secure medium of communication. For this reason, we
          request that you do not send private information to us by email. Some of the
          information you may enter on this site may be transmitted securely via Secure
          Sockets Layer SSL, 128 bit encryption services. Pages utilizing this technology
          will have URLs that start with HTTPS instead of HTTP.
        </p>
        <p>
          <strong>What About Other Websites Linked to This Site?</strong>
        </p>
        <p>
          We are not responsible for the practices employed by websites linked to or from
          our site nor the information or content contained therein. Often links to other
          websites are provided solely as pointers to information on topics that may be
          useful to the users of our site. Please remember that when you use a link to go
          from our site to another website, our Privacy Policy is no longer in effect.
          Your browsing and interaction on any other website, including sites which have a
          link on our site, is subject to that website’s own rules and policies. Please
          read over those rules and policies before proceeding.&nbsp;
        </p>
        <p>
          <strong>Your Consent</strong>&nbsp;
        </p>
        <p>
          By using this site, applying or signing up for a program,&nbsp;you consent to
          our collection and use of your personal information as described in this Privacy
          Policy. If we change our privacy policies and procedures, we will notify you of
          the changes pursuant to the notice procedure set forth in the Contract.
        </p>
        <p>
          <strong>How Can You Withdraw or Refuse Consent?</strong>
        </p>
        <p>
          You can withdraw or refuse consent at any time without detriment in the
          following ways:You may unsubscribe from our emails at any time by clicking the
          “unsubscribe” button at the bottom of the email. By unsubscribing, you will no
          longer receive marketing emails from Reforge. If you would like to access the
          data we have stored about you, you can do so by emailing{' '}
          <a href="mailto:hello@reforge.com">hello@reforge.com</a>. If you would like us
          to erase your personal data, you can do so by emailing{' '}
          <a href="mailto:hello@reforge.com">hello@reforge.com</a>. Our website does not
          provide Do Not Track requests at this time, which means our third party
          analytics providers may associate your usage on Reforge with your usage on other
          sites.
        </p>
      </div>
    </div>
  )
}

export default PrivacyPolicyPage
