import { Dispatch, SetStateAction, createContext, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import ExpertCollaborators from 'domains/Cms/ExpertCollaborators'
import LessonHeader from 'domains/Cms/LessonHeader'
import CmsToc from 'domains/Cms/Toc'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'
import LessonViewerMainContent from 'domains/LessonViewer/LessonViewerMainContent'
import LessonViewerRightSideNav from 'domains/LessonViewer/LessonViewerRightSideNav'
import ProgramNotificationModal from 'domains/Program/ProgramNotificationModal'

import DiscoveryDrawer from 'components/DiscoveryDrawer'
import { SectionPage } from 'components/SectionPage'
import { SideDrawer, SideDrawerProvider, useSideDrawer } from 'components/SideDrawer'

import {
  BookmarkType,
  CmsSectionContentType,
  CurrentUserPartsFragment,
  LessonViewerQuery,
  ProgramBookmarkPartsFragment,
  useBookmarkFoldersQuery
} from 'gql'

import { track } from 'utils/tracking/segment'

import { WistiaVideo } from 'typings/WistiaVideo'

type WistiaPlayerState = {
  wistiaPlayer: WistiaVideo | undefined | null
  setWistiaPlayer: Dispatch<SetStateAction<WistiaVideo | undefined | null>>
}
export const WistiaPlayerContext = createContext<WistiaPlayerState>({
  wistiaPlayer: undefined,
  setWistiaPlayer: () => {}
})

interface LessonViewerContainerProps {
  currentUser?: CurrentUserPartsFragment
  lessonViewerData: LessonViewerQuery
  currentLessonId: string
  contentBookmarkId: string
  needsFeedback: boolean
  cohortSlug?: string
  fromCohortViewer?: boolean
  isInSavedItems: boolean
  setIsInSavedItems: (isInSavedItems: boolean) => void
}

const leftDrawerId = 'lessonContentsDrawer'
const rightDrawerId = 'discoveryDrawer'

const LessonViewerContainer = ({
  currentUser,
  lessonViewerData,
  currentLessonId,
  contentBookmarkId,
  isInSavedItems,
  setIsInSavedItems,
  needsFeedback,
  cohortSlug,
  fromCohortViewer
}: LessonViewerContainerProps) => {
  const { lessonViewer } = lessonViewerData

  const { isDrawerOpen, toggleDrawer } = useSideDrawer()

  const [activeDiscoveryDrawerPanel, setActiveDiscoveryDrawerPanel] =
    useState('bookmarks')

  const [wistiaPlayer, setWistiaPlayer] = useState(null)

  const onSetSidenavOpen = useCallback((isOpen: boolean) => {
    // @ts-ignore - 'Content Viewer - Action' event is not defined in Segment JIRA#REF-5159
    track('Content Viewer - Action', {
      action: isOpen ? 'ToC__show' : 'ToC__hide',
      location: window.location.pathname
    })
  }, [])

  const { data: bookmarkFolderData } = useBookmarkFoldersQuery({
    skip: !currentUser
  })

  const {
    isAddToBookmarkFolderModalOpen,
    currentBookmarkForDropdown,
    openAddToBookmarkFolderModal,
    closeAddToBookmarkFolderModal
  } = useOpenAddToBookmarkFolderModal()
  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    closeCreateBookmarkFolderModal,
    openCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()

  const handleOpenAddToBookmarkFolderModal = (bookmark: ProgramBookmarkPartsFragment) => {
    if (isDrawerOpen(leftDrawerId)) toggleDrawer(leftDrawerId)
    openAddToBookmarkFolderModal(bookmark)
  }

  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }

  const { cmsSectionSlug, cmsSectionParentSlug } = useParams<{
    cmsSectionSlug: string
    cmsSectionParentSlug: string
  }>()

  if (
    !lessonViewer?.cmsProgram ||
    !lessonViewer?.cmsModule ||
    !lessonViewer?.cmsSection
  ) {
    return null
  }

  const { cmsProgram, cmsModule, cmsSection, userProgram } = lessonViewer
  const isSectionPage = Boolean(
    cmsSectionSlug && !cmsSectionParentSlug && !(cmsSection.contentType === 'Lesson')
  )

  const trackActionButtonClick = (type: 'discussions' | 'bookmarks') => {
    const actionTrigger = {
      discussions: 'comment_button_header',
      bookmarks: 'bookmark_button_header'
    }[type]

    const anchor = {
      discussions: 'discovery-drawer-discussions-button',
      bookmarks: 'discovery-drawer-bookmarks-button'
    }[type]

    // @ts-ignore - 'Content Viewer - Action' event is not defined in Segment JIRA#REF-5159
    track('Content Viewer - Action', {
      user_id: currentUser?.id,
      cms_module_id: cmsModule.id,
      cms_program_id: cmsProgram.id,
      cms_section_id: cmsSection.id,
      action: 'display_content_viewer_drawer',
      anchor,
      action_trigger: actionTrigger,
      location: window.location.pathname
    })
  }

  const openOnLoad = cmsSection.contentType === CmsSectionContentType.RESOURCE

  return (
    <SideDrawerProvider onSetIsOpen={onSetSidenavOpen} openDrawerId={leftDrawerId}>
      <WistiaPlayerContext.Provider value={{ wistiaPlayer, setWistiaPlayer }}>
        <div
          className={twMerge(
            'uk-grid-collapse min-h-screen',
            fromCohortViewer ? 'w-full' : 'ml-0'
          )}
          uk-grid=""
        >
          <div className="uk-width-expand">
            <div className="flex justify-between">
              <SideDrawer
                className={twMerge(
                  'uk-overflow-auto border-solid border-rb-gray-100 bg-white md:transition-all md:duration-300 md:ease-out',
                  fromCohortViewer && 'top-[68px] box-border lg:ml-0',
                  !currentUser && 'hidden lg:block tl:top-24 2xl:top-32'
                )}
                id={leftDrawerId}
                openOnLoad={openOnLoad}
                openClasses="tl:border-r-[1px]"
              >
                <div className="cms-sidebar box-border min-w-[400px] pt-0 pr-0 pb-[30px] font-sans text-[15px] text-rb-black">
                  <CmsToc
                    cohortViewer={fromCohortViewer}
                    cohortSlug={cohortSlug}
                    currentUser={currentUser}
                    lessonViewer={lessonViewer}
                    currentLessonId={currentLessonId}
                  />
                </div>
              </SideDrawer>
              <div
                className={twMerge(
                  'uk-width-expand cms-container relative',
                  fromCohortViewer && 'w-full',
                  !currentUser && 'mx-auto w-full sm:w-auto'
                )}
              >
                <LessonHeader
                  currentUser={currentUser}
                  userProgram={userProgram}
                  cmsSection={cmsSection}
                  cmsProgram={cmsProgram}
                  cmsModule={cmsModule}
                  viewAccess={lessonViewer.viewAccess}
                  leftDrawerId={leftDrawerId}
                  rightDrawerId={rightDrawerId}
                  setActivePanel={setActiveDiscoveryDrawerPanel}
                  activePanel={activeDiscoveryDrawerPanel}
                  isCohortViewer={fromCohortViewer}
                  trackActionButtonClick={trackActionButtonClick}
                  openAddToBookmarkFolderModal={handleOpenAddToBookmarkFolderModal}
                  isInSavedItems={isInSavedItems}
                  setIsInSavedItems={setIsInSavedItems}
                />

                {!currentUser && (
                  <div className="lg:hidden xs:px-4 md:px-8 mt-8">
                    <p className="cms-sidebar__title m-0 mt-4 font-sans text-base font-medium leading-[24px] tracking-normal !text-rb-gray-500">
                      Expert Collaborators
                    </p>
                    <ExpertCollaborators experts={lessonViewer.expertUsers || []} />
                  </div>
                )}

                {currentUser && (
                  <>
                    <AddBookmarkToFolderModal
                      isOpen={isAddToBookmarkFolderModalOpen}
                      handleClose={closeAddToBookmarkFolderModal}
                      bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders}
                      openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
                      currentBookmarkForDropdown={currentBookmarkForDropdown}
                      showCollectionsOnboardingInfo={
                        !bookmarkFolderData?.currentUser?.hasSeenCollectionsPrompt
                      }
                    />
                    <CreateBookmarkFolderModal
                      isModalOpen={isCreateBookmarkFolderModalOpen}
                      handleClose={closeCreateBookmarkFolderModal}
                      currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
                      trackingTriggerAction={'bookmark'}
                    />
                  </>
                )}
                <div id="content">
                  {isSectionPage && (
                    <SectionPage
                      lessons={cmsSection.children}
                      cmsSectionName={cmsSection.name}
                      currentUser={currentUser}
                      isEnrolled={cmsSection.cmsProgram?.enrolled}
                    />
                  )}
                  <LessonViewerMainContent
                    lessonViewerData={lessonViewerData}
                    currentUser={currentUser}
                    needsFeedback={needsFeedback}
                    isSectionPage={isSectionPage}
                    fromCohortViewer={fromCohortViewer}
                    cohortSlug={cohortSlug}
                    setActiveDiscoveryDrawerPanel={setActiveDiscoveryDrawerPanel}
                    trackActionButtonClick={trackActionButtonClick}
                    openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                  />
                </div>
                <div
                  id="cms_section_feedbacks"
                  data-answered={needsFeedback ? 'false' : 'true'}
                />
              </div>
              {currentUser && (
                <>
                  <DiscoveryDrawer
                    id={rightDrawerId}
                    activePanel={activeDiscoveryDrawerPanel}
                    setActivePanel={setActiveDiscoveryDrawerPanel}
                    contentBookmarkId={contentBookmarkId}
                    isInSavedItems={isInSavedItems}
                    setIsInSavedItems={setIsInSavedItems}
                    cmsProgramId={cmsProgram.id}
                    cmsSectionId={cmsSection.id}
                    bookmarkType={BookmarkType.LESSONBOOKMARK}
                    cmsModuleId={cmsModule.id}
                    userId={currentUser.id}
                    openAddToBookmarkFolderModal={handleOpenAddToBookmarkFolderModal}
                    isCohortViewer={fromCohortViewer}
                  >
                    <DiscoveryDrawer.BookmarksPanel
                      isActive={activeDiscoveryDrawerPanel === 'bookmarks'}
                      cmsProgramId={cmsProgram.id}
                      cmsModuleId={cmsModule.id}
                      cmsSectionId={currentLessonId}
                      cmsProgramName={cmsProgram.name}
                    />
                    <DiscoveryDrawer.DiscussionsPanel
                      isActive={activeDiscoveryDrawerPanel === 'discussions'}
                      cmsProgramId={cmsProgram.id}
                      cmsModuleId={cmsModule.id}
                      cmsSectionId={currentLessonId}
                      userId={currentUser.id}
                      isTrial={false}
                    />
                  </DiscoveryDrawer>
                  <a
                    id="right-sidenav-toggle"
                    uk-toggle="target: #right-sidenav; cls: right-nav-revealed"
                    hidden
                  ></a>
                  {cmsProgram.programNotification && (
                    <ProgramNotificationModal
                      notificationName={cmsProgram.programNotification.notificationName}
                      title={cmsProgram.programNotification.title}
                      message={cmsProgram.programNotification.message}
                      imageUrl={cmsProgram.programNotification.imageUrl}
                    />
                  )}

                  <LessonViewerRightSideNav
                    currentUser={currentUser}
                    cmsSection={cmsSection}
                    setActivePanel={setActiveDiscoveryDrawerPanel}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </WistiaPlayerContext.Provider>
    </SideDrawerProvider>
  )
}

export default LessonViewerContainer
