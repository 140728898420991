import { closing, opening } from './sharedMarkdown'

const programName = 'Technical Strategy'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://www.reforge.com/technical-strategy), because I will learn how to define a technical strategy, optimize it for **[your company]**, assess trade-offs more effectively, execute successfully and ensure my team is best supporting **[your company]**’s strategy. This course was built with [Harsh Sinha](https://www.reforge.com/experts/harsh-sinha) and [Bryan Dove](https://www.reforge.com/experts/bryan-dove), and covers:
* **The Technical Strategy Portfolio.** You will learn The Types of Engineering Work framework, which will help you assess your own portfolio, communicate the value of work across critical stakeholders, and negotiate time and resources for scale more effectively.
* **Company and Technical Strategy Calibration.** Learn how to connect cross-company strategies like acquisition, monetization, and finance to your technical decisions. Learn how different company archetypes approach funding, financial objectives, and resource allocation, all of which will impact your technical strategy and portfolio of work.
* **Strategic Technical Trade-Offs.** Learn how to participate more effectively in early-stage strategy discussions by generating better ideas for technical solutions and improving your abilities to analyze long-term technical trade-offs.
* **Strategic Execution.** Learn how to manage tech debt systematically and strategically, while delivering customer value continuously. Gain practical tools to help you best sequence work to prioritize customer value, leverage shorter iteration cycles, and define clear milestones around what really matters.

&NewLine;

${closing}
`

export default { markdown, title: programName }
