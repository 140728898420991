import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import {
  CourseDashboardCourseBlockPartsFragment,
  CourseDetailCourseBlockPartsFragment,
  CourseIdentifierPartsFragment,
  CourseSessionIdentifierPartsFragment
} from 'gql'

import useTableOfContentHighlighter from 'hooks/useTableOfContentHighlighter'

import { useContentMode } from 'utils/contentTrackingUtils'

import { ReactComponent as VideoIcon } from 'images/icon--video.svg'

export interface CourseContentBlockNavigationProps {
  courseBlocks?: CourseDashboardCourseBlockPartsFragment[] | null
  cclCourseBlocks?: CourseDetailCourseBlockPartsFragment[] | null
  course: CourseIdentifierPartsFragment
  courseSession?: CourseSessionIdentifierPartsFragment
}

export const CourseContentBlockNavigation = ({
  courseBlocks,
  cclCourseBlocks,
  courseSession,
  course
}: CourseContentBlockNavigationProps) => {
  const contentMode = useContentMode({ courseSession })
  const querySelector = courseBlocks?.map((block) => `[id='${block?.id}']`) || []
  useTableOfContentHighlighter(
    querySelector.join(', '),
    ['underline', 'underline-offset-4'],
    {
      root: document.querySelector('#page'),
      rootMargin: '-60% 0px -40% 0px'
    }
  )

  const handleClick = (
    courseBlock: CourseDashboardCourseBlockPartsFragment,
    index: number
  ) => {
    window.analytics.track('Content Outline Section Click', {
      location: 'course_dashboard',
      content_block_title: courseBlock.title,
      content_block_sanity_id: courseBlock.id,
      content_block_number: index,
      related_identifiers: {
        content_type: 'course',
        content_block_id: courseBlock.id,
        content_mode: contentMode,
        course_id: course.id,
        course_title: course.title,
        course_session_id: courseSession?.id,
        sanity_course_id: course.sanityId
      }
    })
  }

  return (
    <div className="flex flex-wrap max-w-full flex-row gap-4 overflow-y-auto lg:sticky lg:top-44 lg:flex-col lg:gap-2">
      {courseBlocks?.length &&
        courseBlocks.map(
          (courseBlock: CourseDashboardCourseBlockPartsFragment, i: number) => (
            <div
              className="flex items-center justify-end gap-4 truncate max-w-full"
              key={i}
              data-test="course_block_navigation_link_container"
            >
              <a
                data-test="course_block_navigation_link"
                className="text-right group"
                href={`#${courseBlock?.id}`}
                onClick={() => handleClick(courseBlock, i)}
              >
                {cclCourseBlocks?.[i]?.hasVideoContent && (
                  <VideoIcon height="16" width="16" className="inline-block mr-2" />
                )}
                <RfParagraphSmall
                  className="w-fit inline-block
                group-hover:underline group-hover:underline-offset-4 group-[.underline]:underline
                group-[.underline-offset-4]:underline-offset-4
                "
                >
                  {courseBlock.title}
                </RfParagraphSmall>
              </a>
            </div>
          )
        )}
    </div>
  )
}

export default CourseContentBlockNavigation
