import { Link } from 'react-router-dom'

import useURLParams from 'hooks/useURLParams'

const SearchPagePagination = ({
  page,
  searchResultsTotalCount
}: {
  page: number
  searchResultsTotalCount: number
}) => {
  const currentPage = +(page || 1)
  const prevPage = currentPage - 1
  const nextPage = currentPage + 1
  const totalPages = Math.min(10, Math.ceil(searchResultsTotalCount / 10))

  const { setParam, getQueryString } = useURLParams()

  const formatLinkUrl = (pageNum: number) => {
    setParam('page', pageNum.toString())
    return `/search?${getQueryString()}`
  }

  if (totalPages <= 1) return null

  return (
    <ul className="uk-pagination pt-8" uk-margin="">
      {currentPage > 1 && (
        <li>
          <Link to={formatLinkUrl(prevPage)}>
            <span uk-pagination-previous=""></span>
          </Link>
        </li>
      )}

      {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNum) => (
        <li className={pageNum === currentPage ? 'uk-active' : ''} key={pageNum}>
          <Link to={formatLinkUrl(pageNum)}>{pageNum}</Link>
        </li>
      ))}

      {currentPage < totalPages && (
        <li>
          <Link to={formatLinkUrl(nextPage)}>
            <span uk-pagination-next=""></span>
          </Link>
        </li>
      )}
    </ul>
  )
}

export default SearchPagePagination
