import { useState } from 'react'

import { ProfileSidebarSectionHeader } from 'pages/MemberProfilePage'

import { SVGIcon } from 'components/Icon'
import {
  ShareableSnapshotModal,
  useShareableSnapshotModal
} from 'components/ShareableSnapshotModal'

import { MemberHostedCohortFieldsFragment, MemberProfileUserFieldsFragment } from 'gql'

import { formatSeasonName } from 'utils/cohortUtils'

interface ProgramListProps {
  user: MemberProfileUserFieldsFragment
  isCurrentUser: boolean
}

interface CohortsSectionProps {
  cohort: MemberHostedCohortFieldsFragment
  isCurrentUser: boolean
  numberCohorts: number
  idx: number
}

function CohortsSection({
  cohort,
  isCurrentUser,
  numberCohorts,
  idx
}: CohortsSectionProps) {
  const { canShowSnapshotModal, showShareableSnapshotModal } = useShareableSnapshotModal()

  const programName = cohort.cmsProgram.name.replace(' Deep Dive', '')

  return (
    <div key={`${cohort.season}${idx}`}>
      <div className="flex items-center">
        <div
          style={{
            backgroundImage: `url(${cohort.cmsProgram.iconImageUrl || ''})`,
            backgroundSize: 22,
            backgroundPosition: 4
          }}
          className="h-7 w-7 rounded-[46px] bg-center bg-no-repeat shadow-default"
        />
        <div className="ml-4">
          <div className="text-xs font-medium uppercase tracking-widest text-rb-gray-300">
            {formatSeasonName(cohort.season)}
          </div>
          <div className="text-base font-semibold leading-4">{programName}</div>
          {isCurrentUser && canShowSnapshotModal(cohort.id) && (
            <>
              <button
                className="text-rb-black focus:outline-none"
                data-test="member-profile-snapshot-entrypoint"
                onClick={() => {
                  showShareableSnapshotModal('Member Profile')
                }}
              >
                View snapshot
              </button>
              <ShareableSnapshotModal page="Member Profile" />
            </>
          )}
        </div>
      </div>
      {idx + 1 < numberCohorts && <div className="my-1 ml-4 h-3.5" />}
    </div>
  )
}

export default function HostedCohortsList({ user, isCurrentUser }: ProgramListProps) {
  const [isActive, setIsActive] = useState(false)

  const hostedCohorts = user.cohortsHosted

  if (!hostedCohorts.length) return null

  const numCohorts = Object.keys(hostedCohorts).length

  const abbreviatedCohorts = hostedCohorts.slice(0, 3)

  return (
    <div>
      <ProfileSidebarSectionHeader title={'Hosted Cohorts'} />
      <div>
        {abbreviatedCohorts.map((cohort, idx) => {
          return (
            <CohortsSection
              cohort={cohort}
              isCurrentUser={isCurrentUser}
              numberCohorts={abbreviatedCohorts.length}
              idx={idx}
              key={idx}
            />
          )
        })}
      </div>
      {numCohorts > 3 && (
        <div className="accordion-menu">
          <div className="mt-4 mb-2 flow-root" onClick={() => setIsActive(!isActive)}>
            <div className="float-left text-base leading-4 text-rb-gray-300">
              {!isActive ? 'Show' : 'Hide'} {numCohorts - 3} more
            </div>
            <SVGIcon
              name={!isActive ? 'thin-chevron-up' : 'thin-chevron-down'}
              fill="currentColor"
              stroke="currentColor"
              width="15"
              className="float-right text-rb-gray-300 opacity-0 transition-opacity lg:opacity-100"
            />
          </div>
          {isActive && (
            <div>
              {hostedCohorts.slice(3).map((cohort, idx) => {
                return (
                  <CohortsSection
                    cohort={cohort}
                    isCurrentUser={isCurrentUser}
                    numberCohorts={numCohorts - 3}
                    idx={idx}
                    key={idx}
                  />
                )
              })}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
