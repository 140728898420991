import { ReactComponent as PlusIcon } from 'icon--plus-sign.svg'

import BookmarkFolderEmptyState from 'domains/Bookmarks/BookmarkFolderEmptyState'
import SharedBookmarkFolderEmptyState from 'domains/Bookmarks/SharedBookmarkFolderEmptyState'
import { DEFAULT_COLLECTION_NAME } from 'domains/Collections/collectionConstants'

import Button from 'components/Button'
import BookmarkFolderCard from 'components/cards/BookmarkFolderCard'

import { BookmarkFolderPartsFragment, ProgramBookmarkPartsFragment } from 'gql'

interface CollectionFolderListProps {
  bookmarkFolders?: BookmarkFolderPartsFragment[]
  openCreateBookmarkFolderModal: (bookmark: ProgramBookmarkPartsFragment | null) => void
  currentUserId: string
}

const CollectionFolderList = ({
  bookmarkFolders,
  currentUserId,
  openCreateBookmarkFolderModal
}: CollectionFolderListProps) => {
  const ownedBookmarkFolders = bookmarkFolders?.filter(
    (folder: BookmarkFolderPartsFragment) => folder?.user?.id === currentUserId
  )
  const sharedNonOwnedBookmarkFolders = bookmarkFolders?.filter(
    (folder: BookmarkFolderPartsFragment) => folder?.user?.id !== currentUserId
  )

  const hasSharedFolders = !!sharedNonOwnedBookmarkFolders?.length
  const hasOwnedFolders = !!ownedBookmarkFolders?.length

  return (
    <div>
      <div className="my-8 flex items-center justify-between">
        <div className="mb-0 text-xl font-semibold text-rb-gray-400 md:mb-0">
          My Collections
        </div>
        <Button
          size="x-small"
          onClick={() => openCreateBookmarkFolderModal(null)}
          shape="rounded"
          iconAfter={<PlusIcon />}
          iconClassName="sm:ml-2.5 ml-0"
        >
          <span className="hidden sm:inline-block">Create Collection</span>
        </Button>
      </div>
      {hasOwnedFolders ? (
        <div className="mb-4 grid grid-cols-[repeat(auto-fill,minmax(252px,auto))] gap-[20px] p-0">
          {ownedBookmarkFolders?.map((folder: BookmarkFolderPartsFragment) => (
            <BookmarkFolderCard
              key={folder.id}
              bookmarkFolder={folder}
              hasControls={
                folder?.user?.id === currentUserId &&
                folder?.name !== DEFAULT_COLLECTION_NAME
              }
            />
          ))}
        </div>
      ) : (
        <div className="pt-4">
          <BookmarkFolderEmptyState />
        </div>
      )}
      <div className="mt-16 mb-8">
        <div className="mb-0 text-xl font-semibold text-rb-gray-400 md:mb-0">
          Shared With Me
        </div>
      </div>
      {hasSharedFolders ? (
        <div className="mb-4 grid grid-cols-[repeat(auto-fill,minmax(252px,auto))] gap-[20px] p-0">
          {sharedNonOwnedBookmarkFolders?.map((folder: BookmarkFolderPartsFragment) => (
            <BookmarkFolderCard
              key={folder.id}
              bookmarkFolder={folder}
              hasControls={
                folder?.user?.id === currentUserId &&
                folder?.name !== DEFAULT_COLLECTION_NAME
              }
            />
          ))}
        </div>
      ) : (
        <div className="pt-4">
          <SharedBookmarkFolderEmptyState />
        </div>
      )}
    </div>
  )
}

export default CollectionFolderList
