import { useEffect, useState } from 'react'

import CheckboxFilter from 'components/CheckboxFilter'

import { FilterProps } from './helpers'

const PROFESSIONAL_RELATIONSHIPS = [
  'Mentor Others',
  'Find a Mentor',
  'Grow My Network',
  'Discuss Material'
]

const CommunityInterests = ({ filters, setFilters }: FilterProps) => {
  const name = 'professionalRelationship'
  const filteredProfessionalRelationships = filters[name]
  const checkboxState = PROFESSIONAL_RELATIONSHIPS.map((relationship) => {
    return {
      name: relationship,
      boxLabel: relationship,
      checked: !!filteredProfessionalRelationships?.includes(relationship)
    }
  })

  const [checkboxes, setCheckboxes] = useState(checkboxState)

  useEffect(() => {
    setCheckboxes(checkboxState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredProfessionalRelationships])

  return (
    <div className="relationship">
      <CheckboxFilter
        checkboxes={checkboxes}
        filters={filters}
        label="community interests"
        name={name}
        setFilters={setFilters}
        text="Browse members by the community relationships they're seeking."
        setCheckboxes={setCheckboxes}
      />
    </div>
  )
}

export default CommunityInterests
