import { ApolloError } from '@apollo/client'
import { Redirect, matchPath, useLocation } from 'react-router-dom'

import { ErrorMessage, Loading } from 'components'

import { EventCountdownPageEventPartsFragment, EventQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { isAfterDate } from 'utils/date'

interface BlockEventPageProps {
  event?: EventQuery['event'] | EventCountdownPageEventPartsFragment
  eventLoading: boolean
  eventError?: ApolloError
  children: any
  allowRedirectToDashboard?: boolean
}

const BlockEventPage = ({
  event,
  eventLoading,
  eventError,
  children,
  allowRedirectToDashboard = false
}: BlockEventPageProps) => {
  const { showCohortDashboard } = useFeatureFlags()
  const { pathname, search } = useLocation()
  const { currentUser, currentUserLoading, currentUserError } = useCurrentUser()

  const loading = currentUserLoading || eventLoading
  const error = currentUserError || eventError

  // redirect user to 404 page if event is not found, either because it does not exist or unpublished
  if (error?.message === 'The specified event was not found') {
    window.location.assign('/404')
    return null
  }

  if (error) return <ErrorMessage error={error} />
  if (loading) return <Loading />

  if (!event) {
    return <ErrorMessage error="Failed to load event." />
  }

  if (event.isPublic) return children

  if (!currentUser) return <Redirect to="/login" />

  const userCan = currentUser.can
  const hasNoEventAccess = !userCan.viewEventsIndex && !userCan.viewEvent
  const hasEventIndexAccessOnly = userCan.viewEventsIndex && !userCan.viewEvent
  const isCohortEvent = event.kind === 'Cohort Event'
  const isAskAnExpertEvent =
    event.name.toLowerCase().includes('ask an expert') && event.cohorts?.[0]

  // this block handles redirecting to the Cohort Dashboard Event Page
  if (
    allowRedirectToDashboard &&
    showCohortDashboard &&
    !!matchPath(pathname, { path: '/events/*' }) &&
    (isCohortEvent || isAskAnExpertEvent)
  ) {
    const cohort = event.cohorts?.[0]
    const showCohortViewerAt = cohort?.season?.showCohortViewerAt
    const currentUserCohort = currentUser?.cohorts?.all.find(
      (obj) => obj.cohort.id === cohort.id
    )

    if (
      currentUserCohort &&
      Boolean(showCohortViewerAt) &&
      isAfterDate(showCohortViewerAt)
    ) {
      return <Redirect to={`/cohorts/${cohort.slug}${pathname}${search}`} />
    }
  }

  if (hasNoEventAccess) return <Redirect to="/" />
  if (hasEventIndexAccessOnly) return <Redirect to="/events" />

  return children
}

export default BlockEventPage
