import dayjs from 'dayjs'

import { Concept } from 'pages/CourseSeoLandingPage/CourseSeoLandingPage'

import SeoPageSectionSubtitle from 'domains/Seo/Section/SeoPageSectionSubtitle'
import SeoPageSectionTitle from 'domains/Seo/Section/SeoPageSectionTitle'

import { Loading } from 'components'
import Button from 'components/Button'

import { CourseForTopicAndFunction } from 'gql'

import { trackCtaClicked } from 'utils/tracking/analytics'

import CourseSeoLargeCard from '../CourseSeoLargeCard'

interface PopularCoursesSectionProps {
  concept: Concept
  courses: CourseForTopicAndFunction[]
  totalCourseCount?: number
  coursesLoading: boolean
}

const PopularCoursesSection = ({
  concept,
  courses,
  totalCourseCount,
  coursesLoading
}: PopularCoursesSectionProps) => {
  const ctaText = 'View all courses'
  const ctaHref = '/courses'
  const ctaLocation = 'SEO most popular courses list'

  const onCtaClick = () => {
    trackCtaClicked({
      cta_type: 'button',
      destination: ctaHref,
      cta_location: ctaLocation,
      text: ctaText.toLowerCase(),
      related_identifiers: {
        sanity_type: 'topic',
        sanity_id: concept.id || '',
        sanity_name: concept.title.toLowerCase()
      }
    })
  }

  return (
    <section className="mb-[104px] mt-12">
      <SeoPageSectionTitle>
        Popular {concept.title.toLowerCase()} courses
      </SeoPageSectionTitle>

      <SeoPageSectionSubtitle>
        Explore {dayjs().format('MMMM')}’s most popular {concept.title.toLowerCase()}{' '}
        courses
      </SeoPageSectionSubtitle>

      {coursesLoading ? (
        <Loading />
      ) : (
        courses.map((course) => <CourseSeoLargeCard course={course} key={course.id} />)
      )}

      {totalCourseCount && (
        <p className="mb-2 text-sm leading-[1.5] text-rb-gray-400 md:text-base md:leading-[1.6]">
          View all {totalCourseCount} courses from top experts
        </p>
      )}

      <Button
        color="teal"
        shape="rounded"
        size="small"
        href={ctaHref}
        onClick={onCtaClick}
      >
        {ctaText}
      </Button>
    </section>
  )
}

export default PopularCoursesSection
