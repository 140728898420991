import { ReactElement, cloneElement, useState } from 'react'
import ReactDOMServer from 'react-dom/server'

import { FAQ } from 'components/FAQs'
import Modal from 'components/Modal'
import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphMediumSemiBold from 'components/typography/RfParagraph/RfParagraphMediumSemiBold'
import RfParagraphSmallSemiBold from 'components/typography/RfParagraph/RfParagraphSmallSemiBold'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import { onEnterKeyPress } from 'utils/keyboard'

import { ReactComponent as EnvelopeSharpIcon } from 'images/icon--envelope-sharp.svg'
import { ReactComponent as SlackIcon } from 'images/slack-icon.svg'

const SUPPORT_EMAIL = 'hello@reforge.com'

const SupportLink = () => (
  <a href="mailto:hello@reforge.com" target="_blank" rel="noreferrer">
    hello@reforge.com
  </a>
)

export type qa = {
  question: string
  answer: ReactElement
}

export interface Faq {
  faqCategory: string
  qas: qa[]
}

const FAQS: Array<Faq> = [
  {
    faqCategory: 'Live Events',
    qas: [
      {
        question:
          'How many events should I attend per week during the Course? What happens if I miss an event?',
        answer: (
          <>
            <p>
              You should attend one of the weekly events each week during the Course, and
              we ask that you RSVP to the session you plan to attend. If your Course
              offers two weekly events, the 8:30 am PT and 5:00 pm PT sessions follow the
              same format. In addition to the weekly event, some Courses offer optional
              Ask an Expert events, which provide time and space to unpack and discuss
              program content with your host. If you cannot make an event you RSVP&apos;ed
              to, please un-RSVP. You can un-RSVP on all event pages.{' '}
            </p>
            <br />
            <p>
              If you are unable to join an event, all of our events are recorded. Both
              videos and transcriptions will be available within 2 business days. However,
              in order to receive your completion certificate you must attend all events.
              Learn more about the requirements for completion certificates in the FAQ
              “How do I earn a Completion Certificate”.{' '}
            </p>
          </>
        )
      },
      {
        question: 'My Course offers 2 weekly events. How do I switch to the other event?',
        answer: (
          <p>
            If your program offers both an 8:30 am PT and 5 pm PT weekly event, you can
            attend the event that best fits in your schedule. In order to join the second
            session, navigate to the event in your Course dashboard, click on the event
            time, and switch your RSVP to the other session. If you also added all events
            to your calendar through the AddEvent tool, you will be able to see both
            sessions and can join the session you are able to attend.{' '}
          </p>
        )
      },
      {
        question: "I'm having issues accessing an event. What do I do?", // eslint-disable-line quotes
        answer: (
          <p>
            You will be able to join the waiting room for live events 5 minutes before the
            event starts. We have found that some browser extensions prevent members from
            joining the live event - if you launch the Reforge platform in an Incognito
            browser, this should prevent the issue. If you are still having issues
            accessing an event, please reach out immediately to <SupportLink />- we
            actively monitor this email during events and will work with you to ensure you
            can join.{' '}
          </p>
        )
      },
      {
        question:
          "If I haven't been able to review the content, should I still attend the weekly event?", // eslint-disable-line quotes
        answer: (
          <p>
            Yes, the weekly event will still be valuable even if you haven&apos;t been
            able to review the content. Make sure to review the pre-read in advance of the
            event, and try to complete the week&apos;s key lessons if you have time.{' '}
          </p>
        )
      },
      {
        question: 'When is the next Course event?',
        answer: (
          <p>
            Weekly events are scheduled to start at 8:30 am PT. Some programs offer a
            second session starting at 5:00 pm PT. These events will take place on the
            same day & time every week. Additionally, some programs offer optional Ask an
            Expert events, which provide time and space to unpack and discuss program
            content with your host. You will be able to see the schedule for your live
            events by clicking into each week of your Course dashboard to determine which
            events are coming up.{' '}
          </p>
        )
      }
    ]
  },
  {
    faqCategory: 'Content',
    qas: [
      {
        question: 'What are key lessons?',
        answer: (
          <p>
            The key lessons are a subset of lessons within each week that are most
            relevant for weekly event. If you&apos;re short on time and can&apos;t
            complete all the week&apos;s content before the weekly event, focus your
            attention on the key lessons. To get the most value out of the program, we
            encourage you to go through all the lessons, not only the key lessons.
          </p>
        )
      },
      {
        question: 'What do I do if I fall behind?',
        answer: (
          <>
            <p>
              If you aren&apos;t able to follow your live program&apos;s weekly schedule,
              we get it. Life happens! There&apos;s still time to get the most out of your
              program. First, you should still attend the weekly event even if you are
              unable to complete the program content. When you&apos;re squeezed for time
              before the event, focus on the key lessons.
            </p>
            <br />
            <p>
              If a week goes by and you&apos;re unable to complete the content and attend
              the weekly event, we recommend that you review the module recap videos
              before moving on to the next week. When you have additional time, you can
              also watch the event recording of the event, which are available within two
              business days after the event takes place. These recordings will also be
              available for you after the Course is done.
            </p>
          </>
        )
      },
      {
        question: 'Do I have access to the content after the Course is over?',
        answer: (
          <p>
            Yes, you will have access to all the program content and recordings for the
            program you&apos;re enrolled in.
          </p>
        )
      },
      {
        question: 'What can I use the Reforge content for? Can I share it publicly?',
        answer: (
          <>
            <p>
              Your Reforge license gives you access to Reforge program content for the use
              of applying the content and learnings to your role, product, company, and
              career. All other uses are not allowed.
            </p>
            <br />
            <p>
              Examples Of Uses That Are Not Allowed <br />
              <br />
              <strong>Content Marketing &ndash; </strong>Using Reforge content,
              frameworks, concepts, or descriptions of concepts for content marketing
              purposes (blog posts, ebooks, presentations, or other formats) for a company
              or person. <br />
              <strong>Sharing Logins &ndash; </strong>Sharing login credentials with any
              other individuals. <br />
              <strong>Public Presentations &ndash; </strong>Using Reforge content,
              frameworks, concepts, or descriptions of concepts for use in presentations
              outside of your company and team. <br />
              <strong>Information Or Software Products &ndash; </strong>Incorporating
              Reforge content, frameworks, concepts, or descriptions of concepts in other
              information or software products.
            </p>
            <br />
            <p>
              Your access to Reforge content will be revoked without refund if we find you
              to violate this policy. Reforge may also pursue additional legal action for
              damages incurred. If you&apos;d like to license Reforge content for other
              purposes, please email <SupportLink />.
            </p>
          </>
        )
      },
      {
        question: 'Can I view the content offline?',
        answer: (
          <p>
            At this time, we do not offer a way to download or consume content offline.
            The reason for this is to protect our content and IP from copyright
            infringement. For those concerned about this due to travel, we welcome you to
            check out the mobile browser experience.
          </p>
        )
      }
    ]
  },
  {
    faqCategory: 'Other',
    qas: [
      {
        question:
          "What is Reforge's approach to grading, passing/failing, and completion?", // eslint-disable-line quotes
        answer: (
          <>
            <p>
              There are no tests, no quizzes, and no grades. We don&apos;t want you to
              prove how much you learned through these artificial mechanisms. Instead,
              we&apos;re excited to see the impact you drive in your company and your
              career. That will be the best indicator of what you learned.
            </p>
            <br />
            <p>
              However, we do offer a Completion Certificate to validate your completion of
              the live program. Here&apos;s more on how the completion certificate works.
            </p>
            <br />
            <p>
              Live events are core to the course experience. We treat attendance as
              mandatory to help you get the most out of your time. At the end of the
              course, you will receive a completion certificate if you attend the events
              for every week of the program.
            </p>
            <br />
            <p>
              We know that this isn&apos;t always feasible, so wanted to share some
              additional details around this requirement: <br />
              <ul>
                <li>
                  Life happens! One absence is permitted during the course (Ask an Expert
                  events are optional and do not count towards completion).{' '}
                </li>
                <li>
                  If you are in a prohibitive time zone (e.g., they&apos;re in the middle
                  of the night), reach out to hello@reforge.com to waive the requirement
                  in lieu of you watching the weekly event recording.
                </li>
              </ul>
            </p>
          </>
        )
      },
      {
        question: 'How much time should I dedicate to the Course?',
        answer: (
          <p>
            To get the most out of the program, we expect you to devote a minimum of 3-5
            hours per week, including a 90-minute weekly event. However, many members will
            devote additional time to consuming content more deeply, filling in provided
            templates, and engaging in Slack.
            <ul>
              <li>
                Live event case study (at end of week):&nbsp;90 minutes plus 5-10 min
                reviewing case pre-read in advance.
              </li>
              <li>Consume content:&nbsp;1 - 3 hours depending on the program</li>
              <li>Engage asynchronously:&nbsp;30 - 90 min per week</li>
            </ul>
          </p>
        )
      },
      {
        question: 'Is it OK to set up 1:1 time with an EIR or Featured Guest?',
        answer: (
          <p>
            Our EIRs and featured guests are very busy! While we&apos;d love to be able to
            offer 1:1 sessions to meet with them, we ask that you do not reach out to set
            up 1:1 time.
          </p>
        )
      },
      {
        question: 'How do I stay in touch with fellow members after the Course?',
        answer: (
          <p>
            You will be able to continue asynchronous discussions with your fellow members
            through Reforge&apos;s member Slack.
          </p>
        )
      }
    ]
  }
]

export interface SupportModalProps {
  isOpen: boolean
  onClose: () => void
}

const SupportModal = ({ isOpen, onClose }: SupportModalProps) => {
  const [ctaText, setCtaText] = useState('Copy')

  const copyText = () => {
    navigator.clipboard.writeText(SUPPORT_EMAIL)
    setCtaText('Copied!')
  }

  const openSlack = () => {
    window.open('slack://channel?team=T01LD82F0M8&id=C01MD30FQE6') // #reforge-help channel
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={onClose}
      fullWidth={true}
      className="overflow-hidden rounded-xl sm:mx-[40px] md:max-w-[689px]"
    >
      <div className="overflow-auto p-12 pt-0">
        <RfHeader3SemiBold>Reforge support</RfHeader3SemiBold>
        <RfParagraphMedium className="mb-8">
          You can contact us in the following ways
        </RfParagraphMedium>
        <ContactSupportItem
          onClick={copyText}
          text={SUPPORT_EMAIL}
          icon={<EnvelopeSharpIcon />}
          ctaText={ctaText}
        />
        <ContactSupportItem
          onClick={openSlack}
          text="#reforge-help"
          icon={<SlackIcon />}
          ctaText="Go to Slack"
        />
        <div className="mt-20">
          <RfHeader3SemiBold className="!mb-12">
            Frequently asked questions
          </RfHeader3SemiBold>
          {FAQS.map((faq, idx) => (
            <div key={`faq_${idx + 1}`} className="pb-8">
              <RfParagraphMediumSemiBold className="pb-6">
                {faq.faqCategory}
              </RfParagraphMediumSemiBold>
              {faq.qas.map((qa) => (
                <FAQ
                  question={qa.question}
                  key={`faq_${idx + 1}`}
                  answer={ReactDOMServer.renderToString(qa.answer)}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}

interface ContactSupportItemProps {
  onClick: () => void
  text: string
  icon: ReactElement
  ctaText: string
}

const ContactSupportItem = ({
  text,
  icon,
  onClick,
  ctaText
}: ContactSupportItemProps) => {
  return (
    <div
      className="mt-4 flex h-16 w-full items-center justify-between rounded-xl border border-rb-gray-100 px-[20px] hover:border-rb-gray-500"
      role="button"
      onClick={onClick}
      tabIndex={0}
      onKeyUp={onEnterKeyPress(() => onClick())}
    >
      <div className="flex">
        {cloneElement(icon, { className: 'w-6 mr-[20px]' })}
        {text}
      </div>
      <RfParagraphSmallSemiBold color={ColorOptions.brandTeal200}>
        {ctaText}
      </RfParagraphSmallSemiBold>
    </div>
  )
}

export default SupportModal
