import { twMerge } from 'tailwind-merge'

import Progress from 'components/Progress'
import ColorCircleCheckIcon from 'components/icons/ColorCircleCheckIcon'

export interface ProgressThumbnailProps {
  heroImageUrl?: string | null
  progressPercent: number
  progressComplete: boolean
  className?: string
}

const ProgressThumbnail = ({
  heroImageUrl,
  progressPercent,
  progressComplete,
  className
}: ProgressThumbnailProps) => {
  return (
    <div className={twMerge('relative h-16 w-[109px]', className)}>
      <img
        src={heroImageUrl || ''}
        className={`h-full w-full object-cover ${
          progressComplete ? 'rounded-lg' : 'rounded-t-lg'
        }`}
        alt=""
      />
      {progressComplete && (
        <div
          className="absolute bottom-1 right-1"
          data-testid="progress-thumbnail-finished-check"
        >
          <ColorCircleCheckIcon name="color-circle-check" />
        </div>
      )}
      {!progressComplete && (
        <div data-testid="progress-thumbnail-progress-bar">
          <Progress
            classNames="w-full h-[5px] p-0 m-0"
            progressBarClasses={{
              progress: 'rounded-bl-xl',
              background: progressPercent === 0 ? 'rounded-b-xl' : 'rounded-br-xl'
            }}
            percentComplete={progressPercent}
            backgroundClass="bg-rb-gray-50"
          />
        </div>
      )}
    </div>
  )
}

export default ProgressThumbnail
