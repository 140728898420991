import { useParams } from 'react-router-dom'

import { SharedType } from 'typings/sharedType'

type PathParams = {
  cmsContentType: SharedType
  cmsSectionSlug: string
}

const useContentViewerUrlData = (): PathParams => {
  const matchedParams = useParams<PathParams>()

  return matchedParams
}

export default useContentViewerUrlData
