import { Link } from 'react-router-dom'

import { Function } from 'gql'

import { trackNavigationClicked } from 'utils/tracking/analytics'

export interface CourseSeoFunctionLinkProps {
  functionFilter: Function
}

export const CourseSeoFunctionLink = ({ functionFilter }: CourseSeoFunctionLinkProps) => {
  const { title, slug, id } = functionFilter
  const destination = `/course-categories/${slug}`

  const trackNavigation = () => {
    // track the click
    trackNavigationClicked({
      location: `SEO related function section (${title})`,
      text: title.toLowerCase(),
      type: 'link',
      related_identifiers: {
        sanity_type: 'function',
        sanity_id: id,
        sanity_name: title.toLowerCase()
      }
    })
  }

  return (
    <Link
      to={destination}
      className="inline-flex h-14 items-center rounded-lg border border-rb-gray-400 px-6 text-lg font-medium leading-[1.2] text-rb-gray-400 hover:text-rb-gray-400 hover:no-underline md:h-[4.5rem] md:px-8 md:leading-[1.4]"
      data-testid="course-seo-function-button"
      onClick={trackNavigation}
    >
      {title}
    </Link>
  )
}

export default CourseSeoFunctionLink
