import Button from 'components/Button'

import { SsoFeatureStatusEnum } from 'gql'

interface SamlCardProps {
  status?: SsoFeatureStatusEnum
  errors?: string[]
}

const LabelByStatus = {
  active: 'Active',
  inactive: 'Inactive',
  incomplete: 'Setup incomplete: Requires configuration',
  error: 'Error'
}

const LabelStyleByStatus = {
  active: 'text-rb-success-200',
  inactive: 'text-rb-destructive-100',
  incomplete: 'text-rb-warning-200',
  error: 'text-rb-destructive-100'
}

const MessagesByStatus = {
  active:
    'Please contact support if you have any questions or need to update configuration settings.',
  inactive: 'Please contact support to learn about setting up SAML for your team.',
  incomplete:
    'Your SAML has not been configured and can not be activated. Please complete your SAML configuration to enable. Please contact support if you have any questions.',
  error:
    'There is an issue with your SAML configuration. Your team’s access to Reforge may be suspended until corrected. Contact support to resolve this configuration issue.'
}

const SamlCard = ({ status = SsoFeatureStatusEnum.INACTIVE }: SamlCardProps) => {
  const label = LabelByStatus[status]
  const labelStyle = LabelStyleByStatus[status]
  const message = MessagesByStatus[status]

  return (
    <div>
      <div className="text-lg font-medium">SSO SAML</div>
      <div className={`${labelStyle}`}>{label}</div>
      <div>{message}</div>
      <div className="mt-4">
        <Button size="x-small" variant="outline" href="mailto:hello@reforge.com">
          Contact support
        </Button>
      </div>
    </div>
  )
}

export default SamlCard
