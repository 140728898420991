import { Route, Switch } from 'react-router-dom'

import { PurchaseFlowHeader } from './components/PurchaseFlowHeader'
import { BREADCRUMB_STEPS } from './components/PurchaseFlowHeader/PurchaseFlowHeader'
import {
  PURCHASE_FLOW_BASEPATH,
  PURCHASE_TYPE_MEMBERSHIP,
  PurchaseFlowProvider,
  steps
} from './contexts/PurchaseFlowContext'

const shouldShowNavigation = () => {
  const path = window.location.pathname
  let showNav = false

  BREADCRUMB_STEPS.forEach((step) => {
    if (path.includes(step.url)) {
      showNav = true
    }
  })

  return showNav
}

export const PurchaseFlow = () => {
  return (
    <PurchaseFlowProvider purchaseType={PURCHASE_TYPE_MEMBERSHIP}>
      {shouldShowNavigation() ? <PurchaseFlowHeader /> : null}
      <div className="sm:mt-28 md:mt-16">
        <Switch>
          {steps.map((step) => (
            <Route key={step.path} path={`${PURCHASE_FLOW_BASEPATH}/${step.path}`}>
              {step.component}
            </Route>
          ))}
        </Switch>
      </div>
    </PurchaseFlowProvider>
  )
}
