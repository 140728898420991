import { ReactElement, cloneElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { useCohortDashboardTrackingContext } from 'domains/CohortDashboard/hooks/useCohortDashboardTrackingContext'

import SupportModal from 'components/CohortDashboard/SupportModal'
import TemplatesModal from 'components/CohortDashboard/TemplatesModal'
import { useModal } from 'components/Modal'
import { ChevronRightThinIcon } from 'components/icons'
import SkeletonRfParagraph from 'components/skeletons/typography/SkeletonRfParagraph'
import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'

import { CohortCmsModulePartsFragment } from 'gql'

import { onEnterKeyPress } from 'utils/keyboard'
import { trackModalDisplayed } from 'utils/tracking/analytics'

import { ReactComponent as TemplatesIcon } from 'images/icon--cohort-template.svg'
import { ReactComponent as InfoIcon } from 'images/icon--info.svg'

interface CohortResourcesSkeletonProps {
  className?: string
}

export const CohortResourcesSkeleton = ({ className }: CohortResourcesSkeletonProps) => (
  <div className={twMerge('mt-16 flex animate-pulse flex-col space-y-4', className)}>
    <SkeletonRfParagraph className="h-3 w-full" />
    <CohortResourceItemSkeleton />
    <CohortResourceItemSkeleton />
  </div>
)

export interface CohortResourcesProps {
  templates?: CohortCmsModulePartsFragment | null
  programName: string
  cohortSlug: string
}

export const CohortResources = ({
  templates,
  programName,
  cohortSlug
}: CohortResourcesProps) => {
  const hasTemplates = templates?.cmsSections && templates.cmsSections.length > 0
  const {
    isModalOpen: isSupportModalOpen,
    openModal: openSupportModal,
    closeModal: closeSupportModal
  } = useModal()
  const {
    isModalOpen: isTemplatesModalOpen,
    openModal: openTemplatesModal,
    closeModal: closeTemplatesModal
  } = useModal()

  const { cohort, userCohortId } = useCohortDashboardTrackingContext()

  const onTemplatesModalOpen = () => {
    trackModalDisplayed({
      category: 'app',
      location: 'cohort_dashboard_templates_modal',
      modal_group: 'cohort_dashboard',
      modal_name: 'templates',
      related_identifiers: {
        cohort_id: cohort.id,
        cohort_name: cohort.name,
        user_cohort_id: userCohortId
      }
    })
    openTemplatesModal()
  }

  const onSupportModalOpen = () => {
    trackModalDisplayed({
      category: 'app',
      location: 'cohort_dashboard_support_modal',
      modal_group: 'cohort_dashboard',
      modal_name: 'support',
      related_identifiers: {
        cohort_id: cohort.id,
        cohort_name: cohort.slug,
        user_cohort_id: userCohortId
      }
    })
    openSupportModal()
  }

  return (
    <>
      <div>
        <RfHeader3SemiBold className="!mb-8">Course resources</RfHeader3SemiBold>
        <div className="space-y-4">
          {hasTemplates && (
            <CohortResourceItem
              onClick={onTemplatesModalOpen}
              text="Templates"
              icon={<TemplatesIcon />}
            />
          )}
          <CohortResourceItem
            onClick={onSupportModalOpen}
            text="Support & FAQs"
            icon={<InfoIcon />}
          />
        </div>
      </div>
      <SupportModal isOpen={isSupportModalOpen} onClose={closeSupportModal} />
      {hasTemplates && (
        <TemplatesModal
          isOpen={isTemplatesModalOpen}
          onClose={closeTemplatesModal}
          templates={templates}
          programName={programName}
          cohortSlug={cohortSlug}
        />
      )}
    </>
  )
}

interface CohortResourceItemProps {
  onClick: () => void
  text: string
  icon: ReactElement
}

const CohortResourceItemSkeleton = () => {
  return (
    <div className="rf-rb-card-interactive flex min-h-[4rem] min-w-full animate-pulse items-center justify-between rounded-xl p-4">
      <SkeletonRfParagraph className="mb-0 h-2/3 w-2/3" />
      <SkeletonRfParagraph className="mb-0 h-2/3 w-1/4" />
    </div>
  )
}

const CohortResourceItem = ({ text, icon, onClick }: CohortResourceItemProps) => {
  return (
    <div
      className="flex h-16 w-[312px] items-center justify-between rounded-xl border border-rb-gray-100 hover:border-rb-gray-500"
      role="button"
      onClick={onClick}
      tabIndex={0}
      onKeyUp={onEnterKeyPress(() => onClick())}
    >
      <div className="flex">
        {cloneElement(icon, { className: 'w-6 h-6 mx-[20px]' })}
        {text}
      </div>
      <ChevronRightThinIcon className="mx-[20px] w-3 fill-current" />
    </div>
  )
}
