import Button from 'components/Button'

import { SsoFeatureStatusEnum } from 'gql'

interface ScimCardProps {
  status?: SsoFeatureStatusEnum
  errors?: string[]
}

const LabelByStatus = {
  active: 'Active',
  inactive: 'Inactive',
  incomplete: 'Enabled: Requires configuration',
  error: 'Error'
}

const LabelStyleByStatus = {
  active: 'text-rb-success-200',
  inactive: 'text-rb-destructive-100',
  incomplete: 'text-rb-warning-200',
  error: 'text-rb-destructive-100'
}

const MessagesByStatus = {
  active:
    'Please contact support if you have any questions or need to update configuration settings.',
  inactive: 'Please contact support to learn about setting up SCIM for your team.',
  incomplete:
    'Your SCIM has not been configured and can not be activated. Please complete your SCIM configuration to enable. Please contact support if you have any questions.',
  error:
    'There is an issue with your SCIM configuration. Your directories may not sync until corrected. Contact support to resolve this configuration issue.'
}

const ScimCard = ({ status = SsoFeatureStatusEnum.INACTIVE }: ScimCardProps) => {
  const label = LabelByStatus[status]
  const labelStyle = LabelStyleByStatus[status]
  const message = MessagesByStatus[status]

  return (
    <div>
      <div className="text-lg font-medium">SCIM</div>
      <div className={`${labelStyle}`}>{label}</div>
      <div>{message}</div>
      <div className="mt-4">
        <Button size="x-small" variant="outline" href="mailto:hello@reforge.com">
          Contact support
        </Button>
      </div>
    </div>
  )
}

export default ScimCard
