import { trackApplicationFormAction } from 'utils/tracking/analytics'

interface AnalyticsTrackerMethods {
  registerEmailConsentClick: () => void
}

export const useAnalyticsTracker = (): AnalyticsTrackerMethods => {
  const registerEmailConsentClick = () => {
    trackApplicationFormAction({ action: 'email_consent_click' })
  }

  return {
    registerEmailConsentClick
  }
}
