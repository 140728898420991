import { ReactNode, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

// This hook can be used to make the value of showCollapsedHeader available to other components

export const useCollapsibleHeader = (
  uncollapsedHeaderId: string,
  collapsedHeaderDisplayOffset: number = 0
) => {
  const [showCollapsedHeader, setShowCollapsedHeader] = useState(false)

  const handleScroll = (e: IntersectionObserverEntry[]) => {
    const { isIntersecting } = e[0]

    setShowCollapsedHeader(!isIntersecting)
  }

  useEffect(() => {
    const element = document.querySelector(`#${uncollapsedHeaderId}`)

    if (!element) return

    const options = {
      root: document.querySelector('#page'),
      rootMargin: `${collapsedHeaderDisplayOffset}px`,
      threshold: 1.0
    }
    const observer = new IntersectionObserver(handleScroll, options)
    observer.observe(element)

    return () => {
      observer.disconnect()
    }
  }, [collapsedHeaderDisplayOffset, uncollapsedHeaderId])
  return { showCollapsedHeader }
}

interface CollapsibleHeaderProps {
  uncollapsedHeaderNode: ReactNode
  uncollapsedHeaderId: string
  collapsedHeaderNode: ReactNode
  collapsedTopOffset?: string
  uncollapsedOutOfViewportOffset?: number
}

const CollapsibleHeader = ({
  uncollapsedHeaderNode,
  uncollapsedHeaderId,
  collapsedHeaderNode,
  collapsedTopOffset = '',
  uncollapsedOutOfViewportOffset = 0
}: CollapsibleHeaderProps) => {
  const { showCollapsedHeader } = useCollapsibleHeader(
    uncollapsedHeaderId,
    uncollapsedOutOfViewportOffset
  )

  return (
    <>
      <div
        className={twMerge(
          'sticky z-10 w-full',
          showCollapsedHeader ? 'block' : 'hidden',
          collapsedTopOffset
        )}
      >
        {collapsedHeaderNode}
      </div>
      <div id={uncollapsedHeaderId}>{uncollapsedHeaderNode}</div>
    </>
  )
}

export default CollapsibleHeader
