import { ErrorMessage, Loading } from 'components'
import Status, { StatusTypes } from 'components/Status'

import { useUserPatronageQuery } from 'gql'

import useURLParams from 'hooks/useURLParams'

import redirectUtils from 'utils/redirects'

const MakePayment = () => {
  const { getParam } = useURLParams()
  const externalId = getParam('external_id')
  const { data, error, loading } = useUserPatronageQuery({ variables: { externalId } })

  if (loading) {
    return <Loading />
  }

  if (error || !data) {
    return <Status statusType={StatusTypes.PATRONAGE_USER_NOT_FOUND} />
  }

  const userDoesNotExist = !data?.userPatronage?.userExists
  const userHasActiveSubscription = data?.userPatronage?.userHasActiveSubscription
  const stripePaymentLink = data?.userPatronage?.stripePaymentLink
  if (userDoesNotExist) {
    return <Status statusType={StatusTypes.PATRONAGE_USER_NOT_FOUND} />
  } else if (userHasActiveSubscription) {
    return <Status statusType={StatusTypes.SUBSCRIPTION_ALREADY_ACTIVE} />
  } else if (stripePaymentLink) {
    redirectUtils.redirectTo(stripePaymentLink)
    return null
  } else {
    return <ErrorMessage />
  }
}

export default MakePayment
