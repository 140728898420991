import { PortableTextBlock } from '@portabletext/types'

import { UnitComplexPortableText } from 'domains/Sanity/PortableText/UnitComplexPortableText/UnitComplexPortableText'

import RfHeader2 from 'components/typography/RfHeader/RfHeader3SemiBold'

import { CopyAnchorLinkButton } from './CopyAnchorLinkButton'
import { UnitBlockContainer } from './UnitBlockContainer'

interface UnitRecapProps {
  recap?: PortableTextBlock[]
}

export const UnitRecap = ({ recap }: UnitRecapProps) => {
  if (!recap) return null

  const header = 'Recap'

  return (
    <UnitBlockContainer className="flex flex-col space-y-4">
      <div className="flex flex-row" data-test="unit_recap_section">
        <RfHeader2>{header}</RfHeader2>
        <CopyAnchorLinkButton anchorSlug="recap" className="ml-auto" />
      </div>
      <UnitComplexPortableText content={recap} />
    </UnitBlockContainer>
  )
}

export default UnitRecap
