import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef
} from 'react'
import { v4 as uuid } from 'uuid'

import { getHeaders } from 'domains/Ai/Chat/helpers'
import { Data, Message } from 'domains/Ai/types'

import { useChat as useChatHook } from 'hooks/ai/useChat'

export const ResultHighlightsContext = createContext<{
  messages: Message[]
  isLoading: boolean
} | null>(null)

export function ResultHighlightsProvider({
  query,
  children,
  searchType
}: {
  query: string
  children: ReactNode
  searchType: string
}) {
  const value = useResultsHighlightsInner({ query, searchType })

  return (
    <ResultHighlightsContext.Provider value={value}>
      {children}
    </ResultHighlightsContext.Provider>
  )
}

export function useResultHighlights() {
  const context = useContext(ResultHighlightsContext)
  if (!context) {
    throw new Error(
      'useResultHighlightsContext must be used within a ResultHighlightsProvider'
    )
  }
  return context
}

function useResultsHighlightsInner({
  query,
  searchType,
  useChat = useChatHook
}: {
  query: string
  searchType: string
  useChat?: typeof useChatHook
}) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const chatId = useMemo(() => uuid(), [query])
  const previousMessage = useRef<string>()

  const { messages, append, setMessages, isLoading } = useChat<Data>({
    api: '/api/v1/ai/chat?source=results-highlights',
    id: chatId,
    // also pass message id to backend
    sendExtraMessageFields: true,
    headers: getHeaders(),
    body: {
      mode: 'search',
      query,
      type: searchType
    }
  })

  const resetAndInitializeChat = useCallback(() => {
    if (previousMessage.current === query) {
      return
    }

    previousMessage.current = query
    setMessages([])
    query &&
      append(
        { role: 'user', content: query, mode: 'search' },
        {
          options: {
            body: { mode: 'search' }
          }
        }
      )
  }, [query, append, setMessages])

  useEffect(() => {
    resetAndInitializeChat()
  }, [resetAndInitializeChat])

  return useMemo(() => ({ messages, isLoading }), [messages, isLoading])
}
