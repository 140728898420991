import { Maybe } from 'graphql/jsutils/Maybe'

import { Host } from 'gql'

interface HostsColumnProps {
  hosts: Omit<Host, 'status'>[]
  label: string
}

const getHostUrl = (userId: Maybe<string>, name: Maybe<string>) => {
  if (userId) return `/directory/${userId}`
  else return `/directory?contains=${name}`
}

const HostsColumn = ({ hosts, label }: HostsColumnProps) => (
  <div className="mb-4 w-60">
    <div className="text-lg font-medium">{label}</div>
    {hosts.map((host, i) => (
      <a
        key={i}
        className="cursor-pointer text-rb-gray-500 hover:text-rb-teal-200 hover:no-underline"
        href={getHostUrl(host.userId, host.name)}
        target="_blank"
        rel="noreferrer"
      >
        <div className="mr-2 mt-5 flex">
          <img
            className="h-14 w-14 rounded-full"
            // thumbnailUrl is always defined
            src={host.thumbnailUrl!}
            alt={host.name || 'host'}
          />
          <div className="ml-3 ">
            <div className="text-md tracking-wide">{host.name}</div>
            <div className="mb-[5px] text-sm">{host.title}</div>
            <div className="text-light text-xs">{host.previousCompanies}</div>
          </div>
        </div>
      </a>
    ))}
  </div>
)

export default HostsColumn
