import { PropsWithChildren } from 'react'

import RfHeader3 from 'components/typography/RfHeader/RfHeader3'

interface RfHeader3Props {
  id?: string
  className?: string
}

const RfHeader3SemiBold = ({
  id,
  children,
  className
}: PropsWithChildren<RfHeader3Props>) => (
  <RfHeader3 className={className} id={id}>
    <span className="font-semibold">{children}</span>
  </RfHeader3>
)

export default RfHeader3SemiBold
