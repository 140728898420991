import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { ArtifactSeoTopicLink } from 'domains/ArtifactSeo/ArtifactSeoTopicLink'

import { ArtifactAuthor, ExpertUserProfileFragmentFragment } from 'gql'

export interface ProfileViewerProps {
  authorProfile: ArtifactAuthor
  overviewMode?: boolean
  userProfile: ExpertUserProfileFragmentFragment
}

const TopCreator = ({ authorProfile, userProfile, overviewMode }: ProfileViewerProps) => {
  const [authorTopics, setAuthorTopics] = useState([])
  const [artifactCount, setArtifactCount] = useState(0)

  useEffect(() => {
    const getArtifactCount = () => {
      let count = 0
      const topics: any = {}
      const workEntries = authorProfile.workEntries || []
      workEntries.forEach((workEntry) => {
        if (!workEntry.artifactContributions?.length) {
          return
        }

        const { artifactContributions } = workEntry
        count += artifactContributions.length

        artifactContributions.forEach(({ artifact }) => {
          const topicFilters = artifact?.topics || []

          for (const t of topicFilters) {
            topics[t.slug] = t
          }
        })
      })

      const sortedTopics: any = Object.values(topics).sort((a: any, b: any) => {
        // Ensure case-insensitivity by converting to lowercase
        const nameA = a.slug
        const nameB = b.slug

        if (nameA < nameB) return -1 // `a` comes before `b`
        if (nameA > nameB) return 1 // `b` comes before `a`
        return 0 // names are equal
      })

      setAuthorTopics(sortedTopics)
      setArtifactCount(count)
    }

    getArtifactCount()
  }, [authorProfile])

  return (
    <div className={twMerge('flex flex-col items-center', !overviewMode && 'mt-12')}>
      {(artifactCount >= 3 || userProfile?.is?.collective) && !!authorTopics.length && (
        <div className="rounded-md flex-col flex">
          <div className="font-sans text-base font-semibold text-rb-gray-400 mb-4">
            Top creator
          </div>

          <div className="flex flex-wrap items-center gap-3">
            {authorTopics?.map((topic: any) => {
              const tracking = {
                sanityType: 'subtopic',
                sanityId: topic.id!,
                sanityName: topic.title.toLowerCase()
              }

              return (
                <ArtifactSeoTopicLink
                  onProfilePage
                  topic={topic}
                  key={topic.slug}
                  tracking={tracking}
                  className="!h-10 hover:border-rb-teal-600 !p-5 font-sans text-sm w-fit"
                />
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default TopCreator
