import initMockTime from 'api/initMockTime'
import { FunctionComponent, useEffect, useState } from 'react'

import TimeMachine from 'domains/TimeMacine/TimeMachine'

const WithTimeMachine = <P extends Object>(
  Component: FunctionComponent<P>
): FunctionComponent<P> => {
  const WrappedComponent = ({ ...props }) => {
    const [timestamp, setTimestamp] = useState<number>()
    const init = async () => {
      const timestamp = await initMockTime()
      setTimestamp(timestamp)
    }
    useEffect(() => {
      init()
    }, [])

    return (
      <>
        {timestamp ? <TimeMachine /> : null}
        <Component {...(props as P)} />
      </>
    )
  }

  return WrappedComponent
}

export default WithTimeMachine
