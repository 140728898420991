import { publicApolloClient } from 'apolloClient'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import store from 'storejs'

import { Loading } from 'components'

import { useVerifyEmailMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

const EmailVerifyPageContainer = () => {
  const { nonce } = useParams<{ nonce: string }>()
  const { currentUser } = useCurrentUser()
  const [verifyEmail] = useVerifyEmailMutation({
    client: publicApolloClient
  })
  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    async function run() {
      try {
        // If nonce doesn't match what's in local storage, make the API call
        if (store.get('nonce') !== nonce) {
          store.set('nonce', nonce)
          const response = await verifyEmail({ variables: { input: { nonce } } })
          if (response?.data?.verifyEmail) {
            store.set('verifyEmailData', JSON.stringify(response?.data?.verifyEmail))
            setSuccess(true)
          } else {
            // If no email is found using that nonce, set success to false and clear local storage nonce
            setSuccess(false)
            store.set('nonce', '')
          }
        } else {
          // If the nonce matches what's in local storage, it's due to a reload,
          // so we can just show the success message
          setSuccess(true)
          store.set('nonce', nonce)
        }
        // Handle error from API call and clear local storage nonce
      } catch (e) {
        setSuccess(false)
        store.set('nonce', '')
      }
      setLoading(false)
    }
    run()
  }, [verifyEmail, nonce])

  if (loading) return <Loading />

  if (success) {
    return <EmailVerifySuccess isLoggedIn={!!currentUser} />
  } else {
    return <EmailVerifyFailure />
  }
}

const EmailVerifyFailure = () => (
  <div className="mx-auto mt-5 w-[450px] text-center">
    <h3>Email Verification Failed</h3>
    <div className="p-2.5 xs:p-4 md:p-8">
      We were unable to verify this email. This may be because you have already verified
      it. To check whether this is the case, go to your account page and look for the
      email address under Connected Accounts. If it does not say Resend Verify Email, then
      the email address has already been verified. If it does say Resend Verify Email,
      please try again or reach out to us at{' '}
      <a href="mailto:hello@reforge.com">hello@reforge.com</a>.
    </div>
  </div>
)

const EmailVerifySuccess = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const data = JSON.parse(store.get('verifyEmailData') || '{}')
  const { name, companyName, isPrimaryEmail } = data
  const path = isLoggedIn ? '/account' : '/login'
  return (
    <div className="mx-auto mt-5 w-[450px] text-center">
      <h3>Thanks for verifying your email{name ? `, ${name}.` : '.'}</h3>
      {companyName && (
        <p className="text-rb-success">
          We were able to verify this email belongs to {companyName}. Thank you.
        </p>
      )}

      {isPrimaryEmail ? (
        <p>
          This is your primary email address. If you’d like to make another address your
          primary email address, please go to <Link to={path}>your account</Link>.
        </p>
      ) : (
        <p>
          If you’d like to make this the primary email address, please go to{' '}
          <Link to={path}>your account</Link>.
        </p>
      )}
      <i>All communication is sent to your primary email address.</i>
    </div>
  )
}

export default EmailVerifyPageContainer
