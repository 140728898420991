import { useEffect, useState } from 'react'

import { useModal } from 'components/Modal'

import { CourseBookmarkPartsFragment, ProgramBookmarkPartsFragment } from 'gql'

import { trackModalDisplayed } from 'utils/tracking/analytics'

const useOpenCreateBookmarkFolderModal = () => {
  const [currentBookmarkForDropdown, setCurrentBookmarkForDropdown] = useState<
    ProgramBookmarkPartsFragment | CourseBookmarkPartsFragment | null | undefined
  >(undefined)

  const {
    isModalOpen: isCreateBookmarkFolderModalOpen,
    openModal,
    closeModal
  } = useModal()

  useEffect(() => {
    if (currentBookmarkForDropdown || currentBookmarkForDropdown === null) {
      openModal()
    }
  }, [currentBookmarkForDropdown, openModal])

  const openCreateBookmarkFolderModal = (
    bookmark: ProgramBookmarkPartsFragment | CourseBookmarkPartsFragment | null
  ) => {
    trackModalDisplayed({
      category: 'app',
      location: window.location.href,
      modal_name: 'create_new_collection_modal',
      modal_group: 'collections'
    })
    setCurrentBookmarkForDropdown(bookmark)
  }

  const closeCreateBookmarkFolderModal = () => {
    closeModal()
    setCurrentBookmarkForDropdown(undefined)
  }

  return {
    isCreateBookmarkFolderModalOpen,
    openCreateBookmarkFolderModal,
    closeCreateBookmarkFolderModal,
    currentBookmarkForDropdown
  }
}

export default useOpenCreateBookmarkFolderModal
