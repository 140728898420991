import * as React from 'react'

import { SVGIcon } from 'components/Icon'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

interface NoneElementProps {
  icon: string
  responseText: string | React.ReactElement
}

const NoneElement = ({ icon, responseText }: NoneElementProps) => (
  <div className="event-index__msg-box sm:w-11/12">
    <div
      className="uk-width-1-1 flex flex-col items-center justify-center bg-rb-gray-50 p-8 sm:w-11/12"
      style={{ height: '275px' }}
    >
      <div>
        <SVGIcon height="36" width="39" name={icon} />
      </div>
      <div className="uk-text-center uk-margin-top">{responseText}</div>
    </div>
  </div>
)

interface NoneAttendingProps {
  onLinkClick: () => void
}

export const NoAttendingElement = ({ onLinkClick }: NoneAttendingProps) => (
  <NoneElement
    icon="calendar"
    responseText={
      <RfParagraphMedium>
        Check back soon for upcoming events or{' '}
        <button type="button" className="text-blue-500" onClick={onLinkClick}>
          view a replay
        </button>
      </RfParagraphMedium>
    }
  />
)

export const NoUserPastElement = ({ onLinkClick }: NoneAttendingProps) => (
  <NoneElement
    icon="calendar"
    responseText={
      <RfParagraphMedium className={'text-rb-gray-300'}>
        You haven&apos;t attended any events in the past.
        <br />
        Your Reforge membership includes access to our catalog of{' '}
        <button type="button" className="text-blue-500" onClick={onLinkClick}>
          event replays
        </button>
      </RfParagraphMedium>
    }
  />
)

export const noPastElement = (
  <NoneElement
    icon="play-circle"
    responseText="After each event, you can access a recording of the session. Videos will be posted within 72 hours"
  />
)
