import { useEffect } from 'react'

import { UserCohortFilter } from 'pages/MemberDirectoryPage/helpers'

import Card from 'domains/Member/Card'
import NoneElement from 'domains/Member/NoneElement'

import { SkeletonMembersPage, SkeletonWrapper } from 'components/skeletons'

import { FilteredUserSearchFieldsFragment, UserSearchFilters } from 'gql'

interface ListProps {
  filters: UserSearchFilters
  handleResetAll: () => void
  isFetching: boolean
  isMobile: boolean
  members: FilteredUserSearchFieldsFragment[]
  numberOfFilters: number
  numberOfRecords: number
  setShowModalFilter: (show: boolean) => void
  showSidebarFilter: boolean
  userCohorts: UserCohortFilter[]
}

const List = ({
  filters,
  handleResetAll,
  isFetching,
  members,
  isMobile,
  numberOfFilters,
  numberOfRecords,
  setShowModalFilter,
  showSidebarFilter,
  userCohorts
}: ListProps) => {
  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }, [filters])

  const membersFoundText = numberOfRecords === 1 ? ' member found' : ' members found'

  return (
    <div className="w-full tl:w-[calc(100%-292px)] tl:pr-8 xl:w-[calc(75%-2rem)]">
      <SkeletonWrapper loading={isFetching} skeleton={<SkeletonMembersPage />}>
        <div className="mb-2 flex justify-between leading-[21px]">
          <div className="h-[33px] text-sm leading-[33px] text-rb-gray-300">
            {isFetching ? (
              ''
            ) : (
              <span>
                <strong>{numberOfRecords}</strong> {membersFoundText}
              </span>
            )}
          </div>
          {!showSidebarFilter && (
            <div
              className="rounded-2xl bg-rb-gray-50 py-2 px-2.5 text-center text-sm font-medium text-rb-gray-400"
              onClick={() => setShowModalFilter(true)}
              onKeyUp={() => setShowModalFilter(true)}
              role="button"
              tabIndex={0}
            >
              Filter{' '}
              {numberOfFilters > 0 && (
                <span className="inline-block h-3 w-3 bg-rb-gray-500 text-center align-middle text-[0.625rem] leading-3 text-white">
                  {numberOfFilters}
                </span>
              )}
            </div>
          )}
        </div>
        {!isFetching && members.length === 0 && (
          <NoneElement
            handleResetAll={handleResetAll}
            setShowModalFilter={setShowModalFilter}
          />
        )}
        {members.length > 0 &&
          members.map((member) => (
            <Card
              key={`user-${member.id}-container`}
              isMobile={isMobile}
              member={member}
              filtersToTrack={filters}
              userCohorts={userCohorts}
            />
          ))}
      </SkeletonWrapper>
    </div>
  )
}

export default List
