import { CommentSummaryFieldsFragment, ReplySummaryFieldsFragment } from 'gql'

type ReplyRecord = ReplySummaryFieldsFragment | CommentSummaryFieldsFragment

interface IReplySummary {
  record: ReplyRecord
}

const kindMap: Record<ReplyRecord['__typename'], string> = {
  Reply: 'reply',
  Comment: 'comment'
}

export default function ReplySummary({ record }: IReplySummary) {
  const handleClick = () => {
    window.location.href = `/posts/${record.post?.toParam}#${kindMap[record.__typename]}${
      record.id
    }`
  }

  return (
    <div
      onClick={handleClick}
      className="group mt-2.5 p-4 shadow-default sm:mt-4 sm:flex sm:cursor-pointer sm:py-8 sm:pr-4 sm:pl-8"
    >
      <div style={{ flexGrow: 1 }} className="overflow-hidden">
        <div className="flex text-rb-gray-300 sm:hidden">{record.createdAtInWords}</div>
        <div className="flex flex-grow">
          <div style={{ flexGrow: 1 }}>
            <div>
              Responded to{' '}
              <span className="font-medium group-hover:text-rb-blue-200 group-focus:text-rb-blue-200 group-active:text-rb-blue-200">
                {record.post?.title}
              </span>
            </div>
            <div
              className="uk-text-small uk-margin-small-top p-0 text-rb-gray-400 sm:flex-wrap sm:border-l-2 sm:border-solid sm:border-rb-gray-100 sm:px-4"
              dangerouslySetInnerHTML={{ __html: record.body || '' }}
            />
          </div>
          <div className="hidden text-rb-gray-300 sm:block sm:min-w-[100px] sm:text-right">
            {record.createdAtInWords}
          </div>
        </div>
      </div>
    </div>
  )
}
