import { CohortEvent } from 'domains/CohortViewer/utils'

import { InterventionEventSameInfoPartsFragment } from 'gql'

import {
  findRsvpdCohortEventInfo,
  hasCohortEventEnded,
  isCohortEventHappening1orLessDaysFromToday,
  isCohortEventHappening3orLessDaysFromToday,
  isCohortEventHappeningLaterToday,
  isCohortEventHappeningNow,
  isRsvpdToCohortEventInfo
} from 'utils/cohortUtils'

export const CONVERSATION_TOPIC_SLUG: string = 'conversations'

export const INTERVENTION_THRESHOLD: number = 20

interface InterventionEventSummary {
  event?: InterventionEventSameInfoPartsFragment
  isHappeningNow: boolean
  isHappeningLaterToday: boolean
  isHappeningTomorrow: boolean
  isHappening3orLessDaysFromToday: boolean
}

export interface InterventionSummary {
  rsvpdEvents: {
    coreEvent: InterventionEventSummary
    askAnExpertEvent: InterventionEventSummary
  }
  isHappeningNow: {
    coreEvent?: InterventionEventSameInfoPartsFragment
    askAnExpertEvent?: InterventionEventSameInfoPartsFragment
  }
  isHappeningLaterToday: {
    coreEvent?: InterventionEventSameInfoPartsFragment
    askAnExpertEvent?: InterventionEventSameInfoPartsFragment
  }
  isHappeningTomorrow: {
    coreEvent?: InterventionEventSameInfoPartsFragment
    askAnExpertEvent?: InterventionEventSameInfoPartsFragment
  }
  isHappening3orLessDaysFromToday: {
    coreEvent?: InterventionEventSameInfoPartsFragment
    askAnExpertEvent?: InterventionEventSameInfoPartsFragment
  }
}

export const getInterventionSummary = (events: CohortEvent[]): InterventionSummary => {
  const rsvpdCoreEvent = findRsvpdCohortEventInfo(events[0])
  const rsvpdAskanExpertEvent = findRsvpdCohortEventInfo(events[1])

  const coreEvent = events[0]
  const askAnExpertEvent = events[1]

  return {
    rsvpdEvents: {
      coreEvent: {
        event: rsvpdCoreEvent,
        isHappeningNow: isCohortEventHappeningNow(rsvpdCoreEvent),
        isHappeningLaterToday: isCohortEventHappeningLaterToday(rsvpdCoreEvent),
        isHappeningTomorrow: isCohortEventHappening1orLessDaysFromToday(rsvpdCoreEvent),
        isHappening3orLessDaysFromToday:
          isCohortEventHappening3orLessDaysFromToday(rsvpdCoreEvent)
      },
      askAnExpertEvent: {
        event: rsvpdAskanExpertEvent,
        isHappeningNow: isCohortEventHappeningNow(rsvpdAskanExpertEvent),
        isHappeningLaterToday: isCohortEventHappeningLaterToday(rsvpdAskanExpertEvent),
        isHappeningTomorrow:
          isCohortEventHappening1orLessDaysFromToday(rsvpdAskanExpertEvent),
        isHappening3orLessDaysFromToday:
          isCohortEventHappening3orLessDaysFromToday(rsvpdAskanExpertEvent)
      }
    },
    isHappeningNow: {
      coreEvent: coreEvent?.sameEventInfo.find((eventInfo) =>
        isCohortEventHappeningNow(eventInfo)
      ),
      askAnExpertEvent: askAnExpertEvent?.sameEventInfo.find((eventInfo) =>
        isCohortEventHappeningNow(eventInfo)
      )
    },
    isHappeningLaterToday: {
      coreEvent: coreEvent?.sameEventInfo.find((eventInfo) =>
        isCohortEventHappeningLaterToday(eventInfo)
      ),
      askAnExpertEvent: askAnExpertEvent?.sameEventInfo.find((eventInfo) =>
        isCohortEventHappeningLaterToday(eventInfo)
      )
    },
    isHappeningTomorrow: {
      coreEvent: coreEvent?.sameEventInfo.find((eventInfo) =>
        isCohortEventHappening1orLessDaysFromToday(eventInfo)
      ),
      askAnExpertEvent: askAnExpertEvent?.sameEventInfo.find((eventInfo) =>
        isCohortEventHappening1orLessDaysFromToday(eventInfo)
      )
    },
    isHappening3orLessDaysFromToday: {
      coreEvent: coreEvent?.sameEventInfo.find((eventInfo) =>
        isCohortEventHappening3orLessDaysFromToday(eventInfo)
      ),
      askAnExpertEvent: askAnExpertEvent?.sameEventInfo.find((eventInfo) =>
        isCohortEventHappening3orLessDaysFromToday(eventInfo)
      )
    }
  }
}

export interface EventSummary {
  event?: InterventionEventSameInfoPartsFragment
  type: 'Ask an Expert' | 'Core'
}

export const getRelevantEventSummary = (
  interventionSummary: InterventionSummary,
  eventSummaryType:
    | 'isHappeningNow'
    | 'isHappeningLaterToday'
    | 'isHappeningTomorrow'
    | 'isHappening3orLessDaysFromToday'
): EventSummary | null => {
  // rsvpd event is selected over unrsvpd events
  // check ask an expert events first because they happen last in the week
  // which means we can look from the end of the week backwards

  const eventSummary = (
    interventionEventType: 'askAnExpertEvent' | 'coreEvent',
    eventType: 'Ask an Expert' | 'Core',
    event: InterventionEventSameInfoPartsFragment
  ): EventSummary | null => {
    // check if event has been RSVPd - if so, return result based on RSVPd event
    if (interventionSummary.rsvpdEvents[interventionEventType].event) {
      if (interventionSummary.rsvpdEvents[interventionEventType][eventSummaryType]) {
        return {
          event: interventionSummary.rsvpdEvents[interventionEventType].event,
          type: eventType
        }
      }
    } else {
      return {
        event: event,
        type: eventType
      }
    }
    return null
  }

  const askAnExpertEventForEventSummaryType =
    interventionSummary[eventSummaryType].askAnExpertEvent

  if (askAnExpertEventForEventSummaryType) {
    return eventSummary(
      'askAnExpertEvent',
      'Ask an Expert',
      askAnExpertEventForEventSummaryType
    )
  }

  const coreEventForEventSummaryType = interventionSummary[eventSummaryType].coreEvent
  if (coreEventForEventSummaryType) {
    return eventSummary('coreEvent', 'Core', coreEventForEventSummaryType)
  }

  return null
}

export const lastRelevantEventIsOver = (events: CohortEvent[]) => {
  // if RSVPd to last event, only take into consideration the RSVPd time
  const lastCombinedEvent = events[events.length - 1]

  const rsvpdEventInfo = lastCombinedEvent.sameEventInfo.find((eventInfo) =>
    isRsvpdToCohortEventInfo(eventInfo)
  )

  if (rsvpdEventInfo) {
    return hasCohortEventEnded(rsvpdEventInfo)
  }

  const lastEventInfo =
    lastCombinedEvent.sameEventInfo[lastCombinedEvent.sameEventInfo.length - 1]

  return hasCohortEventEnded(lastEventInfo)
}
