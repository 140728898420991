import cx from 'classnames'

import { ComplexPortableText } from '../ComplexPortableText/ComplexPortableText'
import QuestionAndAnswerSection from './QuestionAndAnswerSection'

export interface GridProps {
  data: {
    size?: string
    columns?: {
      blocks: any // @todo: define type
      sizes?: any[] // @todo: define type
    }[]
  }
}

const Grid = ({ data = {} }: GridProps) => {
  const { size, columns } = data

  const getGridSize = (
    breakpoint: any,
    size: any,
    justify = false,
    align = false,
    start = false
  ) => {
    const hasBreakpoint = breakpoint && breakpoint.trim()
    const colSpan = hasBreakpoint ? `${breakpoint}:col-span-${size}` : `col-span-${size}`

    const colStart = hasBreakpoint
      ? `${breakpoint}:col-start-${start}`
      : `col-start-${start}`

    const colJustify = hasBreakpoint ? `${breakpoint}:${justify}` : justify
    const colAlign = hasBreakpoint ? `${breakpoint}:${align}` : align

    return cx(colSpan, start && colStart, justify && colJustify, align && colAlign)
  }

  return (
    <section className="section grid-content-section">
      <div className="section--content">
        <div
          className={`grid grid-cols-${size} col-span gap-x-[16px] gap-y-[16px] md:gap-x-[32px] xl:gap-x-[48px]`}
        >
          {columns?.map((col, key) => {
            const { sizes, blocks } = col

            return (
              <div
                key={key}
                className={cx(
                  sizes?.map((size) =>
                    getGridSize(
                      size.breakpoint,
                      size.width,
                      size.justify,
                      size.align,
                      size.start
                    )
                  )
                )}
              >
                {blocks.map((block: any, key: any) => (
                  <GridBlock key={key} block={block} />
                ))}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

const GridBlock = ({ block }: { block: any }) => {
  const type = block._type

  switch (type) {
    case 'freeform':
      return <ComplexPortableText content={block.content} />
    case 'questionAndAnswerSection':
      return <QuestionAndAnswerSection data={block} />
    default:
      return null
  }
}

export default Grid
