interface InlineImageProps {
  id?: string
  caption: string
  image: { url: string }
}

const InlineImage = ({ id, caption = '', image }: InlineImageProps) => (
  <div className="relative my-6 w-full max-w-2xl" id={id}>
    <img
      src={image.url}
      alt={caption}
      sizes="100vw"
      className="h-full w-full cursor-pointer rounded-sm border border-rb-gray-100 object-cover"
    />
  </div>
)

export default InlineImage
