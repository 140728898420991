import Image from 'domains/Sanity/Image'
import MarketingCourseCard from 'domains/Sanity/marketingSite/MarketingCourseCard'
import MarketingHero from 'domains/Sanity/marketingSite/MarketingHero'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { ErrorMessage, Loading } from 'components'

import {
  CourseIndex,
  CourseIndexContent,
  MarketingCourse,
  useMarketingCoursesIndexQuery
} from 'gql'

const OldLoggedOutCoursesIndex = () => {
  const { data, loading } = useMarketingCoursesIndexQuery()
  const { page, courses } = (data?.marketingCoursesIndex || {}) as CourseIndex

  if (!loading && !page) {
    return <ErrorMessage />
  }

  return (
    <MarketingLayout page={page}>
      {loading ? (
        <Loading />
      ) : (
        <OldLoggedOutCoursesIndexContent content={page.content} courses={courses} />
      )}
    </MarketingLayout>
  )
}

export const OldLoggedOutCoursesIndexContent = ({
  content,
  courses
}: {
  content: CourseIndexContent
  courses: MarketingCourse[]
}) => {
  return (
    <>
      <MarketingHero
        headerText={content.heading}
        ctas={[
          {
            href: content.ctaLink,
            text: content.ctaText
          }
        ]}
        heroImage={{
          url: content.heroImage.imageUrl || '',
          alt: content.heroImage.imageAlt
        }}
        noCtaMargin
        contentClassName="pb-[6vw] md:pb-[12vw] md:pt-[6vw]"
      />

      <section className="px-[16px] pb-[6.6vmax] md:max-w-[85%] md:px-[4vw] ">
        <h3 className="font-normal tracking-tight fluid-text-3xl">
          {content.subheading}
        </h3>
      </section>

      <section className="grid grid-cols-1 gap-[26px] px-[16px] pb-[6.6vmax] md:px-[4vw] tl:grid-cols-3">
        {content.courseBenefits.map((benefit) => (
          <div
            className="mb-[40px] flex flex-col justify-between rounded-[10px] bg-rb-white px-[8vw] pt-[50px] pb-[4vw] drop-shadow-[rgba(0,0,0,0.24)_0_0_3px] md:px-[4vw] md:pt-[6vw] tl:mb-0"
            key={benefit.id}
          >
            <Image
              src={benefit.icon.imageUrl || ''}
              alt=""
              width={100}
              height={100}
              className="absolute -top-[30px] left-1/2 h-auto w-[60px] -translate-x-1/2 md:-top-[2.5vw] md:w-[5vw]"
            />

            <p className="mb-[4vw] !leading-8 fluid-text-xl">
              <strong className="font-semibold">{benefit.title}</strong>
            </p>
            <p className="!leading-6 fluid-text-base">{benefit.description}</p>
          </div>
        ))}
      </section>

      <section className="-mt-[560px] bg-rb-background-beige px-[16px] pb-[6.6vmax] pt-[500px] md:-mt-[500px] md:px-[4vw] md:pt-[460px] tl:-mt-[320px] tl:pt-[320px]">
        <h3 className="mb-[4vw] text-center font-normal tracking-tight fluid-text-3xl">
          Case studies & examples from companies like:
        </h3>

        <div className="flex flex-row flex-wrap items-center justify-between md:px-[4vw]">
          {content.logos?.map((logo) => (
            <div
              key={logo.imageUrl}
              className="flex w-1/2 grow-0 items-center justify-center md:w-1/4"
            >
              <Image
                src={logo.imageUrl || ''}
                width={40 * (logo.aspectRatio || 1)}
                height={40}
                alt={logo.imageAlt || ''}
                className="my-[22px] h-[4vw] min-h-[20px] w-auto md:h-[3vw] md:max-h-[40px]"
              />
            </div>
          ))}
        </div>
      </section>

      <section className="px-[16px] py-[6.6vmax] md:px-[4vw]" id="courses">
        <h2 className="mb-[4vw] font-normal tracking-tight fluid-text-6xl">
          Our Courses
        </h2>

        <ul className="grid list-none grid-cols-1 gap-[20px] p-0 sm:grid-cols-2 md:grid-cols-3 lgr:grid-cols-4">
          {courses.map((course) => (
            <MarketingCourseCard course={course} key={course.id} />
          ))}
        </ul>
      </section>
    </>
  )
}

export default OldLoggedOutCoursesIndex
