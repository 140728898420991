import { Fragment } from 'react'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import { PricingPageContent, ReforgeComparison } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { cn } from 'utils/tailwind'

const TABLE_HEAD: { [key: string]: string } = {
  name: 'Name',
  cost: 'Cost',
  audience: 'Audience',
  timeCommitment: 'Time commitment',
  relevance: 'Relevance'
}

const ReforgeComparisonTable = ({ content }: { content: PricingPageContent }) => {
  const tableHeadClassName = 'p-3 pl-0 text-sm lg:text-lg'
  const tableCellClassName =
    'py-3 px-2 md:pr-3 md:pl-6 border-t border-rb-gray-100 text-sm lg:text-lg'
  const comparisonsCount = content.reforgeComparisons?.length || 0
  const isXsWidth = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)

  return (
    <div className="bg-rb-white rounded-xl w-full p-6 sm:p-8 flex flex-col">
      <h3 className="text-lg lg:text-2xl !leading-[1] font-normal mb-3 tracking-[-0.36px] lg:tracking-[-0.48px]">
        How Reforge compares
      </h3>
      <p className="mb-[50px] text-sm lg:text-lg !leading-[1.35] max-w-[85%]">
        Membership provides a cost-effective and flexible approach to transform learning
        into doing for tech professionals.
      </p>

      <div className="grid grid-cols-1 sm:grid-cols-[100px,repeat(3,1fr)] md:grid-cols-[140px,repeat(3,1fr)] lg:grid-cols-[220px,repeat(3,1fr)]">
        {Object.keys(TABLE_HEAD).map((key) => (
          <Fragment key={key}>
            <div
              className={cn(
                'hidden sm:block',
                TABLE_HEAD[key] === TABLE_HEAD.name ? '' : 'border-t border-rb-gray-100'
              )}
            >
              <div className={tableHeadClassName}>
                {TABLE_HEAD[key] === TABLE_HEAD.name ? '' : TABLE_HEAD[key]}
              </div>
            </div>

            {content.reforgeComparisons?.map((item, index) => (
              <div
                className={cn('grid grid-cols-[100px,1fr] sm:grid-cols-1')}
                style={{ order: isXsWidth ? index % comparisonsCount : 0 }}
                key={index}
              >
                <div
                  className={cn(
                    tableHeadClassName,
                    'sm:hidden',
                    TABLE_HEAD[key] === TABLE_HEAD.name
                      ? ''
                      : 'border-t border-rb-gray-100'
                  )}
                >
                  {TABLE_HEAD[key] === TABLE_HEAD.name ? '' : TABLE_HEAD[key]}
                </div>
                <div
                  className={cn(
                    tableCellClassName,
                    TABLE_HEAD[key] === TABLE_HEAD.name &&
                      'font-semibold border-0 flex items-end',
                    index === 0 && 'bg-rb-blue-50 md:pl-3',
                    index === 0 &&
                      TABLE_HEAD[key] === TABLE_HEAD.name &&
                      'rounded-t-xl pt-6 sm:pt-3',
                    index !== 0 &&
                      TABLE_HEAD[key] === TABLE_HEAD.name &&
                      'mt-2.5 sm:mt-0',
                    index === 0 &&
                      TABLE_HEAD[key] === TABLE_HEAD.relevance &&
                      'rounded-b-xl'
                  )}
                >
                  {item[key as keyof ReforgeComparison]}
                </div>
              </div>
            ))}
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default ReforgeComparisonTable
