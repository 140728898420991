import { useEffect, useState } from 'react'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'
import Modal, { ModalContent } from 'components/Modal'

import { StripePaymentSources, useDeletePaymentSourceMutation } from 'gql'

export type Props = {
  paymentSource: StripePaymentSources
  afterDelete: () => void
  isOpen: boolean
  onClose: () => void
}
export default function DeletePaymentSourceModal({
  isOpen,
  onClose,
  paymentSource,
  afterDelete
}: Props) {
  const [deleted, setDeleted] = useState(false)
  const [deletePaymentSource, mutation] = useDeletePaymentSourceMutation()

  useEffect(() => {
    setDeleted(false)
  }, [isOpen])

  const deleteSource = async () => {
    const { data } = await deletePaymentSource({
      variables: { input: { id: paymentSource.id } }
    })
    if (data?.deletePaymentSource?.id) {
      afterDelete()
      setDeleted(true)
    }
  }

  return (
    <Modal isOpen={isOpen} handleClose={onClose} className="max-w-2xl" header={deleted}>
      <ModalContent>
        {!deleted && (
          <div className="uk-text-center p-6 text-xl text-rb-gray-300">
            <p className="uk-margin uk-margin-bottom p-4">
              Are you sure you want to remove this payment method?
            </p>
            <div className="uk-margin flex items-center justify-center">
              <Button
                size="small"
                variant="outline"
                disabled={mutation.loading}
                onClick={onClose}
                className="mr-4"
              >
                Cancel
              </Button>

              <Button size="small" disabled={mutation.loading} onClick={deleteSource}>
                Remove
              </Button>
            </div>
          </div>
        )}
        {deleted && (
          <>
            <div className="uk-text-center remove-payment-source__confirmation text-xl text-rb-gray-300">
              <SVGIcon
                name="check-slim"
                height="42"
                width="55"
                fill="#000000"
                className="uk-margin-small-bottom uk-margin-top mx-auto"
              />
              <div className="uk-margin" style={{ marginBottom: '50px' }}>
                <p>Payment method removed</p>
              </div>
            </div>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
