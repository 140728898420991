import Pluralize from 'react-pluralize'
import { twMerge } from 'tailwind-merge'

import WeeklyBlockEvent from 'domains/CohortDashboard/CohortDashboardProgram/CohortDashboardWeeklyBlock/WeeklyBlockEvent'
import { CohortEvent } from 'domains/CohortViewer/utils'

import { SkeletonEventCard } from 'components/skeletons/cards/SkeletonEventCard'
import SkeletonRfParagraph from 'components/skeletons/typography/SkeletonRfParagraph'
import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'

import { CohortDashboardScheduledWeekCurrentUserPartsFragment } from 'gql'

interface WeeklyBlockEventsSkeletonProps {
  className?: string
}

export const WeeklyBlockEventsSkeleton = ({
  className
}: WeeklyBlockEventsSkeletonProps) => (
  <div className={twMerge('mt-48 flex animate-pulse flex-col space-y-4', className)}>
    <SkeletonRfParagraph className="h-3 w-full" />
    <SkeletonRfParagraph className="mt-8 h-2 w-2/3" />
    <SkeletonRfParagraph className="mt-1 h-2 w-1/3" />
    <SkeletonRfParagraph className="mt-8 h-2 w-1/3" />
    <SkeletonEventCard />
  </div>
)

export interface WeeklyBlockEventsProps {
  slug: string
  currentUser: CohortDashboardScheduledWeekCurrentUserPartsFragment
  scheduledWeekTitle: string
  scheduledWeekIndex: number
  programName: string
  events: CohortEvent[]
  rsvpCallback: (event: CohortEvent, index: number) => void
}

export const WeeklyBlockEvents = ({
  events,
  currentUser,
  slug,
  scheduledWeekTitle,
  scheduledWeekIndex,
  programName,
  rsvpCallback
}: WeeklyBlockEventsProps) => {
  return (
    <div className="pb-16">
      <RfHeader3SemiBold className="!mb-8">
        <Pluralize
          singular="This week's event"
          plural="This week's events"
          count={events.length}
          showCount={false}
        />
      </RfHeader3SemiBold>
      <div className="flex flex-col gap-12 sm:flex-row sm:gap-6 xl:flex-col xl:gap-12">
        {events.map((event, i) => (
          <WeeklyBlockEvent
            rsvpCallback={(updatedEvent) => rsvpCallback(updatedEvent, i)}
            key={`weekly-block-event-${i}`}
            combinedEvent={event}
            index={i}
            slug={slug}
            currentUser={currentUser}
            scheduledWeekTitle={scheduledWeekTitle}
            scheduledWeekIndex={scheduledWeekIndex}
            programName={programName}
          />
        ))}
      </div>
    </div>
  )
}

export default WeeklyBlockEvents
