import { ReactComponent as ChevronLeft } from 'chevron-left.svg'
import { useHistory } from 'react-router-dom'

import { SWIMLANE_ALL } from 'pages/GuidesPage/constants'

import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'

import { useSanityTagGroupQuery } from 'gql'

import { onEnterKeyPress } from 'utils/keyboard'
import { capitalizeFirstLetter } from 'utils/stringUtils'
import { trackCtaClicked } from 'utils/tracking/analytics'

interface SwimlaneBreadcrumbProps {
  swimlaneSlug: string
  unitTitle: string
}

const GuidesBreadcrumb = ({
  unitTitle,
  swimlaneSlug,
  label
}: SwimlaneBreadcrumbProps & { label: string }) => {
  const history = useHistory()
  const handleNavClick = () => {
    trackCtaClicked({
      cta_type: 'hyperlink text',
      cta_location: 'guide_page',
      text: label,
      related_identifiers: {
        content_mode: 'async'
      }
    })
    history.push(`/guides?swimlane=${swimlaneSlug}`)
  }

  return (
    <div
      id="all-guides-breadcrumb"
      className="sticky top-0 z-10 mb-2 flex w-full items-center bg-white p-2 pb-2 sm:mb-6"
    >
      <RfParagraphMini className="flex items-center gap-2">
        <span
          tabIndex={0}
          role="button"
          className="group flex items-center gap-2 text-rb-gray-300 hover:text-rb-teal-400 hover:no-underline"
          onClick={handleNavClick}
          onKeyUp={onEnterKeyPress(handleNavClick)}
        >
          <span className="rounded-full border border-rb-gray-100 p-2 text-rb-gray-100 group-hover:text-rb-teal-400">
            <ChevronLeft className="h-[17px] min-w-[17px] stroke-current" />
          </span>
          {label}
        </span>
        <span className="hidden sm:block">/</span>
        <span className="hidden flex-1 font-semibold text-rb-gray-400 sm:block">
          {unitTitle}
        </span>
      </RfParagraphMini>
    </div>
  )
}

const TagGroupSwimlaneBreadcrumb = ({
  swimlaneSlug,
  unitTitle
}: SwimlaneBreadcrumbProps) => {
  const history = useHistory()
  const { loading, data } = useSanityTagGroupQuery({
    variables: {
      slug: swimlaneSlug
    },
    skip: swimlaneSlug === SWIMLANE_ALL
  })

  // Data is loading, may want a skeleton component here
  if (loading) return null

  // No swimlane found, do not render bread crumbs
  if (!data?.sanityTagGroup) return null

  const { title, slug, functionTag } = data.sanityTagGroup

  const handleFunctionClick = () => {
    trackCtaClicked({
      cta_type: 'hyperlink text',
      cta_location: 'guide_page',
      text: functionTag.title,
      related_identifiers: {
        content_mode: 'async'
      }
    })
    history.push(`/guides?category=${functionTag.slug}`)
  }
  const handleSwimlaneClick = () => {
    trackCtaClicked({
      cta_type: 'hyperlink text',
      cta_location: 'guide_page',
      text: title,
      related_identifiers: {
        content_mode: 'async'
      }
    })
    history.push(`/guides?swimlane=${slug}`)
  }

  return (
    <div
      id="tag-group-swimlane-breadcrumb"
      className="sticky top-0 z-10 mb-2 flex w-full items-center bg-white p-2 pb-2 sm:mb-6"
    >
      <RfParagraphMini className="flex items-center gap-2">
        <span
          tabIndex={0}
          role="button"
          className="group flex items-center gap-2 text-rb-gray-300 hover:text-rb-teal-400 hover:no-underline"
          onClick={handleFunctionClick}
          onKeyUp={onEnterKeyPress(handleFunctionClick)}
        >
          <span className="rounded-full border border-rb-gray-100 p-2 text-rb-gray-100 group-hover:text-rb-teal-400">
            <ChevronLeft className="h-[17px] min-w-[17px] stroke-current" />
          </span>

          {functionTag.title}
        </span>
        <span className="hidden sm:block">/</span>
        <span
          tabIndex={0}
          role="button"
          className="flex hidden text-rb-gray-300 hover:text-rb-teal-400 hover:no-underline sm:block"
          onClick={handleSwimlaneClick}
          onKeyUp={onEnterKeyPress(handleSwimlaneClick)}
        >
          {title}
        </span>
        <span className="hidden sm:block">/</span>
        <span className="hidden flex-1 font-semibold text-rb-gray-400 sm:block">
          {unitTitle}
        </span>
      </RfParagraphMini>
    </div>
  )
}

export const SwimlaneBreadcrumb = ({
  swimlaneSlug,
  unitTitle
}: SwimlaneBreadcrumbProps) => {
  if (swimlaneSlug === SWIMLANE_ALL) {
    return (
      <GuidesBreadcrumb
        unitTitle={unitTitle}
        swimlaneSlug={swimlaneSlug}
        label={`${capitalizeFirstLetter(swimlaneSlug.toLowerCase())} guides`}
      />
    )
  }

  return <TagGroupSwimlaneBreadcrumb unitTitle={unitTitle} swimlaneSlug={swimlaneSlug} />
}
