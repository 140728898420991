import { SuggestedPrompts } from 'domains/Ai/SuggestedPrompts'

import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { ContentBlock, Unit } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useTableOfContentHighlighter from 'hooks/useTableOfContentHighlighter'

import { cn } from 'utils/tailwind'

interface UnitContentBlockNavigationProps {
  unit: Unit
}

export const UnitContentBlockNavigation = ({ unit }: UnitContentBlockNavigationProps) => {
  const { currentUser } = useCurrentUser()
  let sectionsQuerySelector = unit?.overview ? '[id="overview"]' : ''
  sectionsQuerySelector += sectionsQuerySelector.length ? ', ' : ''
  sectionsQuerySelector +=
    unit?.contentBlocks
      ?.map((contentBlock: any) => `[id="${contentBlock?.slug}"]`)
      .join(', ') || ''
  sectionsQuerySelector += unit?.artifacts?.length ? ', [id="artifacts"]' : ''
  sectionsQuerySelector += unit?.recap?.length ? ', [id="recap"]' : ''

  const scrollTo = (elementId: string) => {
    const element = document.getElementById(elementId)
    element?.scrollIntoView({ behavior: 'smooth' })

    window.history.pushState({}, '', `${window.location.href.split('#')[0]}#${elementId}`)
  }

  const handleContentBlockLinkClick = (contentBlock: ContentBlock) => {
    const elementId = `${contentBlock.slug || contentBlock.id}`

    const element = document.getElementById(elementId)
    element?.scrollIntoView({ behavior: 'smooth' })

    window.history.pushState({}, '', `${window.location.href.split('#')[0]}#${elementId}`)

    window.analytics.track('Content Outline Section Click', {
      location: 'guide',
      content_block_title: contentBlock.header,
      content_block_sanity_id: contentBlock.id,
      content_block_number: contentBlock.order,
      related_identifiers: {
        content_type: 'guide',
        content_block_id: contentBlock.id,
        content_mode: 'async'
      }
    })
  }

  useTableOfContentHighlighter(
    unit.showContentGate ? null : sectionsQuerySelector,
    ['text-rb-teal-400', 'underline', 'underline-offset-4'],
    {
      root: document.querySelector('#page'),
      rootMargin: '-60% 0px -40% 0px'
    }
  )

  return (
    <div className="flex flex-col gap-12">
      <div className="flex flex-col gap-4">
        <button
          data-test="overview_unit_navigation_link"
          className={cn(
            'text-left hover:underline hover:decoration-rb-teal-300 hover:decoration-2 hover:underline-offset-4',
            { 'pointer-events-none': unit.showContentGate }
          )}
          onClick={() => scrollTo('overview')}
        >
          <RfParagraphSmall className={unit.showContentGate ? '!text-rb-gray-300' : ''}>
            Overview
          </RfParagraphSmall>
        </button>
        {unit.contentBlocks?.length && (
          <div className="flex flex-col gap-2">
            {unit.contentBlocks.map((contentBlock: ContentBlock) => (
              <button
                data-test="unit_navigation_link"
                id={`${contentBlock.slug}.nav`}
                className={cn(
                  'text-left hover:underline hover:decoration-rb-teal-300 hover:decoration-2 hover:underline-offset-4',
                  { 'pointer-events-none': unit.showContentGate }
                )}
                key={contentBlock.slug}
                onClick={() => handleContentBlockLinkClick(contentBlock)}
              >
                <RfParagraphSmall
                  className={unit.showContentGate ? '!text-rb-gray-300' : ''}
                >
                  {contentBlock.order}. {contentBlock.header}
                </RfParagraphSmall>
              </button>
            ))}
          </div>
        )}
        {unit.recap?.length > 0 && !unit.showContentGate && (
          <button
            data-test="recap_unit_navigation_link"
            className="text-left hover:underline hover:decoration-rb-teal-300 hover:decoration-2 hover:underline-offset-4"
            onClick={() => scrollTo('recap')}
          >
            <RfParagraphSmall>Recap</RfParagraphSmall>
          </button>
        )}
        {unit.artifacts?.length && (
          <button
            data-test="artifacts_unit_navigation_link"
            className={cn(
              'text-left hover:underline hover:decoration-rb-teal-300 hover:decoration-2 hover:underline-offset-4',
              { 'pointer-events-none': unit.showContentGate }
            )}
            onClick={() => scrollTo('artifacts-section')}
          >
            <RfParagraphSmall className={unit.showContentGate ? '!text-rb-gray-300' : ''}>
              See how others have applied this
            </RfParagraphSmall>
          </button>
        )}
      </div>

      {currentUser && (
        <SuggestedPrompts
          showPremiumBadge={currentUser.is.freeUser}
          pageLocation="right_rail"
        />
      )}
    </div>
  )
}

export default UnitContentBlockNavigation
