import React from 'react'

import BackButton from 'components/Breadcrumbs/BackButton'
import BreadcrumbLink from 'components/Breadcrumbs/Link'
import useBreadcrumbs from 'components/Breadcrumbs/useBreadcrumbs'
import RfMetaMini from 'components/typography/RfMeta/RfMetaMini'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

export type BreadcrumbHistoryEntry = {
  name: string
  path: string
}

interface BreadcrumbsProps {
  history: BreadcrumbHistoryEntry[]
}

export const Breadcrumbs = ({ history }: BreadcrumbsProps) => {
  const {
    editableHistory,
    getOnBreadcrumbClick,
    hasMultipleCrumbs,
    historyEntriesWithChevrons,
    lastHistoryEntry,
    removeHistoryEntry
  } = useBreadcrumbs({ history })

  return (
    <nav className="flex h-12 items-center justify-between px-2.5 sm:px-8 md:px-5">
      <div className={`items-center ${hasMultipleCrumbs ? 'hidden xs:flex' : 'flex'}`}>
        {historyEntriesWithChevrons.map((historyEntry, index) => (
          <BreadcrumbLink
            historyEntry={historyEntry}
            onClick={getOnBreadcrumbClick(index)}
            key={historyEntry.name}
          />
        ))}
        <RfMetaMini color={ColorOptions.gray}>{lastHistoryEntry.name}</RfMetaMini>
      </div>
      {hasMultipleCrumbs && (
        <div className="xs:hidden">
          <BackButton history={editableHistory} removeHistoryEntry={removeHistoryEntry} />
        </div>
      )}
    </nav>
  )
}

export default Breadcrumbs
