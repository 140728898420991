import { track } from 'utils/tracking/segment'

const PolicyLink = () => {
  const handleClick = (e: any) => {
    e.preventDefault()
    // @ts-ignore - 'Piracy Modal - Action' event is not defined in Segment JIRA#REF-5159
    track('Piracy Modal - Action', {
      action: 'copyright_and_acceptable_use_policy_click'
    })

    window.open(
      e?.target?.attributes?.href?.value || 'https://www.reforge.com/terms-of-service',
      '_blank'
    )
  }

  return (
    <a
      onClick={handleClick}
      target="_blank"
      rel="noreferrer"
      href="https://www.reforge.com/terms-of-service"
    >
      Copyright and Acceptable Use Policy
    </a>
  )
}

export default PolicyLink
