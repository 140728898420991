import React from 'react'
import { twMerge } from 'tailwind-merge'

import Tooltip from 'components/Tooltip/Tooltip'

import { UserAvatarPartsFragment } from 'gql'

import { UserProps } from './ActivityFacepile'
import DefaultAvatar from './DefaultAvatar'

interface FacePileProps {
  users?: UserAvatarPartsFragment[]
  imageSize?: 'small' | 'large' | 'xlarge' | 'xxlarge'
  className?: string
}

export const FacePile = ({
  users = [],
  imageSize = 'large',
  className
}: FacePileProps) => (
  <>
    {users.map((u, i) => (
      <FacePileImage
        imageSize={imageSize}
        key={u.id}
        zIndex={users.length - i}
        src={u.profile?.avatarUrl || ''}
        className={className}
      />
    ))}
  </>
)

interface FacePileTooltipProps {
  usersWithActivities: UserProps[]
  usersWithoutActivities: UserProps[]
  imageSize?: 'small' | 'large' | 'xlarge' | 'xxlarge'
  className?: string
  getTooltipBody: ({ user }: { user: UserProps }) => React.ReactNode
}

export const FacePileWithTooltip = ({
  usersWithActivities = [],
  usersWithoutActivities = [],
  imageSize = 'large',
  className,
  getTooltipBody
}: FacePileTooltipProps) => {
  const zIndexMax = usersWithActivities.length + usersWithoutActivities.length
  return (
    <>
      {usersWithActivities.map((u, i) => {
        const tooltipBody = getTooltipBody({ user: u })
        return (
          <FacePileImage
            imageSize={imageSize}
            key={u.id}
            zIndex={zIndexMax - i}
            src={u.profile?.avatarUrl || ''}
            className={className}
            tooltipBody={tooltipBody}
            fullName={u.fullName}
          />
        )
      })}
      {usersWithoutActivities.map((u, i) => {
        const tooltipBody = getTooltipBody({ user: u })
        return (
          <FacePileImage
            imageSize={imageSize}
            key={u.id}
            zIndex={zIndexMax - usersWithActivities.length - i}
            src={u.profile?.avatarUrl || ''}
            className={twMerge(className, 'opacity-30')}
            tooltipBody={tooltipBody}
            fullName={u.fullName}
          />
        )
      })}
    </>
  )
}

interface FacePileImageProps {
  className?: string
  src?: string
  zIndex: number
  imageSize: 'small' | 'large' | 'xlarge' | 'xxlarge'
  tooltipBody?: React.ReactNode
  fullName?: string | null
}

export const FacePileImage = ({
  className,
  src,
  zIndex,
  imageSize,
  tooltipBody,
  fullName // for now, used to determine color of default avatar
}: FacePileImageProps) => {
  const getImageSize = () => {
    if (imageSize === 'small') return 'w-5 h-5'
    if (imageSize === 'large') return 'w-5 h-5 sm:w-8 sm:h-8'
    if (imageSize === 'xlarge') return 'w-8 h-8'
    if (imageSize === 'xxlarge') return 'w-12 h-12'

    return ''
  }

  const getFontSizeFromImageSize = () => {
    if (imageSize === 'small') return 'text-xs'
    if (imageSize === 'large') return 'text-sm'
    if (imageSize === 'xlarge') return 'text-base'
    if (imageSize === 'xxlarge') return 'text-2xl'

    return ''
  }

  // for now we only show this coloured Initial avatar when user has fullName set, otherwise it will use the person icon
  const isDefaultAvatar = !!(src?.includes('default_avatar') && fullName) || !src

  const baseAvatarClass = twMerge(
    `${getImageSize()} ${getFontSizeFromImageSize()} -ml-2 rounded-full border border-white`,
    className
  )

  // TODO: make sure circles look right when surrounded by this tooltip
  if (tooltipBody) {
    return (
      <Tooltip
        place="bottom"
        html={true}
        tooltipBody={tooltipBody}
        contentWrapperStyle={{ zIndex }}
        contentWrapperClassname={twMerge(
          `${getImageSize()} -ml-2 rounded-full border border-white bg-white hover:no-underline `,
          className
        )}
        className="!opacity-100 "
        disable={!tooltipBody}
      >
        {isDefaultAvatar && fullName ? (
          <DefaultAvatar
            fullName={fullName}
            className={`${baseAvatarClass} ml-0`}
            zIndex={zIndex}
          />
        ) : (
          <img
            style={{ zIndex }}
            className="rounded-full border border-white bg-white"
            src={src}
            data-test="facepile-image"
            alt=""
          />
        )}
      </Tooltip>
    )
  }

  return isDefaultAvatar && fullName ? (
    <DefaultAvatar className={`${baseAvatarClass}`} fullName={fullName} />
  ) : (
    <img
      style={{ zIndex }}
      className={twMerge(baseAvatarClass, 'bg-white')}
      src={src}
      data-test="facepile-image"
      alt=""
    />
  )
}
