import React, { useContext, useEffect, useState } from 'react'

import VideoControlBar from 'domains/Cms/VideoControlBar'
import { NextLessonData } from 'domains/Cms/VideoOverlay/NextLessonOverlay'
import { WistiaPlayerContext } from 'domains/LessonViewer/LessonViewerContainer'

import { useSideDrawer } from 'components/SideDrawer'

import {
  BookmarkNoteFormPartsFragment,
  BookmarkType,
  CreateBookmarkInput,
  Maybe,
  useCreateBookmarkMutation,
  useUserBookmarksFeedQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useWistiaVideo from 'hooks/useWistiaVideo'

import notifyError from 'utils/errorNotifier'
import { sendData } from 'utils/sendData'

import { WistiaVideo } from 'typings/WistiaVideo'
import { SendProgressUpdatePayload } from 'typings/payloads'
import { CmsSectionProgress } from 'typings/scalars'

import VideoOverlay from './VideoOverlay/VideoOverlay'

const rightDrawerId = 'discoveryDrawer'

const sendProgressUpdate = (
  cmsSectionId: string,
  videoSeconds: number,
  videoPercent: number
) => {
  const data: SendProgressUpdatePayload = {
    cms_section_id: cmsSectionId,
    video_seconds: videoSeconds,
    video_percent: videoPercent
  }
  sendData('/api/v1/user_programs/update_video_progress', 'POST', data, () => {
    // no user facing error display
  })
}

export type MappedVideoBookmarks = Record<string, BookmarkNoteFormPartsFragment>

interface BookmarkableWistiaPlayerProps {
  code?: Maybe<string>
  objectType: string
  cmsProgramId: string
  cmsModuleId: string
  cmsSectionId: string
  userEmail: string
  bookmarks: MappedVideoBookmarks
  moduleName: string
  isCoreLesson: boolean
  nextLessonData: Maybe<NextLessonData>
  userProgress: CmsSectionProgress
}

const BookmarkableWistiaPlayer = ({
  code,
  objectType,
  cmsProgramId,
  cmsModuleId,
  cmsSectionId,
  userEmail,
  bookmarks: playerBookmarks,
  moduleName,
  isCoreLesson,
  nextLessonData,
  userProgress
}: BookmarkableWistiaPlayerProps) => {
  const [bookmarks, setBookmarks] = useState(playerBookmarks)
  const [bookmarking, setBookmarking] = useState(false)
  const [activeBookmarkId, setActiveBookmarkId] = useState<Maybe<string>>()
  const [isVideoOverlayOpen, setIsVideoOverlayOpen] = useState<boolean>()
  const { currentUser } = useCurrentUser()
  const { openDrawer } = useSideDrawer()
  const { setWistiaPlayer } = useContext(WistiaPlayerContext)

  const { refetch: refetchBookmarks } = useUserBookmarksFeedQuery({
    variables: {
      userId: currentUser?.id || '',
      filters: {
        cmsProgramId,
        cmsModuleId,
        cmsSectionId
      }
    },
    skip: !currentUser
  })

  const [createBookmarkMutation] = useCreateBookmarkMutation({
    update: (cache, mutationResult) => {
      const newBookmark = mutationResult?.data?.createBookmark?.bookmark
      const cmsSectionId = newBookmark?.cmsSection?.id
      if (!newBookmark || !cmsSectionId) return

      cache.modify({
        id: `CmsSection:${cmsSectionId}`,
        fields: {
          bookmarks(existingBookmarkRefs) {
            const newBookmarkRef = { __ref: `Bookmark:${newBookmark.id}` }
            return [...existingBookmarkRefs, newBookmarkRef]
          }
        }
      })
    }
  })

  useEffect(() => {
    setBookmarks(playerBookmarks)
  }, [playerBookmarks, setBookmarks])

  const handleOnVideoTimeChange = (percent: number) => {
    // Close overlay when customer goes back in video.
    setIsVideoOverlayOpen(percent === 100)
  }

  const handleOnProgressUpdate = (percent: number, seconds: number) => {
    sendProgressUpdate(cmsSectionId, seconds, percent)
    window.markSectionComplete(cmsSectionId)
  }

  const handleCustomPlayerClick = () => {
    if (!videoInstance) return
    if (videoIsPlaying) {
      videoPause()
    } else {
      videoPlay()
    }
  }

  const handleBookmarkerClick = () => {
    const seconds = getVideoTime(videoInstance)
    const existingBookmark = typeof seconds === 'number' && bookmarks[seconds]

    if (videoIsPlaying) {
      videoPause()
    }

    if (existingBookmark) {
      // already have a bookmark for this time, not creating another
      // instead focus on old one and pause video
      if (activeBookmarkId !== existingBookmark.id) {
        setActiveBookmarkId(existingBookmark.id)
      }
    } else {
      saveNewBookmark(createBookmark(videoInstance))
    }
  }

  const { videoIsPlaying, videoPause, videoPlay, videoInstance, getVideoTime } =
    useWistiaVideo({
      userEmail: userEmail,
      code: code,
      enableKeyboardHandlers: true,
      initialVideoPercent: userProgress.video_percent || 0,
      initialVideoSeconds: userProgress.video_seconds || 0,
      initialCompleted: userProgress.video_percent
        ? userProgress.video_percent > 90
        : false,
      onVideoTimeChange: handleOnVideoTimeChange,
      onProgressUpdate: handleOnProgressUpdate,
      eventTrackingInformation: {
        cmsProgramId,
        objectId: cmsSectionId,
        objectType
      },
      onPressEnter: handleBookmarkerClick,
      onCustomPlayerClick: handleCustomPlayerClick,
      celebrationModal: true,
      autoplay: true
    })

  useEffect(() => {
    setWistiaPlayer(videoInstance)
    return () => videoInstance?.remove()
  }, [videoInstance, setWistiaPlayer])

  const createBookmark = (video: WistiaVideo | undefined) => {
    const seconds = getVideoTime(video)
    const thumbnailUrl = video?.data.media.assets[0].url?.replace(
      '.bin',
      `.jpg?video_still_time=${seconds}`
    )
    const bookmark: CreateBookmarkInput = {
      cmsProgramId,
      cmsModuleId,
      cmsSectionId,
      videoCode: code,
      seconds,
      referenceImageUrl: thumbnailUrl,
      type: BookmarkType.VIDEOBOOKMARK
    }
    return bookmark
  }

  const saveNewBookmark = async (bookmark: CreateBookmarkInput) => {
    if (!(bookmark.seconds && bookmark.seconds in bookmarks)) {
      // Only create one bookmark per second of video
      setBookmarking(true)
      try {
        const { data } = await createBookmarkMutation({
          variables: {
            input: bookmark
          }
        })

        await refetchBookmarks()

        openDrawer(rightDrawerId)

        setBookmarking(false)
        setBookmarks((bookmarks) => ({
          ...bookmarks,
          [bookmark.seconds!]: data?.createBookmark?.bookmark
        }))
        setActiveBookmarkId(data?.createBookmark?.bookmark?.id)

        window.changeNumSectionBookmarks?.(bookmark.cmsSectionId!, 1)
      } catch (error: unknown) {
        setBookmarking(false)
        notifyError(error)
      }
    }
  }

  const isOverlayAvailable = isCoreLesson && isVideoOverlayOpen

  return (
    <div className="uk-margin-small-bottom mt-7">
      <div
        className="wistia_responsive_padding z-0"
        style={{ padding: '56.0% 0 0 0', position: 'relative' }}
      >
        <div
          className="wistia_responsive_wrapper"
          style={{
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%'
          }}
        >
          {/* Overlay for small screen. */}
          {isOverlayAvailable && (
            <VideoOverlay
              contentType={objectType}
              currentModuleName={moduleName}
              nextLessonData={nextLessonData}
              isOpen={isVideoOverlayOpen}
              onClose={() => {
                setIsVideoOverlayOpen(false)
              }}
            />
          )}
          <div
            className={`wistia_embed wistia_async_${code} videoFoam=true`}
            style={{ height: '100%', width: '100%' }}
          >
            &nbsp;
          </div>
        </div>
      </div>
      {videoInstance /* for some Wistia embeds, the onReady doesn't fire and the video object isn't accessible. Disabled bookmarks in these cases */ && (
        <React.Fragment>
          <VideoControlBar
            bookmarking={bookmarking}
            videoIsPlaying={videoIsPlaying}
            onBookmarkClick={handleBookmarkerClick}
            onCustomPlayerClick={handleCustomPlayerClick}
          />
          <hr />
        </React.Fragment>
      )}
    </div>
  )
}

export default BookmarkableWistiaPlayer
