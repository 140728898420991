import React from 'react'
import { twMerge } from 'tailwind-merge'

import Button from 'components/Button'

interface CohortConversationSortButtonGroupProps {
  activeSortBy: string
  setActiveSortBy: (sortBy: string) => void
}

const CohortConversationSortButtonGroup = ({
  activeSortBy,
  setActiveSortBy
}: CohortConversationSortButtonGroupProps) => (
  <div className="flex gap-4 pb-8">
    {['Recent', 'Popular'].map((sortBy) => (
      <Button
        key={sortBy}
        shape="rounded-full"
        variant="outline"
        size="x-small"
        className={twMerge(
          activeSortBy === sortBy
            ? 'bg-rb-black text-rb-white hover:border-rb-black hover:text-white'
            : 'border-rb-gray-200 text-rb-black hover:border-rb-black hover:text-rb-black'
        )}
        onClick={() => setActiveSortBy(sortBy)}
      >
        {sortBy}
      </Button>
    ))}
  </div>
)

export default CohortConversationSortButtonGroup
