import { PortableText } from '@portabletext/react'
import { PortableTextBlock } from '@portabletext/types'

export type Cell = {
  _key: string
  _type: 'tableCell'
  cellContents: PortableTextBlock[]
}

export type Row = {
  _key: string
  _type: 'tableRow'
  cells: Cell[]
}

const rowObjectToRow = (rowObject: Row) => {
  return (
    <tr key={rowObject._key}>
      {rowObject.cells.map((cell) => {
        return (
          <td className="border-b p-2" key={cell._key}>
            <PortableText value={cell.cellContents} />
          </td>
        )
      })}
    </tr>
  )
}

interface TableProps {
  rows: Row[]
}

const Table = ({ rows }: TableProps) => {
  if (!rows?.length) {
    return null
  }

  return (
    <div className="my-2 rounded-2xl border-2 border-gray-100 bg-white px-6 py-4">
      <table className="w-full border-hidden text-left">
        <tbody>{rows.map(rowObjectToRow)}</tbody>
      </table>
    </div>
  )
}

export default Table
