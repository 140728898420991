import pluralize from 'pluralize'

import ReactivateSubscriptionButton from 'domains/Subscription/ReactivateSubscriptionButton'

import Button from 'components/Button'

import {
  PlanName,
  SubscriptionCardFieldsFragment,
  useCurrentUserAvailableTeamsQuery
} from 'gql'

import { getPrettyFullDate } from 'utils/date'
import { titleize } from 'utils/stringUtils'
import { trackCtaClicked } from 'utils/tracking/analytics'

import LegacySubscriptionCard from './LegacySubscriptionCard'

interface PricingAndPackagingSubscriptionCardProps {
  amountDue: string
  canManageTeam: boolean
  canViewBilling: boolean
  expiresAt: string
  isCanceled: boolean
  isDunning: boolean
  subscription: SubscriptionCardFieldsFragment
  isIndividual: boolean
  isTrialing: boolean
}

export const PricingAndPackagingSubscriptionCard = ({
  amountDue,
  expiresAt,
  isCanceled,
  isDunning,
  subscription,
  canManageTeam,
  canViewBilling,
  isIndividual,
  isTrialing
}: PricingAndPackagingSubscriptionCardProps) => {
  const isInDunning = isDunning && !isCanceled
  const planName = subscription.planName
  const isSubscriptionLegacy = planName === 'unlimited'
  const hasPastDueInvoice = subscription.stripeSubscriptionStatus === 'past_due'
  const { data } = useCurrentUserAvailableTeamsQuery()

  if (isInDunning) {
    if (hasPastDueInvoice) {
      return (
        <>
          <p>
            <span className="text-rb-destructive">Your invoice is past due.</span>{' '}
            Complete your payment now from your Billing History or get in touch at{' '}
            <a className="underline" href="mailto:hello@reforge.com">
              hello@reforge.com.
            </a>
          </p>
          <div className="flex gap-5">
            <Button
              shape="rounded-full"
              size="x-small"
              href="/billing"
              onClick={() => {
                trackCtaClicked({
                  cta_location: 'subscription_card',
                  cta_type: 'button',
                  text: 'view billing history',
                  destination: '/billing'
                })
              }}
            >
              View Billing History
            </Button>
          </div>
        </>
      )
    } else {
      const dunningDaysLeft = subscription.dunningDaysLeft! // we know it's in dunning if we're here
      return (
        <>
          <p>
            <span className="text-rb-destructive">We couldn’t process your payment.</span>{' '}
            Update your payment method to maintain access to Reforge. Your subscription
            will expire in {dunningDaysLeft} day{dunningDaysLeft > 1 ? 's' : ''}.
          </p>
          <div className="flex gap-5">
            <Button
              shape="rounded-full"
              size="x-small"
              href="/billing"
              onClick={() => {
                trackCtaClicked({
                  cta_location: 'subscription_card',
                  cta_type: 'button',
                  text: 'view billing history',
                  destination: '/billing'
                })
              }}
            >
              View Billing History
            </Button>
          </div>
        </>
      )
    }
  }

  if (isSubscriptionLegacy) {
    const hasSelectedFlatTierPlan =
      !!subscription.stripeUpcomingInvoice?.planName &&
      subscription.stripeUpcomingInvoice?.planName !== 'unlimited'
    return (
      <LegacySubscriptionCard
        amountDue={amountDue}
        expiresAt={expiresAt}
        hasSelectedFlatTierPlan={hasSelectedFlatTierPlan}
        isCanceled={isCanceled}
        isIndividual={subscription.numSeats === 1}
        isSubscriptionOwner={subscription.userIsOwner}
      />
    )
  }

  if (isCanceled) {
    return (
      <>
        <p>
          <span className="text-rb-destructive">
            Your {isTrialing ? 'trial' : 'subscription'} is set to expire on {expiresAt}.{' '}
          </span>

          {isIndividual || canManageTeam
            ? 'Reactivate your subscription to maintain access to Reforge.'
            : 'Be sure to tell your subscription owner to select a plan before it expires.'}
        </p>
        <div className="flex gap-5">
          <ReactivateSubscriptionButton className="rounded-full" />

          {isIndividual || canManageTeam ? (
            <Button
              shape="rounded-full"
              size="x-small"
              variant="outline"
              href="/manage-plan"
              onClick={() => {
                trackCtaClicked({
                  cta_location: 'subscription_card',
                  cta_type: 'button',
                  text: 'manage plan',
                  destination: '/manage-plan'
                })
              }}
            >
              Manage Plan
            </Button>
          ) : null}

          {canManageTeam ? (
            <Button
              shape="rounded-full"
              size="x-small"
              variant="outline"
              href="/team_members"
              onClick={() => {
                trackCtaClicked({
                  cta_location: 'subscription_card',
                  cta_type: 'button',
                  text: 'manage team',
                  destination: '/team_members'
                })
              }}
            >
              Manage Team
            </Button>
          ) : null}
        </div>
      </>
    )
  }

  const numCohortPasses = subscription.cohortCredits.unexpired.length
  const numAvailablePasses =
    planName === PlanName.INDIVIDUAL
      ? subscription.cohortCredits.useable.length
      : subscription.cohortCredits.assignable.length

  const pendingJoinRequestCount =
    data?.currentUser?.subscriptions?.active?.pendingJoinRequests.length || 0

  return (
    <>
      <p>
        {canViewBilling ? (
          isTrialing ? (
            <>
              {' '}
              You are on a free trial of the Individual plan, your trial ends on{' '}
              {getPrettyFullDate(subscription.trialEndDate)}.
              {numAvailablePasses > 0 ? (
                <>
                  {' '}
                  You have {numAvailablePasses} available Live Course{' '}
                  {pluralize('pass', numAvailablePasses)}.
                </>
              ) : null}
            </>
          ) : (
            <>
              {' '}
              You are on the {titleize(planName)} Plan, which will renew on {expiresAt}.{' '}
            </>
          )
        ) : (
          <>
            Your subscription will renew on {expiresAt}. To make changes to your plan,
            contact your subscription owner.
          </>
        )}
        {canManageTeam && numCohortPasses > 0 ? (
          <>
            You have purchased {numCohortPasses} Live Course Passes, {numAvailablePasses}{' '}
            of which are available.
          </>
        ) : null}
      </p>
      <div className="flex gap-5">
        <Button
          shape="rounded-full"
          size="x-small"
          href={canViewBilling ? '/billing' : '/manage-plan'}
          onClick={() => {
            trackCtaClicked({
              cta_location: 'subscription_card',
              cta_type: 'button',
              text: canViewBilling ? 'manage subscription' : 'view plans',
              destination: canViewBilling ? '/billing' : '/manage-plan'
            })
          }}
        >
          {canViewBilling ? 'Manage Subscription' : 'View Plans'}
        </Button>

        {canManageTeam && (
          <>
            <Button
              shape="rounded-full"
              size="x-small"
              variant="outline"
              href="/team_members"
              onClick={() => {
                trackCtaClicked({
                  cta_location: 'subscription_card',
                  cta_type: 'button',
                  text: 'manage team',
                  destination: '/team_members'
                })
              }}
            >
              <>
                <div>Manage Team</div>
                <JoinRequestCounter joinRequestCount={pendingJoinRequestCount} />
              </>
            </Button>
          </>
        )}
      </div>
    </>
  )
}
const JoinRequestCounter = ({ joinRequestCount }: { joinRequestCount: number }) => {
  if (joinRequestCount > 0) {
    return (
      <span className="px-[6.5px] text-white bg-rb-destructive rounded-full ml-2 -mr-1">
        {joinRequestCount}
      </span>
    )
  }
  return null
}
export default PricingAndPackagingSubscriptionCard
