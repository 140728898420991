import { FC, ReactElement, SVGProps } from 'react'
import { twMerge } from 'tailwind-merge'

import { ButtonProps } from 'components/Button'

export interface DiscoveryDrawerActionButtonProps
  extends Omit<ButtonProps, 'iconBefore' | 'iconAfter' | 'children'> {
  // extends our base Button props while omitting icon-related props
  icon: FC<SVGProps<SVGSVGElement> & { title?: string | undefined }> | ReactElement // svg as React Component
  isActive?: boolean
  title?: string
}

/**
 * DiscoveryDrawerActionButtonProps
 * Renders a button within the CMS sidebar header for bookmarking, discussions, etc
 * */
export const DiscoveryDrawerActionButton: FC<DiscoveryDrawerActionButtonProps> = ({
  icon,
  iconClassName = '',
  isActive = false,
  className,
  ...props
}) => {
  const baseClasses =
    'rounded-[10px] h-8 w-8 flex items-center justify-center mr-4 last:mr-0'
  const inactiveClasses = 'hover:bg-rb-gray '
  const activeClasses = 'bg-rb-green-75'
  return (
    <button
      className={twMerge(
        baseClasses,
        isActive ? activeClasses : inactiveClasses,
        className
      )}
      data-testid="discovery-drawer-action-button"
      {...props}
    >
      <span className={twMerge('fill-rb-black text-rb-black', iconClassName)}>
        {icon}
      </span>
    </button>
  )
}

export default DiscoveryDrawerActionButton
