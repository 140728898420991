export interface TeamGoal {
  __typename: 'BookmarkFolder'
  id: string
  forkedFromId?: string | null | undefined
  name: string
}

export const SET_TEAM_GOAL_BUTTON_TEXT = 'Set as team goal'
export const REMOVE_GOAL_BUTTON_TEXT = 'Remove goal'
export const SET_PERSONAL_GOAL_BUTTON_TEXT = 'Set as personal goal'
export const TEAM_GOAL_REMOVAL_SUCCESS_MESSAGE =
  'You have successfully removed this goal and it is no longer visible to your team.'
export const PERSONAL_GOAL_REMOVAL_SUCCESS_MESSAGE =
  'You have successfully removed this goal.'
export const TEAM_GOAL_TYPE = 'team'
export const PERSONAL_GOAL_TYPE = 'personal'

export const DEFAULT_COLLECTION_NAME = 'My Saved Items'
