import { closing, opening } from './sharedMarkdown'

const programName = 'Product Leadership'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://www.reforge.com/product-leadership), because I will learn methods for building high-performance product teams, managing up/down/across, and creating cross-functional influence. This course was built with [Ravi Mehta](https://www.reforge.com/experts/ravi-mehta), and covers:
* **Developing Your Product Team.** Learn how to evaluate PM’s on 12 different competencies, give feedback that creates compounding rather than linear improvements, understand areas of weakness of your team, figure out where to invest in high performers, and then make critical decisions like promotions, terminations, and reassignments. 
* **Designing Your Product Team.** Your team structure needs to constantly evolve with the needs of the company. You will learn how to design and redesign your team in a structured and deliberate way. Then build a system to attract, hire, and onboard the best people. Develop a winning culture that implements key rituals to identify and resolve bottlenecks. 
* **Managing Product Work.** Identify areas where you need to lead with context and where you need to exert targeted control. Deconflict and align your PM’s across OKRs. Make critical tradeoff decisions using strategic frameworks. Drive cross-functional alignment and communicate with leadership across the company. 
* **Accelerating Your Career.** Managing yourself is just as important as managing your team. Dive into how Product Leaders progress through three vectors: domain, product work, and scale. Develop a 90-day plan as a product leader. Understand how to showcase success and failure. Build long-lasting credibility as a leader.

&NewLine;

${closing}
`

export default { markdown, title: programName }
