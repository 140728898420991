export const WISTIA_EVENTS = [
  'play',
  'crosstime',
  'timechange',
  'pause',
  'cancelfullscreen',
  'end',
  'playbackratechange'
] as const

export type WistiaVideoEvents = (typeof WISTIA_EVENTS)[number]

export const WISTIA_PIP_HEIGHT = 280
export const WISTIA_PIP_WIDTH = 500

// eslint-disable-next-line no-undef
export interface WistiaVideo extends WistiaPlayer.Player {
  cancelFullscreen: () => void
  requestFullscreen: () => void
  getInputContext: () => string
  playbackRate: (rate?: number) => number
  replaceWith: (code: string) => void
  data: {
    media: {
      duration: number
      assets: { url: string }[]
    }
  }
}

export interface WistiaQInit {
  id: string
  onReady: (video: WistiaVideo) => void
  options?: { hls: boolean }
}

export interface Wistia {
  api: (matcher: (video: WistiaVideo) => void) => void
}

export type WistiaQ = (WistiaQInit | ((wistia: Wistia) => void))[]
