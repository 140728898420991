import MuxPlayer from '@mux/mux-player-react'
import dayjs from 'dayjs'
import { ReactComponent as PlayIcon } from 'icon--play-rounded.svg'
import { useEffect, useRef, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { ExpertPreview } from 'pages/UnitLandingPage/UnitExpertCollaboratorsPreview'

import CourseFeatureGridModal from 'domains/Course/CourseFeatureGridModal'
import CourseDetailContent from 'domains/CourseDetail/CourseDetailContent'
import CourseDetailCreator from 'domains/CourseDetail/CourseDetailCreator'
import CourseDetailOnDemand from 'domains/CourseDetail/CourseDetailOnDemand'
import CourseDetailRelatedCourses from 'domains/CourseDetail/CourseDetailRelatedCourses'
import CourseDetailTestimonials from 'domains/CourseDetail/CourseDetailTestimonials'
import CourseDetailUpcomingSessions from 'domains/CourseDetail/CourseDetailUpcomingSessions'
import CourseDetailWhyTakeCourse from 'domains/CourseDetail/CourseDetailWhyTakeCourse'
import CourseDetailsEnroll from 'domains/CourseDetail/CourseDetailsEnroll'
import { UnitComplexPortableText } from 'domains/Sanity/PortableText/UnitComplexPortableText/UnitComplexPortableText'

import { Loading } from 'components'
import BaseBreadcrumbs from 'components/Breadcrumbs/BaseBreadcrumbs'
import { ReactComponent as ChevronLeftIcon } from 'components/Breadcrumbs/BaseBreadcrumbs/base-chevron-left.svg'
import Button from 'components/Button'
import { useModal } from 'components/Modal'
import RfHeader1 from 'components/typography/RfHeader/RfHeader1'
import RfHeader3 from 'components/typography/RfHeader/RfHeader3'

import { COURSES_ON_DEMAND_PATH } from 'constants/courses'

import {
  useCourseDetailPageQuery,
  useTrackCourseEnrollmentStartedMutation,
  useTrackServerEventMutation
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { trackVideoPlayed } from 'utils/tracking/generated/events'
import { getAnonymousId } from 'utils/tracking/segment'

import { ReactComponent as InformationIcon } from 'images/p-info.svg'

interface CourseDetailsPageProps {
  loggedOut?: boolean
  courseSlug: string
}

const CourseDetailsPage = ({ courseSlug, loggedOut = false }: CourseDetailsPageProps) => {
  const { isLoggedIn } = useCurrentUser()

  const { data, loading } = useCourseDetailPageQuery({
    variables: {
      slug: courseSlug
    }
  })

  const course = data?.cclCourse
  const relatedCourses = data?.relatedCourses
  const upcomingCourseSessions = data?.upcomingCourseSessions

  const courseDetails = course

  const [showTrailerOverlay, setShowTrailerOverlay] = useState(true)
  const playerRef = useRef<any>()
  const { isModalOpen, setIsModalOpen } = useModal()

  const { currentUser } = useCurrentUser()

  const [trackCourseEnrollmentStarted] = useTrackCourseEnrollmentStartedMutation()
  const [trackServerEvent] = useTrackServerEventMutation()

  useEffect(() => {
    if (!courseDetails) {
      return
    }

    const isConversionEligible = !(
      currentUser?.is?.paidMember && upcomingCourseSessions?.length === 0
    )

    trackServerEvent({
      variables: {
        input: {
          event: 'Conversion Page Viewed - Server',
          anonymousId: getAnonymousId(),
          properties: {
            access_policy_kind: currentUser?.accessPolicyKind,
            page_type: 'course_details',
            path: window.location.pathname,
            referrer: document.referrer,
            url: window.location.href,
            is_conversion_eligible: isConversionEligible,
            related_identifiers: {
              ccl_course_id: courseDetails.id,
              course_title: courseDetails.title,
              course_sanity_id: courseDetails.sourceId,
              video_preview_include: courseDetails.trailerUrl !== undefined,
              number_live_sessions_available: upcomingCourseSessions?.length,
              is_available_on_demand: courseDetails.onDemand
            },
            trial_status: currentUser?.trialStatus
          }
        }
      }
    })
  }, [
    courseDetails,
    currentUser?.accessPolicyKind,
    currentUser?.is?.paidMember,
    trackServerEvent,
    upcomingCourseSessions?.length,
    currentUser?.trialStatus
  ])

  if (loading) return <Loading />

  if (!courseDetails) {
    return <Redirect to="/courses" />
  }

  const enrollableSessions = upcomingCourseSessions?.filter(
    (session) => session?.isDuringEnrollmentPeriod
  )

  const enrollableSession =
    enrollableSessions && enrollableSessions.length > 0 ? enrollableSessions[0] : null

  const hasMultipleEnrollableSessions = enrollableSessions
    ? enrollableSessions?.length > 1
    : false

  const onEnrollClick = () => {
    // Th CTA on this component only supports a single session
    if (!courseDetails?.slug || !enrollableSession) return

    trackCourseEnrollmentStarted({
      variables: {
        input: {
          ctaLocation: 'course_details_page',
          courseSlug: courseDetails?.slug,
          cclCourseSessionId: enrollableSession.id
        }
      }
    })

    const formattedStartDate = dayjs(enrollableSession?.startsAt).format('YYYY-MM-DD')

    window.location.href = `/course-payment/${courseDetails?.slug}/${formattedStartDate}/${enrollableSession?.id}`
  }

  const getRightColumn = (className: string) => (
    <div
      className={twMerge(
        'flex h-full max-h-screen w-full flex-col gap-4 xl:max-w-[360px] xl:gap-8',
        loggedOut ? 'top-[188px]' : 'top-16',
        className
      )}
    >
      {/* Enroll in live course */}
      <CourseDetailsEnroll
        courseDetails={courseDetails}
        courseSessions={upcomingCourseSessions}
        loggedOut={loggedOut}
        showTitle={hasMultipleEnrollableSessions}
      />
      {/* On demand access */}
      <CourseDetailOnDemand courseDetails={courseDetails} />

      <Button
        variant="text-only"
        fullWidth
        className="text-center text-rb-gray-300 max-w-[360px]"
        onClick={() => setIsModalOpen(true)}
      >
        <InformationIcon className="h-4 w-4 min-h-4 min-w-4 mr-2 fill-rb-gray-300" />
        Should I enroll live or on demand?
      </Button>
    </div>
  )

  const onPause = () => {
    setShowTrailerOverlay(true)
  }

  const onPlay = () => {
    setShowTrailerOverlay(false)
    playerRef.current?.play()

    trackVideoPlayed({
      object_type: 'preview',
      related_identifiers: {
        ccl_course_id: courseDetails?.id,
        course_title: courseDetails?.title,
        course_sanity_id: courseDetails?.sourceId,
        location: 'course_details_page'
      }
    })
  }

  const courseBlocks = [...(courseDetails?.blocks || [])].sort(
    (a, b) => a.displayOrder - b.displayOrder
  )

  const renderCourseBlocks = () => {
    return courseBlocks.map((block, index) => {
      return (
        <CourseDetailContent
          key={block?.id}
          courseDetail={courseDetails}
          courseBlock={block}
          position={index + 1}
        />
      )
    })
  }

  return (
    <div className="flex flex-col">
      <div className="flex">
        {isLoggedIn && (
          <BaseBreadcrumbs
            oneLevel
            breadcrumbPages={[{ title: 'all courses', path: COURSES_ON_DEMAND_PATH }]}
            backIcon={
              <ChevronLeftIcon width={16} height={16} className="mr-4 fill-rb-black" />
            }
          />
        )}
      </div>
      <div
        className={twMerge(
          'relative flex flex-col justify-between gap-8 tl:flex-row xl:pt-8',
          loggedOut && 'justify-center px-4'
        )}
      >
        {/* Left column */}
        <div className="flex w-full max-w-[792px] flex-col gap-6 pb-32 sm:px-4">
          <div className="flex flex-col gap-6">
            <RfHeader1 className="!mb-0 font-polysans">
              <span className="text-[32px] font-normal">{courseDetails?.title}</span>
            </RfHeader1>

            {/* Course Creators */}
            {courseDetails?.creators && courseDetails.creators.length > 0 && (
              <div className="flex flex-col items-start gap-4 sm:flex-row sm:items-center">
                {courseDetails?.creators?.map((creator, index) => {
                  if (!creator) return null

                  return (
                    <Link to={`/profiles/${creator.slug}`} rel="noreferrer" key={index}>
                      <ExpertPreview
                        expert={creator}
                        containerClassName="min-w-[240px] max-w-[240px] w-full"
                      />
                    </Link>
                  )
                })}
              </div>
            )}

            {/* Hero Image / Video */}
            {(courseDetails?.trailerUrl || courseDetails?.heroImageUrl) && (
              <div className="aspect-w-16 aspect-h-9 relative flex items-center justify-center">
                {courseDetails.trailerUrl && (
                  <div>
                    <MuxPlayer
                      poster={
                        `${courseDetails?.heroImageUrl}?auto=format&w=760` || undefined
                      }
                      ref={playerRef}
                      playbackId={courseDetails.trailerUrl}
                      streamType="on-demand"
                      onPause={onPause}
                      className={twMerge(
                        'h-auto w-full',
                        showTrailerOverlay && '[--controls:none]'
                      )}
                      onPlay={() => setShowTrailerOverlay(false)}
                    />

                    {showTrailerOverlay && (
                      <button
                        onClick={onPlay}
                        className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50"
                      >
                        <PlayIcon className="h-16 w-16 text-white" />
                      </button>
                    )}
                  </div>
                )}

                {!courseDetails.trailerUrl && courseDetails?.heroImageUrl && (
                  <img
                    src={`${courseDetails?.heroImageUrl}?auto=format&w=760`}
                    alt={courseDetails?.title || ''}
                    className="h-full w-full object-cover"
                  />
                )}
              </div>
            )}

            {/* Course description */}
            {courseDetails?.shortDescription && (
              <span className="font-polysans text-[20px] font-light text-rb-gray-400">
                {courseDetails?.shortDescription}
              </span>
            )}

            {enrollableSession && !hasMultipleEnrollableSessions && (
              <Button
                color="teal"
                className="hidden px-4 py-2.5 normal-case xl:block"
                size="small"
                onClick={onEnrollClick}
              >
                Enroll today
              </Button>
            )}

            {getRightColumn('xl:hidden')}
          </div>

          <div className="mt-5 flex max-w-[792px] flex-col gap-8">
            {/* Who this course is for Section */}
            {courseDetails?.audience && (
              <div>
                <RfHeader3 className="font-polysans">
                  <span className="text-[24px] font-normal">Who this course is for</span>
                </RfHeader3>
                <UnitComplexPortableText
                  content={JSON.parse(courseDetails?.audience || '{}')}
                />
              </div>
            )}

            {/* Course outcomes */}
            {courseDetails?.goals && (
              <div>
                <RfHeader3 className="font-polysans">
                  <span className="text-[24px] font-normal">Course outcomes</span>
                </RfHeader3>
                <UnitComplexPortableText
                  content={JSON.parse(courseDetails?.goals || '{}')}
                />
              </div>
            )}

            {/* Upcoming live courses */}
            {!!upcomingCourseSessions && upcomingCourseSessions.length > 0 && (
              <CourseDetailUpcomingSessions
                courseDetails={courseDetails}
                upcomingCourseSessions={upcomingCourseSessions}
                showTitle={hasMultipleEnrollableSessions}
              />
            )}

            {/* Course content */}
            {courseDetails?.outlineComplete &&
              courseBlocks &&
              courseBlocks.length > 0 && (
                <div>
                  <RfHeader3 className="font-polysans">
                    <span className="text-[24px] font-normal">Course content</span>
                  </RfHeader3>
                  <div className="flex flex-col gap-4">{renderCourseBlocks()}</div>
                </div>
              )}

            {loggedOut && <CourseDetailWhyTakeCourse />}

            {/* What others are saying */}
            {courseDetails?.testimonials && courseDetails.testimonials.length > 0 && (
              <CourseDetailTestimonials
                courseDetail={courseDetails}
                testimonials={courseDetails.testimonials}
              />
            )}

            {/* Meet the creators */}
            {courseDetails?.creators && courseDetails.creators.length > 0 && (
              <div>
                <RfHeader3 className="font-polysans">
                  <span className="text-[24px] font-normal">
                    Meet the creator
                    {courseDetails.creators.length > 1 ? 's' : ''}
                  </span>
                </RfHeader3>

                <div className="space-y-4">
                  {courseDetails.creators.map((creator) => (
                    <CourseDetailCreator key={creator?.slug} creator={creator} />
                  ))}
                </div>
              </div>
            )}

            {/* Related courses */}
            {relatedCourses && relatedCourses.length > 0 && (
              <CourseDetailRelatedCourses
                relatedCourses={relatedCourses}
                courseDetails={courseDetails}
                loggedOut={loggedOut}
              />
            )}
          </div>
        </div>

        {getRightColumn('hidden xl:flex')}

        <CourseFeatureGridModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </div>
    </div>
  )
}

export default CourseDetailsPage
