import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { cn } from 'utils/tailwind'
import { trackExpandClick } from 'utils/tracking/generated/events'

interface CourseCheckoutTestimonialProps {
  testimonial: any
  course: any
  position: number
  className?: string
}

const CourseCheckoutTestimonial = ({
  testimonial,
  course,
  position,
  className
}: CourseCheckoutTestimonialProps) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  const hasOverflow = testimonial?.quote?.length && testimonial?.quote?.length > 113

  const onExpandClick = () => {
    if (!expanded) {
      trackExpandClick({
        expandable_section_title: testimonial?.title || '',
        expandable_section_type: 'testimonial',
        location: 'course_details_page',
        related_identifiers: {
          ccl_course_id: course?.id,
          course_title: course?.title,
          course_sanity_id: course?.sourceId,
          position: position
        }
      })
    }

    setExpanded((prevState) => !prevState)
  }

  return (
    <div
      className={cn(
        'mr-6 flex h-full min-h-[206px] w-full max-w-[370px] flex-col bg-white gap-2.5 rounded-xl border border-rb-gray-100 p-6',
        className
      )}
    >
      <div>
        <RfParagraphMedium>{testimonial?.title}</RfParagraphMedium>
        <RfParagraphSmall>
          <span className="text-rb-gray-300">{testimonial?.subTitle}</span>
        </RfParagraphSmall>
      </div>

      <RfParagraphMedium className={twMerge(!expanded && 'line-clamp-4')}>
        {testimonial?.quote}
      </RfParagraphMedium>

      {hasOverflow && !expanded && (
        <button
          className="text-left text-sm text-rb-gray-300 underline"
          onClick={onExpandClick}
        >
          Read more
        </button>
      )}

      {testimonial.company?.logoUrl && (
        <div className="mt-auto max-w-[100px] self-end pt-4">
          <img
            className="h-auto max-h-[22px]"
            src={testimonial.company.logoUrl}
            alt={testimonial.company?.name || 'Company logo'}
          />
        </div>
      )}
    </div>
  )
}

export default CourseCheckoutTestimonial
