import { PaymentElement } from '@stripe/react-stripe-js'
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js'
import React, { forwardRef, useImperativeHandle, useState } from 'react'

import { useAddPaymentMethod } from 'components/PaymentMethods/AddPaymentMethod/useAddPaymentMethod'
import { ToggleSwitch } from 'components/ToggleSwitch'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import { BillingAddressFragment } from 'gql'

export type PaymentInfoFormExposedMethods = {
  save: () => Promise<string | undefined>
}

interface PaymentInfoFormProps {
  billingAddress?: BillingAddressFragment | null
  handlePaymentInfoChange: (e: StripePaymentElementChangeEvent) => void
  onSave?: (paymentMethodId: string) => void
  setAsDefaultOverride?: boolean
}

const PaymentInfoForm = (
  {
    billingAddress,
    handlePaymentInfoChange,
    onSave,
    setAsDefaultOverride
  }: PaymentInfoFormProps,
  ref: React.MutableRefObject<PaymentInfoFormExposedMethods>
) => {
  const [saveAsDefault, setSaveAsDefault] = useState(false)
  const addPaymentMethod = useAddPaymentMethod()

  const save = async () => {
    const paymentMethodId = await addPaymentMethod({
      saveAsDefault: saveAsDefault || setAsDefaultOverride,
      billingAddress
    })
    onSave?.(paymentMethodId)
    return paymentMethodId
  }

  /** Expose methods to parent */
  useImperativeHandle(ref, () => ({
    save
  }))

  return (
    <>
      <PaymentElement
        onChange={handlePaymentInfoChange}
        className="chromatic-ignore mb-4"
        options={{
          fields: {
            billingDetails: {
              address: 'never'
            }
          }
        }}
      />

      {!setAsDefaultOverride && (
        <div className="mb-4 flex items-center">
          <ToggleSwitch
            className="flex"
            toggled={saveAsDefault}
            handleClick={() => setSaveAsDefault((prev) => !prev)}
          />
          <RfParagraphMedium>Save as default payment method</RfParagraphMedium>
        </div>
      )}
    </>
  )
}

export default forwardRef(PaymentInfoForm)
