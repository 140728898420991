import Image from 'domains/Sanity/Image'

import { Image as ImageType } from 'gql'

const LogoSection = ({ logos }: { logos?: ImageType[] | null }) => {
  return (
    <section className="px-4 md:px-[4vw] py-[50px] lg:py-[100px]">
      <div className="mx-auto max-w-[1280px] flex flex-col items-center ">
        <div className="mb-6 text-sm !leading-[1.35] font-semibold text-rb-gray-300 lg:text-lg">
          Trusted by 1,000s of top tech companies
        </div>
        <div className="flex w-full flex-wrap justify-center md:justify-between gap-x-6 gap-y-3 lg:gap-y-6 xs:px-10 md:px-0">
          {logos?.map((logo) => {
            return (
              <div key={logo.imageUrl}>
                <Image
                  src={logo.imageUrl || ''}
                  alt={logo.imageAlt || ''}
                  className="h-[37px] sm:h-[50px] md:h-[37px] lg:h-[47px] xl:h-[60px]"
                />
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
export default LogoSection
