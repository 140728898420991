import { useState } from 'react'
import Pluralize from 'react-pluralize'

import { FacePile } from 'components/FacePile'

import ArtifactContext from './ArtifactContext'
import AuthorBylines from './AuthorBylines'
import BlockContent from './BlockContent'
import ContributorDropdown from './ContributorDropdown'
import { authorsToProps } from './utils'

interface ArtifactViewerHeroProps {
  artifactTitle: string
  authors: any[]
  companyName: string
  companyType: string
  companyMaturity: string
  companyEmployeeCount: string
  contributors: any[] // TODO: define these types!!
  summary: any[]
}

const ArtifactViewerHero = ({
  artifactTitle,
  authors,
  companyName,
  companyType,
  companyMaturity,
  companyEmployeeCount,
  contributors,
  summary
}: ArtifactViewerHeroProps) => {
  const [showDropdown, setShowDropdown] = useState(false)

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown)
  }

  return (
    <div>
      <div className="flex flex-col gap-4">
        <div className="flex w-full flex-row">
          <div className="w-full">
            <h1 className="mr-10 w-full text-2xl font-semibold md:text-4xl">
              {artifactTitle}
            </h1>
          </div>
          {/* avoid overlap with save button */}
          <div className="w-85 shrink-0" />
        </div>
        <div className="flex items-center justify-between">
          {!!authors && (
            <div className="relative flex w-full flex-wrap items-center justify-between gap-[15px]">
              <AuthorBylines bylines={authorsToProps(authors)} />

              {contributors.length > 0 && (
                <div>
                  <button
                    className="flex items-center bg-white hover:cursor-pointer hover:text-rb-teal-300"
                    onClick={toggleDropdown}
                  >
                    <FacePile
                      users={contributors.map((c) => c.user)}
                      limit={3}
                      imageSize="xlarge"
                      showExtraCount={false}
                    />
                    <div className="pl-[10px] text-xs font-semibold">
                      <Pluralize count={contributors.length} singular="contributor" />
                    </div>
                  </button>
                </div>
              )}

              {showDropdown && (
                <ContributorDropdown
                  contributors={contributors}
                  isOpen={showDropdown}
                  setIsOpen={setShowDropdown}
                />
              )}
            </div>
          )}
        </div>
        {summary && (
          <div className="bg-rb-orange-25 p-6 rounded-2xl">
            <div className="mb-4 text-xl leading-[1.4] font-semibold">Summary</div>
            {/* compensating for BlockContent including a 16px margin below the ul */}
            <div className="mb-[-16px] [&_li]:!mb-3">
              <BlockContent blocks={summary} />
            </div>
          </div>
        )}

        <ArtifactContext
          companyMaturity={companyMaturity}
          companyName={companyName}
          companyType={companyType}
          employeeCount={companyEmployeeCount}
        />
      </div>
    </div>
  )
}

export default ArtifactViewerHero
