import { useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import BadgeableAvatar from 'components/BadgeableAvatar'
import { SVGIcon } from 'components/Icon'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import {
  CohortActivityListItem,
  ReactionKind,
  useMarkActivitiesAsReadMutation
} from 'gql'

import { prettyDelimiter } from 'utils/arrayUtils'
import { kindToSvg } from 'utils/reactionUtils'
import { makeConversationUrl } from 'utils/url'

interface ReactionsProps {
  reactions: ReactionKind[]
}

const Reactions = ({ reactions }: ReactionsProps) => (
  <>
    {'reacted with '}
    {reactions.map((reaction, i) => (
      <>
        <SVGIcon
          name={kindToSvg[reaction]}
          height="16"
          width="16"
          fill="black"
          className="inline-block"
        />
        {reactions.length > 1 &&
          i !== reactions.length - 1 &&
          prettyDelimiter(reactions.length, i, ',', 'and')}
      </>
    ))}
    {' to '}
  </>
)

interface CohortActivityProps {
  activity: CohortActivityListItem
  cohortSlug: string
  refetchActivities?(): Promise<unknown>
  isNotificationsFeed?: boolean
  textClassName?: string
}

const CohortActivity = ({
  activity,
  cohortSlug,
  refetchActivities,
  isNotificationsFeed = false,
  textClassName
}: CohortActivityProps) => {
  const history = useHistory()
  const [markActivitiesAsRead] = useMarkActivitiesAsReadMutation({
    variables: { input: { ids: activity.unreadActivityIds || [] } }
  })
  const isReply = activity.actionType === 'Reply'
  const isReaction = activity.actionType === 'Reaction'
  const isNewPost = activity.actionType === 'added'

  const markAllRead = async () => {
    if (isNotificationsFeed && activity.unreadActivityIds?.length) {
      await markActivitiesAsRead()
      refetchActivities?.()
    }

    history.push({
      pathname: makeConversationUrl(cohortSlug, activity.postTopicSlug, activity.postId),
      search: isNotificationsFeed ? '?refreshBadgeCount=true' : ''
    })
  }

  return (
    <div
      className={twMerge(
        'rounded-lg border border-rb-gray-100 p-4',
        activity.anyActivityUnread && 'bg-rb-teal-50'
      )}
      role="button"
      tabIndex={0}
      onKeyDown={markAllRead}
      onClick={markAllRead}
    >
      <div className="flex flex-row">
        <BadgeableAvatar
          className="mr-4"
          width={40}
          height={40}
          avatarUrl={activity.userAvatarUrl || ''}
          fullName={activity.userFullName}
        />
        <div className="flex flex-col">
          <div className="flex flex-row items-start">
            <RfParagraphSmall
              className={twMerge('line-clamp-3 sm:line-clamp-1', textClassName)}
            >
              <span className="font-medium">
                {activity.userFullName || 'A member'}&nbsp;
              </span>
              {isReaction && <Reactions reactions={activity.reactions} />}
              {isReply && 'replied to '}
              {isNewPost && 'posted in '}
              {(isReaction || isReply) && (
                <span className="font-medium">{activity.postBodyText}</span>
              )}
              {isNewPost && (
                <span className="font-medium">{activity.postTopicTitle}</span>
              )}
            </RfParagraphSmall>
          </div>
          <RfParagraphSmall color={ColorOptions.gray}>
            {activity.agoTimephrase}
          </RfParagraphSmall>
        </div>
      </div>
      {(isReply || isNewPost) && (
        <div className="ml-14 mt-3 line-clamp-2">
          <RfParagraphSmall>
            {isReply && activity.replies[activity.replies.length - 1]}
            {isNewPost && activity.postBodyText}
          </RfParagraphSmall>
        </div>
      )}
    </div>
  )
}

export default CohortActivity
