import { ReactElement } from 'react'

import { SVGIcon } from 'components/Icon'

import { trackCtaClicked } from 'utils/tracking/analytics'
import {
  BLOG_GROWTH_LOOPS,
  BLOG_GROWTH_METRIC,
  BLOG_GROWTH_SYSTEM,
  REFORGE_URL,
  REVIEWS,
  makeUrl
} from 'utils/url'

const listItems = [
  {
    icon: <SVGIcon width="34" height="35" name="task-checklist" />,
    copy: "Make a plan for how you'll make time to apply what you learn to show your commitment."
  },
  {
    icon: <SVGIcon width="34" height="34" name="profit" />,
    copy: 'Position the program as an investment, not an expense.'
  },
  {
    icon: <SVGIcon width="34" height="34" name="investment" className="sm:-mr-1" />,
    copy: "Research your company's learning and development budget."
  },
  {
    icon: <SVGIcon width="34" height="30" name="piggy-bank" />,
    copy: 'Try tapping into a conference budget.'
  },
  {
    icon: <SVGIcon width="34" height="34" name="group-of-three" />,
    copy: <AlumniReviewsCopy />
  },
  {
    icon: (
      <SVGIcon width="34" height="37" name="view-document" className="-mr-1 sm:-mr-2" />
    ),
    copy: 'Share our content with your manager.'
  }
]

export function ReimbursementTips() {
  return (
    <section>
      <h2 className="mb-4 text-base font-semibold lg:text-xl">Reimbursement Tips</h2>
      <ul className="list-none">
        {listItems.map(({ icon, copy }, i) => (
          <li
            className="-ml-3 mb-6 sm:mb-10 lg:ml-0"
            key={`reimbursement-list-item-${i}`}
          >
            <ReimbursementListItem icon={icon} copy={copy} />
          </li>
        ))}
        <li className="-mt-10">
          <ReimbursementListItem
            icon={<SVGIcon width="33" height="37" />}
            copy={<ShareContentLinks />}
          />
        </li>
      </ul>
    </section>
  )
}

// Sub-components
function ReimbursementListItem({
  icon,
  copy
}: {
  icon: ReactElement
  copy: string | ReactElement
}) {
  return (
    <div className="-ml-3 mb-6 flex items-center sm:mb-10 lg:ml-0">
      <span className="flex w-12 justify-center">{icon}</span>
      <div className="ml-6 sm:ml-7 md:ml-9 lg:ml-14">{copy}</div>
    </div>
  )
}

function AlumniReviewsCopy() {
  return (
    <>
      Include{' '}
      <a
        className="text-rb-teal-200 underline"
        href={makeUrl(`${REFORGE_URL}${REVIEWS}`)}
        onClick={() => trackReimbursementTipLink('alumni reviews')}
      >
        alumni reviews
      </a>{' '}
      to add even more value.
    </>
  )
}

const GROWTH_METRIC_COPY =
  'The One Growth Metric that Moves Acquisition, Monetization, and Virality'
const GROWTH_BUILDING_COPY = 'Building Growth: Product, Process, and Team'
const GROWTH_LOOPS_COPY = 'Growth Loops are the New Funnels'
function ShareContentLinks() {
  return (
    <>
      <ul className="mt-6 sm:ml-2 sm:mt-0">
        <li>
          <a
            className="text-rb-teal-200 underline"
            href={makeUrl(`${REFORGE_URL}${BLOG_GROWTH_METRIC}`)}
            onClick={() => {
              trackReimbursementTipLink(GROWTH_METRIC_COPY)
            }}
          >
            {GROWTH_METRIC_COPY}
          </a>
        </li>
        <li>
          <a
            className="text-rb-teal-200 underline"
            href={makeUrl(`${REFORGE_URL}${BLOG_GROWTH_SYSTEM}`)}
            onClick={() => {
              trackReimbursementTipLink(GROWTH_BUILDING_COPY)
            }}
          >
            {GROWTH_BUILDING_COPY}
          </a>
        </li>
        <li>
          <a
            className="text-rb-teal-200 underline"
            href={makeUrl(`${REFORGE_URL}${BLOG_GROWTH_LOOPS}`)}
            onClick={() => {
              trackReimbursementTipLink(GROWTH_LOOPS_COPY)
            }}
          >
            {GROWTH_LOOPS_COPY}
          </a>
        </li>
      </ul>
    </>
  )
}

function trackReimbursementTipLink(text: string) {
  trackCtaClicked({
    cta_type: 'hyperlink text',
    cta_location: 'tips_section',
    text
  })
}
