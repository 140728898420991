import { twMerge } from 'tailwind-merge'

import Button from './Button'

const arrowPositionMap = {
  bottom: 'left-[138px] top-[-7px]',
  top: 'left-[138px] bottom-[-7px] transform rotate-180',
  right: 'top-1/2 left-[-14px] transform -translate-y-1/2 -rotate-90',
  left: 'top-1/2 right-[-14px] transform -translate-y-1/2 rotate-90',
  topRight: 'left-[20px] bottom-[-7px] transform rotate-180',
  topLeft: 'right-[20px] bottom-[-7px] transform rotate-180',
  bottomRight: 'left-[20px] top-[-7px]',
  bottomLeft: 'right-[20px] top-[-7px]'
}

const calloutPositionMap = {
  bottom: { top: 'calc(100% + 8px)', left: '50%', transform: 'translateX(-50%)' },
  top: { bottom: 'calc(100% + 8px)', left: '50%', transform: 'translateX(-50%)' },
  right: { left: 'calc(100% + 8px)', top: '50%', transform: 'translateY(-50%)' },
  left: { right: 'calc(100% + 8px)', top: '50%', transform: 'translateY(-50%)' },
  topRight: { bottom: 'calc(100% + 8px)', left: '-15px' },
  topLeft: { bottom: 'calc(100% + 8px)', right: '-15px' },
  bottomLeft: { top: 'calc(100% + 8px)', right: '-15px' },
  bottomRight: { top: 'calc(100% + 8px)', left: '-15px' }
}

interface CalloutV2Props {
  position:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'topRight'
    | 'topLeft'
    | 'bottomRight'
    | 'bottomLeft'
  title: string
  ctaText: string
  ctaAction: () => void
  bodyText: string
  betaTag?: boolean
}

const CalloutV2 = ({
  title,
  ctaText,
  ctaAction,
  bodyText,
  position,
  betaTag
}: CalloutV2Props) => {
  return (
    <div className="absolute z-1000" style={calloutPositionMap[position]}>
      <div className="p-8 pt-6 flex flex-col items-center gap-6 rounded-xl shadow-rb-hover text-white bg-rb-black">
        <div className="flex flex-col gap-1">
          {betaTag && (
            <div className="text-xs font-semibold justify-center flex">
              New! Beta Release
            </div>
          )}
          <div className="flex flex-col gap-2">
            <div className="text-4xl font-normal w-max font-polysans text-center">
              {title}
            </div>
            <div className="text-center font-normal">{bodyText}</div>
          </div>
        </div>
        <Button variant="outline" onClick={ctaAction}>
          {ctaText}
        </Button>
      </div>
      <CalloutArrow position={position} />
    </div>
  )
}

const CalloutArrow = ({
  position
}: {
  position:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'topRight'
    | 'topLeft'
    | 'bottomRight'
    | 'bottomLeft'
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="12"
      viewBox="0 0 23 12"
      fill="none"
      className={twMerge('absolute', arrowPositionMap[position])}
    >
      <path d="M22.6274 11.6855L11.3137 0.371839L0 11.6855H22.6274Z" fill="#080a0a" />
      <rect width="23" height="3" transform="matrix(1 0 0 -1 0 12)" fill="#080a0a" />
    </svg>
  )
}

export default CalloutV2
