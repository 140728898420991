import { closing, opening } from './sharedMarkdown'

const programName = 'Brand Marketing'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://reforge.com/brand-marketing), because I believe it will help me deliver impact through brand marketing in the world of product and growth. This course was built with [Josh Grau](https://www.reforge.com/experts/josh-grau), [Kira Klaas](https://www.reforge.com/experts/kira-klaas), and [Patrick Moran](https://www.reforge.com/experts/patrick-moran), and covers:
* **The Impact of Brand Distribution.** Learn how to select touchpoints, get buy-in for your brand initiatives, and evaluate the impact of brand initiatives to prove business growth.
* **Brand Strategy.** Develop a brand strategy that will serve as a compass to guide all of your brand marketing efforts.
* **Brand Identity and Governance.** Bring your brand to life by codifying each component of your brand identity.
* **Managing Brand Marketing.** Examine key moments in the life of your brand and determine how to respond in each situation.

&NewLine;

${closing}
`

export default { markdown, title: programName }
