import { Link } from 'react-router-dom'

import Image from 'domains/Sanity/Image'

import { CourseForTopicAndFunction } from 'gql'

import { trackNavigationClicked } from 'utils/tracking/analytics'

interface CoursesSeoCarouselCardProps {
  course: CourseForTopicAndFunction
}

const onNavigationClick = (course: CourseForTopicAndFunction) => {
  trackNavigationClicked({
    type: 'card',
    text: course.title.toLocaleLowerCase(),
    location: 'SEO other courses carousel'
  })
}

const CoursesSeoCarouselCard = ({ course }: CoursesSeoCarouselCardProps) => {
  const creatorsLength = course.creators?.length || 0

  return (
    <Link
      to={`/courses/${course.slug}`}
      className="flex w-[288px] flex-col rounded-xl border border-rb-gray-100 p-4 text-rb-gray-400 hover:text-rb-gray-400 hover:no-underline hover:shadow-seo-page-card md:w-[400px]"
      onClick={() => onNavigationClick(course)}
    >
      <div className="mb-4 flex gap-2">
        {course.creators?.map((creator) => (
          <div
            className="h-[146px] overflow-hidden rounded-xl md:h-[210px]"
            key={creator.id}
            style={{ width: `calc(${Math.min(50, 100 / creatorsLength)}%)` }}
          >
            <Image
              src={creator.avatarPhoto?.imageUrl || ''}
              alt={creator.avatarPhoto?.imageAlt || creator.name || ''}
              className="h-full w-full object-cover"
            />
          </div>
        ))}
      </div>

      <div className="flex h-full flex-col">
        <p className="mb-0 text-xl font-semibold leading-[1.4]">{course.title}</p>
        <p className="mb-2 text-sm leading-[1.5] text-rb-gray-300 line-clamp-1">
          Created by{' '}
          {course.creators?.map((creator, i) => {
            return `${i === creatorsLength - 1 && i !== 0 ? ' & ' : ''}${creator.name}${
              i < creatorsLength - 2 ? ', ' : ''
            }`
          })}
        </p>
        <p className="mb-2 text-base leading-[1.6] line-clamp-2">
          {course.shortDescription}
        </p>
        <p className="mb-0 mt-auto text-base leading-[1.5] text-rb-gray-300 underline">
          Learn more
        </p>
      </div>
    </Link>
  )
}

export default CoursesSeoCarouselCard
