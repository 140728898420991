import * as React from 'react'

import Button from 'components/Button'

interface EditableSectionProps {
  handleEditClick?: React.MouseEventHandler<HTMLButtonElement>
  heading?: string
  text?: any
  ctaText: string
  dataTest?: string
}

const EditableSection = ({
  handleEditClick,
  heading,
  text,
  ctaText,
  dataTest
}: EditableSectionProps) => {
  return (
    <div className="sm: mb-2.5 flex flex-col items-center rounded-sm border border-rb-gray-100 p-3 text-rb-gray-400 sm:mb-4 sm:p-4 tl:mb-6 ">
      {heading && (
        <div className="pb-1 text-sm font-semibold sm:text-base">{heading}</div>
      )}
      <div className="pb-3 text-center text-xs sm:text-sm">{text}</div>
      <Button
        className="font-semibold"
        onClick={handleEditClick}
        shape="rounded-full"
        size="x-small"
        variant="outline"
        dataTest={dataTest}
      >
        {ctaText}
      </Button>
    </div>
  )
}

export default EditableSection
