import { ReactElement } from 'react'

import { ModalPropsWithoutProvidedProps, useGlobalModal } from 'components/GlobalModal'

import { CompletionSnapshot, useCompletionSnapshotQuery } from 'gql'

import { track } from 'utils/tracking/segment'

import { ShareableSnapshotModalContent } from './ShareableSnapshotModalContent'

const trackShareableSnapshotModalOpen = (
  snapshotData: CompletionSnapshot,
  page: string
) => {
  // @ts-ignore - 'Shareable Snapshot Modal Shown' event is not defined in Segment JIRA#REF-5159
  track('Shareable Snapshot Modal Shown', {
    page: page,
    user_cohort_id: snapshotData.userCohortId,
    cohort_id: snapshotData.cohortId
  })
}

const trackShareableSnapshotModalClose = (
  snapshotData: CompletionSnapshot,
  page: string
) => {
  // @ts-ignore - 'Shareable Snapshot Modal Dismissed' event is not defined in Segment JIRA#REF-5159
  track('Shareable Snapshot Modal Dismissed', {
    page: page,
    user_cohort_id: snapshotData.userCohortId,
    cohort_id: snapshotData.cohortId
  })
}

export const getSnapshotDismissalCookieName = (seasonName: string) => {
  return `dismiss-shareable-snapshot-${seasonName}`
}

export const useShareableSnapshotModal = () => {
  const { openGlobalModal, closeGlobalModal } = useGlobalModal()
  const { data: snapshotData, loading } = useCompletionSnapshotQuery()
  const completionSnapshot = snapshotData?.completionSnapshot as CompletionSnapshot | null

  const showShareableSnapshotModal = async (page: string = '') => {
    if (completionSnapshot && canShowSnapshotModal() && !loading) {
      openShareableSnapshotModal({
        snapshotData: completionSnapshot,
        page,
        openGlobalModal,
        closeGlobalModal,
        isOneTime: false
      })
    }
  }

  const canShowSnapshotModal = (userCohortId?: string) => {
    const defaultResult =
      completionSnapshot && !!completionSnapshot.isReleased && !!completionSnapshot.token

    if (userCohortId) {
      return defaultResult && userCohortId === completionSnapshot.userCohortId
    }

    return defaultResult
  }

  return {
    showShareableSnapshotModal,
    canShowSnapshotModal
  }
}

export const openShareableSnapshotModal = ({
  snapshotData,
  page,
  openGlobalModal,
  closeGlobalModal,
  isOneTime
}: {
  snapshotData: CompletionSnapshot
  page: string
  openGlobalModal: (
    content: ReactElement,
    modalProps: ModalPropsWithoutProvidedProps
  ) => void
  closeGlobalModal: () => void
  // used to differentiate between a snapshot modal that displays automatically on page load
  // (e.g. when the active cohort is complete and the user sees the snapshot for the first time)
  // and snapshot modals displayed on user interaction (e.g. on /cohort-history page)
  isOneTime: boolean
}) => {
  openGlobalModal(
    <ShareableSnapshotModalContent
      snapshotData={snapshotData}
      page={page}
      showConfetti={isOneTime}
    />,
    {
      className: 'mx-auto w-[320px] sm:w-[320px] tl:w-[800px] 2xl:w-[800px]',
      closeOnOutsideClick: false,
      handleClose: () => {
        trackShareableSnapshotModalClose(snapshotData, page)
        closeGlobalModal()

        if (isOneTime && snapshotData?.seasonName) {
          window.localStorage.setItem(
            getSnapshotDismissalCookieName(snapshotData.seasonName),
            '1'
          )
        }
      }
    }
  )

  trackShareableSnapshotModalOpen(snapshotData, page)
}
