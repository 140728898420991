import pluralize from 'pluralize'
import { useHistory } from 'react-router-dom'

import { SubscriptionManagementCard } from 'domains/Subscription/SubscriptionCard/SubscriptionManagementCards'

import { useCheckoutModal } from 'components/CheckoutModal/useCheckoutModal'

import { trackCtaClicked } from 'utils/tracking/analytics'

export interface CohortPassesCardProps {
  numTotalCohortPasses: number
  numUsedCohortPasses: number
  isSubscriptionOwner: boolean
  isAssistantAdmin?: boolean
  isIndividual?: boolean
  isTrialing?: boolean
}

export const CohortPassesCard = ({
  numTotalCohortPasses,
  numUsedCohortPasses,
  isSubscriptionOwner,
  isAssistantAdmin,
  isIndividual,
  isTrialing
}: CohortPassesCardProps) => {
  const history = useHistory()
  const { openCheckoutModal } = useCheckoutModal()

  let secondaryButton
  if (isSubscriptionOwner && !isTrialing) {
    secondaryButton = {
      copy: 'Buy Passes',
      dataTest: 'buy-passes-button',
      onclick: () => {
        trackCtaClicked({
          cta_location: 'subscription_card',
          cta_type: 'button',
          text: 'buy passes'
        })
        openCheckoutModal({ isIndividual, flowContext: 'cohortPassPurchase' })
      }
    }
  }

  return (
    <SubscriptionManagementCard
      dataTestId="cohort-passes-card"
      heading="Live Course Passes"
      contentLeft={
        numTotalCohortPasses === 0
          ? 'No Live Course Passes'
          : `${numTotalCohortPasses} ${pluralize(
              'Live Course Pass',
              numTotalCohortPasses
            )}`
      }
      progressBar={{
        type: 'pass',
        isIndividual,
        used: numUsedCohortPasses,
        total: numTotalCohortPasses,
        color: 'rb-blue-100',
        cta:
          (isSubscriptionOwner || isAssistantAdmin) &&
          !isIndividual &&
          numTotalCohortPasses > 1
            ? {
                copy: 'Assign Passes',
                onclick: () => {
                  trackCtaClicked({
                    cta_location: 'subscription_card',
                    cta_type: 'button',
                    text: 'assign passes',
                    destination: '/team_members'
                  })
                  history.push('/team_members')
                }
              }
            : undefined
      }}
      secondaryButton={secondaryButton}
      details={
        !isSubscriptionOwner &&
        !isAssistantAdmin &&
        numTotalCohortPasses - numUsedCohortPasses < 1 ? (
          <p className="text-sm">
            Contact your subscription administrator for access to a Live Course Pass.
          </p>
        ) : undefined
      }
    />
  )
}
