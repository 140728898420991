import AccountLayout from 'domains/Account/AccountLayout'
import { MyOrganization } from 'domains/Org/MyOrganization'

import { ErrorMessage, Loading } from 'components'

import { UserOrgFragment, useUserOrgQuery } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

const AccountMyOrganizationContainer = () => {
  const { id } = useAssertCurrentUser()

  const { loading, error, data } = useUserOrgQuery({
    variables: { id }
  })

  // NOTE: I just made this not-break, but later we should deal with finding the
  // org only if the person is the admin of the org. I'm just sayin'.
  const org = data?.user?.subscriptions?.active?.org
  if (loading) return <Loading />
  if (error) return <ErrorMessage error={error} />
  if (!org) return <ErrorMessage error={'Organization could not be found'} />

  return <AccountMyOrganization org={org} />
}

interface AccountMyOrganizationProps {
  org: UserOrgFragment
}

const AccountMyOrganization = ({ org }: AccountMyOrganizationProps) => {
  return (
    <AccountLayout>
      <MyOrganization org={org} />
    </AccountLayout>
  )
}

export default AccountMyOrganizationContainer
