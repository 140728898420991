import { useCohortConversationTrackingContext } from 'domains/CohortConversation/hooks/useCohortConversationsTrackingContext'

import { useDeleteCohortReplyMutation } from 'gql'

import { trackReplyAction } from 'utils/tracking/analytics'

export interface CohortReplyDeleteProps {
  replyId: string
  replyToId: string
  replyToType?: string
}

export const useDeleteCohortReply = () => {
  const [deleteCohortReplyMutation] = useDeleteCohortReplyMutation()
  const { cohort, topicId, groupId } = useCohortConversationTrackingContext()

  const deleteCohortReply = async ({
    replyToId,
    replyToType = 'post',
    replyId
  }: CohortReplyDeleteProps) => {
    const response = await deleteCohortReplyMutation({
      variables: { input: { id: replyId } }
    })

    const responseReplyId = response?.data?.deleteCohortReply?.id
    if (responseReplyId) {
      trackReplyAction({
        action: 'delete',
        cms_program_id: cohort?.cmsProgram?.id,
        cohort_id: cohort?.id,
        group_ids: [groupId],
        reply_id: replyId,
        reply_to_id: replyToId,
        reply_to_type: replyToType,
        season_id: cohort?.season?.id,
        topic_ids: [topicId]
      })
    }
  }
  return { deleteCohortReply }
}

export default useDeleteCohortReply
