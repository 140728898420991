import ReactDOMServer from 'react-dom/server'

import { qa } from 'components/CohortDashboard/SupportModal'
import { FAQ } from 'components/FAQs'
import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'

const FAQS: Array<qa> = [
  {
    question: 'When are the next courses being offered? ',
    answer: (
      <p>
        There will be a new slate of courses that will start in Q1 of 2024. Keep an eye
        out for more information about these courses. You can make sure you are eligible
        to enroll with your membership by checking with your plan manager or reaching out
        to hello@reforge.com.
      </p>
    )
  },
  {
    question: 'How do I receive my Certificate of Completion? ',
    answer: (
      <>
        <p>
          You will be able to access and download your Certificate of Completion directly
          from within the platform.
        </p>
        <br />
        <p>
          Please note that this program is not graded. Reforge is intentionally not an
          accredited institution, therefore there are no tests, no quizzes, and no grades.
          We don’t want you to prove how much you learned through these artificial
          mechanisms. Instead, we’re excited to see the impact you drive in your company
          and your career. That will be the best indicator of what you learned.
        </p>
      </>
    )
  },
  {
    question: 'Do I have access to the content and my Courses dashboard? ',
    answer: (
      <>
        <p>
          You will continue to have access to all the program content and recordings for
          the program you’re enrolled in! You can access event recordings by visiting your
          Past Live Courses from{' '}
          <a href="https://reforge.com/courses">the courses page</a> or by clicking into
          your Live Course dashboard and finding the recordings listed on the event each
          week. As a member, your bookmarks and highlights will also always accessible via{' '}
          <a href="https://reforge.com/saved">Saved</a>.
        </p>
        <br />
        <p>
          You also have access to the broader library of Reforge content as part of your
          membership.
        </p>
        <br />
        <p>
          Please note that the Slack channel will no longer be monitored once the Live
          Course ends, but you can reach out and connect with other members through
          Reforge’s member Slack!
        </p>
      </>
    )
  },
  {
    question: 'How can I share Reforge content with my team? ',
    answer: (
      <>
        <p>
          We encourage you to share your learnings with your team! We often hear from
          members that they like to share insights from their Reforge program with their
          team in the form of lunch and learns, slide decks, and more. A few ways you can
          do this:
        </p>
        <ol type="1">
          <li>
            {' '}
            You may download templates and materials clearly designated as a
            “downloadable” within Reforge and you may take notes summarizing materials,
            for your personal use or for use internally within your organization. You can
            learn more about our Terms of Service{' '}
            <a href="https://www.reforge.com/terms-of-service">here</a>.
          </li>
          <li>
            If you’re interested in additional ways of leveraging Reforge with your team,
            you can learn more about Team Memberships and get in touch with us{' '}
            <a href="https://www.reforge.com/teams">here</a>.
          </li>
          <li>
            You can also share unlimited{' '}
            <a href="https://reforge.com/artifacts">Reforge Artifacts</a> with members of
            your team. Team members will just need to create their own free accounts to
            view the full Artifact. Select Artifacts allow you to create copies in the
            original format so you can tailor the Artifact for use in your organization.
          </li>
        </ol>
      </>
    )
  },
  {
    question:
      'How can I get more involved with Reforge to share my expertise with the broader community? ',
    answer: (
      <>
        <p>
          We are looking to expand the Reforge network by bringing on new Artifacts
          creators. <a href="https://reforge.com/artifacts">Artifacts</a> are a way to
          showcase your expertise through the real work you do. Artifact creators get a
          profile with Reforge that serves as a platform for additional opportunities. You
          can get started by submitting your Artifacts{' '}
          <a href="https://artifacts.typeform.com/to/CfQmVXNA?typeform-source=artifacts.reforge.com">
            here
          </a>
          .
        </p>
        <br />
        <p>
          Most Artifacts take less than 20 minutes to create. A good Artifact captures
          your <i>real</i> work: a presentation you gave, PRD you wrote, analysis you
          completed, or dashboard you created. If you filled out a template from your
          Reforge course based on your company, or you applied a Reforge framework to a
          situation you were facing at work, that can be a great Artifact, too!
        </p>
      </>
    )
  },
  {
    question: 'How do I represent my Reforge experience on my LinkedIn? ',
    answer: (
      <p>
        At Reforge we focus on impact over credentials, however, we know it’s valuable to
        highlight your hard work and what you’ve accomplished as a member. We recommend
        adding your completed programs to the “Licenses & Certifications” section of your
        profile and your status as a member under the “Experience” section.
      </p>
    )
  },
  {
    question: 'How can I see more real examples of how people apply Reforge at work? ',
    answer: (
      <>
        <p>
          Check out our new product, <a href="https://reforge.com/artifacts">Artifacts</a>
          . Artifacts are examples of real work created by members of our community,
          allowing you to see how practitioners apply Reforge learnings across diverse
          companies, industries, business models, and geographies.
        </p>
        <br />
        <p>
          Artifacts allow you to never start from scratch—see real strategy presentations,
          PRDs, analyses, org charts, career ladders, GTM plans, and more from some of the
          best in the business.
        </p>
      </>
    )
  }
]

export const CohortOffboardingFaqs = () => {
  return (
    <div className="w-full max-w-[890px]">
      <RfHeader3SemiBold className="!mb-12">Frequently asked questions</RfHeader3SemiBold>
      {FAQS.map((faq, idx) => (
        <FAQ
          key={`faq_${idx + 1}`}
          question={faq.question}
          answer={ReactDOMServer.renderToString(faq.answer)}
        />
      ))}
    </div>
  )
}

export default CohortOffboardingFaqs
