export function TailwindIndicator() {
  if (process.env.NODE_ENV === 'production') return null

  return (
    <div className="font-mono fixed bottom-1 left-1/2 -ml-3 flex h-6 w-6 items-center justify-center rounded-full bg-gray-800 p-3 text-xs text-white">
      <div className="block xs:hidden">2xs</div>
      <div className="hidden xs:block sm:hidden">xs</div>
      <div className="hidden sm:block md:hidden">sm</div>
      <div className="hidden md:block tl:hidden">md</div>
      <div className="hidden tl:block lg:hidden">tl</div>
      <div className="hidden lg:block lgr:hidden">lg</div>
      <div className="hidden lgr:block xl:hidden">lgr</div>
      <div className="hidden xl:block 2xl:hidden">xl</div>
      <div className="hidden 2xl:block 3xl:hidden">2xl</div>
      <div className="hidden 3xl:block">3xl</div>
    </div>
  )
}
