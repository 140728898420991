import { useState } from 'react'
import UIkit from 'uikit'

import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'

import { useCancelRsvpMutation, useNotAttendingMutation, useRsvpMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import {
  DAY_MONTH_ABBREV_NO_TIME_FORMAT,
  MONTH_ABBREV_FORMAT,
  TWO_DIGIT_DATE,
  formatInTimezone,
  getCurrentTimezone,
  getFormattedStartDate,
  getFormattedTimeWithTimeZone
} from 'utils/date'
import notifyError from 'utils/errorNotifier'
import { isEventInSession } from 'utils/eventUtils'
import { cn } from 'utils/tailwind'
import sendEventTrackingEvent from 'utils/tracking/event'

import EventRsvpButton from './EventRsvpButton/EventRsvpButton'
import { EventRsvpProps } from './types'

const EventRsvp = ({
  args: { event, timezone, dataTestId, attendCallback, notAttendCallback, ...rest }
}: EventRsvpProps) => {
  const { currentUser } = useCurrentUser()
  const { startsAtUtc, endsAtUtc } = event
  const userTimezone = getCurrentTimezone(currentUser?.timezone || timezone)
  const eventRecordingAvailable = event.past && !!event.summaryWistiaCodeOptional

  const formattedStartDate = getFormattedStartDate(
    startsAtUtc,
    userTimezone,
    DAY_MONTH_ABBREV_NO_TIME_FORMAT
  )
  const formattedTimeWithTimezone = getFormattedTimeWithTimeZone(
    startsAtUtc,
    endsAtUtc,
    userTimezone
  )
  const [createRsvp] = useRsvpMutation()
  const [cancelRsvp] = useCancelRsvpMutation()
  const [notAttending] = useNotAttendingMutation()

  const [attendingStatus, setAttendingStatus] = useState(event.attendingStatus)

  const currentTimezone = getCurrentTimezone(userTimezone)

  const eventIsInSession = isEventInSession(event.startsAtUtc, event.endsAtUtc)
  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)

  const rsvpYesOrAttend = async () => {
    if (!eventIsInSession) {
      if (document.getElementById(`#event-card-dropdown-${event.id}`)) {
        window.UIkit.dropdown(`#event-card-dropdown-${event.id}`).hide(0)
      }
    }

    let data
    try {
      const response = await createRsvp({
        variables: {
          input: {
            id: event.id.toString(),
            timezone: currentTimezone,
            happening: eventIsInSession
          }
        }
      })
      data = response.data
    } catch (err) {
      return notifyError(err)
    }

    if (data?.rsvp?.errors?.length) {
      const { errors } = data.rsvp
      UIkit.notification({
        message: `<span uk-icon="icon: warning"></span> ${errors[0]}`,
        status: 'warning',
        pos: 'top-right'
      })

      return notifyError(errors)
    }

    setAttendingStatus('Attending')
    if (attendCallback) {
      attendCallback({ ...event, attendingStatus: 'Attending' })
    }
  }

  const rsvpNotAttending = async (e: any) => {
    e.stopPropagation()

    sendEventTrackingEvent('cant_make_it_click', event, currentTimezone)

    if (document.getElementById(`#event-card-dropdown-${event.id}`)) {
      window.UIkit.dropdown(`#event-card-dropdown-${event.id}`).hide(0)
    }

    let data
    try {
      const response = await notAttending({
        variables: { input: { id: event.id.toString() } }
      })
      data = response.data
    } catch (err) {
      return notifyError(err)
    }

    if (data?.notAttending?.errors?.length) {
      const { errors } = data.notAttending
      UIkit.notification({
        message: `<span uk-icon="icon: warning"></span> ${errors[0]}`,
        status: 'warning',
        pos: 'top-right'
      })

      return notifyError(errors)
    }

    setAttendingStatus('Not Attending')

    if (notAttendCallback) {
      notAttendCallback({ ...event, attendingStatus: 'Not Attending' })
    }
  }

  const rsvpCancel = async (e: any) => {
    e.stopPropagation()

    if (document.getElementById(`#event-card-dropdown-${event.id}`)) {
      window.UIkit.dropdown(`#event-card-dropdown-${event.id}`).hide(0)
    }

    let data
    try {
      const response = await cancelRsvp({
        variables: { input: { id: event.id.toString() } }
      })
      data = response.data
    } catch (err) {
      return notifyError(err)
    }

    if (data?.cancelRsvp?.errors?.length) {
      const { errors } = data.cancelRsvp
      UIkit.notification({
        message: `<span uk-icon="icon: warning"></span> ${errors[0]}`,
        status: 'warning',
        pos: 'top-right'
      })

      return notifyError(errors)
    }

    setAttendingStatus('Not Attending')

    if (notAttendCallback) {
      notAttendCallback({ ...event, attendingStatus: 'Not Attending' })
    }
  }

  return (
    <div className="grid w-full grid-flow-row gap-5 rounded-md border border-rb-gray bg-white p-6 md:static md:w-[360px] md:rounded-lg">
      {event.past && <div className="text-xl font-semibold">This event has ended</div>}

      <div className="flex space-x-4">
        <div className="flex flex-col items-center rounded-md bg-rb-blue-50 py-1.5 px-3.5">
          <div className="text-xl font-semibold">
            {formatInTimezone(startsAtUtc, userTimezone, TWO_DIGIT_DATE)}
          </div>
          <div className="text-sm font-normal">
            {formatInTimezone(
              startsAtUtc,
              userTimezone,
              MONTH_ABBREV_FORMAT
            ).toUpperCase()}
          </div>
        </div>
        <div className="flex items-center">
          <div>
            <div className="text-sm font-semibold">{formattedStartDate}</div>
            <div className="text-sm text-rb-gray-300">{formattedTimeWithTimezone}</div>
          </div>
        </div>
      </div>

      <div
        className={cn(
          'md:static',
          isMobile &&
            'fixed bottom-0 left-0 z-1 w-full border border-rb-gray bg-white p-6',
          event.past && 'hidden'
        )}
      >
        {!eventRecordingAvailable && (
          <EventRsvpButton
            event={event}
            attendingStatus={attendingStatus}
            rsvpConfirmHandler={rsvpYesOrAttend}
            dataTestId={dataTestId}
            rsvpCancelHandler={(e: any) =>
              attendingStatus === 'attending' ? rsvpCancel(e) : rsvpNotAttending(e)
            }
            {...rest}
          />
        )}
      </div>
    </div>
  )
}

export default EventRsvp
