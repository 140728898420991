import React from 'react'

import { PlanName } from 'gql'

export interface PlanItems {
  included: string[]
  excluded?: string[]
}

export interface PlanOptionFields {
  name: PlanName
  title: string
  subtitle: string | React.ReactElement<any> | null
  secondSubtitle: string | React.ReactElement<any> | null
  price: number | null
  savingsTagContent?: string
  content: PlanItems | React.ReactElement<any>
  features: string[]
  headerBarBgClass: string
  maxSeatCount: number | null
  footnote?: string
}

export const PLAN_ITEMS = [
  'Full content library',
  'Downloadable templates',
  'Slack community',
  'Account manager'
]

export const PLAN_OPTIONS: PlanOptionFields[] = [
  {
    name: PlanName.INDIVIDUAL,
    title: 'Individual',
    price: null,
    subtitle: null,
    secondSubtitle: null,
    content: {
      included: PLAN_ITEMS.slice(0, 3),
      excluded: PLAN_ITEMS.slice(3, PLAN_ITEMS.length)
    },
    features: [
      'Reforge AI',
      '1,100+ Artifacts',
      '450+ Guides',
      '35+ On-demand courses',
      'Events',
      'Slack community'
    ],
    headerBarBgClass: 'rounded-t bg-rb-green-50',
    maxSeatCount: null
  },
  {
    name: PlanName.STARTER,
    title: 'Starter',
    price: null,
    subtitle: null,
    secondSubtitle: null,
    savingsTagContent: '40% savings',
    content: {
      included: PLAN_ITEMS.slice(0, 4),
      excluded: PLAN_ITEMS.slice(4, PLAN_ITEMS.length)
    },
    features: ['Shared collections', 'User roles & management'],
    headerBarBgClass: 'rounded-t bg-rb-blue-50',
    maxSeatCount: null
  },
  {
    name: PlanName.SCALE,
    title: 'Scale',
    price: null,
    subtitle: null,
    secondSubtitle: null,
    savingsTagContent: '50% savings',
    content: {
      included: PLAN_ITEMS
    },
    features: ['Priority support', 'Standard engagement report'],
    headerBarBgClass: 'rounded-t bg-rb-orange-50',
    maxSeatCount: null
  },
  {
    name: PlanName.ENTERPRISE,
    title: 'Enterprise',
    price: null,
    subtitle: null,
    secondSubtitle: null,
    content: (
      <div className="text-sm text-black">
        <p>
          Managing a large team? Talk to our experts and get a plan tailored to your
          business.{' '}
        </p>
      </div>
    ),
    features: ['Custom onboarding', 'Dedicated account manager', 'SAML SSO'],
    headerBarBgClass: 'rounded-t bg-rb-pink-50',
    maxSeatCount: null
  }
]
