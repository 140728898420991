import {
  renderInlineImage,
  renderPhoto,
  renderTable
} from '../../shared/componentTypes/Types'
import { ComplexPortableTextBlockValueProp } from '../../types'
import ForceLoadPhoto from '../ForceLoadPhoto'

export const renderForcedLoadPhoto = ({ value }: ComplexPortableTextBlockValueProp) => (
  <ForceLoadPhoto className="p-6" id={value._key} photo={value} useLightbox />
)

const types = {
  inlineImage: renderInlineImage,
  table: renderTable
}

const generateTypes = (forcePhotoLoad: boolean) => ({
  photo: forcePhotoLoad ? renderForcedLoadPhoto : renderPhoto,
  ...types
})

export default generateTypes
