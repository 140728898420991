import { useCallback, useEffect, useMemo, useState } from 'react'

import { useStripeSetupIntent } from 'components/StripeElements'

import { useCurrentUserPaymentMethodsQuery } from 'gql'

export const usePaymentMethods = () => {
  const { data, loading, refetch } = useCurrentUserPaymentMethodsQuery()
  const [isFormShown, setIsFormShown] = useState(false)
  const { createSetupIntent } = useStripeSetupIntent()

  const paymentMethods = useMemo(
    () => data?.userPaymentMethods || [],
    [data?.userPaymentMethods]
  )

  useEffect(() => {
    if (!loading && paymentMethods.length === 0) {
      setIsFormShown(true)
    }
  }, [loading, paymentMethods, setIsFormShown])

  const onSave = useCallback(() => {
    refetch()
    setIsFormShown(false)
    // create a new setup intent so that the user can add another payment method
    createSetupIntent()
  }, [refetch, setIsFormShown, createSetupIntent])

  return {
    paymentMethods,
    loading,
    isFormShown,
    setIsFormShown,
    onSave
  }
}
