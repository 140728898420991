import { useEffect, useMemo } from 'react'

import { usePage } from 'components/PageHeader/usePage'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

export const useAccountLayoutNav = () => {
  const { can } = useAssertCurrentUser()
  const { setPageTabs } = usePage()

  const navItems = useMemo(() => {
    const navItems = [{ label: 'Overview', href: '/account' }]

    if (can.viewBilling) {
      navItems.push({ label: 'Billing', href: '/billing' })
    }
    if (can.manageMyTeam) {
      navItems.push({ label: 'Team Settings', href: '/my_team/general' })
    }
    // NOTE: `accessAllCohortEventReplays` basically means they are a pre(P&P) legacy user
    const teamMembersDestination = can.accessAllCohortEventReplays
      ? '/my_team/members'
      : '/team_members'

    if (can.manageMyTeam || can.assistantManageTeam) {
      navItems.push({ label: 'Team Members', href: teamMembersDestination })
    }
    if (can.manageMyTeam) {
      navItems.push({ label: 'Team Engagement', href: '/team-engagement/summary' })
    }
    if (can.viewOrg) {
      navItems.push({ label: 'My Organization', href: '/my_organization' })
    }
    if (can.viewCohortHistory) {
      navItems.push({ label: 'Course History', href: '/course-history' })
    }

    return navItems
  }, [
    can.accessAllCohortEventReplays,
    can.manageMyTeam,
    can.assistantManageTeam,
    can.viewBilling,
    can.viewCohortHistory,
    can.viewOrg
  ])

  useEffect(() => {
    setPageTabs(
      navItems.map((item) => ({
        ...item,
        isActive: window.location.pathname.startsWith(item.href)
      }))
    )

    return () => {
      setPageTabs(null)
    }
  }, [navItems, setPageTabs])

  return null
}
