import { useState } from 'react'
import { Link } from 'react-router-dom'

import RfParagraphMediumSemiBold from 'components/typography/RfParagraph/RfParagraphMediumSemiBold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import RfParagraphSmallBold from 'components/typography/RfParagraph/RfParagraphSmallBold'

import { CourseOnDemandCmsProgramPartsFragment, useUpdateUserMutation } from 'gql'

import { useCourseTrackingIdentifiersContext } from 'hooks/useCourseTrackingIdentifiersContext'

import { trackNavigationClicked } from 'utils/tracking/analytics'

import { ReactComponent as MinusSignIcon } from 'images/icon--minus-sign.svg'
import { ReactComponent as PlusSignIcon } from 'images/icon--plus-sign.svg'

interface CourseOnDemandBannerProps {
  cmsProgram: CourseOnDemandCmsProgramPartsFragment
}

const CourseOnDemandBanner = ({ cmsProgram }: CourseOnDemandBannerProps) => {
  const [expanded, setExpanded] = useState(true)
  const [updateUser] = useUpdateUserMutation()
  const courseTrackingIdentifiers = useCourseTrackingIdentifiersContext()

  const handleLegacyLinkOnClick = () => {
    updateUser({
      variables: {
        input: {
          preferredCourseView: 'legacy'
        }
      }
    })

    trackNavigationClicked({
      location: 'course_dashboard',
      location_id: courseTrackingIdentifiers.cclCourse?.legacyCourseId || '',
      text: 'go_to_legacy_program',
      destination: `/programs/${cmsProgram.slug}`,
      type: 'hyperlink text',
      path: window.location.pathname,
      url: window.location.href,
      related_identifiers: {
        course_id: courseTrackingIdentifiers.cclCourse?.legacyCourseId,
        ccl_course_id: courseTrackingIdentifiers.cclCourse?.id,
        ccl_static_course_id: courseTrackingIdentifiers.cclCourse?.staticId,
        cms_program_id: cmsProgram.id,
        course_title: courseTrackingIdentifiers.cclCourse?.title,
        content_mode: 'on-demand'
      }
    })
  }

  return (
    <div className="px-6 py-4 w-full bg-[#F5E9DF] flex flex-col gap-2.5 rounded-md">
      <div className="flex items-center justify-between">
        <RfParagraphMediumSemiBold>
          Welcome to our new course experience
        </RfParagraphMediumSemiBold>

        <button onClick={() => setExpanded((current) => !current)}>
          {expanded ? (
            <MinusSignIcon className="w-[20px] h-[20px]" />
          ) : (
            <PlusSignIcon className="w-[20px] h-[20px]" />
          )}
        </button>
      </div>
      {expanded && (
        <div className="flex flex-col gap-3">
          <RfParagraphSmall>
            To make your learning more actionable, this course format features
            step-by-step guides, real-world examples, and Reforge AI for personalized
            advice. We’re constantly iterating and adding to the experience and we’d love
            your feedback on how to make things better! Please{' '}
            <a
              href="mailto:hello@reforge.com?subject=Feedback%20about%20the%20new%20course%20experience"
              className="underline text-rb-gray-400 underline-offset-2"
            >
              contact us
            </a>{' '}
            with your thoughts.
          </RfParagraphSmall>
          <RfParagraphSmallBold>
            <Link
              onClick={handleLegacyLinkOnClick}
              to={`/programs/${cmsProgram.slug}`}
              className="text-rb-gray-400"
            >
              View legacy experience →
            </Link>
          </RfParagraphSmallBold>
        </div>
      )}
    </div>
  )
}

export default CourseOnDemandBanner
