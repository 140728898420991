import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useScrollToTopOnLocationChange = () => {
  const location = useLocation()

  useEffect(() => {
    document.getElementById('page')?.scrollTo(0, 0)
  }, [location.pathname, location.search])
}

export default useScrollToTopOnLocationChange
