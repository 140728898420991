import { EventQuery } from 'gql'

import { cn } from 'utils/tailwind'

export interface IEventShowTitle {
  timeZone: string
  event: EventQuery['event']
}

const EventShowTitle = ({ event }: IEventShowTitle) => {
  const { name, featuredImageUrl } = event

  return (
    <div
      className={cn(
        'mb-5 w-full rounded-xl pl-1 md:mb-10 md:mb-0',
        featuredImageUrl
          ? 'h-[445px] bg-cover bg-center bg-no-repeat'
          : 'max-h-max bg-event-fallback'
      )}
      style={{
        backgroundImage: featuredImageUrl ? `url(${featuredImageUrl})` : undefined // Set background image using inline styles
      }}
    >
      <div className="flex flex-col justify-center py-8 px-6 lg:py-16 lg:px-12 ">
        <h3 className="sm:text-md m-0 font-polysans text-2xl font-normal text-rb-gray-400 lg:text-3xl">
          {name}
        </h3>
      </div>
    </div>
  )
}

export default EventShowTitle
