import SimpleModal from 'components/SimpleModal'

import { trackCtaClicked } from 'utils/tracking/analytics'

export interface SubscriptionUpdatedModalProps {
  handleClose: ({
    trackClose,
    nextPagePath
  }: {
    trackClose?: boolean
    nextPagePath?: string
  }) => void
}

export const SubscriptionUpdatedModalContent = ({
  handleClose
}: SubscriptionUpdatedModalProps) => {
  return (
    <SimpleModal
      renderHeaderIcon={() => 'CheckIcon'}
      renderHeaderText={() => 'Your subscription has been updated.'}
      renderBodyText={() => (
        <div>
          Your subscription has been updated. You can check out the details of your
          updated plan and make further changes on the <a href="/billing">Billing Page</a>
          .
        </div>
      )}
      renderFooterActionButtons={() => [
        {
          variant: 'outline',
          className: 'w-[150px] hover:text-black hover:bg-white hover:border-black',
          onClick: () => {
            handleClose({ trackClose: true })
          },
          children: 'Done'
        },
        {
          className: 'w-[250px] hover:bg-black hover:text-white',
          dataTest: 'manage-team-button',
          onClick: () => {
            trackCtaClicked({
              cta_location: 'subscription_purchase_success_modal',
              cta_type: 'button',
              text: 'manage team'
            })

            handleClose({ trackClose: false, nextPagePath: '/team_members' })
          },
          children: 'Manage Team'
        }
      ]}
    />
  )
}
