import clsx from 'clsx'

interface SkeletonTagsProps {
  tagsCount?: number
  className?: string
}

export const SkeletonTags = ({ tagsCount = 20, className = '' }: SkeletonTagsProps) => (
  <div className={clsx('flex flex-wrap', className)}>
    {[...Array(tagsCount)].map((_, index) => (
      <div
        key={index}
        style={{ width: (130 - index * 4) * Math.random() + 10 }}
        className={`mr-2 mb-2 h-[18px] rounded-sm bg-rb-gray-100 md:flex ${
          index >= 2 ? 'hidden' : 'flex'
        } `}
      />
    ))}
  </div>
)
