import { KeyboardEvent, MouseEvent } from 'react'
import { twMerge } from 'tailwind-merge'

import Button, { ButtonProps, ButtonVariant } from 'components/Button'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { trackCtaClicked } from 'utils/tracking/analytics'
import { CtaClicked } from 'utils/tracking/generated/types'

export type ButtonMarketingCTAVariant = 'text' | 'outline'

interface ButtonMarketingCTAProps {
  variant?: ButtonMarketingCTAVariant
  size?: 'small' | 'regular'
  underline?: boolean
  rel?: string
  location?: string
  ctaText?: string
}

const ButtonMarketingCTA = ({
  variant,
  size = 'regular',
  underline = false,
  location,
  ctaText,
  ...props
}: ButtonMarketingCTAProps & Omit<ButtonProps, 'size' | 'variant'>) => {
  const { isLoggedIn } = useCurrentUser()

  const textSizeClasses = twMerge(
    size === 'regular' && 'text-md',
    size === 'small' && 'text-sm',
    variant === 'text' && size === 'small' && 'text-base'
  )

  const computedSize = size === 'regular' ? 'medium' : 'x-small'
  const variantMapper = {
    text: 'text-only',
    outline: 'outline'
  }

  const handleOnClick = (e: MouseEvent<Element> | KeyboardEvent<Element>) => {
    const analyticsData: CtaClicked = {
      cta_location: location || window.location.pathname,
      cta_type: variant === 'text' ? 'link' : 'button',
      text:
        typeof props.children === 'string'
          ? props.children.toLowerCase()
          : ctaText
            ? ctaText.toLowerCase()
            : '<content is not a string>',
      logged_in: isLoggedIn
    }

    if (props.href) {
      analyticsData.destination = props.href
    }

    if (!props.disabled) {
      trackCtaClicked(analyticsData)
    }

    props.onClick && props.onClick(e)
  }

  return (
    <Button
      {...props}
      data-cta={location}
      onClick={handleOnClick}
      shape="rounded-full"
      size={computedSize}
      variant={(variant ? variantMapper[variant] : 'fill') as ButtonVariant}
      className={twMerge(
        'button-marketing-cta',
        textSizeClasses,
        underline && 'underline',
        variant === 'text' && 'px-0 hover:bg-transparent',
        props.className
      )}
    >
      {props.children}
    </Button>
  )
}

export default ButtonMarketingCTA
