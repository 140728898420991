import {
  CollapsibleContentBlock,
  typeForDisplay
} from 'pages/UnitLandingPage/UnitContentBlock'

import { UnitComplexPortableText } from 'domains/Sanity/PortableText/UnitComplexPortableText/UnitComplexPortableText'

import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { CclContentBlockPartsFragment, CourseDetailCourseBlockPartsFragment } from 'gql'

import { ReactComponent as LightBulbRaysIcon } from 'images/icon--light-bulb-rays.svg'
import { ReactComponent as VideoIcon } from 'images/icon--video.svg'

interface CourseDetailCourseBlockContentBlockProps {
  cclCourseBlock: CourseDetailCourseBlockPartsFragment
  contentBlock: CclContentBlockPartsFragment
}

const CourseDetailCourseBlockContentBlock = ({
  cclCourseBlock,
  contentBlock
}: CourseDetailCourseBlockContentBlockProps) => {
  if (!contentBlock) return null

  const renderCourseBlockContent = () => {
    return (
      <>
        {contentBlock.description && (
          <UnitComplexPortableText
            renderParaAsSubheading
            content={JSON.parse(contentBlock.description)}
            forcePhotoLoad
          />
        )}
        {contentBlock.contentBlockCollapsible &&
          contentBlock.contentBlockCollapsible.map((collapsibleContentItem, i) => (
            <CollapsibleContentBlock
              key={i}
              type={typeForDisplay(collapsibleContentItem.type)}
              label={collapsibleContentItem.label}
              description={JSON.parse(collapsibleContentItem.description)}
            />
          ))}
      </>
    )
  }

  return (
    <div className="flex flex-grow items-center justify-between gap-6">
      <div tabIndex={0} className="flex items-center">
        <div className="relative mr-6 flex h-[50px] min-w-[50px] items-center justify-center rounded-2xl border border-rb-gray-100 group-hover:bg-rb-orange-25">
          {contentBlock.hasVideoContent ? (
            <VideoIcon height="16" width="16" />
          ) : (
            <LightBulbRaysIcon height="16" width="16" />
          )}
        </div>
        <div className="flex w-full flex-col gap-1">
          <RfParagraphSmall>
            <span className="text-rb-gray-400 line-clamp-2">{contentBlock.header}</span>
          </RfParagraphSmall>
          {cclCourseBlock.kind === 'module' && renderCourseBlockContent()}
        </div>
      </div>
    </div>
  )
}

export default CourseDetailCourseBlockContentBlock
