import { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { CohortEvent, combineSameEvents } from 'domains/CohortViewer/utils'

import { ErrorMessage } from 'components'

import { MIN_WIDTH_TAILWIND_XL } from 'constants/breakpoints'

import {
  CohortCmsModulePartsFragment,
  CohortDashboardCmsProgramPartFragment,
  CohortDashboardCohortPartsFragment,
  CohortDashboardCurrentUserPartsFragment,
  CohortDashboardScheduledWeekCurrentUserPartsFragment,
  CohortDashboardScheduledWeekPartsFragment,
  CohortDashboardSeasonPartsFragment,
  CohortEventCardPartsFragment,
  useCohortDashboardScheduledWeekQuery
} from 'gql'

import { useFeatureFlags } from 'hooks/useFeatureFlags'
import useMediaQuery from 'hooks/useMediaQuery'

import {
  CohortResources,
  CohortResourcesSkeleton,
  WeeklyBlockChecklist,
  WeeklyBlockEvents,
  WeeklyBlockEventsSkeleton,
  WeeklyBlockHeader,
  WeeklyBlockIntervention,
  WeeklyBlockModules,
  WeeklyBlockModulesSkeleton
} from '.'
import CohortDashboardEndBlock from '../CohortDashboardEndBlock'
import CohortDashboardEndBlockBottomSection from '../CohortDashboardEndBlock/CohortDashboardEndBlockBottomSection'
import { INTERVENTION_THRESHOLD } from './WeeklyBlockIntervention/interventionUtils'
import { WeeklyTab } from './WeeklyTab'

interface CohortDashboardWeeklyBlockSkeletonProps {
  className?: string
}

export const CohortDashboardWeeklyBlockSkeleton = ({
  className
}: CohortDashboardWeeklyBlockSkeletonProps) => (
  <div
    className={twMerge('flex animate-pulse flex-col space-x-8 xl:flex-row', className)}
  >
    <WeeklyBlockModulesSkeleton />
    <div className="flex flex-col">
      <WeeklyBlockEventsSkeleton />
      <CohortResourcesSkeleton />
    </div>
  </div>
)

interface CohortDashboardWeeklyBlockProps {
  currentUser: CohortDashboardScheduledWeekCurrentUserPartsFragment
  slug: string
  weekId: string
  weekIndex: number
  gettingStartedModule?: CohortCmsModulePartsFragment | null
  askAnExpertEventsForRsvp: CohortEvent[]
  templates?: CohortCmsModulePartsFragment | null
  scheduledWeeksWithLessons: CohortDashboardScheduledWeekPartsFragment[]
  onWeekClick: (id: string, index: number) => void
  pathContainsEnd: boolean
  cohort: CohortDashboardCohortPartsFragment
}

export const CohortDashboardWeeklyBlock = ({
  onWeekClick,
  slug,
  weekId,
  weekIndex,
  gettingStartedModule,
  askAnExpertEventsForRsvp,
  templates,
  scheduledWeeksWithLessons,
  pathContainsEnd,
  cohort,
  currentUser
}: CohortDashboardWeeklyBlockProps) => {
  const isEndWeekId = weekId === 'end'

  const { data, loading, error } = useCohortDashboardScheduledWeekQuery({
    variables: {
      cohortSlug: slug,
      weekId: weekId,
      interventionThreshold: INTERVENTION_THRESHOLD
    },
    skip: isEndWeekId,
    fetchPolicy: 'no-cache' // ensure we have the most up-to-date lesson progress via currentUser.activeProgram
  })

  if (!isEndWeekId && (!data?.cohortDashboardScheduledWeek || loading)) {
    return <CohortDashboardWeeklyBlockSkeleton />
  }

  if (error) {
    return <ErrorMessage error="Something went wrong while loading the week" />
  }

  const reachedThresholdForIntervention =
    !!data?.scheduledWeekProgressPercentHasReachedThresholdForIntervention

  return (
    <WeeklyBlock
      onWeekClick={onWeekClick}
      weekId={weekId}
      scheduledWeeksWithLessons={scheduledWeeksWithLessons}
      events={data?.cohortDashboardScheduledWeek.events || []}
      scheduledWeek={data?.cohortDashboardScheduledWeek}
      weekIndex={weekIndex}
      slug={slug}
      currentUser={currentUser}
      gettingStartedModule={gettingStartedModule}
      askAnExpertEventsForRsvp={askAnExpertEventsForRsvp}
      templates={templates}
      reachedThresholdForIntervention={reachedThresholdForIntervention}
      pathContainsEnd={pathContainsEnd}
      cohort={cohort}
    />
  )
}

interface WeeklyBlockProps extends Omit<CohortDashboardWeeklyBlockProps, 'weekId'> {
  onWeekClick: (id: string, index: number) => void
  weekId: string
  events: CohortEventCardPartsFragment[]
  scheduledWeek?: CohortDashboardScheduledWeekPartsFragment
  currentUser: CohortDashboardCurrentUserPartsFragment
  reachedThresholdForIntervention: boolean
  scheduledWeeksWithLessons: CohortDashboardScheduledWeekPartsFragment[]
  pathContainsEnd: boolean
  cohort: CohortDashboardCohortPartsFragment
}

const WeeklyBlock = ({
  askAnExpertEventsForRsvp,
  events,
  scheduledWeek,
  weekIndex,
  currentUser,
  gettingStartedModule,
  slug,
  templates,
  reachedThresholdForIntervention,
  scheduledWeeksWithLessons,
  weekId,
  onWeekClick,
  pathContainsEnd,
  cohort
}: WeeklyBlockProps) => {
  const program: CohortDashboardCmsProgramPartFragment = cohort.cmsProgram
  const cohortHasEnded = cohort.hasCohortOffboardingStarted
  const snapshotReleased = !!cohort.snapshotReleased
  const season: CohortDashboardSeasonPartsFragment = cohort.season

  const history = useHistory()
  const { showCohortOffboarding } = useFeatureFlags()
  const [combinedEvents, setCombinedEvents] = useState(combineSameEvents(events))
  const isXLOrLarger = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_XL})`)

  const rsvpCallback = (event: CohortEvent, index: number) => {
    if (!combinedEvents.length) return

    const updatedCombinedEvents = [...combinedEvents]
    updatedCombinedEvents[index] = event
    setCombinedEvents(updatedCombinedEvents)
  }

  const weeklyBlockEvents = !!scheduledWeek && (
    <WeeklyBlockEvents
      rsvpCallback={rsvpCallback}
      events={combinedEvents}
      slug={slug}
      currentUser={currentUser}
      scheduledWeekTitle={scheduledWeek.title || ''}
      scheduledWeekIndex={weekIndex}
      programName={program.name}
    />
  )

  const cohortResources = (
    <CohortResources templates={templates} programName={program.name} cohortSlug={slug} />
  )

  const sectionOrLessonRef = useRef<HTMLDivElement>(null)
  const scrollToRelevantLesson = () =>
    sectionOrLessonRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })

  return (
    <>
      <div
        className={twMerge(
          'flex w-full max-w-full flex-col px-6 pt-6 xl:items-center xl:px-0'
        )}
        style={
          cohortHasEnded && pathContainsEnd && showCohortOffboarding
            ? {
                background:
                  'radial-gradient(96.67% 128.89% at 25.14% 71.61%, rgba(180, 243, 241, 0.4) 0%, rgba(225, 255, 228, 0.4) 79.69%)'
              }
            : {}
        }
      >
        <div className="flex flex-row justify-center">
          <div className="flex w-full flex-col xl:min-w-[890px]">
            <div className="flex min-h-[32px] min-w-[400px] flex-row space-x-2 overflow-x-auto pr-6 md:min-h-[42px] md:space-x-5">
              {scheduledWeeksWithLessons.map((week, index) => {
                return (
                  <WeeklyTab
                    key={week.id}
                    title={index === 0 ? 'Kickoff' : `Week ${index}`}
                    className={twMerge(
                      weekId === week.id && !pathContainsEnd
                        ? 'bg-black text-white'
                        : 'border border-gray-200 bg-white',
                      weekId !== week.id &&
                        week.isCurrentWeek &&
                        !pathContainsEnd &&
                        'border-2 border-black'
                    )}
                    onClick={() => {
                      onWeekClick(week.id, index)
                    }}
                  />
                )
              })}
              {cohortHasEnded && showCohortOffboarding && (
                <WeeklyTab
                  title="End"
                  className={twMerge(
                    pathContainsEnd ? 'bg-black text-white' : 'border border-gray-200',
                    !pathContainsEnd && 'border-2 border-black'
                  )}
                  onClick={() => {
                    history.push(`/cohorts/${slug}/end`)
                  }}
                />
              )}
            </div>

            <div className="">
              {pathContainsEnd ? (
                <div className="flex flex-row">
                  <div className="flex w-full flex-col">
                    <CohortDashboardEndBlock
                      slug={slug}
                      program={program}
                      snapshotReleased={snapshotReleased}
                    />
                  </div>
                </div>
              ) : (
                !!scheduledWeek && (
                  <div className="flex flex-row space-x-20">
                    <div className="flex w-full flex-col lg:max-w-[700px]">
                      <WeeklyBlockHeader
                        isKickoffWeek={weekIndex === 0}
                        programName={program.name}
                        programDescription={program.shortDescription}
                        scheduledWeek={scheduledWeek}
                      />
                      <WeeklyBlockIntervention
                        scheduledWeekIndex={weekIndex}
                        events={combinedEvents}
                        timezone={currentUser.timezone || ''}
                        scheduledWeekStartsAt={scheduledWeek.startsAt || ''}
                        reachedThresholdForIntervention={reachedThresholdForIntervention}
                      />
                      <WeeklyBlockChecklist
                        scheduledWeekIndex={weekIndex}
                        cohortSlug={slug}
                        currentUser={currentUser}
                        askAnExpertEventsForRsvp={askAnExpertEventsForRsvp}
                        events={combinedEvents}
                        scheduledWeek={scheduledWeek}
                        scrollToSectionWithRelevantLesson={scrollToRelevantLesson}
                      />
                      {!isXLOrLarger && weeklyBlockEvents}
                      <div className="w-full max-w-[700px]">
                        <WeeklyBlockModules
                          scheduledWeek={scheduledWeek}
                          scheduledWeekIndex={weekIndex}
                          currentUser={currentUser}
                          slug={slug}
                          gettingStartedModule={gettingStartedModule}
                          events={combinedEvents}
                          sectionOrLessonRef={sectionOrLessonRef}
                        />
                      </div>
                      {!isXLOrLarger && cohortResources}
                    </div>
                    {isXLOrLarger && (
                      <div className="mt-96 flex flex-col xl:w-1/3">
                        {weeklyBlockEvents}
                        {cohortResources}
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      {pathContainsEnd && (
        <div
          className={`flex w-full px-6 xl:px-0 ${
            !snapshotReleased && 'max-w-[890px]'
          } flex-col xl:m-auto`}
        >
          <div className="flex w-full flex-col">
            <CohortDashboardEndBlockBottomSection
              season={season}
              programName={program.name}
              currentUser={currentUser}
              shouldShowNpsSurvey={cohort.shouldShowNpsSurvey || false}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default CohortDashboardWeeklyBlock
