import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useParams } from 'react-router-dom'

import { Loading } from 'components'
import { displayToast } from 'components/Toast'
import { CloseIcon } from 'components/icons'

import { useCourseSessionHostDashboardQuery } from 'gql'

import {
  MONTH_ABBREV_DATE_FORMAT,
  formatInTimezone,
  getCurrentTimezone
} from 'utils/date'

const LINK_CLASSES = 'mx-2 p-2 inline-block cursor-pointer'
const HEADER_CELL_CLASSES = 'py-3 px-6'
const BODY_CELL_CLASSES = 'max-w-md py-4 px-6'
const ROW_CLASSES = 'text-black odd:bg-gray-100'

const toastOptions = {
  className:
    '!bg-rb-gray-400 !text-rb-white flex items-center p-0 w-full min-h-4 xs:w-[400px] ',
  bodyClassName: 'w-full h-full max-w-xl my-0 py-0 w-screen',
  position: displayToast.POSITION.BOTTOM_CENTER,
  closeButton: <CloseIcon className="mr-4 h-16 w-auto text-white" />
}

export type CourseSessionHostDashboardParams = {
  courseSlug: string
  courseSessionId: string
}

export const CourseSessionHostDashboard = () => {
  const { courseSessionId, courseSlug } = useParams<CourseSessionHostDashboardParams>()

  const { data, loading } = useCourseSessionHostDashboardQuery({
    variables: { courseSessionId }
  })

  if (!data || loading) {
    return (
      <div className="p-4">
        <Loading />
      </div>
    )
  }

  const onCopy = (contentName: string) =>
    displayToast(`${contentName} copied to clipboard`, toastOptions)

  const details = data.supplyCourseSession
  const timezone = getCurrentTimezone()
  const startDate = formatInTimezone(details.startsAt, timezone, MONTH_ABBREV_DATE_FORMAT)
  const endDate = formatInTimezone(details.endsAt, timezone, 'MMM d, yyyy')
  const enrollmentStartDate = formatInTimezone(
    details.enrollmentStartsAt,
    timezone,
    'MMM d, yyyy'
  )

  const emails: string[] = []
  for (let i = 0; i < details.enrollees.length; i++) {
    emails.push(details.enrollees[i].email)
  }

  return (
    <main className="relative overflow-x-auto p-2">
      <h1 className="text-center text-3xl">
        {details.courseTitle}: {startDate}-{endDate}
      </h1>
      <div>Enrollment opens: {enrollmentStartDate}</div>
      <div>Total enrolled: {details.numEnrolledTotal}</div>
      <div>Max enrollment: {details.maxEnrollmentTotal}</div>

      <div className="p-2 text-center">
        <a
          className={LINK_CLASSES}
          href={`/courses/${courseSlug}/sessions/${courseSessionId}`}
          target="_blank"
          rel="noreferrer"
        >
          Course Dashboard
        </a>

        <span className={LINK_CLASSES}>
          <CopyToClipboard
            text={`https://www.reforge.com/courses/${courseSlug}?utm_source=sharing_&utm_medium=social&utm_campaign=course_promotion`}
            onCopy={() => onCopy('Link')}
          >
            <span>Copy Marketing Link</span>
          </CopyToClipboard>
        </span>

        <span className={LINK_CLASSES}>
          <CopyToClipboard text={emails.join(', ')} onCopy={() => onCopy('Emails')}>
            <span>Copy Emails</span>
          </CopyToClipboard>
        </span>
      </div>

      <div className="text-rb-destructive-100 text-sm">
        In order of enrollment (most recent at the end)
      </div>
      <table className="w-full text-left text-black dark:text-gray-400">
        <thead className="bg-gray-200 text-xs uppercase text-gray-700 dark:bg-gray-600 dark:text-gray-400">
          <tr>
            <th className={HEADER_CELL_CLASSES}>Name</th>
            <th className={HEADER_CELL_CLASSES}>Email</th>
            <th className={HEADER_CELL_CLASSES}>Company</th>
            <th className={HEADER_CELL_CLASSES}>Job Function</th>
            <th className={HEADER_CELL_CLASSES}>Role</th>
          </tr>
        </thead>

        <tbody>
          {details.enrollees.map((enrollee, index) => (
            <tr className={ROW_CLASSES} key={index}>
              <td className={BODY_CELL_CLASSES}>{enrollee.fullName}</td>
              <td className={BODY_CELL_CLASSES}>{enrollee.email}</td>
              <td className={BODY_CELL_CLASSES}>{enrollee.companyName}</td>
              <td className={BODY_CELL_CLASSES}>{enrollee.jobFunction}</td>
              <td className={BODY_CELL_CLASSES}>{enrollee.role}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </main>
  )
}
