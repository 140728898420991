import {
  AvailableTeamSeatFragment,
  AvailableTeamSubscriptionFragment,
  AvailableTeamSubscriptionMemberFragment
} from 'gql'

export function getSubscriptionMembers(
  seats: AvailableTeamSeatFragment[]
): AvailableTeamSubscriptionMemberFragment[] {
  const subscriptionMembers: AvailableTeamSubscriptionMemberFragment[] = seats
    .map((seat) => seat.subscriptionMember)
    .filter(isSubcriptionMember)

  return subscriptionMembers.filter((subscriptionMember) => subscriptionMember?.user)
}

function isSubcriptionMember(
  member: AvailableTeamSubscriptionMemberFragment | null | undefined
): member is AvailableTeamSubscriptionMemberFragment {
  return member?.__typename === 'SubscriptionMember'
}
interface SortedSubscriptionsByStatus {
  draftTeams: AvailableTeamSubscriptionFragment[]
  joinableTeams: AvailableTeamSubscriptionFragment[]
  requestedTeams: AvailableTeamSubscriptionFragment[]
  rejectedTeams: AvailableTeamSubscriptionFragment[]
}

export function sortAvailableSubscriptionsByRequestStatus({
  availableTeamSubscriptions
}: {
  availableTeamSubscriptions: AvailableTeamSubscriptionFragment[]
}) {
  const initialSortedSubscriptionsByStatus: SortedSubscriptionsByStatus = {
    draftTeams: [],
    joinableTeams: [],
    requestedTeams: [],
    rejectedTeams: []
  }

  const subscriptionsByRequestStatus = [...availableTeamSubscriptions]
    .sort((a, b) => {
      const now = new Date().getTime()
      const aDate = a?.userJoinRequests?.[0]?.createdAt
        ? new Date(a?.userJoinRequests?.[0]?.createdAt).getTime()
        : now
      const bDate = b?.userJoinRequests?.[0]?.createdAt
        ? new Date(b?.userJoinRequests?.[0]?.createdAt).getTime()
        : now
      return bDate - aDate
    })
    .reduce((groupedTeams, currentTeam) => {
      const requests = currentTeam.userJoinRequests || []
      if (requests.length === 0) {
        groupedTeams.joinableTeams.push(currentTeam)
      } else if (requests.some((request) => request.status === 'draft')) {
        groupedTeams.draftTeams.push(currentTeam)
      } else if (requests.some((request) => request.status === 'pending')) {
        groupedTeams.requestedTeams.push(currentTeam)
      } else if (requests.some((request) => request.status === 'rejected')) {
        groupedTeams.rejectedTeams.push(currentTeam)
      }
      return { ...groupedTeams }
    }, initialSortedSubscriptionsByStatus)

  return subscriptionsByRequestStatus
}
