import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { Loading } from 'components'
import CopyrightDispute from 'components/MarketingSite/CopyrightDispute'

import { useCopyrightDisputePageQuery } from 'gql'

const CopyRightDisputePage = () => {
  const { data, loading } = useCopyrightDisputePageQuery()

  return (
    <MarketingLayout page={data?.copyrightDisputePage}>
      {loading ? <Loading /> : <CopyrightDispute />}
    </MarketingLayout>
  )
}

export default CopyRightDisputePage
