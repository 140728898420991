import { Fragment, useEffect } from 'react'
import { Redirect, useParams, useRouteMatch } from 'react-router-dom'

import GroupMembers from 'domains/Group/GroupMembers'
import MembersWrapper from 'domains/Group/MembersWrapper'
import PostsWrapper from 'domains/Group/PostsWrapper'
import ArchivedBanner from 'domains/Post/ArchivedBanner'

import { Loading } from 'components'
import { usePage } from 'components/PageHeader/usePage'

import { useGroupShowQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

const activeTab = (path: string) => {
  if (path === '/groups/:group/members') {
    return 'members'
  } else {
    return 'posts'
  }
}

const GroupPage = () => {
  const { group: groupSlug = '' } = useParams<{ group: string }>()
  const { path } = useRouteMatch()
  const tab = activeTab(path)
  const { currentUser, currentUserLoading } = useCurrentUser()
  const { data, loading } = useGroupShowQuery({
    variables: { groupSlug },
    skip: !groupSlug
  })
  const group = data?.group
  const currentSeason = data?.currentSeasonPretty || ''
  const { setPageTitle, setPageTabs, setPageHeaderTopRightContent } = usePage()

  // Note - most of the ~50 lines of code below are to handle injecting components into the shared page header erb template
  // We should refactor this into a React component, it would really reduce technical debt.
  useEffect(() => {
    Array.from(document.querySelectorAll('.posts-header')).forEach(
      function (headerElement) {
        headerElement.classList.add('group-header')
        headerElement.classList.add(`group-header--${groupSlug}`)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPageTitle(
      <div className="flex justify-between" style={{ width: '100%' }}>
        <div className="group-title-wrapper">
          <h4 className="mb-1.5 text-xs font-medium uppercase tracking-widest text-rb-gray-300">
            Group
          </h4>

          <h3 className="mb-0 truncate text-xl font-medium text-black sm:text-2xl">
            {group?.title || ''}
          </h3>
        </div>
      </div>
    )

    return () => {
      setPageTitle(null)
    }
  }, [group?.title, setPageTitle])

  useEffect(() => {
    setPageTabs([
      {
        label: 'Discussion',
        href: `/groups/${groupSlug}`,
        isActive: tab === 'posts'
      },
      {
        label: 'Members',
        href: `/groups/${groupSlug}/members`,
        isActive: tab === 'members'
      }
    ])

    return () => {
      setPageTabs(null)
    }
  }, [groupSlug, setPageTabs, tab])

  useEffect(() => {
    if (group) {
      setPageHeaderTopRightContent(
        <GroupMembers
          variation="header"
          memberStack={group.memberStack}
          membersCount={group.membersCount}
          userIsMember={group.userIsMember}
        />
      )
    }

    return () => {
      setPageHeaderTopRightContent(null)
    }
  }, [group, setPageHeaderTopRightContent])

  if (!currentUserLoading && (!currentUser || !currentUser.can.viewDiscussionIndex)) {
    return <Redirect to="/" />
  }

  return (
    <Fragment>
      <ArchivedBanner currentUser={currentUser} />

      <div className="w-full py-0 xs:py-4 md:py-8">
        <div className="ml-0 flex justify-center" uk-grid="">
          {tab === 'posts' && group && (
            <PostsWrapper
              currentSeason={currentSeason}
              group={groupSlug}
              groupTitle={group.title}
              groupTopics={group.populatedTopics}
              groupId={group.id}
              groupCategory={group?.groupCategory?.category || ''}
              industries={group.populatedIndustries}
            />
          )}
          {tab === 'members' && <MembersWrapper group={groupSlug} />}
          {loading && <Loading />}
        </div>
      </div>
    </Fragment>
  )
}

export default GroupPage
