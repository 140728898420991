import Image from 'domains/Sanity/Image'
import CareersBottomCards from 'domains/Sanity/marketingSite/Careers/CareersBottomCards'
import CareersOpenPositions from 'domains/Sanity/marketingSite/Careers/CareersOpenPositions'
import MarketingHero from 'domains/Sanity/marketingSite/MarketingHero'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { Loading } from 'components'

import { Page, useCareersPageQuery } from 'gql'

import TopFacetLeak from 'images/Top-Facet-Leak.png'
import TopProgPinkOrange from 'images/Top_Prog_Pink-Orange.png'
import Icon401k from 'images/marketing-round-icons/careers/401k.png'
import HandHeartIcon from 'images/marketing-round-icons/careers/HandHeart.png'
import HealthcareIcon from 'images/marketing-round-icons/careers/Healthcare.png'
import HomeOfficeIcon from 'images/marketing-round-icons/careers/HomeOffice.png'
import MapIcon from 'images/marketing-round-icons/careers/Map.png'
import PTOIcon from 'images/marketing-round-icons/careers/PTO.png'

const BENEFITS = [
  {
    icon: HealthcareIcon,
    title: 'Health, Vision, Dental'
  },
  {
    icon: MapIcon,
    title: 'Medical Travel Reimbursement'
  },
  {
    icon: HandHeartIcon,
    title: 'Sick & Mental Health Time Away'
  },
  {
    icon: Icon401k,
    title: '401(k)'
  },
  {
    icon: HomeOfficeIcon,
    title: 'Workspace Setup Reimbursements'
  },
  {
    icon: PTOIcon,
    title: 'Remote First, Flexible Time Off'
  }
]

const CareersPage = () => {
  const { data, loading } = useCareersPageQuery()
  const page = (data?.careersPage || {}) as Page

  return (
    <MarketingLayout page={page}>
      {loading ? <Loading /> : <CareersPageContent />}
    </MarketingLayout>
  )
}

export const CareersPageContent = () => {
  return (
    <>
      <MarketingHero
        headerText="Careers at Reforge"
        description="Join us in building the future of professional development"
        bgImage={{
          url: TopProgPinkOrange,
          alt: ''
        }}
        ctas={[
          {
            href: '/open-positions',
            text: 'Open Positions ↗'
          }
        ]}
        noCtaMargin
        contentClassName="lg:max-w-[60%]"
      />

      <section className="bg-rb-background-beige py-[4vmax] px-[16px] md:px-[4vw]">
        <h2 className="mb-[60px] font-normal leading-5 tracking-tight fluid-text-6xl">
          Reforge Benefits
        </h2>

        <ul className="mx-auto grid max-w-[1440px] list-none grid-cols-1 gap-[60px] p-0 sm:grid-cols-2 md:grid-cols-3">
          {BENEFITS.map((benefit, i) => (
            <li className="flex flex-col" key={i}>
              <div className="relative mb-[4%] aspect-1 w-[60px] md:w-1/5">
                <Image src={benefit.icon} fill className="object-contain" alt="" />
              </div>

              <p className="pr-[4vw] tracking-tight fluid-text-xl">{benefit.title}</p>
            </li>
          ))}
        </ul>
      </section>

      <section className="px-[16px] pt-[4vmax] md:px-[4vw]">
        <h2 className="font-normal leading-5 tracking-tight fluid-text-6xl">
          Open Positions
        </h2>
        <CareersOpenPositions pathname="careers" />
      </section>

      <section className="relative min-h-[100px] py-[4vmax] px-[16px] md:px-[4vw] md:pt-[6.6vmax]">
        <Image src={TopFacetLeak} fill className="-z-1 object-cover" alt="" />
        <h2 className="mb-[50px] font-normal leading-5 tracking-tight fluid-text-6xl">
          Life at Reforge
        </h2>

        <ul className="grid list-none grid-cols-1 gap-[60px] p-0 sm:grid-cols-2 md:grid-cols-3">
          <li className="flex justify-center text-center">
            <blockquote className="max-w-[87%]">
              <p className="mb-[7%] !leading-[1.2] tracking-tight fluid-text-lg">
                &quot;I am constantly stoked by the transparency leadership shows here.
                Leaders look to their team for help, and aren’t afraid to be honest.&quot;
              </p>

              <footer className="font-light fluid-text-base">Chris E., Marketing</footer>
            </blockquote>
          </li>
          <li className="flex justify-center text-center">
            <blockquote className="max-w-[87%]">
              <p className="mb-[7%] !leading-[1.2] tracking-tight fluid-text-lg">
                &quot;Despite being remote, I feel very close to my team. We have clear
                work and life boundaries that embrace the fact that we are all
                human.&quot;
              </p>

              <footer className="font-light fluid-text-base">
                Morgan K., Recruiting
              </footer>
            </blockquote>
          </li>
          <li className="flex justify-center text-center">
            <blockquote className="max-w-[87%]">
              <p className="mb-[7%] !leading-[1.2] tracking-tight fluid-text-lg">
                &quot;The culture, the people, the work ethics, and the product are
                unmatched. What else do you need?&quot;
              </p>

              <footer className="font-light fluid-text-base">
                Rachael S., Operations
              </footer>
            </blockquote>
          </li>
        </ul>
      </section>

      <CareersBottomCards />
    </>
  )
}

export default CareersPage
