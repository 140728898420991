import { Component, KeyboardEvent } from 'react'
import { twMerge } from 'tailwind-merge'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'

import { BookmarkNoteFormPartsFragment } from 'gql'

import { WithInlinePostsProps, withInlinePostsHook } from './helpers'

export interface CommentBlockProps {
  synopsis: string
  cmsSectionId?: string
  elementId: string
  referenceImageUrl?: string
  userId?: string
  cmsModuleId?: string
  cmsProgramId?: string
  bookmarksByAnchor: any
  inlinePostAnchors: string[]
  accessPolicyKind: string
  hasApplied: boolean
  saveBookmark: any
  removeBookmark: any
  updateBookmark: any
  setActivePanel: (type: string) => void
  setActiveFilterId: (id: string) => void
  filteredItemId?: string
  openDrawer: (type: string) => void
  scrollDrawerToPost: (type: string) => void
  onCommentBlockOpen: (
    anchor: string,
    basedOn?: string,
    referenceImageUrl?: string
  ) => void
  hoveredItemId?: string
  setHoveredItemId: (id: string) => void
}

interface CommentBlockState {
  bookmark: undefined | (Partial<BookmarkNoteFormPartsFragment> & { error?: boolean })
  bookmarking: boolean
  mounted: boolean
  isPopoverOpen: boolean
}

const CommentButton = ({
  onClick,
  numPosts,
  anchor,
  filteredItemId,
  hoveredItemId,
  setHoveredItemId
}: {
  onClick?: () => void
  numPosts: number
  anchor: string
  filteredItemId?: string
  hoveredItemId?: string
  setHoveredItemId: (id: string) => void
}) => {
  return (
    <Button
      data-testid="comment-button"
      data-dd-action-name="Comment block"
      variant="text-only"
      size="small"
      onClick={onClick}
      onMouseEnter={() => setHoveredItemId(anchor)}
      onMouseLeave={() => setHoveredItemId('')}
      shape="rounded-full"
      className={twMerge(
        'rounded-[10px] px-2 py-2 font-sans font-semibold',
        anchor === hoveredItemId
          ? 'bg-rb-gray-100'
          : anchor === filteredItemId && 'bg-rb-green-75'
      )}
      iconClassName="mr-0"
      iconBefore={<SVGIcon name="discussions" className="h-4 w-3.5 stroke-0" />}
    >
      {numPosts > 0 && <span className="pb-1 pl-1">{numPosts}</span>}
      <span className="ml-1 hidden text-[10.5px] leading-[15.75px] text-rb-gray-400 sm:text-sm sm:leading-[18px] lg:block">
        {numPosts === 0 ? ' Ask question' : ''}
      </span>
    </Button>
  )
}

class CommentBlock extends Component<
  CommentBlockProps & WithInlinePostsProps,
  CommentBlockState
> {
  constructor(props: CommentBlockProps & WithInlinePostsProps) {
    super(props)

    this.state = {
      bookmark:
        props.bookmarksByAnchor && props.elementId
          ? props.bookmarksByAnchor[props.elementId]
          : undefined,
      bookmarking: false,
      mounted: false,
      isPopoverOpen: false
    }
  }

  componentDidUpdate() {
    if (this.state.mounted) {
      const potentialBookmark = this.props?.elementId
        ? this.props?.bookmarksByAnchor?.[this.props.elementId]
        : undefined

      const bookmarkNotesDoNotMatch =
        potentialBookmark?.noteBody !== this.state?.bookmark?.noteBody

      if (bookmarkNotesDoNotMatch) {
        this.setState({ bookmark: potentialBookmark })
      }
    }
  }

  componentDidMount() {
    const { elementId, setActivePanel, openDrawer } = this.props
    const linkedSection = window.location.hash.replace('#', '')
    if (elementId === linkedSection) {
      if (window.location.search.indexOf('referring-post') > -1) {
        // link is from a post so open the posts sidebar for context
        setActivePanel('discussions')
        openDrawer('discoveryDrawer')
      }
    }
  }

  handleEmptyKey = (e: KeyboardEvent) => {
    e.preventDefault()
  }

  showPostPane = () => {
    this.props.setActivePanel('discussions')
    this.props.openDrawer('discoveryDrawer')
    this.props.setActiveFilterId(this.props.elementId)
    this.props.scrollDrawerToPost(this.props.elementId)
  }

  renderDiscussionIcon = (numPosts: number) => {
    if (numPosts === 0) {
      return null
    }
    return (
      <CommentButton
        onClick={this.showPostPane}
        numPosts={numPosts}
        filteredItemId={this.props.filteredItemId}
        anchor={this.props.elementId}
        hoveredItemId={this.props.hoveredItemId}
        setHoveredItemId={this.props.setHoveredItemId}
      />
    )
  }

  render() {
    // TODO: better way to hook into inlinePosts updates?
    const numPosts = this.props.inlinePostAnchors?.reduce(
      (n, x) => n + Number(x === this.props.elementId),
      0
    )

    return (
      <div className="inline-posts__controls flex items-center justify-between">
        <div className="inline-posts__comments-control" data-posted={numPosts > 0}>
          {this.renderDiscussionIcon(numPosts)}
        </div>
      </div>
    )
  }
}

export default withInlinePostsHook(CommentBlock)
