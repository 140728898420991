import { PopoverPosition } from 'react-tiny-popover'

import Image from 'domains/Sanity/Image'

import { FacePile, FacePileUser } from 'components/FacePile'

import {
  ArtifactAuthor,
  BookmarkFolderPartsFragment,
  CclBlogPost,
  ProgramBookmarkPartsFragment
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { listify } from 'utils/stringUtils'
import { cn } from 'utils/tailwind'
import { trackContentClicked } from 'utils/tracking/analytics'

import BaseCard, { CardVariant, CardVariants } from './BaseCard'

export interface BlogCardProps {
  blogPost: CclBlogPost
  variant?: CardVariant
  inNewTab?: boolean
  pageLocation?: string
  additionalRelatedIdentifiers?: {}
  // Bookmark-related props
  bookmark?: any
  currentFolder?: BookmarkFolderPartsFragment | null
  bookmarkFolders?: BookmarkFolderPartsFragment[] | undefined
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  restoreBookmark?: (bookmark: ProgramBookmarkPartsFragment) => void
  handleRemoveFromFolder?: (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => Promise<string | null | undefined>
  hideBookmarkButton?: boolean
  // This is needed because some cards are rendered in a container that manages overflow (e.g., uk-slider) that
  // will clip the bookmark dropdown menu now that the button is at the bottom of the card.
  bookmarkDropdownPosition?: PopoverPosition
}

const BlogCard = ({
  blogPost,
  variant = CardVariants.Vertical,
  pageLocation = 'blog_index',
  currentFolder,
  bookmarkFolders,
  bookmark,
  openAddToBookmarkFolderModal,
  restoreBookmark,
  handleRemoveFromFolder,
  additionalRelatedIdentifiers,
  hideBookmarkButton,
  bookmarkDropdownPosition
}: BlogCardProps) => {
  const { currentUser } = useCurrentUser()
  const destination = `/blog/${blogPost.slug}`
  const hideBookmark = hideBookmarkButton || !currentUser

  const relatedIdentifiers = {
    referrer: window.location.pathname,
    content_mode: 'async',
    ...additionalRelatedIdentifiers
  }

  const handleTracking = () => {
    trackContentClicked({
      content_sanity_id: blogPost.id,
      content_title: blogPost.title,
      content_type: 'blog',
      path: destination,
      location: pageLocation,
      display_type: `${variant}_card`,
      logged_in: !!currentUser,
      related_identifiers: relatedIdentifiers
    })
  }

  const parseSummary = (summary: any) => {
    if (!summary) return ''
    const data = JSON.parse(blogPost?.summary || '{}')

    return data?.[0].children?.[0]?.text
  }

  return (
    <BaseCard
      contentType="BlogPost"
      variant={variant}
      body={parseSummary(blogPost?.summary)}
      title={blogPost.title}
      header={<Header blogPost={blogPost} />}
      thumbnail={<Thumbnail blogPost={blogPost} />}
      horizontalThumbnail={<Thumbnail blogPost={blogPost} />}
      verticalThumbnail={<Thumbnail blogPost={blogPost} />}
      destination={destination}
      trackCardClick={handleTracking}
      bookmark={bookmark}
      currentFolder={currentFolder}
      bookmarkFolders={bookmarkFolders}
      openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
      restoreBookmark={restoreBookmark}
      handleRemoveFromFolder={handleRemoveFromFolder}
      hideBookmarkButton={hideBookmark}
      bookmarkDropdownPosition={bookmarkDropdownPosition}
    />
  )
}

const Header = ({ blogPost }: { blogPost: CclBlogPost }) => {
  if (!blogPost.authors) return null
  const authors = blogPost.authors as ArtifactAuthor[]
  const authorNames = authors
    .filter((author) => author && author.name)
    .map((author) => author.name) as string[]
  const facepileUsers = authors
    .filter((author) => author && (author.avatarPhoto?.imageUrl || author.avatarUrl))
    .map((author) => {
      return {
        id: author.id,
        avatarUrl: author.avatarPhoto?.imageUrl || author.avatarUrl
      } as FacePileUser
    })

  return (
    <div className="flex gap-2">
      <div className="flex shrink-0 grow-0">
        <FacePile users={facepileUsers} imageSize="small" />
      </div>
      <div className={cn('text-ellipsis line-clamp-1')}>{listify(authorNames)}</div>
    </div>
  )
}

const Thumbnail = ({ blogPost }: { blogPost: CclBlogPost }) => {
  return (
    <Image
      className="h-full w-full rounded-xl object-cover"
      src={blogPost.thumbnailUrl || ''}
      alt={blogPost.title || ''}
    />
  )
}

export default BlogCard
