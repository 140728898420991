import React from 'react'

import { Loading } from 'components'

import { SearchDocumentResultFragment } from 'gql'

import EmptySearchResults from './EmptySearchResults'
import SearchResultItem from './SearchResultItem'
import SearchResultsPagination from './SearchResultsPagination'

const SearchResultsList = ({
  searchDocuments,
  searchResultsTotalCount,
  searchQuery,
  searchType,
  loading,
  page,
  userCan,
  handleResultClick
}: {
  searchDocuments: SearchDocumentResultFragment[]
  searchResultsTotalCount: number
  searchQuery: string
  searchType: string
  loading: boolean
  page: number
  userCan: {
    linkToSearchResults?: boolean | null
    viewMemberProfile?: boolean | null
    viewPost?: boolean | null
  }
  handleResultClick: (
    e: React.MouseEvent<HTMLAnchorElement>,
    searchPosition: number,
    resultComponent?: string,
    type?: string,
    subtype?: string | null
  ) => void
}) => {
  if (loading) return <Loading />

  return searchResultsTotalCount === 0 ? (
    <EmptySearchResults searchType={searchType} searchQuery={searchQuery} />
  ) : (
    <div>
      <ul className="grid list-none grid-cols-1 gap-6 p-0">
        {searchDocuments.map((doc, index) => (
          <SearchResultItem
            key={doc.id}
            document={doc}
            searchQuery={searchQuery}
            searchPosition={index + 1}
            userCan={userCan}
            handleResultClick={handleResultClick}
          />
        ))}
      </ul>
      <SearchResultsPagination
        page={page}
        searchResultsTotalCount={searchResultsTotalCount}
      />
    </div>
  )
}

export default SearchResultsList
