import { useEffect } from 'react'
import joinSlackImage from 'slack-banner-image.png'

import Button from 'components/Button'
import SlackIcon from 'components/icons/SlackIcon'

import { track } from 'utils/tracking/segment'

interface SlackBannerProp {
  hasSeenJoinSlackBanner: boolean
  joinSlackHref?: string
}

const SlackBanner = ({ joinSlackHref, hasSeenJoinSlackBanner }: SlackBannerProp) => {
  useEffect(() => {
    if (!hasSeenJoinSlackBanner) {
      // @ts-ignore - 'Slack - Action' event is not defined in Segment JIRA#REF-5159
      track('Slack - Action', {
        action: 'join_slack_cta_displayed',
        location: 'top_banner_cta'
      })
    }
  }, [hasSeenJoinSlackBanner])

  const handleClick = () => {
    // @ts-ignore - 'Slack - Action' event is not defined in Segment JIRA#REF-5159
    track('Slack - Action', {
      action: 'join_slack_cta_clicked',
      location: 'top_banner_cta'
    })

    window.open(
      joinSlackHref ||
        'https://join.slack.com/t/reforge-members/shared_invite/zt-1hcoz4s76-vvIfW2QIvHDa5R~wV64kFA'
    )
  }

  if (hasSeenJoinSlackBanner) return null

  return (
    <div className="flex bg-[#653866] h-[280px] w-full flex-col justify-center gap-6 self-stretch overflow-hidden rounded-xl p-6 sm:gap-8 md:h-[200px] md:flex-row md:items-center  md:py-8 md:px-10 lg:px-20">
      <div className="hidden md:flex">
        <img alt="" className="" src={joinSlackImage} />
      </div>
      <div className="flex w-full flex-col gap-8 md:flex-1 md:flex-row">
        <div className="flex-1 text-white">
          <div className="flex max-w-[600px] flex-col gap-2">
            <div className="font-polysans text-[28px] leading-[32px] tracking-[-0.56px]">
              From new connections to career companions
            </div>
            <div>
              Meet people who can shape your career journey by joining Reforge members on
              Slack
            </div>
          </div>
        </div>
      </div>
      <div>
        <Button
          shape="rounded"
          className="bg-white text-black hover:border-black hover:bg-black hover:text-white"
          iconBefore={<SlackIcon />}
          onClick={handleClick}
          style={{ minWidth: '158px' }}
        >
          <span className="font-semibold">Join Slack</span>
        </Button>
      </div>
    </div>
  )
}

export default SlackBanner
