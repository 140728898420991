import Lottie from 'lottie-react'
import { useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import checkmarkAnimation from './checkmark.json'
import confettiAnimation from './confetti.json'
import loaderAnimation from './loaderCircle.json'

interface ConfettiAnimationWithLoaderProps {
  percentComplete: number
  className?: string
}

const NUM_LOADER_FRAMES = 30

const ConfettiAnimationWithLoader = ({
  percentComplete,
  className
}: ConfettiAnimationWithLoaderProps) => {
  const numberOfLoaderFramesToPlay =
    Math.ceil((percentComplete * NUM_LOADER_FRAMES) / 100) + 1

  // useMemo to provide a stable reference to the `lottie-react` lib.
  const loaderSegment = useMemo<[number, number]>(
    () => [1, numberOfLoaderFramesToPlay],
    [numberOfLoaderFramesToPlay]
  )

  return (
    <div className={twMerge('relative mt-12 mb-8 h-16', className)}>
      <Lottie
        animationData={loaderAnimation}
        initialSegment={loaderSegment}
        loop={false}
        style={{
          height: '85px',
          inset: 0,
          position: 'absolute',
          zIndex: 2
        }}
        className="chromatic-ignore"
      />
      <Lottie
        animationData={checkmarkAnimation}
        loop={false}
        style={{
          height: '85px',
          inset: 0,
          position: 'absolute',
          zIndex: 2
        }}
        className="chromatic-ignore"
      />
      <Lottie
        animationData={confettiAnimation}
        style={{
          height: '150px',
          left: 0,
          right: 0,
          top: '-60px',
          position: 'absolute'
        }}
        className="chromatic-ignore"
      />
    </div>
  )
}

export default ConfettiAnimationWithLoader
