import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { PageProvider } from 'components/PageHeader/usePage'

import { PageSeo, PageTypeEnum } from 'gql'

import CreatorsShare from 'images/creators-share.png'

import CreatorLandingPageContent from './CreatorLandingPageContent'

const CreatorLandingPage = () => {
  const title = 'Become a creator on Reforge'
  const description = 'Share your work to unlock career opportunities.'

  const page: GenericPageType = {
    title: title,
    seo: {
      metaTitle: title,
      metaDesc: description,
      shareTitle: title,
      shareDesc: description,
      shareGraphic: { asset: CreatorsShare },
      shareGraphicAlt: title,
      type: 'website' as PageTypeEnum
    } as PageSeo
  }

  return (
    <MarketingLayout page={page} scrollToTop={false}>
      <PageProvider showHeader headerWrapperClassName="px-4 md:px-[4vw] pt-4">
        <CreatorLandingPageContent />
      </PageProvider>
    </MarketingLayout>
  )
}

export default CreatorLandingPage
