import { useState } from 'react'
import { useSearchParams } from 'react-router-dom-v5-compat'
import { useGlobalState } from 'state'

import AccountLayout from 'domains/Account/AccountLayout'
import { MyTeam } from 'domains/Account/MyTeam'
import ProfileSummary from 'domains/Profile/ProfileSummary'
import SubscriptionCard from 'domains/Subscription/SubscriptionCard'
import { EditForm } from 'domains/User/EditForm'
import Logout from 'domains/User/Logout'

import { ErrorMessage, Loading } from 'components'
import { useOutOfSeatsModal } from 'components/OutOfSeatsModal/useOutOfSeatsModal'

import {
  UserAccountDetailsFieldsFragment,
  useSubscriptionJoinRequestQuery,
  useUserAccountDetailsQuery,
  useUserSubscriptionWithTeamQuery
} from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

const AccountOverviewContainer = () => {
  const [envVariables] = useGlobalState('envVariables')

  const { openOutOfSeatsModal } = useOutOfSeatsModal()
  const [showAssignSeatModal, setShowAssignSeatModal] = useState(false)

  const { id, timezone, can } = useAssertCurrentUser()
  const { loading, error, data } = useUserAccountDetailsQuery({
    variables: { id }
  })
  const [searchParams] = useSearchParams()
  const joinRequestId = searchParams.get('join_request_id')

  const { loading: joinRequestLoading, error: joinRequestError } =
    useSubscriptionJoinRequestQuery({
      variables: { subscriptionJoinRequestId: joinRequestId },
      onCompleted: (data) => {
        const joinRequest = data?.subscriptionJoinRequest

        if (
          joinRequest &&
          joinRequest?.subscription &&
          joinRequest?.status !== 'accepted'
        ) {
          if (joinRequest?.subscription?.hasAvailableSeats) {
            setShowAssignSeatModal(true)
          } else {
            openOutOfSeatsModal()
          }
        }
      }
    })

  if (loading || joinRequestLoading) return <Loading />
  if (error || joinRequestError) return <ErrorMessage error={error} />
  if (!data || !data?.user) return null

  const { user } = data

  return (
    <AccountOverview
      user={user}
      isLegacyUser={can.accessAllCohortEventReplays}
      joinSlackHref={envVariables.joinSlackHref}
      timezone={timezone}
      showAssignSeatModal={showAssignSeatModal}
      joinRequestId={joinRequestId}
    />
  )
}

export interface AccountOverviewProps {
  user: UserAccountDetailsFieldsFragment
  isLegacyUser: boolean
  joinSlackHref: string
  timezone: string | null | undefined
  showAssignSeatModal: boolean
  joinRequestId: string | null
}

export function AccountOverview({ user, joinSlackHref, timezone }: AccountOverviewProps) {
  const {
    loading: subscriptionLoading,
    error: subscriptionError,
    data: subscriptionData
  } = useUserSubscriptionWithTeamQuery({ variables: { id: user.id } })

  if (subscriptionLoading) return <Loading />

  if (subscriptionError) {
    return <ErrorMessage error={subscriptionError} />
  }

  if (!subscriptionData?.user) {
    return <ErrorMessage error={'Something went wrong while loading your subscription'} />
  }

  return (
    <>
      <AccountLayout>
        <ProfileSummary user={user} />
        <SubscriptionCard
          subscriptionData={subscriptionData}
          subscriptionLoading={subscriptionLoading}
          subscriptionError={subscriptionError}
          timezone={timezone}
        />
        {subscriptionData.user && <MyTeam user={subscriptionData.user} />}
        <EditForm
          user={user}
          preference={user.contact.notificationPreferences}
          // NOTE: Temporarily turned off while we don't have any discussion activity emails sending.
          // canEditEmailPreferences={user.can.editEmailPreferences}
          canEditEmailPreferences={false}
          oauthProviders={user.oauthProviders}
          joinSlackHref={
            joinSlackHref ||
            'https://join.slack.com/t/reforge-members/shared_invite/zt-1hcoz4s76-vvIfW2QIvHDa5R~wV64kFA'
          }
        />
        <Logout />
      </AccountLayout>
    </>
  )
}

export default AccountOverviewContainer
