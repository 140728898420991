import { Link } from 'react-router-dom'

interface SeoSectionLinkProps {
  subject: {
    title: string
  }
  path: string
  trackNavigation: (topic?: any) => void
}

const SeoSectionLink = ({ subject, path, trackNavigation }: SeoSectionLinkProps) => {
  return (
    <Link
      key={subject.title}
      className="flex w-full items-center pb-2 text-base font-normal leading-[1.6rem] text-rb-gray-400 underline decoration-solid decoration-1"
      to={path}
      onClick={() => trackNavigation(subject)}
    >
      {subject.title}
    </Link>
  )
}

export default SeoSectionLink
