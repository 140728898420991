import placeholderimgsrc from 'p-placeholders.svg'
import { Link } from 'react-router-dom'

import Image from 'domains/Sanity/Image'
import { SEOTrackingRelatedIdentifiers } from 'domains/Seo/helpers'

import { ArtifactTopic } from 'gql'

import { cn } from 'utils/tailwind'
import { trackNavigationClicked } from 'utils/tracking/analytics'

export interface ArtifactSeoTopicLinkProps {
  topic: ArtifactTopic
  tracking: SEOTrackingRelatedIdentifiers
  className?: string
  onProfilePage?: boolean
}

/**
 * ArtifactSeoTopicLink
 * Returns a styled link
 * Button content includes a topic with an icon
 * onClick: track, and redirect the user to the logged out artifact index with the topic selected as a filter
 * */
export const ArtifactSeoTopicLink = ({
  topic,
  tracking,
  className,
  onProfilePage = false
}: ArtifactSeoTopicLinkProps) => {
  const { title, icon, slug, id } = topic
  const destination = `/artifacts/c/${slug}`

  const trackNavigation = () => {
    trackNavigationClicked({
      location: onProfilePage
        ? 'expert_profile'
        : `Artifact SEO related topic link (${title})`,
      text: title.toLowerCase(),
      type: onProfilePage ? 'button' : 'link',
      related_identifiers: {
        sanity_type: tracking.sanityType,
        sanity_id: id,
        sanity_name: title.toLowerCase()
      }
    })
  }

  return (
    <Link
      to={destination}
      className={cn(
        'inline-flex h-14 items-center rounded-lg border border-rb-gray-400 px-6 text-lg font-medium leading-[1.2] text-rb-gray-400 hover:text-rb-gray-400 hover:no-underline md:h-[4.5rem] md:px-8 md:leading-[1.4]',
        className
      )}
      data-testid="artifact-seo-topic-button"
      onClick={trackNavigation}
    >
      {!icon?.imageUrl ? (
        <img
          src={placeholderimgsrc}
          className="mr-4 h-6 w-6"
          alt={title}
          aria-hidden={true} // screen readers don't really need to narrate this icon for folks
        />
      ) : (
        <Image
          src={icon.imageUrl}
          className="mr-4 h-6 w-6"
          alt={icon.imageAlt || title}
          aria-hidden={true}
        />
      )}
      {title}
    </Link>
  )
}

export default ArtifactSeoTopicLink
