import { Link } from 'react-router-dom'

import Image from 'domains/Sanity/Image'
import ButtonMarketingCTA from 'domains/Sanity/marketingSite/ButtonMarketingCTA'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { Loading } from 'components'

import { Page, useMarketingReimbursementPageQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import Reimbursements1 from 'images/Reimbursements_01.png'
import Reimbursements2 from 'images/Reimbursements_02.png'
import ShallowSoftBluePink from 'images/ShallowSoft_Blue-Pink.jpg'

const MarketingReimbursementPage = () => {
  const { isLoggedIn } = useCurrentUser()
  const { data, loading } = useMarketingReimbursementPageQuery({
    skip: isLoggedIn
  })
  const page = (data?.reimbursementPage || {}) as Page

  if (isLoggedIn) {
    return null
  }

  return (
    <MarketingLayout page={page}>
      {loading ? <Loading /> : <Reimbursement />}
    </MarketingLayout>
  )
}

const EXAMPLE_PITCHES = {
  cohortCourses: {
    col1: [
      {
        title: 'Advanced Growth Strategy',
        href: 'https://docs.google.com/document/d/1nULZe0ppgLLckamk9OJm7mHEMhSTgvSgkg92IXuwPkk/edit?usp=sharing'
      },
      {
        title: 'Brand Marketing',
        href: 'https://docs.google.com/document/d/1RcVx9l_i-FYgytiYhHkMs3-3I1frHWKtJ5TQ-i6iNYo/edit'
      },
      {
        title: 'Data for Product Managers',
        href: 'https://docs.google.com/document/d/1pgYiHTYFgvveK8OK0QwcF_rYNUL2PwDhCcyHayTHQVA/edit?usp=sharing'
      },
      {
        title: 'Engineering Management',
        href: 'https://docs.google.com/document/d/1swyuupHyYrEtaOGYVhfdfBQ79DyzaySqS_Ji6eW9fLs/edit?usp=sharing'
      },
      {
        title: 'Experimentation & Testing',
        href: 'https://docs.google.com/document/d/1SvaHUiCYRUTLzBgS1g4XVKzv9NMyC5k2XaQCYwODl-0/edit?usp=sharing'
      },
      {
        title: 'Finding Product/Market Fit',
        href: 'https://docs.google.com/document/d/1DdJ7Zq0Ri1UG5ARieXO_N_VSAnqBle2RcfCnwjIGngc/edit'
      },
      {
        title: 'Growth Marketing',
        href: 'https://docs.google.com/document/d/1oXhot9RQxnhG4Twb0pw2xD7WGzK96dAht_xyqyiuouM/edit?usp=sharing'
      },
      {
        title: 'Growth Leadership',
        href: 'https://docs.google.com/document/d/1t2EpY08UmrQMX28559lcr9C16XYpJ-PhOdmvm8mYD58/edit'
      },
      {
        title: 'Growth Series',
        href: 'https://docs.google.com/document/d/1qxA949jCHTJz9AXuCAS5KqTz-GLoc0y7sbOh51qr8S4/edit?usp=sharing'
      },
      {
        title: 'Marketing Leadership',
        href: 'https://docs.google.com/document/d/1ZTYnoSItaJfzL1rUbb--nzfRLC8p5TUUDpnsRynq8_A/edit'
      },
      {
        title: 'Marketing Strategy',
        href: 'https://docs.google.com/document/d/1EK5pEm4aaTCDA7ALKSiXC_Loc5k3KAqzYIMK4AbuLgs/edit?usp=sharing'
      }
    ],
    col2: [
      {
        title: 'Mastering Product Management',
        href: 'https://docs.google.com/document/d/1N29zgD32cDZy4HDjSwreHCDuDqRSRS-1fi6BYI2MY7U/edit?usp=sharing'
      },
      {
        title: 'Monetization & Pricing',
        href: 'https://docs.google.com/document/d/1QP2vDm3shzgNENYGwsfadO3ceInV2wqmCgTFcttkSts/edit?usp=sharing'
      },
      {
        title: 'Product Leadership',
        href: 'https://docs.google.com/document/d/1vhGmnznzzJMi756ST08p8cP1naFOaDXliJkSpWAxiKE/edit?usp=sharing'
      },
      {
        title: 'Product Management Foundations',
        href: 'https://docs.google.com/document/d/1MstGi8JPOuT8RnzlsEkJddjR1d89HwXRm0PwjFcifl4/edit?usp=sharing'
      },
      {
        title: 'Product Marketing',
        href: 'https://docs.google.com/document/d/1R5LGZiLdlWxa-8AuxXIp5PMUxW5aDBSHtcyR5GrskzQ/edit'
      },
      {
        title: 'Product Strategy',
        href: 'https://docs.google.com/document/d/1arlvx9ufBAy--GBRff5m8pe0gTwFSJooASPhFuGAx1s/edit?usp=sharing'
      },
      {
        title: 'Retention & Engagement',
        href: 'https://docs.google.com/document/d/1tRZYuVBp4pj3WUjHk0eWck61rtetI9FrUovWeATDLWA/edit?usp=sharing'
      },
      {
        title: 'Scaling Product Delivery',
        href: 'https://docs.google.com/document/d/1RLibhUFzTY5NgqAFxeXTQDMlLOFyVIIfHgstNJIRVEk/edit?usp=sharing'
      },
      {
        title: 'Technical Strategy',
        href: 'https://docs.google.com/document/d/1qecWqjgI08ulkrdqcKco7rkAW5uv1WZWH8z0AEMKi7w/edit?usp=sharing'
      },
      {
        title: 'User Insights for Product Decisions',
        href: 'https://docs.google.com/document/d/1ZZltt7CFuA4VmhlIkWOBtC7m91ie3nKwcF3-ShllLZs/edit?usp=sharing'
      }
    ]
  },
  marketplaceBeta: [
    {
      title: 'Growing a Thriving Developer Platform',
      href: 'https://docs.google.com/document/d/1zcE6WVE0L9qAfIu6EFgoGynPJlMrYjeX4g56AFgDAlY/edit?usp=sharing'
    },
    {
      title: 'Designing Customer Pilots',
      href: 'https://docs.google.com/document/d/1T1fLmnqNgxWuNOVPxZZpw-JpRB_0bwcVJGys4-Mc-NE/edit?usp=sharing'
    },
    {
      title: 'Managing Your PM Career',
      href: 'https://docs.google.com/document/d/1C-v_fbda-xXi-QI0nwlInhHQQ_ykrmrU9NpoYd5irqM/edit?usp=sharing'
    },
    {
      title: 'Marketplace Growth',
      href: 'https://docs.google.com/document/d/1ufXLncb79PTq4uEw8feXzQADglqbcK408bdF-iWcAgc/edit?usp=sharing'
    },
    {
      title: 'Product Innovation',
      href: 'https://docs.google.com/document/d/1igJHwD77jcQX9H6Vf2X0o4MhEtyIMmApKSv4gWqA1js/edit?usp=sharing'
    },
    {
      title: 'Storytelling for PMs',
      href: 'https://docs.google.com/document/d/1tRnrDy9tyipY11-bM0NNIZzA9a8JForErl0icyAwrXA/edit?usp=sharing'
    },
    {
      title: 'Technical Fluency for PMs',
      href: 'https://docs.google.com/document/d/1qXqzVl1oBZ3A6qNVpzquZmH1xMAQmDBEcmIdAbEP3q8/edit?usp=sharing'
    },
    {
      title: 'Design and Build Conversational AI',
      href: 'https://docs.google.com/document/d/14V4FO3lQpXN_Vdi5gO9vyaWWtJsISyXQY0TxeG6WP5s/edit?usp=sharing'
    }
  ]
}

export const Reimbursement = () => {
  return (
    <div className="reimbursement-page">
      <section className="relative px-4 py-[3.3vmax] md:px-[4vw]">
        <Image
          src={ShallowSoftBluePink}
          alt=""
          fill
          priority
          className="-z-1 scale-x-[-1] object-cover object-top opacity-75 xl:h-full xl:object-fill"
        />

        <div className="md:max-w-[65%]">
          <h1 className="mb-[32px] font-normal tracking-tight fluid-text-7xl">
            Reforge Payment and Funding Options
          </h1>
          <p>
            To participate in a Reforge program you must be a member. Reforge membership
            costs $1,995 per year and is payable via credit card or wire transfer. We
            realize this is a financial investment in your career and we’re confident that
            our programs are well worth it.
          </p>
        </div>
      </section>

      <section className="flex flex-col-reverse px-4 py-[3.3vmax] md:flex-row md:px-[4vw]">
        <div className="flex flex-col md:mr-[4%] md:max-w-[45%]">
          <h3 className="mb-[32px] font-normal tracking-tight fluid-text-3xl">
            Book with a team
          </h3>
          <p className="mb-[5%]">
            Reforge offers tiered pricing for teams. The pricing tiers are designed to
            maximize flexibility and provide great unit economics for getting your full
            team on Reforge. They start at $5,995/yr for up to 10 seats. To learn more,{' '}
            <Link to="/teams/get-in-touch">get in touch here.</Link>
          </p>
          <ButtonMarketingCTA href="/teams" className="mb-[16px] mt-auto">
            Explore Reforge for Teams ↗
          </ButtonMarketingCTA>
        </div>

        <div className="relative mb-[20px] h-[200px] min-h-[16vw] w-full md:mb-0 md:h-auto md:max-w-[35%]">
          <Image src={Reimbursements1} alt="" fill className="object-contain" />
        </div>
      </section>

      <section className="flex flex-col-reverse px-4 py-[3.3vmax] md:flex-row-reverse md:justify-end md:px-[4vw]">
        <div className="flex flex-col md:ml-[4%] md:max-w-[45%]">
          <h3 className="mb-[32px] font-normal tracking-tight fluid-text-3xl">
            Expense through your company
          </h3>
          <p>
            A Reforge membership is just as valuable to your company as it is for you. The
            systems and frameworks you learn at Reforge will expand your ability to
            produce results that move the business forward. For this reason, many of our
            participants expense their membership to their company. Every company’s policy
            is different, so we’ve put together example pitches you can use below.
          </p>
        </div>

        <div className="relative mb-[20px] h-[200px] min-h-[16vw] w-full md:mb-0 md:h-auto md:max-w-[30%]">
          <Image src={Reimbursements2} alt="" fill className="object-contain" />
        </div>
      </section>

      <section className="bg-rb-background-beige py-[6.6vmax] px-4 md:px-[4vw]">
        <h3 className="mb-[32px] font-normal tracking-tight fluid-text-3xl">
          Example Pitches
        </h3>

        <p className="mb-[16px] max-w-[60%]">
          You can use the pre-written emails below to pitch Reforge to your manager,
          customizing as necessary. Just copy the doc and edit from there!
        </p>

        <div className="flex flex-col md:flex-row md:justify-between">
          <div className="w-full md:max-w-[60%]">
            <p className="mb-[20px] border-b border-rb-black pb-[16px] font-medium tracking-tight fluid-text-xl">
              <em>Reforge Live Courses</em>
            </p>

            <div className="flex items-start">
              <ul className="w-1/2 fluid-text-base">
                {EXAMPLE_PITCHES.cohortCourses.col1.map((pitch) => (
                  <li key={pitch.href}>
                    <a href={pitch.href} target="_blank" rel="noopener noreferrer">
                      {pitch.title}
                    </a>
                  </li>
                ))}
              </ul>

              <ul className="w-1/2 fluid-text-base">
                {EXAMPLE_PITCHES.cohortCourses.col2.map((pitch) => (
                  <li key={pitch.href}>
                    <a href={pitch.href} target="_blank" rel="noopener noreferrer">
                      {pitch.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="w-full md:max-w-[30%]">
            <p className="mb-[20px] border-b border-rb-black pb-[16px] font-medium tracking-tight fluid-text-xl">
              <em>Course Marketplace Beta</em>
            </p>

            <ul className="fluid-text-base">
              {EXAMPLE_PITCHES.marketplaceBeta.map((pitch) => (
                <li key={pitch.href}>
                  <a href={pitch.href} target="_blank" rel="noopener noreferrer">
                    {pitch.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section className="px-4 py-[3.3vmax] md:px-[4vw]">
        <h3 className="mb-[32px] font-normal tracking-tight fluid-text-3xl">
          Reimbursement tips from Reforge alumni
        </h3>

        <ul className="fluid-text-base md:max-w-[65%]">
          <li>
            <strong>Show your commitment by having a plan</strong> of how you are going to
            make time and apply the learnings to your product.
          </li>
          <li>
            <strong>Position the program as an investment</strong>, not an expense. If the
            program helps you increase growth of your product by even a tiny amount, it
            pays for itself.
          </li>
          <li>
            <strong>Include a list of the leaders</strong> involved in Reforge.
          </li>
          <li>
            <strong>Understand your company’s education budget.</strong> Most companies
            have professional development budgets set aside for their employees.
            Understanding the policy can help you make your case.
          </li>
          <li>
            <strong>Tap into a conference budget.</strong> Many companies have budgets to
            send employees to a conference. The ROI of Reforge compared to a conference is
            much greater. You get structured content, focused networking, and specific
            outcomes.
          </li>
          <li>
            <strong>Include a sample of the content.</strong> The following blog posts
            offer primers on program material:
            <ul>
              <li>
                <Link to="/blog/growth-metric-acquisition-monetization-virality">
                  <strong>
                    The One Growth Metric that Moves Acquisition, Monetization, and
                    Virality
                  </strong>
                </Link>
              </li>
              <li>
                <Link to="/blog/growth-system">
                  <strong>Building Growth: Product, Process, and Team</strong>
                </Link>
              </li>
              <li>
                <Link to="/blog/growth-loops">
                  <strong>Growth Loops are the New Funnels</strong>
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </section>

      <section className="mb-[40px] flex justify-center px-4 py-[3.3vmax] md:px-[4vw]">
        <ButtonMarketingCTA href="/login">View Your Dashboard ↗</ButtonMarketingCTA>
      </section>
    </div>
  )
}

export default MarketingReimbursementPage
