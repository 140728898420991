import { ReactComponent as DiscussionIcon } from 'icon--discussion.svg'
import { ReactComponent as DoubleChevronRightIcon } from 'icon--double-chevron-right.svg'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { twMerge } from 'tailwind-merge'

import FeedbacksCmsSection from 'domains/Cms/FeedbacksCmsSection'
import ManageBookmarkButton from 'domains/Collections/ManageBookmarkButton'

import DiscoveryDrawer from 'components/DiscoveryDrawer'
import { SVGIcon } from 'components/Icon'
import { useSideDrawer } from 'components/SideDrawer'
import HighlightIcon from 'components/icons/HighlightIcon'

import {
  BookmarkType,
  CmsSectionContentType,
  CurrentUserPartsFragment,
  LessonHeaderCmsModulePartsFragment,
  LessonHeaderCmsProgramPartsFragment,
  LessonHeaderCmsSectionPartsFragment,
  LessonHeaderUserProgramPartsFragment,
  LessonViewerDocument,
  Maybe,
  ProgramBookmarkPartsFragment
} from 'gql'

import { cn } from 'utils/tailwind'

interface LessonHeaderProps {
  cmsProgram: LessonHeaderCmsProgramPartsFragment
  userProgram?: Maybe<LessonHeaderUserProgramPartsFragment>
  cmsSection: LessonHeaderCmsSectionPartsFragment
  cmsModule: LessonHeaderCmsModulePartsFragment
  currentUser?: CurrentUserPartsFragment
  viewAccess: string
  leftDrawerId: string
  rightDrawerId: string
  setActivePanel: (type: string) => void
  activePanel: string
  isCohortViewer?: boolean
  trackActionButtonClick?: (type: 'discussions' | 'bookmarks') => void
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  isInSavedItems: boolean
  setIsInSavedItems: (isInSavedItems: boolean) => void
}

const LessonHeader = ({
  cmsSection,
  cmsProgram,
  cmsModule,
  currentUser,
  viewAccess,
  leftDrawerId,
  rightDrawerId,
  setActivePanel,
  isCohortViewer,
  trackActionButtonClick,
  openAddToBookmarkFolderModal,
  isInSavedItems,
  setIsInSavedItems
}: LessonHeaderProps) => {
  const { isDrawerOpen, openDrawer, toggleDrawer } = useSideDrawer()

  const handleActionButtonClick = (type: 'discussions' | 'bookmarks') => {
    openDrawer(rightDrawerId)
    setActivePanel?.(type)
    trackActionButtonClick?.(type)
  }

  const lessonBookmark = {
    type:
      cmsSection.contentType === CmsSectionContentType.LESSON
        ? BookmarkType.LESSONBOOKMARK
        : BookmarkType.RESOURCEBOOKMARK,
    cmsProgramId: cmsProgram?.id,
    cmsSectionId: cmsSection?.id,
    cmsModuleId: cmsModule?.id,
    ...(cmsSection.contentBookmarkId && { id: cmsSection.contentBookmarkId })
  }

  const unitTitle = cmsSection?.parent?.name || cmsSection.name
  const showLessonIconImage = !['Unit', 'Resource'].includes(
    cmsSection?.contentType || ''
  )

  const showDiscoveryDrawer =
    (cmsSection.contentType === CmsSectionContentType.LESSON ||
      cmsSection.contentType === CmsSectionContentType.RESOURCE) &&
    currentUser &&
    (currentUser?.is.member || currentUser?.is.paidMember || currentUser?.is.planManager)

  return (
    <>
      <Helmet>
        <title>
          {cmsModule.name} - {cmsSection.name}
        </title>
      </Helmet>
      {!isDrawerOpen(leftDrawerId) && !isCohortViewer && (
        <div className="cms-header__left fixed m-0 h-[56px] pr-4 3xl:relative">
          <button
            className="flex h-full items-center pr-3 pt-3 text-rb-gray-300 hover:text-rb-gray-400 hover:no-underline"
            onClick={() => toggleDrawer(leftDrawerId)}
            uk-tooltip="title: Open Sidebar; cls: uk-active rf-tooltip-left-arrow; pos: right"
          >
            <DoubleChevronRightIcon width={16} height={16} />
            <span className="hidden pl-3 text-xs uppercase tracking-widest 3xl:flex">
              Contents
            </span>
          </button>
        </div>
      )}

      <div
        className={twMerge(
          'cms-header top-0 z-20 bg-white',
          isDrawerOpen(leftDrawerId) && 'ml-2',
          isCohortViewer && 'top-[68px]'
        )}
        id="sticky-top"
        data-test={`lesson-header__${viewAccess}-access`}
      >
        <div
          className={twMerge(
            'pr-4 pt-3 pl-12 lg:pr-8 xl:pl-8',
            isCohortViewer && 'pl-3',
            !currentUser && 'pl-0 lg:pl-12'
          )}
        >
          <div>
            <div className="uk-grid uk-grid-collapse uk-container m-auto flex h-10 max-w-[970px] flex-nowrap items-center justify-between pb-2.5">
              <div className="flex grow items-center">
                {!isDrawerOpen(leftDrawerId) && isCohortViewer && (
                  <button
                    className="flex h-full items-center pr-3 text-rb-gray-300 hover:text-rb-gray-400 hover:no-underline"
                    onClick={() => toggleDrawer(leftDrawerId)}
                    uk-tooltip="title: Open Sidebar; cls: uk-active rf-tooltip-left-arrow; pos: right"
                  >
                    <SVGIcon name="thin-chevron-right" width="9" height="14" />
                    <SVGIcon name="thin-chevron-right" width="9" height="14" />
                    <span className="hidden pl-3 text-xs uppercase tracking-widest xl:flex">
                      Contents
                    </span>
                  </button>
                )}
                {showLessonIconImage && (
                  <div className="mr-2.5 flex items-center">
                    <Link
                      to={`/programs/${cmsProgram?.slug}${!currentUser ? '/preview' : ''}`}
                      data-for="lesson-header-link-tooltip"
                      data-tip={`Return to ${cmsProgram?.name}${!currentUser ? ' Preview' : ''}`}
                      className={cn(
                        'h-[25px] w-[25px] flex items-center',
                        !currentUser && 'w-auto lg:w-[25px] xs:pl-2 md:pl-6 lg:pl-0'
                      )}
                    >
                      {!currentUser && (
                        <div className="lg:hidden h-full w-6 pt-1 flex items-center">
                          <SVGIcon name="thin-chevron-left" />
                        </div>
                      )}
                      <img
                        className="inline-block"
                        src={cmsProgram?.iconImageUrl || ''}
                        alt={cmsProgram?.name}
                        width="25"
                        height="25"
                      />
                    </Link>
                    <ReactTooltip
                      place={currentUser ? 'bottom' : 'right'}
                      effect="solid"
                      backgroundColor="#4D4D4D"
                      id="lesson-header-link-tooltip"
                    />
                  </div>
                )}
                <div className="pr-2 text-lg font-semibold line-clamp-2">
                  {isCohortViewer
                    ? cmsSection?.name
                    : ['Unit', 'Resource'].includes(cmsSection?.contentType || '')
                      ? unitTitle
                      : cmsModule?.name}
                </div>
                <div className="hidden justify-between gap-4 tl:flex"></div>
              </div>
              {showDiscoveryDrawer && !isDrawerOpen(rightDrawerId) && (
                <div className="hidden justify-between gap-4 tl:flex">
                  <DiscoveryDrawer.Buttons>
                    <DiscoveryDrawer.Button
                      icon={<HighlightIcon className="h-4 w-4 fill-black" />}
                      key="bookmarks"
                      id="discovery-drawer-bookmarks-button"
                      className="discovery-drawer-bookmarks-button"
                      onClick={() => handleActionButtonClick('bookmarks')}
                    />
                    <DiscoveryDrawer.Button
                      icon={<DiscussionIcon width={16} height={16} />}
                      key="discussions"
                      id="discovery-drawer-discussions-button"
                      className="discovery-drawer-discussions-button"
                      onClick={() => handleActionButtonClick('discussions')}
                    />
                    <ManageBookmarkButton
                      contentBookmarkId={cmsSection.contentBookmarkId}
                      fetchOptions={{ refetchQueries: [LessonViewerDocument] }}
                      openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                      bookmarkInfo={lessonBookmark}
                      isInSavedItems={isInSavedItems}
                      setIsInSavedItems={setIsInSavedItems}
                    />
                  </DiscoveryDrawer.Buttons>
                </div>
              )}
              {!isCohortViewer && (
                <div className="cms-header__right uk-align-right uk-hidden@s m-0 flex-shrink-0">
                  {cmsSection?.nextSectionLearningCmsSectionPath && currentUser && (
                    <div className="lesson-feedback js-lesson-feedback--top uk-position-top-right uk-animation-fast uk-position-absolute uk-invisible uk-position-z-index uk-box-shadow-medium uk-background-default p-7 text-rb-gray-400">
                      <FeedbacksCmsSection
                        cmsSectionId={cmsSection?.id}
                        cmsSectionCmsProgramId={cmsProgram?.id}
                        userId={currentUser?.id}
                        nextSectionUrl={cmsSection?.nextSectionLearningCmsSectionPath}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            {cmsSection?.nextSectionLearningCmsSectionPath && currentUser && (
              <div className="lesson-feedback js-lesson-feedback--top uk-position-top-right uk-animation-fast uk-position-absolute uk-invisible uk-position-z-index uk-box-shadow-medium uk-background-default p-7 text-rb-gray-400">
                <FeedbacksCmsSection
                  cmsSectionId={cmsSection?.id}
                  cmsSectionCmsProgramId={cmsProgram?.id}
                  userId={currentUser.id}
                  nextSectionUrl={cmsSection?.nextSectionLearningCmsSectionPath}
                />
              </div>
            )}
          </div>
        </div>
        {currentUser && (
          <div
            className={twMerge(
              'w-100 flex items-center justify-between pl-12 pb-2 tl:hidden',
              isCohortViewer && 'pl-3'
            )}
          >
            <DiscoveryDrawer.Buttons>
              <DiscoveryDrawer.Button
                icon={<HighlightIcon className="h-4 w-4 fill-black" />}
                key="bookmarks"
                onClick={() => handleActionButtonClick('bookmarks')}
              />
              <DiscoveryDrawer.Button
                icon={<DiscussionIcon width={16} height={16} />}
                key="discussions"
                onClick={() => handleActionButtonClick('discussions')}
              />
              <ManageBookmarkButton
                contentBookmarkId={cmsSection.contentBookmarkId}
                fetchOptions={{ refetchQueries: [LessonViewerDocument] }}
                openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                bookmarkInfo={lessonBookmark}
                isInSavedItems={isInSavedItems}
                setIsInSavedItems={setIsInSavedItems}
              />
            </DiscoveryDrawer.Buttons>
          </div>
        )}
      </div>
    </>
  )
}

export default LessonHeader
