import { useCreateUserClipActivityMutation } from 'gql'

import useWistiaPictureInPicture from 'hooks/useWistiaPictureInPicture'
import useWistiaVideo from 'hooks/useWistiaVideo'

import sendEventTrackingEvent from 'utils/tracking/event'

interface WistiaPlayerProps {
  code: string
  userEmail?: string
  wrapperClasses?: string
  objectType?: string
  objectId?: string
  onVideoFirstPlay?(): void
  isMobile?: boolean
}

const WistiaPlayer = ({
  code,
  userEmail,
  objectType,
  objectId,
  onVideoFirstPlay,
  wrapperClasses = '',
  isMobile = false
}: WistiaPlayerProps) => {
  const [createUserClipActivity] = useCreateUserClipActivityMutation()

  const handleOnVideoPlay = () => {
    if (objectType === 'Clip' && objectId) {
      createUserClipActivity({
        variables: {
          input: {
            clipId: objectId.toString(),
            action: 'viewed'
          }
        }
      })
    }
  }

  const handleOnVideoFirstPlay = () => {
    onVideoFirstPlay?.()
    if (objectType === 'Event' && objectId) {
      sendEventTrackingEvent('recording_play_click', { id: objectId })
    }
  }

  const handleOnVideoEnd = () => {
    if (objectType === 'Clip' && objectId) {
      createUserClipActivity({
        variables: {
          input: {
            clipId: objectId.toString(),
            action: 'completed'
          }
        }
      })
    }
  }

  const { videoInstance } = useWistiaVideo({
    eventTrackingInformation: {
      objectType,
      objectId
    },
    userEmail: userEmail || '',
    onVideoPlay: handleOnVideoPlay,
    onVideoFirstPlay: handleOnVideoFirstPlay,
    onVideoEnd: handleOnVideoEnd,
    code
  })

  useWistiaPictureInPicture(videoInstance, isMobile)

  return (
    <div className={wrapperClasses}>
      <div
        id="wistia-player-wrapper"
        className="wistia_responsive_padding"
        style={{ padding: '56.0% 0 0 0', position: 'relative' }}
      >
        <div
          id="wistia-player"
          data-test="wistia-player"
          className="wistia_responsive_wrapper absolute left-0 top-0 h-[100%] w-[100%]"
        >
          <div
            className={`wistia_embed wistia_async_${code} videoFoam=true`}
            style={{ height: '100%', width: '100%' }}
          >
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  )
}

export default WistiaPlayer
