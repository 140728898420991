import { Link } from 'react-router-dom'

import Image from 'domains/Sanity/Image'

import { BlogPost } from 'gql'

import ButtonMarketingCTA from '../ButtonMarketingCTA'

const FeaturedBlogPostCard = ({ summaryImage, title, slug, authors }: BlogPost) => {
  const blogPostUrl = `/blog/${slug}`

  return (
    <article className="mb-[45px] flex w-full shrink-0 flex-col md:w-1/2 md:px-[1.5vw] 2xl:w-1/3">
      <section className="mb-[16px] flex">
        <Link to={blogPostUrl} className="relative aspect-[16/9] w-full">
          <Image
            src={summaryImage?.imageUrl || ''}
            alt={summaryImage?.imageAlt || ''}
            fill
            className="object-cover object-center"
          />
        </Link>
      </section>

      <section className="flex w-full flex-col">
        <Link
          to={blogPostUrl}
          className="mb-[10px] font-normal tracking-tighter text-rb-black no-underline fluid-text-lg hover:no-underline"
        >
          {title}
        </Link>

        {(authors || []).length > 0 && (
          <p className="mb-[10px] text-sm !leading-[1.3]">
            {'By '}
            {authors?.map((expert, i) => {
              return `${i === authors.length - 1 && i !== 0 ? ' & ' : ''}${expert.name}${
                i < authors.length - 2 ? ', ' : ''
              }`
            })}
          </p>
        )}

        <ButtonMarketingCTA variant="text" size="small" href={blogPostUrl}>
          Read More ↗
        </ButtonMarketingCTA>
      </section>
    </article>
  )
}

export default FeaturedBlogPostCard
