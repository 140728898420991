import React from 'react'

export interface SkeletonWrapperProps {
  children: React.ReactNode
  skeleton: React.ReactNode
  loading: boolean
}

/**
 * SkeletonWrapper
 * wrapper toggles between skeleton and children depending on loading state
 * */
export default function SkeletonWrapper({
  children,
  loading,
  skeleton
}: SkeletonWrapperProps) {
  return <div data-testid="skeleton-wrapper">{loading ? skeleton : children}</div>
}
