import { CohortPassSectionProps } from 'components/CheckoutModal/components/CohortPassSection'

import { PlanName, TaxInfoItem } from 'gql'

const DEFAULT_INDIVIDUAL_PASS_COUNT = 0 // Individuals get 1 discounted pass by default, but 0 undiscounted passes by default
const DEFAULT_STARTER_PASS_COUNT = 2
const DEFAULT_SCALE_PASS_COUNT = 6

export enum ProductItemCodes {
  COHORT_PASS_ITEM_CODE = 'cohort-pass',
  SUBSCRIPTION_ITEM_CODE = 'reforge-membership',
  COURSE_ITEM_CODE = 'reforge-course'
}

export type CheckoutFlowContext =
  | 'upgradeSubscription'
  | 'restartSubscription'
  | 'cohortPassPurchase'

export const locale = 'en-US'
export const currencyOptions = {
  style: 'currency',
  currency: 'USD'
}

export const getDefaultPassCount = (planName: PlanName | null) => {
  if (planName === PlanName.INDIVIDUAL) return DEFAULT_INDIVIDUAL_PASS_COUNT
  if (planName === PlanName.STARTER) return DEFAULT_STARTER_PASS_COUNT
  if (planName === PlanName.SCALE) return DEFAULT_SCALE_PASS_COUNT
  return 0
}

export const getCohortPassPrice = ({
  cohortPassPriceTiers,
  count
}: {
  cohortPassPriceTiers: CohortPassSectionProps['cohortPassPriceTiers']
  count: number
}): number => {
  if (count === 0) return 0

  const priceTier = cohortPassPriceTiers.find(
    (tier) => count >= tier.minCount && count <= tier.maxCount
  )

  if (!priceTier) {
    // this shouldn't happen but in the event that it does
    console.error('Pricing tier not found')
    return 0
  }

  return priceTier.pricePerPass
}

export type OrderRecapType = {
  plan?: {
    price: number
    name: PlanName
    seatCount: number
  }
  proratedAdjustment?: {
    price: number
    start: string
    end: string
  }
  discountedCohortPassAmount: number | null
  cohortPass: {
    quantity: number
    price: number
    label: string
  }
  startingBalance: {
    price: number
  }
  subTotal: {
    price: number
  }
  taxInfo?: {
    price: number
  }
}

export const calculateTaxPrice = ({
  price,
  taxItem
}: {
  price: number
  taxItem?: TaxInfoItem
}) => {
  if (!taxItem) {
    return 0
  }

  let taxAmount = 0

  taxItem.details
    .filter((item) => item.taxableAmount > 0)
    .forEach((item) => {
      taxAmount += price * item.rate * item.taxableAmount
    })

  return taxAmount
}

export const calculateOrderTotal = ({
  subTotal,
  taxTotal,
  startingBalance
}: {
  subTotal: number
  taxTotal: number
  startingBalance: number
}) => {
  const total = subTotal + taxTotal + startingBalance

  if (total < 0) {
    return 0
  }

  return total
}
