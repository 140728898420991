import { twMerge } from 'tailwind-merge'

import { getColorFromFullName } from 'utils/colors'

import placeholderAvatarImg from 'assets/images/avatar-placeholder.svg'

type size = 'large' | 'medium' | 'small' | 'xsmall' | 'mini'

export interface AvatarImageProps {
  borderRadius?: string
  cursor?: string
  size?: size
  user: Record<string, any> | null | undefined // @todo: define type
  withBadge?: boolean
  withWhiteBorder?: boolean
}

const sizeToRems: {
  [K in size]: {
    image: number
    initial: '4xl' | '2xl' | 'xl' | 'base' | 'xs'
  }
} = {
  mini: { image: 30, initial: 'xs' },
  xsmall: { image: 40, initial: 'base' },
  small: { image: 60, initial: 'xl' },
  medium: { image: 80, initial: '2xl' },
  large: { image: 109, initial: '4xl' }
}

const AvatarImage = ({
  size = 'xsmall',
  user,
  withBadge = false,
  withWhiteBorder = false
}: AvatarImageProps) => {
  const avatarUrl = user?.avatarUrl || user?.profile?.avatarUrl

  return (
    <div className="flex shrink-0 flex-row">
      {avatarUrl && !avatarUrl.includes('default_avatar') ? (
        <AvatarImageAsPhoto
          fullName={user.fullName}
          avatarUrl={avatarUrl}
          size={size}
          withWhiteBorder={withWhiteBorder}
        />
      ) : user?.fullName ? (
        <AvatarAsInitial
          fullName={user.fullName}
          size={size}
          withWhiteBorder={withWhiteBorder}
        />
      ) : (
        <AvatarImageAsFallback size={size} withWhiteBorder={withWhiteBorder} />
      )}
      {withBadge && (
        <div>
          <span className="ml-[3px] inline-block h-[12px] w-[12px] rounded-full bg-rb-green-100" />
        </div>
      )}
    </div>
  )
}

const AvatarImageAsPhoto = ({
  fullName,
  avatarUrl,
  withWhiteBorder,
  size
}: {
  fullName: string
  avatarUrl: string
  size: size
  withWhiteBorder: boolean
}) => (
  <img
    style={{ width: sizeToRems[size].image, height: sizeToRems[size].image }}
    alt={fullName || 'Unknown'}
    className={`rounded-full ${withWhiteBorder ? 'border-2 border-rb-white' : ''}`}
    src={avatarUrl ?? placeholderAvatarImg}
    referrerPolicy="no-referrer"
  />
)

const AvatarImageAsFallback = ({
  size,
  withWhiteBorder
}: {
  size: size
  withWhiteBorder: boolean
}) => (
  <img
    alt="Unknown"
    className={`${withWhiteBorder ? 'border-2 border-rb-white' : ''}`}
    src={placeholderAvatarImg}
    height={sizeToRems[size].image}
    width={sizeToRems[size].image}
  />
)

export const DEFAULT_AVATAR_TAILWIND_COLORS = [
  'bg-blue-500',
  'bg-green-500',
  'bg-yellow-500',
  'bg-red-500',
  'bg-purple-500',
  'bg-pink-500',
  'bg-indigo-500',
  'bg-orange-500',
  'bg-teal-500',
  'bg-lime-500',
  'bg-emerald-500',
  'bg-cyan-500',
  'bg-fuchsia-500',
  'bg-violet-500',
  'bg-rose-500',
  'bg-amber-500',
  'bg-sky-500'
]

const AvatarAsInitial = ({
  fullName,
  size,
  withWhiteBorder
}: {
  fullName?: string
  size: size
  withWhiteBorder?: boolean
}) => {
  const baseAvatarClass = twMerge(
    'rounded-full',
    withWhiteBorder ? 'border-2 border-rb-white' : '',
    size === 'mini' && 'w-[30px] h-[30px]',
    size === 'xsmall' && 'w-[40px] h-[40px]',
    size === 'small' && 'w-[60px] h-[60px]',
    size === 'medium' && 'w-[80px] h-[80px]',
    size === 'large' && 'w-[109px] h-[109px]'
  )

  return (
    <span
      className={twMerge(
        baseAvatarClass,
        getColorFromFullName(fullName || ''),
        `inline-flex place-items-center items-center justify-center uppercase text-white text-${sizeToRems[size].initial}`
      )}
    >
      {fullName?.[0]}
    </span>
  )
}

export default AvatarImage
