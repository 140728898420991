import { ProfileSidebarSectionHeader } from 'pages/MemberProfilePage'

import { ErrorMessage, Loading } from 'components'
import EventCard from 'components/EventCard/EventCard'

import { EventPartsFragment, useUserEventsQuery } from 'gql'

export interface RecentEventActivityProps {
  userId: string
}

const getIterableEvents = (events: any) => events || []

export function RecentEventActivity({ userId }: RecentEventActivityProps) {
  const { loading, error, data } = useUserEventsQuery({ variables: { userId } })

  if (loading) return <Loading />
  if (error) return <ErrorMessage error={error} />
  if (!data) return <ErrorMessage error="Failed to load events." />
  if (!data.user) return <ErrorMessage error="Failed to load user." />
  if (!data.currentUser) return <ErrorMessage error="Failed to load current user." />

  const { user: userFromQuery, currentUser } = data
  const { eventsHosted } = userFromQuery

  const hostedEvents = getIterableEvents(eventsHosted)

  if (hostedEvents.length > 0) return null

  const { eventsRecentlyInteracted } = userFromQuery

  const events = getIterableEvents(eventsRecentlyInteracted)

  if (!events.length) return null

  const trackingQueryString = `?from=profile-recent-event-section&from_member_id=${userFromQuery.id}`

  return (
    <div>
      <ProfileSidebarSectionHeader title={'Recent Event Activity'} />
      <div className="flex flex-col gap-y-4">
        {events.map((event: EventPartsFragment) => {
          return (
            <EventCard
              currentUser={currentUser}
              event={event}
              key={event.id}
              eventPageQueryString={trackingQueryString}
            />
          )
        })}
      </div>
    </div>
  )
}
