import { Popover } from '@headlessui/react'
import { useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom-v5-compat'
import ReactTooltip from 'react-tooltip'

import { useQueryParamPresentOnLoad } from 'domains/Artifact/hooks/useQueryParamPresentOnLoad'

import Button from 'components/Button'
import { useCalendarDropDown } from 'components/CalendarDropdownMenu'
import { SVGIcon } from 'components/Icon'
import { CircleCheckIcon } from 'components/icons'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { getCurrentTimezone } from 'utils/date'
import { isEventInSession } from 'utils/eventUtils'
import { cn } from 'utils/tailwind'
import { trackCtaClicked } from 'utils/tracking/analytics'
import {
  COHORT_EVENT_LINK_URL,
  EVENT_LINK_URL,
  makeSearchParamsString,
  makeUrl
} from 'utils/url'

import { JoinCtaProps } from '../types'
import AddToCalendarCard from './Calendar'
import CongratsModal from './CongratsModal'
import { ctaClickedLocation, getSizeClasses } from './helper'

function createLoginForEventRSVPUrl({
  eventId,
  userTimezone,
  happening
}: {
  eventId: string
  userTimezone: string
  happening: boolean
}) {
  const referer = `${window.location.pathname}${window.location.search}?rsvp=true`
  const searchParamString = makeSearchParamsString({
    forEventRSVP: 'true',
    eventId: eventId,
    userTimezone,
    happening: happening.toString(),
    referer
  })

  return `/login?${searchParamString}`
}

const JoinCTA = ({
  event,
  eventAttending,
  eventNoRsvp,
  eventIsInSession,
  rsvpCancel,
  rsvpYes,
  timezone,
  dataTestId,
  buttonSize,
  disable
}: JoinCtaProps) => {
  const history = useHistory()
  const size = getSizeClasses(buttonSize)
  const { path } = useRouteMatch()
  const ctaLocation = useMemo(() => ctaClickedLocation(path), [path])
  const { showCohortDashboard } = useFeatureFlags()
  const [showCongratsModal, setShowCongratsModal] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const { isAddToCalendarOpen, setIsAddToCalendarOpen } = useCalendarDropDown()
  const { currentUser } = useCurrentUser()
  const [isAttending, setIsAttending] = useState(eventAttending)
  const attendCTA = currentUser ? 'Attend this event' : 'Attend for free'
  const [searchParams] = useSearchParams()

  useQueryParamPresentOnLoad({
    queryParam: 'rsvp',
    onQueryParamPresentOnLoad: async () => {
      await onUserAttendEvent()
    }
  })

  const makeEventUrl = () => {
    if (event.kind === 'Cohort Event' && showCohortDashboard) {
      const cohortEvent = event.cohorts[0]
      return makeUrl(COHORT_EVENT_LINK_URL, {
        slug: cohortEvent.slug,
        eventUUID: event.uuid
      })
    } else {
      return makeUrl(EVENT_LINK_URL, { eventUUID: event.uuid })
    }
  }

  const handleMemberClickDuringEvent = async (e: any) => {
    e.stopPropagation()
    if (!isAttending) {
      await rsvpYes()
      setIsAttending(true)
    }

    history.push(makeEventUrl())
  }

  const handleAnonClickDuringEvent = () => {
    trackCtaClicked({
      cta_location: 'event_details_page',
      cta_type: 'button',
      text: 'join now',
      related_identifiers: {
        event_id: event.id,
        event_title: event.name,
        event_kind: event.kind,
        is_completed_event: event.endsAtUtc < new Date().toISOString()
      }
    })

    history.push(
      makeUrl(
        '/login',
        {},
        {
          forEventRSVP: true,
          referer: makeEventUrl(),
          eventId: event.id,
          userTimezone: getCurrentTimezone(currentUser?.timezone),
          happening: isEventInSession(event.startsAtUtc, event.endsAtUtc)
        }
      )
    )
  }

  function trackAttendClicked() {
    trackCtaClicked({
      cta_type: 'button',
      cta_location: ctaLocation,
      related_identifiers: {
        event_id: event.id,
        event_title: event.name,
        event_kind: event.kind,
        is_completed_event: event.past
      },
      text: attendCTA.toLowerCase()
    })
  }

  async function onUserAttendEvent(e: any = undefined) {
    e?.stopPropagation()
    await rsvpYes()
    setIsAttending(true)
    if (!eventIsInSession || !event.past) {
      setShowCongratsModal(true)
    }

    if (searchParams.get('rsvp') === 'upcoming_event_card') return
    trackAttendClicked()
  }

  function onAnonAttendEvent() {
    trackAttendClicked()

    window.location.href = createLoginForEventRSVPUrl({
      eventId: event.id,
      userTimezone: timezone || '',
      happening: isEventInSession(event.startsAtUtc, event.endsAtUtc)
    })
  }

  function handleRsvpCancel(e: any) {
    rsvpCancel(e)
    setIsAttending(false)
  }
  const handleClose = () => {
    setShowCongratsModal(false)
  }

  if (eventIsInSession) {
    return (
      <Button
        dataTest={dataTestId}
        className={`${size} min-w-[100px] gap-x-2 text-xs`}
        color="teal"
        size="x-small"
        onClick={currentUser ? handleMemberClickDuringEvent : handleAnonClickDuringEvent}
      >
        Join now
      </Button>
    )
  }

  return (
    <>
      {!event.past && (eventNoRsvp || !isAttending) && (
        <>
          <ReactTooltip
            className="w-64"
            backgroundColor="#4d4d4d"
            effect="solid"
            multiline={true}
          />
          <div
            data-tip={
              disable
                ? 'This is an event from a Live Program you are not enrolled in.'
                : ''
            }
          >
            <Button
              dataTest={dataTestId}
              className={cn('w-full gap-x-2 border-[1.5px] text-xs', size)}
              variant="fill"
              size="x-small"
              iconClassName="!mr-1"
              onClick={currentUser ? onUserAttendEvent : onAnonAttendEvent}
              disabled={disable}
            >
              {attendCTA}
            </Button>
          </div>
        </>
      )}

      {!event.past && isAttending && (
        <div className="relative flex items-center justify-between">
          <Popover className="relative w-full md:w-[259px]">
            <Popover.Button as="div" className="w-full">
              <Button
                dataTest={dataTestId}
                className={`${size} mr-3 h-10  w-11/12 gap-x-2 border-[1.5px] bg-rb-success-50 text-xs md:w-[259px]`}
                variant="outline"
                shape="rounded"
                color="teal"
                size="x-small"
                iconClassName="!ml-1 !w-3"
              >
                <CircleCheckIcon className="h-4 w-4 fill-current" />
                <span className="font-sans text-sm font-semibold text-rb-teal-600">
                  {"You're in!"}
                </span>
              </Button>
            </Popover.Button>
            <Popover.Panel className="absolute left-1/2 -top-16 z-10 w-[150px] -translate-x-1/2 transform rounded md:top-16 md:w-full">
              <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className="relative bg-rb-gray-50 p-0 text-rb-gray-400 shadow-event-popup"
              >
                <div
                  className="cursor-pointer bg-white py-3 pr-1.5 pl-4 text-center font-sans text-rb-gray-400 hover:bg-rb-gray-50"
                  onClick={handleRsvpCancel}
                >
                  Can&apos;t make it
                </div>
              </div>
              <span
                className={cn(
                  'absolute left-1/2 top-10 h-4 w-4 -translate-x-1/2 rotate-[225deg] transform bg-white hover:bg-rb-gray-50 md:-top-2 md:shadow-popupMenuTooltip',
                  isHovered && 'bg-rb-gray-50'
                )}
              ></span>
            </Popover.Panel>
          </Popover>

          <div
            onClick={() => setIsAddToCalendarOpen(true)}
            className="relative flex h-10 w-10 cursor-pointer items-center justify-center rounded border border-gray-300"
          >
            <SVGIcon width="16" height="16" name="events" fill="#2D2F2F" />
          </div>
          {isAddToCalendarOpen && (
            <AddToCalendarCard
              event={event}
              onClose={() => setIsAddToCalendarOpen(false)}
              className="absolute right-10 bottom-10 z-2 shadow-md md:top-10"
              sourceCtaText="calendar icon"
            />
          )}
        </div>
      )}

      {showCongratsModal && (
        <CongratsModal open={showCongratsModal} event={event} handleClose={handleClose} />
      )}
    </>
  )
}

export default JoinCTA
