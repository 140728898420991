import React, { useState } from 'react'
import Pluralize from 'react-pluralize'
import { useHistory, useLocation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import ContentBookmark from 'domains/Cms/ContentBookmark'
import ProgramStatusBadge from 'domains/CohortViewer/ProgramStatusBadge'
import EnrollInProgramCallout from 'domains/Program/EnrollInProgramCallout'

import { Tag } from 'components/Tag'
import ProgramIcon from 'components/cards/shared/ProgramIcon'
import TopProgressBar from 'components/cards/shared/TopProgressBar'
import Bookmark from 'components/icons/BookmarkIcon'
import LockIcon from 'components/icons/LockIcon'
import PlayCircleBorder from 'components/icons/PlayCircleBorderIcon'

import {
  CmsProgramCardPartsFragment,
  Maybe,
  ProgramBookmarkPartsFragment,
  Season
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { MONTH_FULL_YEAR_FORMAT, formatInTimezone } from 'utils/date'
import { onEnterKeyPress } from 'utils/keyboard'
import { getPageFromPath } from 'utils/location'
import { track, trackNavigationClicked } from 'utils/tracking/analytics'

interface FullWidthProgramCardBottomSectionProps {
  notLaunched?: boolean | null
  lessonCount: number
  numBookmarks: number
  cardLocked: boolean
  id: number | string
  navigateToBookmarks: (e: React.KeyboardEvent | React.MouseEvent) => void
  bookmarkId?: string | null
  setBookmarkId: (id?: string | null) => void
  releaseSeasonDate?: string | null
  releaseStartDate?: string | null
  showMemberContent?: boolean
  programSlug?: string
  hasPreviewableContent?: boolean
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  contentIsInMySavedItems?: boolean
}

const FullWidthProgramCardBottomSection = ({
  notLaunched,
  cardLocked,
  lessonCount,
  numBookmarks,
  navigateToBookmarks,
  bookmarkId,
  id,
  showMemberContent = false,
  programSlug = '',
  releaseSeasonDate,
  releaseStartDate,
  openAddToBookmarkFolderModal,
  contentIsInMySavedItems
}: FullWidthProgramCardBottomSectionProps) => {
  const history = useHistory()
  const navigateToProgramMaterial = (e: React.KeyboardEvent | React.MouseEvent) => {
    if (!showMemberContent) {
      e.stopPropagation()
      history.push(`/programs/${programSlug}/preview/material`)
    }
  }
  const [isInSavedItems, setIsInSavedItems] = useState<boolean>(!!contentIsInMySavedItems)

  return cardLocked ? (
    <div className="mt-3 flex flex-row items-center text-xs font-medium tracking-widest text-rb-gray-300">
      <LockIcon className="h-4 w-3 fill-current" />
      <div
        className="ml-2 uppercase"
        uk-tooltip={`title: You can access this brand new program in ${formatInTimezone(
          releaseStartDate,
          null,
          MONTH_FULL_YEAR_FORMAT
        )}.; cls: uk-active rf-tooltip-down-arrow; pos: top`}
      >
        Available {releaseSeasonDate}
      </div>
    </div>
  ) : (
    <div className="mt-3 flex flex-row items-center justify-between">
      <div className="flex flex-row">
        <div
          className="flex flex-row items-center text-xs font-medium tracking-widest text-rb-gray-300 hover:text-rb-teal-300"
          uk-tooltip="title: View Material; cls: uk-active rf-tooltip-down-arrow; pos: top"
          onClick={navigateToProgramMaterial}
          onKeyUp={onEnterKeyPress(navigateToProgramMaterial)}
          role="link"
          tabIndex={0}
        >
          <PlayCircleBorder className="h-5 w-5 fill-current" />
          <span className="ml-2 uppercase">
            <Pluralize singular={'lesson'} count={lessonCount} />
            {notLaunched && ' AVAILABLE. MORE TO COME SOON'}
          </span>
        </div>
        {showMemberContent && numBookmarks > 0 && (
          <div
            className="ml-4 flex flex-row items-center text-xs font-medium tracking-widest text-rb-gray-300 hover:text-rb-teal-300"
            uk-tooltip={`title: View ${numBookmarks} ${
              numBookmarks > 1 ? 'bookmarks' : 'bookmark'
            }; cls: uk-active rf-tooltip-down-arrow; pos: top`}
            onClick={navigateToBookmarks}
            onKeyUp={onEnterKeyPress(navigateToBookmarks)}
            data-testid="full-width-program-card-bookmarks"
            role="link"
            tabIndex={0}
          >
            <Bookmark className="h-4 w-4 stroke-current" />
            <span className="ml-2">{numBookmarks}</span>
          </div>
        )}
      </div>

      {showMemberContent && (
        <div
          className={`font-sans text-xs font-medium tracking-widest text-rb-gray-300 ${
            isInSavedItems ? 'fill-rb-gray-300' : 'fill-transparent'
          }`}
        >
          <ContentBookmark
            cmsProgramId={id}
            contentType="Program"
            bookmarkId={bookmarkId}
            isInSavedItems={isInSavedItems}
            setIsInSavedItems={setIsInSavedItems}
            openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
          />
        </div>
      )}
    </div>
  )
}

export interface FullWidthProgramCardProps {
  season?: Maybe<Season>
  lastActiveSeason?: Maybe<Season>
  program: CmsProgramCardPartsFragment
  contentHeight: number | null
  onEnrollClick: (programId: number | string) => void
  currentUserId: string
  isEnrolledInSeasonProgram: boolean
  isAUserCohortOngoing?: boolean
  canUserFinishedWithCoreCohortWeeksEnroll?: boolean
  containerRef?: any
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  containerClassName?: string
  additionalTrackingParams?: {}
  disableEnrollTooltip?: boolean
}

const FullWidthProgramCard = ({
  containerRef,
  containerClassName,
  program,
  season,
  lastActiveSeason,
  contentHeight,
  currentUserId,
  onEnrollClick,
  isEnrolledInSeasonProgram,
  isAUserCohortOngoing,
  canUserFinishedWithCoreCohortWeeksEnroll,
  openAddToBookmarkFolderModal,
  disableEnrollTooltip = false
}: FullWidthProgramCardProps) => {
  const history = useHistory()
  const [bookmarkId, setBookmarkId] = useState(program.contentBookmarkId)
  const { currentUser } = useCurrentUser()
  // Show modified content on the programs page to accepted users vs paid members.
  const showMemberContent = !!currentUser?.is.member

  const cardLocked = Boolean(program.notLaunched && program.lessonCount === 0)
  const { pathname } = useLocation()

  const handleOnClick = () => {
    if (cardLocked) {
      return null
    }

    const path = showMemberContent
      ? `/programs/${program.slug}`
      : `/programs/${program.slug}/preview`

    if (!pathname.includes('/explore')) {
      trackNavigationClicked({
        location: 'programs_page',
        type: 'program_card',
        destination: `https://reforge.com${path}`,
        text: program?.name,
        related_identifiers: { programId: program.id }
      })
    }

    history.push(path)
  }

  const navigateToBookmarks = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation()
    history.push(`/programs/${program.slug}/bookmarks`)
  }

  const canEnrollToProgram = () => {
    // Prevent users from enrolling in Growth for founders
    return (
      currentUser?.can.enrollInCohort &&
      season?.openForEnrollment &&
      !isAUserCohortOngoing &&
      canUserFinishedWithCoreCohortWeeksEnroll &&
      !program.deprecatedAt &&
      program.canEnroll &&
      !currentUser?.is.enrolledInActiveCourse &&
      !isEnrolledInSeasonProgram
    )
  }

  const trackEnrollmentPopover = () => {
    if (season?.openForEnrollment) {
      // @ts-ignore - 'Shown Enroll Hover On Program Card' event is not defined in Segment JIRA#REF-5159
      track('Shown Enroll Hover On Program Card', {
        source_flow: 'Program Card on Program Index Page',
        location: getPageFromPath(),
        season_name: `${season.year}-${season.name}`,
        user_id: currentUserId,
        cms_program_id: program.id
      })
    }
  }

  const isProgramOnDemand = () => {
    if (isAUserCohortOngoing || !canUserFinishedWithCoreCohortWeeksEnroll) {
      return !program.canEnrollForCurrentSeason
    }

    if (currentUser?.is.premember) {
      return false
    }

    return !program.canEnroll
  }

  return (
    <EnrollInProgramCallout
      containerRef={containerRef}
      data-test={`program-enroll-callout-${program.code}`}
      onPopoverDisplayed={trackEnrollmentPopover}
      isDisabled={disableEnrollTooltip || !canEnrollToProgram()}
      onEnrollClick={onEnrollClick}
      season={season}
      cmsProgramId={program.id}
      showMemberContent={showMemberContent}
    >
      <div
        className={twMerge(
          'full-width-card rf-rb-card-interactive flex flex-col',
          cardLocked && 'cursor-pointer',
          containerClassName
        )}
        onClick={handleOnClick}
        onKeyUp={onEnterKeyPress(handleOnClick)}
        tabIndex={-1}
        role="link"
        data-test="program-page-link"
      >
        {showMemberContent && (
          <div id="top-progress-bar">
            <TopProgressBar progressPercent={program.progressPercent} />
          </div>
        )}

        <div
          data-test={`program-card-${program.id}`}
          className="flex flex-col pb-5 pr-5 sm:flex-row"
        >
          <div className="mx-4 mt-3 flex flex-row items-center justify-between sm:items-start">
            <ProgramIcon
              height={30}
              width={30}
              iconImageUrl={program.iconImageUrl || ''}
            />

            <div className="ml-2 flex items-center sm:hidden">
              {currentUser?.is.premember &&
                program.hasPreviewableContent &&
                !cardLocked && (
                  <Tag
                    variant="teal"
                    text="Preview Available"
                    dataTest="preview-available-tag"
                  />
                )}
              <ProgramStatusBadge
                progressPercent={program.progressPercent}
                notLaunched={program.notLaunched}
                deprecated={Boolean(program.deprecatedAt)}
                isRecommended={!!program.recommendationRanking}
                isProgramOnDemand={isProgramOnDemand()}
                launchAt={program.launchAt}
                upcomingSeason={season}
                lastActiveSeason={lastActiveSeason}
                programName={program.name}
              />
            </div>
          </div>
          <div className="flex w-full flex-col pt-2 pl-4 sm:pt-4 sm:pl-0">
            <div data-test="badge" className="flex flex-row justify-between">
              <div className="text-lg font-medium">{program.name}</div>
              <div
                className={`hidden items-center sm:flex ${
                  program.notLaunched || Boolean(program.deprecatedAt) ? 'ml-auto' : ''
                }`}
              >
                {currentUser?.is.premember &&
                  program.hasPreviewableContent &&
                  !cardLocked && (
                    <Tag
                      variant="teal"
                      text="Preview Available"
                      dataTest="preview-available-tag"
                    />
                  )}
                <ProgramStatusBadge
                  progressPercent={program.progressPercent}
                  notLaunched={program.notLaunched}
                  deprecated={Boolean(program.deprecatedAt)}
                  isRecommended={!!program.recommendationRanking}
                  isProgramOnDemand={isProgramOnDemand()}
                  launchAt={program.launchAt}
                  upcomingSeason={season}
                  lastActiveSeason={lastActiveSeason}
                  programName={program.name}
                />
              </div>
            </div>
            <div
              data-testid="program-card-synopsis"
              className="full-width-program-card-synopsis xl:pr-36"
              style={{ minHeight: `${contentHeight}px` }}
            >
              {program.synopsis}
            </div>
            <FullWidthProgramCardBottomSection
              notLaunched={program.notLaunched}
              cardLocked={cardLocked}
              numBookmarks={program.numBookmarks || 0}
              navigateToBookmarks={navigateToBookmarks}
              bookmarkId={bookmarkId}
              id={program.id}
              setBookmarkId={setBookmarkId}
              showMemberContent={showMemberContent}
              programSlug={program.slug}
              releaseSeasonDate={program.releaseSeasonDate}
              releaseStartDate={program.releaseStartDate}
              lessonCount={program.lessonCount}
              openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
              contentIsInMySavedItems={!!program.contentIsInMySavedItems}
            />
          </div>
        </div>
      </div>
    </EnrollInProgramCallout>
  )
}

export default FullWidthProgramCard
