import { useState } from 'react'
import ReactDOM from 'react-dom'

import ContentBookmark from 'domains/Cms/ContentBookmark'

import { CmsSectionContentType, Maybe } from 'gql'

interface IHeaderBookmark {
  cmsContentId?: string
  cmsModuleId?: string
  cmsProgramId: Maybe<string>
  contentType: CmsSectionContentType
  contentBookmarkId?: Maybe<string>
  contentIsInMySavedItems: boolean
}

export default function HeaderBookmark({
  cmsContentId,
  cmsModuleId,
  cmsProgramId,
  contentType,
  contentBookmarkId,
  contentIsInMySavedItems
}: IHeaderBookmark) {
  const [isInSavedItems, setIsInSavedItems] = useState(contentIsInMySavedItems)

  const contentBookmarkAnchor = `content-header-bookmark-${cmsContentId}`
  const contentBookmarkAnchorElement = document.getElementById(contentBookmarkAnchor)

  const container = document.getElementById(contentBookmarkAnchor)

  if (!container) {
    return null
  }

  return (
    contentBookmarkAnchorElement &&
    ReactDOM.createPortal(
      <ContentBookmark
        cmsContentId={cmsContentId}
        cmsModuleId={cmsModuleId}
        cmsProgramId={cmsProgramId}
        contentType={contentType}
        bookmarkId={contentBookmarkId}
        isInSavedItems={isInSavedItems}
        setIsInSavedItems={setIsInSavedItems}
      />,
      contentBookmarkAnchorElement
    )
  )
}
