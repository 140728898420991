import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import BaseBreadcrumbLink from 'components/Breadcrumbs/BaseBreadcrumbs/Link'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import useMediaQuery from 'hooks/useMediaQuery'

export type BreadcrumbsPageType = {
  title: string
  path: string
}

export interface BaseBreadcrumbProps {
  breadcrumbPages: BreadcrumbsPageType[]
  className?: string
  oneLevel?: boolean
  backIcon?: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

const addReforgeBreadcrumb = (
  breadcrumbPages: BreadcrumbsPageType[],
  isMobile: boolean,
  oneLevel: boolean = false
) => {
  const breadcrumbPagesCopy = [...breadcrumbPages]

  if (isMobile && breadcrumbPagesCopy.length === 2) {
    const lastBreadcrumb = breadcrumbPagesCopy.pop()
    breadcrumbPagesCopy.length = 0 // This clears the array
    if (lastBreadcrumb) {
      breadcrumbPagesCopy.push({ title: 'reforge', path: '/' }, lastBreadcrumb)
    }
  } else if (
    !oneLevel &&
    (breadcrumbPagesCopy.length === 0 ||
      breadcrumbPagesCopy[0].title !== 'reforge' ||
      breadcrumbPagesCopy[0].path !== '/')
  ) {
    breadcrumbPagesCopy.unshift({ title: 'reforge', path: '/' })
  }

  return breadcrumbPagesCopy
}

const addEllipsisBreadcrumb = (
  breadcrumbPages: BreadcrumbsPageType[],
  isMobile: boolean
) => {
  if (!isMobile && breadcrumbPages.length >= 5) {
    breadcrumbPages = [
      breadcrumbPages[0],
      { path: '', title: '...' },
      ...breadcrumbPages.slice(-3)
    ]
  }
  return breadcrumbPages
}

const getPreviousBreadcrumb = (
  breadcrumbPages: BreadcrumbsPageType[],
  oneLevel: boolean
) => {
  return oneLevel
    ? breadcrumbPages[0]
    : breadcrumbPages.length > 2
      ? breadcrumbPages[breadcrumbPages.length - 2]
      : null
}

export const BaseBreadcrumbs = ({
  breadcrumbPages,
  className,
  oneLevel = false,
  backIcon,
  onClick = () => {}
}: BaseBreadcrumbProps) => {
  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)

  breadcrumbPages = addReforgeBreadcrumb(breadcrumbPages, isMobile, oneLevel)
  breadcrumbPages = addEllipsisBreadcrumb(breadcrumbPages, isMobile)
  const previous = getPreviousBreadcrumb(breadcrumbPages, oneLevel)
  const location = useLocation()
  const isEventSubpage = ['/upcoming', '/recording'].some((path) =>
    location.pathname.includes(path)
  )

  return (
    <div className={twMerge('flex w-full items-start bg-rb-white', className)}>
      {oneLevel && !isMobile ? (
        <div className={'flex items-center md:h-12'}>
          <Link to={previous?.path || '/'} onClick={onClick}>
            <div className={'flex items-center font-normal text-rb-black'}>
              <span>{backIcon}</span>
              Back to {breadcrumbPages[0].title}
            </div>
          </Link>
        </div>
      ) : isMobile && (oneLevel || breadcrumbPages.length >= 3) && previous ? (
        <div className={'flex items-center md:h-12'}>
          <Link to={previous.path} onClick={onClick}>
            <div className={'flex items-center font-normal text-rb-black'}>
              {backIcon}
              {isEventSubpage ? `Back to ${previous.title}` : previous.title}
            </div>
          </Link>
        </div>
      ) : (
        <ul className="flex list-none items-center pl-0">
          {breadcrumbPages.map((breadcrumbPage, index) => {
            return (
              <li key={index}>
                <BaseBreadcrumbLink
                  hierarchialEntry={breadcrumbPage}
                  isLast={breadcrumbPages.length - 1 === index}
                />
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default BaseBreadcrumbs
