import { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import Image from 'domains/Sanity/Image'
import { ComplexPortableText } from 'domains/Sanity/PortableText/ComplexPortableText/ComplexPortableText'

import { Podcast } from 'gql'

import useIntersection from 'hooks/useIntersection'

import ButtonMarketingCTA from '../ButtonMarketingCTA'

interface PodcastCardProps extends Podcast {
  isChromatic?: boolean
}

const PodcastCard = ({
  summaryImage,
  summaryText,
  title,
  slug,
  isChromatic = false
}: PodcastCardProps) => {
  const cardRef = useRef<HTMLDivElement | null>(null)
  const isVisible = useIntersection(cardRef, '0px')
  const hasAnimated = useRef(false)

  useEffect(() => {
    if (isVisible) {
      hasAnimated.current = true
    }
  }, [isVisible, hasAnimated])

  const podcastUrl = `/podcast/unsolicited-feedback/${slug}`

  return (
    <article
      className={twMerge(
        'mb-[75px] flex flex-col items-center justify-center transition-all duration-1000 ease-in-out md:flex-row',
        !isVisible && !hasAnimated.current && !isChromatic
          ? '-translate-y-20 opacity-0'
          : '',
        isVisible || hasAnimated.current || isChromatic ? 'translate-y-0 opacity-100' : ''
      )}
      ref={cardRef}
    >
      <section className="mb-[5vw] flex w-full md:mb-0 md:w-1/2 md:pr-[2%]">
        <Link to={podcastUrl} className="relative aspect-[16/9] w-full">
          <Image
            src={summaryImage?.imageUrl || ''}
            alt={summaryImage?.imageAlt || ''}
            fill
            className="object-cover object-center"
          />
        </Link>
      </section>

      <section className="flex flex-col md:w-1/2 md:pl-[2%]">
        <Link
          to={podcastUrl}
          className="mb-[16px] font-normal tracking-tighter text-rb-black no-underline fluid-text-3xl hover:no-underline"
        >
          {title}
        </Link>

        {summaryText && (
          <div className="block-content">
            <ComplexPortableText content={summaryText} />
          </div>
        )}

        <ButtonMarketingCTA variant="text" size="small" href={podcastUrl}>
          Read More ↗
        </ButtonMarketingCTA>
      </section>
    </article>
  )
}
export default PodcastCard
