import Button from 'components/Button'
import RfHeader2 from 'components/typography/RfHeader/RfHeader2'
import RfHeader3 from 'components/typography/RfHeader/RfHeader3'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { trackCtaClicked } from 'utils/tracking/analytics'
import { PURCHASE_FLOW_URL } from 'utils/url'

export const UpgradeHeroCard = () => {
  const handleSelectPlan = () => {
    trackCtaClicked({
      cta_location: 'premember_hero_card',
      cta_type: 'button',
      destination: PURCHASE_FLOW_URL,
      text: 'select plan'
    })
  }

  return (
    <section className="mx-auto mb-20 max-w-6xl rounded-lg border border-gray-300 bg-[url(/app/assets/images/bg-premember-hero-card.svg)] bg-cover bg-center bg-no-repeat px-4 py-8 transition-all duration-200 hover:shadow-default sm:p-10 lg:p-20">
      <RfHeader2>Level Up Your Work with Unlimited Access</RfHeader2>
      <RfHeader3 className="!mb-12 !font-medium">
        A Reforge membership offers access to all on-demand courses and actionable
        frameworks pulled from the hard-earned insights of the best operators in tech.
      </RfHeader3>

      <div className="mb-12 flex flex-col gap-4">
        <div>
          <RfHeader3 className="!mb-1 !font-semibold">
            Unlock step-change growth in your career
          </RfHeader3>
          <RfParagraphSmall className="!text-rb-gray-300">
            Make the leap from product manager to product leader, or from beginner to
            growth expert
          </RfParagraphSmall>
        </div>
        <div>
          <RfHeader3 className="!mb-1 !font-semibold">
            Level up on new skills or topics
          </RfHeader3>
          <RfParagraphSmall className="!text-rb-gray-300">
            From learning about experimentation or strategy, to advanced approaches on
            retention & engagement
          </RfParagraphSmall>
        </div>
        <div>
          <RfHeader3 className="!mb-1 !font-semibold">
            Solve your toughest work challenges
          </RfHeader3>
          <RfParagraphSmall className="!text-rb-gray-300">
            Learn how to analyze your newest feature, improve your activation flow, grow
            your audiece, and more
          </RfParagraphSmall>
        </div>
      </div>

      <Button
        href={PURCHASE_FLOW_URL}
        shape="rounded-full"
        size="medium"
        className="min-w-[230px]"
        onClick={handleSelectPlan}
        dataTest="premember-dashboard-hero-cta"
      >
        Select plan
      </Button>
    </section>
  )
}

export default UpgradeHeroCard
