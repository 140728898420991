import { Link } from 'react-router-dom'

import SVGIcon from 'components/Icon'

import { getStatusData } from './utils'

export enum StatusTypes {
  // Team statuses
  TEAM_REQUEST_DECLINED = 'team-request-declined',
  TEAM_REQUEST_ALREADY_DECLINED = 'team-request-already-declined',
  TEAM_REQUEST_ALREADY_APPROVED = 'team-request-already-approved',
  // Subscription statuses
  SUBSCRIPTION_ALREADY_ADDED = 'subscription-already-added',
  SUBSCRIPTION_ALREADY_ACTIVE = 'subscription-already-active',
  // Patronage statuses
  PATRONAGE_USER_NOT_FOUND = 'patronage-user-not-found',
  PATRONAGE_USER_NOT_ACCEPTED = 'patronage-user-not-accepted'
}

export interface StatusProps {
  statusType: StatusTypes
  email?: string
  name?: string
}

export default function Status({ statusType, email, name }: StatusProps) {
  const { iconName, headerCopy, subHeaderCopy, shouldShowLink } = getStatusData({
    statusType,
    email,
    name
  })
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <SVGIcon name={iconName} />
      <h2 className="mt-10 text-center text-xl font-semibold sm:text-2xl">
        {headerCopy}
      </h2>
      {subHeaderCopy ? (
        <h3 className="mt-4 max-w-xs text-center text-sm font-normal sm:max-w-sm sm:text-base">
          {subHeaderCopy}
        </h3>
      ) : (
        ''
      )}
      {shouldShowLink ? (
        <Link className="mt-4 text-sm sm:text-base" to="/account">
          Go to my account
        </Link>
      ) : (
        ''
      )}
    </div>
  )
}
