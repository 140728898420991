import { includes } from 'lodash'
import { useState } from 'react'

import Callout from 'components/Callout'
import { getUserCoursesForCourseLinks } from 'components/LeftSidebar/CourseLinkSection/CourseLinkSection'

import { CurrentUserPartsFragment, useUpdateUserCourseMutation } from 'gql'

import { isFirstPilotCourseSession } from 'utils/courseUtils'

interface PostCourseCalloutProps {
  userCourseId: string
  courseName: string
}

export const shouldShowPostCourseCallout = (user?: CurrentUserPartsFragment | null) => {
  const userCoursesForCourseLinkSection = getUserCoursesForCourseLinks(user)

  const mostRecentPastUserCourse = user?.userCourses?.last

  const mostRecentPastUserCourseInCourseLinkSection = includes(
    userCoursesForCourseLinkSection.map((uc) => uc.id),
    mostRecentPastUserCourse?.id
  )

  const showPostCourseCallout =
    mostRecentPastUserCourse &&
    !isFirstPilotCourseSession(mostRecentPastUserCourse.courseSession) &&
    !mostRecentPastUserCourse?.hasDismissedPostCourseCallout &&
    !mostRecentPastUserCourseInCourseLinkSection

  return showPostCourseCallout
}

const PostCourseCallout = ({ userCourseId, courseName }: PostCourseCalloutProps) => {
  const [isShown, setIsShown] = useState(true)
  const [updateUserCourse] = useUpdateUserCourseMutation()

  if (!isShown) {
    return null
  }

  const onClose = () => {
    setIsShown(false)

    updateUserCourse({
      variables: {
        input: {
          id: userCourseId,
          hasDismissedPostCourseCallout: true
        }
      }
    })
  }

  return (
    <div id="post-course-callout" className="absolute z-[2] w-full max-w-[300px]">
      <Callout
        title={courseName}
        data-test="post-course-callout"
        className="rf-tooltip-caret rf-tooltip-caret-top-left whitespace-normal bg-white text-rb-gray-400"
        closeCallback={onClose}
      >
        <div className="text-sm">
          Your live course material can now be found in Courses.
        </div>
      </Callout>
    </div>
  )
}

export default PostCourseCallout
