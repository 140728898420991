import { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

type useQueryParamCallOptions = {
  replace?: boolean
}

export function useQueryParams() {
  const { search } = useLocation()
  const history = useHistory()
  const queryParams = useMemo(() => new URLSearchParams(search), [search])

  const setQueryParams = (params: any, options?: useQueryParamCallOptions) => {
    if (options?.replace) {
      history.replace({ search: params.toString() })
      return
    }

    history.push({ search: params.toString() })
  }

  const setNewParams = (
    params: { [key: string]: string },
    options?: useQueryParamCallOptions
  ) => {
    const newQueryParams = new URLSearchParams()

    Object.keys(params).forEach((key) => {
      newQueryParams.set(key, params[key])
    })

    if (options?.replace) {
      history.replace({ search: newQueryParams.toString() })
      return
    }

    history.push({ search: newQueryParams.toString() })
  }

  const deleteQueryParam = (paramKey: string, options?: useQueryParamCallOptions) => {
    if (queryParams.has(paramKey)) {
      queryParams.delete(paramKey)
      setQueryParams(queryParams, options)
    }
  }

  return { queryParams, setNewParams, deleteQueryParam }
}
