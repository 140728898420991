import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import UIkit from 'uikit'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'

import {
  useAddEmailToUserMutation,
  useUpdateProjectDeliverableMutation,
  useUpdateUserMutation
} from 'gql'

import { onEnterKeyPress } from 'utils/keyboard'

const emailErrorMsg = 'Please enter a valid email address'
const emailShareErrorMsg =
  'Error sharing project with selected email. Please be sure it is a Google account.'
const emailValidateRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

interface UserProjectEmailProps {
  emails?: string[]
  cmsDeliverableId: string
  cmsDeliverableUrl: string | null | undefined
  cmsDeliverableLoading: boolean
  projectEmail?: string | null
}

const UserProjectEmail = (props: UserProjectEmailProps) => {
  const { projectEmail, cmsDeliverableId, cmsDeliverableUrl, cmsDeliverableLoading } =
    props

  const [showAddEmail, setShowAddEmail] = useState(false)
  const [selectedIdx, setSelectedIdx] = useState<number | undefined>(undefined)
  const [disabled, setDisabled] = useState(true)
  const [emailError, setEmailError] = useState<string | undefined>(undefined)
  const [done, setDone] = useState(false)
  const [emails, setEmails] = useState(props.emails || [])
  const emailAddInRef = useRef<HTMLInputElement>(null)
  const [updateProjectDeliverable] = useUpdateProjectDeliverableMutation()
  const [updateUser, { loading: updateUserLoading }] = useUpdateUserMutation()
  const [addEmailToUser, { loading: addEmailToUserLoading, error: addEmailToUserError }] =
    useAddEmailToUserMutation()

  useEffect(() => {
    if (addEmailToUserError) {
      setEmailError(addEmailToUserError.message)
    }
  }, [addEmailToUserError])

  useEffect(() => {
    emails?.forEach((email, idx) => {
      if (email === projectEmail) {
        setSelectedIdx(idx)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectEmail])

  useEffect(() => {
    if (emailAddInRef.current) {
      emailAddInRef.current.select()
    }
  }, [showAddEmail])

  useEffect(() => {
    if (done) {
      if (cmsDeliverableUrl === '#') {
        setDone(false)
        setEmailError(emailShareErrorMsg)
        return
      }
      if (cmsDeliverableUrl) {
        UIkit.modal('#project-email-modal').hide()
        window.open(cmsDeliverableUrl)
        window.location.reload()
      }
    }
  }, [done, cmsDeliverableUrl])

  const onCancelClicked = () => {
    setSelectedIdx(undefined)
    setEmailError(undefined)
    if (emailAddInRef.current) {
      emailAddInRef.current.value = ''
    }
  }

  const clearPlaceholderDeliverable = async () => {
    const { errors } = await updateProjectDeliverable({
      variables: {
        input: {
          id: cmsDeliverableId,
          url: null
        }
      }
    })
    if (errors?.length) {
      setEmailError('Error creating your project')
    } else {
      setDone(true)
    }
  }

  const saveProjectEmailForUser = async (email: string) => {
    const { errors } = await updateUser({
      variables: {
        input: {
          projectEmail: email
        }
      }
    })
    if (errors?.length) {
      setEmailError('Error setting project email')
    } else {
      clearPlaceholderDeliverable()
    }
  }

  const addAndSaveProjectEmailForUser = async (email: string) => {
    await addEmailToUser({
      variables: {
        input: {
          email
        }
      }
    })
    if (emailAddInRef.current) {
      emailAddInRef.current.value = ''
    }
    setEmails([...emails, email])
    saveProjectEmailForUser(email)
    setEmailError(undefined)
  }

  const saveSelectedEmail = () => {
    let selectedEmail
    if (selectedIdx === undefined) {
      if (emailAddInRef.current) {
        selectedEmail = emailAddInRef.current.value.trim()
        if (!emailValidateRegex.test(selectedEmail)) {
          setEmailError(emailErrorMsg)
          setDisabled(true)
          return
        }
        addAndSaveProjectEmailForUser(selectedEmail)
      }
    } else if (emails && selectedIdx < emails.length) {
      selectedEmail = emails[selectedIdx]
      saveProjectEmailForUser(selectedEmail)
    }
  }

  const emailSelected = (idx: number) => {
    setSelectedIdx(idx)
    setDisabled(false)
    setEmailError(undefined)
  }

  const showAddEmailClicked = () => {
    setSelectedIdx(undefined)
    setShowAddEmail(true)
  }

  const inClicked = () => {
    setSelectedIdx(undefined)
    if (emailError === undefined) {
      setDisabled(!emailAddInRef.current || emailAddInRef.current.value.trim() === '')
    }
  }

  const inChanged = () => {
    const email = emailAddInRef.current ? emailAddInRef.current.value.trim() : null

    if (email) {
      setDisabled(true)
    } else if (disabled) {
      setDisabled(false)
    }
    if (emailError) {
      setEmailError(undefined)
    }
  }

  return (
    <div className="project-email p-2.5 xs:p-4 md:p-8">
      {done && (
        <div className="text-center text-xl text-rb-gray-400">
          <span className="mt-12 mb-3" uk-icon="icon: check; ratio: 3" />
          {selectedIdx === undefined && (
            <div>
              Your email address for Reforge Projects has been added. Creating project...
            </div>
          )}
          {selectedIdx !== undefined && (
            <div>
              Your email address for Reforge Projects has been set. Creating project...
            </div>
          )}
        </div>
      )}

      {!done && (
        <>
          <div className="text-xl font-bold leading-6 text-black">
            All of your work in one place
          </div>
          <div className="mt-2 mb-4 text-base font-normal leading-6">
            Select or add a Google email address to use for Reforge Projects (Access to
            Google Sheets required).
          </div>
          <div>
            <table className="uk-table uk-table-striped uk-overflow-auto mb-0">
              <tbody>
                {emails &&
                  emails.map((email, idx) => (
                    <tr key={`email${email}`} className="h-9 !border-t-0 !border-b-0">
                      <td
                        onClick={() => {
                          emailSelected(idx)
                        }}
                        className={clsx(
                          'px-3 pt-2.5 pb-1 text-sm text-black',
                          selectedIdx === idx &&
                            'rounded-sm border-1.5 border-rb-blue-100'
                        )}
                      >
                        {email}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            {!showAddEmail && (
              <a
                onClick={showAddEmailClicked}
                className="uk-link-reset mt-5 flex items-center text-xs font-medium uppercase tracking-widest"
              >
                <SVGIcon
                  name="plus-in-square"
                  fill="#000"
                  className="uk-margin-small-left mr-1 stroke-current"
                />
                Add email address
              </a>
            )}
            {showAddEmail && (
              <>
                <input
                  ref={emailAddInRef}
                  style={{
                    background: emails && emails.length % 2 === 0 ? '#f4f4f4' : '#fff'
                  }}
                  onClick={inClicked}
                  onChange={inChanged}
                  onKeyUp={onEnterKeyPress(saveSelectedEmail)}
                  className={twMerge(
                    'h-9 w-full border-none p-0 pl-3 placeholder:text-rb-gray-300 placeholder-shown:text-rb-gray-300 focus:outline-none',
                    selectedIdx === undefined &&
                      'rounded-sm border-1.5 border-rb-blue-100'
                  )}
                  type="text"
                  id="new-email"
                  name="new-email"
                  placeholder="example@gmail.com"
                />
              </>
            )}
            {emailError && (
              <div className="pt-2 pl-3 text-sm text-rb-warning-200">{emailError}</div>
            )}
          </div>
          <div className="mt-5 flex items-center justify-end">
            <Button
              onClick={onCancelClicked}
              variant="text-only"
              size="small"
              className="uk-modal-close"
            >
              Cancel
            </Button>

            <Button
              onClick={saveSelectedEmail}
              disabled={disabled}
              isLoadingSpinner={
                cmsDeliverableLoading || addEmailToUserLoading || updateUserLoading
              }
              size="small"
            >
              Use This Email
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default UserProjectEmail
