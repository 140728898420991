/* eslint-disable camelcase */
import { Reference, StoreObject } from '@apollo/client'

import { useCohortConversationTrackingContext } from 'domains/CohortConversation/hooks/useCohortConversationsTrackingContext'

import {
  CohortPostPartsFragment,
  CohortPostReactionPartsFragmentDoc,
  InlinePost,
  PostShowDocument,
  PostShowFieldsFragment,
  PostTopic,
  ReactionKind,
  ReplyParent,
  useAddReactionMutation,
  useCreateReplyMutation,
  useRemoveReactionMutation
} from 'gql'

import { trackReactAction } from 'utils/tracking/analytics'

interface GroupType {
  id: string
  title: string
  slug: string
}

interface TrackPayloadType {
  action: string
  group_ids: Array<string>
  topic_ids: Array<string>
  post_id?: string
  reply_id?: string
  cms_program_id: string
  season_id: string
  cohort_id: string
}
interface CreateReactionProps {
  kind: ReactionKind
  reactableId: string
  reactableType: string
  isFromCohort?: boolean
  reactablePost?: PostShowFieldsFragment | CohortPostPartsFragment
  reactableInlinePost?: InlinePost
}

const usePost = () => {
  const [addReaction] = useAddReactionMutation({ refetchQueries: [PostShowDocument] })
  const [removeReaction] = useRemoveReactionMutation({
    refetchQueries: [PostShowDocument]
  })
  const [createReplyMutation] = useCreateReplyMutation()
  const { cohort } = useCohortConversationTrackingContext()

  const createReaction = async ({
    kind,
    reactableId,
    reactableType,
    reactablePost,
    reactableInlinePost,
    isFromCohort = false
  }: CreateReactionProps) => {
    const { data, errors } = await addReaction({
      variables: {
        input: {
          kind,
          reactableId,
          reactableType
        }
      },
      update(cache, { data, errors }) {
        if (!errors && isFromCohort) {
          if (reactableType === 'Post') {
            cache.modify({
              id: `Post:${reactableId}`,
              fields: {
                reactions(existingReactions) {
                  const newReactionRef = cache.writeFragment({
                    data: data?.addReaction?.reaction,
                    fragment: CohortPostReactionPartsFragmentDoc,
                    fragmentName: 'CohortPostReactionParts'
                  })
                  return [...existingReactions, newReactionRef]
                }
              },
              broadcast: false
            })
          } else {
            cache.modify({
              id: `Reply:${reactableId}`,
              fields: {
                reactions(existingReactions) {
                  const newReactionRef = cache.writeFragment({
                    data: data?.addReaction?.reaction,
                    fragment: CohortPostReactionPartsFragmentDoc,
                    fragmentName: 'CohortPostReactionParts'
                  })
                  return [...existingReactions, newReactionRef]
                }
              },
              broadcast: false
            })
          }
        }
      }
    })

    if (!errors) {
      const trackPayload: TrackPayloadType = {
        action: 'create',
        group_ids: reactableInlinePost
          ? []
          : reactablePost?.groups.map((group: GroupType) => group.id) ?? [],
        topic_ids: reactableInlinePost
          ? []
          : reactablePost?.topics.map((topic: PostTopic) => topic.id) ?? [],
        cms_program_id: isFromCohort ? cohort?.cmsProgram?.id : '',
        season_id: isFromCohort ? cohort?.cmsProgram?.id : '',
        cohort_id: isFromCohort ? cohort?.id : ''
      }
      reactableType === 'Post'
        ? (trackPayload.post_id = reactableId)
        : (trackPayload.reply_id = reactableId)

      trackReactAction(trackPayload)

      if (data && data.addReaction && data.addReaction.reaction) {
        return data.addReaction.reaction
      }
    }
  }

  const destroyReaction = async (
    id: string,
    reactablePost?: PostShowFieldsFragment | CohortPostPartsFragment,
    reactableInlinePost?: InlinePost,
    reactableId?: string,
    reactableType?: string,
    isFromCohort = false
  ) => {
    const { data, errors } = await removeReaction({
      variables: {
        input: { id }
      },
      update(cache, { errors }) {
        if (!errors && reactableType && reactableId) {
          if (reactableType === 'Post') {
            cache.modify({
              id: `Post:${reactableId}`,
              fields: {
                reactions(existingReactions) {
                  return existingReactions.filter(
                    (reactionRef: Reference | StoreObject | undefined) => {
                      return `Reaction:${id}` !== reactionRef?.__ref
                    }
                  )
                }
              },
              broadcast: false
            })
          } else {
            cache.modify({
              id: `Reply:${reactableId}`,
              fields: {
                reactions(existingReactions) {
                  return existingReactions.filter(
                    (reactionRef: Reference | StoreObject | undefined) => {
                      return `Reaction:${id}` !== reactionRef?.__ref
                    }
                  )
                }
              },
              broadcast: false
            })
          }
        }
      }
    })

    if (!errors) {
      const trackPayload: TrackPayloadType = {
        action: 'delete',
        group_ids: reactableInlinePost
          ? []
          : reactablePost?.groups.map((group: GroupType) => group.id) ?? [],
        topic_ids: reactableInlinePost
          ? []
          : reactablePost?.topics.map((topic: PostTopic) => topic.id) ?? [],
        cms_program_id: isFromCohort ? cohort?.cmsProgram?.id : '',
        season_id: isFromCohort ? cohort?.cmsProgram?.id : '',
        cohort_id: isFromCohort ? cohort?.id : ''
      }
      reactableType === 'Post'
        ? (trackPayload.post_id = reactableId)
        : (trackPayload.reply_id = reactableId)

      trackReactAction(trackPayload)

      return data?.removeReaction?.id
    }
  }

  const createTeamReply = async ({ id, body }: { id: string; body: string }) => {
    return createBaseReply({ id, body, parentType: ReplyParent.TEAM })
  }

  const createCommunityReply = async ({ id, body }: { id: string; body: string }) => {
    return createBaseReply({ id, body, parentType: ReplyParent.COMMUNITY })
  }

  const createBaseReply = async ({
    id,
    parentType,
    body
  }: {
    id: string
    parentType: ReplyParent
    body: string
  }) => {
    const { data, errors } = await createReplyMutation({
      variables: { input: { id, parentType, body, substituteNewlines: true } }
    })

    if (!errors) {
      return data?.createReply
    }
  }

  return {
    createReaction,
    destroyReaction,
    createCommunityReply,
    createTeamReply
  }
}

export default usePost
