import { useCreateCohortReplyMutation } from 'gql'

import { trackReplyAction } from 'utils/tracking/analytics'

import { useCohortConversationTrackingContext } from './useCohortConversationsTrackingContext'

export interface CohortReplyCreateProps {
  body: string
  replyToId: string // can be post or reply id
  replyToType?: string
}

export const useCreateCohortReply = () => {
  const [createReplyMutation] = useCreateCohortReplyMutation()
  const { cohort, topicId, groupId } = useCohortConversationTrackingContext()

  const createCohortReply = async ({
    body,
    replyToId,
    replyToType = 'post'
  }: CohortReplyCreateProps) => {
    const response = await createReplyMutation({
      variables: { input: { cohortGroupId: groupId, body, id: replyToId } }
    })

    const replyId = response?.data?.createCohortReply?.id

    if (replyId) {
      trackReplyAction({
        action: 'create',
        cms_program_id: cohort?.cmsProgram?.id,
        cohort_id: cohort?.id,
        group_ids: [groupId],
        reply_id: replyId,
        reply_to_id: replyToId,
        reply_to_type: replyToType,
        season_id: cohort?.season?.id,
        topic_ids: [topicId]
      })
    }
  }
  return { createCohortReply }
}

export default useCreateCohortReply
