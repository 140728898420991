import produce from 'immer'
import { useState } from 'react'

import useHandleAddToFolder from 'domains/Collections/hooks/useHandleAddToFolder'

import Button from 'components/Button'
import Modal, {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle
} from 'components/Modal'
import { ToggleSwitch } from 'components/ToggleSwitch'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'

import {
  BookmarkFolderPartsFragment,
  BookmarkFoldersDocument,
  BookmarkFoldersQuery,
  CourseBookmarkPartsFragment,
  ProgramBookmarkPartsFragment,
  useCreateBookmarkFolderMutation,
  useShareBookmarkFolderMutation
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import notifyError from 'utils/errorNotifier'
import { onEnterKeyPress } from 'utils/keyboard'
import { displayErrorToast, displaySuccessToast } from 'utils/toastService'
import {
  trackBookmarkAction,
  trackCollectionAction,
  trackModalDismissed
} from 'utils/tracking/analytics'

interface CreateBookmarkFolderCardProps {
  successCallback?: (folder: BookmarkFolderPartsFragment) => void
  isModalOpen: boolean
  handleClose: () => void
  currentBookmarkForDropdown?:
    | ProgramBookmarkPartsFragment
    | CourseBookmarkPartsFragment
    | null
  trackingTriggerAction?: string
}

const CreateBookmarkFolderModal = ({
  successCallback,
  isModalOpen,
  handleClose,
  currentBookmarkForDropdown,
  trackingTriggerAction
}: CreateBookmarkFolderCardProps) => {
  const [name, setName] = useState('')
  const [desc, setDesc] = useState('')
  const { currentUser } = useCurrentUser()

  const [isShared, setIsShared] = useState(false)
  const { AddBookmarkToFolder } = useHandleAddToFolder({})
  const handleAddToFolder = async (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => {
    const { errors, data } = await AddBookmarkToFolder({
      variables: {
        input: {
          bookmarkId: bookmarkId,
          bookmarkFolderId: bookmarkFolder.id
        }
      }
    })

    if (errors) {
      notifyError(errors)
      displayErrorToast({
        message: `There was a problem saving your bookmark to ${bookmarkFolder.name}`
      })
      return null
    } else {
      trackBookmarkAction({
        action: 'save_to_collection',
        collection_id: bookmarkFolder.id,
        collection_name: bookmarkFolder.name,
        is_reforge_collection: bookmarkFolder.reforgeCollection,
        is_shared_collection: bookmarkFolder?.sharedFolders[0]?.status === 'shared'
      })

      displaySuccessToast({
        message: `Added to ${bookmarkFolder.name} Collection`,
        viewHref: `/saved/${bookmarkFolder.id}`,
        viewText: 'View'
      })
      return data?.createFiledBookmark?.filedBookmark?.bookmarkFolderId
    }
  }

  const [createBookmarkFolder] = useCreateBookmarkFolderMutation({
    update: (cache, { data }) => {
      const existingData = cache.readQuery<BookmarkFoldersQuery>({
        query: BookmarkFoldersDocument
      })
      const newBookmarkFolder = data?.createBookmarkFolder?.bookmarkFolder

      if (!existingData || !newBookmarkFolder) return

      const newData = produce(existingData, (draft) => {
        draft?.currentUser?.bookmarkFolders?.unshift(newBookmarkFolder)
      })

      cache.writeQuery<BookmarkFoldersQuery>({
        query: BookmarkFoldersDocument,
        data: newData
      })
    }
  })

  const handleTracking = (
    action: 'collection_created' | 'cancel_create_new_collection' | 'collection_shared',
    triggerAction: string,
    collection?: BookmarkFolderPartsFragment
  ) => {
    trackCollectionAction({
      action: action,
      location: window.location.pathname,
      collection_name: collection?.name,
      collection_id: collection?.id,
      is_reforge_collection: collection?.reforgeCollection || !!collection?.forkedFromId,
      trigger_action: triggerAction
    })
  }

  const handleCancel = () => {
    handleClose()
    trackModalDismissed({
      category: 'app',
      location: window.location.pathname,
      modal_name: 'create_new_collection_modal',
      modal_group: 'collections'
    })
  }

  const [shareFolder] = useShareBookmarkFolderMutation()

  const createFolder = async () => {
    if (!name) return

    let bookmarkFolder
    try {
      const response = await createBookmarkFolder({
        variables: {
          input: {
            name: name.trim(),
            description: desc.trim()
          }
        }
      })

      if (response?.data?.createBookmarkFolder?.bookmarkFolder) {
        setName('')
        handleClose()
        successCallback?.(response?.data?.createBookmarkFolder?.bookmarkFolder)
      }

      handleTracking(
        'collection_created',
        `${trackingTriggerAction}__create_new`,
        response?.data?.createBookmarkFolder?.bookmarkFolder || undefined
      )

      if (isShared) {
        handleTracking(
          'collection_shared',
          `${trackingTriggerAction}__create_new`,
          response?.data?.createBookmarkFolder?.bookmarkFolder || undefined
        )
      }

      bookmarkFolder = response?.data?.createBookmarkFolder?.bookmarkFolder
    } catch (error: unknown) {
      notifyError(error)
    }

    if (bookmarkFolder && currentBookmarkForDropdown) {
      handleAddToFolder(currentBookmarkForDropdown.id, bookmarkFolder)
    }

    if (!isShared || !bookmarkFolder) return

    try {
      await shareFolder({
        variables: {
          input: {
            bookmarkFolderId: bookmarkFolder.id
          }
        }
      })
    } catch (error: unknown) {
      notifyError(error)
    }
  }

  if (
    !currentUser?.is.member &&
    !currentUser?.is.paidMember &&
    !currentUser?.is.planManager
  ) {
    return null
  }

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        handleClose={handleCancel}
        className="max-w-md rounded-2xl"
        header={false}
        closeOnEscape={true}
        closeOnOutsideClick={true}
      >
        <ModalHeader className="mt-8 flex md:px-8">
          <ModalTitle>Create new Collection</ModalTitle>
        </ModalHeader>
        <ModalContent className="px-8 md:px-8" scrollContent={false}>
          <div className="flex flex-col pb-6">
            <div className="pb-2 text-sm font-medium">Collection Name</div>
            <input
              type="text"
              className="mr-3 h-12 w-full rounded-sm border pl-5 text-rb-gray-400 outline-none focus:border-blue-300"
              name="folderName"
              onChange={(e) => setName(e.target.value)}
              // eslint-disable-next-line
              autoFocus
            />
          </div>
          <div className="flex flex-col pb-10">
            <div className="pb-2 text-sm font-medium">Description</div>
            <textarea
              className="mr-3 h-12 w-full resize-none rounded-sm border pt-2 pl-5 pr-1 text-rb-gray-400 outline-none focus:border-blue-300"
              name="folderDesc"
              onChange={(e) => setDesc(e.target.value)}
              onKeyUp={onEnterKeyPress(createFolder)}
              rows={5}
            />
          </div>
          <div className="inline-flex justify-between">
            <div className="w-3/4">
              <div>Share with your team</div>
              <RfParagraphMini>
                Sharing will give everyone on your Reforge subscription view and edit
                access
              </RfParagraphMini>
            </div>
            <ToggleSwitch handleClick={() => setIsShared(!isShared)} toggled={isShared} />
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            className="mr-3 w-32 border border-gray-300 bg-white font-normal text-black"
            onClick={handleCancel}
            dataTest="commitment-modal-submit-button"
            size="medium"
          >
            Cancel
          </Button>
          <Button
            className="w-32 font-normal"
            disabled={!name || name.trim().length === 0}
            onClick={createFolder}
            dataTest="commitment-modal-submit-button"
            size="medium"
          >
            Create
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default CreateBookmarkFolderModal
