import { EventCurrentUserPartsFragment, EventPartsFragment, EventSpeaker } from 'gql'

import {
  HOUR_MIN_FORMAT,
  formatInTimezone,
  getCurrentTimezone,
  getTimezoneAbbreviation
} from 'utils/date'

interface UseEventCardProps {
  event: EventPartsFragment
  currentUser?: EventCurrentUserPartsFragment | null
  eventPageQueryString?: string
  contentClickDisabled?: boolean
  shouldOpenNewTabOnClick?: boolean
}
const useEventCard = ({
  event,
  currentUser,
  eventPageQueryString,
  contentClickDisabled,
  shouldOpenNewTabOnClick
}: UseEventCardProps) => {
  const canViewEvent = !currentUser || currentUser.can.viewEvent

  const orderedSpeakers: EventSpeaker[] = ([] as EventSpeaker[])
    .concat(event.speakers as any)
    .sort((speaker: EventSpeaker) => (speaker.kind === 'host' ? -1 : 1))

  const userTimezone = getCurrentTimezone(currentUser?.timezone)
  const timeOfEventInLocalTimeZone = `${formatInTimezone(
    event.startsAtUtc,
    userTimezone,
    HOUR_MIN_FORMAT
  )} - ${formatInTimezone(event.endsAtUtc, userTimezone, HOUR_MIN_FORMAT)} `
  const tzAbbrev = getTimezoneAbbreviation(currentUser?.timezone, event.startsAtUtc)
  const eventDate = `${formatInTimezone(event.startsAtUtc, userTimezone, 'dd MMM yy')}`

  const onCardClick = (e: any) => {
    if (contentClickDisabled) return false

    const clickedToggle = e.target.closest('.prevent-default')

    const href = `/events/${event.id}-${event.slug + (event.past ? '/summary' : '')}`
    if (shouldOpenNewTabOnClick) {
      window.open(href)
    }

    if (!clickedToggle && canViewEvent && !shouldOpenNewTabOnClick) {
      if (event.summaryWistiaCodeOptional) {
        // there react router push cannot be used because this component is rendered in /member/{id} where context is not available
        window.location.href = href + eventPageQueryString
      } else {
        window.location.href = href
      }
    }
  }

  return {
    canViewEvent,
    orderedSpeakers,
    userTimezone,
    timeOfEventInLocalTimeZone,
    tzAbbrev,
    onCardClick,
    eventDate
  }
}

export default useEventCard
