// This hook is used to convert a relay-style connection into a simple array.
// This makes it a lot easier to work with connections for pagination.
// Unlike the `mapNodes` utility this returns a stable array (it only changes if the data changes)
// so it can be passed as a dependency to `useEffect`.
import { useMemo } from 'react'

import { Connection, mapNodes } from 'utils/mapNodes'

export function useMapNodes<Node>(connection: Connection<Node> | null | undefined): Node[]

export function useMapNodes<Result extends unknown, Node>(
  connection: Connection<Node> | null | undefined,
  callback: (node: Node) => Result
): Result[]

export function useMapNodes<Node, Result extends unknown = Node>(
  connection: Connection<Node> | null | undefined,
  callback?: (item: Node) => Result
) {
  return useMemo(() => {
    if (callback) {
      return mapNodes(connection, callback)
    }
    return mapNodes(connection)
  }, [connection, callback])
}
