import React, { type ChangeEvent, useReducer } from 'react'

import CommentForm from 'domains/Artifact/CommentForm'
import { showAddedToast, showErrorToast } from 'domains/Artifact/comments/utils'

import { useAddArtifactCommentMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { trackTextBoxEntered } from 'utils/tracking/analytics'

type State = {
  mode: 'idle' | 'idleDirty' | 'creating' | 'submitting'
  commentTextareaValue: string
  commentTextareaPlaceholder: string
}
type Action =
  | { type: 'commentFormFocused' }
  | { type: 'commentFormBlurred' }
  | { type: 'newValueEnteredForCommentTextarea'; payload: string }
  | { type: 'commentSubmitIntended' }
  | { type: 'commentSubmitSucceeded' }
  | { type: 'commentSubmitFailed' }
  | { type: 'commentCancelClicked' }

const intialStateReducerState: State = {
  mode: 'idle',
  commentTextareaValue: '',
  commentTextareaPlaceholder: 'Share your thoughts...'
}

function stateReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'newValueEnteredForCommentTextarea':
      return { ...state, commentTextareaValue: action.payload }

    case 'commentFormFocused':
      return { ...state, mode: 'creating', commentTextareaPlaceholder: '' }

    case 'commentFormBlurred':
      if (state.commentTextareaValue) {
        return { ...state, mode: 'idleDirty' }
      }

      return {
        ...state,
        mode: 'idle',
        commentTextareaPlaceholder: intialStateReducerState.commentTextareaPlaceholder
      }

    case 'commentSubmitIntended':
      return { ...state, mode: 'submitting' }

    case 'commentSubmitSucceeded':
      return {
        ...state,
        mode: 'idle',
        commentTextareaValue: '',
        commentTextareaPlaceholder: intialStateReducerState.commentTextareaPlaceholder
      }

    case 'commentSubmitFailed':
      return { ...state, mode: 'idleDirty' }

    case 'commentCancelClicked':
      return {
        ...state,
        mode: 'idle',
        commentTextareaValue: '',
        commentTextareaPlaceholder: intialStateReducerState.commentTextareaPlaceholder
      }

    default:
      return state
  }
}

interface AddCommentFormProps {
  artifact: any
  onSubmitSucceeded: () => void
}

const AddCommentForm = ({ artifact, onSubmitSucceeded }: AddCommentFormProps) => {
  const { currentUser: user } = useCurrentUser()

  const [state, dispatchStateAction] = useReducer(stateReducer, intialStateReducerState)

  const [addArtifactComment] = useAddArtifactCommentMutation({
    refetchQueries: ['ArtifactComments'],
    awaitRefetchQueries: true
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (state.mode === 'submitting') return

    dispatchStateAction({ type: 'commentSubmitIntended' })

    const message = state.commentTextareaValue

    try {
      const addCommentResult = await addArtifactComment({
        variables: {
          input: {
            artifactId: artifact.id,
            message,
            userId: user?.id || ''
          }
        },
        refetchQueries: ['ArtifactComments'],
        awaitRefetchQueries: true
      })

      const addData = addCommentResult?.data?.addArtifactComment?.artifactComment

      if (addData?.id) {
        showAddedToast()
        dispatchStateAction({ type: 'commentSubmitSucceeded' })
        onSubmitSucceeded()
      } else {
        showErrorToast()
        dispatchStateAction({ type: 'commentSubmitFailed' })
      }
    } catch {
      showErrorToast()
      dispatchStateAction({ type: 'commentSubmitFailed' })
    }
  }

  const handleTextChange = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
    dispatchStateAction({
      type: 'newValueEnteredForCommentTextarea',
      payload: target.value
    })
  }

  const handleFocus = () => {
    dispatchStateAction({ type: 'commentFormFocused' })

    trackTextBoxEntered({
      location: 'artifacts_right_sidebar',
      text_box_type: 'artifact comment'
    })
  }

  return (
    <div id="artifact-comment-form" className="flex flex-row">
      <CommentForm
        mode={state.mode === 'idle' ? 'collapsed' : 'expanded'}
        value={state.commentTextareaValue}
        onChange={handleTextChange}
        onSubmit={handleSubmit}
        onFocus={handleFocus}
        onBlur={() => dispatchStateAction({ type: 'commentFormBlurred' })}
        onCancel={() => dispatchStateAction({ type: 'commentCancelClicked' })}
        isSubmitting={state.mode === 'submitting'}
        placeholder={state.commentTextareaPlaceholder}
      />
    </div>
  )
}

export default AddCommentForm
