import { ReactComponent as InfoIcon } from 'icon--info.svg'
import { useHistory } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import { SubscriptionManagementCard } from 'domains/Subscription/SubscriptionCard/SubscriptionManagementCards'

import { ErrorMessage, Loading } from 'components'

import { usePlansForSaleQuery } from 'gql'

import { getPrettyFullDate } from 'utils/date'
import { formatMoney } from 'utils/moneyUtils'
import { titleize } from 'utils/stringUtils'
import { trackCtaClicked } from 'utils/tracking/analytics'

import { useCohortPassOptOut } from '../CohortPassOptOutModalContent/useCohortPassOptOut'

export interface UpcomingPlanCardProps {
  numTotalSeats: number
  amountDue: number
  isSubscriptionOwner: boolean
  expiresAt: string
  planName: string
  numCohortPasses: number
}

export const UpcomingPlanCard = ({
  numTotalSeats,
  amountDue,
  isSubscriptionOwner,
  expiresAt,
  planName,
  numCohortPasses
}: UpcomingPlanCardProps) => {
  const history = useHistory()
  const { openCohortPassOptOutModal } = useCohortPassOptOut()
  const { loading, error, data } = usePlansForSaleQuery()
  if (loading) return <Loading />
  if (error) return <ErrorMessage error={error} />
  if (!data?.plansForSale) return <ErrorMessage error={'No plan data available.'} />
  const { plansForSale } = data
  const selectedPlanDetails = plansForSale.find((plan) => plan.name === planName)
  const pricePerYear = selectedPlanDetails?.pricePerYear
  if (!pricePerYear) return null

  const prettyPricePerYear = formatMoney(pricePerYear)

  const cohortPassTotal = amountDue / 100 - pricePerYear
  const prettyCohortPassTotal = formatMoney(cohortPassTotal)
  const heading = `New Plan: ${titleize(planName)}`
  let primarybutton

  let secondaryButton = {
    copy: 'Manage Plan',
    onclick: () => {
      trackCtaClicked({
        cta_location: 'subscription_card',
        cta_type: 'button',
        text: 'manage plan',
        destination: '/manage-plan'
      })
      history.push('/manage-plan')
    }
  }
  if (isSubscriptionOwner) {
    if (numCohortPasses > 0 && planName === 'individual') {
      primarybutton = secondaryButton
      secondaryButton = {
        copy: 'Remove Live Course Passes',
        onclick: () => {
          trackCtaClicked({
            cta_location: 'subscription_card',
            cta_type: 'button',
            text: 'remove Live Course Passes'
          })
          openCohortPassOptOutModal()
        }
      }
    }
  } else {
    secondaryButton = {
      copy: 'View Plans',
      onclick: () => {
        trackCtaClicked({
          cta_location: 'subscription_card',
          cta_type: 'button',
          text: 'view plans',
          destination: '/manage-plan'
        })
        history.push('/manage-plan')
      }
    }
  }

  let details
  if (numCohortPasses === 0) {
    details = null
  } else if (planName === 'individual') {
    details = (
      <div className="mb-7">
        +{prettyCohortPassTotal} for {numCohortPasses} Live Course Pass
        {numCohortPasses > 1 ? 'es' : ''}{' '}
        {numCohortPasses === 2 && (
          <>
            <span className="ml-1 text-xs text-rb-success-200">
              One-time renewal discount applied
              <span
                className="ml-1 inline-flex"
                data-tip="For being an existing member, you are receiving a one-time discount on Live Course Passes."
                data-for="renewal-discount-tip"
              >
                <InfoIcon width={10} height={10} />
              </span>
            </span>
            <ReactTooltip
              id="renewal-discount-tip"
              effect="solid"
              backgroundColor="#4D4D4D"
              className="w-64"
              multiline={true}
            />
          </>
        )}
      </div>
    )
  } else {
    details = (
      <div className="flex">
        <h3 className="mr-3 font-medium">+{prettyCohortPassTotal}</h3>{' '}
        <div className="pt-1 text-sm text-rb-success-200">One-time purchase</div>
      </div>
    )
  }

  return (
    <SubscriptionManagementCard
      dataTestId="upcoming-plan-card"
      topBarColorClass="gradient-to-r from-rb-pink-50 to-rb-teal-50"
      heading={heading}
      note={`Starts on ${getPrettyFullDate(expiresAt)}`}
      contentLeft={` ${prettyPricePerYear}/year`}
      contentRight={`${numTotalSeats} Seat${numTotalSeats > 1 ? 's' : ''}`}
      details={numCohortPasses === 0 ? undefined : details}
      primaryButton={primarybutton}
      secondaryButton={secondaryButton}
    />
  )
}
