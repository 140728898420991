import { useCallback, useEffect, useState } from 'react'

import { EventPartsFragment } from 'gql'

const useRealTimeEventReminderCalculateTimeLeft = (event: EventPartsFragment) => {
  const calculateTimeLeft = useCallback(() => {
    const eventStartTime = new Date(event.startsAtUtc)
    const timeNow = new Date()

    const difference = eventStartTime.getTime() - timeNow.getTime()

    let timeLeft = {
      minutes: 0,
      seconds: 0
    }

    if (difference > 0) {
      timeLeft = {
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      }
    }

    return timeLeft
  }, [event])

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft.minutes * 60 + timeLeft.seconds > 0) {
        setTimeLeft(calculateTimeLeft())
      }
    }, 1000)

    return () => clearTimeout(timer)
  }, [timeLeft, setTimeLeft, calculateTimeLeft])
  return timeLeft
}

export default useRealTimeEventReminderCalculateTimeLeft
