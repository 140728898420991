import Button from 'components/Button'
import ProgressCircle from 'components/cards/shared/ProgressCircle'

export interface FullWidthLinkCardProps {
  content: string
  linkText: string
  linkAction: () => void
  isTaskCompleted: boolean
  disableActionButton: boolean
  dataTestId?: string
}

const FullWidthLinkCard = ({
  content,
  linkText,
  linkAction,
  isTaskCompleted,
  disableActionButton,
  dataTestId
}: FullWidthLinkCardProps) => {
  return (
    <div
      style={{ minHeight: '70px' }}
      onClick={disableActionButton ? undefined : linkAction}
      className={`full-width-card flex flex-col ${
        disableActionButton ? 'rf-rb-card' : 'rf-rb-card-interactive'
      }`}
    >
      <div className="flex flex-row flex-wrap items-center justify-between bg-rb-gray-20 p-4 sm:flex-nowrap">
        <div className="flex flex-row items-center">
          <div
            style={{ minWidth: '40px', minHeight: '40px' }}
            className="h-5 w-5 xs:h-8 xs:w-8"
          >
            <ProgressCircle percentComplete={isTaskCompleted ? 100 : 0} />
          </div>
          <span className="ml-4 font-medium" data-test={`${dataTestId}-content`}>
            {content}
          </span>
        </div>
        <div className="mt-4 ml-2 flex w-full justify-end sm:mt-0 sm:w-auto">
          <Button
            className="h-8 w-32 px-1.5"
            variant="outline"
            size="x-small"
            shape="rounded-full"
            disabled={disableActionButton}
            onClick={linkAction}
            dataTest={`${dataTestId}-cta`}
          >
            {linkText}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default FullWidthLinkCard
