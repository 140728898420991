import CourseSnapshotExplore from 'domains/CourseSnapshot/CourseSnapshotExplore'
import CourseSnapshotHeader from 'domains/CourseSnapshot/CourseSnapshotHeader'

const CourseSnapshotLandingPage = () => {
  return (
    <div>
      <CourseSnapshotHeader />
      <CourseSnapshotExplore />
    </div>
  )
}

export default CourseSnapshotLandingPage
