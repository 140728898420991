import { MutableRefObject, useEffect, useRef, useState } from 'react'

import { ReactComponent as PauseIcon } from 'images/icon--pause.svg'
import { ReactComponent as PlayIcon } from 'images/icon--play-full.svg'

interface AudioPlayerProps {
  src: string
  title: string
}

const AudioPlayer = ({ src, title }: AudioPlayerProps) => {
  const [trackProgress, setTrackProgress] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)

  const audioRef = useRef(new Audio(src))
  const intervalRef = useRef<number | null>(null)

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef)
    if (intervalRef.current) window.clearInterval(intervalRef.current)

    intervalRef.current = window.setInterval(() => {
      if (audioRef.current.ended) {
        setIsPlaying(false)
        setTrackProgress(duration)
      } else {
        setTrackProgress(audioRef.current.currentTime)
      }
    }, 1000)
  }

  useEffect(() => {
    if (isPlaying) {
      if (audioRef.current.ended) {
        audioRef.current.currentTime = 0
      }

      audioRef.current.play()
      startTimer()
    } else {
      clearInterval(intervalRef)
      audioRef.current.pause()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying])

  useEffect(() => {
    audioRef.current.addEventListener('canplaythrough', () => {
      setDuration(audioRef.current.duration)
    })

    // Pause and clean up on unmount
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      audioRef.current.pause()
      clearInterval(intervalRef)
    }
  }, [])

  const onScrub = (value: string) => {
    // Clear any timers already running
    clearInterval(intervalRef)
    audioRef.current.currentTime = Number(value)
    setTrackProgress(Number(value))
  }

  const onScrubEnd = () => {
    // If not already playing, start
    if (!isPlaying) {
      setIsPlaying(true)
    }
    startTimer()
  }

  const clearInterval = (intervalRef: MutableRefObject<number | null>) => {
    if (intervalRef.current) window.clearInterval(intervalRef.current)
  }

  return (
    <div className="audio-player chromatic-ignore flex items-center bg-rb-gray-500">
      <button
        type="button"
        className="flex h-[60px] w-[60px] shrink-0 cursor-pointer items-center justify-center border-0 bg-transparent text-rb-white"
        onClick={() => setIsPlaying(!isPlaying)}
        aria-label={isPlaying ? 'Pause' : 'Play'}
      >
        {isPlaying ? <PauseIcon width={28} /> : <PlayIcon width={28} />}
      </button>
      <div className="relative z-0 flex h-[60px] w-full">
        <input
          type="range"
          className="absolute top-0 left-0 z-2 h-full w-full cursor-pointer opacity-0"
          step="1"
          min="0"
          max={duration}
          onChange={(e) => onScrub(e.target.value)}
          onMouseUp={onScrubEnd}
          onKeyUp={onScrubEnd}
        />

        <div
          className="absolute top-0 left-0 z-0 h-full border-r border-r-rb-gray-300 bg-rb-gray-400"
          style={{
            width: `${(trackProgress / duration) * 100}%`
          }}
        />

        <div className="absolute top-0 left-0 z-1 grid h-full max-w-full grid-cols-[auto_auto] grid-rows-1 gap-[20px] px-[20px]">
          <div className="flex shrink-0 grow-0 flex-wrap items-center text-base leading-5 text-rb-white">
            <div className="line-clamp-2">{title}</div>
          </div>
          <div className="flex max-w-max shrink-0 items-center text-xs text-rb-white">
            {formatTimeAsMinutesSeconds(trackProgress)} /{' '}
            {formatTimeAsMinutesSeconds(duration)}
          </div>
        </div>
      </div>
    </div>
  )
}

const formatTimeAsMinutesSeconds = (seconds: number) => {
  return new Date(seconds * 1000).toISOString().substring(14, 19)
}

export default AudioPlayer
