import { Redirect } from 'react-router-dom'

import RfHeader1 from 'components/typography/RfHeader/RfHeader1'

import { MAX_WIDTH_TAILWIND_TL } from 'constants/breakpoints'

import {
  CohortViewerNewCohortPartsFragment,
  CohortViewerNewCohortTeamPartsFragment
} from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { ActivityFeed, Members } from '.'

interface CohortDashboardMyTeamProps {
  cohort: CohortViewerNewCohortPartsFragment
  cohortTeam: CohortViewerNewCohortTeamPartsFragment
}

export const CohortDashboardMyTeam = ({
  cohort,
  cohortTeam
}: CohortDashboardMyTeamProps) => {
  const isLargeScreen = useMediaQuery(`(min-width: ${MAX_WIDTH_TAILWIND_TL})`)
  const members = <Members cohort={cohort} cohortTeam={cohortTeam} />

  if (!cohortTeam) {
    return <Redirect to={`/cohorts/${cohort.slug}`} />
  }

  return (
    <div className="flex w-full justify-center overflow-y-auto">
      <div className="w-full max-w-[1092px] px-6 py-8 lg:mx-12 lg:px-0 2xl:mx-24">
        <RfHeader1>{cohortTeam.name}</RfHeader1>
        <div className="mt-16 flex flex-col gap-16 pb-20 lg:flex-row xl:gap-24">
          {!isLargeScreen && members}
          <ActivityFeed cohort={cohort} cohortTeam={cohortTeam} />
          {isLargeScreen && members}
        </div>
      </div>
    </div>
  )
}
