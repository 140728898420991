import { SetStateAction, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { ReactComponent as ChevronDown } from 'thin-chevron-down.svg'
import { ReactComponent as ChevronUp } from 'thin-chevron-up.svg'

import { CollapsibleContentBlock } from 'pages/UnitLandingPage/UnitContentBlock'

import { UnitComplexPortableText } from 'domains/Sanity/PortableText/UnitComplexPortableText/UnitComplexPortableText'

import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'

import {
  CourseDashboardCoursePartsFragment,
  CourseDashboardCourseSessionPartsFragment
} from 'gql'

import { useContentMode } from 'utils/contentTrackingUtils'
import { isAfterDate } from 'utils/date'
import { scrollToElementTop } from 'utils/scrollToElementTop'
import { trackExpandClick } from 'utils/tracking/analytics'

export interface CourseDashboardGettingStartedProps {
  course: CourseDashboardCoursePartsFragment
  courseSession: CourseDashboardCourseSessionPartsFragment
  isExpanded?: boolean
}

const GETTING_STARTED_BLOCK_ID = 'getting-started-block'

const CourseDashboardGettingStarted = ({
  course,
  courseSession,
  isExpanded = false
}: CourseDashboardGettingStartedProps) => {
  const contentMode = useContentMode({ courseSession }) || ''
  const [expanded, setExpanded] = useState(isExpanded)

  const setExpandedSync = (prev: SetStateAction<boolean>) => {
    if (prev) {
      scrollToElementTop(GETTING_STARTED_BLOCK_ID)
    }

    return !prev
  }

  const onExpandClick = (e: any) => {
    e.stopPropagation()
    if (!expanded) {
      trackExpandClick({
        location: 'course_dashboard',
        expandable_section_title: `Welcome to ${course.title}`,
        expandable_section_type: 'course_welcome_details',
        related_identifiers: {
          content_block_id: courseSession?.gettingStartedBlock?.id || '',
          content_block_title: `Welcome to ${course.title}`,
          content_block_number: '0',
          content_mode: contentMode,
          course_id: course.id,
          course_title: course.title,
          course_session_id: courseSession?.id,
          sanity_course_id: course.sanityId,
          content_type: 'course'
        }
      })
    }

    setExpanded(setExpandedSync)
  }

  useEffect(() => {
    if (!courseSession?.startsAt) setExpanded(true)

    const now = new Date()
    const startDate = new Date(courseSession?.startsAt)

    setExpanded(now < startDate)
  }, [courseSession?.startsAt, setExpanded])

  const hasCourseEnded = isAfterDate(courseSession?.endsAt)

  return (
    <div
      id={GETTING_STARTED_BLOCK_ID}
      className={twMerge(
        'flex w-full scroll-mt-[136px] flex-col rounded-xl px-8 transition-all duration-500 ease-in-out bg-rb-gray-50',
        expanded ? 'py-8' : 'py-4',
        hasCourseEnded && 'border border-rb-gray-100'
      )}
    >
      <div
        className={twMerge(
          'flex cursor-pointer items-center justify-between overflow-hidden'
        )}
        onKeyUp={onExpandClick}
        onClick={onExpandClick}
        role="button"
        tabIndex={0}
      >
        <RfParagraphMediumBold className="w-full">
          Welcome to {course.title} 👋
        </RfParagraphMediumBold>

        {!expanded && <ChevronDown className="h-[22px] w-[22px] text-rb-gray-200" />}
      </div>

      <div
        className={twMerge(
          'overflow-hidden transition-all duration-500 ease-in-out',
          expanded ? 'max-h-fit' : 'max-h-0'
        )}
      >
        {courseSession.gettingStartedBlock?.description && (
          <div className="pt-4">
            <UnitComplexPortableText
              content={JSON.parse(courseSession.gettingStartedBlock.description)}
            />
          </div>
        )}

        <div className="flex flex-col gap-7 pt-7">
          {courseSession.gettingStartedBlock?.gettingStartedCollapsible &&
            courseSession.gettingStartedBlock?.gettingStartedCollapsible.map(
              (unitContentBlockCollapsible, i: number) => (
                <CollapsibleContentBlock
                  key={i}
                  isExpanded={isExpanded}
                  type={unitContentBlockCollapsible.type || ''}
                  description={JSON.parse(unitContentBlockCollapsible.description)}
                />
              )
            )}
        </div>

        <div className="pt-7">
          <button
            onClick={onExpandClick}
            className="flex w-full items-center justify-center"
          >
            <ChevronUp className="h-[22px] w-[22px] text-rb-gray-200" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default CourseDashboardGettingStarted
