import React, { RefObject, forwardRef, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import BookmarkNoteControls from 'domains/Bookmarks/BookmarkNoteControls'

import VideoTimeElement from 'components/VideoTimeElement'

import { BookmarkNoteFormPartsFragment, ProgramBookmarkPartsFragment } from 'gql'

import { track } from 'utils/tracking/segment'

export interface BookmarkNoteFormProps {
  bookmark: ProgramBookmarkPartsFragment
  type: string
  saveBookmarkNote: (editedBookmark: ProgramBookmarkPartsFragment) => void
  onBlur?: (bookmark: BookmarkNoteFormPartsFragment) => void
  onDeleteBookmark?: () => void
  onSelectBookmark?: () => void
  onFocusedBookmark?: (id: string) => void
  shouldShowNoteElement?: boolean
  startActive?: boolean
  className?: string
}

const BookmarkNoteForm = (
  {
    bookmark,
    type,
    saveBookmarkNote,
    onBlur,
    onDeleteBookmark,
    onSelectBookmark,
    onFocusedBookmark = () => {},
    startActive = false,
    className
  }: BookmarkNoteFormProps,
  ref: RefObject<HTMLTextAreaElement>
) => {
  const [bookmarkNoteText, setBookmarkNote] = useState(bookmark.noteBody || '')

  const haveUnsavedEdits = () => {
    const trimmedText = bookmarkNoteText.trim()

    // Handles empty and unequal string condition saves
    return !trimmedText || trimmedText !== bookmark.noteBody
  }

  const handleSave = () => {
    // @ts-ignore - 'Content Viewer - Action' event is not defined in Segment JIRA#REF-5159
    track('Content Viewer - Action', {
      action: 'Bookmark__save',
      location: window.location.pathname
    })

    const trimmedText = bookmarkNoteText.trim()
    setBookmarkNote(trimmedText)

    if (haveUnsavedEdits()) {
      saveBookmarkNote({ ...bookmark, noteBody: trimmedText })
    }
    ref?.current?.blur()
  }

  const handleTextAreaBlurred = () => {
    onBlur?.({ ...bookmark, noteBody: bookmarkNoteText })
    handleSave()
  }

  useEffect(() => {
    if (startActive) {
      'id' in bookmark && onFocusedBookmark(bookmark.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startActive])

  const referenceImageElement =
    type === 'Video' && bookmark.referenceImageUrl ? (
      <div
        className="uk-width-1-5 relative p-0 hover:cursor-pointer"
        onClick={onSelectBookmark}
      >
        <img src={bookmark.referenceImageUrl} alt="bookmark" />
        <div className="absolute left-2 bottom-[5px]">
          <VideoTimeElement
            bookmark={bookmark}
            type="Dashboard"
            onSelectBookmark={onSelectBookmark}
          />
        </div>
      </div>
    ) : (
      ''
    )

  const noteEditElement = (ref: React.Ref<HTMLTextAreaElement>) => (
    <div className="group flex w-full justify-between rounded-md border-2 border-transparent bg-rb-gray-50 p-4 transition-[border] duration-500 hover:border-rb-gray-250">
      <textarea
        ref={ref}
        className="autoexpand w-full resize-none overflow-hidden border-none !bg-rb-gray-50 font-sans !leading-6 !text-black opacity-100 outline-none placeholder:text-black sm:pr-[15px]"
        value={bookmarkNoteText}
        onChange={(e) => setBookmarkNote(e.target.value)}
        onBlur={handleTextAreaBlurred}
      />
      <BookmarkNoteControls
        bookmark={bookmark}
        onDeleteBookmark={onDeleteBookmark}
        onEditBookmark={saveBookmarkNote}
        type={type}
      />
    </div>
  )

  return (
    <div className={twMerge('flex', className)}>
      {referenceImageElement}
      {noteEditElement(ref)}
    </div>
  )
}

export default forwardRef<HTMLTextAreaElement, BookmarkNoteFormProps>(BookmarkNoteForm)
