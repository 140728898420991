import { Maybe } from 'graphql/jsutils/Maybe'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useAddPostModal } from 'domains/Post/AddPostModal'

import { getCookie, setCookie } from 'utils/cookieUtils'
import { EventMapper } from 'utils/tracking/generated/types/mapper'
import { track } from 'utils/tracking/segment'

import { Wistia, WistiaQInit, WistiaVideo } from 'typings/WistiaVideo'

const TRIGGER_SHOW_CELEBRATION_MODAL_DELAY = 1000 // 1 second

export interface UseWistiaVideoProps {
  userEmail: string
  code?: Maybe<string>
  enableKeyboardHandlers?: boolean
  initialVideoPercent?: number
  initialVideoSeconds?: number
  initialCompleted?: boolean
  eventTrackingInformation?: Record<string, unknown>
  onVideoPause?: (inputContext: string, video: WistiaVideo) => void
  onVideoTimeChange?: (percent?: number, seconds?: number) => void
  onProgressUpdate?: (percent?: number, seconds?: number) => void
  onPressEnter?: () => void
  onCustomPlayerClick?: () => void
  onVideoPlay?: () => void
  onVideoFirstPlay?: () => void
  onVideoEnd?: () => void
  celebrationModal?: boolean
  autoplay?: boolean
}

const useWistiaVideo = ({
  userEmail,
  code,
  enableKeyboardHandlers = false,
  initialVideoPercent = 0,
  initialVideoSeconds = 0,
  onVideoPause,
  initialCompleted = false,
  onProgressUpdate,
  onVideoTimeChange,
  eventTrackingInformation,
  onPressEnter,
  onCustomPlayerClick,
  onVideoPlay,
  onVideoFirstPlay,
  onVideoEnd,
  celebrationModal = false,
  autoplay = false
}: UseWistiaVideoProps) => {
  const [videoIsPlaying, setVideoIsPlaying] = useState(false)
  const videoPercent = useRef(initialVideoPercent)
  const videoSeconds = useRef(initialVideoSeconds)
  const duration = useRef<number>()
  const played = useRef(false)
  const [videoInstance, setVideoInstance] = useState<WistiaVideo>()
  const [completed, setCompleted] = useState(initialCompleted)
  const [assetUrl, setAssetUrl] = useState<string>()
  const { isAddPostModalOpen } = useAddPostModal()
  const wistaVideoContainer = document.querySelector(`.wistia_async_${code}`)

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (isAddPostModalOpen) {
        // Wistia player keyboard shortcuts activating when AddPostModal is open is still a bit buggy
        // May need to explore Wistia event binding: https://wistia.com/support/developers/player-api#bind-eventtype-callbackfn
        return
      }

      const targetElement = e.target as HTMLElement
      if (targetElement.className?.startsWith('video-bookmarker__control')) {
        // the key also triggered a button click by selection,
        // skip the key and let button click handle
        return
      }
      if (!videoInstance) return

      const isEventContainedInVideo =
        wistaVideoContainer && wistaVideoContainer.contains(targetElement)

      if (
        (!isEventContainedInVideo &&
          ['textarea', 'input', 'select', 'button', 'a'].includes(
            targetElement.tagName?.toLowerCase()
          )) ||
        document?.activeElement?.attributes?.getNamedItem('contentEditable')?.value
      ) {
        return
      }
      const inputContext = videoInstance.getInputContext()

      if (e.code === 'Enter') {
        e.preventDefault()
        onPressEnter?.()
      } else if (e.code === 'Space' && !isEventContainedInVideo) {
        if (inputContext === 'player-mouseover') return

        e.preventDefault()
        onCustomPlayerClick?.()
      }
    },
    [
      isAddPostModalOpen,
      onCustomPlayerClick,
      onPressEnter,
      videoInstance,
      wistaVideoContainer
    ]
  )

  useEffect(() => {
    if (!enableKeyboardHandlers) return
    window.addEventListener('keypress', handleKeyPress)

    return () => {
      window.removeEventListener('keypress', handleKeyPress)
    }
  }, [enableKeyboardHandlers, videoInstance, handleKeyPress])

  const createVideoPlayCallback = (video: WistiaVideo) => () => {
    const lastPlaybackRate = getCookie('lastPlaybackRate') || ''
    if (
      lastPlaybackRate !== null &&
      parseFloat(lastPlaybackRate) &&
      parseFloat(lastPlaybackRate) !== video.playbackRate()
    ) {
      video.playbackRate(parseFloat(lastPlaybackRate))
    }
    setVideoIsPlaying(true)
    // @ts-ignore - 'Video Played' event is not defined in Segment JIRA#REF-5159
    sendSegmentEventForWistia('Video Played', video)
    if (!played.current) {
      onVideoFirstPlay?.()
    }
    onVideoPlay?.()
    played.current = true
  }
  const createVideoPauseCallback = (video: WistiaVideo) => () => {
    if (onVideoPause) {
      const inputContext = video.getInputContext()
      onVideoPause(inputContext, video)
    }

    setVideoIsPlaying(false)
    // @ts-ignore - 'Video Paused' event is not defined in Segment JIRA#REF-5159
    sendSegmentEventForWistia('Video Paused', video)
  }
  const videoTimechangeCallback = (videoSecondsDecimal: number) => {
    const calculatedVideoSeconds = Math.floor(videoSecondsDecimal)
    const calculatedVideoPercent = Math.round(
      100 * (calculatedVideoSeconds / Math.floor(duration.current!))
    )
    onVideoTimeChange?.(calculatedVideoPercent, calculatedVideoSeconds)

    if (calculatedVideoSeconds > videoSeconds.current) {
      if (calculatedVideoPercent > videoPercent.current) {
        if (!completed && calculatedVideoPercent > 90) {
          onProgressUpdate?.(calculatedVideoPercent, calculatedVideoSeconds)
          setCompleted(true)
        }
        videoPercent.current = calculatedVideoPercent
        videoSeconds.current = calculatedVideoSeconds
      }
    }
  }

  const handleTriggerShowCelebrationModal = () => {
    setTimeout(() => {
      window.triggerShowCelebrationModal()
    }, TRIGGER_SHOW_CELEBRATION_MODAL_DELAY)
  }

  const createVideoEndCallback = (video: WistiaVideo) => () => {
    setVideoIsPlaying(false)
    // @ts-ignore - 'Video Completed' event is not defined in Segment JIRA#REF-5159
    sendSegmentEventForWistia('Video Completed', video)

    // Close fullscreen to reveal autoplay overlay.
    video.cancelFullscreen()

    if (celebrationModal) handleTriggerShowCelebrationModal()
    onVideoEnd?.()
  }

  const videoPlaybackratechangeCallback = (playbackRate: number) => {
    setCookie('lastPlaybackRate', `${playbackRate}`, 365)
    const playbackRateElement = document.querySelector('.js-video__playback_rate')
    if (playbackRateElement) {
      playbackRateElement.textContent = `${playbackRate}x`
    }
  }

  const onVideoReady = (video: WistiaVideo) => {
    // Swap out the controls icon with the playback rate
    const lastPlaybackRate = getCookie('lastPlaybackRate')
    const sp0 = document.createElement('div')
    sp0.setAttribute('class', '!flex justify-center  h-full')
    const sp1 = document.createElement('div')
    sp1.setAttribute('class', 'js-video__playback_rate !flex items-center !text-xs')
    sp1.textContent = '1x'
    if (lastPlaybackRate) {
      sp1.textContent = `${lastPlaybackRate}x`
    }
    sp0.appendChild(sp1)
    const parent = document.querySelector(
      ".w-vulcan-icon-wrapper[data-handle='settingsButton_icon_wrapper']"
    )
    parent?.firstElementChild && parent.replaceChild(sp0, parent.firstElementChild)

    video.email(userEmail)
    video.bind('play', createVideoPlayCallback(video))

    video.bind('pause', createVideoPauseCallback(video))
    const quarterLength = Math.round(video.data.media.duration / 4)

    video.bind('crosstime', quarterLength, () => {
      // @ts-ignore - 'Video Progress Recorded' event is not defined in Segment JIRA#REF-5159
      sendSegmentEventForWistia('Video Progress Recorded', video, {
        progress: 25
      })
    })

    video.bind('crosstime', quarterLength * 2, () => {
      // @ts-ignore - 'Video Progress Recorded' event is not defined in Segment JIRA#REF-5159
      sendSegmentEventForWistia('Video Progress Recorded', video, {
        progress: 50
      })
    })

    video.bind('crosstime', quarterLength * 3, () => {
      // @ts-ignore - 'Video Progress Recorded' event is not defined in Segment JIRA#REF-5159
      sendSegmentEventForWistia('Video Progress Recorded', video, {
        progress: 75
      })
    })

    video.bind('timechange', videoTimechangeCallback)
    // cancelfullscreen event is  missing from typing from @types/wistia-player-browser
    video.bind('cancelfullscreen' as any, () => {
      if (celebrationModal) handleTriggerShowCelebrationModal()
    })

    video.bind('end', createVideoEndCallback(video))
    video.bind('playbackratechange', videoPlaybackratechangeCallback)

    duration.current = video.data.media.duration
    setVideoInstance(video)
    setAssetUrl(video.data.media.assets[0].url)

    const searchParams = new window.URLSearchParams(window.location.search)
    if (searchParams.has('seconds')) {
      // seconds embedded in link - seek video to this point
      const secondsValue = Number(searchParams.get('seconds'))
      if (Number.isInteger(secondsValue) && secondsValue <= video.data.media.duration) {
        video.time(secondsValue)
      }
    }
  }

  const initWistia = () => {
    if (!code) {
      throw new Error('There was an error initializing Video player - missing video code')
    }

    const autoPlayNextLessonIfActive = (wistia: Wistia) => {
      let playedOnce = false

      wistia.api((video: WistiaVideo) => {
        const autoPlayStorageItem = window.localStorage.getItem('autoplay') || '0'
        const isAutoplayActive = JSON.parse(autoPlayStorageItem)

        window.localStorage.setItem('autoplay', '0')

        if (!playedOnce && isAutoplayActive) {
          playedOnce = true
          video.requestFullscreen()
          video.play()
        }
      })
    }

    const playArgsBase: WistiaQInit[] = [
      {
        id: code,
        options: { hls: false },
        onReady: onVideoReady
      }
    ]

    const playArgs = autoplay
      ? [...playArgsBase, autoPlayNextLessonIfActive]
      : playArgsBase

    // wistia script loads asynchronously, so in the case there is no array defined we define one that will wait for the script to be picked up

    window._wq = window._wq || []
    window._wq.push(...playArgs)
  }

  useEffect(() => {
    if (videoInstance && code) {
      videoInstance.replaceWith(code)
    } else {
      initWistia()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, videoInstance])

  const sendSegmentEventForWistia = (
    eventName: keyof EventMapper,
    video: WistiaVideo,
    additionalInformation?: Record<string, unknown>
  ) => {
    const trackingInformation = {
      url: window.location.href,
      playbackSpeed: video.playbackRate(),
      muted: video.isMuted(),
      path: window.location.pathname
    }

    // @ts-ignore we might want to remove this method JIRA#REF-5159
    const eventInfo: EventMapper[typeof eventName] = {
      ...trackingInformation,
      ...additionalInformation,
      ...eventTrackingInformation
    }

    track(eventName, eventInfo)
  }

  const getVideoTime = (video: WistiaVideo | undefined) =>
    video && Math.floor(video.time())

  const videoPause = () => {
    videoInstance?.pause()
  }

  const videoPlay = () => {
    videoInstance?.play()
  }

  const getVideoInputContext = () => videoInstance?.getInputContext()

  return {
    videoSeconds: videoSeconds.current,
    videoPercent: videoPercent.current,
    videoIsPlaying,
    assetUrl,
    videoInstance,
    videoPause,
    videoPlay,
    duration,
    getVideoTime,
    getVideoInputContext
  }
}

export default useWistiaVideo
