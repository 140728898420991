import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { GoalSessionData } from 'pages/coaching/type'

import AvatarImage from 'domains/Artifact/AvatarImage'

import { FacePile } from 'components/FacePile'
import { SVGIcon } from 'components/Icon'

import { CclArtifact, CclGuide } from 'gql'

import { trackNavigationClicked } from 'utils/tracking/analytics'

const ChevronUp = <SVGIcon name="thin-chevron-up" height="7" width="12" fill="#0f0f0f" />
const ChevronDown = (
  <SVGIcon name="thin-chevron-down" height="7" width="12" fill="#0f0f0f" />
)

type ExpertSourceProps = {
  session: GoalSessionData
}

export const ExpertSource = ({ session }: ExpertSourceProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { pathname } = useLocation()
  const authors: any[] = session.data.authors
  const artifacts: CclArtifact[] = session.data.resources.artifacts
  const guides: CclGuide[] = session.data.resources.guides

  const ResourceCard = ({ resource, type }: any) => {
    const author: any = authors.find((author) => {
      return String(author.profileId) === String(resource.profiles_ids[0])
    })

    const handleCardClick = () => {
      const destinationUrl = `/${type}/${resource.slug}`

      trackNavigationClicked({
        destination: destinationUrl,
        location: 'coaching__steps_to_do',
        location_type: 'coaching_sessions',
        location_id: session.externalId,
        path: pathname,
        type: 'card'
      })
      window.open(destinationUrl, '_blank', 'noopener,noreferrer')
    }

    return (
      <div
        onClick={handleCardClick}
        className="py-3 cursor-pointer gap-3 px-4 grid grid-cols-[40px_1fr] trucate rounded-lg bg-[rgba(210,229,228,0.20)] w-full"
      >
        <div className="h-full flex items-start justify-center">
          <AvatarImage size="mini" user={author} />
        </div>
        <div className="flex flex-col flex-1 gap-1 overflow-hidden">
          <div className="text-sm truncate text-ellipsis whitespace-nowrap">
            {resource.title}
          </div>
          <div className="text-xs truncate text-ellipsis whitespace-nowrap">
            {author.fullName}
          </div>
          <div className="text-xs truncate text-ellipsis whitespace-nowrap">{`${author.position} at ${author.company}`}</div>
        </div>
      </div>
    )
  }
  return (
    <div className="w-full font-sans">
      <div className="flex items-center">
        <div className="text-sm font-normal mr-[10px]">Expert sources</div>
        <FacePile
          imageSize="medium"
          limit={3}
          showExtraCount={false}
          users={authors as any}
        />

        <div
          onClick={() => {
            setIsExpanded(!isExpanded)
          }}
          className="bg-rb-teal-50 rounded-lg ml-[10px] h-5 py-1 px-[6px] flex items-center justify-center"
        >
          {isExpanded ? ChevronUp : ChevronDown}
        </div>
      </div>

      {isExpanded && (
        <div className="grid grid-cols-2 gap-[10px] mt-2 w-full">
          {guides.map((guide, index) => {
            return <ResourceCard type="guides" key={index} resource={guide} />
          })}

          {artifacts.map((artifact, index) => {
            return <ResourceCard type="artifacts" key={index} resource={artifact} />
          })}
        </div>
      )}
    </div>
  )
}
