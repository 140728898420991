import { useMemo } from 'react'

import {
  CohortDashboardHeaderScheduledWeekPartsFragment,
  CohortDashboardScheduledWeekPartsFragment,
  CohortViewerNewCohortPartsFragment,
  UserProgramPartsFragment
} from 'gql'

import { getLessonToDisplay } from 'utils/cohortUtils'
import { makeLessonViewerUrl } from 'utils/url'

interface UseLessonNavInfoProps {
  cohort: CohortViewerNewCohortPartsFragment
  scheduledWeek:
    | CohortDashboardScheduledWeekPartsFragment
    | CohortDashboardHeaderScheduledWeekPartsFragment
    | undefined
  activeProgram: UserProgramPartsFragment | undefined | null
}

export const useLessonNavInfo = ({
  cohort,
  scheduledWeek,
  activeProgram
}: UseLessonNavInfoProps) => {
  return useMemo(() => {
    const { moduleSlug, lessonSlug, parentSlug, progress, isFirstLesson } =
      getLessonToDisplay(scheduledWeek, activeProgram)

    const url = makeLessonViewerUrl(
      cohort.slug,
      cohort.cmsProgram.slug,
      moduleSlug,
      parentSlug,
      lessonSlug
    )
    const navInfo = { url, label: '' }

    if (isFirstLesson && progress === 0) {
      navInfo.label = 'Start First Lesson'
    } else if (progress === 0) {
      navInfo.label = 'Next Lesson'
    } else if (progress > 0) {
      navInfo.label = 'Continue Lesson'
    }

    return navInfo
  }, [cohort.cmsProgram.slug, cohort.slug, scheduledWeek, activeProgram])
}
