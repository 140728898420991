import { format } from 'date-fns'
import pluralize from 'pluralize'
import { ReactNode } from 'react'
import { PopoverPosition } from 'react-tiny-popover'

import { getContributorTimedPositionAtCompany } from 'domains/Profile/utils'
import Image from 'domains/Sanity/Image'

import { FacePile } from 'components/FacePile'
import { Tag } from 'components/Tag'
import BaseCardGalleryIcon from 'components/icons/BaseCardGalleryIcon copy'
import BaseCardPlayIcon from 'components/icons/BaseCardPlayIcon'

import {
  BookmarkFolderPartsFragment,
  ConceptCurrentUserPartsFragment,
  CurrentUserPartsFragment,
  ProgramBookmarkPartsFragment
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { FULL_DATE_ABBREV_MONTH_FORMAT } from 'utils/date'
import { numberWithCommas } from 'utils/stringUtils'
import { trackContentClicked } from 'utils/tracking/analytics'

import BaseCard, { CardVariant, CardVariants } from './BaseCard'

export interface ArtifactCardProps {
  // TODO: Current Artifact card uses `any`, so we mirror that here, but the type
  // should be better defined
  artifact: any
  variant?: CardVariant
  currentUser?: CurrentUserPartsFragment | ConceptCurrentUserPartsFragment | null
  swimlaneSlug?: string
  historyState?: {}
  pageLocation?: string
  additionalRelatedIdentifiers?: {}
  isOnboardingFlow?: boolean
  // TODO: The current Artifact GraphQL type does not provide fields for views or comments, so
  // these are added to properties of the card. Depending on how the query is ultimately made, these
  // could be removed if the values become fields on the supplied artifact. If a method similar to
  // the saveCounts is employed, then keeping these as independent properties is appropriate.
  viewCount?: number
  commentCount?: number
  // Bookmark-related props
  bookmark?: ProgramBookmarkPartsFragment
  currentFolder?: BookmarkFolderPartsFragment | null
  bookmarkFolders?: BookmarkFolderPartsFragment[] | undefined
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  restoreBookmark?: (bookmark: ProgramBookmarkPartsFragment) => void
  handleRemoveFromFolder?: (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => Promise<string | null | undefined>
  hideBookmarkButton?: boolean
  customFooter?: ReactNode
  showPublishDateInFooter?: boolean
  bookmarkDropdownPosition?: PopoverPosition
  baseCardClassName?: string
  customHoverMiniCard?: boolean
  impressionTrackingProperties?: { [key: string]: any }
}

const ArtifactCard = ({
  artifact,
  variant = CardVariants.Vertical,
  pageLocation = 'artifact_index',
  bookmark,
  currentFolder,
  bookmarkFolders,
  openAddToBookmarkFolderModal,
  restoreBookmark,
  handleRemoveFromFolder,
  viewCount,
  commentCount,
  additionalRelatedIdentifiers,
  hideBookmarkButton,
  customFooter,
  showPublishDateInFooter,
  historyState,
  bookmarkDropdownPosition,
  baseCardClassName,
  customHoverMiniCard,
  impressionTrackingProperties
}: ArtifactCardProps) => {
  const { isLoggedIn, currentUser } = useCurrentUser()
  const destination = `/artifacts/${artifact.slug}`

  const relatedIdentifiers = {
    referrer: window.location.pathname,
    content_mode: 'async',
    ...additionalRelatedIdentifiers
  }

  const handleTracking = () => {
    trackContentClicked({
      content_sanity_id: artifact.id,
      content_title: artifact.title,
      content_type: 'artifact',
      path: destination,
      location: pageLocation,
      display_type: `${variant}_card`,
      logged_in: isLoggedIn,
      related_identifiers: relatedIdentifiers
    })
  }

  const showPremiumIcon =
    !artifact?.isFree && (!isLoggedIn || !!currentUser?.is?.premember)

  return (
    <BaseCard
      contentType="Artifact"
      variant={variant}
      sanityId={artifact.id || artifact.sanityId}
      title={artifact.title}
      header={<ArtifactHeader artifact={artifact} />}
      body={artifact.description}
      thumbnail={<Thumbnail artifact={artifact} />}
      horizontalThumbnail={<ArtifactHorizontalThumbnail artifact={artifact} />}
      verticalThumbnail={<ArtifactVerticalThumbnail artifact={artifact} />}
      impressionTrackingProperties={impressionTrackingProperties}
      footer={
        customFooter || (
          <ArtifactFooter
            showPublishDate={showPublishDateInFooter || false}
            artifact={artifact}
            viewCount={artifact.viewCount || viewCount}
            commentCount={artifact.commentCount || commentCount}
          />
        )
      }
      destination={destination}
      historyState={historyState}
      trackCardClick={handleTracking}
      bookmark={bookmark}
      currentFolder={currentFolder}
      bookmarkFolders={bookmarkFolders}
      openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
      restoreBookmark={restoreBookmark}
      handleRemoveFromFolder={handleRemoveFromFolder}
      hideBookmarkButton={hideBookmarkButton}
      bookmarkDropdownPosition={bookmarkDropdownPosition}
      className={baseCardClassName}
      customHoverMiniCard={customHoverMiniCard}
      showPremiumIcon={showPremiumIcon}
    />
  )
}

const ArtifactHeader = ({ artifact }: { artifact: any }) => {
  if (!artifact?.authors?.length) return null

  const author = artifact.authors[0]

  const facepileUsers = author
    ? [
        {
          id: author?.id,
          avatarUrl: author?.avatarUrl || author?.avatarPhoto?.imageUrl
        }
      ]
    : []

  return (
    <div className="flex gap-2">
      <div className="flex shrink-0 grow-0">
        <FacePile users={facepileUsers} imageSize="small" />
      </div>
      <div className="text-ellipsis line-clamp-1">
        {author?.name}{' '}
        <span className="text-rb-gray-300">
          {getContributorTimedPositionAtCompany(author)}
        </span>
      </div>
    </div>
  )
}

const ArtifactFormat = ({ format }: { format: string }) => {
  if (format === 'video') {
    return (
      <div className="absolute top-0 h-full w-full flex items-center justify-center">
        <BaseCardPlayIcon />
      </div>
    )
  }

  if (format === 'image') {
    return (
      <div className="absolute top-0 flex items-end justify-end w-full h-full p-2.5">
        <BaseCardGalleryIcon />
      </div>
    )
  }

  return null
}

const ArtifactVerticalThumbnail = ({ artifact }: { artifact: any }) => {
  if (artifact.thumbnailUrl) {
    return (
      <div className="relative h-full">
        {artifact.draftTemplateName && (
          <div className="absolute top-3 left-3">
            <Tag
              text="Draft this"
              className="bg-white px-3 py-0 rounded-full text-rb-gray-400 font-semibold"
            />
          </div>
        )}
        {artifact.companyLogoUrl && (
          <Image
            className="absolute left-3 bottom-3 rounded-md"
            src={artifact.companyLogoUrl}
            width="40"
            height="40"
            alt={artifact.companyName}
          />
        )}
        <Image
          className="h-full w-full rounded-t-xl object-cover"
          src={artifact.thumbnailUrl}
          alt={artifact.title || ''}
        />
        <ArtifactFormat format={artifact.format} />
      </div>
    )
  }

  return null
}

const ArtifactHorizontalThumbnail = ({ artifact }: { artifact: any }) => {
  if (artifact.thumbnailUrl) {
    return (
      <div className="relative h-full">
        {artifact.companyLogoUrl && (
          <Image
            className="absolute left-3 bottom-3 rounded-md"
            src={artifact.companyLogoUrl}
            width="40"
            height="40"
            alt={artifact.companyName}
          />
        )}
        <Image
          className="h-full w-full rounded-xl object-cover"
          src={artifact.thumbnailUrl}
          alt={artifact.title || ''}
        />
        <ArtifactFormat format={artifact.format} />
      </div>
    )
  }

  return null
}

const Thumbnail = ({ artifact }: { artifact: any }) => {
  if (artifact.thumbnailUrl) {
    return (
      <Image
        className="h-full w-full rounded-xl object-cover"
        src={artifact.thumbnailUrl}
        alt={artifact.title || ''}
      />
    )
  }
  return null
}

const ArtifactFooter = ({
  artifact,
  viewCount,
  commentCount,
  showPublishDate
}: {
  artifact: any
  viewCount?: number
  commentCount?: number
  showPublishDate?: boolean
}) => {
  return (
    <div className="whitespace-nowrap">
      {showPublishDate && (
        <span>
          Published{' '}
          {format(new Date(artifact.publishDate), FULL_DATE_ABBREV_MONTH_FORMAT)}
        </span>
      )}
      {showPublishDate && (!!viewCount || !!commentCount) && (
        <span className="px-1">·</span>
      )}
      {!!viewCount && (
        <span>
          {numberWithCommas(viewCount)} {pluralize('view', viewCount)}
        </span>
      )}
      {!!viewCount && !!commentCount && <span className="px-1">·</span>}
      {!!commentCount && (
        <span>
          {commentCount} {pluralize('comment', commentCount)}
        </span>
      )}
    </div>
  )
}

export default ArtifactCard
