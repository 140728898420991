import { cn } from '@/lib/utils'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { GoalSessionData } from 'pages/coaching/type'

import { useGlobalChat } from 'domains/Ai/GlobalChatProvider'

import { useUpdateCoachingSessionMutation } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { trackAiChatSessionOpened } from 'utils/tracking/analytics'

import { Todo } from './Todo'

type RecommendationsProps = {
  args: {
    activeSession?: GoalSessionData
    setActiveSession: React.Dispatch<React.SetStateAction<any>>
    allSessions: GoalSessionData[]
    refetchSessions: () => void
  }
}

export const Recommendations = ({
  args: { activeSession, setActiveSession, refetchSessions }
}: RecommendationsProps) => {
  const suggestedGoal = activeSession?.data?.goalName
  const steps: any[] = activeSession?.data?.steps || []
  const { setIsExpanded, setIsChatOpen, setMenuSideBarIsOpen, setMode, setChatId } =
    useGlobalChat()
  const [updateSession] = useUpdateCoachingSessionMutation()
  const { currentUser } = useCurrentUser()
  const { pathname } = useLocation()

  const handleOpenChat = async () => {
    const activeChatId = activeSession?.data?.chatId || uuid()
    const chatExists = !!activeSession?.data?.chatId

    if (!activeSession?.data?.chatId) {
      const payload = JSON.stringify({ setChatId: { value: activeChatId } })
      updateSession({
        variables: {
          input: {
            id: activeSession!.id,
            data: payload
          }
        }
      })

      const sessionData: any = {
        ...activeSession,
        data: { ...activeSession!.data, chatId: activeChatId }
      }

      setActiveSession(sessionData)
    }

    setMode({
      mode: 'default',
      modeOptions: {}
    })
    setChatId(activeChatId)
    setMenuSideBarIsOpen(true)
    setIsExpanded(true)
    setIsChatOpen(true)

    trackAiChatSessionOpened({
      mode: 'default',
      chat_session_type: chatExists ? 'existing' : 'new',
      access_policy_kind: currentUser?.accessPolicyKind,
      chat_session_id: activeChatId,
      is_draft: false,
      is_suggested_prompt: false,
      path: pathname,
      location: 'coaching__ai_chat_box',
      text: 'ask anything'
    })
  }

  return (
    <div className="font-sans rounded-xl p-6 w-full max-w-[800px] pt-12">
      <div className="flex flex-col items-start justify-start">
        <div className="mb-2 font-medium text-rb-gray-300 text-xl">Goal</div>
        <div className="font-medium text-rb-black text-[28px]">{suggestedGoal}</div>

        <div
          className="px-5 mt-10 mb-6 py-4 cursor-pointer flex items-center justify-between bg-white w-full rounded-xl shadow-coaching-input"
          onClick={handleOpenChat}
        >
          <span className="font-normal text-rb-gray-300">Ask anything</span>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <circle cx="16" cy="16" r="16" fill="#D5D5D5" />
            <path
              d="M16.4993 24L16.4993 10.5725L21.0379 15.1111L22 14.149L16.3111 8.46013L15.8301 8L15.349 8.46013L9.66014 14.149L10.6222 15.1111L15.1608 10.5725L15.1608 24L16.4993 24Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div className="text-base py-6 font-semibold">Step-by-step plan</div>
      {steps.map((step, index) => (
        <div className={cn('pb-6', steps.length - 1 === index && 'pb-20')} key={index}>
          <Todo
            data={{
              activeSession: activeSession!,
              step,
              stepIndex: index,
              refetchSessions,
              sessionId: Number(activeSession?.id)
            }}
          />
        </div>
      ))}
    </div>
  )
}
