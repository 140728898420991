import { sortBy, split } from 'lodash'

import {
  CclCourseCourseCardPartsFragment,
  CclCourseSessionCourseCardPartsFragment,
  MarketingCourseCourseCardPartsFragment
} from 'gql'

import { getDurationInMinutes } from 'utils/date'

export const COURSE_SORT_TYPE_START_DATE = 'Start date'
export const COURSE_SORT_TYPE_DURATION = 'Duration'
export const COURSE_SORT_TYPE_ALPHA = 'Alphabetical'

const sortByStartsAtFn = (
  a: CclCourseCourseCardPartsFragment,
  b: CclCourseCourseCardPartsFragment
) => {
  const aUpcomingSessions: CclCourseSessionCourseCardPartsFragment[] =
    a?.upcomingSessions || []
  const bUpcomingSessions: CclCourseSessionCourseCardPartsFragment[] =
    b?.upcomingSessions || []

  const aStartsAt = aUpcomingSessions[0].startsAt
  const bStartsAt = bUpcomingSessions[0].startsAt

  if (!aStartsAt || !bStartsAt) {
    return 0
  }

  return getDurationInMinutes(bStartsAt, aStartsAt)
}

const sortByWeekDurationFn = (
  a: CclCourseCourseCardPartsFragment,
  b: CclCourseCourseCardPartsFragment
) => {
  const aUpcomingSessions: CclCourseSessionCourseCardPartsFragment[] =
    a?.upcomingSessions || []
  const bUpcomingSessions: CclCourseSessionCourseCardPartsFragment[] =
    b?.upcomingSessions || []

  const aNumWeeksStr = split(aUpcomingSessions[0].duration || '', ' ')?.[0]
  const bNumWeeksStr = split(bUpcomingSessions[0].duration || '', ' ')?.[0]
  if (!aNumWeeksStr || !bNumWeeksStr) {
    return 0
  }

  const aNumWeeks = parseInt(aNumWeeksStr)
  const bNumWeeks = parseInt(bNumWeeksStr)

  return aNumWeeks - bNumWeeks
}

const separateUpcomingCourses = (courses: CclCourseCourseCardPartsFragment[]) => {
  const upcomingCourses: CclCourseCourseCardPartsFragment[] = []
  const noUpcomingCourses: CclCourseCourseCardPartsFragment[] = []

  courses.forEach((cclCourse) => {
    const hasLiveUpcomingCourseSession = !!cclCourse.upcomingSessions?.length

    if (hasLiveUpcomingCourseSession) {
      upcomingCourses.push(cclCourse)
    } else {
      noUpcomingCourses.push(cclCourse)
    }
  })

  return {
    upcomingCourses,
    noUpcomingCourses
  }
}

export const sortCourses = ({
  courses,
  selectedSortType
}: {
  courses: CclCourseCourseCardPartsFragment[]
  selectedSortType: string
}): (CclCourseCourseCardPartsFragment | MarketingCourseCourseCardPartsFragment)[] => {
  const allLiveCourses = [...courses]
  if (selectedSortType === COURSE_SORT_TYPE_START_DATE) {
    const { upcomingCourses, noUpcomingCourses } = separateUpcomingCourses(allLiveCourses)

    return [
      ...upcomingCourses.sort(sortByStartsAtFn),
      // sort rest (since have no start date) by title (COURSE_SORT_TYPE_ALPHA)
      ...sortBy([...noUpcomingCourses], 'title')
    ]
  } else if (selectedSortType === COURSE_SORT_TYPE_DURATION) {
    const { upcomingCourses, noUpcomingCourses } = separateUpcomingCourses(allLiveCourses)

    return [
      ...upcomingCourses.sort(sortByWeekDurationFn),
      // sort rest (since have no duration) by title (COURSE_SORT_TYPE_ALPHA)
      ...sortBy([...noUpcomingCourses], 'title')
    ]
  }

  // COURSE_SORT_TYPE_ALPHA
  return sortBy([...allLiveCourses], 'title')
}
