export interface LosingCohortAccessInfoCardProps {
  userEnrolledInCohort?: boolean
  firstName?: string | null
}
/**
 * LosingCohortAccessInfoCard
 * presentational component
 * used in ManageSeatSidePanel/CohortAccessPanelContent
 * */

export function LosingCohortAccessInfoCard({
  userEnrolledInCohort = false,
  firstName
}: LosingCohortAccessInfoCardProps) {
  const firstNameOrGeneric = firstName || 'this member'
  return (
    <div
      className="rounded-md p-3.5 text-base font-normal"
      data-testid="losing-cohort-access-info-card"
    >
      {userEnrolledInCohort ? (
        <p className="m-0">
          &#9888;&#65039; You can&apos;t unassign this member&apos;s Live Course Passes
          because they are already enrolled in a live course.
        </p>
      ) : (
        <>
          <p className="m-0">
            {`If you remove this Live Course Pass, ${firstNameOrGeneric} will not be able to enroll in a live course.`}
          </p>
          <p className="mt-3">
            You will be able to apply this Live Course Pass to another member.
          </p>
        </>
      )}
    </div>
  )
}
