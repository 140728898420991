import MuxPlayer from '@mux/mux-player-react'
import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { useSnapshot } from 'valtio'

import {
  useAddAnnotationReactionMutation,
  useRemoveAnnotationReactionMutation
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { trackContentClicked, trackVideoPlayed } from 'utils/tracking/analytics'

import { artifactState } from 'stores'

import BlockContent from './BlockContent'
import { CopyLinkButton } from './CopyLinkButton'
import ReactionBar from './ReactionBar'

const AnnotationViewer = ({
  annotation,
  artifact,
  active = false,
  isImageZoomOverlay = false,
  primaryAuthor = undefined
}: {
  annotation: any
  artifact?: any
  active?: boolean
  isImageZoomOverlay?: boolean
  primaryAuthor?: any
}) => {
  const { isLoggedIn, currentUser: user } = useCurrentUser()
  const history = useHistory()
  const [wasLinkCopied, setWasLinkCopied] = useState(false)

  const { welcomeMode } = useSnapshot(artifactState)

  const [addAnnotationReaction] = useAddAnnotationReactionMutation()
  const [removeArtifactReaction] = useRemoveAnnotationReactionMutation()

  const artifactData = artifact || annotation?.artifact

  useEffect(() => {
    if (active) {
      trackContentClicked({
        content_sanity_id: annotation.id,
        content_type: 'artifact_annotation'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  useEffect(() => {
    if (wasLinkCopied) {
      const timer = setTimeout(() => setWasLinkCopied(false), 4000)
      return () => clearTimeout(timer)
    }
  }, [wasLinkCopied])

  if (!annotation) return null

  // TODO: Add tracking for video pause, end, etc.
  const onPlay = () => {
    trackVideoPlayed({
      object_id: annotation?.id,
      object_type: 'artifact_annotation',
      related_identifiers: {
        artifact_id: artifact?.id,
        artifact_title: artifact?.title
      }
    })
  }

  const handleAnnotationClick = () => {
    if (typeof window === 'undefined' || active) return
    return history.push({ hash: `${annotation.id}` })
  }

  const getNoteMetadata = () => {
    return {
      artifactId: artifactData.id,
      artifactTitle: artifactData.title,
      annotationId: annotation.id,
      annotationTitle: annotation.title,
      annotationType: annotation.annotationType
    }
  }

  const handleReactionAdded = async (reactionId: any) => {
    if (!isLoggedIn) return

    const noteMetadata = getNoteMetadata()

    await addAnnotationReaction({
      variables: {
        input: {
          artifactId: noteMetadata.artifactId,
          artifactTitle: noteMetadata.artifactTitle,
          annotationId: noteMetadata.annotationId,
          userId: user?.id || '',
          reaction: reactionId
        }
      },
      refetchQueries: ['ArtifactAnnotations']
    })
  }

  const handleReactionRemoved = async (reactionId: any) => {
    if (!isLoggedIn) return

    const noteMetadata = getNoteMetadata()

    await removeArtifactReaction({
      variables: {
        input: {
          artifactId: noteMetadata.artifactId,
          artifactTitle: noteMetadata.artifactTitle,
          annotationId: noteMetadata.annotationId,
          userId: user?.id || '',
          reaction: reactionId
        }
      },
      refetchQueries: ['ArtifactAnnotations']
    })
  }

  const handleReactionBarClick = (e: any) => {
    // prevent annotation click for this case
    e.stopPropagation()
  }

  const author = primaryAuthor
  const authorFirstName = author?.name?.split(' ')[0]
  const authorSlug = author?.slug
  const tooltipId = `copy-${annotation.id}`

  return (
    <div
      className="mt-[15px]"
      data-annotation-viewer-hash={annotation.id}
      onClick={handleAnnotationClick}
      onKeyDown={handleAnnotationClick}
      role="button"
      tabIndex={0}
    >
      <div
        className={twMerge(
          'relative',
          welcomeMode.annotationId === annotation.id && 'z-3'
        )}
      >
        <div
          className={`${
            active ? 'border-[#CADD48]' : ''
          } border bg-rb-white p-6 transition-shadow hover:shadow-md`}
        >
          <div className="artifact--artifact-annotations-body w-full break-words text-sm">
            <div
              className={twMerge(
                'mb-[15px] flex flex-row items-center justify-between text-base font-semibold text-rb-teal-300',
                isImageZoomOverlay && 'justify-end'
              )}
            >
              {Boolean(author) && (
                <div className="flex grow flex-row">
                  <div className="mr-[12px] w-[30px]">
                    {author.avatarUrl && (
                      <img
                        alt={author.name}
                        className="rounded-full border-2 border-rb-teal-200 object-cover"
                        src={author.avatarUrl}
                        height="30"
                        width="30"
                      />
                    )}
                  </div>
                  <div className="h-[30px] leading-[30px] text-black">
                    {isLoggedIn && (
                      <>
                        <Link
                          className="text-black hover:text-black hover:underline"
                          to={`/profiles/${authorSlug}`}
                        >
                          {authorFirstName}
                        </Link>{' '}
                        says:
                      </>
                    )}
                    {!isLoggedIn && <>{authorFirstName} says:</>}
                  </div>
                </div>
              )}
              {isLoggedIn && (
                <div className="">
                  <CopyLinkButton
                    tooltipId={tooltipId}
                    tooltipTextInit="Copy link to this note"
                    textToCopy={`${window.location.origin + window.location.pathname}#${
                      annotation.id
                    }`}
                    trackingData={{
                      content_link_id: annotation.id,
                      content_link_type: 'artifact_annotation',
                      related_identifiers: {
                        artifact_id: artifact?.id,
                        artifact_title: artifact?.title ?? undefined
                      }
                    }}
                  />
                </div>
              )}
            </div>

            {!!annotation.video && (
              <div className="relative z-0 my-[10px]">
                <MuxPlayer
                  playbackId={annotation.video.playbackId}
                  onPlay={onPlay}
                  streamType="on-demand"
                  thumbnailTime={annotation.video.thumbTime}
                />
              </div>
            )}

            <BlockContent blocks={annotation.descriptionBody} />

            <div
              className="mt-[21px] flex flex-row text-sm"
              onClick={handleReactionBarClick}
              onKeyDown={handleReactionBarClick}
              role="button"
              tabIndex={0}
            >
              {isLoggedIn && (
                <ReactionBar
                  id={`reaction-bar-note-${annotation.id}${
                    isImageZoomOverlay ? '-image-zoom-overlay' : ''
                  }`}
                  reactions={annotation.reactionsGroupedByName}
                  onReactionAdded={handleReactionAdded}
                  onReactionRemoved={handleReactionRemoved}
                />
              )}
              <div className="grow" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnnotationViewer
