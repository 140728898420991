import { useEffect, useRef, useState } from 'react'
import UIkit from 'uikit'

import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'
import Loading from 'components/Loading'

import { useAddEmailToUserMutation } from 'gql'

import { isValidEmail } from 'utils/emailUtils'
import { trackCtaClicked } from 'utils/tracking/analytics'

interface EmailAddFormProps {
  fetchData: () => void
}

const EmailAddForm = ({ fetchData }: EmailAddFormProps) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const emailInputRef = useRef<HTMLInputElement | null>(null)
  const [addEmailToUserMutation, { error }] = useAddEmailToUserMutation()

  useEffect(() => {
    if (error) {
      UIkit.notification(error.message, { status: 'danger', pos: 'top-right' })
      setLoading(false)
    }
  }, [error])

  const onSubmit = async () => {
    if (!isValid()) return

    setLoading(true)

    await addEmailToUserMutation({ variables: { input: { email } } })
    UIkit.notification('Verification Email Sent', {
      status: 'success',
      pos: 'top-right'
    })
    setLoading(false)
    setOpen(false)
    setEmail('')
    fetchData()
  }

  const isValid = () => {
    if (email.length < 4) {
      UIkit.notification('Email is too short', { status: 'danger', pos: 'top-right' })
      return false
    }
    if (!isValidEmail(email)) {
      UIkit.notification('Our robots tell us this is an invalid email address.', {
        status: 'danger',
        pos: 'top-right'
      })
      return false
    }
    return true
  }

  const toggleAddEmail = () => {
    trackCtaClicked({
      cta_location: 'account_page_connected_accounts_card',
      cta_type: 'button',
      text: 'add email address'
    })
    setOpen(!open)
  }

  useEffect(() => {
    if (open) {
      emailInputRef.current?.focus()
    }
  }, [open])

  if (loading) return <Loading />

  return (
    <>
      {!open && (
        <a
          onClick={toggleAddEmail}
          className="uk-link-reset flex items-center text-xs font-medium uppercase tracking-widest"
        >
          <SVGIcon
            name="plus-in-square"
            fill="#000000"
            className="uk-margin-small-left mr-1 stroke-current"
          />
          Add Email Address
        </a>
      )}

      {open && (
        <div>
          <input
            className="uk-input text-m-small sm:text-m-medium"
            name="email-input"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            ref={emailInputRef}
          />

          <div className="mt-3 flex items-center justify-end">
            <Button size="x-small" color="teal" shape="rounded-full" onClick={onSubmit}>
              Save Email
            </Button>

            <Button size="x-small" variant="text-only" onClick={toggleAddEmail}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default EmailAddForm
