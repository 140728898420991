import { PortableTextBlock } from '@portabletext/types'
import { ReactComponent as MinusIcon } from 'icon--minus-sign.svg'
import { ReactComponent as PlusIcon } from 'icon--plus-sign.svg'
import React, { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { UnitComplexPortableText } from 'domains/Sanity/PortableText/UnitComplexPortableText/UnitComplexPortableText'
import { UnitContext } from 'domains/Unit/contexts/UnitContext'

import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphMediumSemiBold from 'components/typography/RfParagraph/RfParagraphMediumSemiBold'

import {
  ContentBlock,
  ContentBlockCollapsible,
  CoursePageAccessPartsFragment,
  CourseSessionAccessPartsFragment,
  Unit
} from 'gql'

import { useContentMode } from 'utils/contentTrackingUtils'
import { trackVideoPlayed } from 'utils/tracking/analytics'

import { CopyAnchorLinkButton } from './CopyAnchorLinkButton'
import { UnitBlockContainer } from './UnitBlockContainer'

export interface CollapsibleContentBlockProps {
  type: string
  label?: string | null
  description: PortableTextBlock[]
  isExpanded?: boolean
  contentBlock?: ContentBlock | null
}

export const CollapsibleContentBlock = ({
  type,
  label,
  description,
  isExpanded = false,
  contentBlock = null
}: CollapsibleContentBlockProps) => {
  const [isOpen, setIsOpen] = useState(isExpanded)
  const toggleIsOpen = () => {
    if (contentBlock) {
      window.analytics.track('Expand Click', {
        location: 'guide',
        expandable_section_type: type,
        expandable_section_title: label,
        related_identifiers: {
          content_type: 'guide',
          content_block_id: contentBlock.id,
          content_block_title: contentBlock.header,
          content_block_number: contentBlock.order,
          content_sanity_id: contentBlock.id,
          content_mode: 'async'
        }
      })
    }

    setIsOpen(!isOpen)
  }

  const onKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      e.preventDefault()
      setIsOpen(!isOpen)
    }
  }

  const labelText = () => {
    if (label) {
      return (
        <div className="flex flex-row gap-2">
          <RfParagraphMediumSemiBold className="!mb-0">{type}</RfParagraphMediumSemiBold>
          <RfParagraphMedium>
            <span className="text-gray-300">|</span>
          </RfParagraphMedium>
          <RfParagraphMedium className="pr-1 line-clamp-1">{label}</RfParagraphMedium>
        </div>
      )
    }
    return <RfParagraphMediumSemiBold className="!mb-0">{type}</RfParagraphMediumSemiBold>
  }

  return (
    <div
      className={twMerge(
        'rounded-2xl border border-gray-200 bg-white p-4',
        isOpen && 'border-rb-gray-500'
      )}
    >
      <div
        onClick={toggleIsOpen}
        data-test="unit_expandable_section"
        onKeyUp={onKeyUp}
        role="button"
        tabIndex={0}
      >
        <div className="flex flex-row">
          {labelText()}
          <div className="my-auto ml-auto flex h-6 w-6 items-center justify-center">
            {isOpen ? (
              <span data-test="unit_expandable_section_minus_icon">
                <MinusIcon width={16} height={16} />
              </span>
            ) : (
              <span data-test="unit_expandable_section_plus_icon">
                <PlusIcon width={16} height={16} />
              </span>
            )}
          </div>
        </div>
      </div>
      <div data-test="unit_expandable_section_content" hidden={!isOpen} className="pt-6">
        <UnitComplexPortableText content={description} />
      </div>
    </div>
  )
}

export const typeForDisplay = (type?: string | null) => {
  if (!type) {
    return ''
  }

  if (type === 'Examples') {
    return 'Example'
  }

  return type
}

interface UnitContentBlockProps {
  contentBlock: ContentBlock
  className?: string | null
  unit: Unit
  course?: CoursePageAccessPartsFragment | null
  courseSession?: CourseSessionAccessPartsFragment | null
}

export const UnitContentBlock = ({
  contentBlock,
  unit,
  course,
  courseSession,
  className
}: UnitContentBlockProps) => {
  const contentMode = useContentMode({ courseSession })

  if (!contentBlock.order) return null
  const unitContextData = {
    content_sanity_id: unit.id,
    content_type: 'guide',
    content_title: unit.title,
    contentBlock: contentBlock,
    content_block_id: contentBlock.id,
    content_block_title: contentBlock.header,
    content_block_number: contentBlock.order
  }

  const trackVideoPlay = () =>
    trackVideoPlayed({
      object_id: unit.id,
      object_type: 'guide',
      path: window.location.pathname,
      related_identifiers: {
        course_id: course?.id,
        related_identifier_content_mode: contentMode,
        course_session_id: courseSession?.id,
        course_title: course?.title
      },
      url: window.location.href
    })
  return (
    <UnitBlockContainer
      data-test="unit_content_block"
      className={twMerge(
        'flex w-full max-w-[918px] scroll-mt-24 flex-col space-y-4',
        className
      )}
      id={contentBlock.slug || contentBlock.id}
    >
      <div className="flex items-center">
        <div className="mr-5 flex h-12 w-full max-w-[48px] items-center justify-center rounded-xl bg-[#E4E5E3]">
          <RfHeader3SemiBold className="!mb-0">{contentBlock.order}</RfHeader3SemiBold>
        </div>
        <RfHeader3SemiBold className="!mb-0">{contentBlock.header}</RfHeader3SemiBold>
        <CopyAnchorLinkButton
          contentBlock={contentBlock}
          anchorSlug={contentBlock.slug || contentBlock.id || ''}
          className="ml-auto"
        />
      </div>
      <UnitContext.Provider value={{ unitContextData }}>
        <UnitComplexPortableText
          trackVideoPlay={trackVideoPlay}
          content={JSON.parse(contentBlock.description)}
          forcePhotoLoad
        />
      </UnitContext.Provider>
      <div className="flex flex-col space-y-6">
        {contentBlock.contentBlockCollapsible &&
          contentBlock.contentBlockCollapsible.map(
            (unitContentBlockCollapsible: ContentBlockCollapsible, i: number) => (
              <CollapsibleContentBlock
                contentBlock={contentBlock}
                key={i}
                type={typeForDisplay(unitContentBlockCollapsible.type)}
                label={unitContentBlockCollapsible.label}
                description={JSON.parse(unitContentBlockCollapsible.description)}
              />
            )
          )}
      </div>
    </UnitBlockContainer>
  )
}
