import { useMemo } from 'react'

import { useRecentlyViewedContentQuery } from 'gql'

import { SearchComboboxOptions, type SearchOption } from './SearchCombobox'

export function SearchRecentlyViewedContext() {
  const recentlyViewedOptions = useRecentlyViewed()

  return <SearchComboboxOptions title="Recently Viewed" options={recentlyViewedOptions} />
}

function useRecentlyViewed(): SearchOption[] {
  const { data } = useRecentlyViewedContentQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'standby'
  })

  const recentlyViewed = useMemo(
    () =>
      data?.recentlyViewedContent?.map((rc) => ({
        value: rc.name,
        path: rc.path,
        origin: 'recently-viewed' as const
      })) || [],
    [data?.recentlyViewedContent]
  )

  return recentlyViewed
}
