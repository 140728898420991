import WeeklyBlockChecklistCard from 'domains/CohortDashboard/CohortDashboardProgram/CohortDashboardWeeklyBlock/WeeklyBlockChecklist/WeeklyBlockChecklistCard'

import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

export interface BulkRsvpChecklistCardProps {
  bulkRsvpChecked?: boolean
  handleRsvpClick: () => void
}

export const BulkRsvpChecklistCard = ({
  handleRsvpClick,
  bulkRsvpChecked = false
}: BulkRsvpChecklistCardProps) => {
  const rsvpTitle = <RfParagraphMedium>RSVP to your course events</RfParagraphMedium>

  return (
    <div
      onClick={handleRsvpClick}
      onKeyDown={handleRsvpClick}
      role="button"
      tabIndex={0}
      data-test-id="bulk-rsvp-checklist-card"
    >
      <WeeklyBlockChecklistCard titleComponent={rsvpTitle} checked={bulkRsvpChecked} />
    </div>
  )
}

export default BulkRsvpChecklistCard
