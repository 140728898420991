import clsx from 'clsx'

export default function SkeletonMemberCard({ className = '' }: { className?: string }) {
  return (
    <div
      className={clsx(
        'flex animate-pulse gap-3 rounded-md border border-rb-gray-100 bg-white px-4 py-5',
        className
      )}
    >
      <div className="rounded-full bg-rb-gray-100 p-7" />
      <div className="flex h-full w-full flex-col gap-3">
        <div className="h-4 w-1/2 rounded-full bg-rb-gray-100" />
        <div className="h-2 w-4/5 rounded-full bg-rb-gray-100" />
        <div className="h-2 w-4/5 rounded-full bg-rb-gray-100" />
      </div>
    </div>
  )
}
