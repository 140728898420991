import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { twMerge } from 'tailwind-merge'

import {
  SearchCombobox,
  SearchInitialContext,
  SearchOption,
  SearchRecentlyViewedContext
} from 'domains/Search/SearchCombobox'

import {
  ProductTourKey,
  useCompleteProductTourMutation,
  useCompletedProductToursQuery,
  useSearchSuggestionsQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useURLParams from 'hooks/useURLParams'

import { trackCtaClicked } from 'utils/tracking/analytics'

import { SearchSuggestionsContext } from '../SearchCombobox/SearchSuggestionsContext'

interface SearchFieldProps {
  className?: string
  searchType?: string
  placeholder?: string
  shouldAlwaysShowFull?: boolean
  dropdownClassName?: string
  inputClassName?: string
}

export function SearchField({
  className,
  searchType,
  placeholder = 'Search Reforge',
  shouldAlwaysShowFull,
  dropdownClassName,
  inputClassName
}: SearchFieldProps) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { showSearchTrainer, registerSearchTrainerView } = useSearchTrainer()
  const { q: initialQuery, type: searchTypeParam } = useSearchParams()
  const [query, setQuery] = useState(initialQuery)
  const { data, previousData } = useSearchSuggestionsQuery({
    variables: { query: query },
    skip: query.length < 2,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  })

  const searchSuggestions =
    data?.searchSuggestions || previousData?.searchSuggestions || []

  useEffect(() => {
    if (initialQuery && initialQuery !== query) {
      setQuery(initialQuery)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialQuery])

  const handleSearch = (queryOption: SearchOption) => {
    // increment search trainer counter
    registerSearchTrainerView()
    if (queryOption.value.length < 1) {
      return
    }

    if (queryOption.path) {
      trackCtaClicked({
        destination: queryOption.path,
        cta_location: 'search dropdown',
        cta_type: 'link',
        related_identifiers: {
          origin: queryOption.origin
        },
        text: queryOption.value
      })
      return navigate(queryOption.path)
    }

    const queryParams = new URLSearchParams()
    queryParams.set('q', queryOption.value)
    queryParams.set('path', window.location.pathname || '/')
    if (searchType) {
      queryParams.set('type', searchType)
    } else if (searchTypeParam) {
      queryParams.set('type', searchTypeParam)
    }

    const url = `/search?${queryParams.toString()}`

    // when user types in the search field, we don't want to track the click
    if (queryOption.origin !== 'user-input') {
      trackCtaClicked({
        destination: url,
        cta_location: 'search dropdown',
        cta_type: 'link',
        related_identifiers: {
          origin: queryOption.origin
        },
        text: queryOption.value
      })
    }

    navigate(url)
  }

  let context = null
  if (searchType) {
    context = null
  } else if (showSearchTrainer) {
    context = (
      <>
        <SearchSuggestionsContext key="suggestions" suggestions={searchSuggestions} />
        {(!query || query === '' || !searchSuggestions.length) && (
          <SearchInitialContext />
        )}
      </>
    )
  } else {
    context = (
      <>
        <SearchSuggestionsContext key="suggestions" suggestions={searchSuggestions} />
        {(!query || query === '' || !searchSuggestions.length) && (
          <SearchRecentlyViewedContext />
        )}
      </>
    )
  }

  useEffect(() => {
    if (!pathname.startsWith('/search')) {
      setQuery('')
    }
  }, [pathname])

  return (
    <div className={twMerge(className)}>
      <SearchCombobox
        query={query}
        initialQuery={initialQuery}
        className="w-full"
        shouldAlwaysShowFull={shouldAlwaysShowFull}
        dropdownClassName={dropdownClassName}
        placeholder={placeholder}
        onSearch={handleSearch}
        context={context}
        onChange={setQuery}
        inputClassName={inputClassName}
        // onFocus={...shouldAlwaysShowFull}
      />
    </div>
  )
}

function useSearchParams() {
  const { getParam } = useURLParams()
  const q = getParam('q', '')
  const type = getParam('type', '')

  return {
    q,
    type
  }
}

const SEARCH_TRAINER_TRERSHOLD = 5
function useSearchTrainer() {
  const { data } = useCompletedProductToursQuery()
  const [completeProductTour] = useCompleteProductTourMutation()
  const { isLoggedIn } = useCurrentUser()

  const productTour = data?.currentUser?.completedProductTours
  const showSearchTrainer =
    (productTour?.searchTrainerViews || 0) < SEARCH_TRAINER_TRERSHOLD

  const registerSearchTrainerView = useCallback(() => {
    if (!showSearchTrainer || !isLoggedIn) return

    completeProductTour({
      variables: {
        input: {
          productTourKey: ProductTourKey.SEARCH_TRAINER_VIEWS
        }
      }
    })
  }, [showSearchTrainer, isLoggedIn, completeProductTour])

  return { showSearchTrainer, registerSearchTrainerView }
}
