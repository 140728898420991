import { ReactNode } from 'react'

interface TableProps {
  tableName: string
  headers: (string | ReactNode)[]
  rowData: (string | number | ReactNode)[][]
}

export const Table = ({ tableName, headers, rowData }: TableProps) => {
  const rows = rowData.map((row, index) => {
    const cells = row.map((cell, index) => {
      const extraClassName = index > 1 ? 'pl-14' : ''
      return (
        <td key={index} className={`px-8 text-sm ${extraClassName}`}>
          {cell === '0' || cell === 0 ? '' : cell}
        </td>
      )
    })

    return (
      <tr key={index} className="h-[3.75rem]">
        {cells}
      </tr>
    )
  })

  return (
    <table
      data-test={`${tableName}-table`}
      className="min-w-full table-auto border-collapse divide-y divide-rb-gray-100"
    >
      <thead className="sticky top-0 z-10 bg-white text-left text-rb-gray-300">
        <tr>
          {headers.map((header, index) => {
            return (
              <th key={index} className="border-b px-8 pt-10 pb-4 text-sm font-semibold">
                {header}
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody className="divide-y divide-rb-gray-100">{rows}</tbody>
    </table>
  )
}
