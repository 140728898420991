import * as React from 'react'

import {
  ProgramCardActiveSeasonPartsFragment,
  ProgramCardPartsFragment,
  ProgramCardUserPartsFragment
} from 'gql'

import useOpenPurchaseFlow from 'hooks/useOpenPurchaseFlow'

import { formatInTimezone, getTimezoneAbbreviation } from 'utils/date'
import { getPageFromPath } from 'utils/location'
import { track } from 'utils/tracking/analytics'

export interface HoverContentProps {
  program: ProgramCardPartsFragment
  currentUser: ProgramCardUserPartsFragment
  enrollmentSeason?: ProgramCardActiveSeasonPartsFragment | null
  onProgramHoverEnrollClick?: (id: string) => void
  sourceFlow?: string
}

const HoverContent = ({
  currentUser,
  program,
  enrollmentSeason,
  onProgramHoverEnrollClick,
  sourceFlow
}: HoverContentProps) => {
  const { openPurchaseFlow } = useOpenPurchaseFlow()
  const openForEnrollment = enrollmentSeason && enrollmentSeason.openForEnrollment
  const formattedEnrollementsOpen =
    enrollmentSeason &&
    enrollmentSeason.enrollmentsOpenAt &&
    formatInTimezone(
      enrollmentSeason.enrollmentsOpenAt,
      currentUser.timezone,
      "MMMM d 'at' haaaaa'm'"
    )
  const formattedSeasonStart =
    enrollmentSeason &&
    enrollmentSeason.startsAt &&
    formatInTimezone(enrollmentSeason.startsAt, currentUser.timezone, 'MMMM d')

  const customHoverText = !program.canEnroll
    ? 'This program is not currently being offered live, but the content is always available on demand.'
    : null

  const upcoming = (
    <span>
      This is a brand new program.
      {openForEnrollment
        ? ' Content '
        : ` You can reserve a seat beginning ${formattedEnrollementsOpen} ${getTimezoneAbbreviation(
            currentUser.timezone,
            enrollmentSeason?.startsAt
          )} and content `}
      will become available
      {formattedSeasonStart ? ` ${formattedSeasonStart}` : ' soon'}.
    </span>
  )
  const unenrolled = formattedSeasonStart ? (
    <span>
      You can now reserve your seat for the live program kicking off the week of{' '}
      {formattedSeasonStart}.
    </span>
  ) : (
    <span>You can reserve your seat soon</span>
  )

  const getMemberContentText = () => {
    if (customHoverText) {
      return customHoverText
    }

    return program.upcoming ? upcoming : unenrolled
  }

  const redirectToEnrollment = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    if (enrollmentSeason && program && program.id) {
      // @ts-ignore - 'Enroll Hover - Action' event is not defined in Segment JIRA#REF-5159
      track('Enroll Hover - Action', {
        action: 'clicks enroll button',
        user_id: currentUser.id,
        cms_program_id: program.id,
        source_flow: sourceFlow,
        location: getPageFromPath(),
        season_name: `${enrollmentSeason.year}-${enrollmentSeason.name}`
      })
    }

    if (onProgramHoverEnrollClick) {
      onProgramHoverEnrollClick(program.id)
    } else {
      window.location.href = `/?enroll=${program.id || 'true'}`
    }
  }

  const showEnrollButton =
    openForEnrollment &&
    program.canEnroll &&
    !customHoverText &&
    currentUser.is.paidMember &&
    !program.enrolled

  const shownEnrollOnHomePage = () => {
    if (showEnrollButton) {
      // @ts-ignore - 'Shown Enroll Hover On Home Page' event is not defined in Segment JIRA#REF-5159
      track('Shown Enroll Hover On Home Page', {
        source_flow: 'Program Card on Home Page',
        location: getPageFromPath(),
        season_name: `${enrollmentSeason.year}-${enrollmentSeason.name}`,
        user_id: currentUser.id
      })
    }
  }

  const memberContent = (
    <div className="mx-3.5 text-center">
      <div className="text-left">{getMemberContentText()}</div>
      {showEnrollButton && (
        <button
          data-test={`hover-content-enroll-button-${program.id}`}
          className="mt-4 h-8 rounded-3xl bg-white px-8 text-xs font-semibold uppercase tracking-vast text-rb-gray-400"
          onClick={redirectToEnrollment}
        >
          ENROLL
        </button>
      )}
    </div>
  )

  const nonmemberContent = (
    <div className="text-center">
      <div className="mx-5">
        Become a member to get access to all content across all programs.
      </div>
      <button
        className="mt-4 h-8 rounded-3xl bg-white px-8 text-xs font-semibold uppercase tracking-vast text-rb-gray-400"
        onClick={openPurchaseFlow}
      >
        Become a Member
      </button>
    </div>
  )

  shownEnrollOnHomePage()

  return (
    <div className="absolute bottom-0 left-0 right-0 z-100 flex flex-1 rotate-0 flex-col items-center justify-center whitespace-pre-line bg-rb-gray-400 bg-opacity-90 p-2 text-sm text-white">
      {currentUser.is.member ? memberContent : nonmemberContent}
    </div>
  )
}

export default HoverContent
