import { useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import ModuleCardRow from 'domains/CohortViewer/ModuleCardRow'

import { SVGIcon } from 'components/Icon'
import Popover from 'components/Popover/Popover'
import { Tag } from 'components/Tag'
import LegacyContentCard from 'components/cards/Content/LegacyContentCard'

import { CohortViewerMaterialCmsModulePartsFragment, CurrentUserPartsFragment } from 'gql'

import { FULL_DATE_ABBREV_MONTH_FORMAT, formatInTimezone } from 'utils/date'
import { onEnterKeyPress } from 'utils/keyboard'
import { cn } from 'utils/tailwind'
import { trackNavigationElementToggled } from 'utils/tracking/analytics'

import { ProgramProgressScalar } from 'typings/scalars'

interface ModuleSectionProps {
  cmsModule: CohortViewerMaterialCmsModulePartsFragment
  currentModuleId?: number
  numSectionBookmarks: Record<number | string, number>
  numModuleBookmarks: Record<number | string, number>
  currentUser?: CurrentUserPartsFragment
  userProgress: ProgramProgressScalar
  startsOpen: boolean | undefined
}

// TODO: Refactor to a util
export const getTimeInWords = (t: number) => {
  let timeInWords = ''
  if (t > 59) {
    const timeInHours = Math.round((t * 2) / 60) / 2
    timeInWords = `${timeInHours} hrs`
  } else if (t > 0) {
    timeInWords = `${t} min`
  }
  return timeInWords
}

const ModuleSection = ({
  cmsModule,
  currentUser,
  numSectionBookmarks,
  numModuleBookmarks,
  userProgress,
  startsOpen
}: ModuleSectionProps) => {
  const ref = useRef(null) as any
  const [moduleOpen, setModuleOpen] = useState(startsOpen)
  const isPremember = !currentUser || currentUser?.is.premember

  // Show modified content on the programs page to accepted users vs paid members.
  const showMemberContent = !!currentUser?.is.member

  const percentComplete =
    (userProgress && (userProgress[cmsModule.id]?.percent_completed as number)) || 0

  useEffect(() => {
    if (moduleOpen) {
      const moduleDom = document.getElementById(`cohortViewerModule${cmsModule.id}`)
      const cohortHeader = document.getElementById('cohort-viewer-header-wrapper')

      if (moduleDom && cohortHeader) {
        document.getElementById('page')?.scrollTo({
          left: 0,
          top: moduleDom.offsetTop - cohortHeader.offsetHeight,
          behavior: 'smooth'
        })
      }
    }

    if (ref.current?.style.borderBottom) {
      ref.current.classList.add('border-b-[1px]', 'border-rb-gray-100')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleModuleOpen = (text: string) => {
    trackNavigationElementToggled({
      location: 'program_preview_materials',
      type: 'module_title',
      text,
      related_identifiers: { cmsModuleId: cmsModule.id }
    })

    if (cmsModule.published) setModuleOpen(!moduleOpen)
  }

  const getScheduleWeekKickoffDate = (
    cmsModule: CohortViewerMaterialCmsModulePartsFragment
  ): string => {
    return formatInTimezone(
      cmsModule.publishAt,
      currentUser?.timezone,
      FULL_DATE_ABBREV_MONTH_FORMAT
    )
  }

  const totalModuleBookmarks = numModuleBookmarks?.[cmsModule.id] || 0
  const moduleTimeInWords = getTimeInWords(cmsModule.estimatedTime)

  const moduleOpenClass = moduleOpen ? '' : 'hover:bg-rb-gray-50 group'

  return (
    <li
      id={`cohortViewerModule${cmsModule.id}`}
      className={cn(
        'mt-0 -mb-3 px-4 pb-3 lg:px-8',
        moduleOpenClass,
        !currentUser &&
          'hover:bg-transparent first:border-t-0 border-t py-2.5 mt-12 first:mt-0'
      )}
    >
      <div
        className="px-0 py-5"
        onClick={() => toggleModuleOpen(cmsModule.name)}
        onKeyUp={onEnterKeyPress(() => toggleModuleOpen(cmsModule.name))}
        role="button"
        tabIndex={0}
      >
        <div
          className={`m-0 flex w-full flex-col justify-center ${
            cmsModule.published ? 'cursor-pointer' : ''
          }`}
        >
          <div className="flex items-center pl-0">
            <div className="w-[60%] pr-4">
              <div className="text-base font-medium leading-5 text-rb-gray-500 line-clamp-2 sm:text-[21px] sm:leading-[26px]">
                {cmsModule.name}
              </div>
            </div>
            {!cmsModule.published ? (
              <div className="text-sm text-rb-gray-300 sm:inline-block sm:w-[200px] sm:text-base sm:font-normal">
                <div className="flex items-center">
                  <SVGIcon
                    name="lock"
                    height="26.67px"
                    width="20.94px"
                    fill="#a2a1a2"
                    className="pr-2"
                  />
                  Releasing {getScheduleWeekKickoffDate(cmsModule)}
                </div>
              </div>
            ) : (
              <>
                {isPremember && cmsModule.containsPreviewableLessons && (
                  <Tag
                    variant="teal"
                    className="ml-auto"
                    text="Preview Available"
                    dataTest="preview-available-tag"
                  />
                )}
                <div className="font-sm sm:font-base ml-auto hidden text-rb-gray-300 sm:inline-block sm:w-[75px] sm:font-normal">
                  {moduleTimeInWords}
                </div>
                {totalModuleBookmarks > 0 && (
                  <div
                    className={twMerge(
                      'group flex w-[76px] justify-center pr-4 text-sm text-rb-gray-300 group-hover:text-rb-gray-500',
                      moduleOpen ? 'text-rb-gray-500' : ''
                    )}
                  >
                    <>
                      <SVGIcon
                        name="bookmark"
                        width="18"
                        height="18"
                        className="fill-current text-rb-gray-500"
                      />
                      <span>{totalModuleBookmarks}</span>
                    </>
                  </div>
                )}
                {cmsModule.anyCountsTowardsCompletion && showMemberContent && (
                  <Popover
                    text="Bonus material does not count towards your progress"
                    containerClassName="ml-auto w-1/4 min-w-[55px] shrink-0 cursor-pointer text-right text-sm font-normal self-end"
                    tooltipClassName="text-xs left-0 right-0 p-2 w-auto max-w-[290px] text-center mx-auto"
                    offsetTop="-top-[80px] md:-top-[55px]"
                    offsetBottom="-bottom:[70px] md:-bottom-[35px]"
                    zIndex="z-[1011]"
                    caretPosition="center"
                  >
                    <progress
                      className={twMerge(
                        'uk-progress mb-0 !h-[5px] opacity-50 group-hover:opacity-100',
                        moduleOpen ? 'opacity-100' : ''
                      )}
                      value={percentComplete}
                      max="100"
                    />
                    <div className="mt-[3px] text-sm font-medium text-rb-black">
                      {percentComplete}%
                    </div>
                  </Popover>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {cmsModule.published && (
        <div className={`uk-section-small pt-0 ${moduleOpen ? '' : 'uk-hidden'}`}>
          <div className="uk-container items-stretch px-0">
            <ModuleCardRow
              cmsModuleId={`${cmsModule.id}`}
              numSectionBookmarks={numSectionBookmarks}
              cmsSections={cmsModule.cmsSections}
              currentUser={currentUser}
              userProgress={userProgress}
            />
            {cmsModule.project && (
              <div className="mb-5 w-full sm:mr-[30px] sm:w-[252px]">
                <LegacyContentCard
                  content={{
                    ...cmsModule.project,
                    numBookmarks: numSectionBookmarks?.[cmsModule.project.id]
                  }}
                  hideBookmarkButton={!currentUser}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </li>
  )
}

export default ModuleSection
