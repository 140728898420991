import SeoPageSectionSubtitle from 'domains/Seo/Section/SeoPageSectionSubtitle'
import SeoPageSectionTitle from 'domains/Seo/Section/SeoPageSectionTitle'
import { SEOTrackingRelatedIdentifiers } from 'domains/Seo/helpers'

import { Loading } from 'components'
import Button from 'components/Button'

import { Function } from 'gql'

import { cn } from 'utils/tailwind'
import { trackCtaClicked } from 'utils/tracking/analytics'

import { CourseSeoFunctionLink } from '../CourseSeoFunctionLink'

interface CourseSeoFunctionLinkSectionProps {
  functions: Function[]
  title: string
  subtitle?: string
  tracking: SEOTrackingRelatedIdentifiers
  loading: boolean
}

const CourseSeoFunctionLinkSection = ({
  title,
  subtitle,
  functions,
  tracking,
  loading
}: CourseSeoFunctionLinkSectionProps) => {
  const ctaText = 'Explore all courses'
  const destination = '/courses'

  const onCtaClick = () => {
    trackCtaClicked({
      cta_type: 'button',
      destination,
      cta_location: 'SEO related functions section',
      text: ctaText.toLowerCase(),
      related_identifiers: {
        sanity_type: tracking.sanityType,
        sanity_id: tracking.sanityId,
        sanity_name: tracking.sanityName
      }
    })
  }

  return (
    <section className="mb-[104px]">
      <SeoPageSectionTitle className={cn({ 'mb-6': !subtitle })}>
        {title}
      </SeoPageSectionTitle>
      {subtitle && <SeoPageSectionSubtitle>{subtitle}</SeoPageSectionSubtitle>}

      {loading && <Loading />}
      {!loading && (
        <div className="mb-6 grid grid-cols-1 gap-4 md:grid-cols-3">
          {functions.map((f) => (
            <CourseSeoFunctionLink functionFilter={f} key={f.slug} />
          ))}
        </div>
      )}

      <Button
        color="teal"
        shape="rounded"
        size="small"
        href={destination}
        onClick={onCtaClick}
      >
        {ctaText}
      </Button>
    </section>
  )
}

export default CourseSeoFunctionLinkSection
