import cohortTeams from 'cohort--teams.png'

import { useCohortDashboardTrackingContext } from 'domains/CohortDashboard/hooks/useCohortDashboardTrackingContext'

import Button from 'components/Button'
import RfHeader2 from 'components/typography/RfHeader/RfHeader2'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import { onEnterKeyPress } from 'utils/keyboard'
import { trackCtaClicked } from 'utils/tracking/analytics'

const CohortDashboardTeams = () => {
  const { cohort } = useCohortDashboardTrackingContext()
  const learnAboutTeamsButtonText = 'Learn about teams'
  const handleLearnAboutTeamsClick = () => {
    trackCtaClicked({
      cta_location: 'reforge_for_teams_card',
      cta_type: 'card',
      text: learnAboutTeamsButtonText,
      related_identifiers: {
        cohort_id: cohort.id,
        cms_program_id: cohort.cmsProgram.id
      }
    })

    window.location.href =
      'https://www.reforge.com/teams?utm_source=marketing&utm_medium=product&utm_campaign=fall_23&utm_term=&utm_content=cohort_offboarding'
  }

  return (
    <div
      tabIndex={0}
      role="button"
      onClick={handleLearnAboutTeamsClick}
      onKeyDown={onEnterKeyPress(handleLearnAboutTeamsClick)}
      className="flex max-w-[890px] flex-col md:flex-row"
    >
      <div className="flex w-full flex-col items-center justify-center bg-black px-16 py-12 md:items-start md:rounded-l-lg">
        <RfHeader2 className="!text-rb-white">Elevate Your Entire Team</RfHeader2>
        <RfParagraphSmall color={ColorOptions.white}>
          Reforge for Teams minimizes trial and error by equipping your team with
          structured insights from industry experts for efficient, impactful work.
        </RfParagraphSmall>
        <div className="pt-12">
          <Button
            size="medium"
            variant="outline"
            data-method="post"
            className="normal-case"
            onClick={handleLearnAboutTeamsClick}
          >
            Discover Reforge for Teams
          </Button>
        </div>
      </div>
      <div className="w-full md:w-[80%] lg:w-full">
        <img
          src={cohortTeams}
          alt="cohort teams"
          className="h-full object-cover md:rounded-r-lg lg:h-full"
        />
      </div>
    </div>
  )
}

export default CohortDashboardTeams
