import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import UnitExpertCollaboratorsPreview from 'pages/UnitLandingPage/UnitExpertCollaboratorsPreview'

import CoursePreviewButton from 'components/Courses/CoursePreviewButton'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import {
  CourseDashboardCourseBlockArtifactPartsFragment,
  CourseDashboardCourseBlockPartsFragment,
  CourseDashboardCoursePartsFragment,
  CourseDashboardCourseSessionPartsFragment
} from 'gql'

import {
  trackCourseDashboardContentClicked,
  useContentMode
} from 'utils/contentTrackingUtils'
import { buildCourseDashboardLinkFromPath } from 'utils/courseUtils'

export interface CourseContentBlockArtifactProps {
  artifact: CourseDashboardCourseBlockArtifactPartsFragment
  course: CourseDashboardCoursePartsFragment
  courseBlock: CourseDashboardCourseBlockPartsFragment
  courseSession?: CourseDashboardCourseSessionPartsFragment
  preview?: boolean
  courseBlockIndex?: number
  index: number
}

const CourseContentBlockArtifact = ({
  artifact,
  courseSession,
  course,
  courseBlock,
  courseBlockIndex = 0,
  index,
  preview = false
}: CourseContentBlockArtifactProps) => {
  const contentMode = useContentMode({ courseSession }) || ''

  if (!artifact) return null

  const onArtifactClick = (e: any) => {
    e.stopPropagation() // Prevent double tracking

    trackCourseDashboardContentClicked({
      contentType: 'artifact',
      contentTitle: artifact.title || '',
      contentSanityId: artifact.id,
      contentIndex: index,
      courseBlockIndex,
      course,
      courseSession,
      courseBlock,
      contentMode
    })
  }

  return (
    <Link
      to={{
        pathname: buildCourseDashboardLinkFromPath({
          courseSlug: course.slug,
          sessionId: courseSession?.id,
          artifactSlug: artifact.slug
        }),
        state: {
          backTo: [{ label: 'Course Home', path: window.location.pathname }],
          fromCourse: true
        }
      }}
      data-test="course_block_artifact"
      className={twMerge(
        'flex cursor-pointer flex-row items-center justify-between space-x-6 rounded-2xl border p-4 transition-shadow delay-75 hover:shadow-lg',
        preview ? 'border-rb-green-100' : 'border-gray-200'
      )}
      onClick={onArtifactClick}
      tabIndex={0}
      onKeyUp={onArtifactClick}
    >
      <div className="flex flex-row items-center space-x-6">
        <div className="h-[75px] w-full max-w-[75px] overflow-hidden rounded-xl bg-gray-50 lg:h-[140px] lg:max-w-[140px]">
          {artifact.thumbnailPhoto && (
            <img
              className="h-full w-full object-cover"
              alt={JSON.parse(artifact.thumbnailPhoto)?.alt}
              src={JSON.parse(artifact.thumbnailPhoto)?.asset?.url}
            />
          )}
        </div>
        <div className="flex flex-1 flex-col justify-center">
          <RfParagraphSmall>{artifact.title}</RfParagraphSmall>
          <UnitExpertCollaboratorsPreview
            mainClassName="mt-6 mb-0"
            experts={artifact.authors}
            avatarImageClassName="w-[39px] h-[39px] hidden lg:block"
            carouselItemWidth={240}
            containerClassName="w-[240px]"
          />
        </div>
      </div>
      {preview && (
        <div className="hidden lg:block">
          <CoursePreviewButton onClick={onArtifactClick} />
        </div>
      )}
    </Link>
  )
}

export default CourseContentBlockArtifact
