import ArtifactCard from 'components/cards/Content/ArtifactCard'
import { CardVariants } from 'components/cards/Content/BaseCard'
import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'

import { Artifact } from 'gql'

import { BackToParams } from './UnitLandingPage'

interface UnitArtifactsProps {
  unit: any
  course?: any
  courseSession?: any
  backTo?: BackToParams
}

export const MEMBERSHIP_HOST = 'https://reforge.com'
export const ARTIFACTS_HOST = 'https://reforge.com'

export const UnitArtifacts = ({
  unit,
  course,
  courseSession,
  backTo
}: UnitArtifactsProps) => {
  const artifacts: Artifact[] = unit.artifacts || []
  if (!artifacts.length) return null

  const state =
    backTo && backTo.length > 0
      ? {
          backTo: [
            ...backTo,
            {
              label:
                unit.title.length > 10 ? `${unit.title.substring(0, 10)}...` : unit.title,
              path: window.location.pathname
            }
          ],
          fromCourse: true
        }
      : {}

  return (
    <div data-test="unit_artifacts_section">
      <RfHeader3SemiBold className="!mb-8">
        See how others have applied this
      </RfHeader3SemiBold>
      <div className="grid grid-cols-1 gap-4">
        {artifacts.map((artifact) => (
          <ArtifactCard
            variant={CardVariants.Horizontal}
            key={artifact.slug}
            artifact={artifact}
            pageLocation="guide"
            historyState={state}
            additionalRelatedIdentifiers={{
              course_id: course?.id,
              course_title: course?.title,
              course_session_id: courseSession?.id,
              unit_title: unit.title,
              unit_sanity_id: unit.id,
              content_mode: 'async',
              display_type: 'vertical'
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default UnitArtifacts
