import clsx from 'clsx'

import { Tag } from 'components/Tag'
import SkinnyInfoIcon from 'components/icons/SkinnyInfoIcon'

import { Maybe, Season } from 'gql'

import { formatInTimezone } from 'utils/date'
import { showNewBadge } from 'utils/programUtils'

interface IStatusBadge {
  upcomingSeason?: Maybe<Season>
  lastActiveSeason?: Maybe<Season>
  isRecommended: boolean
  progressPercent?: number
  notLaunched?: boolean | null
  deprecated?: boolean | null
  isProgramOnDemand: boolean
  launchAt?: string | null
  programName: string
}

const ProgramStatusBadge = ({
  isRecommended,
  progressPercent,
  notLaunched,
  deprecated,
  isProgramOnDemand,
  launchAt,
  upcomingSeason,
  lastActiveSeason,
  programName
}: IStatusBadge) => {
  const badges = []

  const _showNewBadge = showNewBadge(launchAt || '', lastActiveSeason, upcomingSeason)

  if (deprecated) {
    const editionYear = formatInTimezone(launchAt, null, 'yyyy')

    badges.push(
      <Tag
        variant="gray"
        text={`${editionYear} Edition`}
        tooltipContent={`We revamped this program - more details on the updated version can be found in the other ${programName} program listed on this page.`}
        className="cursor-pointer"
        contentAfter={
          <SkinnyInfoIcon className="ml-1.5 h-3.5 w-3.5 fill-current stroke-0" />
        }
      />
    )
  } else if (isRecommended || notLaunched || _showNewBadge) {
    if (isRecommended) {
      badges.push(<Tag variant="blue" text="Recommended" />)
    }
    if (notLaunched || _showNewBadge) {
      badges.push(<Tag text="New" />)
    }
  }

  if (!notLaunched && progressPercent && progressPercent === 100) {
    badges.push(<Tag text="Completed" />)
  }

  if (isProgramOnDemand) {
    badges.push(
      <Tag
        variant="gray"
        text="On Demand"
        tooltipContent="This program is not currently being offered live, but the content is always available on demand."
        className="cursor-pointer"
        contentAfter={
          <SkinnyInfoIcon className="ml-1.5 h-3.5 w-3.5 fill-current stroke-0" />
        }
      />
    )
  }

  if (badges && badges.length > 0) {
    return (
      <div className="flex items-center">
        {badges.map((badge, i) => {
          const moreThanOneBadge = badges.length > 1
          const isNotLastBadge = badges.length - 1 !== i
          return (
            <span
              key={i}
              className={clsx(
                'flex items-center',
                moreThanOneBadge && isNotLastBadge && 'mr-3'
              )}
            >
              {badge}
            </span>
          )
        })}
      </div>
    )
  }

  return null
}

export default ProgramStatusBadge
