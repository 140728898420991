import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface UnitBlockContainerProps {
  id?: string
  children: ReactNode
  className?: string
}

export const UnitBlockContainer = ({
  id,
  children,
  className
}: UnitBlockContainerProps) => {
  return (
    <div id={id} className={twMerge('rounded-xl bg-rb-orange-25 p-10', className)}>
      {children}
    </div>
  )
}

export default UnitBlockContainer
