import { closing, opening } from './sharedMarkdown'

const programName = 'Retention + Engagement'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://www.reforge.com/retention-engagement-series), because I'll learn proven frameworks to retain, activate, engage, and resurrect users to accelerate [your company]‘s growth. This course was built with [Shaun Clowes](https://www.reforge.com/experts/shaun-clowes) and [Casey Winters](https://www.reforge.com/experts/casey-winters), and covers:
* **Defining + Analyzing Retention.** Retention is different for every product. Learn how to define, measure, and analyze retention from first principles for your product. 
* **Activation - Defining, Measuring, and Analyzing.** Activation is the first key input into improving retention. You will define your activation moments and metrics, analyze the activation journey, and identify key friction points. 
* **Activation Strategies.** Learn the various strategies of how to establish user habits quickly. You will take your analysis from week 2, and build a plan for how to specifically improve activation for various user segments.  
* **Engagement - Defining, Measuring, and Analyzing.** Engagement is the second key input into improving retention. You will choose the right engagement strategy for your product, define measurement that aligns to that strategy, and analyze engagement to find new opportunities. 
* **Engagement Strategies.** Go deeper into the four strategies that move users from low to high engagement and learn how to combine them to build a compounding growth machine.  
* **Churn Prediction + Resurrection.** Define non-activated users vs. dormant users, and learn to identify resurrection opportunities. Identify leading indicators of churn while understanding the true cost of any resurrection attempt.

&NewLine;

${closing}
`

export default { markdown, title: programName }
