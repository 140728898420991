import Button from 'components/Button'
import Modal from 'components/Modal'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

export interface CohortPostDeleteConfirmationModalProps {
  isOpen: boolean
  onClose: () => void
  deletePost: () => void
  label: string
}

export const CohortPostDeleteConfirmationModal = ({
  isOpen,
  onClose,
  deletePost,
  label
}: CohortPostDeleteConfirmationModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={onClose}
      header={false}
      dataTest="cohort-post-delete-confirmation-modal"
      className="max-w-[312px] rounded-2xl p-8"
      closeOnEscape
    >
      <RfParagraphMediumBold className="mb-3">Delete {label}</RfParagraphMediumBold>
      <RfParagraphSmall className="mb-10">
        Are you sure you want to delete this post? This cannot be undone.
      </RfParagraphSmall>

      <Button
        variant="fill"
        fullWidth
        color="destructive"
        onClick={deletePost}
        className="mb-4"
      >
        Delete
      </Button>
      <Button variant="outline" fullWidth onClick={onClose}>
        Cancel
      </Button>
    </Modal>
  )
}
