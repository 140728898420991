import cx from 'classnames'
import FsLightbox from 'fslightbox-react'
import {
  HTMLAttributes,
  KeyboardEvent,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { Link } from 'react-router-dom'

import { buildSrc, buildSrcSet } from 'domains/Sanity/lib/helpers'
import { UnitContext } from 'domains/Unit/contexts/UnitContext'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { track } from 'utils/tracking/segment'

import { PhotoProps } from '../shared/Photo'

const Photo = ({
  id,
  photo,
  width,
  height,
  srcSizes = [400, 600, 800, 1000, 1200],
  sizes = '(min-width: 940px) 50vw, 100vw',
  layout = 'intrinsic',
  quality = 80,
  hasPlaceholder = true,
  onLoad,
  className,
  rounded = false,
  useLightbox = false
}: PhotoProps) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [toggler, setToggler] = useState(false)
  const { currentUser } = useCurrentUser()
  const unitContext = useContext(UnitContext)

  // trigger any onLoad callbacks
  useEffect(() => {
    if (isLoaded) onLoad?.()
  }, [isLoaded, onLoad])

  if (!photo?.asset) return null

  // define our aspect ratio if not a background fill
  const aspect =
    typeof width === 'number' && typeof height === 'number'
      ? (height / width) * 100
      : 100 / (photo.customRatio || photo.aspectRatio)

  const aspectCustom =
    layout === 'intrinsic' && aspect ? { paddingTop: `${aspect}%` } : undefined

  // define our src and srcset
  // @ts-ignore
  const src = buildSrc(photo, {
    ...{ width },
    ...{ height },
    ...{ quality }
  })

  // @ts-ignore
  const srcset = buildSrcSet(photo, {
    ...{ srcSizes },
    ...{ aspect },
    ...{ quality }
  })

  // handle our image onLoad
  function handleLoad() {
    requestAnimationFrame(() => {
      setIsLoaded(true)
    })
  }

  function handleKeyDown(event: KeyboardEvent<HTMLButtonElement>) {
    if (event.key === 'Enter') {
      setToggler(!toggler)
    }
  }

  function handleImageClick() {
    // @ts-ignore
    track('Content Image Expanded', {
      access_policy_kind: currentUser?.accessPolicyKind ?? undefined,
      content_sanity_id: unitContext?.content_sanity_id,
      content_type: unitContext?.content_type,
      content_title: unitContext?.content_title
    })
  }

  return (
    <figure className={className} id={id}>
      <Component
        className={cx('relative block overflow-hidden', {
          'absolute inset-0': layout === 'fill' || layout === 'contain',
          'rounded-full': rounded === true
        })}
        style={aspectCustom}
        href={photo.link || null}
      >
        <picture>
          <button
            className="block"
            onKeyDown={useLightbox ? handleKeyDown : undefined}
            onClick={useLightbox ? () => setToggler(!toggler) : undefined}
            tabIndex={0}
          >
            <img
              width={width}
              onClick={() => handleImageClick()}
              height={height}
              data-loaded={isLoaded}
              src={src}
              srcSet={srcset}
              sizes={sizes}
              decoding="async"
              onLoad={handleLoad}
              alt={photo.alt || photo.asset?.altText}
              className={cx(
                'z-1 opacity-0 transition-opacity duration-400 ease-linear',
                aspectCustom
                  ? 'absolute left-1/2 top-1/2 block h-[calc(100%+1px)] w-[calc(100%+1px)] -translate-x-1/2 -translate-y-1/2'
                  : 'relative',
                { 'cursor-pointer': useLightbox, 'opacity-100': isLoaded },
                getSize(layout)
              )}
            />
          </button>
        </picture>

        {hasPlaceholder && (
          <div
            className={cx(
              'absolute inset-0 scale-[1.15] grayscale-[1] transition-opacity duration-400 ease-linear after:absolute after:inset-0 after:z-1 after:backdrop-blur-lg after:backdrop-contrast-150',
              { 'opacity-0': isLoaded }
            )}
          >
            <img src={photo.lqip} alt="" role="presentation" className="opacity-100" />
          </div>
        )}
        {useLightbox && !photo.link && (
          <FsLightbox toggler={toggler} sources={[src]} type="image" />
        )}
      </Component>
      {photo.caption && (
        <figcaption className="mt-10 text-center text-base text-rb-gray-500">
          <em>{photo.caption}</em>
        </figcaption>
      )}
    </figure>
  )
}

const Component = ({
  href,
  children,
  ...props
}: {
  href?: string
  children: ReactNode
} & HTMLAttributes<HTMLDivElement | HTMLAnchorElement>) => {
  if (!href) {
    return <div {...props}>{children}</div>
  }

  if (href.includes('http')) {
    return (
      <a href={href} {...props}>
        {children}
      </a>
    )
  }

  return (
    <Link to={href} {...props}>
      {children}
    </Link>
  )
}

const getSize = (layout: string) => {
  switch (layout) {
    case 'intrinsic':
      return 'object-cover'
    case 'fill':
      return 'object-cover'
    case 'contain':
      return 'object-contain'
  }
}

export default Photo
