import { SetStateAction, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { ReactComponent as ChevronDown } from 'thin-chevron-down.svg'
import { ReactComponent as ChevronUp } from 'thin-chevron-up.svg'

import CourseBlockContentBlock from 'domains/Course/CourseBlockContentBlock'
import CourseContentBlockArtifact from 'domains/Course/CourseContentBlockArtifact'
import CourseContentBlockUnit from 'domains/Course/CourseContentBlockUnit'
import { UnitComplexPortableText } from 'domains/Sanity/PortableText/UnitComplexPortableText/UnitComplexPortableText'

import RfHeader3 from 'components/typography/RfHeader/RfHeader3'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import RfParagraphSmallSemiBold from 'components/typography/RfParagraph/RfParagraphSmallSemiBold'

import {
  CourseDashboardCourseBlockPartsFragment,
  CourseDashboardCoursePartsFragment,
  CourseDashboardCourseSessionPartsFragment,
  CourseDetailCourseBlockPartsFragment
} from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

import { useContentMode } from 'utils/contentTrackingUtils'
import { FULL_MONTH_DATE_FORMAT, formatInTimezone } from 'utils/date'
import { scrollToElementTop } from 'utils/scrollToElementTop'
import { trackExpandClick } from 'utils/tracking/analytics'

import { ReactComponent as VideoIcon } from 'images/icon--video.svg'

import { CourseContentBlockEvent } from './CourseContentBlockEvent'

interface CourseContentBlockProps {
  isGettingStarted?: boolean
  cclCourseBlock: CourseDetailCourseBlockPartsFragment
  courseBlock: CourseDashboardCourseBlockPartsFragment
  course: CourseDashboardCoursePartsFragment
  courseSession: CourseDashboardCourseSessionPartsFragment
  hasCourseDatesOutsideCourseSession: boolean
  courseBlockIndex: number
}

const CourseContentBlock = ({
  cclCourseBlock,
  courseBlock,
  course,
  courseSession,
  hasCourseDatesOutsideCourseSession,
  courseBlockIndex
}: CourseContentBlockProps) => {
  const contentMode = useContentMode({ courseSession }) || ''
  const [expanded, setExpanded] = useState(false)
  const { timezone } = useAssertCurrentUser()

  const setExpandedSync = (prev: SetStateAction<boolean>) => {
    if (prev && courseBlock?.id) {
      scrollToElementTop(courseBlock.id)
    }

    return !prev
  }

  const handleChevronClick = () => {
    if (!expanded) {
      trackExpandClick({
        location: 'course_dashboard',
        expandable_section_title: courseBlock.title || '',
        expandable_section_type: 'course_block',
        related_identifiers: {
          content_block_id: courseBlock.id || '',
          content_block_title: courseBlock.title || '',
          content_block_number: `${courseBlockIndex}`,
          content_mode: contentMode,
          course_id: course.id,
          course_title: course.title,
          course_session_id: courseSession?.id,
          sanity_course_id: course.sanityId,
          content_type: 'course'
        }
      })
    }
    setExpanded(setExpandedSync)
  }

  const hasNotStarted = !!courseBlock?.units?.every((unit) => !unit?.completed)
  const isModuleCompleted = !!courseBlock?.units?.every((unit) => unit?.completed)
  const isInProgress =
    !!courseBlock?.units?.some((unit) => unit?.completed) && !isModuleCompleted

  useEffect(() => {
    if (hasNotStarted || isInProgress) {
      setExpanded(true)
    }
  }, [hasNotStarted, isInProgress])

  const renderCourseBlockContent = () => {
    if (!cclCourseBlock.contentBlocks?.length) {
      return null
    }

    return (
      <div className="mt-3 mb-7">
        <div className="flex flex-col gap-8">
          {cclCourseBlock.contentBlocks.map((block, index) => (
            <CourseBlockContentBlock
              key={index}
              courseBlockIndex={courseBlockIndex}
              contentBlock={block}
              courseSlug={course.slug}
              cclCourseBlock={cclCourseBlock}
              courseSession={courseSession}
            />
          ))}
        </div>
      </div>
    )
  }

  return (
    <div
      data-test="course_content_block"
      id={`${courseBlock.id}`}
      className="w-full scroll-mt-[136px] rounded-xl border border-rb-gray-100 p-7.5 pb-0 transition-all duration-500 ease-in-out"
    >
      <div className="flex items-center justify-between pb-6">
        {hasNotStarted && (
          <RfParagraphSmall className="rounded-full bg-rb-gray-50 px-3 py-1">
            <span className="text-rb-gray-400">Not Started</span>
          </RfParagraphSmall>
        )}
        {isInProgress && !isModuleCompleted && (
          <RfParagraphSmall className="rounded-full bg-rb-orange-50 px-3 py-1">
            <span className="text-rb-gray-400">In Progress</span>
          </RfParagraphSmall>
        )}
        {isModuleCompleted && (
          <RfParagraphSmall className="rounded-full bg-rb-success-50 px-3 py-1">
            <span className="text-rb-gray-300">Complete</span>
          </RfParagraphSmall>
        )}
      </div>

      <RfHeader3>
        {cclCourseBlock.hasVideoContent && (
          <VideoIcon className="mr-3 inline-block w-[22px] h-[22px] md:w-[24px] md:h-[24px]" />
        )}
        <span className="font-medium">{courseBlock.title}</span>
      </RfHeader3>
      {courseBlock.description && (
        <UnitComplexPortableText
          content={JSON.parse(courseBlock.description)}
          forcePhotoLoad
        />
      )}

      <div
        data-test="course_content_block_expanded_section"
        className={twMerge(
          'transition-all duration-500 ease-in-out',
          expanded ? '' : 'max-h-0 overflow-hidden'
        )}
      >
        {renderCourseBlockContent()}

        {/* Guides Section */}
        {courseBlock?.units && courseBlock.units.length > 0 && (
          <div className="mt-3">
            {courseBlock?.units && courseBlock.units.length > 0 && (
              <RfParagraphSmallSemiBold className="mb-2">
                Step-by-step guides
              </RfParagraphSmallSemiBold>
            )}
            {!hasCourseDatesOutsideCourseSession && courseBlock?.readByDate && (
              <RfParagraphMini className="mb-4 w-fit rounded-full bg-rb-gray-50 px-3 py-2">
                Read by{' '}
                {formatInTimezone(
                  courseBlock?.readByDate,
                  timezone,
                  FULL_MONTH_DATE_FORMAT
                )}
              </RfParagraphMini>
            )}
            <div className="flex flex-col gap-8">
              {courseBlock?.units?.map((unit, index) => (
                <CourseContentBlockUnit
                  course={course}
                  courseSession={courseSession}
                  courseBlock={courseBlock}
                  key={unit.id}
                  unit={unit}
                  courseBlockIndex={courseBlockIndex}
                  index={index}
                  size={courseBlock.units?.length}
                />
              ))}
            </div>
          </div>
        )}

        {/* Artifacts Section */}
        {courseBlock?.artifacts && courseBlock.artifacts.length > 0 && (
          <div className="mt-7">
            <RfParagraphSmallSemiBold className="mb-2.5">
              Real-world examples
            </RfParagraphSmallSemiBold>

            <div className="mb-2 flex flex-col gap-8">
              {courseBlock.artifacts.map((artifact, index) => (
                <CourseContentBlockArtifact
                  courseBlockIndex={courseBlockIndex}
                  index={index}
                  artifact={artifact}
                  key={artifact.id}
                  courseBlock={courseBlock}
                  course={course}
                  courseSession={courseSession}
                />
              ))}
            </div>
          </div>
        )}

        {/* Events Section */}
        {courseBlock?.events && courseBlock.events.length > 0 && (
          <div className="mt-7" data-test="course_content_block_event">
            <RfParagraphSmallSemiBold className="mb-2.5">
              Live events
            </RfParagraphSmallSemiBold>

            <div className="mb-2 flex flex-col gap-8">
              {courseBlock.events.map((event) => (
                <CourseContentBlockEvent
                  timezone={timezone}
                  event={event}
                  key={event.sanityId}
                  courseBlock={courseBlock}
                  course={course}
                  courseSession={courseSession}
                  hasCourseDatesOutsideCourseSession={hasCourseDatesOutsideCourseSession}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="mt-7">
        {expanded ? (
          <button
            data-test="course_content_block_chevron"
            onClick={handleChevronClick}
            className="flex w-full items-center justify-center pt-2 pb-7.5"
          >
            <ChevronUp className="h-[22px] w-[22px] text-rb-gray-200" />
          </button>
        ) : (
          <button
            data-test="course_content_block_chevron"
            onClick={handleChevronClick}
            className="flex w-full items-center justify-center pt-2 pb-7.5"
          >
            <ChevronDown className="h-[22px] w-[22px] text-rb-gray-200" />
          </button>
        )}
      </div>
    </div>
  )
}

export default CourseContentBlock
