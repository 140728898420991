import useURLParams from 'hooks/useURLParams'

import OnboardingModal from './OnboardingModal'

export interface OnboardingModalContainerProps {
  isOpen: boolean
  showLoader: boolean
}

// NOTE: This container component prevents the OnboardingModal from mounting unless it's needed
//       and prevents the heavy graphql query involved.
export const OnboardingModalContainer = ({
  isOpen,
  showLoader
}: OnboardingModalContainerProps) => {
  const { getParam } = useURLParams()
  const joinedTeam = !!getParam('joined-team')
  if (isOpen || joinedTeam) {
    return <OnboardingModal showLoader={showLoader} />
  }

  return null
}
