import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ExpertUserProfileFragmentFragment } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import {
  MONTH_ABBREV_FORMAT,
  TWO_DIGIT_DATE,
  formatInTimezone,
  getCurrentTimezone,
  getFormattedTimeWithTimeZone
} from 'utils/date'
import { cn } from 'utils/tailwind'
import { trackNavigationClicked } from 'utils/tracking/analytics'

export interface ProfileViewerProps {
  userProfile: ExpertUserProfileFragmentFragment
  overviewMode?: boolean
}
type EventAndCourses = {
  id: number
  name: string
  startsAt: string
  endsAt: string
  startTimestamp: number
  duration?: string
  slug: string
  kind?: string
  courseId?: string
  upcomingType: 'course' | 'event'
}

const UpcomingEventCourses = ({ userProfile, overviewMode }: ProfileViewerProps) => {
  const history = useHistory()
  const { currentUser } = useCurrentUser()
  const userTimezone = getCurrentTimezone(currentUser?.timezone)
  const [seeMore, setSeeMore] = useState(false)
  const [upcomingEventsAndCourses, setUpcomingEventsAndCourses] = useState<
    EventAndCourses[]
  >([])

  const durationOrDate = (data: EventAndCourses) => {
    if (data.upcomingType === 'event') {
      return getFormattedTimeWithTimeZone(data.startsAt, data.endsAt, userTimezone)
    }

    return data.duration
  }

  const handleUpcomingClick = (link: string, obj: EventAndCourses) => {
    // @todo- add tracking
    if (obj.upcomingType === 'course') {
      trackNavigationClicked({
        destination: link,
        location: 'expert_profile__upcoming',
        type: 'mini_card',
        related_identifiers: {
          course_id: obj.courseId,
          course_title: obj.name,
          course_session_id: obj.id,
          is_featured: false,
          is_filtered_reference: false,
          is_empty_index_results: false
        }
      })
    } else {
      trackNavigationClicked({
        destination: link,
        location: 'expert_profile__upcoming',
        type: 'mini_card',
        text: obj.name,
        related_identifiers: {
          content_id: obj.id,
          content_title: obj.name,
          content_type: 'event',
          event_type: obj.kind
        }
      })
    }
    history.push(link)
  }

  useEffect(() => {
    const sessions: any = []
    userProfile.upcomingLiveCourses?.forEach((course) => {
      course.upcomingSessions?.forEach((session) => {
        const date = new Date(session.startsAt)
        sessions.push({
          courseId: course.id,
          name: course.title,
          slug: course.slug,
          ...session,
          upcomingType: 'course',
          startTimestamp: date.getTime()
        })
      })
    })

    const data = [
      ...sessions,
      ...userProfile.upcomingEvents.map((event) => {
        const date = new Date(event.startsAt!)

        return {
          ...event,
          upcomingType: 'event',
          startTimestamp: date.getTime()
        }
      })
    ].sort((a, b) => b.startTimestamp - a.startTimestamp)

    setUpcomingEventsAndCourses(data as EventAndCourses[])
  }, [userProfile])

  if (!upcomingEventsAndCourses.length) {
    return null
  }

  return (
    <div className={cn(overviewMode ? 'mt-8' : 'mt-12')}>
      <div className="text-rb-gray-400 font-sans font-semibold">
        Upcoming events & courses
      </div>
      <div
        className={cn(
          overviewMode && 'grid grid-flow-row grid-cols-1 sm:grid-cols-2 gap-4'
        )}
      >
        {(seeMore ? upcomingEventsAndCourses : upcomingEventsAndCourses.slice(0, 3)).map(
          (obj, index) => {
            const link =
              obj.upcomingType === 'course'
                ? `/courses/${obj.slug}/details`
                : `/events/${obj.id}-${obj.slug}`

            return (
              <div
                key={index}
                className="flex space-x-4 mt-4 cursor-pointer"
                onClick={() => handleUpcomingClick(link, obj)}
              >
                <div className="flex flex-col items-center justify-center rounded-md bg-rb-blue-50 py-1.5 px-3.5 h-20 w-20">
                  <div className="text-xl font-semibold">
                    {formatInTimezone(obj.startsAt, userTimezone, TWO_DIGIT_DATE)}
                  </div>
                  <div className="text-sm font-normal">
                    {formatInTimezone(
                      obj.startsAt,
                      userTimezone,
                      MONTH_ABBREV_FORMAT
                    ).toUpperCase()}{' '}
                  </div>
                </div>
                <div className="flex items-center flex-1">
                  <div>
                    <div className="font-sans font-semibold mb-2">{obj.name}</div>
                    <div className="text-xs text-rb-gray-300">{durationOrDate(obj)}</div>
                  </div>
                </div>
              </div>
            )
          }
        )}
      </div>

      {upcomingEventsAndCourses.length > 3 && (
        <div
          className="mt-4 font-sans font-normal text-rb-gray-400"
          onClick={() => setSeeMore(true)}
        >
          See more
        </div>
      )}
    </div>
  )
}

export default UpcomingEventCourses
