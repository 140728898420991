import Image from 'domains/Sanity/Image'

import { CclExpertCourseCardPartsFragment } from 'gql'

import { CardVariant, CardVariants } from '../Content/BaseCard'

const CourseInProgressThumbnail = ({
  experts,
  cardVariant
}: {
  experts?: CclExpertCourseCardPartsFragment[] | null
  cardVariant?: CardVariant
}) => {
  const expertsImageData =
    experts?.map((expert) => ({
      url: expert.avatarUrl || '',
      name: expert.name
    })) || []

  if (expertsImageData.length === 0) return null

  let imageSize
  if (cardVariant === CardVariants.BiggerMini) {
    imageSize = 'h-[120px] w-[120px]'
  } else if (cardVariant === CardVariants.Mini) {
    imageSize = 'h-20 w-20'
  } else {
    imageSize = 'h-20 w-20'
  }

  return (
    <div className="relative">
      {expertsImageData.length > 1 && (
        <div className="absolute right-0 bottom-0 rounded-tl-lg bg-rb-orange-25 p-2 text-sm text-rb-gray-400">
          +{expertsImageData.length - 1}
        </div>
      )}
      <Image
        className={`${imageSize} rounded-l-lg object-cover`}
        src={expertsImageData[0].url}
        alt={expertsImageData[0].name}
        preventOptimization={true}
      />
    </div>
  )
}

export default CourseInProgressThumbnail
