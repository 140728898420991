import React, { useEffect, useState } from 'react'
import Pluralize from 'react-pluralize'

import { useCurrentScheduledWeekId } from 'domains/CohortDashboard'

import ProfileModal from 'components/CohortDashboard/ProfileModal'
import Loading from 'components/Loading'
import { useModal } from 'components/Modal'
import OverallLessonProgress from 'components/OverallLessonProgress/OverallLessonProgress'
import { SquarishAvatarImage } from 'components/SquarishAvatarImage'
import Dropdown from 'components/forms/Dropdown'
import ColorCircleCheckIcon from 'components/icons/ColorCircleCheckIcon'
import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import {
  CohortTeamWeekProgressEventAttendeePartsFragment,
  CohortTeamWeekProgressEventPartsFragment,
  CohortTeamWeekProgressScheduledWeekPartsFragment,
  CohortTeamWeekProgressUserPartsFragment,
  CohortViewerNewCohortPartsFragment,
  CohortViewerNewCohortTeamPartsFragment,
  CohortViewerNewScheduledWeekPartsFragment,
  useCohortTeamWeekProgressLazyQuery
} from 'gql'

import {
  filterForScheduledWeeksWithEvents,
  getScheduledWeekProgressSummary
} from 'utils/cohortUtils'

interface MembersProps {
  cohort: CohortViewerNewCohortPartsFragment
  cohortTeam: CohortViewerNewCohortTeamPartsFragment
}

export const Members = ({ cohort, cohortTeam }: MembersProps) => {
  const { weekId } = useCurrentScheduledWeekId({
    cohort,
    ignoreUrlParams: true,
    loaded: true
  })
  const [selectedDropdownWeek, setSelectedDropdownWeek] = useState(weekId)
  const [selectedUserId, setSelectedUserId] = useState('')
  const [getCohortTeamWeekProgress, { data, loading }] =
    useCohortTeamWeekProgressLazyQuery({
      variables: {
        cohortTeamId: cohortTeam.id,
        weekId: selectedDropdownWeek,
        cohortSlug: cohort.slug
      }
    })
  const { isModalOpen, closeModal, openModal } = useModal()

  const handleProfileOpen = (userId: string) => {
    setSelectedUserId(userId)
    openModal()
  }

  useEffect(() => {
    if (weekId) setSelectedDropdownWeek(weekId)
  }, [weekId])

  useEffect(() => {
    if (selectedDropdownWeek) getCohortTeamWeekProgress()
  }, [getCohortTeamWeekProgress, selectedDropdownWeek])

  const containerClassName = 'w-full lg:w-[312px]'
  const scheduledWeeksWithLessons = filterForScheduledWeeksWithEvents(
    cohort
  ) as CohortViewerNewScheduledWeekPartsFragment[]
  const dropdownOptions = scheduledWeeksWithLessons.map((scheduledWeek, idx) => ({
    label: idx === 0 ? 'Kickoff' : `Week ${idx}`,
    id: scheduledWeek.id
  }))
  const { members } = data?.cohortTeam || {}
  const scheduledWeek = data?.cohortDashboardScheduledWeek

  if (loading || !members || !scheduledWeek) {
    return (
      <div className={containerClassName}>
        <Loading />
      </div>
    )
  }

  return (
    <div className={containerClassName}>
      <RfHeader3SemiBold>
        <Pluralize showCount singular="Member" count={members.length} />
      </RfHeader3SemiBold>
      <div className="space-y-8">
        <Dropdown
          options={dropdownOptions}
          selection={selectedDropdownWeek}
          setSelection={setSelectedDropdownWeek as (id: string) => void}
          containerClassName="w-[90px]"
        />
        {members.map((member) => (
          <Member
            member={member}
            scheduledWeek={scheduledWeek}
            events={scheduledWeek.events}
            key={member.id}
            handleProfileOpen={handleProfileOpen}
          />
        ))}
      </div>
      {isModalOpen && (
        <ProfileModal isOpen onClose={closeModal} userId={selectedUserId} />
      )}
    </div>
  )
}

interface MemberProps {
  member: CohortTeamWeekProgressUserPartsFragment
  scheduledWeek: CohortTeamWeekProgressScheduledWeekPartsFragment
  events: CohortTeamWeekProgressEventPartsFragment[]
  handleProfileOpen: (userId: string) => void
}

const Member = ({ member, scheduledWeek, events, handleProfileOpen }: MemberProps) => {
  const {
    totalEstimatedTime,
    totalLessons,
    totalCompleteLessons,
    totalLessonProgressPercent
  } = getScheduledWeekProgressSummary(scheduledWeek, member.activeProgram)

  const getEventsAttendedInWeek = (
    rsvpsInWeek: CohortTeamWeekProgressEventAttendeePartsFragment[],
    events: CohortTeamWeekProgressEventPartsFragment[]
  ): object => {
    const results: { [key: string]: string } = {}

    // We assume a Cohort Event maps to a Core cohort event & all other events associated with the scheduled week are
    // Ask an Expert event(s) (usually only one AE event in a week)
    events.forEach((event) => {
      if (results[event.name]) return

      const attendedEvent = rsvpsInWeek.find(
        (rsvp) => rsvp.eventId === event.id && rsvp.status === 'Attended'
      )

      if (attendedEvent) {
        results[event.name] =
          event.kind === 'Cohort Event'
            ? 'Attended the event'
            : 'Attended the Ask an Expert event'
      }
    })

    return results
  }

  const eventsAttendedInWeek = getEventsAttendedInWeek(member.rsvpsInWeek, events)
  const { avatarUrl, fullName } = member.profile

  return (
    <div
      className="flex hover:cursor-pointer"
      onClick={() => handleProfileOpen(member.id)}
      role="button"
      tabIndex={0}
    >
      <SquarishAvatarImage
        avatarUrl={avatarUrl}
        alt={`${fullName} avatar`}
        className="mr-4 h-8 w-8 rounded"
      />
      <div className="flex flex-col space-y-2 pt-1">
        <RfParagraphMediumBold>{fullName}</RfParagraphMediumBold>
        {totalCompleteLessons > 0 && (
          <OverallLessonProgress
            overallProgressPercent={totalLessonProgressPercent}
            totalLessons={totalLessons}
            completeLessonCount={totalCompleteLessons}
            totalEstimatedTime={totalEstimatedTime}
            uniqueId={member.id}
            includeEstimatedTime={false}
            includeTags={false}
            checkClassName="mr-2 h-4 w-4"
            wordsSize="small"
          />
        )}
        {Object.values(eventsAttendedInWeek).map((event, idx) => (
          <div key={idx} className="flex items-center">
            <ColorCircleCheckIcon name="color-circle-check" className="mr-2 h-4 w-4" />
            <RfParagraphSmall color={ColorOptions.gray}>{event}</RfParagraphSmall>
          </div>
        ))}
      </div>
    </div>
  )
}
