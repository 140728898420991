import { useHistory } from 'react-router-dom'

import Button from 'components/Button'
import { ReforgeBenefits } from 'components/ReforgeBenefits'
import RfHeader1 from 'components/typography/RfHeader/RfHeader1'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import { MIN_WIDTH_TAILWIND_LG } from 'constants/breakpoints'

import { SnapshotDataFragment } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'
import useURLParams from 'hooks/useURLParams'

import { trackCtaClicked } from 'utils/tracking/analytics'

import { ReactComponent as ReforgeLogo } from 'images/reforge-logo-black.svg'
import VideoMeeting from 'images/video-meeting.png'

const MEMBERSHIP_CTA_TEXT = 'See How Membership Works'
const MEMBERSHIP_URL = 'https://reforge.com/membership'

export interface SnapshotLandingPageProps {
  accessPolicyKind?: string
  isLoggedIn: Boolean
  data: SnapshotDataFragment
}

export const SnapshotLandingPage = ({
  accessPolicyKind,
  isLoggedIn,
  data
}: SnapshotLandingPageProps) => {
  const history = useHistory()
  const [, snapshotType] = window.location.pathname.split('/')

  const isDesktop = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_LG})`)
  const { getParam } = useURLParams()
  const source = getParam('utm_source')

  const shouldShowApplyCta =
    !isLoggedIn ||
    (accessPolicyKind !== 'account_created' &&
      accessPolicyKind &&
      ['plan_manager'].includes(accessPolicyKind))

  const shouldShowJoinCta =
    isLoggedIn && accessPolicyKind && ['accepted', 'expired'].includes(accessPolicyKind)

  const previewCtaText = 'View a Lesson'
  const applyCtaCopy = isDesktop ? 'Join Now' : 'Join'
  const joinCtaCopy = isDesktop ? 'Join Now' : 'Join'
  const learnCopy = snapshotType === 'enrollment_snapshots' ? 'will learn' : 'learned'

  const {
    cmsProgramId,
    contentLink,
    firstName,
    imageUrl,
    programDescription,
    programName,
    showcaseContentId,
    slug
  } = data

  const previewHeader = `See what ${firstName} ${learnCopy} in ${programName}`

  const getUrlParams = () => {
    const internalRef = `internal_ref=${slug}-snapshot`
    const cmsProgram = `cms_program_id=${cmsProgramId}`
    const utmCampaign = `utm_campaign=${
      snapshotType === 'enrollment_snapshots'
        ? 'enrollment_snapshot'
        : 'completion_snapshot'
    }`
    const utmMedium = 'utm_medium=social'
    const utmSource = `utm_source=${source}`
    return [internalRef, cmsProgram, utmCampaign, utmMedium, utmSource].join('&')
  }
  const applyLink = `/apply?${getUrlParams()}`
  const joinLink = '/'

  const handleHeaderCtaClick = () => {
    const ctaLocation = 'snapshot_top_nav'
    const ctaType = 'button'
    if (shouldShowApplyCta) {
      trackCtaClicked({
        cta_location: ctaLocation,
        cta_type: ctaType,
        destination: applyLink,
        related_identifiers: { cms_program_id: cmsProgramId },
        text: applyCtaCopy
      })
      history.push(applyLink)
    }
    if (shouldShowJoinCta) {
      trackCtaClicked({
        cta_location: ctaLocation,
        cta_type: ctaType,
        destination: joinLink,
        related_identifiers: { cms_program_id: cmsProgramId },
        text: joinCtaCopy
      })
      window.location.href = joinLink
    }
  }

  const handlePreviewButtonClick = () => {
    trackCtaClicked({
      cta_location: 'snapshot_lesson_preview_section',
      cta_type: 'button',
      destination: contentLink,
      related_identifiers: { cms_section_id: showcaseContentId },
      text: previewCtaText
    })
    window.location.href = contentLink
  }

  const handleSnapshotImageClick = () => {
    trackCtaClicked({
      cta_location: 'snapshot_landing_page_image',
      cta_type: 'link',
      destination: contentLink,
      related_identifiers: { cms_section_id: showcaseContentId },
      text: 'clickable_image'
    })
    window.location.href = contentLink
  }

  const handleMembershipButtonClick = () => {
    const destination = `${MEMBERSHIP_URL}?internal_ref=${slug}-snapshot&cms_program_id=${cmsProgramId}`
    trackCtaClicked({
      cta_location: 'snapshot_reforge_membership_section',
      cta_type: 'button',
      destination: destination,
      text: MEMBERSHIP_CTA_TEXT
    })
    window.open(destination, '_blank', 'noopener,noreferrer')
  }

  return (
    <div className="bg-rb-gray-50">
      <div className="flex h-14 items-center justify-between bg-white px-4 shadow-default lg:h-24 lg:px-8">
        <a href="/" className="hover:cursor-pointer">
          <ReforgeLogo className="w-24 fill-current text-rb-black lg:w-36" />
        </a>
        {shouldShowApplyCta && (
          <Button
            className="lg:h-11 lg:rounded-sm"
            data-testid="slp-apply-cta"
            size="x-small"
            color="teal"
            shape="rounded-full"
            onClick={handleHeaderCtaClick}
          >
            {applyCtaCopy}
          </Button>
        )}
        {shouldShowJoinCta && (
          <Button
            className="lg:h-11 lg:rounded-sm"
            size="x-small"
            color="teal"
            shape="rounded-full"
            onClick={handleHeaderCtaClick}
          >
            {joinCtaCopy}
          </Button>
        )}
      </div>
      <div className="p-4 pt-6 lg:pt-8">
        {imageUrl && (
          <div className="mb-6 rounded-lg border border-rb-gray-100 bg-white px-4 py-6 lg:flex lg:items-center">
            <button className="lg:w-80" onClick={handleSnapshotImageClick}>
              <img
                className="m-auto mb-4 rounded-lg lg:mb-0"
                alt="cohort snapshot"
                src={imageUrl}
              />
            </button>
            <div className="flex-col lg:ml-12 lg:flex-1">
              <RfHeader1 className="mb-3 lg:mb-5">{previewHeader}</RfHeader1>
              <RfParagraphMedium className="mb-4">{programDescription}</RfParagraphMedium>
              <Button
                className="m-auto rounded-sm normal-case lg:ml-0"
                size="medium"
                variant="outline"
                onClick={handlePreviewButtonClick}
              >
                {previewCtaText}
              </Button>
            </div>
          </div>
        )}
        <div className="rounded-lg border border-rb-gray-100 bg-white px-4 py-6 lg:flex lg:items-center">
          <div className="lg:w-80">
            <img
              className="m-auto mb-4 w-36 sm:w-56 lg:mb-0"
              alt="video meeting"
              src={VideoMeeting}
            />
          </div>
          <div className="flex-col lg:ml-12 lg:flex-1">
            <RfHeader1 className="mb-3 lg:mb-5">About Reforge membership</RfHeader1>
            <RfParagraphMedium className="mb-4">
              Our membership puts you in the driver seat of your career. From cohort-based
              programs to our unprecedented content library and expert network, members
              unlock instant access to insights you can apply immediately to solve your
              toughest business challenges.
            </RfParagraphMedium>
            <Button
              className="m-auto rounded-sm lg:ml-0"
              size="medium"
              variant="outline"
              onClick={handleMembershipButtonClick}
            >
              {MEMBERSHIP_CTA_TEXT}
            </Button>
          </div>
        </div>
        <ReforgeBenefits />
      </div>
    </div>
  )
}
