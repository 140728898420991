import { useState } from 'react'

import DropdownContextMenu from 'components/dropdowns/Dropdown/DropdownContextMenu'
import { ChevronUpThinIcon } from 'components/icons'

import { trackSortSelected } from 'utils/tracking/analytics'

interface MySavedItemsFilterToggleProps {
  updateFilter: (param: string) => void
  currentFilter: string
  options: string[]
}

const MySavedItemsFilterToggle = ({
  updateFilter,
  currentFilter,
  options
}: MySavedItemsFilterToggleProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const triggerElement = () => {
    return (
      <span className="flex items-center text-rb-gray-300">
        <span className="mx-2">{currentFilter}</span>
        <ChevronUpThinIcon
          className={`h-4 w-4 fill-current ${isOpen ? '' : 'rotate-180 transform'}`}
        />
      </span>
    )
  }

  const toggleFilter = (option: string) => {
    trackSortSelected({
      location: 'saved_items',
      sort_by: option
    })
    updateFilter(option)
  }

  return (
    <DropdownContextMenu
      className="text-right"
      triggerElement={triggerElement()}
      positions={['bottom']}
      align={'end'}
      onToggleDropdown={(toOpen) => setIsOpen(toOpen)}
    >
      {options.map((option) => (
        <DropdownContextMenu.DropdownItem
          text={option}
          onClick={() => toggleFilter(option)}
          key={option}
        />
      ))}
    </DropdownContextMenu>
  )
}

export default MySavedItemsFilterToggle
