import Button from 'components/Button'

import useOpenPurchaseFlow from 'hooks/useOpenPurchaseFlow'

import { trackCtaClicked } from 'utils/tracking/analytics'

const Expired = ({
  hasPaymentMethod,
  wasTeam
}: {
  hasPaymentMethod: boolean
  wasTeam: boolean
}) => {
  const { openPurchaseFlow, destination: purchaseFlowDestination } = useOpenPurchaseFlow({
    reactivate: true
  })

  const getText = () => {
    if (!hasPaymentMethod && wasTeam) {
      return (
        <>
          Your team’s subscription has expired and you have automatically been downgraded
          to the free tier. Reactivate your membership by contacting{' '}
          <a href="mailto:hello@reforge.com">hello@reforge.com</a> or by adding a new
          payment method in order to regain access for your team.
        </>
      )
    } else {
      return (
        <>
          Your subscription has expired and you have automatically been downgraded to the
          free tier. Reactivate your membership today to regain access to Reforge content.
        </>
      )
    }
  }

  const handleCtaClick = () => {
    trackCtaClicked({
      cta_location: 'subscription_card',
      cta_type: 'button',
      text: 'reactivate membership',
      destination: purchaseFlowDestination
    })

    openPurchaseFlow()
  }

  return (
    <>
      {getText()}
      <div className="mt-6 grid max-w-max gap-3 md:grid-flow-col md:gap-5">
        <Button
          color="teal"
          size="x-small"
          shape="rounded-full"
          className="h-7.5"
          onClick={handleCtaClick}
        >
          Reactivate Membership
        </Button>
      </div>
    </>
  )
}

export default Expired
