import { CarouselProvider } from 'pure-react-carousel'

import { CarouselWithStore } from 'domains/Seo/SeoPageCarousel/SeoPageCarousel'
import useSeoPageSlidesCount from 'domains/Seo/useSeoPageSlidesCount'

import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'

import { Artifact } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { cn } from 'utils/tailwind'

import SeoPageArtifactCard from '../SeoPageArtifactCard'

export interface RelatedArtifactsCarouselSectionProps {
  artifacts: Artifact[]
  providerClassName?: string // What's this?
}

const TRACKING_LOCATION = 'artifacts__related_content_section'

export const RelatedArtifactsCarouselSection = ({
  artifacts,
  providerClassName
}: RelatedArtifactsCarouselSectionProps) => {
  const isMobileView = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)
  const slideWidth = isMobileView ? 288 : 352
  const visibleSlides = useSeoPageSlidesCount()

  return (
    <CarouselProvider
      naturalSlideWidth={slideWidth}
      naturalSlideHeight={400}
      totalSlides={artifacts.length + 1} // +1 for the empty element
      visibleSlides={visibleSlides}
      isIntrinsicHeight={true}
      className={cn('mb-[104px] w-full', providerClassName)}
    >
      <CarouselWithStore
        isSmallGap={isMobileView}
        slideWidth={slideWidth}
        visibleSlides={visibleSlides}
        title="Related Examples"
        bottomText="Explore more examples from top experts"
        ctaText="See all examples"
        ctaHref="/artifacts"
        navigationLocation={TRACKING_LOCATION}
      >
        {artifacts.map((artifact) => (
          <SeoPageArtifactCard
            artifact={artifact}
            location={TRACKING_LOCATION}
            key={artifact.id}
          />
        ))}
      </CarouselWithStore>
    </CarouselProvider>
  )
}

export default RelatedArtifactsCarouselSection
