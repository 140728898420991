import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { Page, PageSeo } from 'gql'

import ExtensionMarketingPageContent from './ExtensionMarketingPageContent'

const ExtensionMarketingPage = () => {
  const title = 'Chrome Extension — Reforge'
  const description =
    'An expert by your side, as you work. Instant feedback from those who have done it before in the places you work.'

  const pageSeo = {
    __typename: 'PageSeo',
    canonicalUrl: 'https://www.reforge.com/extension',
    metaTitle: title,
    metaDesc: description,
    shareDesc: description,
    shareGraphic: {
      asset:
        'https://cdn.sanity.io/images/pghoxh0e/production/07a9a78b6e40f5340a7550895e33d32f2efc1b9b-1200x630.png'
    },
    shareTitle: title,
    type: 'website'
  } as PageSeo
  return (
    <MarketingLayout
      page={
        {
          title: 'Chrome Extension',
          seo: pageSeo
        } as Page
      }
    >
      <ExtensionMarketingPageContent />
    </MarketingLayout>
  )
}

export default ExtensionMarketingPage
