import InputFilter from './InputFilter'
import { FilterProps } from './helpers'

const Location = ({ filters, setFilters }: FilterProps) => (
  <InputFilter
    label="location"
    placeholder="San Francisco, Lisbon, Tel Aviv, etc."
    name="locationContains"
    filters={filters}
    setFilters={setFilters}
  />
)

export default Location
