import { useHistory } from 'react-router-dom'

import Button from 'components/Button'
import { Modal, ModalContent, ModalHeader } from 'components/Modal'
import WarningIcon from 'components/icons/WarningIcon'

import { BookmarkFolderPartsFragment, useDeleteBookmarkFolderMutation } from 'gql'

import useModalTracking from 'hooks/useModalTracking'

import notifyError from 'utils/errorNotifier'

interface IDeleteCollectionConfirmationModal {
  isOpen: boolean
  isSmallScreen: boolean
  setIsOpen: (arg0: boolean) => void
  setOpenPreviousModal: (arg0: boolean) => void
  collection: BookmarkFolderPartsFragment
}

export default function DeleteCollectionConfirmationModal({
  isOpen,
  isSmallScreen,
  setIsOpen,
  setOpenPreviousModal,
  collection
}: IDeleteCollectionConfirmationModal) {
  const history = useHistory()
  useModalTracking(isOpen, {
    modal_group: 'dunning',
    modal_name: 'leave checkout confirm'
  })

  const [deleteBookmarkFolder] = useDeleteBookmarkFolderMutation({
    update: (cache, mutationResult) => {
      const deletedBookmarkFolderId = mutationResult?.data?.deleteBookmarkFolder?.id
      if (!deletedBookmarkFolderId) return

      const normalizedId = cache.identify({
        id: deletedBookmarkFolderId,
        __typename: 'BookmarkFolder'
      })
      cache.evict({ id: normalizedId })
      cache.gc()
    }
  })

  const handleClose = () => {
    setIsOpen(false)
    setOpenPreviousModal(true)
  }

  const handleConfirm = async () => {
    setIsOpen(false)
    const { errors } = await deleteBookmarkFolder({
      variables: { input: { bookmarkFolderId: collection.id } }
    })
    if (errors) {
      notifyError(errors)
    }
    history.push('/saved')
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className="max-w-screen-lg pt-9"
      scrollContent={false}
      header={false}
    >
      <div className="flex flex-col items-center">
        <ModalHeader>
          <WarningIcon className="h-11 w-11 fill-current text-black" />
        </ModalHeader>
        <ModalContent className="w-full py-3 md:py-10" scrollContent={false}>
          <p className="text-center">
            Are you sure you want to delete {collection.name}?
          </p>
          <div className={`flex ${isSmallScreen ? 'justify-center' : 'justify-end'}`}>
            <Button
              onClick={handleClose}
              size={isSmallScreen ? 'small' : 'medium'}
              variant="text-only"
              className={isSmallScreen ? 'mr-5 border-2 border-rb-gray-500' : ''}
            >
              No
            </Button>
            <Button
              onClick={handleConfirm}
              size={isSmallScreen ? 'small' : 'medium'}
              shape="rounded"
              className="border-rb-warning-200 bg-rb-warning-200"
            >
              Yes
            </Button>
          </div>
        </ModalContent>
      </div>
    </Modal>
  )
}
