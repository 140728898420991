export const lightboxImages = (selector: string) => {
  Array.from(document.querySelectorAll(selector)).forEach(function (img: any) {
    // skip images that are already wrapped in an external link
    if (
      img.parentNode.tagName === 'A' &&
      img.parentNode.getAttribute('href') &&
      img.parentNode.getAttribute('href').startsWith('http')
    ) {
      return
    }
    // skip example card company logos
    if (img.classList.contains('example-card__company-img')) {
      return
    }

    // wrap the image in an anchor
    const a = document.createElement('a')
    a.innerHTML = img.outerHTML
    a.setAttribute('href', img.src)
    a.setAttribute('data-type', 'image')
    a.className = 'rf-lightbox-anchor'

    // // wrap the anchor + image in a container
    const lightboxContainer = document.createElement('div')
    lightboxContainer.appendChild(a)
    lightboxContainer.setAttribute('uk-lightbox', 1 as any)
    lightboxContainer.className = 'rf-lightbox-container'

    // replace the original image with the wrapped one
    img.parentNode.replaceChild(lightboxContainer, img)
  })
}
