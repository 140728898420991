export const DocumentIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M25.9459 26.4456C25.9459 26.9734 25.7362 27.4797 25.363 27.8529C24.9898 28.226 24.4835 28.4358 23.9557 28.4358H6.04413C5.5163 28.4358 5.0101 28.226 4.63686 27.8529C4.26364 27.4797 4.05396 26.9734 4.05396 26.4456V4.55366C4.05396 4.02582 4.26364 3.51962 4.63686 3.14639C5.0101 2.77316 5.5163 2.56348 6.04413 2.56348H18.9803L25.9459 9.5291V26.4456Z"
        stroke="#2D2F2F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0244 10.5244H14.0048"
        stroke="#2D2F2F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0244 16.4951H19.9753"
        stroke="#2D2F2F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0244 22.4648H19.9753"
        stroke="#2D2F2F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
