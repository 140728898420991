import LegacyContentCard from 'components/cards/Content/LegacyContentCard'

import { RelatedContentPartsFragment } from 'gql'

import { ProgramProgressScalar } from 'typings/scalars'

// TODO: After old ContentCard component is deprecated
// move slug & previewable to RelatedContentParts
type RelatedCardsProps = RelatedContentPartsFragment & {
  slug: string
  previewable: boolean
}
interface IRelatedContent {
  cmsContentId: string
  userProgress?: ProgramProgressScalar
  relatedCards: RelatedCardsProps[]
}

export default function RelatedContent({ relatedCards }: IRelatedContent) {
  const projectCards =
    relatedCards?.filter((card) => card.contentType === 'Project') || []
  const conceptCards =
    relatedCards?.filter((card) => card.contentType === 'Concept') || []

  return (
    <div className="content-cards mt-12">
      {projectCards.length > 0 && (
        <>
          <div className="mb-3 font-sans text-2xl font-bold text-rb-gray-500">
            Related Projects
          </div>
          <div className="m-0 mr-9 mb-2.5 grid grid-cols-[repeat(auto-fill,minmax(252px,auto))] gap-[20px] p-0">
            {projectCards.map((contentCard) => (
              <div key={`relatedcard${contentCard.id}`}>
                <LegacyContentCard content={contentCard} />
              </div>
            ))}
          </div>
        </>
      )}

      {conceptCards.length > 0 && (
        <>
          <div className="mb-3 font-sans text-2xl font-bold text-rb-gray-500">
            Related Concepts
          </div>
          <div className="m-0 mr-9 mb-2.5 grid grid-cols-[repeat(auto-fill,minmax(252px,auto))] gap-[20px] p-0">
            {conceptCards.map((contentCard) => (
              <div key={`relatedcard${contentCard.id}`}>
                <LegacyContentCard content={contentCard} />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}
