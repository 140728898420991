import ReactTooltip from 'react-tooltip'
import { twMerge } from 'tailwind-merge'

import { CurrentUserPartsFragment, EventCurrentUserPartsFragment } from 'gql'

import FacePile, { FacePileUser } from './FacePile'
import FacePileContainer from './FacePileContainer'
import FacePileImage from './FacePileImage'

export interface FacePileButtonProps {
  currentUser?: CurrentUserPartsFragment | EventCurrentUserPartsFragment
  users: FacePileUser[]
  count: number
  toolTip: string
  imageSize?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge'
  isAttending?: boolean
  showOutline?: boolean
  href?: string
  onClick: () => void
  showCountAsMore?: boolean
}

const FacePileButton = ({
  currentUser,
  users,
  count,
  toolTip,
  isAttending,
  imageSize = 'large',
  showOutline = true,
  href,
  onClick,
  showCountAsMore = false
}: FacePileButtonProps) => {
  const btnContent = (
    <div
      className={twMerge(
        'flex h-auto w-auto max-w-max items-center outline-none focus:outline-none',
        showOutline &&
          'rounded-full border border-rb-gray-100 px-2 py-1 shadow-default sm:px-3'
      )}
    >
      <FacePileContainer>
        {currentUser && isAttending ? (
          <FacePileImage
            imageSize={imageSize}
            className="ml-0"
            zIndex={users.length + 1}
            src={currentUser.profile.avatarUrl}
          />
        ) : null}
        <FacePile users={users} imageSize={imageSize} />
      </FacePileContainer>
      <div
        className={twMerge('text-xs sm:ml-1', showOutline && 'font-medium sm:text-sm')}
      >
        <AttendeeCount
          showCountAsMore={showCountAsMore}
          count={count}
          facepileCount={users.length}
        />
      </div>
    </div>
  )

  return (
    <div>
      {href ? (
        <a
          href={href}
          data-for="facepile-tooltip"
          data-tip={toolTip}
          data-test="facepile-button"
        >
          {btnContent}
        </a>
      ) : (
        <button
          onClick={onClick}
          data-for="facepile-tooltip"
          data-tip={toolTip}
          data-test="facepile-button"
        >
          {btnContent}
        </button>
      )}

      {toolTip ? (
        <ReactTooltip
          place="bottom"
          effect="solid"
          backgroundColor="#4D4D4D"
          id="facepile-tooltip"
        />
      ) : null}
    </div>
  )
}

const AttendeeCount = ({
  count,
  facepileCount,
  showCountAsMore
}: {
  count: number
  facepileCount: number
  showCountAsMore: boolean
}) => {
  return (
    <span className="text-rb-black">
      {getAttendeesCount(count, facepileCount, showCountAsMore)}
    </span>
  )
}

function getAttendeesCount(
  count: number,
  facepileCount: number,
  showCountAsMore: boolean
) {
  if (!facepileCount) return count
  if (count <= facepileCount) return count

  return showCountAsMore ? `${count - facepileCount} more` : count
}

export default FacePileButton
