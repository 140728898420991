import React, { useState } from 'react'

import Button from 'components/Button'
import Modal, {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle
} from 'components/Modal'

import { ProgramBookmarkPartsFragment, useUpdateBookmarkMutation } from 'gql'

import notifyError from 'utils/errorNotifier'
import { onEnterKeyPress } from 'utils/keyboard'

interface LinkModalProps {
  isModalOpen: boolean
  bookmark?: ProgramBookmarkPartsFragment
  handleClose: () => void
  handleSubmit?: (title: string, externalUrl: string) => Promise<void> | void
  handleEditLinkTracking?: () => void
}

const LinkModal: React.FC<LinkModalProps> = ({
  isModalOpen,
  bookmark,
  handleClose,
  handleSubmit,
  handleEditLinkTracking
}) => {
  const [title, setTitle] = useState(bookmark?.title || '')
  const [url, setUrl] = useState(bookmark?.externalUrl || '')
  const [updateBookmark] = useUpdateBookmarkMutation()

  const handleUpdate = async () => {
    if (!bookmark) return

    const httpUrl = url?.indexOf('http') === 0 ? url : `https://${url}`

    const { errors } = await updateBookmark({
      variables: {
        input: {
          bookmarkId: bookmark.id,
          title: title,
          externalUrl: httpUrl
        }
      }
    })

    handleEditLinkTracking?.()

    if (errors) {
      notifyError(
        `Error modifying ExternalUrlBookmark, got errors ${errors} for bookmark ${JSON.stringify(
          bookmark
        )}`
      )
    }
    setUrl(httpUrl)
    handleClose()
  }

  return (
    <Modal
      isOpen={isModalOpen}
      handleClose={handleClose}
      className="max-w-md rounded-2xl"
      header={false}
      closeOnEscape={true}
      closeOnOutsideClick={true}
    >
      <ModalHeader className="mt-8 flex md:px-8">
        <ModalTitle>{bookmark ? 'Update' : 'Add'} Link</ModalTitle>
      </ModalHeader>
      <ModalContent className="px-8 md:px-8" scrollContent={false}>
        <div className="pb-2 pt-4 text-sm font-medium">Title</div>
        <input
          type="title"
          className="mr-3 h-12 w-full rounded-sm border pl-5 text-rb-gray-400 outline-none focus:border-blue-300"
          name="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <div className="pb-2 pt-8 text-sm font-medium">Link</div>
        <input
          type="url"
          className="mr-3 h-12 w-full rounded-sm border pl-5 text-rb-gray-400 outline-none focus:border-blue-300"
          name="url"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          onKeyUp={onEnterKeyPress(
            bookmark ? handleUpdate : () => handleSubmit?.(title, url)
          )}
          // eslint-disable-next-line
          autoFocus
        />
      </ModalContent>
      <ModalFooter>
        <Button
          className="mr-3 w-32 border border-gray-300 bg-white font-normal text-black"
          onClick={handleClose}
          size="medium"
        >
          Cancel
        </Button>
        <Button
          className="w-32 font-normal"
          onClick={bookmark ? handleUpdate : () => handleSubmit?.(title, url)}
          size="medium"
          disabled={
            url?.trim() === '' ||
            (title === bookmark?.title && url === bookmark?.externalUrl)
          }
        >
          {bookmark ? 'Update' : 'Save'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default LinkModal
