import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom-v5-compat'
import { twMerge } from 'tailwind-merge'

import BadgeableAvatar from 'components/BadgeableAvatar'
import {
  CohortPostCardDropdown,
  CohortPostCardReactions
} from 'components/CohortPostCard'
import CohortPostContentPreview from 'components/CohortPostCard/CohortPostContentPreview'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'
import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import RfParagraphSmallBold from 'components/typography/RfParagraph/RfParagraphSmallBold'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import { CohortPostPartsFragment, CohortPostReplyPartsFragment } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useTogglePostFollow } from 'hooks/useTogglePostFollow'

import { isStaffOrCollective } from 'utils/cohortConversationUtils'
import { displaySuccessToast } from 'utils/toastService'

import useUpdateCohortPost from './hooks/useUpdateCohortPost'

interface CohortConversationThreadPostProps {
  post: CohortPostPartsFragment
  reply?: CohortPostReplyPartsFragment
  reactableType: string
  refetchPosts?(): Promise<unknown>
  currentUserId: string
  openEditModal: () => void
  onDeletePostClick: () => void
  onReportPostClick: () => void
  label: string
  openProfileModal: () => void
  cohortSlug: string
}

const CohortConversationThreadPost = ({
  reply,
  post,
  reactableType,
  refetchPosts,
  currentUserId,
  openEditModal,
  onDeletePostClick,
  onReportPostClick,
  label,
  openProfileModal,
  cohortSlug
}: CohortConversationThreadPostProps) => {
  const { currentUser } = useCurrentUser()
  const { onTogglePinPost, isUpdatingPost: posting } = useUpdateCohortPost()
  const [searchParams, setSearchParams] = useSearchParams()

  const { loading, toggleFollow } = useTogglePostFollow({
    postId: post?.id,
    following: post?.following,
    refetch: refetchPosts
  })

  useEffect(() => {
    const _toggleFollow = () => {
      toggleFollow().then(() => {
        const message = queryParam === 'false' ? 'no longer' : 'now'
        displaySuccessToast({
          message: `You will ${message} receive notifications for this conversation.`
        })

        searchParams.delete('follow')
        setSearchParams(searchParams)
      })
    }

    const queryParam = searchParams.get('follow')

    if (label === 'post' && !loading) {
      if (queryParam === 'false' && post?.following) {
        _toggleFollow()
      } else if (queryParam === 'true' && !post?.following) {
        _toggleFollow()
      }
    }
  }, [setSearchParams, searchParams, post?.following, label, toggleFollow, loading])

  const sharedObject = label === 'reply' ? reply : post

  if (!sharedObject) return null

  const isPost = label === 'post'

  return (
    <div
      className={twMerge('flex flex-col pb-6 pl-6 pr-4 pt-10', !isPost && 'pt-6')}
      style={
        isPost
          ? {
              background:
                'radial-gradient(96.67% 128.89% at 25.14% 71.61%, rgba(180, 243, 241, 0.3) 0%, rgba(225, 255, 228, 0.3) 79.69%)'
            }
          : {}
      }
    >
      <div className="flex items-start">
        <button onClick={openProfileModal} className="cursor-pointer hover:underline">
          <BadgeableAvatar
            width="40"
            height="40"
            avatarUrl={sharedObject.user.profile.avatarUrl}
            borderClassNames="rounded"
            className="static"
            fullName={sharedObject.user.profile.fullName}
          />
        </button>
        <div className="flex flex-col items-baseline pl-[16px] sm:flex-row">
          <button onClick={openProfileModal} className="cursor-pointer hover:underline">
            <RfParagraphSmallBold className="pr-2.5" color={ColorOptions.black}>
              {sharedObject.user.profile.fullName}
            </RfParagraphSmallBold>
          </button>
          <RfParagraphMini color={ColorOptions.gray}>
            {sharedObject.createdAtInWords} ago
          </RfParagraphMini>
        </div>

        <div className="flex flex-1 items-center justify-end">
          <div className="flex w-1/2 justify-end">
            <CohortPostCardDropdown
              disabled={loading || posting}
              onDeletePost={onDeletePostClick}
              onReportPost={onReportPostClick}
              onEditPost={openEditModal}
              onTogglePostNotification={toggleFollow}
              isPostPinned={post.isPinned || false}
              onTogglePinPost={
                isPost
                  ? () =>
                      onTogglePinPost({
                        postId: post.id,
                        isPinned: !post.isPinned,
                        refetch: refetchPosts
                      })
                  : () => {}
              }
              isUserFollowingPost={post?.following || false}
              canUserPinPost={(isPost && isStaffOrCollective(currentUser)) || false}
              canUserEditPost={currentUser?.id === sharedObject.user.id}
              canUserDeletePost={
                isStaffOrCollective(currentUser) ||
                currentUser?.id === sharedObject.user.id
              }
              label={label}
              reactions={sharedObject.reactions}
              currentUserId={currentUserId}
            />
          </div>
        </div>
      </div>

      <div className="pl-0 pt-1 pr-2 sm:pl-[56px] lg:-mt-6">
        <div>
          {isPost && (
            <RfParagraphMediumBold className="mb-2">{post?.title}</RfParagraphMediumBold>
          )}

          <RfParagraphSmall color={ColorOptions.black}>
            <span dangerouslySetInnerHTML={{ __html: sharedObject.body || '' }} />
          </RfParagraphSmall>

          {isPost && <CohortPostContentPreview post={post} cohortSlug={cohortSlug} />}

          <CohortPostCardReactions
            currentUserId={currentUserId}
            reactions={sharedObject.reactions}
            reactableId={sharedObject.id}
            reactableType={reactableType}
            reactablePost={post}
          />
        </div>
      </div>
    </div>
  )
}

export default CohortConversationThreadPost
