import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid'
import { formatDistanceToNowStrict } from 'date-fns'
import React, { useState } from 'react'
import ClickAwayListener from 'react-click-away-listener'
import ReactTooltip from 'react-tooltip'
import { twMerge } from 'tailwind-merge'

import { showDeletedToast } from 'domains/Artifact/comments/utils'

import { displayToast } from 'components/Toast'
import ToastCard, { toastOptions } from 'components/ToastCard'

import { COMMENT_CLASSNAME } from 'constants/artifacts'

import {
  Artifact,
  ArtifactComment,
  useAddArtifactCommentReactionMutation,
  useFlagArtifactCommentMutation,
  useRemoveArtifactCommentMutation,
  useRemoveArtifactCommentReactionMutation
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import AvatarImage from './AvatarImage'
import { CopyLinkButton } from './CopyLinkButton'
import EditCommentForm from './EditCommentForm'
import ReactionBar from './ReactionBar'

const CommentTime = ({ createdAtUtc }: { createdAtUtc: string | null }) => {
  if (!createdAtUtc) return null

  return (
    <span className="mr-[10px] text-right text-xs text-rb-gray-300">
      {`${formatDistanceToNowStrict(new Date(createdAtUtc))} ago`}
    </span>
  )
}

interface ArtifactCommentItemProps {
  artifact: Artifact
  comment: ArtifactComment
  isActive?: boolean
  onDeleteClicked?: (commentId: number) => void
}

type Mode = 'viewing' | 'editing'

const ArtifactCommentItem = ({
  artifact,
  comment,
  isActive,
  onDeleteClicked
}: ArtifactCommentItemProps) => {
  const { currentUser: user } = useCurrentUser()

  const [mode, setMode] = useState<Mode>('viewing')

  const [addArtifactCommentReaction] = useAddArtifactCommentReactionMutation()
  const [removeArtifactCommentReaction] = useRemoveArtifactCommentReactionMutation()

  const getCommentMetadata = () => {
    return {
      artifactId: artifact.id,
      artifactTitle: artifact.title,
      commentId: comment.id,
      commentAuthorId: comment.user.id
    }
  }

  const handleReactionBarClick = (e: any) => {
    // prevent comment click for this case
    e.stopPropagation()
  }

  const handleReactionAdded = async (reactionId: string) => {
    const commentMetadata = getCommentMetadata()

    await addArtifactCommentReaction({
      variables: {
        input: {
          artifactId: commentMetadata.artifactId,
          artifactCommentId: commentMetadata.commentId,
          userId: user?.id || '',
          reaction: reactionId
        }
      },
      refetchQueries: ['ArtifactComments']
    })
  }

  const handleReactionRemoved = async (reactionId: string) => {
    const commentMetadata = getCommentMetadata()

    await removeArtifactCommentReaction({
      variables: {
        input: {
          artifactCommentId: commentMetadata.commentId,
          userId: user?.id || '',
          reaction: reactionId
        }
      },
      refetchQueries: ['ArtifactComments']
    })
  }

  const ownComment = user?.id === comment?.user?.id
  const createdAtUtc = comment?.createdAt
  const author = comment?.user

  const handleEditClicked = () => {
    setMode('editing')
  }

  if (mode === 'editing') {
    return (
      <EditCommentForm
        comment={comment}
        onSaveSuccess={() => setMode('viewing')}
        onCancel={() => setMode('viewing')}
      />
    )
  }

  if (mode === 'viewing') {
    return (
      <div
        className={twMerge(
          COMMENT_CLASSNAME,
          'flex w-full flex-col border bg-white p-6',
          isActive && 'border-[#CADD48]'
        )}
        data-comment-id={comment.id}
      >
        <div className="flex flex-row gap-4">
          <AvatarImage size="mini" user={author} />
          <div className="flex w-full flex-row justify-between">
            <div>
              <div className="flex items-start gap-2 text-sm">
                <span className="font-semibold leading-none">{author.fullName}</span>
                <CommentTime createdAtUtc={createdAtUtc} />
              </div>
              <div className="text-xs">
                {!!author.role &&
                  !!author.companyName &&
                  `${author.role} @ ${author.companyName}`}
                {!author.role && !!author.companyName && `${author.companyName}`}
              </div>
            </div>
            <div className="flex flex-row justify-end">
              <ButtonCommentMenu
                artifact={artifact}
                comment={comment}
                onDeleteClicked={onDeleteClicked}
                onEditClicked={handleEditClicked}
                ownComment={ownComment}
              />
            </div>
            <div>
              <CopyLinkButton
                tooltipId={`copy-link-button-comment-${comment.id}`}
                tooltipTextInit="Copy link"
                textToCopy={`${
                  window.location.origin + window.location.pathname
                }?comment=${comment.id}`}
                trackingData={{
                  content_link_id: comment.id,
                  content_link_type: 'artifact_comment',
                  related_identifiers: {
                    artifact_id: artifact.id,
                    artifact_title: artifact.title ?? undefined
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col">
          <div className="mt-[15px] mb-[20px] flex w-full flex-row flex-wrap overflow-hidden break-words text-sm">
            {comment.message.split('\n').map(
              (commentPart, index) =>
                commentPart.length > 0 && (
                  <div className="mb-[10px]" key={`${comment.id}-${index}`}>
                    {commentPart}
                  </div>
                )
            )}
          </div>
          <div
            className="flex flex-row text-sm"
            onClick={handleReactionBarClick}
            onKeyDown={handleReactionBarClick}
            role="button"
            tabIndex={0}
          >
            <ReactionBar
              id={`reaction-bar-comment-${artifact.id}-${comment.id}`}
              reactions={comment.reactionsGroupedByName}
              onReactionAdded={handleReactionAdded}
              onReactionRemoved={handleReactionRemoved}
            />
            <div className="grow" />
          </div>
          <ReactTooltip
            id={`reaction-bar-comment-${artifact.id}-${comment.id}-add-button`}
            className="text-sm"
            place="top"
            effect="solid"
          >
            Add Reaction
          </ReactTooltip>
        </div>
      </div>
    )
  }

  return null
}

const CommentButtonItem = ({
  children,
  onClick = async () => {}
}: {
  children: React.ReactNode
  onClick?: () => void
}) => {
  return (
    <button
      className="text-rb-gray-3 group flex h-[32px] w-full items-center justify-start rounded-md bg-rb-white px-[12px] py-[3px] text-base hover:bg-rb-gray-50"
      onClick={onClick}
    >
      {children}
    </button>
  )
}

const ButtonCommentMenu = ({
  artifact,
  comment,
  ownComment = false,
  showEdit = true,
  onDeleteClicked,
  onEditClicked = () => {}
}: {
  artifact: Artifact
  comment: ArtifactComment
  ownComment?: boolean
  showEdit?: boolean
  onDeleteClicked?: (commentId: number) => void
  onEditClicked?: (comment: any) => void
}) => {
  const [open, setOpen] = useState(false)

  const [flagArtifactComment] = useFlagArtifactCommentMutation()
  const [removeArtifactComment] = useRemoveArtifactCommentMutation()

  const { currentUser: user } = useCurrentUser()

  const handleClickAway = () => {
    setOpen(false)
  }

  const toggleOpen = () => {
    setOpen(!open)
  }

  const handleDeleteClicked = () => {
    setOpen(false)

    if (onDeleteClicked) {
      return onDeleteClicked(Number(comment.id))
    }

    if (!user?.id) return

    const artifactCommentId = String(comment.id)

    removeArtifactComment({
      variables: { input: { artifactCommentId } },
      refetchQueries: ['ArtifactComments']
    })

    showDeletedToast()
  }

  const handleEditClicked = () => {
    setOpen(false)
    onEditClicked(comment)
  }

  const handleFlagClicked = async (commentId: string) => {
    if (!user?.id) return

    await flagArtifactComment({
      variables: {
        input: {
          artifactCommentId: commentId,
          artifactTitle: artifact.title || ''
        }
      },
      refetchQueries: ['ArtifactComments']
    })

    displayToast(
      <ToastCard
        type="success"
        message="Thank you for flagging. Our team will review this comment."
      />,
      { ...toastOptions, toastId: 'reforge-flagged-comment-toast' }
    )
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="relative">
        <button
          className="-mt-[4px] h-[24px] w-[24px] bg-transparent p-0"
          onClick={toggleOpen}
        >
          <EllipsisHorizontalIcon className="h-[24px] w-[24px]" />
        </button>
        {open && (
          <div className="absolute top-[25px] right-0 w-[175px] rounded-sm border border-rb-gray bg-rb-white text-base shadow-md">
            {!ownComment && (
              <CommentButtonItem onClick={() => handleFlagClicked(comment.id)}>
                Flag comment
              </CommentButtonItem>
            )}
            {ownComment && (
              <>
                {showEdit && (
                  <CommentButtonItem onClick={handleEditClicked}>
                    Edit comment
                  </CommentButtonItem>
                )}
                <CommentButtonItem onClick={handleDeleteClicked}>
                  Delete comment
                </CommentButtonItem>
              </>
            )}
          </div>
        )}
      </div>
    </ClickAwayListener>
  )
}

export default ArtifactCommentItem
