import React from 'react'

import { EventRecordingCard } from 'pages/EventRecordings/components/EventRecordingCard'

import ArtifactCard from 'components/cards/Content/ArtifactCard'
import CourseCard, {
  COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS
} from 'components/cards/Content/CourseCard'
import GuideCard from 'components/cards/Content/GuideCard'
import LegacyContentCard from 'components/cards/Content/LegacyContentCard'
import LegacyCourseCard, {
  COURSE_CARD_DESTINATION_TYPE_COURSE
} from 'components/cards/Content/LegacyCourseCard'
import LegacyProgramCard from 'components/cards/Content/LegacyProgramCard'

import {
  BookmarkFolderPartsFragment,
  BookmarkType,
  BookmarksFromFolderPartsFragment,
  CurrentUserPartsFragment,
  ProgramBookmarkPartsFragment,
  Unit,
  UserCohortsQuery
} from 'gql'

interface MySavedItemsListSortedProps {
  bookmarks: BookmarksFromFolderPartsFragment[]
  handleRemoveFromFolder: (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => Promise<string | null | undefined>
  handleBookmarkClickTracking: (
    bookmark: ProgramBookmarkPartsFragment,
    component?: string
  ) => void
  restoreBookmark: (bookmark: ProgramBookmarkPartsFragment) => void
  openAddToBookmarkFolderModal: (bookmark: ProgramBookmarkPartsFragment) => void
  currentUser: CurrentUserPartsFragment
  mySavedItems: BookmarkFolderPartsFragment
  userCohortsData?: UserCohortsQuery
  bookmarkFolders?: BookmarkFolderPartsFragment[]
  currentToggle: string
}

const sortBookmarks = (
  bookmarks: BookmarksFromFolderPartsFragment[],
  currentToggle: string
) => {
  if (currentToggle === 'Date Saved') {
    return [...bookmarks].sort((a, b) => {
      return (
        new Date(b.createdAtInteger).getTime() - new Date(a.createdAtInteger).getTime()
      )
    })
  } else if (currentToggle === 'Recently Viewed') {
    return [...bookmarks].sort((a, b) => {
      if (!a.lastViewedContentAtInteger) return 1
      if (!b.lastViewedContentAtInteger) return -1
      return (
        new Date(b.lastViewedContentAtInteger).getTime() -
        new Date(a.lastViewedContentAtInteger).getTime()
      )
    })
  } else {
    return bookmarks
  }
}

const MySavedItemsListSorted = ({
  bookmarks,
  handleRemoveFromFolder,
  handleBookmarkClickTracking,
  restoreBookmark,
  openAddToBookmarkFolderModal,
  currentUser,
  currentToggle,
  mySavedItems,
  bookmarkFolders
}: MySavedItemsListSortedProps) => {
  const isPaidMember =
    currentUser && (currentUser.is.paidMember || currentUser.is.planManager)
  const sortedBookmarks = sortBookmarks(bookmarks, currentToggle)

  return (
    <div className="mb-4 mt-6 grid list-none grid-cols-1 gap-x-6 gap-y-8 p-0 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
      {sortedBookmarks.map((bookmark) => {
        return (
          <React.Fragment key={bookmark.id}>
            {bookmark.type === BookmarkType.PROGRAMBOOKMARK && !!bookmark.cmsProgram && (
              <LegacyProgramCard
                program={bookmark.cmsProgram}
                currentUser={currentUser}
                bookmark={bookmark}
                restoreBookmark={restoreBookmark}
                bookmarkFolders={bookmarkFolders}
                currentFolder={mySavedItems}
                openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                handleRemoveFromFolder={handleRemoveFromFolder}
                key={bookmark.id}
              />
            )}
            {bookmark.type === BookmarkType.EVENTBOOKMARK && bookmark.event && (
              <EventRecordingCard
                key={bookmark.event.id}
                event={bookmark.event}
                bookmark={bookmark}
                imgVariants={'teal'}
                pageLocation="saved"
                bookmarkFolders={bookmarkFolders}
                restoreBookmark={restoreBookmark}
                currentFolder={mySavedItems}
                handleRemoveFromFolder={handleRemoveFromFolder}
                openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
              />
            )}
            {bookmark.type === BookmarkType.ARTIFACTBOOKMARK && bookmark.artifact && (
              <ArtifactCard
                pageLocation="saved"
                artifact={bookmark.artifact}
                bookmarkFolders={bookmarkFolders}
                restoreBookmark={restoreBookmark}
                currentFolder={mySavedItems}
                handleRemoveFromFolder={handleRemoveFromFolder}
                openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                bookmark={bookmark}
              />
            )}
            {bookmark.type === BookmarkType.COURSEBOOKMARK &&
              (bookmark.cclCourse ? (
                <CourseCard
                  pageLocation={`saved (${isPaidMember ? '/saved' : 'free'})`}
                  course={bookmark.cclCourse}
                  key={bookmark.id}
                  destinationType={COURSE_CARD_DESTINATION_TYPE_COURSE_DETAILS}
                  bookmark={bookmark}
                  restoreBookmark={restoreBookmark}
                  bookmarkFolders={bookmarkFolders}
                  currentFolder={mySavedItems}
                  openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                  handleRemoveFromFolder={handleRemoveFromFolder}
                />
              ) : (
                bookmark.course && (
                  <LegacyCourseCard
                    pageLocation={`saved (${isPaidMember ? '/saved' : 'free'})`}
                    cardType="on-demand"
                    course={bookmark.course}
                    key={bookmark.id}
                    user={currentUser}
                    showStartDate={false}
                    destinationType={COURSE_CARD_DESTINATION_TYPE_COURSE}
                    bookmark={bookmark}
                    restoreBookmark={restoreBookmark}
                    bookmarkFolders={bookmarkFolders}
                    currentFolder={mySavedItems}
                    openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                    handleRemoveFromFolder={handleRemoveFromFolder}
                  />
                )
              ))}
            {bookmark.type === BookmarkType.GUIDEBOOKMARK && bookmark.guide && (
              <GuideCard
                pageLocation="saved"
                guide={bookmark.guide as Unit}
                key={bookmark.id}
                bookmarkFolders={bookmarkFolders}
                restoreBookmark={restoreBookmark}
                currentFolder={mySavedItems}
                handleRemoveFromFolder={handleRemoveFromFolder}
                openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                bookmark={bookmark}
              />
            )}
            {bookmark.type !== BookmarkType.PROGRAMBOOKMARK &&
              bookmark.type !== BookmarkType.ARTIFACTBOOKMARK &&
              bookmark.type !== BookmarkType.GUIDEBOOKMARK &&
              !!bookmark.content && (
                <LegacyContentCard
                  content={bookmark.content}
                  onClick={() => handleBookmarkClickTracking(bookmark, 'content_card')}
                  bookmarkFolders={bookmarkFolders}
                  restoreBookmark={restoreBookmark}
                  currentFolder={mySavedItems}
                  handleRemoveFromFolder={handleRemoveFromFolder}
                  openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                  bookmark={bookmark}
                  key={bookmark.id}
                />
              )}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default MySavedItemsListSorted
