import { KeyboardEvent, MouseEvent } from 'react'

import Image from 'domains/Sanity/Image'

import {
  BookmarkFolderPartsFragment,
  ConceptCurrentUserPartsFragment,
  CurrentUserPartsFragment,
  ProgramBookmarkPartsFragment,
  ProgramCardPartsFragment
} from 'gql'

import { listify } from 'utils/stringUtils'

import BaseCard, { CardVariant, CardVariants } from './BaseCard'

export interface LegacyProgramCardProps {
  program: ProgramCardPartsFragment
  variant?: CardVariant
  currentUser?: CurrentUserPartsFragment | ConceptCurrentUserPartsFragment | null
  onClick?: (e: MouseEvent | KeyboardEvent) => void
  additionalTrackingParams?: {}
  // Bookmark-related props
  bookmark?: ProgramBookmarkPartsFragment
  currentFolder?: BookmarkFolderPartsFragment | null
  bookmarkFolders?: BookmarkFolderPartsFragment[] | undefined
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  restoreBookmark?: (bookmark: ProgramBookmarkPartsFragment) => void
  handleRemoveFromFolder?: (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => Promise<string | null | undefined>
  hideBookmarkButton?: boolean
}

const LegacyProgramCard = ({
  program,
  variant = CardVariants.Vertical,
  onClick,
  bookmark,
  currentFolder,
  bookmarkFolders,
  openAddToBookmarkFolderModal,
  restoreBookmark,
  handleRemoveFromFolder,
  hideBookmarkButton
}: LegacyProgramCardProps) => {
  const destinationUrl = `/programs/${program.slug}`

  return (
    <BaseCard
      contentType="Program"
      variant={variant}
      title={program.name}
      cmsProgramId={program.id}
      byline={<ProgramByline program={program} />}
      body={program.description}
      horizontalThumbnail={<ProgramHorizontalThumbnail program={program} />}
      verticalThumbnail={<ProgramVerticalThumbnail program={program} />}
      destination={destinationUrl}
      onClick={onClick}
      bookmarkId={program.contentBookmarkId}
      bookmark={bookmark}
      currentFolder={currentFolder}
      bookmarkFolders={bookmarkFolders}
      openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
      restoreBookmark={restoreBookmark}
      handleRemoveFromFolder={handleRemoveFromFolder}
      hideBookmarkButton={hideBookmarkButton}
    />
  )
}

const ProgramByline = ({ program }: { program: ProgramCardPartsFragment }) => {
  const authors = program.collaborators
  if (!authors || authors.length === 0) return null

  const authorNames = authors
    .filter((author) => author && author.name)
    .map((author) => author.name) as string[]

  return <span>Created by {listify(authorNames)}</span>
}

const ProgramHorizontalThumbnail = ({
  program
}: {
  program: ProgramCardPartsFragment
}) => {
  const creators =
    program.collaborators?.map((creator) => {
      return {
        url: creator.avatarUrl || '',
        name: creator.name || ''
      }
    }) || []
  if (creators.length === 0) return null

  if (creators.length === 1) {
    return (
      <Image
        className="h-full w-full rounded-xl object-cover"
        src={creators[0].url}
        alt={creators[0].name}
      />
    )
  }
  return (
    <div className="relative flex h-full gap-x-2">
      {creators.length > 2 && (
        <div className="absolute right-0 bottom-0 rounded-tl-xl rounded-br-md bg-rb-orange-25 p-2 text-sm text-rb-gray-400">
          +{creators.length - 2}
        </div>
      )}
      {creators.slice(0, 2).map((creator, idx) => (
        <div
          key={`card-thumbnail-${idx}`}
          className="h-full w-[calc(50%-4px)] flex-none rounded-xl"
        >
          <Image
            className="h-full w-full rounded-xl object-cover"
            src={creator.url}
            alt={creator.name}
          />
        </div>
      ))}
    </div>
  )
}

const ProgramVerticalThumbnail = ({ program }: { program: ProgramCardPartsFragment }) => {
  const creators =
    program.collaborators?.map((creator) => {
      return {
        url: creator.avatarUrl || '',
        name: creator.name || ''
      }
    }) || []
  if (creators.length === 0) return null

  if (creators.length <= 2) {
    return (
      <div className="flex h-full gap-x-2 px-4 pt-4">
        {creators.map((creator, idx) => (
          <div
            key={`card-thumbnail-${idx}`}
            className="h-full w-[calc(50%-4px)] flex-none rounded-xl"
          >
            <Image
              className="h-full w-full rounded-xl object-cover"
              src={creator.url}
              alt={creator.name}
            />
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="h-full px-4 pt-4">
      <div className="relative flex h-full gap-x-2 overflow-hidden">
        {creators.length > 3 && (
          <div className="absolute right-0 bottom-0 rounded-tl-xl rounded-br-md bg-rb-orange-25 p-2 text-sm text-rb-gray-400">
            +{creators.length - 3}
          </div>
        )}
        {creators.slice(0, 3).map((creator, idx) => (
          <div
            key={`card-thumbnail-${idx}`}
            className="h-full w-[calc(33.3%-5.3px)] flex-none rounded-xl"
          >
            <Image
              className="h-full w-full rounded-xl object-cover"
              src={creator.url}
              alt={creator.name}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default LegacyProgramCard
