import PersonalizeContent from 'domains/Ai/Chat/PersonalizeContent'

import { Loading } from 'components'
import { useGlobalModal } from 'components/GlobalModal'
import { ModalContent } from 'components/Modal'

import { AiPersonalization, ArtifactTopicFilter } from 'gql'

interface PersonalizeModalProps {
  onSave?: () => void
  onCancel?: () => void
  dedupedTopicsAndFunctions: ArtifactTopicFilter[]
  loading: boolean
  refetch?: () => void
  personalizationData?: AiPersonalization | null
}

const PersonalizeModal = ({
  onSave,
  onCancel,
  dedupedTopicsAndFunctions,
  loading,
  personalizationData
}: PersonalizeModalProps) => {
  const { closeGlobalModal } = useGlobalModal()
  const handleCancel = async () => {
    closeGlobalModal()
    onCancel?.()
  }

  const handleSave = () => {
    onSave?.()
    closeGlobalModal()
  }

  return (
    <ModalContent className="px-4 md:px-12" scrollContent={false}>
      {loading ? (
        <Loading />
      ) : (
        <PersonalizeContent
          onSave={handleSave}
          dedupedTopicsAndFunctions={dedupedTopicsAndFunctions}
          onCancel={handleCancel}
          personalizationData={personalizationData}
          isModal={true}
        />
      )}
    </ModalContent>
  )
}

export default PersonalizeModal
