/* eslint-disable react/prop-types */
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { debounce } from 'throttle-debounce'
import UIkit from 'uikit'

import { CurrentUserPartsFragment, LessonFooterPartsFragment } from 'gql'

import { track } from 'utils/tracking/segment'

interface FooterNavProps {
  currentUser: CurrentUserPartsFragment
  lessonDetails: LessonFooterPartsFragment
  fromCohortViewer?: boolean
  cohortSlug?: string
}

const FooterNav = ({
  currentUser,
  lessonDetails,
  fromCohortViewer = false,
  cohortSlug
}: FooterNavProps) => {
  const { cmsSection, cmsProgram } = lessonDetails || {}

  useEffect(() => {
    const handleInview = debounce(100, function () {
      const header: HTMLElement | null = document.querySelector('.cms-header')
      if (header) {
        header.style.top = `-${header.clientHeight}px`
      }
      const topFeedbacks = document.querySelectorAll('.js-lesson-feedback--top')
      if (topFeedbacks.length && header) {
        topFeedbacks.forEach((feedbackElement: HTMLElement) => {
          feedbackElement.style.marginTop = `${header.clientHeight - 10}px`
        })
      }
    })

    const handleOutview = debounce(100, function () {
      const header: HTMLElement | null = document.querySelector('.cms-header')

      if (header) {
        fromCohortViewer ? header.removeAttribute('style') : (header.style.top = '0px')
      }
      const topFeedbacks = document.querySelectorAll('.js-lesson-feedback--top')
      if (topFeedbacks.length) {
        topFeedbacks.forEach((feedbackElement: HTMLElement) => {
          feedbackElement.style.marginTop = '-10px'
        })
      }
    })

    // @ts-ignore
    UIkit.util.on('.cms-footer__nav', 'inview', handleInview)
    // @ts-ignore
    UIkit.util.on('.cms-footer__nav', 'outview', handleOutview)

    return () => {
      // @ts-ignore
      UIkit.util.off('.cms-footer__nav', 'inview', handleInview)
      // @ts-ignore
      UIkit.util.off('.cms-footer__nav', 'outview', handleOutview)
    }
  }, [currentUser, fromCohortViewer])

  const trackClickOnNextLessonInFooter = () => {
    // @ts-ignore - 'Content Viewer - Action' event is not defined in Segment JIRA#REF-5159
    track('Content Viewer - Action', {
      action: 'Footer__click_to_next_lesson',
      location: window.location.pathname
    })
  }

  const getNextSectionButtonComponent = () => {
    if (currentUser.is.premember) {
      return null
    }

    if (currentUser?.hasApplied || currentUser?.is.accountCreated) {
      return (
        <a
          className="next-section bottom block rounded-[3px]  bg-rb-gray-500 p-[35px] text-white no-underline transition-background duration-300 sm:mb-[70px] sm:hover:bg-rb-teal-200 sm:hover:text-inherit"
          href={`/programs/${cmsProgram?.slug}`}
        >
          Return to Dashboard
        </a>
      )
    }

    if (
      cmsProgram?.upcoming &&
      cmsSection?.nextSectionNameAndPath[1] === `/programs/${cmsProgram?.slug}`
    ) {
      return (
        <a
          className="next-section bottom block rounded-[3px]  bg-rb-gray-500 p-[35px] text-white no-underline transition-background duration-300 sm:mb-[70px] sm:hover:bg-rb-teal-200 sm:hover:text-inherit"
          href="/"
        >
          Return Home
        </a>
      )
    }

    if (
      cmsSection?.nextSectionNameAndPath[1] === `/programs/${cmsProgram?.slug}` &&
      cmsSection?.nextSectionLearningCmsSectionPath
    ) {
      if (fromCohortViewer) {
        return (
          <Link
            className="next-section bottom block rounded-[3px]  bg-rb-gray-500 p-[35px] text-white no-underline transition-background duration-300 sm:mb-[70px] sm:hover:bg-rb-teal-200 sm:hover:text-inherit"
            to={`/cohorts/${cohortSlug}`}
          >
            Return to Program
          </Link>
        )
      }

      if (cmsSection.isResource && !cmsSection.nextSection) {
        return (
          <Link
            className="next-section bottom block rounded-[3px]  bg-rb-gray-500 p-[35px] text-white no-underline transition-background duration-300 sm:mb-[70px] sm:hover:bg-rb-teal-200 sm:hover:text-inherit"
            to={'/'}
          >
            Return Home
          </Link>
        )
      }

      return (
        <a
          className="next-section bottom block rounded-[3px]  bg-rb-gray-500 p-[35px] text-white no-underline transition-background duration-300 sm:mb-[70px] sm:hover:bg-rb-teal-200 sm:hover:text-inherit"
          href={cmsSection?.nextSectionLearningCmsSectionPath}
        >
          Return to Dashboard
        </a>
      )
    }

    if (cmsSection?.nextSectionLearningCmsSectionPath) {
      if (fromCohortViewer) {
        if (cmsSection?.nextSection?.href) {
          return (
            <Link
              className="next-section bottom block rounded-[3px]  bg-rb-gray-500 p-[35px] text-white no-underline transition-background duration-300 sm:mb-[70px] sm:hover:bg-rb-teal-200 sm:hover:text-inherit"
              to={`/cohorts/${cohortSlug}${cmsSection?.nextSection?.href}`}
              onClick={trackClickOnNextLessonInFooter}
            >
              Next up: {cmsSection?.nextSectionNameAndPath[0]}
            </Link>
          )
        }

        return (
          <Link
            className="next-section bottom block rounded-[3px]  bg-rb-gray-500 p-[35px] text-white no-underline transition-background duration-300 sm:mb-[70px] sm:hover:bg-rb-teal-200 sm:hover:text-inherit"
            to={`/cohorts/${cohortSlug}`}
            onClick={trackClickOnNextLessonInFooter}
          >
            Return to Program
          </Link>
        )
      }

      return (
        <a
          className="next-section bottom block rounded-[3px]  bg-rb-gray-500 p-[35px] text-white no-underline transition-background duration-300 sm:mb-[70px] sm:hover:bg-rb-teal-200 sm:hover:text-inherit"
          href={cmsSection?.nextSectionLearningCmsSectionPath}
          onClick={trackClickOnNextLessonInFooter}
        >
          Next up: {cmsSection?.nextSectionNameAndPath[0]}
        </a>
      )
    }
  }

  return (
    <footer
      className="cms-footer__nav bg-rb-gray-500 text-center font-sans text-lg font-bold text-white transition-background duration-300 sm:!bg-white sm:!text-3xl sm:!font-normal sm:leading-[35px]"
      uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true"
    >
      <div className="uk-grid uk-grid-collapse">
        <div className="uk-width-expand box-border max-w-[970px] transition-width-margin duration-300 tl:my-0 tl:mx-auto">
          <div className="max-w-[660px]">{getNextSectionButtonComponent()}</div>
        </div>
      </div>
    </footer>
  )
}

export default FooterNav
