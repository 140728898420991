import BlockContent from 'domains/Artifact/BlockContent'

interface BeigeBlockProps {
  value: {
    contentBlock: unknown[]
    searchIcon: boolean
  }
}

const BeigeBlock = ({ value }: BeigeBlockProps) => {
  return (
    <div className="block-content z-0 mb-32 bg-rb-background-beige p-24">
      {value.searchIcon && (
        <div className="mb-24 flex items-center">
          <div className="mr-16">
            <img
              src="../../../images/marketing-round-icons/MagnifyingGlass.png"
              alt="Search Icon"
              style={{ width: '50px', height: '50px' }}
            />
          </div>
        </div>
      )}

      <BlockContent blocks={value.contentBlock} />
    </div>
  )
}

export default BeigeBlock
