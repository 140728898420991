import React from 'react'

import CourseInProgressThumbnail from '../elements/CourseInProgressThumbnail'
import CourseCard, { CourseCardProps } from './CourseCard'

export const withCustomThumbnail = (Component: React.ComponentType<CourseCardProps>) => {
  const WithCustomThumbnail = (props: CourseCardProps) => {
    return (
      <Component
        {...props}
        customThumbnail={
          <CourseInProgressThumbnail
            experts={props.course.creators}
            cardVariant={props.variant}
          />
        }
      />
    )
  }

  WithCustomThumbnail.displayName = `WithCustomThumbnail(${Component.displayName || Component.name || 'Component'})`

  return WithCustomThumbnail
}

const CourseCardWithCustomThumbnail = withCustomThumbnail(CourseCard)

export default CourseCardWithCustomThumbnail
