import { User } from 'gql'

import {
  DAY_OF_MONTH_FORMAT,
  MONTH_ABBREV_FORMAT,
  formatInTimezone,
  getCurrentTimezone
} from 'utils/date'

interface IEventCardDateCircle {
  startsAtUtc: string
  currentUser: User | any
}

const EventCardDateCircle = ({ startsAtUtc, currentUser }: IEventCardDateCircle) => {
  const userTimezone = getCurrentTimezone(currentUser.timezone)

  return (
    <div
      style={{ width: '40px', height: '40px' }}
      className="flex items-center justify-center rounded-full border border-rb-blue-100 bg-rb-blue-100 text-xs text-white"
    >
      <div>
        <div className="leading-none">
          {formatInTimezone(startsAtUtc, userTimezone, MONTH_ABBREV_FORMAT).toUpperCase()}
        </div>
        <div className="text-center font-medium leading-none">
          {formatInTimezone(startsAtUtc, userTimezone, DAY_OF_MONTH_FORMAT).toUpperCase()}
        </div>
      </div>
    </div>
  )
}

export default EventCardDateCircle
