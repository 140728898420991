import { Navigate, useSearchParams } from 'react-router-dom-v5-compat'

import { ErrorMessage, Loading } from 'components'
import Status, { StatusTypes } from 'components/Status'

import { useSubscriptionJoinRequestQuery } from 'gql'

export const ApproveJoinTeamRequestStatus = () => {
  const [searchParams] = useSearchParams()
  const joinRequestId = searchParams.get('join_request_id')
  const { data, loading, error } = useSubscriptionJoinRequestQuery({
    variables: { subscriptionJoinRequestId: joinRequestId }
  })

  if (loading) return <Loading />

  if (!data?.subscriptionJoinRequest) {
    return (
      <ErrorMessage error="Something went wrong while trying to approve join request" />
    )
  }

  if (error) {
    return <ErrorMessage error={error} />
  }

  const joinRequestStatus = data?.subscriptionJoinRequest?.status
  switch (joinRequestStatus) {
    case 'pending':
      return <Navigate to={`/account?join_request_id=${joinRequestId}`} />
    case 'accepted':
    case 'accepted_unassigned':
      return <Status statusType={StatusTypes.SUBSCRIPTION_ALREADY_ADDED} />
    case 'rejected':
      return <Status statusType={StatusTypes.TEAM_REQUEST_ALREADY_DECLINED} />
    case 'canceled':
      return <Status statusType={StatusTypes.TEAM_REQUEST_ALREADY_APPROVED} />
    default:
      return (
        <ErrorMessage error="Join request in unrecognized state. Engineering team has been notified" />
      )
  }
}
