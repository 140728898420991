import { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom-v5-compat'

import AllHighlightsList from 'domains/Collections/AllHighlightsList'
import CollectionFolderList from 'domains/Collections/CollectionFolderList'
import MySavedItemsList from 'domains/Collections/MySavedItemsList'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'
import useCollectionsTabs from 'domains/Collections/useCollectionsTabs'

import { Loading } from 'components'

import { CurrentUserPartsFragment, useBookmarkFoldersQuery } from 'gql'

import { trackNavigationClicked } from 'utils/tracking/analytics'

import CreateBookmarkFolderModal from './CreateBookmarkFolderModal'
import MySavedItemsFilterToggle from './MySavedItemsFilterToggle'

const TOGGLE_OPTIONS = ['Date Saved', 'Recently Viewed', 'Type']

const CollectionsIndex = ({ currentUser }: { currentUser: CurrentUserPartsFragment }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentTab, setCurrentTab] = useState(searchParams.get('tab') || 'saved_items')
  const [currentToggle, setCurrentToggle] = useState(TOGGLE_OPTIONS[0])
  const { data: bookmarkFolderData, loading: loadingFolders } = useBookmarkFoldersQuery()

  const {
    isCreateBookmarkFolderModalOpen,
    openCreateBookmarkFolderModal,
    closeCreateBookmarkFolderModal,
    currentBookmarkForDropdown
  } = useOpenCreateBookmarkFolderModal()

  const handleTabChange = useCallback(
    (tab: string) => {
      trackNavigationClicked({
        location: 'saved_items_library',
        text: tab,
        type: 'tab'
      })
      setSearchParams({ tab: tab })
      setCurrentTab(tab)
    },
    [setSearchParams]
  )

  const mySavedItemsFolder = bookmarkFolderData?.currentUser?.bookmarkFolders.find(
    (bf) => bf.name === 'My Saved Items'
  )

  const showTabs =
    currentUser?.is?.member || currentUser?.is?.paidMember || currentUser?.is?.planManager

  useCollectionsTabs({
    showTabs,
    handleTabChange,
    currentTab
  })

  return (
    <div className="mx-auto w-full gap-x-8 pb-6 lg:flex">
      <div className="w-full">
        {currentTab === 'saved_items' && (
          <div className="flex w-full justify-end">
            <MySavedItemsFilterToggle
              currentFilter={currentToggle}
              updateFilter={setCurrentToggle}
              options={TOGGLE_OPTIONS}
            />
          </div>
        )}
        <div className="">
          {currentTab === 'saved_items' && (
            <>
              {loadingFolders || !mySavedItemsFolder ? (
                <Loading />
              ) : (
                <MySavedItemsList
                  mySavedItems={mySavedItemsFolder}
                  currentToggle={currentToggle}
                  currentUser={currentUser}
                  bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders}
                  openCreateBookmarkFolderModal={openCreateBookmarkFolderModal}
                />
              )}
            </>
          )}
          {currentTab === 'collections' && (
            <>
              {loadingFolders ? (
                <Loading />
              ) : (
                <CollectionFolderList
                  bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders.filter(
                    (bf) => bf.name !== 'My Saved Items'
                  )}
                  openCreateBookmarkFolderModal={openCreateBookmarkFolderModal}
                  currentUserId={currentUser.id}
                />
              )}
            </>
          )}
          {currentTab === 'highlights' && (
            <AllHighlightsList
              bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders}
              openCreateBookmarkFolderModal={openCreateBookmarkFolderModal}
            />
          )}
        </div>
      </div>
      <CreateBookmarkFolderModal
        isModalOpen={isCreateBookmarkFolderModalOpen}
        handleClose={closeCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdown}
        trackingTriggerAction={'saved'}
      />
    </div>
  )
}

export default CollectionsIndex
