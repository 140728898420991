export function stripMarkdown(markdownString: string): string {
  return (
    markdownString
      // Remove headers
      .replace(/^#+\s/gm, '')
      // Remove emphasis (bold and italic)
      .replace(/(\*{1,2}|_{1,2})(.*?)\1/g, '$2')
      // Remove links
      .replace(/!?\[.*?\]\(.*?\)/g, '')
      // Remove images
      .replace(/!\[.*?\]\(.*?\)/g, '')
      // Remove code blocks
      .replace(/```[^`]*```/g, '')
      // Remove inline code
      .replace(/`[^`]*`/g, '')
      // Remove blockquotes
      .replace(/^\s*>\s*(.*)$/gm, '$1')
      // Remove horizontal rules
      .replace(/^(-{3,}|\*{3,}|_{3,})$/gm, '')
      // Remove unordered list bullets
      .replace(/^\s*[-*+]\s+/gm, '')
      // Remove ordered list numbers
      .replace(/^\s*\d+\.\s+/gm, '')
      // Remove leading and trailing whitespace
      .trim()
  )
}
