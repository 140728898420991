const Content404 = () => {
  return (
    <div className="flex flex-col items-center justify-center text-center">
      <h1>404</h1>
      <p>Sorry, we couldn&apos;t find the page you were looking for.</p>
    </div>
  )
}

export default Content404
