import { ReactionKind } from 'gql'

import { TLegacyOrGraphQLReaction } from 'utils/reactionUtils'

interface useReactionPickerProps {
  reactions: TLegacyOrGraphQLReaction[]
  currentUserId: string
  addReaction: (kind: ReactionKind) => void
  removeReaction: (id: string | number) => void
}

export const useReactionPicker = ({
  reactions,
  currentUserId,
  addReaction,
  removeReaction
}: useReactionPickerProps) => {
  const userReactions = reactions.filter((reaction) => reaction.user.id === currentUserId)

  const handleReactionClick = (kind: ReactionKind) => {
    const userReaction = userReactions.find((reaction) => reaction.kind === kind)
    if (userReaction) {
      removeReaction(userReaction.id)
    } else {
      addReaction(kind)
    }
  }

  return { handleReactionClick }
}
