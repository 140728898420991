import Button from 'components/Button'
import Modal from 'components/Modal'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import { OrgSubscriptionFragment, UserOrgFragment } from 'gql'
import { useRemoveSubscriptionFromOrgMutation } from 'gql/index'

import useMediaQuery from 'hooks/useMediaQuery'

import { displayErrorToast, displaySuccessToast } from 'utils/toastService'

interface RemoveSubscriptionModalProps {
  isModalOpen: boolean
  handleClose: () => void
  subscription: OrgSubscriptionFragment
  org: UserOrgFragment
}

const RemoveSubscriptionModal = ({
  isModalOpen,
  handleClose,
  subscription,
  org
}: RemoveSubscriptionModalProps) => {
  const removalText = subscription.isTeam
    ? `the team ${subscription.teamName}`
    : subscription.user.fullName || subscription.user.email
  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
  const [removeSubscription] = useRemoveSubscriptionFromOrgMutation()

  const handleRemoveSub = async () => {
    const result = await removeSubscription({
      variables: {
        input: { subscriptionId: subscription.id, orgId: org.id }
      }
    }).catch((err) => {
      displayErrorToast({
        message: `Unable to remove ${removalText} from your organization: ${err}`
      })
    })

    if (result?.errors) {
      displayErrorToast({
        message: `Unable to remove ${removalText} from your organization.`
      })
    } else if (result) {
      displaySuccessToast({
        message: `You’ve successfully removed ${removalText} from your organization.`
      })
    }
    handleClose()
  }

  return (
    <Modal isOpen={isModalOpen} handleClose={handleClose}>
      <div className="flex flex-col justify-center p-10 text-center">
        <div className="text-xl">
          {`Are you sure you'd like to remove ${removalText} from your organization?`}
        </div>
        <div className="my-10 flex justify-center">
          <Button
            onClick={handleClose}
            size={isMobile ? 'x-small' : 'medium'}
            variant="text-only"
            className={`mx-4 ${isMobile ? 'mr-5 border-2 border-rb-gray-500' : ''}`}
          >
            Cancel
          </Button>
          <Button onClick={handleRemoveSub} dataTest="remove-sub-from-org">
            Remove Subscription
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default RemoveSubscriptionModal
