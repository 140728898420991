import { getModuleProgress } from 'domains/Concepts/ConceptCardList/utils'

import LessonCard from 'components/CohortDashboard/LessonCard/LessonCard'
import SectionCardContainer from 'components/CollapsibleSectionCard/SectionCardContainer'

import {
  CohortDashboardLessonPartsFragment,
  CohortDashboardSectionCardCmsModulePartsFragment,
  CohortDashboardUserProgramPartsFragment
} from 'gql'

import { isAfterDate } from 'utils/date'

import { ModuleProgress } from 'typings/scalars'

interface RecapLessonCardProps {
  lesson: CohortDashboardLessonPartsFragment
  module: CohortDashboardSectionCardCmsModulePartsFragment
  slug: string
  activeProgram: CohortDashboardUserProgramPartsFragment
  numModules: number
  scheduledWeekIndex: number
}

export const RecapLessonCard = ({
  lesson,
  module,
  slug,
  activeProgram,
  numModules,
  scheduledWeekIndex
}: RecapLessonCardProps) => {
  const moduleProgress = getModuleProgress(activeProgram, module.id)
  const userProgress = ((module.project
    ? moduleProgress[`${module.project.id}d`]
    : moduleProgress) || {}) as ModuleProgress

  const weekName =
    scheduledWeekIndex === 0 ? 'Kickoff Week' : `Week ${scheduledWeekIndex}`
  const nameOverride = `${numModules > 1 ? module.name : weekName} Recap`
  const lockLesson = !module.published || !isAfterDate(module.publishAt)

  return (
    <SectionCardContainer darkenBorderOnHover={false}>
      <LessonCard
        className="rounded-xl px-6 pt-6 pb-8"
        thumbnailClassname="w-16 h-16"
        lesson={lesson}
        userProgress={userProgress}
        slug={slug}
        nameOverride={nameOverride}
        largeTitle
        lockLesson={lockLesson}
      />
    </SectionCardContainer>
  )
}

export default RecapLessonCard
