export const ArrowRightIcon = ({
  className,
  fill
}: {
  fill?: string
  className?: string
}) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_513_16304)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 8.5C0 8.22386 0.223858 8 0.5 8H15.5C15.7761 8 16 8.22386 16 8.5C16 8.77614 15.7761 9 15.5 9H0.5C0.223858 9 0 8.77614 0 8.5Z"
          fill={fill || '#2D2F2F'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.14645 1.14645C8.34171 0.951184 8.65829 0.951184 8.85355 1.14645L15.8536 8.14645C16.0488 8.34171 16.0488 8.65829 15.8536 8.85355L8.85355 15.8536C8.65829 16.0488 8.34171 16.0488 8.14645 15.8536C7.95118 15.6583 7.95118 15.3417 8.14645 15.1464L14.7929 8.5L8.14645 1.85355C7.95118 1.65829 7.95118 1.34171 8.14645 1.14645Z"
          fill={fill || '#2D2F2F'}
        />
      </g>
      <defs>
        <clipPath id="clip0_513_16304">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
