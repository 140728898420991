import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Route, Switch, useParams } from 'react-router-dom'

import BlockEventPage from 'domains/Event/BlockEventPage'
import EventShow from 'domains/Event/EventShow'
import EventPageTabs from 'domains/Event/EventShowTabs'
import EventShowTitle from 'domains/Event/EventShowTitle'
import EventSummary from 'domains/Event/EventSummary'
import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'

import AttendeesModal from 'components/AttendeesModal'
import { useModal } from 'components/Modal'
import PageHeader from 'components/PageHeader'

import { MIN_WIDTH_TAILWIND_MD } from 'constants/breakpoints'

import {
  CurrentUserPartsFragment,
  EventQuery,
  PageSeo,
  PageTypeEnum,
  UserEventActivity,
  useCreateUserEventActivityMutation,
  useEventQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useFirstSigninSource from 'hooks/useFirstSigninSource'
import useMediaQuery from 'hooks/useMediaQuery'

import { getCurrentTimezone } from 'utils/date'
import { eventAccess } from 'utils/eventAccess'
import { cn } from 'utils/tailwind'

import EventSidebar from './EventSidebar'

const EventPageContainer = ({
  eventId,
  cohortSlug,
  isCohortDashboard = false,
  setPage
}: {
  eventId?: string
  isCohortDashboard?: boolean
  cohortSlug?: string
  setPage?: (page: GenericPageType) => void
}) => {
  const { id: _id, uuid } = useParams<{ id?: string; uuid?: string }>()
  const { currentUser, isLoggedIn } = useCurrentUser()
  const id = _id || eventId

  const {
    data: eventData,
    loading: eventLoading,
    error: eventError,
    refetch: refetchEvent
  } = useEventQuery({
    variables: { eventId: id, eventUuid: uuid }
  })

  useEffect(() => {
    if (!isLoggedIn && eventData?.event && setPage) {
      const page: GenericPageType = {
        title: eventData.event.name,
        seo: {
          metaTitle: eventData.event.name,
          metaDesc: eventData.event.description,
          shareTitle: eventData.event.name,
          shareDesc: eventData.event.description,
          shareGraphic: eventData.event.featuredImageUrl,
          shareGraphicAlt: eventData.event.name,
          type: 'event' as PageTypeEnum
        } as PageSeo
      }

      setPage(page)
    }
  }, [eventData, setPage, isLoggedIn])

  return (
    <BlockEventPage
      event={eventData?.event}
      eventLoading={eventLoading}
      eventError={eventError}
      allowRedirectToDashboard={true}
    >
      {eventData && (
        <EventPage
          currentUser={currentUser || undefined}
          event={eventData.event}
          refetchEvent={refetchEvent}
          cohortSlug={cohortSlug}
          isCohortDashboard={isCohortDashboard}
        />
      )}
    </BlockEventPage>
  )
}

export interface EventProps {
  currentUser?: CurrentUserPartsFragment
  event: EventQuery['event']
  refetchEvent: () => void
  cohortSlug?: string
  isCohortDashboard?: boolean
}

const EventPage = ({
  currentUser,
  event,
  refetchEvent,
  cohortSlug,
  isCohortDashboard = false
}: EventProps) => {
  const isAtLeastMd = useMediaQuery(`(min-width: ${MIN_WIDTH_TAILWIND_MD})`)
  const timezone = getCurrentTimezone(currentUser?.timezone)
  const [createUserEventActivity] = useCreateUserEventActivityMutation()
  const canAccessEvent = eventAccess(event, currentUser)

  const { setCustomSigninSource } = useFirstSigninSource()
  setCustomSigninSource(`event (${event.kind?.toLowerCase()})`)

  // TODO: use enum
  const isAttending = event.rsvp?.status === 'Attending'

  const { isModalOpen: isAttendeeModalOpen, setIsModalOpen: setIsAttendeeModalOpen } =
    useModal()

  useEffect(() => {
    if (currentUser?.id) {
      createUserEventActivity({
        variables: {
          input: {
            eventId: event.id,
            activityType: UserEventActivity.VISITED
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event])

  const { hosts, featuredGuests } = event

  return (
    <div className="px-4 md:px-[4vw]">
      {/* defer=false ensures title populates in an unfocused tab,
          say if a user ctrl/cmd-clicks multiple events */}
      <Helmet defer={false}>
        <title>{event.name} — Reforge</title>
      </Helmet>
      <AttendeesModal
        event={event}
        isOpen={isAttendeeModalOpen}
        onClose={() => setIsAttendeeModalOpen(false)}
      />

      <div className={cn('flex pb-40 md:gap-8 md:pb-0')}>
        <div className={cn('w-full')}>
          <PageHeader
            title={<EventShowTitle event={event} timeZone={timezone} />}
            dataTest="page-header-events"
            headerTopRight={
              !isAtLeastMd && (
                <div className="w-full md:hidden">
                  <EventSidebar
                    data={{
                      event,
                      refetchEvent,
                      setIsAttendeeModalOpen
                    }}
                  />
                </div>
              )
            }
          />

          <EventPageTabs
            event={event}
            isAttending={isAttending}
            setIsAttendeeModalOpen={setIsAttendeeModalOpen}
            cohortSlug={cohortSlug}
            isCohortDashboard={isCohortDashboard}
          />

          <Switch>
            <Route exact path={['/events/:id', '/cohorts/:slug/events/:id']}>
              <EventShow
                event={event}
                hosts={hosts}
                featuredGuests={featuredGuests}
                userTimezone={timezone}
              />
            </Route>
            <Route path={['/events/:id/summary', '/cohorts/:slug/events/:id/summary']}>
              <EventSummary
                event={event}
                userEmail={currentUser?.contact?.primaryEmail}
                canAccessEvent={canAccessEvent}
              />
            </Route>
          </Switch>
        </div>

        {isAtLeastMd && (
          <div className="min-h-full hidden md:block">
            <EventSidebar
              data={{
                event,
                refetchEvent,
                setIsAttendeeModalOpen
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default EventPageContainer
