interface CompanyWithLogo {
  company: string | null
  imageUrl: string | null
}

export interface CompanyLogoAvatarProps extends CompanyWithLogo {}

const GrayAvatar = () => {
  return <div className="h-8 w-8 rounded border-2 border-rb-white bg-rb-gray-100"></div>
}

const CompanyLogo = ({ imageUrl }: { imageUrl: string }) => {
  return (
    <img
      src={imageUrl}
      alt="Company Logo"
      className="h-8 w-8 rounded border-2 border-rb-white"
    />
  )
}

export const CompanyLogoAvatar = ({ company, imageUrl }: CompanyLogoAvatarProps) => {
  if (!company || !imageUrl) {
    return <GrayAvatar />
  } else {
    return <CompanyLogo imageUrl={imageUrl} />
  }
}

export default CompanyLogoAvatar
