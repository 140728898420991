import MuxPlayer from '@mux/mux-player-react'

import AudioPlayer from '../../shared/AudioPlayer'
import VideoEmbed from '../../shared/VideoEmbed'
import { renderPhoto } from '../../shared/componentTypes/Types'
import BeigeBlock from '../components/BeigeBlock'
import InlineImage from '../components/InlineImage'
import PodcastEmbed from '../components/PodcastEmbed'
import Stats from '../components/Stats'

interface BlockProps {
  value?: any // @todo: define type
  children?: any // @todo: define type
}

export default {
  horizontalRule: () => <hr />,
  inlineImage: ({ value }: BlockProps) => {
    return (
      <InlineImage
        caption={value.caption}
        hotspots={value.hotspots}
        image={value.image}
      />
    )
  },
  photo: renderPhoto,
  muxVideo: ({ value }: BlockProps) => (
    <div className="my-6" id={value?._key}>
      <MuxPlayer
        className="max-h-2xl w-full"
        playbackId={value?.video?.asset?.playbackId || value?.video?.playbackId}
        streamType="on-demand"
        thumbnailTime={value?.video?.thumbTime}
      />
    </div>
  ),
  stats: ({ value }: BlockProps) => <Stats statsObject={value} />,
  stepNumber: ({ value }: BlockProps) => {
    return (
      <span className="step-number mt-16 mb-10 flex h-14 w-14 items-center justify-center rounded-full bg-rb-black text-2xl font-medium text-white">
        {value.value}
      </span>
    )
  },
  videoEmbed: ({ value }: BlockProps) => (
    <VideoEmbed src={value.src} title={value.title} />
  ),
  beigeBlock: ({ value }: BlockProps) => {
    return <BeigeBlock value={value} />
  },
  audio: ({ value }: BlockProps) => (
    <AudioPlayer src={value.audio.url} title={value.title} />
  ),
  podcastEmbed: ({ value }: BlockProps) => (
    <PodcastEmbed src={value.embedUrl} title={value.title} />
  )
}
