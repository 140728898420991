import { useHistory } from 'react-router-dom'

import Button from 'components/Button'
import { ContentCardContainer, ContentCardHeader } from 'components/ContentCard'
import useEventCard from 'components/EventCard/useEventCard'
import { FacePileButton } from 'components/FacePile'
import SquarishAvatarImage from 'components/SquarishAvatarImage/SquarishAvatarImage'
import { AttendButtonContainerProps } from 'components/cards/EventCard/AttendButton'
import SpeakerList from 'components/cards/EventCard/SpeakerList'
import RfCardTitle from 'components/typography/RfCard/RfCardTitle'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import { EventCurrentUserPartsFragment, EventPartsFragment } from 'gql'

import { useMapNodes } from 'hooks/useMapNodes'

import { onEnterKeyPress } from 'utils/keyboard'

export interface EventCardProps {
  event: EventPartsFragment
  currentUser?: EventCurrentUserPartsFragment | null
  attendCallback?: AttendButtonContainerProps['attendCallback']
  notAttendCallback?: AttendButtonContainerProps['notAttendCallback']
  eventPageQueryString?: string
  contentClickDisabled?: boolean
  shouldOpenNewTabOnClick?: boolean
}

export default function EventCard({
  event,
  currentUser,
  eventPageQueryString = '',
  contentClickDisabled = false,
  shouldOpenNewTabOnClick = false
}: EventCardProps) {
  const {
    canViewEvent,
    orderedSpeakers,
    timeOfEventInLocalTimeZone,
    tzAbbrev,
    eventDate,
    onCardClick
  } = useEventCard({
    event,
    currentUser,
    eventPageQueryString,
    contentClickDisabled,
    shouldOpenNewTabOnClick
  })

  const attendees = useMapNodes(event.attendees).map((node) => node.user)
  const userCanAttend = currentUser?.id && canViewEvent
  const history = useHistory()

  const isAttending = event.attendingStatus === 'Attending'

  const onAttendClick = async (e: any) => {
    e.stopPropagation()
    const eventShowPagePath = `/events/${event.id}-${event.slug}`

    if (event.past) {
      return history.push(`${eventShowPagePath}/summary`)
    }

    if (isAttending) {
      return history.push(eventShowPagePath)
    }

    history.push(`${eventShowPagePath}?rsvp=true`)
  }

  const ButtonWrapper = () => {
    const dataTestId = `event-card-button-${event.id}`
    return (
      <CTAButton
        event={event}
        isAttending={isAttending}
        onAttendClick={onAttendClick}
        dataTestId={dataTestId}
      />
    )
  }

  return (
    <ContentCardContainer
      data-test={`event-card-${event.id}`}
      onClick={onCardClick}
      onKeyUp={onEnterKeyPress(onCardClick)}
      containerClassName="w-full h-full min-w-[250px] min-h-[410px] max-w-[560px] max-h-[500px] lg:max-h-[none]"
    >
      <ContentCardHeader
        typename={event.__typename}
        contentSubtype={event.kind === 'Workspace Event' ? 'Session' : null}
        contentDate={eventDate}
        contentTime={timeOfEventInLocalTimeZone}
        contentTimeZone={tzAbbrev}
        hideDefaultUnit
        headerClassName="pb-4"
      />

      <div className="aspect-w-16 aspect-h-9">
        <div className="flex justify-start space-x-4 pb-2">
          {orderedSpeakers.slice(0, 2).map((speaker) => (
            <div className="h-full w-1/2" key={speaker.user.profile.avatarUrl}>
              <SquarishAvatarImage
                className="h-full w-full"
                avatarUrl={speaker.user.profile.avatarUrl}
                alt={speaker.user.profile.fullName || ''}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="flex-grow pb-3">
        <div data-test={`event-card-title-${event.id}`}>
          <RfCardTitle className="pt-2 pb-2 font-medium tracking-normal">
            <span className="line-clamp-3">{event.name}</span>
          </RfCardTitle>
        </div>
        <RfParagraphSmall>
          <SpeakerList orderedSpeakers={orderedSpeakers} maxNames={2} />
        </RfParagraphSmall>
      </div>
      <div id={`calendar-dropdown-portal-${event.id}`} className="absolute top-[92px]" />
      <div
        data-test="event-card-button"
        className="flex items-center justify-between space-x-4"
      >
        {currentUser?.id ? (
          <FacePileButton
            currentUser={currentUser}
            users={attendees.filter((user) => user.id !== currentUser?.id).slice(0, 3)}
            count={event.attendeesCount || 0}
            toolTip=""
            onClick={() => {}}
            imageSize="medium"
            showOutline={false}
          />
        ) : null}

        {userCanAttend ? <ButtonWrapper /> : null}
      </div>
    </ContentCardContainer>
  )
}

const CTAButton = ({
  event,
  isAttending,
  onAttendClick,
  dataTestId
}: {
  event: EventPartsFragment
  isAttending: boolean
  onAttendClick: (e: any) => void
  dataTestId: string
}) => {
  if (event.past) {
    return (
      <Button
        className="ml-0 h-10 w-fit gap-x-2 border-1.5 font-sans text-xs font-semibold"
        variant="outline"
        color="default"
        shape="rounded"
        size="x-small"
        iconClassName="!mr-1"
        onClick={onAttendClick}
      >
        View Recording
      </Button>
    )
  }

  return isAttending ? (
    <Button
      dataTest={dataTestId}
      className={
        'ml-0 h-10 w-fit gap-x-2 border-1.5 bg-rb-success-50 font-sans text-xs font-semibold'
      }
      variant="outline"
      color="teal"
      shape="rounded"
      size="x-small"
      iconClassName="!mr-1"
      onClick={onAttendClick}
    >
      <span className="font-sans text-sm font-semibold text-rb-teal-600">
        {"You're in!"}
      </span>
    </Button>
  ) : (
    <Button
      dataTest={dataTestId}
      className={'h-10 w-[78px] gap-x-2  border-1.5 font-sans text-xs font-semibold'}
      variant="fill"
      shape="rounded"
      size="x-small"
      iconClassName="!mr-1"
      onClick={onAttendClick}
    >
      Attend
    </Button>
  )
}
