import pluralize from 'pluralize'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import { SVGIcon } from 'components/Icon'
import { getTooltipCopy } from 'components/ReactionButton/reactionButtonUtils'
import Tooltip from 'components/Tooltip/Tooltip'

import { ReactionKind } from 'gql'

import { onEnterKeyPress } from 'utils/keyboard'
import { TLegacyOrGraphQLReaction, kindToSvg } from 'utils/reactionUtils'

interface UpvoteButtonProps {
  reactions: TLegacyOrGraphQLReaction[]
  currentUserId: string
  isActive: boolean
  removeReaction: (id: string | number) => void
  addReaction: (kind: ReactionKind) => void
  disabled: boolean
  className?: string
  containerClassName?: string
  svgContainerClassName?: string
  readOnly?: boolean
}

const UpvoteButton = ({
  reactions,
  currentUserId,
  isActive,
  removeReaction,
  addReaction,
  disabled,
  className,
  containerClassName,
  svgContainerClassName,
  readOnly = false
}: UpvoteButtonProps) => {
  const upvoteEnum = ReactionKind.UPVOTE

  const handleReaction = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation()
    if (disabled || readOnly) return

    const reactionId = reactions.find(
      // eslint-disable-next-line eqeqeq
      (reaction) => reaction?.user?.id == currentUserId
    )?.id

    if (isActive && reactionId) {
      removeReaction(reactionId)
    } else {
      addReaction(upvoteEnum)
    }
  }

  return (
    <Tooltip
      html={true}
      tooltipBody={getTooltipCopy({
        reactions,
        currentUserId,
        kind: upvoteEnum
      })}
      className="!opacity-100"
    >
      <div
        tabIndex={disabled || readOnly ? -1 : 0}
        role="button"
        className={twMerge(
          'mr-2.5 flex w-full cursor-pointer items-center rounded-2xl p-2 text-center leading-4 text-rb-gray-400 hover:bg-rb-gray-100',
          containerClassName
        )}
        onClick={handleReaction}
        onKeyUp={onEnterKeyPress(handleReaction)}
        data-testid={`reaction-button-${upvoteEnum}`}
        data-test={`reaction-button-${upvoteEnum}`}
      >
        <span className={twMerge('flex h-4 w-4 justify-center', svgContainerClassName)}>
          <SVGIcon
            className={twMerge('mr-1 text-rb-gray-300', className)}
            name={kindToSvg[upvoteEnum]}
            height="16"
            width="16"
            fill="currentColor"
          />
        </span>
        <span
          className={twMerge(
            'ml-1 inline-block w-full whitespace-nowrap text-xs text-rb-gray-300',
            className
          )}
          data-test="upvote-reaction-length"
        >
          {readOnly || reactions.length > 0
            ? ` ${pluralize('Upvote', reactions.length, true)}`
            : 'Upvote'}
        </span>
      </div>
    </Tooltip>
  )
}

export default UpvoteButton
