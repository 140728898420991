import { useEffect, useState } from 'react'

import { useModal } from 'components/Modal'

import { CourseBookmarkPartsFragment, ProgramBookmarkPartsFragment } from 'gql'

import { trackModalDisplayed } from 'utils/tracking/analytics'

const useOpenAddToBookmarkFolderModal = () => {
  const [currentBookmarkForDropdown, setCurrentBookmarkForDropdown] = useState<
    ProgramBookmarkPartsFragment | CourseBookmarkPartsFragment | null
  >(null)

  const {
    isModalOpen: isAddToBookmarkFolderModalOpen,
    openModal,
    closeModal
  } = useModal()

  useEffect(() => {
    if (currentBookmarkForDropdown) {
      trackModalDisplayed({
        category: 'app',
        location: window.location.href,
        modal_name: 'update_collection_modal',
        modal_group: 'collections'
      })
      openModal()
    }
  }, [currentBookmarkForDropdown, openModal])

  const openAddToBookmarkFolderModal = (
    bookmark: ProgramBookmarkPartsFragment | CourseBookmarkPartsFragment
  ) => {
    setCurrentBookmarkForDropdown(bookmark)
  }

  const closeAddToBookmarkFolderModal = () => {
    closeModal()
    setCurrentBookmarkForDropdown(null)
  }

  return {
    isAddToBookmarkFolderModalOpen,
    openAddToBookmarkFolderModal,
    closeAddToBookmarkFolderModal,
    currentBookmarkForDropdown
  }
}

export default useOpenAddToBookmarkFolderModal
