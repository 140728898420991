import { closing, opening } from './sharedMarkdown'

const programName = 'Product-Led Growth'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://reforge.com/courses/product-led-growth), because I will learn how to build a great PLG strategy to escalate users through product tiers and drive growth loops for **[your company]**. This course was built with [Elena Verna](https://www.reforge.com/experts/elena-verna) and [Kelly Watkins](https://www.reforge.com/experts/kelly-watkins), and covers:
* **B2B Growth Mix.** A successful PLG strategy requires a deep understanding of how product-led efforts fit into your org’s overall growth motion. 
* **PLG Monetization.** Great product-led monetization depends on both a strategic approach to your product offerings and a smooth process for delivering customers to sales when necessary.
* **PLG Activation.** Explore how PLG activation for B2B orgs differs from the standard B2C approaches and develop a strategy that sets your PLG motion up for success.
* **PLG Acquisition.** Choose the right tactics to drive acquisition and the right metrics to measure their success.

&NewLine;

${closing}
`

export default { markdown, title: programName }
