import {
  ContentCardContainer,
  ContentCardFooter,
  ContentCardHeroImage
} from 'components/ContentCard'
import Progress from 'components/Progress'

import { getPrememberCardTooltip } from 'utils/prememberCardTooltips'

import { ContentCardProps } from './ContentCard'
import ContentCardBody from './ContentCardBody'
import ContentCardTopSection from './ContentCardTopSection'
import useContentCardData from './useContentCardData'

const WideContentCard = (props: ContentCardProps) => {
  const {
    content,
    contentCardContainerProps,
    topSectionProps,
    heroImageProps,
    isPremember,
    previewable,
    showNewBadge,
    tooltipId
  } = useContentCardData(props)

  return (
    <ContentCardContainer
      {...contentCardContainerProps}
      containerClassName="relative sm:p-6"
    >
      {props.showProgress && typeof props.progressPercent === 'number' && (
        <Progress
          percentComplete={props.progressPercent}
          classNames="absolute top-0 left-0 h-1 w-full"
          backgroundClass="bg-rb-gray-100"
          progressBarClasses={{ progress: 'rounded-xl', background: 'rounded-xl' }}
        />
      )}
      <ContentCardTopSection {...props} {...topSectionProps} />
      <div className="flex h-full flex-col gap-x-2 sm:flex-row-reverse lg:items-stretch">
        <ContentCardHeroImage isWide {...heroImageProps} />
        <div className="flex h-full grow flex-col justify-between sm:h-auto sm:w-[60%] xl:w-[70%]">
          <div className="mt-6 grow sm:mt-0 sm:pb-6">
            <ContentCardBody content={content} />
          </div>
          <ContentCardFooter
            bookmark={props.bookmark}
            collectionId={props.currentFolder?.id}
            lastViewedAt={content.lastViewedAt}
            showNewBadge={showNewBadge}
            showFreeBadge={isPremember && previewable}
          />
        </div>
      </div>
      {isPremember && getPrememberCardTooltip(tooltipId)}
    </ContentCardContainer>
  )
}

export default WideContentCard
