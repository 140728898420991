import React, { Children, useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'

import ClipViewerModal from 'domains/Clip/ClipViewerModal'
import AboutMeSection from 'domains/Profile/AboutMeSection'
import BaseballCard from 'domains/Profile/BaseballCard'
import ForumActivity from 'domains/Profile/ForumActivity'
import HostedCohortsList from 'domains/Profile/HostedCohortsList/HostedCohortsList'
import HostedEvents from 'domains/Profile/HostedEvents'
import { useProfileEditModal } from 'domains/Profile/ProfileEditModal'
import ProgramList from 'domains/Profile/ProgramList'
import { RecentEventActivity } from 'domains/Profile/RecentEventActivity'
import RecentlyReviewedContent from 'domains/Profile/RecentlyReviewedContent'
import WorkSection from 'domains/Profile/WorkSection'

import ErrorMessage from 'components/ErrorMessage'
import { SVGIcon } from 'components/Icon'
import Loading from 'components/Loading'
import { useModal } from 'components/Modal'
import HighlightsCards from 'components/cards/HighlightsCards'

import {
  GENERAL_TAB,
  HEADING_CLASSES,
  SLACK_RESPONSE_TIME_OPTIONS
} from 'constants/profile'

import { ClipPartsFragment, useMemberProfileQuery } from 'gql'

import { useAssertCurrentUser } from 'hooks/useCurrentUser'

interface ProfileSidebarProps {
  children: React.ReactNode
  setIsShowingSidebar: (isShowingSidebar: boolean) => void
}

const ProfileSidebar = ({ children, setIsShowingSidebar }: ProfileSidebarProps) => {
  // the child components should return null if they shouldn't be rendered
  useEffect(() => {
    const nonNullChildren = Children.toArray(children).filter(Boolean)
    if (nonNullChildren.length === 0) {
      setIsShowingSidebar(false)

      return
    }

    setIsShowingSidebar(true)
  }, [setIsShowingSidebar, children])

  return (
    <div className="flex flex-col gap-y-8 lg:ml-8 lg:w-1/3" data-test="profile-sidebar">
      {children}
    </div>
  )
}

export const ProfileSidebarSectionHeader = ({ title }: { title: string }) => {
  return (
    <>
      <div className={HEADING_CLASSES}>{title}</div>
      <div className="mb-3 border border-rb-gray-100" />
    </>
  )
}

export default function MemberProfile() {
  const currentUser = useAssertCurrentUser()
  const params = useParams<{ userId: string }>()
  const { data, loading } = useMemberProfileQuery({
    variables: {
      userId: params.userId
    }
  })

  const user = data?.user
  const joinSlackHref = user?.joinSlackHref

  const lastForumContributions = user?.lastForumContributions
  const threeRecentSlackJoins = user?.recentSlackJoins

  /* prettier-ignore */
  const [selectedClip, setSelectedClip] = useState<ClipPartsFragment | null>(null)
  const { isModalOpen, setIsModalOpen } = useModal()
  const { openProfileEditModal } = useProfileEditModal()

  const handleEditClick = (tab: number, field: string | undefined = undefined) => {
    openProfileEditModal({
      initialTab: tab || GENERAL_TAB,
      autofocusField: field,
      showTabs: true
    })
  }

  const canEditProfile = user?.id === currentUser?.id
  const getSlackResponseTime = () => {
    const responseTime = SLACK_RESPONSE_TIME_OPTIONS.find(
      (dictionary) => dictionary.id === user?.slackResponseTime
    )
    return responseTime ? responseTime.label : null
  }

  const [isShowingSidebar, setIsShowingSidebar] = useState(false)

  if (loading) return <Loading />

  if (!user) return <ErrorMessage error="Failed to load member profile" />

  if (user.is.creator && user.profile?.sanitySlug) {
    return <Redirect to={`/profiles/${user.profile.sanitySlug}`} />
  }

  return (
    <div>
      {user.status === 'private' && user.id !== currentUser.id ? (
        <div className="flex h-screen flex-col items-center justify-center bg-white text-rb-gray-400">
          <SVGIcon name="private-user-lock" width="61" height="49" fill="#2D2F2F" />
          <div className="mt-7 text-xl">This member’s profile is private</div>
        </div>
      ) : (
        <div>
          {(user.status === 'public' || canEditProfile) && (
            <div className="py-2.5 sm:py-4 tl:py-8 3xl:py-10">
              <div className="flex flex-col lg:flex-row">
                <div className={`w-full ${isShowingSidebar ? 'lg:w-2/3' : ''}`}>
                  <BaseballCard
                    areasOfExpertiseTags={user.profile.areasOfExpertiseTags}
                    currentUser={currentUser}
                    joinSlackHref={
                      joinSlackHref ||
                      'https://join.slack.com/t/reforge-members/shared_invite/zt-1hcoz4s76-vvIfW2QIvHDa5R~wV64kFA'
                    }
                    handleEditClick={() => handleEditClick(GENERAL_TAB)}
                    communityInterestTags={user.profile.communityInterestTags}
                    slackResponseTime={getSlackResponseTime()}
                    threeRecentSlackJoins={threeRecentSlackJoins}
                    user={user}
                  />
                  <AboutMeSection
                    aboutMeText={user.profile.aboutMeText}
                    areasOfExpertiseTags={user.profile.areasOfExpertiseTags}
                    askMeAboutText={user.profile.askMeAboutText}
                    handleEditClick={handleEditClick}
                    canEdit={canEditProfile}
                    communityInterestText={user.profile.communityInterestText}
                    communityInterestTags={user.profile.communityInterestTags}
                  />
                  <WorkSection
                    businessModelTags={user.profile.businessModelTags}
                    canEdit={canEditProfile}
                    companyAudienceTags={user.profile.companyAudienceTags}
                    companyProductText={user.profile.companyProductText}
                    handleEditClick={handleEditClick}
                    learningGoalsText={user.profile.learningGoalsText}
                  />
                  {lastForumContributions && (
                    <div className="mt-7">
                      <ForumActivity lastForumContributions={lastForumContributions} />
                    </div>
                  )}
                </div>
                <ProfileSidebar setIsShowingSidebar={setIsShowingSidebar}>
                  <HostedEvents userId={user.id} />
                  <HostedCohortsList
                    user={user}
                    isCurrentUser={currentUser.id === user.id}
                  />
                  <ProgramList user={user} isCurrentUser={currentUser.id === user.id} />
                  <HighlightsCards
                    className="text-base font-normal leading-6"
                    headerName={`View ${user.profile.firstName}’s top moments from past live events`}
                    headerTextClassName="font-medium tracking-normal text-rb-gray-400 text-lg lg:text-xl"
                    chevronButtonVisibilityClassName="invisible"
                    headerDividerClassName="mb-3 border border-rb-gray-100"
                    onClipSelect={(clip) => {
                      setSelectedClip(clip)
                      setIsModalOpen(true)
                    }}
                    clips={{
                      thisWeekClips: [],
                      allClips: user.clips
                    }}
                  />
                  <RecentlyReviewedContent userId={user.id} />
                  <RecentEventActivity userId={user.id} />
                </ProfileSidebar>
                <ClipViewerModal
                  clip={selectedClip}
                  isOpen={isModalOpen}
                  handleClose={() => setIsModalOpen(false)}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
