import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import Image from 'domains/Sanity/Image'
import { ComplexPortableText } from 'domains/Sanity/PortableText/ComplexPortableText/ComplexPortableText'

import Button from 'components/Button'
import { Tag } from 'components/Tag'

import { ArtifactWithWorkEntry } from 'gql'

import { onEnterKeyPress } from 'utils/keyboard'
import { trackContentClicked } from 'utils/tracking/analytics'

import { getCompanyLogo } from '../helpers'

export interface ArtifactSeoCardLargeProps {
  artifact: ArtifactWithWorkEntry
  topArtifact: boolean
}

export const ArtifactSeoCardLarge = ({
  artifact,
  topArtifact
}: ArtifactSeoCardLargeProps) => {
  const history = useHistory()
  const { companyLogoAlt, companyLogoUrl } = getCompanyLogo(artifact)
  const author = artifact.authors?.[0]
  const hrefDestination = `/artifacts/${artifact.slug}`

  const authorBlock = (
    <p className="mb-0 text-xs leading-[1.5] text-rb-gray-400 line-clamp-2 md:text-base md:leading-[1.6] md:line-clamp-none">
      by{' '}
      <Link
        to={`/profiles/${author?.slug}`}
        className="hover:underline"
        onClick={(e) => e.stopPropagation()}
      >
        {author?.name?.trim()}
      </Link>
      {artifact.workEntry &&
        `, Created 
      ${
        artifact.workEntry.specificCompany
          ? `as ${artifact.workEntry.position} @ ${artifact.workEntry.company?.name}`
          : artifact.workEntry.generalRole
      }`}
    </p>
  )

  const onCardClick = (e: React.MouseEvent | React.KeyboardEvent) => {
    trackContentClick()

    if (e.metaKey || e.ctrlKey) {
      window.open(hrefDestination)
    } else {
      history.push(hrefDestination)
    }
  }

  const trackContentClick = () => {
    trackContentClicked({
      content_sanity_id: artifact.id,
      content_title: artifact.title,
      content_type: 'artifact',
      related_identifiers: {
        display_type: 'horizontal'
      }
    })
  }

  return (
    <div
      className="flex flex-col rounded-lg border border-rb-gray-200 bg-rb-white p-3 text-rb-black transition-shadow hover:text-rb-black hover:shadow-seo-page-card md:p-8 lg:flex-row-reverse"
      onClick={onCardClick}
      onKeyUp={onEnterKeyPress(onCardClick)}
      tabIndex={0}
      role="button"
    >
      <div className="relative mb-6 h-[120px] shrink-0 overflow-hidden rounded-lg md:mb-10 md:h-[200px] lg:mb-0 lg:ml-12 lg:h-auto lg:w-[300px]">
        <Image
          src={artifact.thumbnailUrl || ''}
          alt={`Image of ${artifact.title}`}
          fill
          className="h-full w-full object-cover"
        />

        {topArtifact && (
          <Tag
            variant="pink"
            text="🔥️ Top artifact"
            className="absolute top-6 left-6 z-1"
          />
        )}
      </div>

      <div className="w-full">
        <div className="mb-2 flex items-start">
          <Image
            src={companyLogoUrl}
            alt={companyLogoAlt}
            className="mr-4 h-auto w-12 shrink-0 rounded-lg md:w-[100px]"
          />

          <div>
            <p className="mb-0 w-full text-base font-semibold leading-[1.5] line-clamp-2 md:mb-2 md:text-[28px] md:font-medium md:leading-[1.2] md:line-clamp-none">
              {artifact.title}
            </p>

            <div className="hidden md:block">{authorBlock}</div>
          </div>
        </div>

        <div className="md:hidden">{authorBlock}</div>

        <div className="mt-4 text-rb-gray-400 md:mt-8 [&_*]:!text-base">
          <div className="mb-2">
            <ComplexPortableText content={artifact.description} />
          </div>
          <div className="hidden md:block">
            <ComplexPortableText content={artifact.summary} />
          </div>
        </div>

        <Button
          variant="text-only"
          size="small"
          className="mt-2 p-0 underline md:absolute md:-left-[99999px]"
          tabIndex={-1}
          href={`/artifacts/${artifact.slug}`}
          onClick={trackContentClick}
        >
          Read more
        </Button>
      </div>
    </div>
  )
}

export default ArtifactSeoCardLarge
