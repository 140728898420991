import { Link } from 'react-router-dom'

import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphMediumSemiBold from 'components/typography/RfParagraph/RfParagraphMediumSemiBold'

import { ArtifactAuthor } from 'gql'

import { UnitExpertCollaboratorsPreview } from './UnitExpertCollaboratorsPreview'

interface UnitExpertSummaryProps {
  expert?: ArtifactAuthor
  avatarImageClassName?: string
  trackAuthorClick?: (author: ArtifactAuthor) => void
}

export const UnitExpertSummary = ({
  expert,
  avatarImageClassName,
  trackAuthorClick
}: UnitExpertSummaryProps) => {
  if (!expert) return null

  return (
    <div className="flex flex-col space-y-4 rounded-2xl bg-[#F8F8F8] px-8 pb-8 pt-4">
      <UnitExpertCollaboratorsPreview
        linkToAuthProfiles
        experts={[expert]}
        avatarImageClassName={avatarImageClassName}
      />
      <RfParagraphMedium>{expert.shortBio}</RfParagraphMedium>
      <Link
        className="text-black underline"
        to={`/profiles/${expert.slug}`}
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          trackAuthorClick && trackAuthorClick(expert)
        }}
      >
        <RfParagraphMediumSemiBold>View Profile</RfParagraphMediumSemiBold>
      </Link>
    </div>
  )
}

export default UnitExpertSummary
