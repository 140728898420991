import produce from 'immer'
import { useState } from 'react'

import CollectionTextBlockInput from 'domains/Collections/CollectionTextBlockInput'

import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'

import {
  CollectionActivityDocument,
  CollectionActivityQuery,
  ProgramBookmarkPartsFragment,
  useDeleteBookmarkMutation,
  useUpdateBookmarkMutation
} from 'gql'

import notifyError from 'utils/errorNotifier'
import { onEnterKeyPress } from 'utils/keyboard'

interface CollectionTextBlockSectionProps {
  bookmark: ProgramBookmarkPartsFragment
  handleTextBlockTracking?: () => void
}

const CollectionTextBlockSection = ({
  bookmark,
  handleTextBlockTracking
}: CollectionTextBlockSectionProps) => {
  const [formOpen, setFormOpen] = useState(false)
  const [updateBookmark] = useUpdateBookmarkMutation({
    update: (cache, mutationResult) => {
      const newCollectionActivity =
        mutationResult?.data?.updateBookmark?.collectionActivity
      if (!newCollectionActivity) return

      const existingCollectionActivities = cache.readQuery<CollectionActivityQuery>({
        query: CollectionActivityDocument,
        variables: {
          collectionId: newCollectionActivity?.bookmarkFolderId,
          page: 1
        }
      })

      if (existingCollectionActivities && newCollectionActivity) {
        const newCollectionActivityData = produce(
          existingCollectionActivities,
          (draft) => {
            draft?.collectionActivity?.collectionActivities?.unshift(
              newCollectionActivity
            )
          }
        )

        cache.writeQuery<CollectionActivityQuery>({
          query: CollectionActivityDocument,
          variables: {
            collectionId: newCollectionActivity?.bookmarkFolderId,
            page: 1
          },
          data: newCollectionActivityData
        })
      }
    }
  })
  const [deleteBookmark] = useDeleteBookmarkMutation({
    update: (cache, mutationResult) => {
      const deletedBookmarkId = mutationResult?.data?.deleteBookmark?.id
      if (!deletedBookmarkId) return

      const normalizedId = cache.identify({
        id: deletedBookmarkId,
        __typename: 'Bookmark'
      })
      cache.evict({ id: normalizedId })
      cache.gc()
    }
  })

  const handleDeleteBookmark = async () => {
    const { errors } = await deleteBookmark({
      variables: {
        input: {
          bookmarkId: bookmark.id
        }
      }
    })
    if (errors) {
      notifyError(
        `Error deleting TextInputBookmark, got errors ${errors} for bookmark ${JSON.stringify(
          bookmark
        )}`
      )
    }
  }

  const handleEditBookmark = async (textInput: string) => {
    if (textInput === bookmark.noteBody) return

    let responseErrors
    if (textInput === '') {
      const { errors } = await deleteBookmark({
        variables: {
          input: {
            bookmarkId: bookmark.id
          }
        }
      })
      responseErrors = errors
    } else {
      const { errors } = await updateBookmark({
        variables: {
          input: {
            bookmarkId: bookmark.id,
            noteBody: textInput
          }
        }
      })
      handleTextBlockTracking?.()
      responseErrors = errors
    }
    if (responseErrors) {
      notifyError(
        `Error modifying TextInputBookmark, got errors ${responseErrors} for bookmark ${JSON.stringify(
          bookmark
        )}`
      )
    }
    setFormOpen(false)
  }

  const openForm = () => {
    setFormOpen(true)
  }

  return (
    <>
      {!formOpen && (
        <div
          className="cursor-pointer pr-2"
          onClick={openForm}
          onKeyUp={onEnterKeyPress(openForm)}
          role="textbox"
          tabIndex={0}
        >
          <RfParagraphMedium>
            <span dangerouslySetInnerHTML={{ __html: bookmark.noteBody || '' }} />
          </RfParagraphMedium>
        </div>
      )}
      <div className={`${formOpen ? '' : 'hidden'} flex items-center`}>
        <CollectionTextBlockInput
          onSubmit={handleEditBookmark}
          onDelete={handleDeleteBookmark}
          onClose={() => setFormOpen(false)}
          initialTextInput={bookmark.noteBody}
        />
      </div>
    </>
  )
}

export default CollectionTextBlockSection
