import { useEffect, useState } from 'react'

import CheckboxFilter from 'components/CheckboxFilter'

import { FilterProps } from './helpers'

const CHECKBOXES = [
  {
    name: 'collective',
    boxLabel: 'In the Reforge Collective',
    checked: false
  }
]

const FILTER_NAME = 'kind'

const IsCollective = ({ filters, setFilters }: FilterProps) => {
  const [checkboxes, setCheckboxes] = useState(CHECKBOXES)

  useEffect(() => {
    const filteredAudiences = filters[FILTER_NAME]
    setCheckboxes((checkboxes) =>
      checkboxes.map((item) => ({
        ...item,
        checked: !!filteredAudiences?.includes(item.name)
      }))
    )
  }, [filters])

  return (
    <div className="collective">
      <CheckboxFilter
        checkboxes={checkboxes}
        filters={filters}
        label="Reforge collective"
        name={FILTER_NAME}
        setFilters={setFilters}
        text="Our network of experts who contribute as Executives in Residence (EIRs), Program Collaborators and Featured Guests"
        setCheckboxes={setCheckboxes}
      />
    </div>
  )
}
export default IsCollective
