import { Select } from 'components/forms/Select'

import { SortOption } from './BookmarksFilterSidebar'

export interface SortByFilterProps {
  sortOptions: string[]
  selectedSortOption: string
  handleSortOptionChange: (option: SortOption) => void
}

/**
 * SortByFilter
 * returns the Sort By dropdown for the Saved Items page
 */
export default function SortByFilter({
  sortOptions,
  selectedSortOption,
  handleSortOptionChange
}: SortByFilterProps) {
  return (
    <div className="text-md" data-testid="sort-by-filter">
      <div className="flex items-center">
        <div className="mr-1 text-rb-gray-300">Sort by:</div>
        <div>
          <Select
            name="bookmark-sort"
            options={sortOptions.map((option) => ({
              id: option,
              option
            }))}
            value={selectedSortOption}
            onChange={handleSortOptionChange}
            classNameAdditions="px-0"
            iconClassName="h-3 w-3 ml-3.5"
            dropdownClassName="mt-0 md:w-max"
            showLabel
          />
        </div>
      </div>
    </div>
  )
}
