// Wraps each rating svg in a div with the correct css class so they can be styled
import { SVGIcon } from 'components/Icon'

type NameMap = {
  isDisabled: string
  isActive: string
  isActiveHalf: string
  willBeActive: string
}

const NAME_MAP: NameMap = {
  isDisabled: 'is-disabled',
  isActive: 'is-active',
  isActiveHalf: 'is-active-half',
  willBeActive: 'will-be-active'
}

type RaterStyle = {
  [T in keyof NameMap]?: NameMap[T]
}

type RaterIconProps = {
  name?: string
  fill?: string
  width?: string
  height?: string
  className?: string
  svgClassName?: string
} & RaterStyle

const RaterIcon = ({
  name,
  fill,
  width,
  height,
  className = '',
  svgClassName = '',
  ...props
}: RaterIconProps) => {
  const classes = Object.keys(NAME_MAP)
    .filter((prop: keyof NameMap) => props[prop])
    .map((prop: keyof NameMap) => NAME_MAP[prop])
    .join(' ')

  return (
    <div className={`react-rater-icon ${classes} ${className}`}>
      <SVGIcon
        name={name}
        width={width}
        height={height}
        fill={fill}
        className={svgClassName}
      />
    </div>
  )
}

export default RaterIcon
