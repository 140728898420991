import { useState } from 'react'

import Paginator from 'components/Paginator'

import { useCollectionActivityQuery } from 'gql'

import CollectionActivityCard from './CollectionActivityCard'

interface CollectionActivitySectionProps {
  collectionId: string
}

const CollectionActivitySection = ({ collectionId }: CollectionActivitySectionProps) => {
  const [page, setPage] = useState(1)
  const { data } = useCollectionActivityQuery({
    variables: {
      collectionId: collectionId,
      page: page
    },
    fetchPolicy: 'network-only'
  })

  return (
    <div>
      <div className="text-xl font-bold">Activity</div>
      <div className="flex flex-col items-center">
        {data?.collectionActivity?.collectionActivities?.map((activity) => (
          <div key={activity.id} className="my-2 w-full">
            <CollectionActivityCard activity={activity} />
          </div>
        ))}
      </div>
      <Paginator
        meta={{
          totalPages: Math.ceil((data?.collectionActivity?.totalCount || 0) / 20),
          currentPage: page
        }}
        handlePagination={setPage}
      />
    </div>
  )
}

export default CollectionActivitySection
