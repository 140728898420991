import { useLocation } from 'react-router-dom'

import Button from 'components/Button'

import { trackCtaClicked } from 'utils/tracking/analytics'

const SignUpButton = () => {
  const { pathname } = useLocation()
  const ctaText = 'Start trial'
  const destination = '/login/?reforgeLogin=true'
  return (
    <Button
      size="small"
      className="max-h-10 shrink-0 px-4 text-sm"
      href={destination}
      onClick={() =>
        trackCtaClicked({
          cta_location: `${pathname} top nav`,
          cta_type: 'button',
          destination: destination,
          text: ctaText.toLowerCase()
        })
      }
    >
      {ctaText}
    </Button>
  )
}

export default SignUpButton
