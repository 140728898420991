import Pluralize from 'react-pluralize'

import { getTimeInWords } from 'domains/CohortViewer/ModuleSection'

import RfParagraphMini from 'components/typography/RfParagraph/RfParagraphMini'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

interface OverallLessonProgressInWordsProps {
  totalCompleteLessons: number
  totalLessons: number
  totalEstimatedTime: number
  totalProgressPercent: number
  includeEstimatedTime: boolean
  wordsSize: 'mini' | 'small'
}

const OverallLessonProgressInWords = ({
  totalCompleteLessons,
  totalLessons,
  totalEstimatedTime,
  totalProgressPercent,
  includeEstimatedTime,
  wordsSize
}: OverallLessonProgressInWordsProps) => {
  if (totalCompleteLessons === totalLessons) {
    return (
      <WordsContainer wordsSize={wordsSize}>
        <Pluralize singular="Lesson" count={totalLessons} showCount />
      </WordsContainer>
    )
  }

  if (totalCompleteLessons === 0) {
    return (
      <WordsContainer wordsSize={wordsSize}>
        <Pluralize singular="Lesson" count={totalLessons} showCount />
        {includeEstimatedTime && (
          <>
            <span className="mx-1 pb-2 text-[6px]">{String.fromCharCode(9679)}</span>
            {getTimeInWords(totalEstimatedTime)}
          </>
        )}
      </WordsContainer>
    )
  }

  const estimatedTimeLeft =
    totalEstimatedTime - Math.floor((totalProgressPercent / 100) * totalEstimatedTime)

  return (
    <WordsContainer wordsSize={wordsSize}>
      <span data-testid="completed-lessons-numerator">
        {`${totalCompleteLessons} / `}
      </span>
      <span data-testid="completed-lessons-denominator">
        <Pluralize singular="Lesson" count={totalLessons} showCount />
      </span>
      {includeEstimatedTime && (
        <>
          <span className="mx-1 pb-2 text-[6px]">{String.fromCharCode(9679)}</span>
          <Pluralize
            singular="minute left"
            plural="minutes left"
            count={estimatedTimeLeft}
            showCount
          />
        </>
      )}
    </WordsContainer>
  )
}

const WordsContainer = ({
  wordsSize,
  children
}: {
  wordsSize: string
  children: any
}) => {
  if (wordsSize === 'mini') {
    return (
      <RfParagraphMini className="mr-2" color={ColorOptions.gray}>
        {children}
      </RfParagraphMini>
    )
  } else if (wordsSize === 'small') {
    return (
      <RfParagraphSmall className="mr-2" color={ColorOptions.gray}>
        {children}
      </RfParagraphSmall>
    )
  }

  return null
}

export default OverallLessonProgressInWords
