// determine if current page is active or not
export const getActive = (isStatic = false, pageSlug = '', router: any) => {
  if (isStatic !== false) {
    return isStatic === router.pathname.replace(/^\//g, '')
  } else {
    const slugs = [].concat(router.query.slug)
    const currentPath = slugs ? slugs.join('/') : router.asPath.replace(/^\//g, '')
    return currentPath === pageSlug
  }
}

export const getStaticRoute = (name: string = '') => {
  switch (name) {
    default:
      return false
  }
}

export const getDynamicRoute = (pageType = '') => {
  // get route from page type
  switch (pageType) {
    case 'artifact':
      return 'artifacts'
    case 'artifactAuthor':
      return 'profiles'
    default:
      return false
  }
}
