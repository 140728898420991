import { WorkEntry } from 'gql'

interface WorkEntryHeaderProps {
  firstName: string
  workEntry: WorkEntry
}

const WorkEntryCompany = ({ workEntry, firstName }: WorkEntryHeaderProps) => {
  const { company, position, specificCompany, artifactContributions, generalRole } =
    workEntry
  const companyName = company?.name

  const getRoleAndCompany = () => {
    if (!specificCompany) {
      return (
        <>
          {(artifactContributions?.length || 0) > 0 ? (
            <>
              {firstName} created {generalRole}
            </>
          ) : (
            <>{generalRole}</>
          )}
        </>
      )
    }

    if ((artifactContributions?.length || 0) > 0) {
      return companyName === 'Independent' ? (
        <div>
          {firstName} created as {position}
        </div>
      ) : (
        <div>
          {firstName} created as {position} at&nbsp;
          <span className="text-rb-gray-300">{companyName}</span>
        </div>
      )
    }

    return companyName === 'Independent' ? (
      <div>{position}</div>
    ) : (
      <div>
        {position}&nbsp;
        <span className="text-rb-gray-300">{companyName}</span>
      </div>
    )
  }

  return (
    <div className="text-base font-sans font-normal text-rb-gray-400">
      {getRoleAndCompany()}
    </div>
  )
}

export default WorkEntryCompany
