import { useState } from 'react'

import DunningFlow from 'domains/Subscription/DunningFlow'

import Button, { ButtonColors } from 'components/Button'

import { trackCtaClicked } from 'utils/tracking/analytics'

interface UpdatePaymentMethodButtonProps {
  color?: ButtonColors
  shape?: 'rounded' | 'rounded-full' | 'rounded-none'
  classes?: string
}

const UpdatePaymentMethodButton = ({
  color,
  shape,
  classes = ''
}: UpdatePaymentMethodButtonProps) => {
  const [initiateFlow, setInitiateFlow] = useState(false)

  const handleClick = () => {
    trackCtaClicked({
      cta_location: 'subscription section',
      cta_type: 'button',
      text: 'update payment'
    })

    setInitiateFlow(true)
  }

  return (
    <>
      <Button
        color={color || 'teal'}
        size="x-small"
        shape={shape || 'rounded-full'}
        className={`h-7.5 ${classes}`}
        onClick={handleClick}
      >
        Update Payment
      </Button>
      <DunningFlow initiateFlow={initiateFlow} setInitiateFlow={setInitiateFlow} />
    </>
  )
}

export default UpdatePaymentMethodButton
