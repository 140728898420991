import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'

import Activity from 'domains/Post/Activity'

import Button from 'components/Button'
import { FiltersProp } from 'components/Filters'
import Loading from 'components/Loading'
import Paginator from 'components/Paginator'

import {
  ActivityListItem,
  Pagination,
  useActivityListLazyQuery,
  useMarkAllActivitiesAsReadMutation
} from 'gql'

import notifyError from 'utils/errorNotifier'

import { ReactComponent as GlassesIcon } from 'images/icon--glasses.svg'

interface ActivityListProps {
  filters: FiltersProp
  setFilters(nextState: FiltersProp): void
  parentIsMounted: boolean
  setBusy(nextState: boolean): void
}

const ActivityList = (props: ActivityListProps) => {
  const [loading, setLoading] = useState(true)
  const [activities, setActivities] = useState<ActivityListItem[]>([])
  const [meta, setMeta] = useState<Pagination | undefined>()
  const [allRead, setAllRead] = useState(false)
  const [getActivities] = useActivityListLazyQuery()
  const [markAllActivitiesAsRead] = useMarkAllActivitiesAsReadMutation({
    variables: { input: {} }
  })

  const fetchActivities = async () => {
    const { filters, setBusy } = props
    if (Object.keys(filters).length === 0) {
      return
    } /* wait until filters have been set by Filters component */

    setLoading(true)
    setBusy(true)

    const data: FiltersProp = {}

    // if any filters have a value, put them into the data hash
    Object.keys(filters).forEach(function (key) {
      const value = filters[key]
      if (value !== null) {
        data[key] = value
      }
    })

    if (data.page === undefined) {
      data.page = 1
    }

    document.getElementById('page')?.scrollTo(0, 0)

    try {
      const response = await getActivities({ variables: { ...data } })

      setLoading(false)
      setBusy(false)
      setActivities(response.data?.activityList.activities || [])
      setMeta(response.data?.activityList.meta)
    } catch (e) {
      notifyError(e)
    }
  }

  const handlePagination = (page: number) => {
    props.setFilters(Object.assign({}, props.filters, { page: page }))
  }

  const markAllRead = async () => {
    try {
      await markAllActivitiesAsRead()

      setActivities(
        activities.map((activity) => {
          return { ...activity, read: true }
        })
      )
    } catch (e) {
      notifyError(e)
    }
  }

  const sidebarButtons = () => {
    return (
      <>
        <Button
          size="small"
          disabled={allRead}
          onClick={markAllRead}
          variant="outline"
          iconBefore={<GlassesIcon width={24} />}
          className="group mt-5 mb-2.5 w-64 transition-none"
        >
          Mark all as read
        </Button>

        {/* TODO: Remap this when functionality exists again */}
        {/* <Button
          size="small"
          href="/account#email_preferences"
          variant="outline"
          iconBefore={<EnvelopeIcon width={21} className="mr-4" />}
          className="group w-64 transition-none"
        >
          Manage Preferences
        </Button> */}
      </>
    )
  }

  const unreadMessage = () => {
    switch (props.filters.filter) {
      case undefined:
        return (
          <p>
            When you <Link to={{ pathname: '/posts', hash: 'new' }}>post a question</Link>
            , <Link to="/posts">follow a thread</Link>, or{' '}
            <Link to="/posts">add a response</Link> your activity will show up here.
          </p>
        )
      case 'byUser':
        return (
          <p>
            When you <Link to={{ pathname: '/posts', hash: 'new' }}>post a question</Link>
            , <Link to="/posts">follow a thread</Link>, or{' '}
            <Link to="/posts">add a response</Link> your activity will show up here.
          </p>
        )
      case 'unread':
        return (
          <p>
            You&apos;re all caught up!{' '}
            <Link to="/posts">Check out the latest discussions</Link>.
          </p>
        )
      case 'onUser':
        return (
          <p>
            When you{' '}
            <Link to={{ pathname: '/posts', hash: 'new' }}>post your first question</Link>
            , you&apos;ll be notified when someone follows, responds, or reacts to what
            you&apos;ve shared.
          </p>
        )
      case 'mentions':
        return (
          <p>
            When someone mentions you in a thread, you&apos;ll be notified here. Until
            then, <Link to="/posts">check out the latest discussions</Link>.
          </p>
        )
      case 'onFollowing':
        return (
          <p>
            When you <Link to="/posts">follow a thread</Link>, you&apos;ll be notified
            when people add responses and reactions.
          </p>
        )
    }
  }

  useEffect(() => {
    fetchActivities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filters])

  useEffect(() => {
    if (activities.length) {
      if (
        activities.every((activity) => {
          return activity.read === true
        })
      ) {
        setAllRead(true)
      } else {
        setAllRead(false)
      }
    } else {
      setAllRead(true)
    }
  }, [activities])

  const additionalSidebarContent = document.getElementById('additional-sidebar-content')

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <div data-test="loaded-content">
          {activities.length === 0 && unreadMessage()}
          {activities.length > 0 &&
            activities.map((activity) => {
              return <Activity key={activity.activityIds[0]} activity={activity} />
            })}
          <Paginator
            meta={{
              currentPage: meta?.currentPage || 1,
              totalPages: meta?.totalPages || 1
            }}
            handlePagination={handlePagination}
          />
        </div>
      )}
      {props.parentIsMounted &&
        additionalSidebarContent &&
        ReactDOM.createPortal(sidebarButtons(), additionalSidebarContent)}
    </>
  )
}

export default ActivityList
