import { useEffect, useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { Loading } from 'components'
import Button from 'components/Button'
import { Pill } from 'components/Pill'

import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'

import {
  ArtifactTopicFilter,
  useCreateRecommendationsMutation,
  useTrackOnboardingMutation
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import { capitalizeFirstLetter } from 'utils/stringUtils'
import { displayErrorToast } from 'utils/toastService'
import { trackCtaClicked } from 'utils/tracking/analytics'

import BusinessAndOperationsArtifact from 'images/job-function-artifacts/BusinessAndOperationsArtifact.png'
import DataArtifact from 'images/job-function-artifacts/DataArtifact.png'
import DesignArtifact from 'images/job-function-artifacts/DesignArtifact.png'
import EngineeringArtifact from 'images/job-function-artifacts/EngineeringArtifact.png'
import ExecutiveAndFounderArtifact from 'images/job-function-artifacts/ExecutiveAndFounderArtifact.png'
import LearningAndDevelopmentArtifact from 'images/job-function-artifacts/LearningAndDevelopmentArtifact.png'
import MarketingArtifact from 'images/job-function-artifacts/MarketingArtifact.png'
import ProductAndOtherArtifact from 'images/job-function-artifacts/ProductAndOtherArtifact.png'
import SalesArtifact from 'images/job-function-artifacts/SalesArtifact.png'

import {
  businessAndOperationsImageAlt,
  dataImageAlt,
  defaultImageAlt,
  designImageAlt,
  engineeringImageAlt,
  executiveAndFounderImageAlt,
  learningAndDevelopmentImageAlt,
  marketingImageAlt,
  salesAndPartnershipsImageAlt
} from './FocusAreaConstants'

const MIN_SELECTED_VALUES = 2

export interface FocusAreaProps {
  updateFocus?: boolean
  onFocusAreasSave?: () => void
  onFocusAreasSkip?: () => void
  dedupedTopicsAndFunctions: ArtifactTopicFilter[]
  dedupedTopicsAndFunctionsLoading: boolean
}

const FocusAreas = (props: FocusAreaProps) => {
  return props.updateFocus ? (
    <FocusAreasSection {...props} />
  ) : (
    <div className="grid h-screen lg:grid-cols-2 w-full">
      <FocusAreasSection {...props} />
      <RecommendedArtifactsSection />
    </div>
  )
}

const RecommendedArtifactsSection = () => {
  const { currentUser } = useCurrentUser()
  const jobFunction = currentUser?.jobFunction

  const imageAndAlt = useMemo(() => {
    switch (jobFunction) {
      case 'Business / Operations':
        return {
          image: BusinessAndOperationsArtifact,
          alt: businessAndOperationsImageAlt
        }
      case 'Data':
        return { image: DataArtifact, alt: dataImageAlt }
      case 'Design':
        return { image: DesignArtifact, alt: designImageAlt }
      case 'Engineering':
        return { image: EngineeringArtifact, alt: engineeringImageAlt }
      case 'Executive':
      case 'Founder':
        return { image: ExecutiveAndFounderArtifact, alt: executiveAndFounderImageAlt }
      case 'Learning / Development':
        return {
          image: LearningAndDevelopmentArtifact,
          alt: learningAndDevelopmentImageAlt
        }
      case 'Marketing':
        return { image: MarketingArtifact, alt: marketingImageAlt }
      case 'Sales / Partnerships':
        return { image: SalesArtifact, alt: salesAndPartnershipsImageAlt }
      default:
        return { image: ProductAndOtherArtifact, alt: defaultImageAlt }
    }
  }, [jobFunction])

  return (
    <div className="hidden items-center justify-center bg-rb-orange-25 px-16 font-polysans text-[28px] lg:flex lg:w-full lg:flex-col">
      <div className="mb-[24px] text-center">
        {jobFunction && !['Other', 'null', 'undefined'].includes(jobFunction)
          ? `Top ${jobFunction.toLowerCase()} resources for you`
          : 'Top resources for you'}
      </div>
      <img
        src={imageAndAlt.image}
        alt={imageAndAlt.alt}
        style={{ width: '288px', height: '272px', minWidth: '288px', minHeight: '272px' }}
      />
    </div>
  )
}

const FocusAreasSection = ({
  updateFocus = false,
  onFocusAreasSave,
  onFocusAreasSkip,
  dedupedTopicsAndFunctions,
  dedupedTopicsAndFunctionsLoading
}: FocusAreaProps) => {
  const [nextDisabled, setNextDisabled] = useState(true)
  const [selectedValues, setSelectedValues] = useState<ArtifactTopicFilter[]>([])
  const { currentUser, refetchCurrentUser } = useCurrentUser()
  const [createRecommendations, { loading: createRecommendationsLoading }] =
    useCreateRecommendationsMutation()
  const [trackOnboarding] = useTrackOnboardingMutation()
  const smallerThanMd = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)

  useEffect(() => {
    trackOnboarding({
      variables: {
        input: {
          onboardingType: 'artifacts',
          stepName: 'select_focus_areas',
          stepNumber: 1,
          startedEvent: true
        }
      }
    })
  }, [trackOnboarding])

  useEffect(() => {
    if (selectedValues.length >= MIN_SELECTED_VALUES) {
      setNextDisabled(false)
    } else {
      setNextDisabled(true)
    }
  }, [selectedValues])

  useEffect(() => {
    if (
      currentUser?.networkFocus &&
      currentUser.networkFocus.length > 0 &&
      dedupedTopicsAndFunctions
    ) {
      const selectedTopics = dedupedTopicsAndFunctions?.filter((tf) =>
        currentUser.networkFocus?.includes(tf.id)
      )

      setSelectedValues(selectedTopics)
    }
  }, [currentUser, dedupedTopicsAndFunctions])

  const handleCheckboxChange = (data: ArtifactTopicFilter, checked: boolean) => {
    let temp = [...selectedValues]

    if (checked) {
      temp.push(data)
    } else {
      temp = temp.filter((el) => el.id !== data.id)
    }

    setSelectedValues(temp)
  }
  const saveFocusAreaText = 'save focus areas'
  const handleSeeRecommendations = async () => {
    trackCtaClicked({
      cta_location: 'artifact_onboarding__focus_areas',
      cta_type: 'button',
      text: saveFocusAreaText
    })

    trackOnboarding({
      variables: {
        input: {
          onboardingType: 'artifacts',
          stepName: 'select_focus_areas',
          stepNumber: 1,
          option1Name: 'selected topics',
          option1Value: selectedValues.map((val) => val.title).join(', ')
        }
      }
    })

    const { data } = await createRecommendations({
      variables: {
        input: {
          topics: selectedValues.map((val) => ({ id: val.id, title: val.title }))
        }
      }
    })

    if (data?.createRecommendations?.errors) {
      displayErrorToast({
        message: data?.createRecommendations?.errors[0]
      })

      return
    }

    refetchCurrentUser()

    onFocusAreasSave?.()
  }

  const ctaText = 'Cancel'

  const handleSkip = async () => {
    trackCtaClicked({
      cta_location: 'artifact_onboarding__focus_areas',
      cta_type: 'button',
      text: ctaText.toLowerCase()
    })

    onFocusAreasSkip?.()
  }

  return (
    <div
      data-test="focus-areas-selection"
      className={twMerge(
        'mx-4 mt-8 flex flex-col justify-center sm:mx-16 sm:mt-16',
        updateFocus && 'lg:mx-auto lg:max-w-[800px]'
      )}
    >
      <div className="mx-auto max-w-[800px]">
        <h1 className="font-semibold leading-10 text-rb-black md:text-4xl">
          What topics are you currently focused on?
        </h1>
        <p className="text-rb-gray-3 text-base font-normal lg:text-xl lg:font-medium">
          We curate your recommendations based on these interests. You can update them
          anytime. Select at least {MIN_SELECTED_VALUES}.
        </p>
        {dedupedTopicsAndFunctionsLoading ? (
          <Loading className="mt-[160px]" />
        ) : (
          <div className="flex flex-initial flex-col">
            <div className="flex-start my-5 flex flex-wrap items-start self-stretch">
              {dedupedTopicsAndFunctions.map((tf) => {
                const isChecked = !!selectedValues.find((item) => {
                  return item.id === tf.id
                })

                return (
                  <Pill
                    isActive={isChecked}
                    key={tf.id}
                    id={tf.id}
                    onClick={() => handleCheckboxChange(tf, !isChecked)}
                    className="mb-4 mr-4 text-sm"
                    size={smallerThanMd ? 'x-small' : 'small'}
                    dataTest="focus-areas-pill"
                  >
                    {capitalizeFirstLetter(tf.title)}
                  </Pill>
                )
              })}
            </div>
          </div>
        )}
        {!dedupedTopicsAndFunctionsLoading && (
          <div className="w-full border-black bg-white pt-4 pb-8 px-5 sm:border-t-0 md:pt-0 tl:relative tl:p-0 tl:pb-8 lg:left-0 lg:bottom-0">
            <div className="flex items-center justify-between lg:items-end">
              <div className="ml-4 flex flex-1 items-center justify-end">
                {updateFocus && (
                  <Button
                    onClick={handleSkip}
                    variant="outline"
                    size={smallerThanMd ? 'small' : 'large'}
                  >
                    {ctaText}
                  </Button>
                )}
                <Button
                  disabled={nextDisabled}
                  className="ml-4"
                  onClick={handleSeeRecommendations}
                  isLoadingSpinner={createRecommendationsLoading}
                  size={smallerThanMd ? 'small' : 'large'}
                  color="teal"
                  dataTest="focus-areas-save-cta"
                >
                  {updateFocus ? (
                    <>
                      <span>Save</span>
                    </>
                  ) : (
                    <>Continue</>
                  )}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default FocusAreas
