import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import ContentBookmark from 'domains/Cms/ContentBookmark'

import { ProgramBookmarkPartsFragment } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { artifactState, useSnapshot } from 'stores'

import OpenInDropdown from './OpenInDropdown'
import StartDraftFromButton from './StartDraftFromButton'
import { getArtifactLinks } from './utils'

interface ArtifactButtonsProps {
  artifact: any // @todo: define type
  isInMenu?: boolean
  savedCount?: number
  className?: string
  bookmark?: ProgramBookmarkPartsFragment
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
}

const ArtifactButtons = ({
  artifact,
  savedCount = 0,
  isInMenu = false,
  className = '',
  bookmark = undefined,
  openAddToBookmarkFolderModal = () => {}
}: ArtifactButtonsProps) => {
  const [isSavedInternal, setIsSavedInternal] = useState(!!bookmark?.id)
  const { currentUser: user } = useCurrentUser()

  useEffect(() => {
    if (bookmark?.id) {
      setIsSavedInternal(true)
    }
  }, [bookmark])

  const { welcomeMode } = useSnapshot(artifactState)

  const getDynamicSavedCount = () => {
    if (bookmark?.id) {
      // originally saved on first render
      if (isSavedInternal) {
        // still saved, no change to count
        return savedCount
      } else {
        // no longer saved, decrement count
        return savedCount - 1
      }
    } else {
      if (isSavedInternal) {
        // now saved, increment count
        return savedCount + 1
      } else {
        // still not saved, no change to count
        return savedCount
      }
    }
  }

  const hasLinks = !!getArtifactLinks(artifact)?.length
  const scrollingMenuActive = false

  const dynamicCount = getDynamicSavedCount()
  return (
    <div
      id={`artifact-save${isInMenu ? '-scrolling' : '-content'}`}
      className={twMerge(
        'flex w-full items-center justify-around gap-4',
        className,
        welcomeMode.step === 'OpenInSave' && 'z-[1004] rounded bg-white px-4 py-10'
      )}
    >
      {user && (
        <div
          className={twMerge(
            'flex items-center',
            scrollingMenuActive && 'justify-between',
            !scrollingMenuActive && 'h-full w-full justify-evenly'
          )}
          data-for={`viewer-${artifact.id}`}
          data-tip={isSavedInternal ? 'Remove from saved' : 'Save'}
        >
          <button className="flex h-10 w-[65px] cursor-pointer items-center justify-evenly rounded border px-[6px] py-[3px] font-sans text-sm font-medium capitalize leading-[24px] tracking-wider  transition duration-200 ease-in-out border-rb-black bg-transparent text-rb-teal-200 hover:border-rb-teal-400 hover:text-rb-teal-400 active:bg-rb-gray-50 group hover:stroke-rb-teal-400">
            <ContentBookmark
              contentType="Artifact"
              bookmarkId={bookmark?.id}
              isInSavedItems={isSavedInternal}
              setIsInSavedItems={setIsSavedInternal}
              savedCount={dynamicCount}
              sanityId={artifact.id}
              bookmark={bookmark}
              openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
              showSaveCount
            />
          </button>
        </div>
      )}
      {hasLinks && !artifact.draftTemplateName && (
        <div className="hidden sm:block">
          <OpenInDropdown artifact={artifact} />
        </div>
      )}
      {!!artifact.draftTemplateName && user && (
        <StartDraftFromButton artifact={artifact} showCallout={true} />
      )}
    </div>
  )
}

export default ArtifactButtons
