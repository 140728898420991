import { useEffect, useState } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'

import Material from 'domains/CohortViewer/Material'
import ProgramHeader from 'domains/Program/ProgramHeader'
import ProgramCollaborators from 'domains/ProgramPreview/ProgramCollaborators'
import ProgramFormat from 'domains/ProgramPreview/ProgramFormat'
import ProgramOutcomes from 'domains/ProgramPreview/ProgramOutcomes'

import { Section, SectionContent, SectionTitle } from 'components/Section'
import TabFilters from 'components/TabFilters'
import { Tag } from 'components/Tag'
import Testimonial from 'components/Testimonial'

import { CmsProgramPreviewFragment } from 'gql'

import useScrollToTopOnLocationChange from 'hooks/useScrollToTopOnLocationChange'

import { pickRandom } from 'utils/arrayUtils'

interface ProgramPreviewPageProps {
  program: CmsProgramPreviewFragment
  programSlug: string
  storybook?: boolean
}

const Divider = () => <div className="my-4 mr-6 border-t border-rb-gray-100" />

const ProgramPreviewPage = ({
  program,
  programSlug,
  storybook = false
}: ProgramPreviewPageProps) => {
  useScrollToTopOnLocationChange()
  const history = useHistory()
  const location = useLocation()
  const [filter, setFilter] = useState('Overview')

  useEffect(() => {
    const parts = location.pathname.split('/')
    if (parts[parts.length - 1] === 'material') {
      setFilter('Material')
    }
  }, [location])

  const {
    iconImageUrl: thumbnail,
    name: title,
    synopsis: description,
    lessonCount,
    whoItsFor,
    outcomes,
    collaborators
  } = program

  const testimonial = pickRandom(program.testimonials)

  const formatListUnfiltered = program.cohort?.scheduledWeeks || []

  const programFormatList = formatListUnfiltered.filter(({ core }: any) => {
    if ((core && core === true) || core === 'undefined') return true
  })

  // Use root route of / if rendering in Storybook
  const rootRoute = storybook ? '/' : `/programs/${programSlug}/preview`

  const onSetFilter = (newFilter: string) => {
    setFilter(newFilter)
    if (newFilter === 'Material') {
      history.push(`${rootRoute}/material`)
    } else {
      history.push(`${rootRoute}`)
    }
  }

  return (
    <div className="grid">
      <ProgramHeader
        thumbnail={thumbnail}
        title={title}
        description={description}
        lessonCount={lessonCount}
        testimonial={testimonial ? <Testimonial testimonial={testimonial} /> : null}
      />

      <Divider />

      <TabFilters
        className="p-4 pb-2 sm:px-6 lg:px-8"
        activeFilter={filter}
        setFilter={onSetFilter}
        filters={[
          { id: 'Overview', option: 'Overview' },
          {
            id: 'Material',
            option: (
              <span className="flex items-center gap-2 ">
                Material{' '}
                {Number.isInteger(program.previewableLessonCount) && (
                  <Tag
                    variant="teal"
                    text={`${program.previewableLessonCount}`}
                    dataTest="preview-available-tag"
                    className="rounded-lg px-2.5"
                  />
                )}
              </span>
            )
          }
        ]}
        preventDropdown={true}
      />

      <Switch>
        <Route
          exact
          path={rootRoute}
          render={() => (
            <div className="max-w-[800px]">
              <div className="px-4 sm:px-6 lg:flex lg:px-8">
                <div className="flex-1">
                  <ProgramOutcomes slug={programSlug} description={outcomes} />

                  {whoItsFor && (
                    <Section className="mt-8 max-w-5xl">
                      <SectionTitle>Who it’s for</SectionTitle>
                      <SectionContent>{whoItsFor}</SectionContent>
                    </Section>
                  )}

                  <ProgramCollaborators
                    className="mt-8"
                    title="Meet the course collaborators"
                    programCollaborators={collaborators}
                  />

                  <ProgramFormat
                    list={programFormatList}
                    programSlug={programSlug}
                    programId={program.id}
                    programName={program.name}
                  />
                </div>
              </div>
            </div>
          )}
        />
        <Route
          path={`${rootRoute}/material`}
          render={() => (
            <Material programId={program.id} scheduledWeeks={formatListUnfiltered} />
          )}
        />
      </Switch>
    </div>
  )
}

export default ProgramPreviewPage
