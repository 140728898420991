import { FC } from 'react'

export const ContactUs: FC = () => {
  return (
    <div className="grid grid-cols-1 px-[16px] py-[6.6vmax] md:grid-cols-2 md:justify-between md:px-[4vw]">
      <h1 className="mb-[72px] font-normal fluid-text-8xl">Contact</h1>
      <p>
        For any questions please contact us at{' '}
        <a href="mailto:hello@reforge.com" className="underline">
          hello@reforge.com
        </a>
      </p>
    </div>
  )
}

export default ContactUs
