import React from 'react'
import { twMerge } from 'tailwind-merge'

import Button from 'components/Button'
import RfParagraphSmallBold from 'components/typography/RfParagraph/RfParagraphSmallBold'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import { isTouchScreen } from 'utils/device.utils'
import { trackCtaClicked } from 'utils/tracking/analytics'

import { ReactComponent as CloseIcon } from 'images/icon--close.svg'
import { ReactComponent as Megaphone } from 'images/icon--megaphone-gray.svg'
import { ReactComponent as PlayIcon } from 'images/icon--play-empty.svg'

import { useListeningModeContext } from './ListeningModeContextProvider'

const closePlayerText = 'Close player'
const listenText = 'Listen'
const listeningText = 'Listening'

const baseIconClassName = 'mr-2 sm:mr-4 my-auto '

const buttonContentsAudioPlayerClosed = {
  className: 'bg-rb-white border-rb-black',
  ctaTextElem: (
    <RfParagraphSmallBold color={ColorOptions.black}>{listenText}</RfParagraphSmallBold>
  ),
  icon: (
    <PlayIcon
      data-testid="play-icon"
      fill="black"
      className={baseIconClassName}
      width={16}
    />
  )
}

// touch screens do not have a "hover" state
// so for touch screens just have two button states, and do not have a "hover" state at all
// instead we should just ignore the listening state altogether
// and use "Close player" when audio player is open
const buttonContentsAudioPlayerOpenTouchScreen = {
  className: 'bg-rb-gray',
  ctaTextElem: (
    <RfParagraphSmallBold color={ColorOptions.black}>
      {closePlayerText}
    </RfParagraphSmallBold>
  ),
  icon: (
    <CloseIcon
      data-testid="close-icon"
      className={twMerge(baseIconClassName, 'min-w-4 max-w-5')}
    />
  )
}

const buttonContentsAudioPlayerOpen = {
  className: 'group bg-rb-gray-50 border-rb-gray-50 hover:bg-rb-gray"',
  ctaTextElem: (
    <RfParagraphSmallBold>
      <span data-testid="listening-text" className="group-hover:hidden text-[#B5B8B4]">
        {listeningText}
      </span>
      <span className="hidden group-hover:block text-black">{closePlayerText}</span>
    </RfParagraphSmallBold>
  ),
  icon: (
    <>
      <Megaphone
        data-testid="megaphone-icon"
        className={twMerge(baseIconClassName, 'group-hover:hidden')}
        width={16}
      />
      <CloseIcon
        data-testid="close-icon"
        className={twMerge(baseIconClassName, 'hidden group-hover:block')}
        width={20}
      />
    </>
  )
}

export const ListeningModeCTA = React.memo(function ListeningModeCTA() {
  const touchScreen = isTouchScreen()
  const { isOpen, toggleOpen, trackingMetadata } = useListeningModeContext()

  const buttonContents = () => {
    if (!isOpen) {
      return buttonContentsAudioPlayerClosed
    }

    if (touchScreen) {
      return buttonContentsAudioPlayerOpenTouchScreen
    }

    return buttonContentsAudioPlayerOpen
  }

  const contents = buttonContents()

  const handleButtonClick = () => {
    trackCtaClicked({
      cta_type: 'button',
      cta_location: trackingMetadata.ctaLocation,
      text: isOpen ? closePlayerText : listenText,
      access_policy_kind: trackingMetadata.accessPolicyKind,
      related_identifiers: {
        ...trackingMetadata.relatedIdentifiers,
        path: window.location.pathname,
        url: window.location.href
      }
    })

    toggleOpen()
  }

  return (
    <Button
      variant="text-only"
      className={twMerge(
        'border flex flex-row hover:border-rb-teal-300 rounded py-2 px-2 sm:px-4 text-nowrap',
        contents.className
      )}
      data-testid="listening-mode-cta"
      onClick={handleButtonClick}
      aria-label="listening-mode-button"
    >
      {contents.icon}
      {contents.ctaTextElem}
    </Button>
  )
})

export default ListeningModeCTA
