import { CarouselProvider } from 'pure-react-carousel'

import { Concept } from 'pages/CourseSeoLandingPage/CourseSeoLandingPage'

import { CarouselWithStore } from 'domains/Seo/SeoPageCarousel/SeoPageCarousel'
import { SEOTrackingRelatedIdentifiers } from 'domains/Seo/helpers'
import useSeoPageSlidesCount from 'domains/Seo/useSeoPageSlidesCount'

import { ArtifactAuthor } from 'gql'

import CoursesSeoExpertCard from '../CoursesSeoExpertCard'

export interface ExpertsCarouselSectionProps {
  concept: Concept
  experts: ArtifactAuthor[]
  tracking?: SEOTrackingRelatedIdentifiers
}

export const ExpertsCarouselSection = ({
  concept,
  experts,
  tracking
}: ExpertsCarouselSectionProps) => {
  const slideWidth = 317
  const visibleSlides = useSeoPageSlidesCount({ fourthSlide: true }) // we display 4 slides on desktop (even though the 4th one is barely visible)

  return (
    <CarouselProvider
      naturalSlideWidth={slideWidth}
      naturalSlideHeight={400}
      totalSlides={experts.length + 1} // +1 for the empty element
      visibleSlides={visibleSlides}
      isIntrinsicHeight={true}
      className="mb-[104px] w-full"
    >
      <CarouselWithStore
        isSmallGap={true}
        slideWidth={slideWidth}
        visibleSlides={visibleSlides}
        title={`Meet our ${concept.title.toLowerCase()} experts`}
        subtitle="Reforge experts are operators, not influencers. Learn from experienced executives actively doing the work."
        navigationLocation="SEO course experts carousel"
        ctaText="See all Reforge experts"
        ctaLocation="SEO course experts carousel"
        ctaHref="/experts"
        tracking={tracking}
      >
        {experts.map((expert) => (
          <CoursesSeoExpertCard expert={expert} key={expert.id} />
        ))}
      </CarouselWithStore>
    </CarouselProvider>
  )
}

export default ExpertsCarouselSection
