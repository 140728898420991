import ExpertCard from 'domains/Sanity/marketingSite/ExpertCard'
import MarketingHero from 'domains/Sanity/marketingSite/MarketingHero'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { Loading } from 'components'

import { ArtifactAuthor, ExpertsIndex, useExpertsIndexQuery } from 'gql'

import ExpertCluster from 'images/Expert-Cluster.png'
import TopYellowGreen from 'images/Top_Yellow-Green.jpg'

const ExpertsIndexPage = () => {
  const { data, loading } = useExpertsIndexQuery()
  const { page, experts } = (data?.marketingExperts || {}) as ExpertsIndex

  return (
    <MarketingLayout page={page}>
      {loading ? <Loading /> : <ExpertsIndexPageContent experts={experts} />}
    </MarketingLayout>
  )
}

export const ExpertsIndexPageContent = ({ experts }: { experts: ArtifactAuthor[] }) => {
  return (
    <>
      <MarketingHero
        headerText="Operators. Not Influencers."
        description="Learn directly from experienced executives actively doing the work — not just talking about it."
        bgImage={{
          url: TopYellowGreen,
          alt: ''
        }}
        heroImage={{
          url: ExpertCluster,
          alt: ''
        }}
        ctas={[
          {
            href: '/login?reforgeLogin=true',
            text: 'Sign Up ↗'
          }
        ]}
        noCtaMargin
        containerClassName="mb-[8vw] md:mb-[4vw]"
        contentClassName="[&_.button-marketing-cta]:mt-[2vw]"
      />

      <section className="px-[16px] pt-[1vmax] pb-[6.6vmax] md:px-[4vw]">
        <h2 className="mb-[60px] font-medium fluid-text-6xl">Reforge Partners</h2>

        <div className="grid grid-cols-1 items-stretch gap-[20px] sm:grid-cols-2 md:grid-cols-3 lgr:grid-cols-4">
          {experts.map((expert) => (
            <ExpertCard key={expert.id} expert={expert} />
          ))}
        </div>
      </section>
    </>
  )
}

export default ExpertsIndexPage
