import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { ErrorMessage, Loading } from 'components'
import Button from 'components/Button'
import { ModalContent, ModalFooter } from 'components/Modal'
import InfoIcon from 'components/icons/InfoIcon'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import {
  useCancelSubscriptionMotivationMutation,
  useUserSubscriptionCancelModalQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'
import { trackModalDisplayed } from 'hooks/useModalTracking'

import { trackCtaClicked, trackSurveyAnswerSubmitted } from 'utils/tracking/analytics'

import {
  MOTIVATIONS_FOR_INDIVIDUAL,
  MOTIVATIONS_FOR_TEAM,
  getCancelMotivationAsString,
  getCancelationInfo,
  getWarningBannerCopy,
  validationSchema
} from './helpers'

export type CancelMotivationType = {
  motivationForCanceling: string
  motivationOtherValue: string
}

export interface SubscriptionCancelModalContentProps {
  onCancelSubscription: () => void
  handleClose: () => void
}

export const SubscriptionCancelModalContent = ({
  onCancelSubscription,
  handleClose
}: SubscriptionCancelModalContentProps) => {
  const [showTextInput, setShowTextInput] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [otherText, setOtherText] = useState('')
  const { currentUser } = useCurrentUser()
  const { id: userId, timezone } = currentUser || {}
  const [cancelSubscriptionMotivation] = useCancelSubscriptionMotivationMutation()

  const { loading, error, data } = useUserSubscriptionCancelModalQuery({
    variables: { id: userId || '' }
  })
  const numberOfSeatsAffected =
    data?.user?.subscriptions?.active?.numSeatsAffectedByCancellation || 0
  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)

  const {
    register,
    handleSubmit,
    formState: { isValid }
  } = useForm<CancelMotivationType>({
    validationSchema,
    mode: 'onChange'
  })

  useEffect(() => {
    trackModalDisplayed({
      modal_group: 'cancelation_flow',
      modal_name: 'cancel'
    })
  }, [])

  const handleCancelSubscription = async (data: CancelMotivationType) => {
    setShowErrorMessage(false)
    const motivationForCanceling = getCancelMotivationAsString(data)

    const response = await cancelSubscriptionMotivation({
      variables: {
        input: { motivationForCanceling }
      }
    })

    if (response.data?.cancelSubscriptionMotivation?.errors?.[0] || response.errors) {
      setShowErrorMessage(true)
    }

    trackSurveyAnswerSubmitted({
      question_name: 'motivation_for_canceling',
      answer_selected: motivationForCanceling,
      modal_group: 'cancelation_flow',
      modal_name: 'cancel_subscription'
    })

    onCancelSubscription()
  }

  const keepSubscription = () => {
    trackCtaClicked({
      cta_location: 'cancellation_survey',
      cta_type: 'button',
      text: 'keep subscription'
    })
    handleClose()
  }

  if (loading) {
    return <Loading />
  }

  const isTeamPlan = !!data?.user?.subscriptions?.active?.isTeam
  const motivations = isTeamPlan ? MOTIVATIONS_FOR_TEAM : MOTIVATIONS_FOR_INDIVIDUAL

  return (
    <>
      <ModalContent className="px-3 sm:px-8" dataTest="subscription-cancel-modal">
        <div className="mb-1 text-lg font-semibold leading-3 sm:mb-4 sm:text-2xl sm:leading-7">
          Are you sure you want to cancel?
        </div>

        {error || showErrorMessage ? (
          <ErrorMessage error={error} />
        ) : (
          <div>
            <div className="border-b border-rb-gray-100 pb-4 sm:pb-10">
              <div className="text-sm sm:text-base">
                {getCancelationInfo(isTeamPlan, data, timezone)}
              </div>
              {numberOfSeatsAffected > 0 && (
                <div className="mt-2 flex rounded border border-rb-green-100 bg-rb-blue-50 p-2 sm:mt-4 sm:p-3">
                  {!isMobile && <InfoIcon className="mr-3 w-7" fill="#f0f5fc" />}
                  <div className="text-sm sm:text-base">
                    {getWarningBannerCopy(numberOfSeatsAffected, isMobile)}
                  </div>
                </div>
              )}
            </div>
            <div>
              <form
                id="confirm-cancellation-survey-form"
                onSubmit={handleSubmit(handleCancelSubscription)}
              >
                <div className="mt-3 mb-2 pb-1 text-sm sm:mt-10 sm:pb-3 sm:text-base">
                  What is the main reason you are canceling?{' '}
                  <span className="text-rb-destructive-100">*required</span>
                </div>

                {motivations.map((value, i) => {
                  return (
                    <div
                      key={value}
                      className={`flex items-center text-sm ${
                        i > 0 ? 'mt-3 sm:mt-2.5' : ''
                      }`}
                      onClick={() => {
                        setShowTextInput(false)
                        setOtherText('')
                      }}
                    >
                      <label
                        htmlFor={`motivationForCanceling-${value}`}
                        className="flex items-center"
                      >
                        <input
                          id={`motivationForCanceling-${value}`}
                          name="motivationForCanceling"
                          type="radio"
                          value={value}
                          className="mr-2 accent-rb-gray-500"
                          ref={register}
                        />
                        {value}
                      </label>
                    </div>
                  )
                })}

                <div
                  key="Other"
                  className="mt-2.5 flex items-center text-sm sm:text-sm"
                  onClick={() => {
                    setShowTextInput(true)
                  }}
                >
                  <label
                    htmlFor="motivationForCanceling-Other"
                    className="flex items-center"
                  >
                    <input
                      id="motivationForCanceling-Other"
                      name="motivationForCanceling"
                      type="radio"
                      value="Other"
                      className="mr-2"
                      ref={register}
                    />
                    Other
                  </label>
                </div>

                {showTextInput && (
                  <div className="mt-1 mb-8 rounded border border-rb-gray-100 py-1 pl-2 text-xs sm:mt-3 sm:p-2.5 sm:text-sm">
                    <input
                      id="motivationForCanceling-Other-text"
                      type="text"
                      name="motivationOtherValue"
                      value={otherText}
                      className="mr-2 w-full overflow-y-auto focus:outline-none"
                      placeholder="Please explain..."
                      ref={register}
                      onChange={(e) => setOtherText(e.target.value)}
                      autoComplete="off"
                      maxLength={200}
                      autoFocus
                    />
                  </div>
                )}
              </form>
            </div>
          </div>
        )}
      </ModalContent>

      <ModalFooter className="mt-1 px-3 sm:mt-10 sm:px-8">
        <Button
          dataTest="keep-subscription-btn"
          size="small"
          variant="outline"
          className="mr-4"
          onClick={keepSubscription}
        >
          Keep Subscription
        </Button>

        <Button
          type="submit"
          disabled={!isValid}
          size="small"
          dataTest="cancel-subscription"
          form="confirm-cancellation-survey-form"
        >
          Continue With Cancelation
        </Button>
      </ModalFooter>
    </>
  )
}

export default SubscriptionCancelModalContent
