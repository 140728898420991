export const ReimbursementTitle = () => {
  return (
    <div>
      <h1 className="text-lg font-semibold md:text-[28px] lg:text-2xl">
        Reimbursement Resources
      </h1>
      <p className="mb-0">
        Did you know that the majority of alumni report that their company reimbursed them
        for Reforge? Check out our reimbursement tips and resources here.
      </p>
    </div>
  )
}

export default ReimbursementTitle
