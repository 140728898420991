import { useState } from 'react'

import { UnitLandingPage } from 'pages/UnitLandingPage'

import { GenericPageType } from 'domains/Sanity/marketingSite/MarketingHeadSEO/MarketingHeadSEO'
import MarketingLayout from 'domains/Sanity/marketingSite/MarketingLayout'

import { PageProvider } from 'components/PageHeader/usePage'

const LoggedOutGuidesDetailPage = () => {
  const [page, setPage] = useState<GenericPageType>()

  return (
    <MarketingLayout page={page}>
      <PageProvider
        showHeader
        headerWrapperClassName="px-4 md:px-[4vw] pt-4 mx-auto max-w-[calc(1280px+8vw)]"
      >
        <div className="px-4 pb-8 md:px-[4vw]">
          <div className="mx-auto max-w-[1280px]">
            <UnitLandingPage setPage={setPage} />
          </div>
        </div>
      </PageProvider>
    </MarketingLayout>
  )
}

export default LoggedOutGuidesDetailPage
