import type { Key, PropsWithChildren } from 'react'
import { useRef } from 'react'
import { Link } from 'react-router-dom'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useOnClickOutside from 'hooks/useOnClickOutside'

import AuthorByline from './AuthorByline'
import type { AuthorBylineProps } from './artifact-types'

const LinkIfAuthed = ({
  children,
  href,
  key
}: PropsWithChildren<{ href: string; key: Key }>) => {
  const { currentUser } = useCurrentUser()

  return currentUser ? (
    <Link
      to={href}
      key={key}
      className="flex items-center rounded py-[10px] px-[15px] text-rb-black hover:bg-rb-gray-50"
    >
      {children}
    </Link>
  ) : (
    <div
      key={key}
      className="flex items-center rounded py-[10px] px-[15px] text-rb-black"
    >
      {children}
    </div>
  )
}

/**
 * This is the contents for an author-focused dropdown.
 *
 * Note: This is similar to the {@link #components/artifacts-viewer/contributor-dropdown/ContributorDropdown}.
 * The two can't be easily combined due to the way authors and contributors are modeled in sanity.
 * That modeling issue should likely be fixed before attempting to generalize this component to both authors and contributors.
 */
const AuthorDropdownContent = ({
  authors,
  isOpen,
  setIsOpen
}: {
  authors: AuthorBylineProps[]
  isOpen: boolean
  setIsOpen: (arg0: boolean) => void
}) => {
  const ref = useRef<HTMLDivElement | null>(null)

  useOnClickOutside(ref, () => setIsOpen(false), {
    enabled: isOpen
  })

  return (
    <div
      ref={ref}
      className="box-shadow1 absolute z-10 mt-[8px] max-h-max max-w-max rounded border border-rb-gray-250 bg-white p-[10px]"
    >
      {authors.map(({ author, source }) => {
        return (
          <LinkIfAuthed href={`/profiles/${author.slug}`} key={author.id}>
            <AuthorByline key={author.id} author={author} source={source} />
          </LinkIfAuthed>
        )
      })}
    </div>
  )
}

export default AuthorDropdownContent
