import clsx from 'clsx'
import { ReactComponent as DiscussionIcon } from 'icon--discussion.svg'
import { ReactComponent as DoubleChevronRightIcon } from 'icon--double-chevron-right.svg'

import ManageBookmarkButton from 'domains/Collections/ManageBookmarkButton'

import DiscoveryDrawer from 'components/DiscoveryDrawer'
import { useSideDrawer } from 'components/SideDrawer'
import HighlightIcon from 'components/icons/HighlightIcon'

import {
  BookmarkType,
  ContentViewerDocument,
  ContentViewerHeaderPartsFragment,
  ProgramBookmarkPartsFragment
} from 'gql'

import useContentViewerUrlData from 'hooks/useContentViewerUrlData'

import { onEnterKeyPress } from 'utils/keyboard'

interface IContentHeader {
  contentViewer: ContentViewerHeaderPartsFragment
  leftDrawerId: string
  rightDrawerId: string
  setActiveDiscoveryDrawerPanel: (type: string) => void
  activeDiscoveryDrawerPanel: string
  trackActionButtonClick: (type: 'discussions' | 'bookmarks') => void
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  isInSavedItems: boolean
  setIsInSavedItems: (isInSavedItems: boolean) => void
}

const ContentHeader = ({
  contentViewer,
  leftDrawerId,
  rightDrawerId,
  setActiveDiscoveryDrawerPanel,
  trackActionButtonClick,
  openAddToBookmarkFolderModal,
  isInSavedItems,
  setIsInSavedItems
}: IContentHeader) => {
  const { cmsContent } = contentViewer
  const { cmsContentType } = useContentViewerUrlData()
  const { isDrawerOpen, openDrawer, toggleDrawer } = useSideDrawer()

  const contentBookmark = {
    type:
      contentViewer?.cmsContent?.contentType === 'Concept'
        ? BookmarkType.CONCEPTBOOKMARK
        : BookmarkType.PROJECTBOOKMARK,
    cmsProgramId: contentViewer?.cmsProgram?.id,
    cmsSectionId: contentViewer?.cmsContent?.id,
    cmsModuleId: contentViewer?.cmsModule?.id,
    ...(contentViewer?.cmsContent?.contentBookmarkId && {
      id: contentViewer?.cmsContent.contentBookmarkId
    })
  }

  const handleActionButtonClick = (type: 'discussions' | 'bookmarks') => {
    openDrawer(rightDrawerId)
    setActiveDiscoveryDrawerPanel(type)
    trackActionButtonClick(type)
  }

  const bgIconUrl =
    cmsContentType === 'project'
      ? "bg-[url('/app/assets/images/icon--project.svg')]"
      : cmsContentType === 'event'
        ? "bg-[url('/app/assets/images/icon--event.svg')]"
        : "bg-[url('/app/assets/images/icon--concept.svg')]"

  return (
    <>
      {!isDrawerOpen(leftDrawerId) && (
        <div
          role="button"
          tabIndex={0}
          className="cms-header__left fixed m-0 flex h-[56px] items-center 3xl:relative"
          onClick={() => toggleDrawer(leftDrawerId)}
          onKeyUp={() => onEnterKeyPress(() => toggleDrawer(leftDrawerId))}
        >
          <a
            className="flex h-full items-center pr-3 pt-3 text-rb-gray-300 hover:text-rb-gray-400 hover:no-underline"
            uk-tooltip="title: Open Sidebar; cls: uk-active rf-tooltip-left-arrow; pos: right"
            uk-toggle="target: #sidenav; cls: left-nav-revealed"
          >
            <DoubleChevronRightIcon width={16} height={16} />
            <span className="hidden pl-3 text-xs uppercase tracking-widest 3xl:flex">
              Contents
            </span>
          </a>
        </div>
      )}
      <div
        className={clsx(
          'cms-header top-0 z-20 bg-white',
          isDrawerOpen(leftDrawerId) && 'ml-2'
        )}
        id="sticky-top"
      >
        <div className="px-4 pt-3 pl-12 lg:px-8">
          <div>
            <div className="uk-grid uk-grid-collapse uk-container m-auto flex h-10 max-w-[970px] flex-nowrap items-center justify-between pb-2.5">
              {cmsContentType && (
                <div
                  style={{ backgroundSize: '20px' }}
                  className={`cms-header__${cmsContentType} flex flex-grow items-center ${bgIconUrl} bg-left-center bg-no-repeat pl-[35px]`}
                >
                  <div className="pr-2 text-lg font-semibold line-clamp-2">
                    {cmsContent?.name}
                  </div>
                  <div className="hidden justify-between gap-4 tl:flex"></div>
                </div>
              )}
              <div className="hidden justify-between gap-4 tl:flex">
                {!isDrawerOpen(rightDrawerId) && (
                  <DiscoveryDrawer.Buttons>
                    <DiscoveryDrawer.Button
                      icon={<HighlightIcon className="h-4 w-4 fill-black" />}
                      key="bookmarks"
                      id="discovery-drawer-bookmarks-button"
                      className="discovery-drawer-bookmarks-button"
                      onClick={() => handleActionButtonClick('bookmarks')}
                    />
                    <DiscoveryDrawer.Button
                      icon={<DiscussionIcon width={16} height={16} />}
                      key="discussions"
                      id="discovery-drawer-discussions-button"
                      className="discovery-drawer-discussions-button"
                      onClick={() => handleActionButtonClick('discussions')}
                    />
                    <ManageBookmarkButton
                      contentBookmarkId={contentViewer?.cmsContent?.contentBookmarkId}
                      fetchOptions={{ refetchQueries: [ContentViewerDocument] }}
                      openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                      bookmarkInfo={contentBookmark}
                      isInSavedItems={isInSavedItems}
                      setIsInSavedItems={setIsInSavedItems}
                    />
                  </DiscoveryDrawer.Buttons>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="w-100 flex items-center justify-between pl-2 pb-2 tl:hidden">
          <DiscoveryDrawer.Buttons>
            <DiscoveryDrawer.Button
              icon={<HighlightIcon className="h-4 w-4 fill-black" />}
              key="bookmarks"
              onClick={() => handleActionButtonClick('bookmarks')}
            />
            <DiscoveryDrawer.Button
              icon={<DiscussionIcon width={16} height={16} />}
              key="discussions"
              onClick={() => handleActionButtonClick('discussions')}
            />
            <ManageBookmarkButton
              contentBookmarkId={contentViewer?.cmsContent?.contentBookmarkId}
              fetchOptions={{ refetchQueries: [ContentViewerDocument] }}
              openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
              bookmarkInfo={contentBookmark}
              isInSavedItems={isInSavedItems}
              setIsInSavedItems={setIsInSavedItems}
            />
          </DiscoveryDrawer.Buttons>
        </div>
      </div>
    </>
  )
}

export default ContentHeader
