import CohortActivity from 'domains/CohortDashboard/CohortActivity'

import Loading from 'components/Loading'
import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'
import RfParagraphSmall from 'components/typography/RfParagraph/RfParagraphSmall'

import {
  CohortViewerNewCohortPartsFragment,
  CohortViewerNewCohortTeamPartsFragment,
  useCohortTeamActivitiesQuery
} from 'gql'

interface ActivityFeedProps {
  cohort: CohortViewerNewCohortPartsFragment
  cohortTeam: CohortViewerNewCohortTeamPartsFragment
}

export const ActivityFeed = ({ cohort, cohortTeam }: ActivityFeedProps) => {
  const { data, loading } = useCohortTeamActivitiesQuery({
    variables: { cohortTeamId: cohortTeam.id }
  })
  const activities = data?.cohortTeam?.activities || []

  return (
    <div className="w-full flex-1 lg:max-w-[308px] xl:max-w-[536px] 2xl:max-w-[700px]">
      <RfHeader3SemiBold>Activity</RfHeader3SemiBold>
      {loading && <Loading />}
      {!loading &&
        (activities && activities.length > 0 ? (
          <div className="mt-8 flex flex-col space-y-6">
            {activities.map((activity) => {
              return (
                <CohortActivity
                  cohortSlug={cohort.slug}
                  key={activity.activityIds.toString()}
                  activity={activity}
                  textClassName="lg:line-clamp-3 xl:line-clamp-1"
                />
              )
            })}
          </div>
        ) : (
          <div className="mt-16 flex flex-col items-center">
            <RfHeader3SemiBold>No one has posted anything yet</RfHeader3SemiBold>
            <RfParagraphSmall>
              Activity from your team in the channels will appear here.
            </RfParagraphSmall>
          </div>
        ))}
    </div>
  )
}
