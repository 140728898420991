import { BreadcrumbHistoryEntry } from 'components/Breadcrumbs'

import useSyncedState from 'hooks/useSyncedState'

interface UseEditableHistoryProps {
  history: BreadcrumbHistoryEntry[]
}

const useEditableHistory = ({ history }: UseEditableHistoryProps) => {
  const [editableHistory, setEditableHistory] =
    useSyncedState<BreadcrumbHistoryEntry[]>(history)

  const lastEntryIndex = editableHistory.length - 1

  const removeHistoryEntry = () => {
    setEditableHistory((prevHistory) => {
      const newHistory = prevHistory.slice(0, lastEntryIndex)

      return newHistory
    })
  }

  const removeHistoryEntries = (index: number) => {
    setEditableHistory((prevHistory) => {
      const newHistory = prevHistory.slice(0, index + 1)

      return newHistory
    })
  }

  return { editableHistory, lastEntryIndex, removeHistoryEntries, removeHistoryEntry }
}

export default useEditableHistory
