import { format } from 'date-fns'

import { useRestartPlanNowSuccessModal } from 'domains/Subscription/RestartPlanNowSuccessModal/useRestartPlanNowSuccessModal'

import { SelectedPlanProps } from 'components/CheckoutModal/SelectedPlan'
import ConfirmPlanModal from 'components/ConfirmPlanFlow/ConfirmPlanModal'
import ConfirmPlanSuccessModalContent from 'components/ConfirmPlanFlow/ConfirmPlanSuccessModal'
import { useGlobalModal } from 'components/GlobalModal'

import { useUserSubscriptionModalQuery } from 'gql'

import { trackModalDismissed, trackModalDisplayed } from 'hooks/useModalTracking'

import { FULL_DATE_FORMAT } from 'utils/date'

export const useConfirmPlan = () => {
  const { openGlobalModal, closeGlobalModal } = useGlobalModal()
  const { openRestartPlanNowSuccessModal } = useRestartPlanNowSuccessModal()

  const { loading, error, data } = useUserSubscriptionModalQuery()

  const activeSubscription = data?.currentUser?.subscriptions?.active

  if (loading) {
    return { openConfirmPlanModal: () => {}, errors: error, loading: true }
  }
  if (error) {
    return { openConfirmPlanModal: () => {}, errors: error, loading: false }
  }

  if (!activeSubscription) {
    return {
      openConfirmPlanModal: () => {},
      errors: 'No active subscription for user.',
      loading: false
    }
  }

  const prettyExpirationDate = format(
    new Date(activeSubscription!.expiresAt),
    FULL_DATE_FORMAT
  )

  const openConfirmPlanModal = ({
    selectedPlan,
    numCohortPasses,
    flowContext = 'upgradeSubscription'
  }: {
    selectedPlan: SelectedPlanProps
    numCohortPasses?: number
    flowContext?: 'upgradeSubscription' | 'restartSubscription'
  }) => {
    trackModalDisplayed({
      modal_group: 'plan_choice_modal_flow',
      modal_name: 'schedule_plan_update_confirmation'
    })
    openGlobalModal(
      <ConfirmPlanModal
        renewalDate={prettyExpirationDate}
        selectedPlan={selectedPlan}
        numCohortPasses={numCohortPasses}
        onConfirmPlanSuccess={(amountDue: number) => {
          if (flowContext === 'restartSubscription') {
            openRestartPlanNowSuccessModal({
              restartedWhen: 'later',
              amountDue,
              renewalDate: prettyExpirationDate
            })
          } else {
            openSuccessModal(amountDue)
          }
        }}
        handleClose={handleConfirmPlanModalClose}
      />,
      {
        className: 'mx-2 max-w-[646px] rounded-lg',
        handleClose: handleConfirmPlanModalClose
      }
    )
  }

  const handleConfirmPlanModalClose = () => {
    trackModalDismissed({
      modal_group: 'plan_choice_modal_flow',
      modal_name: 'schedule_plan_update_confirmation'
    })
    closeGlobalModal()
  }

  const openSuccessModal = (amountDue: number | null) => {
    trackModalDisplayed({
      modal_group: 'plan_choice_modal_flow',
      modal_name: 'update_plan_success'
    })
    openGlobalModal(
      <ConfirmPlanSuccessModalContent
        handleClose={handleConfirmPlanSuccessModalClose}
        amountDue={amountDue}
        renewalDate={prettyExpirationDate}
      />,
      {
        className: 'mx-2 max-w-[646px] rounded-lg',
        handleClose: handleConfirmPlanSuccessModalClose
      }
    )
  }

  const handleConfirmPlanSuccessModalClose = () => {
    trackModalDismissed({
      modal_group: 'plan_choice_modal_flow',
      modal_name: 'update_plan_success'
    })
    closeGlobalModal()
    window.location.reload()
  }

  return {
    openConfirmPlanModal
  }
}
