import { closing, opening } from './sharedMarkdown'

const programName = ' Experimentation + Testing'

const markdown = `
${opening}

The course I’m most interested in tackling immediately is [${programName}](https://www.reforge.com/experimentation-testing), because it will help me learn how to build a strategic system of experimentation. This course was built with [Elena Verna](https://www.reforge.com/experts/elena-verna), and covers:
* **Experimentation Strategy.** Great experimentation starts with identifying a strategic opportunity. Define and scope an opportunity by piecing together user insights, data insights, and business insights. 
* **Solution Ideation.** Once we have a strategic opportunity, we need to generate ideas targeted at that opportunity. Learn the spectrum of solution ideation from optimizations to innovations and then evaluate and prioritize the ideas.   
* **The Science of Testing + Preparing Tests.** Experimentation is based on a foundation of statistics. Learn the applicable statistics needed to scope, prioritize, and analyze tests properly for both a/b and Bayesian tests. Learn how to create a test by crafting a hypothesis, simplifying build complexity, assigning users, and deploying a simultaneous vs sequential test. 
* **Analyzing Test Results.** Good tests are nothing without proper analysis. Learn how to monitor test data, methods to call a test, analyze the test outcome, and then build a plan to iterate on wins and losses. 
* **Acting on Test Results + Productizing Wins.** Acting on results is an art form that requires collaboration. Learn to use test outcomes to inform iterations, measure value, and document initiatives in a way that will drive confidence and buy-in from stakeholders.
* **Managing an Experimentation System.** Learn how to build a program from the ground up including measuring an experimentation system, setting goals, creating experimentation roadmaps, measuring individual performance, developing experimentation tools, and evangelizing the program. 

&NewLine;

${closing}
`

export default { markdown, title: programName }
