import clsx from 'clsx'
import React, { FormEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'
import Autosuggest from 'react-autosuggest'
import { debounce } from 'throttle-debounce'

import Person, { PersonData } from 'domains/Post/Person'

import { usePostContributorsFilterLazyQuery } from 'gql'

import notifyError from 'utils/errorNotifier'

type RenderSuggestionsContainerArgs = {
  children: React.ReactNode
  containerProps: { [key: string]: string }
}

type CurrentSuggestion = PersonData | {} | undefined

interface PersonSuggesterProps {
  addPerson: (person: CurrentSuggestion) => void
  groupSlug?: string
  removeLastPerson: () => void
  selectedPeopleSlugs: string[]
}

const PersonSuggester = ({
  addPerson,
  removeLastPerson,
  groupSlug,
  selectedPeopleSlugs
}: PersonSuggesterProps) => {
  const [suggestions, setSuggestions] = useState<PersonData[]>([])
  const [value, setValue] = useState('')
  const [postContributorsFilterQuery] = usePostContributorsFilterLazyQuery()
  let currentSuggestion: CurrentSuggestion = {}

  const selectedPeopleSlugsRef = useRef<string[]>([])
  useEffect(() => {
    if (selectedPeopleSlugs.length) {
      selectedPeopleSlugsRef.current = selectedPeopleSlugs
    }
  }, [selectedPeopleSlugs])

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 8 || event.keyCode === 46) {
      if (value.length === 0) {
        // remove the last person that was selected.
        removeLastPerson()
      }
    }
  }

  // get user objects from the server that match value
  const onSuggestionsFetchRequested = async (value: { value: string }) => {
    try {
      const response = await postContributorsFilterQuery({
        variables: {
          query: value.value,
          excludeSlugs: selectedPeopleSlugsRef.current,
          groupSlug
        }
      })

      setSuggestions(response.data?.postContributorsFilter || [])
    } catch (e) {
      notifyError(e)
    }
  }

  const debouncedOnSuggestionsFetchRequested = useRef(
    debounce(300, onSuggestionsFetchRequested)
  ).current

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const getSuggestionValue = (personData: PersonData) => {
    currentSuggestion = personData
    return personData.fullName
  }

  const onSuggestionSelected = () => {
    addPerson(currentSuggestion)
    currentSuggestion = undefined
    setValue('')
  }

  const renderSuggestion = (personData: PersonData) => {
    return <Person person={personData} />
  }

  const shouldRenderSuggestions = () => {
    return true
  }

  const renderSuggestionsContainer = (args: RenderSuggestionsContainerArgs) => {
    const { containerProps, children } = args
    return (
      <div
        {...containerProps}
        className={clsx(
          'shadow-[-1px_2px_2px_0px_rgba(30, 30, 30, 0.15)] absolute top-10 -left-3 z-[1] w-[calc(100%+24px)] rounded bg-rb-gray-50',
          value ? 'block' : 'hidden'
        )}
      >
        <div className="mx-[15px] mt-[10px] mb-[15px] text-xs font-medium uppercase text-black">
          Recommended
        </div>
        {children}
        <div className="mx-[15px] mt-[10px] mb-[15px] flex items-center pl-0.5"></div>
      </div>
    )
  }

  const onChange = (
    _event: FormEvent<HTMLElement>,
    { newValue }: { newValue: string }
  ) => {
    setValue(newValue)
  }

  const inputProps = {
    placeholder: 'Type a name',
    className:
      'uk-margin-small-bottom w-full py-0 pr-0 pl-[5px] text-sm leading-[18px] text-rb-gray-500 bg-transparent border-none focus:outline-none',
    onChange: onChange,
    value: value,
    onKeyDown: onKeyDown
  }

  return (
    <Autosuggest<PersonData>
      suggestions={suggestions}
      onSuggestionsFetchRequested={debouncedOnSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderSuggestionsContainer={renderSuggestionsContainer}
      inputProps={inputProps}
      shouldRenderSuggestions={shouldRenderSuggestions}
      highlightFirstSuggestion={true}
      focusInputOnSuggestionClick={false}
    />
  )
}

export default PersonSuggester
