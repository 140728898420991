import React, { useEffect, useState } from 'react'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'

import {
  formatTime,
  isEventAboutToStart,
  isEventEnded,
  isEventInProgress,
  startsInLessThan24Hours
} from 'pages/EventCountdownPage/utils'

import BlockEventPage from 'domains/Event/BlockEventPage'

import AttendeesModal from 'components/AttendeesModal'
import AvatarStack from 'components/AvatarStack'
import Button from 'components/Button'
import { SVGIcon } from 'components/Icon'
import { useModal } from 'components/Modal'
import { WorksheetIcon } from 'components/icons'
import RfHeader2 from 'components/typography/RfHeader/RfHeader2'
import RfParagraphLarge from 'components/typography/RfParagraph/RfParagraphLarge'
import RfParagraphLargeBold from 'components/typography/RfParagraph/RfParagraphLargeBold'
import RfParagraphMedium from 'components/typography/RfParagraph/RfParagraphMedium'
import RfParagraphMediumBold from 'components/typography/RfParagraph/RfParagraphMediumBold'
import { ColorOptions } from 'components/typography/TypographyColorOptions'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import {
  EventCountdownPageEventPartsFragment,
  useEventCountdownPageQuery,
  useRsvpMutation
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import useMediaQuery from 'hooks/useMediaQuery'

import {
  formatInTimezone,
  getCurrentTimezone,
  getTimezoneAbbreviation,
  isAfterDate
} from 'utils/date'
import { hasEventEnded, isEventInSession } from 'utils/eventUtils'

const REDIRECT_DELAY = 5000
const POLLING_TEMPO = 1000

interface EventCountdownPageProps {
  event: EventCountdownPageEventPartsFragment
  userTimezone: string
  currentUserId: string
}

const EventCountdownPage = ({
  event,
  userTimezone,
  currentUserId
}: EventCountdownPageProps) => {
  const [eventIsAboutToStart, setEventIsAboutToStart] = useState(
    isEventAboutToStart(event)
  )
  const [eventIsInProgress, setEventIsInProgress] = useState(isEventInProgress(event))
  const [eventIsEnded, setEventIsEnded] = useState(isEventEnded(event))
  const { isModalOpen, setIsModalOpen } = useModal()
  const { showCohortDashboard } = useFeatureFlags()
  const history = useHistory()
  const isPhone = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
  const { currentUser } = useCurrentUser()
  const attendees = event.attendees.edges

  useEffect(() => {
    const interval = setInterval(() => {
      if (isEventInProgress(event) || isEventAboutToStart(event) || isEventEnded(event)) {
        setEventIsInProgress(isEventInProgress(event))
        setEventIsAboutToStart(isEventAboutToStart(event))
        setEventIsEnded(isEventEnded(event))
      }
    }, POLLING_TEMPO)
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [rvsp] = useRsvpMutation()

  useEffect(() => {
    if (!event.rsvp && !hasEventEnded(event.endsAtUtc)) {
      rvsp({
        variables: {
          input: {
            id: event.id,
            timezone: getCurrentTimezone(currentUser?.timezone),
            happening: isEventInSession(event.startsAtUtc, event.endsAtUtc)
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let timer: NodeJS.Timeout
    if (eventIsInProgress || eventIsAboutToStart) {
      timer = setTimeout(() => {
        if (event.zoomLink) {
          window.location.href = event.zoomLink
        }
      }, REDIRECT_DELAY)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [eventIsInProgress, eventIsAboutToStart, event])

  const getAttendeesText = () => {
    if (attendees.length > 2) {
      const [firstAttendee, secondAttendee] = attendees
        .filter((attendee) => attendee.node.id !== currentUserId)
        .slice(0, 2)
      return `You, ${firstAttendee && firstAttendee.node.user.profile.fullName}, ${
        secondAttendee && secondAttendee.node.user.profile.fullName
      }, and ${(event.attendeesCount || 3) - 3} others are attending`
    }
  }

  const handleButtonClick = () => {
    const isCohortEvent = event.kind === 'Cohort Event'
    const isAskAnExpertEvent =
      event.name.toLowerCase().includes('ask an expert') && event.cohorts?.[0]

    if ((isCohortEvent || isAskAnExpertEvent) && showCohortDashboard) {
      const cohort = event.cohorts?.[0]
      const showCohortViewerAt = cohort.season.showCohortViewerAt

      if (!!showCohortViewerAt && isAfterDate(showCohortViewerAt)) {
        history.push(`/cohorts/${cohort.slug}/events/${event.id}-${event.slug}`)
      } else {
        history.push(`/events/${event.id}-${event.slug}`)
      }
    } else {
      history.push(`/events/${event.id}-${event.slug}`)
    }
  }

  const date = formatInTimezone(event.startsAtUtc, userTimezone, 'EEEE, MMMM d, yyyy')
  const startTime = formatTime(event.startsAtUtc, 'p', userTimezone)
  const endTime = formatTime(event.endsAtUtc, 'p', userTimezone)
  const abbreviatedUserTimezone = getTimezoneAbbreviation(userTimezone, event.startsAtUtc)
  const eventTimeString = (
    <RfParagraphMedium className="text-center">
      {date} • {startTime} - {endTime} ({abbreviatedUserTimezone})
    </RfParagraphMedium>
  )

  const eventAboutToStartOrInProgress = (
    <>
      <RfParagraphLargeBold className="mb-3">
        {eventIsInProgress
          ? 'Joining event in progress...'
          : 'This event is starting now...'}
      </RfParagraphLargeBold>
      {eventTimeString}
      <SVGIcon className="mx-auto mt-8 mb-12" name="processing-spinner" />
    </>
  )

  const eventEnded = (
    <>
      <RfParagraphLargeBold className="mb-3">This event has ended</RfParagraphLargeBold>
      {eventTimeString}
    </>
  )

  const eventInFuture = (
    <>
      <RfHeader2>{event.name}</RfHeader2>
      <div className="mb-3 text-base font-medium leading-8 text-rb-gray-400 md:text-2xl">
        This event begins in{' '}
        {startsInLessThan24Hours(event) ? (
          <span
            className="text-base leading-8 text-rb-blue-100 md:text-2xl"
            uk-countdown={`date: ${event.startsAtUtc}`}
          >
            <span className="uk-countdown-hours"></span>
            <span>:</span>
            <span className="uk-countdown-minutes"></span>
            <span>:</span>
            <span className="uk-countdown-seconds"></span>
          </span>
        ) : (
          <span className="text-rb-blue-100">{event.timeUntil}</span>
        )}
      </div>
      {eventTimeString}
      <hr className="mt-6 md:mt-8" />
      <RfParagraphLarge color={ColorOptions.gray} className="mb-6">
        While you wait...
      </RfParagraphLarge>
      <div
        onClick={() => setIsModalOpen(true)}
        className="mb-3 flex w-full max-w-screen-md cursor-pointer items-center justify-between rounded-md bg-rb-gray-50 p-4 text-left"
      >
        <div className="flex w-full items-center justify-between">
          <div className="w-2/3 pr-4">
            <RfParagraphMediumBold>Browse the attendee list</RfParagraphMediumBold>
            <RfParagraphMedium>{getAttendeesText()}</RfParagraphMedium>
          </div>
          <AvatarStack
            members={attendees.map((attendee) => ({
              ...attendee.node,
              avatarUrl: attendee.node.user.profile.avatarUrl,
              fullName: attendee.node.user.profile.fullName
            }))}
            totalCount={attendees.length}
            avatarSize={isPhone ? 25 : 30}
          />
        </div>
      </div>
      {event.prep && (
        <div
          onClick={handleButtonClick}
          className="mb-3 flex w-full max-w-screen-md cursor-pointer items-center justify-between rounded-md bg-rb-gray-50 p-4 text-left"
        >
          <div className="flex w-full items-center justify-between">
            <div className="w-3/4 pr-4">
              <RfParagraphMediumBold>Prepare for the event</RfParagraphMediumBold>
              <RfParagraphMedium>
                Complete event prep and jot down questions for the hosts & featured guests
              </RfParagraphMedium>
            </div>
            <WorksheetIcon className="h-8 w-fit" />
          </div>
        </div>
      )}
    </>
  )

  return (
    <div className="flex h-screen flex-col items-center justify-center p-8">
      {(eventIsAboutToStart || eventIsInProgress) && eventAboutToStartOrInProgress}
      {!(eventIsAboutToStart || eventIsInProgress || eventIsEnded) && eventInFuture}
      {eventIsEnded && eventEnded}
      <Button className="mt-8" onClick={handleButtonClick}>
        Go back to event page
      </Button>
      <AttendeesModal
        event={event}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  )
}

const EventCountdownPageContainer = () => {
  const { uuid } = useParams<{ uuid: string }>()
  const { pathname } = useLocation()
  const { currentUser } = useCurrentUser()
  const {
    data: eventData,
    loading: eventDataLoading,
    error: eventDataError
  } = useEventCountdownPageQuery({ variables: { eventUuid: uuid } })

  if (!currentUser) {
    return (
      <Redirect
        to={`/login?fwd=${encodeURIComponent(`${window.location.origin}${pathname}`)}`}
      />
    )
  }

  return (
    <BlockEventPage
      event={eventData?.event}
      eventLoading={eventDataLoading}
      eventError={eventDataError}
    >
      {currentUser && eventData && (
        <EventCountdownPage
          event={eventData.event}
          userTimezone={getCurrentTimezone(currentUser.timezone)}
          currentUserId={currentUser.id}
        />
      )}
    </BlockEventPage>
  )
}

export default EventCountdownPageContainer
