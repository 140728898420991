import { useEffect } from 'react'

import { useListeningModeContext } from 'components/ListeningMode/ListeningModeContextProvider'

interface UseListeningModeProgressListenerParams {
  onTrackEnded: () => void
}

export const useListeningModeProgressListener = ({
  onTrackEnded
}: UseListeningModeProgressListenerParams) => {
  const { trackEnded, setTrackEnded } = useListeningModeContext()

  useEffect(() => {
    if (trackEnded) {
      onTrackEnded()
      setTrackEnded(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackEnded])
}

export default useListeningModeProgressListener
