export const redirectToMemberProfile = (memberId) => {
  window.location = `/directory/${memberId}`
}

export const redirectTo = (url) => {
  window.location.replace(url)
}

export default {
  redirectToMemberProfile,
  redirectTo
}
