import { useEffect } from 'react'

import SearchModal from 'components/SearchModal'

import {
  EventCountdownPageEventPartsFragment,
  EventQuery,
  useAttendeesFeedQuery
} from 'gql'

import useDebouncedStringState from 'hooks/useDebouncedStringState'

import { trackModalDisplayed } from 'utils/tracking/analytics'

interface AttendeesModalProps {
  event: EventQuery['event'] | EventCountdownPageEventPartsFragment
  onClose?: () => void
  isOpen: boolean
}

const AttendeesModal = ({ event, onClose, isOpen }: AttendeesModalProps) => {
  useEffect(() => {
    if (isOpen) {
      trackModalDisplayed({
        category: 'app',
        location: 'event_details_page',
        modal_group: 'event registration',
        modal_name: 'view event attendees'
      })
    }
  }, [isOpen])

  const [search, setSearch] = useDebouncedStringState()

  const { loading, error, data, fetchMore } = useAttendeesFeedQuery({
    variables: {
      eventId: event.id,
      limit: 40,
      offset: 0,
      search
    },
    nextFetchPolicy: 'network-only',
    skip: !isOpen
  })

  const attendeesCount = event.attendeesCount || 0
  const count = data?.attendeesFeed?.count || 0
  const attendeesQuery = data?.attendeesFeed?.attendees || []

  const users = attendeesQuery.map((node) => node.user)
  const hasMoreUsers = count > users.length

  const loadMore = () => {
    fetchMore({
      variables: {
        eventId: event.id,
        limit: 20,
        offset: users.length,
        search
      }
    })
  }

  const handleClose = () => {
    // Clear search filter when modal is closed.
    setSearch('')
    onClose?.()
  }

  return (
    <SearchModal
      isOpen={isOpen}
      onClose={handleClose}
      users={users}
      count={attendeesCount}
      modalTitle="Event Attendees"
      handleSearch={setSearch}
      hasMoreUsers={hasMoreUsers}
      loadMore={loadMore}
      isLoading={loading}
      error={error}
    />
  )
}

export default AttendeesModal
