import { LessonSectionPartsFragment } from 'gql'

import { CmsSectionData } from '../Toc'

// This function iterates through the currently viewed content and
// extracts three properties needed for tracking: cms_programId, lessonName, lessonSubtype
// return [lessonCmsProgramId, lessonName, lessonSubType]
const getSectionDataForTracking = (
  sectionId: string,
  sections: CmsSectionData[]
): string[] => {
  const copyOfSections: CmsSectionData[] = Object.assign([], sections)
  let lessonName = ''
  let lessonCmsProgramId = ''
  let lessonSubType = ''
  copyOfSections.forEach((copied: CmsSectionData, idx: number) => {
    const section: CmsSectionData = copied

    if (section.id === sectionId) {
      lessonName = copyOfSections[idx].name
      lessonCmsProgramId = copyOfSections[idx].cmsProgramId
      lessonSubType = copyOfSections[idx].contentSubtype || ''
    } else {
      section.children.forEach(function (
        child: LessonSectionPartsFragment,
        childIdx: number
      ) {
        if (child.id === sectionId) {
          lessonName = section.children[childIdx].name
          lessonCmsProgramId = copyOfSections[idx].cmsProgramId
          lessonSubType = copyOfSections[idx].contentSubtype || ''
        }
      })
    }
  })
  // properties needed for tracking: cms_programId, lessonName, lessonSubtype
  return [lessonCmsProgramId, lessonName, lessonSubType]
}

export default getSectionDataForTracking
