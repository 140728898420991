import { differenceInDays } from 'date-fns'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import UIkit from 'uikit'

import AntiCopyViolationOne from 'domains/AntiPiracy/AntiCopyViolationOne'
import AntiCopyViolationThree from 'domains/AntiPiracy/AntiCopyViolationThree'
import AntiCopyViolationTwo from 'domains/AntiPiracy/AntiCopyViolationTwo'

import { useAnticopyExceptionIdsQuery } from 'gql'

import useLocalStorage from 'hooks/useLocalStorage'

import { sendData } from 'utils/sendData'
import { countToPlace } from 'utils/stringUtils'
import { track } from 'utils/tracking/segment'

import { ExpireAccessPayload } from 'typings/payloads'

interface AntiCopyProps {
  isAdmin?: boolean
  userId: string
}

const AntiCopy = ({ isAdmin = false, userId }: AntiCopyProps) => {
  const { data } = useAnticopyExceptionIdsQuery()
  const [violationCount, setViolationCount] = useLocalStorage('rf-violation-count', 0)
  const [violationDate, setViolationDate] = useLocalStorage(
    'rf-violation-date',
    new Date()
  )
  const history = useHistory()
  const [persistModal, setPersistModal] = useLocalStorage('rf-violation-show', false)

  useEffect(() => {
    if (!isAdmin && persistModal) showModal()
  }, [isAdmin, persistModal, data?.anticopyExceptionIds])

  useEffect(() => {
    if (violationCount > 0 && persistModal) {
      const blockAccess = violationCount >= 3
      const count = countToPlace(violationCount)

      // Javascript analytics.
      if (blockAccess) {
        // @ts-ignore - 'Piracy Modal - Action' event is not defined in Segment JIRA#REF-5159
        track('Piracy Modal - Action', {
          action: 'account_locked_modal_shown'
        })
      } else {
        // @ts-ignore - 'Piracy Modal - Action' event is not defined in Segment JIRA#REF-5159
        track('Piracy Modal - Action', {
          action: `anticopy_modal_shown_${count}`
        })
      }
      // TODO: convert this to GraphQL mutation
      const data: ExpireAccessPayload = {
        // API Analytics
        anti_piracy_block_access: blockAccess,
        anti_piracy_message: 'Piracy - Action',
        anti_piracy_action: blockAccess
          ? 'account_locked_modal_shown'
          : `anticopy_modal_shown_${count}`
      }
      sendData(`/api/v1/users/${userId}/expire_access`, 'POST', data, () => {
        if (blockAccess) clearStorage()
      })
    }
  }, [persistModal, userId, violationCount])

  const showModal = () => UIkit.modal('#anticopy-download-modal')?.show()
  const hideModal = () => UIkit.modal('#anticopy-download-modal')?.hide()

  const triggerAntiCopy = () => {
    // First offense
    if (!violationCount || violationCount === 0) {
      setViolationDate(new Date())
      setViolationCount((currentCount) => currentCount + 1)
      return setPersistModal(true)
    }

    const now = new Date()
    const daysSinceLastViolation = differenceInDays(now, new Date(violationDate))

    // If the last violation was less than 7 days ago, move them on to the next violation step.
    if (daysSinceLastViolation <= 7) {
      setViolationCount((currentCount) => currentCount + 1)
    }

    setViolationDate(now)
    return setPersistModal(true)
  }

  const submitForm = () => {
    if (Number(violationCount) === 1 || Number(violationCount) === 2) {
      const count = countToPlace(Number(violationCount))
      // @ts-ignore - 'Piracy Modal - Action' event is not defined in Segment JIRA#REF-5159
      track('Piracy Modal - Action', {
        action: `anticopy_modal_submit_click_${count}`
      })
      // @ts-ignore - 'Piracy Modal - Action' event is not defined in Segment JIRA#REF-5159
      track('Piracy Modal - Action', {
        action: `anticopy_modal_submit_success_${count}`
      })
    }

    setPersistModal(false)
    hideModal()
  }

  const clearStorage = () => {
    localStorage.removeItem('rf-violation-date')
    localStorage.removeItem('rf-violation-count')
    localStorage.removeItem('rf-violation-show')
  }

  const reset = async () => {
    await clearStorage()
    history.push('/account')
  }

  if (!data?.anticopyExceptionIds || data?.anticopyExceptionIds.includes(userId)) {
    return null
  }

  return (
    <>
      {/* This button isn't shown to the user. It is tied to the copy event in the browser and
      is "clicked" if the user copies more than 48% of the page content.  */}
      <button id="anticopy-button" hidden={true} onClick={triggerAntiCopy} />

      <div
        id="anticopy-download-modal"
        uk-modal="container: false; bg-close: false; esc-close: false"
        className="uk-modal-container select-none"
      >
        <div className="uk-modal-dialog uk-modal-body text-center">
          <div className="mx-auto max-w-4xl">
            {violationCount === 1 && <AntiCopyViolationOne submitForm={submitForm} />}
            {violationCount === 2 && <AntiCopyViolationTwo submitForm={submitForm} />}
            {violationCount >= 3 && <AntiCopyViolationThree reset={reset} />}
          </div>
        </div>
      </div>
    </>
  )
}

export default AntiCopy
