import { StandaloneBillingInfoForm } from 'domains/User/BillingInfoForm'

import { StripeElementsWithoutIntent } from 'components/StripeElements'
import RfHeader3SemiBold from 'components/typography/RfHeader/RfHeader3SemiBold'

const CONFIRM_PLAN_MODAL_TRACKING_LOCATION = 'update_billing_address_modal'

interface BillingAddressUpdateModalProps {
  onConfirm: (isComplete: boolean) => void
}

export const BillingAddressUpdateModal = ({
  onConfirm
}: BillingAddressUpdateModalProps) => {
  return (
    <div className="p-8 pt-0">
      <RfHeader3SemiBold className="text-center mb-8">
        Update your Billing Address
      </RfHeader3SemiBold>
      <div className="overflow-auto">
        <div className="">
          <StripeElementsWithoutIntent>
            <StandaloneBillingInfoForm
              trackingLocation={CONFIRM_PLAN_MODAL_TRACKING_LOCATION}
              onSave={(_, isComplete) => onConfirm(isComplete)}
            />
          </StripeElementsWithoutIntent>
        </div>
      </div>
    </div>
  )
}

export default BillingAddressUpdateModal
