import { DomNode, SelectedNode, SelectedNodeType, SplitType } from './types'

/**
 *  Conversion string to html need to do this since html is stored in db
 */
export const stringToHtml = (html: string) => {
  const newHtml = new DOMParser().parseFromString(html, 'text/html')

  return newHtml.body
}

/**
 *  Validates if node is a text node
 */
export const isTextN = ({ nodeType }: Node) => nodeType === Node.TEXT_NODE
export const isElementN = ({ nodeType }: Node) => nodeType === Node.ELEMENT_NODE

export const isMatchSelector = ($node: HTMLElement, selector: string): boolean => {
  if (!$node) {
    return false
  }

  if (/^\./.test(selector)) {
    const className = selector.replace(/^\./, '')

    return $node && $node.classList.contains(className)
  } else if (/^#/.test(selector)) {
    const id = selector.replace(/^#/, '')

    return $node && $node.id === id
  } else if (/^data-/.test(selector)) {
    return $node && $node.hasAttribute(selector)
  }

  const tagName = selector.toUpperCase()

  return $node && $node.tagName === tagName
}

const getNodesIfSameStartEnd = (
  $startNode: Text,
  startOffset: number,
  endOffset: number,
  exceptSelectors?: string[]
) => {
  let $element = $startNode as Node

  const isExcepted = ($e: HTMLElement) =>
    exceptSelectors?.some((s) => isMatchSelector($e, s))

  while ($element) {
    if (isElementN($element) && isExcepted($element as HTMLElement)) {
      return []
    }

    $element = $element.parentNode!
  }

  $startNode.splitText(startOffset)

  const passedNode = $startNode.nextSibling as Text

  passedNode.splitText(endOffset - startOffset)

  return [
    {
      $node: passedNode,
      type: SelectedNodeType.text,
      splitType: SplitType.both
    }
  ]
}

export const getSelectedNodes = (
  $root: Document | HTMLElement,
  start: DomNode,
  end: DomNode,
  exceptSelectors: string[]
): SelectedNode[] => {
  const $startNode = start.$node
  const $endNode = end.$node
  const startOffset = start.offset
  const endOffset = end.offset

  // split current node when the start-node and end-node is the same
  if ($startNode === $endNode && $startNode instanceof Text) {
    return getNodesIfSameStartEnd($startNode, startOffset, endOffset, exceptSelectors)
  }

  const nodeStack: (ChildNode | Document | HTMLElement | Text)[] = [$root]
  const selectedNodes: SelectedNode[] = []

  const isExcepted = ($e: HTMLElement) =>
    exceptSelectors?.some((s) => isMatchSelector($e, s))

  let withinSelectedRange = false
  let curNode

  while ((curNode = nodeStack.pop())) {
    // do not traverse the excepted node

    if (isElementN(curNode) && isExcepted(curNode as HTMLElement)) {
      continue
    }

    const children = curNode.childNodes

    for (let i = children.length - 1; i >= 0; i--) {
      nodeStack.push(children[i])
    }

    // only collect text nodes
    if (curNode === $startNode) {
      if (isTextN(curNode)) {
        const tempNode = curNode as Text

        tempNode.splitText(startOffset)
        const node = curNode.nextSibling as Text

        selectedNodes.push({
          $node: node,
          type: SelectedNodeType.text,
          splitType: SplitType.head
        })
      }

      // meet the start-node (begin to traverse)
      withinSelectedRange = true
    } else if (curNode === $endNode) {
      if (isTextN(curNode)) {
        const node = curNode as Text

        node.splitText(endOffset)
        selectedNodes.push({
          $node: node,
          type: SelectedNodeType.text,
          splitType: SplitType.tail
        })
      }

      // meet the end-node
      break
    } else if (withinSelectedRange && isTextN(curNode)) {
      // handle text nodes between the range

      selectedNodes.push({
        $node: curNode as Text,
        type: SelectedNodeType.text,
        splitType: SplitType.none
      })
    }
  }

  return selectedNodes
}
