import { Redirect } from 'react-router-dom'

import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'
import EventModule from 'domains/Program/EventModule'
import Module from 'domains/Program/Module'

import { ErrorMessage, Loading } from 'components'

import { useBookmarkFoldersQuery, useProgramDetailsQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { eventAccess } from 'utils/eventAccess'

interface ProgramPageContentProps {
  cmsProgramId: string
}

const ProgramPageContent = ({ cmsProgramId }: ProgramPageContentProps) => {
  const { currentUser, currentUserLoading, currentUserError } = useCurrentUser()
  const { data: bookmarkFolderData } = useBookmarkFoldersQuery()

  const {
    isAddToBookmarkFolderModalOpen,
    openAddToBookmarkFolderModal,
    closeAddToBookmarkFolderModal,
    currentBookmarkForDropdown
  } = useOpenAddToBookmarkFolderModal()
  const {
    isCreateBookmarkFolderModalOpen,
    openCreateBookmarkFolderModal,
    closeCreateBookmarkFolderModal,
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate
  } = useOpenCreateBookmarkFolderModal()
  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }

  const {
    data: programData,
    loading: programDataLoading,
    error: programDataError
  } = useProgramDetailsQuery({
    fetchPolicy: 'no-cache',
    variables: { cmsProgramId }
  })

  const error = currentUserError || programDataError
  const loading = currentUserLoading || programDataLoading

  const {
    currentModule,
    cmsModules,
    progressPercent,
    name: cmsProgramName
  } = programData?.cmsProgram || {}

  let firstHref = ''
  if (cmsModules) {
    firstHref =
      cmsModules[0]?.cmsSections[0]?.children[0]?.href ||
      cmsModules[0]?.cmsSections[0].href ||
      ''
  }

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <ErrorMessage error={error} />
  }

  if (!currentUser) {
    return <Redirect to="/" />
  }

  const events = cmsModules
    ?.map((module) => module.events.filter((event) => eventAccess(event, currentUser)))
    .flat()

  return (
    <div data-test="loaded-content">
      <ul className="mt-2.5 list-none divide-neutral-200 border-t border-solid p-0 pt-2.5">
        <div>
          {cmsModules?.map((module, idx) => (
            <Module
              key={`module${module.id}`}
              currentModuleId={currentModule?.id}
              currentUser={currentUser}
              cmsModule={module}
              featuredGuests={module.featuredGuests?.map((guest) => guest.user) || []}
              showMemberContent={!!currentUser?.is.member}
              firstHref={progressPercent === 0 && idx === 0 ? firstHref : ''}
              cmsProgramId={cmsProgramId}
              cmsProgramName={cmsProgramName}
              openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
            />
          ))}
        </div>
        {events && events.length > 0 && (
          <>
            <EventModule
              events={events}
              currentUser={currentUser}
              showMemberContent={!!currentUser?.is.member}
            />
          </>
        )}
      </ul>
      <AddBookmarkToFolderModal
        isOpen={isAddToBookmarkFolderModalOpen}
        handleClose={closeAddToBookmarkFolderModal}
        bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders}
        openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdown}
        showCollectionsOnboardingInfo={
          !bookmarkFolderData?.currentUser?.hasSeenCollectionsPrompt
        }
      />
      <CreateBookmarkFolderModal
        isModalOpen={isCreateBookmarkFolderModalOpen}
        handleClose={closeCreateBookmarkFolderModal}
        currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
        trackingTriggerAction={'bookmark'}
      />
    </div>
  )
}

export default ProgramPageContent
