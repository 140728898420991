import { useEffect, useState } from 'react'

import CheckboxFilter from 'components/CheckboxFilter'

import { FilterProps } from './helpers'

const BUSINESS_MODELS = [
  'Advertising',
  'Sales-Led',
  'Marketplace',
  'Transactional (Non-Marketplace)',
  'Subscription'
]

const BusinessModel = ({ filters, setFilters }: FilterProps) => {
  const name = 'businessModel'
  const filteredBusinessModels = filters[name]
  const checkboxState = BUSINESS_MODELS.map((businessModel) => {
    return {
      name: businessModel,
      boxLabel: businessModel,
      checked: !!filteredBusinessModels?.includes(businessModel)
    }
  })

  const [checkboxes, setCheckboxes] = useState(checkboxState)

  useEffect(() => {
    setCheckboxes(checkboxState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredBusinessModels])

  return (
    <div className="business-model">
      <CheckboxFilter
        checkboxes={checkboxes}
        filters={filters}
        label="business model"
        name={name}
        setFilters={setFilters}
        text="View members based on their company's business model(s)."
        setCheckboxes={setCheckboxes}
      />
    </div>
  )
}

export default BusinessModel
