import { ReactNode } from 'react'

import Image from 'domains/Sanity/Image'

interface ThreeColumnsWithIconsProps {
  headerText: string
  description?: string
  cta?: ReactNode
  icon1: {
    url: string
    alt?: string | null
  }
  column1Header: string
  column1Text: string | ReactNode
  icon2: {
    url: string
    alt?: string | null
  }
  column2Header: string
  column2Text: string | ReactNode
  icon3: {
    url: string
    alt?: string | null
  }
  column3Header: string
  column3Text: string | ReactNode
}

const ThreeColumnsWithIcons = ({
  headerText,
  description,
  cta,
  icon1,
  column1Header,
  column1Text,
  icon2,
  column2Header,
  column2Text,
  icon3,
  column3Header,
  column3Text
}: ThreeColumnsWithIconsProps) => {
  return (
    <>
      <h2 className="mb-[40px] font-normal leading-[5px] tracking-tight fluid-text-6xl">
        {headerText}
      </h2>
      {description && (
        <p className="pb-[80px] tracking-tight fluid-text-lg">{description}</p>
      )}
      <ul className="grid grid-cols-1 grid-rows-3 gap-x-[100px] gap-y-8 p-0 sm:grid-cols-2 sm:grid-rows-2 md:grid-cols-3 md:grid-rows-1">
        <li className="list-none">
          <Image
            src={icon1.url}
            alt={icon1.alt || ''}
            width={158}
            height={158}
            className="mb-[10px] h-auto w-1/4"
          />
          <h3 className="my-[10px] font-normal tracking-tight fluid-text-xl">
            {column1Header}
          </h3>
          <p className="fluid-text-base">{column1Text} </p>
          {cta}
        </li>
        <li className="list-none">
          <Image
            src={icon2.url}
            alt={icon2.alt || ''}
            width={158}
            height={158}
            className="mb-[10px] h-auto w-1/4"
          />
          <h3 className="my-[10px] font-normal tracking-tight fluid-text-xl">
            {column2Header}
          </h3>
          <p className="fluid-text-base">{column2Text} </p>
          {cta}
        </li>
        <li className="list-none">
          <Image
            src={icon3.url}
            alt={icon3.alt || ''}
            width={158}
            height={158}
            className="mb-[10px] h-auto w-1/4"
          />
          <h3 className="my-[10px] font-normal tracking-tight fluid-text-xl">
            {column3Header}
          </h3>
          <p className="fluid-text-base">{column3Text} </p>
          {cta}
        </li>
      </ul>
    </>
  )
}

export default ThreeColumnsWithIcons
