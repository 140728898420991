import { Dispatch, SetStateAction } from 'react'

import EventRsvp from 'domains/Event/EventRsvp'
import SpeakerCard from 'domains/Event/SpeakerCard'

import { FacePileButton } from 'components/FacePile'

import { MAX_WIDTH_TAILWIND_TL } from 'constants/breakpoints'

import { EventQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import { useMapNodes } from 'hooks/useMapNodes'
import useMediaQuery from 'hooks/useMediaQuery'

import { getCurrentTimezone } from 'utils/date'
import eventSignUpAccess from 'utils/eventAccess'
import { cn } from 'utils/tailwind'

type AttendSectionProps = {
  data: {
    event: EventQuery['event']
    refetchEvent: () => void
    setIsAttendeeModalOpen: Dispatch<SetStateAction<boolean>>
  }
}
export default function EventSidebar({
  data: { event, refetchEvent, setIsAttendeeModalOpen }
}: AttendSectionProps) {
  const { currentUser, isLoggedIn } = useCurrentUser()
  const isSmallerTabletCustomSize = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_TL})`)
  const timezone = getCurrentTimezone(currentUser?.timezone)
  // TODO: use enum
  const isAttending = event.rsvp?.status === 'Attending'
  const { hosts } = event
  const attendees = useMapNodes(event.attendees).map((node) => node.user)
  return (
    <div className={cn('md:sticky ', isLoggedIn ? 'md:top-0' : 'md:top-[102px]')}>
      <EventRsvp
        args={{
          dataTestId: 'event-rsvp-button',
          timezone,
          event,
          dropdownPosition: 'bottom-center',
          arrowPosition: isSmallerTabletCustomSize ? 'bottom' : 'top',
          attendCallback: refetchEvent,
          buttonSize: 'large',
          disable: !eventSignUpAccess(currentUser!, event)
        }}
      />

      <div className="order-2 mt-4 md:mt-8 tl:mr-2">
        <div className="mb-5 text-2xl font-normal">Attendees</div>
        {hosts.length > 0 && (
          <>
            <div className="mb-5">
              {hosts.map((host) => (
                <SpeakerCard
                  host
                  speaker={host.user}
                  key={`speaker${host.id}`}
                  forEventRSVP
                />
              ))}
            </div>
          </>
        )}
      </div>

      {!!event.attendeesCount && (
        <div className="inline xs:mt-0 xs:ml-auto sm:flex">
          <FacePileButton
            currentUser={currentUser!}
            users={attendees.filter((user) => user?.id !== currentUser?.id).slice(0, 3)}
            count={event.attendeesCount || 0}
            toolTip="View all event attendees"
            onClick={() => {
              setIsAttendeeModalOpen(true)
            }}
            href={
              !currentUser
                ? `/login?forEventRSVP=true&referer=${encodeURIComponent(
                    window.location.pathname + window.location.search
                  )}`
                : undefined
            }
            isAttending={isAttending}
          />
        </div>
      )}
    </div>
  )
}
