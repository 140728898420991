import { Link } from 'react-router-dom'

import { Section, SectionContent, SectionTitle } from 'components/Section'
import ArrowRightLong from 'components/icons/ArrowRightLong'

import { ScheduledWeekPartsFragment } from 'gql'

import { trackCtaClicked } from 'utils/tracking/analytics'

interface ProgramFormatProps {
  list: ScheduledWeekPartsFragment[]
  programSlug: string
  programId?: string
  programName?: string
}

const ProgramFormat = ({
  list = [],
  programSlug,
  programId,
  programName
}: ProgramFormatProps) => {
  if (!list?.length) return null

  const ctaText = 'Explore the Material'

  const handleLinkClick = (destination: string) => {
    trackCtaClicked({
      cta_location: 'program_preview_page',
      cta_type: 'hyperlink text',
      destination: `https://reforge.com/${destination}`,
      text: ctaText,
      related_identifiers: { programId, programName }
    })
  }

  return (
    <Section>
      <SectionTitle>Live course format</SectionTitle>
      <SectionContent>
        Each week you will go through 2 to 3 hours of self-paced material created by
        leading executives. Expect to spend about 2 hours a week on active learning.
      </SectionContent>

      <div className="mt-4">
        {list.map((item, i) => (
          <div className="mb-4 flex flex-row" key={item.title}>
            <img
              className="mr-4 h-[102px] w-[136px] sm:h-[120px] sm:w-[160px]"
              src={`https://reforge-uploads-dev.s3.amazonaws.com/programs/${programSlug}/${programSlug}--week${
                i + 1
              }.png`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = `https://reforge-uploads-dev.s3.amazonaws.com/programs/default/default--week${
                  i + 1
                }.png`
              }}
              alt={item.title || ''}
            />

            <div>
              <div className="text-xs font-semibold md:text-base">{item.title}</div>

              <Link
                to={`/programs/${programSlug}/preview/material`}
                className="flex items-center text-xs font-semibold md:text-base"
                onClick={() =>
                  handleLinkClick(`/programs/${programSlug}/preview/material`)
                }
              >
                {ctaText}
                <ArrowRightLong className="h-auto w-4 fill-current pl-2 sm:w-6" />
              </Link>
            </div>
          </div>
        ))}
      </div>
    </Section>
  )
}

export default ProgramFormat
