import { useState } from 'react'

import { useCurrentUser } from 'hooks/useCurrentUser'

import {
  identify,
  trackMarketingActionCompleted,
  trackMarketingFormSubmitted
} from 'utils/tracking/analytics'

import ButtonMarketingCTA from '../ButtonMarketingCTA'
import MarketingInput from '../MarketingInput'

interface PodcastSubscribeSectionProps {
  formTitle: string
}

const PodcastSubscribeSection = ({ formTitle }: PodcastSubscribeSectionProps) => {
  const { isLoggedIn } = useCurrentUser()
  const [isSubmitted, setIsSubmitted] = useState(false)

  const trackFormSubmission = () => {
    const email = (document.querySelector('input[name="email"]') as HTMLInputElement)
      .value
    const formFields = { email }

    // legacy Segment event -- need to keep around for LinkedIn and other fun things
    // must keep around until data & marketing states that we're allowed to remove it
    trackMarketingActionCompleted({
      action_type: 'subscribe',
      email,
      list: process.env.REACT_APP_ITERABLE_PODCAST_LIST_ID,
      location: 'podcast index mid-page',
      page_url: window.location.href,
      name: 'Unsolicited Feedback Form',
      type: 'Unsolicited Feedback Form'
    })

    // NEW - generic "Marketing Form Submitted"
    trackMarketingFormSubmitted({
      form_fields: formFields,
      email,
      form_id: process.env.REACT_APP_ITERABLE_PODCAST_LIST_ID!,
      form_type: 'iterable',
      form_name: 'Unsolicited Feedback Form',
      location: 'podcast index mid-page',
      url: window.location.href,
      source: 'marketing site',
      type: 'page'
    })

    setIsSubmitted(true)

    // identify users who haven't been identified yet
    if (!isLoggedIn) {
      identify(undefined, { email })
    }
  }

  return (
    <section className="flex flex-col justify-between bg-rb-background-beige px-[16px] py-[3.3vmax] md:flex-row md:px-[4vw]">
      <h3 className="font-normal tracking-tight fluid-text-3xl md:w-[45%]">
        {formTitle}
      </h3>

      <div className="md:w-[50%]">
        {isSubmitted ? (
          <p>Thank you for signing up!</p>
        ) : (
          <form className="flex flex-col" onSubmit={trackFormSubmission}>
            <label className="mb-[20px] font-serif fluid-text-base">
              Email{' '}
              <span className="tracking-tighter opacity-70 fluid-text-sm">
                (required)
              </span>
              <MarketingInput
                className="fluid-text-base"
                name="email"
                type="email"
                required
              />
            </label>

            <ButtonMarketingCTA type="submit">Submit</ButtonMarketingCTA>
          </form>
        )}
      </div>
    </section>
  )
}

export default PodcastSubscribeSection
