import AiChatEntryImage from 'ai-chat-entry.png'
import { FC } from 'react'

import PremiumPill from 'components/PremiumPill'
import { SelectableTag } from 'components/Tag'

import { SuggestedPrompts } from './SuggestedPrompts'

interface SuggestedPromptsWidgetProps {
  premiumUser: boolean
}

export const SuggestedPromptsWidget: FC<SuggestedPromptsWidgetProps> = ({
  premiumUser
}) => {
  return (
    <div className="@container/widget w-full p-8 bg-white rounded-xl border border-gray-200">
      <div className="w-full flex justify-between">
        <p className="text-xl font-semibold @xl/widget:text-2xl">
          Get strategic advice backed by Reforge&apos;s knowledge base, personalized to
          you.
        </p>
        {premiumUser ? (
          <SelectableTag text="Beta" className="ml-2 cursor-default" />
        ) : (
          <PremiumPill />
        )}
      </div>
      <p className="mt-2">
        Start a conversation with suggested prompts, tailored to you:
      </p>
      <div className="mt-2 flex justify-between items-center">
        <div className="@xl/widget:mr-6">
          <SuggestedPrompts
            pageLocation="content_embedded"
            fullWidth={false}
            showBetaLabel={false}
          />
        </div>
        <div className="hidden @xl/widget:block">
          <img
            src={AiChatEntryImage}
            className="max-w-48"
            alt="A preview of Reforge AI chat. The user asks What's the most effective way to build my go-to-market plan? and the AI responds with an answer."
          />
        </div>
      </div>
    </div>
  )
}
